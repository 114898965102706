import React from 'react';
// import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// import IdentificationEdit from './IdentificationEdit';
import // selectIdentificationUi,
'../../../redux/actions/identification';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import clientsNewStyles from "./ClientsNewStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';
import Button from '../../../components/CustomButtons/Button';

import {FormattedMessage} from 'react-intl';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import IdentificationEditContainer from '../../../components/Identification/IdentificationEdit';
const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientsNew
 * ----------------------------------------------------------------------------
 */
class IdentificationEdit extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const {classes} = this.props;
    // console.log(this.props.match.params.client_id);
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage
                  id='IdentificationFunction.cardItem1.title'
                  defaultMessage={`Identification Record`}
                />
              </h4>
              <div>
                <Button round color='info' onClick={() => this.props.history.push('/identification/list')}>
                  <FormattedMessage id='newClient.cardItem1.showAll' defaultMessage={`Show All`} />
                </Button>
                <Button
                  round
                  color='primary'
                  // onClick={() => this.props.history.push("/identification/new")}
                >
                  <FormattedMessage id='editClient.showRecord' defaultMessage={`Show Record`} />
                </Button>
                <Button round color='info' onClick={() => this.props.history.push('/identification/new')}>
                  <FormattedMessage id='newIdentification.cardItem1.createNew' defaultMessage={`Create New`} />
                </Button>
                <Button
                  round
                  color='info'
                  disabled
                  // onClick={() => this.props.fetchIdentificationList()}
                >
                  <FormattedMessage id='newClient.cardItem1.refresh' defaultMessage={`Refresh`} />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                {/* <ClientEditContainer client_detail_id={this.props.clients.client_detail_id}/> */}
                <IdentificationEditContainer identification_record_id={this.props.match.params.identification_id} />
                {/*<ClientEditContainer client_detail_id="52"/>*/}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

// IdentificationEdit.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withStyles(styles)(IdentificationEdit);
IdentificationEdit =  withStyles(styles)(IdentificationEdit);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    identification: state.identification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // setClientSelectId: (aws_cognito_id) => {
    //   dispatch(setClientSelectId(aws_cognito_id))
    // },
    // selectIdentificationUi: (data) => {
    //   dispatch(selectIdentificationUi(data))
    // },
  };
};

// const IdentificationEditContainer = connect(mapStateToProps, mapDispatchToProps)(IdentificationEdit);
IdentificationEdit = connect(mapStateToProps, mapDispatchToProps)(IdentificationEdit);

// export default IdentificationEditContainer;
export default IdentificationEdit;
