import React from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';

import {API} from 'aws-amplify';
import {AiOutlineSearch} from 'react-icons/ai';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';
import Button from 'components/CustomButtons/Button.jsx';
import {withRouter} from 'react-router-dom';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - Division List
 * ----------------------------------------------------------------------------
 */
class DivisionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_list: []
    };
  }

  componentDidMount() {
    const {fetchEntityListNotDeleted} = this.props;
    fetchEntityListNotDeleted();
  }

  buildTableData() {
    var entity_list_length = this.props.divisions.divisions_list.length;
    if (entity_list_length > 0) {
      return this.props.divisions.divisions_list.map((prop, key) => {
        return {
          id: prop.id,
          nickname: prop.nickname,
          system_email: prop.system_email,
          actions: (
            <div className='actions-right'>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  this.props.history.push(`/divisions/edit/${prop.id}`);
                }}
                color='warning'
                className='edit'
              >
                <Dvr />
              </Button>{' '}
              {/* use this button to remove the data row */}
              {/*<Button*/}
              {/*  justIcon*/}
              {/*  round*/}
              {/*  simple*/}
              {/*  onClick={() => {*/}
              {/*    var data = this.props.divisions.divisions_list;*/}
              {/*    data.find((o, i) => {*/}
              {/*      if (o.id === prop.id) {*/}
              {/*        API.put(*/}
              {/*          "divisions",*/}
              {/*          `/update/id/${prop.id}`,*/}
              {/*          {*/}
              {/*            body: { deleted: true }*/}
              {/*          }*/}
              {/*        )*/}
              {/*          .then(response => {*/}
              {/*            console.log(response);*/}
              {/*            this.props.fetchEntityListNotDeleted();*/}
              {/*            // dispatch(receiveStaffList(response));*/}
              {/*          })*/}
              {/*          .catch(error => {*/}
              {/*            console.log(error);*/}
              {/*          });*/}
              {/*        return true;*/}
              {/*      }*/}
              {/*      return false;*/}
              {/*    });*/}
              {/*    this.setState({ data: data });*/}
              {/*  }}*/}
              {/*  color="danger"*/}
              {/*  className="remove"*/}
              {/*>*/}
              {/*  <Close />*/}
              {/*</Button>{" "}*/}
            </div>
          )
        };
      });
    }
  }

  render() {
    return (
      <ReactTable
        data={this.buildTableData()}
        filterable
        columns={[
          {
            Header: 'Nick Name',
            accessor: 'nickname'
          },
          {
            Header: 'System EMail',
            accessor: 'system_email'
          },
          {
            Header: 'Actions',
            accessor: 'actions',
            sortable: false,
            filterable: false
          }
        ]}
        defaultSorted={[
          {
            id: 'nickname',
            desc: false
          }
        ]}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom={false}
        className='-striped -highlight'
      />
    );
  }
}

export default withRouter(withStyles(styles)(DivisionList));
