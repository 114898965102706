import {connect} from 'react-redux';
import ReportsTransfer from './ReportsTransfer';
import {fetchTransferList, setTransferSelectId} from '../../redux/actions/transfers';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers: state.transfers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTransferList: () => {
      dispatch(fetchTransferList());
    },
    setTransferSelectId: id => {
      dispatch(setTransferSelectId(id));
    }
  };
};

const ReportsTransferContainer = connect(mapStateToProps, mapDispatchToProps)(ReportsTransfer);

export default ReportsTransferContainer;
