import React from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Step2Transfer from './Step2Transfer';
import Step3PayingTo from './Step3PayingTo';

const Step2TransferPayingTo = props => {
  return (
    <GridContainer>
      <GridItem xs={6}>
        <Step2Transfer {...props} />
      </GridItem>
      <GridItem xs={6}>
        <Step3PayingTo {...props} />
      </GridItem>
    </GridContainer>
  );
};

export default Step2TransferPayingTo;
