import React from 'react';
import {connect} from 'react-redux';
import {API} from 'aws-amplify';
// core components
import Wizard from 'components/Wizard/Wizard.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import Step1ClientContainer from './WizardSteps/Step1Client';
// import Step2TransferContainer from './WizardSteps/Step2Transfer';
import Step2TransferPayingTo from './WizardSteps/Step2TransferPayingTo';
import MultiPayTransferStep3Container from './WizardSteps/Step4Confirm';

import {withRouter} from 'react-router-dom';

import {FormattedMessage} from 'react-intl';
import {
  change_current_step,
  clearNewTransfer,
  createNewTransfer,
  updateNewTransferCreation,
  updateNewTransferCreationBeneficiary
} from 'redux/actions/transfers_dashboard_multiple';
import {startNewTransferCreation} from 'redux/actions/transfers_2';

const moment = require('moment');
const uuidv4 = require('uuid/v4');

class CreateMultiPayTransferWizard extends React.Component {
  state = {currentStepState: 0};
  getNextBusinessDay(d, n) {
    d = new Date(d.getTime());
    const day = d.getDay();
    d.setDate(d.getDate() + n + (day === 6 ? 2 : Number(!day)) + Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2);
    return d;
  }

  componentDidMount() {
    this.props.startNewTransferCreation();
  }

  async getWorldPayPaymentURL() {
    console.log(this.props.transfers_2);
    if (this.props.transfers_2.new_transfer_data.currency_from === undefined) {
      return;
    }

    const uuid_number = uuidv4();
    this.props.updateNewTransferCreation('payment_method_reference', uuid_number);

    console.log(this.props.transfers_2.new_transfer_data.amount_from);
    console.log(Number.parseFloat(this.props.transfers_2.new_transfer_data.amount_from) * 100 || '');
    const from_amount = Number.parseFloat(this.props.transfers_2.new_transfer_data.amount_from) * 100 || '';

    const from_currency = this.props.transfers_2.new_transfer_data.currency_from.iso_alpha_3.toUpperCase() || '';
    const clientObj = this.props.transfers_2.new_transfer_data.client || '';

    const url = await API.post('worldpay', '/get-payment-url', {
      body: {
        from_currency: from_currency,
        from_amount: from_amount,
        order_code: uuid_number,
        client: clientObj
      }
    })
      .then(response => {
        const res = JSON.stringify(response.paymentService.reply[0].orderStatus[0].reference[0]);
        const firstExplode = res.split(',');
        let res1 = firstExplode[0].substring(6, firstExplode[0].length - 1);
        res1 = res1.concat(
          '&successURL=https%3A%2F%2Fcrm-api.forexworldwide.com%2Fworldpay%2Fresponse%2Fsuccess&pendingURL=https%3A%2F%2Fcrm-api.forexworldwide.com%2Fworldpay%2Fresponse%2Fpending&failureURL=https%3A%2F%2Fcrm-api.forexworldwide.com%2Fworldpay%2Fresponse%2Ffailure&cancelURL=https%3A%2F%2Fcrm-api.forexworldwide.com%2Fworldpay%2Fresponse%2Fcancel&errorURL=https%3A%2F%2Fcrm-api.forexworldwide.com%2Fworldpay%2Fresponse%2Ferror'
        );
        this.props.updateNewTransferCreation('payment_method_url', res1);
        return res1;
      })
      .catch(error => {
        console.log(error);
      });
    return url;
  }

  finished = async () => {
    console.log('here')
    const set_date = this.getNextBusinessDay(new Date(), 2);
    const settlement_date = set_date.toISOString().slice(0, 10).replace('T', ' ');
    console.log(this.props.transfers_2.new_transfer_data);

    if (this.props.transfers_2.new_transfer_data.payment_method === 'worldpay') {
      const url = await this.getWorldPayPaymentURL();
      window.open(url);
    }

    API.post('email', '/interaction/main', {
      body: {
        interaction: 'New Transfer Request',
        data: {
          ...this.props.transfers_2.new_transfer_data,
          client_id: this.props.transfers_2.new_transfer_data.client.id,
          client_nickname: this.props.transfers_2.new_transfer_data.client.nickname,
          record_created_datetime: new Date().toISOString().slice(0, 19).replace('T', ' '),
          transaction_datetime: new Date().toISOString().slice(0, 10).replace('T', ' '),
          settlement_date: moment(set_date).format('DD/MM/YYYY'),
          status: '2',
          from: 'Step 0 - Email us',
          purpose: 'test only'
        },
        result: 'database submission in process'
      }
    });

    // return;
    await this.props.createNewTransfer({
      ...this.props.transfers_2.new_transfer_data,
      nickname: `${this.props.transfers_2.new_transfer_data.amount_from} ${
        this.props.transfers_2.new_transfer_data.currency_from_name
      } to ${this.props.transfers_2.new_transfer_data.amount_to} ${
        this.props.transfers_2.new_transfer_data.currency_to_name
      } Settling ${moment(set_date).format('DD/MM/YYYY')}`,
      record_created_datetime: new Date().toISOString().slice(0, 10).replace('T', ' '),
      transaction_datetime: new Date().toISOString().slice(0, 10).replace('T', ' '),
      settlement_date: settlement_date,
      client_id: this.props.transfers_2.new_transfer_data.client.id,
      staff_id: this.props.transfers_2.new_transfer_data.client.responsible_staff_member,
      beneficiary_ids: this.props.transfers_2.new_transfer_data.multiple_beneficiary_id,
      status: '2'
    });

    this.props.history.push('/transfers/list');
  };
  currentStep = step => {
    // alert(step);
    this.setState({currentStepState: step});
  };

  checkDisabled = () => {
    let disabled = false;
    if (this.state.currentStepState == 0 && this.props.transfers_2.new_transfer_data.client == undefined) {
      disabled = true;
    } else if (this.state.currentStepState == 1 || this.state.currentStepState == 2) {
      if (!this.props.transfers_2.new_transfer_data.purpose_of_payment_detail) {
        disabled = true;
      }
      if (
        !this.props.transfers_2.new_transfer_data.beneficiary_id &&
        !this.props.transfers_2.new_transfer_data.ledgerTo
      ) {
        disabled = true;
      }
    } else {
      disabled = false;
    }
    if (this.props.transfers_2.new_transfer_data.disable && this.state.currentStepState == 2) {
      disabled = true;
    }
    return disabled;
  };

  render() {
    return (
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12}>
          <Wizard
            // validate
            steps={[
              {
                stepName: 'Client',
                stepComponent: Step1ClientContainer,
                stepId: 'client'
              },
              {
                stepName: 'Transfer',
                stepComponent: Step2TransferPayingTo,
                stepId: 'transfer_to'
              },
              {
                stepName: 'Confirm',
                stepComponent: MultiPayTransferStep3Container,
                stepId: 'confirm'
              }
            ]}
            title={
              <FormattedMessage id='newTransfer.title' defaultMessage={'Start a New FX Transfer'} />
              //"Start a New FX Transfer"
            }
            subtitle={
              <FormattedMessage
                id='newTransfer.subtitle'
                defaultMessage={'Transfer from one currency to make payment in another'}
              />
            }
            currentStep={this.currentStep}
            // isDisabled={
            //   (this.state.currentStepState == 0 && this.props.transfers_2.new_transfer_data.client == undefined) ||
            //   ((this.state.currentStepState === 1 || this.state.currentStepState === 2) &&
            //     ((!this.props.transfers_2.new_transfer_data.beneficiary_id) ||
            //       !this.props.transfers_2.new_transfer_data.purpose_of_payment_detail))
            // }
            isDisabled={this.checkDisabled()}
            finishButtonClick={() => this.finished()}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers_2: state.transfers_2,
    language: state.language,
    transfers_dashboard_multiple: state.transfers_dashboard_multiple
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startNewTransferCreation: () => {
      dispatch(startNewTransferCreation());
    },
    createNewTransfer: data => {
      dispatch(createNewTransfer(data));
    },
    change_current_step: step => {
      dispatch(change_current_step(step));
    },
    clearNewTransfer: () => {
      dispatch(clearNewTransfer());
    },
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    },
    updateNewTransferCreationBeneficiary: array => {
      dispatch(updateNewTransferCreationBeneficiary(array));
    }
  };
};

const CreateMultiPayTransferWizardContaainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMultiPayTransferWizard);

export default withRouter(CreateMultiPayTransferWizardContaainer);
