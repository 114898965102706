import React from 'react';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import passportImage from '../../assets/img/identifications/sample_passport.jpg';
import driversLicenceImage from '../../assets/img/identifications/sample_drivers_vic.jpg';
import medicareImage from '../../assets/img/identifications/sample_medicare.jpg';
import visaImage from '../../assets/img/identifications/sample_visa.jpg';
import birthRecordImage from '../../assets/img/identifications/sample_birthcertificate.jpg';
import marriageRecordImage from '../../assets/img/identifications/sample_marriagecertificate.jpg';
import {BankOutlined, UserOutlined} from '@ant-design/icons';

export const IdentificationTemplates = {
  getDocumentHelp: (documentId) => getDocumentHelp(documentId),
  countries: (sortedCountry) => {
    return sortedCountry.map(
      item => ({
        value: item.id,
        label: (
          <React.Fragment>
            <span>
              <img width={15} height={15}
                src={
                  item.iso_alpha_2 !== ''
                    ? require(`../../assets/img/flags/${
                      item.iso_alpha_2
                    }.png`)
                    : ''
                }
              />
            </span>{' '}
            {' ' + item.iso_alpha_2 + ': ' + item.full_name}{' '}
          </React.Fragment>
        ),
        alt: item.full_name,
      })
    )
  }
}

const getDocumentHelp = (document_category_id) => {
  switch (document_category_id) {
    case 1:
      return (
        <React.Fragment>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <h6>When providing details of a Passport</h6>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <img src={passportImage} alt='Passport' style={{width: '100%'}}/>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <p>When using a passport for identification, we require</p>
              <ul>
                <li>Document number</li>
                <li>Expiry date</li>
                <li>MRZ Line</li>
                <li>Country of issue</li>
              </ul>
            </GridItem>
          </GridContainer>
        </React.Fragment>
      );
    case 2:
      return (
        <React.Fragment>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <h6>When providing details of a Drivers Licnece</h6>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <img src={driversLicenceImage} alt='Drivers licence' style={{width: '100%'}}/>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <p>When using a drivers licence for identification, we require</p>
              <ul>
                <li>Document number</li>
                <li>Expiry date</li>
                <li>State or territory of issue</li>
                <li>Country of issue</li>
                <li>
                                    Please note for NSW drivers licence we require two uploads (the front and back
                                    of the licence)
                </li>
              </ul>
            </GridItem>
          </GridContainer>
        </React.Fragment>
      );
    case 3:
      return (
        <React.Fragment>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <h6>When providing details of a Medicare Card</h6>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <img src={medicareImage} alt='Medicare card' style={{width: '100%'}}/>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <p>When using a Medicare card for identification, we require</p>
              <ul>
                <li>Document number</li>
                <li>Expiry date (month and year only)</li>
                <li>Country of issue</li>
              </ul>
            </GridItem>
          </GridContainer>
        </React.Fragment>
      );
    case 4:
      return (
        <React.Fragment>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <h6>When providing details of a Visa</h6>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <img src={visaImage} alt='Visa' style={{width: '100%'}}/>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <p>When using a visa for identification, we require</p>
              <ul>
                <li>Document number (visa number)</li>
                <li>Expiry date (stay until date)</li>
                <li>Country of issue</li>
              </ul>
            </GridItem>
          </GridContainer>
        </React.Fragment>
      );
    case 5:
      return (
        <React.Fragment>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <h6>When providing details of a Bank Statement</h6>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              {/* <img src={birthRecordImage} alt='Birth record' style={{width: '100%'}}/> */}
            </GridItem>
            <GridItem xs={12} sm={8}>
            <p>When using a bank statement for proof of address, we require</p>
              <ul>
                <li>The top of the statement</li>
                <li>Including full bank details</li>
                <li>We do not need to see your balance</li>
              </ul>
            </GridItem>
          </GridContainer>
        </React.Fragment>
      );
    case 7:
      return (
        <React.Fragment>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <h6>When providing details of a Marriage Record</h6>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <img src={marriageRecordImage} alt='Marriage record' style={{width: '100%'}}/>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <p>When using a birth or marriage record for identification, we require</p>
              <ul>
                <li>Document number</li>
                <li>Country of issue</li>
              </ul>
            </GridItem>
          </GridContainer>
        </React.Fragment>
      );
    default:
      return <React.Fragment/>;
  }
}