import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';
// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import {API} from 'aws-amplify';
import CustomReactSelect from '../Forms/CustomReactSelect/CustomReactSelect';
import NavPills from 'components/NavPills/NavPills.jsx';

import Checkbox from '@material-ui/core/Checkbox';
//import Check from "@material-ui/icons/Check";

const staffEditStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  }
};

class DivisionEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      is_loading: true,
      nickname: '',
      system_email: '',
      entity_id: '',
      staff_admin: '',
      default_beneficiary: '',
      brand_app_name: '',
      brand_send_to_code: '',
      brand_send_to_description: '',
      brand_send_from_code: '',
      brand_send_from_description: '',
      brand_email_signature: '',
      brand_sidebar_identification_list: '',
      brand_sidebar_identification_all: '',
      brand_sidebar_new_transfer: '',
      brand_sidebar_multipay: '',
      brand_sidebar_currencytools: '',
      brand_domain: '',
      entity_list: [],
      staff_list: [],
      beneficiaries_list: [],
      brand_domain_selection_list: [],
      division_shortname: '',
      brand_sidebar_logo: ''
    };
  }

  componentDidMount() {
    if (this.props.division_id) {
      API.get('divisions', `/get/id/${this.props.division_id}`)
        .then(entity_detail => {
          //console.log(entity_detail)
          this.setState({
            loaded: true,
            is_loading: false,
            nickname: entity_detail.nickname,
            system_email: entity_detail.system_email,
            entity_id: entity_detail.entity_id,
            staff_admin: entity_detail.staff_admin,
            default_beneficiary: entity_detail.default_beneficiary,
            brand_app_name: entity_detail.brand_app_name,
            brand_send_to_code: entity_detail.brand_send_to_code,
            brand_send_to_description: entity_detail.brand_send_to_description,
            brand_send_from_code: entity_detail.brand_send_from_code,
            brand_send_from_description: entity_detail.brand_send_from_description,
            brand_email_signature: entity_detail.brand_email_signature,
            brand_sidebar_identification_list: entity_detail.brand_sidebar_identification_list,
            brand_sidebar_identification_all: entity_detail.brand_sidebar_identification_all,
            brand_sidebar_new_transfer: entity_detail.brand_sidebar_new_transfer,
            brand_sidebar_multipay: entity_detail.brand_sidebar_multipay,
            brand_sidebar_currencytools: entity_detail.brand_sidebar_currencytools,
            brand_domain: entity_detail.brand_domain,
            division_shortname: entity_detail.division_shortname,
            brand_sidebar_logo: entity_detail.brand_sidebar_logo
          });
        })
        .catch(error => {
          console.log(error);
        });
    }

    API.get('entities', `/get-list-not-deleted`)
      .then(response => {
        //console.log(response);
        this.setState({
          entity_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });

    API.get('staff', `/get-list-not-deleted`)
      .then(response => {
        this.setState({
          staff_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });

    API.get('beneficiaries', `/get-list-not-deleted`)
      .then(response => {
        this.setState({
          beneficiaries_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });

    API.get('admin', `/domains/get`)
      .then(response => {
        //console.log(response);
        this.setState({
          brand_domain_selection_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  async saveDivision(entity_detail) {
    return API.put('divisions', `/update/id/${this.props.division_id}`, {
      body: entity_detail
    });
  }

  validateForm() {
    var valid = true;
    if (this.state.nickname_state === '') {
      this.setState({nickname_state: 'error'});
      valid = false;
    }

    return valid;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'checkbox':
        if (event.target.checked) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-length':
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'url':
        if (this.verifyUrl(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'min-value':
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-value':
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'range':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      default:
        break;
    }
    switch (type) {
      case 'checkbox':
        this.setState({[stateName]: event.target.checked});
        break;
      default:
        this.setState({[stateName]: event.target.value});
        break;
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleToggle = name => event => {
    this.setState({[name]: event.target.checked});
  };

  async handleSubmit() {
    this.setState({isLoading: true});
    try {
      await this.saveDivision({
        nickname: this.state.nickname,
        system_email: this.state.system_email,
        entity_id: this.state.entity_id,
        staff_admin: this.state.staff_admin,
        default_beneficiary: this.state.default_beneficiary,
        brand_app_name: this.state.brand_app_name,
        brand_send_to_code: this.state.brand_send_to_code,
        brand_send_to_description: this.state.brand_send_to_description,
        brand_send_from_code: this.state.brand_send_from_code,
        brand_send_from_description: this.state.brand_send_from_description,
        brand_email_signature: this.state.brand_email_signature,
        brand_sidebar_identification_list: this.state.brand_sidebar_identification_list,
        brand_sidebar_identification_all: this.state.brand_sidebar_identification_all,
        brand_sidebar_new_transfer: this.state.brand_sidebar_new_transfer,
        brand_sidebar_multipay: this.state.brand_sidebar_multipay,
        brand_sidebar_currencytools: this.state.brand_sidebar_currencytools,
        brand_domain: this.state.brand_domain,
        division_shortname: this.state.division_shortname,
        brand_sidebar_logo: this.state.brand_sidebar_logo
      });
      this.props.history.push(`/system-settings/divisions`);
    } catch (e) {
      alert(e);
    }
  }

  handleCancel() {
    this.props.history.push(`/system-settings/divisions`);
  }

  handleCustomReactSelectChange = name => value => {
    var option_value;
    if (value === null) {
      option_value = null;
    } else {
      option_value = value.value;
    }
    this.setState({
      [name]: option_value
    });
  };

  renderForm() {
    const {classes} = this.props;

    const entity_list_select_options = this.state.entity_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));
    const staff_list_select_options = this.state.staff_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));
    //build options for beneficiary
    const beneficiaries_list_select_options = this.state.beneficiaries_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));

    return (
      <GridItem xs={12} sm={12} md={12}>
        <h3>Edit Division Details</h3>
        <form>
          <CustomInput
            success={this.state.nickname_state === 'success'}
            error={this.state.nickname_state === 'error'}
            labelText='Nick Name *'
            id='nickname'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.nickname,
              onChange: event => this.change(event, 'nickname', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            success={this.state.system_email_state === 'success'}
            error={this.state.system_email_state === 'error'}
            labelText='System email*'
            id='system_email'
            formControlProps={{
              fullWidth: true,
              disabled: true
            }}
            inputProps={{
              value: this.state.system_email,
              type: 'text'
            }}
          />
          <CustomReactSelect
            label='Entity'
            options={entity_list_select_options}
            value={this.state.entity_id}
            onChange={this.handleCustomReactSelectChange('entity_id')}
            isClearable={true}
          />
          <CustomReactSelect
            label='Division Administrator'
            options={staff_list_select_options}
            value={this.state.staff_admin}
            onChange={this.handleCustomReactSelectChange('staff_admin')}
            isClearable={true}
          />
          <div className={classes.formCategory}>
            <small>*</small> Required fields
          </div>

          <CustomReactSelect
            label='Default Beneficiary'
            options={beneficiaries_list_select_options}
            value={this.state.default_beneficiary}
            onChange={this.handleCustomReactSelectChange('default_beneficiary')}
            isClearable={true}
          />

          <Button color='rose' onClick={() => this.handleSubmit()} className={classes.registerButton}>
            Update
          </Button>
          <Button
            // color=""
            onClick={() => this.handleCancel()}
            className={classes.registerButton}
          >
            Cancel
          </Button>
        </form>
      </GridItem>
    );
  }

  renderFormBranding() {
    const {classes} = this.props;

    const entity_list_select_options = this.state.entity_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));
    const staff_list_select_options = this.state.staff_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));
    //build options for beneficiary
    const beneficiaries_list_select_options = this.state.beneficiaries_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));

    //build options for domains #968
    const domains_list_select_options = this.state.brand_domain_selection_list
      .sort((a, b) => a.domain_name.localeCompare(b.domain_name))
      .map(item => ({
        value: item.id,
        label: item.domain_name
      }));

    return (
      <GridItem xs={12} sm={12} md={12}>
        <h3>Edit Division Details</h3>
        <form>
          <CustomInput
            success={this.state.brand_app_name_state === 'success'}
            error={this.state.brand_app_name_state === 'error'}
            labelText='Brand app name'
            id='brand_app_name'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.brand_app_name,
              onChange: event => this.change(event, 'brand_app_name', 'length', 0),
              type: 'text'
            }}
          />

          <CustomInput
            success={this.state.brand_send_to_code === 'success'}
            error={this.state.brand_send_to_code === 'error'}
            labelText='Brand send to code'
            id='brand_send_to_code'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.brand_send_to_code,
              onChange: event => this.change(event, 'brand_send_to_code', 'length', 0),
              type: 'text'
            }}
          />

          <CustomInput
            success={this.state.brand_send_to_description === 'success'}
            error={this.state.brand_send_to_description === 'error'}
            labelText='Brand send to description'
            id='brand_send_to_description'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.brand_send_to_description,
              onChange: event => this.change(event, 'brand_send_to_description', 'length', 0),
              type: 'text'
            }}
          />

          <CustomInput
            success={this.state.brand_send_from_code === 'success'}
            error={this.state.brand_send_from_code === 'error'}
            labelText='Brand send from code'
            id='brand_send_from_code'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.brand_send_from_code,
              onChange: event => this.change(event, 'brand_send_from_code', 'length', 0),
              type: 'text'
            }}
          />

          <CustomInput
            success={this.state.brand_send_from_description === 'success'}
            error={this.state.brand_send_from_description === 'error'}
            labelText='Brand send from description'
            id='brand_send_from_description'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.brand_send_from_description,
              onChange: event => this.change(event, 'brand_send_from_description', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            labelText='Brand app brand email signature'
            id='brand_email_signature'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.brand_email_signature,
              onChange: event => this.change(event, 'brand_email_signature', 'length', 0),
              type: 'text'
            }}
          />
          {
            //Put a DDL here later Issue #968 by Tom
            //database field : divisions.brand_domain
          }
          <CustomReactSelect
            label='Select domain to send emails from'
            options={domains_list_select_options}
            value={this.state.brand_domain}
            onChange={this.handleCustomReactSelectChange('brand_domain')}
            error={this.state.brand_domain === ''}
            isClearable={true}
          />
          {
            //Add these two fields to division settings #970 by Tom
            //datafield:  division_shortname, brand_sidebar_logo
          }
          <CustomInput
            success={this.state.division_shortname === 'success'}
            error={this.state.division_shortname === 'error'}
            labelText='Division Shortname'
            id='division_shortname'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.division_shortname,
              onChange: event => this.change(event, 'division_shortname', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            success={this.state.brand_sidebar_logo === 'success'}
            error={this.state.brand_sidebar_logo === 'error'}
            labelText='Brand sidebar logo'
            id='brand_sidebar_logo'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.brand_sidebar_logo,
              onChange: event => this.change(event, 'brand_sidebar_logo', 'length', 0),
              type: 'text'
            }}
          />

          <Button color='rose' onClick={() => this.handleSubmit()} className={classes.registerButton}>
            Update
          </Button>
          <Button
            // color=""
            onClick={() => this.handleCancel()}
            className={classes.registerButton}
          >
            Cancel
          </Button>
        </form>
      </GridItem>
    );
  }

  renderFormSidebar() {
    const {classes} = this.props;
    return (
      <GridItem xs={12} sm={12} md={12}>
        <h3>Edit Division Details</h3>
        <form>
          <Checkbox
            checked={
              this.state.brand_sidebar_identification_list == 1 || this.state.brand_sidebar_identification_list === true
            }
            value='brand_sidebar_identification_list'
            onClick={this.handleToggle('brand_sidebar_identification_list')}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
            inputProps={{
              disabled: false
            }}
          />
          Show identification list <br />
          <Checkbox
            checked={
              this.state.brand_sidebar_identification_all == 1 || this.state.brand_sidebar_identification_all === true
            }
            value='brand_sidebar_identification_all'
            onClick={this.handleToggle('brand_sidebar_identification_all')}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
            inputProps={{
              disabled: false
            }}
          />
          Show identification system <br />
          <Checkbox
            checked={this.state.brand_sidebar_new_transfer == 1 || this.state.brand_sidebar_new_transfer === true}
            value='brand_sidebar_new_transfer'
            onClick={this.handleToggle('brand_sidebar_new_transfer')}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
            inputProps={{
              disabled: false
            }}
          />
          Show currency transfer <br />
          <Checkbox
            checked={this.state.brand_sidebar_multipay == 1 || this.state.brand_sidebar_multipay === true}
            value='brand_sidebar_multipay'
            onClick={this.handleToggle('brand_sidebar_multipay')}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
            inputProps={{
              disabled: false
            }}
          />
          Show multi pay <br />
          <Checkbox
            checked={this.state.brand_sidebar_currencytools == 1 || this.state.brand_sidebar_currencytools === true}
            value='brand_sidebar_currencytools'
            onClick={this.handleToggle('brand_sidebar_currencytools')}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
            inputProps={{
              disabled: false
            }}
          />
          Show currency tools <br />
          <Button color='rose' onClick={() => this.handleSubmit()} className={classes.registerButton}>
            Update
          </Button>
          <Button
            // color=""
            onClick={() => this.handleCancel()}
            className={classes.registerButton}
          >
            Cancel
          </Button>
        </form>
      </GridItem>
    );
  }

  render() {
    // console.log(this.state.brand_domain_selection_list)
    // console.log(this.state)
    return (
      <div>
        <GridContainer>
          <NavPills
            color='info'
            tabs={[
              {
                tabButton: 'Summary',
                tabContent: this.renderForm()
              },
              {
                tabButton: 'Branding',
                tabContent: this.renderFormBranding()
              },
              {
                tabButton: 'Sidebar',
                tabContent: this.renderFormSidebar()
              }
            ]}
          />
        </GridContainer>
      </div>
    );
  }
}

DivisionEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(staffEditStyles)(DivisionEdit));
