import React from 'react';
import {connect} from 'react-redux';
import {
    update_map_data,
    updateNewTransferCreation,
    updateNewTransferCreationBeneficiary
} from '../../../redux/actions/transfers_dashboard_multiple';
import {createNewBeneficiary} from '../../../redux/actions/beneficiaries';

import withStyles from '@material-ui/core/styles/withStyles';
import dashboardStyle from '../../../views/Pages/DashboardPageStyle';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import queryString from 'query-string';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import {
    Alert,
    Button as AntButton,
    Descriptions,
    Flex,
    Form,
    Image,
    Input,
    InputNumber,
    message,
    Modal,
    Select
} from 'antd';

import {FormattedMessage} from 'react-intl';

import {API, Storage} from 'aws-amplify';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react';
import cardPng from '../../../assets/img/payment_24px.png';
import CommoneCurrentComponent from '../../common/Common';

import {withRouter} from 'react-router-dom';
import Slide from '@material-ui/core/Slide';
import {isEmpty} from 'libs/miscFunc.js';
import {Divider} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import Clearfix from "../../Clearfix/Clearfix";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {BeneficiaryAPI} from "../../Beneficiaries/BeneficiaryUtils";
import AntUploader from "../../AntUploader/AntUploader";
import TypographyCurrency from "../../CurrencySelect/TypographyCurrency";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />;
});
const min_transfer_amount = 0;

const style = {
    ...dashboardStyle,
    ...customCheckboxRadioSwitch,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    infoText: {
        fontWeight: '300',
        margin: '10px 0 30px',
        textAlign: 'center'
    },
    primaryB: {
        fontFamily: 'Roboto',
        width: '100%',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        letterSpacing: '0.15em',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        background: '#7C4DFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), -10px -10px 30px rgba(124, 77, 255, 0.3)'
    },
    primaryC: {
        width: '100%',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        letterSpacing: '0.15em',
        textTransform: 'uppercase',
        backgroundColor: 'transparent'
    },
    inputAdornmentIcon: {color: '#555'},
    inputAdornment: {position: 'relative'},
    selectControl: {
        backgroundColor: '#00BDAB !important',
        color: 'white',
        padding: '5%',
        width: '100% !important'
    },
    optionStyle: {backgroundColor: '#00BDAB !important'},

    column: {
        paddingRight: '0 !important',
        paddingBottom: '0 !important'
    },
    columnRight: {paddingLeft: '0 !important'},
    boxShadow: {
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        boxSizing: 'border-box'
    },
    input: {
        margin: 0,
        padding: 0,
        border: 'none',
        direction: 'rtl',
        paddingRight: '4%',
        fontWeight: '700',
        fontFamily: 'Roboto',
        fontSize: '24px'
    },
    divv: {
        filter: 'drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25))',
        boxSizing: 'border-box'
    },
    pp: {
        float: 'right',
        paddingRight: '9px',
        padding: '0',
        margin: '0',
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '12px',
        paddingTop: '1px'
    },
    iso: {fontSize: '13px !important'},
    isoSpan: {width: '33% !important'},
    textArea: {
        backgroundColor: 'white',
        width: '100%'
    },
    inputReadOnly: {
        paddingTop: '4px',
        zIndex: '-1000',
        paddingRight: '4%',
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '13px',
        margin: 0,
        padding: 0,
        paddingBottom: '0',
        border: 'none',
        direction: 'rtl'
    },
    transferRate: {
        fontFamily: 'Roboto',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '2px',
        paddingLeft: '4%',
        color: '#131313',
        paddingTop: '3%',
        textTransform: 'uppercase'
    },
    selectContainer: {
        padding: '4%',
        background: '#FFFFFF',
        border: '1px solid #0BACF4',
        boxSizing: 'border-box',
        borderRadius: '6px'
    },
    paymentMethod: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '17.04px',
        float: 'right',
        textAlign: 'right',
        opacity: '0.7',
        color: '#131313',
        paddingTop: '4%',
        paddingRight: '3%'
    },
    cardImg: {
        textAlign: 'center',
        paddingLeft: '7% !important'
    },
    transactionOptions: {
        border: 'none',
        width: '100%',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '2px',
        textAlign: 'right',
        letterSpacing: '0.15em',
        textTransform: 'uppercase',
        color: '#0BACF4',
        backgroundColor: 'white'
    },
    benficiaryTitle: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '13px',
        marginBottom: '0 !important',
        paddingBottom: '0 !important'
    },
    beneficiaryDivider: {
        border: '0px solid rgba(0, 0, 0, 0.12)',
        marginTop: '1px !important',
        paddingTop: '0 !important',
        marginBottom: '5% !important'
    },
    createBeneficary: {
        fontFamily: 'Roboto',
        cursor: 'pointer',
        fontWeight: '700',
        fontSize: '16px',
        letterSpacing: '1px',
        color: '#0BACF4'
    }
};

class MultiPayTransferStep2 extends React.Component {

    formRef = React.createRef();
    beneficiaryRef = React.createRef();
    uploadRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            editIndex: null,
            modalOpen: false,
            beneficiaries_list: [],
            purpose_list: [],
            purpose_of_payment_detail: null,
            currencies_list_priority: [],
            map_data: {},
            show_iban: false,
            reRender: true,
            show_bsb: false,
            show_sort_code: false,
            show_swift_code: false,
            show_aba: false,
            show_account_number: false,
            country_list: [],
            currencies_list: [],
            clientTransfers: [],
            ben_telephone_work: '',
            showDocumentUpload: false,
            file_id: null,
            old_file_url: null,
            isSubmittingPayout: false,
            selectedBeneficiaryDetail: null,
            isBenLoading: false
        };
    }

    queryStrings() {
        const query_strings = queryString.parse(this.props.location.search);
        if (Object.keys(query_strings).length !== 0) {
            if (query_strings.client_id) {
                if (query_strings.from_amount) {
                    this.getClientDetails(Number(query_strings.client_id), 'handleToAmount', query_strings);
                }
                if (query_strings.to_amount) {
                    this.getClientDetails(Number(query_strings.client_id), 'handleFromAmount', query_strings);
                }
            }
        }
    }

    async componentDidMount() {
        await API.get('currencies', '/list_not_deleted')
            .then(response => {
                this.setState({currencies_list_priority: response}, () => {
                    this.queryStrings();
                });
            }).catch(error => {
                console.log(error);
            });

        await API.get('countries', '/list_not_deleted')
            .then(response => {
                this.setState({country_list: response});
            })
            .catch(error => {
                console.log(error);
            });

        await API.get('currencies', '/get-all')
            .then(response => {
                this.setState({currencies_list: response});
            })
            .catch(error => {
                console.log(error);
            });
    }

    defaultCurrencies = (name, option_value) => {
        this.setState({[name]: option_value}, () => {
            switch (this.state.last_user_type_amount_name) {
                case 'from_amount':
                    this.handleAmount('to_amount');
                    break;
                case 'to_amount':
                    this.handleAmount('from_amount');
                    break;
                default:
                    this.handleAmount();
            }
        });
        this.setState({[name + '_state']: 'success'});

        if (name === 'from_currency') {
            this.props.updateNewTransferCreation('currency_from_id', option_value);
            this.props.updateNewTransferCreation('currency_from_state', 'success');
            API.get('currencies', `/get/${option_value}`)
                .then(response => {
                    this.props.updateNewTransferCreation('currency_from', response);
                    this.props.updateNewTransferCreation('currency_from_name', response.iso_alpha_3);
                })
                .catch(error => {
                    console.log(error);
                });
        }
        if (name === 'to_currency') {
            this.props.updateNewTransferCreation('currency_to_id', option_value);
            this.props.updateNewTransferCreation('currency_to_state', 'success');
            API.get('currencies', `/get/${option_value}`)
                .then(response => {
                    this.props.updateNewTransferCreation('currency_to', response);
                    this.props.updateNewTransferCreation('currency_to_name', response.iso_alpha_3);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    initializeDefaultCurries = () => {
        this.defaultCurrencies(
            'from_currency',
            this.props.app_state.current_team && (this.props.app_state.current_team.defaultFromCurrency || 1)
        );
        this.defaultCurrencies(
            'to_currency',
            this.props.app_state.current_team && (this.props.app_state.current_team.defaultToCurrency || 5)
        );
    };


    normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e.fileList || null;
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.prefill !== this.props.prefill) {
            this.componentDidMount();
        }
        if (
            this.props.transfers_dashboard_multiple.new_transfer_data.client &&
            this.state.reRender &&
            this.props.app_state.current_division.id
        ) {
            this.initializeDefaultCurries();
            this.setState({reRender: false});
            API.get('transfers', `/get_purpose_by_team/id/${this.props.app_state.current_team.id}`)
                .then(response => {
                    // console.log('response', response);
                    this.setState({purpose_list: response});
                })
                .catch(error => {
                    console.log(error);
                });
        }

        if (
            this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_id &&
            this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_id !==
            prevProps.transfers_dashboard_multiple.new_transfer_data.currency_to_id
        ) {
            BeneficiaryAPI.fetchBeneficiaryByClientId(this.props.transfers_dashboard_multiple.new_transfer_data.client_id)
                .then(response => {
                    const filterer_beneficiaries_list = response;
                    if (filterer_beneficiaries_list.length > 0) {
                        for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
                            if (
                                filterer_beneficiaries_list[i].account_currency !=
                                this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_id
                            ) {
                                filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
                            }
                        }
                    }
                    this.setState({
                        // beneficiaries_list: response
                        beneficiaries_list: filterer_beneficiaries_list,
                        beneficiaries_list_Empty: true
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    fetchBeneficiaries = () => {
        if (this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_id) {
            BeneficiaryAPI.fetchBeneficiaryByClientId(this.props.transfers_dashboard_multiple.new_transfer_data.client_id)
                .then(response => {
                    console.log(response)
                    const filterer_beneficiaries_list = response;
                    if (filterer_beneficiaries_list.length > 0) {
                        for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
                            if (
                                filterer_beneficiaries_list[i].account_currency !=
                                this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_id
                            ) {
                                filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
                            }
                        }
                    }
                    this.setState({
                        // beneficiaries_list: response
                        beneficiaries_list: filterer_beneficiaries_list,
                        beneficiaries_list_Empty: true
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    fetchNewRate = () => {

        let from_currency_Iso = 'AUD';
        let to_currency_Iso = 'USD';

        this.state.currencies_list_priority.map((item, index) => {
            if (item.id == this.state.from_currency) {
                from_currency_Iso = item.iso_alpha_3;
            }
            if (item.id == this.state.to_currency) {
                to_currency_Iso = item.iso_alpha_3;
            }
        });

        const url = 'https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
        axios
            .get(url, {
                params: {
                    currencies: to_currency_Iso,
                    source: from_currency_Iso,
                    format: '1'
                }
            })
            .then(response => {
                const key = from_currency_Iso + to_currency_Iso;
                const rate_string = response.data.quotes[key];

                const rate = Number.parseFloat(rate_string);
                const rate_adjust =
                    Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate) +
                    Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate_division) +
                    Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate_entity) +
                    Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate_team) +
                    Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate_staff);
                let adjusted_rate = rate - rate * rate_adjust;
                // Add Credit Card Rate Adjustment
                // if (this.props.transfers_2.new_transfer_data.payment_method === 'worldpay') {
                //   adjusted_rate = adjusted_rate - adjusted_rate * WORLDPAY_ADJUSTMENT;
                // }

                if (this.state.from_currency == this.state.to_currency) {
                    adjusted_rate = rate;
                }
                adjusted_rate = adjusted_rate.toFixed(4);
                this.props.updateNewTransferCreation('client_rate', adjusted_rate);
                this.setState({adjusted_rate: adjusted_rate});
                // console.log('adjusted_rate', adjusted_rate);
            })
            .catch(error => {
                console.log(error);
            });
    }

    clearBeneficiaryDetails() {
        const beneficiary_array = this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id || [{}];
        beneficiary_array[0].beneficiary = {};
        beneficiary_array[0].beneficiary_id = '';
        beneficiary_array[0].beneficiary_id_state = '';
        this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
    }

    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    // function that verifies if a value is larger than a number or not
    isLargerThanNumber(value, number) {
        if (value !== '' && value >= number) {
            return true;
        }
        return false;
    }

    isSmallerThanNumber(value, number) {
        if (value !== '' && value <= number) {
            return true;
        }
        return false;
    }

    change(event, stateName, type, stateNameEqualTo) {
        console.log('stateName', stateName);
        console.log('value', event.target.value);
        switch (type) {
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({[stateName + '_state']: 'success'});
                } else {
                    this.setState({[stateName + '_state']: 'error'});
                }
                break;
            case 'isLargerThanNumber':
                if (this.isLargerThanNumber(event.target.value, stateNameEqualTo)) {
                    if (stateName === 'from_amount') {
                        this.props.updateNewTransferCreation('amount_from_state', 'success');
                    }
                    if (stateName === 'to_amount') {
                        this.props.updateNewTransferCreation('amount_to_state', 'success');
                    }
                } else {
                    if (stateName === 'from_amount') {
                        this.props.updateNewTransferCreation('amount_from_state', 'error');
                    }
                    if (stateName === 'to_amount') {
                        this.props.updateNewTransferCreation('amount_to_state', 'error');
                    }
                }
                break;
            default:
                break;
        }
        // Calculate "to amount"
        if (stateName === 'from_amount') {
            this.setState({from_amount: event.target.value})
            this.props.updateNewTransferCreation('amount_lock', 'from_amount');
            this.props.updateNewTransferCreation('amount_from', event.target.value);
            this.handleAmount('to_amount');
        }
        // Calculate "from amount"
        if (stateName === 'to_amount') {
            this.setState({to_amount: event.target.value})
            this.props.updateNewTransferCreation('amount_lock', 'to_amount');
            this.props.updateNewTransferCreation('amount_to', event.target.value);
            this.handleAmount('from_amount');
        }
    }

    handleAmountsBackend(buttonID) {
        const adjusted_rate = this.props.transfers_dashboard_multiple.new_transfer_data.client_rate;
        console.log('adjusted_rate', adjusted_rate);
        if (this.state.from_currency_state === 'success' && this.state.to_currency_state === 'success') {
            let amount_from;
            let amount_to;
            switch (buttonID) {
                case 1:
                    amount_from = Number.parseFloat(this.state.from_amount);
                    amount_to = amount_from * adjusted_rate;
                    this.setState({to_amount: amount_to.toFixed(2)});
                    this.props.updateNewTransferCreation('amount_to', amount_to.toFixed(2));
                    if (amount_to >= min_transfer_amount) {
                        this.setState({to_amount_state: 'success'});
                    } else {
                        this.setState({to_amount_state: 'error'});
                    }
                    break;

                case 2:
                    amount_from = Number.parseFloat(this.state.from_amount);
                    amount_to = amount_from / adjusted_rate;
                    this.setState({to_amount: amount_to.toFixed(2)});
                    this.props.updateNewTransferCreation('amount_to', amount_to.toFixed(2));
                    if (amount_to >= min_transfer_amount) {
                        this.setState({to_amount_state: 'success'});
                    } else {
                        this.setState({to_amount_state: 'error'});
                    }
                    break;

                case 3:
                    // amount_to = Number.parseFloat(this.state.to_amount);
                    amount_to = Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.amount_to);
                    amount_from = amount_to * adjusted_rate;
                    this.setState({from_amount: amount_from.toFixed(2)});
                    this.props.updateNewTransferCreation('amount_from', amount_from.toFixed(2));
                    if (amount_to >= min_transfer_amount) {
                        this.setState({to_amount_state: 'success'});
                    } else {
                        this.setState({to_amount_state: 'error'});
                    }
                    break;

                case 4:
                    // amount_to = Number.parseFloat(this.state.to_amount);
                    amount_to = Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.amount_to);
                    amount_from = amount_to / adjusted_rate;
                    this.setState({from_amount: amount_from.toFixed(2)});
                    this.props.updateNewTransferCreation('amount_from', amount_from.toFixed(2));
                    if (amount_to >= min_transfer_amount) {
                        this.setState({to_amount_state: 'success'});
                    } else {
                        this.setState({to_amount_state: 'error'});
                    }
                    break;

            }
        }
    }

    handleAmount(amount_name) {
        let from_currency_Iso = 'AUD';
        let to_currency_Iso = 'USD';

        this.state.currencies_list_priority.map((item, index) => {
            if (item.id == this.state.from_currency) {
                from_currency_Iso = item.iso_alpha_3;
            }

            if (item.id == this.state.to_currency) {
                to_currency_Iso = item.iso_alpha_3;
            }
        });

        const url = 'https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
        axios
            .get(url, {
                params: {
                    currencies: to_currency_Iso,
                    source: from_currency_Iso,
                    format: '1'
                }
            })
            .then(response => {
                const key = from_currency_Iso + to_currency_Iso;
                const rate_string = response.data.quotes[key];

                const rateFromState = this.props.transfers_dashboard_multiple.new_transfer_data.client_rate;

                const rate = rateFromState != null && rateFromState !== ""
                    ? Number(this.props.transfers_dashboard_multiple.new_transfer_data.client_rate)
                    : Number.parseFloat(rate_string);
                const rate_adjust =
                    Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate) +
                    Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate_division) +
                    Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate_entity) +
                    Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate_team) +
                    Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate_staff);

                let adjusted_rate = rate - rate * rate_adjust;
                if (rateFromState != null && rateFromState !== "") {
                    adjusted_rate = rate;
                } else {
                    if (this.state.from_currency === this.state.to_currency) {
                        adjusted_rate = rate;
                    }
                    adjusted_rate = Number.parseFloat(adjusted_rate).toFixed(4);
                }
                this.props.updateNewTransferCreation('client_rate', adjusted_rate);
                this.setState({adjusted_rate: adjusted_rate});
                if (this.state.from_currency_state === 'success' && this.state.to_currency_state === 'success') {
                    // handle to_amount
                    if (
                        amount_name === 'to_amount' && this.state.to_currency_state === 'success'
                    ) {
                        const amount_from = Number.parseFloat(
                            this.props.transfers_dashboard_multiple.new_transfer_data.amount_from
                        );
                        const amount_to = amount_from * adjusted_rate;

                        this.setState({to_amount: amount_to.toFixed(2)});
                        this.props.updateNewTransferCreation('amount_to', amount_to.toFixed(2));
                        if (amount_to >= min_transfer_amount) {
                            this.props.updateNewTransferCreation('amount_to_state', 'success');
                        } else {
                            this.props.updateNewTransferCreation('amount_to_state', 'error');
                        }
                    }

                    // handle from_amount
                    if (amount_name === 'from_amount') {
                        const amount_to = Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.amount_to);
                        const amount_from = amount_to / adjusted_rate;

                        this.setState({from_amount: amount_from.toFixed(2)});
                        this.props.updateNewTransferCreation('amount_from', amount_from.toFixed(2));
                        if (amount_from >= min_transfer_amount) {
                            this.props.updateNewTransferCreation('amount_from_state', 'success');
                        } else {
                            this.props.updateNewTransferCreation('amount_from_state', 'error');
                        }
                    }
                }

            })
            .catch(error => {
                console.log(error);
            });
    }

    handleCustomReactSelectChange = (name, index, previous_from_currency, previous_to_currency) => value => {
        let option_value;
        let option_label;
        if (value === null) {
            option_value = null;
            option_label = null;
        } else {
            option_value = value.value;
            option_label = value.label;
        }

        if (name === 'bank_country') {
            const country_dataset = this.state.country_list;
            for (let i = 0; i < country_dataset.length; i++) {
                if (country_dataset[i].id === option_value) {
                    console.table(country_dataset[i]);
                    this.setState({
                        show_swift_code: true,
                        show_account_number: true
                    });

                    //iban check
                    if (country_dataset[i].use_iban === 1) {
                        this.setState({show_iban: true});
                    } else {
                        this.setState({show_iban: false});
                    }

                    //bsb check
                    if (country_dataset[i].use_bsb === 1) {
                        this.setState({show_bsb: true});
                    } else {
                        this.setState({show_bsb: false});
                    }

                    //aba_routing check
                    if (country_dataset[i].use_aba === 1) {
                        this.setState({show_aba: true});
                    } else {
                        this.setState({show_aba: false});
                    }

                    //sort_code check
                    if (country_dataset[i].use_sort_code === 1) {
                        this.setState({show_sort_code: true});
                    } else {
                        this.setState({show_sort_code: false});
                    }
                    //account_number check
                    if (country_dataset[i].use_account_number === 1) {
                        this.setState({show_account_number: true});
                    } else {
                        this.setState({show_account_number: false});
                    }

                    // transit_code
                    if (country_dataset[i].use_transit_code === 1) {
                        this.setState({show_transit_code: true});
                    } else {
                        this.setState({show_transit_code: false});
                    }

                    // bank_code
                    if (country_dataset[i].use_bank_code === 1) {
                        this.setState({show_bank_code: true});
                    } else {
                        this.setState({show_bank_code: false});
                    }
                }
            }
        }

        if (name === 'account_currency' || name == 'to_currency') {
            //do something here
            const currency_dataset = this.state.currencies_list_priority;
            for (let i = 0; i < currency_dataset.length; i++) {
                if (currency_dataset[i].id === option_value) {
                    //iban check
                    if (currency_dataset[i].iban === 1) {
                        this.setState({show_iban: true});
                    } else {
                        this.setState({show_iban: false});
                    }
                    //bsb check
                    if (currency_dataset[i].bsb === 1) {
                        this.setState({show_bsb: true});
                    } else {
                        this.setState({show_bsb: false});
                    }
                    //sort_code check
                    if (currency_dataset[i].sort_code === 1) {
                        this.setState({show_sort_code: true});
                    } else {
                        this.setState({show_sort_code: false});
                    }
                    //swift_code check
                    if (currency_dataset[i].swift_code === 1) {
                        this.setState({show_swift_code: true});
                    } else {
                        this.setState({show_swift_code: false});
                    }
                    //aba_routing check
                    if (currency_dataset[i].aba_routing === 1) {
                        this.setState({show_aba: true});
                    } else {
                        this.setState({show_aba: false});
                    }
                    //account number check
                    if (currency_dataset[i].account_number === 1) {
                        this.setState({show_account_number: true});
                    } else {
                        this.setState({show_account_number: false});
                    }
                }
            }
        }

        this.setState({[name]: option_value}, () => {
            if (name === 'from_currency' || name === 'to_currency') {
                switch (this.props.transfers_dashboard_multiple.new_transfer_data.amount_lock) {
                    case 'from_amount':
                        this.handleAmount('to_amount');
                        break;
                    case 'to_amount':
                        this.handleAmount('from_amount');
                        break;
                    default:
                        this.handleAmount();
                }
            }
        });

        if (name === 'from_currency' || name === 'to_currency') {
            this.updateMapData(name, previous_from_currency, previous_to_currency, value);
            if (name === 'from_currency') {
                this.props.updateNewTransferCreation('currency_from_id', option_value);
                this.props.updateNewTransferCreation('currency_from_state', 'success');
                API.get('currencies', `/get/${option_value}`)
                    .then(response => {
                        this.props.updateNewTransferCreation('currency_from', response);
                        this.props.updateNewTransferCreation('currency_from_name', response.iso_alpha_3);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            if (name === 'to_currency') {
                this.props.updateNewTransferCreation('currency_to_id', option_value);
                this.props.updateNewTransferCreation('currency_to_state', 'success');
                this.setState({account_currency: option_value});

                //set a resumable_beneficiary_list
                API.get('beneficiaries', `/get/id/${option_value}`)
                    .then(response => {
                        const resume_beneficiaries_list = response;

                        if (resume_beneficiaries_list.length > 0) {
                            //remove items from beneficiary-list by not related this to_currency items
                            for (let i = resume_beneficiaries_list.length - 1; i > -1; i--) {
                                if (resume_beneficiaries_list[i].account_currency !== option_value) {
                                    //response.remove(i);
                                    resume_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
                                    //console.log("remove index "+i)
                                }
                            }
                        }

                        this.setState({
                            beneficiaries_list: resume_beneficiaries_list,
                            account_currency: option_value
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });

                API.get('currencies', `/get/${option_value}`)
                    .then(response => {
                        this.props.updateNewTransferCreation('currency_to', response);
                        this.props.updateNewTransferCreation('currency_to_name', response.iso_alpha_3);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }

        if (name === 'purpose_of_payment_detail') {
            const clientTransfers = [...this.state.clientTransfers];
            clientTransfers[index] = {
                ...clientTransfers[index],
                [name]: option_value
            };
            clientTransfers[index] = {
                ...clientTransfers[index],
                [name + '_state']: 'success'
            };
            let document_upload_show = false;
            for (const key in this.state.purpose_list) {
                if (this.state.purpose_list[key].id == option_value) {
                    if (this.state.purpose_list[key].document_upload == 1) {
                        document_upload_show = true;
                    }
                }
            }

            clientTransfers[index] = {
                ...clientTransfers[index],
                showDocumentUpload: document_upload_show
            };
            this.setState({clientTransfers});
            this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
        }

        this.fetchBeneficiaries();
    };

    updateMapData(currency_name, previous_from_currency, previous_to_currency, currency_object) {
        const map_values = this.state.map_data;
        const from_currency_map_value = 0;
        const to_currency_map_value = 1;

        // update the properties for visulization
        if (currency_name === 'from_currency') {
            // delele previous currency property in map_values
            if (previous_from_currency !== previous_to_currency) {
                this.deleleCurrency(map_values, previous_from_currency, previous_to_currency);
            }
            // add new currency property in map_values
            this.addCurrency(map_values, currency_object.value, from_currency_map_value);
        }
        if (currency_name === 'to_currency') {
            // delele previous currency property in map_values
            if (previous_to_currency !== previous_from_currency) {
                this.deleleCurrency(map_values, previous_to_currency, previous_from_currency);
            }
            // add new currency property in map_values
            this.addCurrency(map_values, currency_object.value, to_currency_map_value);
        }
        this.setState({map_data: map_values});
        this.props.update_map_data(map_values);
    }

    deleleCurrency(map_values, currency_deleted, the_other_currency) {
        switch (currency_deleted) {
            case 1: // AUD
                delete map_values.AU; // Australia
                break;
            case 5: // USD
                delete map_values.US; // United States
                break;
            case 8: // GBP
                // if the other currency is not Euro, delete GB
                if (the_other_currency !== 9) {
                    delete map_values.GB; // United Kingdom
                }
                break;
            case 9: // Eur. For Europe: italy, spain, france, portugal, germany
                // if the other currency is not GB, delete GB
                if (the_other_currency !== 8) {
                    delete map_values.GB; // United Kingdom
                }
                delete map_values.IT; // Italy
                delete map_values.ES; // Spain
                delete map_values.FR; // France
                delete map_values.PT; // Portugal
                delete map_values.DE; // Germany
                break;
            case 6: // ATS
                delete map_values.AT; // 	Austria
                break;
            case 16:
                delete map_values.JP;
                break;
            case 17:
                delete map_values.NZ;
                break;
            case 18:
                delete map_values.CA;
                break;
            case 19:
                delete map_values.HK;
                break;
            case 20:
                delete map_values.SG;
                break;
            case 21:
                delete map_values.CH;
                break;
            case 22:
                delete map_values.TH;
                break;
            case 23:
                delete map_values.DK;
                break;
            case 24:
                delete map_values.NO;
                break;
            case 25:
                delete map_values.SE;
                break;
            case 30:
                delete map_values.NP;
                break;
            case 31:
                delete map_values.VN;
                break;
            default:
        }
    }

    addCurrency(map_values, currency, currency_map_value) {
        switch (currency) {
            case 1:
                map_values.AU = currency_map_value;
                break;
            case 5:
                map_values.US = currency_map_value;
                break;
            case 8:
                map_values.GB = currency_map_value;
                break;
            case 9:
                map_values.GB = currency_map_value;
                map_values.IT = currency_map_value;
                map_values.ES = currency_map_value;
                map_values.FR = currency_map_value;
                map_values.PT = currency_map_value;
                map_values.DE = currency_map_value;
                break;
            case 6:
                map_values.AT = currency_map_value;
                break;
            case 16:
                map_values.JP = currency_map_value;
                break;
            case 17:
                map_values.NZ = currency_map_value;
                break;
            case 18:
                map_values.CA = currency_map_value;
                break;
            case 19:
                map_values.HK = currency_map_value;
                break;
            case 20:
                map_values.SG = currency_map_value;
                break;
            case 21:
                map_values.CH = currency_map_value;
                break;
            case 22:
                map_values.TH = currency_map_value;
                break;
            case 23:
                map_values.DK = currency_map_value;
                break;
            case 24:
                map_values.NO = currency_map_value;
                break;
            case 25:
                map_values.SE = currency_map_value;
                break;
            case 30:
                map_values.NP = currency_map_value;
                break;
            case 31:
                map_values.VN = currency_map_value;
                break;
            default:
        }
    }

    onEditPayoutRecord = (index) => {
        this.setState({editIndex: index});
        const beneficiaries = this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id || [];
        const data = beneficiaries[index];
        this.formRef.current.setFieldValue('beneficiary_id', data.beneficiary_id);
        this.formRef.current.setFieldValue('purpose_of_payment_detail', data.purpose_of_payment_detail);
        this.formRef.current.setFieldValue('invoice_number', data.invoice_number);
        this.formRef.current.setFieldValue('amount', data.amount);
        this.setState({old_file_url: data.file_url});
        if (data.purpose_of_payment_detail === 1) {
            this.formRef.current.setFieldValue('purpose_of_payment_other', data.purpose_of_payment_other);
        }
        if (data.file_id != null) {
            this.setState({file_id: data.document});
        }
        if (data.beneficiary_id) {
            this.fetchBeneficiaryById(data.beneficiary_id);
        }
        this.uploadRef.current.clearList();
        this.setState({modalOpen: true});
    }

    onDeletePayoutRecord = (index) => {
        const transfers = this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id || [];
        const transferToInclude = transfers.filter((item, i) => i !== index);
        this.props.updateNewTransferCreation('multiple_beneficiary_id', transferToInclude);
        this.onAmountChange(transferToInclude);
    }

    fetchBeneficiaryFromState = (data, index) => {
        const items = [
            {
                key: 1,
                label: <TypographyCurrency iso_alpha_3={this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_name}>{data.beneficiary.ben_legal_name}</TypographyCurrency>,
                children: <>
                    <div style={{float: 'right'}}>
                        <AntButton onClick={() => this.onEditPayoutRecord(index)}
                                   style={{marginRight: '10px', float: 'left'}} type={'primary'} size={'small'}
                                   icon={<EditOutlined/>}></AntButton>
                        <AntButton style={{float: 'right'}} type={'primary'} size={'small'} danger={true}
                                   icon={<DeleteOutlined/>}
                                   onClick={() => this.onDeletePayoutRecord(index)}></AntButton>
                    </div>
                    <Clearfix/>
                </>
            },
            {
                key: 2,
                label: 'Purpose of Payment',
                children: <>{data.purpose_of_payment_detail === 1 ? data.purpose_of_payment_other : this.state.purpose_list.find(item => item.id === data.purpose_of_payment_detail) && this.state.purpose_list.find(item => item.id === data.purpose_of_payment_detail).description}</>
            },
            {
                key: 3,
                label: 'Invoice Number',
                children: <>{data.invoice_number}</>
            },
            {
                key: 4,
                label: 'Amount',
                children: <><TypographyCurrency iso_alpha_3={this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_name}>{data.amount}</TypographyCurrency></>
            }
        ];
        if (data.document && data.file_url) {
            items.push({
                key: 5,
                label: 'Receipt',
                children: <Image width={102} src={data.file_url}></Image>
            })
        }
        return <div style={{
            border: '1px solid #00acc1',
            padding: '5px',
            marginRight: '10px',
            marginBottom: '10px',
            borderRadius: '5px'
        }}>
            <Descriptions layout={'horizontal'}
                          column={1}
                          size={'small'}
                          bordered items={items}></Descriptions>
        </div>
    }

    onBeneficiaryModalOpen = () => {
        this.setState({addBeneficiaryModalOpen: true}, () => {
            this.beneficiaryRef.current.setFieldValue('account_currency', this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_id);
        })
    }

    render_beneficiary_details_section = () => {
        const {classes} = this.props;
        if (
            this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_state !== 'success' ||
            this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_state !== 'success'
        ) {
            return null;
        }

        return (
            <React.Fragment>
                <GridContainer justify='flex-start'>
                    <GridItem xs={12} sm={12}>
                        <h4 style={{float: 'left'}}>Beneficiaries</h4>
                        <AntButton style={{float: 'right', marginRight: '10px'}} type={'primary'}
                                   onClick={() => this.onBeneficiaryModalOpen() }><span><i className={'fa fa-plus'}></i> Create New Beneficiary</span></AntButton>
                        <Clearfix/>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12}>
                        {(this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id || []).map((item, index) => {
                            return <>
                                {this.fetchBeneficiaryFromState(item, index)}
                            </>
                        })}
                        {this.createUI()}
                    </GridItem>
                </GridContainer>
                <Flex justify={'space-evenly'}>
                    <AntButton style={{width: '100%', margin: '5px'}} type={'primary'} danger={true} key='addKey'
                               onClick={() => this.setState({modalOpen: true, editIndex: null})}>
                        <span>Add New Payout Record <i className={'fa fa-plus'}></i></span>
                    </AntButton>
                </Flex>
            </React.Fragment>
        );
    };

    handleSelectChange = event => {
        this.setState({[event.target.name]: event.target.value});
        this.props.updateNewTransferCreation(event.target.name, event.target.value);
        if (event.target.name === 'beneficiary_id') {
            this.getBeneficiaryDetails(event.target.value);
        }
    };

    createBeneficiaryIntoDatabase = async data => {
        await this.props.createNewBeneficiary(data);
        const th = this;
        setTimeout(async () => {
            await BeneficiaryAPI.fetchBeneficiaryById(this.props.transfers_dashboard_multiple.new_transfer_data.client.id)
                .then(response => {
                    const filterer_beneficiaries_list = response;
                    if (filterer_beneficiaries_list.length > 0) {
                        for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
                            if (
                                filterer_beneficiaries_list[i].account_currency !=
                                this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_id
                            ) {
                                filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
                            }
                        }
                    }
                    th.setState({
                        beneficiaries_list: filterer_beneficiaries_list,
                        beneficiaries_list_Empty: true
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }, 2000);

        this.setState({
            ben_legal_name: '',
            ben_email_main: '',
            ben_address_line_1: '',
            ben_address_line_2: '',
            ben_address_suburb: '',
            ben_address_state: '',
            ben_address_postcode: '',
            ben_address_country: '',
            ben_telephone_work: '',

            bank_legal_name: '',
            iban: '',
            account_currency: '',
            bsb_code: '',
            swift_code: '',
            account_number: '',
            aba_routing_number: '',
            sort_code: '',
            bank_code: '',
            transit_code: ''
        });
        return;
    };

    finished = async (values) => {
        this.setState({isBenLoading: true});
        try {
            await this.createBeneficiaryIntoDatabase({
                client_id: this.props.transfers_dashboard_multiple.new_transfer_data.client.id,
                nickname: values.ben_legal_name,
                ben_legal_name: values.ben_legal_name,
                ben_email_main: values.ben_email_main,
                ben_address_line_1: values.ben_address_line_1,
                ben_address_line_2: values.ben_address_line_2,
                ben_address_suburb: values.ben_address_suburb,
                ben_address_state: values.ben_address_state,
                ben_address_postcode: values.ben_address_postcode,
                ben_address_country: values.ben_address_country,
                ben_telephone_work: values.ben_telephone_work,
                bank_legal_name: values.bank_legal_name,
                bank_address_country: values.bank_country,
                iban: values.iban,
                account_currency: values.account_currency,
                bsb_code: values.bsb_code,
                swift_code: values.swift_code,
                account_number: values.account_number,
                aba_routing_number: values.aba_routing_number,
                sort_code: values.sort_code,
                bank_code: values.bank_code,
                transit_code: values.transit_code
            });
            this.setState({addBeneficiaryModalOpen: false, isBenLoading: false});
            this.beneficiaryRef.current.resetFields();
            this.fetchBeneficiaries();
        } catch (e) {
            console.log('errro', e);
            this.setState({isBenLoading: false});
        }
    };

    addBeneficiaryModelClose = () => {
        this.setState({
            addBeneficiaryModalOpen: false,
            ben_legal_name: '',
            ben_email_main: '',
            ben_address_line_1: '',
            ben_address_line_2: '',
            ben_address_suburb: '',
            ben_address_state: '',
            ben_address_postcode: '',
            ben_address_country: '',
            ben_telephone_work: '',
            bank_country: '',
            bank_legal_name: '',
            iban: '',
            bsb_code: '',
            swift_code: '',
            account_number: '',
            aba_routing_number: '',
            sort_code: '',
            bank_code: '',
            transit_code: ''
        });
        this.beneficiaryRef.current.resetFields();
    };

    newBeneficiaryChange(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'email':
                if (this.verifyEmail(event.target.value)) {
                    this.setState({[stateName + '_state']: 'success'});
                } else {
                    this.setState({[stateName + '_state']: 'error'});
                }
                break;
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({[stateName + '_state']: 'success'});
                } else {
                    this.setState({[stateName + '_state']: 'error'});
                }
                break;
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({[stateName + '_state']: 'success'});
                } else {
                    this.setState({[stateName + ' _state']: 'error'});
                }
                break;
            case 'equalTo':
                if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
                    this.setState({[stateName + '_state']: 'success'});
                } else {
                    this.setState({[stateName + '_state']: 'error'});
                }
                break;
            default:
                break;
        }
        this.setState({[stateName]: event.target.value});
    }

    handleChange(e, i, stateName, type, criteria) {
        const {name, value} = e.target;
        const clientTransfers = [...this.state.clientTransfers];
        clientTransfers[i] = {
            ...clientTransfers[i],
            [stateName]: value
        };

        switch (type) {
            case 'isLargerThanNumber':
                if (this.isLargerThanNumber(value, criteria)) {
                    clientTransfers[i] = {
                        ...clientTransfers[i],
                        [stateName + '_state']: 'success'
                    };
                } else {
                    clientTransfers[i] = {
                        ...clientTransfers[i],
                        [stateName + '_state']: 'error'
                    };
                }
                break;
            case 'isSmallerThanNumber':
                if (this.isSmallerThanNumber(value, criteria)) {
                    clientTransfers[i] = {
                        ...clientTransfers[i],
                        [stateName + '_state']: 'success'
                    };
                } else {
                    clientTransfers[i] = {
                        ...clientTransfers[i],
                        [stateName + '_state']: 'error'
                    };
                }
                break;
            default:
                break;
        }
        if (stateName === 'purpose_of_payment_other') {
            if (value !== '') {
                clientTransfers[i] = {
                    ...clientTransfers[i],
                    [stateName + '_state']: 'success'
                };
            } else {
                clientTransfers[i] = {
                    ...clientTransfers[i],
                    [stateName + '_state']: 'error'
                };
            }
        }
        this.setState({clientTransfers});
        this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);

        if (stateName == 'amount') {
            const clientTransfers = [...this.state.clientTransfers];
            console.log('state ', clientTransfers);
            let total_amount = 0;
            let tmpValue = 0;

            clientTransfers.map((el, index) => {
                if (index == i) {
                    if (isEmpty(value)) {
                        tmpValue = 0;
                    } else {
                        tmpValue = value.replace(/,/g, '');
                    }
                    total_amount = Number.parseFloat(total_amount) + Number.parseFloat(tmpValue);
                } else {
                    if (isEmpty(el.amount)) {
                        tmpValue = 0;
                    } else {
                        tmpValue = el.amount.replace(/,/g, '');
                    }
                    total_amount = Number.parseFloat(total_amount) + Number.parseFloat(tmpValue);
                }
            });

            this.setState({amount_to: total_amount});
            this.props.updateNewTransferCreation('amount_to', total_amount);
            if (total_amount > min_transfer_amount) {
                if (this.props.transfers_dashboard_multiple.new_transfer_data.amount_to_state !== 'success') {
                    this.props.updateNewTransferCreation('amount_to_state', 'success');
                }
            } else {
                this.props.updateNewTransferCreation('amount_to_state', 'error');
            }

            this.handleAmount('from_amount');
            this.props.updateNewTransferCreation(
                'nickname',
                `FX Transfer ${moment().format('DD/MM/YY')} ${Number.parseFloat(total_amount).toFixed(2)} ${
                    this.props.transfers_dashboard_multiple.new_transfer_data.currency_to
                        ? this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3
                        : ''
                }`
            );
        }
    }

    handleRateChange = e => {
        const {name, value} = e.target;
        this.props.updateNewTransferCreation('client_rate', value);
        this.setState({adjusted_rate: value});
        // this.handleAmount('from_amount');
    }

    onNewPayoutRecordFormSubmit = (values) => {
        if  (values.amount_reverse != null && values.amount_reverse !== "" && !isNaN(values.amount_reverse)) {
            values.amount = Number((values.amount_reverse * Number(this.props.transfers_dashboard_multiple.new_transfer_data.client_rate)).toFixed(2));
        }
        this.setState({ isSubmittingPayout: true });
        let payoutRecords = this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id || [];
        BeneficiaryAPI.fetchBeneficiaryById(values.beneficiary_id)
            .then(async response => {
                if (this.state.file_id != null) {
                    const fileUrl = await Storage.get(this.state.file_id);
                    values = {...values, document: this.state.file_id, file_url: fileUrl};
                }
                if (this.state.editIndex != null) {
                    payoutRecords[this.state.editIndex] = {...values, beneficiary: response};
                } else {
                    values = {...values, beneficiary: response};
                    payoutRecords.push(values);
                }
                this.setState({
                    modalOpen: false,
                    editIndex: null,
                    file_id: null,
                    old_file_url: null,
                    isSubmittingPayout: false
                });
                this.props.updateNewTransferCreation('multiple_beneficiary_id', payoutRecords);
                this.onAmountChange(payoutRecords);
                this.formRef.current.resetFields();
                this.uploadRef.current.clearList();
            }).catch(error => {
            console.log(error);
            message.error("Failed to fetch the beneficiaries details")
        });
    }

    onAmountChange = (clientTransfers) => {
        console.log('state ', clientTransfers);
        let total_amount = clientTransfers.map(i => i.amount).reduce((a, b) => a + b, 0);
        this.setState({amount_to: total_amount});
        this.props.updateNewTransferCreation('amount_to', total_amount);
        this.handleAmount('from_amount');
        this.props.updateNewTransferCreation(
            'nickname',
            `FX Transfer ${moment().format('DD/MM/YY')} ${Number.parseFloat(total_amount).toFixed(2)} ${
                this.props.transfers_dashboard_multiple.new_transfer_data.currency_to
                    ? this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3
                    : ''
            }`
        );
    }

    handleImageUpload = (file_id) => {
        console.log(file_id);
        this.setState({file_id: file_id});
    }

    fetchBeneficiaryById = (benId) => {
        BeneficiaryAPI.fetchBeneficiaryById(benId)
            .then(response => this.setState({selectedBeneficiaryDetail: response}))
            .catch(error => console.log(error));
    }

    createUI() {
        const {classes} = this.props;
        const beneficiary_list_select_options = this.state.beneficiaries_list
            .sort((a, b) => {
                if (a.nickname && b.nickname) {
                    return a.nickname.localeCompare(b.nickname);
                }
                return a.id > b.id;
            })
            .map(item => {

                const currency = this.state.currencies_list_priority.find(i => i.id === item.account_currency);
                return {
                    value: item.id,
                    label: <TypographyCurrency iso_alpha_3={currency.iso_alpha_3}>{item.nickname}</TypographyCurrency>,
                    alt: item.nickname,
                }
            });

        const purpose_of_payment_select_options = this.state.purpose_list.map(item => ({
            value: item.id,
            label: item.description
        }));

        return <>
            <Modal title={'Add New Payout Record'}
                   open={this.state.modalOpen}
                   onOk={() => {
                       this.formRef.current.submit()
                   }}
                   okButtonProps={{
                       loading: this.state.isSubmittingPayout === true
                   }}
                   onCancel={() => {
                       this.setState({modalOpen: false, editIndex: null, file_id: null, old_file_url: null, selectedBeneficiaryDetail: null});
                       this.formRef.current.resetFields();
                       this.uploadRef.current.clearList();
                   }}>
                <Form name={`payout-record`} labelCol={{span: 10}}
                      ref={this.formRef} layout={'horizontal'} onFinish={this.onNewPayoutRecordFormSubmit}>
                    <Form.Item name="beneficiary_id" label="Select beneficiary"
                               rules={[{required: true, message: 'Beneficiary is required'}]}>
                        <Select placeholder={'-- SELECT BENEFICIARY --'}
                                showSearch
                                onChange={(val) => this.fetchBeneficiaryById(val)}
                                options={beneficiary_list_select_options}></Select>
                    </Form.Item>
                    {
                        this.state.selectedBeneficiaryDetail && <>
                            <Form.Item label="Beneficiary Detail"
                                       rules={[{required: true, message: 'Beneficiary is required'}]}>
                                <Alert type={'info'} message={
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                                <span>Beneficiary: ${this.state.selectedBeneficiaryDetail.nickname}</span>
                                                <br/>
                                                <span>
                                                  Account: 
                                                  ${this.state.selectedBeneficiaryDetail.swift_code || ''}<br />
                                                  ${this.state.selectedBeneficiaryDetail.account_number || ''}<br />
                                                  ${this.state.selectedBeneficiaryDetail.iban || ''}
                                                </span>`,
                                        }}
                                    />
                                }></Alert>
                            </Form.Item>
                        </>
                    }
                    <Form.Item name="purpose_of_payment_detail"
                               label="Purpose of payment"
                               rules={[{required: true, message: 'Purpose of payment detail is required.'}]}>
                        <Select placeholder={'-- SELECT PURPOSE OF PAYMENT --'}
                                onChange={(val) => this.setState({purpose_of_payment_detail: val})}
                                options={purpose_of_payment_select_options}></Select>
                    </Form.Item>
                    {
                        this.state.purpose_of_payment_detail === 1 &&
                        <Form.Item name="purpose_of_payment_other"
                                   label="Specify purpose of payment"
                                   rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                    }
                    <Form.Item name="invoice_number"
                               label="Invoice number">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="amount_reverse"
                               label={<TypographyCurrency right={true} iso_alpha_3={this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_name}>Amount {this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_name}</TypographyCurrency>}
                               rules={[
                                   {
                                       validator: (_, value) => {
                                           if (value) {
                                               this.formRef.current.setFieldsValue({
                                                   amount: '',
                                               });
                                           }
                                           return Promise.resolve();
                                       },
                                   },
                                   {
                                       validator: (_, value) => {
                                           const amount = this.formRef.current.getFieldValue('amount');
                                           const amount_reverse = this.formRef.current.getFieldValue('amount_reverse');
                                           if (!amount && !amount_reverse) {
                                               return Promise.reject(new Error('At least one field must be filled'));
                                           }
                                           return Promise.resolve();
                                       },
                                   }
                               ]}>
                        <InputNumber min={0}/>
                    </Form.Item>
                    <Form.Item name="amount"
                               label={<TypographyCurrency right={true} iso_alpha_3={this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_name}>Amount {this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_name}</TypographyCurrency>}
                               rules={[
                                   {
                                       validator: (_, value) => {
                                           if (value) {
                                               this.formRef.current.setFieldsValue({
                                                   amount_reverse: '',
                                               });
                                           }
                                           return Promise.resolve();
                                       },
                                   },
                                   {
                                       validator: (_, value) => {
                                           const amount = this.formRef.current.getFieldValue('amount');
                                           const amount_reverse = this.formRef.current.getFieldValue('amount_reverse');
                                           if (!amount && !amount_reverse) {
                                               return Promise.reject(new Error('At least one field must be filled'));
                                           }
                                           return Promise.resolve();
                                       }
                                   }
                               ]}>
                        <InputNumber min={0}/>
                    </Form.Item>
                    {
                        this.state.old_file_url && <>
                            <Form.Item label={'Old Upload'}>
                                <Flex vertical justify={'center'} align={'center'}>
                                    <Image width={102} src={this.state.old_file_url}/>
                                    <AntButton type="link" onClick={() => this.rmOldTransferImage()}
                                               icon={<DeleteOutlined/>}></AntButton>
                                </Flex>
                            </Form.Item>

                        </>
                    }
                    <Form.Item label={'Upload'}>
                        <AntUploader ref={this.uploadRef} clearList={() => []}
                                     onHandleResult={(fileId) => this.handleImageUpload(fileId)}></AntUploader>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    }

    rmOldTransferImage = () => {
        let transfers = this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id || [];
        this.setState({old_file_url: null, file_id: null});
        this.props.updateNewTransferCreation('multiple_beneficiary_ids', transfers);
    }

    getInstructions = () => {
        return (
            <React.Fragment>
                <GridContainer>
                    {this.state.bank_country_full_name && (
                        <GridItem xs={12} sm={12}>
                            <p>
                                When transfering to &nbsp;
                                <img
                                    src={
                                        this.state.bank_country_iso_alpha_2 !== ''
                                            ? require(`../../../assets/img/flags/${this.state.bank_country_iso_alpha_2}.png`)
                                            : ''
                                    }
                                />{' '}
                                &nbsp;
                                <strong>{this.state.bank_country_full_name}</strong>, we require
                            </p>
                            <ul>
                                <li>Bank name</li>
                                {this.state.show_iban && <li>IBAN</li>}
                                {this.state.show_swift_code && <li>SWIFT Code</li>}
                                {this.state.show_aba && <li>ABA Routing Number</li>}
                                {this.state.show_sort_code && <li>Sort Code</li>}
                                {this.state.show_bsb && <li>BSB</li>}
                                {this.state.show_transit_code && <li>Transit &amp; Institution No</li>}
                                {this.state.show_bank_code && <li>Bank Code</li>}
                                {this.state.show_account_number && <li>Account Number</li>}
                            </ul>
                        </GridItem>
                    )}
                </GridContainer>
            </React.Fragment>
        );
    };

    setAddNewBeneficiaryState = (key, value) => {
        this.setState({[key]: value});

        if (key === 'bank_country') {
            const country_dataset = this.state.country_list;
            for (let i = 0; i < country_dataset.length; i++) {
                if (country_dataset[i].id === value) {
                    this.setState({
                        show_swift_code: true,
                        show_account_number: true
                    });

                    //iban check
                    if (country_dataset[i].use_iban === 1) {
                        this.setState({show_iban: true});
                    } else {
                        this.setState({show_iban: false});
                    }

                    //bsb check
                    if (country_dataset[i].use_bsb === 1) {
                        this.setState({show_bsb: true});
                    } else {
                        this.setState({show_bsb: false});
                    }

                    //aba_routing check
                    if (country_dataset[i].use_aba === 1) {
                        this.setState({show_aba: true});
                    } else {
                        this.setState({show_aba: false});
                    }

                    //sort_code check
                    if (country_dataset[i].use_sort_code === 1) {
                        this.setState({show_sort_code: true});
                    } else {
                        this.setState({show_sort_code: false});
                    }
                    //account_number check
                    if (country_dataset[i].use_account_number === 1) {
                        this.setState({show_account_number: true});
                    } else {
                        this.setState({show_account_number: false});
                    }

                    // transit_code
                    if (country_dataset[i].use_transit_code === 1) {
                        this.setState({show_transit_code: true});
                    } else {
                        this.setState({show_transit_code: false});
                    }

                    // institution_number
                    if (country_dataset[i].use_institution_number === 1) {
                        this.setState({show_institution_number: true});
                    } else {
                        this.setState({show_institution_number: false});
                    }

                    // bank_code
                    if (country_dataset[i].use_bank_code === 1) {
                        this.setState({show_bank_code: true});
                    } else {
                        this.setState({show_bank_code: false});
                    }
                }
            }
        }
    }

    createNewBeneficiaryUi = () => {
        const {classes} = this.props;

        const country_list_select_options = this.state.country_list
            .sort((a, b) => a.full_name.localeCompare(b.full_name))
            .map(item => ({
            value: item.id,
            label: item.iso_alpha_2 + ': ' + item.full_name
        }));

        const select_currency_select_options = this.state.currencies_list_priority
            .sort((a, b) => a.full_name.localeCompare(b.full_name))
            .map(item => ({
            value: item.id,
            alt: item.full_name,
            label: (
                <React.Fragment>
                    <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`}/>
                    &nbsp;
                    {item.iso_alpha_3 + ': ' + item.full_name}
                </React.Fragment>
            )
        }));

        return (
            <React.Fragment>
                <React.Fragment>
                    <Modal
                        title={'Add New Beneficiary'}
                        width={900}
                        open={this.state.addBeneficiaryModalOpen}
                        transition={Transition}
                        onOk={() => this.beneficiaryRef.current.submit()}
                        onCancel={() => this.addBeneficiaryModelClose()}>
                        <Form labelCol={{span: 12}} ref={this.beneficiaryRef} layout={'horizontal'}
                              onFinish={this.finished}>
                            <GridContainer justify='flex-start'>
                                <GridItem xs={12} md={6}>
                                    <Form.Item label={'Select ACCOUNT Currency'} name="account_currency"
                                               rules={[{required: true, message: 'Select ACCOUNT Currency'}]}>
                                        <Select showSearch
                                                style={{width: '100%'}}
                                                onChange={(e) => this.setAddNewBeneficiaryState('account_currency', e)}
                                                placeholder="-- SELECT CURRENCY --"
                                                optionFilterProp="alt"
                                                filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())}
                                                filterSort={(optionA, optionB) =>
                                                    (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                                                }
                                                options={select_currency_select_options}></Select>
                                    </Form.Item>
                                    <Form.Item label={'Select BANK Country'} name="bank_country"
                                               rules={[{required: true, message: 'Select BANK Country'}]}>
                                        <Select showSearch={true}
                                                style={{width: '100%'}}
                                                placeholder="-- SELECT COUNTRY --"
                                                onChange={(e) => {
                                                    this.setAddNewBeneficiaryState('bank_country', e)
                                                }}
                                                filterOption={(input, option) => (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())}
                                                filterSort={(optionA, optionB) =>
                                                    (optionA && optionA.label ? optionA.label : '').toLowerCase().localeCompare((optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase())
                                                }
                                                options={country_list_select_options}></Select>
                                    </Form.Item>
                                </GridItem>
                                <hr/>
                                <GridItem xs={12} md={6}>
                                    {this.getInstructions()}
                                </GridItem>
                            </GridContainer>
                            <br/>
                            <GridContainer justify='flex-start'>
                                <GridItem xs={6}>
                                    <p className={classes.benficiaryTitle}>Beneficiary details</p>
                                    <Divider className={classes.beneficiaryDivider} style={{marginBottom: '10px'}}/>
                                    <GridContainer>
                                        <GridItem xs={12} md={12}>
                                            <Form.Item labelCol={{span: 12}} label={'Beneficiary (Account) Name'} name="ben_legal_name"
                                                       rules={[{required: true, message: 'Field is required'}]}>
                                                <Input
                                                    onChange={(e) => this.setAddNewBeneficiaryState('ben_legal_name', e.target.value)}/>
                                            </Form.Item>
                                            <Form.Item label={'Address Line 1'} name="ben_address_line_1"
                                                       rules={[{required: true, message: 'Field is required'}]}>
                                                <Input
                                                    onChange={(e) => this.setAddNewBeneficiaryState('ben_address_line_1', e.target.value)}/>
                                            </Form.Item>
                                            <Form.Item label={'Suburb / Region'} name="ben_address_suburb"
                                                       rules={[{required: false, message: 'Field is required'}]}>
                                                <Input
                                                    onChange={(e) => this.setAddNewBeneficiaryState('ben_address_suburb', e.target.value)}/>
                                            </Form.Item>
                                            <Form.Item label={'State'} name="ben_address_state"
                                                       rules={[{required: false, message: 'Field is required'}]}>
                                                <Input
                                                    onChange={(e) => this.setAddNewBeneficiaryState('ben_address_state', e.target.value)}/>
                                            </Form.Item>
                                            <Form.Item label={'Post Code'} name="ben_address_postcode"
                                                       rules={[{required: false, message: 'Field is required'}]}>
                                                <Input
                                                    onChange={(e) => this.setAddNewBeneficiaryState('ben_address_postcode', e.target.value)}/>
                                            </Form.Item>
                                            <Form.Item labelCol={{span: 10}} label={'Beneficiary Email [optional]'} name="ben_email_main">
                                                <Input
                                                    onChange={(e) => this.setAddNewBeneficiaryState('ben_email_main', e.target.value)}/>
                                            </Form.Item>
                                            <Form.Item labelCol={{span: 12}} label={'Telephone [required for NPR]'} name="ben_telephone_work">
                                                <Input
                                                    onChange={(e) => this.setAddNewBeneficiaryState('ben_telephone_work', e.target.value)}/>
                                            </Form.Item>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={6}>
                                    <p className={classes.benficiaryTitle}>Payment Details</p>
                                    <Divider className={classes.beneficiaryDivider} style={{marginBottom: '10px'}}/>
                                    <GridContainer justify='flex-start'>
                                        <GridItem xs={12}>
                                            <Form.Item labelCol={{span: 6}} label={'Bank Name'} name="bank_legal_name"
                                                       rules={[{required: true, message: 'Field is required'}]}>
                                                <Input
                                                    onChange={(e) => this.setAddNewBeneficiaryState('bank_legal_name', e.target.value)}/>
                                            </Form.Item>
                                            <Form.Item label={'Address Line 1'} name="bank_address_line_1"
                                                       rules={[{required: true, message: 'Field is required'}]}>
                                                <Input
                                                    onChange={(e) => this.setAddNewBeneficiaryState('bank_address_line_1', e.target.value)}/>
                                            </Form.Item>
                                            <Form.Item label={'Suburb / Region'} name="bank_address_suburb"
                                                       rules={[{required: false, message: 'Field is required'}]}>
                                                <Input
                                                    onChange={(e) => this.setAddNewBeneficiaryState('bank_address_suburb', e.target.value)}/>
                                            </Form.Item>
                                            <Form.Item label={'State'} name="bank_address_state"
                                                       rules={[{required: false, message: 'Field is required'}]}>
                                                <Input
                                                    onChange={(e) => this.setAddNewBeneficiaryState('bank_address_state', e.target.value)}/>
                                            </Form.Item>
                                            <Form.Item label={'Post Code'} name="bank_address_postcode"
                                                       rules={[{required: false, message: 'Field is required'}]}>
                                                <Input
                                                    onChange={(e) => this.setAddNewBeneficiaryState('bank_address_postcode', e.target.value)}/>
                                            </Form.Item>
                                            {this.state.show_iban && (
                                                <Form.Item labelCol={{span: 6}} label={'IBAN'} name="iban"
                                                           rules={[{required: false, message: 'Field is required'}]}>
                                                    <Input
                                                        onChange={(e) => this.setAddNewBeneficiaryState('iban', e.target.value)}/>
                                                </Form.Item>
                                            )}
                                            {this.state.show_swift_code && (
                                                <Form.Item labelCol={{span: 6}} label={'Swift Code'} name="swift_code"
                                                           rules={[{required: false, message: 'Field is required'}]}>
                                                    <Input
                                                        onChange={(e) => this.setAddNewBeneficiaryState('swift_code', e.target.value)}/>
                                                </Form.Item>
                                            )}
                                            {this.state.show_aba && (
                                                <Form.Item labelCol={{span: 8}} label={'ABA Routing Number'}
                                                           name="aba_routing_number"
                                                           rules={[{required: false, message: 'Field is required'}]}>
                                                    <Input
                                                        onChange={(e) => this.setAddNewBeneficiaryState('aba_routing_number', e.target.value)}/>
                                                </Form.Item>
                                            )}

                                            {this.state.show_sort_code && (
                                                <Form.Item labelCol={{span: 6}} label={'Sort Code'} name="sort_code"
                                                           rules={[{required: false, message: 'Field is required'}]}>
                                                    <Input
                                                        onChange={(e) => this.setAddNewBeneficiaryState('sort_code', e.target.value)}/>
                                                </Form.Item>
                                            )}
                                            {this.state.show_bsb && (
                                                <Form.Item labelCol={{span: 6}} label={'BSB'} name="bsb_code"
                                                           rules={[{required: true, message: 'Field is required'}]}>
                                                    <Input
                                                        onChange={(e) => this.setAddNewBeneficiaryState('bsb_code', e.target.value)}/>
                                                </Form.Item>
                                            )}
                                            {this.state.show_transit_code && (
                                                <Form.Item labelCol={{span: 6}} label={'Transit Code'}
                                                           name="transit_code"
                                                           rules={[{required: false, message: 'Field is required'}]}>
                                                    <Input
                                                        onChange={(e) => this.setAddNewBeneficiaryState('transit_code', e.target.value)}/>
                                                </Form.Item>
                                            )}
                                            {this.state.show_institution_number && (
                                                <Form.Item labelCol={{span: 6}} label={'Institution No'}
                                                           name="institution_number"
                                                           rules={[{required: false, message: 'Field is required'}]}>
                                                    <Input
                                                        onChange={(e) => this.setAddNewBeneficiaryState('institution_number', e.target.value)}/>
                                                </Form.Item>
                                            )}
                                            {this.state.show_bank_code && (
                                                <Form.Item labelCol={{span: 6}} label={'Bank Code'} name="bank_code"
                                                           rules={[{required: false, message: 'Field is required'}]}>
                                                    <Input
                                                        onChange={(e) => this.setAddNewBeneficiaryState('bank_code', e.target.value)}/>
                                                </Form.Item>
                                            )}

                                            {this.state.show_account_number && (
                                                <Form.Item labelCol={{span: 7}} label={'Account Number'}
                                                           name="account_number"
                                                           rules={[{required: true, message: 'Field is required'}]}>
                                                    <Input
                                                        onChange={(e) => this.setAddNewBeneficiaryState('account_number', e.target.value)}/>
                                                </Form.Item>
                                            )}
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </Form>
                    </Modal>
                </React.Fragment>
            </React.Fragment>
        );
    };

    updateAmountFromState = () => {
        const rate = this.props.transfers_dashboard_multiple.new_transfer_data.client_rate;
        const amount = this.props.transfers_dashboard_multiple.new_transfer_data.amount_to;
        this.props.updateNewTransferCreation('amount_from', Number(amount) / Number(rate));
    }

    render() {
        const {classes} = this.props;
        // For CustomRea6ctSelect. Generate select options for dropdown list.
        const select_currency_select_options = this.state.currencies_list_priority.map(item => ({
            value: item.id,
            label: (
                <React.Fragment>
                    <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`}/>
                    &nbsp;
                    {item.iso_alpha_3}
                </React.Fragment>
            )
        }));

        let transferRateCondition = '';

        try {
            if (!isNaN(this.props.transfers_dashboard_multiple.new_transfer_data.client_rate)) {
                transferRateCondition = 'dashboard.transferRate';
            } else {
                transferRateCondition = 'dashboard.blank';
            }
        } catch (error) {
        }

        return (
            <React.Fragment>
                <p>
                    <FormattedMessage id='dashboard.welcome' defaultMessage={' '}/>
                </p>
                <p>
                    <FormattedMessage id='dashboard.welcomeMessage' defaultMessage={' '}/>
                </p>
                <br/>
                <h4 className={classes.infoText}>
                    <FormattedMessage id='dashboard.heading3' defaultMessage={'Request a new transfer...'}/>
                </h4>
                {this.props.app_state.current_brand === 'gostudy' && (
                    <span>
            Payment provider for <strong>Go Study Australia</strong>
          </span>
                )}
                {this.props.app_state.current_brand === 'redhill' && (
                    <span>
            Payment provider for <strong>RedHill Education</strong>
          </span>
                )}
                <GridContainer>
                    <GridItem xs={6}>
                        <GridContainer
                            justify='flex-start'
                            style={{
                                maxWidth: '100%',
                                flexBasis: '100%'
                            }}
                        >
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <GridContainer>
                                    <GridItem xs={23}>
                                        <GridContainer>

                                            {this.props.app_state.current_team && this.props.app_state.current_team.brand_transfer_payeeFirst ? (
                                                <CommoneCurrentComponent
                                                    color='yellow'
                                                    selectLabel={
                                                        <span>
                              <FormattedMessage id='dashboard.toCurrency' defaultMessage={'To currency '}/>
                            </span>
                                                    }
                                                    selectOptions={select_currency_select_options}
                                                    selectValue={this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_id}
                                                    isClearable={false}
                                                    isDisabled={false}
                                                    inputIsDisabled={true}
                                                    inputValue2={
                                                        isNaN(this.props.transfers_dashboard_multiple.new_transfer_data.amount_to)
                                                            ? 'Must be a number greater than ' + min_transfer_amount
                                                            : this.props.transfers_dashboard_multiple.new_transfer_data.amount_to
                                                    }
                                                    onSelectChange={this.handleCustomReactSelectChange(
                                                        'to_currency',
                                                        0,
                                                        this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_id,
                                                        this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_id
                                                    )}
                                                    inputValue='To amount'
                                                    onInputChange={event => {
                                                        this.change(event, 'to_amount', 'isLargerThanNumber', min_transfer_amount);
                                                    }}
                                                />
                                            ) : (
                                                <CommoneCurrentComponent
                                                    selectLabel='From currency'
                                                    defaultValue={1000}
                                                    color='blue'
                                                    selectOptions={select_currency_select_options}
                                                    selectValue={this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_id}
                                                    isClearable={false}
                                                    inputIsDisabled={true}
                                                    onSelectChange={this.handleCustomReactSelectChange(
                                                        'from_currency',
                                                        0,
                                                        this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_id,
                                                        this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_id
                                                    )}
                                                    inputValue='From amount'
                                                    inputValue2={this.props.transfers_dashboard_multiple.new_transfer_data.amount_from}
                                                    isDisabled={false}
                                                    onInputChange={event => {
                                                        this.change(event, 'from_amount', 'isLargerThanNumber', min_transfer_amount);
                                                    }}
                                                />
                                            )}
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem xs={1}>
                                        <AntButton
                                            disabled
                                            onClick={() => this.handleAmountsBackend(1)}
                                        >
                                            &#xd7;
                                        </AntButton>
                                        <br/>
                                        <AntButton
                                            disabled
                                            onClick={() => this.handleAmountsBackend(2)}
                                        >
                                            &#xF7;
                                        </AntButton>
                                    </GridItem>
                                </GridContainer>
                                <br/>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <p className={classes.transferRate}>
                                            <>
                                                Transfer Rate:
                                                <GridContainer>
                                                    <GridItem xs={4}>
                                                        <Input
                                                            value={this.state.adjusted_rate}
                                                            onChange={this.handleRateChange}
                                                        >
                                                        </Input>
                                                    </GridItem>
                                                    <GridItem xs={8}>
                                                            <AntButton style={{ marginRight: '10px' }}
                                                                       onClick={() => this.updateAmountFromState()}>Calculate</AntButton>
                                                            <AntButton onClick={() => this.fetchNewRate()}>Get
                                                                Rate</AntButton>
                                                    </GridItem>
                                                </GridContainer>
                                            </>
                                        </p>
                                    </GridItem>


                                    {false &&
                                        <GridItem xs={8} sm={8} md={5}>
                                            <div className={classes.selectContainer}>
                                                <GridContainer justify='center' style={{marginBottom: '1px'}}>
                                                    <GridItem xs={3} md={3} className={classes.cardImg}>
                                                        <img src={cardPng} alt=''/>
                                                    </GridItem>
                                                    <GridItem xs={9} md={9} style={{paddingLeft: '0'}}>
                                                        <span
                                                            className={classes.paymentMethod}>Choose a payment method:</span>
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer justify='center'>
                                                    <GridItem xs={12} sm={12}>
                                                        <span
                                                            className={classes.transactionOptions}>Bank Transfer (EFT)</span>
                                                    </GridItem>
                                                </GridContainer>
                                            </div>
                                        </GridItem>
                                    }
                                </GridContainer>

                                <br/>
                                <GridContainer>
                                    <GridItem xs={23}>
                                        <GridContainer>
                                            {this.props.app_state.current_team && this.props.app_state.current_team.brand_transfer_payeeFirst ? (
                                                <CommoneCurrentComponent
                                                    selectLabel='From currency'
                                                    defaultValue={1000}
                                                    color='blue'
                                                    selectOptions={select_currency_select_options}
                                                    selectValue={this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_id}
                                                    isClearable={false}
                                                    inputIsDisabled={true}
                                                    onSelectChange={this.handleCustomReactSelectChange(
                                                        'from_currency',
                                                        0,
                                                        this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_id,
                                                        this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_id
                                                    )}
                                                    inputValue='From amount'
                                                    inputValue2={this.props.transfers_dashboard_multiple.new_transfer_data.amount_from}
                                                    isDisabled={false}
                                                    onInputChange={event => {
                                                        this.change(event, 'from_amount', 'isLargerThanNumber', min_transfer_amount);
                                                    }}
                                                />
                                            ) : (
                                                <CommoneCurrentComponent
                                                    color='yellow'
                                                    selectLabel={
                                                        <span>
                              <FormattedMessage id='dashboard.toCurrency' defaultMessage={'To currency '}/>
                            </span>
                                                    }
                                                    selectOptions={select_currency_select_options}
                                                    selectValue={this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_id}
                                                    isClearable={false}
                                                    isDisabled={false}
                                                    inputIsDisabled={true}
                                                    inputValue2={
                                                        isNaN(this.props.transfers_dashboard_multiple.new_transfer_data.amount_to)
                                                            ? 'Must be a number greater than ' + min_transfer_amount
                                                            : this.props.transfers_dashboard_multiple.new_transfer_data.amount_to
                                                    }
                                                    onSelectChange={this.handleCustomReactSelectChange(
                                                        'to_currency',
                                                        0,
                                                        this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_id,
                                                        this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_id
                                                    )}
                                                    inputValue='To amount'
                                                    onInputChange={event => {
                                                        this.change(event, 'to_amount', 'isLargerThanNumber', min_transfer_amount);
                                                    }}
                                                />
                                            )}
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem xs={1}>
                                        <AntButton onClick={() => this.handleAmountsBackend(3)}>
                                            &#xd7;
                                        </AntButton>
                                        <br/>
                                        <AntButton onClick={() => this.handleAmountsBackend(4)}>
                                            &#xF7;
                                        </AntButton>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={6}>
                        <GridContainer justify='flex-start'>
                            {this.createNewBeneficiaryUi()}
                            {this.render_beneficiary_details_section()}
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        language: state.language,
        transfers_dashboard_multiple: state.transfers_dashboard_multiple
    };
};

const mapDispatchToProps = dispatch => {
    return {
        update_map_data: data => {
            dispatch(update_map_data(data));
        },
        updateNewTransferCreation: (key, value) => {
            dispatch(updateNewTransferCreation(key, value));
        },
        updateNewTransferCreationBeneficiary: array => {
            dispatch(updateNewTransferCreationBeneficiary(array));
        },
        createNewBeneficiary: data => {
            dispatch(createNewBeneficiary(data));
        }
    };
};

const MultiPayTransferStep2Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(style)(MultiPayTransferStep2));

export default withRouter(MultiPayTransferStep2Container);
