import {
  TRANSFERS_SPLIT_PAY_UPDATE_NEW_TRANSFER_CREATION,
  TRANSFERS_SPLIT_PAY_CHANGE_CURRENT_STEP,
  TRANSFERS_SPLIT_PAY_UPDATE_MAPDATA,
  TRANSFERS_SPLIT_PAY_CLEAR_NEW_TRANSFER
} from '../actions/transferSplitPay';

const initialState = {
  current_step: 1,
  map_data: {},
  transfer_current_ui: 'list',
  is_fetching: false,
  did_invalidate: false,
  transfer_list: [],
  transfer_detail_fetching: false,
  transfer_detail_loaded: false,
  transfer_detail_id: null,
  new_transfer_data: {
    amount_from: '',
    amount_to: '',
    multiple_beneficiary_id: []
  },
  new_transfer_type: null,
  new_transfer_submitted: false,
  new_transfer_created: false
};

function transferSplitPay(state = initialState, action) {
  switch (action.type) {
    case TRANSFERS_SPLIT_PAY_CHANGE_CURRENT_STEP:
      return {
        ...state,
        current_step: action.value
      };

    case TRANSFERS_SPLIT_PAY_UPDATE_MAPDATA:
      return {
        ...state,
        map_data: action.value
      };

    case TRANSFERS_SPLIT_PAY_CLEAR_NEW_TRANSFER:
      return {
        ...state,
        map_data: {},
        new_transfer_data: {
          amount_from: '',
          amount_to: '',
          multiple_beneficiary_id: []
        }
      };

    case TRANSFERS_SPLIT_PAY_UPDATE_NEW_TRANSFER_CREATION:
      return {
        ...state,
        new_transfer_data: {
          ...state.new_transfer_data,
          [action.value.key]: action.value.value
        }
      };

    default:
      return state;
  }
}

export default transferSplitPay;
