import React, {useEffect, useState} from 'react';

import {
    Affix,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Flex,
    Form,
    Input,
    message,
    Row,
    Select,
    Table,
    Tag
} from 'antd'

import {constants} from './duck';
import {RISK_SCORE_OPTIONS} from './duck/constants';
import {
    COMPANY_OUTCOMES,
    COMPANY_SCREEN_OUTCOMES,
    CUSTOMER_SCREEN_OUTCOMES, RISK_COMPANY_DETAIL,
    RISK_CUSTOMER,
    RISK_CUSTOMER_DETAIL, RiskConstants
} from "./Risk/RiskConstants";
import {API} from "aws-amplify";
import ClientRiskIndividual from "./Risk/ClientRiskIndividual";
import ClientRiskCompany from "./Risk/ClientRiskCompany";
import {CheckBox} from "@material-ui/icons";
import dayjs from "dayjs";
import {connect} from "react-redux";
import {
    createCognitoAccountForClient,
    loadClientDetailsRequest,
    loadClientDetailsSuccess, updateClient
} from "../../../redux/actions/clients";
import {fetchCountryListNotDeleted} from "../../../redux/actions/countries";

import cra from '../../../assets/img/forex_sport_cra.png';

const Risk = ({ state, clientID, emitTotalRiskScore, countries = [] }) => {
    const [formRef] = Form.useForm();
    const [riskRating, setRiskRating] = useState({});
    const [complianceRating, setComplianceRating] = useState({});
    // const riskScoreOptions = RISK_SCORE_OPTIONS;
    const [componentState, setComponentState] = useState({eddRequired: false, hocApproval: false, clientContacts: []});
    const [ownerRiskSum, setOwnerRiskSum] = useState(0);
    const [riskCountry, setRiskCountry] = useState(null);



    const getRiskColor = (newCalcScore) => RiskConstants.GetRiskColor(newCalcScore);
    const getRiskRating = (newCalcScore) => RiskConstants.GetRiskRating(newCalcScore);
    const getComplianceRiskRating = (newCalcScore) => RiskConstants.GetComplianceRiskRating(newCalcScore);
    const getComplianceRiskColor = (newCalcScore) => RiskConstants.GetComplianceRiskColor(newCalcScore);

    const columns = [
        {
            title: 'Overall Risk',
            dataIndex: 'indicator',
            key: 'indicator',
            render: (_, {indicator}) => {
                if (indicator === 'Low') {
                    return (
                        <Tag color='green' key={indicator}>
                            {indicator.toUpperCase()}
                        </Tag>
                    );
                }

                if (indicator === 'Medium') {
                    return (
                        <Tag color={'blue'} key={indicator}>
                            {indicator.toUpperCase()}
                        </Tag>
                    );
                }

                if (indicator === 'High') {
                    return (
                        <Tag color={'warning'} key={indicator}>
                            {indicator.toUpperCase()}
                        </Tag>
                    );
                }

                if (indicator === 'Decline') {
                    return (
                        <Tag color={'volcano'} key={indicator}>
                            {indicator.toUpperCase()}
                        </Tag>
                    );
                }
            }
        },
        {
            title: 'Risk Score',
            dataIndex: 'range',
            key: 'range'
        },
        {
            title: 'Decision',
            dataIndex: 'description',
            key: 'description'
        }
    ];
    const data = [
        {
            key: '1',
            indicator: 'Low',
            range: 'Up to 13',
            description: 'Yes'
        },
        {
            key: '2',
            indicator: 'Medium',
            range: '14-20',
            description: 'Yes (with monitoring)'
        },
        {
            key: '3',
            indicator: 'High',
            range: '21 to 25',
            description: 'Yes (with close monitoring)'
        },
        {
            key: '4',
            indicator: 'Decline',
            range: '>= 26',
            description: 'No'
        }
    ];

    const onFormSubmit = (payload) => {
        payload = {...payload, client_id: clientID, country_id: riskCountry};
        const score = Object.keys(riskRating).map(i => riskRating[i]).reduce((a,b) => a + b, 0) + ownerRiskSum;
        payload.risk_total_score = score;
        API.post('client-risks', `/individual-risk/save`, { body: payload }).then(res => {
            setComponentState({
                eddRequired: payload.risk_edd_req,
                hocApproval: payload.risk_hoc_approval
            });
            message.success("Risk Score Saved");
            emitTotalRiskScore(score);
        })
    }

    const fetchClientRisk = () => {
        API.post('client-risks', `/individual-risk/get`, { body: {client_id: clientID} }).then(res => {
            if (res != null) {
                formRef.setFieldsValue(res);
                const {client_id, id, ...rest} = res;
                let restObj = Object.entries(rest)
                    .reduce((obj, [key, value]) => {
                        if (!key.startsWith(state.account_type === 1 ? 'c_' : 'i_')) {
                            obj[key] = value;
                        }
                        return obj;
                    }, {});
                Object.keys(restObj).forEach(key => {
                    const selectOpts = state.account_type === 1
                        ? [...RISK_CUSTOMER_DETAIL, ...CUSTOMER_SCREEN_OUTCOMES]
                        : [...RISK_COMPANY_DETAIL, ...COMPANY_SCREEN_OUTCOMES];
                    const data = selectOpts.find(item => item.key === key);
                    if (data != null) {
                        if (data.key === 'i_jurisdiction' || data.key === 'c_jurisdiction') {
                            const countryOps = countries.find(i => i.id === rest.country_id);
                           if (countryOps) {
                               console.log(countryOps)
                               setRiskRating(prev => ({...prev, [data.key]: countryOps.riskScore}));
                               setComplianceRating(prev => ({...prev, [data.key]: countryOps.riskRating}));
                               setRiskCountry(rest.country_id);
                           }
                        }
                        const selectedOption = data.options.find(i => restObj[key] === i.value);
                        if (selectedOption != null) {
                            console.log(selectedOption);
                            setRiskRating(prev => ({...prev, [key]: selectedOption.score}));
                            setComplianceRating(prev => ({...prev, [key]: selectedOption.riskLevel}));
                        }
                    }
                });
            }
        })
    }

    const calculateSum = (response = []) => {
        const riskMap = COMPANY_OUTCOMES;
        let sum = 0;
        response.forEach(item => {
            Object.keys(riskMap).forEach(key => {
                const mapItem = riskMap[key];
                const { id, value } = mapItem;
                const outcome = COMPANY_SCREEN_OUTCOMES.find(outcome => outcome.key === id);
                if (outcome && item[value] !== null) {
                    const option = outcome.options.find(opt => opt.value === item[value]);
                    if (option) {
                        sum += option.score;
                    }
                }
            });
        });
        return sum;
    }
    const fetchClientContacts = () => {
            if (state.account_type !== 1) {
                API.get('clientcontacts', `/list-by-clientid/${clientID}`, {}).then(res => {
                    const newRes = res.filter(i => (i.is_ben_owner == null || i.is_ben_owner));
                    const sum = calculateSum(newRes)
                    setComponentState(prev => ({ ...prev, clientContacts: newRes }));
                    setOwnerRiskSum(sum)
                });
            }
    }

    useEffect(() => {
        setRiskRating({});
        fetchClientRisk();
        fetchClientContacts();
        formRef.setFieldValue('risk_edd_req', state.risk_edd_req);
        formRef.setFieldValue('risk_hoc_approval', state.risk_hoc_approval);
        formRef.setFieldValue('risk_review_date', state.risk_review_date);
        setComponentState({eddRequired: state.risk_edd_req, hocApproval: state.risk_hoc_approval});
    }, [state.account_type]);

    const getOptions = (key) => {
        const selectOpts = state.account_type === 1
            ? [...RISK_CUSTOMER_DETAIL, ...CUSTOMER_SCREEN_OUTCOMES]
            : [...RISK_COMPANY_DETAIL, ...COMPANY_SCREEN_OUTCOMES]
        const options = selectOpts.find(item => item.key === key);
        return options != null ? options.options.map(item => ({label: `${item.label} (${item.score})`, value: item.value, score: item.score, riskLevel: item.riskLevel })) : [];
    }

    return (
        <React.Fragment>
            <div style={{padding: 20 }}>
                <Row style={{marginBottom: '10px'}}>
                    <Col xs={4}>
                        <p>Total Risk Score:</p>
                        <Form.Item shouldUpdate>
                            <Tag color={riskRating && Object.keys(riskRating).length ? getRiskColor(Object.keys(riskRating).map(i => riskRating[i]).reduce((a,b) => a + b, 0) + ownerRiskSum) : 'default'}>
                                {riskRating && Object.keys(riskRating).length ? getRiskRating(Object.keys(riskRating).map(i => riskRating[i]).reduce((a,b) => a + b, 0) + ownerRiskSum) : 'Not Calculated'}
                                ({ riskRating && Object.keys(riskRating).length && Object.keys(riskRating).map(i => riskRating[i]).reduce((a,b) => a + b, 0) + ownerRiskSum})
                            </Tag>
                        </Form.Item>
                        <br/>
                        <p>EDD Required:</p>
                        <Form.Item shouldUpdate>
                            <Tag color={componentState.eddRequired ? 'warning' : 'green'}>
                                {componentState.eddRequired ? 'EDD Required' : 'No EDD Required'}
                            </Tag>
                        </Form.Item>
                    </Col>
                    <Col xs={20}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            pagination={{
                                disabled: true,
                                hideOnSinglePage: true,
                                pageSizeOptions: ['5'],
                                defaultPageSize: 5,
                                showSizeChanger: false
                            }}
                        />
                        <Divider/>
                        <img
                            src={cra}
                            style={{width: '100%'}}
                            // className={avatarImage + ' ' + classes.avatarImg}
                            alt="..."
                        />
                    </Col>
                </Row>
                <Form labelCol={{span: 8}} form={formRef} name={'riskForm'} scrollToFirstError onFinish={onFormSubmit}>
                    <Divider/>
                    <Flex justify={'space-between'} style={{marginBottom: '10px'}}>
                        <div>
                            <Form.Item labelCol={{ span: 25 }} name={'risk_hoc_approval'} label={'Head of Compliance Approval'} valuePropName={'checked'}>
                                <Checkbox />
                            </Form.Item>
                            <Form.Item labelCol={{ span: 25 }} name={'risk_edd_req'} label={'EDD Required ?'} valuePropName={'checked'}>
                                <Checkbox />
                            </Form.Item>
                            <Form.Item labelCol={{ span: 25 }}
                                       normalize={(dayjsValue) =>
                                           dayjsValue && dayjsValue.startOf("day").format('YYYY-MM-DD')
                                       }
                                       getValueProps={(value) =>
                                           value && { value: dayjs(value, 'YYYY-MM-DD').startOf("day") }
                                       }
                                       name={'risk_review_date'} label={'Risk Review Date'}>
                                <DatePicker format={'DD/MM/YYYY'} />
                            </Form.Item>
                        </div>
                        <Button htmlType={'submit'} type={'primary'}>Update Risk Score</Button>

                    </Flex>

                    <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
                        <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                            Customer Options
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                            Risk Rating
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                            Risk Value ({Object.keys(riskRating).map(i => riskRating[i]).reduce((a,b) => a + b, 0) + ownerRiskSum})
                        </Col>
                    </Row>
                    <Divider />
                    {
                        state.account_type === 1
                            ? <ClientRiskIndividual getRiskRating={getComplianceRiskRating}
                                                    setRiskRating={setRiskRating}
                                                    setComplianceRating={setComplianceRating}
                                                    riskRating={complianceRating}
                                                    getRiskColor={getComplianceRiskColor}
                                                    overallRating={riskRating}
                                                    setRiskCountry={setRiskCountry}
                                                    getOptions={getOptions}>

                            </ClientRiskIndividual> : <ClientRiskCompany clientContacts={componentState.clientContacts}
                                                                         getRiskRating={getComplianceRiskRating}
                                                                         setRiskRating={setRiskRating}
                                                                         setComplianceRating={setComplianceRating}
                                                                         riskRating={complianceRating}
                                                                         getRiskColor={getComplianceRiskColor}
                                                                         overallRating={riskRating}
                                                                         setRiskCountry={setRiskCountry}
                                                                         getOptions={getOptions}>
                            </ClientRiskCompany>
                    }


                </Form>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {countries: state.countries.countries_list};
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Risk)