import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// import ReportsClient from "./ReportsClient";
import {} from '../../../redux/actions/clients';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import clientsListStyles from "./ClientsListStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import ReportsVerification from '../../../components/Reports/ReportsVerification';

import {FormattedMessage} from 'react-intl';
import ReportsClientRiskContainer from '../../../components/Reports/ReportsClientRisk';

const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class ReportsVerificationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const {classes} = this.props;
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>Client Risk</h4>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <ReportsClientRiskContainer />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

// ReportsClient.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withStyles(styles)(ReportsClient);

const mapStateToProps = state => {
  return {
    app_state: state.app_state
    // transfers: state.transfers
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const ReportsVerificationPageContainer = connect(mapStateToProps, mapDispatchToProps)(ReportsVerificationPage);

export default withStyles(styles)(ReportsVerificationPageContainer);
