import React from 'react';
import {connect} from 'react-redux';
import {selectEntityUi} from '../../../redux/actions/teams';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import entitiesStyles from "./EntitiesStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';
import Button from '../../../components/CustomButtons/Button';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import TeamListComponentContainer from '../../../components/Teams/TeamListContainer';
import {withRouter} from 'react-router-dom';

const entitiesStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};
/**
 * ----------------------------------------------------------------------------
 * CLASS - Divisions
 * ----------------------------------------------------------------------------
 */
class TeamList extends React.Component {
  render() {
    const {classes} = this.props;
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>Teams</h4>
              <div>
                <Button
                  round
                  color={this.props.teams.teams_current_ui === 'list' ? 'rose' : null}
                  // onClick={() => this.props.selectEntityUi("list")}
                >
                  Show All
                </Button>
                <Button
                  round
                  color={this.props.teams.teams_current_ui === 'create' ? 'rose' : null}
                  onClick={() => this.props.history.push('/teams/new')}
                >
                  Create New
                </Button>
                {this.props.teams.teams_current_ui === 'edit' && (
                  <Button
                    round
                    color={this.props.teams.teams_current_ui === 'edit' ? 'rose' : null}
                    onClick={() => this.props.selectEntityUi('edit')}
                  >
                    Edit Record
                  </Button>
                )}
                {this.props.teams.teams_current_ui !== 'edit' && this.props.teams.teams_current_ui !== 'create' && (
                  <Button round onClick={() => this.props.selectEntityUi('list')}>
                    Refresh
                  </Button>
                )}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <TeamListComponentContainer />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    teams: state.teams
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectEntityUi: value => {
      dispatch(selectEntityUi(value));
    }
  };
};

const TeamListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(entitiesStyles)(TeamList)));

export default TeamListContainer;
