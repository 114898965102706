import {
  STAFF_SELECT_STAFF_MEMBER,
  STAFF_FETCH_STAFF_LIST_REQUEST,
  STAFF_FETCH_STAFF_LIST_SUCCESS,
  // FETCH_STAFF_LIST_FAILURE
  // STAFF_CREATE_NEW_SHOW_UI,
  STAFF_CREATE_NEW_START,
  STAFF_CREATE_NEW_CANCEL,
  STAFF_CREATE_NEW_COMPLETE,
  // STAFF_CREATE_PORTAL_START,
  // STAFF_CREATE_PORTAL_SUCCESS,
  // STAFF_EDIT_SHOW_UI,
  STAFF_SELECT_UI,
  STAFF_SELECT_SET_ID,
  STAFF_SELECT_STAFF_MEMBER_REQUEST,
  STAFF_SELECT_STAFF_MEMBER_SUCCESS
} from '../actions/staff.js';

const initialState = {
  is_fetching: false,
  did_invalidate: false,
  staff_list: [],
  staff_detail_id: null,
  staff_detail_fetching: false,
  staff_detail_loaded: false,
  staff_current_ui: 'list',
  // ui_show_create_new_staff_screen: false,
  // ui_show_edit_staff_screen: true,
  staff_create_new_in_process: false,
  staff_create_new_awaiting_confirmation: false
};

function staff(state = initialState, action) {
  switch (action.type) {
    case STAFF_SELECT_STAFF_MEMBER:
      // console.log(action);
      return Object.assign({}, state, {
        staff_detail_id: action.staff_id
      });

    case STAFF_FETCH_STAFF_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
        // console.log(action);
      });

    case STAFF_FETCH_STAFF_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        staff_list: action.staff_list,
        lastUpdated: action.receivedAt
      });

    // case STAFF_CREATE_NEW_SHOW_UI:
    //   return Object.assign({}, state, {
    //     // ui_show_create_new_staff_screen: action.value,
    //     // ui_show_edit_staff_screen: false,
    //     staff_current_ui: "list"
    //   });

    case STAFF_CREATE_NEW_START:
      return Object.assign({}, state, {
        staff_create_new_in_process: true,
        staff_create_new_awaiting_confirmation: true
      });

    case STAFF_CREATE_NEW_CANCEL:
      return Object.assign({}, state, {
        staff_create_new_in_process: false,
        staff_create_new_awaiting_confirmation: false,
        staff_current_ui: 'list'
      });

    case STAFF_CREATE_NEW_COMPLETE:
      return Object.assign({}, state, {
        staff_current_ui: 'edit',
        staff_detail_id: action.value.insertId

        // staff_create_new_in_process: false,
        // staff_create_new_awaiting_confirmation: false,
        // ui_show_create_new_staff_screen: false,
        // staff_current_ui: "list"
      });

    case STAFF_SELECT_UI:
      return Object.assign({}, state, {
        staff_current_ui: action.value
      });

    case STAFF_SELECT_SET_ID:
      return Object.assign({}, state, {
        staff_detail_id: action.value
      });

    case STAFF_SELECT_STAFF_MEMBER_REQUEST:
      return Object.assign({}, state, {
        // staff_detail: null,
        // staff_detail_id: action.staff_id,
        staff_detail_fetching: true
        // staff_detail_loaded: false
      });

    case STAFF_SELECT_STAFF_MEMBER_SUCCESS:
      return Object.assign({}, state, {
        // staff_detail: action.staff_detail,
        staff_detail_fetching: false
        // staff_detail_loaded: true
      });

    default:
      return state;
  }
}

export default staff;
