import {
  REMITTANCE_FETCH_REMITTANCE_LIST_REQUEST,
  REMITTANCE_FETCH_REMITTANCE_LIST_SUCCESS,
  REMITTANCE_CREATE_NEW_SHOW_UI,
  REMITTANCE_CREATE_NEW_START,
  REMITTANCE_CREATE_NEW_CANCEL,
  REMITTANCE_CREATE_NEW_COMPLETE,
  REMITTANCE_SELECT_UI,
  REMITTANCE_REMITTANCE_LIST_IS_DIRTY,
  REMITTANCE_REMITTANCE_EDIT
} from '../actions/remittance_accounts.js';

const initialState = {
  is_fetching: false,
  did_invalidate: false,
  remittance_list_is_dirty: false,
  remittance: [],
  remittance_current_ui: 'list',
  remittance_create_new_in_process: false,
  remittance_id: ''
};

function Remittance(state = initialState, action) {
  switch (action.type) {
    case REMITTANCE_FETCH_REMITTANCE_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
      });

    case REMITTANCE_FETCH_REMITTANCE_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        remittance: action.remittance,
        lastUpdated: action.receivedAt
      });

    case REMITTANCE_CREATE_NEW_SHOW_UI:
      return Object.assign({}, state, {
        remittance_current_ui: 'list'
      });

    case REMITTANCE_CREATE_NEW_START:
      return Object.assign({}, state, {
        remittance_create_new_in_process: true
      });

    case REMITTANCE_CREATE_NEW_CANCEL:
      return Object.assign({}, state, {
        remittance_create_new_in_process: false,
        remittance_current_ui: 'list'
      });

    case REMITTANCE_CREATE_NEW_COMPLETE:
      return Object.assign({}, state, {
        remittance_create_new_in_process: false,
        remittance_current_ui: 'list',
        remittance_list_is_dirty: true
      });

    case REMITTANCE_SELECT_UI:
      return Object.assign({}, state, {
        remittance_current_ui: action.value
      });
    case REMITTANCE_REMITTANCE_LIST_IS_DIRTY:
      return Object.assign({}, state, {
        remittance_list_is_dirty: action.value
      });
    case REMITTANCE_REMITTANCE_EDIT:
      return Object.assign({}, state, {
        remittance_create_new_in_process: false,
        remittance_current_ui: 'edit',
        remittance_id: action.value
      });
    default:
      return state;
  }
}

export default Remittance;
