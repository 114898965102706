import React from 'react';
import {API} from 'aws-amplify';
import {connect} from 'react-redux';

import {
  // updateNewTransferCreationTransferType,
  updateNewTransferCreation
} from '../../../redux/actions/transfers_pending';

import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';

import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';

// const uuidv4 = require('uuid/v4');

const style = {
  ...customCheckboxRadioSwitch,

  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  }
};

class Step1Client extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client_list: [],
      client_id: '',
      client: {},
      team_nickname: '',
      division_nickname: '',
      entity_nickname: '',
      with_query_string: false
    };
  }

  componentDidMount() {
    API.get('clients', `/get-list-not-deleted`)
      .then(response => {
        // console.log(response);
        this.setState(
          {
            client_list: response
          },
          () => {
            this.queryStrings();
          }
        );
      })
      .catch(error => {
        console.log(error);
      });
  }

  queryStrings() {
    const query_strings = queryString.parse(this.props.location.search);
    if (Object.keys(query_strings).length !== 0) {
      if (query_strings.client_id) {
        this.setState({client_id: Number(query_strings.client_id)});
        this.props.updateNewTransferCreation('client_id', Number(query_strings.client_id));
        this.getClientDetails(Number(query_strings.client_id));
        this.setState({with_query_string: true});
      }
    }
  }

  getClientDetails(client_id) {
    API.get('clients', `/get/id/${client_id}`)
      .then(response => {
        this.setState({
          client: response
        });

        this.props.updateNewTransferCreation('client', response);
        return [response.team_id, response.account_status];
      })
      .then(([team_id, account_status]) => {
        API.get('transfers', `/get_purpose_by_team/id/${team_id}`).then(response => {
          console.log(response);
          // this.setState({
          //   client: Object.assign({}, this.state.client, {
          //     division_nickname: response.division_nickname,
          //     entity_nickname: response.entity_nickname,
          //     team_nickname: response.team_nickname
          //   })
          // });
          this.props.updateNewTransferCreation('purpose_of_payment_list', response);
        });

        API.get('teams', `/get/div-entity/${team_id}`)
          .then(response => {
            this.setState({
              client: Object.assign({}, this.state.client, {
                division_nickname: response.division_nickname,
                entity_nickname: response.entity_nickname,
                team_nickname: response.team_nickname
              })
            });
            this.props.updateNewTransferCreation('division_id', response.division_id);

            // API.post("email", `/interaction/main`, {
            //   body: {
            //     interaction: "New Transfer Request Starting",
            //     data: {
            //       staff_id: this.props.app_state.current_staff.id,
            //       staff_nickname: this.props.app_state.current_staff.nickname,
            //       client_id: this.state.client.id,
            //       client_nickname: this.state.client.mickname
            //     },
            //     result: "New transfer request starting"
            //   }
            // });
            return account_status;
          })
          // Get account status nickname from account status id
          .then(account_status => {
            API.get('clients', `/client-status/get`)
              .then(response => {
                let obj;
                for (obj of response) {
                  if (obj.id === account_status) {
                    this.setState({
                      client: Object.assign({}, this.state.client, {
                        account_status_nickname: obj.nickname
                      })
                    });
                  }
                }
              })
              .catch(error => {
                console.log(error);
              });
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  }
  // getPayeeDetails(payee_id) {
  //   API.get("clients", `/get/id/${payee_id}`)
  //     .then(response => {
  //
  //       this.setState({
  //         payee: response
  //       });
  //       this.props.updateNewTransferCreation("payee", response)
  //       return response.team_id;
  //     })
  //     .then((team_id) => {
  //
  //       API.get("teams", `/get/div-entity/${team_id}`)
  //         .then(response => {
  //
  //           this.setState({
  //             payee: Object.assign({}, this.state.payee, {
  //               division_nickname: response.division_nickname,
  //               entity_nickname: response.entity_nickname,
  //               team_nickname: response.team_nickname
  //             })
  //           });
  //         })
  //         .catch(error => {
  //           console.log(error);
  //         });
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }

  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});

    this.props.updateNewTransferCreation(stateName, event.target.value);
  }

  handleSelectChange = event => {
    this.setState({[event.target.name]: event.target.value});
    this.props.updateNewTransferCreation(event.target.name, event.target.value);
    if (event.target.name === 'client_id') {
      this.getClientDetails(event.target.value);
      /*
            console.log(this.props.app_state);
            API.post("email", `/interaction/main`, {
              body: {
                interaction: "New Transfer Request Starting",
                data: {
                  "staff_id": this.props.app_state.current_staff.id,
                  "staff_nickname": this.props.app_state.current_staff.nickname,
                  "client_id": event.target.value,
                  "client_nickname": ""
                },
                result: "New transfer request starting"
              }})
      */
      // if (!this.props.transfers.new_transfer_data.payee_id) {
      //   this.props.updateNewTransferCreation("payee_id", event.target.value)
      // }
    }
  };

  isValidated() {
    if (this.state.client_id > 0) {
      return true;
    }
    return false;
  }

  handleClientSelectChange = event => {
    // console.log(event);
    this.setState({client_id: event.value});
    this.props.updateNewTransferCreation('client_id', event.value);
    this.getClientDetails(event.value);
  };

  render() {
    const {classes} = this.props;

    const client_list_select_options = this.state.client_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return {
          value: item.id,
          label: `${item.nickname} [${item.id.toString().padStart(5, '0')}] (${item.email})`
          // label:
          //   item.nickname +
          //   " [" +
          //   item.id.toString().padStart(5, "0") +
          //   " ]" +
          //   " (" +
          //   item.email +
          //   " )"
        };
      });

    //console.log(this.props.app_state.current_staff_super_admin);

    //setup for language components

    /*
    let languageObj_en_json = require('../../../../translations/en.json');
    let languageObj_zh_json = require('../../../../translations/zh.json');
    let languageObj_de_json = require('../../../../translations/de.json');
    let languageObj_es_json = require('../../../../translations/es.json');
    let languageObj_fr_json = require('../../../../translations/fr.json');
    let languageObj_it_json = require('../../../../translations/it.json');
    let languageObj_pt_json = require('../../../../translations/pt.json');

    let languageObj = "";
      switch (this.props.language.language_current_ui) {
        case "es":
          languageObj = languageObj_es_json;
            //menu_item_text = prop.name_es || prop.name;
          break;
        case "de":
            languageObj = languageObj_de_json;
            //menu_item_text = prop.name_de || prop.name;
          break;
        case "zh":
            languageObj = languageObj_zh_json;
            //menu_item_text = prop.name_zh || prop.name;
          break;
        case "fr":
            languageObj = languageObj_fr_json;
            //menu_item_text = prop.name_fr || prop.name;
            break;
        case "it":
            languageObj = languageObj_it_json;
            //menu_item_text = prop.name_it || prop.name;
            break;
        case "pt":
            languageObj = languageObj_pt_json;
            //menu_item_text = prop.name_pt || prop.name;
            break;
        default:
            languageObj = languageObj_en_json;
            //menu_item_text = prop.name;
          break;
      }
*/
    return (
      <React.Fragment>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              {
                this.state.with_query_string ? 'Please confirm client details for new transfer' : ''
                // languageObj["wizard.transfers.client.sentence1"]
              }
            </h4>
          </GridItem>
        </GridContainer>

        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <CustomReactSelect
              label='Select client'
              options={client_list_select_options}
              value={this.state.client_id}
              onChange={this.handleClientSelectChange}
            />

            {/*<FormControl*/}
            {/*  fullWidth*/}
            {/*  error={!this.state.client_id > 0}*/}
            {/*  className={classes.selectFormControl}*/}
            {/*>*/}
            {/*  <InputLabel*/}
            {/*    htmlFor="simple-select"*/}
            {/*    className={classes.selectLabel}*/}
            {/*  >*/}
            {/*    Select client*/}
            {/*  </InputLabel>*/}
            {/*  <Select*/}
            {/*    MenuProps={{*/}
            {/*      className: classes.selectMenu*/}
            {/*    }}*/}
            {/*    classes={{*/}
            {/*      select: classes.select*/}
            {/*    }}*/}
            {/*    value={this.state.client_id}*/}
            {/*    onChange={this.handleSelectChange}*/}
            {/*    inputProps={{*/}
            {/*      name: "client_id",*/}
            {/*      id: "client_id"*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <MenuItem*/}
            {/*      key="0x0"*/}
            {/*      disabled*/}
            {/*      classes={{*/}
            {/*        root: classes.selectMenuItem*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Select client*/}
            {/*    </MenuItem>*/}
            {/*    {this.state.client_list*/}
            {/*      .sort((a, b) => {*/}
            {/*        if (a.nickname) {*/}
            {/*          return a.nickname.localeCompare(b.nickname)*/}
            {/*        } else {*/}
            {/*          return a.last_name.localeCompare(b.last_name)*/}
            {/*        }*/}
            {/*        }*/}
            {/*      )*/}
            {/*      .map((item) => {*/}
            {/*        return (*/}
            {/*          <MenuItem*/}
            {/*            key={item.id}*/}
            {/*            classes={{*/}
            {/*              root: classes.selectMenuItem,*/}
            {/*              selected: classes.selectMenuItemSelected*/}
            {/*            }}*/}
            {/*            value={item.id}*/}
            {/*          >*/}
            {/*            {item.nickname}*/}
            {/*        </MenuItem>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*  </Select>*/}
            {/*</FormControl>*/}
          </GridItem>
        </GridContainer>

        {!!this.state.client_id && (
          <React.Fragment>
            <p>
              <br />
              Please confirm the following details are correct
            </p>

            {/* if the client status is not 2 (approved) then display warning */}
            {this.state.client.account_status !== 2 && (
              <p>
                <em style={{color: 'red'}}>
                  {this.state.client.account_status_nickname && (
                    <span>
                      Warning: this client has status {this.state.client.account_status_nickname.toUpperCase()}{' '}
                    </span>
                  )}
                </em>
              </p>
            )}

            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <GridContainer justify='center'>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <CustomInput
                      labelText='First Name'
                      id='first_name'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.client.first_name || ''
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <CustomInput
                      labelText='Middle Name(s)'
                      id='niddle_name'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.client.niddle_name || ''
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <CustomInput
                  labelText='Last Name'
                  id='last_name'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: this.state.client.last_name || ''
                  }}
                />
                <CustomInput
                  labelText='Email'
                  id='email'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: this.state.client.email || ''
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={6}>
                <CustomInput
                  labelText='Team'
                  id='team_nickname'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: this.state.client.team_nickname || ''
                  }}
                />
                {!!this.props.app_state.current_staff_super_admin && (
                  <span>
                    <CustomInput
                      labelText='Division'
                      id='division_nickname'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.client.division_nickname || ''
                      }}
                    />
                    {/* <CustomInput
                 labelText="Entity"
                 id="entity_nickname"
                 formControlProps={{
                    fullWidth: true
                 }}
                 inputProps={{
                    disabled: true,
                    value: this.state.client.entity_nickname || ""
                 }}
              /> */}
                  </span>
                )}
              </GridItem>
            </GridContainer>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

// export default withRouter(withStyles(style)(Step1Client));
// import Step1Client from "./Step1Client";

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers_pending: state.transfers_pending,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // updateNewTransferCreationTransferType: (transfer_type) => {
    //   dispatch(updateNewTransferCreationTransferType(transfer_type))
    // },
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    }
  };
};

const Step1ClientContainer = connect(mapStateToProps, mapDispatchToProps)(Step1Client);

export default withRouter(withStyles(style)(Step1ClientContainer));
