import {API} from 'aws-amplify';

export const REMITTANCE_FETCH_REMITTANCE_LIST_REQUEST = 'REMITTANCE_FETCH_REMITTANCE_LIST_REQUEST';
export const REMITTANCE_FETCH_REMITTANCE_LIST_SUCCESS = 'REMITTANCE_FETCH_REMITTANCE_LIST_SUCCESS';
export const REMITTANCE_CREATE_NEW_SHOW_UI = 'REMITTANCE_CREATE_NEW_SHOW_UI';
export const REMITTANCE_CREATE_NEW_START = 'REMITTANCE_CREATE_NEW_START';
export const REMITTANCE_CREATE_NEW_CANCEL = 'REMITTANCE_CREATE_NEW_CANCEL';
export const REMITTANCE_CREATE_NEW_COMPLETE = 'REMITTANCE_CREATE_NEW_COMPLETE';
export const REMITTANCE_SELECT_UI = 'REMITTANCE_SELECT_UI';
export const REMITTANCE_REMITTANCE_LIST_IS_DIRTY = 'REMITTANCE_REMITTANCE_LIST_IS_DIRTY';
export const REMITTANCE_REMITTANCE_EDIT = 'REMITTANCE_REMITTANCE_EDIT';

export function requestRemittanceList() {
  return {type: REMITTANCE_FETCH_REMITTANCE_LIST_REQUEST};
}

export function receiveRemittanceList(json) {
  return {
    type: REMITTANCE_FETCH_REMITTANCE_LIST_SUCCESS,
    remittance: json,
    receivedAt: Date.now()
  };
}

export function fetchRemittanceList() {
  return function (dispatch) {
    dispatch(requestRemittanceList);

    API.get('remittance', '/get-all')
      .then(response => {
        dispatch(receiveRemittanceList(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export async function create_new_remittance_in_database(body) {
  API.post('remittance', '/create', {body: body})
    .then(database_result => {
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
}

export function createNewRemittance(data) {
  return async function (dispatch) {
    dispatch(createNewRemittanceStart());

    try {
      await create_new_remittance_in_database(data);
    } catch (e) {
      console.log(e);
    }
  };
}

export function cancelCreateNewRemittance() {
  return {type: REMITTANCE_CREATE_NEW_CANCEL};
}

export function createNewRemittanceStart() {
  return {type: REMITTANCE_CREATE_NEW_START};
}

export function createNewRemittanceComplete() {
  return {type: REMITTANCE_CREATE_NEW_COMPLETE};
}

export function editRemittance(remittance_id) {
  return {
    type: REMITTANCE_REMITTANCE_EDIT,
    value: remittance_id
  };
}

export function selectRemittanceUi(value) {
  return {
    type: REMITTANCE_SELECT_UI,
    value: value
  };
}
