import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/CustomButtons/Button.jsx';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import {
  fetchClientList
  // setClientSelectId
} from '../../redux/actions/clients';
import {
  requestEntityList,
  fetchCurrencyListNotDeleted,
  currenciesListIsDirty,
  editCurrencies
} from '../../redux/actions/currencies';
import {connect} from 'react-redux';
import {API} from 'aws-amplify';
const style = {
  ...customCheckboxRadioSwitch,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {color: '#555'},
  inputAdornment: {position: 'relative'}
};

export const HoldingAccountCreateNew = ({
  classes,
  clients,
  currencies,
  fetchClientList,
  fetchCurrencyListNotDeleted
}) => {
  const [state, setState] = useState({
    addBeneficiaryModalOpen: false,
    clientId: null,
    currencyId: null
  });

  useEffect(() => {
    fetchCurrencyListNotDeleted();
    fetchClientList();
  }, []);

  const toggleModal = bool => {
    setState({
      ...state,
      addBeneficiaryModalOpen: bool
    });
  };

  const finished = async () => {
    console.info(' state is', state);
    const result = await API.post('holding_accounts', '/create/holding-account', {body: state});
    if (result.date) {
      toggleModal(false);
    } else {
      alert(result.message);
    }
  };

  const client_list_select_options = clients.map(item => ({
    value: item.id,
    label: item.nickname
  }));

  const currencies_list_select_options = currencies.map(item => ({
    value: item.id,
    label: item.full_name
  }));

  const handleCustomReactSelectChange = stateName => value => {
    setState({
      ...state,
      [stateName]: value.value
    });
  };
  return (
    <React.Fragment>
      <Button size='sm' color='info' key='addKey' onClick={() => toggleModal(true)}>
        Create New Account
      </Button>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={state.addBeneficiaryModalOpen}
        keepMounted
        onClose={() => toggleModal(false)}
        aria-labelledby='modal-slide-title'
        aria-describedby='modal-slide-description'
      >
        <DialogTitle id='classic-modal-slide-title' disableTypography className={classes.modalHeader}>
          <h4 className={classes.modalTitle}>Create New Account</h4>
        </DialogTitle>
        <DialogContent id='modal-slide-description' className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <CustomReactSelect
                // error={
                //   el.beneficiary_id_state === 'error'
                // }
                label='Select client'
                options={client_list_select_options}
                value={state.clientId}
                onChange={handleCustomReactSelectChange('clientId')}
                isClearable={false}
                // isDisabled={this.state.prefill_beneficiary}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomReactSelect
                // error={
                //   el.beneficiary_id_state === 'error'
                // }
                label='Select Currency'
                options={currencies_list_select_options}
                value={state.currencyId}
                onChange={handleCustomReactSelectChange('currencyId')}
                isClearable={false}
                // isDisabled={this.state.prefill_beneficiary}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
          <Button onClick={() => toggleModal(false)}>Cancel</Button>
          <Button color='primary' onClick={() => finished()}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    currencies: state.currencies.currencies,
    clients: state.clients.client_list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchClientList: () => {
      dispatch(fetchClientList());
    },
    fetchCurrencyListNotDeleted: () => {
      dispatch(fetchCurrencyListNotDeleted());
    }
  };
};

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(HoldingAccountCreateNew));
