import React, {Fragment, useEffect, useState} from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Box, Grid} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader';
import Button from '../../components/CustomButtons/Button.jsx';
import {API} from 'aws-amplify';
import {Col, Form, Input, Row, Select} from 'antd'
import FileUploader from "../../components/Uploaders/FileUploader";
import {useForm} from "antd/es/form/Form";
import {SaveOutlined} from "@ant-design/icons";

const styles = {
    figure: {
        padding: '5px',
        border: '1px solid #ccc'
    },
    attachmentThumb: {
        width: '100%',
        cursor: 'pointer',
        border: '1px solid #ccc',
        padding: '5px',
    },
    boxPadding: {
        paddingLeft: '10px',
        paddingTop: '10px'
    }
}

const ClientDocumentsEdit = ({classes, clientDocumentData, clientDocumentId}) => {

    const [documentForm] = useForm();
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientsList, setClientsList] = useState([]);
    const [documentCategories, setDocumentCategories] = useState([]);
    const [clientsListLoading, setClientsListLoading] = useState(false);
    const [documentDownloadUrl, setDocumentDownloadUrl] = useState(null);

    const fetchClients = () => {
        setClientsListLoading(true);
        API.post("commons", "/fetch", {
            body: {
                context: 'clientDocumentsCategories',
                fields: ['*']
            }
        }).then(res => {
            console.log(res);
            setDocumentCategories(res)
        });
        API.get('clients', '/get-list-not-deleted', {})
            .then(response => {
                setClientsList(response);
            })
            .catch(error => {
                console.log(error);
                setClientsList([]);
            })
            .finally(() => {
                setClientsListLoading(false);
            });
    };

    const handleSave = (values) => {
        setLoading(true);
        API.put('client-documents', `/id/${clientDocumentId}`, {body: values})
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
                API.put('client-documents', `/id/${clientDocumentId}`, {body: values})
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchClients();
    }, []);

    useEffect(() => {
        if (clientDocumentData) {
            documentForm.setFieldsValue({clientId: clientDocumentData.clientId});
            documentForm.setFieldsValue({description: clientDocumentData.description});
            documentForm.setFieldsValue({categoryID: clientDocumentData.categoryID});
            if (clientDocumentData.attachments != null && clientDocumentData.attachments.length > 0) {
                setTimeout(() => setEditMode(true), 3000);
            }
        }
    }, [clientDocumentData]);

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Card>
                        <CardHeader color='rose' icon>
                            <div className={classes.flexEnd}>
                                <h4 className={classes.cardIconTitle}>{editMode ? 'Edit Record: ' : 'View Record: '}</h4>
                                <>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={editMode}
                                                onChange={() => setEditMode(prevState => !prevState)}
                                                value='edit_mode'
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked
                                                }}
                                            />
                                        }
                                        classes={{
                                            label: classes.label
                                        }}
                                        label={editMode ? 'Edit Mode' : 'Read Only'}
                                    />
                                </>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Form form={documentForm} layout={'vertical'} onFinish={handleSave} disabled={!editMode}>
                                <Form.Item name='clientId' label={'Client'}
                                           rules={[{required: true, message: 'Field is required'}]}>
                                    <Select disabled={true} showSearch={true} options={clientsList.map(i => ({
                                        ...i,
                                        label: [i.first_name, i.last_name].join(' '),
                                        value: i.id
                                    }))}></Select>
                                </Form.Item>
                                <Form.Item name='categoryID' label={'Category'}
                                           rules={[{required: true, message: 'Field is required'}]}>
                                    <Select showSearch={true} options={documentCategories.map(i => ({
                                        ...i,
                                        label: i.categoryName,
                                        value: i.id
                                    }))}></Select>
                                </Form.Item>
                                <Form.Item name='description' label={'Description'}>
                                    <Input.TextArea/>
                                </Form.Item>
                                <Form.Item>
                                    <Button type={'primary'} htmlType={'submit'} icon={<SaveOutlined/>}>Submit</Button>
                                </Form.Item>
                            </Form>
                        </CardBody>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Box boxShadow={2} className={styles.boxPadding}>
                        <Row gutter={16}>
                            {
                                clientDocumentData && clientDocumentData.attachments && clientDocumentData.attachments.map(item => {
                                    return <Col span={8}
                                                style={{textAlign: 'center', paddingLeft: '10px', paddingRight: '10px'}}>
                                        <FileUploader previewOnly={true}
                                                      thumbType={'picture-card'}
                                                      uploaderType={'PDF'}
                                                      fetchFileList={() => {
                                                          const file = {
                                                              name: item,
                                                              uid: item,
                                                              status: 'done'
                                                          }
                                                          return Promise.resolve([file]);
                                                      }}></FileUploader>
                                    </Col>
                                })
                            }
                        </Row>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default ClientDocumentsEdit;
