import {API} from 'aws-amplify';

export const STAFF_SELECT_STAFF_MEMBER = 'STAFF_SELECT_STAFF_MEMBER';
export const TRANSFERS_2_FETCH_TRANSFER_LIST_REQUEST = 'TRANSFERS_2_FETCH_TRANSFER_LIST_REQUEST';
export const TRANSFERS_2_FETCH_TRANSFER_LIST_SUCCESS = 'TRANSFERS_2_FETCH_TRANSFER_LIST_SUCCESS';
export const TRANSFERS_2_FETCH_TRANSFER_LIST_FAILURE = 'TRANSFERS_2_FETCH_TRANSFER_LIST_FAILURE';
export const TRANSFERS_2_SELECT_UI = 'TRANSFERS_2_SELECT_UI';
export const TRANSFERS_2_SELECT_SET_ID = 'TRANSFERS_2_SELECT_SET_ID ';
export const CLIENTS_SELECT_CLIENT_REQUEST = 'CLIENTS_SELECT_CLIENT_REQUEST';
export const CLIENTS_SELECT_CLIENT_SUCCESS = 'CLIENTS_SELECT_CLIENT_SUCCESS';
export const TRANSFERS_2_FETCH_TRANSFER_REQUEST = 'TRANSFERS_2_FETCH_TRANSFER_REQUEST';
export const TRANSFERS_2_FETCH_TRANSFER_SUCCESS = 'TRANSFERS_2_FETCH_TRANSFER_SUCCESS';
export const TRANSFERS_2_START_NEW_TRANSFER_CREATION = 'TRANSFERS_2_START_NEW_TRANSFER_CREATION';
export const TRANSFERS_2_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE =
  'TRANSFERS_2_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE';
export const CLIENTS_2_UPDATE_NEW_CLIENT_CREATION_EMAIL = 'CLIENTS_2_UPDATE_NEW_CLIENT_CREATION_EMAIL';
export const CLIENTS_2_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME = 'CLIENTS_2_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME';
export const CLIENTS_2_UPDATE_NEW_CLIENT_CREATION_LASTNAME = 'CLIENTS_2_UPDATE_NEW_CLIENT_CREATION_LASTNAME';
export const TRANSFERS_2_UPDATE_NEW_TRANSFER_CREATION = 'TRANSFERS_2_UPDATE_NEW_TRANSFER_CREATION';
export const TRANSFERS_2_NEW_TRANSFER_DATABASE_CREATE_REQUEST = 'TRANSFERS_2_NEW_TRANSFER_DATABASE_CREATE_REQUEST';
export const TRANSFERS_2_NEW_TRANSFER_DATABASE_CREATE_SUCCESS = 'TRANSFERS_2_NEW_TRANSFER_DATABASE_CREATE_SUCCESS';
export const TRANSFERS_2_NEW_TRANSFER_DATABASE_CLEAR = 'TRANSFERS_2_NEW_TRANSFER_DATABASE_CLEAR';
export const TRANSFERS_2_NEW_TRANSFER_RESET = 'TRANSFERS_2_NEW_TRANSFER_DATABASE_RESET';

export function fetchTransferListRequest() {
  return {
    type: TRANSFERS_2_FETCH_TRANSFER_LIST_REQUEST
  };
}

export function fetchTransferListSuccess(data) {
  return {
    type: TRANSFERS_2_FETCH_TRANSFER_LIST_SUCCESS,
    transfer_list: data,
    receivedAt: Date.now()
  };
}

export function fetchTransferList() {
  return function (dispatch) {
    dispatch(fetchTransferListRequest());
    API.get('transfers', `/get-list-not-deleted`)
      .then(response => {
        dispatch(fetchTransferListSuccess(response));
      })
      .catch(error => {
        console.log(error);
        API.get('transfers', `/get-list-not-deleted`).then(response => {
          // console.log(response);
          this.setState({
            transfer_list: response
          });
        });
      });
  };
}

export function fetchTransferDetails(id) {
  return function (dispatch) {
    dispatch(loadTransferDetailsRequest());
    API.get('transfers', `/get/${id}`)
      .then(response => {
        dispatch(loadTransferDetailsSuccess(response));
      })
      .catch(error => {
        // console.log(error);
      });
  };
}

export async function createNewTransferInDatabase(data) {
  let database_result = API.post('transfers', `/create/in-database`, {
    body: {
      ...data
    }
  })
    .then(database_result => {
      return database_result;
    })
    .catch(error => {
      // console.log(error);
    });
  return database_result;
}

export function createNewTransfer(data) {
  return async function (dispatch) {
    dispatch(newTransferCreationDatabaseStart());

    try {
      let database_result = await createNewTransferInDatabase(data);
      dispatch(newTransferCreationDatabaseSuccess(database_result.insertId));
      return database_result;
    } catch (e) {
      // console.log(e);
    }
  };
}

export function selectTransferUi(value) {
  return {
    type: TRANSFERS_2_SELECT_UI,
    value: value
  };
}

export function setTransferSelectId(id) {
  return {
    type: TRANSFERS_2_SELECT_SET_ID,
    value: id
  };
}

export function loadTransferDetailsRequest() {
  return {
    type: TRANSFERS_2_FETCH_TRANSFER_REQUEST
  };
}
export function loadTransferDetailsSuccess() {
  return {
    type: TRANSFERS_2_FETCH_TRANSFER_SUCCESS
  };
}
export function startNewTransferCreation() {
  return {
    type: TRANSFERS_2_START_NEW_TRANSFER_CREATION
  };
}
export function updateNewTransferCreationTransferType(client_type) {
  return {
    type: TRANSFERS_2_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE,
    value: client_type
  };
}
export function updateNewTransferCreation(key, value) {
  return {
    type: TRANSFERS_2_UPDATE_NEW_TRANSFER_CREATION,
    value: {key, value}
  };
}
export function newTransferCreationDatabaseStart() {
  return {
    type: TRANSFERS_2_NEW_TRANSFER_DATABASE_CREATE_REQUEST
  };
}
export function newTransferCreationDatabaseClear() {
  return {
    type: TRANSFERS_2_NEW_TRANSFER_DATABASE_CLEAR
  };
}
export function newTransferCreationDatabaseSuccess(id) {
  return {
    type: TRANSFERS_2_NEW_TRANSFER_DATABASE_CREATE_SUCCESS,
    value: id
  };
}

export function resetTransferState() {
  return {
    type: TRANSFERS_2_NEW_TRANSFER_RESET
  };
}
