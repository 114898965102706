export const IndividualComplianceConstants = {
  generalTrustOptions: [
    {label: 'Full Name', value: 'full_name', required: true},
    {label: 'Date of Birth (D.O.B)', value: 'dob', required: true},
    {label: 'Residential Address', value: 'residential_address', required: true},
    {label: 'Trulioo Individual Verification', value: 'added_trulioo_idv', required: true},
    {label: 'Adverse Media Check', value: 'added_adverse_media', required: true},
  ],
  soleOptions: [
    {label: 'Full Business Name', value: 'business_name', required: false},
    {label: 'Principal Place of Business Address', value: 'business_address', required: false},
    {label: 'Residential Address', value: 'business_residential_address', required: false},
  ],
  verificationStandardOptions: [
    {label: 'Australian Driving License', value: 'driving_license', required: false},
    {label: 'Australian / Foreign Passport', value: 'passport', required: false},
    {label: 'Card Issued Under State / Federal Government', value: 'government_id', required: false},
  ],
  nonStandardVerificationOptions: [
    {label: 'Australian Birth Certificate', value: 'birth_certificate', required: false},
    {label: 'Australian Citizenship Certificate', value: 'citizenship', required: false},
    {label: 'Pension Card', value: 'pension_card', required: false},
    {label: 'Health care card', value: 'healthcare_card', required: false},
  ]
}

export const CompanyComplianceConstants = {
  auCompanyOptions: [
    {label: 'Full Name of Company, as registered by ASIC', value: 'au_full_name', required: false},
    {label: 'Address of Registered Office', value: 'au_registered_office', required: false},
    {label: 'Address of principal Place of Business', value: 'au_principal_business', required: false},
    {label: 'ACN', value: 'fc_acn', required: false},
    {label: 'If Proprietary Company - (Name of Each Director)', value: 'is_prop_director', required: false},
    {label: 'If Proprietary Company - (Name of Beneficial Owners)', value: 'beneficial_owners', required: false},
    {label: 'If Regulated Company - (Name of Regular & License)', value: 'is_regulated_company', required: false},
    {label: 'If Subsidy Company - (Name of Australian Listed Company and the name of the relevant market/exchange)', value: 'is_subsidy', required: false},
    {label: 'If Listed Company - (Name of the Relevant Market Exchange)', value: 'is_listed_company', required: false},
    {label: 'Search of Stock Exchange', value: 'search_stock_exchange', required: false},
    {label: 'Search of ASIC or an Original / Certified Copy by ASIC', value: 'asic_certified_copy', required: false},
    {label: 'Obtain a Public Document Issued by Company', value: 'public_document_company', required: false},
    {label: 'Individuals KYC checks in accordance with paragraph 3.3 for directors, shareholders and beneficial owners.', value: 'au_company_kyc_checks', required: false}
    // Add more options as needed
  ],
  foreignCompanyOptions: [
    {label: 'Full Business Name', value: 'fc_business_name', required: false},
    {label: 'Address of registered office in Australia', value: 'fc_address', required: false},
    {label: 'Address of Principal Place of Business in Australia, if any; of the full name and address of companys local agent in Australia; if any', value: 'fc_business_residential_address', required: false},
    {label: 'ARBN', value: 'fc_arbn', required: false},
    {label: 'Country where company was formed (Country of Origin)', value: 'fc_country_origin', required: false},
    {label: 'Whether Registered in country of origin and type of company', value: 'fc_company_type'},
    {label: 'If a proprietary company – name and address of beneficial owners and directors', value: 'fc_prop_company', required: false},
    {label: 'If Regulated Company - (Name of Regular & License)', value: 'fc_au_regulated_company', required: false},
    {label: 'If Subsidy Company - (Name of Australian Listed Company and the name of the relevant market/exchange)', value: 'fc_is_subsidy', required: false},
    {label: 'If Listed Company - (Name of the Relevant Market Exchange)', value: 'fc_is_listed_company', required: false},
    {label: 'Search of Stock Exchange', value: 'fc_search_stock_exchange', required: false},
    {label: 'Search of ASIC or an Original / Certified Copy by ASIC', value: 'fc_asic_certified_copy', required: false},
    {label: 'Obtain a Public Document Issued by Company', value: 'fc_public_document_company', required: false},
  ],
  foreignURCompanyOptions: [
    {label: 'Name of Company', value: 'fcu_business_name', required: false},
    {label: 'Country in which the company was formed', value: 'fcu_company_formed', required: false},
    {label: 'Country where company was formed (Country of Origin)', value: 'fcu_country_origin', required: false},
    {label: 'If a proprietary company – name and address of beneficial owners and directors', value: 'fcu_au_prop_company', required: false},
    {label: 'If Regulated Company - (Name of Regular & License)', value: 'fcu_au_regulated_company', required: false},
    {label: 'If Subsidy Company - (Name of Australian Listed Company and the name of the relevant market/exchange)', value: 'fcu_is_subsidy', required: false},
    {label: 'If Listed Company - (Name of the Relevant Market Exchange)', value: 'fcu_is_listed_company', required: false},
    {label: 'Search of Stock Exchange', value: 'fcu_search_stock_exchange'},
    {label: 'Search of Stock Exchange - Certificate of Registration', value: 'fcu_search_stock_exchange_certified_body', required: false},
    {label: 'Obtain a Public Document Issued by Company', value: 'fcu_public_document_company', required: false},
    {label: 'Individual KYC Checks in accordance with paragraph 3.3 for directors, shareholders and beneficial owners', value: 'fcu_individual_kyc_checks', required: false},
    {label: 'Disclosure Certificate', value: 'fcu_disclosure_certificate', required: false},
  ]
}

export const TrustComplianceConstants = {
  generalTrustOptions: [
    {label: 'Name of trust', value: 'name_of_trust', required: false},
    {label: 'Business name of trust', value: 'Business_name_of_trust', required: false},
    {label: 'Type of Trust', value: 'type_of_trust', required: false},
    {label: 'Country Trust Established', value: 'country_trust_established', required: false},
    {label: 'Name of each Trustee', value: 'name_of_each_trustee', required: false},
    {label: 'Registered address of each_trustee', value: 'trustee_reg_address', required: false},
    {label: 'Settlor of the Trust', value: 'settlor_of_the_trust', required: false},
    {label: 'Name of Address, DOB for each Beneficiary', value: 'name_dob_beneficiary', required: false},
    {label: 'Copy or extract of trust deed', value: 'trust_deed', required: false},
    {label: 'Search of ASIC, APRA, ATO website', value: 'asic_apra', required: false},
    {label: 'Notice of assessment issued by the ATO in the last 12 months', value: 'assessment_ato_issued', required: false},
    {label: 'Individual KYC checks in accordance with paragraph 3.3 for directors, shareholders and beneficial owners', value: 'kyc_checks', required: false},
    {label: 'If the trust identifies the beneficiaries by reference to a class, details of the class. If not, the name of each beneficiary', value: 'class_detail', required: false},
    {label: 'In some circumstances: disclosure certificate', value: 'disclosure_certificate', required: false},
  ],
  individualTrustee: [{label: 'Individual KYC on trustee', value: 'indv_kyc', required: false}],
  companyTrustee:[{label: 'Company KYC on Trustee', value: 'company_kyc', required: false}],
  otherTrusteeOptions:[
    {label: 'If a registered scheme, the scheme\'s ARSN', value: 'scheme_name', required: false},
    {label: 'If a regulated trust, the name of regulator and relevant ABN', value: 'regulated_trustee_abn', required: false},
    {label: 'If a government superannuation fund, the name of the establishing legislation', value: 'government_super_fund', required: false}
    // Add more options as needed
  ]
}