import React from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';

import {API} from 'aws-amplify';
import {AiOutlineSearch} from 'react-icons/ai';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';
import {withRouter} from 'react-router-dom';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import { Button, Table } from 'antd'
import ListTable from '../ListTable/ListTable'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - Team List
 * ----------------------------------------------------------------------------
 */
class TeamList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_list: [],
      columns: []
    };
  }

  componentDidMount() {
    const {fetchEntityListNotDeleted} = this.props;
    fetchEntityListNotDeleted();
    const columns = this.buildTableColumns();
    this.setState({columns: columns});
  }

  buildTableColumns() {
      return [
          {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              sorter: { compare: (a,b) => a.id - b.id },
              ...this.props.getColumnSearchProps({
                  dataIndex: 'id',
                  label: 'id',
                  render: (text, record) => {
                      return (
                          <>
                              <span>{ record.id }</span>
                          </>
                      );
                  }
              })
          },
          {
              title: 'Team Name',
              dataIndex: 'nickname',
              key: 'nickname',
              ...this.props.getColumnSearchProps({
                  dataIndex: 'nickname',
                  label: 'nickname',
                  render: (text, record) => {
                      return (
                          <>
                              <span>{ record.nickname }</span>
                          </>
                      );
                  }
              })
          },
          {
              title: 'Actions',
              dataIndex: 'actions',
              key: 'actions'
          }
      ]
  }

  buildTableData() {
    var entity_list_length = this.props.teams.teams_list.length;
    if (entity_list_length > 0) {
      return this.props.teams.teams_list.map((prop, key) => {
        return {
          id: prop.id,
          nickname: prop.nickname,
          system_email: prop.system_email,
          actions: (
            <div className='actions-right'>
              <Button
                  type={'primary'}
                onClick={() => {
                  this.props.history.push(`/settings/teams/edit/${prop.id}`);
                }}
                color='warning'
                className='edit'
              >
                <span><i className={'fa fa-pencil-alt'}></i> Edit</span>
              </Button>{' '}
              {/* use this button to remove the data row */}
              {/*<Button*/}
              {/*  justIcon*/}
              {/*  round*/}
              {/*  simple*/}
              {/*  onClick={() => {*/}
              {/*    var data = this.props.divisions.divisions_list;*/}
              {/*    data.find((o, i) => {*/}
              {/*      if (o.id === prop.id) {*/}
              {/*        API.put(*/}
              {/*          "divisions",*/}
              {/*          `/update/id/${prop.id}`,*/}
              {/*          {*/}
              {/*            body: { deleted: true }*/}
              {/*          }*/}
              {/*        )*/}
              {/*          .then(response => {*/}
              {/*            console.log(response);*/}
              {/*            this.props.fetchEntityListNotDeleted();*/}
              {/*            // dispatch(receiveStaffList(response));*/}
              {/*          })*/}
              {/*          .catch(error => {*/}
              {/*            console.log(error);*/}
              {/*          });*/}
              {/*        return true;*/}
              {/*      }*/}
              {/*      return false;*/}
              {/*    });*/}
              {/*    this.setState({ data: data });*/}
              {/*  }}*/}
              {/*  color="danger"*/}
              {/*  className="remove"*/}
              {/*>*/}
              {/*  <Close />*/}
              {/*</Button>{" "}*/}
            </div>
          )
        };
      });
    }
  }

  getFilter = (filter, onChange) => {
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AiOutlineSearch />
          <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <Table dataSource={this.buildTableData()} columns={this.state.columns}></Table>
    );
  }
}

export default withRouter(withStyles(styles)(ListTable(TeamList)));
