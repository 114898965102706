import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import MenuItem from '@material-ui/core/MenuItem';

// components for React Select
import ReactSelect from 'react-select';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';

const styles = {
  root: {},
  input: {
    display: 'flex',
    padding: '3px 0 7px'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  valueContainer2: {
    display: 'block',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'left'
  },

  singleValue: {
    fontSize: 12,
    margin: 'auto'
  },
  white: {color: 'white'},
  black: {color: 'black'},
  placeholder: {}
};

function NoOptionsMessage(props) {
  return (
    <Typography color='textSecondary' className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function inputComponent({inputRef, ...props}) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        disableUnderline: props.selectProps.customProps.disableUnderlined,
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          style: props.selectProps.customProps.controlStyle,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{fontWeight: props.isSelected ? 500 : 400}}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography color='textSecondary' className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      style={props.selectProps.customProps.inputStyle}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div
      className={
        props.selectProps.customProps.align == 'center'
          ? props.selectProps.classes.valueContainer
          : props.selectProps.classes.valueContainer2
      }
    >
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {[props.selectProps.classes.chipFocused]: props.isFocused})}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

function IndicatorSeparator(props) {
  return (
    <span style={{color: 'red'}} {...props.innerProps}>
      {props.children}
    </span>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorSeparator
};

class CustomReactSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableUnderlined: this.props.disableUnderlined != undefined ? this.props.disableUnderlined : false,
      align: this.props.align != undefined ? this.props.align : 'left',
      inputStyle: this.props.inputStyle != undefined ? this.props.inputStyle : {},
      controlStyle: this.props.controlStyle != undefined ? this.props.controlStyle : {},
      indicatorStyle: this.props.indicatorStyle != undefined ? this.props.indicatorStyle : {},
      containerStyle: this.props.containerStyle != undefined ? this.props.containerStyle : {},
      rootStyle: this.props.rootStyle != undefined ? this.props.rootStyle : {},
      defaultInputValue: this.props.defaultInputValue != undefined ? this.props.defaultInputValue : null
    };
  }

  componentDidMount() {}

  customStyles = {
    indicatorsContainer: () => ({
      '.myDropDown': {
        '&__dropdown-indicator': {
          color: 'red' // <--- Color of your choice
        }
      }
    })
  };

  render() {
    const {classes} = this.props;

    const current_selection_object = {};
    for (const index in this.props.options) {
      const option_value = this.props.options[index];
      if (option_value.value === this.props.value) {
        current_selection_object.value = option_value.value;
        current_selection_object.label = option_value.label;
      }
    }
    const Tvalue =
      Object.keys(current_selection_object).length > 0 && this.state.disableUnderlined != null
        ? current_selection_object
        : null;
    const label = this.props.error ? <span style={{color: 'red'}}>{this.props.label}</span> : this.props.label;

    return (
      <div className={classes.root} style={this.state.rootStyle}>
        <ReactSelect
          classes={classes}
          textFieldProps={{
            label: label,
            disableUnderline: this.state.disableUnderlined,
            InputLabelProps: {shrink: true}
          }}
          options={this.props.options}
          components={components}
          value={Tvalue}
          onChange={this.props.onChange}
          placeholder={this.state.defaultInputValue}
          customProps={this.state}
          isClearable={this.props.isClearable}
          isDisabled={this.props.isDisabled}
          styles={this.props.style ? this.props.style : this.customStyles}
        />
      </div>
    );
  }
}

CustomReactSelect.propTypes = {classes: PropTypes.object.isRequired};

export default withStyles(styles)(CustomReactSelect);
