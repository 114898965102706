import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {fetchTransferList, setTransferSelectId, selectTransferUi} from '../../../redux/actions/transfers';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import clientsNewStyles from "./ClientsNewStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {FormattedMessage} from 'react-intl';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import Button from '../../../components/CustomButtons/Button';
import TransfersEditContainer from '../../../components/Transfers/TransferEdit';
import {TransferPendingEdit} from '../../../components/Transfers/TransferPendingEdit';

const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - TransfersEdit - #1054 Improvement by Tom
 * ----------------------------------------------------------------------------
 */
class TransfersPendingEdit extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {classes} = this.props;
    //
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage
                  id='TransferFunction.cardItem1.title'
                  defaultMessage={'Transfer Management Functions'}
                />
              </h4>
              <div>
                <Button round color='info' onClick={() => this.props.history.push('/transfers/list')}>
                  <FormattedMessage id='newClient.cardItem1.showAll' defaultMessage={'Show All'} />
                </Button>
                <Button round color='primary'>
                  <FormattedMessage id='editClient.showRecord' defaultMessage={'Show Record'} />
                </Button>
                <Button round color='info' onClick={() => this.props.history.push('/transfers/new')}>
                  <FormattedMessage id='newTransfer.cardItem1.createNew' defaultMessage={'Create New'} />
                </Button>
                <Button
                  round
                  color='info'
                  disabled
                  // onClick={() => this.props.fetchTransferList()}
                >
                  <FormattedMessage id='newClient.cardItem1.refresh' defaultMessage={'Refresh'} />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <TransferPendingEdit {...this.props} transfer_detail_id={this.props.match.params.id} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

TransfersPendingEdit.propTypes = {classes: PropTypes.object.isRequired};

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers: state.transfers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTransferList: () => {
      dispatch(fetchTransferList());
    },
    setTransferSelectId: id => {
      dispatch(setTransferSelectId(id));
    },
    selectTransferUi: data => {
      dispatch(selectTransferUi(data));
    }
  };
};

const TransfersPendingEditContainerView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TransfersPendingEdit));

export default TransfersPendingEditContainerView;
