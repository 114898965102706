import {connect} from 'react-redux';

import {selectEntitybanksUi, fetchEntitybanksListNotDeleted} from '../../../redux/actions/entitybanksaccount';

import Entitybanksaccount from './Entitybanksaccount';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    entity_banks_account: state.entity_banks_account
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectEntitybanksUi: value => {
      dispatch(selectEntitybanksUi(value));
    },
    fetchEntitybanksListNotDeleted: () => {
      dispatch(fetchEntitybanksListNotDeleted());
    }
  };
};

const EntitybanksaccountContainer = connect(mapStateToProps, mapDispatchToProps)(Entitybanksaccount);

export default EntitybanksaccountContainer;
