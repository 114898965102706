import {API} from 'aws-amplify';

export const TEAM_FETCH_TEAM_LIST_REQUEST = 'TEAM_FETCH_TEAM_LIST_REQUEST';
export const TEAM_FETCH_TEAM_LIST_SUCCESS = 'TEAM_FETCH_TEAM_LIST_SUCCESS';
export const TEAM_FETCH_TEAM_LIST_FAILURE = 'TEAM_FETCH_TEAM_LIST_FAILURE';
export const TEAM_CREATE_NEW_SHOW_UI = 'TEAM_CREATE_NEW_SHOW_UI';
export const TEAM_CREATE_NEW_START = 'TEAM_CREATE_NEW_START';
export const TEAM_CREATE_NEW_CANCEL = 'TEAM_CREATE_NEW_CANCEL';
export const TEAM_CREATE_NEW_COMPLETE = 'TEAM_CREATE_NEW_COMPLETE';
export const TEAM_SELECT_UI = 'TEAM_SELECT_UI';
export const TEAM_ENTITIES_LIST_IS_DIRTY = 'TEAM_ENTITIES_LIST_IS_DIRTY';
export const TEAM_SELECT_SET_ID = 'TEAM_SELECT_SET_ID';
export const TEAM_SELECT_TEAM_REQUEST = 'TEAM_SELECT_TEAM_REQUEST';
export const TEAM_SELECT_TEAM_SUCCESS = 'TEAM_SELECT_TEAM_SUCCESS';

export const TEAM_INVITES_FETCH_REQUEST = 'TEAM_INVITES_FETCH_REQUEST';
export const TEAM_INVITES_FETCH_SUCCESS = 'TEAM_INVITES_FETCH_SUCCESS';

export function requestTeamInvites() {
  return {type: TEAM_INVITES_FETCH_REQUEST};
}

export function requestEntityList() {
  return {
    type: TEAM_FETCH_TEAM_LIST_REQUEST
  };
}

export function receiveEntityList(json) {
  return {
    type: TEAM_FETCH_TEAM_LIST_SUCCESS,
    teams_list: json,
    receivedAt: Date.now()
  };
}

export function receiveTeamRequestsList(json) {
  return {
    type: TEAM_INVITES_FETCH_SUCCESS,
    teams_list: json,
    receivedAt: Date.now()
  };
}
export function fetchTeamRequestList() {
  return function (dispatch) {
    dispatch(requestTeamInvites);

    API.get('teams', '/get/team_invites/all').then(res => {
      dispatch(receiveTeamRequestsList(res));
    });
  };
}

export function fetchEntityListNotDeleted() {
  return function (dispatch) {
    dispatch(requestEntityList);

    API.get('teams', `/get-list-not-deleted`)
      .then(response => {
        dispatch(receiveEntityList(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function selectEntityUi(value) {
  return {
    type: TEAM_SELECT_UI,
    value: value
  };
}

export function entitiesListIsDirty(value) {
  return {
    type: TEAM_ENTITIES_LIST_IS_DIRTY,
    value: value
  };
}

export async function create_new_entity_in_database(nickname, division_id) {
  API.post('teams', `/create/in-database`, {
    body: {
      nickname: nickname,
      division_id: division_id
    }
  })
    .then(database_result => {
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
}

export function createNewDivision(data) {
  return async function (dispatch) {
    dispatch(createNewEntityStart());

    try {
      await create_new_entity_in_database(data.nickname, data.division_id);
    } catch (e) {
      console.log(e);
    }
  };
}

export function cancelCreateNewEntity() {
  return {
    type: TEAM_CREATE_NEW_CANCEL
  };
}

export function setEntityDetailId(id) {
  return {
    type: TEAM_SELECT_SET_ID,
    value: id
  };
}

export function loadEntityDetailsRequest() {
  return {
    type: TEAM_SELECT_TEAM_REQUEST
  };
}
export function loadEntityDetailsSuccess() {
  return {
    type: TEAM_SELECT_TEAM_SUCCESS
  };
}

export function createNewEntityStart() {
  return {
    type: TEAM_CREATE_NEW_START
  };
}

export function createNewEntityComplete() {
  return {
    type: TEAM_CREATE_NEW_COMPLETE
  };
}
