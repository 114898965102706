import {connect} from 'react-redux';
import EntitybanksEdit from './EntitybanksEdit';
import {
  createNewEntitybanks,
  createNewEntitybanksStart,
  createNewEntitybanksComplete,
  cancelCreateNewEntitybanks,
  selectEntitybanksUi,
  entitybanksListIsDirty
} from '../../redux/actions/entitybanks';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    entity_banks: state.entity_banks
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createNewEntitybanks: data => {
      dispatch(createNewEntitybanks(data));
    },
    createNewEntitybanksStart: () => {
      dispatch(createNewEntitybanksStart());
    },
    createNewEntitybanksComplete: data => {
      dispatch(createNewEntitybanksComplete(data));
    },
    cancelCreateNewEntitybanks: () => {
      dispatch(cancelCreateNewEntitybanks());
    },
    selectEntitybanksUi: value => {
      dispatch(selectEntitybanksUi(value));
    },
    entitybanksListIsDirty: value => {
      dispatch(entitybanksListIsDirty(value));
    }
  };
};

const EntitybanksEditContainer = connect(mapStateToProps, mapDispatchToProps)(EntitybanksEdit);

export default EntitybanksEditContainer;
