import React from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';

import {API} from 'aws-amplify';
import {AiOutlineSearch} from 'react-icons/ai';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';

// core components
// import GridContainer from "components/Grid/GridContainer.jsx";
// import GridItem from "components/Grid/GridItem.jsx";
import Button from 'components/CustomButtons/Button.jsx';
// import Card from "components/Card/Card.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - ENTITYTABLE
 * ----------------------------------------------------------------------------
 */
class EntityTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_list: []
    };
  }

  componentDidMount() {
    const {fetchEntityListNotDeleted} = this.props;
    // console.log(this.props);
    fetchEntityListNotDeleted();
  }

  buildTableData() {
    // console.log(this.props);
    var entity_list_length = this.props.entities.entities_list.length;
    if (entity_list_length > 0) {
      return this.props.entities.entities_list.map((prop, key) => {
        // console.log(prop);
        return {
          id: prop.id,
          nickname: prop.nickname,
          legal_entity_name: prop.legal_entity_name,
          afsl: prop.afsl,
          actions: (
            <div className='actions-right'>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  // let obj = this.props.staff.staff_list.find(o => o.aws_cognito_id === prop.aws_cognito_id);
                  this.props.setEntityDetailId(prop.id);
                  this.props.selectEntityUi('edit');
                  // alert(
                  //   "You've clicked EDIT button on \n{ \nName: " +
                  //   obj.first_name +
                  //   ", \nlast name: " +
                  //   obj.last_name +
                  //   ", \nemail: " +
                  //   obj.email +
                  //   "\n}."
                  // );
                }}
                color='warning'
                className='edit'
              >
                <Dvr />
              </Button>{' '}
              {/* use this button to remove the data row */}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  var data = this.props.entities.entities_list;
                  data.find((o, i) => {
                    if (o.id === prop.id) {
                      // here you should add some custom code so you can delete the data
                      // from this component and from your server as well
                      // data.splice(i, 1);

                      API.put('system', `/entities/update/id/${prop.id}`, {
                        body: {deleted: true}
                      })
                        .then(response => {
                          console.log(response);
                          this.props.fetchEntityListNotDeleted();
                          // dispatch(receiveStaffList(response));
                        })
                        .catch(error => {
                          console.log(error);
                        });

                      return true;
                    }
                    return false;
                  });
                  this.setState({data: data});
                }}
                color='danger'
                className='remove'
              >
                <Close />
              </Button>{' '}
            </div>
          )
        };
      });
      // console.log(data);
    }
  }

  render() {
    // console.log(this.props);
    return (
      <ReactTable
        // data={this.state.entity_list}
        data={this.buildTableData()}
        filterable
        columns={[
          {
            Header: 'Nick Name',
            accessor: 'nickname'
          },
          {
            Header: 'Legal Entity',
            accessor: 'legal_entity_name'
          },
          {
            Header: 'AFSL',
            accessor: 'afsl'
          },
          {
            Header: 'Actions',
            accessor: 'actions',
            sortable: false,
            filterable: false
          }
        ]}
        defaultSorted={[
          {
            id: 'nickname',
            desc: false
          }
        ]}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom={false}
        className='-striped -highlight'
      />
    );
  }
}

export default withStyles(styles)(EntityTable);
