import {connect} from 'react-redux';
import ReportsPayout from './ReportsPayout';

const mapStateToProps = state => {
  return {
    app_state: state.app_state
  };
};

const ReportsPayoutContainer = connect(mapStateToProps)(ReportsPayout);

export default ReportsPayoutContainer;
