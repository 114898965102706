import {API} from 'aws-amplify';

export const NEWS_CLIENTS_FETCH_NEWS_CLIENTS_LIST_REQUEST = 'NEWS_CLIENTS_FETCH_NEWS_CLIENTS_LIST_REQUEST';
export const NEWS_CLIENTS_FETCH_NEWS_CLIENTS_LIST_SUCCESS = 'NEWS_CLIENTS_FETCH_NEWS_CLIENTS_LIST_SUCCESS';
export const NEWSSTAFF_FETCH_NEWSSTAFF_LIST_FAILURE = 'NEWSSTAFF_FETCH_NEWSSTAFF_LIST_FAILURE';
export const CLIENT_NEWS_CREATE_NEW_CANCEL = 'CLIENT_NEWS_CREATE_NEW_CANCEL';

export function fetchNewsClientsListRequest() {
  return {
    type: NEWS_CLIENTS_FETCH_NEWS_CLIENTS_LIST_REQUEST
  };
}

export function fetchNewsClientsListSuccess(data) {
  return {
    type: NEWS_CLIENTS_FETCH_NEWS_CLIENTS_LIST_SUCCESS,
    news_clients_list: data,
    received_at: Date.now()
  };
}

export function fetchNewsClientsList() {
  return function (dispatch) {
    dispatch(fetchNewsClientsListRequest());
    API.get('news-clients', `/get-all`)
      .then(response => {
        dispatch(fetchNewsClientsListSuccess(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

// export function fetchTransferDetails(id) {
//   return function(dispatch) {
//     // console.log(aws_cognito_id)
//     dispatch(loadTransferDetailsRequest());
//     API.get("transfers", `/get/${id}`)
//       .then(response => {
//         // console.log(response);
//         dispatch(loadTransferDetailsSuccess(response));
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// }

export async function createNewTransferInDatabase(data) {
  let database_result = API.post('news-clients', `/create/in-database`, {
    body: {
      ...data
    }
  })
    .then(database_result => {
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
  return database_result;
}

export function createNewClientNews(data) {
  return async function (dispatch) {
    // dispatch(newTransferCreationDatabaseStart());
    try {
      let database_result = await createNewTransferInDatabase(data);
      // dispatch(newTransferCreationDatabaseSuccess(database_result.insertId));
      return database_result;
    } catch (e) {
      console.log(e);
    }
  };
}

// export function fetchStaffList() {
//   return function(dispatch) {
//     dispatch(requestStaffListRequest);
//
//     API.get("crm", `/staff/get-list`)
//       .then(response => {
//         console.log(response);
//         dispatch(receiveStaffList(response));
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// }

// export function fetchStaffListNotDeleted() {
//   return function(dispatch) {
//     dispatch(requestStaffListRequest);
//
//     API.get("crm", `/staff/get-list-not-deleted`)
//       .then(response => {
//         // console.log(response);
//         dispatch(receiveStaffList(response));
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// }
//
// export function selectTransferUi(value) {
//   return {
//     type: TRANSFERS_SELECT_UI,
//     value: value
//   };
// }

// async function createNewUserWithAws(username, password) {
//   const newUser = await Auth.signUp({
//     username: username,
//     password: password
//   });
//   return newUser;
// }

// export async function create_new_user_in_database(
//   aws_cognito_id,
//   email,
//   first_name,
//   last_name
// ) {
//   API.post("crm", `/staff/create/in-database`, {
//     body: {
//       email: email,
//       aws_cognito_id: aws_cognito_id,
//       first_name: first_name,
//       last_name: last_name
//     }
//   })
//     .then(database_result => {
//       return database_result;
//     })
//     .catch(error => {
//       console.log(error);
//     });
// }
//
export function cancelCreateNewStaffMember() {
  return {
    type: CLIENT_NEWS_CREATE_NEW_CANCEL
  };
}
//
// export function setTransferSelectId(id) {
//   return {
//     type: TRANSFERS_SELECT_SET_ID,
//     value: id
//   };
// }
//
// export function loadStaffMemberDetails(aws_cognito_id) {
//   return async function(dispatch) {
//     dispatch(loadStaffMemberDetailsRequest(aws_cognito_id));
//
//   };
// }
//

export function fetchClientNewsDetails(id) {
  return function (dispatch) {
    dispatch(loadClientNewsDetailsRequest());
    API.get('news-clients', `/get/${id}`)
      .then(response => {
        dispatch(fetchNewsClientsListSuccess(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function loadClientNewsDetailsRequest() {
  return {
    type: NEWS_CLIENTS_FETCH_NEWS_CLIENTS_LIST_REQUEST
  };
}
export function loadClientNewsDetailsSuccess() {
  return {
    type: NEWS_CLIENTS_FETCH_NEWS_CLIENTS_LIST_SUCCESS
  };
}
// export function startNewTransferCreation() {
//   return {
//     type: TRANSFERS_START_NEW_TRANSFER_CREATION
//   };
// }
// export function updateNewTransferCreationTransferType(client_type) {
//   return {
//     type: TRANSFERS_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE,
//     value: client_type
//   };
// }
// export function updateNewTransferCreation(key, value) {
//   // console.log(key, value);
//   return {
//     type: TRANSFERS_UPDATE_NEW_TRANSFER_CREATION,
//     value: { key, value }
//   };
// }
// export function newTransferCreationDatabaseStart() {
//   return {
//     type: TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_REQUEST
//   };
// }
// export function newTransferCreationDatabaseSuccess(id) {
//   return {
//     type: TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_SUCCESS,
//     value: id
//   };
// }

//
// export function createNewStaffMemberStart() {
//   return {
//     type: STAFF_CREATE_NEW_START
//   };
// }
//
// export function createNewStaffMemberComplete() {
//   return {
//     type: STAFF_CREATE_NEW_COMPLETE
//   };
// }
