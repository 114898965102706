import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles.js';

// core components
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
import checkboxStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import {API} from 'aws-amplify';
import {Button, Flex, Form, Input, Select, Switch} from "antd";
import {AppUrlConstants} from "../../AppAPI";
import {RemittanceAPI} from "./RemittanceUtil";

const staffEditStyles = {
    ...checkboxStyles,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center'
    },
    description: {color: '#999999'},
    updateProfileButton: {float: 'right'},
    title: {
        color: '#3C4858',
        textDecoration: 'none'
    },
    checkbox: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center'
    },
    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px'
    },
    registerButton: {float: 'right'}
};

class RemittanceEdit extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            is_loading: false,
            country_list: [],
            clients: [],

            teams: [],
            currencies_list: [],

            bank_list: [],
            remittance_id: '',
            bank_account_id: '',
            country_id: '',
            client_Id: '',
            team_Id: '',
            currency_id: '',
            active: ''
        };
    }

    async componentDidMount() {
        const [clients, teams, bank_list, currencies_list] = await Promise.all([
            AppUrlConstants.fetchClients(),
            AppUrlConstants.fetchTeams(),
            AppUrlConstants.fetchBankAccounts(),
            AppUrlConstants.fetchCurrencies()
        ]);

        if (this.props.remittance_id) {
            RemittanceAPI.fetchRemittanceById(this.props.remittance_id)
                .then(remittance_detail => {
                    // console.info(' details are', remittance_detail);
                    this.formRef.current.setFieldsValue({
                        is_loading: false,
                        bank_account_id: remittance_detail[0].bankAccountId,
                        client_id: remittance_detail[0].clientId,
                        team_id: remittance_detail[0].teamId,
                        currency_id: remittance_detail[0].currencyId,
                        active: remittance_detail[0].active,
                        thresholdAmount: remittance_detail[0].thresholdAmount,
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
        this.setState({
            is_loading: false,
            clients: clients.sort((a, b) => a.nickname.localeCompare(b.nickname)).map(item => ({
                value: item.id,
                label: item.nickname
            })),
            teams: teams.sort((a, b) => a.nickname.localeCompare(b.nickname)).map(item => ({
                value: item.id,
                label: item.nickname
            })),
            bank_list: bank_list.sort((a, b) => a.nickname.localeCompare(b.nickname)).map(item => ({
                label: item.nickname,
                value: item.id
            })),
            currencies_list: currencies_list.sort((a, b) => a.iso_alpha_3.localeCompare(b.iso_alpha_3)).map(item => ({
                value: item.id,
                alt: item.iso_alpha_3 + ': ' + item.full_name,
                label: <React.Fragment>
                    <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`}/>
                    &nbsp;
                    {item.iso_alpha_3 + ': ' + item.full_name}
                </React.Fragment>
            }))
        });
    }

    async saveRemittance(remittance_detail) {
        return API.post('remittance', `/update/id/${this.props.remittance_id}`, {body: remittance_detail});
    }

    handleChange = event => {
        this.setState({[event.target.id]: event.target.value});
    };

    handleToggle() {
        this.setState({active: this.state.active === 1 ? 0 : 1});
    }

    handleSelectChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleSubmit = async values => {
        this.setState({is_loading: true});
        const {bank_account_id, currency_id, client_id, team_id, active, thresholdAmount} = values;
        try {
            await this.saveRemittance({
                bankAccountId: bank_account_id,
                clientId: client_id,
                teamId: team_id,
                currencyId: currency_id,
                active: active,
                thresholdAmount: thresholdAmount
            });
            this.setState({is_loading: false});
        } catch (e) {
            this.setState({is_loading: false});
        }

        this.props.history.push('/system-settings/remittance/list');
    };

    customValidation = (_, values) => {
        if (!this.formRef.current.getFieldValue('client_id') && !this.formRef.current.getFieldValue('team_id')) {
            return Promise.reject('At least one field should be selected');
        }

        return Promise.resolve();
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <h3>Edit Remittance</h3>
                        <Form labelCol={{span: 6}} wrapperCol={{span: 16}} ref={this.formRef} layout={'horizontal'}
                              onFinish={this.handleSubmit}>
                            <Form.Item label={'Select Bank'} name="bank_account_id"
                                       rules={[{required: true, message: 'Field is required'}]}>
                                <Select
                                    showSearch={true}
                                    placeholder='-- SELECT BANK --'
                                    optionFilterProp='alt'
                                    filterOption={(input, option) =>
                                        (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA && optionA.alt ? optionA.alt : '')
                                            .toLowerCase()
                                            .localeCompare(
                                                (optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase()
                                            )
                                    }
                                    options={this.state.bank_list}
                                ></Select>
                            </Form.Item>
                            <Form.Item label={'Client'} name="client_id"
                                       rules={[{validator: this.customValidation}]}>
                                <Select
                                    allowClear
                                    showSearch={true}
                                    placeholder='-- SELECT CLIENT --'
                                    filterOption={(input, option) =>
                                        (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA && optionA.label ? optionA.label : '')
                                            .toLowerCase()
                                            .localeCompare(
                                                (optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase()
                                            )
                                    }
                                    options={this.state.clients}
                                ></Select>
                            </Form.Item>
                            <Form.Item label={'Team'} name="team_id"
                                       rules={[{validator: this.customValidation}]}>
                                <Select
                                    allowClear
                                    showSearch={true}
                                    placeholder='-- SELECT TEAM --'
                                    filterOption={(input, option) =>
                                        (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA && optionA.label ? optionA.label : '')
                                            .toLowerCase()
                                            .localeCompare(
                                                (optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase()
                                            )
                                    }
                                    options={this.state.teams}
                                ></Select>
                            </Form.Item>
                            <Form.Item label={'Currency'} name="currency_id"
                                       rules={[{required: true, message: 'Field is required'}]}>
                                <Select
                                    showSearch={true}
                                    placeholder='-- SELECT CURRENCY --'
                                    optionFilterProp={'alt'}
                                    filterOption={(input, option) =>
                                        (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA && optionA.alt ? optionA.alt : '')
                                            .toLowerCase()
                                            .localeCompare(
                                                (optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase()
                                            )
                                    }
                                    options={this.state.currencies_list}
                                ></Select>
                            </Form.Item>
                            <Form.Item label={'Threshold Amount'} name="thresholdAmount"
                                       rules={[{required: true, message: 'Field is required'}]}>
                                <Input type={'number'} placeholder={'Enter threshold amount'}/>
                            </Form.Item>
                            <Form.Item label="Active" name="active" valuePropName="checked">
                                <Switch/>
                            </Form.Item>
                            <Form.Item wrapperCol={{span: 22}}>
                                <Flex justify={'flex-end'}>
                                    <Button loading={this.state.is_loading} htmlType={'submit'} type={'primary'}
                                            style={{marginRight: '5px'}}>Update Remittance</Button>
                                </Flex>
                            </Form.Item>
                        </Form>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

RemittanceEdit.propTypes = {classes: PropTypes.object.isRequired};

export default withRouter(withStyles(staffEditStyles)(RemittanceEdit));
