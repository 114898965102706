import React from 'react';
import {API} from 'aws-amplify';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {updateNewTransferCreation} from 'redux/actions/transfers_2';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import {Alert, Button, Flex, Input} from 'antd'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react';
import queryString from 'query-string';
import cardPng from 'assets/img/payment_24px.png';
import CommoneCurrentComponent from '../../common/Common';
import axios from 'axios';
import {Checkbox, Chip} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import {ForwardsAPI} from '../../../views/Dashboard/Forwards/ForwardsUtil'
import FormItem from 'antd/lib/form/FormItem'
import dayjs from 'dayjs'
import {HoldingAccountAPI} from "../../HoldingAccounts/HoldingAccountConstants";
import {CurrencyLocales} from "../../../AppUtil";

const style = {
  ...customCheckboxRadioSwitch,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {color: '#555'},
  inputAdornment: {position: 'relative'},
  formControl: {
    minWidth: 120,
    padding: '0 !important'
  },
  selectControl: {
    backgroundColor: '#00BDAB !important',
    color: 'white',
    padding: '5%',
    width: '100% !important'
  },
  optionStyle: {backgroundColor: '#00BDAB !important'},

  column: {
    paddingRight: '0 !important',
    paddingBottom: '0 !important'
  },
  columnRight: {paddingLeft: '0 !important'},
  boxShadow: {
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    boxSizing: 'border-box'
  },
  input: {
    margin: 0,
    padding: 0,
    border: 'none',
    direction: 'rtl',
    paddingRight: '4%',
    fontWeight: '700',
    fontFamily: 'Roboto',
    fontSize: '24px'
  },
  divv: {
    filter: 'drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25))',
    boxSizing: 'border-box'
  },
  pp: {
    float: 'right',
    paddingRight: '9px',
    padding: '0',
    margin: '0',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '12px',
    paddingTop: '1px'
  },
  iso: {fontSize: '13px !important'},
  isoSpan: {width: '33% !important'},
  textArea: {
    backgroundColor: 'white',
    width: '100%'
  },
  inputReadOnly: {
    paddingTop: '4px',
    zIndex: '-1000',
    paddingRight: '4%',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '13px',
    margin: 0,
    padding: 0,
    paddingBottom: '0',
    border: 'none',
    direction: 'rtl'
  },
  transferRate: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '2px',
    paddingLeft: '4%',
    color: '#131313',
    paddingTop: '3%',
    textTransform: 'uppercase'
  },
  selectContainer: {
    padding: '4%',
    background: '#FFFFFF',
    border: '1px solid #0BACF4',
    boxSizing: 'border-box',
    borderRadius: '6px'
  },
  paymentMethod: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '17.04px',
    float: 'right',
    textAlign: 'right',
    opacity: '0.7',
    color: '#131313',
    paddingTop: '4%',
    paddingRight: '3%'
  },
  cardImg: {
    textAlign: 'center',
    paddingLeft: '7% !important'
  },
  transactionOptions: {
    border: 'none',
    width: '100%',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'right',
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
    color: '#0BACF4',
    backgroundColor: 'white'
  }
};

const min_transfer_amount = 0;
const WORLDPAY_ADJUSTMENT = 0.02;

class Step2Transfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from_currency: 'AUD',
      to_currency: 'USD',
      from_amount: '',
      to_amount: '',
      reRender: true,
      from_currency_state: 'error',
      to_currency_state: 'error',
      from_amount_state: 'error',
      to_amount_state: 'error',
      holdingAccount: null,
      beneficiaries_list: [],
      beneficiary_id: '',
      beneficiary: {},
      purpose_of_payment_detail_state: '',
      amount_to_state: '',
      currencies_list: [],
      currencies_list_priority: [],
      prefill_to_currency: false,
      prefill_from_currency: false,
      available_balance: 0,
      ledgerBalance: null,
      // clientHaBalance: null
    };
  }

  componentDidMount() {
      console.log(this.props);

      if (this.props.transfers_2.new_transfer_data.forward_id) {
          ForwardsAPI.fetchForwardById(this.props.transfers_2.new_transfer_data.forward_id).then(response => {
              ForwardsAPI.fetchAvailableBalance(this.props.transfers_2.new_transfer_data.forward_id).then(balance => {
                if (response != null && response.length > 0) {
                  this.setState({
                    forward: response[0],
                    prefill_from_currency: true,
                    prefill_to_currency: true,
                    from_currency: response[0].currency_from_id,
                    to_currency: response[0].currency_to_id,
                    from_currency_state: 'success',
                    to_currency_state: 'success',
                    available_balance: balance
                  })
                }
                this.loadDefaultCurrencies(true);
              })
          })
      }



    API.get('currencies', '/list_not_deleted')
      .then(response => {
        this.setState({currencies_list_priority: response}, () => {
          this.queryStrings();
        });
      })
      .catch(error => {
        console.log(error);
      });

    this.set_payment_radio('bank');

    // this.getClientHoldingAccount();
  }

  loadDefaultCurrencies = (isForward) => {
      const query_strings = queryString.parse(this.props.location.search);

      const currency = parseInt(query_strings.fromCurrency);
      const fromLedger = parseInt(query_strings.fromLedger);
      this.props.updateNewTransferCreation('defaultFromCurrency', isForward ? this.state.from_currency : (isNaN(currency) ? 1 : currency));
      this.defaultCurrencies(
          'from_currency',
          this.props.app_state.current_team &&
          isForward ? this.state.from_currency : (this.props.app_state.current_team.defaultFromCurrency || isNaN(currency) ? 1 : currency)
      );
      this.defaultCurrencies(
          'to_currency',
          isForward ? this.state.to_currency : (this.props.app_state.current_team && (this.props.app_state.current_team.defaultToCurrency || 5))

      );
  }

  initializeDefaultCurries = (isForward) => {
     this.loadDefaultCurrencies(isForward);
  };

  defaultCurrencies = (name, option_value) => {
    this.setState({[name]: option_value}, () => {
      switch (this.state.last_user_type_amount_name) {
        case 'from_amount':
          this.handleAmount('to_amount');
          break;
        case 'to_amount':
          this.handleAmount('from_amount');
          break;
        default:
          this.handleAmount();
      }
    });
    this.setState({[name + '_state']: 'success'});

    // console.log(name);
    if (name === 'from_currency') {
      // console.log(this.state);
      this.props.updateNewTransferCreation('currency_from_id', option_value);
      this.props.updateNewTransferCreation('currency_from_state', 'success');

      this.getClientHoldingAccount(option_value);

      API.get('currencies', `/get/${option_value}`)
        .then(response => {
          this.props.updateNewTransferCreation('currency_from', response);
          this.props.updateNewTransferCreation('currency_from_name', response.iso_alpha_3);
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (name === 'to_currency') {
      // console.log("==do something==");
      // console.log(this.state);
      this.props.updateNewTransferCreation('currency_to_id', option_value);
      this.props.updateNewTransferCreation('currency_to_state', 'success');
      API.get('currencies', `/get/${option_value}`)
        .then(response => {
          this.props.updateNewTransferCreation('currency_to', response);
          this.props.updateNewTransferCreation('currency_to_name', response.iso_alpha_3);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  queryStrings() {
    const query_strings = queryString.parse(this.props.location.search);
    if (Object.keys(query_strings).length !== 0) {
      if (query_strings.client_id) {
        if (query_strings.from_amount) {
          this.getClientDetails(Number(query_strings.client_id), 'handleToAmount', query_strings);
        }
        if (query_strings.to_amount) {
          this.getClientDetails(Number(query_strings.client_id), 'handleFromAmount', query_strings);
        }
      }
    }
  }

  getClientDetails(client_id, option, query_strings) {
    API.get('clients', `/get/id/${client_id}`)
      .then(response => {
        this.props.updateNewTransferCreation('client', response);
        if (option === 'handleToAmount') {
          this.setState(
            {
              from_currency: Number(query_strings.from_currency),
              from_currency_state: 'success',
              to_currency: Number(query_strings.to_currency),
              to_currency_state: 'success',
              from_amount: query_strings.from_amount,
              from_amount_state: 'success',
              last_user_type_amount_name: 'from_amount'
            },
            () => {
              this.handleAmount('to_amount');
            }
          );
        }
        if (option === 'handleFromAmount') {
          this.setState(
            {
              from_currency: Number(query_strings.from_currency),
              from_currency_state: 'success',
              to_currency: Number(query_strings.to_currency),
              to_currency_state: 'success',
              to_amount: query_strings.to_amount,
              to_amount_state: 'success',
              last_user_type_amount_name: 'to_amount'
            },
            () => {
              this.handleAmount('from_amount');
            }
          );
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  getBeneficiaryDetails(beneficiary_id) {
    API.get('beneficiaries', `/get/id/${beneficiary_id}`)
      .then(response => {
        this.setState({beneficiary: response[0]});
        this.props.updateNewTransferCreation('beneficiary', response[0]);

        this.setState({
          beneficiary: {
            ...this.state.beneficiary,
            account_currency_nickname: `${response[0].full_name} [${response[0].iso_alpha_3}]`
          }
        });

        return response;
      })
      .then(response => {
        API.get('system', `/currencies/get/${response[0].account_currency}`)
          .then(response => {
            this.setState({
              beneficiary: {
                ...this.state.beneficiary,
                account_currency_nickname: `${response.full_name} [${response.iso_alpha_3}]`
              }
            });
            this.props.updateNewTransferCreation('currency_to_id', response.id);
            this.props.updateNewTransferCreation('currency_to', response);

            API.post('email', '/interaction/main', {
              body: {
                interaction: 'New Transfer Request Step 2 PayingTo',
                data: {
                  client_id: this.props.app_state.current_client.id,
                  client_nickname: this.props.app_state.current_client.nickname,
                  beneficiary_id: this.state.beneficiary.id,
                  beneficiary_nickname: this.state.beneficiary.nickname
                },
                result: 'New transfer request proceeding'
              }
            });
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  }

  fetchNewRate = () => {

    let from_currency_Iso = 'AUD';
    let to_currency_Iso = 'USD';

    this.state.currencies_list_priority.map((item, index) => {
      if (item.id == this.state.from_currency) {
        from_currency_Iso = item.iso_alpha_3;
      }
      if (item.id == this.state.to_currency) {
        to_currency_Iso = item.iso_alpha_3;
      }
    });

    const url = 'https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
    axios
      .get(url, {
        params: {
          currencies: to_currency_Iso,
          source: from_currency_Iso,
          format: '1'
        }
      })
      .then(response => {
        const key = from_currency_Iso + to_currency_Iso;
        const rate_string = response.data.quotes[key];

        const rate = Number.parseFloat(rate_string);
        const rate_adjust =
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate) +
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_division) +
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_entity) +
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_team) +
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_staff);
        let adjusted_rate = rate - rate * rate_adjust;

        if (this.state.from_currency == this.state.to_currency) {
          adjusted_rate = rate;
        }

        adjusted_rate = adjusted_rate.toFixed(4);
        this.props.updateNewTransferCreation('client_rate', adjusted_rate);
        this.setState({adjusted_rate: adjusted_rate});
        
      })
      .catch(error => {
        console.log(error);
      });
    }

  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if a value is larger than a number or not
  isLargerThanNumber(value, number) {
    if (value !== '' && value >= number) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'isLargerThanNumber':
        if (this.isLargerThanNumber(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
    // Calculate "to amount"
    if (stateName === 'from_amount') {
      this.setState({last_user_type_amount_name: 'from_amount'});
      this.props.updateNewTransferCreation('amount_from', event.target.value);
      this.props.updateNewTransferCreation('amount_lock', 'from_amount');
      // this.handleAmount('to_amount');
    }
    // Calculate "from amount"
    if (stateName === 'to_amount') {
      this.setState({last_user_type_amount_name: 'to_amount'});
      this.props.updateNewTransferCreation('amount_to', event.target.value);
      this.props.updateNewTransferCreation('amount_lock', 'to_amount');
      // this.handleAmount('from_amount');
    }
  }

  handleAmount(amount_name) {
    let from_currency_Iso = 'AUD';
    let to_currency_Iso = 'USD';

    this.state.currencies_list_priority.map((item, index) => {
      if (item.id == this.state.from_currency) {
        from_currency_Iso = item.iso_alpha_3;
      }
      if (item.id == this.state.to_currency) {
        to_currency_Iso = item.iso_alpha_3;
      }
    });

    const url = 'https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
    axios
      .get(url, {
        params: {
          currencies: to_currency_Iso,
          source: from_currency_Iso,
          format: '1'
        }
      })
      .then(response => {
        const key = from_currency_Iso + to_currency_Iso;
        const rate_string = response.data.quotes[key];

        const rate = Number.parseFloat(rate_string);
        const rate_adjust =
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate) +
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_division) +
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_entity) +
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_team) +
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_staff);
        let adjusted_rate = rate - rate * rate_adjust;
        // Add Credit Card Rate Adjustment
        // if (this.props.transfers_2.new_transfer_data.payment_method === 'worldpay') {
        //   adjusted_rate = adjusted_rate - adjusted_rate * WORLDPAY_ADJUSTMENT;
        // }

        if (this.state.from_currency == this.state.to_currency) {
          adjusted_rate = rate;
        }

        adjusted_rate = Number.parseFloat(adjusted_rate).toFixed(4);
        this.props.updateNewTransferCreation('client_rate', adjusted_rate);
        this.setState({adjusted_rate: adjusted_rate});
        console.log('adjusted_rate', adjusted_rate);

        if (this.state.from_currency_state === 'success' && this.state.to_currency_state === 'success') {
          // handle to_amount
          if (amount_name === 'to_amount' && this.state.from_amount_state === 'success') {
            const amount_from = Number.parseFloat(this.state.from_amount);
            const amount_to = amount_from * adjusted_rate;

            this.setState({to_amount: amount_to.toFixed(2)});
            this.props.updateNewTransferCreation('amount_to', amount_to.toFixed(2));
            if (amount_to >= min_transfer_amount) {
              this.setState({to_amount_state: 'success'});
            } else {
              this.setState({to_amount_state: 'error'});
            }
          }

          // handle from_amount
          if (amount_name === 'from_amount' && this.state.to_amount_state === 'success') {
            const amount_to = Number.parseFloat(this.state.to_amount);
            const amount_from = amount_to / adjusted_rate;
            this.props.updateNewTransferCreation('amount_to', amount_to.toFixed(2));

            this.setState({from_amount: amount_from.toFixed(2)});
            this.props.updateNewTransferCreation('amount_from', amount_from.toFixed(2));
            if (amount_from >= min_transfer_amount) {
              this.setState({from_amount_state: 'success'});
            } else {
              this.setState({from_amount_state: 'error'});
            }
          }
        }

      })
      .catch(error => {
        console.log(error);
      });
  }

  handleAmountsBackend(buttonID) {
    const adjusted_rate = this.props.transfers_2.new_transfer_data.client_rate;
    if (this.state.from_currency_state === 'success' && this.state.to_currency_state === 'success') {
      let amount_from;
      let amount_to;
      switch (buttonID) {
        case 1:
            amount_from = Number.parseFloat(this.state.from_amount);
            amount_to = amount_from * adjusted_rate;
            this.setState({to_amount: amount_to.toFixed(2)});
            this.props.updateNewTransferCreation('amount_to', amount_to.toFixed(2));
            if (amount_to >= min_transfer_amount) {
              this.setState({to_amount_state: 'success'});
            } else {
              this.setState({to_amount_state: 'error'});
            }
          break;

        case 2:
            amount_from = Number.parseFloat(this.state.from_amount);
            amount_to = amount_from / adjusted_rate;
            this.setState({to_amount: amount_to.toFixed(2)});
            this.props.updateNewTransferCreation('amount_to', amount_to.toFixed(2));
            if (amount_to >= min_transfer_amount) {
              this.setState({to_amount_state: 'success'});
            } else {
              this.setState({to_amount_state: 'error'});
            }
          break;

        case 3:
          amount_to = Number.parseFloat(this.state.to_amount);
          amount_from = amount_to * adjusted_rate;
          this.setState({from_amount: amount_from.toFixed(2)});
          this.props.updateNewTransferCreation('amount_from', amount_from.toFixed(2));
          if (amount_to >= min_transfer_amount) {
            this.setState({to_amount_state: 'success'});
          } else {
            this.setState({to_amount_state: 'error'});
          }
        break;

        case 4:
          amount_to = Number.parseFloat(this.state.to_amount);
          amount_from = amount_to / adjusted_rate;
          this.setState({from_amount: amount_from.toFixed(2)});
          this.props.updateNewTransferCreation('amount_from', amount_from.toFixed(2));
          if (amount_to >= min_transfer_amount) {
            this.setState({to_amount_state: 'success'});
          } else {
            this.setState({to_amount_state: 'error'});
          }
          break;

      }

      // Update Beneficiary Array with new calculated amount
      const beneficiary_array = this.props.transfers_2.new_transfer_data.multiple_beneficiary_id || [{}];
      beneficiary_array[0].amount = parseFloat(amount_to).toFixed(2);
      this.props.updateNewTransferCreation('multiple_beneficiary_id', beneficiary_array);

    }
  }

  setStoreState = (key, value) => {
      if (key === 'fee_visible_amount') {
          const curId = value != null ? this.props.transfers_2.new_transfer_data.currency_from.id: null;
          this.props.updateNewTransferCreation('fee_visible_currency_id', curId);
      }
      this.props.updateNewTransferCreation(key, value);
  }

  // handleAmountOnly(amount_name) {
  //   const adjusted_rate = this.props.transfers_2.new_transfer_data.client_rate;
  //   if (this.state.from_currency_state === 'success' && this.state.to_currency_state === 'success') {
  //     // handle to_amount
  //     if (amount_name === 'to_amount' && this.state.from_amount_state === 'success') {
  //       const amount_from = Number.parseFloat(this.state.from_amount);
  //       const amount_to = amount_from * adjusted_rate;

  //       this.setState({to_amount: amount_to.toFixed(2)});
  //       this.props.updateNewTransferCreation('amount_to', amount_to.toFixed(2));
  //       if (amount_to >= min_transfer_amount) {
  //         this.setState({to_amount_state: 'success'});
  //       } else {
  //         this.setState({to_amount_state: 'error'});
  //       }
  //     }

  //     // handle from_amount
  //     if (amount_name === 'from_amount' && this.state.to_amount_state === 'success') {
  //       const amount_to = Number.parseFloat(this.state.to_amount);
  //       const amount_from = amount_to / adjusted_rate;
  //       this.props.updateNewTransferCreation('amount_to', amount_to.toFixed(2));
  //       this.setState({from_amount: amount_from.toFixed(2)});
  //       this.props.updateNewTransferCreation('amount_from', amount_from.toFixed(2));
  //       if (amount_from >= min_transfer_amount) {
  //         this.setState({from_amount_state: 'success'});
  //       } else {
  //         this.setState({from_amount_state: 'error'});
  //       }
  //     }
  //   }
  // }

  fetchHoldingAccountBalance = (option_value) => {
    HoldingAccountAPI.fetchHoldingItemLedgerBalance(this.props.transfers_2.new_transfer_data.client.id, option_value).then(response => {
      if (response != null && response.holding_account_list != null && response.holding_account_list.length > 0) {
        this.setState({ ledgerBalance: response.holding_account_list[0] });
      }
    });
  }

  handleCustomReactSelectChange = (name, previous_from_currency, previous_to_currency) => value => {
    let option_value;
    if (value === null) {
      option_value = null;
    } else {
      option_value = value.value;
    }

    this.setState({[name]: option_value}, () => {
      switch (this.state.last_user_type_amount_name) {
        case 'from_amount':
          this.handleAmount('to_amount');
          break;
        case 'to_amount':
          this.handleAmount('from_amount');
          break;
        default:
          this.handleAmount();
      }
    });
    this.setState({[name + '_state']: 'success'});

    if (name === 'from_currency') {
      this.props.updateNewTransferCreation('currency_from_id', option_value);
      this.props.updateNewTransferCreation('currency_from_state', 'success');
      API.get('currencies', `/get/${option_value}`)
        .then(response => {
          this.props.updateNewTransferCreation('currency_from', response);
          this.props.updateNewTransferCreation('currency_from_name', response.iso_alpha_3);
        })
        .catch(error => {
          console.log(error);
        });

      this.fetchHoldingAccountBalance(option_value);
    }
    if (name === 'to_currency') {
      this.props.updateNewTransferCreation('ledgerTo', false);
      this.props.updateNewTransferCreation('currency_to_id', option_value);
      this.props.updateNewTransferCreation('currency_to_state', 'success');
      API.get('currencies', `/get/${option_value}`)
        .then(response => {
          this.props.updateNewTransferCreation('currency_to', response);
          this.props.updateNewTransferCreation('currency_to_name', response.iso_alpha_3);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  set_payment_radio = value => {
    this.setState({payment_method: value});
    this.props.updateNewTransferCreation('payment_method', value);
    this.handleAmount('from_amount');
  };

  isValidated() {
    if (this.state.from_currency_state !== 'success') {
      this.setState({from_currency_state: 'error'});
    }
    if (this.state.to_currency_state !== 'success') {
      this.setState({to_currency_state: 'error'});
    }
    if (this.state.from_amount_state !== 'success') {
      this.setState({from_amount_state: 'error'});
    }
    if (this.state.to_amount_state !== 'success') {
      this.setState({to_amount_state: 'error'});
    }

    if (
      this.state.from_currency_state === 'success' &&
      this.state.to_currency_state === 'success' &&
      this.state.from_amount_state === 'success' &&
      this.state.to_amount_state === 'success'
    ) {
      API.get('system', `/currencies/get/${this.state.from_currency}`)
        .then(response => {
          console.log(response);
          this.props.updateNewTransferCreation('currency_from', response);
          //#1090
          this.props.updateNewTransferCreation('remitting_bank_account_id', response.primary_bank_account_id);
          API.get('system', `/entitybanksaccount/get/id/${response.primary_bank_account_id}`)
            .then(response => {
              console.log(response);
              this.props.updateNewTransferCreation('remitting_bank_account_detail', response);

              API.get('system', `/entitybanks/get/id/${response.entity_bank_id}`)
                .then(response => {
                  console.log(response);
                  this.props.updateNewTransferCreation('remitting_bank_detail', response);
                  //#1090
                  this.props.updateNewTransferCreation(
                    'remitting_bank_id',
                    this.props.transfers_2.new_transfer_data.remitting_bank_account_detail[0].entity_bank_id
                  );
                })
                .catch(error => {
                  console.log(error);
                });
            })
            .catch(error => {
              console.log(error);
            });

          API.get('system', `/currencies/get/${this.state.to_currency}`)
            .then(response => {
              this.props.updateNewTransferCreation('currency_to', response);
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
        });

      this.props.updateNewTransferCreation('currency_from_id', this.state.from_currency);
      this.props.updateNewTransferCreation('currency_to_id', this.state.to_currency);
      this.props.updateNewTransferCreation('amount_from', this.state.from_amount);
      this.props.updateNewTransferCreation('amount_to', this.state.to_amount);
      this.props.updateNewTransferCreation('client_rate', this.state.adjusted_rate);

      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.transfers_2.new_transfer_data.client && this.state.reRender) {
      const isForward = this.props.transfers_2.new_transfer_data.forward_id != null;
      this.initializeDefaultCurries(isForward);
      this.setState({reRender: false});
    }
  }

  getClientHoldingAccount = async (value) => {
    const clientId = this.props.transfers_2.new_transfer_data.client.id;
    const holdingBalance = await API.get('holding_accounts', `/get/client-currency/${clientId}/${value || 5}`, {});
    this.setState({ ledgerBalance: {...this.state.ledgerBalance, balance: holdingBalance.holding_account_list[0].balance}});
  }

  set_payment_radio_second = e => {
    e.preventDefault();
    this.setState({payment_method: e.target.value});
    this.props.updateNewTransferCreation('payment_method', e.target.value);
    this.handleAmount('from_amount');
  };

  // getLedgerBalances = () => {
  //   // console.log('getLedgerBalances')
  //   API.get('holding_accounts', `/get/client_id/${this.props.transfers_2.new_transfer_data.client.id}`)
  //     .then(response => {
  //       console.info('holdingAccounts', response);
  //       this.setState({holdingAccountBalances: response.holding_account_list});

  //       // const {holding_account_list} = response;
  //       // if (holding_account_list.length > 0) {
  //       //   this.setState({
  //       //     holdingAccount: response.holding_account_list[0]
  //       //   });
  //       // }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  updateLedgerAmount = async (stateName, value) => {
    this.setState({[stateName]: value});
    this.props.updateNewTransferCreation(stateName, value);

    if (stateName == 'ledgerTo') {
      if (value) {
        const clientId = this.props.transfers_2.new_transfer_data.client.id;
        const currencyId = this.props.transfers_2.new_transfer_data.currency_to_id;
        const response = await API.get('holding_accounts', `/get/client-currency/${clientId}/${currencyId}`);
        console.log(response);
        this.setState({
          send_to_holding_acc_checkbox: value,
          [stateName]: response.holding_account_list[0].id
        });
        this.props.updateNewTransferCreation('ledgerTo', response.holding_account_list[0].id);
        this.props.updateNewTransferCreation('send_to_holding_acc_checkbox', value);
      } else {
        this.setState({
          send_to_holding_acc_checkbox: value,
          ledgerTo: value
        });
        this.props.updateNewTransferCreation('ledgerTo', value);
        this.props.updateNewTransferCreation('send_to_holding_acc_checkbox', value);
      }
    }
  };

  handleRateChange = e => {
    const {name, value} = e.target;
    this.props.updateNewTransferCreation('client_rate', value);
    this.setState({adjusted_rate: value});
    // console.log('adjusted_rate', value);

    // if (this.props.transfers_2.new_transfer_data.amount_lock == 'from_amount') {
    //   const amount_from = Number.parseFloat(this.state.from_amount);
    //   const amount_to = amount_from * Number.parseFloat(value);

    //   this.setState({to_amount: amount_to.toFixed(2)});
    //   this.props.updateNewTransferCreation('amount_to', amount_to.toFixed(2));
    //   if (amount_to >= min_transfer_amount) {
    //     this.setState({to_amount_state: 'success'});
    //   } else {
    //     this.setState({to_amount_state: 'error'});
    //   }
    // }

    // if (this.props.transfers_2.new_transfer_data.amount_lock == 'to_amount') {
    //   const amount_to = Number.parseFloat(this.state.to_amount);
    //   const amount_from = amount_to / Number.parseFloat(value);
    //   this.props.updateNewTransferCreation('amount_to', amount_to.toFixed(2));
    //   this.setState({from_amount: amount_from.toFixed(2)});
    //   this.props.updateNewTransferCreation('amount_from', amount_from.toFixed(2));
    //   if (amount_from >= min_transfer_amount) {
    //     this.setState({from_amount_state: 'success'});
    //   } else {
    //     this.setState({from_amount_state: 'error'});
    //   }
    // }

  }

  render() {
    const {classes} = this.props;
    // For CustomReactSelect. Generate select options for dropdown list.
    // console.log(this.state.currencies_list_priority)

    const sortedCurrencies = this.state.currencies_list_priority.sort((a, b) => {
      if (a.iso_alpha_3 < b.iso_alpha_3) return -1;
      if (a.iso_alpha_3 > b.iso_alpha_3) return 1;
      return 0;
    })
    const select_currency_select_options = sortedCurrencies.map(item => ({
      value: item.id,
      label: item.iso_alpha_3
    }));

    const checkledger = record => {
      // const query_strings = queryString.parse(this.props.location.search);
      // const fromLedger = parseInt(query_strings.fromLedger);
      // if (!isNaN(fromLedger) && parseInt(this.state.from_amount) > record.balance) {
      //   return 'You have entered an amount higher than the available';
      // } else {
      //   return '';
      // }
      if (
        parseFloat(this.props.transfers_2.new_transfer_data.amount_from) > record.balance &&
        this.props.transfers_2.new_transfer_data.ledgerFrom
      ) {
        if (!this.props.transfers_2.new_transfer_data.disable) {
          this.props.updateNewTransferCreation('disable', true);
        } else if (this.props.transfers_2.new_transfer_data.disable == false) {
          this.props.updateNewTransferCreation('disable', true);
        }
        if (this.props.transfers_2.new_transfer_data.ledgerFrom) {
          this.setState({ledgerFrom: false});
          this.updateLedgerAmount('ledgerFrom', false);
        }
        return 'You have entered an amount higher than your available balance';
      }
      if (this.props.transfers_2.new_transfer_data.disable == true) {
        this.props.updateNewTransferCreation('disable', false);
      }
    };

    let fromCurrencyLedgerChip = <Chip icon={<React.Fragment> Ledger Balance: 0.00</React.Fragment>} />;

    for (const idx in this.state.holdingAccountBalances) {
      const record = this.state.holdingAccountBalances[idx];
      if (record.currency_id === this.props.transfers_2.new_transfer_data.currency_from_id) {
        fromCurrencyLedgerChip = (
          <React.Fragment>
            <p
              style={{
                textAlign: 'right',
                textTransform: 'capitalize',
                fontWeight: 'bold',
                color: 'red'
              }}
            >
              {checkledger(record)}
            </p>
            <Chip
              label={
                <div>
                  <Checkbox
                    tabIndex={-1}
                    disabled={parseFloat(this.props.transfers_2.new_transfer_data.amount_from) > record.balance}
                    // disabled={this.disableCheckBox(
                    //   "amount_from",
                    //   record,
                    //   "ledgerFrom"
                    // )}
                    onClick={e => this.updateLedgerAmount('ledgerFrom', e.target.checked ? record.id : null)}
                    checked={
                      Boolean(this.state.ledgerFrom) || Boolean(this.props.transfers_2.new_transfer_data.ledgerFrom)
                    }
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                  Click to transfer from {record.currencies_short_name} Ledger. Current balance&nbsp;
                  {new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: record.currencies_short_name
                  }).format(record.balance)}
                  <br />
                </div>
              }
            />
          </React.Fragment>
        );
      }
    }

    // fromCurrencyLedgerChip = (
    //   <React.Fragment>
    //     {this.state.holdingAccount &&
    //       <Chip
    //         label={
    //           <div>
    //             Current balance&nbsp;
    //             {new Intl.NumberFormat('en-GB', {
    //               style: 'currency',
    //               currency: this.state.holdingAccount.currencies_short_name
    //             }).format(this.state.holdingAccount.balance)}
    //             <span
    //               style={{
    //                 textTransform: 'capitalize',
    //                 fontWeight: 'bold',
    //                 color: 'red'
    //               }}
    //             >
    //             &nbsp;&nbsp; {this.state.holdingAccount && checkledger(this.state.holdingAccount)}
    //             </span>
    //             <br />
    //           </div>
    //         }
    //       />}
    //   </React.Fragment>
    // );

    // Cross Check
    const calculated = (Number.parseFloat(this.props.transfers_2.new_transfer_data.amount_from).toFixed(2) * 
                  Number.parseFloat(this.props.transfers_2.new_transfer_data.client_rate).toFixed(4)).toFixed(2)
    

    const rateToDisplay = Number.parseFloat(this.state.adjusted_rate).toFixed(4)


    let fromToButton = 'From --> To';
    let toFromButton = 'To --> From';
    if (this.props.transfers_2 && 
        this.props.transfers_2.new_transfer_data && 
        this.props.transfers_2.new_transfer_data.currency_from && 
        this.props.transfers_2.new_transfer_data.currency_to
      ) {
        fromToButton = `${this.props.transfers_2.new_transfer_data.currency_from.iso_alpha_3} to ${this.props.transfers_2.new_transfer_data.currency_to.iso_alpha_3}`
        toFromButton = `${this.props.transfers_2.new_transfer_data.currency_to.iso_alpha_3} to ${this.props.transfers_2.new_transfer_data.currency_from.iso_alpha_3}`
      }

    return (
      <React.Fragment>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText} />
          </GridItem>
        </GridContainer>
        <GridContainer
          justify='flex-start'
          style={{
            maxWidth: '100%',
            flexBasis: '100%'
          }}
        >
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer>
                <GridItem xs={23}>
                    <div style={{marginBottom: '5px'}}>
                        {this.props.transfers_2.new_transfer_data.forward_id && <Alert message={
                            <div dangerouslySetInnerHTML={{ __html: `<span><strong>Head Forward ID :</strong> ${this.props.transfers_2.new_transfer_data.forward_id} </span><br />
                    <span><strong>Forward From :</strong> ${this.props.transfers_2.new_transfer_data.forward_detail.amount_from} </span><br />
                    <span><strong>Forward To :</strong> ${this.props.transfers_2.new_transfer_data.forward_detail.amount_to} </span><br />
                    <span><strong>Available Balance :</strong> ${this.state.available_balance} </span><br />
                    <span><strong>Settlement Date :</strong> ${dayjs(this.props.transfers_2.new_transfer_data.forward_detail.settlement_date).format('DD/MM/YYYY')}</span>`}}></div>
                        } type="info" />}
                    </div>
                </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={23}>
              <GridContainer>
              {this.props.app_state.current_team && this.props.app_state.current_team.brand_transfer_payeeFirst ? (
                  <CommoneCurrentComponent
                  color='yellow'
                  selectLabel='To Currency'
                  selectOptions={select_currency_select_options}
                  selectValue={this.state.to_currency}
                  isClearable={false}
                  isDisabled={this.state.prefill_to_currency}
                  inputValue2={this.state.to_amount}
                  onSelectChange={this.handleCustomReactSelectChange('to_currency')}
                  inputValue='Beneficiary gets'
                  onInputChange={event => {
                    this.change(event, 'to_amount', 'isLargerThanNumber', min_transfer_amount);
                  }}
                />
              ) : (
                <>
                  <CommoneCurrentComponent
                      selectLabel='From Currency'
                      defaultValue={1000}
                      color='blue'
                      selectOptions={select_currency_select_options}
                      selectValue={this.state.from_currency}
                      isClearable={false}
                      isDisabled={this.state.prefill_from_currency}
                      onSelectChange={this.handleCustomReactSelectChange('from_currency')}
                      inputValue='You send'
                      inputValue2={this.state.from_amount}
                      onInputChange={event => {
                        this.change(event, 'from_amount', 'isLargerThanNumber', min_transfer_amount);
                      }}
                  />
                </>
              )}
              </GridContainer>
              </GridItem>
              <GridItem xs={1}>
                <Button onClick={() => this.handleAmountsBackend(1)}>
                  &#xd7;
                </Button>
                <br/>
                <Button onClick={() => this.handleAmountsBackend(2)}>
                  &#xF7;
                </Button>
              </GridItem>
            </GridContainer>

            {this.state.ledgerBalance != null ? <span style={{ display: "block", marginLeft: '10px', marginTop: '10px', marginBottom: '10px' }}>
                    {/* <span style={{ display: 'block'}}>Total Debits : {this.state.ledgerBalance.total_debits || 0}</span>
                    <span style={{ display: 'block'}}>Total Credits : {this.state.ledgerBalance.total_credits || 0}</span> */}
                    <span style={{ display: 'block'}}>Ledger Balance: {(this.state.ledgerBalance.balance || 0).toFixed(2)}</span>
                  </span> : ''}
            <br />

            <GridContainer>
              <GridItem xs={8} sm={8} md={8}>
                <p className={classes.transferRate}>
                  {/* {!isNaN(this.state.adjusted_rate) */}
                    <>
                      Transfer Rate: 
                      <GridContainer>
                        <GridItem xs={8}>
                          <Input 
                            value={this.state.adjusted_rate}
                            // value={rateToDisplay}
                            onChange={this.handleRateChange}
                            >
                          </Input>
                        </GridItem>
                        <GridItem xs={48}>
                          <Button onClick={() => this.fetchNewRate()}>Get Rate</Button>
                        </GridItem>
                      </GridContainer>
                      {/* <br/>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button onClick={() => this.handleAmountOnly('to_amount')}>
                        {fromToButton}
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button onClick={() => this.handleAmountOnly('from_amount')}>
                        {toFromButton}
                      </Button> */}
                    </> 
                    {/* : ''} */}
                </p>
              </GridItem>
              {false && 
              <GridItem xs={8} sm={8} md={5}>
                <div className={classes.selectContainer}>
                  <GridContainer justify='center' style={{marginBottom: '1px'}}>
                    <GridItem xs={3} md={3} className={classes.cardImg}>
                      <img src={cardPng} alt='' />
                    </GridItem>
                    <GridItem xs={9} md={9} style={{paddingLeft: '0'}}>
                      <span className={classes.paymentMethod}>Choose a payment method:</span>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify='center'>
                    <GridItem xs={12} sm={12}>
                      <select
                        name='cars'
                        id='cars'
                        className={classes.transactionOptions}
                        defaultChecked={this.state.payment_method}
                        onChange={this.set_payment_radio_second.bind(this)}
                      >
                        <option value='bank'>Bank Transfer (EFT)</option>
                        {/* <option value="worldpay">Credit Card</option> */}
                      </select>
                    </GridItem>
                    {/* <GridItem xs={1} sm={1} /> */}
                  </GridContainer>
                </div>
              </GridItem>
                }
            </GridContainer>

            <br />

            <GridContainer>
              <GridItem xs={23}>
                <GridContainer>

                  {this.props.app_state.current_team && this.props.app_state.current_team.brand_transfer_payeeFirst ? (
                    <CommoneCurrentComponent
                      selectLabel='From Currency'
                      defaultValue={1000}
                      color='blue'
                      selectOptions={select_currency_select_options}
                      selectValue={this.state.from_currency}
                      isClearable={false}
                      onSelectChange={this.handleCustomReactSelectChange('from_currency')}
                      inputValue='You send'
                      inputValue2={this.state.from_amount}
                      onInputChange={event => {
                        this.change(event, 'from_amount', 'isLargerThanNumber', min_transfer_amount);
                      }}
                    />
                  ) : (
                    <CommoneCurrentComponent
                      color='yellow'
                      selectLabel='To Currency'
                      selectOptions={select_currency_select_options}
                      selectValue={this.state.to_currency}
                      isClearable={false}
                      isDisabled={this.state.prefill_to_currency}
                      inputValue2={this.state.to_amount}
                      onSelectChange={this.handleCustomReactSelectChange('to_currency')}
                      inputValue='Beneficiary gets'
                      onInputChange={event => {
                        this.change(event, 'to_amount', 'isLargerThanNumber', min_transfer_amount);
                      }}
                    />
                  )}
                </GridContainer>
              </GridItem>
              <GridItem xs={1}>
                <Button onClick={() => this.handleAmountsBackend(3)}>
                  &#xd7;
                </Button>
                <br/>
                <Button onClick={() => this.handleAmountsBackend(4)}>
                  &#xF7;
                </Button>
              </GridItem>
            </GridContainer>

            <hr/>

            <GridContainer>
              <GridItem xs={9} sm={9} md={9}>
                  <FormItem name="fee_amount" label={'Fee Amount'}>
                      <Input placeholder={'Enter fee amount'} type={'number'} onChange={(e) => this.setStoreState('fee_visible_amount', e.target.value)} />
                  </FormItem>
                  <FormItem name="fee_description" label={'Fee Description'}>
                      <Input placeholder={'Enter Text Area'} onChange={(e) => this.setStoreState('fee_visible_description', e.target.value)}></Input>
                  </FormItem>
                <strong>Calculation Cross Check:</strong><br/>
                <strong>From Amount: </strong> {this.props.transfers_2.new_transfer_data.amount_from}<br/>
                <strong>Rate: </strong> {Number.parseFloat(this.props.transfers_2.new_transfer_data.client_rate).toFixed(4)}<br/>
                <strong>To Amount: </strong> 
                  <span style={{color: Number.parseFloat(calculated).toFixed(2) != Number.parseFloat(this.props.transfers_2.new_transfer_data.amount_to).toFixed(2) ? 'red' : ''}}>
                    {this.props.transfers_2.new_transfer_data.amount_to}
                  </span>
                <br/>
                <strong>Calculated: </strong> {calculated}
                {/* <br/>
                {(calculated != this.props.transfers_2.new_transfer_data.amount_to) && 
                <em>If 'calculated' does not equal 'to amount' then press rate buttons (From --> To) or (To --> From)</em>
                } */}
              </GridItem>
            </GridContainer>

          </GridItem>{' '}
        </GridContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers_2: state.transfers_2
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    }
  };
};

const Step2TransferContainer = connect(mapStateToProps, mapDispatchToProps)(Step2Transfer);

export default withRouter(withStyles(style)(Step2TransferContainer));
