import {connect} from 'react-redux';
// import { onLoginSuccess } from "../../redux/actions";
// import Link from '../components/Link'
import SettingsStaffProfile from './SettingsStaffProfile.jsx';

const mapStateToProps = state => {
  return {
    app_state: state.app_state
    // staff: state.staff
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onLoginSuccess: (staff_id) => {
    //   dispatch(onLoginSuccess(staff_id));
    // }
  };
};

const SettingsStaffProfileContainer = connect(mapStateToProps, mapDispatchToProps)(SettingsStaffProfile);

export default SettingsStaffProfileContainer;
