import {
  CompanyComplianceConstants,
  IndividualComplianceConstants,
  TrustComplianceConstants
} from './ComplianceConstants';
import {API} from 'aws-amplify';

export const INDIVIDUAL_COMPLIANCE_LIST = [
  ...IndividualComplianceConstants.generalTrustOptions,
  ...IndividualComplianceConstants.soleOptions, ...IndividualComplianceConstants.verificationStandardOptions,
  ...IndividualComplianceConstants.nonStandardVerificationOptions
];

export const COMPANY_COMPLIANCE_LIST = [
  ...CompanyComplianceConstants.auCompanyOptions,
  ...CompanyComplianceConstants.foreignURCompanyOptions,
  ...CompanyComplianceConstants.foreignCompanyOptions
];

export const TRUST_COMPLIANCE_LIST = [
  ...TrustComplianceConstants.generalTrustOptions,
  ...TrustComplianceConstants.otherTrusteeOptions,
  ...TrustComplianceConstants.companyTrustee,
  ...TrustComplianceConstants.individualTrustee
];

export const completeCompliance = (selectedValues, props, isEdd, date, complianceStatus) => {

  const complianceConstants = [
    ...[...INDIVIDUAL_COMPLIANCE_LIST],
    ...[...COMPANY_COMPLIANCE_LIST],
    ...[...TRUST_COMPLIANCE_LIST]
  ];

  const result = {};
  complianceConstants.map(item => item.value).forEach(item => {
    result[item] = selectedValues.checkBoxOptions.includes(item);
  });

  result['note'] = selectedValues.note;
  result['compliance_type'] = selectedValues.complianceType;
  result['required_edd'] = isEdd;

  if (complianceStatus === 'VERIFIED') {
    result['verified_by'] = props.app_state.current_staff.id;
    result['verified_date'] = date == null ? new Date() : date;
  } else if (complianceStatus === 'COMPLETED') {
    result['completed_by'] = props.app_state.current_staff.id;
    result['completed_date'] = date == null ? new Date() : date;
  }
  result['client_id'] = props.client_detail_id;

  return result;
}

export const ComplianceService = {

  createCompliance: (data) => API.post('client-compliance', '/add-new', {body: data}),
  fetchCompliance: (data) => API.post('client-compliance', '/fetch', {body: data})
}

export const ComplianceUtil = {
  accountTypeCheck: (appState) => {
    return appState && (appState.account_type === 1 || (appState.account_type === 2 && appState.account_business_type === 1)
        || (appState.account_business_type == null)) ? 'INDIVIDUAL' : (
        appState.account_type === 2 &&
        appState.account_business_type === 2 ? 'COMPANY' : 'TRUST'
      );
  }
}
