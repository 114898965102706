import {
  BENEFICIARIES_SELECT_SET_ID,
  BENEFICIARIES_SELECT_UI,
  BENEFICIARY_CREATE_NEW_START,
  BENEFICIARY_CREATE_NEW_CANCEL,
  BENEFICIARY_CREATE_NEW_COMPLETE,
  BENEFICIARIES_FETCH_BENEFICIARY_REQUEST,
  BENEFICIARIES_FETCH_BENEFICIARY_SUCCESS,
  BENEFICIARIES_FETCH_BENEFICIARY_LIST_REQUEST,
  BENEFICIARIES_FETCH_BENEFICIARY_LIST_SUCCESS,
  BENEFICIARIES_START_NEW_BENEFICIARY_CREATION,
  BENEFICIARIES_UPDATE_NEW_BENEFICIARY_CREATION,
  BENEFICIARIES_NEW_BENEFICIARY_DATABASE_CREATE_SUCCESS
} from '../actions/beneficiaries.js';

const initialState = {
  beneficiaries_current_ui: 'list',
  is_fetching: false,
  // did_invalidate: false,
  beneficiary_list: [],
  beneficiary_list_lastUpdated: [],
  beneficiary_detail_fetching: false,
  beneficiary_detail_loaded: false,
  beneficiary_detail_id: null,
  new_beneficiary_data: null,
  new_beneficiary_submitted: false,
  new_beneficiary_created: false
};

function clients(state = initialState, action) {
  switch (action.type) {
    case BENEFICIARIES_FETCH_BENEFICIARY_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
        // console.log(action);
      });

    case BENEFICIARIES_FETCH_BENEFICIARY_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        beneficiary_list: action.beneficiary_list,
        beneficiary_list_lastUpdated: action.receivedAt
      });

    case BENEFICIARY_CREATE_NEW_START:
      return Object.assign({}, state, {
        beneficiaries_create_new_in_process: true
      });

    case BENEFICIARY_CREATE_NEW_CANCEL:
      return Object.assign({}, state, {
        beneficiaries_create_new_in_process: false,
        beneficiaries_current_ui: 'list'
      });

    case BENEFICIARY_CREATE_NEW_COMPLETE:
      return Object.assign({}, state, {
        beneficiaries_create_new_in_process: false,
        beneficiaries_current_ui: 'list',
        beneficiaries_list_is_dirty: true
      });

    case BENEFICIARIES_SELECT_UI:
      return Object.assign({}, state, {
        beneficiaries_current_ui: action.value
      });

    case BENEFICIARIES_SELECT_SET_ID:
      return Object.assign({}, state, {
        beneficiary_detail_id: action.value
      });

    case BENEFICIARIES_FETCH_BENEFICIARY_REQUEST:
      return Object.assign({}, state, {
        beneficiary_detail_fetching: true,
        beneficiary_detail_loaded: false
      });

    case BENEFICIARIES_FETCH_BENEFICIARY_SUCCESS:
      return Object.assign({}, state, {
        beneficiary_detail_fetching: false,
        beneficiary_detail_loaded: true
      });

    case BENEFICIARIES_START_NEW_BENEFICIARY_CREATION:
      return Object.assign({}, state, {
        // staff_detail: action.staff_detail,
        new_beneficiary_data: {
          nickname: ''
          // last_name: "Mouse",
          // email: "micky@pimwa.com"
        }
        // new_beneficiary_type: "customer_type_personal"
      });

    case BENEFICIARIES_UPDATE_NEW_BENEFICIARY_CREATION:
      return Object.assign({}, state, {
        new_beneficiary_data: Object.assign({}, state.new_beneficiary_data, {
          [action.value.key]: action.value.value
        })
      });

    case BENEFICIARIES_NEW_BENEFICIARY_DATABASE_CREATE_SUCCESS:
      return Object.assign({}, state, {
        new_beneficiary_submitted: false,
        new_beneficiary_created: true,
        beneficiary_detail_id: action.value
      });

    default:
      return state;
  }
}

export default clients;
