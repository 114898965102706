import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {fetchStaffListNotDeleted, loadStaffMemberDetails, selectStaffUi, setStaffDetailId} from 'redux/actions/staff';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import staffStyles from "./StaffStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';
// import StaffTableContainer from '../../../components/Staff/StaffTableContainer.js';
import StaffTableContainer from '../../../components/Staff/StaffTable';
// import MoreVert from "../../../components/Header/Header";
import Button from '../../../components/CustomButtons/Button';

import {FormattedMessage} from 'react-intl';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
const staffStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class StaffList extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const {classes} = this.props;
    // console.log(this.props);

    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id='staffList.cardItem1.staffListing' defaultMessage={`Staff Management Functions`} />
              </h4>
              <div>
                <Button
                  round
                  color={this.props.staff.staff_current_ui === 'list' ? 'primary' : 'info'}
                  onClick={() => this.props.selectStaffUi('list')}
                >
                  <FormattedMessage id='newStaff.cardItem1.showAll' defaultMessage={`Show All`} />
                </Button>
                <Button
                  disabled={
                    !(this.props.app_state.current_staff && this.props.app_state.current_staff.security_team_admin)
                  }
                  round
                  color={this.props.staff.staff_current_ui === 'create' ? 'primary' : 'info'}
                  // onClick={() => this.props.selectStaffUi("create")}
                  onClick={() => this.props.history.push('/settings/staffnew')}
                >
                  <FormattedMessage id='newStaff.cardItem1.createNew' defaultMessage={`Create New`} />
                </Button>
                {/*{this.props.staff.staff_current_ui === "edit" && (*/}
                {/*  <Button*/}
                {/*    round*/}
                {/*    color={*/}
                {/*      this.props.staff.staff_current_ui === "edit"*/}
                {/*        ? "primary"*/}
                {/*        : "info"*/}
                {/*    }*/}
                {/*    // onClick={() => this.props.selectStaffUi("edit")}*/}
                {/*    onClick={() => this.props.history.push("/settings/staffedit")}*/}
                {/*  >*/}
                {/*    Edit Record*/}
                {/*  </Button>*/}
                {/*)}*/}
                <Button round color={'info'} onClick={() => this.props.fetchStaffList()}>
                  <FormattedMessage id='newStaff.cardItem1.refresh' defaultMessage={`Refresh`} />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                {/*{this.selectUi()}*/}
                <StaffTableContainer />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

StaffList.propTypes = {
  classes: PropTypes.object.isRequired,
  selectStaffUi: PropTypes.func.isRequired
};

// export default withStyles(staffStyles)(Staff);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    staff: state.staff
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectStaffUi: value => {
      dispatch(selectStaffUi(value));
    },
    setStaffDetailId: aws_cognito_id => {
      dispatch(setStaffDetailId(aws_cognito_id));
    },
    fetchStaffList: () => {
      dispatch(fetchStaffListNotDeleted());
    },
    loadStaffMemberDetails: aws_cognito_id => {
      // console.log(data)
      dispatch(loadStaffMemberDetails(aws_cognito_id));
    }
  };
};

const StaffListContainer = connect(mapStateToProps, mapDispatchToProps)(withStyles(staffStyles)(StaffList));

export default StaffListContainer;
