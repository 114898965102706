import {connect} from 'react-redux';
import GroupNew from './GroupNew';
import {selectEntityUi} from '../../../redux/actions/groups';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    groups: state.groups
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectEntityUi: value => {
      dispatch(selectEntityUi(value));
    }
  };
};

const GroupNewContainer = connect(mapStateToProps, mapDispatchToProps)(GroupNew);

export default GroupNewContainer;
