import React, {Fragment, useState} from 'react';

import {Box, Grid, TextField, Typography, makeStyles} from '@material-ui/core';

import Button from '../../../components/CustomButtons/Button.jsx';
import {API} from 'aws-amplify';
import {DropzoneArea} from 'material-ui-dropzone';
import {s3Upload} from 'libs/awsLib.js';

const useStyles = makeStyles(() => ({
  createFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    maxWidth: '360px',
    margin: '16px 0'
  },
  submitButton: {
    float: 'right'
  }
}));

const TransferDocumentsNew = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [transferId, setTransferId] = useState('');
  const [description, setDescription] = useState('');

  const [awsFileId, setAwsFileId] = useState('');

  const handleSubmit = () => {
    const transferDocumentData = {
      transferId,
      description,
      file_ref: awsFileId
    };

    setLoading(true);

    API.post('transfer-documents', '/create', {body: transferDocumentData})
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
        API.post('transfer-documents', '/create', {body: transferDocumentData})
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDropzoneFileChange = async files => {
    const fileId = files && files[0] ? await s3Upload(files[0]) : null;

    if (fileId) {
      setAwsFileId(fileId);
    }
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Box className={classes.createFields}>
            <TextField
              fullWidth
              label='Transfer Id'
              value={transferId}
              onChange={e => setTransferId(e.target.value)}
              size='small'
              autoComplete='no'
            />
            <TextField
              fullWidth
              label='Description'
              value={description}
              onChange={e => setDescription(e.target.value)}
              size='small'
              autoComplete='no'
              multiline
              minRows={3}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={6}>
          <Typography color='textSecondary'>Upload Document</Typography>
          <DropzoneArea
            filesLimit={1}
            acceptedFiles={['image/*', 'application/pdf']}
            onChange={handleDropzoneFileChange}
            showAlerts={false}
          />
        </Grid>
      </Grid>
      <Button
        className={classes.submitButton}
        disabled={loading || !awsFileId || !transferId}
        onClick={handleSubmit}
        type='submit'
        color='primary'
      >
        {loading ? 'Creating...' : 'Create'}
      </Button>
    </Fragment>
  );
};

export default TransferDocumentsNew;
