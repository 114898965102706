import {
  LANGUAGE_FETCH_LANGUAGE_LIST_REQUEST,
  LANGUAGE_FETCH_LANGUAGE_LIST_SUCCESS,
  LANGUAGE_SELECT_UI,
  LANGUAGE_SELECT_SET_ID
} from '../actions/language.js';

const initialState = {
  language_current_ui: 'en',
  //is_fetching: false,
  //did_invalidate: false,
  language_list: ['en', 'de', 'es', 'cn', 'sg']
  // news_detail_fetching: false,
  // news_detail_loaded: false,
  // news_detail_id: null,
  // new_news_data: {},
  // new_news_type: null,
  // new_news_submitted: false,
  // new_news_created: false
};

function language(state = initialState, action) {
  switch (action.type) {
    case LANGUAGE_FETCH_LANGUAGE_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
      });

    case LANGUAGE_FETCH_LANGUAGE_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        language_list: action.language_list,
        lastUpdated: action.receivedAt
      });

    case LANGUAGE_SELECT_UI:
      return Object.assign({}, state, {
        language_current_ui: action.value
      });

    case LANGUAGE_SELECT_SET_ID:
      return Object.assign({}, state, {
        language_detail_id: action.value
      });

    default:
      return state;
  }
}

export default language;
