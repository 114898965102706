import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {
  //   updateClient,
  loadIdentificationRecordDetailsRequest,
  loadIdentificationRecordDetailsSuccess
  //   createCognitoAccountForClient,
} from '../../redux/actions/identification';

import {withRouter} from 'react-router-dom';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import defaultPdf from 'assets/img/preview_pdf.jpg'

// core components
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
import Card from '../Card/Card.jsx';
import CardBody from '../Card/CardBody.jsx';

import CardHeader from '../../components/Card/CardHeader';
import CustomInput from '../CustomInput/CustomInput';
import {API, Storage} from 'aws-amplify';

import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import Button from '../CustomButtons/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {Col, Image, Row, Modal, Button as AntButton, Flex} from 'antd';
import {IdentificationAPI} from './IdentificationAPI';
import {AppUrlConstants} from '../../AppAPI';
import {IdentificationUtil} from './IdentificationConstants';
import {CloseOutlined, EyeOutlined} from "@ant-design/icons";
import FileUploader from "../Uploaders/FileUploader";

require('react-datetime');

const moment = require('moment');
const staffEditStyles = {
  ...switchStyles,
  ...selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  figure: {
    padding: '5px',
    border: '1px solid #ccc'
  },
  attachmentThumb: {
    width: '100%',
    cursor: 'pointer',
    border: '1px solid #ccc',
    padding: '5px',
 }
 }


class IdentificationEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      is_loading: true,
      country_list: [],
      country_list_prio: [],
      identification_status_list: [],
    };
  }
  handleSelectChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  componentDidMount = async () => {
    if (this.props.identification_record_id) {
      this.fetchIdentificationRecord(this.props.identification_record_id);

      AppUrlConstants.fetchCountries()
        .then(response => this.setState({country_list_prio: response}))
        .catch(error => console.log(error));

      IdentificationAPI.fetchIdentificationStatus()
        .then(response => this.setState({identification_status_list: response}))
        .catch(error => console.log(error));
    }
  }

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    if (prevProps.client_detail_id !== this.props.client_detail_id) {
      this.fetchIdentificationRecord(this.props.identification_record_id);
    }
  };
  
  fetchIdentificationRecord = (identificationId) => {
    this.props.loadIdentificationRecordDetailsRequest();
    IdentificationAPI.fetchIdentificationRecordById(identificationId)
      .then(async identification_record => {
        identification_record = await IdentificationUtil.fetchS3Url(identification_record);
        this.setState(identification_record);
      }).catch(error => console.log(error));
  }

  toggleEdit = () => {
    const edit_mode = !this.state.edit_mode;
    this.setState({edit_mode: edit_mode});
  };

  handleChange = event => this.setState({[event.target.id]: event.target.value});

  async updateIdentification(identification_detail) {
    return API.put('identification', `/update/id/${this.props.identification_record_id}`, {
      body: identification_detail
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    const {
      id,
      nickname,
      client_id,
      client,
      document_category_id,
      document_category,
      document_expiry,
      document_number,
      document_issue_state,
      document_issue_country,
      file_id,
      file_id_back,
      record_created_datetime,
      record_created_staff_id,
      record_created_staff_first_name,
      record_created_staff_last_name,
      document_detail,
      id_status,
      id_notes_staff,
      id_notes_client,
    } = this.state;
    try {
      await this.updateIdentification({
        id: id,
        nickname: nickname,
        client_id: client_id,
        client: client,
        document_category_id: document_category_id,
        document_category: document_category,
        document_expiry: document_expiry,
        document_number: document_number,
        document_issue_state: document_issue_state,
        document_issue_country: document_issue_country,
        file_id: file_id,
        file_id_back: file_id_back,
        record_created_datetime: record_created_datetime,
        record_created_staff_id: record_created_staff_id,
        record_created_staff_first_name: record_created_staff_first_name,
        record_created_staff_last_name: record_created_staff_last_name,
        document_detail: document_detail,
        id_status: id_status,
        id_notes_staff: id_notes_staff,
        id_notes_client: id_notes_client,
      });
    } catch (e) {
      // this.setState({ isLoading: false });
    }
  };

  render() {
    const {classes} = this.props;
    if (!this.state.loaded) {
      return null;
    }

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className={classes.flexEnd}>
            <div />
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.edit_mode}
                  onChange={() => this.toggleEdit()}
                  value='edit_mode'
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked
                    // icon: classes.switchIcon,
                    // iconChecked: classes.switchIconChecked,
                    // bar: classes.switchBar
                  }}
                />
              }
              classes={{
                label: classes.label
              }}
              label={this.state.edit_mode ? 'Edit Mode' : 'Read Only'}
            />
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color='rose' icon>
                  <h4 className={classes.cardIconTitle}>Identification Document Details</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText='Document nickname'
                    id='nickname'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: !this.state.edit_mode,
                      value: this.state.nickname || '',
                      onChange: event => {
                        this.handleChange(event);
                      }
                    }}
                  />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Client'
                        id='client'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: !this.state.edit_mode,
                          value: this.state.client || ''
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Document category'
                        id='document_category'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: !this.state.edit_mode,
                          value: this.state.document_category || ''
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Document number'
                        id='document_number'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: !this.state.edit_mode,
                          value: this.state.document_number || '',
                          onChange: event => {
                            this.handleChange(event);
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Document expiry'
                        id='document_expiry'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: !this.state.edit_mode,
                          value: moment(this.state.document_expiry).format('DD/MM/YYYY') || ''
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Document issue state'
                        id='document_issue_state'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: !this.state.edit_mode,
                          value: this.state.document_issue_state || '',
                          onChange: event => {
                            this.handleChange(event);
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      {/* <CustomInput
                      labelText="Document issue country"
                      id="document_issue_country"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !this.state.edit_mode,
                        value: this.state.document_issue_country || ""
                      }}
                    /> */}
                      <FormControl
                        fullWidth
                        error={!this.state.document_issue_country > 0}
                        className={classes.selectFormControl}
                      >
                        <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                          Document issue country
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.document_issue_country || '0'}
                          onChange={this.handleSelectChange}
                          inputProps={{
                            disabled: !this.state.edit_mode,
                            name: 'document_issue_country',
                            id: 'document_issue_country'
                          }}
                        >
                          <MenuItem
                            key='select_document_issue_country_key'
                            value='0'
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            Select Document Issue Country
                          </MenuItem>

                          {this.state.country_list_prio
                            .sort((a, b) => a.list_priority < b.list_priority)
                            .map(item => {
                              return (
                                <MenuItem
                                  key={item.id}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={item.id}
                                >
                                  {item.full_name}
                                </MenuItem>
                              );
                            })}
                          {/*<MenuItem*/}
                          {/*  key="0x0"*/}
                          {/*  value="0"*/}
                          {/*  disabled*/}
                          {/*  classes={{*/}
                          {/*    root: classes.selectMenuItem*/}
                          {/*  }}*/}
                          {/*>*/}
                          {/*  --------------------------------------------------*/}
                          {/*</MenuItem>*/}
                          {/*{this.state.country_list*/}
                          {/*  .sort((a, b) => a.full_name.localeCompare(b.full_name))*/}
                          {/*  .map(item => {*/}
                          {/*    return (*/}
                          {/*      <MenuItem*/}
                          {/*        key={item.id}*/}
                          {/*        classes={{*/}
                          {/*          root: classes.selectMenuItem,*/}
                          {/*          selected: classes.selectMenuItemSelected*/}
                          {/*        }}*/}
                          {/*        value={item.id}*/}
                          {/*      >*/}
                          {/*        {item.full_name}*/}
                          {/*      </MenuItem>*/}
                          {/*    );*/}
                          {/*  })}*/}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <CustomInput
                    labelText='Document detail'
                    id='document_detail'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: !this.state.edit_mode,
                      value: this.state.document_detail || '',
                      onChange: event => {
                        this.handleChange(event);
                      }
                    }}
                  />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Record created'
                        id='record_created_datetime'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: !this.state.edit_mode,
                          value: moment(this.state.record_created_datetime).format('DD/MM/YYYY HH:mm:ss') || ''
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Record created by'
                        id='record_created_staff_last_name'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: !this.state.edit_mode,
                          value:
                            `${this.state.record_created_staff_last_name}, ${this.state.record_created_staff_first_name}` ||
                            ''
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color='rose' icon>
                  <div className={classes.flexEnd}>
                    <h4 className={classes.cardIconTitle}>Status and Notes</h4>
                  </div>
                </CardHeader>
                <CardBody>
                  <FormControl
                    fullWidth
                    error={!this.state.document_category_id > 0}
                    className={classes.selectFormControl}
                  >
                    <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                      Status
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={this.state.id_status}
                      onChange={this.handleSelectChange}
                      inputProps={{
                        disabled: !this.state.edit_mode,
                        name: 'id_status',
                        id: 'id_status'
                      }}
                    >
                      <MenuItem
                        key='0x0'
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Select document category
                      </MenuItem>
                      {this.state.identification_status_list
                        .sort((a, b) => a.nickname.localeCompare(b.nickname))
                        .map(item => {
                          return (
                            <MenuItem
                              key={item.id}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={item.id}
                            >
                              {item.nickname.toUpperCase()}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>

                  <CustomInput
                    labelText='Staff Notes'
                    id='id_notes_staff'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: !this.state.edit_mode,
                      value: this.state.id_notes_staff || '',
                      onChange: event => {
                        this.handleChange(event);
                      }
                    }}
                  />
                  <CustomInput
                    labelText='Client Notes'
                    id='id_notes_client'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: !this.state.edit_mode,
                      value: this.state.id_notes_client || '',
                      onChange: event => {
                        this.handleChange(event);
                      }
                    }}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardHeader color='rose' icon>
                  <div className={classes.flexEnd}>
                    <h4 className={classes.cardIconTitle}>Attached Documents</h4>
                  </div>
                </CardHeader>
                <CardBody>
                  {this.state.attachments && this.state.attachments.length > 0 ? (
                    <Row gutter={[16, 16]}>
                      {
                        this.state.attachments.map(item => {
                          return <Col span={8}>
                            <FileUploader uploaderType={'PDF'}
                                          thumbType={'picture-card'}
                                          onRemove={(file) => Promise.resolve(file)}
                                          fetchFileList={ async () => {
                              const file = {
                                name: item,
                                status: 'done',
                                uid: item,
                                type: 'application/pdf'
                              };
                              return Promise.resolve([file])
                            }}></FileUploader>
                          </Col>
                        })
                      }
                    </Row>
                  ) : <span><strong style={{textAlign: 'center'}}>No Other Images Found</strong></span>}
                </CardBody>
              </Card>
              {this.state.file_id && (
              <Card>
                <CardHeader color='rose' icon>
                  <div className={classes.flexEnd}>
                    <h4 className={classes.cardIconTitle}>Legacy - Image Front</h4>
                  </div>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText='File ID - Image Front'
                    id='file_id'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: !this.state.edit_mode,
                      value: this.state.file_id || ''
                    }}
                  />
                    <React.Fragment>
                      <div className='fileinput text-center'>
                        <div className={'thumbnail'}>
                          <img src={this.state.file_url.fileType === 'pdf' ? defaultPdf : this.state.file_url.url} alt='...' />
                        </div>
                      </div>
                      <br />
                      <a href={this.state.file_url.url} target='_blank' rel="noreferrer">
                        Open image in new tab
                      </a>
                      <br />
                      <strong>Note: this link will expire in 60 seconds</strong>
                    </React.Fragment>
                </CardBody>
              </Card>
              )}
              {this.state.file_id_back && (
              <Card>
                <CardHeader color='rose' icon>
                  <div className={classes.flexEnd}>
                    <h4 className={classes.cardIconTitle}>Legacy - Image Back</h4>
                  </div>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText='File ID - Image Back'
                    id='file_id_back'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: !this.state.edit_mode,
                      value: this.state.file_id_back || ''
                    }}
                  />
                    <React.Fragment>
                      <div className='fileinput text-center'>
                        <div className={'thumbnail'}>
                          <img src={this.state.file_url_back.url} alt='...' />
                        </div>
                      </div>
                      <br />
                      <a href={this.state.file_url_back.url} target='_blank' rel="noreferrer">
                        Open image in new tab
                      </a>
                      <br />
                      <strong>Note: this link will expire in 60 seconds</strong>
                    </React.Fragment>
                </CardBody>
              </Card>
              )}
            </GridItem>
          </GridContainer>
          <Button
            color='primary'
            disabled={!this.state.edit_mode}
            type='submit'
            className={classes.updateProfileButton}
          >
            Update Identification
          </Button>
        </form>
      </div>
    );
  }
}

// IdentificationEdit.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withRouter(withStyles(staffEditStyles)(IdentificationEdit));
IdentificationEdit = withRouter(withStyles(staffEditStyles)(IdentificationEdit));

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    identification: state.identification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadIdentificationRecordDetailsRequest: () => {
      dispatch(loadIdentificationRecordDetailsRequest());
    },
    loadIdentificationRecordDetailsSuccess: () => {
      dispatch(loadIdentificationRecordDetailsSuccess());
    }
    // createCognitoAccountForClient: (id, username, password) => {
    //   dispatch(createCognitoAccountForClient(id, username, password))
    // },
    // updateClient: (data, id) => {
    //   dispatch(updateClient(data, id))
    // }
  };
};

const IdentificationEditContainer = connect(mapStateToProps, mapDispatchToProps)(IdentificationEdit);

export default IdentificationEditContainer;
