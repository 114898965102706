import React, {useState, useEffect} from 'react';
import ReactTable from 'react-table';
import {useHistory} from 'react-router-dom';
import {API} from 'aws-amplify';
import Button from 'components/CustomButtons/Button.jsx';
import matchSorter from 'match-sorter';
import {AiOutlineSearch} from 'react-icons/ai';
const TeamHoldingAccount = props => {
  const [holdingAccount, SetHoldingAccount] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    API.get('holding_accounts', '/get/all')
      .then(response => {
        console.log('response', response.holding_account_list);
        SetHoldingAccount(response.holding_account_list);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const buildTableData = () => {
    const holding_account_list_length = holdingAccount.length;
    if (holding_account_list_length > 0) {
      const accounts = [];
      holdingAccount.map((prop, key) => {
        if (prop.team_id) {
          accounts.push({
            id: prop.id,
            client_id: prop.client_id,
            client_nickname: prop.client_nickname,
            currency_id: prop.currency_id,
            currencies_short_name: prop.currencies_short_name,
            currencies_full_name: prop.currencies_full_name,
            balance: prop.balance,
            teams_nickname: prop.teams_nickname,
            actions: (
              <div className='actions-right'>
                <Button
                  onClick={() => {
                    history.push(`/holding-accounts/view/${prop.id}`);
                  }}
                  color='warning'
                  className='edit'
                >
                  View
                </Button>
              </div>
            )
          });
        }
      });
      return accounts;
    }
  };

  const getFilter = (filter, onChange) => {
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AiOutlineSearch />
          <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
        </div>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      {loading ? (
        <div />
      ) : (
        <ReactTable
          data={buildTableData()}
          filterable
          columns={[
            {
              Header: 'Client ',
              Filter: ({filter, onChange}) => getFilter(filter, onChange),
              accessor: 'client_nickname',
              maxWidth: 200,
              show: !props.client_id,
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['client_nickname']}),
              filterAll: true,
              Cell: c => <React.Fragment>{c.value}</React.Fragment>
            },
            {
              Header: 'Team ',
              Filter: ({filter, onChange}) => getFilter(filter, onChange),
              accessor: 'teams_nickname',
              maxWidth: 200,
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['teams_nickname']}),
              filterAll: true,
              Cell: c => <React.Fragment>{c.value}</React.Fragment>
            },
            {
              Header: 'Currency ',
              Filter: ({filter, onChange}) => getFilter(filter, onChange),
              accessor: 'currencies_short_name',
              maxWidth: 100,
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['currencies_short_name']}),
              filterAll: true,
              Cell: c => (
                <React.Fragment>
                  <div style={{marginLeft: 6}} className={`currency-flag currency-flag-${c.value.toLowerCase()}`} />{' '}
                  {c.value}
                </React.Fragment>
              )
            },
            {
              Header: '',
              Filter: ({filter, onChange}) => getFilter(filter, onChange),
              accessor: 'currencies_full_name',
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['currencies_full_name']}),
              filterAll: true
            },
            {
              Header: 'Balance ',
              Filter: ({filter, onChange}) => getFilter(filter, onChange),
              accessor: 'balance',
              maxWidth: 100,
              Cell: c => {
                if (c.value) {
                  return (
                    <React.Fragment>
                      {new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: c.original.currencies_short_name
                      }).format(c.value)}
                    </React.Fragment>
                  );
                }
              }
            },
            {
              Header: 'Actions',
              Filter: ({filter, onChange}) => getFilter(filter, onChange),
              accessor: 'actions',
              sortable: false,
              filterable: false,
              maxWidth: 100
            }
          ]}
          defaultSorted={[
            {
              id: 'client_name',
              desc: false
            },
            {
              id: 'document_category',
              desc: false
            }
          ]}
          defaultPageSize={10}
          showPaginationBottom
          className='-highlight'
        />
      )}
    </React.Fragment>
  );
};

export default TeamHoldingAccount;
