import React from 'react';
import {connect} from 'react-redux';
import {fetchTransferPendingList} from '../../redux/actions/transfers_pending';


import {API, Storage} from 'aws-amplify';

import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import queryString from 'query-string';
import 'react-dates/initialize';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import ListTable from '../ListTable/ListTable'
import {Button, Flex, message, Modal, Select, Table, Tag, Tooltip} from 'antd'
import {
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    EyeOutlined,
    MailOutlined,
    MessageOutlined
} from "@ant-design/icons";
import {isEmpty} from "../../libs/miscFunc";
import FormItem from "antd/lib/form/FormItem";

var moment = require('moment');

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - TransferList - Components for list all the transfer from this user
 * ----------------------------------------------------------------------------
 */
class TransferListPending extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entity_list: [],
            dialog_delete_confirm: false,
            client_id: '',
            startDate: moment().subtract(6, 'month'),
            endDate: moment().add(1, 'month'),
            transferList: [],
            allPendingTransfers: [],
            groupList: []
        };
    }

    componentDidMount() {
        // this.props.fetchTransferPendingList();
        this.fetchTransferList();
        this.queryStrings();
        const columns = this.buildTableColumns();
        this.setState({columns: columns});
        if (this.props.app_state.current_staff) {
            this.fetchGroupList();
        }
    }

    fetchGroupList = () => {
        API.post("commons", "/fetch", {
            body: {
                context: 'groups',
                fields: ['*'],
                condition: {team_id: this.props.app_state.current_team.id}
            }
        }).then(async res => {
            const group_clients = await API.post("commons", "/fetch", {
                body: {
                    context: 'group_client_cross',
                    fields: ['*'],
                    condition: {group_id: res.map(i => i.id)}
                }
            });

            res = res.map(item => ({
                ...item,
                clients: group_clients.filter(i => i.group_id === item.id)
            }));

            this.setState({groupList: res.map(i => ({...i, label: i.nickname, value: i.id}))});
        }).catch(err => {
            console.log(err);
        });
    }

    buildTableColumns = () => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                sorter: {compare: (a, b) => a.id - b.id},
                ...this.props.getColumnSearchProps({
                    dataIndex: 'id',
                    label: 'ID',
                    filterInputType: 'INPUT',
                    render: (text, record) => {
                        return (
                            <>
                                <Tag
                                    color={record.status_id === 1 ? 'orange' : (record.status_id === 2 ? 'blue' : 'green')}>
                                    <span>ID - {record.id}</span>
                                </Tag>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Client',
                dataIndex: 'client',
                key: 'client',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'client',
                    label: 'client',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{record.client}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Team',
                dataIndex: 'team_nickname',
                key: 'team_nickname',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'team_nickname',
                    label: 'team_nickname',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{record.team_nickname}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Record Created',
                dataIndex: 'record_created',
                key: 'record_created',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'record_created',
                    label: 'Record Created',
                    filterInputType: 'DATE',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{record.record_created}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Expiry Date',
                dataIndex: 'due_date',
                key: 'due_date',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'due_date',
                    label: 'Due Date',
                    filterInputType: 'DATE',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{record.due_date}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Currency',
                dataIndex: 'currency_to',
                key: 'currency_to',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'currency_to',
                    label: 'Currency',
                    render: (text, record) => {
                        return (
                            <>
                                <div className={`currency-flag currency-flag-${
                                    record.currency_to ? record.currency_to.toLowerCase() : ''}`}/>
                                &nbsp;
                                {record.currency_to ? record.currency_to.toUpperCase() : ''}
                            </>
                        );
                    }
                })
            },
            {
                title: 'Amount',
                dataIndex: 'amount_to',
                key: 'amount_to',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'amount_to',
                    label: 'Amount',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{record.amount_to.toFixed(2)}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Status ',
                dataIndex: 'transfer_status',
                key: 'transfer_status',
                filters: [
                    {text: 'Read', value: 'Read'},
                    {text: 'Pending', value: 'Pending'},
                    {text: 'Transfer Created', value: 'Transfer Created'},
                    {text: 'Completed', value: 'Completed'},
                ],
                onFilter: (value, record) => record.transfer_status.indexOf(value) === 0,
            },
            {
                title: 'Actions ',
                dataIndex: 'actions',
                key: 'actions'
            },
        ];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
            console.log(this.props.app_state.current_staff)
            // this.props.fetchStaffList()
            this.fetchTransferList();
        }
        if (prevProps.app_state.current_team != this.props.app_state.current_team) {
            this.fetchGroupList();
        }
    }

    fetchTransferList = () => {
        this.setState({transferList: [], allPendingTransfers: []});
        if (this.props.app_state.current_staff) {
            const staffID = this.props.app_state.current_staff.id

            API.get('transfers_pending', `/list/staff/${staffID}`)
                .then(response => {
                    this.setState({transferList: response, allPendingTransfers: response});
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    sendNewPaymentRequestCreated = (transferID, staffOnly) => {
        console.log('pendingEmail: ', transferID)
        API.post('email', `/client/payment-request-existing`, {
            body: {
                transferID: transferID,
                currentStaffID: this.props.app_state.current_staff.id,
                staffOnly
            }
        })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    }

    sendPendingTransferCompleteEmail = (transferID, staffOnly) => {
        console.log('pendingEmail: ', transferID)
        API.post('email', `/client/pending-transfer-completed`, {
            body: {
                transferID: transferID,
                currentStaffID: this.props.app_state.current_staff.id,
                staffOnly
            }
        })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    }

    // buildTableData() {

    //   let transfer_pending_list = this.props.transfers_pending.transfer_pending_list;
    //   console.log(transfer_pending_list);
    //   // transfer_pending_list = transfer_pending_list.filter((item) =>  !(item.invoice));
    //   // console.log(transfer_pending_list)
    //   console.log(this.state.client_id);

    //   if (this.state.client_id == '') {
    //     let date_range_transfer_list = transfer_pending_list;
    //     if (this.state.startDate && this.state.endDate) {
    //       date_range_transfer_list = transfer_pending_list.filter(transfer => {
    //         return (
    //           this.state.startDate.isBefore(moment(transfer.transaction_datetime).add(1, 'day')) &&
    //           this.state.endDate.isAfter(moment(transfer.transaction_datetime))
    //         );
    //       });
    //     }

    //     if (typeof this.props.status === 'string') {
    //       console.log(this.props.status);
    //       if (this.props.status === 'past') {
    //         date_range_transfer_list = transfer_pending_list.filter(transfer => {
    //           return transfer.status_id === 8 || transfer.status_id === 10;
    //         });


    //         var transfer_list_length = date_range_transfer_list.length;
    //         if (transfer_list_length > 0) {
    //           return date_range_transfer_list.map((prop, key) => {
    //             return {
    //               id: prop.id,
    //               client: prop.client_nickname,
    //               transaction_date: prop.transaction_datetime,
    //               status_id: prop.status_id,
    //               memo: prop.memo,
    //               nickname: prop.nickname,
    //               settlement_date: prop.settlement_date,
    //               beneficiary: prop.beneficiaries_nickname,
    //               amount_from: prop.amount_from,
    //               amount_to: prop.amount_to,
    //               currency_from: prop.currency_from_iso_alpha_3,
    //               currency_to: prop.currency_to_iso_alpha_3,
    //               client_rate: prop.client_rate ? prop.client_rate.toFixed(5) : '',
    //               transfer_status: prop.transfer_status,
    //               actions: (
    //                 <div className='actions-right'>
    //                   <Button type={'primary'}
    //                     onClick={() => {
    //                       this.props.history.push(`/transfers/payment-requests/edit/${prop.id}`);
    //                     }}
    //                     primary
    //                     className='edit'
    //                   >
    //                     <Dvr />
    //                   </Button>{' '}
    //                   {this.props.app_state.current_staff_super_admin && (
    //                     <Button type={'primary'}
    //                       onClick={() => {
    //                         // console.log(this); //disable debug messages #1054
    //                         const data = this.props.transfers.transfer_list;
    //                         // eslint-disable-next-line no-unused-vars
    //                         data.find((o, i) => {
    //                           if (o.id === prop.id) {
    //                             this.setState({
    //                               dialog_delete_confirm: true,
    //                               dialog_delete_item: prop.id,
    //                               dialog_delete_item_name: `${prop.nickname}`
    //                             });

    //                             return true;
    //                           }
    //                           return false;
    //                         });
    //                         this.setState({data: data});
    //                       }} danger
    //                       className='remove'
    //                     >
    //                       <Close />
    //                     </Button>
    //                   )}{' '}
    //                 </div>
    //               )
    //             };
    //           });
    //         } else {
    //           return (
    //             transfer_pending_list
    //               .filter(u => u.client_id == this.state.client_id)
    //               // eslint-disable-next-line no-unused-vars
    //               .map((prop, key) => {
    //                 return {
    //                   id: prop.id,
    //                   client: prop.client_nickname,
    //                   transaction_date: prop.transaction_datetime,
    //                   status_id: prop.status_id,
    //                   memo: prop.memo,
    //                   nickname: prop.nickname,
    //                   settlement_date: prop.settlement_date,
    //                   beneficiary: prop.beneficiaries_nickname,
    //                   currency_from: prop.currency_from_iso_alpha_3,
    //                   amount_from: prop.amount_from,
    //                   amount_to: prop.amount_to,
    //                   currency_to: prop.currency_to_iso_alpha_3,
    //                   client_rate: prop.client_rate.toFixed(5),
    //                   transfer_status: prop.transfer_status,
    //                   actions: (
    //                     <div className='actions-right'>
    //                       <Button
    //                         justIcon
    //                         round
    //                         simple
    //                         onClick={() => {
    //                           this.props.history.push(`/transfers/payment-requests/edit/${prop.id}`);
    //                         }}
    //                         color='warning'
    //                         className='edit'
    //                       >
    //                         <Dvr />
    //                       </Button>{' '}
    //                       {/* use this button to remove the data row */}
    //                       {this.props.app_state.current_staff_super_admin && (
    //                         <Button
    //                           justIcon
    //                           round
    //                           simple
    //                           onClick={() => {
    //                             var data = this.props.transfers.transfer_list;
    //                             data.find((o, i) => {
    //                               if (o.id === prop.id) {
    //                                 this.setState({
    //                                   dialog_delete_confirm: true,
    //                                   dialog_delete_item: prop.id,
    //                                   dialog_delete_item_name: `${prop.nickname}`
    //                                 });

    //                                 return true;
    //                               }
    //                               return false;
    //                             });
    //                             this.setState({data: data});
    //                           }}
    //                           color='danger'
    //                           className='remove'
    //                         >
    //                           <Close />
    //                         </Button>
    //                       )}{' '}
    //                     </div>
    //                   )
    //                 };
    //               })
    //           );
    //         }
    //         //end of code block
    //       } else if (this.props.status == 'pending') {

    //         //filter for current transfers
    //         console.log(date_range_transfer_list);
    //         date_range_transfer_list = transfer_pending_list.filter(transfer => {
    //           return transfer.status_id === 2;
    //           /*
    //            * return (transfer.transfer_status === "Draft"
    //            *   || transfer.transfer_status === "In Process"
    //            *   || transfer.transfer_status === "Acknowledged"
    //            *   || transfer.transfer_status === "Processing"
    //            *   || transfer.transfer_status === "Awaiting Funds"
    //            *   || transfer.transfer_status === "Funds Received"
    //            *   || transfer.transfer_status === "Payment Made"
    //            *   || transfer.transfer_status === "On Hold"
    //            * )
    //            */
    //         });
    //         console.log(date_range_transfer_list);
    //         //end of filter

    //         var transfer_list_length = date_range_transfer_list.length;
    //         if (transfer_list_length > 0) {
    //           return date_range_transfer_list.map((prop, key) => {
    //             return {
    //               id: prop.id,
    //               client: prop.client_nickname,
    //               record_created: prop.record_created == null ? '' : moment(prop.record_created).format('DD/MM/YYYY'),
    //               status_id: prop.status_id,
    //               invoice_date: prop.invoice_date == null ? '' : moment(prop.invoice_date).format('DD/MM/YYYY'),
    //               due_date: prop.expiryDate == null ? '' : moment(prop.expiryDate).format('DD/MM/YYYY'),
    //               amount_to: prop.amount_to,
    //               currency_to: prop.currency_to_iso_alpha_3,
    //               transfer_status: prop.transfer_status_name,
    //               actions: (
    //                 <div className='actions-right'>
    //                   <Button
    //                     justIcon
    //                     round
    //                     simple
    //                     onClick={() => {
    //                       this.props.history.push(`/invoices/edit/${prop.id}`);
    //                     }}
    //                     color='warning'
    //                     className='edit'
    //                   >
    //                     <Dvr />
    //                   </Button>{' '}
    //                   {this.props.app_state.current_staff_super_admin && (
    //                     <Button
    //                       justIcon
    //                       round
    //                       simple
    //                       onClick={() => {
    //                         // console.log(this); //disable debug messages #1054
    //                         var data = this.props.transfers.transfer_list;
    //                         data.find((o, i) => {
    //                           if (o.id === prop.id) {
    //                             this.setState({
    //                               dialog_delete_confirm: true,
    //                               dialog_delete_item: prop.id,
    //                               dialog_delete_item_name: `${prop.nickname}`
    //                             });

    //                             return true;
    //                           }
    //                           return false;
    //                         });
    //                         this.setState({data: data});
    //                       }}
    //                       color='danger'
    //                       className='remove'
    //                     >
    //                       <Close />
    //                     </Button>
    //                   )}{' '}
    //                 </div>
    //               )
    //             };
    //           });
    //         } else {
    //           return transfer_pending_list
    //             .filter(u => u.client_id == this.state.client_id)
    //             .map((prop, key) => {
    //               return {
    //                 id: prop.id,
    //                 client: prop.client_nickname,
    //                 transaction_date: prop.transaction_datetime,
    //                 status_id: prop.status_id,
    //                 memo: prop.memo,
    //                 nickname: prop.nickname,
    //                 settlement_date: prop.settlement_date,
    //                 beneficiary: prop.beneficiaries_nickname,
    //                 currency_from: prop.currency_from_iso_alpha_3,
    //                 amount_from: prop.amount_from,
    //                 amount_to: prop.amount_to,
    //                 currency_to: prop.currency_to_iso_alpha_3,
    //                 client_rate: prop.client_rate.toFixed(5),
    //                 transfer_status: prop.transfer_status,
    //                 actions: (
    //                   <div className='actions-right'>
    //                     <Button
    //                       justIcon
    //                       round
    //                       simple
    //                       onClick={() => {
    //                         this.props.history.push(`/transfers/payment-requests/edit/${prop.id}`);
    //                       }}
    //                       color='warning'
    //                       className='edit'
    //                     >
    //                       <Dvr />
    //                     </Button>{' '}
    //                     {/* use this button to remove the data row */}
    //                     {this.props.app_state.current_staff_super_admin && (
    //                       <Button
    //                         justIcon
    //                         round
    //                         simple
    //                         onClick={() => {
    //                           // console.log(this); //disable debug messages #1054
    //                           var data = this.props.transfers.transfer_list;
    //                           data.find((o, i) => {
    //                             if (o.id === prop.id) {
    //                               this.setState({
    //                                 dialog_delete_confirm: true,
    //                                 dialog_delete_item: prop.id,
    //                                 dialog_delete_item_name: `${prop.nickname}`
    //                               });

    //                               return true;
    //                             }
    //                             return false;
    //                           });
    //                           this.setState({data: data});
    //                         }}
    //                         color='danger'
    //                         className='remove'
    //                       >
    //                         <Close />
    //                       </Button>
    //                     )}{' '}
    //                   </div>
    //                 )
    //               };
    //             });
    //         }
    //         //end of code block
    //       }
    //     }
    //     //end of solutions

    //     var transfer_list_length = date_range_transfer_list.length;
    //     if (transfer_list_length > 0) {
    //       // return this.props.transfers.transfer_list.map((prop, key) => {
    //       return date_range_transfer_list.map((prop, key) => {
    //         // console.log(prop);
    //         return {
    //           id: prop.id,
    //           client: prop.client_nickname,
    //           transaction_date: prop.transaction_datetime,
    //           status_id: prop.status_id,
    //           memo: prop.memo,
    //           nickname: prop.nickname,
    //           settlement_date: prop.settlement_date,
    //           beneficiary: prop.beneficiaries_nickname,
    //           amount_from: prop.amount_from,
    //           amount_to: prop.amount_to,
    //           currency_from: prop.currency_from_iso_alpha_3,
    //           currency_to: prop.currency_to_iso_alpha_3,
    //           client_rate: prop.client_rate ? prop.client_rate.toFixed(5) : '',
    //           transfer_status: prop.transfer_status_name,
    //           actions: (
    //             <div className='actions-right'>
    //               <Button
    //                 justIcon
    //                 round
    //                 simple
    //                 onClick={() => {
    //                   this.props.history.push(`/transfers/payment-requests/edit/${prop.id}`);
    //                 }}
    //                 color='warning'
    //                 className='edit'
    //               >
    //                 <Dvr />
    //               </Button>{' '}
    //               {this.props.app_state.current_staff_super_admin && (
    //                 <Button
    //                   justIcon
    //                   round
    //                   simple
    //                   onClick={() => {
    //                     //console.log(this);
    //                     var data = this.props.transfers.transfer_list;
    //                     data.find((o, i) => {
    //                       if (o.id === prop.id) {
    //                         this.setState({
    //                           dialog_delete_confirm: true,
    //                           dialog_delete_item: prop.id,
    //                           dialog_delete_item_name: `${prop.nickname}`
    //                         });

    //                         return true;
    //                       }
    //                       return false;
    //                     });
    //                     this.setState({data: data});
    //                   }}
    //                   color='danger'
    //                   className='remove'
    //                 >
    //                   <Close />
    //                 </Button>
    //               )}{' '}
    //             </div>
    //           )
    //         };
    //       });
    //     } else {
    //       return transfer_pending_list
    //         .filter(u => u.client_id == this.state.client_id)
    //         .map((prop, key) => {
    //           // if(this.state.client_id == prop.client_id)
    //           return {
    //             id: prop.id,
    //             client: prop.client_nickname,
    //             transaction_date: prop.transaction_datetime,
    //             status_id: prop.status_id,
    //             memo: prop.memo,
    //             nickname: prop.nickname,
    //             settlement_date: prop.settlement_date,
    //             beneficiary: prop.beneficiaries_nickname,
    //             currency_from: prop.currency_from_iso_alpha_3,
    //             amount_from: prop.amount_from,
    //             amount_to: prop.amount_to,
    //             currency_to: prop.currency_to_iso_alpha_3,
    //             client_rate: prop.client_rate.toFixed(5),
    //             transfer_status: prop.transfer_status_name,
    //             attachmentFileId: prop.attachmentFileId,
    //             actions: (
    //               <div className='actions-right'>
    //                 <Button
    //                   justIcon
    //                   round
    //                   simple
    //                   onClick={() => {
    //                     // let obj = this.props.staff.staff_list.find(o => o.aws_cognito_id === prop.aws_cognito_id);
    //                     // this.props.setEntityDetailId(prop.id);
    //                     // this.props.selectEntityUi("edit")
    //                     // this.props.setTransferSelectId(prop.id);
    //                     // this.props.history.push(`/transfers/edit`);
    //                     this.props.history.push(`/transfers/payment-requests/edit/${prop.id}`);
    //                     // alert(
    //                     //   "You've clicked EDIT button on \n{ \nName: " +
    //                     //   obj.first_name +
    //                     //   ", \nlast name: " +
    //                     //   obj.last_name +
    //                     //   ", \nemail: " +
    //                     //   obj.email +
    //                     //   "\n}."
    //                     // );
    //                   }}
    //                   color='warning'
    //                   className='edit'
    //                 >
    //                   <Dvr />
    //                 </Button>{' '}
    //                 {/* use this button to remove the data row */}
    //                 {this.props.app_state.current_staff_super_admin && (
    //                   <Button
    //                     justIcon
    //                     round
    //                     simple
    //                     onClick={() => {
    //                       //console.log(this);
    //                       var data = this.props.transfers.transfer_list;
    //                       data.find((o, i) => {
    //                         if (o.id === prop.id) {
    //                           this.setState({
    //                             dialog_delete_confirm: true,
    //                             dialog_delete_item: prop.id,
    //                             dialog_delete_item_name: `${prop.nickname}`
    //                           });

    //                           return true;
    //                         }
    //                         return false;
    //                       });
    //                       this.setState({data: data});
    //                     }}
    //                     color='danger'
    //                     className='remove'
    //                   >
    //                     <Close />
    //                   </Button>
    //                 )}{' '}
    //               </div>
    //             )
    //           };
    //         });
    //     }
    //   }
    // }
    // //end of function

    handle_delete_dialog_cancel = () => {
        this.setState({
            dialog_delete_confirm: false,
            dialog_delete_item: 0,
            dialog_delete_item_name: ''
        });
    };

    handle_delete_dialog_click = item_to_delete => {
        this.setState({dialog_delete_confirm: false});
        console.log(item_to_delete);
        API.put('transfers', `/update/id/${item_to_delete}`, {
            body: {deleted: true}
        })
            .then(response => {
                console.log(response);
                this.props.fetchTransferList();
                // dispatch(receiveStaffList(response));
            })
            .catch(error => {
                console.log(error);
                // quickfix because of the api bug, so it returns api error because client talks to old database
                this.props.fetchTransferList();
            });
    };

    // handle_date_change = (startDate, endDate) => {
    //   // console.log(startDate, endDate);
    //   this.setState({startDate, endDate});
    //   this.buildTableData();
    // };

    getFullDate = s => {
        let showDate = '';
        if (s.getMonth() >= 9) {
            if (s.getDate() > 9) showDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-' + s.getDate();
            else showDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-0' + s.getDate();
        } else if (s.getMonth() < 9) {
            if (s.getDate() > 9) showDate = s.getFullYear() + '-0' + (s.getMonth() + 1) + '-' + s.getDate();
            else showDate = s.getFullYear() + '-0' + (s.getMonth() + 1) + '-0' + s.getDate();
        }
        return showDate;
    };

    queryStrings() {
        const query_strings = queryString.parse(this.props.location.search);
        if (Object.keys(query_strings).length !== 0) {
            if (query_strings.client_id) {
                this.setState({client_id: Number(query_strings.client_id)});
            }
        }
    }

    downloadInvoice = (data) => {
        if (isEmpty(data.attachmentFileId)) {
            this.props.history.push(`/payment-requests/view/${data.id}`);
        } else {
            Storage.get(data.attachmentFileId, {expires: 60}).then(item => {
                fetch(item)
                    .then(response => response.blob())
                    .then(blob => {
                        const a = document.createElement('a');
                        const url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = data.id + '_REQUEST_INVOICE';
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch(error => console.error('Error downloading file:', error));
            })
        }
    }

    sendSMS = (prop, messageTxt) => {
        console.log(prop);
        const loading = message.loading("Sending sms notification to client. Please wait.", 0);
        const telephone = prop.telephone_mobile.startsWith("0") ? "+61" + prop.telephone_mobile.split(" ")[0].substring(1) : prop.telephone_mobile;
        API.post("commons", "/sms", {
            body: {
                phone_number: telephone,
                message: messageTxt
            }
        }).then(res => {
            message.success("Message Sent");
        }).catch(err => {
            console.log("Failed to send sms. Please try again..");
        }).finally(() => loading());
    }

    sendFollowUpSMS = (prop, messageTxt) => {
        const loading = message.loading("Sending sms notification to client. Please wait.", 0);
        const telephone = prop.telephone_mobile.startsWith("0") ? "+61" + prop.telephone_mobile.split(" ")[0].substring(1) : prop.telephone_mobile;
        API.post("commons", "/sms", {
            body: {
                phone_number: telephone,
                message: messageTxt
            }
        }).then(res => {
            message.success("Message Sent");
        }).catch(err => {
            console.log("Failed to send sms. Please try again..");
        }).finally(() =>  loading());
    }

    render() {
        var transfer_status = this.props.transfers_pending.transfer_pending_list.map((prop, key) => {
            return prop.transfer_status_name;
        });

        var transfer_status_unique = [...new Set(transfer_status)];
        var transfer_status_optionlist = transfer_status_unique.map((prop, key) => {
            return (
                <option key={key} value={prop}>
                    {prop}
                </option>
            );
        });

        let tableData = []
        let date_range_transfer_list = this.state.transferList;
        const transfer_list_length = date_range_transfer_list.length;
        if (transfer_list_length > 0) {
            date_range_transfer_list = date_range_transfer_list.sort((a, b) => {
                return b.id - a.id;
            });
            tableData = date_range_transfer_list.map((prop, key) => {
                // console.log(prop); //disable debug messages #1054

                const message = `You have received a payment request from ${prop.team_nickname}. Please login at https://portal.forexworldwide.com using your email ${prop.email} to view and make payment.`;
                const followUpMsg = `${prop.team_nickname} is following up on the payment request issued. Please login to  https://portal.forexworldwide.com to view.`;
                return {
                    id: prop.id,
                    client: prop.client_nickname,
                    record_created: prop.record_created == null ? '' : moment(prop.record_created).format('DD/MM/YYYY'),
                    status_id: prop.status_id,
                    invoice_date: prop.invoice_date == null ? '' : moment(prop.invoice_date).format('DD/MM/YYYY'),
                    due_date: prop.expiryDate == null ? '' : moment(prop.expiryDate).format('DD/MM/YYYY'),
                    amount_to: prop.amount_to,
                    currency_to: prop.currency_to_iso_alpha_3,
                    transfer_status: prop.transfer_status_name,
                    team_nickname: prop.team_nickname,
                    attachmentFileId: prop.attachmentFileId,
                    telephone_mobile: prop.telephone_mobile,
                    actions: (
                        <>
                            <Flex justify={'center'} gap={5} align={'center'}>
                                <Flex vertical={true} gap={5}>
                                    <Button size={'small'} disabled={prop.status_id === 4 || prop.status_id === 5}
                                            icon={<MailOutlined/>}
                                            style={{width: 100}}
                                            onClick={() => {
                                                this.sendNewPaymentRequestCreated(prop.id, true);
                                            }}>
                                        Staff
                                    </Button>
                                    <Button size={'small'} disabled={prop.status_id === 4 || prop.status_id === 5}
                                            icon={<MailOutlined/>}
                                            style={{width: 100}}
                                            onClick={() => {
                                                this.sendNewPaymentRequestCreated(prop.id, false);
                                            }}>
                                        Client
                                    </Button>
                                </Flex>
                                <Flex vertical={true} gap={5}>
                                    <Tooltip placement={'top'} title={message}>
                                        <Button disabled={(prop.status_id === 4 || prop.status_id === 5 || prop.telephone_mobile == null)}
                                                size={'small'}
                                                style={{width: 100}}
                                                icon={<MessageOutlined />}
                                                onClick={() => this.sendSMS(prop, message)}>Initial</Button>
                                    </Tooltip>
                                    <Tooltip placement={'top'} title={followUpMsg}>
                                        <Button disabled={(prop.status_id === 4 || prop.status_id === 5 || prop.telephone_mobile == null)}
                                                size={'small'}
                                                style={{width: 100}}
                                                icon={<MessageOutlined />}
                                                onClick={() => this.sendFollowUpSMS(prop, followUpMsg)}>Follow Up</Button>
                                    </Tooltip>
                                </Flex>
                                <Flex vertical={true} gap={5}>
                                    <Button size={'small'} type={'primary'}
                                            onClick={() => this.props.history.push(`/transfers/payment-requests/detail/${prop.id}`)}><EditOutlined/></Button>
                                    <Button size={'small'} type={'primary'} danger={true} onClick={() => {
                                        Modal.confirm({
                                            title: 'Delete ?',
                                            content: `Are you sure you want to delete request ${prop.id}`,
                                            onOk: () => {
                                                message.loading(`Deleting payment requests #${prop.id}`).then(() => {
                                                    API.post("transfers_pending", `/update/${prop.id}`, {body: {deleted: true}}).then(() => {
                                                        message.success(`Payment request ${prop.id} has been deleted`);
                                                        this.fetchTransferList();
                                                    }).catch(err => {
                                                        console.log(err);
                                                        message.error("Payment request has been deleted");
                                                    })
                                                });
                                            }
                                        })
                                    }}><DeleteOutlined/></Button>
                                </Flex>
                                <Flex vertical={true} gap={5}>
                                    <Button type={'primary'}
                                            size={'small'}
                                            icon={!prop.attachmentFileId ? <EyeOutlined/> : <DownloadOutlined/>}
                                            onClick={() => this.downloadInvoice(prop)}></Button>
                                </Flex>
                            </Flex>

                        </>
                    )
                };
            });
        }


        return (
            <React.Fragment>
                <Flex justify={'end'}>
                    <FormItem label={'Select Group'}>
                        <Select allowClear={true}
                                showSearch={true}
                                placeholder={'-- SELECT GROUP --'}
                                style={{ width: '200px' }}
                                onChange={(val) => {
                                    if (val) {
                                        const clients = this.state.groupList.find(i => i.id === val).clients.map(k => k.client_id);
                                        this.setState({ transferList:  this.state.allPendingTransfers.filter(i => clients.includes(i.client_id))})
                                    } else {
                                        this.fetchTransferList();
                                    }
                                }}
                                options={this.state.groupList}></Select>
                    </FormItem>
                </Flex>
                <Table virtual={true} dataSource={tableData} columns={this.state.columns}></Table>
                <Dialog
                    open={this.state.dialog_delete_confirm}
                    onClose={this.handleClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            Are you sure that you wish to <strong>delete</strong> transfer
                            record {this.state.dialog_delete_item_name}
                            ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handle_delete_dialog_cancel} color='primary'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => this.handle_delete_dialog_click(this.state.dialog_delete_item)}
                            color='danger'
                            autoFocus
                        >
                            Delete Record
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers: state.transfers,
        transfers_pending: state.transfers_pending
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //TODO: Watch this fetching transfer list function for #892 changes - duplicate transfer display
        fetchTransferPendingList: () => {
            dispatch(fetchTransferPendingList());
        }
    };
};

const TransferListPendingContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(styles)(ListTable(TransferListPending))));

export default TransferListPendingContainer;
