import React from 'react';
import PropTypes from 'prop-types';

// import matchSorter from 'match-sorter'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";

import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import Button from '../../CustomButtons/Button';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react';
import {API} from 'aws-amplify';

// react component for creating dynamic tables
import ReactTable from 'react-table';
import useExpanded from 'react-table';
// import Badge from "../../Badge/Badge";
// import matchSorter from "match-sorter";
import {FormattedNumber} from 'react-intl';

//core components for #Issue 963
// import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
// import StepLabel from '@material-ui/core/StepLabel';

// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from '../../CustomInput/CustomInput';
// import Datetime from "react-datetime";
import CustomReactSelect from '../../Forms/CustomReactSelect/CustomReactSelect';

//component to send email
import TransferDetailPayoutCommunication from './TransferDetailPayoutCommunication';

import {injectIntl, intlShape} from 'react-intl';

// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";
import Slide from '@material-ui/core/Slide';
import Table from 'components/Table/Table.jsx';

var moment = require('moment');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const styles = {
  selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class TransferDetailInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // register form
      loaded: false,
      is_loading: true,
      beneficiary_list: [],
      user_beneficiaries_list: [],
      currencies_list_priority: [],
      payouts_list: [],
      country_list: [],
      currencies_list: [],
      purpose_list: [],
      super_admin: this.props.current_staff_super_admin,
      ACTIONaddBeneficiaryModalOpen: false,
      addBeneficiaryModalOpen: false
    };
  }

  componentDidMount() {
    // console.log(this.props);
    this.setState({
      transfers_pending_id: this.props.transfers_pending_id
      // payout_datetime: this.props.payout_datetime,
      // payout_bank_reference: this.props.payout_bank_reference,
      // transfer_status: this.props.transfer_status
    });

    API.get('transfers_pending', `/get-full/id/${this.props.transfers_pending_id}`)
      .then(response => {
        console.log(response);
        this.setState(
          {
            transfer_pending: response.transfer_pending,
            payouts_list: response.payouts
          },
          () => {
            if (!response.transfer_pending) return;
            API.get('clients', `/get/id/${response.transfer_pending.client_id}`)
              .then(response => {
                console.log(response);
                // this.setState({
                //   client_detail: response
                // });
                this.setState(prevState => ({
                  transfer_pending: {
                    ...prevState.transfer_pending,
                    client_detail: response
                  },
                  current_transfer_client: response
                }));
                // Todo: Need to call other APIs to get current domain
                API.get('teams', `/get/id/${response.team_id}`)
                  .then(response_teams => {
                    console.log(response_teams);
                    this.setState({
                      team: response_teams
                    });

                    // Get all domain names if we don't add another API function for getting domain name by id
                    API.get('admin', `/domains/get`)
                      .then(response_admin => {
                        // console.log(response_admin);
                        // this.setState({
                        //   admin_domains_get_all: response_admin
                        // });
                        for (let domain of response_admin) {
                          if (domain.id === response_teams.entity_id) {
                            this.setState(prevState => ({
                              transfer_pending: {
                                ...prevState.transfer_pending,
                                client_detail: {
                                  ...prevState.transfer_pending.client_detail,
                                  current_domain: domain
                                }
                              }
                            }));
                          }
                        }
                      })
                      .catch(error => {
                        console.log(error);
                      });
                  })
                  .catch(error => {
                    console.log(error);
                  });
              })
              .catch(error => {
                console.log(error);
              });

            API.get('currencies', `/get/${response.transfer_pending.currency_to_id}`)
              .then(response => {
                console.log(response);
                this.setState({
                  currency_to: response
                });
              })
              .catch(error => {
                console.log(error);
              });
          }
        );
      })
      .catch(error => {
        console.log(error);
        // API.get("transfers", `/get-full/id/${this.props.transfer_id}`).then(
        //   response => {
        //     //console.log(response)
        //     this.setState({
        //       transfer: response.fullList,
        //       payouts_list: response.payouts
        //     });
        //   }
        // );
      });

    // API.get("beneficiaries", `/get-all`).then(response => {
    //   this.setState({
    //     beneficiary_list: response
    //   });
    //   return response;
    // });

    // API.get("transfers", `/get_purpose/id/9`)
    //   .then(response => {
    //     this.setState({
    //       purpose_list: response
    //     });
    //   })
    //   .catch(error => {
    //     API.get("transfers", `/get_purpose/id/9`)
    //       .then(response => {
    //         this.setState({
    //           purpose_list: response
    //         });
    //       })
    //       .catch(error => {
    //         console.log(error);
    //       });
    //     console.log(error);
    //   });
    /*
        API.get("beneficiaries", `/get/by_client_id/${this.props.transfers.new_transfer_data.client_id}`)
          .then(response => {
            // console.log(response);
            this.setState({
              beneficiaries_list: response
            });
          })
          .catch(error => {
            console.log(error);
          });
        */

    //Code block of #799 needs of API
    // API.get("system", `/currencies/get-not-deleted`)
    //   .then(response => {
    //     // console.log(response);
    //     this.setState({
    //       currencies_list_priority: response
    //     });
    //   })
    //   .then(() => {
    //     API.get("beneficiaries", `/get/by_client_id/${this.props.client_id}`) //test value 610
    //       .then(response => {
    //         // console.log(response);
    //         this.setState({
    //           user_beneficiaries_list: response
    //         });
    //       })
    //       .catch(error => {
    //         console.log(error);
    //       });
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });

    // API.get("countries", `/list_not_deleted`)
    //   .then(response => {
    //     this.setState({
    //       country_list: response
    //     });
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });

    // API.get("currencies", `/list_not_deleted`)
    //   .then(response => {
    //     // console.log(response);
    //     this.setState({
    //       currencies_list: response
    //     });
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
    //End of Code block

    // if (this.props.beneficiary_id) {
    //   API.get("beneficiaries", `/get/id/${this.props.beneficiary_id}`)
    //     .then(response => {
    //       //console.log(response);
    //       this.setState({
    //         loaded: true,
    //         is_loading: false,
    //         beneficiary: response
    //       });
    //       // this.props.loadTransferDetailsSuccess();
    //       return response;
    //     })
    //     .then(response => {
    //       API.get("system", `/countries/get/${response.ben_address_country}`)
    //         .then(response => {
    //           // console.log(response);
    //           this.setState({
    //             ben_address_country: response[0].full_name
    //           });
    //           // this.props.loadTransferDetailsSuccess();
    //           return response;
    //         })
    //         .catch(error => {
    //           console.log(error);
    //         });

    //       API.get("system", `/countries/get/${response.bank_address_country}`)
    //         .then(response => {
    //           // console.log(response);
    //           this.setState({
    //             bank_address_country: response[0].full_name
    //           });
    //           // this.props.loadTransferDetailsSuccess();
    //           return response;
    //         })
    //         .catch(error => {
    //           console.log(error);
    //         });
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // }

    // if (this.props.currency_to_id) {
    //   API.get("system", `/currencies/get/${this.props.currency_to_id}`)
    //     .then(response => {
    //       // console.log(response);
    //       this.setState({
    //         currency_to: response
    //       });
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // }

    // if (this.props.currency_from_id) {
    //   API.get("system", `/currencies/get/${this.props.currency_from_id}`)
    //     .then(response => {
    //       // console.log(response);
    //       this.setState({
    //         currency_from: response
    //       });
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // }

    // if (this.props.transfer_id) {
    //   API.get("transfers", `/get-full/id/${this.props.transfer_id}`)
    //     .then(response => {
    //       //console.log(response)
    //       this.setState({
    //         transfer: response.fullList,
    //         payouts_list: response.payouts
    //       });

    //       API.get(
    //         "system",
    //         `/currencies/get/${response.fullList.currency_to_id}`
    //       )
    //         .then(response1 => {
    //           this.setState({
    //             currency_to: response1
    //           });
    //         })
    //         .catch(error => {
    //           console.log(error);
    //         });
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       API.get("transfers", `/get-full/id/${this.props.transfer_id}`).then(
    //         response => {
    //           //console.log(response)
    //           this.setState({
    //             transfer: response.fullList,
    //             payouts_list: response.payouts
    //           });
    //         }
    //       );
    //     });
    // }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    const {classes} = this.props;
    // console.log(classes);
    // console.log(this.props)
    // console.log(this.state);
    // console.log(this.state.currency_from);
    // console.log(this.state.payouts_list);
    // this.setState({
    //   transfer: response.fullList,
    //   payouts_list: response.payouts
    // });

    if (!this.state.current_transfer_client) return null;
    if (!this.state.team) return null;

    let currency_from_name = '';
    let currency_to_name = '';
    for (var i in this.state.currencies_list_priority) {
      if (this.state.currencies_list_priority[i].id === this.state.currency_from_id) {
        currency_from_name = this.state.currencies_list_priority[i].short_name;
      }
      if (this.state.currencies_list_priority[i].id === this.state.currency_to_id) {
        currency_to_name = this.state.currencies_list_priority[i].short_name;
      }
    }

    // var beneficiary_list_select_options = this.state.user_beneficiaries_list //user_beneficiaries_list => this user's beneficiaries_list
    //   //this.state.beneficiary_list //all bene
    //   .sort((a, b) => a.nickname.localeCompare(b.nickname))
    //   .map(item => {
    //     return { value: item.id, label: item.nickname };
    //   });

    // if (!this.state.currency_to) {
    //   return null;
    // }

    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <GridContainer>
            <GridItem md={8}>
              <Card style={{height: '95%'}}>
                <CardHeader>
                  <h5>Invoice Details</h5>
                </CardHeader>
                <CardBody>
                  <Table
                    tableData={[
                      // [
                      //   "Transfer From",
                      //   <React.Fragment>
                      //     <span>
                      //       {
                      //         <div
                      //           className={`currency-flag currency-flag-${
                      //             currency_from_name
                      //               ? currency_from_name.toLowerCase()
                      //               : ""
                      //             }`}
                      //         />
                      //       }
                      //     </span>{" "}
                      //     {currency_from_name}{" "}
                      //     {this.props.intl.formatNumber(this.state.amount_from, {
                      //       minimumFractionDigits: 2,
                      //       maximumFractionDigits: 2
                      //     })}
                      //   </React.Fragment>
                      // ],
                      [
                        'Invoice To',
                        <React.Fragment>
                          <a href={`/clients/edit/${this.state.current_transfer_client.id}`} target='_blank'>
                            {this.state.current_transfer_client.nickname}
                          </a>
                        </React.Fragment>
                      ],
                      ['Invoice From', <React.Fragment>{this.state.team.business_name}</React.Fragment>],
                      [
                        'Invoice Amount',
                        <React.Fragment>
                          <span>
                            {
                              <div
                                className={`currency-flag currency-flag-${
                                  this.state.currency_to.iso_alpha_3
                                    ? this.state.currency_to.iso_alpha_3.toLowerCase()
                                    : ''
                                }`}
                              />
                            }
                          </span>{' '}
                          {this.state.currency_to.short_name}{' '}
                          {this.props.intl.formatNumber(this.state.transfer_pending.amount_to, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                        </React.Fragment>
                      ],
                      [
                        'Invoice Date',
                        <React.Fragment>{moment(this.state.invoice_date).format('DD/MM/YYYY')}</React.Fragment>
                      ],
                      [
                        'Due Date',
                        <React.Fragment>
                          {moment(this.state.due_date).format('DD/MM/YYYY')} (due in{' '}
                          {0 - moment().diff(moment(this.state.transfer_pending.due_date), 'days')} days)
                        </React.Fragment>
                      ]

                      // [
                      //   "Rate",
                      //   `${this.props.intl.formatNumber(this.state.client_rate, {
                      //     maximumFractionDigits: 5
                      //   })}`
                      // ]
                    ]}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <CardHeader>
                  <h6>Functions</h6>
                </CardHeader>
                <CardBody>
                  <Button
                    fullwidth='true'
                    color='info'
                    //disabled={!this.props.enabled}
                    size='sm'
                    onClick={() => {
                      var win = window.open(`/invoices/view/${this.props.transfer_pending_detail_id}`, '_blank');
                      win.focus();
                    }}
                    // className={classes.updateProfileButton}
                  >
                    <span style={{height: 20}}>View Invoice</span>
                  </Button>
                </CardBody>
              </Card>
            </GridItem>

            {/* 
              hardcode: this.state.payouts_list[0].beneficiary_id at this phase
              (this.state.payouts_list[0].beneficiary_id === 0) && 
            */}
          </GridContainer>
        </form>
      </React.Fragment>
    );
  }
}

// TransferDetailPayout.propTypes = {
//   classes: PropTypes.object.isRequired
// };

export default injectIntl(withStyles(styles)(TransferDetailInvoice));
