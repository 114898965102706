import {API} from 'aws-amplify';

export const ENTITYBANKSACCOUNT_FETCH_ENTITYBANKS_LIST_REQUEST = 'ENTITYBANKSACCOUNT_FETCH_ENTITYBANKS_LIST_REQUEST';
export const ENTITYBANKSACCOUNT_FETCH_ENTITYBANKS_LIST_SUCCESS = 'ENTITYBANKSACCOUNT_FETCH_ENTITYBANKS_LIST_SUCCESS';
// export const ENTITYBANKSACCOUNT_FETCH_ENTITYBANKS_LIST_FAILURE = "ENTITYBANKSACCOUNT_FETCH_ENTITYBANKS_LIST_FAILURE";
export const ENTITYBANKSACCOUNT_CREATE_NEW_SHOW_UI = 'ENTITYBANKSACCOUNT_CREATE_NEW_SHOW_UI';
export const ENTITYBANKSACCOUNT_CREATE_NEW_START = 'ENTITYBANKSACCOUNT_CREATE_NEW_START';
export const ENTITYBANKSACCOUNT_CREATE_NEW_CANCEL = 'ENTITYBANKSACCOUNT_CREATE_NEW_CANCEL';
export const ENTITYBANKSACCOUNT_CREATE_NEW_COMPLETE = 'ENTITYBANKSACCOUNT_CREATE_NEW_COMPLETE';
export const ENTITYBANKSACCOUNT_SELECT_UI = 'ENTITYBANKSACCOUNT_SELECT_UI';
export const ENTITYBANKSACCOUNT_ENTITYBANKS_LIST_IS_DIRTY = 'ENTITYBANKSACCOUNT_ENTITYBANKS_LIST_IS_DIRTY';
export const ENTITYBANKSACCOUNT_ENTITYBANKS_EDIT = 'ENTITYBANKSACCOUNT_ENTITYBANKS_EDIT';

export function requestEntityList() {
  return {
    type: ENTITYBANKSACCOUNT_FETCH_ENTITYBANKS_LIST_REQUEST
  };
}

export function receiveEntityList(json) {
  return {
    type: ENTITYBANKSACCOUNT_FETCH_ENTITYBANKS_LIST_SUCCESS,
    entitybanks: json,
    receivedAt: Date.now()
  };
}

export function fetchEntitybanksListNotDeleted() {
  return function (dispatch) {
    dispatch(requestEntityList);

    API.get('system', `/entitybanksaccount/get-all`)
      .then(response => {
        //console.log('FROM AJAX');
        //console.log(response);
        dispatch(receiveEntityList(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function selectEntitybanksUi(value) {
  return {
    type: ENTITYBANKSACCOUNT_SELECT_UI,
    value: value
  };
}

export function entitybanksListIsDirty(value) {
  return {
    type: ENTITYBANKSACCOUNT_ENTITYBANKS_LIST_IS_DIRTY,
    value: value
  };
}

export async function create_new_currency_in_database(
  id,
  entity_id,
  eba_swift,
  eba_bsb,
  eba_accout_name,
  eba_accout_number,
  eba_deleted,
  reference_mandatory
) {
  API.post('system', `/entitybanksaccount/create/in-database`, {
    body: {
      id: id,
      entity_id: entity_id,
      eba_swift: eba_swift,
      eba_bsb: eba_bsb,
      eba_accout_name: eba_accout_name,
      eba_accout_number: eba_accout_number,
      eba_deleted: eba_deleted,
      reference_mandatory: reference_mandatory
    }
  })
    .then(database_result => {
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
}

export function createNewEntitybanks(data) {
  return async function (dispatch) {
    //dispatch(createNewEntityStart());

    try {
      await create_new_currency_in_database(
        data.id,
        data.entity_id,
        data.eba_swift,
        data.eba_bsb,
        data.eba_accout_name,
        data.eba_accout_number,
        data.eba_deleted,
        data.reference_mandatory
      );
    } catch (e) {
      console.log(e);
    }
  };
}

export function cancelCreateNewEntitybanks() {
  return {
    type: ENTITYBANKSACCOUNT_CREATE_NEW_CANCEL
  };
}

export function createNewEntitybanksStart() {
  return {
    type: ENTITYBANKSACCOUNT_CREATE_NEW_START
  };
}

export function createNewEntitybanksComplete() {
  return {
    type: ENTITYBANKSACCOUNT_CREATE_NEW_COMPLETE
  };
}

export function editEntitybanks(id) {
  return {
    type: ENTITYBANKSACCOUNT_ENTITYBANKS_EDIT,
    value: id
  };
}
