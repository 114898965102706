import {connect} from 'react-redux';
import TransfersUpload from './TransfersUpload';
import {
  updateClient,
  loadClientDetailsRequest,
  loadClientDetailsSuccess,
  createCognitoAccountForClient
} from '../../redux/actions/clients';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadClientDetailsRequest: () => {
      dispatch(loadClientDetailsRequest());
    },
    loadClientDetailsSuccess: () => {
      dispatch(loadClientDetailsSuccess());
    },
    createCognitoAccountForClient: (id, username, password, language_id) => {
      dispatch(createCognitoAccountForClient(id, username, password, language_id));
    },
    updateClient: (data, id) => {
      dispatch(updateClient(data, id));
    }
  };
};

const TransfersUploadContainer = connect(mapStateToProps, mapDispatchToProps)(TransfersUpload);

export default TransfersUploadContainer;
