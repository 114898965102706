import React from 'react';
import {API} from 'aws-amplify';
import {connect} from 'react-redux';
// import Step2PayingTo from "./Step2PayingTo";
import {updateNewTransferCreation} from 'redux/actions/transfers_2';

import {
  //startNewBeneficiaryCreation,
  updateNewBeneficiaryCreation,
  createNewBeneficiary
} from 'redux/actions/beneficiaries';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import CommoneCurrentComponent from '../../../common/Common';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import CustomTextField from 'components/CustomInput/CustomTextField.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react';
// import CustomReactSelect from "../../Forms/CustomReactSelect/CustomReactSelect";
import DocumentUploadDragDrop from 'components/DocumentUpload/DocumentUploadDragAndDrop.jsx';
import document_image from 'assets/img/document_image.jpg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import {isEmpty} from 'libs/miscFunc.js';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Button from 'components/CustomButtons/Button.jsx';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import {Divider, IconButton} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';
const style = {
  ...customCheckboxRadioSwitch,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {color: '#555'},
  inputAdornment: {position: 'relative'}
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

class Step3PayingTo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beneficiaries_list: [],
      currencies_list_priority: [],
      beneficiary: {},
      show_iban: false,
      show_bsb: false,
      show_sort_code: false,
      show_swift_code: false,
      show_aba: false,
      show_account_number: false,
      purpose_of_payment_detail: '',
      purpose_of_payment_detail_state: '',
      amount_to_state: '',
      purpose_list: [],
      checked_beneficiary_details: false,
      clientTransfers: [
        {
          beneficiary_id: '',
          amount_to: '',
          purpose_of_payment_detail: '',
          beneficiary_id_state: '',
          amount_to_state: '',
          purpose_of_payment_detail_state: '',
          detail_1: '',
          detail_2: '',
          detail_3: '',
          detail_1_state: '',
          detail_2_state: '',
          detail_3_state: '',
          document: '',
          purpose_of_payment_other: '',
          purpose_of_payment_other_state: ''
        }
      ],
      FIXME: false,
      country_list: [],
      currencies_list: [],
      addBeneficiaryModalOpen: false,
      ben_legal_name: '',
      ben_email_main: '',
      ben_address_line_1: '',
      ben_address_line_2: '',
      ben_address_suburb: '',
      ben_address_state: '',
      ben_address_postcode: '',
      ben_address_country: undefined,
      bank_legal_name: '',
      account_currency: '',
      bsb_code: '',
      swift_code: '',
      account_number: '',
      aba_routing_number: '',
      sort_code: ''
    };
  }

  componentDidMount() {
    if (this.props.transfers_2.new_transfer_data.client_id) {
      API.get('beneficiaries', `/get/by_client_id/${this.props.transfers_2.new_transfer_data.client_id}`)
        .then(response => {
          const filterer_beneficiaries_list = response;
          if (filterer_beneficiaries_list.length > 0) {
            for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
              if (
                filterer_beneficiaries_list[i].account_currency !==
                this.props.transfers_2.new_transfer_data.currency_to_id
              ) {
                filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
              }
            }
          }

          this.setState({beneficiaries_list: filterer_beneficiaries_list});
        })
        .catch(error => {
          console.log(error);
        });
    }

    API.get('currencies', '/list_not_deleted').then(response => {
      this.setState({currencies_list_priority: response}, () => {});
    });

    API.get('countries', '/list_not_deleted')
      .then(response => {
        this.setState({country_list: response});
      })
      .catch(error => {
        console.log(error);
      });

    API.get('system', '/currencies/get-all')
      .then(response => {
        this.setState({currencies_list: response});
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.transfers_2.new_transfer_data.client_id !== this.props.transfers_2.new_transfer_data.client_id) {
      API.get('beneficiaries', `/get/by_client_id/${this.props.transfers_2.new_transfer_data.client_id}`)
        .then(response => {
          const filterer_beneficiaries_list = response;
          if (filterer_beneficiaries_list.length > 0) {
            for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
              if (
                filterer_beneficiaries_list[i].account_currency !==
                this.props.transfers_2.new_transfer_data.currency_to_id
              ) {
                filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
              }
            }
          }
          this.setState({
            // beneficiaries_list: response
            account_currency: this.props.transfers_2.new_transfer_data.currency_to_id,
            beneficiaries_list: filterer_beneficiaries_list
          });
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (
      prevProps.transfers_2.new_transfer_data.currency_to_id !== this.props.transfers_2.new_transfer_data.currency_to_id
    ) {
      API.get('beneficiaries', `/get/by_client_id/${this.props.transfers_2.new_transfer_data.client_id}`)
        .then(response => {
          const filterer_beneficiaries_list = response;
          if (filterer_beneficiaries_list.length > 0) {
            for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
              if (
                filterer_beneficiaries_list[i].account_currency !==
                this.props.transfers_2.new_transfer_data.currency_to_id
              ) {
                filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
              }
            }
          }

          this.setState({
            // beneficiaries_list: response
            account_currency: this.props.transfers_2.new_transfer_data.currency_to_id,
            beneficiaries_list: filterer_beneficiaries_list
          });
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (prevProps.transfers_2.new_transfer_data.team_id !== this.props.transfers_2.new_transfer_data.team_id) {
      API.get('transfers', `/get_purpose_by_team/id/${this.props.transfers_2.new_transfer_data.team_id}`)
        .then(response => {
          this.setState({purpose_list: response});
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (prevProps.transfers_2.new_transfer_data.ledgerTo != this.props.transfers_2.new_transfer_data.ledgerTo) {
      this.setState({ledgerTo: this.props.transfers_2.new_transfer_data.ledgerTo});
    }
  }

  getBeneficiaryDetails(beneficiary_id) {
    API.get('beneficiaries', `/get/id/${beneficiary_id}`)
      .then(response => {
        this.setState({beneficiary: response});
        // this.props.updateNewTransferCreation('beneficiary', response);

        this.setState({
          beneficiary: {
            ...this.state.beneficiary,
            account_currency_nickname: `${response.full_name} [${response.iso_alpha_3}]`
          }
        });

        return response;
      })
      .then(response => {
        API.get('system', `/currencies/get/${response.account_currency}`)
          .then(response => {
            this.setState({
              beneficiary: {
                ...this.state.beneficiary,
                account_currency_nickname: `${response.full_name} [${response.iso_alpha_3}]`
              }
            });

            API.post('email', '/interaction/main', {
              body: {
                interaction: 'New Transfer Request Step 2 PayingTo',
                data: {
                  client_id: this.props.transfers_2.new_transfer_data.client_id,
                  client_nickname: this.props.transfers_2.new_transfer_data.nickname,
                  beneficiary_id: this.state.beneficiary.id,
                  beneficiary_nickname: this.state.beneficiary.nickname
                },
                result: 'New transfer request proceeding'
              }
            });
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  }

  // clearBeneficiaryDetails() {
  //   this.setState({beneficiary: {}});
  //   this.setState({beneficiary_id: ''});
  //   this.props.updateNewTransferCreation('beneficiary', {});

  //   const clientTransfers = [...this.state.clientTransfers];
  //   clientTransfers[0] = {
  //     ...clientTransfers[0],
  //     beneficiary_id: ''
  //   };
  //   this.setState({clientTransfers});
  //   this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
  // }

  fetchNewRate = () => {
    if (
      this.props.transfers_2.new_transfer_data.currency_to &&
      this.props.transfers_2.new_transfer_data.currency_from
    ) {
      const url = 'https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
      axios
        .get(url, {
          params: {
            currencies: this.props.transfers_2.new_transfer_data.currency_from.iso_alpha3,
            source: this.props.transfers_2.new_transfer_data.currency_to.iso_alpha3,
            format: '1'
          }
        })
        .then(response => {
          const key = `${this.props.transfers_2.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}${this.props.transfers_2.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}`;
          const rate_string = response.data.quotes[key];

          const amount_to = Number.parseFloat(this.props.transfers_2.new_transfer_data.amount_to);
          const rate = Number.parseFloat(rate_string);
          const rate_adjust =
            Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_division) +
            Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_entity) +
            Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_team) +
            Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_staff);

          const adjusted_rate = rate + rate * rate_adjust;

          const amount_from = amount_to * adjusted_rate;

          this.props.updateNewTransferCreation('current_rate', adjusted_rate.toFixed(5));
          this.props.updateNewTransferCreation('amount_from', amount_from.toFixed(5));
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  sendState() {
    return this.state;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
    this.props.updateNewTransferCreation(stateName, event.target.value);
    if (stateName === 'amount_to') {
      this.fetchNewRate();
    }
  }

  // handleSelectChange = event => {
  //   this.setState({[event.target.name]: event.target.value});
  //   this.props.updateNewTransferCreation(event.target.name, event.target.value);
  //   if (event.target.name === 'beneficiary_id') {
  //     this.getBeneficiaryDetails(event.target.value);
  //   }
  // };

  handleFileUpdate = file_id => {
    console.log(file_id);
    this.props.updateNewTransferCreation('document', file_id);
  };

  // handleToggle = name => event => {
  //   this.setState({[name]: event.target.checked});
  //   if (event.target.checked === true) {
  //     this.clearBeneficiaryDetails();
  //   }
  // };

  // isValidated() {
  //   const clientTransfers = [...this.state.clientTransfers];
  //   let return_flag = true;
  //   clientTransfers.map(el => {
  //     if (el.beneficiary_id == '' && this.state.checked_beneficiary_details == false) {
  //       el.beneficiary_id_state = 'error';
  //       return_flag = false;
  //     }

  //     if (el.purpose_of_payment_detail == '') {
  //       el.purpose_of_payment_detail_state = 'error';
  //       return_flag = false;
  //     }

  //     if (el.purpose_of_payment_detail == 1) {
  //       if (el.purpose_of_payment_other == '') {
  //         el.purpose_of_payment_other_state = 'error';
  //         return_flag = false;
  //       }
  //     } else {
  //       el.purpose_of_payment_other_state = 'success';
  //     }

  //     this.setState({clientTransfers: clientTransfers});
  //   });

  //   return return_flag;
  // }

  onupdate = (file_id, index) => {
    const beneficiary_array = this.props.transfers_2.new_transfer_data.multiple_beneficiary_id || [{}];
    beneficiary_array[0].document = file_id;
    this.props.updateNewTransferCreation('multiple_beneficiary_id', beneficiary_array);

    // const clientTransfers = this.state.clientTransfers;
    // clientTransfers[index] = {
    //   ...clientTransfers[index],
    //   document: file_id
    // };
    // this.setState({clientTransfers});
    // this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
  };

  handleCustomReactSelectChange = name => value => {
    let option_value;
    if (value === null) {
      option_value = null;
    } else {
      option_value = value.value;
    }
    this.setState({[name]: option_value});

    if (name === 'bank_country') {
      const country_dataset = this.state.country_list;
      for (let i = 0; i < country_dataset.length; i++) {
        if (country_dataset[i].id === option_value) {
          console.table(country_dataset[i]);
          this.setState({
            show_swift_code: true,
            show_account_number: true
          });

          //iban check
          if (country_dataset[i].use_iban === 1) {
            this.setState({show_iban: true});
          } else {
            this.setState({show_iban: false});
          }

          //bsb check
          if (country_dataset[i].use_bsb === 1) {
            this.setState({show_bsb: true});
          } else {
            this.setState({show_bsb: false});
          }

          //aba_routing check
          if (country_dataset[i].use_aba === 1) {
            this.setState({show_aba: true});
          } else {
            this.setState({show_aba: false});
          }

          //sort_code check
          if (country_dataset[i].use_sort_code === 1) {
            this.setState({show_sort_code: true});
          } else {
            this.setState({show_sort_code: false});
          }
          //account_number check
          if (country_dataset[i].use_account_number === 1) {
            this.setState({show_account_number: true});
          } else {
            this.setState({show_account_number: false});
          }

          // transit_code
          if (country_dataset[i].use_transit_code === 1) {
            this.setState({show_transit_code: true});
          } else {
            this.setState({show_transit_code: false});
          }

          // bank_code
          if (country_dataset[i].use_bank_code === 1) {
            this.setState({show_bank_code: true});
          } else {
            this.setState({show_bank_code: false});
          }
        }
      }
    }

    if (name === 'beneficiary_id') {
      var beneficiary_array = this.props.transfers_2.new_transfer_data.multiple_beneficiary_id || [{}];

      API.get('beneficiaries', `/get/id/${option_value}`)
        .then(beneficiaries_detail => {
          console.log(beneficiaries_detail);
          beneficiary_array[0].beneficiary = beneficiaries_detail;
          beneficiary_array[0].beneficiary_id = option_value;
          beneficiary_array[0].amount = parseFloat(this.props.transfers_2.new_transfer_data.amount_to).toFixed(2);
          this.props.updateNewTransferCreation('multiple_beneficiary_id', beneficiary_array);

          const clientTransfers = [...this.state.clientTransfers];
          clientTransfers[0] = {
            ...clientTransfers[0],
            beneficiary: beneficiaries_detail
          };
          this.setState({clientTransfers});
          this.getBeneficiaryDetails(option_value);
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (name === 'purpose_of_payment_detail') {
      const beneficiary_array = this.props.transfers_2.new_transfer_data.multiple_beneficiary_id || [{}];
      beneficiary_array[0].purpose_of_payment_detail = option_value;
      beneficiary_array[0].purpose_of_payment_detail_text = value.label;
      this.props.updateNewTransferCreation('multiple_beneficiary_id', beneficiary_array);

      const i = 0;
      const clientTransfers = [...this.state.clientTransfers];
      clientTransfers[i] = {
        ...clientTransfers[i],
        [name]: option_value
      };
      this.setState({clientTransfers});

      // this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
      // console.log(value);
      // this.props.updateNewTransferCreation('purpose_of_payment_detail_text', value.label);
    }

    this.props.updateNewTransferCreation(name, option_value);
  };

  handleChange(e, i, stateName) {
    const {name, value} = e.target;
    var beneficiary_array = this.props.transfers_2.new_transfer_data.multiple_beneficiary_id || [{}];
    beneficiary_array[0] = {
      ...beneficiary_array[0],
      [stateName]: value
    };
    this.props.updateNewTransferCreation('multiple_beneficiary_id', beneficiary_array);

    const clientTransfers = [...this.state.clientTransfers];
    clientTransfers[i] = {
      ...clientTransfers[i],
      [stateName]: value
    };
    this.setState({clientTransfers});
    // this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);

    // if (stateName == 'beneficiary_id' && i == 0) {
    //   this.getBeneficiaryDetails(value);
    // }

    // if (stateName == 'amount') {
    //   const clientTransfers = [...this.state.clientTransfers];
    //   let total_amount = 0;
    //   let tmpValue = 0;

    //   clientTransfers.map((el, index) => {
    //     if (index == i) {
    //       if (isEmpty(value)) {
    //         tmpValue = 0;
    //       } else {
    //         tmpValue = value;
    //       }
    //       total_amount = Number.parseFloat(total_amount) + Number.parseFloat(tmpValue);
    //     } else {
    //       if (isEmpty(el.amount)) {
    //         tmpValue = 0;
    //       } else {
    //         tmpValue = el.amount;
    //       }
    //       total_amount = Number.parseFloat(total_amount) + Number.parseFloat(tmpValue);
    //     }
    //   });
    //   this.setState({amount_to: total_amount});
    //   this.props.updateNewTransferCreation('amount_to', total_amount);
    //   this.props.updateNewTransferCreation(
    //     'nickname',
    //     `FX Transfer ${moment().format('DD/MM/YY')} ${Number.parseFloat(total_amount).toFixed(2)} ${
    //       this.props.transfers.new_transfer_data.currency_to
    //         ? this.props.transfers.new_transfer_data.currency_to.iso_alpha_3
    //         : ''
    //     }`
    //   );
    // }

    if (stateName === 'purpose_of_payment_detail') {
      this.setState({FIXME: value == 1});
    }
  }

  newBeneficiaryChange(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
  }

  isValidateBeneficiary() {
    if (this.state.ben_legal_name == '') {
      this.setState({ben_legal_name_state: 'error'});
    } else {
      this.setState({ben_legal_name_state: 'success'});
    }

    if (this.state.account_currency == '') {
      this.setState({account_currency_state: 'error'});
    } else {
      this.setState({account_currency_state: 'success'});
    }

    if (this.state.ben_legal_name == '' || this.state.account_currency == '') {
      return false;
    }
    return true;
  }

  createBeneficiaryIntoDatabase = async data => {
    await this.props.createNewBeneficiary(data);
    const th = this;
    setTimeout(async () => {
      await API.get('beneficiaries', `/get/by_client_id/${th.props.transfers_2.new_transfer_data.client_id}`).then(
        response => {
          const filterer_beneficiaries_list = response;
          if (filterer_beneficiaries_list.length > 0) {
            for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
              if (
                filterer_beneficiaries_list[i].account_currency !==
                this.props.transfers_2.new_transfer_data.currency_to_id
              ) {
                filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
              }
            }
          }

          this.setState({beneficiaries_list: filterer_beneficiaries_list});
        }
      );
    }, 2000);

    this.setState({
      ben_legal_name: '',
      ben_email_main: '',
      ben_address_line_1: '',
      ben_address_line_2: '',
      ben_address_suburb: '',
      ben_address_state: '',
      ben_address_postcode: '',
      ben_address_country: '',

      bank_legal_name: '',
      account_currency: '',
      bsb_code: '',
      swift_code: '',
      account_number: '',
      aba_routing_number: '',
      sort_code: ''
    });

    return;
  };
  getInstructions = () => {
    return (
      <React.Fragment>
        <GridContainer>
          {this.state.bank_country_full_name && (
            <GridItem xs={12} sm={12}>
              <p>
                When transfering to &nbsp;
                <img
                  src={
                    this.state.bank_country_iso_alpha_2 !== ''
                      ? require(`assets/img/flags/${this.state.bank_country_iso_alpha_2}.png`)
                      : ''
                  }
                />{' '}
                &nbsp;
                <strong>{this.state.bank_country_full_name}</strong>, we require
              </p>
              <ul>
                <li>Bank name</li>
                {this.state.show_iban && <li>IBAN</li>}
                {this.state.show_swift_code && <li>SWIFT Code</li>}
                {this.state.show_aba && <li>ABA Routing Number</li>}
                {this.state.show_sort_code && <li>Sort Code</li>}
                {this.state.show_bsb && <li>BSB</li>}
                {this.state.show_transit_code && <li>Transit &amp; Institution No</li>}
                {this.state.show_bank_code && <li>Bank Code</li>}
                {this.state.show_account_number && <li>Account Number</li>}
              </ul>
            </GridItem>
          )}
        </GridContainer>
      </React.Fragment>
    );
  };
  finished = async () => {
    if (this.isValidateBeneficiary()) {
      const {
        ben_legal_name,
        ben_email_main,
        ben_address_line_1,
        ben_address_line_2,
        ben_address_suburb,
        ben_address_state,
        ben_address_postcode,
        ben_address_country,
        bank_legal_name,
        account_currency,
        bsb_code,
        swift_code,
        account_number,
        aba_routing_number,
        sort_code
      } = this.state;

      try {
        await this.createBeneficiaryIntoDatabase({
          client_id: this.props.transfers_2.new_transfer_data.client_id,
          nickname: ben_legal_name,
          ben_legal_name: ben_legal_name,
          ben_email_main: ben_email_main,
          ben_address_line_1: ben_address_line_1,
          ben_address_line_2: ben_address_line_2,
          ben_address_suburb: ben_address_suburb,
          ben_address_state: ben_address_state,
          ben_address_postcode: ben_address_postcode,
          ben_address_country: ben_address_country,
          bank_legal_name: bank_legal_name,
          account_currency: account_currency,
          bsb_code: bsb_code,
          swift_code: swift_code,
          account_number: account_number,
          aba_routing_number: aba_routing_number,
          sort_code: sort_code
        });
        this.setState({addBeneficiaryModalOpen: false});
      } catch (e) {
        this.setState({isLoading: false});
      }
    }
  };

  addBeneficiaryModelOpen = () => {
    this.setState({addBeneficiaryModalOpen: true});
  };

  addBeneficiaryModelClose = () => {
    this.setState({
      addBeneficiaryModalOpen: false,
      ben_legal_name: '',
      ben_email_main: '',
      ben_address_line_1: '',
      ben_address_line_2: '',
      ben_address_suburb: '',
      ben_address_state: '',
      ben_address_postcode: '',
      ben_address_country: '',
      bank_country: '',
      bank_legal_name: '',
      bsb_code: '',
      swift_code: '',
      account_number: '',
      aba_routing_number: '',
      sort_code: ''
    });
  };

  createNewBeneficiaryUi = () => {
    const {classes} = this.props;
    const country_list_select_options = this.state.country_list.map(item => ({
      value: item.id,
      label: (
        <React.Fragment>
          <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
          &nbsp;
          {item.iso_alpha_3 + ': ' + item.full_name}
        </React.Fragment>
      )
    }));
    const select_currency_select_options = this.state.currencies_list_priority.map(item => ({
      value: item.id,
      label: (
        <React.Fragment>
          <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
          &nbsp;
          {item.iso_alpha_3 + ': ' + item.full_name}
        </React.Fragment>
      )
    }));

    return (
      <React.Fragment>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          maxWidth='lg'
          open={this.state.addBeneficiaryModalOpen}
          transition={Transition}
          keepMounted
          onClose={() => this.addBeneficiaryModelClose()}
          aria-labelledby='modal-slide-title'
          aria-describedby='modal-slide-description'
        >
          <DialogTitle
            id='classic-modal-slide-title'
            disableTypography
            style={{backgroundColor: '#E0E0E0'}}
            onClose={() => this.addBeneficiaryModelClose()}
            className={classes.modalHeader}
          >
            <GridContainer justify='flex-start'>
              <GridItem xs={10} md={10}>
                <h4 className={classes.modalTitle2}>Add New Beneficiary</h4>
              </GridItem>
              <GridItem xs={2} md={2} style={{textAlign: 'right'}}>
                <IconButton edge='start' color='inherit' onClick={this.addBeneficiaryModelClose} aria-label='close'>
                  <CloseIcon />
                </IconButton>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            style={{backgroundColor: '#E0E0E0'}}
            id='modal-slide-description'
            className={classes.modalBody}
          >
            <GridContainer justify='flex-start'>
              <GridItem xs={12} md={6}>
                <CustomReactSelect
                  label='Currency'
                  options={select_currency_select_options}
                  value={this.state.account_currency}
                  onChange={this.handleCustomReactSelectChange('account_currency')}
                  style={{
                    dropdownIndicator: base => ({
                      ...base,
                      color: 'gray' // Custom colour
                    })
                  }}
                  isClearable={false}
                />
                <br />
                <CustomReactSelect
                  label='Bank Country'
                  options={country_list_select_options}
                  value={this.state.bank_country}
                  style={{
                    dropdownIndicator: base => ({
                      ...base,
                      color: 'gray' // Custom colour
                    })
                  }}
                  onChange={this.handleCustomReactSelectChange('bank_country')}
                  isClearable={false}
                  error={this.state.bank_country_state === 'error'}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                {this.getInstructions()}
              </GridItem>
            </GridContainer>
            <br />
            <br />
            <br />
            <GridContainer justify='flex-start'>
              <GridItem xs={6}>
                <p className={classes.benficiaryTitle}>Beneficiary details</p>
                <Divider className={classes.beneficiaryDivider} />
                <GridContainer>
                  <GridItem xs={12} md={12}>
                    <CustomTextField
                      success={this.state.ben_legal_name_state === 'success'}
                      error={this.state.ben_legal_name_state === 'error'}
                      labelText={<span>Beneficiary (Account) Name</span>}
                      id='first_name'
                      formControlProps={{
                        fullWidth: true,
                        style: {
                          marginLeft: '0',
                          borderRadius: '0px'
                        }
                      }}
                      inputProps={{
                        value: this.state.ben_legal_name,
                        variant: 'outlined',
                        size: 'small',
                        label: <span>Name</span>,
                        style: {backgroundColor: 'white'},
                        onChange: event => this.newBeneficiaryChange(event, 'ben_legal_name', 'length', 3)
                      }}
                    />
                    <CustomTextField
                      success={this.state.ben_address_line_1_state === 'success'}
                      error={this.state.ben_address_line_1_state === 'error'}
                      labelText={<span>Address Line 1</span>}
                      id='ben_address_line_1'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: this.state.ben_address_line_1,
                        variant: 'outlined',
                        style: {backgroundColor: 'white'},
                        size: 'small',
                        label: <span>Address</span>,
                        onChange: event => this.newBeneficiaryChange(event, 'ben_address_line_1', 'length', 3)
                      }}
                    />
                    <CustomTextField
                      success={this.state.ben_address_suburb_state === 'success'}
                      error={this.state.ben_address_suburb_state === 'error'}
                      labelText={<span>Suburb / Region</span>}
                      id='ben_address_suburb'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: this.state.ben_address_suburb,
                        variant: 'outlined',
                        style: {backgroundColor: 'white'},
                        size: 'small',
                        label: <span>Suburb / Region</span>,
                        onChange: event => this.newBeneficiaryChange(event, 'ben_address_suburb', 'length', 3)
                      }}
                    />
                    <CustomTextField
                      success={this.state.ben_address_postcode_state === 'success'}
                      error={this.state.ben_address_postcode_state === 'error'}
                      labelText={<span>Postcode</span>}
                      id='ben_address_postcode'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: this.state.ben_address_postcode,
                        variant: 'outlined',
                        style: {backgroundColor: 'white'},
                        size: 'small',
                        label: <span>Postcode</span>,
                        onChange: event => this.newBeneficiaryChange(event, 'ben_address_postcode', 'length', 3)
                      }}
                    />
                    <CustomTextField
                      success={this.state.ben_email_main_state === 'success'}
                      error={this.state.ben_email_main_state === 'error'}
                      labelText={<span>Beneficiary Email [optional]</span>}
                      // helpText="May be used to provide copy of payout confirmation to beneficiary.  Confirmation is required from you on each transfer."
                      id='ben_email_main'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: this.state.ben_email_main,
                        variant: 'outlined',
                        style: {backgroundColor: 'white'},
                        size: 'small',
                        label: <span>Beneficiary Email (optional)</span>,
                        onChange: event => this.newBeneficiaryChange(event, 'ben_email_main', 'length', 3)
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={6}>
                <p className={classes.benficiaryTitle}>Payment Details</p>
                <Divider className={classes.beneficiaryDivider} />
                <GridContainer justify='flex-start'>
                  <GridItem xs={12}>
                    <CustomTextField
                      success={this.state.bank_legal_name_state === 'success'}
                      error={this.state.bank_legal_name_state === 'error'}
                      labelText={<span>Bank Name</span>}
                      style={{borderRadius: '0px'}}
                      id='bank_legal_name'
                      formControlProps={{
                        fullWidth: true,
                        style: {borderRadius: '0px'}
                      }}
                      inputProps={{
                        value: this.state.bank_legal_name,
                        variant: 'outlined',
                        style: {
                          backgroundColor: 'white',
                          borderRadius: '0px'
                        },
                        size: 'small',
                        label: <span>Bank Name</span>,
                        onChange: event => this.newBeneficiaryChange(event, 'bank_legal_name', 'length', 3)
                      }}
                    />
                    {this.state.show_iban && (
                      <CustomTextField
                        success={this.state.iban === 'success'}
                        error={this.state.iban === 'error'}
                        labelText={<span>IBAN</span>}
                        id='iban'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          variant: 'outlined',
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '0px'
                          },
                          size: 'small',
                          value: this.state.iban,
                          onChange: event => this.newBeneficiaryChange(event, 'iban', 'length', 3)
                        }}
                      />
                    )}
                    {this.state.show_swift_code && (
                      <CustomTextField
                        success={this.state.swift_code_state === 'success'}
                        error={this.state.swift_code_state === 'error'}
                        labelText={<span>Swift Code</span>}
                        id='swift_code'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          value: this.state.swift_code,
                          variant: 'outlined',
                          style: {backgroundColor: 'white'},
                          size: 'small',
                          label: <span>Swift Code</span>,
                          onChange: event => this.newBeneficiaryChange(event, 'swift_code', 'length', 3)
                        }}
                      />
                    )}
                    {this.state.show_aba && (
                      <CustomTextField
                        success={this.state.aba_routing_number_state === 'success'}
                        error={this.state.aba_routing_number_state === 'error'}
                        labelText={<span>ABA Routing Number</span>}
                        id='aba_routing_number'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          variant: 'outlined',
                          style: {backgroundColor: 'white'},
                          size: 'small',
                          label: <span>ABA Routing Number</span>,
                          value: this.state.aba_routing_number,
                          onChange: event => this.newBeneficiaryChange(event, 'aba_routing_number', 'length', 3)
                        }}
                      />
                    )}

                    {this.state.show_sort_code && (
                      <CustomTextField
                        success={this.state.sort_code_state === 'success'}
                        error={this.state.sort_code_state === 'error'}
                        labelText={<span>Sort Code</span>}
                        id='sort_code'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          value: this.state.sort_code,
                          variant: 'outlined',
                          style: {backgroundColor: 'white'},
                          size: 'small',
                          label: <span>Sort Code</span>,
                          onChange: event => this.newBeneficiaryChange(event, 'sort_code', 'length', 3)
                        }}
                      />
                    )}
                    {this.state.show_bsb && (
                      <CustomTextField
                        success={this.state.bsb_code_state === 'success'}
                        error={this.state.bsb_code_state === 'error'}
                        labelText={<span>BSB</span>}
                        id='bsb_code'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          value: this.state.bsb_code,
                          variant: 'outlined',
                          style: {backgroundColor: 'white'},
                          size: 'small',
                          label: <span>BSB</span>,
                          onChange: event => this.newBeneficiaryChange(event, 'bsb_code', 'length', 3)
                        }}
                      />
                    )}
                    {this.state.show_transit_code && (
                      <CustomTextField
                        success={this.state.transit_code_state === 'success'}
                        error={this.state.transit_code_state === 'error'}
                        labelText={<span>Transit &amp; Institution No</span>}
                        id='transit_code'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          value: this.state.transit_code,
                          variant: 'outlined',
                          style: {backgroundColor: 'white'},
                          size: 'small',
                          label: <span>Transit &amp; Institution No</span>,
                          onChange: event => this.newBeneficiaryChange(event, 'transit_code', 'length', 3)
                        }}
                      />
                    )}
                    {this.state.show_bank_code && (
                      <CustomTextField
                        success={this.state.bank_code_state === 'success'}
                        error={this.state.bank_code_state === 'error'}
                        labelText={<span>Bank Code</span>}
                        id='bank_code'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          value: this.state.bank_code,
                          variant: 'outlined',
                          style: {backgroundColor: 'white'},
                          size: 'small',
                          label: <span>Bank Code</span>,
                          onChange: event => this.newBeneficiaryChange(event, 'bank_code', 'length', 3)
                        }}
                      />
                    )}

                    {this.state.show_account_number && (
                      <CustomTextField
                        success={this.state.account_number_state === 'success'}
                        error={this.state.account_number_state === 'error'}
                        style={{borderRadius: '0px'}}
                        labelText={<span>Account Number </span>}
                        id='account_number'
                        formControlProps={{
                          fullWidth: true,
                          style: {borderRadius: '0px'}
                        }}
                        inputProps={{
                          value: this.state.account_number,
                          variant: 'outlined',
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '0px'
                          },
                          size: 'small',
                          label: <span>Account Number</span>,
                          onChange: event => this.newBeneficiaryChange(event, 'account_number', 'length', 3)
                        }}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <br />
            <br />
          </DialogContent>

          <DialogActions
            className={classes.modalFooter + ' ' + classes.modalFooterCenter}
            style={{
              backgroundColor: '#E0E0E0',
              display: 'block',
              padding: '0px 30%'
            }}
          >
            <Button onClick={this.finished} className={classes.primaryB}>
              Add Beneficiary
            </Button>
            <Button className={classes.primaryC} onClick={() => this.addBeneficiaryModelClose()}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  createUI() {
    const {classes} = this.props;
    const beneficiary_list_select_options = this.state.beneficiaries_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return {
          value: item.id,
          // label: item.nickname
          label: `${item.nickname} ${item.b_client_id ? '' : ' [Team]'} ${item.holdingAccountId ? '[*]' : ''}`
        };
      });

    const purpose_of_payment_select_options = this.state.purpose_list.map(item => ({
      value: item.id,
      label: item.description
    }));
    console.log(purpose_of_payment_select_options)

    let show_other = false;
    if (this.props.transfers_2.new_transfer_data.multiple_beneficiary_id) {
      for (let i = 0; i < purpose_of_payment_select_options.length; i++) {
        if (
          purpose_of_payment_select_options[i].value ===
          this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].purpose_of_payment_detail
        ) {
          // console.log(purpose_of_payment_select_options[i])
          show_other = purpose_of_payment_select_options[i].label === 'Other';
        }
      }
    }

    return this.state.clientTransfers.map((el, i) => (
      <div key={i}>
        <GridContainer justify='center'>
          <GridItem xs={24} sm={12}>
            {this.state.checked_beneficiary_details !== true && (
              <React.Fragment>
                {!(this.props.transfers_2.new_transfer_data.ledgerTo || this.state.ledgerTo) && (
                  <CustomReactSelect
                    error={el.beneficiary_id_state === 'error'}
                    label='Select beneficiary'
                    options={beneficiary_list_select_options}
                    value={
                      this.props.transfers_2.new_transfer_data.multiple_beneficiary_id
                        ? this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary_id
                        : 0
                    }
                    onChange={this.handleCustomReactSelectChange('beneficiary_id')}
                    isClearable={false}
                    // isDisabled={this.state.prefill_beneficiary}
                  />
                )}
              </React.Fragment>
            )}

            <Checkbox
              id={Math.random()}
              tabIndex={-2}
              disabled={false}
              onClick={e => this.updateLedgerAmount('ledgerTo', e.target.checked)}
              checked={Boolean(this.props.transfers_2.new_transfer_data.ledgerTo)}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
            <span
              style={{
                textTransform: 'capitalize',
                fontSize: '10px'
              }}
            >
              tick box to transfer to your Holding Account.
            </span>

            <br />
            <br />
            <Button size='sm' color='info' key='addKey' onClick={() => this.addBeneficiaryModelOpen()}>
              Create new beneficiary
            </Button>

            {/* {(!!this.state.beneficiary_id) && ( */}
            {Boolean(this.state.beneficiary.id) && (
              <React.Fragment>
                <h6>Selected beneficiary details:</h6>
                <p>
                  <strong>{this.state.beneficiary.nickname}</strong>
                  <br />
                  {this.state.beneficiary.ben_legal_name}
                  <br />
                  {this.state.beneficiary.ben_address_line_1 ? (
                    <React.Fragment>
                      {this.state.beneficiary.ben_address_line_1}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {this.state.beneficiary.ben_address_line_2 ? (
                    <React.Fragment>
                      {this.state.beneficiary.ben_address_line_2}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {this.state.beneficiary.ben_address_suburb ? (
                    <React.Fragment>
                      {this.state.beneficiary.ben_address_suburb} {this.state.beneficiary.ben_address_state}{' '}
                      {this.state.beneficiary.ben_address_postcode}
                      <br />
                    </React.Fragment>
                  ) : null}
                </p>
                <p>
                  <strong>Account details:</strong>
                  <br />
                  {this.state.beneficiary.account_currency_nickname} <br />
                  {this.state.beneficiary.bank_legal_name} <br />
                  {this.state.beneficiary.account_number} <br />
                </p>
              </React.Fragment>
            )}
          </GridItem>
        </GridContainer>
        <hr />
        <GridContainer justify='center'>
          <GridItem xs={12} sm={6}>
            <CustomReactSelect
              error={
                this.props.transfers_2.new_transfer_data.multiple_beneficiary_id &&
                this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].purpose_of_payment_detail_state ===
                  'error'
              }
              label='Purpose of payment'
              options={purpose_of_payment_select_options}
              value={
                this.props.transfers_2.new_transfer_data.multiple_beneficiary_id
                  ? this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].purpose_of_payment_detail
                  : 0
              }
              onChange={this.handleCustomReactSelectChange('purpose_of_payment_detail')}
              isClearable={false}
              isDisabled={this.state.prefill_purpose_of_payment}
            />

            {show_other && (
              <CustomInput
                style={{paddingTop: 25}}
                // success={el.detail_1_state === "success"}
                // error={el.detail_1_state === "error"}
                labelText={<span>Specify purpose of payment (*)</span>}
                id='purpose_of_payment_other'
                name='purpose_of_payment_other'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: el.purpose_of_payment_other,
                  onChange: event => this.handleChange(event, i, 'purpose_of_payment_other')
                }}
              />
            )}

            <CustomInput
              style={{paddingTop: 25}}
              success={el.detail_1_state === 'success'}
              error={el.detail_1_state === 'error'}
              labelText={<span>Payment detail / invoice number (*)</span>}
              id='detail_1'
              name='detail_1'
              formControlProps={{fullWidth: true}}
              inputProps={{
                value: el.detail_1,
                onChange: event => this.handleChange(event, i, 'detail_1')
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            {/* if user selects "personal payment" from DDL, hide the upload component */}
            {el.purpose_of_payment_detail !== 5 && (
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: 25
                }}
              >
                {/*<DocumentUpload*/}
                {/*  circle*/}
                {/*  change={false}*/}
                {/*  placeholder={document_image}*/}
                {/*  updateFunction={this.handleFileUpdate}*/}
                {/*/>*/}
                <DocumentUploadDragDrop
                  circle
                  change={false}
                  placeholder={document_image}
                  updateFunction={this.onupdate}
                  index={i}
                />
                <p>Please upload a copy of an invoice or any form of document regarding this payment.</p>
              </div>
            )}
          </GridItem>
        </GridContainer>
      </div>
    ));
  }

  updateLedgerAmount = async (stateName, value) => {
    this.setState({[stateName]: value});
    this.props.updateNewTransferCreation(stateName, value);

    if (stateName == 'ledgerTo') {
      if (value) {
        const clientId = this.props.transfers_2.new_transfer_data.client_id;
        const currencyId = this.props.transfers_2.new_transfer_data.currency_to_id;
        const response = await API.get('holding_accounts', `/get/client-currency/${clientId}/${currencyId}`);
        this.setState({[stateName]: response.holding_account_list[0].id});
        this.props.updateNewTransferCreation('ledgerTo', response.holding_account_list[0].id);
        this.props.updateNewTransferCreation('send_to_holding_acc_checkbox', response.holding_account_list[0].id);
      } else {
        this.setState({
          send_to_holding_acc_checkbox: value,
          ledgerTo: value
        });
        this.props.updateNewTransferCreation('ledgerTo', value);
        this.props.updateNewTransferCreation('send_to_holding_acc_checkbox', value);
      }
    }
  };

  render() {
    const {classes} = this.props;
    console.log(this.state.purpose_list)
    // For CustomReactSelect. Generate select options for dropdown list.
    const beneficiary_list_select_options = this.state.beneficiaries_list.map(item => ({
      value: item.id,
      label: item.nickname
    }));
    const purpose_of_payment_select_options = this.state.purpose_list.map(item => ({
      value: item.id,
      label: item.description
    }));

    return (
      <React.Fragment>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>Please indicate beneficiary from the drop down menu below</h4>
          </GridItem>
        </GridContainer>
        {this.createNewBeneficiaryUi()}
        {this.createUI()}

        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12} lg={6} />
          <GridItem xs={12} sm={12} md={12} lg={6} />
        </GridContainer>
      </React.Fragment>
    );
  }
}

// export default withStyles(style)(Step3PayingTo);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    beneficiaries: state.beneficiaries,
    transfers_2: state.transfers_2
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    },
    //startNewBeneficiaryCreation: () => {
    //  dispatch(startNewBeneficiaryCreation())
    //},
    createNewBeneficiary: data => {
      dispatch(createNewBeneficiary(data));
    },
    updateNewBeneficiaryCreation: (key, value) => {
      dispatch(updateNewBeneficiaryCreation(key, value));
    }
  };
};

const Step3PayingToContainer = connect(mapStateToProps, mapDispatchToProps)(Step3PayingTo);

export default withStyles(style)(Step3PayingToContainer);
