import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';

import {cardTitle} from '../../assets/jss/material-dashboard-pro-react.jsx';
const entityNewStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  }
};

class CountryNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // register form
      fullname: '',
      fullname_state: '',
      iso_alpha_2: '',
      iso_alpha_2_state: '',
      iso_alpha_3: '',
      iso_alpha_3_state: '',
      un_code: '',
      un_code_state: '',
      legal_entity_name: '',
      legal_entity_name_state: '',
      afsl: '',
      afsl_state: ''
    };
  }

  validateForm() {
    var valid = true;
    if (this.state.fullname_state === '') {
      this.setState({fullname_state: 'error'});
      valid = false;
    }
    if (this.state.legal_entity_name_state === '') {
      this.setState({legal_entity_name_state: 'error'});
      valid = false;
    }
    if (this.state.afsl_state === '') {
      this.setState({afsl_state: 'error'});
      valid = false;
    }
    return valid;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'checkbox':
        if (event.target.checked) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-length':
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'url':
        if (this.verifyUrl(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'min-value':
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-value':
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'range':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      default:
        break;
    }
    switch (type) {
      case 'checkbox':
        this.setState({[stateName]: event.target.checked});
        break;
      default:
        this.setState({[stateName]: event.target.value});
        break;
    }
  }

  async handleSubmitForm() {
    // if (this.validateForm()) {
    await this.props.createNewCountry({
      full_name: this.state.fullname,
      iso_alpha_2: this.state.iso_alpha_2.toUpperCase(),
      iso_alpha_3: this.state.iso_alpha_3.toUpperCase(),
      un_code: this.state.un_code
    });
    //this.props.entitiesListIsDirty(true);
    this.props.selectCountriesUi('list');
  }

  handleCancel() {
    this.props.cancelCreateNewEntity();
  }

  renderForm() {
    const {classes} = this.props;
    return (
      <GridItem xs={12} sm={6}>
        <h3>Enter details for new Country</h3>
        <form>
          <CustomInput
            success={this.state.fullname_state === 'success'}
            error={this.state.fullname_state === 'error'}
            labelText='full Name *'
            id='fullname'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.fullname,
              onChange: event => this.change(event, 'fullname', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            success={this.state.iso_alpha_2_state === 'success'}
            error={this.state.iso_alpha_2_state === 'error'}
            labelText='iso Alpha 2*'
            id='iso_alpha_2'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.iso_alpha_2,
              onChange: event => this.change(event, 'iso_alpha_2', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            success={this.state.iso_alpha_3_state === 'success'}
            error={this.state.iso_alpha_3_state === 'error'}
            labelText='iso Alpha 3*'
            id='iso_alpha_3'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.iso_alpha_3,
              onChange: event => this.change(event, 'iso_alpha_3', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            success={this.state.un_code_state === 'success'}
            error={this.state.un_code_state === 'error'}
            labelText='UN Code*'
            id='un_code'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.un_code,
              onChange: event => this.change(event, 'un_code', 'length', 0),
              type: 'text'
            }}
          />
          {/*<CustomInput*/}

          <div className={classes.formCategory}>
            <small>*</small> Required fields
          </div>
          <Button color='rose' onClick={() => this.handleSubmitForm()} className={classes.registerButton}>
            Register
          </Button>
          <Button
            // color=""
            onClick={() => this.handleCancel()}
            className={classes.registerButton}
          >
            Cancel
          </Button>
        </form>
      </GridItem>
    );
  }

  render() {
    return (
      <div>
        <GridContainer>{this.renderForm()}</GridContainer>
      </div>
    );
  }
}

CountryNew.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(entityNewStyles)(CountryNew);
