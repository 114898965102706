import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {message, Modal, Upload} from 'antd';
import {s3Upload} from "../../libs/awsLib";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const AntUploader  = forwardRef(({ onHandleResult, onRemoveImage }, ref) => {

    useImperativeHandle(ref, () => ({
        clearList: () => {
            setPreviewImage(null);
            setFileList([]);
        },
        restore: (fileList) => setFileList(fileList)
    }));

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({fileList: newFileList}) => setFileList(newFileList);
    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const uploadToS3 = async ({ file, onSuccess, onError }) => {
        try {
            const attachment = file ? await s3Upload(file) : null;
            await getBase64(file);
            onHandleResult(attachment);
            onSuccess(file);
            message.success(`${file.name} file uploaded successfully.`);
        } catch (e) {
            alert('Upload Failed');
            setFileList([]);
        }
    };

    return (
        <>
            <Upload
                maxCount={1}
                listType="picture-card"
                fileList={fileList}
                onRemove={() => onRemoveImage() }
                customRequest={uploadToS3}
                onPreview={(e) => handlePreview(e)}
                onChange={handleChange}
            >
                {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    );
});
export default AntUploader;