import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';

import CardHeader from '../../components/Card/CardHeader';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import NavPills from 'components/NavPills/NavPills.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import {API} from 'aws-amplify';

const staffEditStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  }
};

class EntityEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      is_loading: true,
      nickname: '',
      legal_entity_name: '',
      afsl: ''
    };
  }

  componentDidMount() {
    if (this.props.entity_id) {
      this.props.loadEntityDetailsRequest();
      API.get('system', `/entities/get/${this.props.entity_id}`)
        .then(entity_detail => {
          // console.log(entity_detail);
          this.setState({
            loaded: true,
            is_loading: false,
            nickname: entity_detail.nickname,
            legal_entity_name: entity_detail.legal_entity_name,
            afsl: entity_detail.afsl
          });
          this.props.loadEntityDetailsSuccess();
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  async saveEntity(entity_detail) {
    // console.log(entity_detail);
    return API.put('system', `/entities/update/id/${this.props.entity_id}`, {
      body: entity_detail
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading: true});
    const {nickname, legal_entity_name, afsl} = this.state;
    try {
      await this.saveEntity({
        nickname: nickname,
        legal_entity_name: legal_entity_name,
        afsl: afsl
      });
      this.props.selectEntityUi('list');
    } catch (e) {
      alert(e);
    }
  };

  render() {
    const {classes} = this.props;
    // console.log(this.state);
    if (!this.state.loaded) {
      return <span />;
    }
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Editing {this.state.nickname}</h4>
              </CardHeader>
              <CardBody>
                <NavPills
                  color='info'
                  tabs={[
                    {
                      tabButton: 'Entity Details',
                      tabContent: (
                        <div style={{padding: 20}}>
                          <form onSubmit={this.handleSubmit}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText='Nickname'
                                  id='nickname'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.nickname || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText='Legal Name'
                                  id='legal_entity_name'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.legal_entity_name || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText='AFSL'
                                  id='afsl'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.afsl || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>

                            <Button color='primary' type='submit' className={classes.updateProfileButton}>
                              Update Entity Details
                            </Button>
                          </form>
                        </div>
                      )
                    }
                  ]}
                />
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

EntityEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(staffEditStyles)(EntityEdit);
