import {connect} from 'react-redux';
import EntitybanksTable from './EntitybanksTable';
import {
  requestEntityList,
  fetchEntitybanksListNotDeleted,
  entitybanksListIsDirty,
  editEntitybanks,
  selectEntitybanksUi
} from '../../redux/actions/entitybanks';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    entity_banks: state.entity_banks
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestEntityList: () => {
      dispatch(requestEntityList());
    },
    fetchEntitybanksListNotDeleted: () => {
      dispatch(fetchEntitybanksListNotDeleted());
    },
    entitybanksListIsDirty: value => {
      dispatch(entitybanksListIsDirty(value));
    },
    editEntitybanks: id => {
      dispatch(editEntitybanks(id));
    },
    selectEntitybanksUi: value => {
      dispatch(selectEntitybanksUi(value));
    }

    // fetchStaffList: () => {
    //   dispatch(fetchStaffListNotDeleted())
    // },
    // loadStaffMemberDetails: (aws_cognito_id) => {
    //   // console.log(data)
    //   dispatch(loadStaffMemberDetails(aws_cognito_id))
    // }
  };
};

const EntitybanksTableContainer = connect(mapStateToProps, mapDispatchToProps)(EntitybanksTable);

export default EntitybanksTableContainer;
