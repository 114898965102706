import React from 'react';
import PropTypes from 'prop-types';
import {Switch, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import queryString from 'query-string';

import PagesHeader from '../components/Header/PagesHeader';
import Footer from '../components/Footer/Footer';
import pagesRoutes from 'routes/pages';
import UnauthenticatedRoute from '../components/Routes/UnauthenticatedRoute';

const style = theme => ({
  wrapper: {
    height: 'auto',
    minHeight: '100vh',
    position: 'relative',
    top: '0'
  },
  fullPage: {
    padding: '120px 0',
    position: 'relative',
    minHeight: '100vh',
    display: 'flex!important',
    margin: '0',
    border: '0',
    color: '#fff',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'fit-content!important'
    },
    '& footer': {
      position: 'absolute',
      bottom: '0',
      width: '100%',
      border: 'none !important'
    },
    '&:before': {
      backgroundColor: '#E0E0E0'
    },
    '&:before,&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      zIndex: '2'
    }
  }
});

class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.body.style.overflow = 'unset';
    const query_strings = queryString.parse(this.props.location.search);
    if (query_strings) {
      if (query_strings.ref) {
        this.setState({
          ref: true,
          ref_code: query_strings.ref
        });
      }
    }
  }

  render() {
    const {classes, ...rest} = this.props;

    return (
      <div>
        <PagesHeader {...rest} />
        <div className={classes.wrapper} ref='wrapper'>
          <div className={classes.fullPage}>
            <Switch>
              {pagesRoutes.map((prop, key) => {
                if (prop.collapse) {
                  return null;
                }
                if (prop.redirect) {
                  return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                }
                return (
                  <UnauthenticatedRoute
                    path={prop.path}
                    component={prop.component}
                    key={key}
                    props={{
                      isAuthenticated: this.props.isAuthenticated,
                      userHasAuthenticated: this.props.userHasAuthenticated,
                      set_current_user: this.props.set_current_user
                    }}
                  />
                );
              })}
            </Switch>
            <Footer white ref_code={this.state.ref_code} />
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const PagesContainer = connect(mapStateToProps)(Pages);

export default withStyles(style)(PagesContainer);
