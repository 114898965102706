import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import entitiesStyles from "./EntitiesStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';
//import EntityTableContainer from "../../../components/Entities/EntityTableContainer";

import Button from '../../../components/CustomButtons/Button';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
// import EntityNewContainer from "../../../components/Entities/EntityNewContainer";
import CountriesNewContainer from '../../../components/Countries/CountriesNewContainer';
import CountriesTableContainer from '../../../components/Countries/CountriesTableContainer';
import CountriesEditContainer from '../../../components/Countries/CountriesEditContainer';

const entitiesStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - COUNTRIES
 * ----------------------------------------------------------------------------
 */
class Countries extends React.Component {
  selectUi() {
    // console.log(this.props.countries.countries_current_ui);
    switch (this.props.countries.countries_current_ui) {
      case 'create':
        return <CountriesNewContainer />;
      case 'edit':
        console.log(this.props.countries);
        return <CountriesEditContainer country_id={this.props.countries.country_id} />;
      default:
        return <CountriesTableContainer />;
    }
  }

  render() {
    const {classes} = this.props;
    // console.log(this.props);
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>Countries Management Functions</h4>
              {/* <div>
                <Button
                  round
                  color={this.props.countries.countries_current_ui === 'list' ? 'rose' : null}
                  onClick={() => this.props.selectCountriesUi('list')}
                >
                  Show All
                </Button>
                <Button
                  round
                  color={this.props.countries.countries_current_ui === 'create' ? 'rose' : null}
                  onClick={() => this.props.selectCountriesUi('create')}
                >
                  Create New
                </Button>
                {this.props.countries.countries_current_ui === 'edit' && (
                  <Button
                    round
                    color={this.props.countries.countries_current_ui === 'edit' ? 'rose' : null}
                    onClick={() => this.props.selectCountriesUi('edit')}
                  >
                    Edit Record
                  </Button>
                )}
              </div> */}
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>{this.selectUi()}</GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

Countries.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(entitiesStyles)(Countries);
