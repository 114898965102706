import {API} from 'aws-amplify';

export const AppUrlConstants = {
  fetchCountries: () => API.get('countries', '/list_not_deleted', {}),
  fetchCurrencies: () => API.get("system", '/currencies/get-not-deleted', {}),
  fetchClients: () => API.get('clients', '/get-list-not-deleted', {}),
  fetchTeams: () => API.get('teams', '/get-list-not-deleted', {}),
  fetchBankAccounts: () => API.get('system', '/entitybanksaccount/get-all', {}),
  fetchEntityBanks: () => API.get('system', `/entitybanks/get-all`, {}),
  fetchEntity: () => API.get('system', `/entities/get-list-not-deleted`, {}),
  fetchCountryById: (id) => API.get('countries', `/get/${id}`, {}),
  updateCountryById: (id, payload) => API.put('countries', `/update/id/${id}`, {body: payload}),
  addCountryById: (payload) => API.post('countries', `/create/in-database`, {body: payload}),
  removeCountryById: (id) => API.get("countries", `/delete/id/${id}`, {})
}