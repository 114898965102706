import {connect} from 'react-redux';
import CurrenciesNew from './CurrenciesNew';
import {
  createNewCurrency,
  createNewCurrencyStart,
  createNewCurrencyComplete,
  cancelCreateNewCurrency,
  selectCurrenciesUi,
  currenciesListIsDirty
} from '../../redux/actions/currencies';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    staff: state.staff
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createNewCurrency: data => {
      dispatch(createNewCurrency(data));
    },
    createNewCurrencyStart: () => {
      dispatch(createNewCurrencyStart());
    },
    createNewCurrencyComplete: data => {
      dispatch(createNewCurrencyComplete(data));
    },
    cancelCreateNewCurrency: () => {
      dispatch(cancelCreateNewCurrency());
    },
    selectCurrenciesUi: value => {
      dispatch(selectCurrenciesUi(value));
    },
    currenciesListIsDirty: value => {
      dispatch(currenciesListIsDirty(value));
    }
  };
};

const CurrenciesNewContainer = connect(mapStateToProps, mapDispatchToProps)(CurrenciesNew);

export default CurrenciesNewContainer;
