import React, {useEffect, useState} from 'react';
import Card from '../../../Card/Card';
import {CardHeader} from '@material-ui/core';
import CardBody from '../../../Card/CardBody';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import {Button, Checkbox} from 'antd';
import {CompanyComplianceConstants} from './ComplianceConstants';
import TextArea from 'antd/lib/input/TextArea';

export const CompanyCompliance = ({state, onSelectedValuesChange, complianceData, onNoteChange, requiredFields}) => {

  const auCompanyOptions = CompanyComplianceConstants.auCompanyOptions;
  const foreignCompanyOptions = CompanyComplianceConstants.foreignCompanyOptions;
  const foreignUNRCompanyOptions = CompanyComplianceConstants.foreignURCompanyOptions;

  const [selectedValues, setSelectedValues] = useState([]);
  const [companyComplianceNote, setCompanyComplianceNote] = useState([]);

  const onChange = (e) => {
    const { checked, value } = e.target;
    setSelectedValues((prevSelectedValues) => {
      if (checked) {
        const data = [...prevSelectedValues, value];
        onSelectedValuesChange({checkBoxOptions: data, note: companyComplianceNote, complianceType: 'COMPANY'});
        return data;
      }
      const data = prevSelectedValues.filter((item) => item !== value);
      onSelectedValuesChange({checkBoxOptions: data, note: companyComplianceNote, complianceType: 'COMPANY'});
      return data;
    });
  };

  const [buttonText, setButtonText] = useState('Save');
  const handleNoteChange = () => {
    setButtonText('Saving...');
    onNoteChange(companyComplianceNote);
    setTimeout(() => {
      setButtonText('Save');
    }, 3000);
  }

  useEffect(() => {
    setSelectedValues(complianceData.checkBoxOptions);
    setCompanyComplianceNote(complianceData.note);
  }, [complianceData.checkBoxOptions, complianceData.note]);

  return (
    <React.Fragment>
      <Card className={'compliance-card'}>
        <CardHeader color='rose' icon>
          <h4>Company</h4>
        </CardHeader>
        <CardBody className={'pt-0'}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h3>Australian Company</h3>
              <hr/>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <ul className={'compliance-list'}>
                    {
                      auCompanyOptions.map(option => (
                        <li><Checkbox disabled={(!(state && state.edit_mode))} checked={selectedValues.includes(option.value)} onChange={onChange} key={option.value} value={option.value}>
                          {option.label} {requiredFields.includes(option.value) ? <span style={{color: 'red'}}> *Required</span> : <></> }
                        </Checkbox></li>
                      ))
                    }
                  </ul>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h3>Foreign Company</h3>
              <hr/>
              <p><i>Not Applicable at this time</i></p>
              {/*<GridContainer>*/}
              {/*  <GridItem xs={12} sm={12} md={12}>*/}
              {/*    <ul className={'compliance-list'}>*/}
              {/*      {*/}
              {/*        foreignCompanyOptions.map(option => (*/}
              {/*          <li>*/}
              {/*            <Checkbox onChange={onChange} key={option.value} value={option.value}>*/}
              {/*              {option.label}*/}
              {/*            </Checkbox>*/}
              {/*          </li>*/}
              {/*        ))*/}
              {/*      }*/}
              {/*    </ul>*/}
              {/*  </GridItem>*/}
              {/*</GridContainer>*/}
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h3>Foreign Unregistered Companies</h3>
              <hr/>
              <p><i>Not Applicable at this time</i></p>
              {/*<GridContainer>*/}
              {/*  <GridItem xs={12} sm={12} md={12}>*/}
              {/*    <ul className={'compliance-list'}>*/}
              {/*      {*/}
              {/*        foreignUNRCompanyOptions.map(option => (*/}
              {/*          <li>*/}
              {/*            <Checkbox disabled={(!(state && state.edit_mode))} onChange={onChange} key={option.value} value={option.value}>*/}
              {/*              {option.label}*/}
              {/*            </Checkbox>*/}
              {/*          </li>*/}
              {/*        ))*/}
              {/*      }*/}
              {/*    </ul>*/}
              {/*  </GridItem>*/}
              {/*</GridContainer>*/}
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h3>Note</h3>
              <hr/>
              <TextArea style={{backgroundColor: !state.edit_mode ? '#e0e0e0' : '#fff'}} value={companyComplianceNote} readOnly={(!(state && state.edit_mode))} rows={4} onChange={(e) => setCompanyComplianceNote(e.target.value)}/>
              <Button disabled={buttonText === 'Saving...' || !state.edit_mode } type={'primary'} size={'small'} className="note-save-box mt-1" onClick={() => handleNoteChange() }> {buttonText}</Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}