import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {FormattedMessage} from 'react-intl';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import {API} from 'aws-amplify';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';

import ClientDocumentEdit from '../../../features/Clients/ClientDocumentEdit.jsx';
import {Typography} from '@material-ui/core';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
const clientDocumentStyles = {
  cardTitle,
  ...customCheckboxRadioSwitch,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  clientFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '300px'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardPadding: {
    padding: '0px 12px 16px',
    margin: '8px'
  },
  editFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    maxWidth: '350px',
    margin: '16px 0'
  }
};

const ClientDocumentEditContainer = props => {
  const {classes, match} = props;
  const clientDocumentId = match.params.document_id;

  const [clientDocumentData, setClientDocumentData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchClientDocumentById = id => {
    if (!id) {
      return;
    }
    setLoading(true);
    API.get('client-documents', `/${id}`)
      .then(response => {
        setClientDocumentData(response);
      })
      .catch(error => {
        console.log(error);
        API.get('client-documents', `/${id}`)
          .then(response => {
            setClientDocumentData(response);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchClientDocumentById(clientDocumentId);
  }, [clientDocumentId]);

  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardIcon color='rose'>
          <PermIdentity />
        </CardIcon>
        <div className={classes.flexEnd}>
          <h4 className={classes.cardIconTitle}>
            <FormattedMessage
              id='clientDocument.cardItem1.clientDocument'
              defaultMessage={'Client Document Management'}
            />
          </h4>
        </div>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            {loading ? (
              <Typography>Loading...</Typography>
            ) : (
              <ClientDocumentEdit
                classes={classes}
                clientDocumentData={clientDocumentData}
                clientDocumentId={clientDocumentId}
              />
            )}
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

ClientDocumentEditContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(clientDocumentStyles)(ClientDocumentEditContainer);
