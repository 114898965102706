import React from 'react';
import {connect} from 'react-redux';
import {fetchBeneficiaryList, setBeneficiarySelectId} from '../../../redux/actions/beneficiaries';
import withStyles from '@material-ui/core/styles/withStyles';

import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {FormattedMessage} from 'react-intl';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import BeneficiaryEditContainer from '../../../components/Beneficiaries/BeneficiaryEditContainer';
import { Button } from 'antd'
const clientsNewStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  btnMr5: {
      marginRight: '5px',
      textTransform: 'uppercase'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientsNew
 * ----------------------------------------------------------------------------
 */
class BeneficiariesEdit extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const {classes} = this.props;
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage
                  id='BeneficiaryFunction.cardItem1.title'
                  defaultMessage={`Beneficiary Management Functions`}
                />
              </h4>
              <div style={{marginTop: '15px'}}>
                <Button className={classes.btnMr5} type="primary" onClick={() => this.props.history.push('/beneficiaries/list')}>
                  <FormattedMessage id='newClient.cardItem1.showAll' defaultMessage={`Show All`} />
                </Button>
                <Button className={classes.btnMr5} type={"primary"} danger>
                  <FormattedMessage id='editClient.showRecord' defaultMessage={`Show Record`} />
                </Button>
                <Button className={classes.btnMr5} type="primary" onClick={() => this.props.history.push('/beneficiaries/new')}>
                  <FormattedMessage id='newBeneficiary.cardItem1.createNew' defaultMessage={`Create New`} />
                </Button>
                <Button className={classes.btnMr5} type={'primary'} disabled>
                  <FormattedMessage id='newClient.cardItem1.refresh' defaultMessage={`Refresh`} />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                {/*<BeneficiaryEditContainer beneficiary_detail_id={this.props.beneficiaries.beneficiary_detail_id}/>*/}
                <BeneficiaryEditContainer beneficiary_detail_id={this.props.match.params.beneficiary_detail_id} history={this.props.history} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

// BeneficiariesEdit.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withStyles(clientsNewStyles)(BeneficiariesEdit);
BeneficiariesEdit =  withStyles(clientsNewStyles)(BeneficiariesEdit);
const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    beneficiaries: state.beneficiaries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchBeneficiaryList: () => {
      dispatch(fetchBeneficiaryList());
    },
    setBeneficiarySelectId: id => {
      dispatch(setBeneficiarySelectId(id));
    }
    // selectClientUi: (data) => {
    //   dispatch(selectClientUi(data))
    // },
  };
};

const BeneficiariesEditContainer = connect(mapStateToProps, mapDispatchToProps)(BeneficiariesEdit);

export default BeneficiariesEditContainer;
