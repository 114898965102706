import React from 'react';
// import PropTypes from "prop-types";
import {connect} from 'react-redux';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import clientsNewStyles from "./ClientsNewStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {FormattedMessage} from 'react-intl';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import Button from '../../../components/CustomButtons/Button';
import SimpleClientEditContainerComponent from '../../../components/Clients/SimpleEdit';

const clientsNewStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientsNew
 * ----------------------------------------------------------------------------
 */
class SimpleClientsEdit extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const {classes} = this.props;
    // console.log(this.props.match.params.client_id);
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id='ClientFunction.cardItem1.title' defaultMessage={'Client Management Functions'} />
              </h4>
              <div>
                <Button round color='info' onClick={() => this.props.history.push('/clients/list')}>
                  <FormattedMessage id='newClient.cardItem1.showAll' defaultMessage={'Show All'} />
                </Button>
                <Button round color='primary'>
                  <FormattedMessage id='editClient.showRecord' defaultMessage={'Show Record'} />
                </Button>
                <Button round color='info' onClick={() => this.props.history.push('/clients/new')}>
                  <FormattedMessage id='newClient.cardItem1.createNew' defaultMessage={'Create New'} />
                </Button>
                <Button
                  round
                  color='info'
                  disabled
                  // onClick={() => this.props.fetchClientList()}
                >
                  <FormattedMessage id='newClient.cardItem1.refresh' defaultMessage={'Refresh'} />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                {/* <ClientEditContainer client_detail_id={this.props.clients.client_detail_id}/> */}
                <SimpleClientEditContainerComponent client_detail_id={this.props.match.params.client_id} />
                {/*<ClientEditContainer client_detail_id="52"/>*/}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

// ClientsEdit.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withStyles(clientsNewStyles)(ClientsEdit);
const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const SimpleClientsEditContainer = connect(mapStateToProps, mapDispatchToProps)(SimpleClientsEdit);

export default withStyles(clientsNewStyles)(SimpleClientsEditContainer);
