import React from 'react';
import {connect} from 'react-redux';

import {updateNewClientCreation} from '../../../../redux/actions/clients';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import Card from '../../../../components/Card/Card.jsx';
import CardBody from '../../../../components/Card/CardBody.jsx';
import CardHeader from '../../../../components/Card/CardHeader';

import {cardTitle} from '../../../../assets/jss/material-dashboard-pro-react';

const style = {
  ...customCheckboxRadioSwitch,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  }
};

class Step5Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  sendState() {
    return this.state;
  }

  isValidated() {
    return true
  }


  render() {
    const {classes} = this.props;
    if (!this.props.clients.new_client_data) {
      return null;
    }
    return (
      <React.Fragment>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Confirmation</h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify='center'>
                  <GridItem xs={12} sm={6}>
                    <p>
                      <strong>When you click "Finish" below:</strong><br/>
                      <ul>
                        {this.props.clients.new_client_data.company_name &&
                          <li>
                            <strong>Company: </strong> {this.props.clients.new_client_data.company_name}
                          </li>
                        }

                        {this.props.clients.new_client_data.trust_name && (
                          <li>
                            <strong>Trust: </strong> {this.props.clients.new_client_data.trust_name}
                          </li>
                        )}

                        {(!this.props.clients.new_client_data.company_name &&
                          !this.props.clients.new_client_data.trust_name) && (
                          <li>
                            Individual
                            Account: {this.props.clients.new_client_data.first_name} {this.props.clients.new_client_data.last_name}.
                          </li>
                        )}
                        <li>
                          <strong>Authorised
                            Contact: </strong>{this.props.clients.new_client_data.first_name} {this.props.clients.new_client_data.last_name}
                        </li>
                        <li>
                          <strong>Email: </strong>{this.props.clients.new_client_data.email}
                        </li>

                      </ul>
                    </p>
                    <p>
                      A client portal login will <strong>NOT</strong> be created for this client immediately.<br/>
                      You will be able to create one however immediately following (if you wish).
                    </p>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

// export default withStyles(style)(Step5Confirm);
Step5Confirm =  withStyles(style)(Step5Confirm);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewClientCreation: (key, value) => {
      dispatch(updateNewClientCreation(key, value));
    }
  };
};

const Step5ConfirmContainer = connect(mapStateToProps, mapDispatchToProps)(Step5Confirm);

export default Step5ConfirmContainer;
