import React from 'react';
import {API} from 'aws-amplify';
import {connect} from 'react-redux';
import Button from 'components/CustomButtons/Button.jsx';
import {updateNewTransferCreationTransferType, updateNewTransferCreation} from 'redux/actions/transfers_2';

import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';

import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';

// const uuidv4 = require('uuid/v4');

const style = {
  ...customCheckboxRadioSwitch,

  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {color: '#555'},
  inputAdornment: {position: 'relative'}
};

class Step1Client extends React.Component {
  constructor(props) {
    super(props);
    this.handleUseTeamClient = this.handleUseTeamClient.bind(this);
    this.state = {
      client_list: [],
      client_id: '',
      client: {},
      team_nickname: '',
      teamClientId: null,
      // division_nickname: '',
      // entity_nickname: '',
      with_query_string: false
    };
  }

  componentDidMount() {
    API.get('clients', '/get-list-not-deleted')
      .then(response => {
        // console.log(response);
        this.setState({client_list: response}, () => {
          this.queryStrings();
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  queryStrings() {
    const query_strings = queryString.parse(this.props.location.search);
    if (Object.keys(query_strings).length !== 0) {
      if (query_strings.client_id) {
        this.setState({client_id: Number(query_strings.client_id)});
        this.props.updateNewTransferCreation('client_id', Number(query_strings.client_id));
        this.getClientDetails(Number(query_strings.client_id));
        this.setState({with_query_string: true});
      }
      if (query_strings.clientId) {
        this.setState({client_id: Number(query_strings.clientId)});
        this.props.updateNewTransferCreation('client_id', Number(query_strings.clientId));
        this.getClientDetails(Number(query_strings.clientId));
        this.setState({with_query_string: true});
      }
    }
  }

  getClientDetails(client_id) {
    API.get('clients', `/get/id/${client_id}`)
      .then(response => {
        this.setState({client: response});

        this.props.updateNewTransferCreation('client', response);
        return [response.team_id, response.account_status];
      })
      .then(([team_id, account_status]) => {
        this.props.updateNewTransferCreation('team_id', team_id);
        API.get('teams', `/get/id/${team_id}`)
          .then(response => {
            // console.log(response)
            this.setState({
              client: {
                ...this.state.client,
                team_nickname: response.nickname
              }
            });
            if (response.teamClientId === client_id) {
              this.setState({teamClientId: client_id});
              this.props.updateNewTransferCreation('teamClientId', client_id);
            }
            // this.props.updateNewTransferCreation('division_id', response.division_id);

            if (this.state.client_id != 55) {
              API.post('email', '/interaction/main', {
                body: {
                  interaction: 'New Transfer Request Starting',
                  data: {
                    staff_id: this.props.app_state.current_staff.id,
                    staff_nickname: this.props.app_state.current_staff.nickname,
                    client_id: this.state.client.id,
                    client_nickname: this.state.client.mickname
                  },
                  result: 'New transfer request starting'
                }
              });
            }
            return account_status;
          })
          // Get account status nickname from account status id
          .then(account_status => {
            API.get('clients', '/client-status/get')
              .then(response => {
                let obj;
                for (obj of response) {
                  if (obj.id === account_status) {
                    this.setState({
                      client: {
                        ...this.state.client,
                        account_status_nickname: obj.nickname
                      }
                    });
                  }
                }
              })
              .catch(error => {
                console.log(error);
              });
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  }

  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});

    this.props.updateNewTransferCreation(stateName, event.target.value);
  }

  handleSelectChange = event => {
    this.setState({[event.target.name]: event.target.value});
    this.props.updateNewTransferCreation(event.target.name, event.target.value);
    if (event.target.name === 'client_id') {
      this.getClientDetails(event.target.value);
    }
  };

  isValidated() {
    if (this.state.client_id > 0) {
      return true;
    }
    return false;
  }

  handleClientSelectChange = event => {
    this.setState({client_id: event.value});
    this.props.updateNewTransferCreation('client_id', event.value);
    this.getClientDetails(event.value);
  };

  handleUseTeamClient = () => {
    const value = this.props.app_state.current_team.teamClientId;
    if (value) {
      this.setState({client_id: value});
      this.props.updateNewTransferCreation('client_id', value);
      this.getClientDetails(value);
    }
  };

  render() {
    const {classes} = this.props;

    const client_list_select_options = this.state.client_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return {
          value: item.id,
          label: `${item.nickname} [${item.id.toString().padStart(5, '0')}] (${item.email})`
        };
      });

    return (
      <React.Fragment>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              {this.state.with_query_string ? 'Please confirm client details for new transfer' : ''}
            </h4>
          </GridItem>
        </GridContainer>

        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <GridContainer>
              <GridItem xs={10} sm={10} md={10} lg={10}>
                <CustomReactSelect
                  label='Select client'
                  options={client_list_select_options}
                  value={this.state.client_id}
                  onChange={this.handleClientSelectChange}
                />
              </GridItem>
              {/* <GridItem xs={2} sm={2} md={2} lg={2}>
                <Button
                  disabled={this.props.app_state.current_team && this.props.app_state.current_team.teamClientId == null}
                  onClick={this.handleUseTeamClient}
                >
                  Use Team Client
                </Button>
              </GridItem> */}
            </GridContainer>
          </GridItem>
        </GridContainer>

        {Boolean(this.state.client_id) && (
          <React.Fragment>
            <p>
              <br />
              Please confirm the following details are correct
            </p>

            {this.state.client.account_type == 2 && (
              <p>
                <em style={{color: 'green'}}>
                  {this.state.client.business_company_name && (
                    <span>
                      <b>Bussiness Name: {this.state.client.business_company_name.toUpperCase()} </b>
                    </span>
                  )}
                </em>
              </p>
            )}

            {/* if the client status is not 2 (approved) then display warning */}
            {this.state.client.account_status !== 2 && (
              <p>
                <em style={{color: 'red'}}>
                  {this.state.client.account_status_nickname && (
                    <span>
                      Warning: this client has status {this.state.client.account_status_nickname.toUpperCase()}{' '}
                    </span>
                  )}
                </em>
              </p>
            )}

            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <GridContainer justify='center'>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <CustomInput
                      labelText='First Name'
                      id='first_name'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: true,
                        value: this.state.client.first_name || ''
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <CustomInput
                      labelText='Middle Name(s)'
                      id='middle_name'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: true,
                        value: this.state.client.middle_name || ''
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <CustomInput
                  labelText='Last Name'
                  id='last_name'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: true,
                    value: this.state.client.last_name || ''
                  }}
                />
                <CustomInput
                  labelText='Email'
                  id='email'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: true,
                    value: this.state.client.email || ''
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={6}>
                <CustomInput
                  labelText='Team'
                  id='team_nickname'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: true,
                    value: this.state.client.team_nickname || ''
                  }}
                />{' '}
                <br />
                {this.props.transfers_2.new_transfer_data.teamClientId && (
                  <CustomInput
                    labelText='Team Client'
                    // id='team_nickname'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: true,
                      value: `This client is the TEAM client for ${this.state.client.team_nickname}`
                    }}
                  />
                )}
              </GridItem>
            </GridContainer>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers_2: state.transfers_2,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewTransferCreationTransferType: transfer_type => {
      dispatch(updateNewTransferCreationTransferType(transfer_type));
    },
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    }
  };
};

const Step1ClientContainer = connect(mapStateToProps, mapDispatchToProps)(Step1Client);

export default withRouter(withStyles(style)(Step1ClientContainer));
