import React from 'react';
import PropTypes from 'prop-types';
import {API} from 'aws-amplify';

import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Button from '../../CustomButtons/Button';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react';
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
var moment = require('moment');

const styles = {
  ...switchStyles,
  ...selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class TransferDetailPayoutCommunication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payout_advice_email_sending: false,
      payout_advice_email_sent: false,
      payout_email_sending: false,
      emailsent_payout_staff: null,

      transfer_status: this.props.transfer_status
    };
  }

  componentDidMount() {
    if (this.props.emailsent_payout_staff) {
      console.log(this.props.emailsent_payout_staff)
       API.get('staff', `/get/${this.props.emailsent_payout_staff}`)
        .then(response => {
          console.log(response)
          this.setState({
            emailsent_payout_staff: response.nickname,
          });
        })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log(prevProps)
    if (prevProps.transferId !== this.props.transferID) {
      // console.log(this.props.transferID)
    }
    if (prevProps.emailsent_payout_staff !== this.props.emailsent_payout_staff) {
      console.log(this.props.emailsent_payout_staff)
      if (!this.props.emailsent_payout_staff) { return; }
       API.get('staff', `/get/${this.props.emailsent_payout_staff}`)
        .then(response => {
          console.log(response)
          this.setState({
            emailsent_payout_staff: response.nickname,
          });
        })
    }

  }


  // handle_send_payout_advice = async event => {
  //   // event.preventDefault();
  //   // console.log(this.props);
  //   this.setState({
  //     remittance_advice_email_sending: true
  //   });
  //   API.post('email', `/client/payout-advice`, {
  //     body: {
  //       id: this.props.id,
  //       transfer_id: this.props.transfer_id,
  //       rate: this.props.rate,
  //       amount_from: this.props.amount_from,
  //       amount_to: this.props.amount_to,
  //       currency_from: this.props.currency_from,
  //       currency_to: this.props.currency_to,
  //       rate: this.props.rate,
  //       settlement_date: this.props.settlement_date,
  //       beneficiary_id: this.props.beneficiary_id,
  //       language_id: this.props.language_id,
  //       payouts_list: this.props.payouts_list
  //       // amount_from: this.props.amount_from,
  //       // eba_account_name: this.props.eba_account_name,
  //       // remitting_bank_id: this.props.remitting_bank_id,
  //       // remittance_reference: this.props.remittance_reference
  //     }
  //   })
  //     .then(response => {
  //       // console.log(response);
  //       this.setState({
  //         remittance_advice_email_sent: true,
  //         remittance_advice_email_sending: false
  //       });
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };
  handle_send_payout_advice_new = async event => {
    // event.preventDefault();
    // console.log(this.props);
    this.setState({
      payout_email_sending: true
    });
    API.post('email', `/client/payout-advice-new`, {
      body: {
        id: this.props.id,
        currentStaffID: this.props.currentStaffID,
        transfer_id: this.props.transfer_id,
        transferID: this.props.transferID,
        rate: this.props.rate,
        amount_from: this.props.amount_from,
        amount_to: this.props.amount_to,
        currency_from: this.props.currency_from,
        currency_to: this.props.currency_to,
        rate: this.props.rate,
        settlement_date: moment(this.props.settlement_date).format("DD-MM-YYYY"),
        beneficiary_id: this.props.beneficiary_id,
        language_id: this.props.language_id,
        payouts_list: this.props.payouts_list
        // amount_from: this.props.amount_from,
        // eba_account_name: this.props.eba_account_name,
        // remitting_bank_id: this.props.remitting_bank_id,
        // remittance_reference: this.props.remittance_reference
      }
    })
      .then(response => {
        console.log(response);
        this.setState({
          // remittance_advice_email_sent: true,
          payout_email_sending: false,
          emailsent_payout_date: moment().format('YYYY-MM-DD HH:mm:ss'),
          emailsent_payout_staff: `You have just sent this email`
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
  handle_send_payout_advice_staff = async event => {
    // event.preventDefault();
    console.log(this.props);
    this.setState({
      payout_email_sending: true
    });
    API.post('email', `/client/payout-advice-new`, {
      body: {
        id: this.props.id,
        currentStaffID: this.props.currentStaffID,
        transfer_id: this.props.transfer_id,
        transferID: this.props.transferID,
        rate: this.props.rate,
        amount_from: this.props.amount_from,
        amount_to: this.props.amount_to,
        currency_from: this.props.currency_from,
        currency_to: this.props.currency_to,
        rate: this.props.rate,
        settlement_date: moment(this.props.settlement_date).format("DD-MM-YYYY"),
        beneficiary_id: this.props.beneficiary_id,
        language_id: this.props.language_id,
        payouts_list: this.props.payouts_list,
        staffOnly: true
        // amount_from: this.props.amount_from,
        // eba_account_name: this.props.eba_account_name,
        // remitting_bank_id: this.props.remitting_bank_id,
        // remittance_reference: this.props.remittance_reference
      }
    })
      .then(response => {
        console.log(response);
        this.setState({
          payout_email_sending: false,
          // remittance_advice_email_sent: true,
          // remittance_advice_email_sending: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const {classes} = this.props;
    // console.log(this.props);
    // console.log(this.props.payouts_list)

    const emailsent_payout_date = this.state.emailsent_payout_date || this.props.emailsent_payout_date
    const emailsent_payout_staff = this.state.emailsent_payout_staff


    let disableEmails = false;
    if (!this.props.enabled){ disableEmails = true; }
    if (this.props.payoutDifference != 0 && this.props.payouts_list.length) { disableEmails = true; }
    if (this.props.transfer_status != 8) { disableEmails = true; }
    if (this.props.transfer_status != 8) { disableEmails = true; }
    if (this.props.payouts_list.length && !this.props.payouts_list[0].nickname && !this.props.payouts_list[0].holdingAccountPayee ) { disableEmails = true;}


    // console.log(this.state.transfer_status);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button
              color='info'
              style={{width: '100px'}}
              disabled={this.props.transfer_status != 8}
              size='sm'
              onClick={() => {
                var win = window.open(`https://api.neutronapi.com/pdfservice/api/fww/receipt/${this.props.transfer_id}`, '_blank');
                win.focus();
              }}
            >
              View Receipt
            </Button> View Client Receipt
            <br/>
            
            <Button
              color='info'
              style={{width: '100px'}}
              disabled={disableEmails}
              size='sm'
              onClick={this.handle_send_payout_advice_staff}
            >
              Email Staff
            </Button> Email STAFF - {this.props.current_staff_email})
            <br/>
            <Button
              color='primary'
              style={{width: '100px'}}
              disabled={disableEmails}
              size='sm'
              onClick={this.handle_send_payout_advice_new}
            >
              Email Client
              </Button> Email to CLIENT

            {this.props.payoutDifference != 0 &&
            <span style={{color: 'red', fontWeight: 800}}>
              <br/>
              WARNING: Payouts total amount is not equal to transfer amount.  Please advise SysAdmin
            </span>
            }
            {this.props.payouts_list.length == 0 &&
            <span style={{color: 'red', fontWeight: 800}}>
              <br/>
              WARNING: Payouts list is empty.  Please advise SysAdmin
            </span>
            }
            {this.props.payouts_list.length > 0 && !(this.props.payouts_list[0].nickname) && !(this.props.payouts_list[0].holdingAccountPayee) &&
            <span style={{color: 'red', fontWeight: 800}}>
            <br/>
            WARNING: Payouts beneficiary nickname is missing.  Please advise SysAdmin
            </span>
            }
            {/* {!this.props.enabled && <span>(insufficient security level)</span>} */}

            {this.state.payout_email_sending && <span><br/>(email sending)</span>}
            <br/>
            <span>Sent By: {emailsent_payout_staff ? emailsent_payout_staff : 'n/a'}</span><br/>
            <span>Email sent: {emailsent_payout_date ? moment(emailsent_payout_date).format("DD-MM-YYYY @ HH:mm:ss") : "n/a"}</span>

          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

TransferDetailPayoutCommunication.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(TransferDetailPayoutCommunication));
