import {
  STAFF_SELECT_STAFF_MEMBER,
  TRANSFERS_FETCH_TRANSFER_LIST_REQUEST,
  TRANSFERS_FETCH_TRANSFER_LIST_SUCCESS,
  // TRANSFERS_FETCH_TRANSFER_LIST_FAILURE,
  // STAFF_CREATE_NEW_SHOW_UI,
  // STAFF_CREATE_NEW_START,
  // STAFF_CREATE_NEW_CANCEL,
  // STAFF_CREATE_NEW_COMPLETE,
  // STAFF_EDIT_SHOW_UI,
  TRANSFERS_SELECT_UI,
  TRANSFERS_SELECT_SET_ID,
  // CLIENTS_SELECT_CLIENT_REQUEST,
  // CLIENTS_SELECT_CLIENT_SUCCESS,
  TRANSFERS_FETCH_TRANSFER_REQUEST,
  TRANSFERS_FETCH_TRANSFER_SUCCESS,
  TRANSFERS_START_NEW_TRANSFER_CREATION,
  TRANSFERS_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE,
  CLIENTS_UPDATE_NEW_CLIENT_CREATION_EMAIL,
  CLIENTS_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME,
  CLIENTS_UPDATE_NEW_CLIENT_CREATION_LASTNAME,
  TRANSFERS_UPDATE_NEW_TRANSFER_CREATION,
  TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_REQUEST,
  TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_SUCCESS,
  TRANSFERS_CLEAR_NEW_TRANSFER
} from '../actions/transfers.js';
// import { CLIENTS_START_NEW_CLIENT_DATABASE_CREATE } from "../actions/clients";

const initialState = {
  transfer_current_ui: 'list',
  is_fetching: false,
  did_invalidate: false,
  transfer_list: [],
  transfer_detail_fetching: false,
  transfer_detail_loaded: false,
  transfer_detail_id: null,
  new_transfer_data: {},
  new_transfer_type: null,
  new_transfer_submitted: false,
  new_transfer_created: false,
  current_rate_locked: false
  // client_detail: null,
  // ui_show_create_new_staff_screen: false,
  // ui_show_edit_staff_screen: true,
  // staff_create_new_in_process: false,
  // staff_create_new_awaiting_confirmation: false
};

function clients(state = initialState, action) {
  switch (action.type) {
    case STAFF_SELECT_STAFF_MEMBER:
      // console.log(action);
      return Object.assign({}, state, {
        staff_detail_id: action.staff_id
      });

    case TRANSFERS_FETCH_TRANSFER_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
        // console.log(action);
      });

    case TRANSFERS_FETCH_TRANSFER_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        transfer_list: action.transfer_list,
        lastUpdated: action.receivedAt
      });

    // case STAFF_CREATE_NEW_SHOW_UI:
    //   return Object.assign({}, state, {
    //     // ui_show_create_new_staff_screen: action.value,
    //     // ui_show_edit_staff_screen: false,
    //     staff_current_ui: "list"
    //   });

    // case STAFF_CREATE_NEW_START:
    //   return Object.assign({}, state, {
    //     staff_create_new_in_process: true,
    //     staff_create_new_awaiting_confirmation: true
    //   });
    //
    // case STAFF_CREATE_NEW_CANCEL:
    //   return Object.assign({}, state, {
    //     staff_create_new_in_process: false,
    //     staff_create_new_awaiting_confirmation: false,
    //     staff_current_ui: "list"
    //   });
    //
    // case STAFF_CREATE_NEW_COMPLETE:
    //   return Object.assign({}, state, {
    //     staff_create_new_in_process: false,
    //     staff_create_new_awaiting_confirmation: false,
    //     // ui_show_create_new_staff_screen: false,
    //     staff_current_ui: "list"
    //   });
    //
    case TRANSFERS_SELECT_UI:
      return Object.assign({}, state, {
        transfer_current_ui: action.value
      });
    //
    case TRANSFERS_SELECT_SET_ID:
      return Object.assign({}, state, {
        transfer_detail_id: action.value
      });
    //
    case TRANSFERS_FETCH_TRANSFER_REQUEST:
      return Object.assign({}, state, {
        // staff_detail: null,
        // staff_detail_id: action.staff_id,
        transfer_detail_fetching: true,
        transfer_detail_loaded: false
        // client_detail: null,
        // staff_detail_loaded: false
      });

    case TRANSFERS_FETCH_TRANSFER_SUCCESS:
      return Object.assign({}, state, {
        // staff_detail: action.staff_detail,
        transfer_detail_fetching: false,
        transfer_detail_loaded: true
        // staff_detail_loaded: true
      });

    case TRANSFERS_START_NEW_TRANSFER_CREATION:
      return Object.assign({}, state, {
        // staff_detail: action.staff_detail,
        new_transfer_submitted: false
        // client_detail_loaded: true
        // staff_detail_loaded: true
      });
    case TRANSFERS_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE:
      return Object.assign({}, state, {
        // staff_detail: action.staff_detail,
        // new_client_data: null,
        new_transfer_type: action.value
        // client_detail_loaded: true
        // staff_detail_loaded: true
      });
    case CLIENTS_UPDATE_NEW_CLIENT_CREATION_EMAIL:
      return Object.assign({}, state, {
        // staff_detail: action.staff_detail,
        new_client_data: Object.assign({}, state.new_client_data, {
          email: action.value
        })
        // new_client_type: action.value
        // client_detail_loaded: true
        // staff_detail_loaded: true
      });
    case CLIENTS_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME:
      return Object.assign({}, state, {
        new_client_data: Object.assign({}, state.new_client_data, {
          first_name: action.value
        })
      });
    case CLIENTS_UPDATE_NEW_CLIENT_CREATION_LASTNAME:
      return Object.assign({}, state, {
        new_client_data: Object.assign({}, state.new_client_data, {
          last_name: action.value
        })
      });
    case TRANSFERS_UPDATE_NEW_TRANSFER_CREATION:
      return Object.assign({}, state, {
        new_transfer_data: Object.assign({}, state.new_transfer_data, {
          [action.value.key]: action.value.value
        })
      });
    case TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_REQUEST:
      return Object.assign({}, state, {
        new_transfer_submitted: true,
        new_transfer_created: false
      });
    case TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_SUCCESS:
      return Object.assign({}, state, {
        new_transfer_submitted: false,
        new_transfer_data: {},
        new_transfer_created: true,
        transfer_detail_id: action.value
      });

    case TRANSFERS_CLEAR_NEW_TRANSFER:
      return Object.assign({}, state, {
        new_transfer_submitted: false,
        new_transfer_data: {},
        new_transfer_created: true
      });
    default:
      return state;
  }
}

export default clients;
