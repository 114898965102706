import React, {useEffect, useState} from 'react';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import API from '@aws-amplify/api';
import { useSelector, useDispatch } from 'react-redux'
import {updateCurrentTeam} from '../../redux/actions';

import {
  onInAppLoginSuccesStaffID
} from '../../redux/actions'

import {Input, Select} from 'antd';
const { Search } = Input;

export const ClientDropdownList = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    backOffice: false,
    loading: true,
    clients: [],
    teamSelected: null,
    teams: [],
    isVisible: false,
  });

  const { current_staff } = useSelector(state => state.app_state);

  const onSearch = (staffID) => {
    setState(prev => ({...prev, selected: staffID }));
    dispatch(onInAppLoginSuccesStaffID(staffID, current_staff.aws_cognito_id));
  }

  const fetchClients = async () => {
    const [response] = await Promise.all([
      API.get('staff', '/get-list-not-deleted', {}),
    ])
    setState({
      isVisible: true,
      loading: false,
      clients: response.map(item => ({label: `[${item.id}] ${item.nickname}`, value: item.id})),
    })
    return response
  }

  useEffect(() => {
    if (current_staff && current_staff.super_admin === 1) {
        fetchClients();
        setState({
                    isVisible: true,
                })
    }
}, current_staff);

  return (
    <React.Fragment>
      {state.isVisible && (
          <Select showSearch
                  allowClear
                  onClear={() => window.location.reload()}
                  style={{ width: '200px' }}
                  onChange={(event) => onSearch(event)}
                  placeholder="Search Client"
                  filterOption={(input, option) => (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                      (optionA && optionA.label ? optionA.label : '').toLowerCase().localeCompare((optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase())
                  }
                  options={state.clients || []}></Select>
            )}
    </React.Fragment>
  );
};
