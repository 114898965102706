import {connect} from 'react-redux';

import {selectEntitybanksUi, fetchEntitybanksListNotDeleted} from '../../../redux/actions/entitybanks';

import Entitybanks from './Entitybanks';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    entity_banks: state.entity_banks
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectEntitybanksUi: value => {
      dispatch(selectEntitybanksUi(value));
    },
    fetchEntitybanksListNotDeleted: () => {
      dispatch(fetchEntitybanksListNotDeleted());
    }
  };
};

const EntitybanksContainer = connect(mapStateToProps, mapDispatchToProps)(Entitybanks);

export default EntitybanksContainer;
