import React from 'react';
import {connect} from 'react-redux';

import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import clientsNewStyles from "./ClientsNewStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {FormattedMessage} from 'react-intl';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import Button from '../../../components/CustomButtons/Button';

const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - WorldPayResponse - copied from TransfersEdit - #1054 Improvement by Tom
 * ----------------------------------------------------------------------------
 */
class WorldPayResponse extends React.Component {
  // constructor(props) {
  //   super(props);
  // }
  constructor(props) {
    super(props);
  }

  // componentDidMount = () => {
  // };

  renderSwitch(param) {
    switch (param) {
      case 'success':
        return 'The Credit Card transaction has been success.';
      case 'cancel':
        return 'The Credit Card transaction has been cancelled.';
      case 'failure':
        return 'The Credit Card transaction has been failed.';
      case 'error':
        return 'The Credit Card transaction has been dismissed due to error.';
      default:
        return '';
    }
  }

  render() {
    const {classes} = this.props;
    // console.log(this.props);
    let result = this.props.match.params.result;
    let transfer_id = this.props.match.params.transfer_id;

    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage
                  id='TransferFunction.cardItem1.title'
                  defaultMessage={`Transfer Management Functions`}
                />
              </h4>
              <div>
                <Button round color='info' onClick={() => this.props.history.push('/transfers/list')}>
                  <FormattedMessage id='newClient.cardItem1.showAll' defaultMessage={`Show All`} />
                </Button>
                <Button round color='primary'>
                  <FormattedMessage id='editClient.showRecord' defaultMessage={`Show Record`} />
                </Button>
                <Button round color='info' onClick={() => this.props.history.push('/transfers/new')}>
                  <FormattedMessage id='newTransfer.cardItem1.createNew' defaultMessage={`Create New`} />
                </Button>
                <Button
                  round
                  color='info'
                  disabled
                  // onClick={() => this.props.fetchTransferList()}
                >
                  <FormattedMessage id='newClient.cardItem1.refresh' defaultMessage={`Refresh`} />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <p>
                  {/*{result}*/}
                  {this.renderSwitch(result)}
                  <br />
                  {transfer_id}
                </p>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

//= Code sample of propTypes =
// TransfersEdit.propTypes = {
//   classes: PropTypes.object.isRequired
// };
//

const mapStateToProps = state => {
  return {
    app_state: state.app_state
    // transfers: state.transfers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // fetchTransferList: () => {
    //   dispatch(fetchTransferList())
    // },
    // setTransferSelectId: (id) => {
    //   dispatch(setTransferSelectId(id))
    // },
    // selectTransferUi: (data) => {
    //   dispatch(selectTransferUi(data))
    // },
  };
};

const WorldPayResponseContainer = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WorldPayResponse));

export default WorldPayResponseContainer;
