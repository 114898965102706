// LTE stands for Less Then or Equal

const isLTE = (val, maxLength) => {
  return val && val.length <= maxLength;
};

const isEmailLTE = (val, maxLength) => {
  return (
    val &&
    val.length <= maxLength &&
    String(val)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  );
};

const requiredChar = (val, length) => {
  return (
    val &&
    val.length == length
  )
}

const validFields = [
  {name: 'legal_name', label: 'Legal Name', validationRule: val => isLTE(val, 64)},
  {name: 'currency', label: 'Currency', validationRule: val => requiredChar(val, 3)},
  {name: 'address_line_1', label: 'Address Line 1', validationRule: val => isLTE(val, 64)},
  {name: 'address_line_2', label: 'Address Line 2', validationRule: val => isLTE(val, 64)},
  {name: 'address_suburb', label: 'Address Suburb', validationRule: val => isLTE(val, 64)},
  {name: 'address_state', label: 'Address State', validationRule: val => isLTE(val, 64)},
  {name: 'address_postcode', label: 'Address Postcode', validationRule: val => isLTE(val, 64)},
  {name: 'address_country', label: 'Address Country', validationRule: val => requiredChar(val, 2)},
  {name: 'bank_legal_name', label: 'Bank Legal Name', validationRule: val => isLTE(val, 64)},
  {name: 'bank_address_line_1', label: 'Bank Address Line 1', validationRule: val => isLTE(val, 64)},
  {name: 'bank_address_line_2', label: 'Bank Address Line 2', validationRule: val => isLTE(val, 64)},
  {name: 'bank_address_suburb', label: 'Bank Address Suburb', validationRule: val => isLTE(val, 64)},
  {name: 'bank_address_state', label: 'Bank Address State', validationRule: val => isLTE(val, 64)},
  {name: 'bank_address_postcode', label: 'Bank Address Postcode', validationRule: val => isLTE(val, 64)},
  {name: 'bank_address_country', label: 'Bank Address Country', validationRule: val => requiredChar(val, 2)},
  {name: 'swift_code', label: 'Swift Code', validationRule: val => isLTE(val, 64)},
  {name: 'account_number', label: 'Account Number', validationRule: val => isLTE(val, 64)},
  {name: 'aba_routing_number', label: 'Aba Routing Number', validationRule: val => isLTE(val, 64)},
  {name: 'bsb_code', label: 'BSB Code', validationRule: val => isLTE(val, 64)},
  {name: 'sort_code', label: 'Sort Code', validationRule: val => isLTE(val, 64)},
  {name: 'iban', label: 'IBAN', validationRule: val => isLTE(val, 64)},
  {name: 'transit_code', label: 'Transit Code', validationRule: val => isLTE(val, 32)},
  {name: 'bank_code', label: 'Bank Code', validationRule: val => isLTE(val, 32)},
  {name: 'ben_email_main', label: 'Beneficiary Email', validationRule: val => isEmailLTE(val, 64)}
];

export const validateUploadedBeneficiaryData = uploadedCSVData => {
  if (Array.isArray(uploadedCSVData) && uploadedCSVData.length > 1) {
    let error = null;

    const fieldValidationIndexes = [];

    // the first row of data represents the headers
    const headerRow = uploadedCSVData[0];
    const bodyRows = uploadedCSVData.slice(1);

    // check if all fields are valid
    for (let i = 0; i < headerRow.length; i++) {
      const headerField = headerRow[i];

      const validFieldIndex = validFields.findIndex(validHeaderField => validHeaderField.name === headerField);
      if (validFieldIndex >= 0) {
        fieldValidationIndexes.push(validFieldIndex);
      } else {
        error = `Invalid field '${headerField}'`;
        break;
      }
    }

    if (error) {
      return {error};
    }

    const beneficiariesData = [];

    // check if all rows are valid started from index 1 because index 0 is the headers
    for (let i = 0; i < bodyRows.length; i++) {
      const row = bodyRows[i];
      const beneficiaryData = {};
      // check if all fields in current row valid
      for (let j = 0; j < row.length; j++) {
        const validFieldIndex = fieldValidationIndexes[j];
        const validObjectName = validFields[validFieldIndex].name;
        const validationRule = validFields[validFieldIndex].validationRule;
        const rowField = row[j];

        // skip empty fields
        if (!rowField) {
          row[j] = null;
          beneficiaryData[validObjectName] = null;
          continue;
        }

        beneficiaryData[validObjectName] = rowField.toString();

        if (!validationRule(rowField.toString())) {
          error = `Invalid data: "${rowField}"; at row: ${i + 1}, column: ${j + 1}`;
          break;
        }
      }
      // if any field invalid return with error
      if (error) {
        return {error};
      }

      beneficiariesData.push(beneficiaryData);
    }

    // data is valid return headers and rows
    return {headers: headerRow, rows: bodyRows, beneficiaries: beneficiariesData};
  }

  return {error: 'Invalid file'};
};
