import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {API} from 'aws-amplify';

import withStyles from '@material-ui/core/styles/withStyles';

import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import {Button, Flex, Table, Modal} from "antd";
import ListTable from "../ListTable/ListTable";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    }
};

const EntitybanksaccountTable = forwardRef((props, ref) => {

    // useImperativeHandle(() => ({
    //     addNewEBA: () =>
    // }))
    const buildColumns = () => {
        return [
            {
                title: 'Account Name',
                dataIndex: 'eba_accout_name',
                sorter: (a, b) => a.eba_accout_name - b.eba_accout_name,
                defaultSortOrder: 'ascend',
                ...props.getColumnSearchProps({
                    dataIndex: 'eba_accout_name'
                })
            },
            {
                title: 'Swift',
                dataIndex: 'eba_swift',
                sorter: (a, b) => a.eba_swift - b.eba_swift,
                defaultSortOrder: 'ascend',
                ...props.getColumnSearchProps({
                    dataIndex: 'eba_swift'
                })
            },
            {
                title: 'BSB',
                dataIndex: 'eba_bsb',
                sorter: (a, b) => a.eba_bsb - b.eba_bsb,
                defaultSortOrder: 'ascend',
                ...props.getColumnSearchProps({
                    dataIndex: 'eba_bsb',
                })
            },
            {
                title: 'Account Number',
                dataIndex: 'eba_accout_number',
                sorter: (a, b) => a.eba_accout_number - b.eba_accout_number,
                defaultSortOrder: 'ascend',
                ...props.getColumnSearchProps({
                    dataIndex: 'eba_accout_number',
                })
            },
            {
                title: 'Reference',
                dataIndex: 'reference_mandatory',
                sorter: (a, b) => a.eba_accout_number - b.eba_accout_number,
                defaultSortOrder: 'ascend',
                ...props.getColumnSearchProps({
                    dataIndex: 'reference_mandatory',
                })
            },
            {
              title: 'Actions',
              dataIndex: 'actions',
            }
        ]
    }

    const buildTableData = () => {
        var country_list_length = props.entity_banks_account.entitybanks.length;
        if (country_list_length > 0) {
            return props.entity_banks_account.entitybanks.map((prop, key) => {
                return {
                    eba_swift: prop.eba_swift,
                    eba_bsb: prop.eba_bsb,
                    eba_accout_name: prop.eba_accout_name,
                    eba_accout_number: prop.eba_accout_number,
                    reference_mandatory: prop.reference_mandatory,
                    actions: (
                        <Flex justify={'end'} gap={5}>
                            <Button
                                type={'primary'}
                                icon={<EditOutlined />}
                                onClick={() => {
                                    const data = props.entity_banks_account.entitybanks;
                                    data.find(o => {
                                        if (o.id === prop.id) {
                                            props.editEntitybanks(o.id);
                                        }
                                    });
                                }}
                                color='warning'
                                className='edit'>
                            </Button>
                            <Button
                                type={'primary'}
                                icon={<DeleteOutlined />}
                                danger={true}
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Are you sure?',
                                        content: `Are you sure you want to delete #${prop.id} (${prop.eba_accout_name})`,
                                        onOk: () => {
                                            const data = props.entity_banks_account.entitybanks;
                                            data.find((o, i) => {
                                                if (o.id === prop.id) {
                                                    API.put('system', `/entitybanksaccount/delete/id/${prop.id}`, {})
                                                        .then(response => {
                                                            props.fetchEntitybanksListNotDeleted();
                                                        })
                                                        .catch(error => {
                                                            console.log(error);
                                                        });

                                                    return true;
                                                }

                                                return false;
                                            });
                                        }
                                    })
                                }}>
                            </Button>
                        </Flex>
                    )
                };
            });
        }
    }

    useEffect(() => {
        props.fetchEntitybanksListNotDeleted();
    }, []);

    return <>
        <Table columns={buildColumns()} dataSource={buildTableData()}></Table>
    </>
});

export default withStyles(styles)(ListTable(EntitybanksaccountTable));
