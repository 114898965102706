import ListTable from "../../../components/ListTable/ListTable";
import {Button, Card, Divider, Flex, Form, Input, message, Modal, Select, Table} from "antd";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {API} from "aws-amplify";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {AppTemplates} from "../../../AppTemplates";

const EntityBanks = (props) => {

    const [form] = useForm();

    const [componentState, setComponentState] = useState({
        dataList: [],
        isListLoading: false,
        isLoading: false,
        isModalOpen: false,
        activeId: null
    });


    const fetchEntityBanks = async () => {
        const loading = message.loading("Fetching entity banks. Please wait...", 0);
        setComponentState(prev => ({...prev, isListLoading: true}));
        try {
            const response = await API.post("commons", "/fetch", {
                body: {
                    context: 'entity_banks',
                    fields: ['*'],
                    condition: {eb_deleted: [0, null]}
                }
            });
            setComponentState(prev => ({...prev, dataList: mapTableData(response), isListLoading: false }));
        } catch (e) {
            console.log(e);
            setComponentState(prev => ({...prev, isListLoading: false}));
        } finally {
            loading();
        }
    }

    const mapTableData = (response) => {
        return response.map((prop) => {
            return {
                id: prop.id,
                eb_long_name: prop.eb_long_name,
                eb_short_name: prop.eb_short_name,
                address: `${prop.address_suburb} (${prop.address_line_1} ${prop.address_line_2})`,
                actions: (
                    <Flex justify={'end'} gap={5}>
                        <Button type={'primary'} onClick={() => onEditBtnClick(prop.id)}
                                icon={<EditOutlined />}
                                className='edit'>
                        </Button>
                        <Button type={'primary'}
                                danger={true}
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Are you sure ?',
                                        content: `Are you sure you want to delete this bank account ${prop.id}?`,
                                        onOk: () => {
                                            API.put('system', `/entitybanks/delete/id/${prop.id}`, {})
                                                .then(response => {
                                                    fetchEntityBanks();
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                        }
                                    });
                                }}>
                        </Button>
                    </Flex>
                )
            };
        });
    }

    const onEditBtnClick = async (id) => {
        const loading = message.loading(`Loading entity bank #${id}. Please wait..`, 0);
        try {
            const [response] = await API.post("commons", "/fetch", {
                body: {
                    context: 'entity_banks',
                    fields: ['*'],
                    condition: {id}
                }
            });
            setComponentState(prev => ({...prev, activeId: id, isModalOpen: true}));
            form.setFieldsValue(response);
        } catch (e) {
            console.log(e);
            setComponentState(prev => ({...prev, activeId: null}))
        } finally {
            loading();
        }
    }

    const handleSubmit = async (values) => {

        const loading = message.loading("Saving... Please wait", 0);

        const payload = {
            context: 'entity_banks',
            data: values
        }

        const req = componentState.activeId
            ? API.post("commons", `/update/${componentState.activeId}`, {body: payload})
            : API.post("commons", "/insert", {body: payload});

        await req.then(() => {
            form.resetFields();
            setComponentState(prev => ({...prev, activeId: null, isModalOpen: false, isLoading: false}));
            fetchEntityBanks();
        }).catch(err => {
            console.log(err);
            setComponentState(prev => ({...prev, isLoading: false}));
        }).finally(() => loading());
    }

    const buildColumns = () => {
        return [
            {
                title: 'Nickname',
                dataIndex: 'eb_short_name',
                sorter: (a,b) => a.eb_short_name - b.eb_short_name,
                defaultSortOrder: 'ascend'
            },
            {
                title: 'Legal Name',
                dataIndex: 'eb_long_name',
                sorter: (a,b) => a.eb_long_name - b.eb_long_name,
                defaultSortOrder: 'descend'
            },
            {
                title: 'Address',
                dataIndex: 'address',
                sorter: (a,b) => a.address - b.address,
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                render: (text, record) => text
            }
        ]
    }

    useEffect(() => {
        fetchEntityBanks();
    }, [props.app_state]);

    return <>
        <Card title={<>
            <Flex justify={'space-between'} align={'center'}>
                <h4>Entity Banks</h4>
                <Flex justify={'end'} gap={5}>
                    <Button type="primary"
                            onClick={() => setComponentState(prev => ({...prev, isModalOpen: true}))}>Add New
                        Entity</Button>
                    <Button type="primary" danger={true} onClick={() => fetchEntityBanks()}>Reload Page</Button>
                </Flex>
            </Flex>
        </>}>
            <Table size={'small'} rowKey={'id'} columns={buildColumns()} dataSource={componentState.dataList}
                   loading={componentState.isListLoading}></Table>
            <Modal title={componentState.activeId ? `Update Entity Bank #${componentState.activeId}` : 'Add New Entity Bank'}
                   open={componentState.isModalOpen}
                   okButtonProps={{
                       loading: componentState.isLoading,
                       disabled: componentState.isLoading
                   }}
                   onCancel={() => {
                       form.resetFields();
                       setComponentState(prev => ({...prev, activeId: null, isModalOpen: false }));
                   }}
                   cancelButtonProps={{
                       loading: componentState.isLoading,
                       disabled: componentState.isLoading
                   }}
                   onOk={() => form.submit()}>
                <Divider />
                <Form name={'entity-banks'} form={form} labelAlign={'left'} labelCol={{ span: 5 }} onFinish={handleSubmit}>
                    <Form.Item label={'Long name'} name={'eb_long_name'} rules={[{required: true, message: 'Field is required'}]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'Short name'} name={'eb_short_name'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'Address Line 1'} name={'address_line_1'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'Address Line 2'} name={'address_line_2'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'Suburb'} name={'address_suburb'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'State'} name={'address_state'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'Country'} name={'address_country'}>
                        <Select placeholder={'-- SELECT --'} options={AppTemplates.countries(props.countries)}></Select>
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
    </>
}

const mapStateToProps = (state) => ({
    app_state: state.app_state,
    countries: state.app_state.country_list
});

const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ListTable(EntityBanks));