import {
  ENTITYBANKSACCOUNT_FETCH_ENTITYBANKS_LIST_REQUEST,
  ENTITYBANKSACCOUNT_FETCH_ENTITYBANKS_LIST_SUCCESS,
  // ENTITYBANKSACCOUNT_FETCH_ENTITYBANKS_LIST_FAILURE,
  ENTITYBANKSACCOUNT_CREATE_NEW_SHOW_UI,
  ENTITYBANKSACCOUNT_CREATE_NEW_START,
  ENTITYBANKSACCOUNT_CREATE_NEW_CANCEL,
  ENTITYBANKSACCOUNT_CREATE_NEW_COMPLETE,
  ENTITYBANKSACCOUNT_SELECT_UI,
  ENTITYBANKSACCOUNT_ENTITYBANKS_LIST_IS_DIRTY,
  ENTITYBANKSACCOUNT_ENTITYBANKS_EDIT
} from '../actions/entitybanksaccount.js';

const initialState = {
  is_fetching: false,
  did_invalidate: false,
  entitybanks_list_is_dirty: false,
  entitybanks: [],
  entitybanks_current_ui: 'list',
  entitybanks_create_new_in_process: false,
  id: ''
};

function Entitybanksaccount(state = initialState, action) {
  switch (action.type) {
    case ENTITYBANKSACCOUNT_FETCH_ENTITYBANKS_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
      });

    case ENTITYBANKSACCOUNT_FETCH_ENTITYBANKS_LIST_SUCCESS:
      // console.log(action.entitybanks);
      return Object.assign({}, state, {
        is_fetching: false,
        entitybanks: action.entitybanks,
        lastUpdated: action.receivedAt
      });

    case ENTITYBANKSACCOUNT_CREATE_NEW_SHOW_UI:
      return Object.assign({}, state, {
        entitybanks_current_ui: 'list'
      });

    case ENTITYBANKSACCOUNT_CREATE_NEW_START:
      return Object.assign({}, state, {
        entitybanks_create_new_in_process: true
      });

    case ENTITYBANKSACCOUNT_CREATE_NEW_CANCEL:
      return Object.assign({}, state, {
        entitybanks_create_new_in_process: false,
        entitybanks_current_ui: 'list'
      });

    case ENTITYBANKSACCOUNT_CREATE_NEW_COMPLETE:
      return Object.assign({}, state, {
        entitybanks_create_new_in_process: false,
        entitybanks_current_ui: 'list',
        entitybanks_list_is_dirty: true
      });

    case ENTITYBANKSACCOUNT_SELECT_UI:
      return Object.assign({}, state, {
        entitybanks_current_ui: action.value
      });
    case ENTITYBANKSACCOUNT_ENTITYBANKS_LIST_IS_DIRTY:
      return Object.assign({}, state, {
        entitybanks_list_is_dirty: action.value
      });
    case ENTITYBANKSACCOUNT_ENTITYBANKS_EDIT:
      return Object.assign({}, state, {
        entitybanks_create_new_in_process: false,
        entitybanks_current_ui: 'edit',
        id: action.value
      });
    default:
      return state;
  }
}

export default Entitybanksaccount;
