import React from 'react';
import PropTypes from 'prop-types';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';
import {withRouter} from 'react-router-dom';
// react plugin for creating vector maps
import {VectorMap} from 'react-jvectormap';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AccessTime from '@material-ui/icons/AccessTime';

//core components for Issue #958 by Tom
import CustomTransferList from '../../components/CustomTransferList/CustomTransferList';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardAvatar from 'components/Card/CardAvatar.jsx';
import {tooltip} from 'assets/jss/material-dashboard-pro-react.jsx';
import {dailySalesChart} from 'variables/charts';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import {API} from 'aws-amplify';
import CustomReactSelect from '../../components/Forms/CustomReactSelect/CustomReactSelect';

import {FormattedMessage} from 'react-intl';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import axios from 'axios';
import Chartist from 'chartist';
import moment from 'moment';
import sendMoney from '../../assets/custom-icons/Icon-SendMoney.png';

const min_transfer_amount = 0;
const styles = {
  ...dashboardStyle,
  tooltip,
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  }
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      currencies_list_priority: [],
      map_data: {},
      audusd: {},
      current_audusd: '',
      current_audeur: '',
      current_audnzd: '',
      audeur: {},
      audnzd: {},
      client_list: [],
      client_details: ''
    };
  }

  componentDidMount() {
    let url = 'https://apilayer.net/api/timeframe?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
    axios
      .get(url, {
        params: {
          currencies: 'USD',
          source: 'AUD',
          start_date: '2019-04-01',
          end_date: '2019-05-17'
        }
      })
      .then(response => {
        // console.log(response);
        const data = [];
        for (const item in response.data.quotes) {
          data.push(response.data.quotes[item]['AUDUSD']);
        }
        const data2 = [];
        data2.push(data);
        this.setState({audusd: {series: data2}});
      })
      .catch(error => {
        console.log(error);
      });

    url = 'https://apilayer.net/api/timeframe?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
    axios
      .get(url, {
        params: {
          currencies: 'EUR',
          source: 'AUD',
          start_date: '2019-04-01',
          end_date: '2019-05-17'
        }
      })
      .then(response => {
        // console.log(response);
        const data = [];
        for (const item in response.data.quotes) {
          data.push(response.data.quotes[item]['AUDEUR']);
        }
        const data2 = [];
        data2.push(data);
        this.setState({audeur: {series: data2}});
      })
      .catch(error => {
        console.log(error);
      });

    url = 'https://apilayer.net/api/timeframe?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
    axios
      .get(url, {
        params: {
          currencies: 'NZD',
          source: 'AUD',
          start_date: '2019-04-01',
          end_date: '2019-05-17'
        }
      })
      .then(response => {
        // console.log(response);
        const data = [];
        for (const item in response.data.quotes) {
          data.push(response.data.quotes[item]['AUDNZD']);
        }
        const data2 = [];
        data2.push(data);
        this.setState({audnzd: {series: data2}});
      })
      .catch(error => {
        console.log(error);
      });

    url = 'https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
    axios
      .get(url, {
        params: {
          currencies: 'USD,EUR,NZD',
          source: 'AUD',
          format: '1'
        }
      })
      .then(response => {
        // console.log(response);
        this.setState({
          current_audusd: response.data.quotes['AUDUSD'],
          current_audeur: response.data.quotes['AUDEUR'],
          current_audnzd: response.data.quotes['AUDNZD']
        });
      })
      .catch(error => {
        console.log(error);
      });

    API.get('currencies', '/list_not_deleted')
      .then(response => {
        this.setState({currencies_list_priority: response});
      })
      .catch(error => {
        console.log(error);
        API.get('currencies', '/list_not_deleted')
          .then(response => {
            this.setState({currencies_list_priority: response});
          })
          .catch(error => {
            console.log(error);
          });
      });

    API.get('clients', '/get-list-not-deleted')
      .then(response => {
        // console.log(response);
        this.setState({client_list: response});
      })
      .catch(error => {
        console.log(error);
        API.get('clients', '/get-list-not-deleted')
          .then(response => {
            // console.log(response);
            this.setState({client_list: response});
          })
          .catch(error => {
            console.log(error);
          });
      });
  }

  componentWillUnmount() {
    this.setState({client_details: ''});
  }

  // getClientDetails(client_id, option) {
  //   API.get('clients', `/get/id/${client_id}`)
  //     .then(response => {
  //       this.setState({client_details: response}, () => {
  //         if (option === 'handleAmountSwitch') {
  //           this.handleAmountSwitch(this.state.last_user_type_amount_name);
  //         }
  //       });
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }

  // // function that verifies if a string has a given length or not
  // verifyLength(value, length) {
  //   if (value.length >= length) {
  //     return true;
  //   }
  //   return false;
  // }

  // // function that verifies if a value is larger than a number or not
  // isLargerThanNumber(value, number) {
  //   if (value !== '' && value >= number) {
  //     return true;
  //   }
  //   return false;
  // }

  // change(event, stateName, type, stateNameEqualTo) {
  //   switch (type) {
  //     case 'length':
  //       if (this.verifyLength(event.target.value, stateNameEqualTo)) {
  //         this.setState({[stateName + '_state']: 'success'});
  //       } else {
  //         this.setState({[stateName + '_state']: 'error'});
  //       }
  //       break;
  //     case 'isLargerThanNumber':
  //       if (this.isLargerThanNumber(event.target.value, stateNameEqualTo)) {
  //         this.setState({[stateName + '_state']: 'success'});
  //       } else {
  //         this.setState({[stateName + '_state']: 'error'});
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  //   this.setState({[stateName]: event.target.value});
  //   // Calculate "to amount"
  //   if (stateName === 'from_amount') {
  //     this.setState({last_user_type_amount_name: 'from_amount'});
  //     this.handleAmount('to_amount');
  //   }
  //   // Calculate "from amount"
  //   if (stateName === 'to_amount') {
  //     this.setState({last_user_type_amount_name: 'to_amount'});
  //     this.handleAmount('from_amount');
  //   }
  // }

  // handleAmount(amount_name) {
  //   let from_currency_Iso = '';
  //   let to_currency_Iso = '';

  //   this.state.currencies_list_priority.map((item, index) => {
  //     if (item.id == this.state.from_currency) {
  //       from_currency_Iso = item.iso_alpha_3;
  //     }
  //     if (item.id == this.state.to_currency) {
  //       to_currency_Iso = item.iso_alpha_3;
  //     }
  //   });

  //   const url = 'https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
  //   axios
  //     .get(url, {
  //       params: {
  //         currencies: to_currency_Iso,
  //         source: from_currency_Iso,
  //         format: '1'
  //       }
  //     })
  //     .then(response => {
  //       const key = from_currency_Iso + to_currency_Iso;
  //       const rate_string = response.data.quotes[key];

  //       const rate = Number.parseFloat(rate_string);

  //       const rate_adjust =
  //         Number.parseFloat(this.state.client_details.default_rate_division) +
  //         Number(Number.parseFloat(this.state.client_details.default_rate_entity)) +
  //         Number(Number.parseFloat(this.state.client_details.default_rate_team)) +
  //         Number(Number.parseFloat(this.state.client_details.default_rate_staff));
  //       let adjusted_rate = rate - rate * rate_adjust;
  //       if (this.state.from_currency == this.state.to_currency) {
  //         adjusted_rate = rate;
  //       }

  //       if (this.state.from_currency_state === 'success' && this.state.to_currency_state === 'success') {
  //         // handle to_amount
  //         if (amount_name === 'to_amount' && this.state.from_amount_state === 'success') {
  //           const amount_from = Number.parseFloat(this.state.from_amount);
  //           const amount_to = amount_from * adjusted_rate;

  //           this.setState({to_amount: amount_to.toFixed(2)});
  //           if (amount_to >= min_transfer_amount) {
  //             this.setState({to_amount_state: 'success'});
  //           } else {
  //             this.setState({to_amount_state: 'error'});
  //           }
  //         }

  //         // handle from_amount
  //         if (amount_name === 'from_amount' && this.state.to_amount_state === 'success') {
  //           const amount_to = Number.parseFloat(this.state.to_amount);
  //           const amount_from = amount_to / adjusted_rate;

  //           this.setState({from_amount: amount_from.toFixed(2)});
  //           if (amount_from >= min_transfer_amount) {
  //             this.setState({from_amount_state: 'success'});
  //           } else {
  //             this.setState({from_amount_state: 'error'});
  //           }
  //         }
  //       }

  //       this.setState({adjusted_rate: adjusted_rate});
  //       // Save currency name into state for passing query strings
  //       this.setState({from_currency_name: from_currency_Iso});
  //       this.setState({to_currency_name: to_currency_Iso});
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }

  // handleAmountSwitch(last_user_type_amount_name) {
  //   switch (last_user_type_amount_name) {
  //     case 'from_amount':
  //       this.handleAmount('to_amount');
  //       break;
  //     case 'to_amount':
  //       this.handleAmount('from_amount');
  //       break;
  //     default:
  //       this.handleAmount();
  //   }
  // }

  // handleChange = (event, value) => {
  //   this.setState({value});
  // };

  // handleChangeIndex = index => {
  //   this.setState({value: index});
  // };

  // handleCustomReactSelectChange = (name, previous_from_currency, previous_to_currency) => value => {
  //   let option_value;
  //   if (value === null) {
  //     option_value = null;
  //   } else {
  //     option_value = value.value;
  //   }
  //   this.setState({[name]: option_value}, () => {
  //     if (name === 'client_id') {
  //       if (this.props.app_state.current_brand === 'gostudy') {
  //         this.getClientDetails(option_value);
  //       } else {
  //         this.getClientDetails(option_value, 'handleAmountSwitch');
  //       }
  //     }
  //     if (name === 'from_currency' || name === 'to_currency') {
  //       this.handleAmountSwitch(this.state.last_user_type_amount_name);
  //       this.updateMapData(name, previous_from_currency, previous_to_currency, value);
  //     }
  //   });
  //   this.setState({[name + '_state']: 'success'});
  // };

  // updateMapData(currency_name, previous_from_currency, previous_to_currency, currency_object) {
  //   const map_values = this.state.map_data;
  //   const from_currency_map_value = 1;
  //   const to_currency_map_value = 1;
  //   // update the properties for visulization
  //   if (currency_name === 'from_currency') {
  //     // delele previous currency property in map_values
  //     if (previous_from_currency !== previous_to_currency) {
  //       this.deleleCurrency(map_values, previous_from_currency, previous_to_currency);
  //     }
  //     // add new currency property in map_values
  //     this.addCurrency(map_values, currency_object.value, from_currency_map_value);
  //   }
  //   if (currency_name === 'to_currency') {
  //     // delele previous currency property in map_values
  //     if (previous_to_currency !== previous_from_currency) {
  //       this.deleleCurrency(map_values, previous_to_currency, previous_from_currency);
  //     }
  //     // add new currency property in map_values
  //     this.addCurrency(map_values, currency_object.value, to_currency_map_value);
  //   }
  //   this.setState({map_data: map_values});
  // }

  // deleleCurrency(map_values, currency_deleted, the_other_currency) {
  //   switch (currency_deleted) {
  //     case 1: // AUD
  //       delete map_values.AU; // Australia
  //       break;
  //     case 5: // USD
  //       delete map_values.US; // United States
  //       break;
  //     case 8: // GBP
  //       // if the other currency is not Euro, delete GB
  //       if (the_other_currency !== 9) {
  //         delete map_values.GB; // United Kingdom
  //       }
  //       break;
  //     case 9: // Eur. For Europe: italy, spain, france, portugal, germany
  //       // if the other currency is not GB, delete GB
  //       if (the_other_currency !== 8) {
  //         delete map_values.GB; // United Kingdom
  //       }
  //       delete map_values.IT; // Italy
  //       delete map_values.ES; // Spain
  //       delete map_values.FR; // France
  //       delete map_values.PT; // Portugal
  //       delete map_values.DE; // Germany
  //       break;
  //     case 6: // ATS
  //       delete map_values.AT; // 	Austria
  //       break;
  //     default:
  //   }
  // }

  // addCurrency(map_values, currency, currency_map_value) {
  //   switch (currency) {
  //     case 1:
  //       map_values.AU = currency_map_value;
  //       break;
  //     case 5:
  //       map_values.US = currency_map_value;
  //       break;
  //     case 8:
  //       map_values.GB = currency_map_value;
  //       break;
  //     case 9:
  //       map_values.GB = currency_map_value;
  //       map_values.IT = currency_map_value;
  //       map_values.ES = currency_map_value;
  //       map_values.FR = currency_map_value;
  //       map_values.PT = currency_map_value;
  //       map_values.DE = currency_map_value;
  //       break;
  //     case 6:
  //       map_values.AT = currency_map_value;
  //       break;
  //     default:
  //   }
  // }

  // // with client field
  // renderMainComponentForTransfers() {
  //   const {classes} = this.props;
  //   // For CustomReactSelect. Generate select options for dropdown list.
  //   const client_list_select_options = this.state.client_list
  //     .sort((a, b) => a.nickname.localeCompare(b.nickname))
  //     .map(item => ({
  //       value: item.id,
  //       label: item.nickname
  //     }));

  //   //console.log(this.props)
  //   return (
  //     <GridItem xs={12}>
  //       <Card style={{height: '90%'}}>
  //         <CardHeader color="info" stats icon>
  //         </CardHeader>
  //         <CardBody>
  //           <p className={classes.cardCategory}>
  //             <FormattedMessage id="dashboard.heading1" defaultMessage={'Welcome to'} />
  //           </p>
  //           <h3 className={classes.cardTitle}>
  //             {this.props.app_state.current_division.brand_app_name}
  //           </h3>
  //           <GridContainer justify="center">
  //             <GridItem xs={6}>
  //               <hr />

  //               <br />
  //               <h4>
  //                 <FormattedMessage id="dashboard.heading3" defaultMessage={'Request a new transfer...'} />
  //                 {this.props.app_state.current_brand === 'gostudy' && (
  //                   <span>
  //                     for <strong>Go Study Australia</strong>
  //                   </span>
  //                 )}
  //               </h4>
  //               <GridContainer justify="flex-start">
  //                 <GridItem xs={12} sm={10} md={6}>
  //                   <CustomReactSelect
  //                     label={<FormattedMessage id="dashboard.selectClient" defaultMessage={'Select Clientsss'} />}
  //                     options={client_list_select_options}
  //                     value={this.state.client_id}
  //                     onChange={this.handleCustomReactSelectChange('client_id')}
  //                     isClearable={false}
  //                     // isDisabled={!this.state.edit_mode}
  //                   />
  //                 </GridItem>
  //               </GridContainer>

  //               <GridContainer justify="flex-start">
  //                 <GridItem xs={12} sm={10} md={8}>
  //                   {this.state.client_details && (
  //                     <p>
  //                       <br />
  //                       Press <strong>Proceed</strong> to start a transfer for {this.state.client_details.first_name}{' '}
  //                       {this.state.client_details.last_name}.<br />
  //                       Email: {this.state.client_details.email}
  //                       <br />
  //                       Telephone: {this.state.client_details.telephone_mobile}
  //                     </p>
  //                   )}
  //                   <Button
  //                     color="primary"
  //                     // fullWidth
  //                     // size="sm"
  //                     onClick={() => this.props.history.push(`/payment/new/?client_id=${this.state.client_id}`)}
  //                     disabled={this.state.client_id_state !== 'success'}
  //                   >
  //                     <FormattedMessage id="dashboard.button" defaultMessage={'Proceed'} />
  //                   </Button>
  //                 </GridItem>
  //               </GridContainer>
  //             </GridItem>

  //             <GridItem xs={6}>{this.renderMapComponent()}</GridItem>
  //           </GridContainer>
  //         </CardBody>
  //         <CardFooter stats>
  //           <div className={classes.stats}>

  //           </div>
  //         </CardFooter>
  //       </Card>
  //     </GridItem>
  //   );
  // }

  // // with client, currency and amount fields
  // renderMainComponentForTransfers2() {
  //   // console.log("transfer2")
  //   const {classes} = this.props;
  //   // For CustomReactSelect. Generate select options for dropdown list.
  //   const client_list_select_options = this.state.client_list
  //     .sort((a, b) => a.nickname.localeCompare(b.nickname))
  //     .map(item => ({
  //       value: item.id,
  //       label: item.nickname
  //     }));
  //   // eslint-disable-next-line prettier/prettier
  //   const select_currency_select_options = this.state.currencies_list_priority.map(item => ({
  //     value: item.id,
  //     label: item.iso_alpha_3 + ': ' + item.full_name
  //   }));

  //   //Dynamic Strings
  //   let transferRateCondition = '';

  //   if (!isNaN(this.state.adjusted_rate)) {
  //     transferRateCondition = 'dashboard.transferRate';
  //   } else {
  //     transferRateCondition = 'dashboard.blank';
  //   }

  //   //console.log(this.props)
  //   return (
  //     <GridItem xs={12}>
  //       <Card style={{height: '90%'}}>
  //         <CardHeader color="info" stats icon>
  //         </CardHeader>
  //         <CardBody>
  //           <p className={classes.cardCategory}>
  //             <FormattedMessage id="dashboard.heading1" defaultMessage={'Welcome to'} />
  //           </p>
  //           <h3 className={classes.cardTitle}>
  //             {this.props.app_state.current_division ? this.props.app_state.current_division.brand_app_name : ''}
  //           </h3>
  //           <GridContainer justify="center">
  //             <GridItem xs={6}>
  //               <p>
  //                 <FormattedMessage id="dashboard.welcome" defaultMessage={' '} />
  //               </p>
  //               <p>
  //                 <FormattedMessage id="dashboard.welcomeMessage" defaultMessage={' '} />
  //               </p>
  //               <br />
  //               <h4>
  //                 <FormattedMessage id="dashboard.heading3" defaultMessage={'Request a new transfer...'} />
  //               </h4>

  //               <GridContainer justify="flex-start">
  //                 <GridItem xs={12} sm={6}>
  //                   <CustomReactSelect
  //                     label="Select client"
  //                     options={client_list_select_options}
  //                     value={this.state.client_id}
  //                     onChange={this.handleCustomReactSelectChange('client_id')}
  //                     isClearable={false}
  //                     // isDisabled={!this.state.edit_mode}
  //                   />
  //                 </GridItem>
  //               </GridContainer>

  //               {this.state.client_details && (
  //                 <React.Fragment>
  //                   <GridContainer justify="center">
  //                     <GridItem xs={12} sm={6}>
  //                       <CustomReactSelect
  //                         label="From currency"
  //                         options={select_currency_select_options}
  //                         value={this.state.from_currency}
  //                         onChange={this.handleCustomReactSelectChange(
  //                           'from_currency',
  //                           this.state.from_currency,
  //                           this.state.to_currency
  //                         )}
  //                         isClearable={false}
  //                         // isDisabled={!this.state.edit_mode}
  //                       />
  //                     </GridItem>
  //                     <GridItem xs={12} sm={6}>
  //                       <CustomReactSelect
  //                         label="To currency"
  //                         options={select_currency_select_options}
  //                         value={this.state.to_currency}
  //                         onChange={this.handleCustomReactSelectChange(
  //                           'to_currency',
  //                           this.state.from_currency,
  //                           this.state.to_currency
  //                         )}
  //                         isClearable={false}
  //                         // isDisabled={!this.state.edit_mode}
  //                       />
  //                     </GridItem>
  //                   </GridContainer>

  //                   <GridContainer justify="center">
  //                     <GridItem xs={12} sm={6}>
  //                       <CustomInput
  //                         success={this.state.from_amount_state === 'success'}
  //                         error={this.state.from_amount_state === 'error'}
  //                         labelText={<span>From amount</span>}
  //                         id="from_amount"
  //                         labelProps={{shrink: true}}
  //                         formControlProps={{fullWidth: true}}
  //                         inputProps={{
  //                           value: this.state.from_amount,
  //                           onChange: event =>
  //                             this.change(event, 'from_amount', 'isLargerThanNumber', min_transfer_amount)
  //                         }}
  //                       />
  //                     </GridItem>
  //                     <GridItem xs={12} sm={6}>
  //                       <CustomInput
  //                         success={this.state.to_amount_state === 'success'}
  //                         error={this.state.to_amount_state === 'error'}
  //                         labelText={<span>To amount</span>}
  //                         id="to_amount"
  //                         labelProps={{shrink: true}}
  //                         formControlProps={{fullWidth: true}}
  //                         inputProps={{
  //                           value: this.state.to_amount,
  //                           onChange: event =>
  //                             this.change(event, 'to_amount', 'isLargerThanNumber', min_transfer_amount)
  //                           // disabled: true
  //                         }}
  //                         helpText={
  //                           !isNaN(this.state.adjusted_rate)
  //                             ? 'Transfer Rate: ' + Number.parseFloat(this.state.adjusted_rate).toFixed(5)
  //                             : ''
  //                         }
  //                       />
  //                     </GridItem>
  //                   </GridContainer>
  //                 </React.Fragment>
  //               )}

  //               <GridContainer justify="flex-start">
  //                 <GridItem xs={12} sm={12}>
  //                   {this.state.client_details && (
  //                     <p>
  //                       <br />
  //                       Press <strong>Proceed</strong> to start a transfer for {this.state.client_details.first_name}{' '}
  //                       {this.state.client_details.last_name}.<br />
  //                       Email: {this.state.client_details.email}
  //                       <br />
  //                       Telephone: {this.state.client_details.telephone_mobile}
  //                     </p>
  //                   )}
  //                 </GridItem>
  //                 <GridItem xs={12} sm={12}>
  //                   <Button
  //                     color="primary"
  //                     onClick={() => {
  //                       if (this.state.last_user_type_amount_name === 'from_amount') {
  //                         this.props.history.push(
  //                           `/transfers/new/?client_id=${this.state.client_id}&from_currency=${
  //                             this.state.from_currency
  //                           }&to_currency=${this.state.to_currency}&from_amount=${this.state.from_amount}`
  //                         );
  //                       } else if (this.state.last_user_type_amount_name === 'to_amount') {
  //                         this.props.history.push(
  //                           `/transfers/new/?client_id=${this.state.client_id}&from_currency=${
  //                             this.state.from_currency
  //                           }&to_currency=${this.state.to_currency}&to_amount=${this.state.to_amount}`
  //                         );
  //                       }
  //                     }}
  //                     disabled={
  //                       this.state.from_currency_state !== 'success' ||
  //                       this.state.to_currency_state !== 'success' ||
  //                       this.state.from_amount_state !== 'success' ||
  //                       this.state.to_amount_state !== 'success'
  //                     }
  //                   >
  //                     <FormattedMessage id="dashboard.button" defaultMessage={'Start A New Transfer'} />
  //                   </Button>
  //                 </GridItem>
  //               </GridContainer>
  //             </GridItem>
  //             <GridItem xs={6}>{this.renderMapComponent()}</GridItem>
  //           </GridContainer>
  //         </CardBody>
  //         <CardFooter stats>
  //           <div className={classes.stats}>
  //           </div>
  //         </CardFooter>
  //       </Card>
  //     </GridItem>
  //   );
  // }

  // renderMapComponent() {
  //   return (
  //     <VectorMap
  //       map={'world_mill'}
  //       backgroundColor="transparent"
  //       zoomOnScroll={false}
  //       containerStyle={{
  //         width: '100%',
  //         height: '420px'
  //       }}
  //       containerClassName="map"
  //       regionStyle={{
  //         initial: {
  //           fill: '#e4e4e4',
  //           'fill-opacity': 0.9,
  //           stroke: 'none',
  //           'stroke-width': 0,
  //           'stroke-opacity': 0
  //         }
  //       }}
  //       series={{
  //         regions: [
  //           {
  //             values: this.state.map_data,
  //             scale: ['#ffffff', '#26c6da'],
  //             normalizeFunction: 'polynomial'
  //           }
  //         ]
  //       }}
  //     />
  //   );
  // }

  render() {
    if (!this.props.app_state.current_staff) {
      return <React.Fragment>LOADING</React.Fragment>;
    }
    if (!this.props.app_state.current_staff_id) {
      return <React.Fragment>LOADING</React.Fragment>;
    }
    const {classes} = this.props;

    // let main_component = null;
    // if (this.props.app_state.current_brand === 'gostudy') {
    //   main_component = this.renderMainComponentForTransfers();
    // } else {
    //   main_component = this.renderMainComponentForTransfers2();
    // }

    return (
      <React.Fragment>
        <GridContainer alignItems='stretch'>
          <GridItem md={4}>
            <Card onClick={() => window.location.replace('/transfers/new')}>
              <CardBody>
                <div
                  style={{
                    padding: 20,
                    height: '20vh',
                    textAlign: 'center'
                  }}
                >
                  <CardAvatar
                    style={{
                      marginTop: 0,
                      marginBottom: 25
                    }}
                  >
                    <img src={sendMoney} alt='...' style={{height: 100}} />
                  </CardAvatar>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h3>New Transfer</h3>
                    </GridItem>
                  </GridContainer>
                </div>
              </CardBody>
              <CardFooter>
                <Button style={{width: '100%'}} color='info' onClick={() => window.location.replace('/transfers/new')}>
                  New Transfer
                </Button>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem md={4}>
            <Card onClick={() => window.location.replace('/clients/list')}>
              <CardBody>
                <div
                  style={{
                    padding: 20,
                    height: '20vh',
                    textAlign: 'center'
                  }}
                >
                  <CardAvatar
                    style={{
                      marginTop: 0,
                      marginBottom: 25
                    }}
                  >
                    <img src={sendMoney} alt='...' style={{height: 100}} />
                  </CardAvatar>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h3>Client List</h3>
                    </GridItem>
                  </GridContainer>
                </div>
              </CardBody>
              <CardFooter>
                <Button
                  style={{width: '100%'}}
                  color='info'
                  onClick={() => window.location.replace('/clients/list')}
                >
                  Client List
                </Button>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem md={4}>
            <Card onClick={() => window.location.replace('/transfers/list')}>
              <CardBody>
                <div
                  style={{
                    padding: 20,
                    height: '20vh',
                    textAlign: 'center'
                  }}
                >
                  <CardAvatar
                    style={{
                      marginTop: 0,
                      marginBottom: 25
                    }}
                  >
                    <img src={sendMoney} alt='...' style={{height: 100}} />
                  </CardAvatar>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h3>Transfer List</h3>
                    </GridItem>
                  </GridContainer>
                </div>
              </CardBody>
              <CardFooter>
                <Button
                  style={{width: '100%'}}
                  color='info'
                  onClick={() => window.location.replace('/transfers/list')}
                >
                  Transfer List
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer alignItems='stretch'>
          <GridItem xs={12} md={12}>
            <Card
              style={{
                display: 'flex',
                height: '90%',
                flexDirection: 'column'
              }}
            >
              <CardHeader color='info' stats icon>
                <CardIcon color='info'>
                  <i className='fas fa-exchange-alt' />
                </CardIcon>
                <p className={classes.cardCategory} />
                <h3 className={classes.cardTitle}>Recent Transfers</h3>

                {/*<div>*/}
                {/*  <FormControlLabel*/}
                {/*    control={*/}
                {/*      <Switch*/}
                {/*        checked={this.state.checked}*/}
                {/*        onChange={event => this.toggleChecked(event.target.checked)}*/}
                {/*        value="checkedA"*/}
                {/*        classes={{*/}
                {/*          switchBase: classes.switchBase,*/}
                {/*          checked: classes.switchChecked,*/}
                {/*          thumb: classes.switchIcon,*/}
                {/*          track: classes.switchBar*/}
                {/*        }}*/}
                {/*      />*/}
                {/*    }*/}
                {/*    classes={{*/}
                {/*      label: classes.label*/}
                {/*    }}*/}
                {/*    label={"Switch current/past transfers"}*/}
                {/*  />*/}
                {/*</div>*/}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <CustomTransferList
                    // status={this.state.toggleTransfer}
                    // start_date={"01-09-2019"}
                    // end_date={"02-09-2019"}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter stats />
            </Card>
          </GridItem>
        </GridContainer>

        {this.props.app_state.current_staff.ec_info_dashboard_charts == 1 && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card chart className={classes.cardHover}>
                <CardHeader color='info' className={classes.cardHeaderHover}>
                  <ChartistGraph
                    className='ct-chart-white-colors'
                    data={this.state.audeur}
                    type='Line'
                    options={{
                      lineSmooth: Chartist.Interpolation.cardinal({tension: 0}),
                      low: 0.615,
                      high: 0.64,
                      chartPadding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                      }
                    }}
                    listener={dailySalesChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <div className={classes.cardHoverUnder} />
                  <h4 className={classes.cardTitle}>
                    <FormattedMessage id='dashboard.AUD_EUR.heading1' defaultMessage={'AUD/EUR 30 days'} />
                  </h4>
                  <p className={classes.cardCategory}>
                    <span>
                      <FormattedMessage id='dashboard.currentPrice' defaultMessage={'Current price'} />
                      {Number.parseFloat(this.state.current_audeur).toFixed(5)}
                    </span>
                  </p>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> updated {moment().format('MMMM Do YYYY, h:mm:ss a')}
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card chart className={classes.cardHover}>
                <CardHeader color='info' className={classes.cardHeaderHover}>
                  <ChartistGraph
                    className='ct-chart-white-colors'
                    data={this.state.audusd}
                    type='Line'
                    options={{
                      lineSmooth: Chartist.Interpolation.cardinal({tension: 0}),
                      low: 0.68,
                      high: 0.72,
                      chartPadding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                      }
                    }}
                    listener={dailySalesChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <div className={classes.cardHoverUnder} />
                  <h4 className={classes.cardTitle}>
                    <FormattedMessage id='dashboard.AUD_USD.heading2' defaultMessage={'AUD/USD 30 days'} />
                  </h4>
                  <p className={classes.cardCategory}>
                    <span>
                      <FormattedMessage id='dashboard.currentPrice' defaultMessage={'Current price'} />
                      {Number.parseFloat(this.state.current_audusd).toFixed(5)}
                    </span>
                  </p>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> updated {moment().format('MMMM Do YYYY, h:mm:ss a')}
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card chart className={classes.cardHover}>
                <CardHeader color='info' className={classes.cardHeaderHover}>
                  <ChartistGraph
                    className='ct-chart-white-colors'
                    data={this.state.audnzd}
                    type='Line'
                    options={{
                      lineSmooth: Chartist.Interpolation.cardinal({tension: 0}),
                      low: 1.04,
                      high: 1.075,
                      chartPadding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                      }
                    }}
                    listener={dailySalesChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <div className={classes.cardHoverUnder} />
                  <h4 className={classes.cardTitle}>
                    <FormattedMessage id='dashboard.AUD_NZD.heading3' defaultMessage={'AUD/NZD 30 days'} />
                  </h4>
                  <p className={classes.cardCategory}>
                    <span>
                      <FormattedMessage id='dashboard.currentPrice' defaultMessage={'Current price'} />
                      {Number.parseFloat(this.state.current_audnzd).toFixed(5)}
                    </span>
                  </p>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> updated {moment().format('MMMM Do YYYY, h:mm:ss a')}
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(Dashboard));
