import {
  // STAFF_SELECT_STAFF_MEMBER,
  CLIENTS_FETCH_CLIENT_LIST_REQUEST,
  CLIENTS_FETCH_CLIENT_LIST_SUCCESS,
  SIMPLE_CLIENTS_FETCH_CLIENT_LIST_REQUEST,
  SIMPLE_CLIENTS_FETCH_CLIENT_LIST_SUCCESS,
  // CLIENTS_FETCH_CLIENT_LIST_FAILURE
  // STAFF_CREATE_NEW_SHOW_UI,
  // STAFF_CREATE_NEW_START,
  // STAFF_CREATE_NEW_CANCEL,
  // STAFF_CREATE_NEW_COMPLETE,
  // STAFF_EDIT_SHOW_UI,
  CLIENTS_SELECT_UI,
  CLIENTS_SELECT_SET_ID,
  // CLIENTS_SELECT_CLIENT_REQUEST,
  // CLIENTS_SELECT_CLIENT_SUCCESS,
  CLIENTS_FETCH_CLIENT_REQUEST,
  CLIENTS_FETCH_CLIENT_SUCCESS,
  CLIENTS_START_NEW_CLIENT_CREATION,
  CLIENTS_UPDATE_NEW_CLIENT_CREATION_CLIENT_TYPE,
  CLIENTS_UPDATE_NEW_CLIENT_CREATION_EMAIL,
  CLIENTS_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME,
  CLIENTS_UPDATE_NEW_CLIENT_CREATION_LASTNAME,
  CLIENTS_UPDATE_NEW_CLIENT_CREATION,
  CLIENTS_NEW_CLIENT_DATABASE_CREATE_REQUEST,
  CLIENTS_NEW_CLIENT_DATABASE_CREATE_SUCCESS,
  CLIENTS_CREATE_COGNITO_START,
  CLIENTS_CREATE_COGNITO_SUCCESS
} from '../actions/clients.js';

const initialState = {
  client_current_ui: 'list',
  is_fetching: false,
  did_invalidate: false,
  client_list: [],
  simple_client_list: [],
  client_detail_fetching: false,
  client_detail_loaded: false,
  client_detail_id: null,
  new_client_data: null,
  new_client_type: null,
  new_client_submitted: false,
  new_client_created: false
};

function clients(state = initialState, action) {
  switch (action.type) {
    // case STAFF_SELECT_STAFF_MEMBER:
    //   // console.log(action);
    //   return Object.assign({}, state, {
    //     staff_detail_id: action.staff_id
    //   });

    case CLIENTS_FETCH_CLIENT_LIST_REQUEST:
      return {
        ...state,
        is_fetching: true
      };

    case CLIENTS_FETCH_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        is_fetching: false,
        client_list: action.client_list,
        lastUpdated: action.receivedAt
      };

    case SIMPLE_CLIENTS_FETCH_CLIENT_LIST_REQUEST:
      return {
        ...state,
        is_fetching: true
      };

    case SIMPLE_CLIENTS_FETCH_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        is_fetching: false,
        simple_client_list: action.client_list,
        lastUpdated: action.receivedAt
      };

    // case STAFF_CREATE_NEW_SHOW_UI:
    //   return Object.assign({}, state, {
    //     // ui_show_create_new_staff_screen: action.value,
    //     // ui_show_edit_staff_screen: false,
    //     staff_current_ui: "list"
    //   });

    // case STAFF_CREATE_NEW_START:
    //   return Object.assign({}, state, {
    //     staff_create_new_in_process: true,
    //     staff_create_new_awaiting_confirmation: true
    //   });
    //
    // case STAFF_CREATE_NEW_CANCEL:
    //   return Object.assign({}, state, {
    //     staff_create_new_in_process: false,
    //     staff_create_new_awaiting_confirmation: false,
    //     staff_current_ui: "list"
    //   });
    //
    // case STAFF_CREATE_NEW_COMPLETE:
    //   return Object.assign({}, state, {
    //     staff_create_new_in_process: false,
    //     staff_create_new_awaiting_confirmation: false,
    //     // ui_show_create_new_staff_screen: false,
    //     staff_current_ui: "list"
    //   });
    //
    case CLIENTS_SELECT_UI:
      return {
        ...state,
        client_current_ui: action.value
      };
    //
    case CLIENTS_SELECT_SET_ID:
      return {
        ...state,
        client_detail_id: action.value
      };
    //
    case CLIENTS_FETCH_CLIENT_REQUEST:
      return {
        ...state, // staff_detail: null,
        // staff_detail_id: action.staff_id,
        client_detail_fetching: true,
        client_detail_loaded: false
        // client_detail: null,
        // staff_detail_loaded: false
      };

    case CLIENTS_FETCH_CLIENT_SUCCESS:
      return {
        ...state, // staff_detail: action.staff_detail,
        client_detail_fetching: false,
        client_detail_loaded: true
        // staff_detail_loaded: true
      };

    case CLIENTS_START_NEW_CLIENT_CREATION:
      return {
        ...state, // staff_detail: action.staff_detail,
        new_client_data: {
          first_name: '',
          last_name: '',
          email: '',
          account_type: 1,
          telephone_preferred: '3',
          language_id: '1',
          clientContacts: []
        },
        new_client_type: '1',
        new_client_submitted: false,
        new_client_created: false
        // client_detail_loaded: true
        // staff_detail_loaded: true
      };
    case CLIENTS_UPDATE_NEW_CLIENT_CREATION_CLIENT_TYPE:
      return {
        ...state, // staff_detail: action.staff_detail,
        // new_client_data: null,
        new_client_type: action.value
        // client_detail_loaded: true
        // staff_detail_loaded: true
      };
    case CLIENTS_UPDATE_NEW_CLIENT_CREATION_EMAIL:
      return {
        ...state, // staff_detail: action.staff_detail,
        new_client_data: {
          ...state.new_client_data,
          email: action.value
        }
        // new_client_type: action.value
        // client_detail_loaded: true
        // staff_detail_loaded: true
      };
    case CLIENTS_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME:
      return {
        ...state,
        new_client_data: {
          ...state.new_client_data,
          first_name: action.value
        }
      };
    case CLIENTS_UPDATE_NEW_CLIENT_CREATION_LASTNAME:
      return {
        ...state,
        new_client_data: {
          ...state.new_client_data,
          last_name: action.value
        }
      };
    case CLIENTS_UPDATE_NEW_CLIENT_CREATION:
      return {
        ...state,
        new_client_data: {
          ...state.new_client_data,
          [action.value.key]: action.value.value
        }
      };
    case CLIENTS_NEW_CLIENT_DATABASE_CREATE_REQUEST:
      return {
        ...state,
        new_client_submitted: true,
        new_client_created: false
      };
    case CLIENTS_NEW_CLIENT_DATABASE_CREATE_SUCCESS:
      return {
        ...state,
        new_client_submitted: false,
        new_client_created: true,
        client_detail_id: action.value
      };

    case CLIENTS_CREATE_COGNITO_START:
      return {
        ...state // new_client_submitted: true,
        // new_client_created: false
      };
    case CLIENTS_CREATE_COGNITO_SUCCESS:
      return {
        ...state // new_client_submitted: false,
        // new_client_created: true,
        // client_detail_id: action.value
      };

    default:
      return state;
  }
}

export default clients;
