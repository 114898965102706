import {connect} from 'react-redux';
import ClientsNew from './ClientsNew';
import {
  fetchClientList
  // selectClientUi
} from '../../../redux/actions/clients';

const mapStateToProps = state => {
  return {
    // app_state: state.app_state,
    // clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchClientList: () => {
      dispatch(fetchClientList());
    }
    // selectClientUi: (data) => {
    //   dispatch(selectClientUi(data))
    // },
  };
};

const ClientsNewContainer = connect(mapStateToProps, mapDispatchToProps)(ClientsNew);

export default ClientsNewContainer;
