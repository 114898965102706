import React from 'react';
import { connect } from 'react-redux';
import { updateNewClientCreation } from '../../../../redux/actions/clients';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button as AButton, Button, Col, DatePicker, Flex, Form, Input, message, Modal, Popconfirm, Row, Select, Space, Table } from 'antd'
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import select_styles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import { cardTitle } from '../../../../assets/jss/material-dashboard-pro-react';
import { FormattedMessage } from 'react-intl';
import { AppUrlConstants } from '../../../../AppAPI'
import { AppTemplates } from '../../../../AppTemplates'
import { ClientWizardConstants } from './ClientWizardConstants'
import { ClientAPI } from '../../ClientUtil'
import { UserOutlined, MailOutlined, SearchOutlined } from '@ant-design/icons'
import { PhoneOutlined } from '@material-ui/icons'
import { NavLink } from 'react-router-dom'
import { AppUtil } from '../../../../AppUtil'

const style = {
    ...customCheckboxRadioSwitch,
    ...select_styles,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    infoText: {
        fontWeight: '300',
        margin: '10px 0 30px',
        textAlign: 'center'
    },
    inputAdornmentIcon: { color: '#555' },
    inputAdornment: { position: 'relative' }
};

class Step3AdditionalContacts extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            country_list_prio: [],
            tableColumns: [],
            tableData: [],
            showBeneficiaryModal: false

        };
    }

    componentDidMount() {
        AppUrlConstants.fetchCountries().then(response => {
            const sortedCountries = response.sort((a, b) => a.full_name.localeCompare(b.full_name));
            this.setState({ country_list_prio: sortedCountries });
        }).catch(error => console.log(error));
        this.fetchBeneficiaries();
        const columns = this.buildTableColumns();
        this.setState({ columns: columns });
    }

    fetchBeneficiaries = () => {
        if (this.props.clients.new_client_data && this.props.clients.new_client_data.client_id != null) {
            ClientAPI.fetchClientBeneficiaries(this.props.clients.new_client_data.client_id)
                .then(response => this.props.updateNewClientCreation('clientContacts', response))
                .catch(err => message.error("Unable to fetch Beneficiaries. Please click refresh button below", 1));
        }
    }

    getColumnSearchProps = ({dataIndex, label, render = (text) => text}) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
            return (
                <div
                    style={{
                        padding: 8
                    }}
                    onKeyDown={e => e.stopPropagation()}
                >
                    <Input
                        ref={this.searchInput}
                        placeholder={`Search ${label}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(clearFilters, selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            display: 'block'
                        }}
                    />
                    <Space>
                        <AButton
                            type='primary'
                            onClick={() => this.handleSearch(clearFilters, selectedKeys, confirm, dataIndex)}
                            size='small'
                        >
                            Search
                        </AButton>
                        <AButton
                            onClick={() => clearFilters && this.handleReset(clearFilters, confirm)}
                            size='small'>
                            Reset
                        </AButton>
                    </Space>
                </div>
            )
        },
        filterIcon: filtered => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                    fontSize: '15px'
                }}
            />
        ),
        onFilter: (value, record) => {
            return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false;
        },
        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.current && this.searchInput.current.select(), 100);
            }
        },
        render: (text, record) => {
            const parts = text != null ? text.split(new RegExp(`(${this.state.searchText})`, 'gi')) : [];
            return render(
                this.state.searchColumn === dataIndex
                    ? <div>
                        {parts.map((part, index) => (
                            <span
                                key={index}
                                style={{
                                    backgroundColor: part.toLowerCase() === (this.state.searchText || '').toLowerCase() ? 'orange' : 'transparent',
                                    color: 'black', // Set the text color as needed
                                }}>{part}</span>
                        ))}
                    </div>
                    : (text), record
            )
        }
    });

    getCountry(countryId) {
       if (countryId != null) {
           const country = this.state.country_list_prio.find(item => item.id === countryId);
           return <>{country.full_name} <img alt="flags" src={require('../../../../assets/img/flags/' + country.iso_alpha_2 + '.png')} width={15} height={15}/></>;
       }
       return null;
    }

    onDeleteBtnClick = (email) => {
        const contacts = this.props.clients.new_client_data.clientContacts || [];
        const items = contacts.filter(item => item.email !== email);
        this.props.updateNewClientCreation('clientContacts', items);
    }

    buildTableColumns = () => {
        return [
            {
                title: 'Profile',
                dataIndex: 'firstName',
                key: 'firstName',
                ...this.getColumnSearchProps({
                    dataIndex: 'firstName',
                    label: 'Profile',
                    render: (text, record) => {
                        return <>
                            <span style={{display: 'block'}}>Name - <strong>{`${record.firstName} ${record.lastName}`}</strong></span>
                            <span style={{display: 'block'}}>Email - <strong>{`${record.email}`}</strong></span>
                            <span style={{display: 'block'}}>Tel - <strong>{`${record.telephone || ''}`}</strong></span>
                        </>
                    }
                })
            },
            {
                title: 'Address',
                dataIndex: 'lastName',
                key: 'lastName',
                ...this.getColumnSearchProps({
                    dataIndex: 'lastName',
                    label: 'Address',
                    render: (text, record) => {
                        return <>
                            <span style={{display: 'block'}}>Country - <strong>{this.getCountry(record.country_id)}</strong></span>
                            <span style={{display: 'block'}}>Address - <strong>{`${record.address_line_1 || ''} ${record.address_line_2 || ''} ${record.suburb || ''}`}</strong></span>
                            <span style={{display: 'block'}}>Nationality - <strong>{this.getCountry(record.country_id)}</strong></span>
                            <span style={{display: 'block'}}>Birth Place - <strong>{record.place_of_birth} {this.getCountry(record.country_id)}</strong></span>
                        </>
                    }
                })
            },
            {
                title: 'Action',
                dataIndex: 'email',
                key: 'email',
                ...this.getColumnSearchProps({
                    dataIndex: 'email',
                    label: 'Email',
                    render: (text, record, index) => {
                        return <>
                            <div className='actions-right'>
                                <Flex justify={'space-between'}>
                                    <Popconfirm
                                        title="Delete the Beneficiary"
                                        description="Are you sure to delete this Beneficiary?"
                                        onConfirm={() => this.onDeleteBtnClick(record.email)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <AButton type={'primary'} danger><i className={'fa fa-trash'}></i> {index}</AButton>
                                    </Popconfirm>

                                </Flex>
                            </div>
                        </>
                    }
                })
            }
        ];
    }

    buildTableData() {
        if (this.props.clients.new_client_data.clientContacts.length > 0) {
            return this.props.clients.new_client_data.clientContacts.map(
                (prop, key) => {
                    return {
                        id: prop.id,
                        clientId: prop.clientId,
                        sendEmail: prop.sendEmail,
                        beneficial_owner: prop.beneficial_owner,
                        firstName: prop.firstName,
                        lastName: prop.lastName,
                        email: prop.email,
                        ccEmails: prop.ccEmails,
                        position: prop.position,
                        date_of_birth: prop.date_of_birth,
                        place_of_birth: prop.place_of_birth,
                        country_of_birth: prop.country_of_birth,
                        nationality: prop.nationality,
                        telephone: prop.telephone,
                        address_line_1: prop.address_line_1,
                        address_line_2: prop.address_line_2,
                        suburb: prop.suburb,
                        state: prop.state,
                        postcode: prop.postcode,
                        country_id: prop.country_id,
                        notes: prop.notes,
                    }
                }
            )
        }
    }

    async preValidate() {
        message.info("validating step 3..", 2);
        const payloads = this.props.clients.new_client_data.clientContacts || [];
        if (this.props.clients.new_client_data.client_id != null) {
            return await ClientAPI.saveWizardClientStepThree({  contacts: payloads, clientId: this.props.clients.new_client_data.client_id })
                .then((response) =>  {
                    this.props.updateNewClientCreation('clientContacts', response.payload);
                    message.success("Step 3..OK", 2);
                    return true;
                }).catch((err) => {
                    message.error("Cannot save beneficiary. Please Contact support");
                    return false;
                })
        } else {
            message.error("Cannot find client. Please go back to step one and save client info..");
            return false;
        }
    }

    sendState() {
        return this.state;
    }

    isValidated() {
        return true;
    }

    onAddNewBeneficiaryBtnClick = () => {
        this.setState({ showBeneficiaryModal: true });
    }

    validateForm = async () => {
        const isValidated = await this.formRef.current.validateFields().then(() => true).catch(() => false);
        if (isValidated) {
            const payload = this.formRef.current.getFieldsValue();
            if (payload.date_of_birth) {
                payload.date_of_birth = AppUtil.handle_transaction_date_change(payload.date_of_birth);
            }
            if (this.props.clients.new_client_data.client_id != null) {
                payload.clientId = this.props.clients.new_client_data.client_id;
                const clientContacts = this.props.clients.new_client_data.clientContacts || [];
                const email = clientContacts.find(item => item.email === payload.email);
                if (email) {
                    message.error("An beneficiary with the email " + email + " already exists");
                    return false;
                }
                clientContacts.push(payload);
                this.props.updateNewClientCreation('clientContacts', clientContacts);
                this.setState({showBeneficiaryModal: false});
                this.formRef.current.resetFields();
            } else {
                message.error("Unable to find client. Please go back to step 1 and save the client properly");
            }
        }
    }

    render() {
        const { classes } = this.props;

        if (!this.props.clients) return null;
        if (!this.props.clients.new_client_data) return null;

        const data = this.buildTableData();

        const select_country_select_options = AppTemplates.countries(this.state.country_list_prio);

        return (
            <React.Fragment>
                {this.props.clients.new_client_data.account_type === 2 &&
                    <GridContainer justify='center'>
                        <GridItem xs={12} md={6}>
                            <em>
                                As this is a business contact, please provide details for all Ultimate Beneficial Owners
                                (other than {this.props.clients.new_client_data.first_name} {this.props.clients.new_client_data.last_name}) who have an ownership of 25% or greater of the client business.
                            </em>
                            <br/>
                            <em>
                                You DO NOT need to provide details for&nbsp;
                                {this.props.clients.new_client_data.first_name}&nbsp;
                                {this.props.clients.new_client_data.last_name}&nbsp;
                                as that has been already provided.
                            </em>
                        </GridItem>
                    </GridContainer>
                }

                <Row gutter={16}>
                    <Col span={24}>
                        <Row justify={'end'} style={{ marginBottom: '10px' }}>
                            <Button type={'primary'} onClick={this.onAddNewBeneficiaryBtnClick}>Add Beneficial Owner</Button>
                        </Row>
                        <Table columns={this.state.columns} dataSource={data} />
                    </Col>
                </Row>
                <Modal width={'800px'} open={this.state.showBeneficiaryModal} title="Add Beneficial Owner" onOk={() => this.validateForm()} onCancel={() => this.setState({ showBeneficiaryModal: false })}>
                    <Form labelCol={{ span: 8 }} ref={this.formRef} name={`clientWizardAddBeneficiaryForm`} scrollToFirstError layout={'horizontal'}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="firstName" label="First name" rules={ClientWizardConstants.beneficiaryValidationRules.FIRST_NAME}>
                                    <Input placeholder={'First Name'} />
                                </Form.Item>
                                <Form.Item name="lastName" label="Last name" rules={ClientWizardConstants.beneficiaryValidationRules.LAST_NAME}>
                                    <Input placeholder={'Last Name'} />
                                </Form.Item>
                                <Form.Item name="telephone" label="Telephone" rules={ClientWizardConstants.beneficiaryValidationRules.TELEPHONE}>
                                    <Input placeholder={'Telephone Number'} />
                                </Form.Item>
                                <Form.Item name="email" label="email" rules={ClientWizardConstants.beneficiaryValidationRules.EMAIL}>
                                    <Input placeholder={'Email Address'} />
                                </Form.Item>
                                <Form.Item name="address_line_1" label="Address Line 1" rules={ClientWizardConstants.beneficiaryValidationRules.STREET_LINE_1}>
                                    <Input placeholder={'Address Line 1'} />
                                </Form.Item>
                                <Form.Item name="address_line_2" label="Address Line 2" rules={ClientWizardConstants.beneficiaryValidationRules.STREET_LINE_2}>
                                    <Input placeholder={'Address Line 2'} />
                                </Form.Item>
                                <Form.Item name="suburb" label="Suburb" rules={ClientWizardConstants.beneficiaryValidationRules.SUBURB}>
                                    <Input placeholder={'Suburb'} />
                                </Form.Item>
                                <Form.Item name="state" label="State" rules={ClientWizardConstants.beneficiaryValidationRules.STATE}>
                                    <Input placeholder={'State'} />
                                </Form.Item>
                                <Form.Item name="postcode" label="Post Code" rules={ClientWizardConstants.beneficiaryValidationRules.POST_CODE}>
                                    <Input placeholder={'Post Code'} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="country_id"
                                           label={
                                               <span>
                                            <FormattedMessage id='newClients.step3.form.country' defaultMessage={'Country"'} />{' '}
                                        </span>
                                           }
                                           rules={ClientWizardConstants.beneficiaryValidationRules.COUNTRY}>
                                    <Select
                                        showSearch={true}
                                        placeholder='Country'
                                        optionFilterProp='alt'
                                        filterOption={(input, option) =>
                                            (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA && optionA.alt ? optionA.alt : '')
                                                .toLowerCase()
                                                .localeCompare(
                                                    (optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase()
                                                )
                                        }
                                        options={select_country_select_options}
                                    ></Select>
                                </Form.Item>
                                <Form.Item name="nationality"
                                           label={
                                               <span>
                                            <FormattedMessage id='newClients.step3.form.nationality' defaultMessage={'Nationality"'} />{' '}
                                        </span>
                                           }
                                           rules={ClientWizardConstants.beneficiaryValidationRules.NATIONALITY}>
                                    <Select
                                        showSearch={true}
                                        placeholder='Nationality'
                                        optionFilterProp='alt'
                                        filterOption={(input, option) =>
                                            (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA && optionA.alt ? optionA.alt : '')
                                                .toLowerCase()
                                                .localeCompare(
                                                    (optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase()
                                                )
                                        }
                                        options={select_country_select_options}
                                    ></Select>
                                </Form.Item>
                                <Form.Item labelCol={{ span: 12 }} name="place_of_birth"
                                           label={
                                               <span>
                                            <FormattedMessage id='newClients.step2.form.placeOfBirth' defaultMessage={'Place Of Birth City'} />
                                        </span>
                                           }
                                           rules={ClientWizardConstants.beneficiaryValidationRules.PLACE_OF_BIRTH}>
                                    <Input placeholder={'Birth City'} />
                                </Form.Item>
                                <Form.Item labelCol={{ span: 14 }} name="date_of_birth"
                                           label={
                                               <span>
                                            <FormattedMessage
                                                id='newClients.step3.form.dateOfBirth'
                                                defaultMessage={'Date Of Birth(DD/MM/YYYY)'}
                                            />
                                        </span>
                                           }
                                           rules={ClientWizardConstants.clientContactValidationRules.DATE_OF_BIRTH}>
                                    <DatePicker style={{ width: '100%' }} showTime={false} mode='date' format={'DD/MM/YYYY'} />
                                </Form.Item>
                                <Form.Item labelCol={{ span: 12 }} name="country_of_birth"
                                           label={
                                               <span>
                                            <FormattedMessage id='newClients.step2.form.placeOfBirthCountry' defaultMessage={'Country Of Birth City'} />
                                        </span>
                                           }
                                           rules={ClientWizardConstants.beneficiaryValidationRules.COUNTRY_OF_BIRTH}>
                                    <Select
                                        showSearch={true}
                                        placeholder='Country of Birth'
                                        optionFilterProp='alt'
                                        filterOption={(input, option) =>
                                            (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA && optionA.alt ? optionA.alt : '')
                                                .toLowerCase()
                                                .localeCompare(
                                                    (optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase()
                                                )
                                        }
                                        options={select_country_select_options}
                                    ></Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

            </React.Fragment>
        );
    }
}

Step3AdditionalContacts = withStyles(style)(Step3AdditionalContacts);

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        clients: state.clients
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateNewClientCreation: (key, value) => {
            dispatch(updateNewClientCreation(key, value));
        }
    };
};

const Step3AdditionalContactsContainer = connect(mapStateToProps, mapDispatchToProps)(Step3AdditionalContacts);

export default Step3AdditionalContactsContainer;
