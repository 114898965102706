import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// import BeneficiariesNew from './BeneficiariesNew';
import {
  createNewBeneficiary,
  // createNewBeneficiaryStart,
  // createNewBeneficiaryComplete,
  // cancelCreateNewBeneficiary,
  selectBeneficiaryUi,
  fetchBeneficiaryList
} from '../../../redux/actions/beneficiaries';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import blankPageStyle from "./BlankPageStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import Button from '../../../components/CustomButtons/Button';
// import BeneficiaryNewContainer from "../../../components/Beneficiaries/BeneficiaryNewContainer";
// import BeneficiaryEditContainer from "../../../components/Beneficiaries/BeneficiaryEditContainer";
import BeneficiaryNewContainer from '../../../components/Beneficiaries/BeneficiaryNew';

import {FormattedMessage} from 'react-intl';

const beneficiariesListStyle = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientsList
 * ----------------------------------------------------------------------------
 */
class BeneficiariesNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // selectUi() {
  //   switch (this.props.beneficiaries.beneficiaries_current_ui) {
  //     case "create":
  //       return <BeneficiaryNewContainer />;
  //     case "edit":
  //       return <BeneficiaryEditContainer  beneficiaries_id={this.props.beneficiaries.beneficiaries_id}/>;
  //     default:
  //       return <BeneficiaryListContainer />;
  //   }
  // }

  componentDidMount() {
    // const { fetchBeneficiaryList } = this.props
    // console.log(this.props);
    // fetchBeneficiaryList();
  }

  render() {
    // console.log(this.props);
    // if (!this.props.app_state.current_client) {
    //   return null;
    // }
    // if (this.props.app_state.current_client.account_status == 1) {
    //   this.props.history.push("/pending-registration");
    // }
    // if (this.props.app_state.current_client.account_status == 5) {
    //   this.props.history.push("/registration");
    // }

    // if (this.props.app_state.current_client.account_status == 6) {
    //   this.props.history.push("/migrate");
    // }

    const {classes} = this.props;
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage
                  id='newBeneficiary.cardItem1.title'
                  defaultMessage={`Create a new beneficiary record`}
                />
              </h4>
              <div>
                <Button round color='info' onClick={() => this.props.history.push('/beneficiaries/list')}>
                  Show All Beneficiaries
                </Button>
                {/* <Button
                  round
                  color='primary'
                  // onClick={() => this.props.history.push("/beneficiaries/create")}
                >
                  <FormattedMessage id='newBeneficiary.cardItem1.createNew' defaultMessage={`Create New`} />
                </Button> */}
                {/* <Button
                  round
                  color='info'
                  disabled
                  // onClick={() => this.props.fetchBeneficiaryList()}
                >
                  <FormattedMessage id='newBeneficiary.cardItem1.refresh' defaultMessage={`Refresh`} />
                </Button> */}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <BeneficiaryNewContainer />
                {/*<GridItem xs={12}>{this.selectUi()}</GridItem>*/}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

// BeneficiariesNew.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withStyles(beneficiariesListStyle)(BeneficiariesNew);
BeneficiariesNew =  withStyles(beneficiariesListStyle)(BeneficiariesNew);
const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    beneficiaries: state.beneficiaries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createNewBeneficiary: data => {
      dispatch(createNewBeneficiary(data));
    },
    // createNewBeneficiaryStart: () => {
    //   dispatch(createNewBeneficiaryStart())
    // },
    // createNewBeneficiaryComplete: (data) => {
    //   dispatch(createNewBeneficiaryComplete(data))
    // },
    // cancelCreateNewBeneficiary: () => {
    //   dispatch(cancelCreateNewBeneficiary())
    // },
    selectBeneficiaryUi: () => {
      dispatch(selectBeneficiaryUi());
    },
    fetchBeneficiaryList: () => {
      dispatch(fetchBeneficiaryList());
    }
  };
};

const BeneficiariesNewContainer = connect(mapStateToProps, mapDispatchToProps)(BeneficiariesNew);

export default BeneficiariesNewContainer;
