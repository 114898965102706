import {API} from 'aws-amplify';

export const BATCH_PAYMENTS_FETCH_BATCH_PAYMENTS_LIST_REQUEST = 'BATCH_PAYMENTS_FETCH_BATCH_PAYMENTS_LIST_REQUEST';
export const BATCH_PAYMENTS_FETCH_BATCH_PAYMENTS_LIST_SUCCESS = 'BATCH_PAYMENTS_FETCH_BATCH_PAYMENTS_LIST_SUCCESS';
export const BATCH_PAYMENTS_FETCH_BATCH_PAYMENT_DETAILS_BY_ID_REQUEST =
  'BATCH_PAYMENTS_FETCH_BATCH_PAYMENT_DETAILS_BY_ID_REQUEST';
export const BATCH_PAYMENTS_FETCH_BATCH_PAYMENT_DETAILS_BY_ID_SUCCESS =
  'BATCH_PAYMENTS_FETCH_BATCH_PAYMENT_DETAILS_BY_ID_SUCCESS';

export function fetchBatchPaymentListRequest() {
  return {
    type: BATCH_PAYMENTS_FETCH_BATCH_PAYMENTS_LIST_REQUEST
  };
}

export function fetchBatchPaymentListSuccess(data) {
  return {
    type: BATCH_PAYMENTS_FETCH_BATCH_PAYMENTS_LIST_SUCCESS,
    batch_payments_list: data
  };
}

export function fetchBatchPaymentDetailsByIdRequest() {
  return {
    type: BATCH_PAYMENTS_FETCH_BATCH_PAYMENT_DETAILS_BY_ID_REQUEST
  };
}

export function fetchBatchPaymentDetailsByIdSuccess(data) {
  return {
    type: BATCH_PAYMENTS_FETCH_BATCH_PAYMENT_DETAILS_BY_ID_SUCCESS,
    batch_payment_details: data
  };
}

export function fetchBatchPaymentsList() {
  return function (dispatch) {
    dispatch(fetchBatchPaymentListRequest());
    API.get('batch-payments', '/list')
      .then(response => {
        dispatch(fetchBatchPaymentListSuccess(response));
      })
      .catch(error => {
        console.log(error);
        API.get('batch-payments', '/list').then(response => {
          dispatch(fetchBatchPaymentListSuccess(response));
        });
      });
  };
}

export function fetchBatchPaymentDetailsById(batchPaymentId) {
  return function (dispatch) {
    dispatch(fetchBatchPaymentDetailsByIdRequest());
    API.get('batch-payments', `/get/${batchPaymentId}`)
      .then(response => {
        dispatch(fetchBatchPaymentDetailsByIdSuccess(response));
      })
      .catch(error => {
        console.log(error);
        API.get('batch-payments', `/get/${batchPaymentId}`).then(response => {
          dispatch(fetchBatchPaymentDetailsByIdSuccess(response));
        });
      });
  };
}
