import React, { Fragment, useRef, useState } from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Button from '../../components/CustomButtons/Button';
import {DropzoneArea} from 'material-ui-dropzone';
import {API} from 'aws-amplify';
import {s3Upload} from 'libs/awsLib.js';
import FileUploadNew from '../FileUpload/FileUploadNew'

const useStyles = makeStyles(() => ({
  fields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    maxWidth: '360px',
    margin: '16px 0'
  }
}));

const AddDocumentDialog = ({clientId, open, handleClose}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [description, setDescription] = useState('');

  const [awsFileId, setAwsFileId] = useState('');

  const [uploads, setUploads] = useState([]);

  const uploadRef = useRef();

  const handleSubmit = () => {
      if (!clientId) {
        return;
      }

      const s3FileIds = uploads.map(item => item.file_id);
      setLoading(true);
      API.post('client-documents', '/create/client_document', {body: {clientId: clientId, description, images: s3FileIds}})
        .then(response => console.log(response))
        .catch(error => {
          API.post('client-documents', '/create/client_document', {body: {clientId: clientId, description, images: s3FileIds}})
            .then(response => console.log(response))
            .catch(error => console.log(error));
        })
        .finally(() => {
            setLoading(false);
            handleClose();
        });
  };

  const onUploadImage = doc => {
    const data = [...uploads];
      data.push({
      uid: doc.file.uid,
      name: doc.file.name,
      status: 'done',
      url: doc.url,
      file_id: doc.file_id
    });
    setUploads(data);
  };

  const onRemoveImage = doc => {
    const items = uploads.filter(item => item.uid !== doc.uid);
    setUploads(items);
    uploadRef.current.restore(items.length > 0 ? items : []);
  };

  return (
    <Dialog fullWidth maxWidth={'sm'} open={open} onClose={handleClose}>
      <DialogTitle id='form-dialog-title'>Create New Document</DialogTitle>
      <DialogContent>
        <Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.fields}>
                <TextField
                  fullWidth
                  label='Description'
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  size='small'
                  autoComplete='no'
                  multiline
                  minRows={3}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography color='textSecondary' gutterBottom>
                Upload Document
              </Typography>
                <FileUploadNew listType={'picture'} ref={uploadRef} multiUpload={true} onRemoveImage={onRemoveImage} onHandleResult={onUploadImage}></FileUploadNew>
            </Grid>
          </Grid>
        </Fragment>
      </DialogContent>
      <DialogActions style={{padding: '16px 24px'}}>
        <Button disabled={uploads.length === 0 || !clientId} color='rose' onClick={handleSubmit}>
          {loading ? 'Creating...' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDocumentDialog;
