import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';

const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class ChangeLog extends React.Component {
  render() {
    const {classes} = this.props;
    // console.log(this.props);

    // Test to see if current staff has security (on any team) to create a new staff member

    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                Change Log
                {/* todo: TRANSLATE (just this heading not the whole page) */}
                {/*<FormattedMessage */}
                {/*id="staffList.cardItem1.staffListing"*/}
                {/*defaultMessage={*/}
                {/*  `Staff Management Functions`} */}
                {/*/>*/}
              </h4>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <h6>Version 1.030.1</h6>
                <ul>
                  <li>FWWCRM-1038: Improve security filtering</li>
                  <li>FWWCRM-1038: Alter required identification documents to one for new client</li>
                  <li>FWWCRM-1038: Improve new staff creation process (branding emails)</li>
                  <li>FWWCRM-1035: Add Excel CSV export to reporting</li>
                  <li>FWWCRM-1035: Add date filter to transfer reporting</li>
                </ul>

                <h6>Version 1.030.0</h6>
                <ul>
                  <li>Fix: upper/lowercase email authentication issues</li>
                  <li>Email branding (FWWCRM768)</li>
                  <li>Improve transfer list visuals (FWWCP-373, FWWCP-374, FWWCRM-730)</li>
                  <li>Translate login and registration pages (FWWCP-419, FWWCP-839)</li>
                </ul>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(ChangeLog);
