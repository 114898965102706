import {
  BATCH_PAYMENTS_FETCH_BATCH_PAYMENTS_LIST_REQUEST,
  BATCH_PAYMENTS_FETCH_BATCH_PAYMENTS_LIST_SUCCESS,
  BATCH_PAYMENTS_FETCH_BATCH_PAYMENT_DETAILS_BY_ID_REQUEST,
  BATCH_PAYMENTS_FETCH_BATCH_PAYMENT_DETAILS_BY_ID_SUCCESS
} from '../actions/batch_payments';

const initialState = {
  is_fetching: false,
  batch_payments_list: [],
  batch_payment_details: null
};

function batchPayments(state = initialState, action) {
  switch (action.type) {
    case BATCH_PAYMENTS_FETCH_BATCH_PAYMENTS_LIST_REQUEST:
      return {
        ...state,
        is_fetching: true
      };

    case BATCH_PAYMENTS_FETCH_BATCH_PAYMENTS_LIST_SUCCESS:
      return {
        ...state,
        is_fetching: false,
        batch_payments_list: action.batch_payments_list
      };
    case BATCH_PAYMENTS_FETCH_BATCH_PAYMENT_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        is_fetching: true
      };

    case BATCH_PAYMENTS_FETCH_BATCH_PAYMENT_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        is_fetching: false,
        batch_payment_details: action.batch_payment_details
      };

    default:
      return state;
  }
}

export default batchPayments;
