import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {Switch, Redirect} from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
//import Header from "../components/Header/Header.jsx";
import HeaderContainer from '../components/Header/HeaderContainer.js';
import Footer from '../components/Footer/Footer.jsx';
import SidebarContainer from '../components/Sidebar/SidebarContainer.js';

import dashboardRoutes from '../routes/dashboard.jsx';
import AuthenticatedRoute from '../components/Routes/AuthenticatedRoute';

import appStyle from '../assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx';

// import image from "../assets/img/sidebar-2.jpg";
import image from '../assets/img/sidebar-fww-01.jpg';
import background_image from '../assets/img/background-euro-notes-light.jpg';
// import background_image from "../assets/img/background-dorman.jpg";
// import logo from "../assets/img/ForexWorldWide_Logo_160x150.png";
// import logo from "../assets/img/GOSTUDY_PrimaryUsage_White.png";
// import ClientsNewContainer from "../views/Dashboard/ClientsNewContainer";
// import AppliedRoute from "../components/Routes/AppliedRoute";
// import logo from "assets/img/logo-white.svg";

// const switchRoutes = (
//   <Switch>
//     {dashboardRoutes.map((prop, key) => {
//       if (prop.redirect)
//         return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
//       if (prop.collapse)
//         return prop.views.map((prop, key) => {
//           return (
//             <Route path={prop.path} component={prop.component} key={key} />
//           );
//         });
//       return <Route path={prop.path} component={prop.component} key={key} />;
//     })}
//   </Switch>
// );

let ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', this.resizeFunction);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
    window.removeEventListener('resize', this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({mobileOpen: false});
      }
    }
  }
  handleDrawerToggle = () => {
    this.setState({mobileOpen: !this.state.mobileOpen});
  };
  getRoute() {
    return this.props.location.pathname !== '/maps/full-screen-maps';
  }
  sidebarMinimize() {
    this.setState({miniActive: !this.state.miniActive});
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({mobileOpen: false});
    }
  }

  switchRoutes() {
    return (
      <Switch>
        {/*<AuthenticatedRoute*/}
        {/*path="/clients/edit/:aws_cognito_id"*/}
        {/*component={ClientsNewContainer}*/}
        {/*key="ABC123"*/}
        {/*props={{*/}
        {/*isAuthenticated: this.props.isAuthenticated,*/}
        {/*current_user: this.props.current_user,*/}
        {/*reload_user: this.props.reload_user*/}
        {/*}}*/}
        {/*/>*/}

        {dashboardRoutes.map((prop, key) => {
          if (prop.redirect) {
            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
          }
          if (prop.collapse) {
            return prop.views.map((prop, key) => {
              return (
                <AuthenticatedRoute
                  path={prop.path}
                  component={prop.component}
                  key={key}
                  props={{
                    isAuthenticated: this.props.isAuthenticated,
                    current_user: this.props.current_user
                    // reload_user: this.props.reload_user
                  }}
                />
              );
            });
          }
          return (
            <AuthenticatedRoute
              path={prop.path}
              component={prop.component}
              key={key}
              props={{
                isAuthenticated: this.props.isAuthenticated,
                current_user: this.props.current_user
                // reload_user: this.props.reload_user
              }}
            />
          );
        })}
      </Switch>
    );
  }

  render() {
    const {classes, ...rest} = this.props;
    const mainPanel =
      classes.mainPanel +
      ' ' +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1
      });
    return (
      <div className={classes.wrapper} style={{backgroundColor: '#F4F4F4'}}>
        <SidebarContainer
          routes={dashboardRoutes}
          // logoText={"Forex WorldWide"}
          // logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color='blue'
          bgColor='black'
          // style={{backgroundColor:' #131313'}}
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref='mainPanel'>
          <HeaderContainer
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            routes={dashboardRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{this.switchRoutes()}</div>
            </div>
          ) : (
            <div className={classes.map}>{this.switchRoutes()}</div>
          )}
          {this.getRoute() ? <Footer fluid /> : null}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {classes: PropTypes.object.isRequired};

export default withStyles(appStyle)(Dashboard);
