import {API} from 'aws-amplify';
import {AppUrlConstants} from "../../AppAPI";

// export const ENTITIES_SELECT_STAFF_MEMBER = "STAFF_SELECT_STAFF_MEMBER";
export const COUNTRIES_FETCH_COUNTRY_LIST_REQUEST = 'COUNTRIES_FETCH_COUNTRY_LIST_REQUEST';
export const COUNTRIES_FETCH_ENTITY_LIST_SUCCESS = 'COUNTRIES_FETCH_ENTITY_LIST_SUCCESS';
export const ENTITIES_FETCH_ENTITY_LIST_FAILURE = 'ENTITIES_FETCH_ENTITY_LIST_FAILURE';
export const ENTITIES_CREATE_NEW_SHOW_UI = 'ENTITIES_CREATE_NEW_SHOW_UI';
export const ENTITIES_CREATE_NEW_START = 'ENTITIES_CREATE_NEW_START';
export const ENTITIES_CREATE_NEW_CANCEL = 'ENTITIES_CREATE_NEW_CANCEL';
export const ENTITIES_CREATE_NEW_COMPLETE = 'ENTITIES_CREATE_NEW_COMPLETE';
export const COUNTRIES_SELECT_UI = 'COUNTRIES_SELECT_UI';
export const ENTITIES_ENTITIES_LIST_IS_DIRTY = 'ENTITIES_ENTITIES_LIST_IS_DIRTY';

export const COUNTRIES_COUNTRY_EDIT = 'COUNTRIES_COUNTRY_EDIT';

export function requestEntityList() {
  return {
    type: COUNTRIES_FETCH_COUNTRY_LIST_REQUEST
  };
}

export function receiveCountryList(json) {
  return {
    type: COUNTRIES_FETCH_ENTITY_LIST_SUCCESS,
    countries_list: json,
    receivedAt: Date.now()
  };
}

export function fetchCountryListNotDeleted() {
  return function (dispatch) {
    dispatch(requestEntityList);
    AppUrlConstants.fetchCountries()
      .then(response => {
        dispatch(receiveCountryList(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function selectCountriesUi(value) {
  return {
    type: COUNTRIES_SELECT_UI,
    value: value
  };
}

export function entitiesListIsDirty(value) {
  return {
    type: ENTITIES_ENTITIES_LIST_IS_DIRTY,
    value: value
  };
}

export async function create_new_country_in_database(full_name, iso_alpha_2, iso_alpha_3, un_code) {
  API.post('system', `/countries/create/in-database`, {
    body: {
      full_name: full_name,
      iso_alpha_2: iso_alpha_2,
      iso_alpha_3: iso_alpha_3,
      un_code: un_code
    }
  })
    .then(database_result => {
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
}

export function createNewCountry(data) {
  console.log(data);
  return async function (dispatch) {
    dispatch(createNewEntityStart());

    try {
      await create_new_country_in_database(data.full_name, data.iso_alpha_2, data.iso_alpha_3, data.un_code);
    } catch (e) {
      console.log(e);
    }
  };
}

export function cancelCreateNewEntity() {
  return {
    type: ENTITIES_CREATE_NEW_CANCEL
  };
}

export function createNewEntityStart() {
  return {
    type: ENTITIES_CREATE_NEW_START
  };
}

export function createNewEntityComplete() {
  return {
    type: ENTITIES_CREATE_NEW_COMPLETE
  };
}

export function editCountry(country_id) {
  return {
    type: COUNTRIES_COUNTRY_EDIT,
    value: country_id
  };
}
