import {connect} from 'react-redux';
import ReportsPayout from './ReportsPayout';
// import { } from "../../../redux/actions/transfers"; //remove unused imports CRM #1036

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers: state.transfers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    //While reviewing code based: Saw this function has taken out //Will check in the future CRM #1036
  };
};

const ReportsPayoutContainer = connect(mapStateToProps, mapDispatchToProps)(ReportsPayout);

export default ReportsPayoutContainer;
