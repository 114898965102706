import React, {Fragment} from 'react';
// core components
import GridContainer from '../../Grid/GridContainer.jsx';
import GridItem from '../../Grid/GridItem.jsx';
import Card from '../../Card/Card.jsx';
import CardBody from '../../Card/CardBody.jsx';

import CardHeader from 'components/Card/CardHeader';
import CustomInput from '../../CustomInput/CustomInput';
import Button from '../../CustomButtons/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
// For CustomReactSelect
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
export const Management = ({
  classes,
  handleSubmit,
  app_state,
  state,
  handleChange,
  migrationHandleChange,
  migrationFxMember,
  migrationFwwMember,
  migrationFxBeneficiaries,
  migrationFwwBeneficiaries,
  migrationFxTransfers,
  migrationFwwTransfers,
  setState,
  handleCustomReactSelectChange,
  handleToggle
}) => {
  const client_beneficiary_select_options = state.client_beneficiary_list.map(item => ({
    value: item.id,
    label: item.nickname
  }));

  const clientRiskSelectOptions = [
    {value: 1, label: 'Low'},
    {value: 2, label: 'Medium'},
    {value: 3, label: 'High'}
  ]

  return (
    <Fragment>
      <div style={{padding: 20}}>
        <form onSubmit={handleSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              {app_state.current_staff.super_admin != 0 && (
                <Card style={{height: '90%'}}>
                  <CardHeader color='rose' icon>
                    <h4 className={classes.cardIconTitle}>Rates</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText='Default Rate Client'
                          id='default_rate_client'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            disabled: !state.edit_mode,
                            value: state.default_rate_client,
                            onChange: event => {
                              handleChange(event);
                            },
                            onBlur: event => {
                              const rate = state.default_rate_client;
                              const newRate = Math.round(rate * 100000) / 100000;
                              setState(state => {
                                return {
                                  ...state,
                                  default_rate_client: newRate
                                };
                              });

                              const defaultRateStaff = state.default_rate_staff;
                              const defaultRateTeam = state.default_rate_team;
                              const defaultRateClient = state.default_rate_client;

                              setState({
                                default_rate_total:
                                  Math.round(
                                    (Number(defaultRateClient) + Number(defaultRateStaff) + Number(defaultRateTeam)) *
                                      100000
                                  ) / 100000
                              });
                            }
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <Checkbox
                            checked={state.rates_marketCharts == '1'}
                            value='rates_marketCharts'
                            onClick={handleToggle('rates_marketCharts')}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                            inputProps={{disabled: !state.edit_mode}}
                        />
                        Market Charts
                        <br />
                        <Checkbox
                            checked={state.rates_marketAlerts == '1'}
                            value='rates_marketAlerts'
                            onClick={handleToggle('rates_marketAlerts')}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                            inputProps={{disabled: !state.edit_mode}}
                        />
                        Market Alerts
                        <br />
                      </GridItem>

                      {/*<GridItem xs={12} sm={6} md={6}>*/}
                      {/*  <CustomInput*/}
                      {/*    labelText='Default Rate Staff'*/}
                      {/*    id='default_rate_staff'*/}
                      {/*    formControlProps={{fullWidth: true}}*/}
                      {/*    inputProps={{*/}
                      {/*      disabled: !state.edit_mode,*/}
                      {/*      value: state.default_rate_staff,*/}
                      {/*      onChange: event => {*/}
                      {/*        handleChange(event);*/}
                      {/*      },*/}
                      {/*      onBlur: event => {*/}
                      {/*        const rate = state.default_rate_staff;*/}
                      {/*        const newRate = Math.round(rate * 100000) / 100000;*/}
                      {/*        setState(state => {*/}
                      {/*          return {*/}
                      {/*            ...state,*/}
                      {/*            default_rate_staff: newRate*/}
                      {/*          };*/}
                      {/*        });*/}

                      {/*        const defaultRateStaff = state.default_rate_staff;*/}
                      {/*        const defaultRateClient = state.default_rate_client;*/}
                      {/*        const defaultRateTeam = state.default_rate_team;*/}

                      {/*        setState({*/}
                      {/*          default_rate_total:*/}
                      {/*            Math.round(*/}
                      {/*              (Number(defaultRateClient) + Number(defaultRateStaff) + Number(defaultRateTeam)) **/}
                      {/*                100000*/}
                      {/*            ) / 100000*/}
                      {/*        });*/}
                      {/*      }*/}
                      {/*    }}*/}
                      {/*  />*/}
                      {/*</GridItem>*/}

                      {/*<GridItem xs={12} sm={6} md={6}>*/}
                      {/*  <CustomInput*/}
                      {/*    labelText='Default Rate Team'*/}
                      {/*    id='default_rate_team'*/}
                      {/*    formControlProps={{fullWidth: true}}*/}
                      {/*    inputProps={{*/}
                      {/*      disabled: !state.edit_mode,*/}
                      {/*      value: state.default_rate_team,*/}
                      {/*      onChange: event => {*/}
                      {/*        handleChange(event);*/}
                      {/*      },*/}
                      {/*      onBlur: event => {*/}
                      {/*        const rate = state.default_rate_team;*/}
                      {/*        setState({default_rate_team: Math.round(rate * 100000) / 100000});*/}
                      {/*        // let rateTotal = state.default_rate_total;*/}
                      {/*        const defaultRateStaff = state.default_rate_staff;*/}
                      {/*        const defaultRateClient = state.default_rate_client;*/}
                      {/*        const defaultRateTeam = state.default_rate_team;*/}
                      {/*        setState({*/}
                      {/*          default_rate_total:*/}
                      {/*            Math.round(*/}
                      {/*              (Number(defaultRateClient) + Number(defaultRateStaff) + Number(defaultRateTeam)) **/}
                      {/*                100000*/}
                      {/*            ) / 100000*/}
                      {/*        });*/}
                      {/*      }*/}
                      {/*    }}*/}
                      {/*  />*/}
                      {/*</GridItem>*/}

                    </GridContainer>
                    {/*<GridContainer>*/}
                    {/*  <GridItem xs={12} sm={12} md={6}>*/}
                    {/*    <CustomInput*/}
                    {/*      labelText='Default Rate (total)'*/}
                    {/*      id='default_rate_total'*/}
                    {/*      formControlProps={{fullWidth: true}}*/}
                    {/*      inputProps={{*/}
                    {/*        disabled: true,*/}
                    {/*        value: state.default_rate_total,*/}
                    {/*        onChange: event => {*/}
                    {/*          handleChange(event);*/}
                    {/*        }*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*  </GridItem>*/}
                    {/*</GridContainer>*/}
                  </CardBody>
                </Card>
              )}
              {!app_state.current_staff.super_admin && (
                <Card style={{height: '90%'}}>
                  <CardHeader color='rose' icon>
                    <h4 className={classes.cardIconTitle}>Rates</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText='Default Rate (total)'
                          id='default_rate_total'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            disabled: true,
                            value:
                              Math.round(
                                (Number(state.default_rate_staff) + Number(state.default_rate_team)) * 100000
                              ) / 100000,
                            onChange: event => {
                              handleChange(event);
                            }
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              )}
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card style={{height: '90%'}}>
                <CardHeader color='rose' icon>
                  <h4 className={classes.cardIconTitle}>Economic information</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} md={6}>
                      <Checkbox
                          checked={state.ec_info_dashboard_charts == '1'}
                          value='ec_info_dashboard_charts'
                          onClick={handleToggle('ec_info_dashboard_charts')}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        inputProps={{disabled: !state.edit_mode}}
                      />
                      Dashboard show charts
                      <br />
                      <Checkbox
                        checked={state.ec_info_dashboard == '1'}
                        value='ec_info_dashboard'
                        onClick={handleToggle('ec_info_dashboard')}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        inputProps={{disabled: !state.edit_mode}}
                      />
                      Dashboard show economic info
                      <br />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <Checkbox
                        checked={state.ec_info_economicnews == '1'}
                        value='ec_info_economicnews'
                        onClick={handleToggle('ec_info_economicnews')}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        inputProps={{disabled: !state.edit_mode}}
                      />
                      Access to economic news
                      <br />
                      <Checkbox
                        checked={state.ec_info_economicholidays == '1'}
                        value='ec_info_economicholidays'
                        onClick={handleToggle('ec_info_economicholidays')}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        inputProps={{disabled: !state.edit_mode}}
                      />
                      Access to economic holidays
                      <br />
                      <Checkbox
                        checked={state.ec_info_charts == '1'}
                        value='ec_info_charts'
                        onClick={handleToggle('ec_info_charts')}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        inputProps={{disabled: !state.edit_mode}}
                      />
                      Access to charts
                      <br />
                      <Checkbox
                        checked={state.ec_info_rates == '1'}
                        value='ec_info_rates'
                        onClick={handleToggle('ec_info_rates')}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        inputProps={{disabled: !state.edit_mode}}
                      />
                      Access to rates
                      <br />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card style={{height: '90%'}}>
                <CardHeader color='rose' icon>
                  <h4 className={classes.cardIconTitle}>Feature</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Checkbox
                        checked={state.show_legacy_holding_acc == '1'}
                        value='show_legacy_holding_acc'
                        onClick={handleToggle('show_legacy_holding_acc')}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        inputProps={{disabled: !state.edit_mode}}
                      />
                      Access to holding account [LEGACY]
                      <br />
                      <Checkbox
                        checked={state.feature_ledger == '1'}
                        value='feature_ledger'
                        onClick={handleToggle('feature_ledger')}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        inputProps={{disabled: !state.edit_mode}}
                      />
                      Activate <strong>Ledger (Holding Account)</strong> feature
                      <br />
                      <Checkbox
                        checked={state.feature_multiClient == '1'}
                        value='feature_multiClient'
                        onClick={handleToggle('feature_multiClient')}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        inputProps={{disabled: !state.edit_mode}}
                      />
                      Activate <strong>Multi-Client</strong> Access
                      <br />
                      <Checkbox
                          checked={state.notify_checkRates == '1'}
                          value='notify_checkRates'
                          onClick={handleToggle('notify_checkRates')}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          inputProps={{disabled: !state.edit_mode}}
                      />
                      Notify <strong>when-client is checking rates</strong>
                      <br />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card style={{height: '90%'}}>
                <CardHeader color='rose' icon>
                  <h4 className={classes.cardIconTitle}>Data Migration</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      {state.migrate_fww && 
                      <span>FWW Migrate ID: {state.migrate_fww}</span>
                      }
                      {state.migrate_fx && 
                      <span>FX Migrate ID: {state.migrate_fx}</span>
                      }
                      {(!state.migrate_fx && !state.migrate_fww) && 
                      <CustomInput
                        labelText='Backend ID'
                        id='migrationBackendId'
                        inputProps={{
                          onChange: event => {
                            migrationHandleChange(event);
                          }
                        }}
                      />
                      }
                      <br />
                      {!state.migrate_fww && 
                      <>
                      <Button disabled={!state.migrationBackendId && !state.migrate_fx} color='info' size='sm' style={{width: 150}} onClick={migrationFxMember}>
                        FX Member
                      </Button>
                      <Button disabled={!state.migrationBackendId && !state.migrate_fx} color='info' size='sm' style={{width: 150}} onClick={migrationFxBeneficiaries}>
                        FX Beneficiaries
                      </Button>
                      <Button disabled={!state.migrationBackendId && !state.migrate_fx} color='info' size='sm' style={{width: 150}} onClick={migrationFxTransfers}>
                        FX Transfers
                      </Button>
                      </>
                      }
                      <br />
                      {!state.migrate_fx && 
                      <>
                      <Button disabled={!state.migrationBackendId && !state.migrate_fww} color='info' size='sm' style={{width: 150}} onClick={migrationFwwMember}>
                        FWW Member
                      </Button>
                      <Button disabled={!state.migrationBackendId && !state.migrate_fww} color='info' size='sm' style={{width: 150}} onClick={migrationFwwBeneficiaries}>
                        FWW Beneficiaries
                      </Button>
                      <Button disabled={!state.migrationBackendId && !state.migrate_fww} color='info' size='sm' style={{width: 150}} onClick={migrationFwwTransfers}>
                        FWW Transfers
                      </Button>
                      </>
                      }
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

          <GridContainer alignItems='flex-start'>
            <GridItem xs={12} sm={6} md={6}>
              <Card style={{height: '90%'}}>
                <CardHeader color='rose' icon>
                  <h4 className={classes.cardIconTitle}>Limits</h4>
                </CardHeader>
                <CardBody>
                  <CustomReactSelect
                    label='Client Beneficiary'
                    options={client_beneficiary_select_options}
                    value={state.my_beneficiary}
                    onChange={handleCustomReactSelectChange('my_beneficiary')}
                    isClearable={false}
                    isDisabled={!state.edit_mode}
                  />
                  <Checkbox
                    checked={state.transfer_auto_accept == '1'}
                    value='transfer_auto_accept'
                    onClick={handleToggle('transfer_auto_accept')}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                    inputProps={{disabled: !state.edit_mode}}
                  />
                  Auto accept details
                  <br />
                  <Checkbox
                    checked={state.transfer_forwards_allowed == '1'}
                    value='transfer_forwards_allowed'
                    onClick={handleToggle('transfer_forwards_allowed')}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                    inputProps={{disabled: !state.edit_mode}}
                  />
                  Forwards allowed
                  <CustomInput
                    labelText='Forwards Limit'
                    id='transfer_forwards_limit'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.transfer_forwards_limit || '',
                      onChange: event => {
                        handleChange(event);
                      }
                    }}
                  />
                  <CustomInput
                    labelText='Max Deals Accepted'
                    id='transfer_max_deals_accepted'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.transfer_max_deals_accepted || '',
                      onChange: event => {
                        handleChange(event);
                      }
                    }}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card style={{height: '90%'}}>
                <CardHeader color='rose' icon>
                  <h4 className={classes.cardIconTitle}>Risk</h4>
                </CardHeader>
                <CardBody>
                  <CustomReactSelect
                    label='Client Risk'
                    options={clientRiskSelectOptions}
                    value={state.riskRating}
                    onChange={handleCustomReactSelectChange('riskRating')}
                    isClearable={false}
                    isDisabled={!state.edit_mode}
                  />
                  <CustomInput
                    labelText='Risk Staff'
                    id='riskStaff'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.riskStaff || '',
                      onChange: event => {
                        handleChange(event);
                      }
                    }}
                  />
                  <CustomInput
                    labelText='Risk Notes'
                    id='riskNotes'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.riskNotes || '',
                      onChange: event => {
                        handleChange(event);
                      }
                    }}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

          <Button color='primary' disabled={!state.edit_mode} type='submit' className={classes.updateProfileButton}>
            Update Management
          </Button>
        </form>
      </div>
    </Fragment>
  );
};
