import {combineReducers} from 'redux';

import entities from './entities';
import staff from './staff';
import clients from './clients';
import beneficiaries from './beneficiaries';
import identification from './identification';
import transfers from './transfers';
import transfers_pending from './transfers_pending';
import countries from './countries';
import currencies from './currencies';
import entity_banks from './entitybanks';
import entity_banks_account from './entitybanksaccount';
import news_staff from './news_staff';
import news_clients from './news_clients';
import remittance from './remittance_accounts';
import language from './language';
import transfers_2 from './transfers_2';
import divisions from './divisions';
import teams from './teams';
import groups from './groups';
import forwards from './forwards';
import transfers_dashboard_multiple from './transfers_dashboard_multiple';
import transferSplitPay from './transferSplitPay';
import batch_payments from './batchPayments';

import {
  UPDATE_CURRENT_BRAND,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_ID,
  UPDATE_CURRENT_TEAM,
  UPDATE_CURRENT_USER_LOG_OUT
} from '../actions';
import {PaymentRequestReducer} from "./paymentRequest";

const initialState = {
  current_brand: null,
  current_staff_id: null,
  current_staff_aws_cognito_id: null,
  current_staff: null,
  country_list: []
};

function app_state(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CURRENT_BRAND:
      // console.log(action);
      return {
        ...state,
        current_brand: action.brand
      };
    case UPDATE_CURRENT_TEAM:
      // console.log(action);
      return {
        ...state,
        current_team: action.current_team
      };
    case UPDATE_CURRENT_USER_ID:
      // console.log(action);
      return {
        ...state,
        current_staff_id: action.current_staff_id,
        current_staff_aws_cognito_id: action.current_staff_aws_cognito_id,
        current_staff: action.current_staff_record
      };

    case UPDATE_CURRENT_USER:
      // console.log(action);
      return {
        ...state,
        current_staff_id: action.current_staff_id,
        current_staff_aws_cognito_id: action.current_staff_aws_cognito_id,
        current_staff_super_admin: action.current_staff_super_admin,
        current_staff: action.current_staff_record,
        current_staff_security: action.current_staff_security,
        current_brand: action.current_brand,
        current_division: action.current_division,
        current_team: action.current_team,
        country_list: action.country_list
      };

    case UPDATE_CURRENT_USER_LOG_OUT:
      return {
        ...state,
        current_staff_id: null,
        current_staff_aws_cognito_id: null,
        current_staff: null
      };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  app_state,
  entities,
  staff,
  clients,
  beneficiaries,
  identification,
  transfers,
  countries,
  currencies,
  entity_banks,
  entity_banks_account,
  news_staff,
  news_clients,
  remittance,
  language,
  transfers_2,
  transfers_pending,
  transfers_dashboard_multiple,
  transferSplitPay,
  divisions,
  teams,
  groups,
  forwards,
  batch_payments,
  paymentRequest: PaymentRequestReducer
});

export default rootReducer;
