import React from 'react';
import PropTypes from 'prop-types';
import {API} from 'aws-amplify';

import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Button from '../../CustomButtons/Button';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react';
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
// var moment = require('moment');

const styles = {
  ...switchStyles,
  ...selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class TransferDetailPayoutCommunication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remittance_advice_email_sending: false,
      remittance_advice_email_sent: false,
      transfer_status: this.props.transfer_status
    };
  }

  // componentDidMount() {
  // }

  handle_send_payout_advice = async event => {
    // event.preventDefault();
    console.log(this.props);
    this.setState({
      remittance_advice_email_sending: true
    });
    API.post('email', `/client/payout-advice`, {
      body: {
        id: this.props.id,
        transfer_id: this.props.transfer_id,
        rate: this.props.rate,
        amount_from: this.props.amount_from,
        amount_to: this.props.amount_to,
        currency_from: this.props.currency_from,
        currency_to: this.props.currency_to,
        rate: this.props.rate,
        settlement_date: this.props.settlement_date,
        beneficiary_id: this.props.beneficiary_id,
        language_id: this.props.language_id,
        payouts_list: this.props.payouts_list
        // amount_from: this.props.amount_from,
        // eba_account_name: this.props.eba_account_name,
        // remitting_bank_id: this.props.remitting_bank_id,
        // remittance_reference: this.props.remittance_reference
      }
    })
      .then(response => {
        console.log(response);
        this.setState({
          remittance_advice_email_sent: true,
          remittance_advice_email_sending: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const {classes} = this.props;
    // console.log(this.props);

    // console.log(this.state.transfer_status);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button
              color='info'
              disabled={!this.props.enabled}
              size='sm'
              onClick={this.handle_send_payout_advice}
              className={classes.updateProfileButton}
            >
              Email
            </Button>
            &nbsp;&nbsp; Send payout advice (in {this.props.language_nickname}){'  '}
            {!this.props.enabled && <span>(insufficient security level)</span>}
            {this.state.remittance_advice_email_sending && <span>(email sending)</span>}
            {this.state.remittance_advice_email_sent && <span>(email sent)</span>}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <br />
            <Button
              fullwidth='true'
              color='info'
              //disabled={!this.props.enabled}
              size='sm'
              onClick={() => {
                var win = window.open(`/transfers/receipt/transfer/${this.props.transfer_id}`, '_blank');
                win.focus();
              }}
              className={classes.updateProfileButton}
            >
              <span style={{height: 20}}> View / PDF Payout Receipt </span>
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

TransferDetailPayoutCommunication.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(TransferDetailPayoutCommunication));
