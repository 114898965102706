import {
  TEAM_FETCH_TEAM_LIST_REQUEST,
  TEAM_INVITES_FETCH_REQUEST,
  TEAM_FETCH_TEAM_LIST_SUCCESS,
  TEAM_CREATE_NEW_SHOW_UI,
  TEAM_CREATE_NEW_START,
  TEAM_CREATE_NEW_CANCEL,
  TEAM_CREATE_NEW_COMPLETE,
  TEAM_SELECT_UI,
  TEAM_ENTITIES_LIST_IS_DIRTY,
  TEAM_SELECT_SET_ID,
  TEAM_SELECT_TEAM_REQUEST,
  TEAM_SELECT_TEAM_SUCCESS,
  TEAM_INVITES_FETCH_SUCCESS
} from '../actions/teams.js';

const initialState = {
  is_fetching: false,
  did_invalidate: false,
  teams_list_is_dirty: false,
  teams_list: [],
  teams_request_list: [],
  teams_detail_id: null,
  teams_detail_fetching: false,
  teams_current_ui: 'list',
  teams_create_new_in_process: false
};

function team(state = initialState, action) {
  switch (action.type) {
    case TEAM_FETCH_TEAM_LIST_REQUEST:
    case TEAM_INVITES_FETCH_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
      });

    case TEAM_INVITES_FETCH_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        teams_request_list: action.teams_list,
        lastUpdated: action.receivedAt
      });

    case TEAM_FETCH_TEAM_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        teams_list: action.teams_list,
        lastUpdated: action.receivedAt
      });

    case TEAM_CREATE_NEW_SHOW_UI:
      return Object.assign({}, state, {
        teams_current_ui: 'list'
      });

    case TEAM_CREATE_NEW_START:
      return Object.assign({}, state, {
        teams_create_new_in_process: true
      });

    case TEAM_CREATE_NEW_CANCEL:
      return Object.assign({}, state, {
        teams_create_new_in_process: false,
        teams_current_ui: 'list'
      });

    case TEAM_CREATE_NEW_COMPLETE:
      return Object.assign({}, state, {
        teams_create_new_in_process: false,
        teams_current_ui: 'list',
        teams_list_is_dirty: true
      });

    case TEAM_SELECT_UI:
      return Object.assign({}, state, {
        teams_current_ui: action.value
      });
    case TEAM_ENTITIES_LIST_IS_DIRTY:
      return Object.assign({}, state, {
        teams_list_is_dirty: action.value
      });

    case TEAM_SELECT_SET_ID:
      return Object.assign({}, state, {
        teams_detail_id: action.value
      });

    case TEAM_SELECT_TEAM_REQUEST:
      return Object.assign({}, state, {
        teams_detail_fetching: true
      });

    case TEAM_SELECT_TEAM_SUCCESS:
      return Object.assign({}, state, {
        teams_detail_fetching: false
      });

    default:
      return state;
  }
}

export default team;
