import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react.jsx';
import {API} from 'aws-amplify';
import {withRouter} from 'react-router-dom';
import {Button, Card, Col, Form, Input, Row, Select} from "antd";
import {connect} from "react-redux";
import {createNewGroup} from "../../redux/actions/groups";

const entityNewStyles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center'
    },
    description: {
        color: '#999999'
    },
    updateProfileButton: {
        float: 'right'
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none'
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px'
    },
    registerButton: {
        float: 'right'
    }
};

class GroupNew extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            nickname: '',
            nickname_state: '',
            team_id: '',
            team_id_state: '',
            team_list: []
        };
    }

    componentDidMount() {
        API.get('teams', `/get-list-not-deleted`)
            .then(response => {
                console.log(response);
                this.setState({
                    team_list: response
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleSubmitForm = async (values) => {
        console.log(this.props);
        await this.props.createNewGroup({
            nickname: values.nickname,
            team_id: values.team_id
        });
        this.props.history.push(`/groups/list`);
    }

    renderForm() {
        const team_list_select_options = this.state.team_list
            .sort((a, b) => a.nickname.localeCompare(b.nickname))
            .map(item => ({
                value: item.id,
                label: item.nickname
            }));

        return (
            <Card>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <h3>Enter Details for new Group</h3>
                        <Form layout={'vertical'} form={this.formRef.current} ref={this.formRef}
                              onFinish={this.handleSubmitForm}>
                            <Form.Item label={'Nickname'} name={'nickname'}
                                       rules={[{required: true, message: 'Nickname is required'}]}>
                                <Input placeholder={'Enter nickname'}/>
                            </Form.Item>
                            <Form.Item label={'Team'} name={'team_id'}
                                       rules={[{required: true, message: 'Team is required'}]}>
                                <Select placeholder={'-- SELECT TEAM --'} options={team_list_select_options}></Select>
                            </Form.Item>
                            <Form.Item>
                                <Button type={'primary'} htmlType={'submit'}>Create Group</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Card>
        );
    }

    render() {
        console.log(this.state);
        console.log("state", this.props);
        console.log("proops", this.props)
        return (
            <>
                <>{this.renderForm()}</>
            </>
        );
    }
}

const mapStateToProps = state => ({
    app_state: state.app_state
})

const mapDispatchToProps = dispatch => ({
    createNewGroup: data => dispatch(createNewGroup(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(entityNewStyles)(GroupNew)));
