/* eslint-disable react/jsx-handler-names */
import React from 'react';
import PropTypes from 'prop-types';
import {API, Auth} from 'aws-amplify';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import {Grid as DefaultGrid} from '@material-ui/core';
// @material-ui/icons

import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from '@material-ui/icons/Check';
import Business from '@material-ui/icons/Business';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import CardHeader from '../../components/Card/CardHeader.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import registerPageStyle from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle';

import {FormattedMessage} from 'react-intl';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      social: false,
      activeStep: 0,
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password_state: '',
      confirm_password: '',
      confirm_code: '',
      newUser: null,
      business_name: ''
      // referral_code: this.props.match.params.team ? this.props.match.params.team : ""
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(value) {
    const {checked} = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({checked: newChecked});
  }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value});
  };

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirm_password &&
      this.state.checked[0] === 1
    );
  }

  validateConfirmationForm() {
    return this.state.confirm_code.length > 0;
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading: true});
    try {
      const newUser = await Auth.signUp({
        username: this.state.email.toLowerCase(),
        password: this.state.password
      });
      this.handleNext();
      this.setState({newUser});
      console.log(newUser);
      await this.create_new_business_in_database(
        newUser.userSub,
        newUser.user.username.toLowerCase(),
        this.state.first_name,
        this.state.last_name,
        this.state.business_name
      );
      API.post('email', '/staff/new-business-created', {
        body: {
          data: {
            email: this.state.email.toLowerCase(),
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            password: this.state.password,
            business_name: this.state.business_name
          }
        }
      })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    } catch (e) {
      alert(e.message);
      console.log(e);
      if (e.code === 'UsernameExistsException') {
        const newUser = await Auth.resendSignUp(this.state.email);
        this.setState({newUser});
      }
    }

    this.setState({isLoading: false});
  };

  async create_new_business_in_database(aws_cognito_id, email, first_name, last_name, business_name) {
    API.post('staff', '/create/in-database-self', {
      body: {
        email: email,
        aws_cognito_id: aws_cognito_id,
        first_name: first_name,
        last_name: last_name,
        nickname: `${last_name}, ${first_name}`,
        portal_account_created: 1,
        portal_email_confirmed: 0,
        business_name: business_name
        // referral_code: referral_code
      }
    })
      .then(response => {
        console.log(response);

        return response;
      })
      .catch(error => {
        console.log(error);
      });
  }

  async confirmUser(user_id, data) {
    console.log(user_id, data);
    return API.put('staff', `/update/aws_cognito_id/${user_id}`, {body: data});
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({isLoading: true});

    try {
      await Auth.confirmSignUp(this.state.email.toLowerCase(), this.state.confirm_code);
      const signed_in_user = await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
      const database_result = await this.confirmUser(signed_in_user.username, {portal_email_confirmed: 1});
      this.props.history.push('/');
    } catch (e) {
      alert(e.message);
      this.setState({isLoading: false});
    }
  };

  // function that returns true if value is valid password, false otherwise
  validatePassword(event, value, stateName) {
    // var passwordRex = /^(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z])(?=.*\d.*\d)(?=.*\W.*\W)[a-zA-Z0-9\S]{9,}$/;
    // This pattern requires at least two lowercase letters, two uppercase letters, two digits, and two special characters. There must be a minimum of 9 characters total, and no white space characters are allowed.
    // very strong password

    // var passwordRex = /^(?=.*[!@#$%^&*()\-_=+`~\[\]{}?|])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/;
    // This pattern requires at least longer than 8 characters and contain at least 1 uppercase & 1 number and 1 special character

    const passwordRex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*\S)[A-Za-z\d\S]{6,}$/;
    // This pattern requires at least 6 characters and contain at least 1 number

    this.setState({[stateName]: event.target.value});
    if (passwordRex.test(event.target.value)) {
      this.setState({
        password_state: 'success',
        password_helptext: ''
      });
      return true;
    }
    this.setState({
      password_state: 'error',
      password_helptext: 'Must be at least 6 characters and contain at least 1 number'
    });
    return false;
  }

  render_form() {
    const {classes} = this.props;
    //setup for language components
    const languageObj_en_json = require('../../translations/en.json');
    const languageObj_zh_json = require('../../translations/zh.json');
    const languageObj_de_json = require('../../translations/de.json');
    const languageObj_es_json = require('../../translations/es.json');
    const languageObj_fr_json = require('../../translations/fr.json');
    const languageObj_it_json = require('../../translations/it.json');
    const languageObj_pt_json = require('../../translations/pt.json');

    let languageObj = '';
    switch (this.props.language.language_current_ui) {
      case 'es':
        languageObj = languageObj_es_json;
        break;
      case 'de':
        languageObj = languageObj_de_json;
        break;
      case 'zh':
        languageObj = languageObj_zh_json;
        break;
      case 'fr':
        languageObj = languageObj_fr_json;
        break;
      case 'it':
        languageObj = languageObj_it_json;
        break;
      case 'pt':
        languageObj = languageObj_pt_json;
        break;
      default:
        languageObj = languageObj_en_json;
        break;
    }
    return (
      <React.Fragment>
        <br />
        <DefaultGrid container justify='center'>
          <DefaultGrid item xs={6} sm={6} md={6}>
            <NavLink to={'/pages/login/' + this.props.app_state.current_brand}>
              <Button className={classes.nonActiveButton}>
                <b>LOGIN</b>
              </Button>
            </NavLink>
          </DefaultGrid>
          <DefaultGrid item xs={6} sm={6} md={6}>
            <Button color='twitter' className={classes.activeButton}>
              <b>REGISTER</b>
            </Button>
          </DefaultGrid>
        </DefaultGrid>

        <form className={classes.form} onSubmit={this.handleSubmit}>
          <CustomTextField
            id='business_name'
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses
            }}
            inputProps={{
              value: this.state.business_name,
              variant: 'outlined',
              label: languageObj['register.businessName'],
              style: {backgroundColor: 'white'},
              onChange: event => {
                this.handleChange(event);
              },
              startAdornment: (
                <InputAdornment position='start' className={classes.inputAdornment}>
                  <Business className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomTextField
            id='first_name'
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses
            }}
            inputProps={{
              variant: 'outlined',
              label: languageObj['register.firstname'],
              style: {backgroundColor: 'white'},
              value: this.state.first_name,
              onChange: event => {
                this.handleChange(event);
              },
              startAdornment: (
                <InputAdornment position='start' className={classes.inputAdornment}>
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomTextField
            id='last_name'
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses
            }}
            inputProps={{
              variant: 'outlined',
              label: languageObj['register.lastname'],
              style: {backgroundColor: 'white'},
              value: this.state.last_name,
              onChange: event => {
                this.handleChange(event);
              },
              startAdornment: (
                <InputAdornment position='start' className={classes.inputAdornment}>
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomTextField
            id='email'
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses
            }}
            inputProps={{
              variant: 'outlined',
              label: languageObj['register.email'],
              style: {backgroundColor: 'white'},
              value: this.state.email,
              onChange: event => {
                this.handleChange(event);
              },
              startAdornment: (
                <InputAdornment position='start' className={classes.inputAdornment}>
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              type: 'email'
            }}
          />
          <CustomTextField
            success={this.state.password_state === 'success'}
            error={this.state.password_state === 'error'}
            id='password'
            helpText={this.state.password_helptext}
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses
            }}
            inputProps={{
              variant: 'outlined',
              label: languageObj['register.password'],
              style: {backgroundColor: 'white'},
              value: this.state.password,
              onChange: event => this.validatePassword(event, 'password', 'password'),
              type: 'password',
              startAdornment: (
                <InputAdornment position='start' className={classes.inputAdornment}>
                  <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                </InputAdornment>
              )
            }}
          />
          <CustomTextField
            // labelText="Confirm Password..."
            success={this.state.confirm_password_state === 'success'}
            error={this.state.confirm_password_state === 'error'}
            id='confirm_password'
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses
            }}
            inputProps={{
              value: this.state.confirm_password,
              variant: 'outlined',
              label: languageObj['register.confirmPassword'],
              style: {
                backgroundColor: 'white',
                padding: '0px'
              },
              onChange: event => {
                this.handleChange(event);
              },
              type: 'password',
              startAdornment: (
                <InputAdornment position='start' className={classes.inputAdornment}>
                  <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                </InputAdornment>
              )
            }}
          />

          <FormControlLabel
            classes={{
              root: classes.checkboxLabelControl,
              label: classes.checkboxLabel
            }}
            control={
              <Checkbox
                tabIndex={-1}
                value={this.state.agree_terms}
                onClick={() => this.handleToggle(1)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            label={
              <span style={{color: 'black'}}>
                <FormattedMessage id='register.agreement' defaultMessage={'I agree to the '} />
                <a href='https://www.forexworldwide.com/legal' target='_blank' style={{color: 'black'}}>
                  <b>
                    <FormattedMessage id='login.termsAndConditon' defaultMessage={'Terms and Conditions'} />
                  </b>
                </a>
                .
              </span>
            }
          />
          <GridContainer justify='center'>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                color='purple'
                style={{
                  height: '50px',
                  fontSize: '14px'
                }}
                disabled={!this.validateForm()}
                key={'googlelogin'}
                type='submit'
                size='sm'
                block
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormattedMessage id='register.getStarted' defaultMessage={'Get started'} />
                  </GridItem>
                </GridContainer>
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </React.Fragment>
    );
  }

  render_confirmation_form() {
    const {classes} = this.props;
    return (
      <form className={classes.form} onSubmit={this.handleConfirmationSubmit}>
        <h5>Email validation</h5>
        <p>Please enter the confirmation code which has been emailed to your given address: {this.state.email}.</p>
        <CustomInput
          // labelText="First Name..."
          id='confirm_code'
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            value: this.state.confirm_code,
            onChange: event => {
              this.handleChange(event);
            },
            startAdornment: (
              <InputAdornment position='start' className={classes.inputAdornment}>
                <Face className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
            placeholder: 'Confirmation Code...'
          }}
        />
        <div className={classes.center}>
          <Button round color='primary' disabled={!this.validateConfirmationForm()} type='submit'>
            Validate Code
          </Button>
        </div>
      </form>
    );
  }

  handleNext = () => {
    const t = this.state.activeStep;
    this.setState({activeStep: t + 1});
  };
  render() {
    const {classes} = this.props;
    let custom_message = '';

    switch (this.props.app_state.current_brand) {
      case 'gostudy':
        custom_message = 'with GoStudy...';
        break;
      case 'gostudyadmin':
        custom_message = 'with GoStudy Admin Team...';
        break;
      case 'gostudyaustralia':
        custom_message = 'with GoStudy Australia...';
        break;
      case 'gostudyitaly':
        custom_message = 'with GoStudy Italy...';
        break;
      case 'gostudyfrance':
        custom_message = 'with GoStudy France...';
        break;
      case 'gostudyspain':
        custom_message = 'with GoStudy Spain...';
        break;
      case 'redhill':
        custom_message = 'with RedHill Education...';
        break;
      case 'abcu':
        custom_message = 'with ABC University...';
        break;
      case 'smithsonian':
        custom_message = 'with Smithsonian College...';
        break;
      case 'smithsonianadelaide':
        custom_message = 'with Smithsonian College Adelaide...';
        break;
      case 'smithsoniansydney':
        custom_message = 'with Smithsonian College Sydney...';
        break;
      case 'dorman':
        custom_message = 'with Dorman Securities Forex Transfer Services';
        break;
      case 'abctours':
        custom_message = 'with ABC Tours FX Portal';
        break;
      default:
        custom_message = '';
        break;
    }

    //setup for language components
    const languageObj_en_json = require('../../translations/en.json');
    const languageObj_zh_json = require('../../translations/zh.json');
    const languageObj_de_json = require('../../translations/de.json');
    const languageObj_es_json = require('../../translations/es.json');
    const languageObj_fr_json = require('../../translations/fr.json');
    const languageObj_it_json = require('../../translations/it.json');
    const languageObj_pt_json = require('../../translations/pt.json');

    let languageObj = '';
    switch (this.props.language.language_current_ui) {
      case 'es':
        languageObj = languageObj_es_json;
        break;
      case 'de':
        languageObj = languageObj_de_json;
        break;
      case 'zh':
        languageObj = languageObj_zh_json;
        break;
      case 'fr':
        languageObj = languageObj_fr_json;
        break;
      case 'it':
        languageObj = languageObj_it_json;
        break;
      case 'pt':
        languageObj = languageObj_pt_json;
        break;
      default:
        languageObj = languageObj_en_json;
        break;
    }

    const steps = getSteps();

    function getSteps() {
      return ['PERSONAL DETAILS', 'VERIFICATION', 'COMPLETE REGISTRATION'];
    }

    let register_form_title = 'Client Portal Register';
    register_form_title = languageObj['register.formTitle'];

    switch (this.props.app_state.current_brand) {
      case 'dorman':
        register_form_title = 'Dorman Client Register';
        break;
      case 'abctravel':
        register_form_title = 'ABC Travel Client Register';
        break;
      case 'gostudy':
        register_form_title = 'Go Study Student Register';
        break;
      case 'crocmedia':
        register_form_title = 'CrocMedia FX Portal Register';
        break;
      default:
        register_form_title = 'Register Your Business';
        break;
    }

    // Branding
    let logo;
    let form_text = '';
    switch (this.props.app_state.current_brand) {
      case 'dorman':
        form_text = 'Dorman';
        break;
      case 'gostudy':
        logo = require('assets/img/GOSTUDY_HorizontalWhite.png');
        form_text = 'GoStudy';
        break;
      case 'abctravel':
        logo = require('assets/img/brand/travel-logo.jpg');
        form_text = 'ABC Travel';
        break;
      case 'crocmedia':
        logo = require('assets/img/brand/crocmedia_white.png');
        form_text = 'CrocMedia';
        break;
      default:
        break;
    }
    return (
      <div className={classes.container}>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={8} md={6}>
            <Card className={classes.cardSignup}>
              <CardHeader className={`${classes.cardHeader} ${classes.center}`} color='primary'>
                {logo && <img src={logo} height={70} width={''} alt='' />}
                <h4
                  className={classes.cardTitle}
                  style={{
                    paddingTop: 12,
                    fontWeight: '300',
                    color: 'white'
                  }}
                >
                  {register_form_title}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify='center'>
                  <GridItem xs={12} sm={12} md={12}>
                    <Stepper className={classes.root} activeStep={this.state.activeStep}>
                      {steps.map(label => (
                        <Step key={label}>
                          <StepLabel>
                            <span style={{fontSize: '10px'}}> {label}</span>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    {this.state.newUser === null ? this.render_form() : this.render_confirmation_form()}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

RegisterPage.propTypes = {classes: PropTypes.object.isRequired};

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const RegisterPageContainer = connect(mapStateToProps)(RegisterPage);

export default withStyles(registerPageStyle)(RegisterPageContainer);
