import React from 'react';
import {connect} from 'react-redux';
import {
  fetchStaffListNotDeleted,
  createNewStaffMember,
  cancelCreateNewStaffMember,
  confirmNewStaffMember,
  selectStaffUi
} from '../../redux/actions/staff';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
// import PermIdentity from "@material-ui/icons/PermIdentity";
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import Card from '../Card/Card.jsx';
import CardBody from '../Card/CardBody.jsx';
import CardHeader from '../Card/CardHeader';
import FormControl from '@material-ui/core/FormControl';

import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react.jsx';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {API} from 'aws-amplify';

import {FormattedMessage} from 'react-intl';

var generator = require('generate-password');

const styles = {
  ...selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {float: 'right'}
};

class StaffNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // register form
      team_list: [],
      title: '',
      first_name: '',
      first_name_state: '',
      middle_name: '',
      last_name: '',
      last_name_state: '',
      email: '',
      email_state: '',
      team_security: 0,
      // password: '',
      // password_state: '',
      // password_confirm: '',
      // password_confirm_state: '',
      newUser: null,
      team_id: '',
      team_id_state: '',
      team_nickname: '',
      division_nickname: '',
      entity_nickname: '',
      error: false,
      error_message: ''
    };
  }

  componentDidMount() {
    this.getTeamList()
    // API.get('teams', '/get-list-not-deleted')
    //   .then(response => {
    //     // console.log(response);
    //     this.setState({team_list: response});
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
      console.log(this.props.app_state.current_staff)
      this.getTeamList()
      this.setState({team_id: this.props.app_state.current_staff.team_id});
      this.getTeamDetails(this.props.app_state.current_staff.team_id);
    }
  }

  getTeamList = () => {
    if (!this.props.app_state.current_staff) {
      this.setState({team_list: []});
    } else {
      const staffID = this.props.app_state.current_staff.id
      API.get('teams', `/list/staff/${staffID}`)
      .then(response => {
        console.log(response);
        this.setState({team_list: response});
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  validateForm() {
    if (this.state.first_name_state === '') {
      this.setState({first_name_state: 'error'});
    }
    if (this.state.last_name_state === '') {
      this.setState({last_name_state: 'error'});
    }
    if (this.state.email_state === '') {
      this.setState({email_state: 'error'});
    }
    // if (this.state.password_state === '') {
    //   this.setState({password_state: 'error'});
    // }
    // if (this.state.password_confirm_state === '') {
    //   this.setState({password_confirm_state: 'error'});
    // }
    if (this.state.team_id < 0) {
      this.setState({team_id_state: 'error'});
    }

    if (
      this.state.first_name_state === 'success' &&
      this.state.last_name_state === 'success' &&
      this.state.email_state === 'success' &&
      this.state.team_id_state === 'success'
      // this.state.password_state === 'success'
    ) {
      return true;
    }
    return false;
  }

  verifyEmail(value) {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'checkbox':
        if (event.target.checked) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'max-length':
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'url':
        if (this.verifyUrl(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'min-value':
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'max-value':
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'range':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    switch (type) {
      case 'checkbox':
        this.setState({[stateName]: event.target.checked});
        break;
      default:
        this.setState({[stateName]: event.target.value});
        break;
    }
  }
  handleSelectChange = event => {
    this.setState({[event.target.name]: event.target.value});

    if (event.target.name === 'team_id') {
      this.setState({team_id_state: 'success'});
      this.getTeamDetails(event.target.value);

      // Run check to see what security level the current staff member has with that team
      // if (this.props.app_state.current_staff && this.props.app_state.current_staff_security) {
      //   for (var i = 0; i < this.props.app_state.current_staff_security.team_access.length; i++) {
      //     if (this.props.app_state.current_staff_security.team_access[i].team_id === event.target.value) {
      //       console.log(`This team's security is ${this.props.app_state.current_staff_security.team_access[i].access}.`)
      //       this.setState({
      //         team_security: this.props.app_state.current_staff_security.team_access[i].access - 1
      //       })
      //     }
      //   }
      // }
    }
  };
  getTeamDetails(team_id) {
    API.get('teams', `/get/div-entity/${team_id}`)
      .then(response => {
        // console.log(response);
        this.setState({
          division_nickname: response.division_nickname,
          entity_nickname: response.entity_nickname,
          team_nickname: response.team_nickname,
          division_id: response.division_id,
          entity_id: response.entity_id
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  handleSubmitForm() {
    this.setState({
      error: false,
      error_message: ''
    });

    if (this.validateForm()) {

      // var password = generator.generate({
      //   length: 8,
      //   numbers: true,
      //   symbols: true
      // });


      API.post('staff', '/get/email-check', {body: {email: this.state.email.toLowerCase()}})
        .then(response => {
          // console.log(response);
          if (response.length > 0) {
            this.setState({
              error: true,
              error_message: 'Email address already exists.'
            });
            return;
          }
          this.props.createNewStaffMember({
            title: this.state.title,
            first_name: this.state.first_name,
            middle_name: this.state.middle_name,
            last_name: this.state.last_name,
            email: this.state.email.toLowerCase(),
            team_id: this.state.team_id,
            division_id: this.state.division_id,
            entity_id: this.state.entity_id,
            // password: password
          });
          this.props.fetchStaffList();
          this.props.history.push('/settings/stafflist');
          // this.setState({newUser: 'sd'});
        })
        .catch(error => {
          console.log(error);
          this.setState({
            error: true,
            error_message: 'Internal error'
          });
        });
    } else {
      return;
    }
  }

  handleCancel() {
    this.props.history.push('/settings/stafflist');
  }

  render() {
    const {classes} = this.props;
    const form_valid = this.validateForm();
    return (
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <h3>
            <FormattedMessage id='newStaff.title' defaultMessage={'Create a new staff member'} />
          </h3>
          <p>
            <FormattedMessage
              id='newStaff.subtitle'
              defaultMessage={`Provide details for your new staff member in order to create an
              account.`}
            />
          </p>
          <p>
            <FormattedMessage
              id='newStaff.instruction'
              defaultMessage={`Once you press REGISTER, an account will be created
              in this CRM and you will be taken to an "edit" page where you can
              choose to provide further details and/or create a login for them to
              access this portal.`}
            />
          </p>
          <GridContainer justify='center'>
            <GridItem xs={12} sm={6}>
              <Card>
                <CardHeader>
                  <h5>Staff Details</h5>
                </CardHeader>
                <CardBody>
                  <FormControl className={classes.selectFormControl}>
                    <InputLabel htmlFor='title'>title</InputLabel>
                    <Select
                      value={this.state.title}
                      onChange={this.handleSelectChange}
                      inputProps={{
                        name: 'title',
                        id: 'title'
                      }}
                    >
                      <MenuItem value='Mr'>Mr</MenuItem>
                      <MenuItem value='Ms'>Ms</MenuItem>
                      <MenuItem value='Mrs'>Mrs</MenuItem>
                    </Select>
                  </FormControl>

                  <CustomInput
                    success={this.state.first_name_state === 'success'}
                    error={this.state.first_name_state === 'error'}
                    labelText='First Name *'
                    id='first_name'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.first_name,
                      onChange: event => this.change(event, 'first_name', 'length', 0),
                      type: 'text'
                    }}
                  />
                  <CustomInput
                    labelText='Middle Name(s)'
                    id='middle_name'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.middle_name,
                      onChange: event => this.change(event, 'middle_name'),
                      type: 'text'
                    }}
                  />
                  <CustomInput
                    success={this.state.last_name_state === 'success'}
                    error={this.state.last_name_state === 'error'}
                    labelText='Last Name *'
                    id='last_name'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.last_name,
                      onChange: event => this.change(event, 'last_name', 'length', 0),
                      type: 'text'
                    }}
                  />
                  <CustomInput
                    success={this.state.email_state === 'success'}
                    error={this.state.email_state === 'error'}
                    labelText='Email Address *'
                    id='email'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.email,
                      onChange: event => this.change(event, 'email', 'email'),
                      type: 'email'
                    }}
                  />
                  <div className={classes.formCategory}>
                    <small>*</small> Required fields
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Card>
                <CardHeader>
                  <h5>Classification</h5>
                </CardHeader>
                <CardBody>
                  <React.Fragment>
                    <GridContainer>
                      <GridItem xs={12} sm={12}>
                        <FormControl fullWidth error={!this.state.team_id > 0} className={classes.selectFormControl}>
                          <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                            Team
                          </InputLabel>
                          <Select
                            MenuProps={{className: classes.selectMenu}}
                            classes={{select: classes.select}}
                            value={this.state.team_id}
                            onChange={this.handleSelectChange}
                            inputProps={{
                              name: 'team_id',
                              id: 'team-id'
                            }}
                          >
                            <MenuItem key='0x0' disabled classes={{root: classes.selectMenuItem}}>
                              Select staff member
                            </MenuItem>
                            {this.state.team_list
                              .sort((a, b) => a.nickname.localeCompare(b.nickname))
                              .map(item => {
                                return (
                                  <MenuItem
                                    key={item.id}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value={item.id}
                                  >
                                    {item.nickname.toUpperCase()}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </GridItem>
                      {/* {this.state.team_id > 0 && (
                        <GridItem xs={12} sm={10}>
                          <Card>
                            <CardBody>
                              <p>This staff member will be allocated to Team {this.state.team_nickname}.</p>
                              <p>The {this.state.team_nickname} Team is a member of:</p>
                              <ul>
                                <li>Division: {this.state.division_nickname}</li>
                                <li>Entity: {this.state.entity_nickname}</li>
                              </ul>
                            </CardBody>
                          </Card>
                        </GridItem>
                      )} */}
                    </GridContainer>
                  </React.Fragment>
                </CardBody>
              </Card>
              {/* <Card>
                <CardHeader>
                  <h5>Password</h5>
                </CardHeader>
                <CardBody>
                  <React.Fragment>
                    <GridContainer>
                      <GridItem xs={12} sm={12}>
                        <CustomInput
                          success={this.state.password_state === 'success'}
                          error={this.state.password_state === 'error'}
                          labelText='Password *'
                          id='password'
                          helpText={this.state.password_helptext}
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value: this.state.password,
                            onChange: event => this.verifyPassword(event, 'password', 'password'),
                            type: 'password'
                          }}
                        />
                        <CustomInput
                          success={this.state.password_confirm_state === 'success'}
                          error={this.state.password_confirm_state === 'error'}
                          labelText='Confirm Password *'
                          id='password_confirm'
                          formControlProps={{fullWidth: true}}
                          inputProps={{
                            value: this.state.password_confirm,
                            onChange: event => this.change(event, 'password_confirm', 'equalTo', 'password'),
                            type: 'password'
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </React.Fragment>
                </CardBody>
              </Card> */}
            </GridItem>
            <GridItem xs={12}>
              {this.state.error && <strong>{this.state.error_message}</strong>}
              <br />
              <Button
                color='primary'
                disabled={!form_valid}
                onClick={() => this.handleSubmitForm()}
                className={classes.registerButton}
              >
                <FormattedMessage id='newStaff.register' defaultMessage={'Register'} />
              </Button>
              <Button color='info' onClick={() => this.handleCancel()} className={classes.registerButton}>
                <FormattedMessage id='newStaff.cancel' defaultMessage={'Cancel'} />
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

StaffNew =  withRouter(withStyles(styles)(StaffNew));

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    staff: state.staff
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createNewStaffMember: data => {
      // console.log(data)
      dispatch(createNewStaffMember(data));
    },
    cancelCreateNewStaffMember: () => {
      // console.log(data)
      dispatch(cancelCreateNewStaffMember());
    },
    confirmNewStaffMember: data => {
      dispatch(confirmNewStaffMember(data));
    },
    selectStaffUi: data => {
      dispatch(selectStaffUi(data));
    },
    fetchStaffList: () => {
      dispatch(fetchStaffListNotDeleted());
    }
  };
};

const StaffNewContainer = connect(mapStateToProps, mapDispatchToProps)(StaffNew);

export default StaffNewContainer;
