import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
// import ListItem from "@material-ui/core/ListItem";

import footerStyle from 'assets/jss/material-dashboard-pro-react/components/footerStyle';

function Footer({...props}) {
  const {classes, fluid, white} = props;
  const {ref_code} = props;

  let footer_text = 'Forex WorldWide';
  let footer_url = 'https://www.forexworldwide.com';

  let current_domain;
  try {
    current_domain = this.props.app_state.current_division.brand_domain;
    if (typeof this.state.domain_list[current_domain - 1].id === 'number') {
      footer_text = this.state.domain_list[current_domain - 1].domain_text;
      footer_url = `https://www.${this.state.domain_list[current_domain - 1].domain_name}`;
    }
  } catch (error) {
    //console.log(error);
  }

  if (typeof ref_code === 'string') {
    switch (ref_code) {
      case 'forexsport':
        footer_text = 'Forex Sport';
        footer_url = 'https://www.forexsport.com';
        break;
      case 'forexstudent':
        footer_text = 'Forex Student';
        footer_url = 'https://www.forexstudent.com';
        break;
      case 'forexworldwide':
        footer_text = 'Forex WorldWide';
        footer_url = 'https://www.forexworldwide.com';
        break;
      case 'dorman':
        footer_text = 'Forex Sport';
        footer_url = 'https://www.forexsport.com';
        break;
      default:
        footer_text = 'Forex WorldWide';
        footer_url = 'https://www.forexworldwide.com';
        break;
    }
  }

  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [' ' + classes.whiteColor]: white
    });
  // var block = cx({
  //   [classes.block]: true,
  //   [classes.whiteColor]: white
  // });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <p className={classes.right}>
          &copy; {1900 + new Date().getYear()}{' '}
          <a href={footer_url} className={anchor}>
            {footer_text}
          </a>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
