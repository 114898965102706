import React from 'react';
import {API} from 'aws-amplify';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

import {TextField, Typography, Box} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';


const style = {
  // infoText: {
  //   fontWeight: '300',
  //   margin: '10px 0 30px',
  //   textAlign: 'center'
  // },
  // inputAdornmentIcon: {color: '#555'},
  // inputAdornment: {position: 'relative'}
};

class SelectClient extends React.Component {
  constructor(props) {
    super(props);
    // this.handleUseTeamClient = this.handleUseTeamClient.bind(this);
    this.state = {
      client_list: [],
      client_id: '',
      client: {},
      team_nickname: '',
      teamClientId: null,
      // division_nickname: '',
      // entity_nickname: '',
      with_query_string: false
    };
  }

  componentDidMount() {
    API.get('clients', '/get-list-not-deleted')
      .then(response => {
        // console.log(response);
        this.setState({client_list: response});
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getClientDetails(client_id) {
    return await API.get('clients', `/get/id/${client_id}`)
      .then(response => {
        this.setState({client: response});
        return response;
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleClientSelectChange = async event => {
    this.setState({client_id: event.value});
    // this.props.updateNewTransferCreation('client_id', event.value);
    // console.log(event.value);
    const clientDetails = await this.getClientDetails(event.value);
    this.props.setSelectedClient(clientDetails || null);
  };

  
  render() {
    const {classes} = this.props;

    const client_list_select_options = this.state.client_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return {
          value: item.id,
          label: `${item.nickname} [${item.id.toString().padStart(5, '0')}] (${item.email})`
        };
      });

    return (
      <React.Fragment>

        <GridContainer justify='left'>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <GridContainer>
              <GridItem xs={10} sm={10} md={10} lg={10}>
                <CustomReactSelect
                  label='Select client'
                  options={client_list_select_options}
                  value={this.state.client_id}
                  onChange={this.handleClientSelectChange}
                />
              </GridItem>
              {/* <GridItem xs={2} sm={2} md={2} lg={2}>
                <Button
                  disabled={this.props.app_state.current_team && this.props.app_state.current_team.teamClientId == null}
                  onClick={this.handleUseTeamClient}
                >
                  Use Team Client
                </Button>
              </GridItem> */}
            </GridContainer>
          </GridItem>
        </GridContainer>

        {Boolean(this.state.client_id) && (
          <React.Fragment>
            <p>

            {this.state.client.account_type == 2 && (
                <em>
                  {this.state.client.business_company_name && (
                    <span>
                      <b>{this.state.client.business_company_name.toUpperCase()} </b>
                    </span>
                  )}
                </em>
            )}

            {/* if the client status is not 2 (approved) then display warning */}
            {this.state.client.account_status !== 2 && (
                <em style={{color: 'red'}}>
                  {this.state.client.account_status_nickname && (
                    <span>
                      <br/>
                      Warning: this client has status {this.state.client.account_status_nickname.toUpperCase()}{' '}
                    </span>
                  )}
                </em>
            )}
              <br/>
              {this.state.client.first_name} {this.state.client.last_name}<br/>
              {this.state.client.email}<br/>
            </p>

          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}



const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers_2: state.transfers_2,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

const SelectClientContainer = connect(mapStateToProps, mapDispatchToProps)(SelectClient);

export default withStyles(style)(SelectClientContainer);


