import React, {useState} from 'react';
import {API} from 'aws-amplify';
import {Form, Input, message, Modal} from "antd";
import {useForm} from "antd/es/form/Form";

const NewClientContactsDialog = ({defaultClientId, open, handleClose}) => {

    const [contactForm] = useForm();
    const [state, setState] = useState({
        isModalOpen: false,
        loading: false
    });

    const handleCreateNewClientContact = (values) => {
        setState(prev => ({...prev, loading: true}));
        values.clientId = defaultClientId;
        API.post('clientcontacts', '/create/client_contact', {body: values})
            .then(response => {
                console.log(response);
                setState(prev => ({...prev}));
                handleClose();
            })
            .catch(err => {
                console.log(err);
                message.error("Unable to add client contact. Please try again..");
            }).finally(() => {
            setState(prev => ({...prev, loading: false}));
        });
    };

    if (defaultClientId == null) {
        return <>...</>
    }

    return (
        <Modal title={'Add New Client Contact'}
               open={open}
               okButtonProps={{
                   loading: state.loading
               }}
               onOk={() => contactForm.submit()}
               onCancel={() => {
                   handleClose();
                   contactForm.resetFields();
               }}>
            <Form form={contactForm} onFinish={handleCreateNewClientContact}>
                <Form.Item name={'firstName'} label={'First Name'}
                           rules={[{required: true, message: 'First name is required'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'lastName'} label={'Last Name'}
                           rules={[{required: true, message: 'Last name is required'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'email'} label={'Email'}>
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default NewClientContactsDialog;
