import {
  GROUP_FETCH_GROUP_LIST_REQUEST,
  GROUP_FETCH_GROUP_LIST_SUCCESS,
  GROUP_CREATE_NEW_SHOW_UI,
  GROUP_CREATE_NEW_START,
  GROUP_CREATE_NEW_CANCEL,
  GROUP_CREATE_NEW_COMPLETE,
  GROUP_SELECT_UI,
  GROUP_ENTITIES_LIST_IS_DIRTY,
  GROUP_SELECT_SET_ID,
  GROUP_SELECT_GROUP_REQUEST,
  GROUP_SELECT_GROUP_SUCCESS
} from '../actions/groups.js';

const initialState = {
  is_fetching: false,
  did_invalidate: false,
  groups_list_is_dirty: false,
  groups_list: [],
  groups_detail_id: null,
  groups_detail_fetching: false,
  groups_current_ui: 'list',
  groups_create_new_in_process: false
};

function group(state = initialState, action) {
  switch (action.type) {
    case GROUP_FETCH_GROUP_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
      });

    case GROUP_FETCH_GROUP_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        groups_list: action.groups_list,
        lastUpdated: action.receivedAt
      });

    case GROUP_CREATE_NEW_SHOW_UI:
      return Object.assign({}, state, {
        groups_current_ui: 'list'
      });

    case GROUP_CREATE_NEW_START:
      return Object.assign({}, state, {
        groups_create_new_in_process: true
      });

    case GROUP_CREATE_NEW_CANCEL:
      return Object.assign({}, state, {
        groups_create_new_in_process: false,
        groups_current_ui: 'list'
      });

    case GROUP_CREATE_NEW_COMPLETE:
      return Object.assign({}, state, {
        groups_create_new_in_process: false,
        groups_current_ui: 'list',
        groups_list_is_dirty: true
      });

    case GROUP_SELECT_UI:
      return Object.assign({}, state, {
        groups_current_ui: action.value
      });
    case GROUP_ENTITIES_LIST_IS_DIRTY:
      return Object.assign({}, state, {
        groups_list_is_dirty: action.value
      });

    case GROUP_SELECT_SET_ID:
      return Object.assign({}, state, {
        groups_detail_id: action.value
      });

    case GROUP_SELECT_GROUP_REQUEST:
      return Object.assign({}, state, {
        groups_detail_fetching: true
      });

    case GROUP_SELECT_GROUP_SUCCESS:
      return Object.assign({}, state, {
        groups_detail_fetching: false
      });

    default:
      return state;
  }
}

export default group;
