import React, {Fragment, useEffect, useState} from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import PermIdentity from '@material-ui/icons/PermIdentity';
import BeneficiaryDocumentsView from '../../../features/Beneficiaries/documents/View.jsx';

import {Typography} from '@material-ui/core';
import {FormattedMessage} from 'react-intl';
import {API} from 'aws-amplify';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */

const styles = {
  cardTitle,
  ...customCheckboxRadioSwitch,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  editFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    maxWidth: '350px',
    margin: '16px 0'
  }
};

const DocumentsViewContainer = props => {
  const {classes, match} = props;
  const beneficiaryDocumentId = match.params.id;

  const [beneficiaryDocumentData, setBeneficiaryDocumentData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchBeneficiaryDocumentById = id => {
    if (!id) {
      return;
    }
    setLoading(true);
    API.get('beneficiary-documents', `/${id}`)
      .then(response => {
        setBeneficiaryDocumentData(response);
      })
      .catch(error => {
        console.log(error);
        API.get('beneficiary-documents', `/${id}`)
          .then(response => {
            setBeneficiaryDocumentData(response);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log({beneficiaryDocumentId});
    if (beneficiaryDocumentId) {
      fetchBeneficiaryDocumentById(beneficiaryDocumentId);
    }
  }, [beneficiaryDocumentId]);

  return (
    <Fragment>
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <PermIdentity />
          </CardIcon>
          <div className={classes.flexEnd}>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage
                id='beneficiaryDocuments.cardItem1.beneficiaryDocumentsView'
                defaultMessage={'View Beneficiary Documents'}
              />
            </h4>
          </div>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              {loading ? (
                <Typography>Loading...</Typography>
              ) : (
                <BeneficiaryDocumentsView
                  classes={classes}
                  beneficiaryDocumentData={beneficiaryDocumentData}
                  beneficiaryDocumentId={beneficiaryDocumentId}
                />
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default withStyles(styles)(DocumentsViewContainer);
