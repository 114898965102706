import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
// react component for creating dynamic tables
import ReactTable from 'react-table';

import {API} from 'aws-amplify';
import {AiOutlineSearch} from 'react-icons/ai';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';
// import GroupList from "./GroupList";
import {
    requestEntityList,
    fetchEntityListNotDeleted,
    entitiesListIsDirty,
    setEntityDetailId,
    selectEntityUi
} from '../../redux/actions/groups';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import {Button, Flex, Table} from 'antd';
import ListTable from '../ListTable/ListTable';
import {RemoveOutlined} from '@material-ui/icons';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - Group List
 * ----------------------------------------------------------------------------
 */
class GroupListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            group_list: []
        };
    }

    componentDidMount() {
        this.getGroupList();
        const columns = this.buildTableColumns();
        this.setState({columns: columns});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
            this.getGroupList();
        }
    }

    getGroupList = () => {
        API.get('groups', `/get/list/${this.props.app_state.current_staff.team_id}`)
            .then(response => {
                console.log(response);
                this.setState({
                    group_list: response
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    buildTableColumns = () => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                sorter: {compare: (a, b) => a.id - b.id},
                ...this.props.getColumnSearchProps({
                    dataIndex: 'id',
                    label: 'id',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{record.id}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Group Name',
                dataIndex: 'nickname',
                key: 'nickname',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'nickname',
                    label: 'nickname',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{record.nickname}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions'
            }
        ];
    };

    buildTableData() {
        var entity_list_length = this.state.group_list.length;
        if (entity_list_length > 0) {
            return this.state.group_list.map((prop, key) => {
                return {
                    id: prop.id,
                    nickname: prop.nickname,
                    system_email: prop.system_email,
                    actions: (
                        <Flex justify={'start'} gap={5}>
                            <Button
                                type={'primary'}
                                onClick={() => {
                                    this.props.history.push(`/groups/edit/${prop.id}`);
                                }}
                                icon={<EditOutlined />}
                            ></Button>
                            <Button
                                type={'primary'}
                                danger={true}
                                onClick={() => {
                                    const data = this.props.divisions.divisions_list;
                                    data.find((o, i) => {
                                        if (o.id === prop.id) {
                                            API.put('divisions', `/update/id/${prop.id}`, {
                                                body: {deleted: true}
                                            })
                                                .then(response => {
                                                    console.log(response);
                                                    this.props.fetchEntityListNotDeleted();
                                                    // dispatch(receiveStaffList(response));
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                            return true;
                                        }
                                        return false;
                                    });
                                    this.setState({data: data});
                                }}
                                icon={<DeleteOutlined />}
                            ></Button>
                        </Flex>
                    )
                };
            });
        }
    }

    getFilter = (filter, onChange) => {
        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <AiOutlineSearch />
                    <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
                </div>
            </React.Fragment>
        );
    };

    render() {
        return <Table dataSource={this.buildTableData()} columns={this.state.columns}></Table>;
    }
}

// export default withRouter(withStyles(styles)(GroupList));

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        groups: state.groups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        requestEntityList: () => {
            dispatch(requestEntityList());
        },
        fetchEntityListNotDeleted: team_id => {
            dispatch(fetchEntityListNotDeleted(team_id));
        },
        entitiesListIsDirty: value => {
            dispatch(entitiesListIsDirty(value));
        },
        setEntityDetailId: id => {
            dispatch(setEntityDetailId(id));
        },
        selectEntityUi: value => {
            dispatch(selectEntityUi(value));
        }
    };
};

const GroupListComponentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(styles)(ListTable(GroupListComponent))));

export default GroupListComponentContainer;
