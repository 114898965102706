import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// import BeneficiariesList from './BeneficiariesList';
// import {fetchBeneficiaryList, selectBeneficiaryUi} from '../../../redux/actions/beneficiaries';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import blankPageStyle from "./BlankPageStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import Button from '../../../components/CustomButtons/Button';
import BeneficiaryListContainer from '../../../components/Beneficiaries/BeneficiaryListContainer';

import {FormattedMessage} from 'react-intl';

const beneficiariesListStyle = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientsList
 * ----------------------------------------------------------------------------
 */
class BeneficiariesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // const { fetchBeneficiaryList } = this.props
    // console.log(this.props);
    // fetchBeneficiaryList();
  }

  render() {
    // console.log(this.props);
    const {classes} = this.props;
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage
                  id='beneficiaryList.cardItem1.beneficiariesListing'
                  defaultMessage={`Beneficiary Management`}
                />
              </h4>
              <div>
                {/* <Button
                  round
                  color='primary'
                  // onClick={() => this.props.history.push("/beneficiaries/list")}
                >
                  <FormattedMessage id='newBeneficiary.cardItem1.showAll' defaultMessage={`Show All`} />
                </Button> */}
                <Button round color='info' onClick={() => this.props.history.push('/beneficiaries/create')}>
                  <FormattedMessage id='newBeneficiary.cardItem1.createNew' defaultMessage={`Create New`} />
                </Button>
                {/* <Button round color='info' onClick={() => this.props.fetchBeneficiaryList()}>
                  <FormattedMessage id='newBeneficiary.cardItem1.refresh' defaultMessage={`Refresh`} />
                </Button> */}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <BeneficiaryListContainer />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

// BeneficiariesList.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withStyles(beneficiariesListStyle)(BeneficiariesList);
BeneficiariesList =  withStyles(beneficiariesListStyle)(BeneficiariesList);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    beneficiaries: state.beneficiaries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // fetchBeneficiaryList: () => {
    //   dispatch(fetchBeneficiaryList());
    // },
    // selectBeneficiaryUi: data => {
    //   dispatch(selectBeneficiaryUi(data));
    // }
  };
};

const BeneficiariesListContainer = connect(mapStateToProps, mapDispatchToProps)(BeneficiariesList);

export default BeneficiariesListContainer;