import React, {useEffect, useState} from 'react';
import {API} from 'aws-amplify';

import withStyles from '@material-ui/core/styles/withStyles';

import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import {Button, Card, Checkbox, Divider, Flex, Form, Input, message, Modal, Select, Table} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import ListTable from "../../../components/ListTable/ListTable";
import {useForm} from "antd/es/form/Form";
import {connect} from "react-redux";
import TypographyCurrency from "../../../components/CurrencySelect/TypographyCurrency";
import TypographyCountry from "../../../components/CurrencySelect/TypographyCountry";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    }
};

const EntityBankAccounts = (props) => {

    const [form] = useForm();

    const [componentState, setComponentState] = useState({
        dataList: [],
        bankList: [],
        entities: [],
        activeId: null,
        isModalOpen: false,
        isLoading: false,
        isListLoading: false,
        currencies: [],
        formValues: null
    });

    const buildColumns = () => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: (a, b) => a.id - b.id,
                defaultSortOrder: 'descend',
            },
            {
                title: 'Account Name',
                dataIndex: 'eba_accout_name',
                sorter: (a, b) => a.eba_accout_name - b.eba_accout_name,
                defaultSortOrder: 'ascend',
                ...props.getColumnSearchProps({
                    dataIndex: 'eba_accout_name'
                })
            },
            {
                title: 'Swift',
                dataIndex: 'eba_swift',
                sorter: (a, b) => a.eba_swift - b.eba_swift,
                defaultSortOrder: 'ascend',
                ...props.getColumnSearchProps({
                    dataIndex: 'eba_swift'
                })
            },
            {
                title: 'BSB',
                dataIndex: 'eba_bsb',
                sorter: (a, b) => a.eba_bsb - b.eba_bsb,
                defaultSortOrder: 'ascend',
                ...props.getColumnSearchProps({
                    dataIndex: 'eba_bsb',
                })
            },
            {
                title: 'Account Number',
                dataIndex: 'eba_accout_number',
                sorter: (a, b) => a.eba_accout_number - b.eba_accout_number,
                defaultSortOrder: 'ascend',
                ...props.getColumnSearchProps({
                    dataIndex: 'eba_accout_number',
                })
            },
            {
                title: 'Reference',
                dataIndex: 'reference_mandatory',
                sorter: (a, b) => a.eba_accout_number - b.eba_accout_number,
                defaultSortOrder: 'ascend',
                ...props.getColumnSearchProps({
                    dataIndex: 'reference_mandatory',
                })
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
            }
        ]
    }


    const fetchCurrencies = () => {
        API.post("commons", "/fetch", {
            body: {
                context: 'currencies',
                fields: ['*'],
                condition: {deleted: false},
            }
        }).then(res => {
            setComponentState(prev => ({...prev, currencies: res}));
        }).catch(err => {
            console.log(err);
        });
    }

    const fetchEntityBanks = () => {
        const loading = message.info("Fetching entity banks. Please wait")
        API.post("commons", "/fetch", {
            body: {
                context: 'entity_banks',
                fields: ['*']
            }
        }).then(res => {
            setComponentState(prev => ({...prev, bankList: res}));
        }).catch(err => {
            console.log(err)
        }).finally(() => loading())
    }

    const fetchEntities = () => {
        const loading = message.info("Fetching entities. Please wait..", 0);
        API.post("commons", "/fetch", {
            body: {
                context: 'entities',
                fields: ['*']
            }
        }).then(response => {
            setComponentState(prev => ({...prev, entities: response}));
        }).catch(err => {
            console.log(err);
        }).finally(() => loading());
    }


    const fetchEntityBankAccounts = async () => {
        setComponentState(prev => ({...prev, isListLoading: true}));
        try {
            let response = await API.post("commons", "/fetch", {
                body: {
                    context: 'entity_bank_accounts',
                    fields: ['*']
                }
            });
            response = mapTableData(response);
            setComponentState(prev => ({...prev, dataList: response, isListLoading: false}));
        } catch (e) {
            console.log(e);
            setComponentState(prev => ({...prev, isListLoading: false}));
        }
    }

    const onEditBtnClick = async (id) => {
        const loading = message.loading("Please wait.. fetching data", 0);
        try {
            const [data] = await API.post("commons", "/fetch", {
                body: {
                    context: 'entity_bank_accounts',
                    fields: ['*'],
                    condition: {id: id}
                }
            });

            let mid = null;

            const backendResponse = await API.post("commons", "/backend-fetch", {
                body: {
                    context: 'remitting_accounts',
                    condition: {crm_remitting_account_id: id}
                }
            });
            const [backendData] = backendResponse.data;
            setComponentState(prev => ({
                ...prev,
                activeId: id,
                isModalOpen: true
            }));

            form.setFieldsValue({...data, backend_mid: backendData.mid });
            setComponentState(prev => ({...prev, formValues: {...data, backend_mid: backendData.mid}}));

        } catch (e) {
            console.log(e);
            message.loading(`Unable to fetch data with an id  #${id}`);
        } finally {
            loading();
        }

    }

    const mapTableData = (response) => {
        return response.map(prop => {
            return {
                id: prop.id,
                eba_swift: prop.eba_swift,
                eba_bsb: prop.eba_bsb,
                eba_accout_name: prop.eba_accout_name,
                eba_accout_number: prop.eba_accout_number,
                reference_mandatory: prop.reference_mandatory,
                actions: (
                    <Flex justify={'end'} gap={5}>
                        <Button
                            type={'primary'}
                            icon={<EditOutlined/>}
                            onClick={() => onEditBtnClick(prop.id)}
                            color='warning'
                            className='edit'>
                        </Button>
                        <Button
                            type={'primary'}
                            icon={<DeleteOutlined/>}
                            danger={true}
                            onClick={() => {
                                Modal.confirm({
                                    title: 'Are you sure?',
                                    content: `Are you sure you want to delete #${prop.id} (${prop.eba_accout_name})`,
                                    onOk: () => {
                                        API.put('system', `/entitybanksaccount/delete/id/${prop.id}`, {})
                                            .then(response => {
                                                fetchEntityBankAccounts();
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                    }
                                })
                            }}>
                        </Button>
                    </Flex>
                )
            }
        })
    }

    const handleSubmit = (values) => {
        const loading = message.loading("Saving entity bank accounts. Please wait.", 0);
        values.nickname = values.eba_accout_name;
        values.nicknameInternal = !values.monoova ? values.eba_accout_name : 'Mon ' + values.eba_accout_name;
        const {backend_mid, ...requestBody} = values;
        setComponentState(prev => ({...prev, isLoading: true}));
        let payload = {
            context: 'entity_bank_accounts',
            data: requestBody
        };
        const req = componentState.activeId !== null
            ? API.post("commons", `/update/${componentState.activeId}`, {body: payload})
            : API.post("commons", "/insert", {body: payload});
        req.then(async (response) => {
            message.success("Entity bank account added");
            form.resetFields();
            setComponentState(prev => ({...prev, isLoading: false, activeId: null, isModalOpen: false }));
            if (componentState.activeId != null) {
                if (!requestBody.monoova) {
                    await API.post("commons", "/backend-delete", {
                        body: {
                            context: 'remitting_accounts',
                            condition: {crm_remitting_account_id: componentState.activeId}
                        }
                    });
                    console.log("Removed from backend");
                } else {
                    await API.post("commons", "/backend-update", {
                        body: {
                            context: 'remitting_accounts',
                            data: {
                                currency: 'AUD',
                                bank_is_active: 1,
                                bank_priority: 1,
                                indiv_acc: 1,
                                mid: backend_mid
                            },
                            condition: {crm_remitting_account_id: response.id, currency: 'AUD'}
                        }
                    });
                    console.log("Updating into the backend");
                }

            } else if (requestBody.monoova) {
                await API.post("commons", "/backend-insert", {
                    body: {
                        context: 'remitting_accounts',
                        data: {
                            currency: 'AUD',
                            crm_remitting_account_id: response.id,
                            bank_is_active: 1,
                            bank_priority: 1,
                            indiv_acc: 1,
                            nickname: values.nicknameInternal,
                            mid: backend_mid
                        }
                    }
                });
            }
            await fetchEntityBankAccounts();
        })
            .catch(err => {
                console.log(err);
                setComponentState(prev => ({...prev, isLoading: false}));
            })
            .finally(() => loading());
    }

    const onMonoovaChecked = (isChecked) => {
        if (isChecked) {
            form.setFieldsValue({
                eba_swift: 'CUSCAU2S',
                eba_bsb: '802-985',
                address_line_1: 'L6 100 Collins Street',
                address_suburb: 'Melbourne',
                address_state: 'VIC',
                address_postcode: '3000',
                address_country: 9,
                entity_bank_id: 24,
                entity_id: 2
            });
        }
    }

    useEffect(() => {
        fetchCurrencies();
        fetchEntities()
        fetchEntityBanks();
        fetchEntityBankAccounts();
    }, [props.app_state.current_staff]);

    return <>
        <Card title={
            <Flex justify={'space-between'} align={'center'}>
                <span><h4>Entity Bank Accounts List</h4></span>
                <Flex justify={'end'} gap={5}>
                    <Button type={'primary'} icon={<PlusOutlined/>}
                            onClick={() => setComponentState(prev => ({...prev, isModalOpen: true}))}>Add New</Button>
                    <Button type={'primary'} icon={<ReloadOutlined/>} onClick={() => fetchEntityBankAccounts()}
                            danger={true}>Refresh Page</Button>
                </Flex>
            </Flex>
        }>
            <Table size={'small'} dataSource={componentState.dataList} columns={buildColumns()}></Table>
            <Modal
                title={componentState.activeId ? `Update EBA #${componentState.activeId}` : 'Add Entity Bank Accounts'}
                width={'600px'}
                okButtonProps={{
                    loading: componentState.isLoading,
                    disabled: componentState.isLoading
                }}
                cancelButtonProps={{
                    loading: componentState.isLoading,
                    disabled: componentState.isLoading
                }}
                open={componentState.isModalOpen}
                onOk={() => form.submit()}
                style={{top: 10}}
                styles={{body: {maxHeight: '80vh', overflowY: 'scroll', padding: '10px'}}}
                onCancel={() => {
                    form.resetFields();
                    setComponentState(prev => ({...prev, activeId: null, isModalOpen: false}))
                }}>
                <Divider/>
                <Form labelAlign={'left'}
                      labelCol={{span: 6}}
                      name={'eba-form'}
                      form={form}
                      onValuesChange={(current, all) => setComponentState(prev => ({...prev, formValues: all}))}
                      onFinish={handleSubmit}>
                    <Form.Item name={'eba_swift'} label={'EBA Swift'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'entity_bank_id'} label={'Entity Banks'} rules={[{required: true, message: 'Field is required'}]}>
                        <Select placeholder={'-- SELECT --'} options={componentState.bankList.map(i => ({
                            ...i,
                            label: i.nickname,
                            value: i.id
                        }))}></Select>
                    </Form.Item>
                    <Form.Item name={'entity_id'} label={'Entity'} rules={[{required: true, message: 'Field is required'}]}>
                        <Select placeholder={'-- SELECT CURRENCY --'}
                                options={componentState.entities.map(i => ({
                            ...i,
                            label: i.nickname,
                            value: i.id
                        }))}></Select>
                    </Form.Item>
                    <Form.Item name={'currency_id'} label={'Currency'} rules={[{required: true, message: 'Field is required'}]}>
                        <Select optionFilterProp={'alt'} placeholder={'-- SELECT --'}
                                options={(componentState.currencies || []).map(i => ({
                                    ...i,
                                    alt: i.iso_alpha_3 + i.full_name,
                                    label: <TypographyCurrency
                                        iso_alpha_3={i.iso_alpha_3}>{i.full_name}</TypographyCurrency>,
                                    value: i.id
                                }))}></Select>
                    </Form.Item>
                    {
                        componentState.formValues != null && componentState.formValues.currency_id === 1 &&
                       <>
                           <Form.Item name={'monoova'} label={'Create Monoova ?'} valuePropName={'checked'}>
                               <Checkbox onClick={(e) => onMonoovaChecked(e.target.checked)}></Checkbox>
                           </Form.Item>
                           { componentState.formValues.monoova ?
                               <Form.Item name={'backend_mid'} label={'Backend MID'}>
                                   <Input />
                               </Form.Item> : <></>
                           }
                       </>
                    }
                    <Form.Item name={'eba_accout_name'} label={'Account Name'} rules={[{required: true, message: 'Field is required'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'eba_accout_number'} label={'Account Number'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'reference_mandatory'} label={'Reference'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'eba_bsb'} label={'BSB Code'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'eba_sortcode'} label={'Sort Code'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'eba_routing'} label={'EBA Routing'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'eba_iban'} label={'iBAN'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'address_country'} label={'Country'} rules={[{required: true, message: 'Field is required'}]}>
                        <Select optionFilterProp={'alt'}
                                placeholder={'-- SELECT COUNTRY --'}
                                options={props.app_state.country_list.map(item => ({
                            ...item,
                            value: item.id,
                            label: <TypographyCountry iso_alpha_2={item.iso_alpha_2}>{item.full_name}</TypographyCountry>,
                            alt: item.iso_alpha_3 + item.iso_alpha_2 + item.full_name
                        }))}></Select>
                    </Form.Item>
                    <Form.Item name={'address_line_1'} label={'Address Line 1'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'address_line_2'} label={'Address Line 2'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'address_suburb'} label={'Suburb'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'address_state'} label={'State'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'address_postcode'} label={'Post Code'}>
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
    </>
}

const mapStateToProps = state => ({
    app_state: state.app_state,
});

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListTable(EntityBankAccounts)));
