import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import checkboxStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';

import {cardTitle} from '../../assets/jss/material-dashboard-pro-react.jsx';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
const entityNewStyles = {
  ...checkboxStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  }
};

class CurrenciesNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // register form
      fullname: '',
      fullname_state: '',
      short_name: '',
      short_name_state: '',
      iso_alpha_3: '',
      iso_alpha_3_state: '',
      iso_numeric: '',
      iso_numeric_state: '',
      list_priority: '',

      iban: '',
      bsb: '',
      sort_code: '',
      swift_code: '',
      aba_routing: ''
    };
  }

  handleToggle(stateName) {
    let current_stateName = this.state[stateName];
    this.setState({
      [stateName]: current_stateName === 1 ? 0 : 1
    });
  }

  validateForm() {
    var valid = true;
    if (this.state.fullname_state === '') {
      this.setState({fullname_state: 'error'});
      valid = false;
    }
    if (this.state.short_name_state === '') {
      this.setState({short_name_state: 'error'});
      valid = false;
    }
    if (this.state.iso_alpha_3_state === '') {
      this.setState({iso_alpha_3_state: 'error'});
      valid = false;
    }
    if (this.state.iso_numeric_state === '') {
      this.setState({iso_numeric_state: 'error'});
      valid = false;
    }
    return valid;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'checkbox':
        if (event.target.checked) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-length':
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'url':
        if (this.verifyUrl(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'min-value':
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-value':
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'range':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      default:
        break;
    }
    switch (type) {
      case 'checkbox':
        this.setState({[stateName]: event.target.checked});
        break;
      default:
        this.setState({[stateName]: event.target.value});
        break;
    }
  }

  async handleSubmitForm() {
    if (this.validateForm()) {
      await this.props.createNewCurrency({
        full_name: this.state.fullname,
        short_name: this.state.short_name,
        iso_alpha_3: this.state.iso_alpha_3,
        iso_numeric: this.state.iso_numeric,
        list_priority: this.state.list_priority,
        iban: this.state.iban,
        bsb: this.state.bsb,
        sort_code: this.state.sort_code,
        swift_code: this.state.swift_code,
        aba_routing: this.state.aba_routing
      });

      this.props.selectCurrenciesUi('list');
    }
  }

  handleCancel() {
    this.props.cancelCreateNewCurrency();
  }

  renderForm() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <h3>Enter details for new Country</h3>
        <GridContainer>
          <form>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      success={this.state.fullname_state === 'success'}
                      error={this.state.fullname_state === 'error'}
                      labelText='full Name *'
                      id='fullname'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.fullname,
                        onChange: event => this.change(event, 'fullname', 'length', 0),
                        type: 'text'
                      }}
                    />

                    <CustomInput
                      success={this.state.short_name_state === 'success'}
                      error={this.state.short_name_state === 'error'}
                      labelText='Short Name*'
                      id='short_name'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.short_name,
                        onChange: event => this.change(event, 'short_name', 'length', 0),
                        type: 'text'
                      }}
                    />

                    <CustomInput
                      success={this.state.iso_alpha_3_state === 'success'}
                      error={this.state.iso_alpha_3_state === 'error'}
                      labelText='iso Alpha 3*'
                      id='iso_alpha_3'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.iso_alpha_3,
                        onChange: event => this.change(event, 'iso_alpha_3', 'length', 0),
                        type: 'text'
                      }}
                    />

                    <CustomInput
                      success={this.state.iso_numeric_state === 'success'}
                      error={this.state.iso_numeric_state === 'error'}
                      labelText='ISO numeric*'
                      id='iso_numeric'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.iso_numeric,
                        onChange: event => this.change(event, 'iso_numeric', 'length', 0),
                        type: 'text'
                      }}
                    />

                    <div className={classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => this.handleToggle('list_priority')}
                            checked={this.state.list_priority == 1 ? true : false}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{checked: classes.checked}}
                          />
                        }
                        classes={{label: classes.label}}
                        label='Priority Country (place at top of lists)'
                      />
                    </div>

                    <div className={classes.formCategory}>
                      <small>*</small> Required fields
                    </div>
                    <Button color='rose' onClick={() => this.handleSubmitForm()} className={classes.registerButton}>
                      Register
                    </Button>
                    <Button
                      // color=""
                      onClick={() => this.handleCancel()}
                      className={classes.registerButton}
                    >
                      Cancel
                    </Button>
                  </GridItem>

                  <GridItem xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle('iban')}
                          checked={this.state.iban == 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{checked: classes.checked}}
                        />
                      }
                      classes={{label: classes.label}}
                      label='Is IBAN'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle('bsb')}
                          checked={this.state.bsb == 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{checked: classes.checked}}
                        />
                      }
                      classes={{label: classes.label}}
                      label='Is BSB'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle('sort_code')}
                          checked={this.state.sort_code == 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{checked: classes.checked}}
                        />
                      }
                      classes={{label: classes.label}}
                      label='Is Sort Code'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle('swift_code')}
                          checked={this.state.swift_code == 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{checked: classes.checked}}
                        />
                      }
                      classes={{label: classes.label}}
                      label='Is Swift Code'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle('aba_routing')}
                          checked={this.state.aba_routing == 1 ? true : false}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{checked: classes.checked}}
                        />
                      }
                      classes={{label: classes.label}}
                      label='Is ABA Routing'
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </form>
        </GridContainer>
      </React.Fragment>
    );
  }

  render() {
    console.log(this);
    return (
      <div>
        <GridContainer>{this.renderForm()}</GridContainer>
      </div>
    );
  }
}

CurrenciesNew.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(entityNewStyles)(CurrenciesNew);
