import {API, Auth} from 'aws-amplify';

export const STAFF_SELECT_STAFF_MEMBER = 'STAFF_SELECT_STAFF_MEMBER';
export const STAFF_FETCH_STAFF_LIST_REQUEST = 'STAFF_FETCH_STAFF_LIST_REQUEST';
export const STAFF_FETCH_STAFF_LIST_SUCCESS = 'STAFF_FETCH_STAFF_LIST_SUCCESS';
// export const STAFF_FETCH_STAFF_LIST_FAILURE = "STAFF_FETCH_STAFF_LIST_FAILURE";
// export const STAFF_CREATE_NEW_SHOW_UI = "STAFF_CREATE_NEW_SHOW_UI";
export const STAFF_CREATE_NEW_START = 'STAFF_CREATE_NEW_START';
export const STAFF_CREATE_NEW_CANCEL = 'STAFF_CREATE_NEW_CANCEL';
export const STAFF_CREATE_NEW_COMPLETE = 'STAFF_CREATE_NEW_COMPLETE';
export const STAFF_CREATE_PORTAL_START = 'STAFF_CREATE_PORTAL_START';
export const STAFF_CREATE_PORTAL_SUCCESS = 'STAFF_CREATE_PORTAL_SUCCESS';

// export const STAFF_EDIT_SHOW_UI = "STAFF_EDIT_SHOW_UI";
export const STAFF_SELECT_UI = 'STAFF_SELECT_UI';
export const STAFF_SELECT_SET_ID = 'STAFF_SELECT_SET_ID';
export const STAFF_SELECT_STAFF_MEMBER_REQUEST = 'STAFF_SELECT_STAFF_MEMBER_REQUEST';
export const STAFF_SELECT_STAFF_MEMBER_SUCCESS = 'STAFF_SELECT_STAFF_MEMBER_SUCCESS';

export function requestStaffListRequest() {
  return {
    type: STAFF_FETCH_STAFF_LIST_REQUEST
  };
}

export function receiveStaffList(json) {
  return {
    type: STAFF_FETCH_STAFF_LIST_SUCCESS,
    staff_list: json,
    receivedAt: Date.now()
  };
}

// export function fetchStaffList() {
//   return function(dispatch) {
//     dispatch(requestStaffListRequest);
//
//     API.get("crm", `/staff/get-list`)
//       .then(response => {
//         console.log(response);
//         dispatch(receiveStaffList(response));
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// }

export function fetchStaffListNotDeleted() {
  return function (dispatch) {
    dispatch(requestStaffListRequest);
    API.get('staff', `/get-list-not-deleted`)
      .then(response => {
        // console.log(response);
        dispatch(receiveStaffList(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function selectStaffUi(value) {
  return {
    type: STAFF_SELECT_UI,
    value: value
  };
}

// async function createNewUserWithAws(username, password) {
//   const newUser = await Auth.signUp({
//     username: username,
//     password: password
//   });
//   return newUser;
// }

export async function create_new_user_in_database(
  // aws_cognito_id,
  email,
  first_name,
  middle_name,
  last_name
) {
  API.post('staff', `/create/in-database`, {
    body: {
      email: email,
      // aws_cognito_id: aws_cognito_id,
      first_name: first_name,
      middle_name: middle_name,
      last_name: last_name,
      nickname: `${last_name}, ${first_name}`
    }
  })
    .then(database_result => {
      console.log(database_result);
      API.post('email', `/interaction/main`, {
        body: {
          interaction: 'Create New User In Database',
          data: {
            email: email,
            first_name: first_name,
            middle_name: middle_name,
            last_name: last_name
          },
          result: database_result.insertId
        }
      });
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
}

export function createNewStaffMember(data) {
  // console.log(data);
  return async function (dispatch) {
    dispatch(createNewStaffMemberStart());

    try {
      API.post('staff', `/create/in-database`, {
        body: {
          email: data.email,
          title: data.title,
          nickname: `${data.last_name}, ${data.first_name}`,
          first_name: data.first_name,
          middle_name: data.middle_name,
          last_name: data.last_name,
          team_id: data.team_id,
          // team_security: data.team_security,
          division_id: data.division_id,
          entity_id: data.entity_id,
          // password: data.password
        }
      })
        .then(database_result => {
          data.id = database_result.staff_members.insertId;
          // dispatch(createCognitoAccountForStaff(data));

          API.post('email', `/interaction/main`, {
            body: {
              interaction: 'Create New Staff Member',
              data,
              result: database_result.insertId
            }
          });
          // API.post("email", `/staff/new-staff-member-created`, {
          //   body: {
          //     data: data,
          //   }})
          // .then(response => {
          //   console.log(response);
          // })
          // .catch(error => {
          //   console.log(error);
          // });

          dispatch(createNewStaffMemberComplete(database_result.staff_members));
          // return database_result;
          // window.location.href = '/settings/stafflist';
        })
        .catch(error => {
          console.log(error);
        });

      // const database_result = await create_new_user_in_database(
      //   // newUser.userSub,
      //   data.email,
      //   data.first_name,
      //   data.middle_name,
      //   data.last_name
      // );
      // console.log(database_result)
      // dispatch(createNewStaffMemberComplete());
    } catch (e) {
      console.log(e);
      // if (e.code === "UsernameExistsException") {
      //   await Auth.resendSignUp(data.email);
      // }
    }
  };
}

export function createCognitoAccountForStaff(data) {
  // console.log(data);
  return async function (dispatch) {
    dispatch(createStaffPortalAccountStart());
    try {
      const newUser = await Auth.signUp({
        username: data.email,
        password: data.password
      });

      // console.log(newUser);
      API.post('email', `/interaction/main`, {
        body: {
          interaction: 'Create Staff AWS Cognito Account',
          data: newUser,
          result: 'Cognito account created'
        }
      });

      await update_staff_in_database(data.id, {
        aws_cognito_id: newUser.userSub,
        portal_account_created: '1'
      });

      API.post('email', `/staff/new-staff-member-portal-created`, {
        body: {
          id: data.id,
          username: data.email,
          password: data.password
        }
      })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });

    } catch (e) {
      console.log(e);
      if (e.code === 'UsernameExistsException') {
        try {
          await Auth.resendSignUp(data.email);
        } catch (e2) {
          console.log(e2);
        }
      }
    }
    dispatch(createStaffPortalAccountSuccess());
  };
}

async function update_staff_in_database(staff_id, staff_detail) {
  // console.log(staff_detail);
  API.put('staff', `/update/id/${staff_id}`, {
    body: staff_detail
  })
    .then(database_result => {
      // console.log(database_result);
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
}

export function confirmNewStaffMember(data) {
  return async function (dispatch) {
    try {
      await Auth.confirmSignUp(data.email, data.confirmation_code);
      dispatch(createNewStaffMemberComplete());
      // dispatch(createNewStaffMemberComplete());
    } catch (e) {
      alert(e.message);
    }
  };
}

export function cancelCreateNewStaffMember() {
  return {
    type: STAFF_CREATE_NEW_CANCEL
  };
}

export function setStaffDetailId(aws_cognito_id) {
  return {
    type: STAFF_SELECT_SET_ID,
    value: aws_cognito_id
  };
}

export function loadStaffMemberDetails(aws_cognito_id) {
  return async function (dispatch) {
    dispatch(loadStaffMemberDetailsRequest(aws_cognito_id));
  };
}

export function loadStaffMemberDetailsRequest() {
  return {
    type: STAFF_SELECT_STAFF_MEMBER_REQUEST
  };
}
export function loadStaffMemberDetailsSuccess() {
  return {
    type: STAFF_SELECT_STAFF_MEMBER_SUCCESS
  };
}

export function createNewStaffMemberStart() {
  return {
    type: STAFF_CREATE_NEW_START
  };
}

export function createNewStaffMemberComplete(database_result) {
  return {
    type: STAFF_CREATE_NEW_COMPLETE,
    value: database_result
  };
}
export function createStaffPortalAccountStart() {
  return {
    type: STAFF_CREATE_PORTAL_START
  };
}

export function createStaffPortalAccountSuccess() {
  return {
    type: STAFF_CREATE_PORTAL_SUCCESS
  };
}
