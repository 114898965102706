import {API} from 'aws-amplify';
import axios from 'axios';

export const BENEFICIARY_CREATE_NEW_START = 'BENEFICIARY_CREATE_NEW_START';
export const BENEFICIARY_CREATE_NEW_CANCEL = 'BENEFICIARY_CREATE_NEW_CANCEL';
export const BENEFICIARY_CREATE_NEW_COMPLETE = 'BENEFICIARY_CREATE_NEW_COMPLETE';

export const BENEFICIARIES_SELECT_SET_ID = 'BENEFICIARIES_SELECT_SET_ID';
export const BENEFICIARIES_SELECT_UI = 'BENEFICIARIES_SELECT_UI';

export const BENEFICIARIES_FETCH_BENEFICIARY_REQUEST = 'BENEFICIARIES_FETCH_BENEFICIARY_REQUEST';
export const BENEFICIARIES_FETCH_BENEFICIARY_SUCCESS = 'BENEFICIARIES_FETCH_BENEFICIARY_SUCCESS';
export const BENEFICIARIES_FETCH_BENEFICIARY_LIST_REQUEST = 'BENEFICIARIES_FETCH_BENEFICIARY_LIST_REQUEST';
export const BENEFICIARIES_FETCH_BENEFICIARY_LIST_SUCCESS = 'BENEFICIARIES_FETCH_BENEFICIARY_LIST_SUCCESS';

export const BENEFICIARIES_START_NEW_BENEFICIARY_CREATION = 'BENEFICIARIES_START_NEW_BENEFICIARY_CREATION';
export const BENEFICIARIES_UPDATE_NEW_BENEFICIARY_CREATION = 'BENEFICIARIES_UPDATE_NEW_BENEFICIARY_CREATION';
export const BENEFICIARIES_NEW_BENEFICIARY_DATABASE_CREATE_REQUEST =
  'BENEFICIARIES_NEW_BENEFICIARY_DATABASE_CREATE_REQUEST';
export const BENEFICIARIES_NEW_BENEFICIARY_DATABASE_CREATE_SUCCESS =
  'BENEFICIARIES_NEW_BENEFICIARY_DATABASE_CREATE_SUCCESS';

export function fetchBeneficiaryListRequest() {
  return {
    type: BENEFICIARIES_FETCH_BENEFICIARY_LIST_REQUEST
  };
}

export function fetchBeneficiaryListSuccess(data) {
  return {
    type: BENEFICIARIES_FETCH_BENEFICIARY_LIST_SUCCESS,
    beneficiary_list: data,
    receivedAt: Date.now()
  };
}

export function fetchBeneficiaryList() {
  return function (dispatch) {
    dispatch(fetchBeneficiaryListRequest());
    API.get('beneficiaries', `/get-list-not-deleted`)
      .then(response => {
        // console.log(response);
        dispatch(fetchBeneficiaryListSuccess(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export async function createNewBeneficiaryInDatabase(data) {
  // console.log(data)
  let database_result = API.post('beneficiaries', `/create/in-database`, {
    body: {
      ...data
    }
  })
    .then(database_result => {
      // console.log(database_result)
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
  return database_result;
}

export async function insertIntoLagacyCRM(id, data) {
  var dte = new Date();
  var sec_keys =
    dte.getUTCFullYear() +
    '-' +
    dte.getDate().toString().padStart(4, '0') +
    '-' +
    dte.getDay().toString().padStart(4, '0') +
    '-' +
    dte.getDate().toString().padStart(4, '0');

  data.secret_keys = sec_keys;

  let axiosConfig = {
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  };

  axios
    .post('https://www.forexsport.com/API/add_beneficiary.php', {data: data}, axiosConfig)
    .then(res => {
      console.log('RESPONSE RECEIVED: ', res);
    })
    .catch(err => {
      console.log('AXIOS ERROR: ', err);
    });
}

export function createNewBeneficiary(data) {
  // console.log(data);
  return async function (dispatch) {
    dispatch(newBeneficiaryCreationDatabaseStart());

    try {
      let database_result = await createNewBeneficiaryInDatabase(data);
      // console.log(database_result)
      dispatch(newBeneficiaryCreationDatabaseSuccess(database_result.insertId));
      // dispatch(insertIntoLagacyCRM(database_result, data));
      return database_result;
    } catch (e) {
      console.log(e);
    }
  };
}

export function selectBeneficiaryUi(value) {
  return {
    type: BENEFICIARIES_SELECT_UI,
    value: value
  };
}

export function setBeneficiarySelectId(id) {
  return {
    type: BENEFICIARIES_SELECT_SET_ID,
    value: id
  };
}

export function loadBeneficiaryDetailsRequest() {
  return {
    type: BENEFICIARIES_FETCH_BENEFICIARY_REQUEST
  };
}
export function loadBeneficiaryDetailsSuccess() {
  return {
    type: BENEFICIARIES_FETCH_BENEFICIARY_SUCCESS
  };
}
export function startNewBeneficiaryCreation() {
  return {
    type: BENEFICIARIES_START_NEW_BENEFICIARY_CREATION
  };
}

export function updateNewBeneficiaryCreation(key, value) {
  // console.log(key, value);
  return {
    type: BENEFICIARIES_UPDATE_NEW_BENEFICIARY_CREATION,
    value: {key, value}
  };
}
export function newBeneficiaryCreationDatabaseStart() {
  return {
    type: BENEFICIARIES_NEW_BENEFICIARY_DATABASE_CREATE_REQUEST
  };
}
export function newBeneficiaryCreationDatabaseSuccess(id) {
  return {
    type: BENEFICIARIES_NEW_BENEFICIARY_DATABASE_CREATE_SUCCESS,
    value: id
  };
}

export function cancelCreateNewBeneficiary() {
  return {
    type: BENEFICIARY_CREATE_NEW_CANCEL
  };
}

export function createNewBeneficiaryStart() {
  return {
    type: BENEFICIARY_CREATE_NEW_START
  };
}

export function createNewBeneficiaryComplete() {
  return {
    type: BENEFICIARY_CREATE_NEW_COMPLETE
  };
}
