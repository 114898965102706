import React, {useEffect} from 'react';
import ReactTable from 'react-table';
import {API} from 'aws-amplify';
import withStyles from '@material-ui/core/styles/withStyles';

import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';

import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import ListTable from "../ListTable/ListTable";
import {Flex, Table, Button, Modal} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

const EntitybanksTable = (props) => {

    const buildColumns = () => {
        return [
            {
                title: 'Nickname',
                dataIndex: 'eb_short_name',
                sorter: (a,b) => a.eb_short_name - b.eb_short_name,
                defaultSortOrder: 'ascend'
            },
            {
                title: 'Legal Name',
                dataIndex: 'eb_long_name',
                sorter: (a,b) => a.eb_long_name - b.eb_long_name,
                defaultSortOrder: 'descend'
            },
            {
                title: 'Address',
                dataIndex: 'address',
                sorter: (a,b) => a.address - b.address,
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                render: (text, record) => text
            }
        ]
    }

    const buildTableData = () => {
        var country_list_length = props.entity_banks.entitybanks.length;
        if (country_list_length > 0) {
            return props.entity_banks.entitybanks.map((prop, key) => {
                return {
                    id: prop.id,
                    eb_long_name: prop.eb_long_name,
                    eb_short_name: prop.eb_short_name,
                    address: `${prop.address_suburb} (${prop.full_name})`,
                    actions: (
                        <Flex justify={'end'} gap={5}>
                            <Button type={'primary'} onClick={() => {
                                    const data = props.entity_banks.entitybanks;
                                    data.find(o => {
                                        if (o.id === prop.id) {
                                            props.editEntitybanks(o.id);
                                        }
                                    });
                                }}
                                icon={<EditOutlined />}
                                className='edit'>
                            </Button>
                            <Button type={'primary'}
                                    danger={true}
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Are you sure ?',
                                        content: 'Are you sure you want to delete this bank account?',
                                        onOk: () => {
                                            const data = props.entity_banks.entitybanks;
                                            data.find((o, i) => {
                                                if (o.id === prop.id) {
                                                    API.put('system', `/entitybanks/delete/id/${prop.id}`)
                                                        .then(response => {
                                                            props.fetchEntitybanksListNotDeleted();
                                                        })
                                                        .catch(error => {
                                                            console.log(error);
                                                        });

                                                    return true;
                                                }
                                                return false;
                                            });
                                        }
                                    });
                                }}>
                            </Button>
                        </Flex>
                    )
                };
            });
        }
    }

    useEffect(() => {
        props.fetchEntitybanksListNotDeleted();
    }, []);

    return <>
        <Table dataSource={buildTableData()} columns={buildColumns()}></Table>
    </>
}

export default withStyles(styles)(ListTable(EntitybanksTable));
