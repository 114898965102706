import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles.js';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// @material-ui/icons
import Check from '@material-ui/icons/Check';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import checkboxStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';

import CardHeader from '../../components/Card/CardHeader';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import NavPills from 'components/NavPills/NavPills.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import {API} from 'aws-amplify';
// import { loadStaffMemberDetailsSuccess } from "../../redux/actions/staff";

const staffEditStyles = {
  ...checkboxStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  }
};

class StaffEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      is_loading: true,
      id: '',
      full_name: '',
      iso_alpha_2: '',
      iso_alpha_3: '',
      un_code: '',
      list_priority: ''
      // checked: 0,
    };
  }

  componentDidMount() {
    // console.log(this.props);
    if (this.props.country_id) {
      //this.props.loadStaffMemberDetailsRequest();
      API.get('system', `/countries/get/${this.props.country_id}`)
        .then(country_detail => {
          console.log(country_detail);
          this.setState({
            loaded: true,
            is_loading: false,
            id: country_detail[0].country_id,
            full_name: country_detail[0].full_name,
            iso_alpha_2: country_detail[0].iso_alpha_2,
            iso_alpha_3: country_detail[0].iso_alpha_3,
            un_code: country_detail[0].un_code,
            list_priority: country_detail[0].list_priority
          });
          //this.props.loadStaffMemberDetailsSuccess();
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  async saveStaffMember(country_detail) {
    console.log(country_detail);
    return API.put('system', `/countries/update/id/${this.props.country_id}`, {
      body: country_detail
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleToggle() {
    // const { checked } = this.state;
    // const currentIndex = checked.indexOf(value);
    // const newChecked = [...checked];

    // if (currentIndex === -1) {
    //   newChecked.push(value);
    // } else {
    //   newChecked.splice(currentIndex, 1);
    // }

    // this.setState({
    //   checked: newChecked
    // });

    // //this.setState({ list_priority: this.state.checked.indexOf(0) });
    // console.log(this.state.checked.indexOf(1));
    // if(this.state.checked.indexOf(1) === -1)
    // {
    let current_list_priority = this.state.list_priority;
    this.setState({
      list_priority: current_list_priority === 1 ? 0 : 1
    });
    // } else
    // {
    //   this.setState({ list_priority: 0});
    // }
    // console.log("list_priority: "+this.state.list_priority);
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading: true});
    // console.log(this.state);
    const {full_name, iso_alpha_2, iso_alpha_3, un_code, list_priority} = this.state;
    try {
      await this.saveStaffMember({
        full_name: full_name,
        iso_alpha_2: iso_alpha_2.toUpperCase(),
        iso_alpha_3: iso_alpha_3.toUpperCase(),
        un_code: un_code,
        list_priority: list_priority
      });
    } catch (e) {
      // this.setState({ isLoading: false });
    }
  };

  render() {
    const {classes} = this.props;
    // console.log(this.state);
    // console.log(this.state);
    // if (!this.state.loaded) {
    //   return <span />;
    // }
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Editing {this.state.full_name}</h4>
              </CardHeader>
              <CardBody>
                <NavPills
                  color='info'
                  tabs={[
                    {
                      tabButton: 'Country',
                      tabContent: (
                        <div style={{padding: 20}}>
                          <form onSubmit={this.handleSubmit}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText='Full Name'
                                  id='full_name'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    disabled: true,
                                    value: this.state.full_name || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText='ISO Alpha 2'
                                  id='iso_alpha_2'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.iso_alpha_2 || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText='ISO Alpha 3'
                                  id='iso_alpha_3'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.iso_alpha_3 || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText='UN Code'
                                  id='un_code'
                                  disabled
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.un_code || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>

                            {/* <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText="Priority"
                                  id="list_priority"
                                  disabled
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.list_priority,
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer> */}

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <div className={classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={() => this.handleToggle()}
                                        // onClick={() => this.handleToggle(1)}
                                        // checked={this.state.checked.indexOf(1) !== -1 ? true:false}
                                        checked={this.state.list_priority == 1 ? true : false}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked}}
                                      />
                                    }
                                    classes={{label: classes.label}}
                                    label='Priority Country (place at top of lists)'
                                  />
                                </div>
                              </GridItem>
                            </GridContainer>

                            <Button color='primary' type='submit' className={classes.updateProfileButton}>
                              Update Country
                            </Button>
                          </form>
                        </div>
                      )
                    }
                  ]}
                />
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

StaffEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(staffEditStyles)(StaffEdit);
