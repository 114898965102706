import {connect} from 'react-redux';
import ReportsClient from './ReportsClient';
import {
  fetchClientList
  // setClientSelectId
} from '../../redux/actions/clients';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchClientList: () => {
      dispatch(fetchClientList());
    }
    // setClientSelectId: (id) => {
    // dispatch(setClientSelectId(id))
    // }
  };
};

const ReportsClientContainer = connect(mapStateToProps, mapDispatchToProps)(ReportsClient);

export default ReportsClientContainer;
