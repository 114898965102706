import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import {API} from 'aws-amplify';

import {FormattedMessage} from 'react-intl';
import {TextareaAutosize} from '@material-ui/core';
import Button from 'components/CustomButtons/Button.jsx';
import {message} from 'antd';
import { Form, Input } from 'antd';
import moment from 'moment';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';

import NavPills from 'components/NavPills/NavPills.jsx';

const styles = themes => ({
  customFormControlClasses: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    marginTop: '0px !important',
    marginBottom: '0px !important'
  },
  backgroundColor: {}
});

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};


const MBL_GetExRate = ({classes}) => {
  const [state, setState] = useState({
    AGENT_CODE: 'FOREX001',
    USER_ID: 'FOREX_API',
    PASSWORD: 'PASSWORD',
    AGENT_SESSION_ID: 'AGENT_SESSION_ID',
    ACCOUNT_NO: 'ACCOUNT_NO',
    TRANSFERAMOUNT: '11000.00',
    PAYMENTMODE: 'D',
    CALC_BY: 'C',
    LOCATION_ID: '96811068',
    PAYOUT_COUNTRY: 'NEPAL',
    SIGNATURE: '80a6af32a94befd3be8fc8de5b8f9f62d63f1f0d2fd6c2c729f862a928b7d227'
  });

  const [form] = Form.useForm();

  const handleChange = (stateName, value) => {
    setState({
      ...state,
      [stateName]: value
    });
  }

  const prefill = () => {
    form.setFieldsValue({ 
      TRANSFERAMOUNT: '200.00', 
      PAYMENTMODE: 'B', 
      CALC_BY: 'P', 
      LOCATION_ID: '11004036',
      PAYOUT_COUNTRY: 'NEPAL'
    });
  };

  const onFinish = (values: any) => {
    console.log(values);
    API.post('banking', '/mbl/get-ex-rate', {body: values})
    .then(response => {
      console.log(response);
      console.log(response.axiosResponse);
      setState(state => {
        return {
          ...state,
          axiosResponse: response.axiosResponse
        };
      });

    })
    .catch(error => {
      console.log(error);
    })
    .finally(() => {
      console.log('finally')
    });
  };

  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={6}>

        <Form
      {...layout}
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      style={{ maxWidth: 600 }}
    >
      <Form.Item name="TRANSFERAMOUNT" label="TRANSFERAMOUNT" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="PAYMENTMODE" label="PAYMENTMODE" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="CALC_BY" label="CALC_BY" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="LOCATION_ID" label="LOCATION_ID" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="PAYOUT_COUNTRY" label="PAYOUT_COUNTRY" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>

        </GridItem>


        <GridItem xs={6}>
        <Button htmlType="button" onClick={prefill}>
          Prefill
        </Button>

        <TextareaAutosize
                minRows={13}
                style={{width: '100%'}}
                multiline
                aria-label='maximum height'
                disabled
                placeholder='JSON Response'
                value={state.axiosResponse}
              />


      </GridItem>


      </GridContainer>
    </Fragment>
  );
};

MBL_GetExRate.propTypes = {classes: PropTypes.object.isRequired};

export default withStyles(styles)(MBL_GetExRate);
