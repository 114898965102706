import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';

import CardHeader from '../../components/Card/CardHeader';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import NavPills from 'components/NavPills/NavPills.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import {API} from 'aws-amplify';
import {MenuItem, Select} from '@material-ui/core';

import styles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';

import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
// import SelectField from '@material-ui/core/SelectField';

const staffEditStyles = {
  ...styles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  }
};

class EntitybanksaccountEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_opts: [],
      entitybanks_opts: [],
      entitybanks_res: '',
      loaded: false,
      is_loading: true,
      id: '',
      entity_id: '',
      entity_bank_id: '',
      eba_swift: '',
      eba_bsb: '',
      eba_accout_name: '',
      eba_accout_number: '',
      reference_mandatory: '',
      country_list: [],
      country_list_prio: []
    };
  }

  componentDidMount() {
    // console.log(this.props);
    API.get('system', `/entitybanks/get-all`)
      .then(result => {
        var arrTen1 = [];
        for (var k = 0; k < result.length; k++) {
          arrTen1.push(
            <MenuItem key={result[k].id} value={result[k].id}>
              {' '}
              {result[k].eb_long_name}{' '}
            </MenuItem>
          );
        }
        this.setState({
          entitybanks_opts: result
        });
      })
      .catch(error => {
        console.log(error);
      });

    API.get('system', `/entities/get-list-not-deleted`)
      .then(result => {
        var arrTen = [];
        for (var k = 0; k < result.length; k++) {
          arrTen.push(
            <MenuItem key={result[k].id} value={result[k].id}>
              {' '}
              {result[k].nickname}{' '}
            </MenuItem>
          );
        }
        this.setState({
          entity_opts: arrTen
        });
      })
      .catch(error => {
        console.log(error);
      });

    if (this.props.id) {
      API.get('system', `/entitybanksaccount/get/id/${this.props.id}`)
        .then(entity_detail => {
          console.log('FROM ENTITY BANKS ACCOUNT');
          console.log(entity_detail);
          this.setState({
            loaded: true,
            is_loading: false,
            id: entity_detail[0].id,
            entity_id: entity_detail[0].entity_id,
            entity_bank_id: entity_detail[0].entity_bank_id,
            eba_swift: entity_detail[0].eba_swift,
            eba_bsb: entity_detail[0].eba_bsb,
            eba_accout_name: entity_detail[0].eba_accout_name,
            eba_accout_number: entity_detail[0].eba_accout_number,
            reference_mandatory: entity_detail[0].reference_mandatory
          });
          //this.props.loadStaffMemberDetailsSuccess();
        })
        .catch(error => {
          console.log(error);
        });
    }

    API.get('countries', `/list_not_deleted`)
      .then(response => {
        this.setState({
          country_list_prio: response
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  async saveStaffMember(country_detail) {
    return API.put('system', `/entitybanksaccount/update/id/${this.props.id}`, {
      body: country_detail
    });
  }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value});
  };

  handleChangeSelect = event => {
    console.log(event.target.name + '<>' + event.target.value);
    this.setState({[event.target.name]: event.target.value});
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading: true});

    const {id, entity_id, entity_bank_id, eba_swift, eba_bsb, eba_accout_name, eba_accout_number, reference_mandatory} =
      this.state;
    try {
      await this.saveStaffMember({
        id: id,
        entity_id: entity_id,
        entity_bank_id: entity_bank_id,
        eba_swift: eba_swift,
        eba_bsb: eba_bsb,
        eba_accout_name: eba_accout_name,
        eba_accout_number: eba_accout_number,
        reference_mandatory: reference_mandatory
      });
    } catch (e) {}

    this.props.selectEntitybanksUi('list');
  };

  render() {
    const {classes} = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Editing {this.state.eba_swift}</h4>
              </CardHeader>
              <CardBody>
                <NavPills
                  color='info'
                  tabs={[
                    {
                      tabContent: (
                        <div style={{padding: 20}}>
                          <form onSubmit={this.handleSubmit}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText='SWIFT'
                                  id='eba_swift'
                                  name='eba_swift'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.eba_swift || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText='BSB'
                                  id='eba_bsb'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.eba_bsb || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText='Account Name'
                                  id='eba_accout_name'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.eba_accout_name || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <FormControl fullWidth className={classes.formControl}>
                                  <Select
                                    value={this.state.entity_id}
                                    onChange={this.handleChangeSelect}
                                    input={<Input name='entity_id' id='entity_id' />}
                                  >
                                    {this.state.entity_opts}
                                  </Select>
                                  <FormHelperText>Entity</FormHelperText>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <FormControl fullWidth className={classes.formControl}>
                                  <Select
                                    value={this.state.entity_bank_id}
                                    onChange={this.handleChangeSelect}
                                    input={<Input name='entity_bank_id' id='entity_bank_id' />}
                                  >
                                    {/*{this.state.entitybanks_opts}*/}
                                    {this.state.entitybanks_opts
                                      .sort((a, b) => a.eb_long_name.localeCompare(b.eb_long_name))
                                      .map((item, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected
                                            }}
                                            value={item.id}
                                          >
                                            {item.eb_long_name}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                  <FormHelperText>Entity Banks</FormHelperText>
                                </FormControl>
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText='Account Number'
                                  id='eba_accout_number'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.eba_accout_number || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText='Reference '
                                  id='reference_mandatory'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.reference_mandatory || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>

                            <Button color='primary' type='submit' className={classes.updateProfileButton}>
                              Update Account
                            </Button>
                          </form>
                        </div>
                      )
                    }
                  ]}
                />
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

EntitybanksaccountEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(staffEditStyles)(EntitybanksaccountEdit));
