import {
  NEWS_STAFF_FETCH_NEWS_STAFF_LIST_REQUEST,
  NEWS_STAFF_FETCH_NEWS_STAFF_LIST_SUCCESS
} from '../actions/news_staff.js';

const initialState = {
  transfer_current_ui: 'list',
  is_fetching: false,
  did_invalidate: false,
  news_staff_list: [],
  last_updated: ''
};

function news_staff(state = initialState, action) {
  switch (action.type) {
    case NEWS_STAFF_FETCH_NEWS_STAFF_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
      });

    case NEWS_STAFF_FETCH_NEWS_STAFF_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        news_staff_list: action.news_staff_list,
        last_updated: action.received_at
      });

    default:
      return state;
  }
}

export default news_staff;
