/*
 *  Take out unused imports improvement by Tom #1076
 */

import React from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';

import {API} from 'aws-amplify';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

// @material-ui/icons
// import Dvr from "@material-ui/icons/Dvr";
// import Close from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from 'components/CustomButtons/Button.jsx';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import matchSorter from 'match-sorter';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import GridItem from 'components/Grid/GridItem.jsx';

import {FormattedMessage, FormattedNumber} from 'react-intl';
import {CSVLink} from 'react-csv';
// import Clearfix from "../Clearfix/Clearfix"; //remove unused imports
import GridContainer from '../Grid/GridContainer';

import {
  DateRangePicker
  //SingleDatePicker,
  //DayPickerRangeController
} from 'react-dates';

import ProtoTypes from 'prop-types';

var moment = require('moment');

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

const columns = [
  {
    Header: 'Payout Date',
    accessor: 'payout_datetime',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['payout_datetime']}),
    filterAll: true,
    maxWidth: 150,
    minWidth: 150
  },
  {
    Header: 'Payout Bank Reference',
    accessor: 'payout_bank_reference',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['payout_bank_reference']}),
    pfilterAll: true,
    maxWidth: 250,
    minWidth: 250
  },
  {
    Header: 'Id',
    accessor: 'id',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['id']}),
    filterAll: true,
    maxWidth: 50,
    minWidth: 50
  },
  {
    Header: 'Client',
    accessor: 'client',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['client']}),
    filterAll: true,
    maxWidth: 150,
    minWidth: 150
  },
  //client_email #1037 due to requirement changes
  {
    Header: 'Client Email',
    accessor: 'client_email',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['client_email']}),
    filterAll: true,
    maxWidth: 175,
    minWidth: 175
  },
  //client_external_reference #1037 due to requirement changes
  {
    Header: 'Client External Reference',
    accessor: 'client_external_reference',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['client_external_reference']}),
    filterAll: true,
    maxWidth: 300,
    minWidth: 300
  },
  {
    Header: 'Transaction',
    accessor: 'transaction_date',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['transaction_date']}),
    filterAll: true,
    maxWidth: 150,
    minWidth: 150
  },
  {
    Header: 'Settlement',
    accessor: 'settlement_date',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['settlement_date']}),
    filterAll: true,
    maxWidth: 150,
    minWidth: 150
  },
  {
    Header: 'Nickname',
    accessor: 'nickname',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['nickname']}),
    filterAll: true,
    maxWidth: 250,
    minWidth: 250
  },
  // #1037 Remove Beneficiary field due to requirement changes
  // {
  //   Header: "Beneficiary",
  //   accessor: "beneficiary",
  //   filterMethod: (filter, rows) =>
  //     matchSorter(rows, filter.value, { keys: ["beneficiary"] }),
  //   filterAll: true,
  //   maxWidth: 250,
  //   minWidth: 250
  // },
  {
    Header: 'From Curr',
    accessor: 'currency_from',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['currency_from']}),
    filterAll: true,
    maxWidth: 150,
    minWidth: 150
  },
  {
    Header: 'From Amount',
    accessor: 'amount_from',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['amount_from']}),
    Cell: c => (
      <React.Fragment>
        <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={c.value} />{' '}
      </React.Fragment>
    ),
    filterAll: true,
    maxWidth: 150,
    minWidth: 150
  },
  {
    Header: 'Rate',
    accessor: 'client_rate',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['client_rate']}),
    Cell: c => (
      <React.Fragment>
        <FormattedNumber minimumFractionDigits={5} maximumFractionDigits={5} value={c.value} />{' '}
      </React.Fragment>
    ),
    filterAll: true,
    maxWidth: 150,
    minWidth: 150
  },
  {
    Header: 'To Curr',
    accessor: 'currency_to',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['currency_to']}),
    filterAll: true,
    maxWidth: 150,
    minWidth: 150
  },
  {
    Header: 'To Amount',
    accessor: 'amount_to',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['amount_to']}),
    Cell: c => (
      <React.Fragment>
        <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={c.value} />{' '}
      </React.Fragment>
    ),
    filterAll: true,
    maxWidth: 150,
    minWidth: 150
  },
  {
    Header: 'Transfer Status',
    accessor: 'transfer_status_name',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['transfer_status_name']}),
    // Cell: (c) => <React.Fragment> {
    //   c.original.transfer_status_name
    // } </React.Fragment>,

    Filter: ({filter, onChange}) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{width: '100%'}}
        value={filter ? filter.value : 'all'}
      >
        <option value=''>Show All</option>
        <option value='Draft'>Draft</option>
        <option value='In Process'>In Process</option>
        <option value='Acknowledged'>Acknowledged</option>
        <option value='Processing'>Processing</option>
        <option value='Awaiting Funds'>Awaiting Funds</option>
        <option value='Funds Received'>Funds Received</option>
        <option value='Payment Made'>Payment Made</option>
        <option value='Completed'>Completed</option>
        <option value='On Hold'>On Hold</option>
        <option value='Not Proceeding'>Not Proceeding</option>
        {
          //Use a dynamic list later #1076
          //this.state.transfer_status_optionlist
        }
      </select>
    ),

    //end of test
    filterAll: true,
    maxWidth: 150,
    minWidth: 150
  }
];

class ReportsTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_list: [],
      team_list: [],
      dialog_delete_confirm: false,
      transfer_list: [],
      table_data: [],
      finished: false,
      dataToDownload: [],
      startDate: moment().subtract(6, 'month'),
      endDate: moment().add(1, 'month'),
      transfer_status_optionlist: ''
    };
  }

  // = React lifecycle =
  componentDidMount() {
    API.get('teams', `/get-list-not-deleted`)
      .then(response => {
        this.setState({
          team_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  //Take out unused lifecyle overwriting //improvement by Tom #1076

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (prevState.transfer_list !== this.state.transfer_list) {
  //     // console.log(this.state.transfer_list); //#1036 Debug message
  //     // this.setState({table_data: this.buildTableData()}, this.finished())
  //   }
  // }

  buildTableData() {
    // console.log(this.state);

    let date_range_transfer_list = this.state.transfer_list;
    if (this.state.startDate && this.state.endDate) {
      date_range_transfer_list = this.state.transfer_list.filter(transfer => {
        //  console.log("testing: ", transfer.transaction_datetime);
        //  console.log("testing: ", moment(transfer.transaction_datetime));
        //  console.log("testing: ", this.state.startDate.isBefore(moment(transfer.transaction_datetime).add(1, "day")));
        //  console.log("testing: ", this.state.endDate.isAfter(moment(transfer.transaction_datetime)));

        //Before Change
        //return (this.state.startDate.isBefore(moment(transfer.transaction_datetime).add(1, "day")) && this.state.endDate.isAfter(moment(transfer.transaction_datetime)));

        //Bugfix #1075 improvement: filter payout_datetime instead of filter transaction datetime by Tom
        return (
          this.state.startDate.isBefore(moment(transfer.payout_datetime)) &&
          this.state.endDate.isAfter(moment(transfer.payout_datetime).subtract(1, 'day'))
        );
      });
    }

    var transfer_list_length = date_range_transfer_list.length;

    // var transfer_list_length = this.state.transfer_list.length;

    //= Remove #1076 Debug message
    //console.log(date_range_transfer_list);

    if (transfer_list_length > 0) {
      //return this.state.transfer_list.map((prop, key) => {
      return date_range_transfer_list.map((prop, key) => {
        // console.log(prop.payout_datetime);
        // console.log(key);
        // console.log(moment(prop.payout_datetime).format("DD/MM/YYYY") === "Invalid date");

        if (moment(prop.payout_datetime).format('DD/MM/YYYY') === 'Invalid date') {
          return {
            id: prop.id,
            client: prop.client_nickname,
            client_external_reference: prop.client_external_reference,
            client_email: prop.client_email,
            transaction_date: moment(prop.transaction_datetime).format('DD/MM/YYYY'),
            memo: prop.memo,
            nickname: prop.nickname,
            settlement_date: moment(prop.settlement_date).format('DD/MM/YYYY'),
            beneficiary: prop.beneficiaries_nickname,
            amount_from: prop.amount_from,
            amount_to: prop.amount_to,
            currency_from: prop.currency_from_iso_alpha_3,
            currency_to: prop.currency_to_iso_alpha_3,
            client_rate: prop.client_rate,
            payout_bank_reference: prop.payout_bank_reference,
            transfer_status_name: prop.transfer_status_name
          };
        } else {
          return {
            id: prop.id,
            client: prop.client_nickname,
            client_external_reference: prop.client_external_reference,
            client_email: prop.client_email,
            transaction_date: moment(prop.transaction_datetime).format('DD/MM/YYYY'),
            memo: prop.memo,
            nickname: prop.nickname,
            settlement_date: moment(prop.settlement_date).format('DD/MM/YYYY'),
            beneficiary: prop.beneficiaries_nickname,
            amount_from: prop.amount_from,
            amount_to: prop.amount_to,
            currency_from: prop.currency_from_iso_alpha_3,
            currency_to: prop.currency_to_iso_alpha_3,
            client_rate: prop.client_rate,
            payout_bank_reference: prop.payout_bank_reference,
            payout_datetime: moment(prop.payout_datetime).format('DD/MM/YYYY'),
            transfer_status_name: prop.transfer_status_name
          };
        }
      });
    } else {
      return [];
    }
  }

  download = event => {
    const currentRecords = this.reactTable.getResolvedState().sortedData;
    var data_to_download = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < columns.length; colIndex++) {
        record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor];
      }
      data_to_download.push(record_to_download);
    }
    this.setState({dataToDownload: data_to_download}, () => {
      // click the CSVLink component to trigger the CSV download
      this.csvLink.link.click();
    });
  };

  finished() {
    this.setState({finished: true});
  }

  getTransferDetails(team_id) {
    API.get('reports', `/getByTeam/id/${team_id}`)
      .then(response => {
        this.setState({transfer_list: response});
      })
      .catch(error => {
        console.log(error);
      });
  }

  handle_date_change = (startDate, endDate) => {
    // console.log(startDate, endDate); //#1036 Debug message
    this.setState({startDate, endDate});
  };

  handle_delete_dialog_click = item_to_delete => {
    this.setState({dialog_delete_confirm: false});
    // console.log(item_to_delete);
    API.put('transfers', `/update/id/${item_to_delete}`, {
      body: {deleted: true}
    })
      .then(response => {
        // console.log(response);
        this.props.fetchTransferList();
        // dispatch(receiveStaffList(response));
      })
      .catch(error => {
        console.log(error);
        // quickfix because of the api bug, so it returns api error because client talks to old database
        this.props.fetchTransferList();
      });
  };

  handle_delete_dialog_cancel = () => {
    this.setState({
      dialog_delete_confirm: false,
      dialog_delete_item: 0,
      dialog_delete_item_name: ''
    });
  };

  handleSelectChange = event => {
    this.setState({[event.target.name]: event.target.value});
    this.getTransferDetails(event.target.value);
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const {classes} = this.props;

    //= Remove #1076 Debug message
    //console.log(this.state);

    //#1076 Tom work around to set a map(key, prop) for optionlist
    //console.log(this.state.transfer_list);

    // let transfer_status = this.state.transfer_list.map((prop, key) => {
    //   switch (prop.status) {
    //     case 1:
    //       return "Draft"; //prop.status;
    //       break;
    //     case 2:
    //       return "In Process"; //prop.status;
    //       break;
    //     case 3:
    //       return "Acknowledged"; //prop.status;
    //       break;
    //     case 4:
    //       return "Processing"; //prop.status;
    //       break;
    //     case 5:
    //       return "Awaiting Funds"; //prop.status;
    //       break;
    //     case 6:
    //       return "Funds Received"; //prop.status;
    //       break;
    //     case 7:
    //       return "Payment Made"; //prop.status;
    //       break;
    //     case 8:
    //       return "Completed"; //prop.status;
    //       break;
    //     case 9:
    //       return "On Hold"; //prop.status;
    //       break;
    //     case 10:
    //       return "Not Proceeding"; //prop.status;
    //       break;
    //     default:
    //       return prop.status;
    //       break;
    //   }
    // });

    /* Use a dynamic list later #1076 by Tom
     *  //this.state.transfer_status_optionlist
     */

    // let transfer_status_unique = [...new Set(transfer_status)];
    // let transfer_status_optionlist = transfer_status_unique.map((prop, key) => {
    //   return (
    //     <option key={key} value={prop}>{prop}</option>
    //   );
    // });

    // this.setState({
    //   transfer_status_optionlist: transfer_status_optionlist
    // });

    // console.log(transfer_status);
    // console.log(transfer_status_optionlist);

    //end of workaround

    //= Remove #1076 debug message =
    //console.log(this.state.transfer_list);
    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                <FormattedMessage id='reportsTransfer.cardItem1.team' defaultMessage={`Teams`} />
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.team || ''}
                onChange={this.handleSelectChange}
                inputProps={{
                  name: 'team',
                  id: 'team'
                }}
              >
                <MenuItem
                  key='0x0'
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Select client {' from ' + this.state.clients_fullname}
                </MenuItem>
                {this.state.team_list
                  // .sort((a, b) => a.nickname.localeCompare(b.nickname))
                  .map(item => {
                    return (
                      <MenuItem
                        key={item.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={item.id}
                      >
                        {item.nickname}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <div style={{float: 'right'}}>
              <Button round color={'primary'} onClick={this.download}>
                <i className='material-icons'>cloud_download</i> CSV
              </Button>
            </div>
          </GridItem>
        </GridContainer>
        <hr />

        <DateRangePicker
          isOutsideRange={() => false}
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
          displayFormat='DD/MM/YYYY'
          onDatesChange={({startDate, endDate}) => this.handle_date_change(startDate, endDate)} // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => this.setState({focusedInput})} // PropTypes.func.isRequired,
        />
        <ReactTable
          data={this.buildTableData()}
          ref={r => (this.reactTable = r)}
          // data={this.state.table_data}
          filterable
          columns={columns}
          defaultSorted={[
            {
              id: 'transaction_date',
              desc: true
            },
            {
              id: 'client_fullname',
              desc: false
            }
          ]}
          defaultPageSize={10}
          // showPaginationTop
          showPaginationBottom
          className='-highlight'
        />
        <CSVLink
          data={this.state.dataToDownload}
          filename='data.csv'
          className='hidden'
          ref={r => (this.csvLink = r)}
          target='_blank'
        />

        <Dialog
          open={this.state.dialog_delete_confirm}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Are you sure that you wish to <strong>delete</strong> transfer record {this.state.dialog_delete_item_name}
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handle_delete_dialog_cancel} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => this.handle_delete_dialog_click(this.state.dialog_delete_item)}
              color='danger'
              autoFocus
            >
              Delete Record
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ReportsTransfer.protoType = {
  //ReportsTransfer: ProtoTypes.func,
  fetchTransferList: ProtoTypes.func
};

export default withRouter(withStyles(styles)(ReportsTransfer));
