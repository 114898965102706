export default {
  s3: {
    REGION: 'YOUR_S3_UPLOADS_BUCKET_REGION',
    BUCKET: 'YOUR_S3_UPLOADS_BUCKET_NAME'
  },
  apiGateway: {
    REGION: 'ap-southeast-2',
    URL: 'https://crm-api.forexworldwide.com/system'
  },
  cognito: {
    REGION: 'ap-southeast-2',
    USER_POOL_ID: 'ap-southeast-2_j8yViqt34',
    APP_CLIENT_ID: '64n5lrg1emb9pbjurrj09ulidc',
    IDENTITY_POOL_ID: 'ap-southeast-2:3d1e2212-2ed8-4aa8-b0e4-45ed28a4c701'
  }
};
