import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';
// import StaffEditContainer from '../../../components/Staff/StaffEditContainer';
import StaffEditContainer from '../../../components/Staff/StaffEdit'
import Button from '../../../components/CustomButtons/Button';

import {FormattedMessage} from 'react-intl';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
const staffStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class Staff extends React.Component {
  render() {
    const {classes} = this.props;

    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id='StaffFunction.cardItem1.title' defaultMessage={'Staff Management Functions'} />
              </h4>
              <div>
                <Button round color={'info'} onClick={() => this.props.history.push('/settings/stafflist')}>
                  <FormattedMessage id='newClient.cardItem1.showAll' defaultMessage={'Show All'} />
                </Button>
                <Button
                  disabled={
                    !(this.props.app_state.current_staff && this.props.app_state.current_staff.security_team_admin)
                  }
                  round
                  color={'info'}
                  onClick={() => this.props.history.push('/settings/staffnew')}
                >
                  <FormattedMessage id='newStaff.cardItem1.createNew' defaultMessage={'Create New'} />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                {/*{this.selectUi()}*/}
                <StaffEditContainer staff_id={this.props.match.params.staff_id} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

Staff.propTypes = {
  classes: PropTypes.object.isRequired,
  selectStaffUi: PropTypes.func.isRequired
};

export default withStyles(staffStyles)(Staff);
