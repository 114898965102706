import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {FormattedMessage} from 'react-intl';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import {API} from 'aws-amplify';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';

import ClientContactsEdit from '../../../features/Clients/ClientContactsEdit';
import {Typography} from '@material-ui/core';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
const clientContactsStyles = {
  cardTitle,
  ...customCheckboxRadioSwitch,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  clientFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '300px'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardPadding: {
    padding: '0px 12px 16px',
    margin: '8px'
  }
};

const ClientContactsEditContainer = props => {
  const {classes, match} = props;
  const clientContactId = match.params.clientContactId;

  const [clientContactData, setClientContactData] = useState(null);

  const fetchClientContactsById = id => {
    if (!id) {
      return;
    }

    API.get('clientcontacts', `/id/${id}`)
      .then(response => {
        setClientContactData(response);
      })
      .catch(error => {
        console.log(error);
        API.get('clientcontacts', `/id/${id}`)
          .then(response => {
            setClientContactData(response);
          })
          .catch(error => {
            console.log(error);
          });
      });
  };

  // const updateClientContacts = () => {};

  useEffect(() => {
    fetchClientContactsById(clientContactId);
  }, [clientContactId]);

  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardIcon color='rose'>
          <PermIdentity />
        </CardIcon>
        <div className={classes.flexEnd}>
          <h4 className={classes.cardIconTitle}>
            <FormattedMessage
              id='clientContacts.cardItem1.clientContactsList'
              defaultMessage={'Client Contacts Management Functions'}
            />
          </h4>
        </div>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            {!clientContactData ? (
              <Typography>Loading...</Typography>
            ) : (
              <ClientContactsEdit classes={classes} clientContactData={clientContactData} id={clientContactId} />
            )}
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

ClientContactsEditContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(clientContactsStyles)(ClientContactsEditContainer);
