import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
// import Card from "../../components/Card/Card.jsx";
// import CardBody from "../../components/Card/CardBody.jsx";

// import staffNewStyles from "./StaffNewStyles";
// import CardHeader from "../../components/Card/CardHeader";
// import CardIcon from "../../components/Card/CardIcon";
// import PermIdentity from "@material-ui/icons/PermIdentity";
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';

import {cardTitle} from '../../assets/jss/material-dashboard-pro-react.jsx';
const entityNewStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  }
};

class EntityNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // register form
      nickname: '',
      nickname_state: '',
      legal_entity_name: '',
      legal_entity_name_state: '',
      afsl: '',
      afsl_state: ''
    };
  }

  validateForm() {
    var valid = true;
    if (this.state.nickname_state === '') {
      this.setState({nickname_state: 'error'});
      valid = false;
    }
    if (this.state.legal_entity_name_state === '') {
      this.setState({legal_entity_name_state: 'error'});
      valid = false;
    }
    if (this.state.afsl_state === '') {
      this.setState({afsl_state: 'error'});
      valid = false;
    }
    return valid;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'checkbox':
        if (event.target.checked) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-length':
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'url':
        if (this.verifyUrl(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'min-value':
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-value':
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'range':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      default:
        break;
    }
    switch (type) {
      case 'checkbox':
        this.setState({[stateName]: event.target.checked});
        break;
      default:
        this.setState({[stateName]: event.target.value});
        break;
    }
  }

  async handleSubmitForm() {
    // if (this.validateForm()) {
    await this.props.createNewEntity({
      nickname: this.state.nickname,
      legal_entity_name: this.state.legal_entity_name,
      afsl: this.state.afsl
    });
    this.props.entitiesListIsDirty(true);
    this.props.fetchEntityListNotDeleted();
    this.props.selectEntityUi('list');
    // this.setState({
    //   newUser: "sd"
    // });
    // }
    // else {
    //   return;
    // }
  }

  handleCancel() {
    // if (this.validateForm()) {
    this.props.cancelCreateNewEntity();
    // this.setState({
    //   newUser: "sd"
    // });
    // }
    // else {
    //   return;
    // }
  }

  // handleSubmitConfirmation() {
  //   // if (this.validateForm()) {
  //   console.log("hey");
  //   this.props.confirmNewStaffMember({
  //     email: this.state.email,
  //     confirmation_code: this.state.confirmation_code
  //   });
  //   this.setState({
  //     newUser: "sd"
  //   });
  //   // }
  //   // else {
  //   //   return;
  //   // }
  // }

  renderForm() {
    const {classes} = this.props;
    return (
      <GridItem xs={12} sm={6}>
        <h3>Enter details for new entity</h3>
        <form>
          <CustomInput
            success={this.state.nickname_state === 'success'}
            error={this.state.nickname_state === 'error'}
            labelText='Nickname *'
            id='nickname'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.nickname,
              onChange: event => this.change(event, 'nickname', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            success={this.state.legal_entity_name_state === 'success'}
            error={this.state.legal_entity_name_state === 'error'}
            labelText='Legal Entity Name *'
            id='legal_entity_name'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.legal_entity_name,
              onChange: event => this.change(event, 'legal_entity_name'),
              type: 'text'
            }}
          />
          <CustomInput
            success={this.state.afsl_state === 'success'}
            error={this.state.afsl_state === 'error'}
            labelText='AFSL*'
            id='afsl'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.afsl,
              onChange: event => this.change(event, 'afsl', 'length', 0),
              type: 'text'
            }}
          />
          <div className={classes.formCategory}>
            <small>*</small> Required fields
          </div>
          <Button color='rose' onClick={() => this.handleSubmitForm()} className={classes.registerButton}>
            Register
          </Button>
          <Button color='' onClick={() => this.handleCancel()} className={classes.registerButton}>
            Cancel
          </Button>
        </form>
      </GridItem>
    );
  }

  // renderConfirmationForm() {
  //   const { classes } = this.props;
  //   return (
  //     <GridItem xs={12} sm={6}>
  //       <h3>Enter details for new staff member</h3>
  //       <form>
  //         <CustomInput
  //           success={this.state.emailState === "success"}
  //           error={this.state.emailState === "error"}
  //           labelText="Email Address *"
  //           id="email"
  //           formControlProps={{
  //             fullWidth: true
  //           }}
  //           inputProps={{
  //             value: this.state.email,
  //             onChange: event => this.change(event, "email", "email"),
  //             type: "email"
  //           }}
  //         />
  //         <CustomInput
  //           success={this.state.confirmationCodeState === "success"}
  //           error={this.state.confirmationCodeState === "error"}
  //           labelText="Confirmation Code*"
  //           id="confirmation_code"
  //           formControlProps={{
  //             fullWidth: true
  //           }}
  //           inputProps={{
  //             value: this.state.confirmation_code,
  //             onChange: event =>
  //               this.change(event, "confirmation_code", "length", 0),
  //             type: "text"
  //           }}
  //         />
  //         <div className={classes.formCategory}>
  //           <small>*</small> Required fields
  //         </div>
  //         <Button
  //           color="rose"
  //           onClick={() => this.handleSubmitConfirmation()}
  //           className={classes.registerButton}
  //         >
  //           Register
  //         </Button>
  //       </form>
  //     </GridItem>
  //   );
  // }

  render() {
    // const { classes } = this.props;
    // console.log(this.props);
    // console.log(this.props);
    return (
      <div>
        <GridContainer>
          {this.renderForm()}
          {/*{this.props.staff.staff_create_new_awaiting_confirmation === false*/}
          {/*? this.renderForm()*/}
          {/*: this.renderConfirmationForm()}*/}
        </GridContainer>
      </div>
    );
  }
}

EntityNew.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(entityNewStyles)(EntityNew);
