import React, {Fragment, useState, useEffect} from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Box, Grid, Link, TextField, Typography} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CardHeader from '../../../components/Card/CardHeader';
import Button from '../../../components/CustomButtons/Button.jsx';
import {API, Storage} from 'aws-amplify';

const BeneficiaryDocumentsView = ({classes, beneficiaryDocumentData, beneficiaryDocumentId}) => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [beneficiaryId, setBeneficiaryId] = useState('');
  const [description, setDescription] = useState('');
  const [documentId, setDocumentId] = useState('');

  const [documentDownloadUrl, setDocumentDownloadUrl] = useState(null);

  const handleSave = () => {
    setLoading(true);

    const beneficiaryDocumentData = {
      beneficiaryId,
      description
    };

    API.put('beneficiary-documents', `/id/${beneficiaryDocumentId}`, {body: beneficiaryDocumentData})
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
        API.put('beneficiary-documents', `/id/${beneficiaryDocumentId}`, {body: beneficiaryDocumentData})
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUrl = async fileKey => {
    return await Storage.get(fileKey);
  };

  useEffect(() => {
    if (!beneficiaryDocumentData) {
      return;
    }

    if (beneficiaryDocumentData.file_ref) {
      getUrl(beneficiaryDocumentData.file_ref)
        .then(response => {
          setDocumentDownloadUrl(response);
        })
        .catch(err => {
          console.log(err.message || 'error');
        });
    }

    setBeneficiaryId(beneficiaryDocumentData.beneficiaryId);
    setDescription(beneficiaryDocumentData.description);
    setDocumentId(beneficiaryDocumentData.documentId);
  }, [beneficiaryDocumentData]);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader color='rose' icon>
              <div className={classes.flexEnd}>
                <h4 className={classes.cardIconTitle}>{editMode ? 'Edit Record: ' : 'View Record: '}</h4>
                <React.Fragment>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editMode}
                        onChange={() => setEditMode(prevState => !prevState)}
                        value='edit_mode'
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label={editMode ? 'Edit Mode' : 'Read Only'}
                  />
                </React.Fragment>
              </div>
            </CardHeader>
            <CardBody>
              <Box className={classes.editFields}>
                {documentId && (
                  <Typography color={editMode ? 'textPrimary' : 'textSecondary'}>Document ID: {documentId}</Typography>
                )}

                <TextField
                  disabled={!editMode}
                  fullWidth
                  label='Beneficiary Id'
                  value={beneficiaryId}
                  onChange={e => setBeneficiaryId(e.target.value)}
                  size='small'
                  autoComplete='no'
                />
                <TextField
                  disabled={!editMode}
                  fullWidth
                  label='Description'
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  size='small'
                  autoComplete='no'
                  multiline
                  minRows={3}
                />
              </Box>
              <Button
                disabled={!editMode || !beneficiaryDocumentId || loading}
                onClick={handleSave}
                type='submit'
                color='primary'
              >
                {loading ? 'Saving...' : 'Save'}
              </Button>
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography>
            Document Preview{' - '}
            <Typography component={Link} target={'_blank'} rel='noreferrer' href={documentDownloadUrl}>
              Click here to download
            </Typography>
          </Typography>
          <Box boxShadow={2}>
            {documentDownloadUrl && (
              <Fragment>
                <object data={documentDownloadUrl} width='100%' height={800}>
                  <Typography component={Link} target={'_blank'} href={documentDownloadUrl} rel='noreferrer'>
                    Click here to download
                  </Typography>
                </object>
              </Fragment>
            )}
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default BeneficiaryDocumentsView;
