import React, {Fragment} from 'react';
// used for making the prop types of this component
import PropTypes from 'prop-types';

// core components
import Button from 'components/CustomButtons/Button.jsx';

import defaultImage from 'assets/img/image_placeholder.jpg';
import defaultAvatar from 'assets/img/placeholder.jpg';
import defaultPdf from 'assets/img/preview_pdf.jpg';

import {FormattedMessage} from 'react-intl';

import {s3Upload} from 'libs/awsLib.js';

class DocumentUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.placeholder ? this.props.placeholder : defaultImage
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  handleImageChange = async e => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };
    // this.props.updateNewIdentificationRecordCreation("file", file)
    reader.readAsDataURL(file);

    try {
      const attachment = file ? await s3Upload(file) : null;
      // console.log(attachment)
      // this.props.updateNewIdentificationRecordCreation("file_id", attachment)
      this.props.updateFunction(attachment, this.props.index);
    } catch (e) {
      alert(e);
    }
  };
  handleSubmit(e) {
    console.log(e);
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    // this.props.updateNewIdentificationRecordCreation("file", null)
    this.refs.fileInput.value = null;
    // this.props.onRemove(this.props.index); //#1030 Found this bug and fix it later
  }

  previewFile = () => {
    const image = new Image();
    image.src = this.state.imagePreviewUrl;
    const imgWindow = window.open(this.state.imagePreviewUrl);
    //@ts-ignore
    imgWindow.document.write(image.outerHTML);
  };

  render() {
    const {addButtonProps, changeButtonProps, removeButtonProps} = this.props;

    let preview_src = this.state.imagePreviewUrl;
    if (this.state.file) {
      if (this.state.file.type == 'application/pdf') {
        preview_src = defaultPdf;
      }
    }
    // console.log(this.state.file)
    return (
      <div className='fileinput text-center'>
        <input type='file' onChange={this.handleImageChange} ref='fileInput' />
        <div className={'thumbnail'}>
          <img src={preview_src} alt='...' />
        </div>
        {/*<p>{(!this.props.identification.new_identification_record_data || !this.props.identification.new_identification_record_data.file_id) && <span>No file has been loaded</span>}</p>*/}
        {/*<p>{(this.props.identification.new_identification_record_data && this.props.identification.new_identification_record_data.file_id) && <span>File has now loaded</span>}</p>*/}
        <div>
          {this.state.file === null ? (
            <Button {...addButtonProps} onClick={() => this.handleClick()}>
              {/* <FormattedMessage
                      id="newClients.step4.form.selectImage"
                      defaultMessage={
                        `Select Image`}
                    /> */}
              {this.props.buttonMessage || 'Select Image'}
            </Button>
          ) : (
            <Fragment>
              <span>
                {this.props.change && (
                  <React.Fragment>
                    <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                      Change
                    </Button>
                    <br />
                  </React.Fragment>
                )}
                <Button {...removeButtonProps} onClick={() => this.handleRemove()}>
                  <i className='fas fa-times' /> Remove
                </Button>
              </span>
              <br />
              {this.state.imagePreviewUrl && (
                <React.Fragment>
                  <a onClick={this.previewFile} target='_blank' rel='noreferrer'>
                    Open image in new tab
                  </a>
                  <br />
                  <strong>Note: this link will expire in 60 seconds</strong>
                </React.Fragment>
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

DocumentUpload.propTypes = {
  avatar: PropTypes.bool,
  change: PropTypes.bool, // allows user to change image
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default DocumentUpload;
