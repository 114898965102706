import React from 'react';
import {connect} from 'react-redux';
import ReactTable from 'react-table';
import {
  requestEntityList,
  fetchEntityListNotDeleted,
  entitiesListIsDirty,
  setEntityDetailId,
  selectEntityUi
} from '../../redux/actions/teams';

import matchSorter from 'match-sorter';
import moment from 'moment';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';
// core components
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import {API} from 'aws-amplify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import NavPills from 'components/NavPills/NavPills.jsx';
import Card from '@material-ui/core/Card';

import CustomReactSelect from '../Forms/CustomReactSelect/CustomReactSelect';

import sample_invoice from 'assets/img/invoicing/sample_invoice.png';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  InputAdornment,
  Switch
} from '@material-ui/core';
import CustomTextField from '../CustomInput/CustomTextField';
import Icon from '@ant-design/icons/lib/components/Icon';
import {Form, Input, Button as AntButton, Switch as AntSwitch} from "antd";

const staffEditStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  paddingTwo: {marginBottom: '2%'},
  badge: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {float: 'right'}
};

class TeamEdit extends React.Component {

  paymentRequestRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      is_loading: true,
      nickname: '',
      business_name: '',
      abn: '',
      responsible_staff_member: '',
      address_line_1: '',
      address_line_2: '',
      address_suburb: '',
      address_state: '',
      address_postcode: '',
      ref_code: '',
      default_rate: '',
      holdingAmountList: [],
      staff_list: [],
      division_list: [],
      currencies_list_priority: [],
      invoice_style: 1,
      client_list: [],
      transferFee: [],
      createPurposeOfPaymentModal: false,
      purposeOfPayments: [],
      brand_transfer_legacy: false,
      loading: false,
      brand_sidebar_identification_list: false,
      brand_sidebar_searchtransfers: false,
      brand_sidebar_identification_all: false,
      brand_sidebar_new_transfer: false,
      brand_sidebar_multipay: false,
      brand_kyc_hideMaritalStatus: false,
      brand_kyc_hidePlaceOfBirth: false,
      brand_sidebar_currencytools: false,
      brand_sidebar_invoicelist: false,
      brand_sidebar_forwards: false,
      brand_transfer_payeeFirst: false,
      brand_ledger: false,
      brand_paymentRequests: false,
      brand_multipay: false,
      brand_splitpay: false,
      brand_create_transfer: false,
    };
  }

  componentDidMount() {
    this.getTeamDetails()
    this.populateData()
    this.getStaffList()
    this.getPurposeOfPayment();
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
      console.log('current_staff changed')
      this.getStaffList()
      this.getTeamDetails()
      this.populateData()
    }
    if (prevProps.team_id !== this.props.team_id) {
      console.log('team_id changed')
      this.getStaffList()
      this.getTeamDetails()
      this.populateData()
    }
  }

  getTeamDetails = () => {
    API.get('teams', `/get/id/${this.props.team_id}`)
      .then(response => {
        this.setState({
          loaded: true,
          is_loading: false,
          nickname: response.nickname,
          business_name: response.business_name,
          abn: response.abn,
          defaultFromCurrency: response.defaultFromCurrency,
          teamClientId: response.teamClientId,
          defaultToCurrency: response.defaultToCurrency,
          responsible_staff_member: response.responsible_staff_member,
          address_line_1: response.address_line_1,
          address_line_2: response.address_line_2,
          address_suburb: response.address_suburb,
          address_state: response.address_state,
          address_postcode: response.address_postcode,
          invoice_style: response.invoice_style,
          ref_code: response.ref_code,
          default_rate: response.default_rate,
          brand_transfer_legacy: response.brand_transfer_legacy,
          brand_sidebar_searchtransfers: response.brand_sidebar_searchtransfers,
          brand_sidebar_identification_list: response.brand_sidebar_identification_list,
          brand_sidebar_identification_all: response.brand_sidebar_identification_all,
          brand_sidebar_new_transfer: response.brand_sidebar_new_transfer,
          brand_sidebar_multipay: response.brand_sidebar_multipay,
          brand_kyc_hideMaritalStatus: response.brand_kyc_hideMaritalStatus,
          brand_kyc_hidePlaceOfBirth: response.brand_kyc_hidePlaceOfBirth,
          brand_sidebar_currencytools: response.brand_sidebar_currencytools,
          brand_sidebar_invoicelist: response.brand_sidebar_invoicelist,
          brand_sidebar_forwards: response.brand_sidebar_forwards,
          brand_transfer_payeeFirst: response.brand_transfer_payeeFirst,
          brand_ledger: response.brand_ledger,
          brand_paymentRequests: response.brand_paymentRequests,
          brand_multipay: response.brand_multipay,
          brand_splitpay: response.brand_splitpay,
          brand_create_transfer: response.brand_create_transfer,
          portal_registration: response.portal_registration
        });

        this.paymentRequestRef.current.setFieldsValue({
          brand_pr_paymentRequestHeading: response.brand_pr_paymentRequestHeading,
          brand_pr_showGst: response.brand_pr_showGst
        })
      })
      .catch(error => {
        console.log(error);
      });
  }

  getStaffList = () => {
    if (this.props.app_state.current_staff) {
      API.get('staff', `/list/staffID/${this.props.app_state.current_staff.id}`)
        .then(response => {
          this.setState({
            staff_list: response
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

    populateData = () => {
      const teamID = this.props.team_id;

      API.get('divisions', '/get-list-not-deleted')
      .then(response => {
        this.setState({division_list: response});
      })
      .catch(error => {
        console.log(error);
      });

    API.get('beneficiaries', `/get/by_team_id/${teamID}`)
      .then(response => {
        this.setState({beneficiaries_list: response});
      })
      .catch(error => {
        console.log(error);
      });
    API.get('teams', `/get/team_transfer/${teamID}`)
      .then(response => {
        this.setState({transferFee: response});
      })
      .catch(error => {
        console.log(error);
      });
    API.get('currencies', '/list_not_deleted').then(response => {
      this.setState({currencies_list_priority: response}, () => {});
    });
    API.get('clients', `/getByTeam/id/${teamID}`).then(response => {
      // console.log(response);
      this.setState({client_list: response}, () => {});
    });
    API.get('holding_accounts', `/get/team_id/${teamID}`)
      .then(response => {
        this.setState({holdingAmountList: response});
      })
      .catch(error => {
        console.log(error);
      });
    
    }

  getPurposeOfPayment = () => {
    const teamID = this.props.team_id;
    API.get('transfers', `/get_purpose_by_team/id/${teamID}`)
      .then(response => {
        this.setState({purposeOfPayments: response});
      })
      .catch(error => {
        console.log(error);
      });
  };

  async saveDivision(team_detail) {
    const teamID = this.props.team_id;
    return API.put('teams', `/update/id/${teamID}`, {body: team_detail});
  }

  validateForm() {
    let valid = true;
    if (this.state.nickname_state === '') {
      this.setState({nickname_state: 'error'});
      valid = false;
    }

    return valid;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  verifyPositiveNumber(value) {
    const numberRex = new RegExp('^[1-9]+[0-9]*$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'checkbox':
        if (event.target.checked) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'positiveNumber':
        if (this.verifyPositiveNumber(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-length':
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'url':
        if (this.verifyUrl(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'min-value':
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-value':
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'range':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      default:
        break;
    }
    switch (type) {
      case 'checkbox':
        this.setState({[stateName]: event.target.checked});
        break;
      default:
        this.setState({[stateName]: event.target.value});
        break;
    }
  }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value});
  };

  select_invoice_style = style_number => {
    this.setState({invoice_style: style_number});
  };

  async handleSubmit() {
    this.setState({isLoading: true});
    try {
      await this.saveDivision({
        nickname: this.state.nickname,
        responsible_staff_member: this.state.responsible_staff_member,
        business_name: this.state.business_name,
        abn: this.state.abn,
        defaultFromCurrency: this.state.defaultFromCurrency,
        teamClientId: this.state.teamClientId,
        defaultToCurrency: this.state.defaultToCurrency,
        address_line_1: this.state.address_line_1,
        address_line_2: this.state.address_line_2,
        address_suburb: this.state.address_suburb,
        address_state: this.state.address_state,
        address_postcode: this.state.address_postcode,
        invoice_style: this.state.invoice_style,
        ref_code: this.state.ref_code,
        default_rate: this.state.default_rate,
        brand_transfer_legacy: this.state.brand_transfer_legacy,
        brand_sidebar_identification_list: this.state.brand_sidebar_identification_list,
        brand_sidebar_searchtransfers: this.state.brand_sidebar_searchtransfers,
        brand_sidebar_identification_all: this.state.brand_sidebar_identification_all,
        brand_sidebar_new_transfer: this.state.brand_sidebar_new_transfer,
        brand_sidebar_multipay: this.state.brand_sidebar_multipay,
        brand_kyc_hideMaritalStatus: this.state.brand_kyc_hideMaritalStatus,
        brand_kyc_hidePlaceOfBirth: this.state.brand_kyc_hidePlaceOfBirth,
        brand_sidebar_currencytools: this.state.brand_sidebar_currencytools,
        brand_sidebar_invoicelist: this.state.brand_sidebar_invoicelist,
        brand_sidebar_forwards: this.state.brand_sidebar_forwards,
        brand_transfer_payeeFirst: this.state.brand_transfer_payeeFirst,
        brand_ledger: this.state.brand_ledger,
        brand_paymentRequests: this.state.brand_paymentRequests,
        brand_multipay: this.state.brand_multipay,
        brand_splitpay: this.state.brand_splitpay,
        brand_create_transfer: this.state.brand_create_transfer,

      });
    } catch (e) {
      alert(e);
    }
  }

  handleCustomReactSelectChange = name => value => {
    let option_value;
    if (value === null) {
      option_value = null;
    } else {
      option_value = value.value;
    }
    this.setState({[name]: option_value});
  };

  handleCustomReactCheckBoxChange = name => value => {
    this.setState({[name]: !this.state[name]});
  };

  render_team_details() {
    const {classes} = this.props;

    const division_list_select_options = this.state.division_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));

    //list options for #972
    const staff_list_select_options = this.state.staff_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));

    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <form>
              <CustomInput
                success={this.state.nickname_state === 'success'}
                error={this.state.nickname_state === 'error'}
                labelText='Nick Name *'
                id='nickname'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: this.state.nickname,
                  onChange: event => this.change(event, 'nickname', 'length', 0),
                  type: 'text'
                }}
              />
              <CustomReactSelect
                label='Responsible Staff Member'
                options={staff_list_select_options}
                value={this.state.responsible_staff_member}
                onChange={this.handleCustomReactSelectChange('responsible_staff_member')}
                isClearable={true}
              />
              <br />
              <p>
                Static Link: &nbsp;
                <strong>{this.state.portal_registration}</strong> &nbsp; [
                <a
                  onClick={() => {
                    navigator.clipboard.writeText(this.state.portal_registration);
                  }}
                >
                  Copy to Clipboard
                </a>
                ]
              </p>
            </form>
          </GridItem>
          <GridItem xs={12} sm={6} />
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Button
              disabled={
                !this.props.app_state.current_staff_security
                  ? 'false'
                  : this.props.app_state.current_staff_security.staff_team_security[this.props.team_id] < 8 || false
              }
              color='rose'
              onClick={() => this.handleSubmit()}
              className={classes.registerButton}
            >
              Update
            </Button>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
  render_contact_details() {
    const {classes} = this.props;

    return (
      <React.Fragment>
        <form>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText='Address Line 1'
                id='address_line_1'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: this.state.address_line_1,
                  onChange: event => this.change(event, 'address_line_1', 'length', 0),
                  type: 'text'
                }}
              />
              <CustomInput
                labelText='Address Line 2'
                id='address_line_2'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: this.state.address_line_2,
                  onChange: event => this.change(event, 'address_line_2', 'length', 0),
                  type: 'text'
                }}
              />
              <CustomInput
                labelText='Suburb'
                id='address_suburb'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: this.state.address_suburb,
                  onChange: event => this.change(event, 'address_suburb', 'length', 0),
                  type: 'text'
                }}
              />
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText='State'
                    id='address_state'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.address_state,
                      onChange: event => this.change(event, 'address_state', 'length', 0),
                      type: 'text'
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText='Postcode'
                    id='address_postcode'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.address_postcode,
                      onChange: event => this.change(event, 'address_postcode', 'length', 0),
                      type: 'text'
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText='Email'
                id='system_email'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: this.state.system_email,
                  onChange: event => this.change(event, 'system_email', 'length', 0),
                  type: 'text'
                }}
              />
              <CustomInput
                labelText='Telephone'
                id='telephone'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: this.state.telephone,
                  onChange: event => this.change(event, 'telephone', 'length', 0),
                  type: 'text'
                }}
              />
            </GridItem>
          </GridContainer>
        </form>
        <GridContainer>
          <GridItem xs={12}>
            <Button
              disabled={
                !this.props.app_state.current_staff_security
                  ? 'false'
                  : this.props.app_state.current_staff_security.staff_team_security[this.props.team_id] < 8 || false
              }
              color='rose'
              onClick={() => this.handleSubmit()}
              className={classes.registerButton}
            >
              Update
            </Button>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }

  buildTableData() {
    const beneficiaries = this.state.beneficiaries_list;
    try {
      if (beneficiaries.length > 0) {
        return this.state.beneficiaries_list.map((prop, key) => {
          return {
            id: prop.id ? prop.id : null,
            account_currency_iso_alpha_3: prop.account_currency_iso_alpha_3 ? prop.account_currency_iso_alpha_3 : null,
            nickname: prop.nickname ? prop.nickname : null
          };
        });
      }
    } catch (error) {
      console.error('Error has occured', error);
      return [];
    }
  }

  toggleEdit = async ({id, ...record}, active) => {
    await API.put('transfers', `/update/purpose/in-database/${id}`, {
      body: {
        ...record,
        active: active
      }
    });
    this.getPurposeOfPayment();
  };

  buildPurposeOfPaymentTableData() {
    const purposeOfPayments = this.state.purposeOfPayments;
    try {
      if (purposeOfPayments.length > 0) {
        return this.state.purposeOfPayments.map((prop, key) => {
          return {
            id: prop.id ? prop.id : null,
            active: prop.active ? prop.active : null,
            description: prop.description ? prop.description : null,
            actions: (
              <Switch
                checked={prop.active}
                onChange={() => this.toggleEdit(prop, prop.active ? !prop.active : true)}
                value='edit_mode'
                // classes={{
                //   switchBase: classes.switchBase,
                //   checked: classes.switchChecked,
                // }}
              />
            )
          };
        });
      }
    } catch (error) {
      console.error('Error has occured', error);
      return [];
    }
  }

  renderBeneficiariesDetails() {
    const {classes} = this.props;
    return (
      <ReactTable
        data={this.buildTableData()}
        filterable
        columns={[
          {
            Header: 'Id',
            accessor: 'id',
            Cell: c => (
              <React.Fragment>
                <a
                  className={classes.badge}
                  onClick={() => this.props.history.push(`/beneficiaries/edit/${c.original.id}`)}
                >
                  {c.original.id}
                </a>
              </React.Fragment>
            ),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['id']}),
            filterAll: true,
            maxWidth: 50
          },
          {
            Header: 'Beneficiary Name',
            accessor: 'nickname',
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['nickname']}),
            filterAll: true,
            maxWidth: 450
          },
          {
            Header: 'Currency',
            accessor: 'account_currency_iso_alpha_3',
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['account_currency_iso_alpha_3']}),
            filterAll: true,
            maxWidth: 150
          }
        ]}
        defaultSorted={[
          {
            id: 'account_currency_iso_alpha_3',
            desc: true
          },
          {
            id: 'nickname',
            desc: false
          }
        ]}
        defaultPageSize={10}
        showPageSizeOptions={true}
        showPaginationBottom
        className='-highlight'
      />
    );
  }

  render_invoicing_details() {
    const {classes} = this.props;

    const division_list_select_options = this.state.division_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));

    //list options for #972
    const staff_list_select_options = this.state.staff_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));

    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText='Company / Business Name'
              id='business_name'
              formControlProps={{fullWidth: true}}
              inputProps={{
                value: this.state.business_name,
                onChange: event => this.change(event, 'business_name', 'length', 0),
                type: 'text'
              }}
            />
            <CustomInput
              labelText='ABN'
              id='abn'
              formControlProps={{fullWidth: true}}
              inputProps={{
                value: this.state.abn,
                onChange: event => this.change(event, 'abn', 'length', 0),
                type: 'text'
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <Card
                  style={{backgroundColor: this.state.invoice_style === 1 ? '#00acc1' : ''}}
                  onClick={() => this.select_invoice_style(1)}
                >
                  <img
                    style={{
                      width: '100%',
                      padding: 10
                    }}
                    src={sample_invoice}
                  />
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Card
                  style={{backgroundColor: this.state.invoice_style === 2 ? '#00acc1' : ''}}
                  onClick={() => this.select_invoice_style(2)}
                >
                  <img
                    style={{
                      width: '100%',
                      padding: 10
                    }}
                    src={sample_invoice}
                  />
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Card
                  style={{backgroundColor: this.state.invoice_style === 3 ? '#00acc1' : ''}}
                  onClick={() => this.select_invoice_style(3)}
                >
                  <img
                    style={{
                      width: '100%',
                      padding: 10
                    }}
                    src={sample_invoice}
                  />
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Button
              disabled={
                !this.props.app_state.current_staff_security
                  ? 'false'
                  : this.props.app_state.current_staff_security.staff_team_security[this.props.team_id] < 8 || false
              }
              color='rose'
              onClick={() => this.handleSubmit()}
              className={classes.registerButton}
            >
              Update
            </Button>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }

  render_backoffice_details() {
    const {classes} = this.props;

    if (!this.props.app_state.current_staff.backofficeStaff) {
      return <span>Insufficient Security</span>
    }

    const select_currency_select_options = this.state.currencies_list_priority.map(item => ({
      value: item.id,
      label: (
        <React.Fragment>
          <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
          &nbsp;
          {item.iso_alpha_3}
        </React.Fragment>
      )
    }));

    const select_client_select_options = this.state.client_list.map(item => ({
      value: item.id,
      label: (
        <React.Fragment>
          <span>
            {item.nickname}, [{item.id}]
          </span>
        </React.Fragment>
      )
    }));

    return (
      <React.Fragment>
        <form>
          <GridContainer className={classes.paddingTwo}>
            <GridItem xs={12} sm={6}>
              <CustomReactSelect
                label='Default From Currency'
                options={select_currency_select_options}
                value={this.state.defaultFromCurrency}
                onChange={this.handleCustomReactSelectChange('defaultFromCurrency')}
                isClearable={true}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomReactSelect
                label='Team Client'
                options={select_client_select_options}
                value={this.state.teamClientId}
                onChange={this.handleCustomReactSelectChange('teamClientId')}
                isClearable={true}
              />
              <br />
              <a href={`/clients/edit/${this.state.teamClientId}`}>Click here to view client</a>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.paddingTwo}>
            <GridItem xs={12} sm={6}>
              <CustomReactSelect
                label='Default To Currency'
                options={select_currency_select_options}
                value={this.state.defaultToCurrency}
                onChange={this.handleCustomReactSelectChange('defaultToCurrency')}
                isClearable={true}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                success={this.state.default_rate === 'success'}
                error={this.state.default_rate === 'error'}
                labelText='Default Rate'
                id='default_rate'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: this.state.default_rate,
                  onChange: event => this.change(event, 'default_rate', 'length', 0),
                  type: 'text'
                }}
              />
              <CustomInput
                success={this.state.pendingTransferMaxExpiryState === 'success'}
                error={this.state.pendingTransferMaxExpiryState === 'error'}
                labelText='Pending Transfer Max Expiry (days)'
                id='pendingTransferMaxExpiry'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: this.state.pendingTransferMaxExpiry,
                  onChange: event => {
                    this.change(event, 'pendingTransferMaxExpiry', 'positiveNumber', 0);
                  },
                  type: 'number'
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <CustomInput
                success={this.state.ref_code === 'success'}
                error={this.state.ref_code === 'error'}
                labelText='Team Ref Code'
                id='ref_code'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: this.state.ref_code,
                  onChange: event => this.change(event, 'ref_code', 'length', 0),
                  type: 'text'
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={3} sm={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_transfer_legacy}
                      onChange={this.handleCustomReactCheckBoxChange('brand_transfer_legacy')}
                      name='brand_transfer_legacy'
                    />
                  }
                  label='Brand Transfer Legacy'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_sidebar_searchtransfers}
                      onChange={this.handleCustomReactCheckBoxChange('brand_sidebar_searchtransfers')}
                      name='brand_sidebar_searchtransfers'
                    />
                  }
                  label='Brand Sidebar Search Transfer'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_sidebar_identification_list}
                      onChange={this.handleCustomReactCheckBoxChange('brand_sidebar_identification_list')}
                      name='brand_sidebar_identification_list'
                    />
                  }
                  label='Brand Sidebar Identification List'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_sidebar_identification_all}
                      onChange={this.handleCustomReactCheckBoxChange('brand_sidebar_identification_all')}
                      name='brand_sidebar_identification_all'
                    />
                  }
                  label='Brand Sidebar Identification All'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_sidebar_new_transfer}
                      onChange={this.handleCustomReactCheckBoxChange('brand_sidebar_new_transfer')}
                      name='brand_sidebar_new_transfer'
                    />
                  }
                  label='Brand Sidebar New Transfer'
                />
              </FormGroup>
            </GridItem>
            <GridItem xs={3} sm={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_sidebar_multipay}
                      onChange={this.handleCustomReactCheckBoxChange('brand_sidebar_multipay')}
                      name='brand_sidebar_multipay'
                    />
                  }
                  label='Brand Sidebar Multipay'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_sidebar_currencytools}
                      onChange={this.handleCustomReactCheckBoxChange('brand_sidebar_currencytools')}
                      name='brand_sidebar_currencytools'
                    />
                  }
                  label='Brand Sidebar Currencytools'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_sidebar_invoicelist}
                      onChange={this.handleCustomReactCheckBoxChange('brand_sidebar_invoicelist')}
                      name='brand_sidebar_invoicelist'
                    />
                  }
                  label='Brand Sidebar Invoicelist'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_sidebar_forwards}
                      onChange={this.handleCustomReactCheckBoxChange('brand_sidebar_forwards')}
                      name='brand_sidebar_forwards'
                    />
                  }
                  label='Brand Sidebar forwards'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_transfer_payeeFirst}
                      onChange={this.handleCustomReactCheckBoxChange('brand_transfer_payeeFirst')}
                      name='brand_transfer_payeeFirst'
                    />
                  }
                  label='Brand - Transfer Show Payee First'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_ledger}
                      onChange={this.handleCustomReactCheckBoxChange('brand_ledger')}
                      name='brand_ledger'
                    />
                  }
                  label='Brand Ledger'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_paymentRequests}
                      onChange={this.handleCustomReactCheckBoxChange('brand_paymentRequests')}
                      name='brand_paymentRequests'
                    />
                  }
                  label='Brand Sidebar Payment Request List'
                />
              </FormGroup>
            </GridItem>
            <GridItem xs={3} sm={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_kyc_hideMaritalStatus}
                      onChange={this.handleCustomReactCheckBoxChange('brand_kyc_hideMaritalStatus')}
                      name='brand_kyc_hideMaritalStatus'
                    />
                  }
                  label='Brand - Hide Marital Status'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_kyc_hidePlaceOfBirth}
                      onChange={this.handleCustomReactCheckBoxChange('brand_kyc_hidePlaceOfBirth')}
                      name='brand_kyc_hidePlaceOfBirth'
                    />
                  }
                  label='Brand - Hide Place Of Birth'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_splitpay}
                      onChange={this.handleCustomReactCheckBoxChange('brand_splitpay')}
                      name='brand_splitpay'
                    />
                  }
                  label='Brand - SplitPay'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_multipay}
                      onChange={this.handleCustomReactCheckBoxChange('brand_multipay')}
                      name='brand_multipay'
                    />
                  }
                  label='Brand - MultiPay'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.brand_create_transfer}
                      onChange={this.handleCustomReactCheckBoxChange('brand_create_transfer')}
                      name='brand_create_transfer'
                    />
                  }
                  label='Brand - Create Transfer'
                />
              </FormGroup>
            </GridItem>
          </GridContainer>
        </form>
        <GridContainer>
          <GridItem xs={12}>
            <Button
              disabled={this.props.app_state.current_staff && !this.props.app_state.current_staff.backofficeStaff}
              color='rose'
              onClick={() => this.handleSubmit()}
              className={classes.registerButton}
            >
              Update
            </Button>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }

  buildTableData = () => {
    const holdingAccountListLength = this.state.holdingAmountList.length;
    if (holdingAccountListLength > 0) {
      const accounts = [];
      this.state.holdingAmountList.map((prop, key) => {
        if (prop.team_id) {
          accounts.push({
            id: prop.id,
            client_id: prop.client_id,
            client_nickname: prop.client_nickname,
            currency_id: prop.currency_id,
            currencies_short_name: prop.currencies_short_name,
            currencies_full_name: prop.currencies_full_name,
            balance: prop.balance,
            teams_nickname: prop.team_nickname,
            actions: (
              <div className='actions-right'>
                <Button
                  onClick={() => {
                    this.props.history.push(`/holding-accounts/view/${prop.id}`);
                  }}
                  color='warning'
                  className='edit'
                >
                  View
                </Button>
              </div>
            )
          });
        }
      });
      return accounts;
    }
  };

  renderHoldingAccountsDetails() {
    return (
      <React.Fragment>
        <ReactTable
          data={this.buildTableData()}
          filterable
          columns={[
            {
              Header: 'Team ',
              accessor: 'teams_nickname',
              maxWidth: 200,
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['teams_nickname']}),
              filterAll: true,
              Cell: c => <React.Fragment>{c.value}</React.Fragment>
            },
            {
              Header: 'Currency ',
              accessor: 'currencies_short_name',
              maxWidth: 100,
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['currencies_short_name']}),
              filterAll: true,
              Cell: c => (
                <React.Fragment>
                  <div style={{marginLeft: 6}} className={`currency-flag currency-flag-${c.value.toLowerCase()}`} />{' '}
                  {c.value}
                </React.Fragment>
              )
            },
            {
              Header: '',
              accessor: 'currencies_full_name',
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['currencies_full_name']}),
              filterAll: true
            },
            {
              Header: 'Balance ',
              accessor: 'balance',
              maxWidth: 100,
              Cell: c => {
                if (c.value) {
                  return (
                    <React.Fragment>
                      {new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: c.original.currencies_short_name
                      }).format(c.value)}
                    </React.Fragment>
                  );
                }
              }
            },
            {
              Header: 'Actions',
              accessor: 'actions',
              sortable: false,
              filterable: false,
              maxWidth: 100
            }
          ]}
          defaultSorted={[
            {
              id: 'client_name',
              desc: false
            },
            {
              id: 'document_category',
              desc: false
            }
          ]}
          defaultPageSize={10}
          showPaginationBottom
          className='-highlight'
        />
      </React.Fragment>
    );
  }

  renderPurposeOfPaymentDetails() {
    const {classes} = this.props;

    return (
      <React.Fragment>
        <Button
          style={{
            width: 100,
            textAlign: 'right'
          }}
          color='primary'
          size='sm'
          onClick={() => this.togglePurposeOfPayementModal(true)}
        >
          Create New
        </Button>
        <ReactTable
          data={this.buildPurposeOfPaymentTableData()}
          filterable
          columns={[
            {
              Header: 'Id',
              accessor: 'id',
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['id']}),
              filterAll: true,
              maxWidth: 100
            },
            // {
            //   Header: 'Active',
            //   accessor: 'active',
            //   filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['active']}),
            //   filterAll: true,
            //   maxWidth: 450
            // },
            {
              Header: 'Description',
              accessor: 'description',
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['description']}),
              filterAll: true
              // maxWidth: 150
            },
            {
              Header: 'Active',
              accessor: 'actions',
              sortable: false,
              filterable: false
            }
          ]}
          defaultSorted={[
            {
              id: 'account_currency_iso_alpha_3',
              desc: true
            },
            {
              id: 'nickname',
              desc: false
            }
          ]}
          defaultPageSize={10}
          showPageSizeOptions={true}
          showPaginationBottom
          className='-highlight'
        />
      </React.Fragment>
    );
  }
  buildFeeTableData() {
    const transferFee = this.state.transferFee;
    try {
      return transferFee;
    } catch (error) {
      console.error('Error has occured', error);
      return [];
    }
  }
  toggleModal = bool => {
    this.setState({addBeneficiaryModalOpen: bool});
  };

  handleCustomReactSelectChange = stateName => value => {
    this.setState({[stateName]: value.value});
  };

  handleChangeState = (stateName, value) => {
    this.setState({[stateName]: value});
  };

  renderTeamsFees = () => {
    const onHandleSubmit = () => {
      API.post('teams', '/create/team_transfer', {
        body: {
          teamId: this.props.team_id,
          transferAmount: this.state.amount,
          currencyId: this.state.currencyId,
          feeAmount: this.state.amount,
          feeDescription: this.state.description
        }
      })
        .then(response => {
          this.setState({addBeneficiaryModalOpen: false});
          API.get('teams', `/get/team_transfer/${this.props.team_id}`)
            .then(response => {
              this.setState({transferFee: response});
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
        });
    };
    const {classes} = this.props;
    // console.info('/////', this.state.currencies_list_priority)
    const currencies_list_select_options = this.state.currencies_list_priority.map(item => ({
      value: item.id,
      label: item.full_name
    }));

    return (
      <React.Fragment>
        <Button onClick={() => this.toggleModal(true)}> Create new Record</Button>
        <ReactTable
          data={this.buildFeeTableData()}
          filterable
          columns={[
            {
              Header: 'Currency ',
              accessor: 'currencies_short_name',
              maxWidth: 100,
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['currencies_short_name']}),
              filterAll: true,
              Cell: c => (
                <React.Fragment>
                  <div style={{marginLeft: 6}} className={`currency-flag currency-flag-${c.value.toLowerCase()}`} />{' '}
                  {c.value}
                </React.Fragment>
              )
            },
            {
              Header: 'Amount',
              accessor: 'transferAmount',
              maxWidth: 100,
              Cell: c => {
                if (c.value) {
                  return (
                    <React.Fragment>
                      {new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: c.original.currencies_short_name
                      }).format(c.value)}
                    </React.Fragment>
                  );
                }
              }
            },
            {
              Header: 'Fee',
              accessor: 'feeAmount',
              maxWidth: 100,
              Cell: c => {
                if (c.value) {
                  return (
                    <React.Fragment>
                      {new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: c.original.currencies_short_name
                      }).format(c.value)}
                    </React.Fragment>
                  );
                }
              }
            },
            {
              Header: 'Description',
              accessor: 'feeDescription',
              maxWidth: 200
            }
          ]}
          defaultSorted={[
            {
              id: 'currencies_short_name',
              desc: false
            }
          ]}
          defaultPageSize={10}
          showPaginationBottom
          className='-highlight'
        />

        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.addBeneficiaryModalOpen}
          keepMounted
          onClose={() => this.toggleModal(false)}
          aria-labelledby='modal-slide-title'
          aria-describedby='modal-slide-description'
        >
          <DialogTitle id='classic-modal-slide-title' disableTypography className={classes.modalHeader}>
            <h4 className={classes.modalTitle}>Add New Record</h4>
          </DialogTitle>
          <DialogContent id='modal-slide-description' className={classes.modalBody}>
            <GridContainer>
              <GridItem xs={12}>
                <CustomReactSelect
                  label='Select Currency'
                  options={currencies_list_select_options}
                  value={this.state.currencyId}
                  onChange={this.handleCustomReactSelectChange('currencyId')}
                  isClearable={false}
                  // isDisabled={this.state.prefill_beneficiary}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomTextField
                  id='amount'
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses
                  }}
                  inputProps={{
                    variant: 'outlined',
                    label: 'Amount',
                    style: {backgroundColor: 'white'},
                    value: this.state.amount,
                    onChange: event => this.handleChangeState('amount', event.target.value),
                    type: 'number',
                    startAdornment: (
                      <InputAdornment position='start' className={classes.inputAdornment}>
                        <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                      </InputAdornment>
                    )
                  }}
                />
              </GridItem>

              <GridItem xs={12}>
                <CustomTextField
                  id='fee'
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses
                  }}
                  inputProps={{
                    variant: 'outlined',
                    label: 'Fee',
                    style: {backgroundColor: 'white'},
                    value: this.state.fee,
                    onChange: event => this.handleChangeState('fee', event.target.value),
                    type: 'number',
                    startAdornment: (
                      <InputAdornment position='start' className={classes.inputAdornment}>
                        <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                      </InputAdornment>
                    )
                  }}
                />
              </GridItem>

              <GridItem xs={12}>
                <CustomTextField
                  id='description'
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses
                  }}
                  inputProps={{
                    variant: 'outlined',
                    label: 'Description',
                    style: {backgroundColor: 'white'},
                    value: this.state.description,
                    onChange: event => this.handleChangeState('description', event.target.value),
                    type: 'text',
                    startAdornment: (
                      <InputAdornment position='start' className={classes.inputAdornment}>
                        <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                      </InputAdornment>
                    )
                  }}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
            <Button onClick={() => this.toggleModal(false)}>Cancel</Button>
            <Button color='primary' onClick={() => onHandleSubmit()}>
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  togglePurposeOfPayementModal = bool => {
    this.setState({createPurposeOfPaymentModal: bool});
  };

  createPurposeOfPayment = async () => {
    this.setState({loading: true});

    if (this.state.pOpDescription && this.state.pOp_active) {
      await API.post('transfers', '/create/purpose/in-database', {
        body: {
          active: this.state.pOp_active,
          description: this.state.pOpDescription,
          document_upload: null,
          division_Id: null,
          teamId: this.props.team_id
        }
      });
    }
    this.getPurposeOfPayment();
    this.setState({
      loading: false,
      createPurposeOfPaymentModal: false
    });
  };

  onSavePaymentRequest = (values) => {
    console.log(values);
    return API.put('teams', `/update/id/${this.props.team_id}`, {body: values});
  }
  renderPaymentRequests = () => {
    return (
        <>
          <Form name={'payment-request-form'} ref={this.paymentRequestRef} onFinish={this.onSavePaymentRequest}>
              <Form.Item name="brand_pr_paymentRequestHeading" label={'Payment Request Heading'}>
                <Input />
              </Form.Item>
            <Form.Item name="brand_pr_showGst" label={'Show GST'} valuePropName={'checked'}>
              <AntSwitch></AntSwitch>
            </Form.Item>
            <Form.Item>
              <AntButton htmlType={'submit'} type={'primary'} size={'large'}>Save</AntButton>
            </Form.Item>
          </Form>
        </>
    )
  }

  render() {
    const {classes} = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <NavPills
              color='info'
              tabs={[
                {
                  tabButton: 'Details',
                  tabContent: <div style={{padding: 20}}>{this.render_team_details()}</div>
                },
                {
                  tabButton: 'Contact',
                  tabContent: <div style={{padding: 20}}>{this.render_contact_details()}</div>
                },
                // {
                //   tabButton: 'Beneficiaries',
                //   tabContent: <div style={{padding: 20}}>{this.renderBeneficiariesDetails()}</div>
                // },
                // {
                //   tabButton: 'Invoicing',
                //   tabContent: <div style={{padding: 20}}>{this.render_invoicing_details()}</div>
                // },
                {
                  tabButton: 'BackOffice',
                  tabContent: <div style={{padding: 20}}>{this.render_backoffice_details()}</div>
                },
                // {
                //   tabButton: 'Holding Accounts',
                //   tabContent: <div style={{padding: 20}}>{this.renderHoldingAccountsDetails()}</div>
                // },
                {
                  tabButton: 'Purpose of Payment',
                  tabContent: <div style={{padding: 20}}>{this.renderPurposeOfPaymentDetails()}</div>
                },
                {
                  tabButton: 'Fees',
                  tabContent: <div style={{padding: 20}}>{this.renderTeamsFees()}</div>
                },
                {
                  tabButton: 'Payment Requests',
                  tabContent: <div style={{padding: 20}}>{this.renderPaymentRequests()}</div>
                }
              ]}
            />
          </GridItem>
        </GridContainer>

        <Dialog
          open={this.state.createPurposeOfPaymentModal}
          onClose={() => this.togglePurposeOfPayementModal(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Create New Record</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <CustomReactSelect
                label='Active'
                options={[
                  {
                    value: '1',
                    label: 'Active'
                  },
                  {
                    value: '0',
                    label: 'In Active'
                  }
                ]}
                value={this.state.pOp_active}
                onChange={this.handleCustomReactSelectChange('pOp_active')}
                isClearable={true}
              />
              <CustomTextField
                id='Description'
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses
                }}
                inputProps={{
                  value: this.state.pOpDescription,
                  variant: 'outlined',
                  label: 'Description',
                  style: {
                    backgroundColor: 'white',
                    padding: '0px'
                  },
                  onChange: event => {
                    this.setState({pOpDescription: event.target.value});
                  },
                  type: 'text'
                  // startAdornment: (
                  //   <InputAdornment position="start" className={classes.inputAdornment}>
                  //     <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                  //   </InputAdornment>
                  // )
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.togglePurposeOfPayementModal(false)} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => this.createPurposeOfPayment()}
              color='danger'
              autoFocus
              disabled={this.state.loading}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    teams: state.teams
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestEntityList: () => {
      dispatch(requestEntityList());
    },
    fetchEntityListNotDeleted: () => {
      dispatch(fetchEntityListNotDeleted());
    },
    entitiesListIsDirty: value => {
      dispatch(entitiesListIsDirty(value));
    },
    setEntityDetailId: id => {
      dispatch(setEntityDetailId(id));
    },
    selectEntityUi: value => {
      dispatch(selectEntityUi(value));
    }
  };
};

const TeamEditContainer = connect(mapStateToProps, mapDispatchToProps)(TeamEdit);

export default withRouter(withStyles(staffEditStyles)(TeamEditContainer));
