import {
  ENTITYBANKS_FETCH_ENTITYBANKS_LIST_REQUEST,
  ENTITYBANKS_FETCH_ENTITYBANKS_LIST_SUCCESS,
  // ENTITYBANKS_FETCH_ENTITYBANKS_LIST_FAILURE,
  ENTITYBANKS_CREATE_NEW_SHOW_UI,
  ENTITYBANKS_CREATE_NEW_START,
  ENTITYBANKS_CREATE_NEW_CANCEL,
  ENTITYBANKS_CREATE_NEW_COMPLETE,
  ENTITYBANKS_SELECT_UI,
  ENTITYBANKS_ENTITYBANKS_LIST_IS_DIRTY,
  ENTITYBANKS_ENTITYBANKS_EDIT
} from '../actions/entitybanks.js';

const initialState = {
  is_fetching: false,
  did_invalidate: false,
  entitybanks_list_is_dirty: false,
  entitybanks: [],
  entitybanks_current_ui: 'list',
  entitybanks_create_new_in_process: false,
  id: ''
};

function Entitybanks(state = initialState, action) {
  switch (action.type) {
    case ENTITYBANKS_FETCH_ENTITYBANKS_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
      });

    case ENTITYBANKS_FETCH_ENTITYBANKS_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        entitybanks: action.entitybanks,
        lastUpdated: action.receivedAt
      });

    case ENTITYBANKS_CREATE_NEW_SHOW_UI:
      return Object.assign({}, state, {
        entitybanks_current_ui: 'list'
      });
    //
    case ENTITYBANKS_CREATE_NEW_START:
      return Object.assign({}, state, {
        entitybanks_create_new_in_process: true
      });

    case ENTITYBANKS_CREATE_NEW_CANCEL:
      return Object.assign({}, state, {
        entitybanks_create_new_in_process: false,
        entitybanks_current_ui: 'list'
      });

    case ENTITYBANKS_CREATE_NEW_COMPLETE:
      return Object.assign({}, state, {
        entitybanks_create_new_in_process: false,
        entitybanks_current_ui: 'list',
        entitybanks_list_is_dirty: true
      });

    case ENTITYBANKS_SELECT_UI:
      return Object.assign({}, state, {
        entitybanks_current_ui: action.value
      });
    case ENTITYBANKS_ENTITYBANKS_LIST_IS_DIRTY:
      return Object.assign({}, state, {
        entitybanks_list_is_dirty: action.value
      });
    case ENTITYBANKS_ENTITYBANKS_EDIT:
      return Object.assign({}, state, {
        entitybanks_create_new_in_process: false,
        entitybanks_current_ui: 'edit',
        id: action.value
      });
    default:
      return state;
  }
}

export default Entitybanks;
