import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from './AppContainer';
import {applyMiddleware, createStore, compose} from 'redux';
import {Provider} from 'react-redux';
import rootReducer from './redux/reducers/index.js';
import {createLogger} from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import 'assets/scss/material-dashboard-pro-react.css?v=1.4.0';
import 'react-dates/lib/css/_datepicker.css';

const logger = createLogger({collapsed: true});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware, logger)));

ReactDOM.render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
  document.getElementById('root')
);
