import {connect} from 'react-redux';
import CountriesTable from './CountriesTable';
import {
  requestEntityList,
  fetchCountryListNotDeleted,
  entitiesListIsDirty,
  editCountry
} from '../../redux/actions/countries';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    countries: state.countries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestEntityList: () => {
      dispatch(requestEntityList());
    },
    fetchCountryListNotDeleted: () => {
      dispatch(fetchCountryListNotDeleted());
    },
    entitiesListIsDirty: value => {
      dispatch(entitiesListIsDirty(value));
    },
    editCountry: value => {
      dispatch(editCountry(value));
    }
  };
};

const CountriesTableContainer = connect(mapStateToProps, mapDispatchToProps)(CountriesTable);

export default CountriesTableContainer;
