import {API} from 'aws-amplify';

export const NewTransferUtil = {
  getFilteredBeneficiaryList: (data, currency) => {
    const filterer_beneficiaries_list = data;
    if (filterer_beneficiaries_list.length > 0) {
      for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
        if (
          filterer_beneficiaries_list[i].account_currency !==
            currency
        ) {
          filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
        }
      }
    }
    return filterer_beneficiaries_list;
  }
}

export const NewTransferAPI = {
  fetchClients: () => API.get('clients', '/get-list-not-deleted', {}),
  fetchClientsByStaffID: (staffID) => API.get('clients', `/list/staff/${staffID}`, {}),
  saveTransfer: (data) => API.post('transfers', '/create/from_dashboard_multiple', {body: {...data}}),
  fetchBeneficiaryByClientId: (clientId) => API.get('beneficiaries', `/get/by_client_id/${clientId}`, {}),
  fetchTeamPurpose: (teamId) => API.get('transfers', `/get_purpose_by_team/id/${teamId}`, {})
}

export const NewTransferConstants = {

}