import {connect} from 'react-redux';
import EntityTable from './EntityTable';
import {
  requestEntityList,
  fetchEntityListNotDeleted,
  entitiesListIsDirty,
  setEntityDetailId,
  selectEntityUi
} from '../../redux/actions/entities';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    entities: state.entities
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestEntityList: () => {
      dispatch(requestEntityList());
    },
    fetchEntityListNotDeleted: () => {
      dispatch(fetchEntityListNotDeleted());
    },
    entitiesListIsDirty: value => {
      dispatch(entitiesListIsDirty(value));
    },
    setEntityDetailId: id => {
      dispatch(setEntityDetailId(id));
    },
    selectEntityUi: value => {
      dispatch(selectEntityUi(value));
    }

    // fetchStaffList: () => {
    //   dispatch(fetchStaffListNotDeleted())
    // },
    // loadStaffMemberDetails: (aws_cognito_id) => {
    //   // console.log(data)
    //   dispatch(loadStaffMemberDetails(aws_cognito_id))
    // }
  };
};

const EntityTableContainer = connect(mapStateToProps, mapDispatchToProps)(EntityTable);

export default EntityTableContainer;
