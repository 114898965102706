import React from 'react';
// used for making the prop types of this component
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Button from 'components/CustomButtons/Button.jsx';

import defaultImage from 'assets/img/image_placeholder_2.jpg';
import defaultPdf from 'assets/img/preview_pdf.jpg';

// AWS components
import {Storage} from 'aws-amplify';

import {s3Upload} from 'libs/awsLib.js';

import SweetAlert from 'react-bootstrap-sweetalert';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

import Dropzone from 'react-dropzone';

const DocumentUploadStyle = {...sweetAlertStyle};

class DocumentUploadDragDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      // eslint-disable-next-line react/prop-types
      imagePreviewUrl: this.props.placeholder
        ? // eslint-disable-next-line react/prop-types
          this.props.placeholder
        : defaultImage
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleClick() {
    // eslint-disable-next-line react/no-string-refs
    this.refs.fileInput.click();
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleImageChange = async e => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);

    try {
      const attachment = file ? await s3Upload(file) : null;

      console.log(attachment);
      this.setState({
        file_id: attachment,
        isLoaded: true
      });
      this.getBase64(file, (url) => {
        const obj = {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: url
        }
        this.props.updateFunction(attachment, this.props.index, obj);
      });

    } catch (e) {
      alert(e);
    }
  };

  onDrop = async e => {
    console.log(e);
    const reader = new FileReader();
    const file = e[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);

    try {
      const attachment = file ? await s3Upload(file) : null;

      console.log(attachment);
      this.setState({
        file_id: attachment,
        isLoaded: true
      });

      this.getBase64(file, (url) => {
        const obj = {
          uid: `rc-upload-${Date.now()}`,
          name: file.name,
          status: 'done',
          url: url
        }
        // eslint-disable-next-line react/prop-types
        this.props.updateFunction(attachment, this.props.index, obj);
      });
    } catch (e) {
      alert(e);
    }
  };

  handleRemove() {
    //Remove Avatar due to #740
    this.setState({
      file: '', //null, //don't set it to null, it will crash by cannot read type "null" - workaround #835
      // eslint-disable-next-line react/prop-types
      imagePreviewUrl: this.props.placeholder
        ? // eslint-disable-next-line react/prop-types
          this.props.placeholder
        : defaultImage, //this.props.avatar ? defaultAvatar : defaultImage #740 changed
      file_id: '',
      file_url: '',
      isLoaded: false
    });
    // eslint-disable-next-line react/no-string-refs
    this.refs.fileInput.value = null;
    if (this.props.onRemove) {
      this.props.onRemove(this.props.index);
    }
    if (this.props.onRemoveImage) {
      this.props.onRemoveImage(this.state.file);
    }
    // eslint-disable-next-line react/prop-types
  }

  handleSubmit(e) {
    console.log(e);
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }

  /*
   *  Function added because of #740 opens that image up - larger - in a modal window
   *
   *  Author: Tom
   */
  hideAlert() {
    this.setState({alert: null});
  }
  //end of function

  /*
   *  Function added because of #740 opens that image up - larger - in a modal window
   *
   *  Author: Tom
   */
  async openModal() {
    //console.log("== Open modal!! ==");
    // sweetalert Source: http://djorg83.github.io/react-bootstrap-sweetalert/

    let file_id;

    //file_id = this.props.registration.new_registration_data.multi_document[0].document;
    try {
      file_id = this.state.file_id;
    } catch (error) {
      file_id = '';
    }

    let file_url = '';
    if (file_id !== '') {
      file_url = await Storage.get(file_id, {expires: 60});
    }

    try {
      await this.setState({
        file_url: file_url,
        alert: (
          <SweetAlert
            style={{
              display: 'block',
              marginTop: '-100px'
            }}
            title={
              <img
                src={
                  this.state.imagePreviewUrl //default set to imagePreviewUrl
                }
                width='90%'
              />
            }
            confirmBtnText='Close'
            onConfirm={() => this.hideAlert()}
            confirmBtnCssClass={
              // eslint-disable-next-line react/prop-types
              this.props.classes.button + '  ' + this.props.classes.info
            }
          />
        )
      });
    } catch (error) {
      console.log(error);
    }
  }
  //end of function

  /*
   *  Function added because of #816 opens that PDF up - larger - in a modal window later
   *
   *  Author: Tom
   */
  async openModalPDF() {
    console.log('== Open modal PDF!! ==');
    // sweetalert Source: http://djorg83.github.io/react-bootstrap-sweetalert/
    await this.hideAlert();

    let file_id;

    //file_id = this.props.registration.new_registration_data.multi_document[0].document;
    try {
      file_id = this.state.file_id;
    } catch (error) {
      file_id = '';
    }

    let file_url = '';
    if (file_id !== '') {
      file_url = await Storage.get(file_id, {expires: 60});
    }

    this.setState({
      file_url: file_url,
      alert: null
    });

    await window.open(
      this.state.file_id ? this.state.file_url : '../../assets/test/error_page.html' //testing error page
    );
    /*
      <PdfViewer
        file = {
          this.state.file.type == "application/pdf"
          ? file_url //defaultPdf
          : this.state.imagePreviewUrl
        }
      />
    */
  }

  render() {
    const {
      //circle,
      addButtonProps,
      changeButtonProps,
      removeButtonProps
    } = this.props;
    let preview_src = this.state.imagePreviewUrl;
    if (this.state.file) {
      // console.log(this.state.imagePreviewUrl);
      if (this.state.file.type == 'application/pdf') {
        // console.log(this.state.file);
        // console.log(this.state.imagePreviewUrl);
        preview_src = defaultPdf;
      }
    }

    // console.log(this.state);
    // console.log(this.state.file)
    return (
      <React.Fragment>
        <h6>Upload document</h6>
        <section className='container'>
          <div className='dropzone'>
            <Dropzone onDrop={this.onDrop}>
              {({getRootProps, getInputProps}) => (
                <section
                  style={{
                    width: '100%',
                    backgroundColor: '#e6e6e6',
                    border: '1px dotted #999999',
                    padding: 10,
                    textAlign: 'center',
                    display: !(this.state.file_id === '' || this.state.file_id == null) ? 'none' : 'block'
                  }}
                >
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p
                      style={{
                        color: '#444',
                        minHeight: 60
                      }}
                    >
                      Drop image or PDF file HERE
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </section>

        <div className='fileinput text-center'>
          <input
            type='file'
            onChange={this.handleImageChange}
            // eslint-disable-next-line react/no-string-refs
            ref='fileInput'
          />
          {/* remove Circle and Avatar due to #740 */}

          {/*<p>{(!this.props.identification.new_identification_record_data || !this.props.identification.new_identification_record_data.file_id) && <span>No file has been loaded</span>}</p>*/}
          {/*<p>{(this.props.identification.new_identification_record_data && this.props.identification.new_identification_record_data.file_id) && <span>File has now loaded</span>}</p>*/}
          <div>
            {this.state.file_id && this.state.file !== '' && this.state.file.type !== 'application/pdf' ? (
              <React.Fragment>
                <div
                  style={{
                    maxHeight: 200,
                    maxWidth: 400
                  }}
                  //onClick={() => this.openModal()}
                  className={'thumbnail'}
                >
                  <img
                    src={preview_src}
                    alt='...'
                    //onClick={() => this.openModal()}
                  />
                </div>
                <br />
                <Button
                  disabled={!this.state.isLoaded}
                  color='primary'
                  onClick={() => this.openModal()} //View Image
                >
                  View file
                </Button>
              </React.Fragment>
            ) : (
              //start solution of #816
              <React.Fragment>
                <div
                  style={{
                    maxHeight: 600,
                    maxWidth: 800
                  }}
                  //onClick={() => this.openModal()}
                  className={'thumbnail'}
                >
                  <img
                    src={preview_src}
                    alt='...'
                    //onClick={() => this.openModalPDF()}
                  />
                </div>
                <br />
                {this.state.file !== '' && (
                  <Button
                    disabled={!this.state.isLoaded}
                    color='primary'
                    onClick={() => this.openModalPDF()} //View PDF
                  >
                    View file
                  </Button>
                )}
              </React.Fragment>
            )}
            {this.state.file === '' ? (
              <Button {...addButtonProps} onClick={() => this.handleClick()}>
                Upload Document
              </Button>
            ) : (
              <span>
                {this.props.change && (
                  <React.Fragment>
                    <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                      Change
                    </Button>
                    <br />
                  </React.Fragment>
                )}
                {

                  !(this.state.file_id === '' || this.state.file_id == null) ? <Button {...removeButtonProps} onClick={() => this.handleRemove()}>
                    <i className='fas fa-times' /> Remove
                  </Button> : ''
                }
              </span>
            )}
          </div>
          {this.state.alert}
        </div>
      </React.Fragment>
    );
  }
}

DocumentUploadDragDrop.propTypes = {
  avatar: PropTypes.bool,
  change: PropTypes.bool, // allows user to change image
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default withStyles(DocumentUploadStyle)(DocumentUploadDragDrop);
