import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import ListTable from '../../../../components/ListTable/ListTable'
import { Table, Tag } from 'antd'

const ForwardDetailDrawDowns = ({intl, forwardDrawDown, getColumnSearchProps, loading = false}) => {

    const [componentState, setComponentState] = useState({columns: [], data: forwardDrawDown});

    useEffect(() => {
        setComponentState(prev => ({data: forwardDrawDown, columns: prev.columns}));
    }, [forwardDrawDown])

    const buildColumns = () => {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                sorter: { compare: (a,b) => a.id - b.id },
                ...getColumnSearchProps({
                    dataIndex: 'id',
                    label: 'id',
                    render: (text, record) => {
                        return (
                            <>
                                <Tag color={'success'}>ID: { record.id }</Tag>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Status',
                dataIndex: 'status_nickname',
                key: 'status_nickname'
            },
            {
                title: 'Beneficiary Name',
                dataIndex: 'beneficiaries_nickname',
                key: 'beneficiaries_nickname',
                sorter: { compare: (a,b) => a.beneficiaries_nickname - b.beneficiaries_nickname }
            },
            {
                title: 'Amount From',
                dataIndex: 'amount_from',
                key: 'amount_from',
                ...getColumnSearchProps({
                    dataIndex: 'amount_from',
                    label: 'amount_from',
                    render: (text, record) => {
                        return (
                            <>
                                <div className={`currency-flag currency-flag-${record.currency_from.toLowerCase()}`} />{' '}
                                {record.currency_from}{' '}
                                {intl.formatNumber(record.amount_from, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </>
                        );
                    }
                })
            },
            {
                title: 'Amount To',
                dataIndex: 'to',
                key: 'amount_to',
                ...getColumnSearchProps({
                    dataIndex: 'amount_to',
                    label: 'amount_to',
                    render: (text, record) => {
                        return (
                            <>
                                <div className={`currency-flag currency-flag-${record.currency_to.toLowerCase()}`} />{' '}
                                {record.currency_to}{' '}
                                {intl.formatNumber(record.amount_to, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </>
                        );
                    }
                })
            },
            {
                title: 'Rate',
                dataIndex: 'client_rate',
                key: 'client_rate'
            },
            {
                title: 'Settlement Date',
                dataIndex: 'settlement_date',
                key: 'settlement_date',
                ...getColumnSearchProps({
                    dataIndex: 'settlement_date',
                    label: 'settlement_date',
                    filterInputType: 'DATE',
                    render: (text, record) => {
                        return (
                            <>{text}</>
                        )
                    }
                })
            },
            {
                title: 'Transaction Date',
                dataIndex: 'transaction_datetime',
                key: 'transaction_datetime',
                ...getColumnSearchProps({
                    dataIndex: 'transaction_datetime',
                    label: 'transaction_datetime',
                    filterInputType: 'DATE',
                    render: (text, record) => {
                        return (
                            <>{text}</>
                        )
                    }
                })
            },
        ];
        setComponentState(prev => ({...prev, columns: columns}));
    }

    useEffect(() => {
        buildColumns();
    }, [0]);

    return <>
        <Table
            dataSource={componentState.data}
            columns={componentState.columns}
            loading={loading}
            className='-highlight'
        />
    </>
}

export default injectIntl(ListTable(ForwardDetailDrawDowns));