import {connect} from 'react-redux';
import CountriesEdit from './CountriesEdit';
import //loadStaffMemberDetailsRequest,
//loadStaffMemberDetailsSuccess
'../../redux/actions/countries';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    countries: state.countries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // loadStaffMemberDetailsRequest: () => {
    //   // console.log(data)
    //   dispatch(loadStaffMemberDetailsRequest())
    // },
    // loadStaffMemberDetailsSuccess: () => {
    //   // console.log(data)
    //   dispatch(loadStaffMemberDetailsSuccess())
    // }
  };
};

const CountriesEditContainer = connect(mapStateToProps, mapDispatchToProps)(CountriesEdit);

export default CountriesEditContainer;
