import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import {API} from 'aws-amplify';

import {FormattedMessage} from 'react-intl';
import {TextareaAutosize} from '@material-ui/core';
import Button from 'components/CustomButtons/Button.jsx';
import {message,Form, Input} from 'antd';
import moment from 'moment';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';

import NavPills from 'components/NavPills/NavPills.jsx';

const styles = themes => ({
  customFormControlClasses: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    marginTop: '0px !important',
    marginBottom: '0px !important'
  },
  backgroundColor: {}
});

const layout = {
  labelCol: {span: 8},
  wrapperCol: {span: 16}
};

const MBL_SendTransaction = ({classes, query}) => {
  const [state, setState] = useState({
    AGENT_CODE: 'FOREX001',
    USER_ID: 'FOREX_API',
    PASSWORD: 'PASSWORD',
    AGENT_SESSION_ID: 'AGENT_SESSION_ID',
    ACCOUNT_NO: 'ACCOUNT_NO',
    TRANSFERAMOUNT: '11000.00',
    PAYMENTMODE: 'D',
    CALC_BY: 'C',
    LOCATION_ID: '96811068',
    PAYOUT_COUNTRY: 'NEPAL',
    SIGNATURE: '80a6af32a94befd3be8fc8de5b8f9f62d63f1f0d2fd6c2c729f862a928b7d227',
    paymentSaved: false
  });

  const [form] = Form.useForm();
  const [paymentForm] = Form.useForm();

  useEffect(() => {
    console.log(query)

    if (query) {
      form.setFieldsValue({
        paymentID: query.paymentID,
        AGENT_TXNID: 'p' + query.paymentID + '.t' + query.transferID,
        PAYMENTMODE: 'B',
        CALC_BY: 'P',
      });
 
      if (query.paymentID) {
        let amount;
        API.get('payments', `/id/${query.paymentID}`)
          .then(response => {
            amount = response.amount_to
            form.setFieldsValue({
              // remarks: response.detail_1,
              TRANSFERAMOUNT: amount,
              // purpose: response.purposeOfPayment
            });
            paymentForm.setFieldsValue({
              // amount: response.fullList.amount_to,
              transferReference: query.transferID,
              paymentReference: query.paymentID,
              payoutBankID: 'MBL',
            });

            //   if (query.transferID) {
            //     API.get('transfers', `/get-full/id/${query.transferID}`)
            //    .then(response => {
            //      form.setFieldsValue({
            //        // amount: response.fullList.amount_to,
            //        exchangeRate: response.fullList.client_rate,
            //        localAmount: (Number.parseFloat(amount) / Number.parseFloat(response.fullList.client_rate)).toFixed(2),
            //      });
            //      paymentForm.setFieldsValue({
            //        // amount: response.fullList.amount_to,
            //        transferReference: query.transferID,
            //      });
            //    })
            //    .catch(error => {
            //      console.log(error);
            //    });
            //  }

          })
          .catch(error => {
            console.log(error);
          });
      }

      if (query.clientID) {
        API.get('clients', `/get/id/${query.clientID}`)
          .then(response => {
            console.log(response)
            form.setFieldsValue({
              SENDER_NAME: response.nickname,
              SENDER_ADDRESS: (response.residential_street_line_1 || '') + ' ' +
         (response.residential_street_line_2 || '') + ' ' +
         (response.residential_street_suburb || '') + ' ' +
         (response.residential_street_state || '') + ' ' +
         (response.residential_street_postcode || '') + ' ' +
         (response.residential_street_country_full_name || ''),
              SENDER_MOBILE: response.telephone_mobile,
              SENDER_CITY: response.residential_street_suburb,
              SENDER_COUNTRY: response.residential_street_country_full_name || 'Australia',
            });
          })
          .catch(error => {
            console.log(error);
          });
      }

      if (query.beneficiaryID) {
        API.get('beneficiaries', `/get/id/${query.beneficiaryID}`)
          .then(response => {
            console.log(response)
            form.setFieldsValue({
              RECEIVER_NAME: response.ben_legal_name,
              RECEIVER_CONTACT_NUMBER: response.ben_telephone,
              RECEIVER_ADDRESS: (response.ben_address_line_1 || '') + ' ' +
           (response.ben_address_line_2 || '') + ' ' +
           (response.ben_address_suburb || '') + ' ' +
           (response.ben_address_state || '') + ' ' +
           (response.ben_address_postcode || '') + ' ' +
           (response.ben_address_country_full_name || ''),
              RECEIVER_COUNTRY: response.ben_address_country_full_name,
              BANK_ACCOUNT_NUMBER: response.account_number
            });
            switch (response.swift_code) {
              case 'GLBBNPKAXXX':
              case 'GLBBNPKA':
                form.setFieldsValue({
                  LOCATION_ID: 11003990
                });
                break;
              case 'EVBLNPKAXXX':
              case 'EVBLNPKA':
                form.setFieldsValue({
                  LOCATION_ID: 11003989
                });
                break;
              case 'HIMANPKAXXX':
              case 'HIMANPKA':
                form.setFieldsValue({
                  LOCATION_ID: 11003991
                });
                break;
              case 'KMBLNPKAXXX':
              case 'KMBLNPKA':
                form.setFieldsValue({
                  LOCATION_ID: 11003993
                });
                break;
              case 'LXBLNPKAXXX':
              case 'LXBLNPKA':
                form.setFieldsValue({
                  LOCATION_ID: 11003994
                });
                break;
              case 'NMBBNPKAXXX':
              case 'NMBBNPKA':
                form.setFieldsValue({
                  LOCATION_ID: 11004003
                });
                break;
              case 'NARBNPKAXXX':
              case 'NARBNPKA':
                form.setFieldsValue({
                  LOCATION_ID: 11003996
                });
                break;
              case 'NBOCNPKAXXX':
              case 'NBOCNPKA':
                form.setFieldsValue({
                  payingBankBranchCd: 716
                });
                break;
              case 'NIBLNPKTXXX':
              case 'NIBLNPKT':
                form.setFieldsValue({
                  LOCATION_ID: 11004000
                });
                break;
              case 'NICENPKAXXX':
              case 'NICENPKA':
                form.setFieldsValue({
                  LOCATION_ID: 11004002
                });
                break;
              case 'PRVUNPKAXXX':
              case 'PRVUNPKA':
                form.setFieldsValue({
                  LOCATION_ID: 11004004
                });
                break;
              case 'SIDDNPKAXXX':
              case 'SIDDNPKA':
                form.setFieldsValue({
                  LOCATION_ID: 11004008
                });
                break;
              case 'SNMANPKAXXX':
              case 'SNMANPKA':
                form.setFieldsValue({
                  LOCATION_ID: 11004007
                });
                break;
            }
          })
          .catch(error => {
            console.log(error);
          });
      }

      switch (query.clientID) {
        case '11905':
          form.setFieldsValue({
            SENDER_ID_TYPE: 'Driver licence',
            SENDER_ID_NUMBER: '13115683',
          });
          break;
        case '12056':
          form.setFieldsValue({
            SENDER_ID_TYPE: 'Driver licence',
            SENDER_ID_NUMBER: '12915813',
          });
          break;
        case '12241':
          form.setFieldsValue({
            SENDER_ID_TYPE: 'Driver licence',
            SENDER_ID_NUMBER: '13115683',
          });
          break;
        case '12242':
          form.setFieldsValue({
            SENDER_ID_TYPE: 'Driver licence',
            SENDER_ID_NUMBER: '22372046',
          });
          break;
        case '12245':
          form.setFieldsValue({
            SENDER_ID_TYPE: 'Driver licence',
            SENDER_ID_NUMBER: '103125831',
          });
          break;
        case '12251':
          form.setFieldsValue({
            SENDER_ID_TYPE: 'Driver licence',
            SENDER_ID_NUMBER: '12043973',
          });
          break;
        case '12254':
          form.setFieldsValue({
            SENDER_ID_TYPE: 'Driver licence',
            SENDER_ID_NUMBER: '20211610',
          });
          break;
        case '12261':
          form.setFieldsValue({
            SENDER_ID_TYPE: 'Driver licence',
            SENDER_ID_NUMBER: '034901197',
          });
          break;
        case '12271':
          form.setFieldsValue({
            SENDER_ID_TYPE: 'Driver licence',
            SENDER_ID_NUMBER: '21916895',
          });
          break;
        case '12290':
          form.setFieldsValue({
            SENDER_ID_TYPE: 'Driver licence',
            SENDER_ID_NUMBER: '13146473',
          });
          break;
        case '12334':
          form.setFieldsValue({
            SENDER_ID_TYPE: 'Driver licence',
            SENDER_ID_NUMBER: '12915813',
          });
          break;
        case '12468':
          form.setFieldsValue({
            SENDER_ID_TYPE: 'Driver licence',
            SENDER_ID_NUMBER: '20664715',
          });
          break;

      }

    }

  }, [query]);

  const handleChange = (stateName, value) => {
    setState({
      ...state,
      [stateName]: value
    });
  };

  const prefil_senderForexSport = () => {
    form.setFieldsValue({
      // PAYMENTMODE: 'B',
      // CALC_BY: 'P',
      SENDER_NAME: 'FOREX SPORT PTY LTD',
      SENDER_ADDRESS: '100 COLLINS STREET MELBOURNE VIC 3000 AU',
      SENDER_MOBILE: '+61435966957',
      SENDER_CITY: 'MELBOURNE',
      SENDER_COUNTRY: 'AUSTRALIA',
      SENDER_ID_TYPE: 'PASSPORT',
      SENDER_ID_NUMBER: 'N6017912'
    });
  };

  const onFinish = (values: any) => {
    console.log(values);
    API.post('banking', '/mbl/send-transaction', {body: values})
      .then(response => {
        console.log(response);
        console.log(response.axiosResponse);
        setState(state => {
          return {
            ...state,
            axiosResponse: response.axiosResponse
          };
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        console.log('finally');
      });
  };

  const handleSavePayment = (values: any) => {
    console.log(values);
    API.put('transfers', `/update/update_transfer_payout/${values.paymentReference}`, {body: {
      payoutBankId: 32,
      payoutDate: moment().format('YYYY-MM-DD'),
      payoutRef: values.bankPaymentReference,
    }})
      .then(response => {
        console.log(response);
        setState({
          paymentSaved: true
        })
      })
      .catch(error => {
        console.log(error);
      })
  };

  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={6}>
          <Form
            {...layout}
            form={form}
            name='control-hooks'
            onFinish={onFinish}
            style={{maxWidth: 600}}
          >

            <Form.Item name='paymentID' label='paymentID' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='AGENT_TXNID' label='AGENT_TXNID' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='LOCATION_ID' label='LOCATION_ID' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='SENDER_NAME' label='SENDER_NAME' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='SENDER_ADDRESS' label='SENDER_ADDRESS' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='SENDER_MOBILE' label='SENDER_MOBILE' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='SENDER_CITY' label='SENDER_CITY' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='SENDER_COUNTRY' label='SENDER_COUNTRY' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='SENDER_ID_TYPE' label='SENDER_ID_TYPE' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='SENDER_ID_NUMBER' label='SENDER_ID_NUMBER' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='RECEIVER_NAME' label='RECEIVER_NAME' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='RECEIVER_ADDRESS' label='RECEIVER_ADDRESS' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='RECEIVER_CONTACT_NUMBER' label='RECEIVER_CONTACT_NUMBER' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='RECEIVER_COUNTRY' label='RECEIVER_COUNTRY' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='TRANSFERAMOUNT' label='TRANSFERAMOUNT' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='PAYMENTMODE' label='PAYMENTMODE' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='BANK_ACCOUNT_NUMBER' label='BANK_ACCOUNT_NUMBER' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name='CALC_BY' label='CALC_BY' rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form>

        </GridItem>

        <GridItem xs={6}>
          <Button htmlType='button' onClick={prefil_senderForexSport}>
            Sender ForexSport
          </Button>

          <TextareaAutosize
            minRows={13}
            style={{width: '100%'}}
            multiline
            aria-label='maximum height'
            disabled
            placeholder='JSON Response'
            value={state.axiosResponse}
          />

          <Form
            {...layout}
            form={paymentForm}
            name="control-hooks"
            onFinish={handleSavePayment}
            style={{ maxWidth: 600 }}
          >

            <Form.Item name="transferReference" label="Transfer ID">
              <Input disabled />
            </Form.Item>
            <Form.Item name="paymentReference" label="Payment ID">
              <Input disabled />
            </Form.Item>
            <Form.Item name="payoutBankID" label="Payout Bank">
              <Input disabled />
            </Form.Item>
            <Form.Item name="bankPaymentReference" label="Bank Confirmation" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Button type="primary" htmlType="submit">
            Save Payout Details
            </Button>
            {state.paymentSaved &&
          <p><strong>
            Payment Details have been updated
          </strong></p>
            }
          </Form>

        </GridItem>

      </GridContainer>
    </Fragment>
  );
};

MBL_SendTransaction.propTypes = {classes: PropTypes.object.isRequired};

export default withStyles(styles)(MBL_SendTransaction);
