import React, {Fragment} from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import matchSorter from 'match-sorter';
import {AiOutlineSearch} from 'react-icons/ai';
import Dvr from '@material-ui/icons/Dvr';
import Button from 'components/CustomButtons/Button.jsx';

const BatchPaymentsPageComponent = props => {
  const {batch_payments, history} = props;

  const getFilter = (filter, onChange) => {
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AiOutlineSearch />
          <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
        </div>
      </React.Fragment>
    );
  };

  const buildTableData = () => {
    if (!batch_payments.batch_payments_list) {
      return [];
    }

    return batch_payments.batch_payments_list.map(item => ({
      ...item,
      actions: (
        <Button
          justIcon
          round
          simple
          onClick={() => {
            history.push(`/transfers/batch-payments/view/${item.id}`);
          }}
          color='warning'
        >
          <Dvr />
        </Button>
      )
    }));
  };

  return (
    <Fragment>
      <ReactTable
        data={buildTableData()}
        filterable
        columns={[
          {
            Header: 'Id',
            accessor: 'id',
            Filter: ({filter, onChange}) => getFilter(filter, onChange),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['id']}),
            filterAll: true,
            maxWidth: 50
          },
          {
            Header: 'Created At',
            accessor: 'createdAt',
            Filter: ({filter, onChange}) => getFilter(filter, onChange),
            Cell: c => <React.Fragment>{c.value === null ? '' : moment(c.value).format('DD/MM/YYYY')} </React.Fragment>,
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['createdAt']}),
            filterAll: true,
            maxWidth: 150
          },
          {
            Header: 'File By',
            accessor: 'fileBy',
            Filter: ({filter, onChange}) => getFilter(filter, onChange),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['fileBy']}),
            filterAll: true,
            maxWidth: 500
          },
          {
            Header: 'Actions',
            accessor: 'actions',
            sortable: false,
            filterable: false
          }
        ]}
        defaultSorted={[
          {
            id: 'createdAt',
            desc: true
          }
        ]}
        defaultPageSize={10}
        showPageSizeOptions={true}
        showPaginationBottom
      />
    </Fragment>
  );
};

export default BatchPaymentsPageComponent;
