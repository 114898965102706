import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {FormattedMessage} from 'react-intl';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import ClientDocumentCreate from '../../../features/Clients/ClientDocumentCreate.jsx';

const clientDocumentCreateStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  createFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    maxWidth: '360px',
    margin: '16px 0'
  }
};

const ClientDocumentCreateContainer = props => {
  const {classes} = props;

  return (
    <Fragment>
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <PermIdentity />
          </CardIcon>
          <div className={classes.flexEnd}>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage
                id='clientDocumentCreate.cardItem1.title'
                defaultMessage={'Create a new client document'}
              />
            </h4>
          </div>
        </CardHeader>
        <CardBody>
          <ClientDocumentCreate classes={classes} />
        </CardBody>
      </Card>
    </Fragment>
  );
};

ClientDocumentCreateContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(clientDocumentCreateStyles)(ClientDocumentCreateContainer);
