import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import ListTable from '../../../../components/ListTable/ListTable'
import {Button, message, Table} from 'antd'
import {ForwardsAPI} from "../ForwardsUtil";
import {connect} from "react-redux";

const ForwardDetailCommunication = ({state, intl, forwardCommunication, getColumnSearchProps, loading = false, forwardId}) => {

    const [componentState, setComponentState] = useState({columns: [], data: forwardCommunication});
    console.log(state);
    const buildColumns = () => {
        const columns = [];
        setComponentState(prev => ({...prev, columns: columns}));
    }

    const sendEmail = (memberType) => {
        ForwardsAPI.sendCommunicationEmail({
            forwardID: forwardId,
            staffID: state.current_staff.id,
            staffOnly: memberType === 'STAFF'
        }).then(() => {
            message.info("Email Sent");
        }).catch(() => {
            message.error("Email sending Failed");
        })
    }

    useEffect(() => {
        buildColumns();
    }, [0])

    return (
        <>
            <Button type={'primary'} style={{ marginBottom: '10px', display: 'block' }} danger={true} onClick={() => sendEmail('STAFF')}>Send Email Staff</Button>
            <Button type={'primary'} danger={true} onClick={() => sendEmail('CLIENT')}>Send Email Client</Button>
            <Table dataSource={componentState.data}
                   columns={componentState.columns}
                   loading={loading}>
            </Table>
        </>
    )
}

const mapStateToProps = state => {
    return {
        state: state.app_state
    }
};

const mapDispatchToProps = dispatch => {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ListTable(ForwardDetailCommunication)));