import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {onLoginSuccess, updateCurrentBrand} from '../../redux/actions';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import {NavLink} from 'react-router-dom';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Face from '@material-ui/icons/Face';
import Edit from '@material-ui/icons/Edit';
import {Grid as DefaultGrid} from '@material-ui/core';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CustomTextField from '../../components/CustomInput/CustomTextField';

import {API, Auth} from 'aws-amplify';

import loginPageStyles from './LoginPageStyles';

import {FormattedMessage} from 'react-intl';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
      email: '',
      password: '',
      password_reset_code_sending: false,
      password_reset_code_sent: false,
      password_reset_confirming: false,
      password_reset_confirmed: false,
      confirm_code: ''
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    if (this.props.match.params.referral_code) {
      this.props.updateCurrentBrand(this.props.match.params.referral_code);
    }
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({cardAnimaton: ''});
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handle_forgot_password_click = async email => {
    this.setState({password_reset_code_sending: true});

    try {
      await Auth.forgotPassword(this.state.email.toLowerCase());
      this.setState({password_reset_code_sent: true});
    } catch (e) {
      alert(e.message);
      this.setState({password_reset_code_sending: false});
    }
  };

  handle_forgot_password_confirm_click = async event => {
    event.preventDefault();

    this.setState({password_reset_confirming: true});

    try {
      await Auth.forgotPasswordSubmit(this.state.email.toLowerCase(), this.state.confirm_code, this.state.password);
      this.setState({password_reset_confirmed: true});
    } catch (e) {
      alert(e.message);
      this.setState({password_reset_confirming: false});
    }
  };

  validate_password_reset_form() {
    return (
      this.state.confirm_code.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.password_confirm
    );
  }

  validate_confirmation_form() {
    return this.state.confirm_code.length > 0;
  }

  validate_email() {
    const value = this.state.email;
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value});
  };

  handleSubmit = async event => {
    event.preventDefault();
    try {
      const user = await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
      this.props.userHasAuthenticated(true);
      const user_profile = await this.getUserProfile(user.username);
      // const { username, first_name, middle_name, last_name } = user_profile;
      this.props.set_current_user(user_profile);
      this.props.onLoginSuccess(user.username);
      this.props.history.push('/');
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        this.props.history.push('/pages/verify-email');
      }
      alert(e.message);
    }
  };

  async getUserProfile(aws_cognito_id) {
    return API.get('staff', `/get/${aws_cognito_id}`);
  }

  render_confirmation_form() {
    const {classes} = this.props;
    const languageObj_en_json = require('../../translations/en.json');
    const languageObj_zh_json = require('../../translations/zh.json');
    const languageObj_de_json = require('../../translations/de.json');
    const languageObj_es_json = require('../../translations/es.json');
    const languageObj_fr_json = require('../../translations/fr.json');
    const languageObj_it_json = require('../../translations/it.json');
    const languageObj_pt_json = require('../../translations/pt.json');
    let logo;
    let form_text;
    let languageObj = '';
    switch (this.props.language.language_current_ui) {
      case 'es':
        languageObj = languageObj_es_json;
        break;
      case 'de':
        languageObj = languageObj_de_json;
        break;
      case 'zh':
        languageObj = languageObj_zh_json;
        break;
      case 'fr':
        languageObj = languageObj_fr_json;
        break;
      case 'it':
        languageObj = languageObj_it_json;
        break;
      case 'pt':
        languageObj = languageObj_pt_json;
        break;
      default:
        languageObj = languageObj_en_json;
        break;
    }

    // Handle Branding
    let login_form_title = 'Client Portal Login';
    login_form_title = languageObj['login.formTitle'];
    switch (this.props.app_state.current_brand) {
      case 'dorman':
        form_text = 'Dorman';
        break;
      case 'gostudy':
        logo = require('assets/img/GOSTUDY_HorizontalWhite.png');
        form_text = 'GoStudy';
        break;
      case 'abctravel':
        logo = require('assets/img/brand/travel-logo.jpg');
        form_text = 'ABC Travel';
        break;
      case 'crocmedia':
        logo = require('assets/img/brand/crocmedia_white.png');
        form_text = 'CrocMedia';
        break;
      case 'fwwjd':
        form_text = 'Forex WorldWide [JD]';
        break;
      case 'fwwsc':
        form_text = 'Forex WorldWide [SC]';
        break;
      case 'fwwpd':
        form_text = 'Forex WorldWide [PD]';
        break;
      case 'fxsjd':
        form_text = 'Forex Sport [JD]';
        break;
      case 'payfxdemo':
        form_text = 'PayFX.com [DEMO]';
        break;
      case 'skiaspen':
        form_text = 'Ski Aspen';
        break;
      default:
        break;
    }

    return (
      <form className={classes.form} onSubmit={this.handle_forgot_password_confirm_click}>
        <Card
          login
          className={`${classes[this.state.cardAnimaton]} ${classes.backgroundTransparent}`}
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
        >
          <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color='primary'>
            {logo && <img src={logo} height={70} width={''} alt='' />}
            <h4 className={classes.cardTitle} style={{paddingTop: 12}}>
              {form_text}
              &nbsp;
              <FormattedMessage id='login.resetPassword' defaultMessage={'Reset Password'} />
            </h4>
          </CardHeader>
          <CardBody>
            <p>
              &nbsp;
              <FormattedMessage
                id='reset.renderConfirmationForm.text'
                defaultMessage={'A confirmation code has been emailed to'}
              />
              &nbsp;
              {this.state.email}.
            </p>
            <CustomTextField
              id='confirm_code'
              formControlProps={{
                fullWidth: true,
                className: `${classes.customFormControlClasses} ${classes.backgroundColor}`
              }}
              inputProps={{
                value: this.state.confirm_code,
                variant: 'outlined',
                style: {backgroundColor: 'white'},
                label: <FormattedMessage id='reset.conformation.text' defaultMessage={'Enter Confirmation Code'} />,
                onChange: event => {
                  this.handleChange(event);
                },
                startAdornment: (
                  <InputAdornment position='start' className={classes.inputAdornment}>
                    <Edit className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
            <CustomTextField
              id='password'
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses
              }}
              inputProps={{
                value: this.state.password,
                variant: 'outlined',
                style: {backgroundColor: 'white'},
                label: <FormattedMessage id='reset.newPassword.text' defaultMessage={'Enter New Password'} />,
                onChange: event => {
                  this.handleChange(event);
                },
                type: 'password',
                startAdornment: (
                  <InputAdornment position='start' className={classes.inputAdornment}>
                    <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                  </InputAdornment>
                )
              }}
            />
            <CustomTextField
              id='confirm_password'
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses
              }}
              inputProps={{
                value: this.state.confirm_password,
                variant: 'outlined',
                style: {backgroundColor: 'white'},
                label: (
                  <FormattedMessage id='reset.newConfirmPassword.text' defaultMessage={'Enter Confirm Password'} />
                ),
                onChange: event => {
                  this.handleChange(event);
                },
                type: 'password',
                startAdornment: (
                  <InputAdornment position='start' className={classes.inputAdornment}>
                    <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                  </InputAdornment>
                )
              }}
            />
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button
              color='purple'
              style={{height: '40px'}}
              disabled={!this.validate_confirmation_form()}
              type='submit'
              size='sm'
              block
            >
              <FormattedMessage id='login.resetPassword' defaultMessage={'Reset Password'} />
            </Button>
          </CardFooter>
          <Button
            color='tumblr'
            simple
            size='lg'
            block
            onClick={() => this.setState({password_reset_code_sent: false})}
          >
            <b>
              &nbsp;
              <FormattedMessage id='reset.returnLogin.text' defaultMessage={'RETURN TO LOGIN PAGE'} />
            </b>
          </Button>
        </Card>
      </form>
    );
  }

  render_login_form() {
    const {classes} = this.props;
    const languageObj_en_json = require('../../translations/en.json');
    const languageObj_zh_json = require('../../translations/zh.json');
    const languageObj_de_json = require('../../translations/de.json');
    const languageObj_es_json = require('../../translations/es.json');
    const languageObj_fr_json = require('../../translations/fr.json');
    const languageObj_it_json = require('../../translations/it.json');
    const languageObj_pt_json = require('../../translations/pt.json');

    //this.props.language.language_current_ui
    let languageObj = '';
    switch (this.props.language.language_current_ui) {
      case 'es':
        languageObj = languageObj_es_json;
        break;
      case 'de':
        languageObj = languageObj_de_json;
        break;
      case 'zh':
        languageObj = languageObj_zh_json;
        break;
      case 'fr':
        languageObj = languageObj_fr_json;
        break;
      case 'it':
        languageObj = languageObj_it_json;
        break;
      case 'pt':
        languageObj = languageObj_pt_json;
        break;
      default:
        languageObj = languageObj_en_json;
        break;
    }

    // Branding
    let logo;
    let form_text;
    switch (this.props.app_state.current_brand) {
      case 'dorman':
        form_text = 'Dorman';
        break;
      case 'gostudy':
        logo = require('assets/img/GOSTUDY_HorizontalWhite.png');
        form_text = 'GoStudy';
        break;
      case 'abctravel':
        logo = require('assets/img/brand/travel-logo.jpg');
        form_text = 'ABC Travel';
        break;
      case 'crocmedia':
        logo = require('assets/img/brand/crocmedia_white.png');
        form_text = 'CrocMedia';
        break;
      case 'fwwjd':
        form_text = 'Forex WorldWide [JD]';
        break;
      case 'fwwsc':
        form_text = 'Forex WorldWide [SC]';
        break;
      case 'fwwpd':
        form_text = 'Forex WorldWide [PD]';
        break;
      case 'fxsjd':
        form_text = 'Forex Sport [JD]';
        break;
      case 'payfxdemo':
        logo = require('assets/img/brand/payfxcom_250.png');
        form_text = 'PayFX.com [DEMO]';
        break;
      case 'skiaspen':
        logo = require('assets/img/brand/skiaspen.png');
        form_text = 'Ski Aspen';
        break;
      default:
        form_text = 'Login';
        break;
    }

    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <Card
            login
            className={classes[this.state.cardAnimaton]}
            style={{
              backgroundColor: '#e0e0e0',
              boxShadow: 'none'
            }}
          >
            <CardHeader className={`${classes.loginHeader} ${classes.textCenter}`} color='primary'>
              {logo && (
                <img
                  src={logo}
                  alt=''
                  height={form_text == 'CrocMedia' ? '' : 70}
                  width={form_text == 'CrocMedia' ? 220 : ''}
                />
              )}
              <h4 className={classes.cardTitle} style={{paddingTop: 12}}>
                {form_text}
                <br/>
                <FormattedMessage id='login.formTitle' defaultMessage={'CRM Log in'} />
              </h4>
              <br/>
              <em>Powered by Forex Worldwide</em>
            </CardHeader>
            <CardBody>
              <br />
              <br />
              <DefaultGrid container justify='center'>
                <DefaultGrid item xs={6} sm={6} md={6}>
                  <Button color='twitter' className={classes.activeButton}>
                    <b>LOGIN</b>
                  </Button>
                </DefaultGrid>
                <DefaultGrid item xs={6} sm={6} md={6}>
                  <NavLink to={'/pages/register-business/' + this.props.app_state.current_brand}>
                    <Button className={classes.nonActiveButton}>
                      <b>REGISTER</b>
                    </Button>
                  </NavLink>
                </DefaultGrid>
              </DefaultGrid>
              <br />

              <CustomTextField
                labelText={<FormattedMessage id='login.email' defaultMessage={'Email...'} />}
                id='email'
                value={this.state.email}
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses
                }}
                inputProps={{
                  value: this.state.email,
                  variant: 'outlined',
                  style: {backgroundColor: 'white'},
                  label: 'Email',
                  onChange: event => {
                    this.handleChange(event);
                  },
                  type: 'email',
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  )
                }}
              />
              <CustomTextField
                labelText={<FormattedMessage id='login.password' defaultMessage={'Password...'} />}
                id='password'
                value={this.state.password}
                onChange={this.handleChange}
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasse
                }}
                inputProps={{
                  variant: 'outlined',
                  style: {backgroundColor: 'white'},
                  label: 'Password',
                  value: this.state.password,
                  onChange: event => {
                    this.handleChange(event);
                  },
                  type: 'password',
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                    </InputAdornment>
                  )
                }}
              />
              {/* <p className={classes.justifyContentCenter}>
                By continuing you accept our{' '}
                <a href="https://www.forexworldwide.com/legal" target="_blank">
                  Terms and Conditions
                </a>
                .
              </p> */}
              <p className={classes.term}>
                <FormattedMessage id='login.resetwording.line2' defaultMessage={'By continuing you agree to our'} />{' '}
                <a href='https://www.forexworldwide.com/legal' target='_blank'>
                  <strong className={classes.termCondition}>
                    <FormattedMessage id='login.termsAndConditon' defaultMessage={'Terms & Conditions'} />
                  </strong>
                </a>
              </p>
              <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    color='purple'
                    style={{
                      height: '50px',
                      fontSize: '14px'
                    }}
                    disabled={!this.validateForm()}
                    key={'googlelogin'}
                    type='submit'
                    size='sm'
                    block
                  >
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormattedMessage id='login.login' defaultMessage={'PROCEED'} />
                      </GridItem>
                    </GridContainer>
                  </Button>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.reset}>
                    <b>
                      <FormattedMessage id='login.resetwording.line1' defaultMessage={'HAVING TROUBLE? '} />
                      <a
                        style={{cursor: 'pointer'}}
                        onClick={() => this.handle_forgot_password_click(this.state.email)}
                        target='_blank'
                      >
                        <span style={{color: 'black'}}>
                          <FormattedMessage id='login.resetPassword2' defaultMessage={'RESET PASSWORD'} />
                        </span>
                      </a>
                    </b>
                  </p>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              {/* <Button color="rose" simple size="lg" block disabled={!this.validateForm()} type="submit">
                <FormattedMessage id="login.login" defaultMessage={'Login'} />
              </Button>
              <Button
                color="info"
                simple
                size="lg"
                block
                disabled={!this.validate_email()}
                onClick={() => this.handle_forgot_password_click(this.state.email)}
              >
                <FormattedMessage id="login.resetPassword" defaultMessage={'Forgot Password'} />
              </Button> */}
            </CardFooter>
          </Card>
        </form>
      </React.Fragment>
    );
  }

  render_success_message() {
    const {classes} = this.props;
    return (
      <Card login className={classes[this.state.cardAnimaton]}>
        <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color='primary'>
          <h4 className={classes.cardTitle}>Password Reset Success</h4>
        </CardHeader>
        <CardBody>
          <p>Your password has been reset.</p>
          <p>
            <a href='/pages/login'>Click here </a>
            to login with your new credentials.
          </p>
        </CardBody>
      </Card>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={7} md={5}>
            {!this.state.password_reset_code_sent
              ? this.render_login_form()
              : !this.state.password_reset_confirmed
              ? this.render_confirmation_form()
              : this.render_success_message()}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {classes: PropTypes.object.isRequired};

// export default withStyles(loginPageStyles)(LoginPage);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
    // clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentBrand: brand => {
      dispatch(updateCurrentBrand(brand));
    },
    onLoginSuccess: staff_id => {
      dispatch(onLoginSuccess(staff_id));
    }
    // checkLoginOk: (staff_id) => {
    // dispatch(checkLoginOk(staff_id));
    // }
  };
};

const LoginPageContainer = connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyles)(LoginPage));

export default LoginPageContainer;
