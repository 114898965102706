import React from 'react';
import ReactTable from 'react-table';
import {AiOutlineSearch} from 'react-icons/ai';

export const TeamInvites = ({data}) => {
  const getFilter = (filter, onChange) => {
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AiOutlineSearch />
          <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
        </div>
      </React.Fragment>
    );
  };
  return (
    <ReactTable
      data={data}
      filterable
      columns={[
        {
          Header: 'ID',
          accessor: 'id'
        },
        {
          Header: 'Client',
          Filter: ({filter, onChange}) => getFilter(filter, onChange),
          accessor: 'client'
        },
        {
          Header: 'Teams',
          Filter: ({filter, onChange}) => getFilter(filter, onChange),
          accessor: 'team'
        },
        {
          Header: 'Status',
          Filter: ({filter, onChange}) => getFilter(filter, onChange),
          accessor: 'fileby'
        }
      ]}
      defaultSorted={[
        {
          id: 'nickname',
          desc: false
        }
      ]}
      defaultPageSize={10}
      // showPaginationTop
      showPaginationBottom={true}
      className='-striped -highlight'
    />
  );
};
