import {API} from 'aws-amplify';

export const HoldingAccountConstants = {
  formConstants: {
    FORM_NAME: {name: 'holdingAccountLineItem', label: 'Holding Account Form'},
    TRANSACTION_DATE: {name: 'transaction_date', label: 'Transaction Date'},
    AMOUNT: {name: 'amount', label: 'Amount'},
    DESCRIPTION: {name: 'description', label: 'Description'},
    MEMO_INTERNAL: {name: 'memo_internal', label: 'Internal Description'},
    CREDIT: {name: 'plus_credit', label: 'Plus Credit'},
    DEBIT: {name: 'less_debit', label: 'Less Debit'},
    BALANCE_BEFORE: {name: 'balance_before', label: 'Balance Before'},
    EMAIL_RECEIPT: {name: 'emailReceipt', label: 'Email Receipt'},
    NEW_BALANCE: {name: 'new_balance', label: 'New Balance'},
  },
  URL: {
    ALL_HOLDINGS: (accountId) => '/holding-accounts/view/' + accountId
  }
}

export const HoldingAccountAPI = {
  getHoldingAccount: (holding_account_id) => API.get('holding_accounts', `/get/details/${holding_account_id}`, {}),
  saveHoldingAccount: (data) => API.post('holding_accounts', '/create/line-item', {body: data}),
  emailHoldingAccountCompany: (data) => API.post('email-18', '/client/ledger-credit', {body: data}),
  fetchHoldingAccountLineItem: (lineItemId) => API.get('holding_accounts', `/get/line_item/${lineItemId}`, {}),
  fetchHoldingItemLedgerBalance: (clientId, currencyId) => API.get('holding_accounts', `/get/client-currency/${clientId}/${currencyId}`, {})
}

export const HoldingAccountLineItemValidation = {
  TRANSACTION_DATE: [
    {required: true, message: 'Enter date'}
  ],
  AMOUNT: [
    {required: true, message: 'Enter Amount'},
    {message: 'Enter Valid Amount', pattern: new RegExp(/^[1-9]\d*(\.\d+)?$/)}
  ],
  DESC: [
    {required: true, message: 'Enter description'}
  ]
}