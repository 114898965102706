import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import PermIdentity from '@material-ui/icons/PermIdentity';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import ForwardsDetailComponent from '../../../components/Forwards/ForwardsDetail';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import {fetchForwardDetails} from '../../../redux/actions/forwards';
import {connect} from 'react-redux';
import { Button } from 'antd'

const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const ForwardsDetail = ({fetchForwardDetails, classes, app_state, forwardDetail}) => {
  return (
    <div>
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <PermIdentity />
          </CardIcon>
          <div className={classes.flexEnd}>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id='forwardList.cardItem1.forwardListing' defaultMessage={'Forwards Detail'} />
            </h4>
            <div style={{marginTop: '12px'}}>
              <Button danger={true} type={'primary'} onClick={() => fetchForwardDetails(forwardDetail.id)}>
                <FormattedMessage id='newTransfer.cardItem1.refresh' defaultMessage={'Refresh'} />
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <ForwardsDetailComponent current={true} />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
};

// ForwardsDetail.propTypes = {classes: PropTypes.object.isRequired};

// export default withStyles(styles)(ForwardsDetail);
const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    forwardDetail: state.forwards.forwardDetail
  };
};

const mapDispatchToProps = dispatch => {
  return {fetchForwardDetails: (id) => dispatch(fetchForwardDetails(id))};
};

const ForwardsDetailContainer = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForwardsDetail));

export default ForwardsDetailContainer;
