import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';

import {cardTitle} from '../../assets/jss/material-dashboard-pro-react.jsx';
import {API} from 'aws-amplify';
import CustomReactSelect from '../Forms/CustomReactSelect/CustomReactSelect';
import {withRouter} from 'react-router-dom';

const entityNewStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  }
};

class TeamNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nickname: '',
      nickname_state: '',
      division_id: '',
      division_list: []
    };
  }

  componentDidMount() {
    API.get('divisions', `/get-list-not-deleted`)
      .then(response => {
        this.setState({
          division_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  validateForm() {
    var valid = true;
    if (this.state.nickname === '') {
      this.setState({nickname_state: 'error'});
      valid = false;
    }
    if (this.state.system_email === '') {
      this.setState({system_email_state: 'error'});
      valid = false;
    }
    if (this.state.division_id === '') {
      valid = false;
    }

    return valid;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'checkbox':
        if (event.target.checked) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-length':
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'url':
        if (this.verifyUrl(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'min-value':
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-value':
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'range':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      default:
        break;
    }
    switch (type) {
      case 'checkbox':
        this.setState({[stateName]: event.target.checked});
        break;
      default:
        this.setState({[stateName]: event.target.value});
        break;
    }
  }

  handleCustomReactSelectChange = name => value => {
    var option_value;
    if (value === null) {
      option_value = null;
    } else {
      option_value = value.value;
    }
    this.setState({
      [name]: option_value
    });
  };

  async handleSubmitForm() {
    if (this.validateForm()) {
      await this.props.createNewDivision({
        nickname: this.state.nickname,
        division_id: this.state.division_id
      });
      this.props.history.push('/teams/list');
    }
  }

  handleCancel() {
    this.props.history.push('/teams/list');
  }

  renderForm() {
    const {classes} = this.props;

    const division_list_select_options = this.state.division_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));

    return (
      <GridItem xs={12} sm={6}>
        <h3>Enter details for new Team</h3>
        <form>
          <CustomInput
            success={this.state.nickname_state === 'success'}
            error={this.state.nickname_state === 'error'}
            labelText='Nick Name *'
            id='nickname'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.nickname,
              onChange: event => this.change(event, 'nickname', 'length', 0),
              type: 'text'
            }}
          />

          <CustomReactSelect
            label='Division'
            options={division_list_select_options}
            value={this.state.division_id}
            onChange={this.handleCustomReactSelectChange('division_id')}
            error={this.state.division_id === ''}
            isClearable={true}
          />

          <div className={classes.formCategory}>
            <small>*</small> Required fields
          </div>
          <p style={{color: '#FF0000'}}>
            {(!this.props.app_state.current_staff_security
              ? 'false'
              : this.props.app_state.current_staff_security.staff_division_security[this.state.division_id] < 8 ||
                false) && 'Please check with our staff you have the accessibility to setup up in this division!'}
          </p>
          <Button
            disabled={
              !this.props.app_state.current_staff_security
                ? 'false'
                : this.props.app_state.current_staff_security.staff_division_security[this.state.division_id] < 8 ||
                  false
              //should only be accessible if user has staff_division_security for that division > 7
            }
            color='rose'
            onClick={() => this.handleSubmitForm()}
            className={classes.registerButton}
          >
            Register
          </Button>
          <Button
            // color=""
            onClick={() => this.handleCancel()}
            className={classes.registerButton}
          >
            Cancel
          </Button>
        </form>
      </GridItem>
    );
  }

  render() {
    return (
      <div>
        <GridContainer>{this.renderForm()}</GridContainer>
      </div>
    );
  }
}

TeamNew.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(entityNewStyles)(TeamNew));
