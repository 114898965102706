import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';
import Button from '../../../components/CustomButtons/Button';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
//import TeamNewContainer from "../../../components/Teams/TeamNewContainer";
import GroupNewContainer from '../../../components/Groups/GroupNewContainer';
import {withRouter} from 'react-router-dom';

const entitiesStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - Group New
 * ----------------------------------------------------------------------------
 */
class GroupNew extends React.Component {
  render() {
    const {classes} = this.props;

    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>New Group</h4>
              <div>
                <Button round color={null} onClick={() => this.props.history.push('/groups/list')}>
                  Show All
                </Button>
                <Button round color={'rose'} onClick={() => this.props.history.push('/groups/new')}>
                  Create New
                </Button>
                {this.props.groups.groups_current_ui === 'edit' && (
                  <Button
                    round
                    color={this.props.groups.groups_current_ui === 'edit' ? 'rose' : null}
                    onClick={() => this.props.selectEntityUi('edit')}
                  >
                    Edit Record
                  </Button>
                )}
                {/*{(this.props.divisions.divisions_current_ui !== "edit" && this.props.divisions.divisions_current_ui !== "create") && (*/}
                {/*  <Button*/}
                {/*    round*/}
                {/*    onClick={() => this.props.selectEntityUi("list")}*/}
                {/*  >*/}
                {/*    Refresh*/}
                {/*  </Button>*/}
                {/*)}*/}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                {/*<TeamNewContainer />*/}
                <GroupNewContainer />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

GroupNew.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(entitiesStyles)(GroupNew));
