import React, {Fragment, useEffect} from 'react';
// core components
import GridContainer from '../../Grid/GridContainer.jsx';
import GridItem from '../../Grid/GridItem.jsx';
import Card from '../../Card/Card.jsx';
import CardBody from '../../Card/CardBody.jsx';

import CardHeader from 'components/Card/CardHeader';
import CustomInput from '../../CustomInput/CustomInput';
import Button from '../../CustomButtons/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
// For CustomReactSelect
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import ClientEditCommunication from '../Edit/ClientEditCommunication.jsx';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  InputAdornment,
  Radio, TextareaAutosize
} from '@material-ui/core';
import CustomTextField from '../../CustomInput/CustomTextField.jsx';
import Icon from '@ant-design/icons/lib/components/Icon';
import {Face, FiberManualRecord} from '@material-ui/icons';
export const BussinessDetailsComponent = ({
  classes,
  handleSubmit,
  app_state,
  state,
  handleChange,
  setState,
  change,
  client_detail_id,
  handleCustomReactSelectChange,
  handleCreateClientPortalAccount,
  toggleForgetModal,
  forgetPassword,
  validatePassword,
  handleRadioButtonChange,
  handleToggle
}) => {
  handleRadioButtonChange = event => {
    console.log(event.target.value);
    if (event.target.value === 'customer_type_personal' || event.target.value === 'customer_type_business') {
      setState({selectedValue: event.target.value});
      if (event.target.value === 'customer_type_personal') {
        setState({
          // business_trading_name: "",
          // business_company_name: "",
          // business_trust_name: "",
          account_type: 1
        });
      } else if (event.target.value === 'customer_type_business') {
        setState({account_type: 2});
      }
    } else if (
      event.target.value === 'customer_type_soleTrader' ||
      event.target.value === 'customer_type_company' ||
      event.target.value === 'customer_type_trust'
    ) {
      setState({selectedBusinessValue: event.target.value});
      if (event.target.value === 'customer_type_soleTrader') {
        setState({
          // business_company_name: "",
          // business_trust_name: "",
          account_type: 2
        });
      }
      if (event.target.value === 'customer_type_company') {
        setState({
          // business_trading_name: "",
          // business_trust_name: "",
          account_type: 2
        });
      }
      if (event.target.value === 'customer_type_trust') {
        setState({
          // business_trading_name: "",
          // business_company_name: "",
          account_type: 2
        });
      }
    }
  };

  const onInitSetCustomerType = () => {
    if (state.account_type === 1 ||
        (state.account_type === 2 && state.account_business_type === 1)) {
      setState({selectedBusinessValue: 'customer_type_SoleTrader'});
    } else if (state.account_type === 2 && state.account_business_type === 2) {
      setState({selectedBusinessValue: 'customer_type_company'});
    } else if (state.account_type === 2 && state.account_business_type === 3) {
      setState({selectedBusinessValue: 'customer_type_trust'});
    } else {
      setState({selectedBusinessValue: 'customer_type_SoleTrader'});
    }
  }

  useEffect(() => {

    onInitSetCustomerType();

  }, []);
  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <GridContainer>
              {
                <GridItem xs={6} sm={4} md={4}>
                  <div className={classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={state.selectedBusinessValue === 'customer_type_soleTrader'}
                          onChange={handleRadioButtonChange}
                          value='customer_type_soleTrader'
                          name='radio button demo'
                          aria-label='Customer Type Sole Trader'
                          icon={<FiberManualRecord className={classes.radioUnchecked} />}
                          checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{label: classes.label}}
                      label={<span style={{color: 'black'}}>Sole Trader</span>}
                    />
                  </div>

                  <div className={classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={state.selectedBusinessValue === 'customer_type_company'}
                          onChange={handleRadioButtonChange}
                          value='customer_type_company'
                          name='radio button demo'
                          aria-label='Customer Type Company'
                          icon={<FiberManualRecord className={classes.radioUnchecked} />}
                          checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{label: classes.label}}
                      label={<span style={{color: 'black'}}>Company</span>}
                    />
                  </div>

                  <div className={classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={state.selectedBusinessValue === 'customer_type_trust'}
                          onChange={handleRadioButtonChange}
                          value='customer_type_trust'
                          name='radio button demo'
                          aria-label='Customer Type Sole Trader'
                          icon={<FiberManualRecord className={classes.radioUnchecked} />}
                          checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{label: classes.label}}
                      label={<span style={{color: 'black'}}>Trust</span>}
                    />
                  </div>
                  <Divider/>

                  <TextareaAutosize
                      minRows={5}
                      style={{width: '100%'}}
                      multiline
                      id={'business_activities'}
                      onChange={(event) => handleChange(event)}
                      aria-label='maximum height'
                      placeholder='Enter Business Activities'
                      value={state.business_activities}
                  />
                </GridItem>
              }
              {state.selectedBusinessValue === 'customer_type_soleTrader' && (
                <GridItem xs={12} sm={8} md={6}>
                  <h4> Sole Trader </h4>
                  Provide trading name and abn (if applicable)
                  <CustomInput
                    labelText={<span>Trading Name</span>}
                    id='business_trading_name'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.business_trading_name || '',
                      onChange: event => handleChange(event),
                      // endAdornment: (
                      //   <InputAdornment position='end' className={classes.inputAdornment}>
                      //     <Face className={classes.inputAdornmentIcon} />
                      //   </InputAdornment>
                      // )
                    }}
                  />
                  <CustomInput
                    labelText={<span>ABN</span>}
                    id='business_abn'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.business_abn || '',
                      onChange: event => handleChange(event),
                      // endAdornment: (
                      //   <InputAdornment position='end' className={classes.inputAdornment}>
                      //     <Face className={classes.inputAdornmentIcon} />
                      //   </InputAdornment>
                      // )
                    }}
                  />
                </GridItem>
              )}
              {state.selectedBusinessValue === 'customer_type_company' && (
                <GridItem xs={12} sm={8} md={8}>
                  <h4> Company </h4>
                  Provide company name and company number/ACN or ABN if Australian company, or company number if
                  international
                  <CustomInput
                    labelText={<span>Company Name</span>}
                    id='business_company_name'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.business_company_name || '',
                      onChange: event => handleChange(event),
                      // endAdornment: (
                      //   <InputAdornment position='end' className={classes.inputAdornment}>
                      //     <Face className={classes.inputAdornmentIcon} />
                      //   </InputAdornment>
                      // )
                    }}
                  />
                  <CustomInput
                    error={state.business_company_number_state === 'error'}
                    labelText={<span>Company number</span>}
                    id='business_company_number'
                    formControlProps={{fullWidth: true}}
                    helpText={
                      state.business_company_number_state === 'error'
                        ? 'The length of Company Number must be shorter than 16 characters'
                        : ''
                    }
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.business_company_number || '',
                      onChange: event => handleChange(event),
                      // endAdornment: (
                      //   <InputAdornment position='end' className={classes.inputAdornment}>
                      //     <Face className={classes.inputAdornmentIcon} />
                      //   </InputAdornment>
                      // )
                    }}
                  />
                  <CustomInput
                    labelText={<span>ABN</span>}
                    id='business_abn'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.business_abn || '',
                      onChange: event => handleChange(event),
                      // endAdornment: (
                      //   <InputAdornment position='end' className={classes.inputAdornment}>
                      //     <Face className={classes.inputAdornmentIcon} />
                      //   </InputAdornment>
                      // )
                    }}
                  />
                  <CustomInput
                      labelText={<span>AFSL</span>}
                      id='afsl'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: !state.edit_mode,
                        value: state.afsl || '',
                        onChange: event => handleChange(event),
                        // endAdornment: (
                        //     <InputAdornment position='end' className={classes.inputAdornment}>
                        //       <Face className={classes.inputAdornmentIcon} />
                        //     </InputAdornment>
                        // )
                      }}
                  />
                </GridItem>
              )}
              {state.selectedBusinessValue === 'customer_type_trust' && (
                <GridItem xs={12} sm={8} md={6}>
                  <h4> Trust </h4>
                  Please enter trust details
                  <CustomInput
                    labelText={<span>Trust Name</span>}
                    id='business_trust_name'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.business_trust_name || '',
                      onChange: event => handleChange(event),
                      // endAdornment: (
                      //   <InputAdornment position='end' className={classes.inputAdornment}>
                      //     <Face className={classes.inputAdornmentIcon} />
                      //   </InputAdornment>
                      // )
                    }}
                  />
                  <CustomInput
                    labelText={<span>ABN</span>}
                    id='business_abn'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: !state.edit_mode,
                      value: state.business_abn || '',
                      onChange: event => handleChange(event),
                      // endAdornment: (
                      //   <InputAdornment position='end' className={classes.inputAdornment}>
                      //     <Face className={classes.inputAdornmentIcon} />
                      //   </InputAdornment>
                      // )
                    }}
                  />
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
        <Button color='primary' disabled={!state.edit_mode} type='submit' className={classes.updateProfileButton}>
          Update Business Details
        </Button>
      </form>
    </Fragment>
  );
};
