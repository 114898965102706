import React, {Fragment} from 'react';

const BatchPaymentDetailsPageComponent = props => {
  const {batch_payment_details} = props;

  return (
    <Fragment>
      {batch_payment_details ? (
        <Fragment>
          <div>{`ID: ${batch_payment_details.id}`}</div>
          <div>{`File By: ${batch_payment_details.fileBy}`}</div>
          <div>{`Created At: ${batch_payment_details.createdAt}`}</div>
        </Fragment>
      ) : (
        'No data found'
      )}
    </Fragment>
  );
};

export default BatchPaymentDetailsPageComponent;
