import React from 'react';
// import ReactTable from 'react-table';
import {API} from 'aws-amplify';

import withStyles from '@material-ui/core/styles/withStyles';
import {DeleteOutlined, EyeOutlined} from '@ant-design/icons';
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';

import {Divider, Input, Space, Select, Table, Tag, Button, Modal, Form, message, Row, Col, Flex} from 'antd';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import {AppUtil} from "../../AppUtil";
import {AppUrlConstants} from "../../AppAPI";
import {PlusOutlined} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import ListTable from "../ListTable/ListTable";
// import { fetchCountryListNotDeleted } from "../../redux/actions/countries";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - ENTITYTABLE
 * ----------------------------------------------------------------------------
 */
class CountryTable extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            country_list: [],
            isModalOpen: false
        };
    }

    componentDidMount() {
        // const {fetchCountryListNotDeleted} = this.props;
        // fetchCountryListNotDeleted();
        this.fetchCountryList();

    }

    fetchCountryList = () => {
        API.get('system', `/countries/get-all`, {})
            .then(response => {
                console.log(response);
                this.setState({country_list: [...response.fullList, ...response.priorityonly]});
            })
            .catch(error => {
                console.log(error);
            });
    }

    onSubmit = (payload) => {
        const func = this.state.activeRowId != null
            ? AppUrlConstants.updateCountryById(this.state.activeRowId, payload)
            : AppUrlConstants.addCountryById(payload)
        func.then(() => {
            message.info("Country has been updated");
            this.setState({isModalOpen: false, activeRowId: null});
            this.formRef.current.resetFields();
        }).catch(err => {
            console.log(err);
            message.info("Failed to save the country. Failed to save please try again..");
        });
    }


    onAddBtnClick = () => {
        this.setState({activeRowId: null, isModalOpen: true}, () => {
            this.formRef.current.resetFields();
        })
    }

    onEditBtnClick(id) {
        AppUrlConstants.fetchCountryById(id).then(response => {
            this.setState({isModalOpen: true, activeRowId: id}, () => {
                let [data] = response;
                this.formRef.current.setFieldsValue({
                    full_name: data.full_name,
                    iso_alpha_2: data.iso_alpha_2,
                    iso_alpha_3: data.iso_alpha_3,
                    un_code: data.un_code,
                    deleted: data.deleted === 1,
                    list_priority: data.list_priority === 1,
                    backendName: data.backendName,
                    highRisk: data.highRisk === 1,
                    use_bsb: data.use_bsb === 1,
                    use_aba: data.use_aba === 1,
                    use_swift: data.use_swift === 1,
                    use_iban: data.use_iban === 1,
                    use_sort_code: data.use_sort_code === 1,
                    use_account_number: data.use_account_number === 1,
                    use_transit_code: data.use_transit_code === 1,
                    use_bank_code: data.use_bank_code === 1,
                    use_institution_number: data.use_institution_number === 1,
                    fatfText: data.fatfText,
                    fatfUrl: data.fatfUrl
                });
            });

        }).catch(err => console.log(err))
    }

    buildTableData() {
        var country_list_length = this.state.country_list.length;
        if (country_list_length > 0) {
            return this.state.country_list.sort((a, b) => a.full_name.localeCompare(b.full_name)).map((prop, key) => {
                return {
                    id: prop.country_id,
                    full_name: prop.full_name,
                    iso_alpha_2: prop.iso_alpha_2,
                    iso_alpha_3: prop.iso_alpha_3,
                    un_code: prop.un_code,
                    list_priority: prop.list_priority,
                    highRisk: prop.highRisk,
                    deleted: prop.deleted,
                    actions: (
                        <div className='actions-right'>
                            <Button icon={<EyeOutlined twoToneColor="#eb2f96"/> } onClick={() => this.onEditBtnClick(prop.id)}></Button>{' '}
                            <Button danger={true} icon={<DeleteOutlined twoToneColor="#52c41a"/>} onClick={() => {
                                Modal.confirm({
                                    title: 'Confirm',
                                    content: `Do you want to mark the country named ${prop.full_name} as DELETED ?`,
                                    onOk: () => this.onDeleteBtnClick(prop.id)
                            })}}></Button>{' '}
                        </div>
                    )
                };
            });
        }
    }

    onDeleteBtnClick = (id) => {
        AppUrlConstants.removeCountryById(id).then(() => {
            this.fetchCountryList();
        })
    }

    render() {

        const columns = [
            {
                title: 'ISO Alpha 2',
                dataIndex: 'iso_alpha_2',
                key: 'iso_alpha_2',
            },
            {
                title: 'ISO Alpha 3',
                dataIndex: 'iso_alpha_3',
                key: 'iso_alpha_3',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'iso_alpha_3',
                    render: (text, record) => text
                })

            },
            {
                title: 'Full Name',
                dataIndex: 'full_name',
                key: 'full_name',
                defaultSortOrder: 'ascend',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'full_name',
                    render: (text, record) => text
                })
            },
            {
                title: 'Priority',
                dataIndex: 'list_priority',
                key: 'list_priority',
                render: (text, record) => {
                    return (
                        <>
                            <strong style={{color: 'green'}}>{text == 1 ? "Priority" : ""}</strong>
                        </>
                    )
                }
            },
            {
                title: 'UN Code',
                dataIndex: 'un_code',
                key: 'un_code',
            },
            {
                title: 'High Risk',
                dataIndex: 'highRisk',
                key: 'highRisk',
                render: (text, record) => {
                    return (
                        <>
                            <strong style={{color: 'red'}}>{text == 1 ? "High Risk" : ""}</strong>
                        </>
                    )
                }
            },
            {
                title: 'Deleted',
                dataIndex: 'deleted',
                key: 'deleted',
                render: (text, record) => {
                    return (
                        <>
                            <strong style={{color: 'red'}}>{text === 1 ? "Deleted" : ""}</strong>
                        </>
                    )
                }
            },
            {
                title: 'Action',
                dataIndex: 'actions',
                key: 'actions'
            },
        ]

        return (
            <>
                <Flex justify={'end'} style={{marginBottom: '10px'}}>
                    <Button onClick={() => this.onAddBtnClick() } icon={<PlusOutlined/>} type={'primary'}> Add Country</Button>
                </Flex>
                <Table
                    // data={this.state.entity_list}
                    dataSource={this.buildTableData()}
                    columns={columns}
                />
                <Modal width={'700px'} title={this.state.activeRowId != null ? 'Update Country' : 'Add Country'}
                       open={this.state.isModalOpen} onOk={() => this.formRef.current.submit()}
                       onCancel={() => this.setState({activeRowId: null, isModalOpen: false})}>
                    <Form name={'countryForm'} layout={'horizontal'} ref={this.formRef} onFinish={this.onSubmit}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label={'Full Name'} name="full_name">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label={'ISO Alpha 2'} name="iso_alpha_2">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label={'ISO Alpha 3'} name="iso_alpha_3">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label={'UN Code'} name="un_code">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label={'Deleted'} name="deleted">
                                    <Select
                                        options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}></Select>
                                </Form.Item>
                                <Form.Item label={'List Priority'} name="list_priority">
                                    <Select options={[{label: 'Priority', value: true}, {
                                        label: 'Not in Priority',
                                        value: false
                                    }]}></Select>
                                </Form.Item>
                                <Form.Item label={'Backend Name'} name="backendName">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label={'High Risk'} name="highRisk">
                                    <Select
                                        options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}></Select>
                                </Form.Item>
                                <Form.Item label={'Use BSB'} name="use_bsb">
                                    <Select
                                        options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}></Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={'Use ABA'} name="use_aba">
                                    <Select
                                        options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}></Select>
                                </Form.Item>
                                <Form.Item label={'Use Swift'} name="use_swift">
                                    <Select
                                        options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}></Select>
                                </Form.Item>
                                <Form.Item label={'Use IBAN'} name="use_iban">
                                    <Select
                                        options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}></Select>
                                </Form.Item>
                                <Form.Item label={'Use Sort Code'} name="use_sort_code">
                                    <Select
                                        options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}></Select>
                                </Form.Item>
                                <Form.Item label={'Use Account Number'} name="use_account_number">
                                    <Select
                                        options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}></Select>
                                </Form.Item>
                                <Form.Item label={'Use Transit Code'} name="use_transit_code">
                                    <Select
                                        options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}></Select>
                                </Form.Item>
                                <Form.Item label={'Use Bank Code'} name="use_bank_code">
                                    <Select
                                        options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}></Select>
                                </Form.Item>
                                <Form.Item label={'Use Institution Number'} name="use_institution_number">
                                    <Select
                                        options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}></Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label={'FatF Url'} name="fatfUrl">
                                    <Input />
                                </Form.Item>
                                <Form.Item label={'FatF Text'} name="fatfText">
                                    <TextArea></TextArea>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </>

        );
    }
}

export default withStyles(styles)(ListTable(CountryTable));
