import {connect} from 'react-redux';
import CurrenciesTable from './CurrenciesTable';
import {
  requestEntityList,
  fetchCurrencyListNotDeleted,
  currenciesListIsDirty,
  editCurrencies
} from '../../redux/actions/currencies';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    currencies: state.currencies
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestEntityList: () => {
      dispatch(requestEntityList());
    },
    fetchCurrencyListNotDeleted: () => {
      dispatch(fetchCurrencyListNotDeleted());
    },
    currenciesListIsDirty: value => {
      dispatch(currenciesListIsDirty(value));
    },
    editCurrencies: value => {
      dispatch(editCurrencies(value));
    }
  };
};

const CurrenciesTableContainer = connect(mapStateToProps, mapDispatchToProps)(CurrenciesTable);

export default CurrenciesTableContainer;
