import React from 'react';
import {API} from 'aws-amplify';
import {connect} from 'react-redux';
import {updateNewTransferCreationTransferType, updateNewTransferCreation} from 'redux/actions/transfers_2';

import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';

import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
import {NewTransferAPI} from './NewTransferConstants';
import FormItem from 'antd/lib/form/FormItem'
import { Alert, Button, Flex, Modal, Radio, Select, Tag } from 'antd'
import { TeamAPI } from '../../Teams/TeamUtil'
import { ForwardsAPI } from '../../../views/Dashboard/Forwards/ForwardsUtil'
import { Collapse } from 'antd';

// const uuidv4 = require('uuid/v4');

const style = {
  ...customCheckboxRadioSwitch,

  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {color: '#555'},
  inputAdornment: {position: 'relative'},
  forwardWrapper: {
      backgroundColor: '#f6ffed',
      width: '100%',
      padding: '10px',
      border: '1px solid #b7eb8f',
      marginBottom: '10px'
  },
  dBlock: {
      display: 'block'
  }
};

class Step1Client extends React.Component {
  constructor(props) {
    super(props);
    this.handleUseTeamClient = this.handleUseTeamClient.bind(this);
    this.state = {
      client_list: [],
      client_id: '',
      client: {},
      team_nickname: '',
      teamClientId: null,
      // division_nickname: '',
      // entity_nickname: '',
      with_query_string: false,
        isModalOpen: false,
        forwardList: [],
        selectedForwardId: null
    };
  }

  componentDidMount() {
    // NewTransferAPI.fetchClients()
    NewTransferAPI.fetchClientsByStaffID(this.props.app_state.current_staff.id)
      .then(response => {
        this.setState({client_list: response}, () => {
          this.queryStrings();
        });
      }).catch(error => console.log(error));
    // this.restoreState();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.app_state.current_staff.id !== this.props.app_state.current_staff.id) {
      NewTransferAPI.fetchClientsByStaffID(this.props.app_state.current_staff.id)
        .then(response => {
          this.setState({client_list: response}, () => {
            this.queryStrings();
          });
        }).catch(error => console.log(error));
    }
  }

  restoreState = () => {
    if (this.props.transfers_2.new_transfer_data.client && this.props.transfers_2.new_transfer_data.client.id != null) {
      this.handleClientSelectChange(this.props.transfers_2.new_transfer_data.client.id);
    }
  }

  queryStrings() {
    const query_strings = queryString.parse(this.props.location.search);
    if (Object.keys(query_strings).length !== 0) {
      if (query_strings.client_id) {
        this.setState({client_id: Number(query_strings.client_id)});
        this.props.updateNewTransferCreation('client_id', Number(query_strings.client_id));
        this.getClientDetails(Number(query_strings.client_id));
        this.setState({with_query_string: true});
      }
      if (query_strings.clientId) {
        this.setState({client_id: Number(query_strings.clientId)});
        this.props.updateNewTransferCreation('client_id', Number(query_strings.clientId));
        this.getClientDetails(Number(query_strings.clientId));
        this.setState({with_query_string: true});
      }
    }
  }

  getClientDetails(client_id) {
    API.get('clients', `/get/id/${client_id}`)
      .then(response => {
        this.setState({client: response});

        this.props.updateNewTransferCreation('client', response);
        return [response.team_id, response.account_status];
      })
      .then(([team_id, account_status]) => {
        this.props.updateNewTransferCreation('team_id', team_id);
        TeamAPI.fetchTeamById(team_id).then(response => {
            // console.log(response)
            this.setState({
              client: {
                ...this.state.client,
                team_nickname: response.nickname
              }
            });
            if (response.teamClientId === client_id) {
              this.setState({teamClientId: client_id});
              this.props.updateNewTransferCreation('teamClientId', client_id);
            }
            // this.props.updateNewTransferCreation('division_id', response.division_id);

            if (this.state.client_id != 55) {
              // API.post('email', '/interaction/main', {
              //   body: {
              //     interaction: 'New Transfer Request Starting',
              //     data: {
              //       staff_id: this.props.app_state.current_staff.id,
              //       staff_nickname: this.props.app_state.current_staff.nickname,
              //       client_id: this.state.client.id,
              //       client_nickname: this.state.client.nickname
              //     },
              //     result: 'New transfer request starting',
              //     // sms: `Admin Portal New Transfer Step 1: [${this.state.client.id}] ${this.state.client.nickname}`
              //   }
              // }).then(response => {
              //   console.log(response);
              // });
            }
            return account_status;
          })
          // Get account status nickname from account status id
          .then(account_status => {
            API.get('clients', '/client-status/get')
              .then(response => {
                let obj;
                for (obj of response) {
                  if (obj.id === account_status) {
                    this.setState({
                      client: {
                        ...this.state.client,
                        account_status_nickname: obj.nickname
                      }
                    });
                  }
                }
              })
              .catch(error => {
                console.log(error);
              });
          })
          .catch(error => {
            console.log(error);
          });

          // FETCH FORWarDS
          this.fetchForwards();
      })
      .catch(error => {
        console.log(error);
      });
  }

    fetchForwards() {
        ForwardsAPI.fetchForwardsByClientId(this.state.client_id).then(response => {
            if (response.length > 0) {
                this.setState({forwardList: response});
            }
        }).catch(err => console.log(err));
    }

  sendState() {
    return this.state;
  }

  handleSelectChange = event => {
      console.log(event);
    this.setState({[event.target.name]: event.target.value});
    this.props.updateNewTransferCreation(event.target.name, event.target.value);
    if (event.target.name === 'client_id') {
      this.getClientDetails(event.target.value);
    }
  };

  isValidated() {
    if (this.state.client_id > 0) {
      return true;
    }
    return false;
  }

  handleClientSelectChange = (value) => {
    this.setState({client_id: value, selectedForwardId: null});
    this.props.updateNewTransferCreation('client_id', value);
    this.getClientDetails(value);
  };

  handleUseTeamClient = () => {
    const value = this.props.app_state.current_team.teamClientId;
    if (value) {
      this.setState({client_id: value});
      this.props.updateNewTransferCreation('client_id', value);
      this.getClientDetails(value);
    }
  };

  render() {
    const {classes} = this.props;

    const client_list_select_options = this.state.client_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return {
          value: item.id,
          label: `${item.nickname} [${item.id.toString().padStart(5, '0')}] (${item.email})`
        };
      });

    return (
      <React.Fragment>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              {this.state.with_query_string ? 'Please confirm client details for new transfer' : ''}
            </h4>
          </GridItem>
        </GridContainer>

        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <GridContainer>
              <GridItem xs={10} sm={10} md={10} lg={10}>
                <FormItem label={'Select Client'} name="client_id">
                    <Select
                        showSearch
                        placeholder='Type to search for client...'
                        options={client_list_select_options}
                        value={this.state.client_id}
                        filterOption={(input, option) => (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())}
                        filterSort={(optionA, optionB) =>
                            (optionA && optionA.label ? optionA.label : '').toLowerCase().localeCompare((optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase())
                        }
                        onChange={(event) => this.handleClientSelectChange(event)} />
                    {
                        this.state.forwardList.length > 0 &&
                             <Flex style={{marginTop: '5px'}} justify={'center'}> {
                                 this.state.selectedForwardId
                                     ? <>
                                        <Tag color={'success'}>
                                            <span style={{cursor: 'pointer'}} onClick={() => this.setState({isModalOpen: true})}>Forward ID - {this.state.selectedForwardId} (click to change)</span>
                                       </Tag>
                                        <span onClick={() => this.setState({selectedForwardId: null})} style={{color: 'red', cursor: 'pointer'}}><i className={'fa fa-trash'} style={{marginRight: '3px'}}></i></span>
                                      </> : <span style={{cursor: 'pointer'}} onClick={() => this.setState({isModalOpen: true})}><Tag color={'blue'}>View Forwards</Tag></span>}
                             </Flex>
                    }
                </FormItem>
              </GridItem>
            </GridContainer>
              <GridContainer>
                  <Modal title="Forwards Found" open={this.state.isModalOpen}
                         onOk={() => this.setState({isModalOpen: false})}
                         onCancel={() => this.setState({isModalOpen: false, selectedForwardId: null})}>
                      <FormItem>
                              <Collapse collapsible={'icon'} size={'small'} style={{marginBottom: '5px'}} items={this.state.forwardList.map((item, index) => {
                                      return {
                                          key: item.id,
                                          label: <>
                                              <Radio key={index} name={item.value} checked={this.state.selectedForwardId === item.id} value={item.id} onChange={() => {
                                                  this.setState({selectedForwardId: item.id});
                                                  this.props.updateNewTransferCreation('forward_detail', item);
                                                  this.props.updateNewTransferCreation('forward_id', item.id);
                                              }}></Radio>
                                              <span>Forward ID - {item.id}</span>
                                                </>,
                                          children: (
                                              <>
                                                <span className={classes.dBlock}><strong>Client Name:</strong> {item.client_nickname}</span>
                                                <span className={classes.dBlock}><strong>Amount From:</strong> {item.amount_from}</span>
                                                <span className={classes.dBlock}><strong>From Currency:</strong> {item.currency_from}</span>
                                                <span className={classes.dBlock}><strong>Amount To:</strong> {item.amount_to}</span>
                                                <span className={classes.dBlock}><strong>To Currency:</strong> {item.currency_to}</span>
                                                <span className={classes.dBlock}><strong>Staff Name:</strong> {item.staff_name}</span>
                                                <span className={classes.dBlock}><strong>Balance:</strong> {item.available_balance}</span>
                                              </>
                                          )
                                      }
                                  })} />

                      </FormItem>
                  </Modal>
              </GridContainer>
          </GridItem>
        </GridContainer>

        {Boolean(this.state.client_id) && (
          <React.Fragment>
            <p>
              <br />
              Please confirm the following details are correct
            </p>

            {this.state.client.account_type == 2 && (
              <p>
                <em style={{color: 'green'}}>
                  {this.state.client.business_company_name && (
                    <span>
                      <b>Business Name: {this.state.client.business_company_name.toUpperCase()} </b>
                    </span>
                  )}
                </em>
              </p>
            )}

            {/* if the client status is not 2 (approved) then display warning */}
            {this.state.client.account_status !== 2 && (
              <p>
                <em style={{color: 'red'}}>
                  {this.state.client.account_status_nickname && (
                    <span>
                      Warning: this client has status {this.state.client.account_status_nickname.toUpperCase()}{' '}
                    </span>
                  )}
                </em>
              </p>
            )}

            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <GridContainer justify='center'>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <CustomInput
                      labelText='First Name'
                      id='first_name'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: true,
                        value: this.state.client.first_name || ''
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <CustomInput
                      labelText='Middle Name(s)'
                      id='middle_name'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: true,
                        value: this.state.client.middle_name || ''
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <CustomInput
                  labelText='Last Name'
                  id='last_name'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: true,
                    value: this.state.client.last_name || ''
                  }}
                />
                <CustomInput
                  labelText='Email'
                  id='email'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: true,
                    value: this.state.client.email || ''
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={6}>
                <CustomInput
                  labelText='Team'
                  id='team_nickname'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: true,
                    value: this.state.client.team_nickname || ''
                  }}
                />{' '}
                <br />
                {this.props.transfers_2.new_transfer_data.teamClientId && (
                  <CustomInput
                    labelText='Team Client'
                    // id='team_nickname'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      disabled: true,
                      value: `This client is the TEAM client for ${this.state.client.team_nickname}`
                    }}
                  />
                )}
              </GridItem>
            </GridContainer>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers_2: state.transfers_2,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewTransferCreationTransferType: transfer_type => {
      dispatch(updateNewTransferCreationTransferType(transfer_type));
    },
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    }
  };
};

const Step1ClientContainer = connect(mapStateToProps, mapDispatchToProps)(Step1Client);

export default withRouter(withStyles(style)(Step1ClientContainer));
