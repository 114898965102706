import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

// material-ui icons
import Menu from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';

// core components
import HeaderLinks from './HeaderLinks';
import Button from 'components/CustomButtons/Button.jsx';

import headerStyle from 'assets/jss/material-dashboard-pro-react/components/headerStyle.jsx';

//setup for language components
const languageObj_en_json = require('../../translations/en.json');
const languageObj_zh_json = require('../../translations/zh.json');
const languageObj_de_json = require('../../translations/de.json');
const languageObj_es_json = require('../../translations/es.json');
const languageObj_fr_json = require('../../translations/fr.json');
const languageObj_it_json = require('../../translations/it.json');
const languageObj_pt_json = require('../../translations/pt.json');

function Header({...props}) {
  function makeBrand() {
    let name;
    //setup language obj to use
    //this.props.language.language_current_ui
    let languageObj = '';
    switch (props.language.language_current_ui) {
      case 'es':
        languageObj = languageObj_es_json;
        //menu_item_text = prop.name_es || prop.name;
        break;
      case 'de':
        languageObj = languageObj_de_json;
        //menu_item_text = prop.name_de || prop.name;
        break;
      case 'zh':
        languageObj = languageObj_zh_json;
        //menu_item_text = prop.name_zh || prop.name;
        break;
      case 'fr':
        languageObj = languageObj_fr_json;
        //menu_item_text = prop.name_fr || prop.name;
        break;
      case 'it':
        languageObj = languageObj_it_json;
        //menu_item_text = prop.name_it || prop.name;
        break;
      case 'pt':
        languageObj = languageObj_pt_json;
        //menu_item_text = prop.name_pt || prop.name;
        break;
      default:
        languageObj = languageObj_en_json;
        //menu_item_text = prop.name;
        break;
    }
    props.routes.map((prop, key) => {
      if (prop.collapse) {
        //for edit pages
        if (
          prop.views[1] &&
          props.location.pathname.split('/', 3)[1] + props.location.pathname.split('/', 3)[2] ===
            prop.views[1].path.split('/', 3)[1] + prop.views[1].path.split('/', 3)[2]
        ) {
          try {
            if (prop.views[1].path.split('/', 3)[2].includes('edit')) {
              name = languageObj['edit.' + prop.views[1].path.split('/', 3)[1] + '.header'];
            }
          } catch (err) {
            name = prop.name;
          }
        }
        //for new and listing pages
        prop.views.map((prop, key) => {
          if (prop.path === props.location.pathname) {
            try {
              //temp doing this for staffprofile
              if (prop.path.split('/', 3)[2] === 'staffprofile') {
                //console.log(prop.path.split("/",3)[2]);
                name = languageObj[String('icon.' + prop.path.split('/', 3)[2])];
              }
              //other sub components
              else if (prop.path === '/clients/new') {
                name = languageObj['icon.clients.newClient'];
              } else if (prop.path === '/clients/list') {
                name = languageObj['icon.clients.clientList'];
              } else if (prop.path === '/payment/new') {
                name = languageObj['icon.transfers.newPayment'];
              } else if (prop.path === '/transfers/new') {
                name = languageObj['icon.transfers.new'];
              } else if (prop.path === '/transfers/list') {
                name = languageObj['icon.transfers.transferList'];
              } else if (
                prop.path === '/beneficiaries/create') {
                  name = languageObj['icon.beneficiaries.newBeneficiary'];
              } else if (
                prop.path === '/beneficiaries/new') {
                  name = languageObj['icon.beneficiaries.newBeneficiary'];
              } else if (
                prop.path === '/beneficiaries/list') {
                name = languageObj['icon.beneficiaries.beneficiariesList'];
              } else if (
                prop.path === '/identification/new') {
                name = languageObj['icon.identification.newIdentification'];
              } else if (
                prop.path === '/identification/list') {
                name = languageObj['icon.identification.identificationList'];
              } else if (prop.path === '/reports/transfer') {
                name = languageObj['icon.reports.transferList'];
              } else if (prop.path === '/reports/client') {
                name = languageObj['icon.reports.clientList'];
              } else if (prop.path === '/reports/pending-clients') {
                name = languageObj['icon.reports.clientPendingList'];
              } else if (prop.path === '/settings/stafflist') {
                name = languageObj['icon.staff.staffList'];
              } else {
                name = prop.name;
              }
            } catch (err) {
              //console.log("!!");
              name = prop.name;
            }
          }
          return null;
        });
      }
      if (prop.path === props.location.pathname) {
        switch (prop.path) {
          case '/dashboard':
            // for dashboard
            //console.log(prop.path.split("/",2)[1]);
            name = languageObj[String('icon.' + prop.path.split('/', 2)[1])]; //prop.name;
            break;
          case '/settings/staffprofile':
            //console.log(prop.path.split("/",2)[1].split("-")[0]+prop.path.split("/",2)[1].split("-")[1]);
            name =
              languageObj[
                String('icon.' + prop.path.split('/', 2)[1].split('-')[0] + prop.path.split('/', 2)[1].split('-')[1])
              ]; //prop.name;
            break;
          case '/clients/new':
            // for new clients
            name = languageObj['icon.clients.newClient']; //prop.name;
            break;
          default: //prop.name;
            // for dashboard and other components
            name = languageObj[String('icon.' + prop.path.split('/', 2)[1])];
            break;
        }
      }
      return null;
    });
    //console.log(name);
    if (name) {
      return name;
    }
    return 'Forex WorldWide';
  }
  const {classes, color, rtlActive} = props;
  const appBarClasses = cx({[' ' + classes[color]]: color});
  const sidebarMinimize = classes.sidebarMinimize + ' ' + cx({[classes.sidebarMinimizeRTL]: rtlActive});
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation='css'>
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button justIcon round color='white' onClick={props.sidebarMinimize}>
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button justIcon round color='white' onClick={props.sidebarMinimize}>
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button href='#' className={classes.title} color='transparent'>
            {makeBrand()}
          </Button>
        </div>
        <Hidden smDown implementation='css'>
          <HeaderLinks
            isAuthenticated={props.isAuthenticated}
            userHasAuthenticated={props.userHasAuthenticated}
            handleLogout={props.handleLogout}
          />
        </Hidden>
        <Hidden mdUp implementation='css'>
          <Button
            className={classes.appResponsive}
            color='transparent'
            justIcon
            aria-label='open drawer'
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  isAuthenticated: PropTypes.bool
};

export default withStyles(headerStyle)(Header);
