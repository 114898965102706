import React, {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import PermIdentity from '@material-ui/icons/PermIdentity';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import Forwards from '../../../components/Forwards';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import {fetchForwardList} from '../../../redux/actions/forwards';
import {connect} from 'react-redux';
import { Button } from 'antd'
import {withRouter} from "react-router-dom";


const styles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center'
    },
    description: {color: '#999999'},
    updateProfileButton: {float: 'right'},
    title: {
        color: '#3C4858',
        textDecoration: 'none'
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between'
    }
};

const ForwardsList = ({fetchForwardList, classes, app_state, history}) => {
    return (
        <div>
            <Card>
                <CardHeader color='rose' icon>
                    <CardIcon color='rose'>
                        <PermIdentity />
                    </CardIcon>
                    <div className={classes.flexEnd}>
                        <h4 className={classes.cardIconTitle}>
                            <FormattedMessage id='forwardList.cardItem1.forwardListing' defaultMessage={'Forwards Listing'} />
                        </h4>
                        <div style={{marginTop: '10px'}}>
                            <Button style={{marginLeft: '10px'}} type='primary' onClick={() => history.push('/forwards/new')}>
                                <FormattedMessage id='newTransfer.cardItem1.createNew' defaultMessage={'Crate New'} />
                            </Button>
                            <Button style={{marginLeft: '10px'}} type='primary' danger onClick={() => fetchForwardList()}>
                                <FormattedMessage id='newTransfer.cardItem1.refresh' defaultMessage="Refresh" />
                            </Button>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12}>
                            <Forwards current={true} status={[8]} />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </div>
    );
};

const mapStateToProps = state => {
    return {app_state: state.app_state};
};

const mapDispatchToProps = dispatch => {
    return {fetchForwardList: () => dispatch(fetchForwardList())};
};

const PastForwardsListContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(ForwardsList)));

export default PastForwardsListContainer;
