import React, { createRef, useRef } from 'react'
// react component for creating dynamic tables
import { Button as AButton, Divider, Flex, Input, Popconfirm, Select, Table, Tag, message, Space } from 'antd'


import { API } from 'aws-amplify';
import { NavLink } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import { AiOutlineSearch } from 'react-icons/ai';
import { withRouter } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from 'components/CustomButtons/Button.jsx';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import { BeneficiaryAPI, BeneficiaryUtils } from './BeneficiaryUtils'
import { TeamAPI } from '../Teams/TeamUtil'
import { AppUrlConstants } from '../../AppAPI'
import DataGrid from '../DataGrid/DataGrid'
import { SearchOutlined } from '@material-ui/icons'
import ListTable from "../ListTable/ListTable";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    },
    mr1: {
        marginRight: '.7em'
    }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - BeneficiaryList - copied from ClientList
 * ----------------------------------------------------------------------------
 */
class BeneficiaryList extends React.Component {

    searchInput = createRef();
    constructor(props) {
        super(props);
        this.state = {
            entity_list: [],
            team_list: [],
            currencies_list: [],
            beneficiaryList: [],
            teamId: null,
            beneficiaryName: null,
            clientName: null,
            searchText: null,
            searchColumn: null
        };
    }

    componentDidMount() {
        AppUrlConstants.fetchCurrencies()
            .then(response => this.setState({ currencies_list: response }))
            .catch(error => console.log(error));
        this.getTeamList();
        this.fetchBeneficiaryList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
            this.getTeamList()
            this.fetchBeneficiaryList()
        }
    }

    getTeamList = () => {
        if (this.props.app_state.current_staff) {
            TeamAPI.fetchTeamList(this.props.app_state.current_staff.id)
                .then(response => {
                    const teamList = response.sort((a, b) => a.nickname.localeCompare(b.nickname))
                    const teamListSelect = teamList.map((item) => ({
                        text: item.nickname,
                        label: item.nickname,
                        value: item.id
                    }))
                    this.setState({
                        team_list: teamList,
                        teamListSelect: teamListSelect,
                        team_id: teamList[0].id
                    });
                })
                .catch(error => console.log(error));
        }
    }


    fetchBeneficiaryList = () => {
        if (!this.props.app_state.current_staff) {
            this.setState({ beneficiaryList: [] });
        } else {
            const staffID = this.props.app_state.current_staff.id
            BeneficiaryAPI.fetchBeneficiaryList(staffID)
                .then(response => this.processBeneficiaryResponse(response))
                .catch(error => console.log(error));
        }
    }

    fetchBeneficiaryListByTeamID = (teamID) => {
        this.setState({ beneficiaryList: [] });
        BeneficiaryAPI.fetchBeneficiaryByTeamId(teamID)
            .then(response => this.processBeneficiaryResponse(response))
            .catch(error => console.log(error));
    }

    processBeneficiaryResponse = (response) => {
        response.sort((a,b) => a.nickname === null ? -1 : ((b.nickname === null) ? 1 : a.nickname.localeCompare(b.nickname)));
        let currencyList = []
        let currencySelectList = []
        for (let i = 0; i < response.length; i++) {
            const element = response[i];
            if (element.account_currency_iso_alpha_3 && currencyList.indexOf(element.account_currency_iso_alpha_3) === -1) {
                currencyList.push(element.account_currency_iso_alpha_3)
                currencySelectList.push({ text: element.account_currency_iso_alpha_3, value: element.account_currency_iso_alpha_3 })
            }
        }
        this.setState({
            beneficiaryList: BeneficiaryUtils.prepareDataBody(response),
            currencySelectList: currencySelectList.sort((a, b) => a.text.localeCompare(b.text))
        });
    }

    onChange = (value) => {
        if (!value) {
            this.fetchBeneficiaryList()
        }
        this.fetchBeneficiaryListByTeamID(value)
    };

    onSelect = (value) => console.log('search:', value);

    handleSelectChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        this.fetchBeneficiaryListByTeamID(event.target.value);
    };

    getFilter = (filter, onChange) => {
        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <AiOutlineSearch />
                    <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
                </div>
            </React.Fragment>
        );
    };

    toggleActive = (beneficiaryId, activeId) => {
        message.info(`Toggling beneficiary to ${activeId === 0 ? 'Inactive' : 'Active'}...`).then(() => {
            BeneficiaryAPI.toggleBeneficiaryActive(beneficiaryId, activeId)
                .then(() => {
                    message.success(`Beneficiary ${activeId === 0 ? 'Inactive' : 'Active'}`);
                    this.fetchBeneficiaryList();
                })
                .catch(() => message.error("Unable to set beneficiary to active..Try again."));
        })
    }

    activeRef = (row) => {
        if (row.active) {
            return <Tag color='success' style={{ width: 70, textAlign: "center", cursor: 'pointer' }} onClick={() => this.toggleActive(row.id, 0)}>Active</Tag>
        } else {
            return <Tag color='error' style={{ width: 70, textAlign: "center", cursor: 'pointer' }} onClick={() => this.toggleActive(row.id, 1)}>Inactive</Tag>
        }

    }

    accountCurrencyRef = (row) => {
        const link = `https://wise.com/public-resources/assets/flags/rectangle/${row.account_currency_iso_alpha_3 ? row.account_currency_iso_alpha_3.toLowerCase() : ''}.png`
        return (
            <>
                <img src={link} alt={row.account_currency_iso_alpha_3} style={{ height: 16, width: 16, verticalAlign: 'middle', marginBottom: '4px' }} />
                &nbsp;
                {row.account_currency_iso_alpha_3}
            </>)
    }

    actionRef = (row) => {
        return <Flex justify={'space-between'}>
            <AButton type={'primary'} className={this.props.classes.mr1}><NavLink
                to={`/beneficiaries/edit/${row.id}`}
            >
                <i className={'fa fa-eye'}></i>
            </NavLink></AButton>
            <hr />
            <Popconfirm
                title="Delete the Beneficiary"
                description="Are you sure to delete this Beneficiary?"
                onConfirm={() => this.onDeleteBtnClick(row.id)}
                okText="Yes"
                cancelText="No"
            >
                <AButton type={'primary'} danger><i className={'fa fa-trash'}></i></AButton>
            </Popconfirm>
        </Flex>
    }

    handleSearch = (clearFilters, selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({searchText: selectedKeys[0], searchColumn: dataIndex});
    };

    handleReset = (clearFilters, confirm) => {
        clearFilters();
        this.setState({searchText: ''});
        confirm();
    };

    render() {
        const { classes } = this.props;
        const team_name = this.props.beneficiaries.beneficiary_list
            .filter(u => u.team_name != null)
            .map((prop, key) => {
                // eslint-disable-next-line react/jsx-key
                return prop.team_name;
            });
        const team_name_unique = [...new Set(team_name)];
        const team_name_optionlist = team_name_unique.map((prop, key) => {
            // eslint-disable-next-line react/jsx-key
            return <option value={prop}>{prop}</option>;
        });
        const division_name = this.props.beneficiaries.beneficiary_list
            .filter(u => u.division_name != null)
            .map((prop, key) => {
                return prop.division_name;
            });
        const division_name_unique = [...new Set(division_name)];
        const division_name_optionlist = division_name_unique.map((prop, key) => {
            // eslint-disable-next-line react/jsx-key
            return <option value={prop}>{prop}</option>;
        });
        const entity_name = this.props.beneficiaries.beneficiary_list
            .filter(u => u.entity_name != null)
            .map((prop, key) => {
                // eslint-disable-next-line react/jsx-key
                return prop.entity_name;
            });

        const onDeleteBtnClick = (recordId) => {
            message.info("Removing beneficiary..Please wait..", 1)
                .then(() => {
                    BeneficiaryAPI.removeBeneficiary(recordId).then((response) => {
                        message.success("Beneficiary has been deleted", 3);
                        this.fetchBeneficiaryList();
                    }).catch(err => {
                        console.log(err);
                        message.error("Unable to delete the beneficiary. Please try again..", 3);
                    })
                });
        }

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                showSorterTooltip: false,
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.id  - b.id,
            },
            {
                title: 'Currency',
                dataIndex: 'account_currency_iso_alpha_3',
                key: 'account_currency_iso_alpha_3',
                filters: this.state.currencySelectList,
                filterSearch: true,
                onFilter: (value, record) => record.account_currency_iso_alpha_3 && record.account_currency_iso_alpha_3.startsWith(value),
                render: (text) => {
                    // const currentRecord = this.state.currencies_list.find((currency) => currency.id === text);
                    const link = `https://wise.com/public-resources/assets/flags/rectangle/${text ? text.toLowerCase() : ''}.png`
                    return (
                        <>
                            <img src={link} style={{ height: 16, width: 16, verticalAlign: 'middle', marginBottom: '4px' }} />
                            &nbsp;
                            {text}
                        </>
                    )
                },
            },
            {
                title: 'Active',
                dataIndex: 'active',
                filters: [{value: 1, text: 'Active'},{value:0 , text: 'Inactive'}],
                filterSearch: true,
                onFilter: (value, record) => record.active === value,
                render: (text, record) => {
                    switch (record.active) {
                        case 1:
                            return <Tag color='success' style={{ width: 70, textAlign: "center", cursor: 'pointer' }} onClick={() => this.toggleActive(record.id, 0)}>Active</Tag>
                        default:
                            return <Tag color='error' style={{ width: 70, textAlign: "center", cursor: 'pointer' }} onClick={() => this.toggleActive(record.id, 1)}>Inactive</Tag>
                    }
                },

            },
            {
                title: 'Beneficiary',
                dataIndex: 'nickname',
                key: 'nickname',
                filterSearch: true,
                sorter: (a, b) => (a.nickname || '').localeCompare(b.nickname, undefined, { sensitivity: 'base' }),
                ...this.props.getColumnSearchProps({
                    dataIndex: 'nickname',
                    label: 'Beneficiary',
                    searchIndex: ['nickname', 'ben_legal_name'],
                    render: (text, record) => {
                        // console.log(record)
                        return <>
                            <strong>{text}</strong>
                            <br />
                            <em>
                                <small>
                                    {/* {(text || "").localeCompare(record.ben_legal_name) ? null : record.ben_legal_name} */}
                                    {record.ben_legal_name}
                                </small>
                            </em>
                            <br/>
                            <span style={{ lineHeight: '20%' }}>
                                {record.ben_address_line_1} <br />{record.ben_address_suburb} {record.ben_address_state} {record.ben_address_postcode}
                            </span>
                        </>
                    }
                })
            },
            {
                title: 'Team',
                dataIndex: 'team_name',
                key: 'team_name',
                filters: this.state.teamListSelect,
                filterSearch: true,
                onFilter: (value, record) => {
                    console.log(record);
                    return record.team_id === value;
                }
            },
            {
                title: 'Client',
                dataIndex: 'client_nickname',
                key: 'client_nickname',
                sorter: (a, b) => (a.client_nickname || '').localeCompare(b.client_nickname, undefined, { sensitivity: 'base' }),
                ...this.props.getColumnSearchProps({
                    dataIndex: 'client_nickname',
                    label: 'Client',
                    render: (text, record) => {
                        return (
                            <>
                                {record.client_nickname}
                            </>
                        )
                    },
                })
            },
            {
                title: 'Beneficiary Bank',
                dataIndex: 'bank_legal_name',
                key: 'bank_legal_name',
                sorter: (a, b) => (a.bank_legal_name || '').localeCompare(b.bank_legal_name, undefined, { sensitivity: 'base' }),
                ...this.props.getColumnSearchProps({
                    dataIndex: 'bank_legal_name',
                    label:'Beneficiary Bank',
                    render: (text, record) => {
                        return (
                            <>
                                <strong>{text}</strong><br />
                                <span style={{ lineHeight: '20%' }}>
                                {record.bank_address_line_1} <br />{record.bank_address_suburb} {record.bank_address_state} {record.bank_address_postcode}
                            </span>
                            </>
                        )
                    },
                })
            },
            {
                title: 'Account',
                dataIndex: 'bank_address_country_full_name',
                key: 'bank_address_country_full_name',
                render: (text, record) => {
                    return (
                        <React.Fragment>
                            <strong>{record.bank_address_country_full_name}</strong>
                            <Divider style={{ marginTop: 4, marginBottom: 4 }} />
                            <span style={{ lineHeight: '20%' }}>
                                {record.swift_code ? <span><strong>SWIFT: </strong>{record.swift_code}<br /></span> : ''}
                                {record.bsb_code ? <span><strong>BSB: </strong>{record.bsb_code}<br /></span> : ''}
                                {record.account_number ? <span><strong>Account: </strong>{record.account_number}<br /></span> : ''}
                                {record.iban ? <span><strong>IBAN: </strong>{record.iban}<br /></span> : ''}
                                {record.aba_routing_number ? <span><strong>ABA: </strong>{record.aba_routing_number}<br /></span> : ''}
                                {record.sort_code ? <span><strong>Sort: </strong>{record.sort_code}<br /></span> : ''}
                            </span>
                        </React.Fragment>
                    );
                }
            },
            {
                title: 'Risk',
                dataIndex: 'riskRating',
                key: 'riskRating',
                filters: [{value: null, text: '-'}, {value: 1, text: 'LOW'}, {value: 2, text: 'MEDIUM'}, {value: 3, text: 'HIGH'}],
                onFilter: (value, record) => record.riskRating === value,
                render: (text, record) => {
                    switch (record.riskRating) {
                        case 1:
                            return <Tag color='success' style={{ width: 100, textAlign: "center" }}>Low</Tag>
                        case 2:
                            return <Tag color='warning' style={{ width: 100, textAlign: "center" }}>Medium</Tag>
                        case 3:
                            return <Tag color='danger' style={{ width: 100, textAlign: "center" }}>High</Tag>
                    }
                },
            },
            {
                title: 'Actions',
                key: 'actions',
                render(text, record) {
                    return (
                        <div className='actions-right'>
                            <Flex justify={'space-between'}>
                                <AButton type={'primary'} className={classes.mr1}><NavLink
                                    to={`/beneficiaries/edit/${record.id}`}
                                >
                                    <i className={'fa fa-eye'}></i>
                                </NavLink></AButton>
                                <hr />
                                <Popconfirm
                                    title="Delete the Beneficiary"
                                    description="Are you sure to delete this Beneficiary?"
                                    onConfirm={() => onDeleteBtnClick(record.id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <AButton type={'primary'} danger><i className={'fa fa-trash'}></i></AButton>
                                </Popconfirm>

                            </Flex>
                        </div>
                    );
                }
            }
        ]


        return (
            <React.Fragment>
                <Select
                    showSearch
                    placeholder="Select a team"
                    optionFilterProp="children"
                    onChange={this.onChange}
                    onSearch={this.onSelect}
                    allowClear
                    filterOption={(input, option) => {
                        if (option && option.label) {
                            return option.label.toLowerCase().includes(input.toLowerCase())
                        }
                    }}
                    options={this.state.teamListSelect}
                    style={{ width: 300, marginBottom: 16, marginRight: 36 }}
                />
            <Table
              dataSource={this.state.beneficiaryList}
              columns={columns} />
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(ListTable(BeneficiaryList)));