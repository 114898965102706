import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';

import Email from '@material-ui/icons/Email';

// core components
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//core for react-intl
import {FormattedMessage} from 'react-intl';

// import settingsStaffProfileStyles from "./SettingsStaffProfileStyles";
// import PermIdentity from "@material-ui/icons/PermIdentity";

// import avatar from "../../assets/img/faces/blank-profile-picture.jpg";
import {API} from 'aws-amplify';
import {Auth} from 'aws-amplify';

import {cardTitle} from '../../../../assets/jss/material-dashboard-pro-react.jsx';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

class ChangeEmailDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      email_state: '',
      confirm_code: '',
      confirm_code_state: '',
      is_sending_code: false,
      code_sent: false,
      is_confirming: false
    };
  }

  async componentDidMount() {}

  async componentWillReceiveProps(nextProps, nextContext) {}

  async saveStaffMember(email) {
    console.log(email);
    return API.put('staff', `/update/id/${this.props.staff_id}`, {
      body: {
        email: email
      }
    });
  }

  handle_change_email_proceed = async () => {
    this.setState({is_sending_code: true});
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(currentUser, {email: this.state.email});
      this.setState({code_sent: true});
    } catch (e) {
      alert(e.message);
      this.setState({is_sending_code: false});
    }
  };

  handle_change_email_confirm = async () => {
    // event.preventDefault();

    this.setState({is_confirming: true});

    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', this.state.confirm_code);
      this.saveStaffMember(this.state.email);
      this.props.on_close();
      this.setState({
        email: '',
        email_state: '',
        confirm_code: '',
        confirm_code_state: '',
        is_sending_code: false,
        code_sent: false,
        is_confirming: false
      });
      window.location.reload(true);
      return;
    } catch (e) {
      console.log(e);
      if ((e.code = 'ExpiredCodeException')) {
        Auth.resendSignUp(this.state.email)
          .then(() => console.log('code resent'))
          .catch(err => console.log(err));
        return;
      }
      alert(e.message);
      this.setState({is_confirming: false});
    }
  };

  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  verifyEmail(value) {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        if (this.state.email === '') {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
    // this.props.updateNewClientCreation(stateName, event.target.value)
  }

  handleChange = event => {
    console.log(event.target.value);
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  render_update_form() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <CustomInput
          success={this.state.email_state === 'success'}
          error={this.state.email_state === 'error'}
          labelText='Email Address'
          id='email'
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            startAdornment: (
              <InputAdornment position='start' className={classes.inputAdornment}>
                <Email className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
            value: this.state.email,
            onChange: event => this.change(event, 'email', 'email'),
            type: 'email'
          }}
        />
      </React.Fragment>
    );
  }

  render_update_action_button() {
    const {classes} = this.props;
    return (
      <Button
        onClick={() => this.handle_change_email_proceed()}
        color='primary'
        autoFocus
        disabled={!(this.state.email_state === 'success')}
      >
        <FormattedMessage id='staffProfile.emailDialog.confirm' defaultMessage={`Change Email`} />
      </Button>
    );
  }

  render_confirmation_form() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <CustomInput
          success={this.state.confirm_code_state === 'success'}
          error={this.state.confirm_code_state === 'error'}
          labelText='Confirmation Code'
          id='confirm_code'
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            value: this.state.confirm_code,
            onChange: event => this.change(event, 'confirm_code', 'length', 4),
            type: 'tel'
          }}
        />
      </React.Fragment>
    );
  }

  render_confirmation_action_button() {
    const {classes} = this.props;
    return (
      <Button
        onClick={() => this.handle_change_email_confirm()}
        color='primary'
        autoFocus
        disabled={!(this.state.confirm_code_state === 'success')}
      >
        <FormattedMessage id='staffProfile.emailDialog.confirm' defaultMessage={`Change Email`} />
      </Button>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            <FormattedMessage
              id='staffProfile.emailDialog.title'
              defaultMessage={`Enter your new email address for username`}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {!this.state.code_sent ? this.render_update_form() : this.render_confirmation_form()}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.on_close()} color='info'>
              <FormattedMessage id='staffProfile.emailDialog.cancel' defaultMessage={`Cancel`} />
            </Button>
            {!this.state.code_sent ? this.render_update_action_button() : this.render_confirmation_action_button()}
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ChangeEmailDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(ChangeEmailDialog));
