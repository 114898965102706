import React, {useEffect, useState} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from "react-redux";
import ListTable from "../../../components/ListTable/ListTable";
import {Button, Card, Flex, Form, Input, message, Modal, Select, Spin, Table} from "antd";
import {BulbOutlined, DeleteOutlined, EyeOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {BackOfficeAPI} from "./BackOfficeAPI";
import {BiBuilding} from "react-icons/bi";
import {fetchCountryListNotDeleted} from "../../../redux/actions/countries";
import {AppUrlConstants} from "../../../AppAPI";
import {withRouter} from "react-router-dom";

const styles = {
    marginRight1: {
        marginRight: '1em',
    },
    positionRelative: {
        position: 'relative'
    }
}
const RemittingAccounts = ({ getColumnSearchProps: columnProps, classes, app_state, history }) => {

    const [form] = useForm();
    const [state, setState] = useState({
        modalTitle: 'Add Remitting Account',
        tableDataLoaded: true,
        formDataLoaded: true,
        formUpdateId: null,
        isModalOpen: false,
        dataList: [],
        entity: [],
        entityBankAccountList: [],
        countriesList: []
    });
    const updateState = (newState) => setState(prev => ({...state, ...newState}));

    const removeBankAccount = (id) => {
        BackOfficeAPI.updateRemittingBankAccount(id, { eba_deleted: 1 }).then(() => {
            message.success("Bank Account removed...");
            fetchRemittingAccounts();
        })
    }

    const buildColumns = () => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: (a, b) => a.id - b.id,
                defaultSortOrder: 'descend',
                ...columnProps({
                    dataIndex: 'id',
                    render: (text, record) => text
                })
            },
            {
                title: 'Account Name',
                dataIndex: 'eba_accout_name',
                ...columnProps({
                    dataIndex: 'eba_accout_name',
                    render: (text, record) => text
                })
            },
            {
                title: 'Swift',
                dataIndex: 'eba_swift',
                sorter: (a, b) => a.eba_swift - b.eba_swift,
                ...columnProps({
                    dataIndex: 'eba_swift',
                    render: (text, record) => text
                })
            },
            {
                title: 'BSB',
                dataIndex: 'eba_bsb',
                sorter: (a, b) => a.eba_bsb - b.eba_bsb,
                ...columnProps({
                    dataIndex: 'eba_bsb',
                    render: (text, record) => text
                })
            },
            {
                title: 'Account Number',
                dataIndex: 'eba_accout_number',
                sorter: (a, b) => a.eba_accout_number - b.eba_accout_number,
                ...columnProps({
                    dataIndex: 'eba_accout_number',
                    render: (text, record) => text
                })
            },
            {
                title: 'Reference',
                dataIndex: 'reference_mandatory',
                sorter: (a, b) => a.reference_mandatory - b.reference_mandatory,
                ...columnProps({
                    dataIndex: 'reference_mandatory',
                    render: (text, record) => text
                })
            },
            {
                title: '',
                dataIndex: 'action',
                render: (text) => {
                    return <Flex justify={'start'}>
                        <Button type={'link'} icon={<EyeOutlined />} onClick={() => {
                            history.push(`/backoffice/remitting-accounts/edit/${text}`);
                        }}></Button>
                        <Button
                            type={'link'}
                            danger={true}
                            onClick={() => {
                                Modal.confirm({
                                    title: `Are you sure ?`,
                                    content: `Are you sure you want to delete bank account #${text}`,
                                    onOk: () => removeBankAccount(text),
                                    footer: (_, { OkBtn, CancelBtn }) => (
                                        <>
                                            <CancelBtn />
                                            <OkBtn />
                                        </>
                                    ),
                                });
                            }} icon={<DeleteOutlined />}></Button>
                    </Flex>
                }
            }
        ];
    }

    const fetchRemittingAccounts = async () => {
        updateState({tableDataLoaded: false});
        const res = await BackOfficeAPI.fetchRemittingBankAccounts().catch(err => {
            console.log(err);
            updateState({dataList: [], tableDataLoaded: true});
        });
        updateState({dataList: res.map(item => ({...item, action: item.id})), tableDataLoaded: true});
    }

    const fetchInitials = async () => {
        const [countries, bankAccounts, remitAccounts, accounts] = await Promise.all([
            AppUrlConstants.fetchCountries(),
            AppUrlConstants.fetchEntityBanks(),
            AppUrlConstants.fetchEntity(),
            BackOfficeAPI.fetchRemittingBankAccounts()
        ]);
        updateState({
            countriesList: countries.map(item => ({label: item.full_name, value: item.id})),
            entityBankAccountList: bankAccounts.map(item => ({label: item.nickname, value: item.id })),
            entity: remitAccounts.map(item => ({label: item.nickname, value: item.id })),
            dataList: accounts.map(item => ({...item, action: item.id})),
            tableDataLoaded: true
        })

    }

    const onFormSubmit = (values) => {
        updateState({ formDataLoaded: false });
        const request = state.formUpdateId == null
            ? BackOfficeAPI.saveRemittingBankAccount(values)
            : BackOfficeAPI.updateRemittingBankAccount(state.formUpdateId, values);
        request.then(() => {
            message.success("Remitting account saved..");
            form.resetFields();
            updateState({ isModalOpen: false, formUpdateId: null, formDataLoaded: true });
        }).catch(err => {
            console.log(err);
            updateState({ formDataLoaded: true });
            message.success("Failed to save remitting account. Please try again..")
        });
    }

    useEffect(() => {
            fetchInitials();
    }, [0]);

    return (
        <>
            <Modal open={state.isModalOpen}
                   onOk={() => form.submit() }
                   onCancel={() => {
                       updateState({ formUpdateId: null, isModalOpen: false });
                       form.resetFields()
                   }}
                   title={state.modalTitle}>
                <div className={classes.positionRelative}>
                    <Spin tip="Loading..." style={{ position: 'absolute', top: '50%', left: '50%' }} spinning={!state.formDataLoaded}/>
                    <Form layout={'vertical'} name={'remitting-account'} form={form} onFinish={onFormSubmit}>
                        <Form.Item label={'Remitting Account'} name="entity_id">
                            <Select allowClear={true}
                                    placeholder={'-- SELECT ENTITY ACCOUNT --'}
                                    showSearch={true}
                                    optionFilterProp={'label'}
                                    options={state.entity}></Select>
                        </Form.Item>
                        <Form.Item label={'Entity Bank Account'} name="entity_bank_id">
                            <Select allowClear={true}
                                    placeholder={'-- SELECT BANK ACCOUNT --'}
                                    showSearch={true}
                                    optionFilterProp={'label'}
                                    options={state.entityBankAccountList}></Select>
                        </Form.Item>
                        <Form.Item name="address_country" label={'Country'}>
                            <Select allowClear={true}
                                    placeholder={'-- SELECT COUNTRY --'}
                                    optionFilterProp={'label'}
                                    showSearch={true}
                                    options={state.countriesList}></Select>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <Card title={<>
                <Flex justify={'space-between'}>
                    <h4><BiBuilding /> Bank Accounts</h4>
                    <div>
                        <Button
                            className={classes.marginRight1}
                            type={'primary'}
                            onClick={() => {
                                updateState({isModalOpen: true, formUpdateId: null });
                                form.resetFields();
                            }}
                            icon={<PlusOutlined/>}>Add Bank Account</Button>
                        <Button
                            type={'primary'}
                            danger={true}
                            icon={<ReloadOutlined/>} onClick={() => fetchRemittingAccounts() }>Refresh Page</Button>
                    </div>
                </Flex>
            </>}>
                <Table columns={buildColumns()}
                       dataSource={state.dataList}
                       loading={!state.tableDataLoaded}>
                </Table>
            </Card>
        </>
    )
}


const mapStateToProps = state => {
    return {
        app_state: state.app_state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCountryList: () => {
            dispatch(fetchCountryListNotDeleted());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(ListTable(RemittingAccounts))));