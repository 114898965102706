import {Checkbox} from '@material-ui/core';
import {Check} from '@material-ui/icons';
import React from 'react';
import Button from '../../CustomButtons/Button';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';

export const Security2 = ({classes, handleSubmit, state, handleToggle}) => {
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <h2>General</h2>
            <Checkbox
              checked={state.super_admin === 1 || state.super_admin === true}
              value='super_admin'
              onClick={handleToggle('super_admin')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Super Admin
            <br />
            <Checkbox
              checked={state.backofficeStaff === 1 || state.backofficeStaff === true}
              value='backofficeStaff'
              onClick={handleToggle('backofficeStaff')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Back Office Admin
            <br />
            <Checkbox
              checked={state.security_seeTeamClients === 1 || state.security_seeTeamClients === true}
              value='security_seeTeamClients'
              onClick={handleToggle('security_seeTeamClients')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            See Team Clients
            <br />
            <Checkbox
              checked={state.security_team_admin === 1 || state.security_team_admin === true}
              value='security_team_admin'
              onClick={handleToggle('security_team_admin')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Security Team Admin
            <br />
              <Checkbox
                  checked={state.sales_staff === 1 || state.sales_staff === true}
                  value='sales_staff'
                  onClick={handleToggle('sales_staff')}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                  }}
                  inputProps={{disabled: !state.edit_mode}}
              />
              Sales Staff
              <br />
              <Checkbox
                  checked={state.referrer === 1 || state.referrer === true}
                  value='sales_staff'
                  onClick={handleToggle('referrer')}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                  }}
                  inputProps={{disabled: !state.edit_mode}}
              />
              Referrer
          </GridItem>


          <GridItem xs={12} sm={12} md={4}>
            <h2>Rates</h2>
            <Checkbox
              checked={state.security_rateAdjust_show === 1 || state.security_rateAdjust_show === true}
              value='security_rateAdjust_show'
              onClick={handleToggle('security_rateAdjust_show')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Allow User to adjust rates
            <br />
            <Checkbox
              checked={state.security_rateAdjust_staff === 1 || state.security_rateAdjust_staff === true}
              value='security_rateAdjust_staff'
              onClick={handleToggle('security_rateAdjust_staff')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Allow Staff Rate Adjustment
            <br />
            <Checkbox
              checked={state.security_rateAdjust_client === 1 || state.security_rateAdjust_client === true}
              value='security_rateAdjust_client'
              onClick={handleToggle('security_rateAdjust_client')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Allow Client Rate Adjustment
            <br />
            <Checkbox
              checked={state.security_rateAdjust_team === 1 || state.security_rateAdjust_team === true}
              value='security_rateAdjust_team'
              onClick={handleToggle('security_rateAdjust_team')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Allow Team Rate Adjustment
            <br />
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <h2>Beneficiary</h2>
            <Checkbox
              checked={state.security_beneficiary_edit === 1 || state.security_beneficiary_edit === true}
              value='security_beneficiary_edit'
              onClick={handleToggle('security_beneficiary_edit')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Allow User to adjust baneficairy
          </GridItem>
        </GridContainer>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <h2>Menu Items</h2>
            <Checkbox
              checked={state.menuShowGroups === 1 || state.menuShowGroups === true}
              value='menuShowGroups'
              onClick={handleToggle('menuShowGroups')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Show Groups
            <br />
            <Checkbox
              checked={state.menuShowForwards === 1 || state.menuShowForwards === true}
              value='menuShowForwards'
              onClick={handleToggle('menuShowForwards')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Show Forwards
            <br />
            <Checkbox
              checked={state.menuShowInvoicing === 1 || state.menuShowInvoicing === true}
              value='menuShowInvoicing'
              onClick={handleToggle('menuShowInvoicing')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Show Invoicing
            <br />
            <Checkbox
              checked={state.menuShowHoldingAcc === 1 || state.menuShowHoldingAcc === true}
              value='menuShowHoldingAcc'
              onClick={handleToggle('menuShowHoldingAcc')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Show Holding Account
            <br />
              <Checkbox
                  checked={state.menuShowMultipay === 1 || state.menuShowMultipay === true}
                  value='menuShowMultiPpay'
                  onClick={handleToggle('menuShowMultipay')}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                  }}
                  inputProps={{disabled: !state.edit_mode}}
              />
              Show Multi Pay
              <br />
            <Checkbox
              checked={state.menuShowReports === 1 || state.menuShowReports === true}
              value='menuShowReports'
              onClick={handleToggle('menuShowReports')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Show Reports
            <br />
            <Checkbox
              checked={state.menuShowSettings === 1 || state.menuShowSettings === true}
              value='menuShowSettings'
              onClick={handleToggle('menuShowSettings')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Show Settings
            <br />
            <Checkbox
              checked={state.menuSimpleClientList === 1 || state.menuSimpleClientList === true}
              value='menuSimpleClientList'
              onClick={handleToggle('menuSimpleClientList')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Simple ClientList
            <br />
            <Checkbox
              checked={state.menuSimpleTransferList === 1 || state.menuSimpleTransferList === true}
              value='menuSimpleTransferList'
              onClick={handleToggle('menuSimpleTransferList')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{disabled: !state.edit_mode}}
            />
            Simple TransferList
            <br />
          </GridItem>
        </GridContainer>
        <Button disabled={!state.edit_mode} color='primary' type='submit' className={classes.updateProfileButton}>
          Update Security
        </Button>
      </form>
    </React.Fragment>
  );
};
