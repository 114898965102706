import {connect} from 'react-redux';
import CurrenciesEdit from './CurrenciesEdit';
import //loadStaffMemberDetailsRequest,
//loadStaffMemberDetailsSuccess
'../../redux/actions/currencies';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    currencies: state.currencies
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // loadStaffMemberDetailsRequest: () => {
    //   // console.log(data)
    //   dispatch(loadStaffMemberDetailsRequest())
    // },
    // loadStaffMemberDetailsSuccess: () => {
    //   // console.log(data)
    //   dispatch(loadStaffMemberDetailsSuccess())
    // }
  };
};

const CurrenciesEditContainer = connect(mapStateToProps, mapDispatchToProps)(CurrenciesEdit);

export default CurrenciesEditContainer;
