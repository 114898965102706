// ##############################
// // // LoginPage view styles
// #############################

import {container, cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';

const loginPageStyles = theme => ({
  container: {
    ...container,
    zIndex: '4',
    [theme.breakpoints.down('sm')]: {paddingBottom: '100px'}
  },
  cardTitle: {
    ...cardTitle,
    color: '#FFFFFF'
  },
  textCenter: {textAlign: 'center'},
  activeButton: {
    width: '100%',
    border: '1px solid #57acee',
    borderRadius: '0'
  },
  nonActiveButton: {
    color: 'black',
    width: '100% !important',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: '1px solid gray !important',
    borderRight: 0,
    borderRadius: '0'
  },
  reset: {
    fontSize: '13px',
    textAlign: 'center',
    textDecoration: 'bold',
    marginTop: 0,
    paddingTop: 0,
    letterSpacing: '1px',
    color: 'black !important'
  },
  justifyContentCenter: {justifyContent: 'center !important'},
  customButtonClass: {
    '&,&:focus,&:hover': {color: '#FFFFFF'},
    marginLeft: '5px',
    marginRight: '5px'
  },
  inputAdornment: {marginRight: '18px'},
  inputAdornmentIcon: {color: '#555'},
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)'
  },
  customFormControlClasses: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    marginTop: '0px !important',
    marginBottom: '0px !important'
  },
  term: {
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: '500',
    margin: '0',
    paddingTop: '3%'
  },
  termCondition: {color: 'black '},
  cardHeader: {marginBottom: '20px'},
  loginHeader: {
    borderRadius: '6px',
    width: '70%',
    margin: 'auto !important',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    // marginBottom: '100px',
    padding: '0px 0px',
    marginTop: '15vh'
  },
  socialLine: {padding: '0.9375rem 0'}
});

export default loginPageStyles;
