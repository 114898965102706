import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';
import Button from '../../../components/CustomButtons/Button';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import CurrenciesNewContainer from '../../../components/Currencies/CurrenciesNewContainer';
import CurrenciesEditContainer from '../../../components/Currencies/CurrenciesEditContainer';
import CurrenciesTableContainer from '../../../components/Currencies/CurrenciesTableContainer';

const currenciesStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - COUNTRIES
 * ----------------------------------------------------------------------------
 */
class Currencies extends React.Component {
  selectUi() {
    switch (this.props.currencies.currencies_current_ui) {
      case 'create':
        return <CurrenciesNewContainer />;
      case 'edit':
        return <CurrenciesEditContainer />;
      default:
        return <CurrenciesTableContainer />;
    }
  }

  render() {
    const {classes} = this.props;
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>Currency Management Functions</h4>
              <div>
                <Button
                  round
                  color={this.props.currencies.currencies_current_ui === 'list' ? 'rose' : null}
                  onClick={() => this.props.selectCurrenciesUi('list')}
                >
                  Show All
                </Button>
                <Button
                  round
                  color={this.props.currencies.currencies_current_ui === 'create' ? 'rose' : null}
                  onClick={() => this.props.selectCurrenciesUi('create')}
                >
                  Create New
                </Button>
                {this.props.currencies.currencies_current_ui === 'edit' && (
                  <Button
                    round
                    color={this.props.currencies.currencies_current_ui === 'edit' ? 'rose' : null}
                    onClick={() => this.props.selectCurrenciesUi('edit')}
                  >
                    Edit Record
                  </Button>
                )}
                {this.props.currencies.currencies_current_ui !== 'edit' &&
                  this.props.currencies.currencies_current_ui !== 'create' && (
                    <Button round onClick={() => this.props.selectCurrenciesUi('list')}>
                      Refresh
                    </Button>
                  )}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>{this.selectUi()}</GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

Currencies.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(currenciesStyles)(Currencies);
