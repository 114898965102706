import {connect} from 'react-redux';
import TeamList from './TeamList';
import {
  requestEntityList,
  fetchEntityListNotDeleted,
  entitiesListIsDirty,
  setEntityDetailId,
  selectEntityUi
} from '../../redux/actions/teams';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    teams: state.teams
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestEntityList: () => {
      dispatch(requestEntityList());
    },
    fetchEntityListNotDeleted: () => {
      dispatch(fetchEntityListNotDeleted());
    },
    entitiesListIsDirty: value => {
      dispatch(entitiesListIsDirty(value));
    },
    setEntityDetailId: id => {
      dispatch(setEntityDetailId(id));
    },
    selectEntityUi: value => {
      dispatch(selectEntityUi(value));
    }
  };
};

const TeamListContainer = connect(mapStateToProps, mapDispatchToProps)(TeamList);

export default TeamListContainer;
