import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import {FormattedMessage} from 'react-intl';
import {TextareaAutosize} from '@material-ui/core';
import Button from 'components/CustomButtons/Button.jsx';
import {message} from 'antd';
import moment from 'moment';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';

import NavPills from 'components/NavPills/NavPills.jsx';

const styles = themes => ({
  customFormControlClasses: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    marginTop: '0px !important',
    marginBottom: '0px !important'
  },
  backgroundColor: {}
});

// const fields = ['AGENT_CODE', 'USER_ID', 'PASSWORD', 'AGENT_SESSION_ID', 'ACCOUNT_NO'];
const fields = [
  {
    label: 'AGENT_CODE',
    key: 'AGENT_CODE',
    isDisabled: false
  },
  {
    label: 'USER_ID',
    key: 'USER_ID',
    isDisabled: false
  },
  {
    label: 'PASSWORD',
    key: 'PASSWORD',
    isDisabled: false
  },
  {
    label: 'AGENT_SESSION_ID',
    key: 'AGENT_SESSION_ID',
    isDisabled: false
  },
  {
    label: 'ACCOUNT_NO',
    key: 'ACCOUNT_NO',
    isDisabled: false
  }
];

const exch = [
  {
    label: 'AGENT_CODE',
    key: 'AGENT_CODE',
    isDisabled: false
  },
  {
    label: 'USER_ID',
    key: 'USER_ID',
    isDisabled: false
  },
  {
    label: 'TRANSFERAMOUNT',
    key: 'TRANSFERAMOUNT',
    isDisabled: false
  },
  {
    label: 'AGENT_SESSION_ID',
    key: 'AGENT_SESSION_ID',
    isDisabled: false
  },
  {
    label: 'PAYMENTMODE',
    key: 'PAYMENTMODE',
    isDisabled: false
  },
  {
    label: 'CALC_BY',
    key: 'CALC_BY',
    isDisabled: false
  },
  {
    label: 'LOCATION_ID',
    key: 'LOCATION_ID',
    isDisabled: false
  },
  {
    label: 'PAYOUT_COUNTRY',
    key: 'PAYOUT_COUNTRY',
    isDisabled: false
  },
  {
    label: 'SIGNATURE',
    key: 'SIGNATURE',
    isDisabled: false
  }
];
const PrabhuCodeComponent = ({classes}) => {
  const [state, setState] = useState({
    AGENT_CODE: 'FOREX001',
    USER_ID: 'FOREX_API',
    PASSWORD: 'PASSWORD',
    AGENT_SESSION_ID: 'AGENT_SESSION_ID',
    ACCOUNT_NO: 'ACCOUNT_NO',
    TRANSFERAMOUNT: '11000.00',
    PAYMENTMODE: 'D',
    CALC_BY: 'C',
    LOCATION_ID: '96811068',
    PAYOUT_COUNTRY: 'NEPAL',
    SIGNATURE: '80a6af32a94befd3be8fc8de5b8f9f62d63f1f0d2fd6c2c729f862a928b7d227'
  });

  const handleChange = (stateName, value, isTransferInfo, value2) => {
    setState({
      ...state,
      [stateName]: value
    });

    setTimeout(async () => {
      let signature = state.SIGNATURE;

      const API_PASSWORD = 'Pr@bhu$PBL#123';

      let stringF = '';
      Object.keys(state).map(item => {
        if (
          item === 'AGENT_CODE' ||
          item === 'USER_ID' ||
          item === 'AGENT_SESSION_ID' ||
          item === 'TRANSFERAMOUNT' ||
          item === 'PAYMENTMODE' ||
          item === 'CALC_BY' ||
          item === 'LOCATION_ID' ||
          item === 'PAYOUT_COUNTRY'
        ) {
          if (stateName === item) {
            stringF = stringF + value;
          } else {
            stringF = stringF + state[item];
          }
        }
      });
      stringF = stringF + API_PASSWORD + '.';
      const msgBuffer = new TextEncoder().encode(stringF);
      const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      signature = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
      setState(state => {
        return {
          ...state,
          SIGNATURE: signature
        };
      });
    }, 500);
  };

  const getValue = value => {
    if (value === 'validate') {
      return `<?xml version="1.0" encoding="utf-8"?>
  <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
      <AccountValidate xmlns="ClientWebServices">
        <AGENT_CODE>${state.AGENT_CODE}</AGENT_CODE>
        <USER_ID>${state.USER_ID}</USER_ID>
        <PASSWORD>${state.PASSWORD}</PASSWORD>
        <AGENT_SESSION_ID>${state.AGENT_SESSION_ID}</AGENT_SESSION_ID>
        <ACCOUNT_NO>${state.ACCOUNT_NO}</ACCOUNT_NO>
      </AccountValidate>
    </soap:Body>
  </soap:Envelope>`;
    } else if (value === 'exch') {
      return `<?xml version="1.0" encoding="utf-8"?>
<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
  <soap12:Body>
    <GetEXRate xmlns="ClientWebServices">
      <AGENT_CODE>${state.AGENT_CODE}</AGENT_CODE>
      <USER_ID>${state.USER_ID}</USER_ID>
      <AGENT_SESSION_ID>${state.AGENT_SESSION_ID}</AGENT_SESSION_ID>
      <TRANSFERAMOUNT>${state.TRANSFERAMOUNT}</TRANSFERAMOUNT>
      <PAYMENTMODE>${state.PAYMENTMODE}</PAYMENTMODE>
      <CALC_BY>${state.CALC_BY}</CALC_BY>
      <LOCATION_ID>${state.LOCATION_ID}</LOCATION_ID>
      <PAYOUT_COUNTRY>${state.PAYOUT_COUNTRY}</PAYOUT_COUNTRY>
      <SIGNATURE>${state.SIGNATURE}</SIGNATURE>
    </GetEXRate>
  </soap12:Body>
</soap12:Envelope>`;
    }
  };

  const render = (value, Obj) => {
    return (
      <div style={{padding: 20}}>
        {
          <GridContainer>
            <GridItem xs={4}>
              {Obj.map((item, index) => {
                return (
                  <Fragment>
                    <GridContainer key={index}>
                      <GridItem xs={12}>
                        <CustomTextField
                          id={item.key}
                          formControlProps={{
                            fullWidth: true,
                            className: `${classes.customFormControlClasses} ${classes.backgroundColor}`
                          }}
                          inputProps={{
                            value: state[item.key],
                            variant: 'outlined',
                            style: {backgroundColor: 'white'},
                            label: `Enter ${item.label}`,
                            onChange: event => {
                              handleChange(item.key, event.target.value, false, value);
                            }
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </Fragment>
                );
              })}
            </GridItem>
            <GridItem xs={8}>
              <TextareaAutosize
                minRows={13}
                style={{width: '100%'}}
                multiline
                aria-label='maximum height'
                disabled
                placeholder='JSON Response'
                value={getValue(value)}
              />
              <Button
                style={{
                  textAlign: 'right',
                  float: 'right'
                }}
                className={classes.alightRight}
                onClick={() => {
                  navigator.clipboard.writeText(getValue(value));
                  message.success('copied!');
                }}
              >
                Copy to Clipboard
              </Button>
            </GridItem>
          </GridContainer>
        }
      </div>
    );
  };

  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <NavPills
            color='info'
            tabs={[
              {
                tabButton: 'Account Validate',
                tabContent: render('validate', fields)
              },
              {
                tabButton: 'Get Exch Rate',
                tabContent: render('exch', exch)
              }
            ]}
          />
        </GridItem>
      </GridContainer>
    </Fragment>
  );
};

PrabhuCodeComponent.propTypes = {classes: PropTypes.object.isRequired};

export default withStyles(styles)(PrabhuCodeComponent);
