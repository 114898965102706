import React, {Fragment} from 'react';
// core components
import GridContainer from '../../Grid/GridContainer.jsx';
import GridItem from '../../Grid/GridItem.jsx';
import Card from '../../Card/Card.jsx';
import CardBody from '../../Card/CardBody.jsx';

import CardHeader from 'components/Card/CardHeader';
import CustomInput from '../../CustomInput/CustomInput';
import Button from '../../CustomButtons/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
// For CustomReactSelect
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import ClientEditCommunication from '../Edit/ClientEditCommunication.jsx';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment} from '@material-ui/core';
import CustomTextField from '../../CustomInput/CustomTextField.jsx';
import Icon from '@ant-design/icons/lib/components/Icon';
export const UserAccountComponent = ({
  classes,
  handleSubmit,
  app_state,
  state,
  handleChange,
  setState,
  change,
  client_detail_id,
  handleCustomReactSelectChange,
  handleCreateClientPortalAccount,
  toggleForgetModal,
  forgetPassword,
  validatePassword,
  handleToggle
}) => {
  const title_select_options = [{label: 'Mr'}, {label: 'Ms'}, {label: 'Mrs'}].map(item => ({
    value: item.label,
    label: item.label
  }));

  const handleNameChange = (event, toEdit_first_name, toEdit_last_name) => {
    if (event.target.id === 'first_name') {
      //set firstname and nickname
      setState({
        [event.target.id]: event.target.value,
        client_nickname: event.target.value + ', ' + toEdit_last_name
      });
    } else if (event.target.id === 'last_name') {
      setState({
        [event.target.id]: event.target.value,
        client_nickname: toEdit_first_name + ', ' + event.target.value
      });
    } else {
      setState({[event.target.id]: event.target.value});
    }
  };

  const handleNicknameChange = event => {
    setState({[event.target.id]: event.target.value});
  };

  const getClientClassificationCardContent = () => {
    // For CustomReactSelect. Generate select options for dropdown list.
    const account_type_select_options = state.client_types_list.map(item => ({
      value: item.id,
      label: item.nickname
    }));
    const account_status_select_options = state.client_status_list.map(item => ({
      value: item.id,
      label: item.nickname
    }));
    const responsible_staff_member_select_options = state.staff_list
      .sort((a, b) => a.last_name.localeCompare(b.last_name))
      .map(item => ({
        value: item.id,
        label:
          item.last_name.toUpperCase() +
          ', ' +
          item.first_name +
          ' - from ' +
          item.division_nickname +
          ' (' +
          item.team_nickname +
          ' team)'
      }));
    const team_select_options = state.team_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));

    let account_status_staff_nickname = '';
    for (const key in state.staff_list) {
      const staff = state.staff_list[key];
      if (staff.id === state.account_status_staff) {
        account_status_staff_nickname = staff.nickname;
      }
    }

    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <CustomReactSelect
                  label='Account Type'
                  options={account_type_select_options}
                  value={state.account_type}
                  onChange={handleCustomReactSelectChange('account_type')}
                  isClearable={false}
                  isDisabled={!state.edit_mode}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomInput
                  labelText='Client Reference'
                  id='external_reference'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: !state.edit_mode,
                    value: state.external_reference || '',
                    onChange: event => {
                      handleChange(event);
                    }
                  }}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} md={6}>
                <CustomReactSelect
                  label='Responsible Staff Member'
                  options={responsible_staff_member_select_options}
                  value={state.responsible_staff_member}
                  onChange={handleCustomReactSelectChange('responsible_staff_member')}
                  isClearable={true}
                  isDisabled={!state.edit_mode}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomReactSelect
                  label='Team'
                  options={team_select_options}
                  value={state.team_id}
                  onChange={handleCustomReactSelectChange('team_id')}
                  isClearable={false}
                  isDisabled={!state.edit_mode}
                />
              </GridItem>
            </GridContainer>

            <hr />
            <CustomReactSelect
              label='Client Status'
              options={account_status_select_options}
              value={state.account_status}
              onChange={handleCustomReactSelectChange('account_status')}
              isClearable={false}
              isDisabled={!state.edit_mode}
            />
            {app_state.current_staff_super_admin && (
              <React.Fragment>
                <CustomInput
                  labelText='account status note'
                  id='account_status_notes'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: !state.edit_mode,
                    value: state.account_status_notes || '',
                    onChange: event => {
                      handleChange(event);
                    }
                  }}
                  helpText={'These notes will be visible to the client'}
                />
              </React.Fragment>
            )}

            <ul>
              <li>Last change to status: {state.account_status_datetime}</li>
              <li>Last change made by: {account_status_staff_nickname}</li>
            </ul>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  };

  const getClientPortalCardContent = () => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    };
    const portal_last_login = state.portal_last_login_datetime
      ? new Date(state.portal_last_login_datetime).toLocaleString('en-AU', options)
      : 'N/A';

    if (!state.portal_account_created) {
      return (
        <React.Fragment>
          <p>
            {state.first_name} <strong>DOES NOT HAVE</strong> a client portal registration
          </p>
          <p>
            Clicking the button below will initiate the account creation procedure. This will send an email to{' '}
            {state.email} advising them of account opening and requesting email confirmation.
          </p>
          <p>
            {state.password_state === 'error' && (
              <strong style={{color: 'red'}}>
                <em>
                  Please note passwords must contain at least 1 uppercase and 1 lowercase character, as well as 1 number
                  and 1 special character
                </em>
              </strong>
            )}
          </p>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText='Username (primary email)'
                id='username_display_email'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  disabled: true,
                  value: state.email || '',
                  onChange: event => {
                    handleChange(event);
                  }
                }}
              />
              <CustomInput
                success={state.password_state === 'success'}
                error={state.password_state === 'error'}
                labelText='Password *'
                id='password'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: state.password,
                  onChange: event => change(event, 'password', 'password'),
                  type: 'password'
                }}
              />
              <CustomInput
                success={state.password_confirm_state === 'success'}
                error={state.password_confirm_state === 'error'}
                labelText='Confirm Password *'
                id='password_confirm'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: state.password_confirm,
                  onChange: event => change(event, 'password_confirm', 'equalTo', 'password'),
                  type: 'password'
                }}
              />
            </GridItem>
          </GridContainer>
          <Button
            color='primary'
            onClick={() => handleCreateClientPortalAccount()}
            // type="submit"
            className={classes.updateProfileButton}
          >
            Create client portal account
          </Button>
        </React.Fragment>
      );
    }

    if (!state.portal_email_confirmed) {
      return (
        <React.Fragment>
          <p>
            {state.first_name}
            's portal account <strong>HAS NOT </strong> been confirmed.
          </p>
          <p>
            An email has been sent to {state.email} with a confirmation code. The user should visit
            https://portal.forexworldwide.com and verify their account.
          </p>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <ul>
          <li>{state.first_name} has a client portal registration</li>
          <li>
            {state.first_name}
            's client portal registration has been confirmed.
          </li>
          <li>
            {state.first_name} last CRM login: {portal_last_login}
          </li>
        </ul>
        {/* <Button>Login to Client Portal</Button> */}
        <Button onClick={() => toggleForgetModal(true)}>Change user password</Button>

        <Dialog
          open={state.forgetModal}
          onClose={() => toggleForgetModal(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Forget Password</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <CustomTextField
                // success={state.password_state === 'success'}
                // error={state.password_state === 'error'}
                id='password'
                helpText={state.password_helptext}
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses
                }}
                inputProps={{
                  variant: 'outlined',
                  label: 'Password',
                  style: {backgroundColor: 'white'},
                  value: state.password,
                  onChange: event => validatePassword(event, 'password', 'password'),
                  type: 'password',
                  startAdornment: (
                    <InputAdornment position='start' className={classes.inputAdornment}>
                      <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                    </InputAdornment>
                  )
                }}
              />
              <CustomTextField
                // labelText="Confirm Password..."
                // success={state.confirm_password_state === 'success'}
                // error={state.confirm_password_state === 'error'}
                id='confirm_password'
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses
                }}
                inputProps={{
                  value: state.password_confirm,
                  variant: 'outlined',
                  label: 'Confirm Password',
                  style: {
                    backgroundColor: 'white',
                    padding: '0px'
                  },
                  onChange: event => validatePassword(event, 'password_confirm', 'password_confirm'),
                  type: 'password',
                  startAdornment: (
                    <InputAdornment position='start' className={classes.inputAdornment}>
                      <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                    </InputAdornment>
                  )
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toggleForgetModal(false)} color='primary'>
              Cancel
            </Button>
            <Button onClick={() => forgetPassword()} color='danger' autoFocus disabled={state.loading}>
              Change Password
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };
  return (
    <Fragment>
      <div style={{padding: 0}}>
        {/* <form onSubmit={handleSubmit}> */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card style={{height: '90%'}}>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>User Details</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText='Username (primary email)'
                      id='email'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: true,
                        value: state.email || '',
                        onChange: event => {
                          handleChange(event);
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomReactSelect
                      label='Title'
                      options={title_select_options}
                      value={state.title}
                      onChange={handleCustomReactSelectChange('title')}
                      isClearable={false}
                      isDisabled={!state.edit_mode}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8}>
                    <CustomInput
                      labelText='First Name'
                      id='first_name'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: !state.edit_mode,
                        value: state.first_name || '',
                        onChange: event => {
                          handleNameChange(event, state.first_name, state.last_name);
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText='Middle Name(s)'
                      id='middle_name'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: !state.edit_mode,
                        value: state.middle_name || '',
                        onChange: event => {
                          handleChange(event);
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText='Last Name'
                      id='last_name'
                      disabled
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: !state.edit_mode,
                        value: state.last_name || '',
                        onChange: event => {
                          handleNameChange(event, state.first_name, state.last_name);
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} />
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText='Nickname'
                      id='client_nickname'
                      disabled
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: !state.edit_mode,
                        value: state.client_nickname || '',
                        onChange: event => {
                          handleNicknameChange(event);
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} />
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card style={{height: '90%'}}>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Client Classification</h4>
              </CardHeader>
              <CardBody>{getClientClassificationCardContent()}</CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer alignItems='flex-start'>
          <GridItem xs={12} sm={12} md={6}>
            <Card style={{height: '90%'}}>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Client Portal</h4>
              </CardHeader>
              <CardBody>{getClientPortalCardContent()}</CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card style={{height: '90%'}}>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Client Communication</h4>
              </CardHeader>
              <CardBody>
                {state.client_communication != 1 && (
                  <ClientEditCommunication
                    id={client_detail_id}
                    title={state.title}
                    first_name={state.first_name}
                    last_name={state.last_name}
                    email={state.email}
                    // enabled={app_state.current_staff_super_admin}
                    enabled={
                      (app_state.current_staff_super_admin ||
                        (app_state.current_staff && app_state.current_staff.backofficeStaff)) &&
                      state.account_status === 2
                    }
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {/* <Button color='primary' disabled={!state.edit_mode} type='submit' className={classes.updateProfileButton}>
            Update User & Account
          </Button>
        </form> */}
      </div>
    </Fragment>
  );
};
