import {
  CURRENCIES_FETCH_CURRENCY_LIST_REQUEST,
  CURRENCIES_FETCH_CURRENCY_LIST_SUCCESS,
  // CURRENCIES_FETCH_CURRENCY_LIST_FAILURE,
  CURRENCIES_CREATE_NEW_SHOW_UI,
  CURRENCIES_CREATE_NEW_START,
  CURRENCIES_CREATE_NEW_CANCEL,
  CURRENCIES_CREATE_NEW_COMPLETE,
  CURRENCIES_SELECT_UI,
  CURRENCIES_CURRENCIES_LIST_IS_DIRTY,
  CURRENCIES_CURRENCY_EDIT
} from '../actions/currencies.js';

const initialState = {
  is_fetching: false,
  did_invalidate: false,
  currencies_list_is_dirty: false,
  currencies: [],
  currencies_current_ui: 'list',
  currencies_create_new_in_process: false,
  currency_id: ''
};

function Currencies(state = initialState, action) {
  switch (action.type) {
    case CURRENCIES_FETCH_CURRENCY_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
      });

    case CURRENCIES_FETCH_CURRENCY_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        currencies: action.currencies,
        lastUpdated: action.receivedAt
      });

    case CURRENCIES_CREATE_NEW_SHOW_UI:
      return Object.assign({}, state, {
        currencies_current_ui: 'list'
      });
    //
    case CURRENCIES_CREATE_NEW_START:
      return Object.assign({}, state, {
        currencies_create_new_in_process: true
      });

    case CURRENCIES_CREATE_NEW_CANCEL:
      return Object.assign({}, state, {
        currencies_create_new_in_process: false,
        currencies_current_ui: 'list'
      });

    case CURRENCIES_CREATE_NEW_COMPLETE:
      return Object.assign({}, state, {
        currencies_create_new_in_process: false,
        currencies_current_ui: 'list',
        currencies_list_is_dirty: true
      });

    case CURRENCIES_SELECT_UI:
      return Object.assign({}, state, {
        currencies_current_ui: action.value
      });
    case CURRENCIES_CURRENCIES_LIST_IS_DIRTY:
      return Object.assign({}, state, {
        currencies_list_is_dirty: action.value
      });
    case CURRENCIES_CURRENCY_EDIT:
      return Object.assign({}, state, {
        currencies_create_new_in_process: false,
        currencies_current_ui: 'edit',
        currency_id: action.value
      });
    default:
      return state;
  }
}

export default Currencies;
