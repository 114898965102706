import React from 'react';
import ReactTable from 'react-table';
import {API} from 'aws-amplify';

import {connect} from 'react-redux';
import {
  fetchClientList
  // setClientSelectId
} from '../../redux/actions/clients';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

// @material-ui/icons
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// core components
import Button from 'components/CustomButtons/Button.jsx';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import matchSorter from 'match-sorter';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import GridItem from 'components/Grid/GridItem.jsx';

import {FormattedMessage} from 'react-intl';
import {CSVLink} from 'react-csv';
import GridContainer from '../Grid/GridContainer';

import Badge from '../Badge/Badge';
import { Divider, Input, Select, Space,  Table, Tag } from 'antd';

// const moment = require('moment');


import {
  DateRangePicker
  //SingleDatePicker,
  //DayPickerRangeController
} from 'react-dates';


var moment = require('moment');

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

const columns = [
  {
    title: 'Created',
    dataIndex: 'record_created_datetime',
    key: 'record_created_datetime',
    render: (text, record) => {
      return moment(record.record_created_datetime).format('DD-MM-YYYY');
    }

    // filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['record_created_datetime']}),
    // filterAll: true,
    // maxWidth: 200
  },
  {
    title: 'Client ID',
    dataIndex: 'id',
    key: 'id',
    // filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['id']}),
    // filterAll: true,
    // maxWidth: 200
  },
  {
    title: 'Client Name',
    dataIndex: 'nickname',
    key: 'nickname',
    // filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['nickname']}),
    // filterAll: true,
    // maxWidth: 200
  },
  // {
  //   Header: 'First Name',
  //   accessor: 'first_name',
  //   filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['first_name']}),
  //   filterAll: true,
  //   // maxWidth: 200
  // },
  // {
  //   Header: 'Last Name',
  //   accessor: 'last_name',
  //   filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['last_name']}),
  //   filterAll: true,
  //   // maxWidth: 150
  // },
  // {
  //   Header: 'Company Name',
  //   accessor: 'business_company_name',
  //   filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['business_company_name']}),
  //   filterAll: true,
  //   // maxWidth: 150
  // },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    // filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['email']}),
    // filterAll: true,
    // maxWidth: 450
  },
  // {
  //   Header: 'Mobile',
  //   accessor: 'telephone_mobile',
  //   filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['telephone_mobile']}),
  //   filterAll: true,
  //   // maxWidth: 150
  // },
  {
    title: 'Team',
    dataIndex: 'team_nickname',
    key: 'team_nickname',
    // filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['team']}),
    // filterAll: true
  },
  // {
  //   title: 'Risk Score',
  //   dataIndex: 'riskRating',
  //   filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['riskScore']}),
  //   filterAll: true
  // },
  // {
  //   title: 'Risk Comments',
  //   dataIndex: 'riskComments',
  //   key: 'riskComments',
  //   filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['riskComments']}),
  //   filterAll: true
  // },
  {
    title: 'Risk Rating',
    dataIndex: 'riskRating',
    key: 'riskRating',
    // filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['riskRating']}),
    // filterAll: true,
    render: (text, record) => {

      switch (record.riskRating) {
        case 1:
          return <Tag color='success' style={{width: 100, textAlign:"center"}}>Low</Tag>
          break;
        case 2:
          return <Tag color='warning' style={{width: 100, textAlign:"center"}}>Medium</Tag>
          break;
        case 3:
          return <Tag color='danger' style={{width: 100, textAlign:"center"}}>High</Tag>
          break;
      }
    }
  },
  {
    title: 'Industry',
    dataIndex: 'riskIndustry',
    key: 'riskIndustry',
    // filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['team']}),
    // filterAll: true
  },
  {
    title: 'Geo Location',
    dataIndex: 'riskGeoLocation',
    key: 'riskGeoLocation',
    // filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['team']}),
    // filterAll: true
  },
  {
    title: 'ExpectedVolume',
    dataIndex: 'riskExpectedVolume',
    key: 'riskExpectedVolume',
    // filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['team']}),
    // filterAll: true
  },

  // {
  //   Header: 'Division',
  //   accessor: 'division',
  //   filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['division']}),
  //   filterAll: true
  // }
];


const columns2=[
  {
    title: 'Created',
    dataIndex: 'record_created_datetime',
    key: 'record_created_datetime',
    // filters: this.state.currencySelectList,
    // filterSearch: true,
    // onFilter: (value, record) => record.account_currency_iso_alpha_3 && record.account_currency_iso_alpha_3.startsWith(value),
    // render: (text) => {
    //   // const currentRecord = this.state.currencies_list.find((currency) => currency.id === text);
    //   const link = `https://wise.com/public-resources/assets/flags/rectangle/${text ? text.toLowerCase() : ''}.png`
    //   return (
    //     <>
    //     <img src={link} style={{height: 16, width: 16, verticalAlign: 'middle', marginBottom: '4px'}}/>
    //       &nbsp;
    //       {text}
    //       </>
    //     )},
    //   sorter: (a, b) => a.account_currency_iso_alpha_3 && a.account_currency_iso_alpha_3.localeCompare(b.account_currency_iso_alpha_3),
  },
]

class ReportsClientRisk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client_list: [],
      entity_list: [],
      dialog_delete_confirm: false,
      dialog_delete_item: 0,
      dialog_delete_item_name: '',
      team_list: [],
      team: '',
      table_data: [],
      finished: false,
      dataToDownload: [],
      // startDate: moment().subtract(1, 'month').startOf('month'),
      // endDate: moment().subtract(1, 'month').endOf('month')
    };
  }

  componentDidMount() {
    // API.get('teams', `/get-list-not-deleted`)
    //   .then(response => {
    //     this.setState({
    //       team_list: response
    //     });
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
    this.getTeamList()
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (prevState.client_list !== this.state.client_list) {
  //     // console.log(this.state.client_list);
  //     this.setState({table_data: this.buildTableData()}, this.finished());
  //   }
  // }


  componentDidUpdate(prevProps, prevState, snapshot) {
    // if (prevState.transfer_list !== this.state.transfer_list) {
      // console.log(this.state.transfer_list); //#1036 Debug message
      // this.setState({table_data: this.buildTableData()}, this.finished())
    // }
    if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
      this.getTeamList()
    }
  }
  getTeamList = () => {
    if (this.props.app_state.current_staff) {
    API.get('teams', `/list/staff/${this.props.app_state.current_staff.id}`)
      .then(response => {
        console.log(response)

        const teamList = response.sort((a, b) => a.nickname.localeCompare(b.nickname))
        
        const teamListSelect = teamList.map((item) => ({
          text: item.nickname,
          label: item.nickname,
          value: item.id
        }))

        this.setState({
          team_list: teamList,
          teamListSelect: teamListSelect,
          team_id: teamList[0].id
        });
        
        // this.setState({
        //   team_list: response,
        //   team_id: response[0].id
        // });
      })
      .catch(error => {
        console.log(error);
      });
    }
  }


  download = event => {
    const currentRecords = this.reactTable.getResolvedState().sortedData;
    var data_to_download = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < columns.length; colIndex++) {
        record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor];
      }
      data_to_download.push(record_to_download);
    }
    this.setState({dataToDownload: data_to_download}, () => {
      // click the CSVLink component to trigger the CSV download
      this.csvLink.link.click();
    });
  };

  finished() {
    this.setState({finished: true});
  }

  // handleSelectChange = async event => {
  //   this.setState({[event.target.name]: event.target.value});
  //   await this.getClientDetails(event.target.value);
  //   this.buildTableData()
  // };

  // handle_date_change = (startDate, endDate) => {
  //   // console.log(startDate, endDate); //#1036 Debug message
  //   this.setState({startDate, endDate}, this.buildTableData());
  //   this.buildTableData()
  // };

  onSearch = async (value) => {
    console.log(value)
    if (!value) {
      this.getFullClientDetails()
      return
    }
    await API.get('clients', `/list/staff/${this.props.app_state.current_staff.id}`)
    .then(response => {
      console.log(response)
      const filteredList = response.filter((item) => {
        if (item.nickname && item.nickname.toLowerCase().includes(value.toLowerCase())) {
          return true
        }
      })
      this.setState({client_list: filteredList}) 
    })
    .catch(error => {
      console.log(error);
    });
  }

  onChange = (value) => {
    console.log(`selected ${value}`);
    if (!value) {
      this.getFullClientDetails()
    }
    this.getClientDetails(value)
  };
  
  onSelect = (value) => {
    console.log('search:', value);
  };

  getClientDetails = async (team_id) => {
    await API.get('clients', `/getByTeam/id/${team_id}`)
      .then(response => {
        console.log(response)
        const clientList = response.sort((a, b) => moment(a.record_created_datetime).isBefore(b.record_created_datetime) ? -1 : 1)
        this.setState({client_list: clientList});
      })
      .catch(error => {
        console.log(error);
      });
      // this.buildTableData()
  }

  getFullClientDetails = async () => {
    await API.get('clients', `/list/staff/${this.props.app_state.current_staff.id}`)
      .then(response => {
        console.log(response)
        this.setState({client_list: response});
      })
      .catch(error => {
        console.log(error);
      });
      // this.buildTableData()
  }

  // handle_delete_dialog_click = item_to_delete => {
  //   this.setState({dialog_delete_confirm: false});
  //   API.put('clients', `/update/id/${item_to_delete}`, {
  //     body: {deleted: true}
  //   })
  //     .then(response => {
  //       console.log(response);
  //       this.props.fetchClientList();
  //     })
  //     .catch(error => {
  //       console.log(error);
  //       this.props.fetchClientList();
  //     });
  // };

  // handle_delete_dialog_cancel = () => {
  //   this.setState({
  //     dialog_delete_confirm: false,
  //     dialog_delete_item: 0,
  //     dialog_delete_item_name: ''
  //   });
  // };

  buildTableData() {
    var client_list_length = this.state.client_list.length;
    let clientList= []
    if (client_list_length > 0) {
      clientList =  this.state.client_list
      
      clientList = clientList.filter((prop, key) => {
        return (prop.email.charAt(0) !== '_')
      })

      // filter by startDate and endDate
      clientList = clientList.filter((prop, key) => {
        const startDate = this.state.startDate
        const endDate = this.state.endDate
        return (moment(prop.record_created_datetime).isBetween(startDate, endDate, null, '[]'))
      })

      clientList =  clientList.map((prop, key) => {
        return {
          id: prop.id,
          aws_cognito_id: prop.aws_cognito_id,
          nickname: prop.nickname,
          business_company_name: prop.business_company_name,
          first_name: prop.first_name,
          last_name: prop.last_name,
          email: prop.email,
          telephone_mobile: prop.telephone_mobile,
          residential_street_suburb: prop.residential_street_suburb,
          residential_street_country: prop.residential_street_country,
          entity: prop.entity_nickname,
          team: prop.team_nickname,
          riskScore: prop.riskScore,
          riskComments: prop.riskComments,
          overallRisk: (prop.overallRisk == 1 ? 'LOW' : (prop.overallRisk == 2 ? 'MEDIUM' : (prop.overallRisk == 3 ? 'HIGH' : ''))),
          record_created_datetime: moment(prop.record_created_datetime).format("DD/MM/YYYY"),
          // division: prop.division_nickname,
          actions: (
            <div className='actions-right'>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  this.props.history.push(`/clients/edit/${prop.id}`);
                }}
                color='warning'
                className='edit'
              >
                <Dvr />
              </Button>{' '}
              {/* use this button to remove the data row */}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  var data = this.state.client_list;
                  data.find((o, i) => {
                    if (o.id === prop.id) {
                      this.setState({
                        dialog_delete_confirm: true,
                        dialog_delete_item: prop.id,
                        dialog_delete_item_name: `${prop.first_name} ${prop.last_name}`
                      });

                      return true;
                    }
                    return false;
                  });
                  this.setState({data: data});
                }}
                color='danger'
                className='remove'
              >
                <Close />
              </Button>
              }{' '}
            </div>
          )
        };
      });
    }
    this.setState({table_data: clientList});
  }

  render() {
    const {classes} = this.props;

    return (
      <React.Fragment>
        {/* <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                <FormattedMessage id='reportsClient.cardItem1.team' defaultMessage={`Teams`} />
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.team || ''}
                onChange={this.handleSelectChange}
                inputProps={{
                  name: 'team',
                  id: 'team'
                }}
              >
                <MenuItem
                  key='0x0'
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Select client {' from ' + this.state.clients_fullname}
                </MenuItem>
                {this.state.team_list
                  // .sort((a, b) => a.nickname.localeCompare(b.nickname))
                  .map(item => {
                    return (
                      <MenuItem
                        key={item.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={item.id}
                      >
                        {item.nickname}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <Button round onClick={this.getFullClientDetails} color='primary'>
              Show All Teams
            </Button>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <div style={{float: 'right'}}>
              <Button round color={'primary'} onClick={this.download}>
                <i className='material-icons'>cloud_download</i> CSV
              </Button>
            </div>
          </GridItem>
        </GridContainer> */}
        {/* <hr /> */}
        {/* <strong>Search Create Date Range&nbsp;&nbsp;&nbsp;&nbsp;</strong>
        <DateRangePicker
          isOutsideRange={() => false}
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
          displayFormat='DD/MM/YYYY'
          onDatesChange={({startDate, endDate}) => this.handle_date_change(startDate, endDate)} // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => this.setState({focusedInput})} // PropTypes.func.isRequired,
        /> */}

<Select
    showSearch
    placeholder="Select a team"
    optionFilterProp="children"
    onChange={this.onChange}
    onSearch={this.onSelect}
    allowClear
    filterOption={(input, option) => {
      if (option && option.label) {
        return option.label.toLowerCase().includes(input.toLowerCase())
      }
    }}
    options={this.state.teamListSelect}
    style={{width: 300, marginBottom: 16, marginRight: 36}}
  />

        <Input.Search 
          placeholder="Search Client Name" enterButton style={{width: 300, marginBottom: 16, marginRight: 36}}
          allowClear
          onSearch={this.onSearch}
          />

        <div style={{float: 'right'}}>
          <Button round color={'primary'} onClick={this.download}>
            <i className='material-icons'>cloud_download</i> CSV
          </Button>
        </div>



<Table
          // data={this.state.entity_list}
          dataSource={this.state.client_list}
          filterable
          columns={columns}
          defaultSorted={[
            {
              id: 'record_created_datetime',
              desc: true
            },
          ]}
          // defaultPageSize={5}
          pagination={{
            pageSizeOptions: ['5', '10', '20'],
            defaultPageSize: 5,
            showSizeChanger: true
          }}
          // showPaginationTop
          showPaginationBottom
          // className='-highlight'
        />


        {/* <ReactTable
          ref={r => (this.reactTable = r)}
          // data={this.buildTableData()}
          data={this.state.table_data}
          filterable
          columns={columns}
          defaultSorted={[
            {
              id: 'record_created_datetime',
              desc: false
            },
            {
              id: 'nickname',
              desc: false
            }
          ]}
          defaultPageSize={20}
          // showPaginationTop
          showPaginationBottom
          className='-highlight'
        /> */}
        <CSVLink
          data={this.state.client_list}
          filename='data.csv'
          className='hidden'
          // ref={r => (this.csvLink = r)}
          target='_blank'
        />
        {/* <Dialog
          open={this.state.dialog_delete_confirm}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Are you sure that you wish to <strong>delete</strong> staff member record{' '}
              {this.state.dialog_delete_item_name}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handle_delete_dialog_cancel} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => this.handle_delete_dialog_click(this.state.dialog_delete_item)}
              color='danger'
              autoFocus
            >
              Delete Record
            </Button>
          </DialogActions>
        </Dialog> */}
      </React.Fragment>
    );
  }
}

ReportsClientRisk = withRouter(withStyles(styles)(ReportsClientRisk));

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchClientList: () => {
      dispatch(fetchClientList());
    }
    // setClientSelectId: (id) => {
    // dispatch(setClientSelectId(id))
    // }
  };
};

const ReportsClientRiskContainer = connect(mapStateToProps, mapDispatchToProps)(ReportsClientRisk);

export default ReportsClientRiskContainer;
