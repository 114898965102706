// ##############################
// // // RegisterPage view styles
// #############################

import {container, cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';

import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';

const registerPageStyle = {
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    textAlign: 'center'
  },
  container: {
    ...container,
    position: 'relative',
    zIndex: '3'
  },
  cardSignup: {
    borderRadius: '6px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginBottom: '100px',
    padding: '0px 0px',
    marginTop: '15vh'
  },
  cardHeader: {
    marginBottom: '45px !important',
    width: '50%',
    margin: 'auto !important',
    textAlign: 'center !important'
  },
  activeButton: {
    width: '100%',
    border: '1px solid #57acee',
    borderRadius: '0'
  },
  nonActiveButton: {
    color: 'black',
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: '1px solid gray',
    borderRight: 0,
    borderRadius: '0'
  },
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    padding: '0'
  },
  center: {textAlign: 'center'},
  right: {textAlign: 'right'},
  left: {textAlign: 'left'},
  form: {
    padding: '0 0px',
    position: 'relative'
  },
  socialTitle: {fontSize: '18px'},
  inputAdornment: {
    marginRight: '18px',
    position: 'relative'
  },
  inputAdornmentIcon: {color: '#555'},
  customFormControlClasses: {margin: '0 0px'},
  checkboxLabelControl: {margin: '0'},
  checkboxLabel: {
    marginLeft: '6px',
    color: 'rgba(0, 0, 0, 0.26)'
  }
};

export default registerPageStyle;
