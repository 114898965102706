import {
  NEWS_CLIENTS_FETCH_NEWS_CLIENTS_LIST_SUCCESS,
  NEWS_CLIENTS_FETCH_NEWS_CLIENTS_LIST_REQUEST
} from '../actions/news_clients.js';

const initialState = {
  transfer_current_ui: 'list',
  is_fetching: false,
  did_invalidate: false,
  news_clients_list: []
};

function news_clients(state = initialState, action) {
  switch (action.type) {
    case NEWS_CLIENTS_FETCH_NEWS_CLIENTS_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
        // console.log(action);
      });

    case NEWS_CLIENTS_FETCH_NEWS_CLIENTS_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        news_clients_list: action.news_clients_list,
        last_updated: action.received_at
      });

    default:
      return state;
  }
}

export default news_clients;
