import {connect} from 'react-redux';
import {onLoginSuccess} from './redux/actions';
// import Link from '../components/Link'
import App from './App';

// const mapStateToProps = (state, ownProps) => {
//   return {
//     active: ownProps.filter === state.visibilityFilter
//   }
// }

const mapStateToProps = (state, ownProps) => {
  return {
    //app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoginSuccess: aws_cognito_id => {
      dispatch(onLoginSuccess(aws_cognito_id));
    }
  };
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
