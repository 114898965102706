import {connect} from 'react-redux';
import CountriesNew from './CountriesNew';
import {
  createNewCountry,
  createNewEntityStart,
  createNewEntityComplete,
  cancelCreateNewEntity,
  selectCountriesUi,
  entitiesListIsDirty
} from '../../redux/actions/countries';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    staff: state.staff
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createNewCountry: data => {
      dispatch(createNewCountry(data));
    },
    createNewEntityStart: () => {
      dispatch(createNewEntityStart());
    },
    createNewEntityComplete: data => {
      dispatch(createNewEntityComplete(data));
    },
    cancelCreateNewEntity: () => {
      dispatch(cancelCreateNewEntity());
    },
    selectCountriesUi: () => {
      dispatch(selectCountriesUi());
    },
    entitiesListIsDirty: value => {
      dispatch(entitiesListIsDirty(value));
    }
  };
};

const CountriesNewContainer = connect(mapStateToProps, mapDispatchToProps)(CountriesNew);

export default CountriesNewContainer;
