import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import Button from '../../CustomButtons/Button';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react';
import {API} from 'aws-amplify';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import useExpanded from 'react-table';
import {FormattedNumber, injectIntl, intlShape} from 'react-intl';

//core components for #Issue 963
import CustomInput from '../../CustomInput/CustomInput';
// import Datetime from "react-datetime";
import CustomReactSelect from '../../Forms/CustomReactSelect/CustomReactSelect';

//component to send email
import TransferDetailPayoutCommunication from './TransferDetailPayoutCommunication';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';

// var moment = require("moment");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const styles = {
  selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },
  progressBar: {
    width: 50
  },
  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {float: 'right'},
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class TransferDetailSplitPayPayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // register form
      loaded: false,
      is_loading: true,
      beneficiary_list: [],
      user_beneficiaries_list: [],
      currencies_list_priority: [],
      payouts_list: [],
      country_list: [],
      currencies_list: [],
      purpose_list: [],
      super_admin: this.props.current_staff_super_admin,
      ACTIONaddBeneficiaryModalOpen: false,
      addBeneficiaryModalOpen: false
    };
  }

  componentDidMount() {
    // console.log(this.props);
    this.setState({
      beneficiary_id: this.props.beneficiary_id,
      payout_datetime: this.props.payout_datetime,
      payout_bank_reference: this.props.payout_bank_reference,
      transfer_status: this.props.transfer_status
    });

    API.get('beneficiaries', '/get-all').then(response => {
      this.setState({beneficiary_list: response});
      return response;
    });

    API.get('transfers', '/get_purpose/id/9')
      .then(response => {
        this.setState({purpose_list: response});
      })
      .catch(error => {
        API.get('transfers', '/get_purpose/id/9')
          .then(response => {
            this.setState({purpose_list: response});
          })
          .catch(error => {
            console.log(error);
          });
        console.log(error);
      });

    //Code block of #799 needs of API
    API.get('system', '/currencies/get-not-deleted')
      .then(response => {
        // console.log(response);
        this.setState({currencies_list_priority: response});
      })
      .then(() => {
        API.get('beneficiaries', `/get/by_client_id/${this.props.client_id}`) //test value 610
          .then(response => {
            // console.log(response);
            this.setState({user_beneficiaries_list: response});
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });

    API.get('countries', '/list_not_deleted')
      .then(response => {
        this.setState({country_list: response});
      })
      .catch(error => {
        console.log(error);
      });

    API.get('currencies', '/list_not_deleted')
      .then(response => {
        // console.log(response);
        this.setState({currencies_list: response});
      })
      .catch(error => {
        console.log(error);
      });
    //End of Code block

    if (this.props.beneficiary_id) {
      API.get('beneficiaries', `/get/id/${this.props.beneficiary_id}`)
        .then(response => {
          //console.log(response);
          this.setState({
            loaded: true,
            is_loading: false,
            beneficiary: response
          });
          // this.props.loadTransferDetailsSuccess();
          return response;
        })
        .then(response => {
          API.get('system', `/countries/get/${response.ben_address_country}`)
            .then(response => {
              // console.log(response);
              this.setState({ben_address_country: response[0].full_name});
              // this.props.loadTransferDetailsSuccess();
              return response;
            })
            .catch(error => {
              console.log(error);
            });

          API.get('system', `/countries/get/${response.bank_address_country}`)
            .then(response => {
              // console.log(response);
              this.setState({bank_address_country: response[0].full_name});
              // this.props.loadTransferDetailsSuccess();
              return response;
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (this.props.currency_to_id) {
      API.get('system', `/currencies/get/${this.props.currency_to_id}`)
        .then(response => {
          // console.log(response);
          this.setState({currency_to: response});
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (this.props.currency_from_id) {
      API.get('system', `/currencies/get/${this.props.currency_from_id}`)
        .then(response => {
          // console.log(response);
          this.setState({currency_from: response});
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (this.props.transfer_id) {
      API.get('transfers', `/get-split-pay/${this.props.transfer_id}`)
        .then(response => {
          this.setState({
            transfer: response.fullList,
            payouts_list: response.splitpay
          });

          API.get('system', `/currencies/get/${response.fullList.currency_to_id}`)
            .then(response1 => {
              this.setState({currency_to: response1});
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
          API.get('transfers', `/get-split-pay/${this.props.transfer_id}`).then(response => {
            this.setState({
              transfer: response.fullList,
              payouts_list: response.splitpay
            });
          });
        });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.remitting_bank_id !== this.props.remitting_bank_id) {
      API.get('system', `/entitybanksaccount/get/id/${this.props.remitting_bank_id}`)
        .then(response => {
          // console.log(response);
          this.setState({
            loaded: true,
            is_loading: false,
            entity_bank_account: response[0]
          });
          // this.props.loadTransferDetailsSuccess();
          return response[0];
        })
        .then(response => {
          // console.log(response);
          API.get('system', `/entitybanks/get/id/${response.entity_bank_id}`)
            .then(response => {
              // console.log(response);
              this.setState({
                loaded: true,
                is_loading: false,
                entity_bank: response[0]
              });
              // this.props.loadTransferDetailsSuccess();
              return response;
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (prevProps.currency_from_id !== this.props.currency_from_id) {
      API.get('system', `/currencies/get/${this.props.currency_from_id}`)
        .then(response => {
          // console.log(response);
          this.setState({currency_from: response});
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (prevProps.language_id !== this.props.language_id) {
      API.get('admin', '/languages/get')
        .then(response => {
          // console.log(response);
          // console.log(response[this.props.language_id].nickname);
          // let matched_id = 1;
          let matched_nickname = 'English';
          for (const item in response) {
            if (response[item].id === this.props.language_id) {
              matched_nickname = response[item].nickname;
            }
          }
          this.setState({language_nickname: matched_nickname});
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  addBeneficiaryModelOpen = () => {
    this.setState({addBeneficiaryModalOpen: true});
  };

  addBeneficiaryModelClose = () => {
    this.setState({
      addBeneficiaryModalOpen: false,
      ACTIONaddBeneficiaryModalOpen: false
    });
  };

  actionAddBeneficiaryModelOpen = () => {
    this.setState({
      ACTIONaddBeneficiaryModalOpen: true,
      addBeneficiaryModalOpen: false
    });
  };

  actionAddBeneficiaryModelClose = () => {
    this.setState({ACTIONaddBeneficiaryModalOpen: false});
  };

  getFullDate = s => {
    //console.log(s);
    let showDate = '';
    if (s.getMonth() >= 9) {
      if (s.getDate() > 9) {
        showDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-' + s.getDate();
      } else {
        showDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-0' + s.getDate();
      }
    } else if (s.getMonth() < 9) {
      if (s.getDate() > 9) {
        showDate = s.getFullYear() + '-0' + (s.getMonth() + 1) + '-' + s.getDate();
      } else {
        showDate = s.getFullYear() + '-0' + (s.getMonth() + 1) + '-0' + s.getDate();
      }
    }
    return showDate;
  };

  getFullDateTime = s => {
    //console.log(s);
    let showDateTime = '';
    if (s.getMonth() >= 9) {
      if (s.getDate() > 9) {
        showDateTime =
          s.getFullYear() +
          '-' +
          (s.getMonth() + 1) +
          '-' +
          s.getDate() +
          ' ' +
          s.getHours() +
          ':' +
          s.getMinutes() +
          ':' +
          s.getSeconds();
      } else {
        showDateTime =
          s.getFullYear() +
          '-' +
          (s.getMonth() + 1) +
          '-0' +
          s.getDate() +
          ' ' +
          s.getHours() +
          ':' +
          s.getMinutes() +
          ':' +
          s.getSeconds();
      }
    } else if (s.getMonth() < 9) {
      if (s.getDate() > 9) {
        showDateTime =
          s.getFullYear() +
          '-0' +
          (s.getMonth() + 1) +
          '-' +
          s.getDate() +
          ' ' +
          s.getHours() +
          ':' +
          s.getMinutes() +
          ':' +
          s.getSeconds();
      } else {
        showDateTime =
          s.getFullYear() +
          '-0' +
          (s.getMonth() + 1) +
          '-0' +
          s.getDate() +
          ' ' +
          s.getHours() +
          ':' +
          s.getMinutes() +
          ':' +
          s.getSeconds();
      }
    }
    return showDateTime;
  };

  async saveTransferDetail(transfer_detail) {
    return API.put('transfers', `/update/id/${this.props.transfer_id}`, {body: transfer_detail});
  }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value});
  };

  handleCustomReactSelectChange =
    (
      name
      // index,
      // previous_from_currency,
      // previous_to_currency
    ) =>
    value => {
      let option_value;
      let option_label;

      if (value === null) {
        option_value = null;
        option_label = null;
      } else {
        option_value = value.value;
        option_label = value.label;
      }

      if (name === 'account_currency' || name == 'to_currency') {
        const currency_dataset = this.state.currencies_list_priority;
        for (let i = 0; i < currency_dataset.length; i++) {
          if (currency_dataset[i].id === option_value) {
            //iban check
            if (currency_dataset[i].iban === 1) {
              this.setState({show_iban: true});
            } else {
              this.setState({show_iban: false});
            }
            //bsb check
            if (currency_dataset[i].bsb === 1) {
              this.setState({show_bsb: true});
            } else {
              this.setState({show_bsb: false});
            }
            //sort_code check
            if (currency_dataset[i].sort_code === 1) {
              this.setState({show_sort_code: true});
            } else {
              this.setState({show_sort_code: false});
            }
            //swift_code check
            if (currency_dataset[i].swift_code === 1) {
              this.setState({show_swift_code: true});
            } else {
              this.setState({show_swift_code: false});
            }
            //aba_routing check
            if (currency_dataset[i].aba_routing === 1) {
              this.setState({show_aba: true});
            } else {
              this.setState({show_aba: false});
            }
            //account number check
            if (currency_dataset[i].account_number === 1) {
              this.setState({show_account_number: true});
            } else {
              this.setState({show_account_number: false});
            }
          }
        }
      }

      this.setState({[name]: option_value}, () => {
        if (name === 'from_currency' || name === 'to_currency') {
          switch (this.props.transfers_dashboard_multiple.new_transfer_data.amount_lock) {
            case 'from_amount':
              this.handleAmount('to_amount');
              break;
            case 'to_amount':
              this.handleAmount('from_amount');
              break;
            default:
              this.handleAmount();
          }
        }
      });
    };
  // End of test block

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading: true});

    try {
      await this.saveTransferDetail({
        payout_datetime: this.state.payout_datetime,
        beneficiary_id: this.state.beneficiary_id,
        payout_bank_reference: this.state.payout_bank_reference
      });
    } catch (e) {
      alert(e);
      // this.setState({ isLoading: false });
    }
  };

  //A function to save and create Valid Beneficiaries from added
  finishedCreateBene = async () => {
    if (this.isValidateBeneficiary()) {
      const {
        ben_legal_name,
        ben_email_main,
        ben_address_line_1,
        ben_address_line_2,
        ben_address_suburb,
        ben_address_state,
        ben_address_postcode,
        ben_address_country,
        bank_legal_name,
        iban,
        account_currency,
        bsb_code,
        swift_code,
        account_number,
        aba_routing_number,
        sort_code
      } = this.state;

      try {
        console.log('sdfsdfsdf');
        // console.log("sdfsdfsdf"); //disable #867 process check
        await this.createBeneficiaryIntoDatabase({
          client_id: this.props.client_id,
          nickname: ben_legal_name,
          ben_legal_name: ben_legal_name,
          ben_email_main: ben_email_main,
          ben_address_line_1: ben_address_line_1,
          ben_address_line_2: ben_address_line_2,
          ben_address_suburb: ben_address_suburb,
          ben_address_state: ben_address_state,
          ben_address_postcode: ben_address_postcode,
          ben_address_country: ben_address_country,
          bank_legal_name: bank_legal_name,
          iban: iban,
          account_currency: account_currency,
          bsb_code: bsb_code,
          swift_code: swift_code,
          account_number: account_number,
          aba_routing_number: aba_routing_number,
          sort_code: sort_code
        });
        this.setState(
          {
            addBeneficiaryModalOpen: false,
            actionAddBeneficiaryModalOpen: false
          },
          () => {
            console.log(this.state.payouts_list[0]);
            API.get('beneficiaries', `/get/by_client_id/${this.props.client_id}`) //test account 610 #1092
              .then(response => {
                // let resume_beneficiaries_list = response;
                console.log(response);
                if (response.length > 0) {
                  for (let i = response.length - 1; i > -1; i--) {
                    if (response[i].account_currency !== account_currency) {
                      response.splice(i, 1); // Removes the (i)th element of response
                    }
                  }
                }
                this.setState({user_beneficiaries_list: response});
              })
              .catch(error => {
                console.log(error);
              });
          }
        );
      } catch (e) {
        this.setState({isLoading: false});
      }
      // finally {
      //   window.location.reload(); //#867 Safety reload
      // }
    }
  };

  isValidateBeneficiary() {
    if (this.state.ben_legal_name == '' || typeof this.state.ben_legal_name === 'undefined') {
      this.setState({ben_legal_name_state: 'error'});
    } else {
      this.setState({ben_legal_name_state: 'success'});
    }

    if (this.state.account_currency == '' || typeof this.state.account_currency === 'undefined') {
      this.setState({account_currency_state: 'error'});
    } else {
      this.setState({account_currency_state: 'success'});
    }

    if (
      this.state.ben_legal_name == '' ||
      typeof this.state.ben_legal_name === 'undefined' ||
      this.state.account_currency == '' ||
      typeof this.state.account_currency === 'undefined'
    ) {
      return false;
    }
    return true;
  }

  createActionUi = beneficiary_list_select_options => {
    // console.log(this.state.beneficiary_list);
    // console.log(beneficiary_list_select_options);
    //by Tom, will check beneficiary_list_select_options #1092 Continued
    const {classes} = this.props;

    return (
      <React.Fragment>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.ACTIONaddBeneficiaryModalOpen}
          transition={Transition}
          keepMounted
          onClose={() => this.actionAddBeneficiaryModelClose()}
          aria-labelledby='modal-slide-title'
          aria-describedby='modal-slide-description'
        >
          <DialogTitle id='classic-modal-slide-title' disableTypography className={classes.modalHeader}>
            <h4 className={classes.modalTitle}>Add New Beneficiary</h4>
          </DialogTitle>
          <DialogContent id='modal-slide-description' className={classes.modalBody}>
            <h5>Splitpay details for “No beneficiary. from below and click “SAVE” to add” </h5>
            <GridItem xs={12} sm={12}>
              {/*<h4>Now provide beneficiary details</h4>*/}
            </GridItem>
            <GridItem xs={12} sm={6}>
              {
                //(this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].checked_beneficiary_details !== true) && (
              }
              <CustomReactSelect
                //error={this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary_id_state === "error"}
                label='Select beneficiary'
                options={this.state.beneficiary_list}
                value={this.state.beneficiary_id}
                //{
                //this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary_id
                //}
                onChange={this.handleCustomReactSelectChange('beneficiary_id')}
                isClearable={false}
                //isDisabled={this.state.prefill_beneficiary}
              />
            </GridItem>
            <p> </p>
            <Button
              size='sm'
              color='info'
              key='saveBeneficiaryKey'
              onClick={
                //test
                () => {
                  this.setState({
                    addBeneficiaryModalOpen: false,
                    ACTIONaddBeneficiaryModalOpen: false
                  });

                  API.put('payments', `/update/update_from_editor/${this.state.payouts_list[0].id}`, {
                    body: {
                      beneficiary_id: this.state.beneficiary_id //...data
                    }
                  })
                    .then(database_result => {
                      console.log(database_result);
                      //reload the page after getting result
                      window.location.reload();
                    })
                    .catch(error => {
                      console.log(error);
                    });
                }
              }
              disabled={!this.state.beneficiary_id}
            >
              Save
            </Button>{' '}
            <Button
              size='sm'
              color='info'
              key='addBeneficiaryKey'
              onClick={() => this.addBeneficiaryModelOpen()}
              disabled={
                true
                // !!this.state.beneficiary_id ||
                // !this.state.user_beneficiaries_list
              } //disable it before this modal function - create new beneficiaries works
            >
              Create new beneficiary
            </Button>
          </DialogContent>
          <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
            <Button onClick={() => this.actionAddBeneficiaryModelClose()}>Cancel</Button>
            {/* <Button color="primary" onClick={() => this.finishedCreateBene()}>
                    Add Beneficiary
                  </Button>*/}
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  createNewBeneficiaryUi = () => {
    //console.log(this.state.country_list)
    const {classes} = this.props;
    const country_list_select_options = this.state.country_list.map(item => ({
      value: item.id,
      label: (
        <React.Fragment>
          <span>
            <img
              src={
                item.iso_alpha_2 !== ''
                  ? '' //require(`../../../assets/img/flags/${item.iso_alpha_2}.png`)
                  : ''
              }
            />
          </span>{' '}
          &nbsp; {item.full_name}{' '}
        </React.Fragment>
      )
    }));
    //console.log(this.state.currencies_list_priority)
    //console.log(this.state.currencies_list)
    const select_currency_select_options = this.state.currencies_list_priority.map(item => ({
      value: item.id,
      label: (
        <React.Fragment>
          <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
          &nbsp;
          {item.iso_alpha_3 + ': ' + item.full_name}
        </React.Fragment>
      )
    }));

    return (
      <React.Fragment>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.addBeneficiaryModalOpen}
          transition={Transition}
          keepMounted
          onClose={() => this.addBeneficiaryModelClose()}
          aria-labelledby='modal-slide-title'
          aria-describedby='modal-slide-description'
        >
          <DialogTitle id='classic-modal-slide-title' disableTypography className={classes.modalHeader}>
            <h4 className={classes.modalTitle}>Add New Beneficiary</h4>
          </DialogTitle>
          <DialogContent id='modal-slide-description' className={classes.modalBody}>
            <GridContainer justify='flex-start'>
              <GridItem xs={12} md={6}>
                <CustomReactSelect
                  label='Currency'
                  options={select_currency_select_options}
                  value={this.state.account_currency}
                  onChange={this.handleCustomReactSelectChange('account_currency')}
                  isClearable={false}
                />
                <CustomReactSelect
                  label='Bank Country'
                  options={country_list_select_options}
                  value={this.state.bank_country}
                  onChange={this.handleCustomReactSelectChange('bank_country')}
                  isClearable={false}
                  error={this.state.bank_country_state === 'error'}
                />
              </GridItem>
            </GridContainer>
            <hr />
            <h6>Beneficiary Details</h6>

            <GridContainer>
              <GridItem xs={12} md={6}>
                <CustomInput
                  success={this.state.ben_legal_name_state === 'success'}
                  error={this.state.ben_legal_name_state === 'error'}
                  labelText={<span>Beneficiary (Account) Name</span>}
                  id='ben_legal_name'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    value: this.state.ben_legal_name,
                    onChange: event => this.newBeneficiaryChange(event, 'ben_legal_name', 'length', 3)
                  }}
                />
                <CustomInput
                  success={this.state.ben_address_line_1_state === 'success'}
                  error={this.state.ben_address_line_1_state === 'error'}
                  labelText={<span>Address Line 1</span>}
                  id='ben_address_line_1'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    value: this.state.ben_address_line_1,
                    onChange: event => this.newBeneficiaryChange(event, 'ben_address_line_1', 'length', 3)
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomReactSelect
                  label='Country'
                  options={country_list_select_options}
                  value={this.state.ben_address_country}
                  onChange={this.handleCustomReactSelectChange('ben_address_country')}
                  isClearable={false}
                />
                <br />
                <GridContainer justify='flex-start'>
                  <GridItem xs={12} md={this.state.ben_address_country === 9 ? 6 : 8}>
                    <CustomInput
                      success={this.state.ben_address_suburb_state === 'success'}
                      error={this.state.ben_address_suburb_state === 'error'}
                      labelText={<span>Suburb / Region</span>}
                      id='ben_address_suburb'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: this.state.ben_address_suburb,
                        onChange: event => this.newBeneficiaryChange(event, 'ben_address_suburb', 'length', 3)
                      }}
                    />
                  </GridItem>
                  {this.state.ben_address_country === 9 && (
                    <GridItem xs={12} md={3}>
                      <CustomInput
                        success={this.state.ben_address_state_state === 'success'}
                        error={this.state.ben_address_state_state === 'error'}
                        labelText={<span>State</span>}
                        id='ben_address_state'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          value: this.state.ben_address_state,
                          onChange: event => this.newBeneficiaryChange(event, 'ben_address_state', 'length', 2)
                        }}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12} md={this.state.ben_address_country === 9 ? 3 : 4}>
                    <CustomInput
                      success={this.state.ben_address_postcode_state === 'success'}
                      error={this.state.ben_address_postcode_state === 'error'}
                      labelText={<span>Postcode</span>}
                      id='ben_address_postcode'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: this.state.ben_address_postcode,
                        onChange: event => this.newBeneficiaryChange(event, 'ben_address_postcode', 'length', 3)
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer justify='flex-start'>
              <GridItem xs={12}>
                <CustomInput
                  success={this.state.ben_email_main_state === 'success'}
                  error={this.state.ben_email_main_state === 'error'}
                  labelText={<span>Beneficiary Email [optional]</span>}
                  helpText='May be used to provide copy of payout confirmation to beneficiary.  Confirmation is required from you on each transfer.'
                  id='ben_email_main'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    value: this.state.ben_email_main,
                    onChange: event => this.newBeneficiaryChange(event, 'ben_email_main', 'length', 3)
                  }}
                />
              </GridItem>
            </GridContainer>
            <hr />
            <h6>Bank Details</h6>

            <GridContainer justify='flex-start'>
              <GridItem xs={12} md={6}>
                <CustomInput
                  success={this.state.bank_legal_name_state === 'success'}
                  error={this.state.bank_legal_name_state === 'error'}
                  labelText={<span>Bank Name</span>}
                  id='bank_legal_name'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    value: this.state.bank_legal_name,
                    onChange: event => this.newBeneficiaryChange(event, 'bank_legal_name', 'length', 3)
                  }}
                />
                {this.state.show_iban && (
                  <CustomInput
                    success={this.state.iban === 'success'}
                    error={this.state.iban === 'error'}
                    labelText={<span>IBAN</span>}
                    id='iban'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.iban,
                      onChange: event => this.newBeneficiaryChange(event, 'iban', 'length', 3)
                    }}
                  />
                )}
              </GridItem>
              <GridItem xs={12} md={6}>
                {this.state.show_swift_code && (
                  <CustomInput
                    success={this.state.swift_code_state === 'success'}
                    error={this.state.swift_code_state === 'error'}
                    labelText={<span>SWIFT Code</span>}
                    id='swift_code'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.swift_code,
                      onChange: event => this.newBeneficiaryChange(event, 'swift_code', 'length', 3)
                    }}
                  />
                )}
                {this.state.show_aba && (
                  <CustomInput
                    success={this.state.aba_routing_number_state === 'success'}
                    error={this.state.aba_routing_number_state === 'error'}
                    labelText={<span>ABA Routing Number</span>}
                    id='aba_routing_number'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.aba_routing_number,
                      onChange: event => this.newBeneficiaryChange(event, 'aba_routing_number', 'length', 3)
                    }}
                  />
                )}
                {this.state.show_sort_code && (
                  <CustomInput
                    success={this.state.sort_code_state === 'success'}
                    error={this.state.sort_code_state === 'error'}
                    labelText={<span>Sort Code</span>}
                    id='sort_code'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.sort_code,
                      onChange: event => this.newBeneficiaryChange(event, 'sort_code', 'length', 3)
                    }}
                  />
                )}
                {this.state.show_bsb && (
                  <CustomInput
                    success={this.state.bsb_code_state === 'success'}
                    error={this.state.bsb_code_state === 'error'}
                    labelText={<span>BSB</span>}
                    id='bsb_code'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.bsb_code,
                      onChange: event => this.newBeneficiaryChange(event, 'bsb_code', 'length', 3)
                    }}
                  />
                )}
                {this.state.show_transit_code && (
                  <CustomInput
                    success={this.state.transit_code_state === 'success'}
                    error={this.state.transit_code_state === 'error'}
                    labelText={<span>Transit &amp; Institution No</span>}
                    id='transit_code'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.transit_code,
                      onChange: event => this.newBeneficiaryChange(event, 'transit_code', 'length', 3)
                    }}
                  />
                )}
                {this.state.show_bank_code && (
                  <CustomInput
                    success={this.state.bank_code_state === 'success'}
                    error={this.state.bank_code_state === 'error'}
                    labelText={<span>Bank Code</span>}
                    id='bank_code'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.bank_code,
                      onChange: event => this.newBeneficiaryChange(event, 'bank_code', 'length', 3)
                    }}
                  />
                )}

                {this.state.show_account_number && (
                  <CustomInput
                    success={this.state.account_number_state === 'success'}
                    error={this.state.account_number_state === 'error'}
                    labelText={<span>Account Number </span>}
                    id='account_number'
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      value: this.state.account_number,
                      onChange: event => this.newBeneficiaryChange(event, 'account_number', 'length', 3)
                    }}
                  />
                )}
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
            <Button onClick={() => this.addBeneficiaryModelClose()}>Cancel</Button>
            <Button color='primary' onClick={() => this.finishedCreateBene()}>
              Add Beneficiary
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  buildTableData() {
    //return
  }

  createUI() {
    const {classes} = this.props;
    return (
      <Button size='sm' color='info' key='addKey' onClick={() => this.addBeneficiaryModelOpen()}>
        Create new beneficiary
      </Button>
    );
  }

  render() {
    const {classes} = this.props;
    const beneficiary_list_select_options = this.state.user_beneficiaries_list //user_beneficiaries_list => this user's beneficiaries_list
      //this.state.beneficiary_list //all bene
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return {
          value: item.id,
          label: item.nickname
        };
      });

    if (!this.state.currency_to) {
      return null;
    }

    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <GridContainer>
            <GridItem md={12}>
              <Card style={{height: '95%'}}>
                <CardHeader>
                  <h5>Split Pay Details</h5>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    // style={{ width: "100%" }}
                    data={this.state.payouts_list}
                    pageSize={this.state.payouts_list.length > 10 ? 10 : this.state.payouts_list.length}
                    //data={this.buildTableData()}
                    filterable
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'nickname',
                        Cell: c => (
                          <React.Fragment>
                            {c.value === 0 || c.value === 'null' || c.value === '' ? (
                              <React.Fragment>{<div>Beneficiary To Be Provided</div>}</React.Fragment>
                            ) : (
                              c.original.nickname
                            )}
                          </React.Fragment>
                        ),
                        maxWidth: 450
                      },
                      {
                        Header: 'Address',
                        accessor: 'ben_address_line_1', //"nickname", use c.original.nickname to get that value
                        // filterMethod: (filter, rows) =>
                        //   matchSorter(rows, filter.value, { keys: ["transfer_id"] }),
                        // filterAll: true,
                        Cell: c => (
                          //if beneficiary_id === 0, do something, work around #798
                          <React.Fragment>
                            <div>
                              {c.original.ben_address_line_1}
                              {c.original.ben_address_suburb} /{c.original.ben_address_postcode}
                            </div>
                          </React.Fragment>
                        ),
                        maxWidth: 450
                      },
                      {
                        Header: 'Bank',
                        accessor: 'bank_legal_name', //"nickname", use c.original.nickname to get that value
                        // filterMethod: (filter, rows) =>
                        //   matchSorter(rows, filter.value, { keys: ["transfer_id"] }),
                        // filterAll: true,
                        Cell: c => (
                          //if beneficiary_id === 0, do something, work around #798
                          <React.Fragment>
                            <div>
                              {c.original.bank_legal_name}
                              {c.original.bank_address_line_1}
                            </div>
                          </React.Fragment>
                        ),
                        maxWidth: 450
                      },
                      {
                        Header: 'Swift',
                        accessor: 'swift_code',
                        Cell: c => (
                          //if beneficiary_id === 0, do something, work around #798
                          <React.Fragment>
                            <div>
                              {c.original.swift_code} <br />
                              {c.original.aba_routing_number}
                              {c.original.iban} {c.original.account_number}
                            </div>
                          </React.Fragment>
                        ),
                        maxWidth: 450
                      },
                      {
                        Header: 'Amount',
                        accessor: 'amount_to',
                        Cell: c => (
                          //set in a currency flag, and fix digit into minimumFractionDigits 2 and maximumFractionDigits={2}
                          <React.Fragment>
                            <div
                              className={`currency-flag currency-flag-${
                                this.state.transfer.currency_to_iso_alpha_3
                                  ? this.state.transfer.currency_to_iso_alpha_3.toLowerCase()
                                  : ''
                              }`}
                            />{' '}
                            {this.state.transfer.currency_to_iso_alpha_3} <br />
                            <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={c.value} />
                          </React.Fragment>
                        ),
                        maxWidth: 450
                      },
                      {
                        Header: 'Purpose',
                        accessor: 'description',
                        maxWidth: 450
                      },
                      {
                        Header: 'Status',
                        accessor: 'status',
                        Cell: c => (
                          <React.Fragment>
                            <div>
                              <CircularProgressbar
                                className={classes.progressBar}
                                value={this.props.isCompleted ? 100 : this.props.transfer_status * 10}
                                text={`${parseFloat(this.props.isCompleted ? 10 : this.props.transfer_status) * 10}%`}
                                styles={buildStyles({
                                  textColor: '#000',
                                  textSize: '24px',
                                  pathColor: '#632DE6'
                                })}
                              />
                              {this.props.isCompleted ? <span>Completed</span> : <span>In Process</span>}
                            </div>
                          </React.Fragment>
                        ),
                        maxWidth: 250
                      },
                      {
                        Header: 'Action',
                        accessor: 'beneficiary_id', //"nickname", Will switch back to nickname while next build #798
                        // filterMethod: (filter, rows) =>
                        //   matchSorter(rows, filter.value, { keys: ["transfer_id"] }),
                        // filterAll: true,
                        Cell: c => (
                          <React.Fragment>
                            <span>
                              {
                                <Button
                                  fullWidth={true}
                                  color='info'
                                  disabled={this.state.transfer.status !== 8} //disable if transfer status is not completed
                                  size='sm'
                                  onClick={() => {
                                    const win = window.open(
                                      `/transfers/receipt/payout/${c.original.transfer_id}?payout=${c.index}`,
                                      '_blank'
                                    );
                                    win.focus();
                                  }}
                                >
                                  View / PDF Payout Receip
                                </Button>
                              }
                            </span>
                            <span>{this.createActionUi(beneficiary_list_select_options)}</span>
                            <span>{this.createNewBeneficiaryUi()}</span>
                          </React.Fragment>
                        ),
                        maxWidth: 450
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationBottom={false}
                    className='-highlight'
                  />
                </CardBody>
              </Card>
            </GridItem>

            {
              //this.props.current_staff_super_admin && ( //Take out superadmin #1112 workaround by Tom
              this.state.transfer_status === 8 && (
                <React.Fragment>
                  {/* {console.log(this.props)} */}
                  <GridItem md={4}>
                    <Card style={{height: '95%'}}>
                      <CardHeader>
                        <h5>Communication</h5>
                      </CardHeader>
                      <CardBody>
                        <TransferDetailPayoutCommunication
                          id={this.props.client_id}
                          transfer_id={this.props.transfer_id}
                          rate={this.props.rate}
                          amount_from={this.props.amount_from}
                          amount_to={this.props.amount_to}
                          currency_from={this.state.currency_from}
                          currency_to={this.state.currency_to}
                          settlement_date={this.props.settlement_date}
                          beneficiary_id={this.state.beneficiary_id}
                          payouts_list={this.state.payouts_list}
                          // eba_account_name={this.state.entity_bank_account.eba_accout_name}
                          enabled={this.props.current_staff_super_admin}
                          language_nickname={this.state.language_nickname}
                          language_id={this.props.language_id}
                          // remitting_bank_id={this.props.remitting_bank_id}
                          // remittance_reference={remittance_reference}
                          transfer_status={this.state.transfer_status}
                        />
                      </CardBody>
                    </Card>
                  </GridItem>
                </React.Fragment>
              )
            }
            {/*
              hardcode: this.state.payouts_list[0].beneficiary_id at this phase
              (this.state.payouts_list[0].beneficiary_id === 0) &&
            */}
            {typeof this.state.payouts_list[0] === 'undefined'
              ? ''
              : this.props.current_staff_super_admin &&
                this.state.transfer_status === 8 &&
                this.state.payouts_list[0].beneficiary_id === 0 && (
                  <GridItem md={8}>
                    <Card>
                      <CardBody>
                        <React.Fragment>
                          <span>
                            <h5>Payout details for “No beneficiary. from below and click “SAVE” to add” </h5>
                            <CustomReactSelect
                              //error={this.props.transfers_dashboard.new_transfer_data.multiple_beneficiary_id[0].beneficiary_id_state === "error"}
                              label='Select beneficiary'
                              options={beneficiary_list_select_options}
                              value={this.state.beneficiary_id}
                              onChange={this.handleCustomReactSelectChange('beneficiary_id')}
                              isClearable={false}
                              //isDisabled={this.state.prefill_beneficiary}
                            />
                            <p> </p>
                            <Button
                              size='sm'
                              color='info'
                              key='saveBeneficiaryKey'
                              onClick={
                                //test
                                () => {
                                  this.setState({
                                    addBeneficiaryModalOpen: false,
                                    ACTIONaddBeneficiaryModalOpen: false
                                  });

                                  API.put(
                                    'transfers', //copied from client-portal-api, there is a payments in cp project
                                    `/update/update_from_editor/${this.state.payouts_list[0].id}`,
                                    {
                                      body: {
                                        beneficiary_id: this.state.beneficiary_id //...data
                                      }
                                    }
                                  )
                                    .then(database_result => {
                                      console.log(database_result);
                                      //reload the page after getting result
                                      window.location.reload();
                                    })
                                    .catch(error => {
                                      console.log(error);
                                    });
                                }
                              }
                              disabled={!this.state.beneficiary_id}
                            >
                              Save
                            </Button>
                            <Button
                              size='sm'
                              color='info'
                              key='addBeneficiaryKey'
                              onClick={() => this.addBeneficiaryModelOpen()}
                              disabled={Boolean(this.state.beneficiary_id) || !this.state.user_beneficiaries_list} //and {!this.state.user_beneficiaries_list}
                            >
                              Create new beneficiary
                            </Button>
                          </span>
                          <span>{this.createActionUi(beneficiary_list_select_options)}</span>
                          <span>{this.createNewBeneficiaryUi()}</span>
                        </React.Fragment>
                      </CardBody>
                    </Card>
                  </GridItem>
                )}
          </GridContainer>
        </form>
      </React.Fragment>
    );
  }
}

TransferDetailSplitPayPayout.propTypes = {classes: PropTypes.object.isRequired};

export default injectIntl(withStyles(styles)(TransferDetailSplitPayPayout));
