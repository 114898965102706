import React, {Fragment, useState, useEffect} from 'react';
// import PropTypes from "prop-types";

import {connect} from 'react-redux';
// import ClientEdit from "./ClientEdit";

import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

// core components
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
import Card from '../Card/Card.jsx';
import CardBody from '../Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader';
import NavPills from 'components/NavPills/NavPills.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import {API} from 'aws-amplify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import queryString from 'query-string';
import {Summary} from './SimpleClientEdit/Summary';

const staffEditStyles = {
  ...switchStyles,
  ...selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {float: 'right'},
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

export const SimpleClientEdit = ({classes, location, client_detail_id, app_state}) => {
  const [state, setState] = useState({
    client_status_list: [],
    edit_mode: false,
    client_details: {
      id: null,
      nickname: null,
      email: null,
      telephone_mobile: null
    },
    navpill_active: 0
  });

  useEffect(() => {
    fetch();
    initialize();
  }, [app_state.current_team]);

  const initialize = () => {
    const query_strings = queryString.parse(location.search);
    if (query_strings) {
      switch (query_strings.tab) {
        case 'summary':
          setState({
            ...state,
            navpill_active: 1
          });
          break;
        default:
          setState({
            ...state,
            navpill_active: 0
          });
          break;
      }
    }
  };
  const fetch = async () => {
    try {
      const [client_status_list, client_detail] = await Promise.all([
        API.get('clients', '/client-status/get'),
        API.get('clients-simple', `/get-byId/${app_state.current_team.id}/${client_detail_id}`)
      ]);

      if (client_detail.length > 0) {
        setState({
          ...state,
          client_details: {
            id: client_detail[0].id,
            nickname: client_detail[0].nickname,
            email: client_detail[0].email,
            telephone_mobile: client_detail[0].telephone_mobile
          },
          client_status_list
        });
      }
    } catch (e) {
      console.error('error', e);
    }
  };
  const tab_array = [
    {
      tabButton: 'User & Account',
      tabContent: (
        <div style={{padding: 0}}>
          <Summary classes={classes} state={state} />
        </div>
      )
    }
  ];

  const toggleEdit = () => {
    const edit_mode = !state.edit_mode;
    setState({
      ...state,
      edit_mode: edit_mode
    });
  };

  const account_status_nickname = state.client_status_list[state.account_status]
    ? state.client_status_list[state.account_status - 1].nickname
    : '';

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='rose' icon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                {state.edit_mode ? 'Edit Record: ' : 'View Record: '}
                {state.first_name} {state.last_name}{' '}
              </h4>
              <h4
                className={classes.cardIconTitle}
                style={{color: account_status_nickname === 'Approved' ? 'green' : 'red'}}
              >
                Client Status: {account_status_nickname}
              </h4>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.edit_mode}
                      onChange={() => toggleEdit()}
                      value='edit_mode'
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked
                        // icon: classes.switchIcon,
                        // iconChecked: classes.switchIconChecked,
                        // bar: classes.switchBar
                      }}
                    />
                  }
                  classes={{label: classes.label}}
                  label={state.edit_mode ? 'Edit Mode' : 'Read Only'}
                />
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <NavPills color='primary' tabs={tab_array} active={state.navpill_active} />
            <Clearfix />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const SimpleClientEditContainerComponent = connect(mapStateToProps, mapDispatchToProps)(SimpleClientEdit);

export default withRouter(withStyles(staffEditStyles)(SimpleClientEditContainerComponent));
