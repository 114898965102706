import React from 'react';
import {connect} from 'react-redux';
import {fetchRemittanceList} from '../../redux/actions/remittance_accounts';
import {API} from 'aws-amplify';
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import ListTable from "../ListTable/ListTable";
import {Button, Popconfirm, Table, Tag} from "antd";
import {CloseOutlined, DashboardOutlined} from "@ant-design/icons";
import {AppUrlConstants} from "../../AppAPI";

const moment = require('moment');

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    }
};

class Remittancelist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entity_list: [],
            dialog_delete_confirm: false
        };
    }

    async componentDidMount() {
      console.log('FROM REMITTANCE ');
      const {fetchRemittanceList} = this.props;
      fetchRemittanceList();
      const [teams, bank_list, currencies_list] = await Promise.all([
        AppUrlConstants.fetchTeams(),
        AppUrlConstants.fetchBankAccounts(),
        AppUrlConstants.fetchCurrencies()
      ]);
      this.setState({
        teams: teams.sort((a, b) => a.nickname.localeCompare(b.nickname)).map(item => ({
          value: item.nickname,
          text: item.nickname
        })),
        bank_list: bank_list.sort((a, b) => a.nickname.localeCompare(b.nickname)).map(item => ({
          text: item.nickname,
          value: item.nickname
        })),
        currencies_list: currencies_list.sort((a, b) => a.iso_alpha_3.localeCompare(b.iso_alpha_3)).map(item => ({
          value: item.full_name,
          text: `[${item.iso_alpha_3}] ${item.full_name}`
        }))
      })
    }

    handleActive = (remittanceId, active) => {
        API.put('remittance', `/update/id/${remittanceId}`, {body: {active: !active}})
            .then(response => {
                this.props.fetchRemittanceList();
            })
            .catch(error => {
                this.props.fetchRemittanceList();
            });
    };

    buildTableData() {
        const remittance_list_length = this.props.remittance.remittance.length;
        if (remittance_list_length > 0) {
            return this.props.remittance.remittance.map((prop, key) => {
                return {
                    id: prop.id,
                    active: prop.active,
                    currencyId: prop.currencyFullName,
                    teamId: prop.teamNickname,
                    clientId: prop.clientNickname,
                    bankAccountId: prop.bankNickname,
                    amount: prop.thresholdAmount,
                    actions: (
                        <div className='actions-right'>
                            <Button
                                type={'link'}
                                onClick={() => {
                                    this.props.history.push(`/system-settings/remittance/edit/${prop.id}`);
                                }}
                            ><DashboardOutlined/>
                            </Button>
                            <Popconfirm
                                title="Delete the Remittance"
                                description="Are you sure to delete this Remittance?"
                                onConfirm={() => {
                                    API.get('remittance', `/delete/${prop.id}`, {})
                                        .then(response => {
                                            this.props.fetchRemittanceList();
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button type={'link'} size={'small'} danger><CloseOutlined/></Button>
                            </Popconfirm>
                        </div>
                    )
                };
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <Table
                    dataSource={this.buildTableData()}
                    filterable
                    columns={[
                        {
                            title: 'ID',
                            dataIndex: 'id',
                            sorter: {compare: (a, b) => a.id - b.id}
                        },
                        {
                            title: 'Active',
                            dataIndex: 'active',
                            filters: [{text: 'Active', value: 1}, {text: 'Inactive', value: 0}],
                            onFilter: (value, record) => record.active === value,
                            ...this.props.getColumnSearchProps({
                              label: 'Active',
                              filterInputType: 'SELECT',
                              render: (text, record) => <Tag color={text === 1 ? 'success' : 'red'} style={{ cursor: 'pointer' }} onClick={() => this.handleActive(record.id, text)}>{text === 1 ? 'Active' : 'Inactive'}</Tag>
                            })
                        },
                        {
                            title: 'Currency',
                            dataIndex: 'currencyId',
                            filters: this.state.currencies_list,
                            filterSearch: true,
                            onFilter: (value, record) => record.currencyId && record.currencyId.startsWith(value)
                        },
                        {
                          title: 'Threshold Amount',
                          dataIndex: 'amount',
                          ...this.props.getColumnSearchProps({
                            label: 'Threshold Amount',
                            dataIndex: 'amount',
                            render: (text) => text
                          })
                        },
                        {
                            title: 'Team',
                            dataIndex: 'teamId',
                            filters: this.state.teams,
                            filterSearch: true,
                            onFilter: (value, record) => record.teamId && record.teamId.startsWith(value)
                        },
                        {
                            title: 'Client',
                            dataIndex: 'clientId',
                            ...this.props.getColumnSearchProps({
                                label: 'Search Client',
                                dataIndex: 'clientId',
                                render: (text, record) => text
                            })
                        },
                        {
                            title: 'Bank Account',
                            dataIndex: 'bankAccountId',
                            filterSearch: true,
                            filters: this.state.bank_list,
                            onFilter: (value, record) => record.bankAccountId && record.bankAccountId.startsWith(value),
                        },
                        {
                            title: 'Actions',
                            dataIndex: 'actions'
                        }
                    ]}
                    className='-striped -highlight'
                />
            </React.Fragment>
        );
    }
}

// export default withRouter(withStyles(styles)(Remittancelist));
const mapDispatchToProps = dispatch => {
    return {
        fetchRemittanceList: () => {
            dispatch(fetchRemittanceList());
        }
    };
};

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        remittance: state.remittance
    };
};

const RemittancelistContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(styles)(ListTable(Remittancelist))));

export default RemittancelistContainer;
