import {API, Auth} from 'aws-amplify';
import {AppUrlConstants} from "../../AppAPI";


export const UPDATE_CURRENT_BRAND = 'UPDATE_CURRENT_BRAND';
export const UPDATE_CURRENT_TEAM = 'UPDATE_CURRENT_TEAM';
export const UPDATE_CURRENT_USER_ID = 'UPDATE_CURRENT_USER_ID';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_CURRENT_USER_LOG_OUT = 'UPDATE_CURRENT_USER_LOG_OUT';

async function update_staff_database(aws_cognito_id, staff_detail) {
  // console.log(aws_cognito_id, staff_detail);
  API.put('staff', `/update/aws_cognito_id/${aws_cognito_id}`, {body: staff_detail})
    .then(database_result => {
      // console.log(database_result);
      return database_result;
    })
    .catch(error => {
      console.log(error);
      API.put('staff', `/update/aws_cognito_id/${aws_cognito_id}`, {body: staff_detail})
        .then(database_result => {
          // console.log(database_result);
          return database_result;
        })
        .catch(error => {
          console.log(error);
        });
    });
}

async function get_current_staff_details(aws_cognito_id) {
  // console.log(aws_cognito_id, staff_detail);
  return API.get('staff', `/get/aws_cognito_id/${aws_cognito_id}`)
    .then(database_result => {
      // console.log(database_result);
      return database_result;
    })
    .catch(error => {
      console.log(error);
      return API.get('staff', `/get/aws_cognito_id/${aws_cognito_id}`)
        .then(database_result => {
          // console.log(database_result);
          return database_result;
        })
        .catch(error => {
          console.log(error);
        });
    });
}

async function get_current_staff_details_by_staffID(staffID) {
  console.log(staffID);
  return API.get('staff', `/get/${staffID}`)
    .then(database_result => {
      // console.log(database_result);
      return database_result;
    })
    .catch(error => {
      console.log(error);
      // return API.get('staff', `/get/aws_cognito_id/${aws_cognito_id}`)
      //   .then(database_result => {
      //     // console.log(database_result);
      //     return database_result;
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
    });
}

async function get_current_division_details(division_id) {
  // console.log(aws_cognito_id, staff_detail);
  return API.get('divisions', `/get/id/${division_id}`)
    .then(database_result => {
      // console.log(database_result);
      return database_result;
    })
    .catch(error => {
      console.log(error);
      return API.get('divisions', `/get/id/${division_id}`)
        .then(database_result => {
          // console.log(database_result);
          return database_result;
        })
        .catch(error => {
          console.log(error);
        });
    });
}
async function get_current_team_details(team_id) {
  // console.log(team_id);
  return API.get('teams', `/get/id/${team_id}`)
    .then(database_result => {
      // console.log(database_result);
      return database_result;
    })
    .catch(error => {
      console.log(error);
      // return API.get("divisions", `/get/id/${division_id}`)
      //   .then(database_result => {
      //     // console.log(database_result);
      //     return database_result;
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
    });
}

async function get_country_list() {
  // console.log(team_id);
  return AppUrlConstants.fetchCountries()
      .then(database_result => {
        // console.log(database_result);
        return database_result;
      })
      .catch(error => {
        console.log(error);
      });
}

async function get_current_staff_security(id) {
  // console.log(aws_cognito_id, staff_detail);
  return API.get('staff', `/get/security/${id}`)
    .then(database_result => {
      // console.log(database_result);
      return database_result;
    })
    .catch(error => {
      console.log(error);
      return API.get('staff', `/get/security/${id}`)
        .then(database_result => {
          // console.log(database_result);
          return database_result;
        })
        .catch(error => {
          console.log(error);
        });
    });
}

export function updateCurrentTeam(teamId) {
  return async function (dispatch) {
    try {
      const team_details = await get_current_team_details(teamId);
      dispatch(updateCurrentTeam2(team_details));
    } catch (e) {
      console.log(e);
      console.log(e.message);
      // alert(e.,message);
    }
  };
}

export function onLoginSuccess(aws_cognito_id) {
  return async function (dispatch) {
    try {
      await update_staff_database(aws_cognito_id, {
        portal_last_login_datetime: new Date().toISOString().slice(0, 19).replace('T', ' ')
      });
      // const login_ok = await check_login_ok(aws_cognito_id);
      // if (!login_ok) {
      //   try {
      //     await Auth.signOut();
      //     window.location.reload(true);
      //   } catch (e) {
      //     alert(e.message);
      //   }
      // }
      const staff_record = await get_current_staff_details(aws_cognito_id);
      const staff_security = await get_current_staff_security(staff_record.id);
      const division_details = await get_current_division_details(staff_record.division_id);
      // console.log(staff_record)
      const team_details = await get_current_team_details(staff_record.team_id);
      const country_list = await get_country_list();
      // console.log(division_details);
      dispatch(
        updateCurrentStaffMemberIntoStore(aws_cognito_id, staff_record, division_details, team_details, staff_security, country_list)
      );
    } catch (e) {
      console.log(e);
      console.log(e.message);
      // alert(e.message);
    }
  };
}

export function onInAppLoginSuccesStaffID(staffID, aws_cognito_id) {
  return async function(dispatch) {
      try {

          const staff_record = await get_current_staff_details_by_staffID(staffID);
          // console.log(staff_record)
          const staff_security = await get_current_staff_security(staff_record.id);
          // console.log(staff_security)
          const team_details = await get_current_team_details(staff_record.team_id);
          // console.log(team_details);
          const division_details = await get_current_division_details(team_details.division_id);
          // console.log(division_details);
    
          dispatch(
            updateCurrentStaffMemberIntoStore(aws_cognito_id, staff_record, division_details, team_details, staff_security)
          );
    

          // await update_client_database(aws_cognito_id, {
          //     portal_last_login_datetime: new Date()
          //         .toISOString()
          //         .slice(0, 19)
          //         .replace('T', ' '),
          // })
          // const client_record = await getCurrentClientDetailsByClientId(
          //     clientId
          // )
          // const division_details = await get_current_division_details(
          //     client_record.division_id
          // )
          // const team_details = await get_current_team_details(
          //     client_record.team_id
          // )
          // const team_fees = await get_current_team_fees(client_record.team_id)
          // console.log('Teamfees', team_fees)
          // const domain_details = await get_current_domain_details(
          //     division_details.brand_domain
          // )
          // const country_list = await get_current_country_list()
          // dispatch(fetchTransferListV2(client_record.id))
          // // dispatch(fetchBeneficiaryList_New(aws_cognito_id))
          // dispatch(fetchBeneficiaryListByClientId(client_record.id))
          // dispatch(
          //     updateCurrentClientIntoStore(
          //         aws_cognito_id,
          //         client_record,
          //         division_details,
          //         team_details,
          //         team_fees,
          //         domain_details,
          //         country_list
          //     )
          // )
      } catch (e) {
          console.log(e.message)
      }
  }
}

export function check_login_ok(aws_cognito_id) {
  return API.get('staff', `/get/login-check/${aws_cognito_id}`)
    .then(database_result => {
      // console.log(database_result);
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
}

export function updateCurrentStaffMemberIntoStore(
  aws_cognito_id,
  staff_record,
  division_details,
  team_details,
  staff_security,
  country_list
) {
  const staff_team_security = {};
  const staff_division_security = {};
  const staff_entity_security = {};

  for (const item in staff_security.team_access) {
    staff_team_security[staff_security.team_access[item].team_id] = staff_security.team_access[item].access;
  }
  for (const item in staff_security.division_access) {
    staff_division_security[staff_security.division_access[item].division_id] =
      staff_security.division_access[item].access;
  }
  for (const item in staff_security.entity_access) {
    staff_entity_security[staff_security.entity_access[item].entity_id] = staff_security.entity_access[item].access;
  }

  let brand = null;
  switch (staff_record.team_id) {
    case 2:
    case 3:
    case 4:
    case 8:
    case 9:
      brand = 'gostudy';
      break;
    case 6:
    case 7:
      brand = 'redhill';
      break;
    case 10:
    case 11:
      brand = 'abcu';
      break;
    case 12:
    case 13:
    case 14:
      brand = 'smithsonian';
      break;
    case 29:
      brand = 'forexsport';
      break;
    case 37:
      brand = 'abctravel';
      break;
    default:
      brand = null;
  }
  // console.log(brand);

  return {
    type: UPDATE_CURRENT_USER,
    current_staff_id: aws_cognito_id,
    current_staff_aws_cognito_id: aws_cognito_id,
    current_staff_record: staff_record,
    current_staff_super_admin: staff_record.super_admin === 1,
    current_staff_security: {
      staff_team_security,
      staff_division_security,
      staff_entity_security
    },
    current_brand: brand,
    current_division: division_details,
    current_team: team_details,
    country_list: country_list
  };
}

export function updateCurrentTeam2(team_details) {
  return {
    type: UPDATE_CURRENT_TEAM,
    current_team: team_details
  };
}

export function updateCurrentBrand(brand) {
  return {
    type: UPDATE_CURRENT_BRAND,
    brand
  };
}

export function logOutUser() {
  return {type: UPDATE_CURRENT_USER_LOG_OUT};
}
