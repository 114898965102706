import {API} from 'aws-amplify';
import React, {useEffect, useState} from 'react';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import ReactTable from 'react-table';
import Close from '@material-ui/icons/Close';
import Button from 'components/CustomButtons/Button.jsx';
import Dvr from '@material-ui/icons/Dvr';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

export const Teams = () => {
  const [state, setState] = useState({
    teams: [],
    currentTeam: {
      id: '',
      clientId: '',
      nickname: '',
      email: '',
      telephone: '',
      allowAccess: false
    },
    dialog_item_detail_open: false
  });

  const toggleModal = bool => {
    setState({
      ...state,
      dialog_item_detail_open: bool
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetchTem = async id => {
    const response = await API.get('teams', `/get/id/${id}`);

    setState({
      ...state,
      dialog_item_detail_open: true,
      currentTeam: {
        id: response.id,
        clientId: '',
        nickname: response.nickname,
        email: response.contactEmail,
        telephone: response.contactTelephone,
        allowAccess: false
      }
    });
  };

  const fetch = async () => {
    const teams = await API.get('teams', '/get-list-not-deleted');
    console.info('teams', teams);
    setState({
      ...state,
      teams: teams
    });
  };

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <h4>Followings are your teams:</h4>
          <br />
          <ReactTable
            data={state.teams}
            style={{textAlign: 'left'}}
            sortable={false}
            columns={[
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left',
                      paddingRight: 8
                    }}
                  >
                    ID
                  </div>
                ),
                accessor: 'id',
                filterAll: true,
                maxWidth: 500,
                Cell: obj => <div style={{textAlign: 'left'}}>{obj.original.id}</div>
              },
              {
                Header: (
                  <div
                    style={{
                      textAlign: 'left',
                      paddingRight: 8
                    }}
                  >
                    Name
                  </div>
                ),
                accessor: 'nickname',
                filterAll: true,
                maxWidth: 500,
                Cell: obj => <div style={{textAlign: 'left'}}>{obj.original.nickname}</div>
              },
              {
                Header: (
                  <div
                    style={{
                      textAlign: 'left',
                      paddingRight: 8
                    }}
                  >
                    Action
                  </div>
                ),
                accessor: '',
                Cell: obj => (
                  <div className='actions-right' style={{textAlign: 'left'}}>
                    <Button
                      justIcon
                      round
                      simple
                      onClick={() => {
                        fetchTem(obj.original.id);
                      }}
                      color='warning'
                      className='edit'
                    >
                      <Dvr />
                    </Button>
                  </div>
                )
              }
            ]}
            defaultPageSize={20}
          />
        </GridItem>
      </GridContainer>

      {/* ##### Team Details */}

      <Dialog
        open={state.dialog_item_detail_open}
        onClose={() => toggleModal(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <React.Fragment>
          <DialogTitle id='alert-dialog-title'>
            Team Detail: &nbsp;
            {state.currentTeam.id}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <table style={{minWidth: 500}}>
                <tr>
                  <th style={{textAlign: 'left'}}>Name</th>
                  <td>{state.currentTeam.nickname}</td>
                </tr>

                <tr>
                  <th style={{textAlign: 'left'}}>Email</th>
                  <td>{state.currentTeam.email}</td>
                </tr>

                <tr>
                  <th style={{textAlign: 'left'}}>Telephone</th>
                  <td>{state.currentTeam.telephone}</td>
                </tr>
              </table>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toggleModal(false)} color='primary'>
              Close
            </Button>
          </DialogActions>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  );
};
