import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {fetchBatchPaymentsList} from '../../../redux/actions/batch_payments';
import BatchPaymentsPageComponent from '../../../features/Transfers/BatchPayments';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';
import {FormattedMessage} from 'react-intl';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  }
};

const BatchPayments = props => {
  const {classes, fetchBatchPaymentsList, batch_payments} = props;
  React.useEffect(() => {
    fetchBatchPaymentsList();
  }, [fetchBatchPaymentsList]);

  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardIcon color='rose'>
          <PermIdentity />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          <FormattedMessage
            id='batchPaymentsList.cardItem1.batchPaymentsListing'
            defaultMessage={'Batch Payments Listing '}
          />
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <BatchPaymentsPageComponent batch_payments={batch_payments} history={props.history} />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

BatchPayments.propTypes = {
  fetchTransferList: PropTypes.func
};

const mapStateToProps = state => {
  return {
    batch_payments: state.batch_payments
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchBatchPaymentsList: () => {
      dispatch(fetchBatchPaymentsList());
    }
  };
};

const BatchPaymentsContainer = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BatchPayments));

export default BatchPaymentsContainer;
