import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import {FormattedMessage} from 'react-intl';
import {TextareaAutosize} from '@material-ui/core';
import Button from 'components/CustomButtons/Button.jsx';
import {message} from 'antd';
import moment from 'moment';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';

const styles = themes => ({
  customFormControlClasses: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    marginTop: '0px !important',
    marginBottom: '0px !important'
  },
  backgroundColor: {},
  alightRight: {float: 'right'}
});

const fields = [
  {
    label: 'rqUid',
    key: 'rqUid',
    isDisabled: false
  }
  // {
  //   label: 'rqDt',
  //   key: 'rqDt',
  //   isDisabled: true
  // }
];

const subFields = [
  {
    label: 'orderingRefNo',
    key: 'orderingRefNo',
    isDisabled: false
  },
  // {
  //   label: 'institutionId',
  //   key: 'institutionId',
  //   isDisabled: true
  // },
  // {
  //   label: 'debitAcctId',
  //   key: 'debitAcctId',
  //   isDisabled: true
  // },
  {
    label: 'orderingCustName',
    key: 'orderingCustName',
    isDisabled: false
  },
  {
    label: 'orderingCustNationality',
    key: 'orderingCustNationality',
    isLong: true,
    isDisabled: false
  },
  {
    label: 'orderingAddress',
    key: 'orderingAddress',
    isLong: true,
    isDisabled: false
  },
  {
    label: 'orderingCntyOfResidence',
    key: 'orderingCntyOfResidence',
    isDisabled: false
  },
  {
    label: 'orderingCustIdType',
    key: 'orderingCustIdType',
    isDisabled: false
  },
  {
    label: 'orderingCustId',
    key: 'orderingCustId',
    isDisabled: false
  },
  {
    label: 'receivingCustName',
    key: 'receivingCustName',
    isDisabled: false
  },
  {
    label: 'receivingBankCode',
    key: 'receivingBankCode',
    isDisabled: false,
    isSelect: true,
    options: [
      {
        value: '002',
        label: 'BANGKOK BANK PUBLIC COMPANY LIMITED (BBL)'
      },
      {
        value: '004',
        label: 'KASIKORNBANK PUBLIC COMPANY LIMITED (KBANK)'
      },
      {
        value: '006',
        label: 'KRUNG THAI BANK PUBLIC COMPANY LIMITED (KTB)'
      },
      {
        value: '011',
        label: 'TMB BANK PUBLIC COMPANY LIMITED (TMB)'
      },
      {
        value: '014',
        label: 'SIAM COMMERCIAL BANK PUBLIC COMPANY LIMITED (SCB)'
      },
      {
        value: '017',
        label: 'CITIBANK, N.A. (CITI)'
      },
      {
        value: '018',
        label: 'SUMITOMO MITSUI BANKING CORPORATION (SMBC)'
      },
      {
        value: '020',
        label: 'STANDARD CHARTERED BANK (THAI) PUBLIC COMPANY LIMITED'
      },
      {
        value: '022',
        label: 'CIMB THAI BANK PUBLIC COMPANY LIMITED (CIMBT)'
      },
      {
        value: '024',
        label: 'UNITED OVERSEAS BANK (THAI) PUBLIC COMPANY LIMITED'
      },
      {
        value: '025',
        label: 'BANK OF AYUDHYA PUBLIC COMPANY LIMITED (BAY)'
      },
      {
        value: '026',
        label: 'MEGA INTERNATIONAL COMMERCIAL BANK PCL (MEGA ICBC)'
      },
      {
        value: '027',
        label: 'BANK OF AMERICA NATIONAL ASSOCIATION (BA)'
      },
      {
        value: '030',
        label: 'GOVERNMENT SAVING BANK (GSB)'
      },
      {
        value: '031',
        label: 'HONG KONG & SHANGHAI CORPORATION LIMITED (HSBC)'
      },
      {
        value: '032',
        label: 'DEUTSCHE BANK AKTIENGESELLSCHAFT (DEUTSCHE)'
      },
      {
        value: '034',
        label: 'BANK FOR AGRICULTURE AND AGRICULTURAL COOPERATIVES (BAAC)'
      },
      {
        value: '039',
        label: 'MIZUHO BANK BANGKOK BRANCH (MHBC)'
      },
      {
        value: '045',
        label: 'BNP PARIBAS, BANGKOK BRANCH (BNPP)'
      },
      {
        value: '052',
        label: 'BANK OF CHINA LIMITED, BANGKOK BRANCH(BOC)'
      },
      {
        value: '065',
        label: 'THANACHART BANK PUBLIC COMPANY LIMITED (TBANK)'
      },
      {
        value: '066',
        label: 'ISLAMIC BANK OF THAILAND (ISBT)'
      },
      {
        value: '067',
        label: 'TISCO BANK PUBLIC COMPANY LIMITED (TISCO)'
      },
      {
        value: '069',
        label: 'KIATNAKIN BANK PUBLIC COMPANY LIMITED (KK)'
      },
      {
        value: '070',
        label: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED'
      },
      {
        value: '071',
        label: 'THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED (TCRB)'
      },
      {
        value: '073',
        label: 'LAND AND HOUSES BANK PUBLIC COMPANY LIMITED (LH BANK)'
      },
      {
        value: '079',
        label: 'ANZ BANK (THAI) PUBLIC COMPANY LIMITED (ANZ)'
      },
      {
        value: '080',
        label: 'SUMITOMO MITSUI TRUST BANK (THAI) PCL. (SMTB)'
      }
    ]
  },
  // {
  //   label: 'receivingCntyCode',
  //   key: 'receivingCntyCode',
  //   isDisabled: true
  // },
  {
    label: 'txnAmount',
    key: 'txnAmount',
    isDisabled: false
  },
  // {
  //   label: 'txnCurrencyCode',
  //   key: 'txnCurrencyCode',
  //   isDisabled: true
  // },
  // {
  //   label: 'receivingAcctType',
  //   key: 'receivingAcctType',
  //   isDisabled: true
  // },
  {
    label: 'receivingAcctId',
    key: 'receivingAcctId',
    isDisabled: false
  },
  // {
  //   label: 'receivingEmail',
  //   key: 'receivingEmail',
  //   isDisabled: true
  // },
  // {
  //   label: 'txnDt',
  //   key: 'txnDt',
  //   isDisabled: true
  // },
  {
    label: 'purposeCode',
    key: 'purposeCode',
    isDisabled: false,
    isSelect: true,
    options: [
      {
        value: '318005',
        label: 'Product insurance premium / fees'
      },
      {
        value: '318006',
        label: 'Product indemnity insurance'
      },
      {
        value: '318031',
        label: 'Information service charges'
      },
      {
        value: '318033',
        label: 'Advertising & Public relations-related expenses'
      },
      {
        value: '318012',
        label: 'For payments of travel expenses'
      },
      {
        value: '318013',
        label: 'Payments of education-related student expenses'
      },
      {
        value: '318015',
        label: 'Healthcare-related expenses ...'
      },
      {
        value: '318023',
        label: 'Telecommunications / Communication expenses...'
      },
      {
        value: '318024',
        label: 'Construction costs/expenses'
      },
      {
        value: '318025',
        label: 'Royalty fees, trademark fees, patent fees...'
      },
      {
        value: '318028',
        label: 'Fees for advisors, technical assistance...'
      },
      {
        value: '318029',
        label: 'Fees for brokers, front end fee...'
      },
      {
        value: '318032',
        label: 'Representative office expenses'
      },
      {
        value: '318034',
        label: 'Property rent'
      },
      {
        value: '318052',
        label: 'Gifts / Grant of private sector'
      },
      {
        value: '318004',
        label: 'Delivery fees / Freight charge'
      },
      {
        value: '318009',
        label: 'Transportation fees / Fares'
      },
      {
        value: '318134',
        label: 'For payment of exported goods'
      },
      {
        value: '318040',
        label: 'Repatriation of foreigner'
      },
      {
        value: '318069',
        label: 'Condominium purchase'
      }
    ]
  }
  // {
  //   label: 'schedule',
  //   key: 'schedule',
  //   isDisabled: false
  // },
  // {
  //   label: 'otherInfo',
  //   key: 'otherInfo',
  //   isDisabled: false
  // },
  // {
  //   label: 'orderingCntySourceOfFund',
  //   key: 'orderingCntySourceOfFund',
  //   isDisabled: false
  // },
  // {
  //   label: 'orderingCustType',
  //   key: 'orderingCustType',
  //   isDisabled: false
  // }
];

const compassGlobalHoldings = {
  orderingCustName: 'Compass Global Holdings Pty Ltd',
  orderingCustNationality: 'AUS',
  orderingAddress: 'Suite 33 650 George Street Sydney NSW 2000 AUS',
  orderingCntyOfResidence: 'AU',
  orderingCustIdType: '1',
  orderingCustId: '159256014'
};

const acareBank = {
  orderingCustName: 'Acare Business Solutions Pty Ltd',
  orderingCustNationality: 'AUS',
  orderingAddress: 'Shop T26, 730 George Street Haymarket NSW 2000 AUS',
  orderingCntyOfResidence: 'AU',
  orderingCustIdType: '1',
  orderingCustId: '72145761646',
  receivingCustName: 'Acare Business Solutions Co Ltd',
  receivingBankCode: '004',
  txnAmount: '0.00',
  receivingAcctId: '7302131976'
};

const acareBBL = {
  orderingCustName: 'Acare Business Solutions Pty Ltd',
  orderingCustNationality: 'AUS',
  orderingAddress: 'Shop T26, 730 George Street Haymarket NSW 2000 AUS',
  orderingCntyOfResidence: 'AU',
  orderingCustIdType: '1',
  orderingCustId: '72145761646',
  receivingCustName: 'Acare Business Solutions Co Ltd',
  receivingBankCode: '002',
  txnAmount: '0.00',
  receivingAcctId: '0877246116'
};

const acareKrung = {
  orderingCustName: 'Acare Business Solutions Pty Ltd',
  orderingCustNationality: 'AUS',
  orderingAddress: 'Shop T26, 730 George Street Haymarket NSW 2000 AUS',
  orderingCntyOfResidence: 'AU',
  orderingCustIdType: '1',
  orderingCustId: '72145761646',
  receivingCustName: 'Acare Business Solutions Co Ltd',
  receivingBankCode: '006',
  txnAmount: '0.00',
  receivingAcctId: '6900347525'
};

const KBankCodePageComponent = ({classes}) => {
  const [state, setState] = useState({
    rqUid: '20210722-003',
    rqDt: `${moment().add(5, 'minutes').utc().format('YYYY-MM-DDTh:mm:ss+00:00')}`,
    transferInfo: {
      orderingRefNo: 'Missionary Ventures Motors',
      institutionId: '118',
      debitAcctId: '0418271906',
      orderingCustName: 'Compass Global Holdings Pty Ltd',
      orderingCustNationality: 'AUS',
      orderingAddress: 'Suite 33 650 George Street Sydney NSW 2000 AUS',
      orderingCntyOfResidence: 'AU',
      orderingCustIdType: '1',
      orderingCustId: '159256014',
      receivingCustName: 'Free To Serve',
      receivingBankCode: '002',
      receivingCntyCode: 'THA',
      txnAmount: '154000.00',
      txnCurrencyCode: 'THB',
      receivingAcctType: '004',
      receivingAcctId: '3980817989',
      receivingEmail: 'admin@forexsport.com',
      txnDt: `${moment().add(5, 'minutes').utc().format('YYYY-MM-DDTh:mm:ss+00:00')}`,
      purposeCode: '318013',
      schedule: '02',
      otherInfo: null,
      orderingCntySourceOfFund: 'AU',
      orderingCustType: 'COR'
    }
  });

  const handleChange = (stateName, event, isTransferInfo) => {
    if (!isTransferInfo) {
      setState({
        ...state,
        [stateName]: event.target.value
      });
    } else {
      setState({
        ...state,
        transferInfo: {
          ...state.transferInfo,
          [stateName]: event.target.value
        }
      });
    }
  };

  const setValues = obj => {
    let temp = state.transferInfo;
    Object.entries(obj).forEach(item => {
      temp = {
        ...temp,
        [item[0]]: item[1]
      };
    });

    setState({
      ...state,
      transferInfo: temp
    });
  };

  const getValue = () => {
    return `{
      "rqUid": "${state.rqUid}",
       "rqDt": "${moment().add(5, 'minutes').utc().format('YYYY-MM-DDTh:mm:ss+00:00')}",    
       "transferInfo": {
          "orderingRefNo": "${state.transferInfo.orderingRefNo}",
          "institutionId": "${state.transferInfo.institutionId}",
          "debitAcctId": "${state.transferInfo.debitAcctId}",
          "orderingCustName": "${state.transferInfo.orderingCustName}",
          "orderingCustNationality": "${state.transferInfo.orderingCustNationality}",
          "orderingAddress": "${state.transferInfo.orderingAddress}",
          "orderingCntyOfResidence": "${state.transferInfo.orderingCntyOfResidence}",
          "orderingCustIdType": "${state.transferInfo.orderingCustIdType}",
          "orderingCustId": "${state.transferInfo.orderingCustId}",
          "receivingCustName": "${state.transferInfo.receivingCustName}",
          "receivingBankCode": "${state.transferInfo.receivingBankCode}",
          "receivingCntyCode": "${state.transferInfo.receivingCntyCode}",
          "txnAmount": "${state.transferInfo.txnAmount}",
          "txnCurrencyCode": "${state.transferInfo.txnCurrencyCode}",
          "receivingAcctType": "${state.transferInfo.receivingAcctType}",
          "receivingAcctId": "${state.transferInfo.receivingAcctId}",
          "receivingEmail": "${state.transferInfo.receivingEmail}",
          "txnDt": "${moment().add(5, 'minutes').utc().format('YYYY-MM-DDTh:mm:ss+00:00')}",    
          "purposeCode": "${state.transferInfo.purposeCode}",
          "schedule": "${state.transferInfo.schedule}",
          "otherInfo": ${state.transferInfo.otherInfo},
          "orderingCntySourceOfFund": "${state.transferInfo.orderingCntySourceOfFund}",
          "orderingCustType": "${state.transferInfo.orderingCustType}"
          }
  }`;
  };

  const copyValues = () => {
    return `{
      "rqUid": "${state.rqUid}",
      "rqDt": "${moment().add(5, 'minutes').utc().format('YYYY-MM-DDTh:mm:ss+00:00')}",
      "transferInfo": {
          "orderingRefNo": "${state.transferInfo.orderingRefNo}",
          "institutionId": "${state.transferInfo.institutionId}",
          "debitAcctId": "${state.transferInfo.debitAcctId}",
          "orderingCustName": "${state.transferInfo.orderingCustName}",
          "orderingCustNationality": "${state.transferInfo.orderingCustNationality}",
          "orderingAddress": "${state.transferInfo.orderingAddress}",
          "orderingCntyOfResidence": "${state.transferInfo.orderingCntyOfResidence}",
          "orderingCustIdType": "${state.transferInfo.orderingCustIdType}",
          "orderingCustId": "${state.transferInfo.orderingCustId}",
          "receivingCustName": "${state.transferInfo.receivingCustName}",
          "receivingBankCode": "${state.transferInfo.receivingBankCode}",
          "receivingCntyCode": "${state.transferInfo.receivingCntyCode}",
          "txnAmount": "${state.transferInfo.txnAmount}",
          "txnCurrencyCode": "${state.transferInfo.txnCurrencyCode}",
          "receivingAcctType": "${state.transferInfo.receivingAcctType}",
          "receivingAcctId": "${state.transferInfo.receivingAcctId}",
          "receivingEmail": "${state.transferInfo.receivingEmail}",
          "txnDt": "${moment().add(5, 'minutes').utc().format('YYYY-MM-DDTh:mm:ss+00:00')}",
                  "purposeCode": "${state.transferInfo.purposeCode}",
          "schedule": "${state.transferInfo.schedule}",
          "otherInfo": ${state.transferInfo.otherInfo},
          "orderingCntySourceOfFund": "${state.transferInfo.orderingCntySourceOfFund}",
          "orderingCustType": "${state.transferInfo.orderingCustType}"
          }
  }`;
  };

  const handleSelectChange = (event, item) => {
    setState({
      ...state,
      transferInfo: {
        ...state.transferInfo,
        [item.key]: event.value
      }
    });
  };

  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={6}>
          {fields.map((item, index) => {
            return (
              <Fragment>
                <GridContainer key={index}>
                  <GridItem xs={10}>
                    <CustomTextField
                      id={item.key}
                      formControlProps={{
                        fullWidth: true,
                        className: `${classes.customFormControlClasses} ${classes.backgroundColor}`
                      }}
                      inputProps={{
                        value: state[item.key],
                        variant: 'outlined',
                        style: {backgroundColor: 'white'},
                        label: `Enter ${item.label}`,
                        onChange: event => {
                          handleChange(item.key, event, false);
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <br />
              </Fragment>
            );
          })}

          {subFields.map((item, index) => {
            return (
              <Fragment>
                <GridContainer key={index}>
                  <GridItem xs={10}>
                    {item.isSelect ? (
                      <CustomReactSelect
                        label={item.label}
                        options={item.options}
                        value={state.transferInfo[item.key]}
                        onChange={event => handleSelectChange(event, item)}
                      />
                    ) : (
                      <CustomTextField
                        id={item.key}
                        formControlProps={{
                          fullWidth: true,
                          className: `${classes.customFormControlClasses} ${classes.backgroundColor}`
                        }}
                        inputProps={{
                          value: state.transferInfo[item.key],
                          variant: 'outlined',
                          disabled: item.isDisabled,
                          style: {backgroundColor: 'white'},
                          label: `Enter ${item.key}`,
                          onChange: event => {
                            handleChange(item.key, event, true);
                          }
                        }}
                      />
                    )}
                  </GridItem>
                </GridContainer>
                <br />
              </Fragment>
            );
          })}
        </GridItem>
        <GridItem xs={6}>
          <div className={classes.ButtonGroup}>
            <Button
              color='info'
              size='sm'
              round
              className={classes.firstButton}
              onClick={() => setValues(compassGlobalHoldings)}
            >
              Compass Global Holdings
            </Button>
            <Button color='info' size='sm' round className={classes.lastButton} onClick={() => setValues(acareBank)}>
              Acare KBank
            </Button>
            <Button color='info' size='sm' round className={classes.firstButton} onClick={() => setValues(acareBBL)}>
              Acare BBL
            </Button>
            <Button color='info' size='sm' round className={classes.lastButton} onClick={() => setValues(acareKrung)}>
              Acare Krung Thai
            </Button>
          </div>
          <br />
          <TextareaAutosize
            minRows={30}
            style={{width: '100%'}}
            multiline
            aria-label='maximum height'
            disabled
            placeholder='JSON Response'
            value={getValue()}
          />
          <br />
          <Button
            className={classes.alightRight}
            onClick={() => {
              navigator.clipboard.writeText(copyValues());
              message.success('copied!');
            }}
          >
            Copy to Clipboard
          </Button>
        </GridItem>
      </GridContainer>
    </Fragment>
  );
};

KBankCodePageComponent.propTypes = {classes: PropTypes.object.isRequired};

export default withStyles(styles)(KBankCodePageComponent);
