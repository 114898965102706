import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';

import {cardTitle} from '../../assets/jss/material-dashboard-pro-react.jsx';
import {API} from 'aws-amplify';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
const entityNewStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  }
};

class EntitybanksNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eb_long_name: '',
      eb_long_name_state: '',
      eb_short_name: '',
      eb_short_name_state: '',
      eb_address: '',
      address_line_2: '',
      address_suburb: '',
      address_state: '',
      address_postcode: '',
      address_country: '',

      country_list: [],
      country_list_prio: []
    };
  }

  validateForm() {
    var valid = true;
    if (this.state.eb_long_name_state === '') {
      this.setState({eb_long_name_state: 'error'});
      valid = false;
    }
    if (this.state.eb_short_name_state === '') {
      this.setState({eb_short_name_state: 'error'});
      valid = false;
    }
    if (this.state.eb_address_state === '') {
      this.setState({eb_address_state: 'error'});
      valid = false;
    }
    return valid;
  }

  handleSelectChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'checkbox':
        if (event.target.checked) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-length':
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'url':
        if (this.verifyUrl(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'min-value':
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-value':
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'range':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      default:
        break;
    }
    switch (type) {
      case 'checkbox':
        this.setState({[stateName]: event.target.checked});
        break;
      default:
        this.setState({[stateName]: event.target.value});
        break;
    }
  }

  componentDidMount() {
    API.get('countries', `/list_not_deleted`)
      .then(response => {
        this.setState({
          // country_list: response.fullList,
          country_list_prio: response
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  async handleSubmitForm() {
    // if (this.validateForm()) {
    await this.props.createNewEntitybanks({
      eb_long_name: this.state.eb_long_name,
      eb_short_name: this.state.eb_short_name,
      address_line_1: this.state.eb_address,
      eb_active: 1,
      eb_deleted: false,
      address_line_2: this.state.address_line_2,
      address_suburb: this.state.address_suburb,
      address_state: this.state.address_state,
      address_postcode: this.state.address_postcode,
      address_country: this.state.address_country
    });

    this.props.selectEntitybanksUi('list');
  }

  handleCancel() {
    this.props.cancelCreateNewEntitybanks();
  }

  renderForm() {
    const {classes} = this.props;
    return (
      <GridItem xs={12} sm={6}>
        <h3>Enter details for new Entity Banks</h3>
        <form>
          <CustomInput
            success={this.state.eb_long_name_state === 'success'}
            error={this.state.eb_long_name_state === 'error'}
            labelText='Long Name *'
            id='eb_long_name'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.eb_long_name,
              onChange: event => this.change(event, 'eb_long_name', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            success={this.state.eb_short_name_state === 'success'}
            error={this.state.eb_short_name_state === 'error'}
            labelText='Short Name*'
            id='eb_short_name'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.eb_short_name,
              onChange: event => this.change(event, 'eb_short_name', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            success={this.state.eb_address_state === 'success'}
            error={this.state.eb_address_state === 'error'}
            labelText='Address Line 1'
            id='eb_address'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.eb_address,
              onChange: event => this.change(event, 'eb_address', 'length', 0),
              type: 'text'
            }}
          />

          <CustomInput
            labelText='Address Line 2'
            id='address_line_2'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.address_line_2,
              onChange: event => this.change(event, 'address_line_2', 'length', 0),
              type: 'text'
            }}
          />

          <CustomInput
            labelText='Address suburb'
            id='address_suburb'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.address_suburb,
              onChange: event => this.change(event, 'address_suburb', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            labelText='Address State'
            id='address_state'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.address_state,
              onChange: event => this.change(event, 'address_state', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            labelText='Address postcode'
            id='eb_address_postcode'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.eb_address_postcode,
              onChange: event => this.change(event, 'eb_address_postcode', 'length', 0),
              type: 'text'
            }}
          />

          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
              Select Country
            </InputLabel>

            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.address_country || '0'}
              onChange={this.handleSelectChange}
              // onChange={this.change(event, "residential_street_country", "length", 3)}
              inputProps={{
                name: 'address_country',
                id: 'address_country'
              }}
            >
              <MenuItem
                key='0x0'
                value='0'
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Select Country
              </MenuItem>

              {this.state.country_list_prio
                .sort((a, b) => a.list_priority < b.list_priority)
                .map(item => {
                  return (
                    <MenuItem
                      key={item.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={item.id}
                    >
                      {item.full_name}
                    </MenuItem>
                  );
                })}
              {/*<MenuItem*/}
              {/*  key="0x0"*/}
              {/*  value="0"*/}
              {/*  disabled*/}
              {/*  classes={{*/}
              {/*    root: classes.selectMenuItem*/}
              {/*  }}*/}
              {/*>*/}
              {/*  --------------------------------------------------*/}
              {/*</MenuItem>*/}
              {/*{this.state.country_list*/}
              {/*  .sort((a, b) => a.full_name.localeCompare(b.full_name))*/}
              {/*  .map(item => {*/}
              {/*    return (*/}
              {/*      <MenuItem*/}
              {/*        key={item.id}*/}
              {/*        classes={{*/}
              {/*          root: classes.selectMenuItem,*/}
              {/*          selected: classes.selectMenuItemSelected*/}
              {/*        }}*/}
              {/*        value={item.id}*/}
              {/*      >*/}
              {/*        {item.full_name}*/}
              {/*      </MenuItem>*/}
              {/*    );*/}
              {/*  })}*/}
            </Select>
          </FormControl>

          <div className={classes.formCategory}>
            <small>*</small> Required fields
          </div>
          <Button color='rose' onClick={() => this.handleSubmitForm()} className={classes.registerButton}>
            Save
          </Button>
          <Button
            // color=""
            onClick={() => this.handleCancel()}
            className={classes.registerButton}
          >
            Cancel
          </Button>
        </form>
      </GridItem>
    );
  }

  render() {
    return (
      <div>
        <GridContainer>{this.renderForm()}</GridContainer>
      </div>
    );
  }
}

EntitybanksNew.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(entityNewStyles)(EntitybanksNew));
