import React from 'react';

import { connect } from 'react-redux';
import {
    fetchStaffListNotDeleted,
    loadStaffMemberDetails,
    selectStaffUi,
    setStaffDetailId
} from '../../redux/actions/staff';


import withStyles from '@material-ui/core/styles/withStyles';
import { API } from 'aws-amplify';
import { AiOutlineSearch } from 'react-icons/ai';
import { withRouter } from 'react-router-dom';
import {Button, Popconfirm, Table } from 'antd'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import ListTable from '../ListTable/ListTable';

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    }
};

class StaffTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog_delete_confirm: false,
            dialog_delete_item: 0,
            dialog_delete_item_name: '',
            staffList: []
        };
    }

    componentDidMount = () => {
        // const {fetchStaffList} = this.props;
        // fetchStaffList();
        this.getStaffList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
            console.log(this.props.app_state.current_staff)
            // this.props.fetchStaffList()
            this.getStaffList()
        }
    }

    getStaffList = () => {
        if (!this.props.app_state.current_staff) {
            this.setState({ staffList: [] });
        } else {
            const staffID = this.props.app_state.current_staff.id
            API.get('staff', `/list/staffID/${staffID}`, {})
                .then(response => {
                    console.log(response);
                    this.setState({ staffList: response });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }


    handle_delete_dialog_click = item_to_delete => {
        this.setState({ dialog_delete_confirm: false });
        API.put('staff', `/update/id/${item_to_delete}`, { body: { deleted: true } })
            .then(() => this.props.fetchStaffList())
            .catch(error => console.log(error));
    };

    handle_delete_dialog_cancel = () => {
        this.setState({
            dialog_delete_confirm: false,
            dialog_delete_item: 0,
            dialog_delete_item_name: ''
        });
    };

    buildTableData() {
        // console.log(this.props);

        // Check to see if user is super_admin
        const super_admin = this.props.app_state.current_staff_super_admin;

        // const staff_list_length = this.props.staff.staff_list.length;
        // if (staff_list_length > 0) {
        if (this.state.staffList.length > 0) {
            // return this.props.staff.staff_list.map((prop, key) => {
            return this.state.staffList.map((prop) => {
                // console.log(prop);
                return {
                    id: prop.id,
                    name: `${prop.first_name} ${prop.last_name}`,
                    first_name: prop.first_name,
                    last_name: prop.last_name,
                    email: prop.email,
                    aws: prop.aws_cognito_id,
                    team_nickname: prop.team_nickname,
                    division_nickname: prop.division_nickname,
                    entity_nickname: prop.entity_nickname,
                    teamAdmin: prop.security_team_admin,
                    backoffice: prop.backofficeStaff,
                    actions: (
                        // we've added some custom button actions
                        <div className='actions-right'>
                            <Button
                                type={'link'}
                                onClick={() => {
                                    this.props.history.push(`/settings/staffedit/${prop.id}`);
                                }}
                                color='warning'
                                className='edit'
                            >
                                <i className={'fa fa-edit'}></i>
                            </Button>{' '}
                            {super_admin && (
                                <Popconfirm
                                    title="Delete the Beneficiary"
                                    description="Are you sure to delete this Beneficiary?"
                                    onConfirm={() => this.handle_delete_dialog_click(prop.id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type={'primary'} size={'small'} danger><i className={'fa fa-trash'}></i></Button>
                                </Popconfirm>
                            )}{' '}
                        </div>
                    )
                };
            });
        }
    }

    getFilter = (filter, onChange) => {
        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <AiOutlineSearch />
                    <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
                </div>
            </React.Fragment>
        );
    };

    render() {
        const team_name = this.state.staffList
            .filter(u => u.team_nickname != null)
            .map((prop) => {
                return prop.team_nickname;
            });
        const team_name_unique = [...new Set(team_name)];
        const team_name_option_list = team_name_unique.map((prop) => {
            return {
                text: prop,
                value: prop
            }
        });

        return (
            <React.Fragment>
                <Table
                    dataSource={this.buildTableData()}
                    columns={[
                        {
                            title: 'ID',
                            dataIndex: 'id',
                            key: 'id',
                            sorter: (a, b) => a.id - b.id,
                        },
                        {
                            title: 'Last Name',
                            key: 'last_name',
                            dataIndex: 'last_name',
                            sorter: (a, b) => (a.last_name || '').localeCompare(b.last_name, undefined, { sensitivity: 'base' }),
                            ...this.props.getColumnSearchProps({
                                label: 'Last Name',
                                dataIndex: 'last_name',
                                render: (text) => text
                            })
                        },
                        {
                            title: 'First Name',
                            dataIndex: 'first_name',
                            key: 'first_name',
                            sorter: (a, b) => (a.first_name || '').localeCompare(b.first_name, undefined, { sensitivity: 'base' }),
                            ...this.props.getColumnSearchProps({
                                label: 'First Name',
                                dataIndex: 'first_name',
                                render: (text) => text
                            })
                        },
                        {
                            title: 'Email',
                            dataIndex: 'email',
                            key: 'email',
                            sorter: (a, b) => (a.email || '').localeCompare(b.email, undefined, { sensitivity: 'base' }),
                        },
                        {
                            title: 'Team',
                            dataIndex: 'team_nickname',
                            filters: team_name_option_list,
                            filterSearch: true,
                            sorter: (a, b) => (a.team_nickname || '').localeCompare(b.team_nickname, undefined, { sensitivity: 'base' }),
                            onFilter: (value, record) => record.team_nickname === value,
                        },
                        {
                            title: 'Level',
                            dataIndex: 'teamAdmin',
                            sorter: (a, b) => a.teamAdmin - b.teamAdmin,
                            ...this.props.getColumnSearchProps({
                                filterInputType: 'SELECT',
                                render: (text, record) => {
                                        let result = '';
                                        if (text) {
                                            result += '[Team Admin]'
                                        }
                                        if (record.backoffice) {
                                            result += ' [Backoffice]'
                                        }
                                        return result;
                                }
                            })
                        },
                        {
                            title: 'Actions',
                            dataIndex: 'actions',
                            key: 'actions'
                        }
                    ]}
                />
            </React.Fragment>
        );
    }
}

StaffTable = withRouter(withStyles(styles)(StaffTable));
const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        staff: state.staff
    };
};

const mapDispatchToProps = dispatch => {
    return {
        selectStaffUi: value => {
            dispatch(selectStaffUi(value));
        },
        setStaffDetailId: aws_cognito_id => {
            dispatch(setStaffDetailId(aws_cognito_id));
        },
        fetchStaffList: () => {
            dispatch(fetchStaffListNotDeleted());
        },
        loadStaffMemberDetails: aws_cognito_id => {
            // console.log(data)
            dispatch(loadStaffMemberDetails(aws_cognito_id));
        }
    };
};

const StaffTableContainer = connect(mapStateToProps, mapDispatchToProps)(ListTable(StaffTable));

export default StaffTableContainer;
