import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import ReactTable from 'react-table';
import {FormattedNumber} from 'react-intl';
import Table from 'components/Table/Table.jsx';
import {injectIntl} from 'react-intl';

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import {API} from 'aws-amplify';

import queryString from 'query-string';

const axios = require('axios');
var moment = require('moment');

const forexWorldWideLogo = require('assets/img/pdf_img/forexworldwide_logo_transparent_280x50.42dc12df.png');
const forexSportLogo = require('assets/img/pdf_img/fxsport_signature_gmail.png');
const goStudyLogo = require('assets/img/pdf_img/GOSTUDY_HorizontalUsage_Black_250x50.png');

const style = {
  root: {}
};

const TheadComponent = props => null; // a component returning null (to hide) or you could write as per your requirement

class PayoutReceiptPage extends React.Component {
  state = {
    value: 0,
    currencies_list_priority: [],
    map_data: {},
    beneficiaries_list: [],
    beneficiary_id: '',
    transfer_heading: 'Current Transfers',
    transfer: {}
  };

  componentDidMount() {
    // console.log(this.props.match.params);
    this.updateTransferData(this.props.match.params.transfer_id);
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.prefill !== this.props.prefill) {
      this.updateTransferData();
    }
  }

  updateTransferData(option_value) {
    this.setState({transfer_id: option_value});

    API.get('transfers', `/get-full/id/${option_value}`)
      .then(response => {
        // console.log(response);
        this.setState(
          {
            transfer: response.fullList,
            payouts_list: response.payouts
          },
          () => {
            API.get('clients', `/get/id/${response.fullList.client_id}`)
              .then(response => {
                // console.log(response);
                // this.setState({
                //   client_detail: response
                // });
                this.setState(
                  prevState => ({
                    transfer: {
                      ...prevState.transfer,
                      client_detail: response
                    }
                  }),
                  () => {
                    this.queryStrings();
                  }
                );
                // Todo: Need to call other APIs to get current domain
                API.get('teams', `/get/div-entity/${response.team_id}`)
                  .then(response_teams => {
                    // console.log(response_teams);
                    // this.setState({
                    //   teams_get_division_and_entity: response_teams
                    // });

                    // Get all domain names if we don't add another API function for getting domain name by id
                    API.get('admin', `/domains/get`)
                      .then(response_admin => {
                        // console.log(response_admin);
                        // this.setState({
                        //   admin_domains_get_all: response_admin
                        // });
                        for (let domain of response_admin) {
                          if (domain.id === response_teams.entity_id) {
                            this.setState(prevState => ({
                              transfer: {
                                ...prevState.transfer,
                                client_detail: {
                                  ...prevState.transfer.client_detail,
                                  current_domain: domain
                                }
                              }
                            }));
                          }
                        }
                      })
                      .catch(error => {
                        console.log(error);
                      });
                  })
                  .catch(error => {
                    console.log(error);
                  });
              })
              .catch(error => {
                console.log(error);
              });
          }
        );

        API.get('currencies', `/get/${response.fullList.currency_from_id}`)
          .then(response => {
            // console.log(response);
            this.setState({
              currency_from: response
            });
          })
          .catch(error => {
            console.log(error);
          });

        API.get('currencies', `/get/${response.fullList.currency_to_id}`)
          .then(response => {
            // console.log(response);
            this.setState({
              currency_to: response
            });
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
        API.get('transfers', `/get-full/id/${this.props.transfer_id}`).then(response => {
          //console.log(response)
          this.setState({
            transfer: response.fullList,
            payouts_list: response.payouts
          });
        });
      });

    API.get('beneficiaries', `/beneficiaries/get`).then(response => {
      // console.log(response);
      this.setState({
        beneficiaries_list: response
      });
      return response;
    });

    API.get('currencies', `/list_not_deleted`)
      .then(response => {
        // console.log(response);
        this.setState({
          currencies_list_priority: response
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  queryStrings() {
    const query_strings = queryString.parse(this.props.location.search);
    // console.log(query_strings);
    if (Object.keys(query_strings).length !== 0) {
      this.setState({payout_number: Number(query_strings.payout)});
    }
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if a value is larger than a number or not
  isLargerThanNumber(value, number) {
    if (value !== '' && value >= number) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'isLargerThanNumber':
        if (this.isLargerThanNumber(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
  }

  handleChange = (event, value) => {
    this.setState({value});
  };
  handleChangeIndex = index => {
    this.setState({value: index});
  };

  printDocument() {
    const input = document.getElementById('divToPrint');
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      // pdf.output('dataurlnewwindow');
      pdf.save(`transfer_receipt_${this.props.match.params.transfer_id.padStart(5, '0')}`);
    });
  }

  buildData = () => {
    let return_data = [];

    return_data.push({date: ['DATE', 'sdsaa']});

    return return_data;
  };

  //temp <img src={brandingImg}https://forexworldwide.com/static/media/forexworldwide_logo_transparent_280x50.42dc12df.png" alt="ForexWorldWide Logo"/>

  /*
   * = There are three logos images we can use in here #882 by Tom
   * forexWorldWideLogo
   * forexSportLogo
   * goStudyLogo
   */
  getSignatureLine = brand => {
    // console.log("== this is "+brand);
    switch (brand) {
      case '1':
        return (
          <React.Fragment>
            <p>
              <br />
              <br />
              <img src={forexWorldWideLogo} alt='ForexWorldWide Logo' />
              <br />
              <strong>Forex WorldWide</strong>
              <br />
              ABN 22 147 363 175
              <br />
              AFSL 401379
              <br />
              Level 4, 100 Collins Street
              <br />
              Melbourne Vic 3000
              <br />
              <br />E admin@forexworldwide.com
              <br />T +61 (03) 9008 1880
              <br />W www.forexworldwide.com
              <br />
            </p>
            <p>
              Forex Sport Pty Ltd, ABN 22 147 363 175, is regulated by the Australian Securities and Investments
              Commission (ASIC) in Australia and holds an Australian Financial Services Licence (AFSL 401379)
            </p>
          </React.Fragment>
        );
        break;
      case '2':
        break;
      case '4':
        return (
          <React.Fragment>
            <p>
              <strong>Forex Student</strong>
              <br />
              Forex Student is a trading name of Forex Sport Pty Ltd AFSL 401379.
              <br />
              Forex Student is a provider of money transfer services for Go Study Australia Pty Ltd.
              <br />
              ustralia Pty Ltd.
              <br />
              <br />
              <table width='40%'>
                <tr>
                  <td>
                    <img src={forexWorldWideLogo} alt='ForexWorldWide Logo' />
                  </td>
                  <td>
                    <img src={goStudyLogo} alt='ForexWorldWide Logo' />
                  </td>
                </tr>
                <tr>
                  <td>
                    ABN 22 147 363 175
                    <br />
                    AFSL 401379
                    <br />
                    Level 4, 100 Collins Street
                    <br />
                    Melbourne Vic 3000
                    <br />
                    <br />E admin@forexworldwide.com
                    <br />T +61 (03) 9008 1880
                    <br />W www.forexworldwide.com
                    <br />
                  </td>
                  <td>
                    ABN 000 000 000 <br />
                    <br />
                    000 <br />
                    000
                    <br />
                    <br />E abc@abc.com
                    <br />T +61 (03) 0000 0000
                    <br />F +61 (03) 0000 0000
                    <br />W www.gostudy.com.au
                    <br />
                  </td>
                </tr>
              </table>
              <br />
            </p>
            <p>
              Forex Sport Pty Ltd, ABN 22 147 363 175, is regulated by the Australian Securities and Investments
              Commission (ASIC) in Australia and holds an Australian Financial Services Licence (AFSL 401379)
            </p>
          </React.Fragment>
        );
        break;
      case '24':
        return (
          <React.Fragment>
            <p>
              <br />
              <br />
              <img src={forexSportLogo} alt='ForexSport Logo' />
              <br />
              <br />
              <strong>Forex Sport</strong>
              <br />
              ABN 22 147 363 175
              <br />
              AFSL 401379
              <br />
              Level 4, 100 Collins Street
              <br />
              Melbourne Vic 3000
              <br />
              <br />E admin@forexsport.com
              <br />T +61 (03) 9008 1880
              <br />W www.forexsport.com
              <br />
            </p>
            <p>
              Forex Sport Pty Ltd, ABN 22 147 363 175, is regulated by the Australian Securities and Investments
              Commission (ASIC) in Australia and holds an Australian Financial Services Licence (AFSL 401379)
            </p>
          </React.Fragment>
        );
        break;
      case '29':
        return (
          <React.Fragment>
            <p>
              <br />
              <br />
              <img src={forexSportLogo} alt='ForexSport Logo' />
              <br />
              <strong>Forex Sport</strong>
              <br />
              ABN 22 147 363 175
              <br />
              AFSL 401379
              <br />
              Level 4, 100 Collins Street
              <br />
              Melbourne Vic 3000
              <br />
              <br />E admin@forexsport.com
              <br />T +61 (03) 9008 1880
              <br />W www.forexsport.com
              <br />
            </p>
            <p>
              Forex Sport Pty Ltd, ABN 22 147 363 175, is regulated by the Australian Securities and Investments
              Commission (ASIC) in Australia and holds an Australian Financial Services Licence (AFSL 401379)
            </p>
          </React.Fragment>
        );
        break;
      default:
        return (
          <React.Fragment>
            <p>
              <br />
              <br />
              <img src={forexWorldWideLogo} alt='ForexWorldWide Logo' />
              <br />
              <strong>Forex WorldWide</strong>
              <br />
              ABN 22 147 363 175
              <br />
              AFSL 401379
              <br />
              Level 4, 100 Collins Street
              <br />
              Melbourne Vic 3000
              <br />
              <br />E admin@forexworldwide.com
              <br />T +61 (03) 9008 1880
              <br />W www.forexworldwide.com
              <br />
            </p>
            <p>
              Forex Sport Pty Ltd, ABN 22 147 363 175, is regulated by the Australian Securities and Investments
              Commission (ASIC) in Australia and holds an Australian Financial Services Licence (AFSL 401379)
            </p>
          </React.Fragment>
        );
    }
  };

  render() {
    const {classes} = this.props;

    if (!this.props.app_state.current_staff) {
      return null;
    }
    // if (this.props.app_state.current_client.account_status == 1) {
    //   this.props.history.push("/pending-registration");
    // }
    // if (this.props.app_state.current_client.account_status == 5) {
    //   this.props.history.push("/registration");
    // }

    // if (this.props.app_state.current_client.account_status == 6) {
    //   this.props.history.push("/migrate");
    // }

    if (!this.state.payouts_list) {
      return null;
    }
    // assign corresponding payout in payout_list to payout
    let payout = '';
    if (this.state.payout_number < this.state.payouts_list.length && this.state.payout_number >= 0)
      payout = this.state.payouts_list[this.state.payout_number];

    if (!payout)
      return (
        <React.Fragment>
          <strong>Error: Unable to access receipt</strong>
        </React.Fragment>
      );
    if (!this.state.currency_from) return null;
    if (!this.state.currency_to) return null;
    if (!this.state.transfer.client_detail.current_domain) return null;

    // Branding for top of sidebar (based on app_state.current_division
    // ************************************************************************************************
    // let logo = require(`../../../assets/img/receipts/${this.props.app_state.current_domain.domain_receipt_brand_image}`);
    let logo = require(`../../../assets/img/receipts/${this.state.transfer.client_detail.current_domain.domain_receipt_brand_image}`);
    // let logoText = "Forex WorldWide";
    // if (this.props.app_state.current_division) {
    //   if (this.props.app_state.current_division.brand_sidebar_logo)
    //     logo = require(`../../assets/img/brand/${this.props.app_state.current_division.brand_sidebar_logo}`);
    //   if (this.props.app_state.current_division.division_shortname)
    //     logoText = this.props.app_state.current_division.division_shortname + " Portal";
    // }
    // ************************************************************************************************

    // console.log(this.state);
    // console.log(this.state.transfer.client_detail.team_id);
    // console.log(this.props.current_user.team_id);
    // console.log(this.props.current_user.team_id ? this.props.current_user.team_id : 0);
    return (
      <React.Fragment>
        <div>
          <GridContainer alignItems='stretch'>
            <GridItem xs={12}>
              <Card style={{height: '90%'}}>
                <CardHeader color='info' stats icon />
                <CardBody>
                  <p className={classes.cardCategory}>
                    {/* {branding} */}
                    {/*<FormattedMessage*/}
                    {/*  id="dashboard.heading1"*/}
                    {/*  defaultMessage={*/}
                    {/*    `Welcome to`}*/}
                    {/*/>*/}
                  </p>
                  <GridContainer justify='center'>
                    <GridItem xs={12} md={10}>
                      <div style={{float: 'right'}}>
                        <Button color='primary' style={{float: 'right'}} onClick={() => this.printDocument()}>
                          Save PDF
                        </Button>
                      </div>
                    </GridItem>
                  </GridContainer>

                  <div
                    id='divToPrint'
                    className='mt4'
                    style={{
                      hidden: 'hidden',

                      width: '210mm',
                      minHeight: '297mm',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      padding: '20mm'
                    }}
                  >
                    <img src={logo} alt='logo' className={classes.img} />
                    <br />
                    <hr />
                    <h3 className={classes.cardTitle}>Foreign Exchange Deal Confirmation</h3>
                    <br />

                    <GridContainer justify='center'>
                      <GridItem xs={12} md={12}>
                        <h5 style={{fontFamily: 'Arial'}}>Transaction Details</h5>
                        <div style={{border: '1px solid #a1a1a1'}}>
                          <table>
                            <tbody
                              style={{
                                fontFamily: 'Arial',
                                textAlign: 'left',
                                verticalAlign: 'top'
                              }}
                            >
                              <tr>
                                <th
                                  style={{
                                    width: 150,
                                    paddingTop: 4,
                                    paddingBottom: 8,
                                    paddingLeft: 8
                                  }}
                                >
                                  Date
                                </th>
                                <td style={{paddingTop: 4, paddingBottom: 8}}>
                                  <React.Fragment>
                                    {this.state.transfer.settlement_date ? (
                                      <React.Fragment>
                                        {moment(this.state.transfer.settlement_date).format('DD/MM/YYYY')}
                                      </React.Fragment>
                                    ) : (
                                      ''
                                    )}
                                  </React.Fragment>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  style={{
                                    width: 150,
                                    paddingTop: 4,
                                    paddingBottom: 8,
                                    paddingLeft: 8
                                  }}
                                >
                                  Client
                                </th>
                                <td style={{paddingTop: 4, paddingBottom: 8}}>
                                  <React.Fragment>
                                    {this.state.transfer.client_nickname ? this.state.transfer.client_nickname : ''}
                                  </React.Fragment>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  style={{
                                    width: 150,
                                    paddingTop: 4,
                                    paddingBottom: 8,
                                    paddingLeft: 8
                                  }}
                                >
                                  Transfer Reference
                                </th>
                                <td style={{paddingTop: 4, paddingBottom: 8}}>
                                  <React.Fragment>
                                    {this.state.transfer.id
                                      ? `TR${this.state.transfer.id.toString().padStart(6, '0')}`
                                      : ''}
                                  </React.Fragment>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  style={{
                                    width: 150,
                                    paddingTop: 4,
                                    paddingBottom: 8,
                                    paddingLeft: 8
                                  }}
                                >
                                  Client Reference
                                </th>
                                <td style={{paddingTop: 4, paddingBottom: 8}}>
                                  <React.Fragment>
                                    {this.state.payouts_list.length > 1
                                      ? 'Multiple references (see below)'
                                      : this.state.payouts_list[0].detail_1}
                                  </React.Fragment>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  style={{
                                    width: 150,
                                    paddingTop: 4,
                                    paddingBottom: 8,
                                    paddingLeft: 8
                                  }}
                                >
                                  Client Buys
                                </th>
                                <td style={{paddingTop: 4, paddingBottom: 8}}>
                                  <React.Fragment>
                                    {this.state.transfer.amount_to ? (
                                      <React.Fragment>
                                        <div
                                          className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                        />{' '}
                                        {this.state.currency_to.iso_alpha_3.toUpperCase()}{' '}
                                        {this.props.intl.formatNumber(this.state.transfer.amount_to, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}
                                      </React.Fragment>
                                    ) : (
                                      ''
                                    )}
                                  </React.Fragment>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* <div style={{ border: "1px solid #777" }}></div>
                          <Table
                            size="small"
                            tableData={[
                              [
                                "Date",
                                <React.Fragment>
                                  {this.state.transfer.settlement_date ? (
                                    <React.Fragment>
                                      {moment(
                                        this.state.transfer.settlement_date
                                      ).format("DD/MM/YYYY")}
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              ],
                              [
                                "Client",
                                <React.Fragment>
                                  {this.state.transfer.client_nickname
                                    ? this.state.transfer.client_nickname
                                    : ""}
                                </React.Fragment>
                              ],
                              [
                                "Transfer Reference",
                                <React.Fragment>
                                  {this.state.transfer.id
                                    ? `TR${this.state.transfer.id.toString().padStart(6, '0')}`
                                    : ""}
                                </React.Fragment>
                              ],
                              [
                                "Client Reference",
                                <React.Fragment>
                                  {payout.detail_1}
                                </React.Fragment>
                              ],
                              [
                                "Client Buys",
                                <React.Fragment>
                                  {this.state.transfer.amount_to ? (
                                    <React.Fragment>
                                      <div
                                        className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                      />{" "}
                                      {this.state.currency_to.iso_alpha_3.toUpperCase()}{" "}
                                      {this.props.intl.formatNumber(
                                        payout.amount_to,
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        }
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              ]
                            ]}
                          />
                          </div>*/}
                        <br />
                        <h5 style={{fontFamily: 'Arial'}}>Payout Details</h5>

                        {payout && (
                          <div style={{border: '1px solid #a1a1a1'}}>
                            <table>
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      verticalAlign: 'top',
                                      paddingRight: 20
                                    }}
                                  >
                                    {payout.ledgerTo ? 'Holding Account' : null}
                                    <strong>{payout.nickname}</strong>
                                    <br />
                                    <div
                                      className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                    />{' '}
                                    <strong>
                                      {this.state.currency_to.iso_alpha_3.toUpperCase()}{' '}
                                      {this.props.intl.formatNumber(payout.amount_to, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      })}
                                    </strong>
                                    <br />
                                    <br />
                                    {payout.ben_address_line_1 ? (
                                      <span>
                                        {payout.ben_address_line_1}
                                        <br />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    {payout.ben_address_line_2 ? (
                                      <span>
                                        {payout.ben_address_line_2}
                                        <br />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    {payout.ben_address_suburb ? <span>{payout.ben_address_suburb} </span> : ''}
                                    {payout.ben_address_postcode ? <span>{payout.ben_address_postcode} </span> : ''}
                                  </td>
                                  <td style={{verticalAlign: 'top'}}>
                                    <br />
                                    <br />
                                    <br />
                                    {payout.bank_legal_name ? (
                                      <span>
                                        {payout.bank_legal_name}
                                        <br />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    {payout.swift_code ? (
                                      <span>
                                        SWIFT Code: {payout.swift_code}
                                        <br />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    {payout.iban ? (
                                      <span>
                                        IBAN: {payout.iban}
                                        <br />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    {payout.bsb_code ? (
                                      <span>
                                        BSB: {payout.bsb_code}
                                        <br />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    {payout.aba_routing_number ? (
                                      <span>
                                        ABA routing: {payout.aba_routing_number}
                                        <br />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    {payout.sort_code ? (
                                      <span>
                                        Sort Code: {payout.sort_code}
                                        <br />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    {payout.transit_code ? (
                                      <span>
                                        Transit Code: {payout.transit_code}
                                        <br />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    {payout.bank_code ? (
                                      <span>
                                        Bank Code: {payout.bank_code}
                                        <br />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    {payout.account_number ? (
                                      <span>
                                        Account Number: {payout.account_number}
                                        <br />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )}
                      </GridItem>
                    </GridContainer>
                    <hr />
                    <GridContainer justify='center'>
                      <GridItem xs={12} md={12}>
                        {' '}
                        <div>
                          {' '}
                          {this.getSignatureLine(
                            `${
                              !!this.state.transfer.client_detail.team_id
                                ? this.state.transfer.client_detail.team_id
                                : 0
                            }`
                          )}{' '}
                        </div>{' '}
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>
                <CardFooter />
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    app_state: state.app_state
  };
};

// const mapDispatchToProps = (dispatch) => {
// return {
//   onLoginSuccess: (user_id) => {
//     dispatch(onLoginSuccess(user_id));
//   }
// };
// };

const PayoutReceiptPageContainer = connect(
  mapStateToProps
  // mapDispatchToProps
)(injectIntl(withRouter(withStyles(style)(PayoutReceiptPage))));

export default PayoutReceiptPageContainer;
