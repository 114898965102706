import {CardHeader, FormControl, FormControlLabel, InputAdornment, Switch, withStyles} from '@material-ui/core';
import {SignalCellularNullTwoTone} from '@material-ui/icons';
import {API, Storage} from 'aws-amplify';
import React, {useEffect, useState} from 'react';
import Card from '../Card/Card';
import Datetime from 'react-datetime';
import moment from 'moment';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react';
import CardBody from '../Card/CardBody';
import NavPills from 'components/NavPills/NavPills.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import HorizontalStepper from '../Timeline/HorizontalStepper';
import CustomInput from '../CustomInput/CustomInput';

const staffEditStyles = {
  selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {float: 'right'},
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

export const TransferPendingEdit = withStyles(staffEditStyles)(({transfer_detail_id, classes, app_state}) => {
  const [state, setState] = useState({
    details: null,
    navpill_active: 0,
    edit_mode: false
  });

  const fetch = async () => {
    const {transfer_pending} = await API.get('transfers_pending', `/get-full/id/${transfer_detail_id}`);
    const file_url = await Storage.get(transfer_pending.attachmentFileId, {expires: 60});
    setState({
      ...state,
      details: {
        ...transfer_pending,
        file_url
      }
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  if (!state.details) {
    return <div></div>;
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='rose' icon>
              <div className={classes.flexEnd}>
                {/* <h4 className={classes.cardIconTitle}>{getPageHeader()}</h4> */}
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.edit_mode}
                        disabled={!app_state.current_staff_super_admin}
                        onChange={() => {}}
                        value='edit_mode'
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked
                          // icon: classes.switchIcon,
                          // iconChecked: classes.switchIconChecked,
                          // bar: classes.switchBar
                        }}
                      />
                    }
                    classes={{label: classes.label}}
                    label={state.edit_mode ? 'Edit Mode' : 'Read Only'}
                  />
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <NavPills
                active={state.navpill_active}
                color='info'
                tabs={[
                  {
                    tabButton: 'Summary',
                    tabContent: (
                      <CardBody>
                        <React.Fragment>
                          <GridContainer>
                            {/* <GridItem xs={12} sm={12} md={12}>
                            <HorizontalStepper transfer_status={state.transfer_status} />
                          </GridItem> */}

                            <GridItem xs={12} sm={12} md={8}>
                              <Card>
                                <CardHeader>
                                  <h6>Financials</h6>
                                </CardHeader>
                                <CardBody>
                                  <GridContainer>
                                    <GridItem xs={6} md={6}>
                                      <label style={{fontSize: '11px'}}>Record Created</label>
                                      <FormControl fullWidth>
                                        <Datetime
                                          inputProps={{
                                            disabled: !state.edit_mode,
                                            placeholder: state.details.record_created
                                              ? moment(state.details.record_created).format('DD/MM/YYYY')
                                              : '',
                                            value: state.details.record_created
                                              ? moment(state.details.record_created).format('DD/MM/YYYY')
                                              : ''
                                          }}
                                          onChange={event => {}}
                                        />
                                      </FormControl>
                                    </GridItem>
                                    <GridItem xs={6} md={6}>
                                      <label style={{fontSize: '11px'}}>Expiry Date</label>
                                      <FormControl fullWidth>
                                        <Datetime
                                          inputProps={{
                                            disabled: !state.edit_mode,
                                            placeholder: state.details.expiryDate
                                              ? moment(state.details.expiryDate).format('DD/MM/YYYY')
                                              : '',
                                            value: state.details.expiryDate
                                              ? moment(state.details.expiryDate).format('DD/MM/YYYY')
                                              : ''
                                          }}
                                          onChange={event => {}}
                                        />
                                      </FormControl>
                                    </GridItem>

                                    <GridItem xs={6} md={6}>
                                      <CustomInput
                                        success={state.staff_idStatus}
                                        error={state.staff_idStatus === false}
                                        labelText={<span>Staff</span>}
                                        id='staff'
                                        formControlProps={{fullWidth: true}}
                                        inputProps={{
                                          disabled: !state.edit_mode,
                                          value: state.details.staff_nickname,
                                          onChange: event => {}
                                        }}
                                      />
                                    </GridItem>

                                    <GridItem xs={6} md={6}>
                                      <CustomInput
                                        success={state.staff_idStatus}
                                        error={state.staff_idStatus === false}
                                        labelText={<span>Client</span>}
                                        id='Client'
                                        formControlProps={{fullWidth: true}}
                                        inputProps={{
                                          disabled: !state.edit_mode,
                                          value: state.details.client_nickname,
                                          onChange: event => {}
                                        }}
                                      />
                                    </GridItem>

                                    <GridItem xs={6} md={6}>
                                      <CustomInput
                                        success={state.staff_idStatus}
                                        error={state.staff_idStatus === false}
                                        labelText={<span>Curreny to</span>}
                                        id='currenyto'
                                        formControlProps={{fullWidth: true}}
                                        inputProps={{
                                          disabled: !state.edit_mode,
                                          value: state.details.currency_iso_alpha_3,
                                          onChange: event => {}
                                        }}
                                      />
                                    </GridItem>

                                    <GridItem xs={6} md={6}>
                                      <label style={{fontSize: '11px'}}>Expiry Date</label>
                                      <br />
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: '4px',
                                          marginTop: '13px'
                                        }}
                                      >
                                        <span
                                          className={`currency-flag currency-flag-${state.details.currency_iso_alpha_3.toLowerCase()}`}
                                        />
                                        <span>{state.details.currency_iso_alpha_3}</span>
                                        <span>{state.details.amount_to.toFixed(2)}</span>
                                      </div>
                                    </GridItem>

                                    <GridItem xs={6} md={6}>
                                      <CustomInput
                                        success={state.staff_idStatus}
                                        error={state.staff_idStatus === false}
                                        labelText={<span>Status id</span>}
                                        id='statusid'
                                        formControlProps={{fullWidth: true}}
                                        inputProps={{
                                          disabled: !state.edit_mode,
                                          value: state.details.status,
                                          onChange: event => {}
                                        }}
                                      />
                                    </GridItem>

                                    <GridItem xs={6} md={6}>
                                      <label style={{fontSize: '11px'}}>Attachment</label> <br />
                                      <img src={state.details.file_url} alt='no' width={150} height={100} />
                                    </GridItem>
                                  </GridContainer>
                                </CardBody>
                              </Card>
                            </GridItem>
                          </GridContainer>
                        </React.Fragment>
                      </CardBody>
                    )
                  }
                ]}
              />
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
});
