import { validationMessages } from '../../../Identification/IdentificationConstants'

export const ClientWizardConstants = {
    aboutValidationRules: {
        FIRST_NAME: [
            { required: true, message: 'First Name is required'},
        ],
        LAST_NAME: [
            { required: true, message: 'Last Name is required'},
        ],
        EMAIL: [
            { type: 'email', message: 'Must be valid email'},
            { required: true, message: 'Email is required'},
        ],
        TRADING_NAME: [
            { required: true, message: 'Trading name is required'},
        ],
        COMPANY_NAME: [
            { required: true, message: 'Company name is required'},
        ],
        COMPANY_NUMBER: [
            { required: true, message: 'Company number is required'},
        ],
        ABN: [
            { required: false, message: 'ABN is required'},
        ],
        CLIENT_REFERENCE: [
            { required: false, message: 'Client Reference is required'},
        ],
        TRUST: [
            { required: true, message: 'Trust name is required'},
        ],
        RESPONSIBLE_STAFF_MEMBER: [
            { required: true, message: 'Staff member required'},
        ],
        TEAM: [
            { required: true, message: 'Select team for this client'},
        ]
    },
    clientContactValidationRules: {
       TELEPHONE:  [
           { required: false, message: 'Telephone number is required'}
       ],
        STREET_LINE_1: [
            { required: false, message: 'Street Address is required'}
        ],
        STREET_LINE_2: [
            { required: false, message: 'Street Address 2 is required'}
        ],
        SUBURB: [
            { required: false, message: 'Suburb is required'}
        ],
        STATE: [
            { required: false, message: 'State is required'}
        ],
        POSTCODE: [
            { required: false, message: 'Post code is required'}
        ],
        COUNTRY: [
            { required: false, message: 'Country is required'}
        ],
        COUNTRY_OF_NATIONALITY: [
            { required: false, message: 'Country of nationality required'}
        ],
        LANGUAGE: [
            { required: false, message: 'Language is required'}
        ],
        OCCUPATION: [
            { required: false, message: 'Occupation is required'}
        ],
        DATE_OF_BIRTH: [
            {required: false, message: 'Date of birth is required'}
        ],
        PLACE_OF_BIRTH: [
            {required: false, message: 'Place of Birth is required'}
        ],
        BIRTH_COUNTRY: [
            {required: false, message: 'Country of Birth is required'}
        ]
    },
    beneficiaryValidationRules: {
        FIRST_NAME: [
            {required: true, message: 'First Name is required'}
        ],
        LAST_NAME: [
            {required: true, message: 'Last Name is required'}
        ],
        TELEPHONE: [
            {required: false, message: 'Telephone is required'}
        ],
        EMAIL: [
            {type: 'email', message: 'Must be valid email'},
            {required: true, message: 'Email is required'}
        ],
        STREET_LINE_1: [
            {required: false, message: 'Street Line 1 is required'}
        ],
        STREET_LINE_2: [
            {required: false, message: 'Street Line 2 is required'}
        ],
        SUBURB: [
            {required: false, message: 'Suburb is required'}
        ],
        STATE: [
            {required: false, message: 'State is required'}
        ],
        POST_CODE: [
            {required: false, message: 'Post Code is required'}
        ],
        COUNTRY: [
            {required: false, message: 'Country is required'}
        ],
        NATIONALITY: [
            {required: false, message: 'Nationality is required'}
        ],
        DATE_OF_BIRTH: [
            {required: false, message: 'Date of birth is required'}
        ],
        PLACE_OF_BIRTH: [
            {required: false, message: 'Place of Birth is required'}
        ],
        COUNTRY_OF_BIRTH: [
            {required: false, message: 'Country of Birth is required'}
        ]
    }
}