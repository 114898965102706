import React, {useEffect, useState} from 'react';
import {useHistory, withRouter} from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import {connect} from 'react-redux';
import {FormattedNumber} from 'react-intl';
import moment from 'moment';
import 'react-dates/initialize';
import {fetchForwardList} from '../../redux/actions/forwards';
import {Button, Flex, message, Popconfirm, Table, Tag} from 'antd'
import ListTable from '../ListTable/ListTable'
import {ForwardsAPI} from '../../views/Dashboard/Forwards/ForwardsUtil'
import {CloseOutlined, DashboardOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

const styles = {
    currency: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 4,

        '& > span': {marginLeft: 4}
    },
    badge: {cursor: 'pointer'}
};

const Forwards = ({getColumnSearchProps, forwards, fetching, fetchForwardList, classes, status}) => {
    const [state, setState] = useState({
        startDate: '',
        endDate: ''
    });
    const history = useHistory();
    const statusOptions = [
        {text: 'Draft', value: 1},
        {text: 'Submitted', value: 2},
        {text: 'Acknowledged', value: 3},
        {text: 'Processing', value: 4}, // assuming 'green' maps to 'Acknowledged'
        // { text: 'Awaiting Funds', value: 'Awaiting Funds' },
        // { text: 'Funds Received', value: 'Funds Received' },
        {text: 'On Hold', value: 9},
        {text: 'Not Proceeding', value: 10},
        {text: 'Completed', value: 8},
    ];

    useEffect(() => {
        fetchForwardList();
    }, [status]);

    const getTagColor = (nickname) => {
        let color = 'green';
        switch (nickname) {
            case 'Draft':
                color = 'orange';
                break;
            case 'Submitted':
                color = 'cyan';
                break;
            case 'Acknowledged':
            case 'green':
                color = 'success';
                break;
            case 'Awaiting Funds':
            case 'Funds Received':
            case 'On Hold':
                color = 'red';
                break;
            case 'Payment Made':
            case 'Not Proceeding':
                color = 'geekblue';
                break;
            case 'Completed':
                color = 'blue';
                break;
            default:
                color = 'success';
        }
        return color;
    }

    const buildTableColumns = () => {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: (a, b) => a.id - b.id,
                onFilter: (value, record) => record.id === value,
                defaultSortOrder: 'asc',
                ...getColumnSearchProps({
                    dataIndex: 'id',
                    label: 'ID',
                    render: (text, record) => {
                        return <>
                            <Tag color={getTagColor(record.status_nickname)}>ID: {text}</Tag>
                        </>
                    }
                })

            },
            {
                title: 'Transaction Date',
                dataIndex: 'transaction_date',
                ...getColumnSearchProps({
                    dataIndex: 'transaction_date',
                    filterInputType: 'DATE',
                    label: 'Transaction Date',
                    render: (text, record) => {
                        return <>{text}</>
                    }
                })

            },
            {
                title: 'Settlement Date',
                dataIndex: 'settlement_date',
                ...getColumnSearchProps({
                    dataIndex: 'settlement_date',
                    filterInputType: 'DATE',
                    label: 'Settlement Date',
                    render: (text, record) => {
                        return <>{record.settlement_date}</>
                    }
                })

            },
            {
                title: 'Client Name',
                dataIndex: 'client_nickname',
                ...getColumnSearchProps({
                    dataIndex: 'client_nickname',
                    label: 'Client Name',
                    render: (text, record) => {
                        return <>
                            <p>{record.client_nickname}</p>
                        </>
                    }
                })

            },
            {
                title: 'Currency From',
                dataIndex: 'currency_from_iso_alpha_3',
                ...getColumnSearchProps({
                    dataIndex: 'client_nickname',
                    label: 'Currency From',
                    render: (text, record) => {
                        return <>
                            <div className={classes.currency}>
                                <div
                                    className={`currency-flag currency-flag-${
                                        record.currency_from_iso_alpha_3 ? record.currency_from_iso_alpha_3.toLowerCase() : ''
                                    }`}
                                />
                                <span>{record.currency_from_iso_alpha_3}</span>
                            </div>
                            <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2}
                                             value={record.amount_from}/>
                        </>
                    }
                })

            },
            {
                title: 'Currency To',
                dataIndex: 'currency_to_iso_alpha_3',
                ...getColumnSearchProps({
                    dataIndex: 'currency_to_iso_alpha_3',
                    label: 'Currency To',
                    render: (text, record) => {
                        return <>
                            <div className={classes.currency}>
                                <div
                                    className={`currency-flag currency-flag-${
                                        record.currency_to_iso_alpha_3 ? record.currency_to_iso_alpha_3.toLowerCase() : ''
                                    }`}
                                />
                                <span>{record.currency_to_iso_alpha_3}</span>
                            </div>
                            <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2}
                                             value={record.amount_to}/>
                        </>
                    }
                })

            },
            {
                title: 'Balance',
                dataIndex: 'balance',
                ...getColumnSearchProps({
                    dataIndex: 'balance',
                    label: 'Balance',
                    render: (text, record) => {
                        return <>
                            <span>{text.toFixed(2)}</span>
                        </>
                    }
                })
            },
            {
                title: 'Status Name',
                dataIndex: 'status_id',
                filters: statusOptions,
                onFilter: (value, record) => record.status_id && record.status_id === value,
                ...getColumnSearchProps({
                    dataIndex: 'status_id',
                    label: 'Status Name',
                    filterInputType: 'SELECT',
                    render: (text, record) => {
                        return <><Tag
                            color={getTagColor(record.status_nickname)}>{record.status_nickname || '-'}</Tag></>
                    }
                })

            },
            {
                title: 'Action',
                dataIndex: 'deleted',
                ...getColumnSearchProps({
                    dataIndex: 'deleted',
                    label: 'Actions',
                    render: (text, record) => {
                        return <>
                            <Flex gap='small' wrap={'wrap'}>
                                <Button type={'link'} onClick={() => history.push(`/forwards/detail/${record.id}`)}>
                                    <DashboardOutlined/>
                                </Button>
                                <Popconfirm
                                    onConfirm={() => removeForward(record.id)}
                                    title="Delete the forward ?"
                                    description="Are you sure to delete this forward?">
                                    <Button
                                        type={'link'}
                                        size={'small'}
                                        icon={<CloseOutlined/>}
                                        danger={true}
                                        className='remove'>
                                    </Button>
                                </Popconfirm>

                            </Flex>
                        </>
                    }
                })

            },
        ];
        if (status != null && status.length) {
            const sts = [...status];
            columns.forEach(item => {
                if (item.dataIndex === 'status_id') {
                    item.defaultFilteredValue = sts;
                }
            })
        }
        return columns;
    }

    const removeForward = (forwardId) => {
        ForwardsAPI.removeForward(forwardId).then(() => {
            message.success("Forward has been removed");
            fetchForwardList();
        }).catch(() => {
            message.error("Failed to delete the forward. Please try again");
        })
    }

    const filterItems = () => {
        if (state.startDate && state.endDate) {
            return forwards.filter(
                forward =>
                    state.startDate.isBefore(moment(forward.settlement_date).add(1, 'day')) &&
                    state.endDate.isAfter(moment(forward.settlement_date))
            );
        }
        return forwards.sort((a, b) => b.id - a.id);
    };

    return (
        <React.Fragment>
            <Table
                dataSource={filterItems()}
                loading={fetching}
                columns={buildTableColumns()}
                className='-highlight'
            />
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    app_state: state.app_state,
    forwards: state.forwards.forwardList,
    fetching: state.forwards.fetching
});

const mapDispatchToProps = dispatch => ({fetchForwardList: () => dispatch(fetchForwardList())});

const ForwardsComponent = connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Forwards)));

export default ListTable(ForwardsComponent);
