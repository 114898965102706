import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {FormattedMessage} from 'react-intl';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import ClientContacts from '../../../features/Clients/ClientContacts';
import {API} from 'aws-amplify';
import Button from '../../../components/CustomButtons/Button.jsx';
import NewClientContactsDialog from '../../../features/Clients/NewClientContactsDialog';

const clientContactsStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const ClientContactsContainer = props => {
  const {classes, history} = props;

  const [loading, setLoading] = useState(false);
  const [clientContactsList, setClientContactsList] = useState([]);

  const [newClientContactsDialogOpen, setNewClientContactsDialogOpen] = useState(false);

  const openNewClientContactsDialog = () => {
    setNewClientContactsDialogOpen(true);
  };

  const fetchClientContactsList = () => {
    if (!loading) {
      setLoading(true);
    }

    API.get('clientcontacts', '/list')
      .then(response => {
        // console.log(response);
        setClientContactsList(response);
      })
      .catch(error => {
        console.log(error);
        API.get('clientcontacts', '/list')
          .then(response => {
            // console.log(response);
            setClientContactsList(response);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchClientContactsList();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <PermIdentity />
          </CardIcon>
          <div className={classes.flexEnd}>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage
                id='clientContacts.cardItem1.clientContactsList'
                defaultMessage={'Client Contacts List'}
              />
            </h4>
            <Button round color='info' onClick={openNewClientContactsDialog}>
              <FormattedMessage id='clientContactsList.cardItem1.createNew' defaultMessage={'Create New'} />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <ClientContacts
                client_contacts_list={clientContactsList}
                history={history}
                loading={loading}
                setLoading={setLoading}
                fetchClientContactsList={fetchClientContactsList}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <NewClientContactsDialog
        open={newClientContactsDialogOpen}
        handleClose={() => setNewClientContactsDialogOpen(false)}
      />
    </Fragment>
  );
};

ClientContactsContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(clientContactsStyles)(ClientContactsContainer);
