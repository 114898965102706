import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import {FormattedMessage} from 'react-intl';
import {TextareaAutosize} from '@material-ui/core';
import Button from 'components/CustomButtons/Button.jsx';
import {message} from 'antd';
import {Form, Input} from 'antd';
import moment from 'moment';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import MBL_GetExRate from './MBL_GetExRate.jsx';

import NavPills from 'components/NavPills/NavPills.jsx';
import MBL_SendTransaction from './MBL_SendTransaction';

import queryString from 'query-string';


const styles = themes => ({
  customFormControlClasses: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    marginTop: '0px !important',
    marginBottom: '0px !important'
  },
  backgroundColor: {}
});


class MBLTransmissionPageComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      navpill_active: 1
    };
  }

  componentDidMount() {
    this.getActive();
  }

  getActive = () => {
    const query_strings = queryString.parse(this.props.location.search);
    this.setState({query: query_strings})
  };

  render() {


    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <NavPills
              active={this.state.navpill_active}
              color='info'
              tabs={[
                {
                  tabButton: 'Get Exchange Rate',
                  tabContent: <MBL_GetExRate />
                },
                {
                  tabButton: 'Send Transaction',
                  tabContent: <MBL_SendTransaction query={this.state.query} />
                }
              ]}
            />
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
};

export default withStyles(styles)(MBLTransmissionPageComponent);
