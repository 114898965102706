import React from 'react';
import {API} from 'aws-amplify';
import {connect} from 'react-redux';
// import Step2PayingTo from "./Step2PayingTo";
import {updateNewTransferCreation} from 'redux/actions/transfers_2';

import {createNewBeneficiary, updateNewBeneficiaryCreation} from 'redux/actions/beneficiaries';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import CustomTextField from 'components/CustomInput/CustomTextField.jsx';
import CloseIcon from '@material-ui/icons/Close';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Button from 'components/CustomButtons/Button.jsx';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import {Divider, IconButton} from '@material-ui/core';
import axios from 'axios';
import {Col, Form, Input, Modal, Row, Select} from 'antd';
import FileUploadNew from '../../FileUpload/FileUploadNew';
import {NewTransferAPI, NewTransferUtil} from './NewTransferConstants';
import {AppTemplates} from "../../../AppTemplates";

const style = {
  ...customCheckboxRadioSwitch,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {color: '#555'},
  inputAdornment: {position: 'relative'}
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

class Step3PayingTo extends React.Component {

  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      beneficiaries_list: [],
      currencies_list_priority: [],
      beneficiary: {},
      show_iban: false,
      show_bsb: false,
      show_sort_code: false,
      show_swift_code: false,
      show_aba: false,
      show_account_number: false,
      purpose_of_payment_detail: '',
      purpose_of_payment_detail_state: '',
      amount_to_state: '',
      purpose_list: [],
      checked_beneficiary_details: false,
      clientTransfers: [
        {
          beneficiary_id: '',
          amount_to: '',
          purpose_of_payment_detail: '',
          beneficiary_id_state: '',
          amount_to_state: '',
          purpose_of_payment_detail_state: '',
          detail_1: '',
          detail_2: '',
          detail_3: '',
          detail_1_state: '',
          detail_2_state: '',
          detail_3_state: '',
          document: '',
          purpose_of_payment_other: '',
          purpose_of_payment_other_state: ''
        }
      ],
      FIXME: false,
      country_list: [],
      currencies_list: [],
      addBeneficiaryModalOpen: false,
      ben_legal_name: '',
      ben_email_main: '',
      ben_address_line_1: '',
      ben_address_line_2: '',
      ben_address_suburb: '',
      ben_address_state: '',
      ben_address_postcode: '',
      ben_address_country: undefined,
      ben_telephone_work: '',
      bank_legal_name: '',
      account_currency: '',
      bsb_code: '',
      swift_code: '',
      account_number: '',
      transit_code: '',
      institution_number: '',
      aba_routing_number: '',
      sort_code: '',
      uploads: []
    };
    this.formRef = new React.createRef();
  }

  restoreState = () => {
    this.getDocumentsFromState();
    // this.props.updateNewTransferCreation('multiple_beneficiary_id', [{document: []}]);
  }

  getDocumentsFromState() {
    if (this.props.transfers_2 != null
        && this.props.transfers_2.new_transfer_data != null
        && this.props.transfers_2.new_transfer_data.multiple_beneficiary_id != null
        && this.props.transfers_2.new_transfer_data.multiple_beneficiary_id.length > 0
        && this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].document != null
        && this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].document.length
    ) {
      const uploads = [...this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].document];
      this.setState({uploads: uploads});
      this.uploadRef.current.restore(uploads);
    } else {
      this.setState({uploads: []});
    }
  }

  componentDidMount() {
    if (this.props.transfers_2.new_transfer_data.client_id) {
      this.restoreState();
      NewTransferAPI.fetchBeneficiaryByClientId(this.props.transfers_2.new_transfer_data.client_id).then(response => {
        const filteredBeneficiaryList = NewTransferUtil.getFilteredBeneficiaryList(response, this.props.transfers_2.new_transfer_data.currency_to_id);
        this.setState({beneficiaries_list: filteredBeneficiaryList});
      }).catch(error => console.log(error));
    }

    API.get('currencies', '/list_not_deleted').then(response => {
      this.setState({currencies_list_priority: response}, () => {});
    });

    API.get('countries', '/list_not_deleted')
      .then(response => {
        const countryList = response.sort((a, b) => {
          if (a.full_name < b.full_name) {
            return -1;
          } else if (a.full_name > b.full_name) {
            return 1;
          }
          return 0;
        });
        this.setState({country_list: countryList});
      })
      .catch(error => {
        console.log(error);
      });

    API.get('system', '/currencies/get-all')
      .then(response => {
        this.setState({currencies_list: response});
      })
      .catch(error => {
        console.log(error);
      });

    NewTransferAPI.fetchTeamPurpose(this.props.transfers_2.new_transfer_data.team_id)
      .then(response => this.setState({purpose_list: response}))
      .catch(error => console.log(error));

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.transfers_2.new_transfer_data.client_id !== this.props.transfers_2.new_transfer_data.client_id) {
      NewTransferAPI.fetchBeneficiaryByClientId(this.props.transfers_2.new_transfer_data.client_id).then(response => {
        const filteredBeneficiaryList = NewTransferUtil.getFilteredBeneficiaryList(response, this.props.transfers_2.new_transfer_data.currency_to_id);
        this.setState({beneficiaries_list: filteredBeneficiaryList});
      }).catch(error => console.log(error));
    }

    if (
      prevProps.transfers_2.new_transfer_data.currency_to_id !== this.props.transfers_2.new_transfer_data.currency_to_id
    ) {
      NewTransferAPI.fetchBeneficiaryByClientId(this.props.transfers_2.new_transfer_data.client_id, this.props.transfers_2.new_transfer_data.currency_to_id).then(response => {
        const filteredBeneficiaryList = NewTransferUtil.getFilteredBeneficiaryList(response, this.props.transfers_2.new_transfer_data.currency_to_id);
        this.setState({
          account_currency: this.props.transfers_2.new_transfer_data.currency_to_id,
          beneficiaries_list: filteredBeneficiaryList
        });
      }).catch(error => console.log(error));
    }

    if (prevProps.transfers_2.new_transfer_data.team_id !== this.props.transfers_2.new_transfer_data.team_id) {
      NewTransferAPI.fetchTeamPurpose(this.props.transfers_2.new_transfer_data.team_id)
        .then(response => this.setState({purpose_list: response}))
        .catch(error => console.log(error));
    }

    if (prevProps.transfers_2.new_transfer_data.ledgerTo !== this.props.transfers_2.new_transfer_data.ledgerTo) {
      this.setState({ledgerTo: this.props.transfers_2.new_transfer_data.ledgerTo});
    }
  }

  getBeneficiaryDetails(beneficiary_id) {
    API.get('beneficiaries', `/get/id/${beneficiary_id}`)
      .then(response => {
        this.setState({beneficiary: response});
        // this.props.updateNewTransferCreation('beneficiary', response);

        this.setState({
          beneficiary: {
            ...this.state.beneficiary,
            account_currency_nickname: `${response.full_name} [${response.iso_alpha_3}]`
          }
        });

        return response;
      })
      .then(response => {
        API.get('system', `/currencies/get/${response.account_currency}`)
          .then(response => {
            this.setState({
              beneficiary: {
                ...this.state.beneficiary,
                account_currency_nickname: `${response.full_name} [${response.iso_alpha_3}]`
              }
            });

            API.post('email', '/interaction/main', {
              body: {
                interaction: 'New Transfer Request Step 2 PayingTo',
                data: {
                  client_id: this.props.transfers_2.new_transfer_data.client_id,
                  client_nickname: this.props.transfers_2.new_transfer_data.nickname,
                  beneficiary_id: this.state.beneficiary.id,
                  beneficiary_nickname: this.state.beneficiary.nickname
                },
                result: 'New transfer request proceeding',
                // sms: `Admin Portal New Transfer Step 2: [${this.props.transfers_2.new_transfer_data.client_id}] Beneficiary: ${this.state.beneficiary.id} - ${this.state.beneficiary.nickname}`
              }
            });
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  }

  // clearBeneficiaryDetails() {
  //   this.setState({beneficiary: {}});
  //   this.setState({beneficiary_id: ''});
  //   this.props.updateNewTransferCreation('beneficiary', {});

  //   const clientTransfers = [...this.state.clientTransfers];
  //   clientTransfers[0] = {
  //     ...clientTransfers[0],
  //     beneficiary_id: ''
  //   };
  //   this.setState({clientTransfers});
  //   this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
  // }

  fetchNewRate = () => {
    if (
      this.props.transfers_2.new_transfer_data.currency_to &&
      this.props.transfers_2.new_transfer_data.currency_from
    ) {
      const url = 'https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
      axios
        .get(url, {
          params: {
            currencies: this.props.transfers_2.new_transfer_data.currency_from.iso_alpha3,
            source: this.props.transfers_2.new_transfer_data.currency_to.iso_alpha3,
            format: '1'
          }
        })
        .then(response => {
          const key = `${this.props.transfers_2.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}${this.props.transfers_2.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}`;
          const rate_string = response.data.quotes[key];

          const amount_to = Number.parseFloat(this.props.transfers_2.new_transfer_data.amount_to);
          const rate = Number.parseFloat(rate_string);
          const rate_adjust =
            Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_division) +
            Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_entity) +
            Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_team) +
            Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_staff);

          const adjusted_rate = rate + rate * rate_adjust;

          const amount_from = amount_to * adjusted_rate;

          this.props.updateNewTransferCreation('current_rate', adjusted_rate.toFixed(5));
          this.props.updateNewTransferCreation('amount_from', amount_from.toFixed(5));
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  sendState() {
    return this.state;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }

  handleCustomReactSelectChange = name => value => {
    let option_value;
    if (value === null) {
      option_value = null;
    } else {
      option_value = value.value;
    }
    this.setState({[name]: option_value});

    if (name === 'beneficiary_id') {
      const beneficiary_array = this.props.transfers_2.new_transfer_data.multiple_beneficiary_id || [{}];

      API.get('beneficiaries', `/get/id/${option_value}`)
        .then(beneficiaries_detail => {
          console.log(beneficiaries_detail);
          beneficiary_array[0].beneficiary = beneficiaries_detail;
          beneficiary_array[0].beneficiary_id = option_value;
          beneficiary_array[0].amount = parseFloat(this.props.transfers_2.new_transfer_data.amount_to).toFixed(2);
          this.props.updateNewTransferCreation('multiple_beneficiary_id', beneficiary_array);

          const clientTransfers = [...this.state.clientTransfers];
          clientTransfers[0] = {
            ...clientTransfers[0],
            beneficiary: beneficiaries_detail
          };
          this.setState({clientTransfers});
          this.getBeneficiaryDetails(option_value);
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (name === 'purpose_of_payment_detail') {
      const beneficiary_array = this.props.transfers_2.new_transfer_data.multiple_beneficiary_id || [{}];
      beneficiary_array[0].purpose_of_payment_detail = option_value;
      beneficiary_array[0].purpose_of_payment_detail_text = value.label;
      this.props.updateNewTransferCreation('multiple_beneficiary_id', beneficiary_array);

      const i = 0;
      const clientTransfers = [...this.state.clientTransfers];
      clientTransfers[i] = {
        ...clientTransfers[i],
        [name]: option_value
      };
      this.setState({clientTransfers});
    }

    this.props.updateNewTransferCreation(name, option_value);
  };

  handleChange(e, i, stateName) {
    const {name, value} = e.target;
    const beneficiary_array = this.props.transfers_2.new_transfer_data.multiple_beneficiary_id || [{}];
    beneficiary_array[0] = {
      ...beneficiary_array[0],
      [stateName]: value
    };
    this.props.updateNewTransferCreation('multiple_beneficiary_id', beneficiary_array);

    const clientTransfers = [...this.state.clientTransfers];
    clientTransfers[i] = {
      ...clientTransfers[i],
      [stateName]: value
    };
    this.setState({clientTransfers});

    if (stateName === 'purpose_of_payment_detail') {
      this.setState({FIXME: value == 1});
    }
  }

  createBeneficiaryIntoDatabase = async data => {
    await this.props.createNewBeneficiary(data);
    const th = this;
    setTimeout(async () => {
      await API.get('beneficiaries', `/get/by_client_id/${th.props.transfers_2.new_transfer_data.client_id}`).then(
        response => {
          const filterer_beneficiaries_list = response;
          if (filterer_beneficiaries_list.length > 0) {
            for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
              if (
                filterer_beneficiaries_list[i].account_currency !==
                this.props.transfers_2.new_transfer_data.currency_to_id
              ) {
                filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
              }
            }
          }

          this.setState({beneficiaries_list: filterer_beneficiaries_list});
        }
      );
    }, 2000);

    this.setState({
      ben_legal_name: '',
      ben_email_main: '',
      ben_address_line_1: '',
      ben_address_line_2: '',
      ben_address_suburb: '',
      ben_address_state: '',
      ben_address_postcode: '',
      ben_address_country: '',
      ben_telephone_work: '',

      bank_legal_name: '',
      account_currency: '',
      bsb_code: '',
      swift_code: '',
      account_number: '',
      iban: '',
      aba_routing_number: '',
      sort_code: ''
    });

    return;
  };
  getInstructions = () => {
    return (
      <React.Fragment>
        <GridContainer>
          {this.state.bank_country_full_name && (
            <GridItem xs={12} sm={12}>
              <p>
                When transfering to &nbsp;
                <img
                  src={
                    this.state.bank_country_iso_alpha_2 !== ''
                      ? require(`assets/img/flags/${this.state.bank_country_iso_alpha_2}.png`)
                      : ''
                  }
                />{' '}
                &nbsp;
                <strong>{this.state.bank_country_full_name}</strong>, we require
              </p>
              <ul>
                <li>Bank name</li>
                {this.state.show_iban && <li>IBAN</li>}
                {this.state.show_swift_code && <li>SWIFT Code</li>}
                {this.state.show_aba && <li>ABA Routing Number</li>}
                {this.state.show_sort_code && <li>Sort Code</li>}
                {this.state.show_bsb && <li>BSB</li>}
                {this.state.show_transit_code && <li>Transit Code</li>}
                {this.state.show_institution_number && <li>Institution Number</li>}
                {this.state.show_bank_code && <li>Bank Code</li>}
                {this.state.show_account_number && <li>Account Number</li>}
              </ul>
            </GridItem>
          )}
        </GridContainer>
      </React.Fragment>
    );
  };
  finished = async () => {
    this.formRef.current.resetFields();
      const {
        ben_legal_name,
        ben_email_main,
        ben_address_line_1,
        ben_address_line_2,
        ben_address_suburb,
        ben_address_state,
        ben_address_postcode,
        ben_address_country,
        ben_telephone_work,
        bank_legal_name,
        bank_address_line_1,
        bank_address_suburb,
        bank_address_state,
        bank_address_postcode,
        bank_country,
        account_currency,
        bsb_code,
        swift_code,
        account_number,
        iban,
        institution_number,
        bank_code,
        transit_code,
        aba_routing_number,
        sort_code
      } = this.state;

      try {
        await this.createBeneficiaryIntoDatabase({
          client_id: this.props.transfers_2.new_transfer_data.client_id,
          nickname: ben_legal_name,
          ben_legal_name: ben_legal_name,
          ben_email_main: ben_email_main,
          ben_address_line_1: ben_address_line_1,
          ben_address_line_2: ben_address_line_2,
          ben_address_suburb: ben_address_suburb,
          ben_address_state: ben_address_state,
          ben_address_postcode: ben_address_postcode,
          ben_address_country: bank_country,
          ben_telephone_work: ben_telephone_work,
          bank_legal_name: bank_legal_name,
          bank_address_line_1: bank_address_line_1,
          bank_address_suburb: bank_address_suburb,
          bank_address_state: bank_address_state,
          bank_address_postcode: bank_address_postcode,
          bank_address_country: bank_country,
          account_currency: account_currency,
          bsb_code: bsb_code,
          swift_code: swift_code,
          account_number: account_number,
          iban: iban,
          aba_routing_number: aba_routing_number,
          sort_code: sort_code,
          bank_code: bank_code,
          institution_number: institution_number,
          transit_code: transit_code
        });
        this.setState({addBeneficiaryModalOpen: false});
      } catch (e) {
        this.setState({isLoading: false});
      }
  };

  addBeneficiaryModelOpen = () => {
    this.setState({addBeneficiaryModalOpen: true}, () => {
      this.formRef.current.setFieldsValue({ account_currency: this.props.transfers_2.new_transfer_data.currency_to_id });
    });
  };

  addBeneficiaryModelClose = () => {
    this.formRef.current.resetFields();
    this.setState({
      addBeneficiaryModalOpen: false,
      ben_legal_name: '',
      ben_email_main: '',
      ben_address_line_1: '',
      ben_address_line_2: '',
      ben_address_suburb: '',
      ben_address_state: '',
      ben_address_postcode: '',
      ben_address_country: '',
      ben_telephone_work: '',
      bank_country: '',
      bank_legal_name: '',
      bank_address_line_1: '',
      bank_address_suburb: '',
      bank_address_state: '',
      bank_address_postcode: '',
      bsb_code: '',
      swift_code: '',
      account_number: '',
      aba_routing_number: '',
      sort_code: '',
      bank_code: '',
      institution_number: '',
      transit_code: '',
    });
  };

  uploadRef = React.createRef();

  onUploadImage = (data) => {
    const uploads = [...this.state.uploads];
    uploads.push({
      uid: data.file.uid,
      name: data.file.name,
      status: 'done',
      url: data.url,
      file_id: data.file_id
    });
    this.setState({uploads: uploads});
    const beneficiary_array = [...this.props.transfers_2.new_transfer_data.multiple_beneficiary_id || [{document: []}]];
    beneficiary_array[0].document = uploads;
    this.props.updateNewTransferCreation('multiple_beneficiary_id', beneficiary_array);
  }

  onRemoveImage = (data) => {
    const beneficiary = this.props.transfers_2.new_transfer_data.multiple_beneficiary_id;
    const uploads = this.state.uploads;
    const items = uploads.filter(item => item.uid !== data.uid);
    beneficiary[0].document = items;
    this.props.updateNewTransferCreation('multiple_beneficiary_id', beneficiary);
    this.setState({uploads: items.length > 0 ? items : []});
    this.uploadRef.current.restore(items.length > 0 ? items : []);
  }

  setAddNewBeneficiaryState = (key, value) => {
    this.setState({[key]: value});

    if (key === 'bank_country') {
      const country_dataset = this.state.country_list;
      for (let i = 0; i < country_dataset.length; i++) {
        if (country_dataset[i].id === value) {
          this.setState({
            show_swift_code: true,
            show_account_number: true
          });

          //iban check
          if (country_dataset[i].use_iban === 1) {
            this.setState({show_iban: true});
          } else {
            this.setState({show_iban: false});
          }

          //bsb check
          if (country_dataset[i].use_bsb === 1) {
            this.setState({show_bsb: true});
          } else {
            this.setState({show_bsb: false});
          }

          //aba_routing check
          if (country_dataset[i].use_aba === 1) {
            this.setState({show_aba: true});
          } else {
            this.setState({show_aba: false});
          }

          //sort_code check
          if (country_dataset[i].use_sort_code === 1) {
            this.setState({show_sort_code: true});
          } else {
            this.setState({show_sort_code: false});
          }
          //account_number check
          if (country_dataset[i].use_account_number === 1) {
            this.setState({show_account_number: true});
          } else {
            this.setState({show_account_number: false});
          }

          // transit_code
          if (country_dataset[i].use_transit_code === 1) {
            this.setState({show_transit_code: true});
          } else {
            this.setState({show_transit_code: false});
          }

          // institution_number
          if (country_dataset[i].use_institution_number === 1) {
            this.setState({show_institution_number: true});
          } else {
            this.setState({show_institution_number: false});
          }

          // bank_code
          if (country_dataset[i].use_bank_code === 1) {
            this.setState({show_bank_code: true});
          } else {
            this.setState({show_bank_code: false});
          }
        }
      }
    }
  }

  createNewBeneficiaryUi = () => {
    const {classes} = this.props;

    const sortedCountries = this.state.country_list.sort((a, b) => {
      if (a.full_name < b.full_name) {
        return -1;
      }
      if (a.full_name > b.full_name) {
        return 1;
      }
      return 0;
    })
    const country_list_select_options = AppTemplates.countries(sortedCountries);

    const sortedCurrencies = this.state.currencies_list_priority.sort((a, b) => {
      if (a.iso_alpha_3 < b.iso_alpha_3) {
        return -1;
      }
      if (a.iso_alpha_3 > b.iso_alpha_3) {
        return 1;
      }
      return 0;
    })
    const select_currency_select_options = sortedCurrencies.map(item => ({
      value: item.id,
      alt: item.iso_alpha_3 + ': ' + item.full_name,
      label: <React.Fragment>
        <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
        &nbsp;
        {item.iso_alpha_3 + ': ' + item.full_name}
      </React.Fragment>
    }));

    return (
      <React.Fragment>
        <Modal
          title={'Add New Beneficiary'}
          width={1000}
          open={this.state.addBeneficiaryModalOpen}
          transition={Transition}
          onOk={() => this.formRef.current.submit()}
          onCancel={() => this.addBeneficiaryModelClose()}>
            <Form labelCol={{span: 10}} ref={this.formRef} layout={'horizontal'} onFinish={this.finished}>
              <GridContainer justify='flex-start'>
                <GridItem xs={12} md={6}>
                  <Form.Item label={'Select ACCOUNT Currency'} name="account_currency" rules={[{required: true, message: 'Select ACCOUNT Currency'}]}>
                    <Select showSearch
                            style={{width: '100%'}}
                            onChange={(e) =>  this.setAddNewBeneficiaryState('account_currency', e)}
                            placeholder="-- SELECT CURRENCY --"
                            optionFilterProp="alt"
                            filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) =>
                                (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                            }
                            options={select_currency_select_options}></Select>
                  </Form.Item>
                  <Form.Item label={'Select BANK Country'} name="bank_country" rules={[{required: true, message: 'Select BANK Country'}]}>
                    <Select showSearch={true}
                            style={{width: '100%'}}
                            placeholder="-- SELECT COUNTRY --"
                            onChange={(e) =>  {
                              this.setAddNewBeneficiaryState('bank_country', e)
                            }}
                            optionFilterProp="alt"
                            filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) =>
                                (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                            }
                            options={country_list_select_options}></Select>
                  </Form.Item>
                </GridItem>
                <hr/>
                <GridItem xs={12} md={6}>
                  {this.getInstructions()}
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer justify='flex-start' >
                <GridItem xs={6}>
                  <p className={classes.benficiaryTitle}>Beneficiary details</p>
                  <Divider className={classes.beneficiaryDivider} style={{ marginBottom: '10px' }} />
                  <GridContainer>
                    <GridItem xs={12} md={12}>
                      <Form.Item label={'Beneficiary (Account) Name'} name="ben_legal_name" rules={[{required: true, message: 'Field is required'}]}>
                          <Input onChange={(e) =>  this.setAddNewBeneficiaryState('ben_legal_name', e.target.value)} />
                      </Form.Item>
                      <Form.Item label={'Address Line 1'} name="ben_address_line_1" rules={[{required: true, message: 'Field is required'}]}>
                        <Input onChange={(e) =>  this.setAddNewBeneficiaryState('ben_address_line_1', e.target.value)} />
                      </Form.Item>
                      <Form.Item label={'Suburb / Region'} name="ben_address_suburb" rules={[{required: false, message: 'Field is required'}]}>
                        <Input onChange={(e) =>  this.setAddNewBeneficiaryState('ben_address_suburb', e.target.value)} />
                      </Form.Item>
                      <Form.Item label={'State'} name="ben_address_state" rules={[{required: false, message: 'Field is required'}]}>
                        <Input onChange={(e) =>  this.setAddNewBeneficiaryState('ben_address_state', e.target.value)} />
                      </Form.Item>
                      <Form.Item label={'Post Code'} name="ben_address_postcode" rules={[{required: false, message: 'Field is required'}]}>
                        <Input onChange={(e) =>  this.setAddNewBeneficiaryState('ben_address_postcode', e.target.value)} />
                      </Form.Item>
                      <Form.Item label={'Beneficiary Email [optional]'} name="ben_email_main">
                        <Input onChange={(e) =>  this.setAddNewBeneficiaryState('ben_email_main', e.target.value)} />
                      </Form.Item>
                      <Form.Item label={'Telephone [required for NPR]'} name="ben_telephone_work">
                        <Input onChange={(e) =>  this.setAddNewBeneficiaryState('ben_telephone_work', e.target.value)} />
                      </Form.Item>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={6}>
                  <p className={classes.benficiaryTitle}>Payment Details</p>
                  <Divider className={classes.beneficiaryDivider} style={{ marginBottom: '10px' }} />
                  <GridContainer justify='flex-start'>
                    <GridItem xs={12}>
                      <Form.Item labelCol={{span: 6}} label={'Bank Name'} name="bank_legal_name" rules={[{required: true, message: 'Field is required'}]}>
                        <Input onChange={(e) =>  this.setAddNewBeneficiaryState('bank_legal_name', e.target.value)} />
                      </Form.Item>
                      <Form.Item label={'Address Line 1'} name="bank_address_line_1" rules={[{required: true, message: 'Field is required'}]}>
                        <Input onChange={(e) =>  this.setAddNewBeneficiaryState('bank_address_line_1', e.target.value)} />
                      </Form.Item>
                      <Form.Item label={'Suburb / Region'} name="bank_address_suburb" rules={[{required: false, message: 'Field is required'}]}>
                        <Input onChange={(e) =>  this.setAddNewBeneficiaryState('bank_address_suburb', e.target.value)} />
                      </Form.Item>
                      <Form.Item label={'State'} name="bank_address_state" rules={[{required: false, message: 'Field is required'}]}>
                        <Input onChange={(e) =>  this.setAddNewBeneficiaryState('bank_address_state', e.target.value)} />
                      </Form.Item>
                      <Form.Item label={'Post Code'} name="bank_address_postcode" rules={[{required: false, message: 'Field is required'}]}>
                        <Input onChange={(e) =>  this.setAddNewBeneficiaryState('bank_address_postcode', e.target.value)} />
                      </Form.Item>
                      {this.state.show_iban && (
                          <Form.Item labelCol={{span: 6}} label={'IBAN'} name="iban" rules={[{required: false, message: 'Field is required'}]}>
                            <Input onChange={(e) =>  this.setAddNewBeneficiaryState('iban', e.target.value)} />
                          </Form.Item>
                      )}
                      {this.state.show_swift_code && (
                          <Form.Item labelCol={{span: 6}} label={'Swift Code'} name="swift_code" rules={[{required: false, message: 'Field is required'}]}>
                            <Input onChange={(e) =>  this.setAddNewBeneficiaryState('swift_code', e.target.value)} />
                          </Form.Item>
                      )}
                      {this.state.show_aba && (
                          <Form.Item labelCol={{span: 6}} label={'ABA Routing Number'} name="aba_routing_number" rules={[{required: false, message: 'Field is required'}]}>
                            <Input onChange={(e) =>  this.setAddNewBeneficiaryState('aba_routing_number', e.target.value)} />
                          </Form.Item>
                      )}

                      {this.state.show_sort_code && (
                          <Form.Item labelCol={{span: 6}} label={'Sort Code'} name="sort_code" rules={[{required: false, message: 'Field is required'}]}>
                            <Input onChange={(e) =>  this.setAddNewBeneficiaryState('sort_code', e.target.value)} />
                          </Form.Item>
                      )}
                      {this.state.show_bsb && (
                          <Form.Item labelCol={{span: 6}} label={'BSB'} name="bsb_code" rules={[{required: true, message: 'Field is required'}]}>
                            <Input onChange={(e) =>  this.setAddNewBeneficiaryState('bsb_code', e.target.value)} />
                          </Form.Item>
                      )}
                      {this.state.show_transit_code && (
                          <Form.Item labelCol={{span: 6}} label={'Transit Code'} name="transit_code" rules={[{required: false, message: 'Field is required'}]}>
                            <Input onChange={(e) =>  this.setAddNewBeneficiaryState('transit_code', e.target.value)} />
                          </Form.Item>
                      )}
                      {this.state.show_institution_number && (
                          <Form.Item labelCol={{span: 6}} label={'Institution No'} name="institution_number" rules={[{required: false, message: 'Field is required'}]}>
                            <Input onChange={(e) =>  this.setAddNewBeneficiaryState('institution_number', e.target.value)} />
                          </Form.Item>
                      )}
                      {this.state.show_bank_code && (
                          <Form.Item labelCol={{span: 6}} label={'Bank Code'} name="bank_code" rules={[{required: false, message: 'Field is required'}]}>
                            <Input onChange={(e) =>  this.setAddNewBeneficiaryState('bank_code', e.target.value)} />
                          </Form.Item>
                      )}

                      {this.state.show_account_number && (
                          <Form.Item labelCol={{span: 7}} label={'Account Number'} name="account_number" rules={[{required: true, message: 'Field is required'}]}>
                            <Input onChange={(e) =>  this.setAddNewBeneficiaryState('account_number', e.target.value)} />
                          </Form.Item>
                      )}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </Form>
        </Modal>
      </React.Fragment>
    );
  };

  onSingleUploadRemove = (file_id) => {
    console.log(file_id);
  }

  createUI() {
    const {classes} = this.props;
    // console.log(this.state.beneficiaries_list)
    const beneficiary_list_select_options = this.state.beneficiaries_list
      .filter(item => item.active)
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return {
          value: item.id,
          // label: item.nickname
          label: `[${item.account_currency_iso_alpha_3}] ${item.nickname} ${item.client_id ? '' : ' [Team]'} ${item.holdingAccountId ? '[*]' : ''}`
        };
      });

    const purpose_of_payment_select_options = this.state.purpose_list.map(item => ({
      value: item.id,
      label: item.description
    }));
    // console.log(purpose_of_payment_select_options)

    let show_other = false;
    if (this.props.transfers_2.new_transfer_data.multiple_beneficiary_id) {
      for (let i = 0; i < purpose_of_payment_select_options.length; i++) {
        if (
          purpose_of_payment_select_options[i].value ===
          this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].purpose_of_payment_detail
        ) {
          // console.log(purpose_of_payment_select_options[i])
          show_other = purpose_of_payment_select_options[i].label === 'Other';
        }
      }
    }

    return this.state.clientTransfers.map((el, i) => (
      <div key={i}>
        <GridContainer justify='center'>
          <GridItem xs={24} sm={12}>
            {this.state.checked_beneficiary_details !== true && (
              <React.Fragment>
                {!(this.props.transfers_2.new_transfer_data.ledgerTo || this.state.ledgerTo) && (
                  <CustomReactSelect
                    error={el.beneficiary_id_state === 'error'}
                    label='Select beneficiary'
                    options={beneficiary_list_select_options}
                    value={
                      this.props.transfers_2.new_transfer_data.multiple_beneficiary_id
                        ? this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary_id
                        : 0
                    }
                    onChange={this.handleCustomReactSelectChange('beneficiary_id')}
                    isClearable={false}
                    // isDisabled={this.state.prefill_beneficiary}
                  />
                )}
              </React.Fragment>
            )}

            <Checkbox
              id={Math.random()}
              tabIndex={-2}
              disabled={false}
              onClick={e => this.updateLedgerAmount('ledgerTo', e.target.checked)}
              checked={Boolean(this.props.transfers_2.new_transfer_data.ledgerTo)}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
            <span
              style={{
                textTransform: 'capitalize',
                fontSize: '10px'
              }}
            >
              tick box to transfer to your Holding Account.
            </span>

            <br />
            <br />
            <Button size='sm' color='info' key='addKey' onClick={() => this.addBeneficiaryModelOpen()}>
              Create new beneficiary
            </Button>

            {/* {(!!this.state.beneficiary_id) && ( */}
            {Boolean(this.state.beneficiary.id) && (
              <React.Fragment>
                <h6>Selected beneficiary details:</h6>
                <p>
                  <strong>{this.state.beneficiary.ben_legal_name}</strong>
                  <br />
                  [Amount Check: {this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].amount}{' '}
                    {this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary.currency_full_name}]
                  <br/>
                  {this.state.beneficiary.ben_address_line_1 ? (
                    <React.Fragment>
                      {this.state.beneficiary.ben_address_line_1}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {this.state.beneficiary.ben_address_line_2 ? (
                    <React.Fragment>
                      {this.state.beneficiary.ben_address_line_2}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {this.state.beneficiary.ben_address_suburb ? (
                    <React.Fragment>
                      {this.state.beneficiary.ben_address_suburb} {this.state.beneficiary.ben_address_state}{' '}
                      {this.state.beneficiary.ben_address_postcode}
                      <br />
                    </React.Fragment>
                  ) : null}
                </p>
                <p>
                  <strong>Account details:</strong>
                  <br />
                  {this.state.beneficiary.account_currency_nickname} <br />
                  {this.state.beneficiary.bank_legal_name ? <span>Bank: {this.state.beneficiary.bank_legal_name}<br/></span> : null}
                  {this.state.beneficiary.swift_code ? <span>Swift Code: {this.state.beneficiary.swift_code}<br/></span> : null}
                  {this.state.beneficiary.bsb_code ? <span>BSB Code: {this.state.beneficiary.bsb_code}<br/></span> : null}
                  {this.state.beneficiary.bank_code ? <span>Bank Code: {this.state.beneficiary.bank_code}<br/></span> : null}
                  {this.state.beneficiary.transit_code ? <span>Transit Code: {this.state.beneficiary.transit_code}<br/></span> : null}
                  {this.state.beneficiary.institution_number ? <span>Institution No: {this.state.beneficiary.institution_number}<br/></span> : null}
                  {this.state.beneficiary.iban ? <span>IBAN: {this.state.beneficiary.iban}<br/></span> : null}
                  {this.state.beneficiary.account_number ? <span>Account Number: {this.state.beneficiary.account_number}<br/></span> : null}
                </p>
              </React.Fragment>
            )}
          </GridItem>
        </GridContainer>
        <hr />
        <GridContainer justify='center'>
          <GridItem xs={12} sm={6}>
            <CustomReactSelect
              error={
                this.props.transfers_2.new_transfer_data.multiple_beneficiary_id &&
                this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].purpose_of_payment_detail_state ===
                  'error'
              }
              label='Purpose of payment'
              options={purpose_of_payment_select_options}
              value={
                this.props.transfers_2.new_transfer_data.multiple_beneficiary_id
                  ? this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].purpose_of_payment_detail
                  : 0
              }
              onChange={this.handleCustomReactSelectChange('purpose_of_payment_detail')}
              isClearable={false}
              isDisabled={this.state.prefill_purpose_of_payment}
            />

            {show_other && (
              <CustomInput
                style={{paddingTop: 25}}
                // success={el.detail_1_state === "success"}
                // error={el.detail_1_state === "error"}
                labelText={<span>Specify purpose of payment (*)</span>}
                id='purpose_of_payment_other'
                name='purpose_of_payment_other'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: el.purpose_of_payment_other,
                  onChange: event => this.handleChange(event, i, 'purpose_of_payment_other')
                }}
              />
            )}

            <CustomInput
              style={{paddingTop: 25}}
              success={el.detail_1_state === 'success'}
              error={el.detail_1_state === 'error'}
              labelText={<span>Payment detail / invoice number (*)</span>}
              id='detail_1'
              name='detail_1'
              formControlProps={{fullWidth: true}}
              inputProps={{
                value: el.detail_1,
                onChange: event => this.handleChange(event, i, 'detail_1')
              }}
            />
            <div className="ant-upload-list-item-container">
              {/*{*/}
              {/*  this.state.uploads.map(item => {*/}
              {/*    return <div className="ant-upload-list-item ant-upload-list-item-undefined">*/}
              {/*      <div style={{float: 'left', color: '#000', opacity: '0.4'}}><i className={'fas fa-paperclip'}></i> {item.name}</div>*/}
              {/*      <div style={{float: 'right', color: '#000', opacity: '0.4'}} onClick={() => this.onRemoveImage(item)}><i style={{color: '#000', opacity: '0.4'}} className={'fa fa-trash'}></i></div>*/}
              {/*      <div style={{clear: 'both'}}></div>*/}
              {/*    </div>*/}
              {/*  })*/}
              {/*}*/}
            </div>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <FileUploadNew key={i} listType={'picture'} ref={this.uploadRef} multiUpload={true} onRemoveImage={this.onRemoveImage} onHandleResult={this.onUploadImage}></FileUploadNew>
          </GridItem>
        </GridContainer>
      </div>
    ));
  }

  updateLedgerAmount = async (stateName, value) => {
    this.setState({[stateName]: value});
    this.props.updateNewTransferCreation(stateName, value);

    if (stateName == 'ledgerTo') {
      if (value) {
        const clientId = this.props.transfers_2.new_transfer_data.client_id;
        const currencyId = this.props.transfers_2.new_transfer_data.currency_to_id;
        const response = await API.get('holding_accounts', `/get/client-currency/${clientId}/${currencyId}`);
        this.setState({[stateName]: response.holding_account_list[0].id});
        this.props.updateNewTransferCreation('ledgerTo', response.holding_account_list[0].id);
        this.props.updateNewTransferCreation('send_to_holding_acc_checkbox', response.holding_account_list[0].id);
      } else {
        this.setState({
          send_to_holding_acc_checkbox: value,
          ledgerTo: value
        });
        this.props.updateNewTransferCreation('ledgerTo', value);
        this.props.updateNewTransferCreation('send_to_holding_acc_checkbox', value);
      }
    }
  };

  render() {
    const {classes} = this.props;
    // For CustomReactSelect. Generate select options for dropdown list.
    const beneficiary_list_select_options = this.state.beneficiaries_list.map(item => ({
      value: item.id,
      label: item.nickname
    }));
    const purpose_of_payment_select_options = this.state.purpose_list.map(item => ({
      value: item.id,
      label: item.description
    }));

    return (
      <React.Fragment>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>Please indicate beneficiary from the drop down menu below</h4>
          </GridItem>
        </GridContainer>
        {this.createNewBeneficiaryUi()}
        {this.createUI()}

        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12} lg={6} />
          <GridItem xs={12} sm={12} md={12} lg={6} />
        </GridContainer>
      </React.Fragment>
    );
  }
}

// export default withStyles(style)(Step3PayingTo);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    beneficiaries: state.beneficiaries,
    transfers_2: {...state.transfers_2}
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    },
    //startNewBeneficiaryCreation: () => {
    //  dispatch(startNewBeneficiaryCreation())
    //},
    createNewBeneficiary: data => {
      dispatch(createNewBeneficiary(data));
    },
    updateNewBeneficiaryCreation: (key, value) => {
      dispatch(updateNewBeneficiaryCreation(key, value));
    }
  };
};

const Step3PayingToContainer = connect(mapStateToProps, mapDispatchToProps)(Step3PayingTo);

export default withStyles(style)(Step3PayingToContainer);
