import {connect} from 'react-redux';
import DivisionEdit from './DivisionEdit';
import {selectEntityUi} from '../../../redux/actions/divisions';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    divisions: state.divisions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectEntityUi: value => {
      dispatch(selectEntityUi(value));
    }
  };
};

const DivisionsEditContainer = connect(mapStateToProps, mapDispatchToProps)(DivisionEdit);

export default DivisionsEditContainer;
