import {API} from 'aws-amplify';

// export const ENTITIES_SELECT_STAFF_MEMBER = "STAFF_SELECT_STAFF_MEMBER";
export const ENTITIES_FETCH_ENTITY_LIST_REQUEST = 'ENTITIES_FETCH_ENTITY_LIST_REQUEST';
export const ENTITIES_FETCH_ENTITY_LIST_SUCCESS = 'ENTITIES_FETCH_ENTITY_LIST_SUCCESS';
export const ENTITIES_FETCH_ENTITY_LIST_FAILURE = 'ENTITIES_FETCH_ENTITY_LIST_FAILURE';
export const ENTITIES_CREATE_NEW_SHOW_UI = 'ENTITIES_CREATE_NEW_SHOW_UI';
export const ENTITIES_CREATE_NEW_START = 'ENTITIES_CREATE_NEW_START';
export const ENTITIES_CREATE_NEW_CANCEL = 'ENTITIES_CREATE_NEW_CANCEL';
export const ENTITIES_CREATE_NEW_COMPLETE = 'ENTITIES_CREATE_NEW_COMPLETE';
// export const ENTITIES_EDIT_SHOW_UI = "STAFF_EDIT_SHOW_UI";
export const ENTITIES_SELECT_UI = 'ENTITIES_SELECT_UI';
export const ENTITIES_ENTITIES_LIST_IS_DIRTY = 'ENTITIES_ENTITIES_LIST_IS_DIRTY';
export const ENTITIES_SELECT_SET_ID = 'ENTITIES_SELECT_SET_ID';
export const ENTITIES_SELECT_ENTITY_REQUEST = 'ENTITIES_SELECT_ENTITY_REQUEST';
export const ENTITIES_SELECT_ENTITY_SUCCESS = 'ENTITIES_SELECT_ENTITY_SUCCESS';

export function requestEntityList() {
  return {
    type: ENTITIES_FETCH_ENTITY_LIST_REQUEST
  };
}

export function receiveEntityList(json) {
  return {
    type: ENTITIES_FETCH_ENTITY_LIST_SUCCESS,
    entities_list: json,
    receivedAt: Date.now()
  };
}

// export function fetchStaffList() {
//   return function(dispatch) {
//     dispatch(requestStaffListRequest);
//
//     API.get("crm", `/staff/get-list`)
//       .then(response => {
//         console.log(response);
//         dispatch(receiveStaffList(response));
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// }

export function fetchEntityListNotDeleted() {
  return function (dispatch) {
    dispatch(requestEntityList);

    API.get('system', `/entities/get-list-not-deleted`)
      .then(response => {
        // console.log(response);
        dispatch(receiveEntityList(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function selectEntityUi(value) {
  return {
    type: ENTITIES_SELECT_UI,
    value: value
  };
}

export function entitiesListIsDirty(value) {
  return {
    type: ENTITIES_ENTITIES_LIST_IS_DIRTY,
    value: value
  };
}

// async function createNewUserWithAws(username, password) {
//   const newUser = await Auth.signUp({
//     username: username,
//     password: password
//   });
//   return newUser;
// }

export async function create_new_entity_in_database(nickname, legal_entity_name, afsl) {
  API.post('system', `/entities/create/in-database`, {
    body: {
      nickname: nickname,
      legal_entity_name: legal_entity_name,
      afsl: afsl
    }
  })
    .then(database_result => {
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
}

export function createNewEntity(data) {
  console.log(data);
  return async function (dispatch) {
    dispatch(createNewEntityStart());

    try {
      // const newUser = await Auth.signUp({
      //   username: data.email,
      //   password: data.password
      // });
      //
      // console.log(newUser);

      await create_new_entity_in_database(data.nickname, data.legal_entity_name, data.afsl);
    } catch (e) {
      console.log(e);
      // if (e.code === "UsernameExistsException") {
      //   await Auth.resendSignUp(data.email);
      // }
    }
  };
}
export function cancelCreateNewEntity() {
  return {
    type: ENTITIES_CREATE_NEW_CANCEL
  };
}

export function setEntityDetailId(id) {
  return {
    type: ENTITIES_SELECT_SET_ID,
    value: id
  };
}

// export function loadStaffMemberDetails(aws_cognito_id) {
//   return async function(dispatch) {
//     dispatch(loadStaffMemberDetailsRequest(aws_cognito_id));
//
//   };
// }
//
export function loadEntityDetailsRequest() {
  return {
    type: ENTITIES_SELECT_ENTITY_REQUEST
  };
}
export function loadEntityDetailsSuccess() {
  return {
    type: ENTITIES_SELECT_ENTITY_SUCCESS
  };
}

export function createNewEntityStart() {
  return {
    type: ENTITIES_CREATE_NEW_START
  };
}

export function createNewEntityComplete() {
  return {
    type: ENTITIES_CREATE_NEW_COMPLETE
  };
}
