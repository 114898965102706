import React from 'react';
import {connect} from 'react-redux';
import {API} from 'aws-amplify';

import {
  startNewTransferCreation,
  updateNewTransferCreation,
  createNewTransfer
} from '../../redux/actions/transfers_pending';

// core components
import Wizard from 'components/Wizard/Wizard.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import Step1ClientContainer from './WizardSteps/Step1Client';
import Step2TransferContainer from './WizardSteps/Step2Transfer';
import Step3PayingToContainer from './WizardSteps/Step3PayingTo';
import Step4ConfirmContainer from './WizardSteps/Step4Confirm';

import {withRouter} from 'react-router-dom';

import {FormattedMessage} from 'react-intl';

const moment = require('moment');
const uuidv4 = require('uuid/v4');

class CreateNewInvoiceWizard extends React.Component {
  getNextBusinessDay(d, n) {
    d = new Date(d.getTime());
    var day = d.getDay();
    d.setDate(d.getDate() + n + (day === 6 ? 2 : +!day) + Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2);
    return d;
  }

  componentDidMount() {
    this.props.startNewTransferCreation();
  }

  // async getWorldPayPaymentURL() {
  //   console.log(this.props.transfers_2);
  //   if (this.props.transfers_2.new_transfer_data.currency_from == undefined)
  //     return;

  //   let uuid_number = uuidv4();
  //   this.props.updateNewTransferCreation("payment_method_reference", uuid_number);

  //   console.log(this.props.transfers_2.new_transfer_data.amount_from)
  //   console.log(Number.parseFloat(this.props.transfers_2.new_transfer_data.amount_from) * 100 || "")
  //   let from_amount =
  //     // Math.ceil(this.props.transfers_dashboard.new_transfer_data.amount_from) * 100 || "";
  //     Number.parseFloat(this.props.transfers_2.new_transfer_data.amount_from) * 100 || "";

  //   let from_currency = this.props.transfers_2.new_transfer_data.currency_from.iso_alpha_3.toUpperCase() || "";
  //   let clientObj = this.props.transfers_2.new_transfer_data.client || "";

  //   let url = await API.post("worldpay", `/get-payment-url`, {
  //     body: {
  //       from_currency: from_currency,
  //       from_amount: from_amount,
  //       order_code: uuid_number,
  //       client: clientObj
  //     }
  //   })
  //     .then(response => {
  //       let res = JSON.stringify(response.paymentService.reply[0].orderStatus[0].reference[0]);
  //       var firstExplode = res.split(",");
  //       var res1 = firstExplode[0].substring(6, firstExplode[0].length - 1);
  //       res1 = res1.concat("&successURL=https%3A%2F%2Fcrm-api.forexworldwide.com%2Fworldpay%2Fresponse%2Fsuccess&pendingURL=https%3A%2F%2Fcrm-api.forexworldwide.com%2Fworldpay%2Fresponse%2Fpending&failureURL=https%3A%2F%2Fcrm-api.forexworldwide.com%2Fworldpay%2Fresponse%2Ffailure&cancelURL=https%3A%2F%2Fcrm-api.forexworldwide.com%2Fworldpay%2Fresponse%2Fcancel&errorURL=https%3A%2F%2Fcrm-api.forexworldwide.com%2Fworldpay%2Fresponse%2Ferror");
  //       this.props.updateNewTransferCreation("payment_method_url", res1);
  //       return res1;
  //       // let rate = Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.client_rate);
  //       // let adjusted_rate_credit = rate + (rate * 0.02);

  //       // this.props.updateNewTransferCreation("current_rate", adjusted_rate_credit.toFixed(5));

  //       // let amount_to = Number.parseFloat(this.props.transfers_dashboard.new_transfer_data.amount_to);
  //       // let amount_from = amount_to * adjusted_rate_credit;
  //       // this.props.updateNewTransferCreation("amount_from", amount_from.toFixed(5));
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  //     return url;
  // }

  finished = async () => {
    // let set_date = this.getNextBusinessDay(new Date(), 3);
    // let settlement_date = set_date
    //   .toISOString()
    //   .slice(0, 10)
    //   .replace("T", " ");
    // console.log(this.props.transfers_2.new_transfer_data);

    // if (this.props.transfers_2.new_transfer_data.payment_method === "worldpay") {
    //   let url = await this.getWorldPayPaymentURL();
    //   window.open(url);
    // }

    await API.post('email', `/interaction/main`, {
      body: {
        interaction: 'New Invoice Creation',
        data: {
          ...this.props.transfers_pending.new_transfer_data,
          client_id: this.props.transfers_pending.new_transfer_data.client.id,
          client_nickname: this.props.transfers_pending.new_transfer_data.client.nickname,
          client_email: this.props.transfers_pending.new_transfer_data.client.email,
          record_created_datetime: moment().format('DD/MM/YYYY')
          // transaction_datetime: moment().format("DD/MM/YYYY"),
          // client_rate: this.props.transfers_dashboard.new_transfer_data.client_rate,
          // status: "2",
          // from: "Step 0 - Email us",
          // purpose: "test only"
        },
        result: 'database submission in process'
      }
    });

    // return;
    await this.props.createNewTransfer({
      ...this.props.transfers_pending.new_transfer_data,
      team_id: this.props.app_state.current_team.id,
      // nickname: `${
      //   this.props.transfers_2.new_transfer_data.amount_from
      //   } ${
      //   this.props.transfers_2.new_transfer_data.currency_from_name
      //   } to ${this.props.transfers_2.new_transfer_data.amount_to} ${
      //   this.props.transfers_2.new_transfer_data.currency_to_name
      //   } Settling ${moment(set_date).format("DD/MM/YYYY")}`,
      record_created: new Date().toISOString().slice(0, 10).replace('T', ' ')
      // transaction_datetime: new Date()
      //   .toISOString()
      //   .slice(0, 10)
      //   .replace("T", " "),
      // invoice_date: moment().format("YYYY-MM-DD"),
      // due_date: moment().format("YYYY-MM-DD"),
      // client_id: this.props.transfers_pending.new_transfer_data.client.id,
      // staff_id: this.props.transfers_pending.new_transfer_data.client
      //   .responsible_staff_member,
      // beneficiary_ids: this.props.transfers_2.new_transfer_data
      //   .multiple_beneficiary_id,
      // status: "2"
      // client_rate: this.props.payments.new_payment_data.payment_rate
    });
    this.props.history.push(`/invoices/list`);
  };

  render() {
    return (
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12}>
          <Wizard
            validate
            steps={[
              {
                stepName: 'Client',
                stepComponent: Step1ClientContainer,
                stepId: 'client'
              },
              {
                stepName: 'Invoice Items',
                stepComponent: Step2TransferContainer,
                stepId: 'transfer_to'
              },
              // {
              //   stepName: "Paying To",
              //   stepComponent: Step3PayingToContainer,
              //   stepId: "paying_to"
              // },
              {
                stepName: 'Confirm',
                stepComponent: Step4ConfirmContainer,
                stepId: 'confirm'
              }
            ]}
            title={
              // <FormattedMessage
              //   id="newTransfer.title"
              //   defaultMessage={`Start a New FX Transfer`}
              // />
              'Create a New Invoice'
            }
            subtitle={
              ''
              // <FormattedMessage
              //   id="newTransfer.subtitle"
              //   defaultMessage={`Transfer from one currency to make payment in another`}
              // />
            }
            finishButtonClick={() => this.finished()}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

// export default withRouter(CreateNewTransferWizard);
// import { connect } from "react-redux";
// import CreateNewTransferWizard from "./CreateNewTransferWizard";

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers_pending: state.transfers_pending
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startNewTransferCreation: () => {
      dispatch(startNewTransferCreation());
    },
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    },
    createNewTransfer: data => {
      dispatch(createNewTransfer(data));
    }
  };
};

const CreateNewInvoiceWizardContainer = connect(mapStateToProps, mapDispatchToProps)(CreateNewInvoiceWizard);

export default withRouter(CreateNewInvoiceWizardContainer);
