import React from 'react';
// import PropTypes from "prop-types";
import {connect} from 'react-redux';

import {
  updateClient,
  loadClientDetailsRequest,
  loadClientDetailsSuccess,
  createCognitoAccountForClient,
  createNewClient
} from '../../redux/actions/clients';

import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';

import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import {FormattedMessage} from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import {API} from 'aws-amplify';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';

import CustomReactSelect from '../Forms/CustomReactSelect/CustomReactSelect';

var generator = require('generate-password');

const staffEditStyles = {
  ...switchStyles,
  ...selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },
  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class ClientsUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      is_loading: true,
      team_id: '',
      data: [],
      teams_list: [],
      sendNewClientEmail: false,
      sendMigrateEmail: false,
      noNewTeamInvites: false,

      bulkClient: [
        {
          first_name: '',
          last_name: '',
          email: '',
          mobile: '',
          client_ref: '',
          first_name_state: '',
          last_name_state: '',
          email_state: '',
          mobile_state: '',
          client_ref_state: ''
        }
      ]
    };
  }

  componentDidMount() {
    this.getTeamList()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
      this.getTeamList()
    }
  }

  getTeamList = () => {
    if (this.props.app_state.current_staff) {
    API.get('teams', `/list/staff/${this.props.app_state.current_staff.id}`)
      .then(response => {
        console.log(response)
        this.setState({
          teams_list: response,
          team_id: response[0].id
        });
      })
      .catch(error => {
        console.log(error);
      });
    }
  }


  verifyCognitoPassword(value) {
    var passwordRex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/;
    if (passwordRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyCognitoPassword(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
  }

  fileHandler = event => {
    var XLSX = require('xlsx');
    console.log(event);
    console.log(event.target);
    console.log(event.target.files);
    let fileObj = event.target.files[0];
    var reader = new FileReader();
    reader.readAsBinaryString(event.target.files[0]);

    reader.onload = e => {
      var workbook = XLSX.read(e.target.result, {type: 'binary'});
      const wsname = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[wsname];
      const json_data = XLSX.utils.sheet_to_json(worksheet, {header: 1});

      this.setState({['data']: json_data});
      if (json_data.length > 0) {
        for (let i = 0; i < json_data.length; i++) {
          // if(i == 0) continue;
          if (i > 0) {
            this.setState(prevState => ({
              bulkClient: [
                ...prevState.bulkClient,
                {
                  first_name: '',
                  last_name: '',
                  email: '',
                  mobile: '',
                  client_ref: '',
                  first_name_state: '',
                  last_name_state: '',
                  email_state: '',
                  mobile_state: '',
                  client_ref_state: ''
                }
              ]
            }));
          }
        }
        let bulkClient = [...this.state.bulkClient];
        for (let i = 0; i < json_data.length; i++) {
          // if(i == 0) continue;
          bulkClient[i] = {...bulkClient[i], ['first_name']: json_data[i][0]};
          bulkClient[i] = {...bulkClient[i], ['last_name']: json_data[i][1]};
          bulkClient[i] = {...bulkClient[i], ['email']: json_data[i][2]};
          bulkClient[i] = {...bulkClient[i], ['mobile']: json_data[i][3]};
          bulkClient[i] = {...bulkClient[i], ['client_ref']: json_data[i][4]};
        }
        this.setState({bulkClient});
      }
    };
  };

  handleChange(e, i, stateName) {
    const {name, value} = e.target;
    let bulkClient = [...this.state.bulkClient];

    bulkClient[i] = {...bulkClient[i], [stateName]: value};
    bulkClient[i] = {...bulkClient[i], [stateName + '_state']: 'success'};

    this.setState({bulkClient});
  }
  handleToggle = name => event => {
    this.setState({[name]: event.target.checked});
  };

  createUI() {
    const {classes} = this.props;

    return (
      <div key={556}>
        <Card>
          <CardHeader icon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id='Upload details for Clients' defaultMessage={`Upload details for Clients`} />
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify='left'>
              <GridItem xs={12} md={3}>
                <input type='file' style={{padding: '10px'}} onChange={this.fileHandler} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }

  showUploadData() {
    const {classes} = this.props;

    return this.state.bulkClient.map((el, i) => {
      if (i == 0)
        return (
          <div key={i}>
            <Card>
              <CardBody>
                <GridContainer justify='left'>
                  <GridItem xs={12} md={2}>
                    {el.first_name}
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    {el.last_name}
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    {el.email}
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    {el.mobile}
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    {el.client_ref}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </div>
        );

      return (
        <div key={i}>
          <Card>
            <CardBody>
              <GridContainer justify='left'>
                <GridItem xs={12} md={2}>
                  <CustomInput
                    success={el.first_name_state === 'success'}
                    error={el.first_name_state === 'error'}
                    labelText={<span>First Name</span>}
                    id='first_name'
                    name='first_name'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: el.first_name ? el.first_name : '',
                      onChange: event => this.handleChange(event, i, 'first_name')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={2}>
                  <CustomInput
                    success={el.last_name_state === 'success'}
                    error={el.last_name_state === 'error'}
                    labelText={<span>Last Name</span>}
                    id='last_name'
                    name='last_name'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: el.last_name ? el.last_name : '',
                      onChange: event => this.handleChange(event, i, 'last_name')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={2}>
                  <CustomInput
                    success={el.email_state === 'success'}
                    error={el.email_state === 'error'}
                    labelText={<span>Email</span>}
                    id='email'
                    name='email'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: el.email ? el.email : '',
                      onChange: event => this.handleChange(event, i, 'email')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={2}>
                  <CustomInput
                    success={el.mobile_state === 'success'}
                    error={el.mobile_state === 'error'}
                    labelText={<span>Mobile</span>}
                    id='mobile'
                    name='mobile'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: el.mobile ? el.mobile : '',
                      onChange: event => this.handleChange(event, i, 'mobile')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={2}>
                  <CustomInput
                    success={el.client_ref_state === 'success'}
                    error={el.client_ref_state === 'error'}
                    labelText={<span>Client ref</span>}
                    id='client_ref'
                    name='client_ref'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: el.client_ref ? el.client_ref : '',
                      onChange: event => this.handleChange(event, i, 'client_ref')
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </div>
      );
    });
  }

  handleCustomReactSelectChange = name => value => {
    var option_value;
    if (value === null) {
      option_value = null;
    } else {
      option_value = value.value;
    }
    this.setState({
      [name]: option_value
    });
  };

  async saveUploadClient(client_detail) {
    console.log(client_detail);
    let record_created_datetime = new Date().toISOString().slice(0, 19).replace('T', ' ');

    for (var client_record in client_detail.bulkClient) {
      const emailCheck = await API.get('clients', `/get/email_check/${client_detail.bulkClient[client_record].email}`)
        .then(apiResults => {
          // console.log(apiResults)
          return apiResults;
        })
        .catch(error => {
          console.log(error);
        });

      if (emailCheck.length > 0) {
        // Existing client found with that email address

        // if (this.state.noNewTeamInvites) {
          console.log(`Existing client ${client_detail.bulkClient[client_record].email}`);
        // } else {
        //   // Do not send new team invites if tickbox is set to true
        //   const existingClient = emailCheck[0];
        //   console.log('Existing client: ', existingClient.id);
        //   await API.post('teams', `/create/client_team/in-database`, {
        //     body: {teamId: this.state.team_id, clientId: existingClient.id}
        //   });
        //   API.post('email', `/client/invite-to-team`, {
        //     body: {
        //       clientId: existingClient.id,
        //       teamId: this.state.team_id
        //     }
        //   })
        //     .then(response => {
        //       console.log(response);
        //     })
        //     .catch(error => {
        //       console.log(error);
        //     });
        // }
      } else {
        // NO existing client found with that email address
        console.log('New client');

        // Generate random password for user
        // var password = generator.generate({
        //   length: 8,
        //   numbers: true,
        //   symbols: true,
        //   lowercase: true,
        //   uppercase: true,
        //   strict: true
        // });

        console.log(client_detail.bulkClient[client_record]);
        let client_data = new Object();
        client_data.first_name = client_detail.bulkClient[client_record].first_name;
        client_data.last_name = client_detail.bulkClient[client_record].last_name;
        client_data.email = client_detail.bulkClient[client_record].email;
        client_data.telephone_mobile = client_detail.bulkClient[client_record].mobile;
        client_data.responsible_staff_member = 1;
        client_data.record_created_datetime = record_created_datetime;
        client_data.nickname =
          client_detail.bulkClient[client_record].last_name + ', ' + client_detail.bulkClient[client_record].first_name;
        // client_data.default_rate_entity = '0.02';
        client_data.external_reference = client_detail.bulkClient[client_record].client_ref;
        client_data.team_id = this.state.team_id;
        client_data.account_type = 1;
        client_data.account_status = '5';
        client_data.language_id = 1;
        // client_data.initial_portal_password = password;
        client_data.createPortalAccount = true;

        // client_data.sendNewClientEmail = this.state.sendNewClientEmail;
        // client_data.sendMigrateEmail = this.state.sendMigrateEmail;

        this.props.createNewClient(client_data);
      }
    }

    return;

    // return API.put("clients", `/bulk/add`, {
    //   body: client_detail
    // });

    // return API.put("clients", `/bulk/upload`, {
    //   body: client_detail
    // });
  }

  isValidated() {
    let bulkClient = [...this.state.bulkClient];
    let return_flag = true;

    if (this.state.team_id == '') {
      return_flag = false;
      return return_flag;
    }

    bulkClient.map(el => {
      if (el.first_name == '') {
        el.first_name_state = 'error';
        return_flag = false;
      } else {
        el.first_name_state = 'success';
      }
      if (el.last_name == '') {
        el.last_name_state = 'error';
        return_flag = false;
      } else {
        el.last_name_state = 'success';
      }
      if (el.email == '') {
        el.email_state = 'error';
        return_flag = false;
      } else {
        el.email_state = 'success';
      }
      if (el.mobile == '') {
        el.mobile_state = 'error';
        return_flag = false;
      } else {
        el.mobile_state = 'success';
      }
      this.setState({
        bulkClient: bulkClient
      });
    });

    return return_flag;
  }

  async handleSubmit() {
    if (this.isValidated()) {
      this.setState({isLoading: true});
      try {
        await this.saveUploadClient({
          bulkClient: this.state.bulkClient,
          team_id: this.state.team_id
        });
        this.props.history.push(`/clients/list`);
      } catch (e) {
        this.props.history.push(`/clients/list`);
      }
    }
  }

  render() {
    const {classes} = this.props;

    if (this.state.isLoading) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }

    const team_list_select_options = this.state.teams_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));

    return (
      <div>
        <GridContainer justify='left'>
          <GridItem xs={6} md={3}>
            <CustomReactSelect
              label='Team'
              options={team_list_select_options}
              value={this.state.team_id}
              onChange={this.handleCustomReactSelectChange('team_id')}
              error={this.state.team_id === ''}
              isClearable={true}
            />
            <p>Specify the team to which these records will be created</p>
          </GridItem>
          {/* <GridItem xs={6} md={3}>
            <Checkbox
              checked={this.state.noNewTeamInvites == '1'}
              value='noNewTeamInvites'
              onClick={this.handleToggle('noNewTeamInvites')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
            Do not invite existing to new team
            <br />
            <Checkbox
              checked={this.state.sendNewClientEmail == '1'}
              value='sendNewClientEmail'
              onClick={this.handleToggle('sendNewClientEmail')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
            Send "New Client" email
            <br />
            <Checkbox
              checked={this.state.sendMigrateEmail == '1'}
              value='sendMigrateEmail'
              onClick={this.handleToggle('sendMigrateEmail')}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
            Send "Migrate" email
          </GridItem> */}
        </GridContainer>
        {this.createUI()}
        {this.showUploadData()}
        <Button color='rose' onClick={() => this.handleSubmit()} className={classes.registerButton}>
          Save
        </Button>
      </div>
    );
  }
}

// ClientsUpload.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withRouter(withStyles(staffEditStyles)(ClientsUpload));

// import ClientsUpload from "./ClientsUpload";

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadClientDetailsRequest: () => {
      dispatch(loadClientDetailsRequest());
    },
    loadClientDetailsSuccess: () => {
      dispatch(loadClientDetailsSuccess());
    },
    createCognitoAccountForClient: (id, username, password, language_id) => {
      dispatch(createCognitoAccountForClient(id, username, password, language_id));
    },
    updateClient: (data, id) => {
      dispatch(updateClient(data, id));
    },
    createNewClient: data => {
      dispatch(createNewClient(data));
    }
  };
};

const ClientsUploadContainer = connect(mapStateToProps, mapDispatchToProps)(ClientsUpload);

export default withRouter(withStyles(staffEditStyles)(ClientsUploadContainer));
