import {connect} from 'react-redux';
import LanguageSelection from './LanguageSelection';
import {
  //createNewIdentificationRecord,
  selectLanguageUi,
  loadLanguageDetailsRequest,
  loadLanguageDetailsSucesss
  //startNewIdentificationRecordCreation
  //   selectStaffUi
} from '../../redux/actions/language';
// import {
//     createNewIdentificationRecord,
//     updateNewIdentificationRecordCreation,
//     startNewIdentificationRecordCreation
//     //   selectStaffUi
// } from "../../redux/actions/identification";

const mapStateToProps = state => {
  //console.log(state);

  return {
    app_state: state.app_state,
    language: state.language
    //current_browser_ui_language: JSON.parse(win).selectLanguage,
    // identification: state.identification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewIdentificationRecord: (data) => {
    //     // console.log(data)
    //     dispatch(createNewIdentificationRecord(data))
    // },
    // updateNewIdentificationRecordCreation: (key, value) => {
    //     // console.log(data)
    //     dispatch(updateNewIdentificationRecordCreation(key, value))
    // },
    // startNewIdentificationRecordCreation: () => {
    //   dispatch(startNewIdentificationRecordCreation())
    // },
    selectLanguageUi: data => {
      dispatch(selectLanguageUi(data));
    }
  };
};

const LanguageSelectionContainer = connect(mapStateToProps, mapDispatchToProps)(LanguageSelection);

export default LanguageSelectionContainer;
