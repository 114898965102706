import {CheckSquareOutlined, CloseSquareOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons';
import withStyles from '@material-ui/core/styles/withStyles';

import {NavLink, withRouter} from 'react-router-dom';

// import Close from '@material-ui/icons/Close';
import {
    Button as ButtonAntD,
    Button as AntButton,
    Card,
    Col,
    Collapse,
    Descriptions,
    Divider,
    Flex,
    message,
    Modal,
    Popconfirm,
    Row,
    Spin,
    Table,
    Tag
} from 'antd';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {DateRangePicker} from 'react-dates';
import 'react-dates/initialize';
import {AiOutlineSearch} from 'react-icons/ai';
import {FormattedNumber, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {AppUrlConstants} from '../../AppAPI';
import {setTransferSelectId} from '../../redux/actions/transfers';
import ListTable from '../ListTable/ListTable';
import {TransferAPI, TransferConstants, TransfersUtil} from './TransfersUtil';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import CardBody from '../Card/CardBody';
import {ClientAPI} from '../Clients/ClientUtil';
import {fetchCurrencyListNotDeleted} from '../../redux/actions/currencies';
import {fetchCountryListNotDeleted} from '../../redux/actions/countries';
import {BeneficiaryAPI} from '../Beneficiaries/BeneficiaryUtils';
import {API} from "aws-amplify";
import FileUploader from "../Uploaders/FileUploader";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    },
    id: {color: '#0BACF4'},
    currencyContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    currency: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'fit-content',
        '& > .currency-flag': {
            marginRight: '0.5rem',
            width: 30,
            height: 16
        }
    },
    currencyLabel: {
        textAlign: 'right',
        '& > p': {
            marginBottom: -5,
            fontSize: '0.6rem'
        }
    },
    progress: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > span': {
            // marginLeft: 12,
            fontSize: 12,
            textTransform: 'uppercase'
        }
    },
    progressBar: {width: 30},
    trail: {
        stroke: '#d6d6d6',
        strokeLinecap: 'butt',
        transform: 'rotate(0.25turn)',
        transformOrigin: 'left left'
    },
    path: {
        strokeLinecap: 'butt',
        transition: 'stroke-dashoffset 0.5s ease 0s',
        transform: 'rotate(0.25turn)',
        transformOrigin: 'left left'
    },
    circularStyles: {
        textColor: '#000',
        textSize: '20px',
        transformOrigin: 'left center',
        trail: {
            // Trail color
            stroke: '#d6d6d6',
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',
            // Rotate the trail
            transform: 'rotate(0.25turn)',
            transformOrigin: 'left center'
        }
    }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - TransferList - Components for list all the transfer from this user
 * ----------------------------------------------------------------------------
 */
const CurrencyElement = ({classes, currency, value}) => (
    <div className={classes.currency}>
        <div className={`currency-flag currency-flag-${currency ? currency.toLowerCase() : ''}`} />
        <div className={classes.currencyLabel}>
            <p>{currency}</p>
            <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={value} />
        </div>
    </div>
);

class TransferList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entity_list: [],
            dialog_delete_confirm: false,
            client_id: '',
            isSplitPay: 0,
            startDate: moment().subtract(1, 'month'),
            endDate: moment().add(1, 'month'),
            transferList: [],
            dataLoading: false,
            currencies: [],
            expandedRow: {}
        };
    }

    getFilter = (filter, onChange) => {
        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <AiOutlineSearch />
                    <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
                </div>
            </React.Fragment>
        );
    };

    componentDidMount() {
        this.fetchTransferList();
        AppUrlConstants.fetchCurrencies().then(response => {
            const currencies = response.sort((a, b) => a.iso_alpha_3.localeCompare(b.iso_alpha_3));
            this.setState({currencies: currencies.map(item => ({text: item.iso_alpha_3, value: item.iso_alpha_3}))});
        });
        this.queryStrings();
        this.props.fetchCurrencyList();
        this.props.fetchCountryList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
            console.log(this.props.app_state.current_staff);
            // this.props.fetchStaffList()
            this.fetchTransferList();
        }
    }

    fetchTransferList = () => {
        this.setState({dataLoading: true});
        if (this.props.app_state.current_staff) {
            const staffID = this.props.app_state.current_staff.id;
            TransferAPI.fetchTransfer(
                staffID,
                this.state.startDate.format('YYYY-MM-DD'),
                this.state.endDate.format('YYYY-MM-DD'),
                this.props.status
            )
                .then(response => {
                    this.setState({transferList: response, dataLoading: false});
                })
                .catch(error => {
                    this.setState({dataLoading: false});
                    console.log(error);
                });
        }
    };

    handle_delete_dialog_cancel = () => {
        this.setState({
            dialog_delete_confirm: false,
            dialog_delete_item: 0,
            dialog_delete_item_name: ''
        });
    };

    handle_date_change = (startDate, endDate) => {
        this.setState({
            startDate,
            endDate
        });
    };

    queryStrings() {
        const query_strings = queryString.parse(this.props.location.search);
        if (Object.keys(query_strings).length !== 0) {
            if (query_strings.client_id) {
                this.setState({client_id: Number(query_strings.client_id)});
            }
        }
    }

    buildColumns = statusOptions => {
        return [
            {
                title: 'ID',
                key: 'id',
                dataIndex: 'id',
                width: '1%',
                defaultSortOrder: 'DESC',
                sorter: (a, b) => a.id - b.id,
                ...this.props.getColumnSearchProps({
                    label: 'ID',
                    dataIndex: 'id',
                    render: (text, record) => {
                        return (
                            <>
                                <Tag color={TransfersUtil.getTagColor(record.transfer_status)}>
                                    <span>ID : {record.id}</span>
                                </Tag>
                                {record.forward_id && (
                                    <span
                                        style={{cursor: 'pointer'}}
                                        onClick={() => this.props.history.push(`/forwards/detail/${record.forward_id}`)}
                                    >
                                        <Tag color={'gold'}>Draw Down ID - [{record.forward_id}]</Tag>
                                    </span>
                                )}
                            </>
                        );
                    }
                })
            },
            {
                title: 'Transaction',
                key: 'transaction_date',
                dataIndex: 'transaction_date',
                sorter: (a, b) => {
                    const dateA = new Date(a.transaction_date.split('/').reverse().join('/'));
                    const dateB = new Date(b.transaction_date.split('/').reverse().join('/'));
                    return dateA - dateB;
                },
                ...this.props.getColumnSearchProps({
                    label: 'Transaction',
                    dataIndex: 'transaction_date',
                    filterInputType: 'DATE',
                    render: text => text
                })
            },
            {
                title: 'Settlement',
                key: 'settlement_date',
                dataIndex: 'settlement_date',
                sorter: (a, b) => {
                    const dateA = new Date(a.settlement_date.split('/').reverse().join('/'));
                    const dateB = new Date(b.settlement_date.split('/').reverse().join('/'));
                    return dateA - dateB;
                },
                ...this.props.getColumnSearchProps({
                    label: 'Settlement Date',
                    dataIndex: 'settlement_date',
                    filterInputType: 'DATE',
                    render: text => text
                })
            },
            {
                title: 'Client',
                key: 'client',
                dataIndex: 'client',
                sorter: (a, b) => (a.client || '').localeCompare(b.client, undefined, {sensitivity: 'base'}),
                ...this.props.getColumnSearchProps({
                    label: 'Client',
                    dataIndex: 'client',
                    render: (text, record) => (
                        <a
                            onClick={() => this.props.history.push(`/clients/edit/${record.clientId}`)}
                            style={{cursor: 'pointer'}}
                        >
                            {text}
                        </a>
                    )
                })
            },
            {
                title: 'Beneficiary',
                key: 'beneficiary',
                dataIndex: 'beneficiary',
                ...this.props.getColumnSearchProps({
                    label: 'Beneficiary',
                    dataIndex: 'beneficiary',
                    render: (text, record) => {
                        // console.log("record, ", record);
                        if (record.isMultiPay) {
                            return (
                                <a
                                    onClick={() => this.props.history.push(`/transfers/edit/${record.id}?tab=payout`)}
                                    style={{cursor: 'pointer'}}
                                >
                                    MultiPay Transfer
                                </a>
                            );
                        }
                        return (
                            <>
                                <a
                                    onClick={() =>
                                        this.props.history.push(`/beneficiaries/edit/${record.beneficiary_id}`)
                                    }
                                    style={{cursor: 'pointer'}}
                                >
                                    {text}
                                </a>
                            </>
                        );
                    }
                })
            },
            {
                title: 'From',
                key: 'currency_from',
                dataIndex: 'currency_from',
                filters: this.state.currencies,
                onFilter: (value, record) =>
                    record.currency_from != null ? record.currency_from.indexOf(value) === 0 : false,
                filterSearch: true,
                ...this.props.getColumnSearchProps({
                    label: 'currency_from',
                    dataIndex: 'currency_from',
                    filterInputType: 'SELECT',
                    render: (text, record) => {
                        return (
                            <>
                                <CurrencyElement
                                    currency={record.currency_from}
                                    value={record.amount_from}
                                    classes={this.props.classes}
                                />
                            </>
                        );
                    }
                })
            },
            {
                title: 'Rate',
                key: 'client_rate',
                dataIndex: 'client_rate',
                render: text => {
                    return <FormattedNumber minimumFractionDigits={4} maximumFractionDigits={4} value={text} />;
                }
            },
            {
                title: 'To',
                key: 'currency_to',
                dataIndex: 'currency_to',
                filters: this.state.currencies,
                onFilter: (value, record) =>
                    record.currency_to != null ? record.currency_to.indexOf(value) === 0 : false,
                filterSearch: true,
                ...this.props.getColumnSearchProps({
                    label: 'currency_to',
                    dataIndex: 'currency_to',
                    filterInputType: 'SELECT',
                    render: (text, record) => {
                        return (
                            <>
                                <CurrencyElement
                                    currency={record.currency_to}
                                    value={record.amount_to}
                                    classes={this.props.classes}
                                />
                            </>
                        );
                    }
                })
            },
            {
                title: 'Docs',
                key: 'documents',
                dataIndex: 'documents',
                filters: [{text: 'Yes', value: true}, {text: 'No', value: false}],
                onFilter: (value, record) => {
                    return value === record.documents.length > 0
                },
                filterSearch: true,
                render: (text, record) => {
                    if (record.documents && record.documents.length > 0) {
                        return <Tag style={{ cursor: 'pointer'}} onClick={() => this.onViewDocumentsBtnClick(record.documents)} icon={<CheckSquareOutlined />} color={'success'}></Tag>
                    }
                    return <Tag color={'orange'} icon={<CloseSquareOutlined />}></Tag>;
                },
            },
            {
                title: 'Status',
                key: 'transfer_status',
                dataIndex: 'transfer_status',
                filters: statusOptions,
                onFilter: (value, record) =>
                    record.transfer_status != null ? record.transfer_status.indexOf(value) === 0 : false,
                ...this.props.getColumnSearchProps({
                    label: 'Status',
                    dataIndex: 'transfer_status',
                    filterInputType: 'SELECT',
                    render: text => {
                        return (
                            <div>
                                <CircularProgressbar
                                    style={{...this.props.classes.trail, ...this.props.classes.path}}
                                    className={this.props.classes.progressBar}
                                    value={TransfersUtil.getCircularPercentage(text)}
                                    text={`${TransfersUtil.getCircularPercentage(text)}%`}
                                    styles={buildStyles({
                                        ...this.props.classes.circularStyles,
                                        pathColor: TransfersUtil.getCircularColor(text)
                                    })}
                                />
                                &nbsp;
                                <span style={{fontSize: '12px', textAlign: 'center'}}>{text}</span>
                            </div>
                        );
                    }
                })
            },
            {
                title: 'Actions',
                key: 'actions',
                dataIndex: 'actions'
            }
        ];
    };

    onViewDocumentsBtnClick = async (documents) => {
        const loading = message.loading("Loading transfer documents. Please wait..", 0);

        let documentIds = [];

        documents.forEach(i => {
            const id = i.id;
            const attachments = JSON.parse(i.attachments);
            if (attachments) {
                if (attachments.ids && attachments.ids.length > 0) {
                    documentIds = attachments.ids.filter(k => k !== i.documentId);
                }
            }
            if (i.documentId != null) {
                documentIds.push(i.documentId)
            }
        });

        if (documentIds.length > 0) {
            const documents = await API.post("commons", "/fetch", {
                body: {
                    context: 'documents',
                    fields: ['*'],
                    condition: {id: documentIds}
                }
            });

            const attachments = documents.map(item => item.file_ref);

            Modal.confirm({
                title: 'Transfer Documents',
                width: 600,
                content: <Row gutter={[16, 16]}>
                    {attachments.map(i => <>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            <FileUploader previewOnly={true}
                                          thumbType={'picture-card'}
                                          uploaderType={'PDF'}
                                          fetchFileList={() => {
                                              const file = {
                                                  name: i,
                                                  uid: i,
                                                  status: 'done'
                                              }
                                              return Promise.resolve([file]);
                                          }}></FileUploader>
                        </Col>
                    </>)}
                </Row>,
                cancelButtonProps: { hidden: true },
                maskClosable: true
            })
        }

        loading();

    }

    onDeleteTransferBtnClick = transferId => {
        TransferAPI.removeTransfer(transferId)
            .then(() => this.fetchTransferList())
            .catch(error => {
                message.error('Unable to delete the transfer. Please try again...');
                console.log(error);
                this.fetchTransferList();
            });
    };

    getSteps() {
        return ['Submitted', 'Acknowledged', 'Customer Money Received', 'Beneficiary Payment Made', 'Completed'];
    }

    getSteps1() {
        return ['Submitted', 'Not Proceeding'];
    }

    onRowExpand = (event, record) => {
        if (event === true) {
            if (
                this.state.expandedRow[`row_${record.id}`] == null ||
                this.state.expandedRow[`row_${record.id}`].status === 'ERROR'
            ) {
                this.fetchTransferDetail(record).catch(err => {
                    console.log(err);
                    this.setState({expandedRow: {...this.state.expandedRow, [`row_${record.id}`]: {status: 'ERROR'}}});
                });
            }
        }
    };

    fetchTransferDetail = async record => {
        const identifier = `row_${record.id}`;
        this.setState({expandedRow: {...this.state.expandedRow, [identifier]: {status: 'LOADING'}}});
        const [response, benResponse, transferResponse] = await Promise.all([
            ClientAPI.fetchClientById(record.clientId),
            BeneficiaryAPI.fetchBeneficiaryById(record.beneficiary_id),
            TransferAPI.fetchTransferById(record.id)
        ]);
        this.setState({
            expandedRow: {
                ...this.state.expandedRow,
                [identifier]: {status: 'LOADED', client: response, beneficiary: benResponse, transfer: transferResponse}
            }
        });
    };

    buildClientDetails = data => {
        const clientDetails = [
            {
                key: '1',
                label: 'Client ID',
                children: (
                    <span>
                        <NavLink to={`/clients/edit/${data.client.id}`}>{data.client.id}</NavLink>
                    </span>
                )
            },
            {
                key: '2',
                label: 'Team',
                children: <span>{data.client.team_nickname}</span>
            },
            {
                key: '3',
                label: 'Individual',
                children: (
                    <span>
                        <NavLink to={`/clients/edit/${data.client.id}`}>
                            {data.client.first_name} {data.client.last_name}
                        </NavLink>
                    </span>
                )
            },
            data.client.business_company_name
                ? {
                      key: '4',
                      label: 'Company',
                      children: (
                          <span>
                              <NavLink to={`/clients/edit/${data.client.id}`}>
                                  {data.client.business_company_name}
                              </NavLink>
                              <br />
                              Co Num: {data.client.business_company_number}
                              <br />
                              ABN: {data.client.business_abn}
                          </span>
                      )
                  }
                : {},
            {
                key: '5',
                label: 'Email',
                children: data.client.email
            },
            {
                key: '6',
                label: 'Telephone',
                children: data.client.telephone_mobile
            }
        ];

        return (
            <Card>
                <Descriptions
                    title={`Client Details`}
                    layout='horizontal'
                    bordered={true}
                    column={1}
                    size='small'
                    items={clientDetails}
                />
            </Card>
        );
    };

    buildBeneficiaryDetails = data => {
        if (!data.beneficiary) {
            return (
                <Card>
                    <strong>No Beneficiary Details</strong>
                    <br />
                    <em>Payment will be made to currency balance</em>
                </Card>
            );
        }

        const account_info = [
            {label: 'Aba Routing Number', value: 'aba_routing_number'},
            {label: 'Bank Code', value: 'bank_code'},
            {label: 'Sort Code', value: 'sort_code'},
            {label: 'Bsb Code', value: 'bsb_code'},
            {label: 'Iban', value: 'iban'},
            {label: 'Swift Code', value: 'swift_code'},
            {label: 'Transit Code', value: 'transit_code'},
            {label: 'Institution Number', value: 'institution_number'},
            {label: 'Account Number', value: 'account_number'}
        ];

        const infos = [];
        account_info.forEach(item => {
            if (!(data.beneficiary[item.value] == null || data.beneficiary[item.value] === '')) {
                infos.push({label: item.label, value: data.beneficiary[item.value]});
            }
        });
        const transferDetails = [
            {
                key: 1,
                label: 'Name',
                children: data.beneficiary.ben_legal_name
            },
            {
                key: 2,
                label: 'Beneficiary Address',
                children: (
                    <Flex vertical>
                        <span>{data.beneficiary.ben_address_line_1}</span>
                        <span>{data.beneficiary.ben_address_line_2}</span>
                        <span>{data.beneficiary.ben_address_suburb}</span>
                        <span>{data.beneficiary.ben_address_state}</span>
                        <span>
                            {data.beneficiary.ben_address_country != null && data.beneficiary.ben_address_country !== 0
                                ? this.props.countries.countries_list.find(
                                      item => item.id === data.beneficiary.ben_address_country
                                  ).full_name
                                : ''}
                        </span>
                    </Flex>
                )
            },
            {
                key: 3,
                label: 'Bank Address',
                children: (
                    <Flex vertical>
                        <span>{data.beneficiary.bank_address_line_1}</span>
                        <span>{data.beneficiary.bank_address_line_2}</span>
                        <span>{data.beneficiary.bank_address_suburb}</span>
                        <span>{data.beneficiary.bank_address_state}</span>
                        <span>
                            {data.beneficiary.bank_address_country != null &&
                            data.beneficiary.bank_address_country !== 0
                                ? this.props.countries.countries_list.find(
                                      item => item.id === data.beneficiary.bank_address_country
                                  ).full_name
                                : ''}
                        </span>
                    </Flex>
                )
            },
            {
                key: 4,
                label: 'Account Details',
                children: (
                    <Flex vertical>
                        {infos.map(item => (
                            <span>
                                {item.label}: {item.value}
                            </span>
                        ))}
                    </Flex>
                )
            }
        ];

        return (
            <Card>
                <Descriptions
                    title={`Beneficiary Details`}
                    layout='horizontal'
                    bordered={true}
                    column={1}
                    size='small'
                    items={transferDetails}
                />
            </Card>
        );
    };

    buildTransferDetails = (data, row) => {
        const transfer_data = data.transfer;
        const transferDetails = [
            {
                key: 1,
                label: 'Transfer ID',
                children: transfer_data.fullList.id
            },
            {
                key: 2,
                label: 'Transaction Date',
                children: row.transaction_date != null ? row.transaction_date : '-'
            },
            {
                key: 3,
                label: 'Settlement Date',
                children: transfer_data.fullList.settlement_date != null ? row.settlement_date : '-'
            },
            {
                key: 4,
                label: 'From',
                children: (
                    <>
                        <img
                            src={`https://wise.com/public-resources/assets/flags/rectangle/${row.currency_from.toLowerCase()}.png`}
                            width='24px'
                            height='16px'
                        />
                        {` `}
                        {row.currency_from.toUpperCase()}{' '}
                        {this.props.intl.formatNumber(row.amount_from, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}
                    </>
                )
            },
            {
                key: 5,
                label: 'To',
                children: (
                    <>
                        <img
                            src={`https://wise.com/public-resources/assets/flags/rectangle/${row.currency_to.toLowerCase()}.png`}
                            width='24px'
                            height='16px'
                        />
                        {` `}
                        {row.currency_to.toUpperCase()}{' '}
                        {this.props.intl.formatNumber(row.amount_to, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}
                    </>
                )
            },
            {
                key: 6,
                label: 'Client Rate',
                children: this.props.intl.formatNumber(row.client_rate, {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4
                })
            },
            {
                key: 7,
                label: 'Bank Rate',
                children: this.props.intl.formatNumber(transfer_data.fullList.bank_rate, {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4
                })
            }
        ];

        if (this.props.app_state.current_staff.security_seeProfit) {
            if (transfer_data.fullList.bank_rate != null) {
                const amountFrom = row.amount_from;
                const amountTo = row.amount_to;
                const clientRate = row.client_rate;
                let transferProfit =
                    (amountTo * clientRate - amountFrom * transfer_data.fullList.bank_rate) /
                    transfer_data.fullList.bank_rate;
                transferProfit = Math.abs(transferProfit);
                transferProfit = this.props.intl.formatNumber(transferProfit, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
                transferDetails.push({
                    key: 8,
                    label: 'Profit',
                    children: transferProfit
                });
            }
        }

        return (
            <Card>
                <Descriptions
                    title={`Transfer Details`}
                    layout='horizontal'
                    bordered={true}
                    column={1}
                    size='small'
                    items={transferDetails}
                />
            </Card>
        );
    };

    render() {
        const steps = this.getSteps();
        const steps1 = this.getSteps1();
        const statusOptions = Object.keys(TransferConstants.STATUS_OPTIONS).map(key => ({
            text: TransferConstants.STATUS_OPTIONS[key],
            value: TransferConstants.STATUS_OPTIONS[key]
        }));
        const columns = this.buildColumns(statusOptions);
        let tableData = [];
        let date_range_transfer_list = this.state.transferList;
        const transfer_list_length = date_range_transfer_list.length;
        if (transfer_list_length > 0) {
            tableData = date_range_transfer_list.map(prop => {
                return {
                    id: prop.id,
                    clientId: prop.client_id,
                    client: prop.client_nickname,
                    transaction_date:
                        prop.transaction_datetime != null
                            ? moment(prop.transaction_datetime).format('DD/MM/YYYY')
                            : '-',
                    status_id: prop.status_id,
                    isMultiPay: prop.isMultiPay,
                    memo: prop.memo,
                    nickname: prop.nickname,
                    forward_id: prop.forward_id,
                    splitPay: prop.splitPay,
                    settlement_date:
                        prop.settlement_date != null ? moment(prop.settlement_date).format('DD/MM/YYYY') : '-',
                    beneficiary: prop['beneficiaries_nickname'],
                    beneficiary_id: prop['beneficiaries_id'],
                    amount_from: prop.amount_from,
                    amount_to: prop.amount_to,
                    currency_from: prop.currency_from_iso_alpha_3,
                    currency_to: prop.currency_to_iso_alpha_3,
                    client_rate: prop.client_rate ? prop.client_rate.toFixed(5) : '',
                    transfer_status: prop.transfer_status,
                    documents: prop.documents,
                    actions: (
                        <Flex align={'center'} vertical={false}>
                            <AntButton
                                onClick={() => {
                                    this.props.history.push(`/transfers/edit/${prop.id}`);
                                }}
                                type='link'
                                size={'small'}
                                className='edit'
                            >
                                <EyeOutlined />
                            </AntButton>{' '}
                            {this.props.app_state.current_staff_super_admin && (
                                <Popconfirm
                                    title='Delete this transfer ?'
                                    description={`Are you sure to delete this transfer with ID ${prop.id} ?`}
                                    onConfirm={() => this.onDeleteTransferBtnClick(prop.id)}
                                    okText='Yes'
                                    cancelText='No'
                                >
                                    <AntButton
                                        type={'link'}
                                        size={'small'}
                                        icon={<DeleteOutlined />}
                                        danger={true}
                                        className='remove'
                                    ></AntButton>
                                </Popconfirm>
                            )}{' '}
                        </Flex>
                    )
                };
            });
        }

        return (
            <React.Fragment>
                <Row style={{marginBottom: '5px'}}>
                    <Col span={20}>
                        <DateRangePicker
                            isOutsideRange={() => false}
                            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                            startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
                            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                            endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
                            displayFormat='DD/MM/YYYY'
                            onDatesChange={({startDate, endDate}) => this.handle_date_change(startDate, endDate)} // PropTypes.func.isRequired,
                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => this.setState({focusedInput})} // PropTypes.func.isRequired,
                        />
                        <AntButton onClick={() => this.fetchTransferList()}>Search</AntButton>
                    </Col>
                    <Col span={4} flex='auto'>
                        <AntButton
                            type='primary'
                            onClick={() => this.props.history.push('/transfers/new')}
                        >
                            Create New Transfer
                        </AntButton>
                        <Divider type='vertical' />
                        <AntButton
                            color="default" variant="outlined"
                            onClick={() => this.fetchTransferList()}
                        >
                            Refresh List
                        </AntButton>
                    </Col>
                </Row>
                <Table
                    dataSource={tableData}
                    rowKey={row => row.id}
                    columns={columns}
                    size={'middle'}
                    scroll={{x: 'max-content'}}
                    bordered={true}
                    expandable={{
                        onExpand: (event, record) => {
                            this.onRowExpand(event, record);
                        },
                        expandedRowRender: row => {
                            const data = this.state.expandedRow[`row_${row.id}`];
                            if (!data) {
                                return (
                                    <ButtonAntD type={'link'} onClick={() => this.fetchTransferDetail(row)}>
                                        <strong>Reload</strong>
                                    </ButtonAntD>
                                );
                            }
                            if (data.status !== 'LOADED') {
                                return data.status === 'LOADING' ? (
                                    <Spin />
                                ) : (
                                    <ButtonAntD
                                        type={'link'}
                                        danger={true}
                                        onClick={() => this.fetchTransferDetail(row)}
                                    >
                                        <strong>Try again...</strong>
                                    </ButtonAntD>
                                );
                            }

                            let activeTab = 0;
                            switch (row.status_id) {
                                case 2:
                                    activeTab = 0;
                                    break;
                                case 3:
                                case 4:
                                case 5:
                                case 10:
                                    activeTab = 1;
                                    break;
                                case 6:
                                    activeTab = 2;
                                    break;
                                case 7:
                                    activeTab = 3;
                                    break;
                                case 8:
                                    activeTab = 4;
                                    break;
                                default:
                                    activeTab = 0;
                            }

                            return (
                                <>
                                    <Card
                                        style={{width: '100%'}}
                                        title={this.state.expandedRow[`row_${row.id}`].client.nickname}
                                    >
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12}>
                                                    {/*<h6>{v.original.transfer_status_name}</h6>*/}
                                                    {row.status_id !== 10 && (
                                                        <Stepper activeStep={activeTab}>
                                                            {(steps || []).map(label => {
                                                                const stepProps = {};
                                                                const labelProps = {};

                                                                return (
                                                                    <Step key={label} {...stepProps}>
                                                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                                                    </Step>
                                                                );
                                                            })}
                                                        </Stepper>
                                                    )}
                                                    {row.status_id === 10 && (
                                                        <Stepper activeStep={activeTab} style={{width: '30%'}}>
                                                            {(steps1 || []).map(label => {
                                                                const stepProps = {};
                                                                const labelProps = {};

                                                                return (
                                                                    <Step key={label} {...stepProps}>
                                                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                                                    </Step>
                                                                );
                                                            })}
                                                        </Stepper>
                                                    )}
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                    <GridContainer>
                                        <GridItem xs={12} sm={data.beneficiary ? 3 : 5}>
                                            {this.buildClientDetails(data)}
                                        </GridItem>
                                        <GridItem xs={12} sm={data.beneficiary ? 6 : 2}>
                                            {this.buildBeneficiaryDetails(data)}
                                        </GridItem>
                                        <GridItem xs={12} sm={data.beneficiary ? 3 : 5}>
                                            {this.buildTransferDetails(data, row)}
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem>
                                            {data.transfer.fullList.documents != null &&
                                            data.transfer.fullList.documents.length > 0 ? (
                                                <Collapse
                                                    size='small'
                                                    items={[
                                                        {
                                                            key: data.transfer.fullList.id,
                                                            label: 'Invoices List (Click to Expand)',
                                                            children: (
                                                                <ol>
                                                                    {data.transfer.fullList.documents.map(item => (
                                                                        <li>
                                                                            <a
                                                                                style={{cursor: 'pointer'}}
                                                                                onClick={() =>
                                                                                    window.open(
                                                                                        window.location.origin +
                                                                                            '/transfers/documents/view/' +
                                                                                            item.id,
                                                                                        '_blank'
                                                                                    )
                                                                                }
                                                                            >
                                                                                INVOICE - {item.id}
                                                                            </a>{' '}
                                                                            (Click to open in a new tab){' '}
                                                                        </li>
                                                                    ))}
                                                                </ol>
                                                            )
                                                        }
                                                    ]}
                                                />
                                            ) : (
                                                'There are no any uploaded invoices or documents'
                                            )}
                                        </GridItem>
                                    </GridContainer>
                                </>
                            );
                        },
                        rowExpandable: row => row.name !== 'Not Expandable'
                    }}
                    loading={this.state.dataLoading}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        transfers: state.transfers,
        countries: state.countries,
        currencies: state.currencies
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTransferSelectId: id => {
            dispatch(setTransferSelectId(id));
        },
        fetchCurrencyList: () => {
            dispatch(fetchCurrencyListNotDeleted());
        },
        fetchCountryList: () => {
            dispatch(fetchCountryListNotDeleted());
        }
    };
};

const TransferListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(styles)(ListTable(injectIntl(TransferList)))));

export default TransferListContainer;
