import {
  COUNTRIES_FETCH_COUNTRY_LIST_REQUEST,
  COUNTRIES_FETCH_ENTITY_LIST_SUCCESS,
  // ENTITIES_FETCH_ENTITY_LIST_FAILURE,
  ENTITIES_CREATE_NEW_SHOW_UI,
  ENTITIES_CREATE_NEW_START,
  ENTITIES_CREATE_NEW_CANCEL,
  ENTITIES_CREATE_NEW_COMPLETE,
  COUNTRIES_SELECT_UI,
  ENTITIES_ENTITIES_LIST_IS_DIRTY,
  COUNTRIES_COUNTRY_EDIT
} from '../actions/countries.js';

const initialState = {
  is_fetching: false,
  did_invalidate: false,
  countries_list_is_dirty: false,
  countries_list: [],
  countries_current_ui: 'list',
  countries_create_new_in_process: false,
  country_id: ''
};

function Country(state = initialState, action) {
  switch (action.type) {
    case COUNTRIES_FETCH_COUNTRY_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
      });

    case COUNTRIES_FETCH_ENTITY_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        countries_list: action.countries_list,
        lastUpdated: action.receivedAt
      });

    case ENTITIES_CREATE_NEW_SHOW_UI:
      return Object.assign({}, state, {
        countries_current_ui: 'list'
      });
    //
    case ENTITIES_CREATE_NEW_START:
      return Object.assign({}, state, {
        entities_create_new_in_process: true
      });

    case ENTITIES_CREATE_NEW_CANCEL:
      return Object.assign({}, state, {
        entities_create_new_in_process: false,
        countries_current_ui: 'list'
      });

    case ENTITIES_CREATE_NEW_COMPLETE:
      return Object.assign({}, state, {
        entities_create_new_in_process: false,
        countries_current_ui: 'list',
        countries_list_is_dirty: true
      });

    case COUNTRIES_SELECT_UI:
      return Object.assign({}, state, {
        countries_current_ui: action.value
      });
    case ENTITIES_ENTITIES_LIST_IS_DIRTY:
      return Object.assign({}, state, {
        countries_list_is_dirty: action.value
      });
    case COUNTRIES_COUNTRY_EDIT:
      return Object.assign({}, state, {
        entities_create_new_in_process: false,
        countries_current_ui: 'edit',
        country_id: action.value
      });
    default:
      return state;
  }
}

export default Country;
