import React from 'react';
import ReactTable from 'react-table';

import { connect } from 'react-redux';
import { AiOutlineSearch } from 'react-icons/ai';
import {
    fetchClientList
} from '../../redux/actions/clients';
import { Select, Tooltip } from 'antd'

import { API } from 'aws-amplify';

import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Button, Button as AButton, Button as AntButton, Divider, Flex, Input, Popconfirm, Table, Tag } from 'antd'

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';

import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';

import moment from 'moment'


/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import { ClientAPI } from './ClientUtil'
import ListTable from '../ListTable/ListTable'
import FormItem from 'antd/lib/form/FormItem'
import {RISK_SCORE_OPTIONS as riskScoreOptions} from "./ClientEdit/duck/constants";
import {RiskConstants} from "./ClientEdit/Risk/RiskConstants";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    displayBlock: {
        display: 'block'
    }
};


/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientList
 * ----------------------------------------------------------------------------
 */
class ClientList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // entity_list: [],
            dialog_delete_confirm: false,
            dialog_delete_item: 0,
            dialog_delete_item_name: '',
            team_ddl_options: null,
            clientList: [],
            clientListAll: [],
            clientOptions: [],
            dataLoading: false,
            riskScoreFilterOptions: [
                { text: 'LOW', value: 'LOW' },
                { text: 'MEDIUM', value: 'MEDIUM' },
                { text: 'HIGH', value: 'HIGH' },
                { text: 'DECLINE', value: 'DECLINE' },
                { text: 'NOT CALCULATED', value: 'NOT_CALCULATED' }
            ]
        };
    }

    componentDidMount() {
        this.fetchClientList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
            console.log(this.props.app_state.current_staff)
            this.fetchClientList()
        }
    }

    getRiskColor = (newCalcScore) => RiskConstants.GetRiskColor(newCalcScore);
    getRiskRating = (newCalcScore) => RiskConstants.GetRiskRating(newCalcScore);

    getTagColor = (key) => {
        let color = 'cyan';
        switch (key) {
            case 1:
                color = 'orange';
                break;
            case 2:
                color = "success";
                break;
            case 3:
                color = "red";
                break;
            case 4:
                color = "red";
                break;
        }
        return color;
    }

    getClientStatus = (statusKey) => {
        let status = 'Incomplete';
        switch (statusKey) {
            case 1:
                status = 'Pending';
                break;
            case 2:
                status = 'Approved';
                break;
            case 3:
                status = 'Declined';
                break;
            case 4:
                status = 'Suspended';
                break;
        }
        return status;
    }



    buildColumns = (team_nicknames_optionlist, clientNickNameOptionList) => {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 1,
                width: '3%',
                defaultSortOrder: 'DESC',
                sorter: (a, b) => a.id - b.id,
                ...this.props.getColumnSearchProps({
                    dataIndex: 'id',
                    label: 'ID',
                    render: (text, record) => {
                        return <>
                            <Tag color={this.getTagColor(record.account_status)}>
                                <span className={this.props.classes.displayBlock} style={{ textAlign: 'center' }}>ID: {text}</span>
                            </Tag>
                        </>
                    }
                })
            },
            {
                title: 'Created',
                dataIndex: 'record_created',
                key: 2,
                ...this.props.getColumnSearchProps({
                    dataIndex: 'record_created',
                    label: 'Created',
                    filterInputType: 'DATE',
                    render: (text, record) => {
                        return <>
                            {text}
                        </>
                    }
                })
            },
            {
                title: 'Client',
                dataIndex: 'clientNickname',
                key: 3,
                sorter: (a, b) => (a.clientNickname || '').localeCompare(b.clientNickname, undefined, { sensitivity: 'base' }),
                ...this.props.getColumnSearchProps({
                    dataIndex: 'clientNickname',
                    label: 'Client',
                    render: (text, record) => {
                        if (record.account_type === 1) {
                            return <a href={`/clients/edit/${record.id}`}>
                                <strong>
                                    {record.first_name} {record.last_name}
                                </strong>
                            </a>
                        }
                        if (record.account_type === 2) {
                            return <>
                                <a href={`/clients/edit/${record.id}`}>
                                    <strong>
                                        {record.nickname}
                                    </strong>
                                </a>
                                <br />
                                {record.first_name} {record.last_name}</>
                        }
                    },
                })
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 4,
                ...this.props.getColumnSearchProps({
                    label: 'Email',
                    dataIndex: 'email',
                    render: (text, record) => {
                        return <a href={`mailto: ${text}`}>{text}</a>
                    }
                })

            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 5,
                width: '20%',
            },
            {
                title: 'Telephone',
                dataIndex: 'telephone_mobile',
                key: 6,
                ...this.props.getColumnSearchProps({
                    label: 'Telephone',
                    dataIndex: 'telephone_mobile',
                    render: (text, record) => <a href={`tel: ${text}`}>{text}</a>
                })
            },
            {
                title: 'Team',
                dataIndex: 'team',
                width: '200px',
                key: 7,
                filters: team_nicknames_optionlist,
                onFilter: (value, record) => record.team && record.team.startsWith(value)
            },
            {
                title: 'Risk Total Score',
                dataIndex: 'riskScore',
                width: '200px',
                key: 10,
                filters: this.state.riskScoreFilterOptions,
                ...this.props.getColumnSearchProps({
                    filterInputType: 'SELECT',
                    dataIndex: 'riskScore',
                    onFilter: (value, record) => {
                            const riskRating = this.getRiskRating(record.riskScore);
                            return riskRating === value;
                    },
                    render: (text, record) => <Tag color={this.getRiskColor(text)}>
                        {this.state.riskScoreFilterOptions.find(item => item.value === this.getRiskRating(text)).text}
                    </Tag>
                })
            },
            {
                title: 'Status',
                dataIndex: 'client_status_nickname',
                filters: clientNickNameOptionList,

                key: 8,
                ...this.props.getColumnSearchProps({
                    filterInputType: 'SELECT',
                    dataIndex: 'client_status_nickname',
                    onFilter: (value, record) => record.client_status_nickname && record.client_status_nickname.startsWith(value),
                    render: (text, record) => {
                        return <Tag color={this.getTagColor(record.account_status)}>
                            <span className={this.props.classes.displayBlock} style={{ textAlign: 'center' }}>{text}</span>
                        </Tag>
                    }
                })
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 9
            }

        ];
        return columns;
    }

    fetchClientList = () => {
        this.setState({ dataLoading: true });
        if (!this.props.app_state.current_staff) {
            this.setState({ dataLoading: false });
            this.setState({ clientList: [] });
        } else {
            const staffID = this.props.app_state.current_staff.id
            ClientAPI.fetchClients(staffID)
                .then(response => {
                    this.setState({ dataLoading: false });
                    this.setState({
                        clientList: response,
                        clientListAll: response,
                        clientOptions: response.map(prop => ({
                            label: `${prop.first_name || ''} ${prop.last_name || ''} ${prop.business_company_name || ''} ${prop.business_trading_name || ''} ${prop.business_trust_name || ''}`,
                            value: prop.id
                        }))
                    })
                })
                .catch(error => {
                    this.setState({ dataLoading: false });
                    console.log(error);
                });
        }
    }

    handle_delete_dialog_click = item_to_delete => {
        this.setState({ dialog_delete_confirm: false });
        API.put('clients', `/update/id/${item_to_delete}`, { body: { deleted: true } })
            .then(response => {
                this.fetchClientList();
            })
            .catch(error => {
                console.log(error);
                this.fetchClientList();
            });
    };

    handle_delete_dialog_cancel = () => {
        this.setState({
            dialog_delete_confirm: false,
            dialog_delete_item: 0,
            dialog_delete_item_name: ''
        });
    };

    buildTableData() {
        const super_admin = this.props.app_state.current_staff_super_admin;

        const client_list_length = (this.state.clientList || []).length;

        let filteredClientList = this.state.clientList || [];
        if (this.props.status) {
            filteredClientList = filteredClientList.filter(
                client => client.account_status == this.props.status
            );
        }

        console.log(client_list_length);

        if (client_list_length > 0) {
            return filteredClientList.map((prop, key) => {
                if (prop.email === 'responsemail.shashank@gmail.com') {
                    console.log(prop);
                }
                return {
                    id: prop.id,
                    record_created: prop.record_created_datetime != null ? moment(prop.record_created_datetime).format('DD/MM/YYYY') : '-',
                    aws_cognito_id: prop.aws_cognito_id,
                    account_status: prop.account_status,
                    account_type: prop.account_type,
                    first_name: prop.first_name,
                    last_name: prop.last_name,
                    first_and_last_name: `${prop.first_name} ${prop.last_name}`,
                    nickname: prop.nickname,
                    clientNickname: `${prop.first_name} ${prop.last_name} ${prop.business_company_name} ${prop.business_trading_name} ${prop.business_trust_name} `,
                    business_trading_name: prop.business_trading_name,
                    business_company_name: prop.business_company_name,
                    business_trust_name: prop.business_trust_name,
                    address: (
                        <span>
                            {prop.residential_street_line_1 || ''}
                            {prop.residential_street_line_2 ? (
                                <React.Fragment>, {prop.residential_street_line_2}</React.Fragment>
                            ) : (
                                ''
                            )}
                            <br />
                            {`${prop.residential_street_suburb || ''} ${(prop.residential_street_state || '').toUpperCase()} ${prop.residential_street_postcode || ''} ${prop.country_name || ''}`}
                        </span>
                    ),
                    country: prop.country_name,
                    verified: prop.verified,
                    email: prop.email,
                    telephone_mobile: prop.telephone_mobile,
                    residential_street_line_1: prop.residential_street_line_1,
                    residential_street_line_2: prop.residential_street_line_2,
                    residential_street_suburb: prop.residential_street_suburb,
                    residential_street_country: prop.residential_street_country,
                    team: prop.team_nickname,
                    client_status_nickname: prop.client_status_nickname,
                    riskScore: prop.riskScore,
                    actions: (
                        <Flex justify={'space-around'}>
                            <Tooltip title={'Edit Client'}>
                                <Button size={'small'} style={{ marginRight: '5px' }} onClick={() => {
                                    this.props.history.push(`/clients/edit/${prop.id}`);
                                }} type={'primary'} className={'btn btn-primary'}><span className={'fa fa-eye'}></span></Button>
                            </Tooltip>
                            <Tooltip title={'Create Transfer'}>
                                <Button size={'small'} style={{ marginRight: '5px' }} onClick={() => {
                                    this.props.history.push(`/transfers/new/?client_id=${prop.id}`)
                                }} type={'primary'} className={'btn btn-primary'}><span className={'fa fa-file'}></span></Button>
                            </Tooltip>
                            <Tooltip title={'Delete Client'}>
                                {super_admin && (
                                    <Popconfirm
                                        title="Delete the Client ?"
                                        description={`Are you sure to delete this client ${prop.first_name} ${prop.last_name}?`}
                                        onConfirm={() => {
                                            const data = this.state.clientList;
                                            // eslint-disable-next-line no-unused-vars
                                            data.find((o, i) => {
                                                if (o.id === prop.id) {
                                                    this.setState({
                                                        dialog_delete_confirm: true,
                                                        dialog_delete_item: prop.id,
                                                        dialog_delete_item_name: `${prop.first_name} ${prop.last_name}`
                                                    });

                                                    return true;
                                                }
                                                return false;
                                            });
                                            this.setState({ data: data });
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type={'primary'} size={'small'} danger><i className={'fa fa-trash'}></i></Button>
                                    </Popconfirm>)}
                            </Tooltip>
                        </Flex>

                    )
                };
            });
        }
    }

    getFilter = (filter, onChange) => {
        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <AiOutlineSearch />
                    <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
                </div>
            </React.Fragment>
        );
    };

    onClientDropDownChange = (value) => {
        if (value == null) {
            this.setState({ clientList: this.state.clientListAll })
        } else {
            const data = this.state.clientListAll.filter(item => item.id === value)
            this.setState({ clientList: data });
        }
    }

    render() {
        const { classes } = this.props;
        if (!this.props.app_state.current_staff) return null;
        const team_nicknames = (this.state.clientList || []).map((prop, key) => {
            return prop.team_nickname;
        });
        const team_nicknames_unique = [...new Set(team_nicknames)];
        const team_nicknames_optionlist = team_nicknames_unique.map((prop, key) => {
            return {
                text: prop,
                value: prop
            }
        });

        // const account_status_nickname = this.props.clients.client_list.map((prop, key) => {
        const account_status_nickname = (this.state.clientList || []).map((prop, key) => {
            return prop.client_status_nickname;
        });
        const status_nicknames_unique = [...new Set(account_status_nickname)];
        const status_nicknames_optionlist = status_nicknames_unique.map((prop, key) => {
            return {
                text: prop == null ? '-' : prop,
                value: prop
            }
        });

        const columns = this.buildColumns(team_nicknames_optionlist, status_nicknames_optionlist);

        return (
            <React.Fragment>

                <Flex justify={'space-between'} style={{ marginBottom: '10px' }}>
                    <FormItem label={'Search Client'}>
                        <Select
                            showSearch
                            onClear={() => this.setState({clientList: this.state.clientListAll})}
                            style={{ width: '300px' }}
                            allowClear
                            placeholder='Search Client'
                            onChange={e => this.onClientDropDownChange(e)}
                            filterOption={(input, option) =>
                                (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.clientOptions}
                        ></Select>
                    </FormItem>
                    <div>
                        <AntButton type={"primary"} onClick={() => this.props.history.push('/clients/new')}>
                            Create New
                        </AntButton>
                        <Divider type='vertical' />
                        <AntButton type="primary" danger={true} onClick={() => this.fetchClientList()}>
                            Refresh
                        </AntButton>
                    </div>
                </Flex>

                <Table
                    dataSource={this.buildTableData()}
                    columns={columns}
                    virtual={true}
                    loading={this.state.dataLoading}
                />

                <Dialog
                    open={this.state.dialog_delete_confirm}
                    onClose={this.handleClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            Are you sure that you wish to <strong>delete</strong> staff member record{' '}
                            {this.state.dialog_delete_item_name}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handle_delete_dialog_cancel} color='primary'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => this.handle_delete_dialog_click(this.state.dialog_delete_item)}
                            color='danger'
                            autoFocus
                        >
                            Delete Record
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

// export default withRouter(withStyles(styles)(ClientList));

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        clients: state.clients
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchClientList: () => {
            dispatch(fetchClientList());
        }
    };
};

const ClientListContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(ListTable(ClientList))));

export default ClientListContainer;
