import React from 'react';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Button from '../../../components/CustomButtons/Button';
import {FormattedMessage} from 'react-intl';
import TeamHoldingAccount from '../../../components/HoldingAccounts/TeamHoldingAccountList';

const idententificationListStyle = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const TeamHoldingAccountList = props => {
  const {classes} = props;
  return (
    <React.Fragment>
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <PermIdentity />
          </CardIcon>
          <div className={classes.flexEnd}>
            <h4 className={classes.cardIconTitle}>Team Holding Account List</h4>
            <div>
              <Button round color='primary' onClick={() => this.props.history.push('/holding-accounts/list')}>
                <FormattedMessage id='newIdentification.cardItem1.showAll' defaultMessage={'Show All'} />
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <TeamHoldingAccount />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withStyles(idententificationListStyle)(TeamHoldingAccountList);
