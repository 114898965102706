import React, {useEffect, useState} from 'react';
import {AiOutlineSearch} from 'react-icons/ai';
import {API} from 'aws-amplify';
import {Box} from '@material-ui/core';
import Button from '../../CustomButtons/Button';
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';
import Email from '@material-ui/icons/Email';
import NewClientContactsDialog from '../../../features/Clients/NewClientContactsDialog';
import ListTable from "../../ListTable/ListTable";
import {Table} from "antd";

export const Contacts = ListTable(({client_detail_id, getColumnSearchProps, history, isBeneficialOwner }) => {
    const [loading, setLoading] = useState(false);
    const [clientContactsList, setClientContactsList] = useState([]);
    const [newClientContactsDialogOpen, setNewClientContactsDialogOpen] = useState(false);
    const openNewClientContactsDialog = () => {
        setNewClientContactsDialogOpen(true);
    };

    const fetchClientContactsListByClientId = clientId => {
        if (!clientId) {
            return;
        }
        if (!loading) {
            setLoading(true);
        }
        const request = !isBeneficialOwner
            ? API.get('clientcontacts', `/list-by-clientid/${clientId}`)
            : API.post('clientcontacts', `/list-by-clientid/${clientId}`, {body: {isBeneficialOwner}});
            request.then(response => setClientContactsList(response))
            .catch(error => {console.log(error)})
            .finally(() => setLoading(false));
    };

    const getFilter = (filter, onChange) => {
        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <AiOutlineSearch/>
                    <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''}/>
                </div>
            </React.Fragment>
        );
    };

    const handleClientContactsDelete = clientContactsId => {
        if (!loading) {
            setLoading(true);
        }
        API.put('clientcontacts', `/id/${clientContactsId}`, {body: {deleted: true}})
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
                // API.put('clientcontacts', `/id/${clientContactsId}`, {body: {deleted: true}})
                //   .then(response => {
                //     console.log(response);
                //   })
                //   .catch(error => {
                //     console.log(error);
                //   });
            })
            .finally(() => {
                fetchClientContactsListByClientId(client_detail_id);
            });
    };

    const handleClientContactsSendEmailToggle = (clientContactsId, currentState) => {
        if (!loading) {
            setLoading(true);
        }
        API.put('clientcontacts', `/id/${clientContactsId}`, {body: {sendEmail: !currentState}})
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
                // API.put('clientcontacts', `/id/${clientContactsId}`, {body: {deleted: true}})
                //   .then(response => {
                //     console.log(response);
                //   })
                //   .catch(error => {
                //     console.log(error);
                //   });
            })
            .finally(() => {
                fetchClientContactsListByClientId(client_detail_id);
            });
    };

    const buildTableData = data => {
        if (!data) {
            return [];
        }

        return data.map(item => ({
            ...item,
            sendEmail: (
                <div>
                    {item.sendEmail ? (<span>YES</span>) : (<span>NO</span>)}
                    <Button
                        justIcon
                        round
                        simple
                        onClick={() => handleClientContactsSendEmailToggle(item.id, item.sendEmail)}
                        color='info'
                    >
                        <Email/>
                    </Button>
                </div>
            ),
            actions: (
                // <div className='actions-right'>
                <div>
                    <Button
                        justIcon
                        round
                        simple
                        onClick={() => history.push(`/clients/contacts/view/${item.id}?tab=5`)}
                        color='warning'
                    >
                        <Dvr/>
                    </Button>
                    <Button
                        justIcon
                        round
                        simple
                        onClick={() => handleClientContactsDelete(item.id)}
                        color='danger'
                        className='remove'
                    >
                        <Close/>
                    </Button>
                </div>
            )
        }));
    };

    useEffect(() => {
        fetchClientContactsListByClientId(client_detail_id);
        // eslint-disable-next-line
    }, [client_detail_id]);

    return (
        <React.Fragment>
            <Box style={{padding: '0 16px'}}>
                <Box style={{textAlign: 'right', marginBottom: '8px'}}>
                    <Button
                        round color='info'
                        onClick={() => fetchClientContactsListByClientId(client_detail_id)}
                    >Refresh
                    </Button>

                    <Button round color='info' onClick={openNewClientContactsDialog}>
                        Create New Contact
                    </Button>
                </Box>
                <Table
                    loading={loading}
                    dataSource={buildTableData(clientContactsList)}
                    columns={[
                        {
                            title: 'Contact ID',
                            dataIndex: 'id',
                            ...getColumnSearchProps({
                                label: 'Contact ID',
                                dataIndex: 'id',
                                render: (text, record) => text
                            })
                        },
                        {
                            title: 'First Name',
                            dataIndex: 'firstName',
                            ...getColumnSearchProps({
                                label: 'First Name',
                                dataIndex: 'firstName',
                                render: (text, record) => text
                            })
                        },
                        {
                            title: 'Last Name',
                            dataIndex: 'lastName',
                            ...getColumnSearchProps({
                                label: 'Last Name',
                                dataIndex: 'lastName',
                                render: (text, record) => text
                            })
                        },
                        {
                            title: 'Position',
                            dataIndex: 'position',
                            ...getColumnSearchProps({
                                label: 'Position',
                                dataIndex: 'position',
                                render: (text, record) => text
                            })
                        },
                        {
                            title: 'Beneficial Owner',
                            dataIndex: 'is_ben_owner',
                            ...getColumnSearchProps({
                                label: 'Beneficial Owner',
                                dataIndex: 'is_ben_owner',
                                render: (text, record) => Number(text) === 1 ? 'Yes' : 'No'
                            })
                        },
                        {
                            title: 'Email',
                            dataIndex: 'email',
                            ...getColumnSearchProps({
                                label: 'Email',
                                dataIndex: 'email',
                                render: (text, record) => text
                            })
                        },
                        {
                            title: 'Send Emails',
                            dataIndex: 'sendEmail',
                            ...getColumnSearchProps({
                                label: 'Send Emails',
                                dataIndex: 'sendEmail',
                                render: (text, record) => text
                            })
                        },
                        {
                            title: 'Actions',
                            dataIndex: 'actions',
                            sortable: false,
                            filterable: false,
                            render: (text, record) => text
                        }
                    ]}
                />
            </Box>
            <NewClientContactsDialog
                defaultClientId={client_detail_id}
                open={newClientContactsDialogOpen}
                handleClose={() => {
                    setNewClientContactsDialogOpen(false)
                    fetchClientContactsListByClientId(client_detail_id)
                }}
            />
        </React.Fragment>
    );
});
