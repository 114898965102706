import {
  BENEFICIARIES_SELECT_SET_ID,
  IDENTIFICATION_SELECT_UI,
  IDENTIFICATION_FETCH_IDENTIFICATION_REQUEST,
  IDENTIFICATION_FETCH_IDENTIFICATION_SUCCESS,
  IDENTIFICATION_FETCH_BENEFICIARY_LIST_REQUEST,
  IDENTIFICATION_FETCH_IDENTIFICATION_LIST_SUCCESS,
  IDENTIFICATION_START_NEW_IDENTIFICATION_CREATION,
  // BENEFICIARIES_UPDATE_NEW_BENEFICIARY_CREATION,
  IDENTIFICATION_UPDATE_NEW_IDENTIFICATION_CREATION,
  // IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_START,
  IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_SUCCESS
} from '../actions/identification.js';
// import { CLIENTS_START_NEW_CLIENT_DATABASE_CREATE } from "../actions/clients";

const initialState = {
  identification_current_ui: 'list',
  is_fetching: false,
  // did_invalidate: false,
  identification_list: [],
  identification_list_lastUpdated: [],
  identification_record_detail_fetching: false,
  identification_record_detail_loaded: false,
  identification_record_detail_id: null,
  new_identification_record_data: null,
  // new_client_type: null,
  new_identification_record_submitted: false,
  new_identification_record_created: false
  // client_detail: null,
  // ui_show_create_new_staff_screen: false,
  // ui_show_edit_staff_screen: true,
  // staff_create_new_in_process: false,
  // staff_create_new_awaiting_confirmation: false
};

function clients(state = initialState, action) {
  switch (action.type) {
    // case STAFF_SELECT_STAFF_MEMBER:
    //   // console.log(action);
    //   return Object.assign({}, state, {
    //     staff_detail_id: action.staff_id
    //   });

    case IDENTIFICATION_FETCH_BENEFICIARY_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
        // console.log(action);
      });

    case IDENTIFICATION_FETCH_IDENTIFICATION_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        identification_list: action.identification_list,
        identification_list_lastUpdated: action.receivedAt
      });

    // case STAFF_CREATE_NEW_SHOW_UI:
    //   return Object.assign({}, state, {
    //     // ui_show_create_new_staff_screen: action.value,
    //     // ui_show_edit_staff_screen: false,
    //     staff_current_ui: "list"
    //   });

    // case STAFF_CREATE_NEW_START:
    //   return Object.assign({}, state, {
    //     staff_create_new_in_process: true,
    //     staff_create_new_awaiting_confirmation: true
    //   });
    //
    // case STAFF_CREATE_NEW_CANCEL:
    //   return Object.assign({}, state, {
    //     staff_create_new_in_process: false,
    //     staff_create_new_awaiting_confirmation: false,
    //     staff_current_ui: "list"
    //   });
    //
    // case STAFF_CREATE_NEW_COMPLETE:
    //   return Object.assign({}, state, {
    //     staff_create_new_in_process: false,
    //     staff_create_new_awaiting_confirmation: false,
    //     // ui_show_create_new_staff_screen: false,
    //     staff_current_ui: "list"
    //   });
    //
    case IDENTIFICATION_SELECT_UI:
      return Object.assign({}, state, {
        identification_current_ui: action.value
      });
    //
    case BENEFICIARIES_SELECT_SET_ID:
      return Object.assign({}, state, {
        beneficiary_detail_id: action.value
      });

    case IDENTIFICATION_FETCH_IDENTIFICATION_REQUEST:
      return Object.assign({}, state, {
        // staff_detail: null,
        // staff_detail_id: action.staff_id,
        identification_record_detail_fetching: true,
        identification_record_detail_loaded: false
        // client_detail: null,
        // staff_detail_loaded: false
      });
    //
    case IDENTIFICATION_FETCH_IDENTIFICATION_SUCCESS:
      return Object.assign({}, state, {
        // staff_detail: action.staff_detail,
        identification_record_detail_fetching: false,
        identification_record_detail_loaded: true
        // staff_detail_loaded: true
      });

    case IDENTIFICATION_START_NEW_IDENTIFICATION_CREATION:
      return Object.assign({}, state, {
        // staff_detail: action.staff_detail,
        new_identification_record_data: {}
        // new_beneficiary_type: "customer_type_personal",
        // new_client_submitted: false,
        // new_client_created: false
        // client_detail_loaded: true
        // staff_detail_loaded: true
      });
    // case CLIENTS_UPDATE_NEW_CLIENT_CREATION_CLIENT_TYPE:
    //   return Object.assign({}, state, {
    //     // staff_detail: action.staff_detail,
    //     // new_client_data: null,
    //     new_client_type: action.value
    //     // client_detail_loaded: true
    //     // staff_detail_loaded: true
    //   });
    // case CLIENTS_UPDATE_NEW_CLIENT_CREATION_EMAIL:
    //   return Object.assign({}, state, {
    //     // staff_detail: action.staff_detail,
    //     new_client_data: Object.assign({}, state.new_client_data, {
    //       email: action.value
    //     }),
    //     // new_client_type: action.value
    //     // client_detail_loaded: true
    //     // staff_detail_loaded: true
    //   });
    // case CLIENTS_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME:
    //   return Object.assign({}, state, {
    //     new_client_data: Object.assign({}, state.new_client_data, {
    //       first_name: action.value
    //     }),
    //   });
    // case CLIENTS_UPDATE_NEW_CLIENT_CREATION_LASTNAME:
    //   return Object.assign({}, state, {
    //     new_client_data: Object.assign({}, state.new_client_data, {
    //       last_name: action.value
    //     }),
    //   });
    case IDENTIFICATION_UPDATE_NEW_IDENTIFICATION_CREATION:
      return Object.assign({}, state, {
        new_identification_record_data: Object.assign({}, state.new_identification_record_data, {
          [action.value.key]: action.value.value
        })
      });
    // case CLIENTS_NEW_CLIENT_DATABASE_CREATE_REQUEST:
    //   return Object.assign({}, state, {
    //     new_client_submitted: true,
    //     new_client_created: false
    //   });

    case IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_SUCCESS:
      return Object.assign({}, state, {
        new_identification_record_submitted: false,
        new_identification_record_created: true,
        identification_record_detail_id: action.value
      });

    default:
      return state;
  }
}

export default clients;
