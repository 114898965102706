import { API } from 'aws-amplify'

export const BeneficiaryAPI = {
    saveBeneficiary: async (payload) => await API.post('beneficiaries', `/create/in-database`, { body: { ...payload } }),
    saveBeneficiaryDocument: async (payload) => await API.post('beneficiaries', `/create/upload-single-document`, { body: { ...payload } }),
    removeBeneficiary: (recordId) => API.get('beneficiaries', `/delete/beneficiary/${recordId}`, {}),
    fetchBeneficiaryList: (staffID) =>  API.get('beneficiaries', `/list/staff/${staffID}`, {}),
    fetchBeneficiaryByTeamId: (teamId) => API.get('beneficiaries', `/get/by-client-team/${teamId}`, {}),
    toggleBeneficiaryActive: (beneficiaryId, activeId) => API.get('beneficiaries', `/beneficiary/${beneficiaryId}/active/${activeId}`, {}),
    fetchBeneficiaryById: (id) => API.get('beneficiaries', `/get/id/${id}`, {}),
    fetchBeneficiaryDocument: (beneficiaryId) => API.get('beneficiary-documents', `/list-by-beneficiary-id/${beneficiaryId}`, {}),
    fetchBeneficiaryByClientId: (clientId) => API.get('beneficiaries',`/get/by_client_id/${clientId}`, {})
}

export const BeneficiaryUtils = {
    initialState: {
        bene_type: null,
        show_iban: false,
        show_bsb: false,
        show_sort_code: false,
        show_swift_code: true,
        show_aba: false,
        show_account_number: true,
        show_institution_number: false,
        show_transit_code: false,
        show_bank_code: false,
        client_list: [],
        team_list: [],
        country_list: [],
        currencies_list: [],
        isLoading: false,
        activeClient: null
    },
    setFieldVisibility: (key, value, state) => {
        const newState = {...state};
        if (key === 'bank_address_country') {
            newState.country_list.forEach(item => {
                   if (item.id === value) {
                       newState.show_swift_code = true;
                       newState.show_account_number = true;
                       newState.show_iban = item.use_iban === 1;
                       newState.show_bsb = item.use_bsb === 1;
                       newState.show_aba = item.use_aba === 1;
                       newState.show_sort_code = item.use_sort_code === 1;
                       newState.show_account_number = item.use_account_number === 1;
                       newState.show_institution_number = item.use_institution_number === 1;
                       newState.show_transit_code= item.use_transit_code === 1;
                       newState.show_bank_code = item.use_bank_code === 1;
                   }
                });
        }
        return newState;
    },
    prepareDataBody: (response) => {
        console.log(response);
        return response;
    }
}

export const validationMessages = {
    required:(label) => `${label} is required`,
    validEmail:(label) => `${label} is a valid email`
};

export const BeneficiaryValidationConstants = {
    CLIENT_TYPE: [
        { required: false, message: validationMessages.required('Client type')},
    ],
    TEAM: [
        {required: false, message: validationMessages.required('Team')}
    ],
    CLIENT: [
        {required: false, message: validationMessages.required('Client')}
    ],
    CURRENCY: [
        {required: true, message: validationMessages.required("Currency")}
    ],
    BANK_COUNTRY: [
        {required: true, message: validationMessages.required('Bank Country')}
    ],
    BENEFICIARY_NAME: [
        {required: true, message: validationMessages.required('Beneficiary Name')}
    ],
    COUNTRY_NAME: [
        {required: true, message: validationMessages.required('Beneficiary Country')}
    ],
    ADDRESS_LINE_1: [
        {required: false, message: validationMessages.required('Address Line 1')}
    ],
    SUBURB: [
        {required: false, message: validationMessages.required('Suburb')}
    ],
    STATE: [
        {required: false, message: validationMessages.required('State')}
    ],
    POST_CODE: [
        {required: false, message: validationMessages.required('Postcode')}
    ],
    BANK_NAME: [
        {required: true, message: validationMessages.required('Bank name')}
    ],
    SWIFT_CODE: [
        {required: false, message: validationMessages.required('Swift code')}
    ],
    BANK_ADDRESS: [
        {required: false, message: validationMessages.required('Bank Address')}
    ],
    BANK_POST_CODE: [
        {required: false, message: validationMessages.required('Bank post code')}
    ],
    DATA: [
        {required: true, message: validationMessages.required('Data post code')}
    ]
}