import React, {useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles({
  list: {
    width: 380,
    color: 'white',
    padding: '8%'
  },
  fullList: {
    width: 'auto',
    color: 'white'
  },
  paper: {backgroundColor: '#6200EE'},
  close: {
    float: 'right',
    cursor: 'pointer'
  }
});

const CustomDrawerComponent = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  const [content, setContent] = React.useState(<div />);

  useEffect(() => {
    setContent(props.content);
  }, [props.content]);
  useEffect(() => {
    props.toggleDraw(toggleDrawer('right', true));
  }, []);

  const toggleDrawer = (anchor, open) => event => {
    setState({
      ...state,
      [anchor]: open
    });
  };

  const list = anchor => (
    <div
      className={clsx(classes.list, {[classes.fullList]: anchor === 'top' || anchor === 'bottom'})}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <ClearIcon className={classes.close} />
      {content}
    </div>
  );

  return (
    <div>
      {['right'].map(anchor => (
        <React.Fragment key={anchor}>
          <Drawer
            classes={{paper: classes.paper}}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export {CustomDrawerComponent};
