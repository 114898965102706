import React from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {AiOutlineSearch} from 'react-icons/ai';
// import IdentificationList from "./IdentificationList";
import {
  fetchIdentificationList,
  fetchIdentificationListAll
  // setBeneficiarySelectId
} from '../../redux/actions/identification';

import Dvr from '@material-ui/icons/Dvr';

import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import matchSorter from 'match-sorter';
import { Button, Table } from 'antd'
import ListTable from '../ListTable/ListTable'

var moment = require('moment');

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - IdentificationList
 * ----------------------------------------------------------------------------
 */
class IdentificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_list: [],
      columns: []
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const {fetchIdentificationList, fetchIdentificationListAll} = this.props;
    // console.log(this.props);
    // eslint-disable-next-line react/prop-types
    if (this.props.client_id) {
      fetchIdentificationList(this.props.client_id);
    } else {
      fetchIdentificationListAll();
    }
    // const columns = this.buildTableColumns();
    // this.setState({columns: columns});
  }

  buildTableColumns() {
      return [
          {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              sorter: { compare: (a,b) => a.id - b.id },
              defaultSortOrder: 'descend',
              ...this.props.getColumnSearchProps({
                  dataIndex: 'id',
                  label: 'id',
                  render: (text, record) => {
                      return (
                          <>
                              <span>{record.id}</span>
                          </>
                      );
                  }
              })
          },
          !this.props.client_id ? {
              title: 'Client',
              dataIndex: 'client_name',
              key: 'client_name',
              sorter: { compare: (a,b) => a.nickname - b.nickname },
              ...this.props.getColumnSearchProps({
                  dataIndex: 'client_name',
                  label: 'client_name',
                  render: (text, record) => {
                      return (
                          <>
                              <span>{record.client_name}</span>
                          </>
                      );
                  }
              })
          } : {},
          // {
          //     title: 'Description',
          //     dataIndex: 'nickname',
          //     key: 'nickname',
          //     ...this.props.getColumnSearchProps({
          //         dataIndex: 'nickname',
          //         label: 'nickname',
          //         render: (text, record) => {
          //             return (
          //                 <>
          //                     <span>{record.nickname}</span>
          //                 </>
          //             );
          //         }
          //     })
          // },
          {
              title: 'Category',
              dataIndex: 'document_category',
              key: 'document_category',
              filters: [
                  {text: 'Passport', value: 'Passport'},
                  {text: 'Drivers License', value: 'Drivers Licence'},
                  {text: 'Medicare', value: 'Medicare'},
                  {text: 'Visa', value: 'Visa'},
                  {text: 'Birth Record', value: 'Birth Record'},
                  {text: 'Marriage Record', value: 'Marriage Record'},
              ],
              onFilter: (value, record) => record.document_category != null ? record.document_category.indexOf(value) === 0 : false,
          },
          {
              title: 'Document Number',
              dataIndex: 'document_number',
              key: 'document_number',
              ...this.props.getColumnSearchProps({
                  dataIndex: 'document_number',
                  label: 'document_number',
                  render: (text, record) => {
                      return (
                          <>
                              <span>{record.document_number}</span>
                          </>
                      );
                  }
              })
          },
          {
              title: 'Expiry',
              dataIndex: 'document_expiry',
              key: 'document_expiry',
              ...this.props.getColumnSearchProps({
                  dataIndex: 'document_expiry',
                  label: 'document_expiry',
                  filterInputType: 'DATE',
                  render: (text, record) => {
                      return (
                          <>
                              <span>{ record.document_expiry === 'Invalid date' ? '-' : record.document_expiry }</span>
                          </>
                      );
                  }
              })
          },
          {
              title: 'Status',
              dataIndex: 'id_status',
              key: 'id_status',
              filters: [
                  {text: 'Submitted', value: 'Submitted'},
                  {text: 'Draft', value: 'Draft'},
              ],
              onFilter: (value, record) => record.id_status != null ? record.id_status.indexOf(value) === 0 : false,
          },
          {
              title: 'Action',
              dataIndex: 'actions',
              key: 'actions'
          }
      ]
  }

  buildTableData() {
    var identification_list_length = this.props.identification.identification_list.length;
    if (identification_list_length > 0) {
      // eslint-disable-next-line no-unused-vars
      return this.props.identification.identification_list.map((prop, key) => {
        return {
          id: prop.id,
          nickname: prop.nickname,
          // file_id: prop.file_id,
          client_name: prop.client_nickname,
          document_category: prop.document_category,
          document_number: prop.document_number,
          document_expiry: prop.document_expiry != null ? moment(prop.document_expiry).format('DD/MM/YYYY') : null,
          id_status: prop.id_status,
          actions: (
            <div className='actions-right'>
              <Button
                  type={'primary'}
                onClick={() => {
                  this.props.history.push(`/identification/edit/${prop.id}`);
                }} className='edit'>
                <span><i className={'fa fa-pencil-alt'}></i>{' '} Edit</span>
              </Button>
            </div>
          )
        };
      });
    }
  }

  getFilter = (filter, onChange) => {
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AiOutlineSearch />
          <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
        </div>
      </React.Fragment>
    );
  };

  render() {
    var client_name = this.props.identification.identification_list
      .filter(u => u.client_nickname != null)
      .map((prop, key) => {
        return prop.client_nickname;
      });
    var client_name_unique = [...new Set(client_name)];
    // eslint-disable-next-line no-unused-vars
    var client_name_optionlist = client_name_unique.map((prop, key) => {
      // eslint-disable-next-line react/jsx-key
      return (
        <option key={key} value={prop}>
          {prop}
        </option>
      );
    });

    var category_name = this.props.identification.identification_list
      .filter(u => u.document_category != null)
      .map((prop, key) => {
        return prop.document_category;
      });
    var category_name_unique = [...new Set(category_name)];
    // eslint-disable-next-line no-unused-vars
    var category_name_optionlist = category_name_unique.map((prop, key) => {
      // eslint-disable-next-line react/jsx-key
      return (
        <option key={key} value={prop}>
          {prop}
        </option>
      );
    });

    var status_name = this.props.identification.identification_list
      .filter(u => u.id_status != null)
      .map((prop, key) => {
        return prop.id_status;
      });
    var status_name_unique = [...new Set(status_name)];
    // eslint-disable-next-line no-unused-vars
    var status_name_optionlist = status_name_unique.map((prop, key) => {
      // eslint-disable-next-line react/jsx-key
      return (
        <option key={key} value={prop}>
          {prop}
        </option>
      );
    });
    return (
      <Table dataSource={this.buildTableData()} columns={this.buildTableColumns()}></Table>
    );
  }
}

// export default withRouter(withStyles(styles)(IdentificationList));
const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    identification: state.identification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchIdentificationList: client_id => {
      dispatch(fetchIdentificationList(client_id));
    },
    fetchIdentificationListAll: () => {
      dispatch(fetchIdentificationListAll());
    }
    // setBeneficiarySelectId: (id) => {
    //   dispatch(setBeneficiarySelectId(id))
    // }
  };
};

const IdentificationListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(ListTable(IdentificationList))));

export default IdentificationListContainer;
