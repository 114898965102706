import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon";
import PermIdentity from "@material-ui/icons/PermIdentity";
import CardBody from "../Card/CardBody";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import withStyles from "@material-ui/core/styles/withStyles";
import {cardTitle} from "../../assets/jss/material-dashboard-pro-react";
import {Button, DatePicker, Descriptions, Divider, Flex, Input, message} from "antd";
import {TransferAPI} from "./TransfersUtil";
import {ClientAPI} from "../Clients/ClientUtil";
import moment from "moment/moment";
import dayjs from "dayjs";
import {API} from "aws-amplify";


const styles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center'
    },
    description: {
        color: '#999999'
    },
    updateProfileButton: {
        float: 'right'
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none'
    },

    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    spanBlock: {
        display: 'block'
    }
}
const TransferDetailPage = (props) => {

    const [state, setState] = useState({});

    const initialize = async () => {
        await TransferAPI.fetchPendingTransferDetailById(props.match.params.id).then(async transferRes => {
            // console.log(transferRes)
            setState(prev => setState({
                ...prev, 
                transferDetail: transferRes.transfer_pending,
                payouts: transferRes.payouts,
                expiryDate: transferRes.transfer_pending.expiryDate,
                amount_to: transferRes.transfer_pending.amount_to
            }));
            await ClientAPI.fetchClientById(transferRes.transfer_pending.client_id).then(clientRes => {
                setState(prev => setState({...prev, client: clientRes}))
            })
        }).catch(err => {
            console.log(err);
        });
    }

    const onChangeDetail = (event, field) => {
        const body = field === 'expiryDate' ? dayjs(event).startOf('day').format('YYYY-MM-DD'): event.target.value;
        setState(prev => ({...prev, [field]: body}));
    }

    useEffect(() => {
        initialize().then(res => message.success("Data Loaded"));
    }, [0]);

    console.log(state)

    return <div>
        <Card>
            <CardHeader color='rose' icon>
                <CardIcon color='rose'>
                    <PermIdentity/>
                </CardIcon>
                <div className={props.classes.flexEnd}>
                    <h4 className={props.classes.cardIconTitle}>
                        Payment Transfer Detail
                    </h4>
                </div>
            </CardHeader>
            <CardBody>
                <GridContainer>
                {state.client && <Descriptions title={'Client Detail'} layout={'horizontal'} column={1} bordered={true}>
                    <Descriptions.Item label={'Client Name'}>
                        {state.client.nickname}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Email'}>{state.client.email}</Descriptions.Item>
                    <Descriptions.Item label={'Contact'}>
                        <span className={props.classes.spanBlock}>Personal: {state.client.telephone_mobile}</span>
                        <span className={props.classes.spanBlock}>Work: {state.client.telephone_work}</span>
                        <span></span>
                    </Descriptions.Item>
                </Descriptions>}
                </GridContainer>
                <Divider />
                <GridContainer>
                    <GridItem xs={6} sm={6}>
                        {state.transferDetail &&
                            <Descriptions title={'Transfer Detail'} layout={'horizontal'} column={1} bordered={true}>
                                <Descriptions.Item label={'Transfer ID'}>{state.transferDetail.id}</Descriptions.Item>
                                <Descriptions.Item label={'Record Created'}>
                                    {state.transferDetail.record_created != null ? moment(state.transferDetail.record_created).format('DD/MM/YYYY') : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label={'Expiry Date'}>
                                    {
                                        state.transferDetail.status_id < 4
                                        ? <DatePicker defaultValue={dayjs(state.transferDetail.expiryDate)} format={'DD/MM/YYYY'} onChange={(event) => onChangeDetail(event,  'expiryDate')} />
                                        : state.transferDetail.expiryDate != null ? moment(state.transferDetail.expiryDate).format('DD/MM/YYYY') : '-'
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label={'Currency'}>{state.transferDetail.currency_iso_alpha_3}</Descriptions.Item>
                                <Descriptions.Item label={'Amount'}>
                                    {
                                        state.transferDetail.status_id < 4
                                            ? <Input defaultValue={state.transferDetail.amount_to} onChange={(event) => onChangeDetail(event, 'amount_to')} />
                                            : state.transferDetail.amount_to.toFixed(2)
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label={'Status'}>{state.transferDetail.status}</Descriptions.Item>
                            </Descriptions>}
                    </GridItem>
                    <GridItem xs={6} sm={6}>
                    {state.transferDetail &&
                            <Descriptions title={'Payout Detail'} layout={'horizontal'} column={1} bordered={true}>
                                <Descriptions.Item label={'Payout ID'}>{state.transferDetail.id}</Descriptions.Item>
                                <Descriptions.Item label={'Beneficiary'}>{state.payouts[0].beneficiary_nickname}</Descriptions.Item>
                                <Descriptions.Item label={'Currency'}>{state.transferDetail.currency_iso_alpha_3}</Descriptions.Item>
                                <Descriptions.Item label={'Amount'}>{state.transferDetail.amount_to}</Descriptions.Item>
                            </Descriptions>}
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12}>
                        <Flex justify={'end'}>
                            <Button type={'primary'} onClick={() => {
                                API.post("transfers_pending", `/update/${state.transferDetail.id}`, {body: {expiryDate: state.expiryDate, amount_to: state.amount_to}}).then(() => message.success("Updated"))
                            }}>Save</Button>
                        </Flex>
                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card>
    </div>
}


const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (state) => {
    return {}
}

const PendingTransferViewDetailContainer
    = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TransferDetailPage));
export default PendingTransferViewDetailContainer;