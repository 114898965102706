import React, {Fragment} from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import {AiOutlineSearch} from 'react-icons/ai';
import moment from 'moment';
import Dvr from '@material-ui/icons/Dvr';
import Button from 'components/CustomButtons/Button.jsx';

const TransferDocumentsList = ({transfer_documents_list, history}) => {
  const getFilter = (filter, onChange) => {
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AiOutlineSearch />
          <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
        </div>
      </React.Fragment>
    );
  };

  const buildTableData = data => {
    if (!data) {
      return [];
    }

    return data.map(item => ({
      ...item,
      actions: (
        <Button
          justIcon
          round
          simple
          onClick={() => {
            history.push(`/transfers/documents/view/${item.id}`);
          }}
          color='warning'
          className='edit'
        >
          <Dvr />
        </Button>
      )
    }));
  };

  return (
    <Fragment>
      <ReactTable
        data={buildTableData(transfer_documents_list)}
        filterable
        columns={[
          {
            Header: 'Id',
            accessor: 'id',
            Filter: ({filter, onChange}) => getFilter(filter, onChange),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['id']}),
            filterAll: true,
            maxWidth: 50
          },
          {
            Header: 'Transfer Id',
            accessor: 'transferId',
            Filter: ({filter, onChange}) => getFilter(filter, onChange),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['transferId']}),
            filterAll: true,
            maxWidth: 200
          },
          {
            Header: 'Document Id',
            accessor: 'documentId',
            Filter: ({filter, onChange}) => getFilter(filter, onChange),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['documentId']}),
            filterAll: true,
            maxWidth: 200
          },
          {
            Header: 'Description',
            accessor: 'description',
            Filter: ({filter, onChange}) => getFilter(filter, onChange),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['description']}),
            filterAll: true,
            maxWidth: 200
          },
          {
            Header: 'Created',
            accessor: 'recordCreated',
            Cell: c => <React.Fragment>{moment(c.value).format('DD/MM/YYYY')} </React.Fragment>,
            Filter: ({filter, onChange}) => getFilter(filter, onChange),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['recordCreated']}),
            filterAll: true,
            maxWidth: 200
          },
          {
            Header: 'Actions',
            accessor: 'actions',
            sortable: false,
            filterable: false
          }
        ]}
        defaultSorted={[
          {
            id: 'id',
            desc: true
          }
        ]}
        defaultPageSize={10}
        showPageSizeOptions={true}
        showPaginationBottom
      />
    </Fragment>
  );
};

export default TransferDocumentsList;
