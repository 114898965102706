import {API} from 'aws-amplify';

export const IdentificationAPI = {
  fetchCategories: () => API.get('identification', '/get/categories', {}),
  fetchCountries: () => API.get('countries', '/list_not_deleted', {}),
  fetchClientDetails: (clientId) => API.get('clients', `/get/id/${clientId}`, {}),
  fetchClientTeam: (teamId) => API.get('teams', `/get/id/${teamId}`, {}),
  fetchClientList: (staffId) => API.get('clients', `/list/staff/${staffId}`, {}),
  saveIdentificationRecord: (data) => API.post('identification', '/create/in-database', {body: {...data}}),
  fetchIdentificationRecordById: (identificationId) => API.get('identification', `/get/id/${identificationId}`, {}),
  fetchIdentificationStatus: () => API.get('identification', '/get/identification_status', {})
}