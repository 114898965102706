import React, {useEffect, useState} from 'react';
import {CardHeader} from '@material-ui/core';
import CardBody from '../../../Card/CardBody';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import {Button, Checkbox} from 'antd';
import Card from '../../../Card/Card';
import {IndividualComplianceConstants} from './ComplianceConstants';
import TextArea from 'antd/lib/input/TextArea';
import './Compliance.css'

export const IndividualCompliance = ({state, onSelectedValuesChange, complianceData, onNoteChange, requiredFields}) => {

  const generalOptions = IndividualComplianceConstants.generalTrustOptions;
  const soleOptions = IndividualComplianceConstants.soleOptions;
  const verificationStdOptions = IndividualComplianceConstants.verificationStandardOptions
  const nonVerificationStdOptions = IndividualComplianceConstants.nonStandardVerificationOptions;

  const [selectedValues, setSelectedValues] = useState([]);
  const [individualComplianceNote, setIndividualComplianceNote] = useState(null);

  const onChange = (e) => {
    const { checked, value } = e.target;
    setSelectedValues((prevSelectedValues) => {
      if (checked) {
        const data = [...prevSelectedValues, value];
        onSelectedValuesChange({checkBoxOptions: data, note: individualComplianceNote, complianceType: 'INDIVIDUAL'});
        return data;
      }
      const data = prevSelectedValues.filter((item) => item !== value);
      onSelectedValuesChange({checkBoxOptions: data, note: individualComplianceNote, complianceType: 'INDIVIDUAL'});
      return data;
    });
  };

  const [buttonText, setButtonText] = useState('Save');
  const handleNoteChange = () => {
    setButtonText('Saving...');
    onNoteChange(individualComplianceNote);
    setTimeout(() => {
      setButtonText('Save');
    }, 3000);
  }

  useEffect(() => {
    setSelectedValues(complianceData.checkBoxOptions);
    setIndividualComplianceNote(complianceData.note);
  }, [complianceData.checkBoxOptions, complianceData.note]);

  const onHandleNote = (note) => {
    setIndividualComplianceNote(() => {
      onSelectedValuesChange({checkBoxOptions: selectedValues, note: note, complianceType: 'INDIVIDUAL'});
      return note;
    });
  }

  return (
    <React.Fragment>
      <Card className={'compliance-card'}>
        <CardHeader color='rose' icon>
          <h4>Individual</h4>
        </CardHeader>
        <CardBody className={'pt-0'}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h3>Customer Details Checked</h3>
              <hr/>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <ul className={'compliance-list'}>
                    {
                      generalOptions.map(option => (
                        <>
                            <li><Checkbox disabled={(!(state && state.edit_mode))} checked={selectedValues.includes(option.value)} onChange={onChange} key={option.value} value={option.value}>
                              {option.label} {requiredFields.includes(option.value) ? <span style={{color: 'red'}}> *Required</span> : <></> }
                            </Checkbox></li>
                        </>
                      ))
                    }
                  </ul>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h3>Verification Documents Checked</h3>
              <hr/>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Standard</h4>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <ul className={'compliance-list'}>
                        {
                          verificationStdOptions.map(option => (
                            <><li>
                              <Checkbox disabled={(!(state && state.edit_mode))} checked={selectedValues.includes(option.value)} onChange={onChange} key={option.value} value={option.value}>
                                {option.label} {requiredFields.includes(option.value) ? <span style={{color: 'red'}}> *Required</span> : <></>}
                              </Checkbox>
                            </li></>
                          ))
                        }
                      </ul>
                    </GridItem>
                  </GridContainer>
                  <h4>Non-Standard</h4>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <ul className={'compliance-list'}>
                        {
                          nonVerificationStdOptions.map(option => (
                            <><li>
                              <Checkbox disabled={(!(state && state.edit_mode))} checked={selectedValues.includes(option.value)} onChange={onChange} key={option.value} value={option.value}>
                                {option.label} {requiredFields.includes(option.value) ? <span style={{color: 'red'}}> *Required</span> : <></>}
                              </Checkbox>
                            </li></>
                          ))
                        }
                      </ul>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridContainer>

            </GridContainer>
            {
              state.account_type === 2 && state.account_business_type === 1 ? <GridItem xs={12} sm={12} md={6}>
                <h3>Sole Traders</h3>
                <hr/>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <ul className={'compliance-list'}>
                      {
                        soleOptions.map(option => (
                          <><li>
                            <Checkbox disabled={(!(state && state.edit_mode))} checked={selectedValues.includes(option.value)} onChange={onChange} key={option.value} value={option.value}>
                              {option.label} {requiredFields.includes(option.value) ? <span style={{color: 'red'}}> *Required</span> : <></> }
                            </Checkbox>
                          </li>
                          </>
                        ))
                      }
                    </ul>
                  </GridItem>
                </GridContainer>
              </GridItem> : <></>
            }
            {/* <GridItem xs={12} sm={12} md={6}>
              <h3>Verification - Standard</h3>
              <hr/>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <ul className={'compliance-list'}>
                    {
                      verificationStdOptions.map(option => (
                        <><li>
                          <Checkbox disabled={(!(state && state.edit_mode))} checked={selectedValues.includes(option.value)} onChange={onChange} key={option.value} value={option.value}>
                            {option.label} {requiredFields.includes(option.value) ? <span style={{color: 'red'}}> *Required</span> : <></>}
                          </Checkbox>
                        </li></>
                      ))
                    }
                  </ul>
                </GridItem>
              </GridContainer>
            </GridItem> */}
            {/* <GridItem xs={12} sm={12} md={6}>
              <h3>Verification - Non-Standard</h3>
              <hr/>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <ul className="compliance-list">
                    {
                      nonVerificationStdOptions.map(option => (
                        <><li>
                          <Checkbox disabled={(!(state && state.edit_mode))} checked={selectedValues.includes(option.value)} onChange={onChange} key={option.value} value={option.value}>
                            {option.label} {requiredFields.includes(option.value) ? <span style={{color: 'red'}}> *Required</span> : <></>}
                          </Checkbox>
                        </li></>
                      ))
                    }
                  </ul>
                  <p>All of the above documents must be accompanied by one of the following: a letter
                                        issued by
                                        government, ADI or a utility bill issued within the last 3 months with the
                                        Client’s current
                                        address.</p>
                </GridItem>
              </GridContainer>
            </GridItem> */}
            <GridItem xs={12} sm={12} md={6}>
              <h3>Compliance Notes</h3>
              <hr/>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h6>
                    Australian Domiciled with Foreign ID Document
                  </h6>
                  <p>For Australian Domiciled clients, who have Foreign ID documents, we are cautious using identification other than a foreign passport.
                    However a National Identity Card issued by a foreign government containing a photograph and signature of the person or Foreign driver’s licence that contains a photograph of the person is also acceptable.</p>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h3>Compliance Team Notes</h3>
              <hr/>
              <TextArea style={{backgroundColor: !state.edit_mode ? '#e0e0e0' : '#fff'}} disabled={(!(state && state.edit_mode))} value={individualComplianceNote} rows={4} onChange={(e) => onHandleNote(e.target.value)}/>
              <Button disabled={buttonText === 'Saving...' || !state.edit_mode } type={'primary'} size={'small'} className="note-save-box mt-1" onClick={() => handleNoteChange() }> {buttonText}</Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}