import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useState } from 'react'
import {Button, Image, message, Upload} from 'antd';
import './FileUpload.css';
import { LoadingOutlined, PlusOutlined , InboxOutlined } from '@ant-design/icons';
import {s3Upload} from '../../libs/awsLib';
import {FormattedMessage} from 'react-intl';

const {Dragger} = Upload;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const FileUploadNew = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [fileList, setFileList] = useState([]);

    const [visible, setVisible] = useState(false);
    const [activeImage, setActiveImage] = useState('');

  useImperativeHandle(ref, () => ({
    removeImage,
    restore: (fileList) => setFileList(fileList)
  }));

  const uploadToS3 = async ({file, onSuccess, onError}) => {
    try {
      const attachment = file ? await s3Upload(file) : null;
      setLoading(false);
      getBase64(file, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
      props.onHandleResult({
        file: file,
        file_id: attachment
      })
      onSuccess(file);
      message.success(`${file.name} file uploaded successfully.`);
    } catch (e) {
      alert('Upload Failed');
      console.log(e);
    }
  };

  const multiUploadS3 = async ({file, onSuccess, onError}) => {
    try {
      const attachment = file ? await s3Upload(file) : null;
      setLoading(false);
      getBase64(file, (url) => {
        setLoading(false);
        setFileList(prev => {
          file.url = url;
          const images = [...prev];
          images.push(file);
          return images;
        });
        props.onHandleResult({
          file: file,
          file_id: attachment,
          url: url
        });
      });
      onSuccess(file);
      message.success(`${file.name} file uploaded successfully.`);
    } catch (e) {
      console.log(e);
      alert('Upload Failed');
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const removeImage = () => {
    props.onHandleResult('');
    setImageUrl(null);
  }

  const handlePreview = async (file) => {
      setActiveImage(file.url);
      setVisible(true);
  };

  const handleImageRemove = (item) => {
    props.onRemoveImage(item);
    if (props.emitFileChangeEvent != null && props.emitFileChangeEvent === false) {
      const list = fileList.filter(i => i.uid !== item.uid);
      setFileList(list);
    }
  }

  const checkBeforeUpload = (file) => {
    const fileSizeInMB = file.size / 1024 / 1024; // Convert size to MB
    const maxSizeInMB = 5; // 5 MB

    if (fileSizeInMB > maxSizeInMB) {
      console.log('File size exceeds 5 MB.');
      message.info("Cannot upload file more than 5 mb in size. Must be image files only.");
      return false; // Prevent file from being uploaded
    }
    return true;
  }

  return (
    props.multiUpload ? <>
            <Dragger
              multiple={true}
              accept='.pdf, .jpg, .jpeg, .png'
              showUploadList={{showRemoveIcon: true, showPreviewIcon: true}}
              customRequest={multiUploadS3}
              fileList={fileList}
              onRemove={handleImageRemove}
              beforeUpload={checkBeforeUpload}
              maxCount={5}
              listType={props.listType == null ? 'picture' : 'picture'}
              onPreview={handlePreview}
            >
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>Click or drag file to this area to upload (Max 5 MB)</p>
              {!5 && <p className='ant-upload-hint'>Multiple files can be added to this Record.</p>}
            </Dragger>
            <Image
                width={200}
                style={{ display: 'none' }}
                src={activeImage}
                preview={{
                    visible: visible,
                    src: activeImage
                }}
                onPreviewClose={() => setVisible(false)}
            />
          </> :
          <>

            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={uploadToS3}
              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              beforeUpload={() => true}
              onChange={handleChange}
            >
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} className={'uploaded-image'}/> : uploadButton}
            </Upload>
            {
              imageUrl ? <div className={'remove-img-btn'}>
                <Button type={'primary'} onClick={removeImage} disabled={imageUrl === '' || imageUrl == null}>
                  <FormattedMessage id='newIdentification.cancel' defaultMessage={'Remove'} />
                </Button>
              </div> : ''
            }
          </>)
});
export default FileUploadNew;