import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import PermIdentity from '@material-ui/icons/PermIdentity';
import Button from '../../../components/CustomButtons/Button.jsx';

import {FormattedMessage} from 'react-intl';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import ClientDocuments from '../../../features/Clients/ClientDocuments.jsx';
import {API} from 'aws-amplify';

const clientDocumentsStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const ClientDocumentsContainer = props => {
  const {classes, history} = props;

  const [clientDocumentsList, setClientDocumentsList] = useState([]);

  const gotoNewClientDocumentPage = () => {
    history.push('/clients/documents/new');
  };

  const fetchClientDocumentsList = () => {
    API.get('client-documents', '/list')
      .then(response => {
        setClientDocumentsList(response);
      })
      .catch(error => {
        console.log(error);
        API.get('client-documents', '/list')
          .then(response => {
            setClientDocumentsList(response);
          })
          .catch(error => {
            console.log(error);
          });
      });
  };

  useEffect(() => {
    fetchClientDocumentsList();
  }, []);

  return (
    <Fragment>
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <PermIdentity />
          </CardIcon>
          <div className={classes.flexEnd}>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage
                id='clientDocuments.cardItem1.clientDocumentsList'
                defaultMessage={'Client Documents List'}
              />
            </h4>
            <Button round color='info' onClick={gotoNewClientDocumentPage}>
              <FormattedMessage id='clientDocumentsList.createNew' defaultMessage={'Create New'} />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <ClientDocuments client_documents_list={clientDocumentsList} history={history} />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </Fragment>
  );
};

ClientDocumentsContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(clientDocumentsStyles)(ClientDocumentsContainer);
