import React from 'react';
import PropTypes from 'prop-types';
import {API} from 'aws-amplify';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import blankPageStyle from "./BlankPageStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import Button from '../../../components/CustomButtons/Button';
import HoldingAccountLineItemListContainer from '../../../components/HoldingAccounts/HoldingAccountLineItemList';
import queryString from 'query-string';
import {FormattedMessage} from 'react-intl';

const idententificationListStyle = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientsList
 * ----------------------------------------------------------------------------
 */
class HoldingAccountLineItemListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      holding_account: {},
      holding_account_currency: '',
      holding_account_currency_id: null,
      holding_account_balance: 0
    };
  }

  componentDidMount() {
    // const { fetchBeneficiaryList } = this.props
    // console.log(this.props);
    // fetchBeneficiaryList();
    if (this.props.match.params.holding_account_id) {
      API.get('holding_accounts', `/get/line_items/${this.props.match.params.holding_account_id}`)
        .then(response => {
          // console.log(response);
          let balance = 0;
          for (const i in response.line_item_list) {
            const record = response.line_item_list[i];
            balance += record.credit;
            balance -= record.debit;
          }
          this.setState({
            holding_account: response.holding_account[0],
            holding_account_currency: response.holding_account[0].currencies_short_name,
            holding_account_currency_id: response.holding_account[0].currency_id,
            holding_account_balance: balance
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  render() {
    // console.log(this.props);
    const {classes} = this.props;
    const query_strings = queryString.parse(this.props.location.search);
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                {/* <FormattedMessage
                id="identificationList.cardItem1.identificationListing"
                defaultMessage={
                  `Identification Management`}
                /> */}
                <div
                  style={{
                    marginLeft: 6,
                    marginRight: 6
                  }}
                  className={`currency-flag currency-flag-${this.state.holding_account_currency.toLowerCase()}`}
                />{' '}
                <strong>
                  {this.state.holding_account_currency} Holding Account{' '}
                  {this.state.holding_account_currency
                    ? new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: this.state.holding_account_currency.toLowerCase()
                      }).format(this.state.holding_account_balance)
                    : ''}
                </strong>
              </h4>
              <div>
                {/* <Button
                  round
                  disabled={!this.state.holding_account_currency}
                  color='primary'
                  onClick={() => {
                    this.props.history.push(
                      `/transfers/new?fromCurrency=${this.state.holding_account_currency_id}&fromLedger=${this.props.match.params.holding_account_id}&clientId=${query_strings.clientId}`
                    );
                  }}
                >
                  widthDraw
                </Button> */}
                <Button round color='primary' onClick={() => this.props.history.push('/holding-accounts/clients/list')}>
                  <FormattedMessage id='newIdentification.cardItem1.showAll' defaultMessage={'Show All'} />
                </Button>
                <Button
                  round
                  color='info'
                  onClick={() =>
                    this.props.history.push(`/holding-accounts/new/debit/${this.props.match.params.holding_account_id}`)
                  }
                >
                  {/* <FormattedMessage
                  id="newIdentification.cardItem1.createNew"
                  defaultMessage={
                    `Create New`}
                  /> */}
                  New Debit
                </Button>
                <Button
                  round
                  color='info'
                  onClick={() =>
                    this.props.history.push(
                      `/holding-accounts/new/credit/${this.props.match.params.holding_account_id}`
                    )
                  }
                >
                  {/* <FormattedMessage
                  id="newIdentification.cardItem1.refresh"
                  defaultMessage={
                    `Refresh`}
                  /> */}
                  New Credit
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <HoldingAccountLineItemListContainer holding_account_id={this.props.match.params.holding_account_id} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

// IdentificationList.propTypes = {
//   classes: PropTypes.object.isRequired
// };

export default withStyles(idententificationListStyle)(HoldingAccountLineItemListPage);
