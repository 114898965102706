import React, {Fragment} from 'react';
import {connect} from 'react-redux';

import {updateNewClientCreation} from '../../../../redux/actions/clients';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';

import {cardTitle} from '../../../../assets/jss/material-dashboard-pro-react';
import Button from 'components/CustomButtons/Button.jsx';

import {FormattedMessage} from 'react-intl';
import {IdentificationAPI} from '../../../Identification/IdentificationAPI';
import {AppTemplates} from '../../../../AppTemplates';
import {AppUrlConstants} from '../../../../AppAPI';
import {Card, Col, DatePicker, Form, Input, message, Row, Select, Switch} from 'antd';
import FileUploadNew from '../../../FileUpload/FileUploadNew';
import FormItem from 'antd/lib/form/FormItem';
import {IdentificationFormValidationRules} from '../../../Identification/IdentificationConstants';
import {IdentificationTemplates} from '../../../Identification/IdentificationTemplates';
import {ClientAPI} from '../../ClientUtil';

const style = {
  ...customCheckboxRadioSwitch,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  },
  closeIcon: {
    position: 'absolute',
    top: '-3%',
    right: '0',
    zIndex: 9
  },
  idWrapper: {
    padding: '10px',
    position: 'relative',
    width: '100%',
    background: '#f4f4f4',
    borderRadius: '17px',
    marginBottom: '15px'
  }
};

class StepDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      country_list: [],
      document_category_list: [],
      isFormInvalid: false,
      toggleDocumentHelp: true,

      clientDocuments: []
    };
  }

  addClick() {
    this.setState(prevState => ({
      clientDocuments: [
        ...prevState.clientDocuments,
        {
          formRef: React.createRef(),
          document_category_id: '', 
          document_date_format: 'MM/DD/YYYY',
          files: []
        }
      ]
    }));
  }

  removeClick(i) {
    let clientDocuments = [...this.state.clientDocuments];
    clientDocuments.splice(i, 1);
    this.setState({clientDocuments});
  }

  componentDidMount() {
    IdentificationAPI.fetchCategories()
      .then(response =>
        this.setState({document_category_list: response.map(item => ({label: item.nickname, value: item.id}))})
      )
      .catch(err => console.log(err));

    AppUrlConstants.fetchCountries()
      .then(response => this.setState({country_list: response}))
      .catch(err => console.log(err));
  }

  sendState() {
      const state = JSON.parse(JSON.stringify(this.state));
      if (state.clientDocuments != null && state.clientDocuments.length > 0) {
          state.clientDocuments.forEach(item => {
              delete item.formRef;
              if (item.files != null && item.files.length > 0) {
                  console.log(item);
                  item.files = item.files.map(i => i.file_id);
              }
          })
      }
      return state;
  }

  async preValidate() {
      const validForms = [];
      for (let item of this.state.clientDocuments) {
          const isValidated = await item.formRef.current.validateFields().then(() => true).catch(() => false);
          validForms.push(isValidated);
      }
      const isAllFormsValid = validForms.every(item => item === true);
      if (isAllFormsValid) {
          const identification_records = [];
          const clientDocs = [...this.state.clientDocuments];
          clientDocs.forEach(item => {
              const values = item.formRef.current.getFieldsValue();
              values.files = item.files == null ? [] : item.files.map(item => item.file_id);
              if (values.document_expiry != null) {

              }
              identification_records.push(values);
          });
          this.props.updateNewClientCreation('identification', identification_records);
          const requestBody = {identification_records: identification_records, clientId: this.props.clients.new_client_data.client_id};
          return await ClientAPI.saveWizardClientStepFour(requestBody)
            .then(() => true)
            .catch(() => false);
      } else {
          message.error("Fill the required fields below..", 2);
      }
      return false;
  }


  isValidated() {
      return true;
  }

  handleRemoveImage = (data, index) => {
    console.log(data);
    const clientDocuments = [...this.state.clientDocuments];
      clientDocuments[index].files = clientDocuments[index].files.filter(item => item.uid !== data.uid);
    this.setState({clientDocuments: clientDocuments});
  };

  storeMultipleImages = (data, index) => {
    const clientDocuments = [...this.state.clientDocuments];
    clientDocuments[index].files.push({uid: data.file.uid, url: data.url, file_id: data.file_id});
    this.setState({clientDocuments: clientDocuments});
  };

  onChangeDocumentCategory(data, index) {
    const clientDocuments = [...this.state.clientDocuments];
    const label = this.state.document_category_list.find(item => item.value === data).label || '';
    clientDocuments[index].document_category_id = data;
    clientDocuments[index].formRef.current.setFieldsValue({document_nickname: 'My ' + label});

    clientDocuments[index].document_date_format = label.toLowerCase() === 'medicare' ? 'MM/YYYY' : 'DD/MM/YYYY';
      
    clientDocuments[index].formRef.current.setFieldsValue({expiry_date: null});
    clientDocuments[index].formRef.current.setFieldsValue({document_nickname: 'My ' + label});
    this.setState({clientDocuments: clientDocuments});
  }

  removeDialog = index => {
    let clientDocuments = this.state.clientDocuments;
    clientDocuments.splice(index, 1);
    this.setState({clientDocuments: clientDocuments, isFormInvalid: false});
  };

  createUI() {
    const {classes} = this.props;

    const select_country_select_options = AppTemplates.countries(
      this.state.country_list.sort((a, b) => a.full_name.localeCompare(b.full_name))
    );

    const beneficiaries = this.props.clients.new_client_data.clientContacts.map(item => ({label: `${item.firstName} ${item.lastName}`, value: item.id}));
    const firstName = this.props.clients.new_client_data.first_name
    const lastName = this.props.clients.new_client_data.last_name
    beneficiaries.push({value: null, label: `${firstName} ${lastName}`})


    return this.state.clientDocuments.map((el, i) => (
      <div key={i} className={classes.idWrapper}>
          <img
              src={require('../../../../assets/img/close_icon.png')}
              width={15}
              height={15}
              className={classes.closeIcon}
              alt={'close_icon'}
              onClick={() => this.removeDialog(i)}
          />
        <Card style={{borderRadius: '18px'}}>
          <Form
              initialValues={{client_contact_id: null, client_id: this.props.clients.new_client_data.client_id || null}}
            ref={this.state.clientDocuments[i].formRef}
            name={`wizardIdentification-${i}`}
            scrollToFirstError
            layout={'vertical'}
          >
            <Row gutter={16}>
              <Col span={12}>
                  {
                      this.props.clients.new_client_data.account_type === 2 && <FormItem
                          name='client_contact_id'
                          label={
                              <FormattedMessage
                                  id='newClients.step4.form.beneficiary'
                                  defaultMessage={'Beneficiary'}
                              />
                          }
                      >
                          <Select
                              showSearch
                              style={{width: '100%'}}
                              placeholder='Identification Belongs To...'
                              optionFilterProp='children'
                              filterOption={(input, option) =>
                                  (option && option.label ? option.label : '').toLowerCase().includes(input)
                              }
                              filterSort={(optionA, optionB) =>
                                  (optionA && optionA.label ? optionA.label : '')
                                      .toLowerCase()
                                      .localeCompare(
                                          (optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase()
                                      )
                              }
                              options={beneficiaries}
                          ></Select>
                      </FormItem>
                  }
                <Row gutter={16}>
                  <Col span={24}>
                    <Col span={24}>
                      <FormItem rules={IdentificationFormValidationRules.DOCUMENT_CATEGORY}
                        name='document_category_id'
                        label={
                          <FormattedMessage
                            id='newClients.step4.form.documentCategory'
                            defaultMessage={'Document Category'}
                          />
                        }
                      >
                        <Select
                          style={{width: '100%'}}
                          placeholder='Document Category'
                          optionFilterProp='children'
                          onChange={e => this.onChangeDocumentCategory(e, i)}
                          options={this.state.document_category_list}
                        ></Select>
                      </FormItem>
                    </Col>
                    <Col span={24}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <FormItem
                            name='document_number'
                            label={
                              <FormattedMessage
                                id='newClients.step4.form.documentNumber'
                                defaultMessage={'Document Number'}
                              />
                            }
                          >
                            <Input placeholder={'Document number'} />
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem
                            name='document_expiry'
                            label={
                              <FormattedMessage
                                id='newClients.step4.form.documentExpiry'
                                defaultMessage={'Document Expiry'}
                              />
                            }
                            rules={IdentificationFormValidationRules.EXPIRY_DATE}
                          >
                            <DatePicker
                              style={{width: '100%'}}
                              showTime={false}
                              mode='date'
                              format={el.document_date_format}
                            />
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <FormItem
                            name='document_issue_state'
                            label={
                              <FormattedMessage id='newClients.step4.form.issueState' defaultMessage={'Issue State'} />
                            }
                            rules={IdentificationFormValidationRules.ISSUE_STATE}
                          >
                            <Input placeholder={'Document Issue State'} />
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem
                            name='document_issue_country'
                            label={
                              <FormattedMessage
                                id='newClients.step4.form.issueCountry'
                                defaultMessage={'IssueCountry'}
                              />
                            }
                            rules={IdentificationFormValidationRules.COUNTRY}
                          >
                            <Select
                              showSearch
                              style={{width: '100%'}}
                              placeholder='Search to select country'
                              optionFilterProp='alt'
                              filterOption={(input, option) =>
                                (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA && optionA.alt ? optionA.alt : '')
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase()
                                  )
                              }
                              options={select_country_select_options}
                            ></Select>
                          </FormItem>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
                {el.document_category_id != null && el.document_category_id !== '' && this.state.toggleDocumentHelp ? (
                  <Fragment>
                    <hr />
                    <Row gutter={16}>
                      {<Col span={24}>{IdentificationTemplates.getDocumentHelp(el.document_category_id)}</Col>}
                    </Row>
                  </Fragment>
                ) : (
                  ''
                )}
              </Col>
              <Col span={12}>
                <FileUploadNew
                  key={i}
                  style={{marginTop: '10px'}}
                  onRemoveImage={data => this.handleRemoveImage(data, i)}
                  multiUpload={true}
                  emitFileChangeEvent={false}
                  onHandleResult={data => this.storeMultipleImages(data, i)}
                ></FileUploadNew>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    ));
  }

  render() {
    const {classes} = this.props;

    if (!this.props.clients.new_client_data) {
      return null;
    }
    return (
      <React.Fragment>
        <GridContainer justify='center' style={{textAlign: 'center'}}>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              Please upload a photo ID and also proof of address document for {this.props.clients.new_client_data.first_name} {this.props.clients.new_client_data.last_name}.<br/>
              Also upload the same ID documents for other beneficial owners (if any).
            </h4>
          </GridItem>
        </GridContainer>
          {
              this.state.clientDocuments.length > 0 &&
              <div style={{marginBottom: '10px'}}>
                  <span>Show document notes ?: </span>
                  <Switch
                      checked={!!this.state.toggleDocumentHelp}
                      onChange={checked => this.setState({toggleDocumentHelp: checked})}
                  />
              </div>
          }
        {this.createUI()}
        <GridContainer justify='flex-end'>
          <GridItem xs={6} md={2}>
            <Button size='sm' color='info' key='addKey' onClick={this.addClick.bind(this)}>
              <FormattedMessage id='newClients.step4.form.addNew' defaultMessage={`Add New ID Record`} />
            </Button>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

// export default withStyles(style)(StepDocument);
StepDocument = withStyles(style)(StepDocument);
const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewClientCreation: (key, value) => {
      dispatch(updateNewClientCreation(key, value));
    }
  };
};

const StepDocumentContainer = connect(mapStateToProps, mapDispatchToProps)(StepDocument);

export default StepDocumentContainer;
