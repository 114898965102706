import {Col, Form, Row, Select, Tag} from "antd";
import React from "react";
import {connect} from "react-redux";
import {AppTemplates} from "../../../../AppTemplates";

const ClientRiskIndividual = ({ getRiskRating, setRiskRating, setComplianceRating, riskRating, getRiskColor, overallRating, getOptions, setRiskCountry, app_state}) => {
    return (<>

        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_legal_structure'} label={'Legal Structure'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_legal_structure: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_legal_structure: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_legal_structure')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_legal_structure'])}>{getRiskRating(riskRating['i_legal_structure'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_legal_structure'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_complexity'} label={'Complexity'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_complexity: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_complexity: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_complexity')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_complexity'])}>{getRiskRating(riskRating['i_complexity'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_complexity'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_jurisdiction'} label={'Jurisdictions'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select showSearch={true} optionFilterProp="alt" placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({
                            ...prev,
                            i_jurisdiction: (option != null ? option.score : null)
                        }));
                        setComplianceRating(prev => ({
                            ...prev,
                            i_jurisdiction: (option != null ? option.riskLevel : null)
                        }));
                        setRiskCountry(val);
                    }} options={AppTemplates.countries(app_state.country_list)}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_jurisdiction'])}>{getRiskRating(riskRating['i_jurisdiction'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_jurisdiction'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_residency_status'} label={'Residency Status'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_residency_status: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_residency_status: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_residency_status')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_residency_status'])}>{getRiskRating(riskRating['i_residency_status'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_residency_status'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_product_service'} label={'Product / Service'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_product_service: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_product_service: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_product_service')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_product_service'])}>{getRiskRating(riskRating['i_product_service'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_product_service'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_channel_sales_referral'} label={'Channel Sales Referral'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_channel_sales_referral: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_channel_sales_referral: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_channel_sales_referral')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_channel_sales_referral'])}>{getRiskRating(riskRating['i_channel_sales_referral'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_channel_sales_referral'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_channel_service_access'} label={'Channel Service Access'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_channel_service_access: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_channel_service_access: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_channel_service_access')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_channel_service_access'])}>{getRiskRating(riskRating['i_channel_service_access'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_channel_service_access'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_source_of_funds'} label={'Source of Funds'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_source_of_funds: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_source_of_funds: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_source_of_funds')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_source_of_funds'])}>{getRiskRating(riskRating['i_source_of_funds'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_source_of_funds'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_source_of_wealth'} label={'Source of Wealth'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_source_of_wealth: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_source_of_wealth: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_source_of_wealth')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_source_of_wealth'])}>{getRiskRating(riskRating['i_source_of_wealth'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_source_of_wealth'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_business_relationship'} label={'Nature of Business Relationship'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_business_relationship: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_business_relationship: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_business_relationship')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_business_relationship'])}>{getRiskRating(riskRating['i_business_relationship'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_business_relationship'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_expected_account_activity'} label={'Expected Account Activity'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_expected_account_activity: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_expected_account_activity: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_expected_account_activity')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_expected_account_activity'])}>{getRiskRating(riskRating['i_expected_account_activity'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_expected_account_activity'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_customer_operations'} label={'Customer Operations'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_customer_operations: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_customer_operations: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_customer_operations')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_customer_operations'])}>{getRiskRating(riskRating['i_customer_operations'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_customer_operations'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_estimated_net_worth'} label={'Estimated Net Worth'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_estimated_net_worth: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_estimated_net_worth: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_estimated_net_worth')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_estimated_net_worth'])}>{getRiskRating(riskRating['i_estimated_net_worth'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_estimated_net_worth'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_political_person'} label={'Political Exposed Person'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_political_person: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_political_person: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_political_person')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_political_person'])}>{getRiskRating(riskRating['i_political_person'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_political_person'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_sanctions'} label={'sanctions'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_sanctions: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_sanctions: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_sanctions')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_sanctions'])}>{getRiskRating(riskRating['i_sanctions'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_sanctions'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_criminal_record'} label={'Criminal Record'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_criminal_record: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_criminal_record: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_criminal_record')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_criminal_record'])}>{getRiskRating(riskRating['i_criminal_record'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_criminal_record'] || 0}
            </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
            <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                <Form.Item name={'i_terrorism'} label={'Terrorism / Terrorism Financing'} rules={[{required: true, message: 'Field is required'}]}>
                    <Select placeholder={' -- SELECT --'} allowClear={true} onChange={(val, option) => {
                        setRiskRating(prev => ({...prev, i_terrorism: (option != null ? option.score : 0)}));
                        setComplianceRating(prev => ({...prev, i_terrorism: (option != null ? option.riskLevel : 0)}));
                    }} options={getOptions('i_terrorism')}></Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                <Tag color={getRiskColor(riskRating['i_terrorism'])}>{getRiskRating(riskRating['i_terrorism'])}</Tag>
            </Col>
            <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                {overallRating['i_terrorism'] || 0}
            </Col>
        </Row>

        </>)
}

const mapStateToProps = store => {
    return {
        app_state: store.app_state
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientRiskIndividual);