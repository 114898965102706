import {connect} from 'react-redux';
import Dashboard from './Dashboard';
// import {
//   // updateNewTransferCreationTransferType,
//   updateNewTransferCreation
// } from "../../redux/actions/transfers_2";

const mapStateToProps = (state, ownProps) => {
  return {
    app_state: state.app_state
    // transfers: state.transfers,
    // transfers_2: state.transfers_2
  };
};

// const mapDispatchToProps = (dispatch) => {
// return {
//   // onLoginSuccess: (user_id) => {
//   //   dispatch(onLoginSuccess(user_id));
//   // },
//   updateNewTransferCreation: (key, value) => {
//     dispatch(updateNewTransferCreation(key, value))
//   }
// };
// };

const DashboardContainer = connect(
  mapStateToProps
  // mapDispatchToProps
)(Dashboard);

export default DashboardContainer;
