import React from 'react';
import PropTypes from 'prop-types';
import {API} from 'aws-amplify';

import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Button from '../../CustomButtons/Button';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react';
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import { Divider } from 'antd';
// var moment = require('moment');
const moment = require('moment')

const styles = {
  ...switchStyles,
  ...selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class TransferDetailRemittanceCommunication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remittance_advice_email_sending: false,
      remittance_advice_email_sent: false
    };
  }

  componentDidMount() {

    if (this.props.transferId) {
      API.get('transfers', `/get-full/id/${this.props.transferId}`)
        .then(response => {
          // console.log(response)
          this.setState({
            transfer: response.fullList,
            payouts_list: response.payouts
          });

          API.get('system', `/currencies/get/${response.fullList.currency_to_id}`)
            .then(response1 => {
              // console.log(response1)
              this.setState({currency_to: response1});
            })
            .catch(error => {
              console.log(error);
            });
            API.get('clientcontacts', `/list-by-clientid/${response.fullList.client_id}`)
            .then(clientContactResponse => {
              // console.log(clientContactResponse)
              clientContactResponse = clientContactResponse.filter(contact => contact.sendEmail)
              this.setState({clientContacts: clientContactResponse});
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
          API.get('transfers', `/get-full/id/${this.props.transferId}`).then(response => {
            //console.log(response)
            this.setState({
              transfer: response.fullList,
              payouts_list: response.payouts
            });
          });
        });
    }

    if (this.props.emailsent_remittance_staff) {
      // console.log(this.props.emailsent_remittance_staff)
       API.get('staff', `/get/${this.props.emailsent_remittance_staff}`)
        .then(response => {
          console.log(response)
          this.setState({
            emailsent_remittance_staff: response.nickname,
          });
        })
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log(prevProps)
    if (prevProps.transferId !== this.props.transferId) {
      // console.log(this.props.transferId)
    }
    if (prevProps.emailsent_remittance_staff !== this.props.emailsent_remittance_staff) {
      // console.log(this.props.emailsent_remittance_staff)
      if (!this.props.emailsent_remittance_staff) { return; }
       API.get('staff', `/get/${this.props.emailsent_remittance_staff}`)
        .then(response => {
          // console.log(response)
          this.setState({
            emailsent_remittance_staff: response.nickname,
          });
        })
    }

  }

  handle_send_remittance_advice_new = async event => {
    this.setState({
      remittance_advice_email_sending: true
    });
    API.post('email', `/client/remittance-instructions-nmail`, {
      body: {
        transferID: this.props.transferId,
        currentStaffID: this.props.currentStaffID,
      }
    })
      .then(response => {
        console.log('Email success: ', response);
        this.setState({
          // remittance_advice_email_sent: true,
          remittance_advice_email_sending: false,
          emailsent_remittance_date: moment().format('YYYY-MM-DD HH:mm:ss'),
          emailsent_remittance_staff: `You (staff ID ${this.props.currentStaffID})`
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handle_send_remittance_advice_staff = async event => {
    this.setState({
      remittance_advice_email_sending: true
    });
    API.post('email', `/client/remittance-instructions-nmail`, {
      body: {
        transferID: this.props.transferId,
        currentStaffID: this.props.currentStaffID,
        staffOnly: true
      }
    })
      .then(response => {
        console.log('Email success: ', response);
        this.setState({
          // remittance_advice_email_sent: true,
          remittance_advice_email_sending: false,
          // emailsent_remittance_date: moment().format('YYYY-MM-DD HH:mm:ss'),
          // emailsent_remittance_staff: this.props.currentStaffID
        });
      })
      .catch(error => {
        console.log(error);
      });
  };


  render() {
    const {classes} = this.props;

    
    let clientEmail
    if (this.state.transfer) {
      clientEmail = this.state.transfer.client_email
    }

    let emailSendTo
    if (this.state.clientContacts) {
      emailSendTo = this.state.clientContacts.map(contact => {
        return (
          <span key={contact.id}>
            Email CC: {contact.email} - {contact.firstName} {contact.lastName}, {contact.position}
            <br/>
          </span>
        )})
    }

    const emailsent_remittance_date = this.state.emailsent_remittance_date || this.props.emailsent_remittance_date
    const emailsent_remittance_staff = this.state.emailsent_remittance_staff

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button
              color='info'
              disabled={!this.props.enabled}
              size='sm'
              style={{width: '100px'}}
              onClick={this.handle_send_remittance_advice_staff}
              className={classes.updateProfileButton}
            >
              Email Staff
            </Button> &nbsp; {this.props.remitting_bank_id ? "Remitting Bank Selected" : "From Currency Balance"}
            {/* <Divider type="vertical" /> */}
            {/* <Button
              color='info'
              disabled={!this.props.enabled}
              // disabled={true}
              size='sm'
              style={{width: '120px'}}
              onClick={this.handle_send_remittance_advice_staff_norem}
              className={classes.updateProfileButton}
            >
              From Balance
            </Button> */}
            <br/>
            {/* <em>The 'from balance' email tells customer transfer will be from their currency balance</em><br/> */}
            Staff Email: {this.props.current_staff_email}
            <hr/>
            <Button
              color='primary'
              disabled={!this.props.enabled}
              size='sm'
              style={{width: '100px'}}
              onClick={this.handle_send_remittance_advice_new}
              className={classes.updateProfileButton}
            >
              Email Client
            </Button> &nbsp; {this.props.remitting_bank_id ? "Remitting Bank Selected" : "From Currency Balance"}
            {/* <Divider type="vertical" /> */}
            {/* <Button
              color='primary'
              disabled={!this.props.enabled}
              // disabled={true}
              size='sm'
              style={{width: '120px'}}
              onClick={this.handle_send_remittance_advice_new_norem}
              className={classes.updateProfileButton}
            >
              From Balance
            </Button> */}
            <br/>
            {/* <em>The 'from balance' email tells customer transfer will be from their currency balance</em><br/> */}
            Client Email: {clientEmail}<br/>
            {emailSendTo}
            <hr/>
            {/* <br/> */}
            {/* {!this.props.enabled && <span>(insufficient security level)</span>} */}
            {this.state.remittance_advice_email_sending && <span><br/>(email sending)</span>}
            {this.state.remittance_advice_email_sent && <span><br/>(email sent)</span>}
            <br/>
            <span>Email sent: {emailsent_remittance_date ? moment(emailsent_remittance_date).format("DD-MM-YYYY @ HH:mm:ss") : "n/a"}</span><br/>
            <span>Staff: {emailsent_remittance_staff}</span>
            {/* {this.state.emailsent_remittance_staff && <span>Staff: {this.state.emailsent_remittance_staff}</span>} */}
            {/* {this.props.emailsent_remittance_date && <span>Email sent: {moment(this.props.emailsent_remittance_date).format("DD-MM-YYYY HH:mm:ss")}</span>}<br/> */}
            {/* {this.props.emailsent_remittance_staff && <span>Staff: {this.props.emailsent_remittance_staff}</span>} */}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

TransferDetailRemittanceCommunication.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(TransferDetailRemittanceCommunication));
