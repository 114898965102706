import React from "react";
import { BankOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons'

export const AppTemplates= {
    countryTemplate: (item) => {
        return <React.Fragment>
                        <span>
                            <img width={15} height={15}
                                 src={
                                     item.iso_alpha_2 !== ''
                                         ? (require(`./assets/img/flags/${item.iso_alpha_2 ? item.iso_alpha_2 : ''}.png`))
                                         : ''
                                 }
                            />
                        </span>{' '}
            &nbsp; {item.full_name}{' '}
        </React.Fragment>
    },
    countries: (sortedCountry) => {
        return sortedCountry.map(
            item => ({
                score: item.riskScore,
                riskLevel: item.riskRating,
                value: item.id,
                label: (
                    <React.Fragment>
            <span>
              <img width={15} height={15}
                   src={
                       item.iso_alpha_2 !== ''
                           ? require(`./assets/img/flags/${
                               item.iso_alpha_2
                           }.png`)
                           : ''
                   }
              />
            </span>{' '}
                        {' ' + item.iso_alpha_2 + ': ' + item.full_name}{' '}
                    </React.Fragment>
                ),
                alt: item.full_name,
            })
        )
    },
    clients: (clients, showEmail = false, isTeam = false) => {
        return clients.map(
            item => ({
                value: item.id,
                label: (
                    <React.Fragment>
                        {
                            item.account_type === 2 ? <><BankOutlined/> {item.nickname}</> : <>
                            {isTeam ? <UsergroupAddOutlined /> : <UserOutlined/>} {item.nickname} {showEmail ? `[${item.email}]` : ''}</>
                        }
                    </React.Fragment>
                ),
                alt: item.nickname,
            })
        )
    }
}