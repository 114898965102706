import React from 'react';
import {connect} from 'react-redux';

import { useParams, withRouter } from 'react-router-dom'
import {HoldingAccountAPI, HoldingAccountConstants, HoldingAccountLineItemValidation} from './HoldingAccountConstants';
import {Button, Checkbox, Col, DatePicker, Divider, Form, Input, Row} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import HoldingAccountUtil from './HoldingAccountUtil';
import dayjs from 'dayjs'

class HoldingAccountLineItemNew extends React.Component {

    formRef = React.createRef();

    constructor(props) {
      super(props);
      this.state = {
        holding_account: {},
        transaction_datetime: '',
        transaction_datetime_state: '',
        memo: '',
        memo_state: '',
        debit: 0,
        credit: 0,
        amount: '',
        emailReceipt: false,
        updatedBalance: 0,
      };
    }

    componentDidMount() {
      if (this.props.holding_account_id) {
        HoldingAccountAPI.getHoldingAccount(this.props.holding_account_id).then(response => {
          this.setState({holding_account: response.holding_account_list[0]});
          this.formRef.current.setFieldsValue(HoldingAccountUtil.initialValues(this.state));
          if (this.props.holding_detail_id) {
            HoldingAccountAPI.fetchHoldingAccountLineItem(this.props.holding_detail_id).then(response => {
                if (response && response.line_item.length > 0) {
                    this.formRef.current.setFieldValue('description', response.line_item[0].memo);
                    const amount = response.line_item[0].debit || response.line_item[0].credit;
                    const deduction = response.line_item[0].debit ? 'debit' : 'credit';
                    this.formRef.current.setFieldValue('amount', amount);
                    const actualAmount = deduction === 'credit' ? this.state.holding_account.balance - amount : this.state.holding_account.balance + amount;
                    const newBalance = deduction === 'credit' ? actualAmount + amount : actualAmount - amount;
                    this.formRef.current.setFieldValue('balance_before', HoldingAccountUtil.formatCurrency(actualAmount, this.state.holding_account.currencies_short_name));
                    this.formRef.current.setFieldValue('new_balance', HoldingAccountUtil.formatCurrency(newBalance, this.state.holding_account.currencies_short_name));
                    this.formRef.current.setFieldValue(deduction === 'debit' ? 'less_debit' : 'plus_credit', HoldingAccountUtil.formatCurrency(amount, this.state.holding_account.currencies_short_name));
                    this.formRef.current.setFieldValue('transaction_date', dayjs(response.line_item[0].transaction_datetime));
                    this.setState({updatedBalance: actualAmount});
                    this.handleDebitCreditBalance({target: {value: amount}});
                }
            })
          } else {
              this.setState({updatedBalance: this.state.holding_account.balance});
          }
        }).catch(error => console.log(error));
      }
    }

    handleSubmitForm = async (values) => {
      let requestBody = HoldingAccountUtil.prepareRequestBody(this.props.holding_account_id, values, this.props.type);
      if (this.props.holding_detail_id != null && this.props.holding_detail_id !== 0)  {
          requestBody = {...requestBody, id: this.props.holding_detail_id};
      }
      const holdingAccountLineItemId = await HoldingAccountAPI.saveHoldingAccount(requestBody)
        .then(database_result => {
          // console.log('database_result', database_result);
          if (database_result.insertId === 0) {
            return this.props.holding_detail_id;
          } else {
            return database_result.insertId;
          }
        }).catch(error => {
          console.log(error);
        });
      if (values.emailReceipt) {
        // console.log('this.state', this.state)
        // console.log('this.props.holding_detail_id', this.props.holding_detail_id)
        // console.log('holdingAccountLineItemId', holdingAccountLineItemId)
        const emailRequest = HoldingAccountUtil.prepareEmailRequestBody(holdingAccountLineItemId, this.state, values);
        // console.log('emailRequest', emailRequest);
        HoldingAccountAPI.emailHoldingAccountCompany(emailRequest).catch(err => console.log(err));
      }
      this.props.history.push(HoldingAccountConstants.URL.ALL_HOLDINGS(this.props.holding_account_id));
    }

    handleDebitCreditBalance = (event) => {

      const formatBalance = (flag = false) => {
        if (this.props.type === 'debit') {
          return flag
              ? HoldingAccountUtil.formatCurrency((this.state.updatedBalance - Number.parseFloat(event.target.value) || 0), this.state.holding_account.currencies_short_name)
              : (this.state.updatedBalance - Number.parseFloat(event.target.value) || 0);
        }
        return flag
          ? HoldingAccountUtil.formatCurrency((this.state.updatedBalance + Number.parseFloat(event.target.value) || 0), this.state.holding_account.currencies_short_name)
          : (this.state.updatedBalance + Number.parseFloat(event.target.value) || 0);
      }

      const getType = (action) => this.props.type === action
        ? (HoldingAccountUtil.formatCurrency(event.target.value, this.state.holding_account.currencies_short_name) || 0)
        : 0;

      this.formRef.current.setFieldsValue({
        less_debit: getType('debit'),
        plus_credit: getType('credit'),
        new_balance: formatBalance(true),
        hidden_new_balance: formatBalance()
      });
    }

    onCancelBtnClick() {
      this.props.history.push(HoldingAccountConstants.URL.ALL_HOLDINGS(this.props.holding_account_id));
    }

    render() {
      return (
            <>
                <h3>
                    {this.props.holding_detail_id ? 'Update' : 'New'} {this.props.type.toUpperCase()} Transaction
                </h3>
                <p style={{display: 'block'}}>
                  <b>Account Name:</b> {this.state.holding_account.client_nickname}<br/>
                  <b>Currency:</b> {this.state.holding_account.currencies_short_name}<br/>
                  <b>Current Balance:</b> {Number.parseFloat(this.state.holding_account.balance).toFixed(2)}
                </p>
                <hr/>
                <Form initialValues={{formattedBalance: 0}} ref={this.formRef} name={HoldingAccountConstants.formConstants.FORM_NAME.name} scrollToFirstError layout={'vertical'} onFinish={this.handleSubmitForm}>
                  <Row gutter={[16, 24]}>
                    <Col span={12}>
                      <FormItem name="transaction_date" label={HoldingAccountConstants.formConstants.TRANSACTION_DATE.label}
                        rules={HoldingAccountLineItemValidation.TRANSACTION_DATE}>
                        <DatePicker style={{width: '100%'}} showTime={false} mode="date" format={'DD/MM/YYYY'}/>
                      </FormItem>

                      <FormItem name="amount" label={HoldingAccountConstants.formConstants.AMOUNT.label} rules={HoldingAccountLineItemValidation.AMOUNT}>
                        <Input onChange={(e) => this.handleDebitCreditBalance(e)}/>
                      </FormItem>

                      <FormItem name="description" label={HoldingAccountConstants.formConstants.DESCRIPTION.label}
                        rules={HoldingAccountLineItemValidation.DESC}>
                        <Input/>
                      </FormItem>

                        <FormItem name="memo_internal" label={HoldingAccountConstants.formConstants.MEMO_INTERNAL.label}>
                            <Input />
                        </FormItem>

                      {this.props.type === 'credit' ?
                                <>
                                    <FormItem name="emailReceipt" valuePropName="checked">
                                      <Checkbox>{`Email Receipt to ${this.state.holding_account.client_nickname}`}</Checkbox>
                                    </FormItem>

                                </> : ''
                      }
                    </Col>
                    <Col span={12}>
                      <FormItem name="balance_before" label={HoldingAccountConstants.formConstants.BALANCE_BEFORE.label}>
                        <Input disabled/>
                      </FormItem>
                      {this.props.type === 'debit' &&
                      <FormItem name="less_debit" label={HoldingAccountConstants.formConstants.DEBIT.label}>
                        <Input disabled/>
                      </FormItem>
                      }
                      {this.props.type === 'credit' &&
                      <FormItem name="plus_credit" label={HoldingAccountConstants.formConstants.CREDIT.label}>
                        <Input disabled/>
                      </FormItem>
                      }
                      <FormItem name="new_balance" label={HoldingAccountConstants.formConstants.NEW_BALANCE.label}>
                        <Input disabled/>
                      </FormItem>
                      <FormItem hidden name="hidden_new_balance">
                        <Input disabled/>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row justify={'end'}>
                    <Col>
                      <Button type={'primary'} htmlType="submit">
                                Submit
                      </Button>
                      <Divider type={'vertical'}></Divider>
                      <Button type={'primary'} danger
                        onClick={() => this.onCancelBtnClick()}
                      >
                                Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
            </>
      );
    }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const HoldingAccountLineItemNewContainer = connect(mapStateToProps, mapDispatchToProps)(HoldingAccountLineItemNew);

export default withRouter((HoldingAccountLineItemNewContainer));
