import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';

import {cardTitle} from '../../assets/jss/material-dashboard-pro-react.jsx';

import {Select, MenuItem} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import styles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';

import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import {API} from 'aws-amplify';

const entityNewStyles = {
  ...styles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  }
};

class EntitybanksaccountNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_opts: [],
      entitybanks_opts: [],
      eb_id: '',
      e_id: '',
      eba_swift: '',
      eba_swift_state: '',
      eba_bsb: '',
      eba_bsb_state: '',
      eba_accout_name: '',
      eba_accout_name_state: '',
      eba_accout_number: '',
      eba_accout_number_state: '',
      eba_deleted: '',
      reference_mandatory: '',
      reference_mandatory_state: ''
    };
  }

  componentDidMount() {
    API.get('system', `/entitybanks/get-all`)
      .then(result => {
        var arrTen = [];
        for (var k = 0; k < result.length; k++) {
          arrTen.push(
            <MenuItem key={result[k].eb_id} value={result[k].eb_id}>
              {' '}
              {result[k].eb_long_name}{' '}
            </MenuItem>
          );
        }
        this.setState({
          entitybanks_opts: arrTen
        });
      })
      .catch(error => {
        console.log(error);
      });

    API.get('system', `/entities/get-list-not-deleted`)
      .then(result => {
        var arrTen = [];
        for (var k = 0; k < result.length; k++) {
          arrTen.push(
            <MenuItem key={result[k].id} value={result[k].id}>
              {' '}
              {result[k].nickname}{' '}
            </MenuItem>
          );
        }
        this.setState({
          entity_opts: arrTen
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  // componentWillUnmount() {
  //   this.serverRequest.abort();
  // }

  validateForm() {
    var valid = true;
    if (this.state.eba_swift_state === '') {
      this.setState({eba_swift_state: 'error'});
      valid = false;
    }
    if (this.state.eb_bsb_state === '') {
      this.setState({eb_bsb_state: 'error'});
      valid = false;
    }
    if (this.state.eb_account_name_state === '') {
      this.setState({eb_account_name_state: 'error'});
      valid = false;
    }
    return valid;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  handleChange = event => {
    console.log(event.target.name + '<>' + event.target.value);
    this.setState({[event.target.name]: event.target.value});
  };

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'checkbox':
        if (event.target.checked) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-length':
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'url':
        if (this.verifyUrl(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'min-value':
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-value':
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'range':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      default:
        break;
    }
    switch (type) {
      case 'checkbox':
        this.setState({[stateName]: event.target.checked});
        break;
      default:
        this.setState({[stateName]: event.target.value});
        break;
    }
  }

  async handleSubmitForm() {
    // console.log(this.state); return;

    // if (this.validateForm()) {
    await this.props.createNewEntitybanks({
      eb_id: this.state.eb_id,
      e_id: this.state.e_id,
      eba_swift: this.state.eba_swift,
      eba_bsb: this.state.eba_bsb,
      eba_accout_name: this.state.eba_accout_name,
      eba_accout_number: this.state.eba_accout_number,
      eba_deleted: 0,
      reference_mandatory: this.state.reference_mandatory
    });

    this.props.selectEntitybanksUi('list');
  }

  handleCancel() {
    this.props.selectEntitybanksUi('list');
  }

  renderForm() {
    const {classes} = this.props;
    return (
      <GridItem xs={12} sm={6}>
        <h3>Enter details for new Entity Banks Account</h3>
        <form>
          <CustomInput
            success={this.state.eba_swift_state === 'success'}
            error={this.state.eba_swift_state === 'error'}
            labelText='Swift *'
            id='eba_swift'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.eba_swift,
              onChange: event => this.change(event, 'eba_swift', 'length', 0),
              type: 'text'
            }}
          />

          <FormControl fullWidth className={classes.formControl}>
            <Select value={this.state.eb_id} onChange={this.handleChange} input={<Input name='eb_id' id='eb_id' />}>
              {this.state.entitybanks_opts}
            </Select>
            <FormHelperText>Entity Banks</FormHelperText>
          </FormControl>
          <hr />
          <FormControl fullWidth className={classes.formControl}>
            <Select value={this.state.e_id} onChange={this.handleChange} input={<Input name='e_id' id='e_id' />}>
              {this.state.entity_opts}
            </Select>
            <FormHelperText>Entity</FormHelperText>
          </FormControl>
          <hr />

          <CustomInput
            success={this.state.eba_bsb_state === 'success'}
            error={this.state.eba_bsb_state === 'error'}
            labelText='BSB*'
            id='eba_bsb'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.eba_bsb,
              onChange: event => this.change(event, 'eba_bsb', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            success={this.state.eba_accout_name_state === 'success'}
            error={this.state.eba_accout_name_state === 'error'}
            labelText='Account Name*'
            id='eba_accout_name'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.eba_accout_name,
              onChange: event => this.change(event, 'eba_accout_name', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            success={this.state.eba_accout_number_state === 'success'}
            error={this.state.eba_accout_number_state === 'error'}
            labelText='Account Number*'
            id='eba_accout_number'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.eba_accout_number,
              onChange: event => this.change(event, 'eba_accout_number', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            success={this.state.reference_mandatory_state === 'success'}
            error={this.state.reference_mandatory_state === 'error'}
            labelText='Reference'
            id='reference_mandatory'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.reference_mandatory,
              onChange: event => this.change(event, 'reference_mandatory', 'length', 0),
              type: 'text'
            }}
          />
          <div className={classes.formCategory}>
            <small>*</small> Required fields
          </div>
          <Button color='rose' onClick={() => this.handleSubmitForm()} className={classes.registerButton}>
            Save
          </Button>
          <Button
            // color=""
            onClick={() => this.handleCancel()}
            className={classes.registerButton}
          >
            Cancel
          </Button>
        </form>
      </GridItem>
    );
  }

  render() {
    return (
      <div>
        <GridContainer>{this.renderForm()}</GridContainer>
      </div>
    );
  }
}

EntitybanksaccountNew.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(entityNewStyles)(EntitybanksaccountNew);
