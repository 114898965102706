import React from 'react';
import PropTypes from 'prop-types';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import {NavLink} from 'react-router-dom';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import Button from '../CustomButtons/Button';

// core components
import HeaderLinks from 'components/Header/HeaderLinks.jsx';
import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx';
import userAvatar from '../../assets/img/faces/user.png';
import {Divider} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {logOutUser} from "../../redux/actions";
import {connect} from "react-redux";

let ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }
  render() {
    const {className, user, headerLinks, links, brand} = this.props;
    return (
      <div className={className} ref='sidebarWrapper'>
        <ClearIcon
          style={{
            float: 'right',
            cursor: 'pointer',
            marginRight: '3%',
            marginTop: '3%'
          }}
          className={className.close}
        />
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openClients: this.activeRoute('/clients'),
      openTeams: this.activeRoute('/teams'),
      openTransfers: this.activeRoute('/transfers'),
      openBeneficiaries: this.activeRoute('/beneficiaries'),
      openInvoices: this.activeRoute('/invoices'),
      openIdentification: this.activeRoute('/identification'),
      openSettings: this.activeRoute('/settings'),
      openSystemNews: this.activeRoute('/news'),
      openSystemSettings: this.activeRoute('/system-settings'),
      openReports: this.activeRoute('/reports'),
      openKBankCode: this.activeRoute('/backoffice/kbank-code'),
      openHoldingAccounts: this.activeRoute('/holding-accounts'),
      openForwards: this.activeRoute('/forwards'),
      miniActive: true
    };
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1;
  }

  activeNestedRoute(props) {
    if (props.childRoutes != undefined) {
      if (this.props.location.pathname.indexOf(props.path) > -1) {
        return true;
      }
      const locationPath = this.props.location.pathname.split('/');
      // check child routes
      if (locationPath[1] === props.basePath) {
        return true;
      }
      return false;
    }
    return this.props.location.pathname.indexOf(props.path) > -1;
  }

  openCollapse(collapse) {
    const st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }
  logOut() {
    this.props.handleLogout();
    this.props.clearState();
  }
  render() {
    const {classes, color, image, routes, bgColor, rtlActive} = this.props;

    // Branding for top of sidebar (based on app_state.current_division
    // ************************************************************************************************
    let logo = require('../../assets/img/ForexWorldWide_Logo_160x150.png');
    let logoText = 'Forex WorldWide';
    let avatarImage = classes.withoutLogo;
    let avatar_image = userAvatar;
    let photo = classes.photo + ' ' + cx({[classes.photoRTL]: rtlActive});
    if (this.props.app_state.current_team) {
      const brandLogo = this.props.app_state.current_team.brand_sidebar_logo;
      photo = classes.photoLogo + ' ' + cx({[classes.photoRTL]: rtlActive});
      if (brandLogo) {
        logo = require(`../../assets/img/brand/${brandLogo}`);
        // console.log(logo)
        avatar_image = logo;
        switch (brandLogo) {
          case 'crocmedia.jpg':
            avatarImage = classes.withLogo1;
            break;
          case 'travel-logo.jpg':
            avatarImage = classes.withLogo2;
            break;
          case 'crocmedia_white.png':
            avatarImage = classes.withLogo3;
            break;
          case 'ForexWorldWide_Logo_160x150.png':
            avatarImage = classes.withLogo4;
            break;
          case 'GOSTUDY_PrimaryUsage_White.png':
            avatarImage = classes.withLogo5;
            break;
          case 'idta.png':
            avatarImage = classes.withLogo6;
            break;
          case '4QPFa.png':
            avatarImage = classes.logo4QPF;
            break;
          case 'skiaspen.png':
            avatarImage = classes.logoSkiaspen;
            break;
          default:
            avatarImage = classes.withoutLogo;
            break;
        }
      }
      if (this.props.app_state.current_team.team_shortname) {
        logoText = this.props.app_state.current_team.team_shortname;
      }
    }

    const super_admin = this.props.app_state.current_staff && this.props.app_state.current_staff.super_admin === 1;
    const backofficeStaff =
      this.props.app_state.current_staff && this.props.app_state.current_staff.backofficeStaff === 1;
    const itemText =
      classes.itemText +
      ' ' +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive
      });
    const userWrapperClass = classes.user + ' ' + cx({[classes.whiteAfter]: bgColor === 'white'});

    const currentStaffName = this.props.app_state && 
                              this.props.app_state.current_staff && 
                              this.props.app_state.current_staff.first_name + ' ' + this.props.app_state.current_staff.last_name;

    const user = this.props.current_user ? (
      <div className={userWrapperClass}>
        <div className={photo}>
          <img src={avatar_image} className={avatarImage + ' ' + classes.avatarImg} alt='...' />
        </div>
        <div style={{textAlign: 'center', fontWeight: 200}}>
          <em>Powered by Forex WorldWide</em>
        </div>
        <List className={classes.list}>
          <ListItem className={classes.item + ' ' + classes.userItem}>
              <ListItemText
                primary={`${this.props.app_state.current_team ? this.props.app_state.current_team.nickname : 'Loading...'}`}
                disableTypography={true}
                className={itemText + ' ' + classes.userItemText}
              />
          </ListItem>
          <ListItem className={classes.item + ' ' + classes.userItem}>
            <NavLink to='/settings/staffprofile' className={classes.itemLink + ' ' + classes.userCollapseButton}>
              
              <ListItemText
                primary={`${currentStaffName || 'Loading...'}`}
                disableTypography={true}
                style={{fontSize: '1.1rem'}}
                className={itemText + ' ' + classes.userItemText}
              />
              
              {/* <ListItemText
                primary={`${this.props.current_user.first_name} ${this.props.current_user.last_name}`}
                disableTypography={true}
                className={itemText + ' ' + classes.userItemText}
              /> */}

            </NavLink>
          </ListItem>
          <ListItem className={classes.item + ' ' + classes.userItem}>
            <NavLink
              to='/settings/staffprofile'
              className={classes.itemLink + ' ' + classes.userCollapseButton + ' ' + classes.textCenter}
            >
              <Button fullWidth={true} color='info' size='sm'>
                Manage my Account
              </Button>
            </NavLink>
          </ListItem>
          <ListItem className={classes.item + ' ' + classes.userItem}>
            <NavLink to='#' className={classes.itemLink + ' ' + classes.userCollapseButton + ' ' + classes.textCenter}>
              <Button fullWidth={true} color='info' size='sm' onClick={() => this.logOut()}>
                Sign Out
              </Button>
            </NavLink>
          </ListItem>
        </List>
      </div>
    ) : (
      <span />
    );

    const links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (this.props.app_state.current_staff == null) {
            return null;
          }
          if (prop.redirect) {
            return null;
          }
          if (prop.invisible) {
            return null;
          }

          if (prop.superAdmin && !super_admin) {
            return null;
          }
          if (prop.backOffice && !backofficeStaff) {
            return null;
          }
          if (prop.beta && !(this.props.app_state.current_staff.beta)) {
            return null;
          }
          if (prop.dev && !(this.props.app_state.current_staff.dev)) {
            return null;
          }

          if (prop.collapse) {
            const navLinkClasses =
              classes.itemLink + ' ' + cx({[' ' + classes.collapseActive]: this.activeRoute(prop.path)});
            const itemText =
              classes.itemText +
              ' ' +
              cx({
                [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: rtlActive
              });
            const collapseItemText =
              classes.collapseItemText +
              ' ' +
              cx({
                [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextRTL]: rtlActive
              });
            const itemIcon = classes.itemIcon + ' ' + cx({[classes.itemIconRTL]: rtlActive});
            const caret = classes.caret + ' ' + cx({[classes.caretRTL]: rtlActive});

            let menu_item_text = '';
            switch (this.props.language.language_current_ui) {
              case 'es':
                menu_item_text = prop.name_es || prop.name;
                break;
              case 'de':
                menu_item_text = prop.name_de || prop.name;
                break;
              case 'zh':
                menu_item_text = prop.name_zh || prop.name;
                break;
              case 'fr':
                menu_item_text = prop.name_fr || prop.name;
                break;
              case 'it':
                menu_item_text = prop.name_it || prop.name;
                break;
              case 'pt':
                menu_item_text = prop.name_pt || prop.name;
                break;
              default:
                menu_item_text = prop.name;
                break;
            }

            if (this.props.app_state.current_staff) {
              const staff = this.props.app_state.current_staff;

              if (
                (staff.menuShowGroups == false || staff.menuShowGroups == 0 || staff.menuShowGroups == undefined) &&
                menu_item_text == 'Groups'
              ) {
                return null;
              }
              if (
                (staff.menuShowHoldingAcc == false ||
                  staff.menuShowHoldingAcc == 0 ||
                  staff.menuShowHoldingAcc == undefined) &&
                menu_item_text == 'Holding Accounts'
              ) {
                return null;
              }
              if (
                (staff.menuShowInvoicing == false ||
                  staff.menuShowInvoicing == 0 ||
                  staff.menuShowInvoicing == undefined) &&
                menu_item_text == 'Invoicing'
              ) {
                return null;
              }
              if (
                (staff.menuShowReports == false || staff.menuShowReports == 0 || staff.menuShowReports == undefined) &&
                menu_item_text == 'Reports'
              ) {
                return null;
              }
              if (
                (staff.menuShowSettings == false ||
                  staff.menuShowSettings == 0 ||
                  staff.menuShowSettings == undefined) &&
                menu_item_text == 'Settings'
              ) {
                return null;
              }
            }

            return (
              <ListItem key={key} className={classes.item}>
                <NavLink to={'#'} className={navLinkClasses} onClick={() => this.openCollapse(prop.state)}>
                  <ListItemIcon className={itemIcon}>
                    {typeof prop.icon === 'string' ? <Icon>{prop.icon}</Icon> : <prop.icon />}
                  </ListItemIcon>
                  <ListItemText
                    // primary={prop.name}
                    primary={menu_item_text}
                    secondary={<b className={caret + ' ' + (this.state[prop.state] ? classes.caretActive : '')} />}
                    disableTypography={true}
                    className={itemText}
                  />
                </NavLink>
                <Collapse in={this.state[prop.state]} unmountOnExit>
                  <List className={classes.list + ' ' + classes.collapseList}>
                    {prop.views.map((prop, key) => {
                      if (prop.redirect) {
                        return null;
                      }
                      if (prop.invisible) {
                        return null;
                      }
                      if (prop.superAdmin && !super_admin) {
                        return null;
                      }
                      if (prop.backOffice && !backofficeStaff) {
                        return null;
                      }
                      if (prop.beta && !(this.props.app_state.current_staff.beta)) {
                        return null;
                      }

                      switch (prop.code) {
                        case 'transfer_create':
                          if (
                              !(this.props.app_state.current_team.brand_create_transfer === 1)
                          ) {
                              return null
                          }
                          break
                        case 'payment_request_create':
                        case 'payment_request_list':
                          if (
                              !(this.props.app_state.current_team.brand_paymentRequests === 1)
                          ) {
                              return null
                          }
                          break
                        case 'multipay_new':
                          if (
                              !(this.props.app_state.current_team.brand_multipay === 1) || 
                              !(this.props.app_state.current_staff.menuShowMultipay === 1)
                          ) {
                              return null
                          }
                          break
                        case 'splitpay_new':
                          if (
                              !(this.props.app_state.current_team.brand_splitpay === 1)
                          ) {
                              return null
                          }
                          break
                      }
            
                      let menu_item_text = '';
                      switch (this.props.language.language_current_ui) {
                        case 'es':
                          menu_item_text = prop.name_es || prop.name;
                          break;
                        case 'de':
                          menu_item_text = prop.name_de || prop.name;
                          break;
                        case 'zh':
                          menu_item_text = prop.name_zh || prop.name;
                          break;
                        case 'fr':
                          menu_item_text = prop.name_fr || prop.name;
                          break;
                        case 'it':
                          menu_item_text = prop.name_it || prop.name;
                          break;
                        case 'pt':
                          menu_item_text = prop.name_pt || prop.name;
                          break;
                        default:
                          menu_item_text = prop.name;
                          break;
                      }

                      const navLinkClasses =
                        classes.collapseItemLink + ' ' + cx({[' ' + classes[color]]: this.activeRoute(prop.path)});
                      const collapseItemMini =
                        classes.collapseItemMini + ' ' + cx({[classes.collapseItemMiniRTL]: rtlActive});
                      return (
                        <ListItem key={key} className={classes.collapseItem}>
                          <NavLink to={prop.path} className={navLinkClasses}>
                            <span className={collapseItemMini}>{prop.mini}</span>
                            <ListItemText
                              primary={menu_item_text}
                              disableTypography={true}
                              className={collapseItemText}
                            />
                          </NavLink>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
                <Divider className={classes.divider} />
              </ListItem>
            );
          }
          const navLinkClasses = classes.itemLink + ' ' + cx({[' ' + classes[color]]: this.activeNestedRoute(prop)});
          const itemText =
            classes.itemText +
            ' ' +
            cx({
              [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
              [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.itemTextRTL]: rtlActive
            });
          const itemIcon = classes.itemIcon + ' ' + cx({[classes.itemIconRTL]: rtlActive});
          let menu_item_text = '';
          switch (this.props.language.language_current_ui) {
            case 'es':
              menu_item_text = prop.name_es || prop.name;
              break;
            case 'de':
              menu_item_text = prop.name_de || prop.name;
              break;
            case 'zh':
              menu_item_text = prop.name_zh || prop.name;
              break;
            case 'fr':
              menu_item_text = prop.name_fr || prop.name;
              break;
            case 'it':
              menu_item_text = prop.name_it || prop.name;
              break;
            case 'pt':
              menu_item_text = prop.name_pt || prop.name;
              break;
            default:
              menu_item_text = prop.name;
              break;
          }
          if (this.props.app_state.current_staff) {
            const staff = this.props.app_state.current_staff;
            if (
              (staff.menuShowForwards == false || staff.menuShowForwards == 0 || staff.menuShowForwards == null) &&
              menu_item_text == 'Forwards'
            ) {
              return null;
            }
          }
          return (
            <ListItem key={key} className={classes.item}>
              <NavLink to={prop.path} className={navLinkClasses}>
                <ListItemIcon className={itemIcon}>
                  {typeof prop.icon === 'string' ? <Icon>{prop.icon}</Icon> : <prop.icon />}
                </ListItemIcon>
                <ListItemText
                  // primary={prop.name}
                  primary={menu_item_text}
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
              <Divider className={classes.divider} />
            </ListItem>
          );
        })}
      </List>
    );

    const logoNormal =
      classes.logoNormal +
      ' ' +
      cx({
        [classes.logoNormalSidebarMini]: this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive
      });
    const logoMini = classes.logoMini + ' ' + cx({[classes.logoMiniRTL]: rtlActive});
    const logoClasses = classes.logo + ' ' + cx({[classes.whiteAfter]: bgColor === 'white'});
    const drawerPaper =
      classes.drawerPaper +
      ' ' +
      cx({
        [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      ' ' +
      cx({
        [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1
      });
    return (
      <div ref='mainPanel'>
        <Hidden mdUp implementation='css'>
          <Drawer
            variant='temporary'
            anchor={rtlActive ? 'left' : 'right'}
            open={this.props.open}
            classes={{paper: drawerPaper + ' ' + classes[bgColor + 'Background']}}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={<HeaderLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div className={classes.background} style={{backgroundImage: 'url(' + image + ')'}} />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation='css'>
          <Drawer
            onMouseOver={() => this.setState({miniActive: false})}
            onMouseOut={() => this.setState({miniActive: true})}
            anchor={rtlActive ? 'right' : 'left'}
            variant='permanent'
            open
            classes={{paper: drawerPaper + ' ' + classes[bgColor + 'Background']}}
          >
            <SidebarWrapper className={sidebarWrapper} user={user} links={links} />
            {image !== undefined ? (
              <div className={classes.background} style={{backgroundImage: 'url(' + image + ')'}} />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {bgColor: 'blue'};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf(['white', 'red', 'orange', 'green', 'blue', 'purple', 'rose']),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = (state) => {
  return {};
}

const mapDispatchToProps = dispatch => {
  return {
    clearState: () => {
      dispatch(logOutUser());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(sidebarStyle)(Sidebar));
