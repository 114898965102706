import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// import TransfersList from "./TransfersList"; //Take out this import because of joint TransferListPast.jsx and TransferListPastContainers.js #1054
import {fetchTransferList, selectTransferUi} from '../../../redux/actions/transfers';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import clientsListStyles from "./ClientsListStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {FormattedMessage} from 'react-intl';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import Button from '../../../components/CustomButtons/Button';
import TransferListContainer from '../../../components/Transfers/TransferList';

const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - Copied from ClientsList - TransfersListPast
 * ----------------------------------------------------------------------------
 */
class TransfersListPast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    // console.log(this.props);
    const {classes} = this.props;
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id='transferList.cardItem1.transferListing' defaultMessage={`Transfer Listing `} />
              </h4>
              <div>
                <Button
                  round
                  color='primary'
                  onClick={() => this.props.history.push('/transfers/new')}
                >
                  <FormattedMessage id='newTransfer.cardItem1.createNew' defaultMessage={'Create New'} />
                </Button>
                <Button round color='info' onClick={() => this.props.fetchTransferList()}>
                  <FormattedMessage id='newTransfer.cardItem1.refresh' defaultMessage={'Refresh'} />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <TransferListContainer status='past' />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

TransfersListPast.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchTransferList: PropTypes.func
};

// export default withStyles(styles)(TransfersList);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers: state.transfers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTransferList: () => {
      dispatch(fetchTransferList());
    },
    selectTransferUi: data => {
      dispatch(selectTransferUi(data));
    }
  };
};

const TransfersListPastContainer = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TransfersListPast));

export default TransfersListPastContainer;
