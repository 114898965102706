import {fetchCountryListNotDeleted} from "../../../redux/actions/countries";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import ListTable from "../../../components/ListTable/ListTable";
import {Button, Card, Col, Flex, Form, Input, message, Row, Select} from "antd";
import {BiBuilding} from "react-icons/bi";
import React, {useEffect, useState} from "react";
import {useForm} from "antd/es/form/Form";
import {AppUrlConstants} from "../../../AppAPI";
import {BackOfficeAPI, RemittingAccountsFormConstants} from "./BackOfficeAPI";
import {withRouter} from "react-router-dom";

const styles = {
    marginRight1: {
        marginRight: '1em',
    },
    positionRelative: {
        position: 'relative'
    }
}
const RemittingAccountsEdit = ({ app_state, match, history }) => {

    const [form] = useForm();
    const [state, setState] = useState({
        modalTitle: 'Update Remitting Account #',
        formDataLoaded: true,
        dataList: [],
        entity: [],
        entityBankAccountList: [],
        countriesList: [],
        currencies: []
    });
    const updateState = (newState) => setState(prev => ({...state, ...newState}));
    const onSubmitBtnClick = (values) => {
        updateState({formDataLoaded: false});
        BackOfficeAPI.updateRemittingBankAccount(match.params.id, values).then(res => {
            message.success("Remitting bank account updated");
            updateState({formDataLoaded: true});
        }).catch(err => {
            console.log(err);
            updateState({formDataLoaded: true});
        })
    }

    const fetchInitials = async () => {
        const [countries, bankAccounts, remitAccounts, currencies, remittingAccount] = await Promise.all([
            AppUrlConstants.fetchCountries(),
            AppUrlConstants.fetchEntityBanks(),
            AppUrlConstants.fetchEntity(),
            AppUrlConstants.fetchCurrencies(),
            BackOfficeAPI.fetchRemittingBankAccountsById(match.params.id)
        ]);
        updateState({
            countriesList: countries.map(item => ({label: item.full_name, value: item.id})),
            entityBankAccountList: bankAccounts.map(item => ({label: item.nickname, value: item.id })),
            entity: remitAccounts.map(item => ({label: item.nickname, value: item.id })),
            currencies: currencies.map(item => ({label: item.iso_alpha_3, value: item.id}))
        })
            const formConstants = RemittingAccountsFormConstants;
            const values = Object.keys(formConstants)
                .reduce((acc, item) => {
                    acc[formConstants[item].name] = remittingAccount[formConstants[item].name];
                    return acc;
                }, {});
        console.log(values);
            form.setFieldsValue(values);
    }

    useEffect(() => {
        if (app_state && app_state.current_staff && app_state.current_staff.id != null) {
            fetchInitials();
        }
    }, [app_state]);

    return (<>
        <Card title={<>
            <Flex justify={'space-between'}>
                <h4><BiBuilding /> Update Bank Accounts</h4>
                <Button type={'primary'} onClick={() => {
                    history.push('/backoffice/remitting-accounts');
                }}>Back to Remitting Accounts</Button>
            </Flex>
        </>}>
           <Form layout={'horizontal'} labelCol={{ span: 6 }} name={'edit-remit-form'} form={form} onFinish={onSubmitBtnClick}>
               <Row gutter={16}>
                   <Col span={12}>
                       <Form.Item label={'Entity'} name="entity_id">
                           <Select allowClear={true}
                                   placeholder={'-- SELECT REMITTING ACCOUNT --'}
                                   showSearch={true}
                                   optionFilterProp={'label'}
                                   options={state.entity}></Select>
                       </Form.Item>
                       <Form.Item label={'Entity Bank Account'} name="entity_bank_id">
                           <Select allowClear={true}
                                   placeholder={'-- SELECT BANK ACCOUNT --'}
                                   showSearch={true}
                                   optionFilterProp={'label'}
                                   options={state.entityBankAccountList}></Select>
                       </Form.Item>
                       <Form.Item label={'Country'} name="address_country">
                           <Select allowClear={true}
                                   placeholder={'-- SELECT COUNTRY --'}
                                   optionFilterProp={'label'}
                                   showSearch={true}
                                   options={state.countriesList}></Select>
                       </Form.Item>
                       <Form.Item name="currency_id" label={'Currency'}>
                           <Select allowClear={true}
                                   placeholder={'-- SELECT CURRENCY --'}
                                   optionFilterProp={'label'}
                                   showSearch={true}
                                   options={state.currencies}></Select>
                       </Form.Item>
                   </Col>
               </Row>
               <hr />
               <hr />
               <Row gutter={16}>
                   <Col span={12}>
                       <Form.Item label={RemittingAccountsFormConstants.AccountName.label} name={RemittingAccountsFormConstants.AccountName.name}>
                           <Input />
                       </Form.Item>
                       <Form.Item label={RemittingAccountsFormConstants.SwiftCode.label} name={RemittingAccountsFormConstants.SwiftCode.name}>
                           <Input />
                       </Form.Item>
                       <Form.Item label={RemittingAccountsFormConstants.Nickname.label} name={RemittingAccountsFormConstants.Nickname.name}>
                           <Input />
                       </Form.Item>
                       <Form.Item label={RemittingAccountsFormConstants.EbaShortCode.label} name={RemittingAccountsFormConstants.EbaShortCode.name}>
                           <Input />
                       </Form.Item>
                       <Form.Item label={RemittingAccountsFormConstants.EbaRouting.label} name={RemittingAccountsFormConstants.EbaRouting.name}>
                           <Input />
                       </Form.Item>
                       <Form.Item label={RemittingAccountsFormConstants.EbaRouteName.label} name={RemittingAccountsFormConstants.EbaRouteName.name}>
                           <Input />
                       </Form.Item>
                       <Form.Item label={RemittingAccountsFormConstants.EbaRouteNumber.label} name={RemittingAccountsFormConstants.EbaRouteNumber.name}>
                           <Input />
                       </Form.Item>
                       <Form.Item label={RemittingAccountsFormConstants.Iban.label} name={RemittingAccountsFormConstants.Iban.name}>
                           <Input />
                       </Form.Item>
                       <Form.Item label={RemittingAccountsFormConstants.ReferenceMandatory.label} name={RemittingAccountsFormConstants.ReferenceMandatory.name}>
                           <Input />
                       </Form.Item>
                   </Col>
                   <Col span={12}>
                       <Form.Item label={RemittingAccountsFormConstants.AddressLine1.label} name={RemittingAccountsFormConstants.AddressLine1.name}>
                           <Input />
                       </Form.Item>
                       <Form.Item label={RemittingAccountsFormConstants.AddressLine2.label} name={RemittingAccountsFormConstants.AddressLine2.name}>
                           <Input />
                       </Form.Item>
                       <Form.Item label={RemittingAccountsFormConstants.AddressSuburb.label} name={RemittingAccountsFormConstants.AddressSuburb.name}>
                           <Input />
                       </Form.Item>
                       <Form.Item label={RemittingAccountsFormConstants.AddressState.label} name={RemittingAccountsFormConstants.AddressState.name}>
                           <Input />
                       </Form.Item>
                       <Form.Item label={RemittingAccountsFormConstants.AddressPostcode.label} name={RemittingAccountsFormConstants.AddressPostcode.name}>
                           <Input />
                       </Form.Item>
                   </Col>
               </Row>
               <Flex justify={'end'}>
                   <Button type="primary" htmlType={'submit'} loading={!state.formDataLoaded} size={'large'}>Update Form</Button>
               </Flex>
           </Form>
        </Card>
    </>)
}


const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        entities: state.entities
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCountryList: () => {
            dispatch(fetchCountryListNotDeleted());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(ListTable(RemittingAccountsEdit))));