import React, {useEffect, useState} from 'react';
import Card from '../../../Card/Card';
import {CardHeader} from '@material-ui/core';
import CardBody from '../../../Card/CardBody';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import {Button, Checkbox} from 'antd';
import {TrustComplianceConstants} from './ComplianceConstants';
import TextArea from 'antd/lib/input/TextArea';

export const TrustCompliance = ({state, onSelectedValuesChange, complianceData, onNoteChange, requiredFields}) => {

  const generalTrustOptions = TrustComplianceConstants.generalTrustOptions;
  const individualTrustee = TrustComplianceConstants.individualTrustee;
  const companyTrustee = TrustComplianceConstants.companyTrustee;
  const otherTrusteeOptions = TrustComplianceConstants.otherTrusteeOptions;

  const [selectedValues, setSelectedValues] = useState([]);
  const [trustComplianceNote, setTrustComplianceNote] = useState('');

  const onChange = (e) => {
    const { checked, value } = e.target;
    setSelectedValues((prevSelectedValues) => {
      if (checked) {
        const data = [...prevSelectedValues, value];
        onSelectedValuesChange({checkBoxOptions: data, note: trustComplianceNote, complianceType: 'TRUST'});
        return data;
      }
      const data = prevSelectedValues.filter((item) => item !== value);
      onSelectedValuesChange({checkBoxOptions: data, note: trustComplianceNote, complianceType: 'TRUST'});
      return data;
    });
  };

  const [buttonText, setButtonText] = useState('Save');
  const handleNoteChange = () => {
    setButtonText('Saving...');
    onNoteChange(trustComplianceNote);
    setTimeout(() => {
      setButtonText('Save');
    }, 3000);
  }

  useEffect(() => {
    setSelectedValues(complianceData.checkBoxOptions);
    setTrustComplianceNote(complianceData.note);
  }, [complianceData.checkBoxOptions, complianceData.note]);

  return (
    <React.Fragment>
      <Card className={'compliance-card'}>
        <CardHeader color='rose' icon>
          <h4>Trust</h4>
        </CardHeader>
        <CardBody className={'pt-0'}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h3>Australian Company</h3>
              <hr/>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <ul className={'compliance-list'}>
                    {
                      generalTrustOptions.map(option => (
                        <li><Checkbox disabled={(!(state && state.edit_mode))} checked={selectedValues.includes(option.value)} onChange={onChange} key={option.value} value={option.value}>
                          {option.label} {requiredFields.includes(option.value) ? <span style={{color: 'red'}}> *Required</span> : <></> }
                        </Checkbox></li>
                      ))
                    }
                  </ul>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h3>Individual Trustee</h3>
              <hr/>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <ul className={'compliance-list'}>
                    {
                      individualTrustee.map(option => (
                        <li>
                          <Checkbox disabled={(!(state && state.edit_mode))} checked={selectedValues.includes(option.value)} onChange={onChange} key={option.value} value={option.value}>
                            {option.label} {requiredFields.includes(option.value) ? <span style={{color: 'red'}}> *Required</span> : <></> }
                          </Checkbox>
                        </li>
                      ))
                    }
                  </ul>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h3>Company Trustee</h3>
              <hr/>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <ul className={'compliance-list'}>
                    {
                      companyTrustee.map(option => (
                        <li>
                          <Checkbox disabled={(!(state && state.edit_mode))} checked={selectedValues.includes(option.value)} onChange={onChange} key={option.value} value={option.value}>
                            {option.label} {requiredFields.includes(option.value) ? <span style={{color: 'red'}}> *Required</span> : <></> }
                          </Checkbox>
                        </li>
                      ))
                    }
                  </ul>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h3>Other</h3>
              <hr/>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <ul className={'compliance-list'}>
                    {
                      otherTrusteeOptions.map(option => (
                        <li>
                          <Checkbox disabled={(!(state && state.edit_mode))} checked={selectedValues.includes(option.value)} onChange={onChange} key={option.value} value={option.value}>
                            {option.label} {requiredFields.includes(option.value) ? <span style={{color: 'red'}}> *Required</span> : <></> }
                          </Checkbox>
                        </li>
                      ))
                    }
                  </ul>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h3>Note</h3>
              <hr/>
              <TextArea style={{backgroundColor: !state.edit_mode ? '#e0e0e0' : '#fff'}} value={trustComplianceNote} readOnly={(!(state && state.edit_mode))} rows={4} onChange={(e) => setTrustComplianceNote(e.target.value)}/>
              <Button disabled={buttonText === 'Saving...' || !state.edit_mode} type={'primary'} size={'small'} className="note-save-box mt-1" onClick={() => handleNoteChange() }> {buttonText}</Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}