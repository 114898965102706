import {API} from "aws-amplify";

export const BackOfficeAPI = {
    fetchRemittingBankAccounts: () => API.get("bank-accounts", "/remitting-accounts", {}),
    fetchRemittingBankAccountsById: (id) => API.get("bank-accounts", `/remitting-accounts/${id}`, {}),
    saveRemittingBankAccount: (payload) => API.post("bank-accounts", `/remitting-accounts/save`, { body: payload }),
    updateRemittingBankAccount: (id, payload) => API.post("bank-accounts", `/remitting-accounts/update/${id}`, { body: payload })
}

export const RemittingAccountsFormConstants = {
    RemittingAccount: { name: 'entity_id', label: 'Remitting Account' },
    AccountName: { name: 'eba_accout_name', label: 'Account Name' },
    EntityBankAccount: { name: 'entity_bank_id', label: 'Entity Bank Account' },
    Country: { name: 'country_id', label: 'Country' },
    Currency: { name: 'currency_id', label: 'Currency' },
    SwiftCode: { name: 'eba_swift', label: 'Swift Code' },
    Nickname: { name: 'nickname', label: 'Nickname' },
    EbaShortCode: { name: 'eba_shortcode', label: 'EBA Short Code' },
    EbaRouting: { name: 'eba_routing', label: 'EBA Routing' },
    EbaRouteName: { name: 'eba_route_name', label: 'EBA Route Name' },
    EbaRouteNumber: { name: 'eba_route_number', label: 'EBA Route Number' },
    Iban: { name: 'iban', label: 'IBAN' },
    ReferenceMandatory: { name: 'reference_mandatory', label: 'Reference Mandatory' },
    AddressLine1: { name: 'address_line_1', label: 'Address Line 1' },
    AddressLine2: { name: 'address_line_2', label: 'Address Line 2' },
    AddressSuburb: { name: 'address_suburb', label: 'Suburb' },
    AddressState: { name: 'address_state', label: 'State' },
    AddressPostcode: { name: 'address_postcode', label: 'Post Code' }
};