import React, {Fragment, useState, useEffect} from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import PermIdentity from '@material-ui/icons/PermIdentity';
import TransferDocumentsList from '../../../features/Transfers/documents/List.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';

import {FormattedMessage} from 'react-intl';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import {API} from 'aws-amplify';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */

const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const DocumentsListContainer = props => {
  const {classes, history} = props;

  const [transferDocumentsList, setTransferDocumentsList] = useState([]);

  const gotoCreateTransferDocumentsPage = () => {
    history.push('/transfers/documents/new');
  };

  const fetchTransferDocumentsList = () => {
    API.get('transfer-documents', '/list')
      .then(response => {
        setTransferDocumentsList(response);
      })
      .catch(error => {
        console.log(error);
        API.get('transfer-documents', '/list')
          .then(response => {
            setTransferDocumentsList(response);
          })
          .catch(error => {
            console.log(error);
          });
      });
  };

  useEffect(() => {
    fetchTransferDocumentsList();
  }, []);

  return (
    <Fragment>
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <PermIdentity />
          </CardIcon>
          <div className={classes.flexEnd}>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage
                id='transferDocuments.cardItem1.transferDocumentsList'
                defaultMessage={'Transfer Documents List'}
              />
            </h4>
            <Button round color='info' onClick={gotoCreateTransferDocumentsPage}>
              <FormattedMessage id='transferDocumentsList.createNew' defaultMessage={'Create New'} />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <TransferDocumentsList transfer_documents_list={transferDocumentsList} history={history} />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default withStyles(styles)(DocumentsListContainer);
