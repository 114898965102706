import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// import IdentificationList from "./IdentificationList.jsx";
import {fetchIdentificationListAll, selectIdentificationUi} from '../../../redux/actions/identification';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import blankPageStyle from "./BlankPageStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import Button from '../../../components/CustomButtons/Button';
import IdentificationListComponentContainer from '../../../components/Identification/IdentificationList';

import {FormattedMessage} from 'react-intl';

const idententificationListStyle = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientsList
 * ----------------------------------------------------------------------------
 */
class IdentificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // const { fetchBeneficiaryList } = this.props
    // console.log(this.props);
    // fetchBeneficiaryList();
  }

  render() {
    // console.log(this.props);
    const {classes} = this.props;
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage
                  id='identificationList.cardItem1.identificationListing'
                  defaultMessage={`Identification Management`}
                />
              </h4>
              <div>
                <Button
                  round
                  color='primary'
                  // onClick={() => this.props.history.push("/identification/list")}
                >
                  <FormattedMessage id='newIdentification.cardItem1.showAll' defaultMessage={`Show All`} />
                </Button>
                <Button round color='info' onClick={() => this.props.history.push('/identification/new')}>
                  <FormattedMessage id='newIdentification.cardItem1.createNew' defaultMessage={`Create New`} />
                </Button>
                <Button round color='info' onClick={() => this.props.fetchIdentificationListAll()}>
                  <FormattedMessage id='newIdentification.cardItem1.refresh' defaultMessage={`Refresh`} />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <IdentificationListComponentContainer />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

IdentificationList.propTypes = {
  classes: PropTypes.object.isRequired
};

// export default withStyles(idententificationListStyle)(IdentificationList);
const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    identification: state.identification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchIdentificationListAll: () => {
      dispatch(fetchIdentificationListAll());
    },
    selectIdentificationUi: data => {
      dispatch(selectIdentificationUi(data));
    }
  };
};

const IdentificationListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(idententificationListStyle)(IdentificationList));

export default IdentificationListContainer;
