import React from 'react';
import {connect} from 'react-redux';
import {selectEntityUi} from '../../../redux/actions/teams';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import entitiesStyles from "./EntitiesStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';
import Button from '../../../components/CustomButtons/Button';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import TeamEditContainer from '../../../components/Teams/TeamEdit';
import {withRouter} from 'react-router-dom';

const entitiesStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - Team Edit
 * ----------------------------------------------------------------------------
 */
class MyTeam extends React.Component {
  render() {
    const {classes} = this.props;
    if (!this.props.app_state.current_staff) {
      return null;
    }
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
                <h4 className={classes.cardIconTitle}>{this.props.app_state.current_team.nickname}</h4>
              <div>
                {/* <Button round color={'rose'}>
                  Edit Record
                </Button> */}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <TeamEditContainer team_id={this.props.app_state.current_staff.team_id} />
                {/* <TeamEditContainer /> */}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    teams: state.teams
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectEntityUi: value => {
      dispatch(selectEntityUi(value));
    }
  };
};

const MyTeamContainer = connect(mapStateToProps, mapDispatchToProps)(MyTeam);

export default withRouter(withStyles(entitiesStyles)(MyTeamContainer));
