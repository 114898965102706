
export const BackOfficeConstants = {
    COLUMNS: [
        {key: 'bank_name', dataIndex: 'bank_name', width: true, title: 'Bank Name', action: null},
        {key: 'aud', dataIndex: 'aud', width: true, title: 'AUD'},
        {key: 'usd', dataIndex: 'usd', width: true, title: 'USD'},
        {key: 'eur', dataIndex: 'eur', width: true, title: 'EUR'},
        {key: 'gbp', dataIndex: 'gbp', width: true, title: 'GBP'},
        {key: 'nzd', dataIndex: 'nzd', width: true, title: 'NZD'},
        {key: 'jpy', dataIndex: 'jpy', width: true, title: 'JPY'},
        {key: 'sgd', dataIndex: 'sgd', width: true, title: 'SGD'},
        {key: 'chf', dataIndex: 'chf', width: true, title: 'CHF'},
        {key: 'thb', dataIndex: 'thb', width: true, title: 'THB'},
        {key: 'hkd', dataIndex: 'hkd', width: true, title: 'HKD'},
        {key: 'cad', dataIndex: 'cad', width: true, title: 'CAD'},
        {key: 'sek', dataIndex: 'sek', width: true, title: 'SEK'},
        {key: 'nok', dataIndex: 'nok', width: true, title: 'NOK'},
        {key: 'dkk', dataIndex: 'dkk', width: true, title: 'DKK'},
        {key: 'npr', dataIndex: 'npr', width: true, title: 'NPR'},
        {key: 'fjd', dataIndex: 'fjd', width: true, title: 'FJD'},
        {key: 'image_token', dataIndex: 'image_token', width: true, title: ''},
        {key: 'action', dataIndex: 'action', width: true, title: ''},
    ],
    // Donot change these bank indexes
    BANK_LABELS: [
        'Natixis',
        'Investec',
        'Goldfields',
        'GlobalIME',
        'Crown Financial',
        'Macquarie Business Bank',
        'CBA (CommBiz)',
        'Moonova',
        'Great Southern Bank',
        'Macquarie Call Acct FSPORT',
        'Machhapuchchhre Bank',
        'Prabhu Bank',
        'Kaiskornbank',
        'Macquarie Call Acct FWW',
        'Worldpay',
        'Dion Ciavola ANZ Expense Acc',
    ]
}
export const BackOfficeUtil = {

}