import {
  CLIENTS_2_UPDATE_NEW_CLIENT_CREATION_EMAIL,
  CLIENTS_2_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME,
  CLIENTS_2_UPDATE_NEW_CLIENT_CREATION_LASTNAME,
  STAFF_SELECT_STAFF_MEMBER,
  TRANSFERS_2_FETCH_TRANSFER_LIST_REQUEST,
  TRANSFERS_2_FETCH_TRANSFER_LIST_SUCCESS,
  TRANSFERS_2_FETCH_TRANSFER_REQUEST,
  TRANSFERS_2_FETCH_TRANSFER_SUCCESS,
  TRANSFERS_2_NEW_TRANSFER_DATABASE_CLEAR,
  TRANSFERS_2_NEW_TRANSFER_DATABASE_CREATE_REQUEST,
  TRANSFERS_2_NEW_TRANSFER_DATABASE_CREATE_SUCCESS, TRANSFERS_2_NEW_TRANSFER_RESET,
  TRANSFERS_2_SELECT_SET_ID,
  TRANSFERS_2_SELECT_UI,
  TRANSFERS_2_START_NEW_TRANSFER_CREATION,
  TRANSFERS_2_UPDATE_NEW_TRANSFER_CREATION,
  TRANSFERS_2_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE
} from '../actions/transfers_2.js';

const initialState = {
  transfer_current_ui: 'list',
  is_fetching: false,
  did_invalidate: false,
  transfer_list: [],
  transfer_detail_fetching: false,
  transfer_detail_loaded: false,
  transfer_detail_id: null,
  new_transfer_data: {},
  new_transfer_type: null,
  new_transfer_submitted: false,
  new_transfer_created: false
};

function transfers_2(state = initialState, action) {
  switch (action.type) {
    case STAFF_SELECT_STAFF_MEMBER:
      return {
        ...state,
        staff_detail_id: action.staff_id
      };

    case TRANSFERS_2_FETCH_TRANSFER_LIST_REQUEST:
      return {
        ...state,
        is_fetching: true
      };

    case TRANSFERS_2_FETCH_TRANSFER_LIST_SUCCESS:
      return {
        ...state,
        is_fetching: false,
        transfer_list: action.transfer_list,
        lastUpdated: action.receivedAt
      };

    case TRANSFERS_2_SELECT_UI:
      return {
        ...state,
        transfer_current_ui: action.value
      };

    case TRANSFERS_2_SELECT_SET_ID:
      return {
        ...state,
        transfer_detail_id: action.value
      };

    case TRANSFERS_2_FETCH_TRANSFER_REQUEST:
      return {
        ...state,
        transfer_detail_fetching: true,
        transfer_detail_loaded: false
      };

    case TRANSFERS_2_FETCH_TRANSFER_SUCCESS:
      return {
        ...state,
        transfer_detail_fetching: false,
        transfer_detail_loaded: true
      };

    case TRANSFERS_2_START_NEW_TRANSFER_CREATION:
      return {
        ...state,
        new_transfer_submitted: false
      };

    case TRANSFERS_2_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE:
      return {
        ...state,
        new_transfer_type: action.value
      };
    case CLIENTS_2_UPDATE_NEW_CLIENT_CREATION_EMAIL:
      return {
        ...state,
        new_client_data: {
          ...state.new_client_data,
          email: action.value
        }
      };
    case CLIENTS_2_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME:
      return {
        ...state,
        new_client_data: {
          ...state.new_client_data,
          first_name: action.value
        }
      };
    case CLIENTS_2_UPDATE_NEW_CLIENT_CREATION_LASTNAME:
      return {
        ...state,
        new_client_data: {
          ...state.new_client_data,
          last_name: action.value
        }
      };
    case TRANSFERS_2_UPDATE_NEW_TRANSFER_CREATION:
      return {
        ...state,
        new_transfer_data: {
          ...state.new_transfer_data,
          [action.value.key]: action.value.value
        }
      };
    case TRANSFERS_2_NEW_TRANSFER_DATABASE_CREATE_REQUEST:
      return {
        ...state,
        new_transfer_submitted: true,
        new_transfer_created: false
      };
    case TRANSFERS_2_NEW_TRANSFER_DATABASE_CREATE_SUCCESS:
      return {
        ...state,
        new_transfer_submitted: false,
        new_transfer_data: {},
        new_transfer_created: true,
        transfer_detail_id: action.value
      };
    case TRANSFERS_2_NEW_TRANSFER_DATABASE_CLEAR:
      return {
        ...state,
        new_transfer_data: {}
      };

    case TRANSFERS_2_NEW_TRANSFER_RESET:
      return initialState;

    default:
      return state;
  }
}

export default transfers_2;
