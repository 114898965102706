import {connect} from 'react-redux';

import {selectCurrenciesUi} from '../../../redux/actions/currencies';

import Currencies from './Currencies';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    currencies: state.currencies
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectCurrenciesUi: value => {
      dispatch(selectCurrenciesUi(value));
    }
  };
};

const CurrenciesContainer = connect(mapStateToProps, mapDispatchToProps)(Currencies);

export default CurrenciesContainer;
