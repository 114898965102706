import {Card, CardHeader, Checkbox, FormControl, InputLabel, Typography} from '@material-ui/core';
import React from 'react';
import CardBody from '../../Card/CardBody';
import CustomInput from '../../CustomInput/CustomInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import Button from '../../CustomButtons/Button';
import {Check} from '@material-ui/icons';
import Datetime from 'react-datetime';

export const Personal = ({
  classes,
  handleSubmit,
  app_state,
  state,
  handleChange,
  setState,
  handleCustomReactSelectChange,
  handleToggle,
  referral_source_select_options,

  getFullDate
}) => {
  const select_country_select_options = state.country_list_prio.map(item => ({
    value: item.id,
    label: item.full_name
  }));
  const marital_status_select_options = state.marital_status_list
    .sort((a, b) => a.nickname.localeCompare(b.nickname))
    .map(item => ({
      value: item.id,
      label: item.nickname
    }));
  const language_select_options = state.language_id_list
    .sort((a, b) => a.nickname.localeCompare(b.nickname))
    .map(item => ({
      value: item.id,
      label: item.nickname
    }));

  return (
    <React.Fragment>
      <div style={{padding: 20}}>
        {/* <form onSubmit={handleSubmit}> */}
        <h4 className={classes.cardIconTitle}>Personal</h4>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card style={{height: '90%'}}>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Personal Details</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <InputLabel className={classes.label}>Date Of Birth</InputLabel>
                    <br />
                    <FormControl>
                      <Datetime
                        timeFormat={false}
                        dateFormat={'Do MMMM YYYY'}
                        inputProps={{
                          placeholder: getFullDate(new Date(state.date_of_birth)) || '',
                          disabled: !state.edit_mode
                        }}
                        onChange={event => {
                          let selDate = '';
                          if (event.toDate().getMonth() >= 9) {
                            if (event.toDate().getDate() > 9) {
                              selDate =
                                event.toDate().getFullYear() +
                                '-' +
                                (event.toDate().getMonth() + 1) +
                                '-' +
                                event.toDate().getDate() +
                                'T00:00.00.000Z';
                            } else {
                              selDate =
                                event.toDate().getFullYear() +
                                '-' +
                                (event.toDate().getMonth() + 1) +
                                '-0' +
                                event.toDate().getDate() +
                                'T00:00.00.000Z';
                            }
                          } else if (event.toDate().getMonth() < 9) {
                            if (event.toDate().getDate() > 9) {
                              selDate =
                                event.toDate().getFullYear() +
                                '-0' +
                                (event.toDate().getMonth() + 1) +
                                '-' +
                                event.toDate().getDate() +
                                'T00:00.00.000Z';
                            } else {
                              selDate =
                                event.toDate().getFullYear() +
                                '-0' +
                                (event.toDate().getMonth() + 1) +
                                '-0' +
                                event.toDate().getDate() +
                                'T00:00.00.000Z';
                            }
                          }
                          setState({date_of_birth: selDate});
                        }}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      labelText='Place Of Birth City'
                      id='place_of_birth_city'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: !state.edit_mode,
                        value: state.place_of_birth_city || '',
                        onChange: event => {
                          handleChange(event);
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <CustomReactSelect
                      label='Place Of Birth Country'
                      options={select_country_select_options}
                      value={state.place_of_birth_country}
                      onChange={handleCustomReactSelectChange('place_of_birth_country')}
                      isClearable={true}
                      isDisabled={!state.edit_mode}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomReactSelect
                      label='Nationality'
                      options={select_country_select_options}
                      value={state.nationality}
                      onChange={handleCustomReactSelectChange('nationality')}
                      isClearable={true}
                      isDisabled={!state.edit_mode}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <CustomReactSelect
                      label='Marital Status'
                      options={marital_status_select_options}
                      value={state.marital_status}
                      onChange={handleCustomReactSelectChange('marital_status')}
                      isClearable={true}
                      isDisabled={!state.edit_mode}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomReactSelect
                      label='Language'
                      options={language_select_options}
                      value={state.language_id}
                      onChange={handleCustomReactSelectChange('language_id')}
                      isClearable={true}
                      isDisabled={!state.edit_mode}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card style={{height: '90%'}}>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Occupation</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText='Occupation'
                  id='occupation'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: !state.edit_mode,
                    value: state.occupation || '',
                    onChange: event => {
                      handleChange(event);
                    }
                  }}
                />
                <CustomInput
                  labelText='Employer'
                  id='employer'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: !state.edit_mode,
                    value: state.employer || '',
                    onChange: event => {
                      handleChange(event);
                    }
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* <Button
                color="primary"
                disabled={!state.edit_mode}
                type="submit"
                className={classes.updateProfileButton}
              >
                Update Personal Details
              </Button>
            </form> */}
      </div>
    </React.Fragment>
  );
};
