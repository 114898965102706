import React, {createRef} from 'react';
import {connect} from 'react-redux';
import {API} from 'aws-amplify';
// react component for creating dynamic tables
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';
import {AiOutlineSearch} from 'react-icons/ai';
import HoldingAccountCreateNew from './HoldingAccountCreateNew';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import {CheckCircleTwoTone, CloseCircleTwoTone, EditOutlined, EyeOutlined} from '@ant-design/icons';
import {message, Button, Divider, Flex, Form, InputNumber, Modal, Radio, Select, Table} from 'antd'
import ListTable from '../ListTable/ListTable';
import FormItem from 'antd/lib/form/FormItem'
import {ArrayFormatterUtil} from '../../AppUtil'

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - IdentificationList
 * ----------------------------------------------------------------------------
 */
class HoldingAccountList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currencySelected: null,
            tableData: [],
            columns: [],
            loading: false,
            currencies: []
        };
    }

    componentDidMount() {
        this.fetchHoldingAccountList();
        console.log(this.props.client_id)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
            this.fetchHoldingAccountList()
        }
    }

    buildColumns = () => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '100px',
                sorter: (a, b) => a.id - b.id,
                ...this.props.getColumnSearchProps({
                    dataIndex: 'id',
                    label: 'ID',
                    render: (text, record) => {
                        return (
                            <>
                                <span style={{color: record.balance ? '#333' : '#bbb'}}>{text}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Ledger',
                dataIndex: 'feature_ledger',
                key: 'feature_ledger',
                width: '100px',
                filters: [
                    {text: 'Yes', value: 1},
                    {text: 'No', value: 0}
                ],
                onFilter: (value, record) => record.feature_ledger && record.feature_ledger === value,
                render: text => {
                    return (
                        <div
                            style={{
                                textAlign: 'center',
                                fontWeight: 800,
                                fontSize: '1.3rem'
                            }}
                        >
                            {text != null ? <CheckCircleTwoTone twoToneColor='#52c41a'/> :
                                <CloseCircleTwoTone color={'red'}/>}
                        </div>
                    );
                }
            },
            {
                title: 'Client',
                dataIndex: 'client_nickname',
                key: 'client_nickname',
                sorter: (a, b) => (a.client_nickname || '').localeCompare(b.client_nickname, undefined, {sensitivity: 'base'}),
                ...this.props.getColumnSearchProps({
                    dataIndex: 'client_nickname',
                    label: 'Client',
                    render: (text, record) => {
                        return (
                            <>
                                <span style={{color: record.balance ? '#333' : '#bbb'}}>{text}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Currency',
                dataIndex: 'currencies_short_name',
                key: 'currencies_short_name',
                filters: (this.state.currencies || []).map(item => ({text: item, value: item})),
                onFilter: (value, record) => record.currencies_short_name && record.currencies_short_name.startsWith(value),
                ...this.props.getColumnSearchProps({
                    dataIndex: 'currencies_short_name',
                    label: <span style={{textAlign: 'right'}}>'Currency'</span>,
                    filterInputType: 'SELECT',
                    render: (text, record) => {
                        return (
                            <>
                  <span style={{color: record.balance ? '#333' : '#bbb'}}>
                    <div
                        style={{marginLeft: 6}}
                        className={`currency-flag currency-flag-${text ? text.toLowerCase() : ''}`}
                    />
                      {' '}
                      {text}
                  </span>
                            </>
                        );
                    }
                })
            },
            this.props.app_state.current_staff && this.props.app_state.current_staff.id == 65 ? 
            {
                title: 'Beta Available',
                dataIndex: 'totalAvailable',
                key: 'totalAvailable',
                sorter: (a, b) => a.totalAvailable - b.totalAvailable,
                ...this.props.getColumnSearchProps({
                    dataIndex: 'totalAvailable',
                    label: 'Beta Available',
                    render: (text, record) => {
                        return (
                            <>
                                <div
                                    style={{
                                        textAlign: 'right',
                                        color: text ? '#333' : '#bbb'
                                    }}
                                >
                                    {new Intl.NumberFormat('en-GB', {
                                        style: 'currency',
                                        currency: record.currencies_short_name
                                    }).format(text || 0)}
                                </div>
                            </>
                        );
                    }
                })
            } : {},
            this.props.app_state.current_staff && this.props.app_state.current_staff.id == 65 ? 
            {
                title: 'Beta Balance',
                dataIndex: 'totalBalance',
                key: 'totalBalance',
                sorter: (a, b) => a.totalBalance - b.totalBalance,
                ...this.props.getColumnSearchProps({
                    dataIndex: 'totalBalance',
                    label: 'Beta Balance',
                    render: (text, record) => {
                        return (
                            <>
                                <div
                                    style={{
                                        textAlign: 'right',
                                        color: text ? '#333' : '#bbb'
                                    }}
                                >
                                    {new Intl.NumberFormat('en-GB', {
                                        style: 'currency',
                                        currency: record.currencies_short_name
                                    }).format(text || 0)}
                                </div>
                            </>
                        );
                    }
                })
            } : {},
            {
                title: 'Balance',
                dataIndex: 'balance',
                key: 'balance',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.balance - b.balance,
                ...this.props.getColumnSearchProps({
                    dataIndex: 'balance',
                    label: 'Balance',
                    render: (text, record) => {
                        return (
                            <>
                                <div
                                    style={{
                                        textAlign: 'right',
                                        color: text ? '#333' : '#bbb'
                                    }}
                                >
                                    {new Intl.NumberFormat('en-GB', {
                                        style: 'currency',
                                        currency: record.currencies_short_name
                                    }).format(text || 0)}
                                </div>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                width: '80px'
            }
        ];
    }

    fetchHoldingAccountList = async () => {
        this.setState({
            currencyTotals: {},
            tableData: [],
            allData: [],
        })

        let holding_account_list = []
        let currencyTotals = {}
        if (this.props.app_state.current_staff) {
            const staffID = this.props.app_state.current_staff.id
            this.setState({loading: true});
            if (this.props.client_id) {
                console.log(this.props.client_id)
                await API.get('holding_accounts', `/get/client_id/${this.props.client_id}`)
                    .then(response => {
                        console.log(response)
                        holding_account_list = response.holding_account_list;
                        this.setState({loading: false});
                    })
                    .catch(error => {
                        console.log(error);
                        this.fetchHoldingAccountList();
                        this.setState({loading: false});
                    });
            } else {
                await API.get('holding_accounts', `/get/staff/${staffID}`)
                    .then(response => {
                        holding_account_list = response.holding_account_list
                        currencyTotals = response.currencyTotals;
                        this.setState({loading: false});
                    })
                    .catch(error => {
                        console.log(error);
                        this.fetchHoldingAccountList();
                        this.setState({loading: false});
                    });
            }
        }

        if (this.state.currencySelected) {
            holding_account_list = holding_account_list.filter((holdingAccount) => {
                return holdingAccount.currencies_short_name === this.state.currencySelected
            })
        }

        if (holding_account_list.length) {
            const account = [];
            holding_account_list.map((prop, key) => {
                // console.log(prop)
                account.push({
                    id: prop.id,
                    client_id: prop.client_id,
                    client_nickname: prop.client_nickname,
                    feature_ledger: prop.client_feature_ledger || prop.brand_ledger,
                    currency_id: prop.currency_id,
                    currencies_short_name: prop.currencies_short_name,
                    currencies_full_name: prop.currencies_full_name,
                    balance: prop.balance || 0,
                    totalBalance: prop.totalBalance || 0.00,
                    totalAvailable: prop.totalAvailable || 0.00,
                    actions: (
                        <Flex align={'center'} gap={5}>
                            <Button icon={<EyeOutlined/>}
                                    onClick={() => this.props.history.push(`/holding-accounts/view/${prop.id}?clientId=${prop.client_id}`)}
                                    type='primary'>
                            </Button>
                            {
                                this.props.app_state && this.props.app_state.current_staff.backofficeStaff ?
                                <Button icon={<EditOutlined/>}
                                        onClick={() => {
                                            let isLoading = false;
                                            Modal.confirm({
                                                title: 'Change Beta Balances ?',
                                                okButtonProps: {
                                                    hidden: true
                                                },
                                                cancelButtonProps: {
                                                    hidden: true
                                                },
                                                maskClosable: true,
                                                content: <>
                                                    <Divider />
                                                    <Form initialValues={{ totalAvailable: prop.totalAvailable, totalBalance: prop.totalBalance }} name="form" labelAlign={'left'} wrapperCol={{ span: 16 }} labelCol={{ span: 8 }} onFinish={(values) => {
                                                        console.log(values);
                                                        values.staff_id = this.props.app_state.current_staff.id;
                                                        values.id = prop.id;
                                                        isLoading = true;
                                                        const loader = message.loading("Changing holding account balance", 0);
                                                        API.post("holding_accounts", "/update/holding-account-balance", {body: values})
                                                            .then(res => {
                                                                message.success("Holding Account Balance Changed");
                                                                Modal.destroyAll();
                                                                this.fetchHoldingAccountList();
                                                            })
                                                            .catch(err => {
                                                                console.log(err);
                                                                message.error("Holding account balance changed");
                                                            })
                                                            .finally(() => {
                                                                loader();
                                                                isLoading = false;
                                                            });
                                                    }}>
                                                        <Form.Item name="totalAvailable" label={'Beta Available'} rules={[{required: true, message: 'Required'}]}>
                                                            <InputNumber style={{ width: '100%' }} precision={2} decimalSeparator={'.'}/>
                                                        </Form.Item>
                                                        <Form.Item name="totalBalance" label={'Beta Balance'} rules={[{required: true, message: 'Required'}]}>
                                                            <InputNumber style={{ width: '100%' }} precision={2} decimalSeparator={'.'}/>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <Flex justify={'start'} gap={5}>
                                                                <Button type="primary" htmlType="submit" loading={isLoading}>Save</Button>
                                                                <Button type={'default'} onClick={() => Modal.destroyAll()}>Cancel</Button>
                                                            </Flex>
                                                        </Form.Item>
                                                    </Form>
                                                </>
                                            })
                                        }} type='primary' danger={true}>
                                </Button> : <></>
                            }
                        </Flex>
                    )
                });
            });

            const clientList = ArrayFormatterUtil.uniq(account, 'client_id');
            this.setState({
                currencyTotals: currencyTotals,
                tableData: account,
                allData: account,
                currencies: Object.keys(currencyTotals),
                clientList: clientList.sort((a, b) => (a.client_nickname || '').localeCompare(b.client_nickname)).map(item => ({
                    value: item.client_id,
                    label: item.client_nickname
                }))
            });
        }
    }

    getFilter = (filter, onChange) => {
        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <AiOutlineSearch/>
                    <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''}/>
                </div>
            </React.Fragment>
        );
    };

    onCurrencyClick = async (e) => {
        this.setState({
            currencySelected: e.target.value,
        });
        this.fetchHoldingAccountList()
    };

    onShowAllClick = () => {
        this.setState({
            currencySelected: null,
        });
        this.fetchHoldingAccountList()
    }

    onClientDropDownChange = (value) => {
        if (value == null) {
            this.setState({tableData: this.state.allData});
        } else {
            const data = this.state.allData.filter(item => item.client_id === value);
            this.setState({tableData: data});
        }
    }

    render() {
        const {classes} = this.props;
        const columns = this.buildColumns();
        let currencyTotals = []
        if (this.state.currencyTotals) {
            for (var key in this.state.currencyTotals) {
                if (this.state.currencyTotals.hasOwnProperty(key)) {
                    currencyTotals.push({
                        key: key,
                        value: this.state.currencyTotals[key]
                    })
                }
            }
        }

        let currencyButtons = []
        currencyTotals.map((currencyTotal, index) => {
            if (currencyTotal.key && (currencyTotal.value > 0 || currencyTotal.value < 0)) {
                currencyButtons.push(
                    <Radio.Button key={index} value={currencyTotal.key}>
                        <img
                            src={`https://wise.com/public-resources/assets/flags/rectangle/${currencyTotal.key.toLowerCase()}.png`}
                            width='16px'
                            height='16px'
                        />
                        <Divider type='vertical'/>
                        <strong>
                            {currencyTotal.key} {Number.parseFloat(currencyTotal.value).toFixed(2)}
                        </strong>
                    </Radio.Button>
                );
            }
        });

        return (
            <React.Fragment>
                <Flex justify={'space-between'}>
                    <FormItem label={'Search Client'}>
                        <Select
                            showSearch
                            style={{width: '200px'}}
                            allowClear
                            placeholder='Search Client'
                            onChange={e => this.onClientDropDownChange(e)}
                            filterOption={(input, option) =>
                                (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={this.state.clientList}
                        ></Select>
                    </FormItem>
                    <div>
                        <Radio.Group buttonStyle='solid' value={this.state.currencySelected}
                                     onChange={this.onCurrencyClick}>
                            {currencyButtons}
                        </Radio.Group>
                        <Divider type='vertical'/>
                        <Button onClick={this.onShowAllClick} type='primary'>
                            Show All
                        </Button>
                    </div>
                </Flex>
                <Table
                    dataSource={this.state.tableData}
                    columns={columns}
                    loading={this.state.loading}
                    className='-highlight'
                />
                {/* <p>
          {currencyTotals.map((currencyTotal, index) => {
            if (currencyTotal.key && currencyTotal.key != 'null' && currencyTotal.value > 0) return (
              <React.Fragment key={index}>
                {currencyTotal.key}: {Number.parseFloat(currencyTotal.value).toFixed(2)}
                <br />
              </React.Fragment>
            )
              })}
        </p> */}
                <HoldingAccountCreateNew/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        identification: state.identification
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

const HoldingAccountListContainer = connect(mapStateToProps, mapDispatchToProps)(HoldingAccountList);

export default withRouter(withStyles(styles)(ListTable(HoldingAccountListContainer)));
