import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {fetchBatchPaymentDetailsById} from '../../../redux/actions/batch_payments';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';
import {FormattedMessage} from 'react-intl';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import BatchPaymentDetailsPageComponent from '../../../features/Transfers/BatchPaymentDetails';
import {useParams} from 'react-router-dom';

const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  }
};

const BatchPaymentDetails = props => {
  const {classes, fetchBatchPaymentDetailsById, batch_payments} = props;
  const {id} = useParams();

  useEffect(() => {
    fetchBatchPaymentDetailsById(id);
  }, [fetchBatchPaymentDetailsById, id]);

  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardIcon color='rose'>
          <PermIdentity />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          <FormattedMessage
            id='batchPaymentsList.cardItem1.batchPaymentsListing'
            defaultMessage={'Batch Payment Details'}
          />
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            {batch_payments.is_fetching ? (
              'Loading...'
            ) : (
              <BatchPaymentDetailsPageComponent batch_payment_details={batch_payments.batch_payment_details} />
            )}
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

BatchPaymentDetails.propTypes = {
  fetchTransferList: PropTypes.func
};

const mapStateToProps = state => {
  return {
    batch_payments: state.batch_payments
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchBatchPaymentDetailsById: id => {
      dispatch(fetchBatchPaymentDetailsById(id));
    }
  };
};

const BatchPaymentsContainer = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BatchPaymentDetails));

export default BatchPaymentsContainer;
