import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';

import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import {FormattedMessage} from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import {API} from 'aws-amplify';
import CustomReactSelect from '../Forms/CustomReactSelect/CustomReactSelect';

const staffEditStyles = {
  ...switchStyles,
  ...selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },
  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class TransfersUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      is_loading: true,
      team_id: '',
      beneficiary_id: '',
      purpose: '',

      data: [],
      teams_list: [],
      beneficiaries_list: [],
      purpose_list: [],
      account_currency: '',

      bulkClient: [
        {
          first_name: '',
          last_name: '',
          email: '',
          mobile: '',
          amount: '',
          detail: '',
          first_name_state: '',
          last_name_state: '',
          email_state: '',
          mobile_state: '',
          amount_state: '',
          detail_state: ''
        }
      ]
    };
  }

  componentDidMount() {
    API.get('teams', `/get-list-not-deleted`)
      .then(response => {
        this.setState({
          teams_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });

    // API.get("transfers", `/get_purpose/id/9`)
    //   .then(response => {
    //     this.setState({
    //       purpose_list: response
    //     });
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
      API.get('transfers', `/get_purpose/id/${this.props.app_state.current_staff.division_id}`)
        .then(response => {
          // console.log(response);
          this.setState({
            purpose_list: response
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  verifyCognitoPassword(value) {
    var passwordRex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/;
    if (passwordRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyCognitoPassword(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
  }

  fileHandler = event => {
    var XLSX = require('xlsx');
    let fileObj = event.target.files[0];
    var reader = new FileReader();
    reader.readAsBinaryString(event.target.files[0]);

    reader.onload = e => {
      var workbook = XLSX.read(e.target.result, {type: 'binary'});
      const wsname = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[wsname];
      const json_data = XLSX.utils.sheet_to_json(worksheet, {header: 1});

      this.setState({['data']: json_data});
      if (json_data.length > 0) {
        for (let i = 0; i < json_data.length; i++) {
          // if(i == 0) continue;
          if (i > 0) {
            this.setState(prevState => ({
              bulkClient: [
                ...prevState.bulkClient,
                {
                  first_name: '',
                  last_name: '',
                  email: '',
                  mobile: '',
                  amount: '',
                  detail: '',
                  first_name_state: '',
                  last_name_state: '',
                  email_state: '',
                  mobile_state: '',
                  amount_state: '',
                  detail_state: ''
                }
              ]
            }));
          }
        }
        let bulkClient = [...this.state.bulkClient];
        for (let i = 0; i < json_data.length; i++) {
          // if(i == 0) continue;
          bulkClient[i] = {...bulkClient[i], ['first_name']: json_data[i][0]};
          bulkClient[i] = {...bulkClient[i], ['last_name']: json_data[i][1]};
          bulkClient[i] = {...bulkClient[i], ['email']: json_data[i][2]};
          bulkClient[i] = {...bulkClient[i], ['mobile']: json_data[i][3]};
          bulkClient[i] = {...bulkClient[i], ['amount']: json_data[i][4]};
          bulkClient[i] = {...bulkClient[i], ['detail']: json_data[i][5]};
        }
        this.setState({bulkClient});
      }
    };
  };

  handleChange(e, i, stateName) {
    const {name, value} = e.target;
    let bulkClient = [...this.state.bulkClient];

    bulkClient[i] = {...bulkClient[i], [stateName]: value};
    bulkClient[i] = {...bulkClient[i], [stateName + '_state']: 'success'};

    this.setState({bulkClient});
  }

  createUI() {
    const {classes} = this.props;

    return (
      <div key={556}>
        <Card>
          <CardHeader icon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id='Upload details for Transfers' defaultMessage={`Upload details for Transfers`} />
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify='left'>
              <GridItem xs={12} md={3}>
                <input type='file' style={{padding: '10px'}} onChange={this.fileHandler} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }

  showUploadData() {
    const {classes} = this.props;

    return this.state.bulkClient.map((el, i) => {
      if (i == 0)
        return (
          <div key={i}>
            <Card>
              <CardBody>
                <GridContainer justify='left'>
                  <GridItem xs={12} md={2}>
                    {el.first_name}
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    {el.last_name}
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    {el.email}
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    {el.mobile}
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    {el.amount}
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    {el.detail}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </div>
        );

      return (
        <div key={i}>
          <Card>
            <CardBody>
              <GridContainer justify='left'>
                <GridItem xs={12} md={2}>
                  <CustomInput
                    success={el.first_name_state === 'success'}
                    error={el.first_name_state === 'error'}
                    labelText={<span>First Name</span>}
                    id='first_name'
                    name='first_name'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: el.first_name ? el.first_name : '',
                      onChange: event => this.handleChange(event, i, 'first_name')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={2}>
                  <CustomInput
                    success={el.last_name_state === 'success'}
                    error={el.last_name_state === 'error'}
                    labelText={<span>Last Name</span>}
                    id='last_name'
                    name='last_name'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: el.last_name ? el.last_name : '',
                      onChange: event => this.handleChange(event, i, 'last_name')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={2}>
                  <CustomInput
                    success={el.email_state === 'success'}
                    error={el.email_state === 'error'}
                    labelText={<span>Email</span>}
                    id='email'
                    name='email'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: el.email ? el.email : '',
                      onChange: event => this.handleChange(event, i, 'email')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={2}>
                  <CustomInput
                    success={el.mobile_state === 'success'}
                    error={el.mobile_state === 'error'}
                    labelText={<span>Mobile</span>}
                    id='mobile'
                    name='mobile'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: el.mobile ? el.mobile : '',
                      onChange: event => this.handleChange(event, i, 'mobile')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={2}>
                  <CustomInput
                    success={el.amount_state === 'success'}
                    error={el.amount_state === 'error'}
                    labelText={<span>Amount</span>}
                    id='amount'
                    name='amount'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: el.amount ? el.amount : '',
                      onChange: event => this.handleChange(event, i, 'amount')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={2}>
                  <CustomInput
                    success={el.detail_state === 'success'}
                    error={el.detail_state === 'error'}
                    labelText={<span>Detail</span>}
                    id='detail'
                    name='detail'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: el.detail ? el.detail : '',
                      onChange: event => this.handleChange(event, i, 'detail')
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </div>
      );
    });
  }

  handleCustomReactSelectChange = name => value => {
    var option_value;
    if (value === null) {
      option_value = null;
    } else {
      option_value = value.value;
    }
    this.setState({
      [name]: option_value
    });

    if (name == 'team_id') {
      API.get('beneficiaries', `/get/by_team_id/${option_value}`)
        .then(response => {
          this.setState({
            beneficiaries_list: response
          });
        })
        .catch(error => {
          console.log(error);
        });

      for (let index = 0; index < this.state.teams_list.length; index++) {
        if (option_value == this.state.teams_list[index].id) {
          this.setState({
            division_id: this.state.teams_list[index].division_id
          });

          API.get('transfers', `/get_purpose/id/${this.state.teams_list[index].division_id}`)
            .then(response => {
              this.setState({
                purpose_list: response
              });
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    }
    if (name == 'beneficiary_id') {
      for (let index = 0; index < this.state.beneficiaries_list.length; index++) {
        if (option_value == this.state.beneficiaries_list[index].id) {
          this.setState({
            account_currency: this.state.beneficiaries_list[index].account_currency
          });
        }
      }
    }
  };

  async save_pending_transfers(transfer_detail) {
    return API.put('transfers_pending', `/bulk/upload`, {
      body: transfer_detail
    });
  }

  isValidated() {
    let bulkClient = [...this.state.bulkClient];
    let return_flag = true;

    if (this.state.team_id == '') {
      return_flag = false;
    }

    if (this.state.beneficiary_id == '') {
      return_flag = false;
    }

    if (this.state.purpose == '') {
      return_flag = false;
    }

    bulkClient.map(el => {
      if (el.first_name == '') {
        el.first_name_state = 'error';
        return_flag = false;
      } else {
        el.first_name_state = 'success';
      }
      if (el.last_name == '') {
        el.last_name_state = 'error';
        return_flag = false;
      } else {
        el.last_name_state = 'success';
      }
      if (el.email == '') {
        el.email_state = 'error';
        return_flag = false;
      } else {
        el.email_state = 'success';
      }
      if (el.mobile == '') {
        el.mobile_state = 'error';
        return_flag = false;
      } else {
        el.mobile_state = 'success';
      }
      if (el.amount == '') {
        el.amount_state = 'error';
        return_flag = false;
      } else {
        el.amount_state = 'success';
      }
      this.setState({
        bulkClient: bulkClient
      });
    });

    return return_flag;
  }

  async handleSubmit() {
    console.log(this.state);
    // return; // BULK UPLOAD OF TRANSFER NEEDS TO USE THE SAME API AS BULK CREATE (TransfersBulk.jsx)
    if (this.isValidated()) {
      this.setState({isLoading: true});
      try {
        await this.save_pending_transfers({
          bulk_transfer: this.state.bulkClient,
          account_currency: this.state.account_currency,
          team_id: this.state.team_id,
          beneficiary_id: this.state.beneficiary_id,
          purpose_of_payment_detail: this.state.purpose
        });
        this.props.history.push(`/transfers/list-pending`);
      } catch (e) {
        this.props.history.push(`/transfers/list-pending`);
      }
    }
  }

  render() {
    const {classes} = this.props;

    if (this.state.isLoading) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }

    const team_list_select_options = this.state.teams_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));

    const beneficiary_list_select_options = this.state.beneficiaries_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return {value: item.id, label: item.nickname};
      });

    const purpose_list_select_options = this.state.purpose_list
      .sort((a, b) => a.description.localeCompare(b.description))
      .map(item => ({
        value: item.id,
        label: item.description
      }));

    return (
      <div>
        <GridContainer justify='center'>
          <GridItem xs={6} md={2}>
            <CustomReactSelect
              label='Team'
              options={team_list_select_options}
              value={this.state.team_id}
              onChange={this.handleCustomReactSelectChange('team_id')}
              error={this.state.team_id === ''}
              isClearable={true}
            />
          </GridItem>
          <GridItem xs={6} md={2}>
            <CustomReactSelect
              label='Purpose'
              options={purpose_list_select_options}
              value={this.state.purpose}
              onChange={this.handleCustomReactSelectChange('purpose')}
              error={this.state.purpose === ''}
              isClearable={true}
            />
          </GridItem>
          <GridItem xs={6} md={2}>
            <CustomReactSelect
              label='Beneficiary'
              options={beneficiary_list_select_options}
              value={this.state.beneficiary_id}
              onChange={this.handleCustomReactSelectChange('beneficiary_id')}
              error={this.state.beneficiary_id === ''}
              isClearable={true}
            />
          </GridItem>
        </GridContainer>
        {this.createUI()}
        {this.showUploadData()}
        <Button color='rose' onClick={() => this.handleSubmit()} className={classes.registerButton}>
          Save
        </Button>
      </div>
    );
  }
}

TransfersUpload.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(staffEditStyles)(TransfersUpload));
