import {AppUtil} from '../../AppUtil';

const HoldingAccountUtil = {
  initialValues: (state) => {
    return {
      emailReceipt: false,
      balance_before: state.holding_account.balance
        ? new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: state.holding_account.currencies_short_name
        }).format(state.holding_account.balance)
        : ' ',
      less_debit: state.debit
        ? new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: state.holding_account.currencies_short_name
        }).format(state.debit)
        : ' ',
      plus_credit: state.credit
        ? new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: state.holding_account.currencies_short_name
        }).format(state.credit)
        : ' ',
      new_balance: state.new_holding_account_balance
        ? new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: state.holding_account.currencies_short_name
        }).format(state.new_holding_account_balance)
        : ' '
    }
  },
  formatCurrency: (balance, countryCode) => {
    return balance
      ? new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: countryCode
      }).format(balance)
      : 0;
  },
  prepareRequestBody: (accountId, values, action) => {
    return {
      holding_account_id: accountId,
      transaction_datetime: AppUtil.handle_transaction_date_change(values.transaction_date),
      memo: values.description,
      debit: action === 'debit' ? values.amount : 0,
      credit: action === 'credit' ? values.amount : 0,
      emailReceipt: values.emailReceipt,
      memo_internal: values.memo_internal
    }
  },
  prepareEmailRequestBody: (lineItemId, state, values) => {
    return {
      holdingAccount: state.holding_account,
      holdingAccountLineItemId: lineItemId,
      transactionDateTime: AppUtil.handle_transaction_date_change(values.transaction_date),
      memo: values.description,
      credit: values.amount,
      newBalance: values.hidden_new_balance
    };
  }
}

export default HoldingAccountUtil;