import React from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import MultiPayTransferStep2Container from './Step2Transfer';

const Step2TransferPayingTo = props => {
  return (
    <GridContainer>
      <GridItem xs={12}>
        <MultiPayTransferStep2Container {...props} refs={props.stepRefs} />
      </GridItem>
    </GridContainer>
  );
};



export default Step2TransferPayingTo;
