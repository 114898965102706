import React from 'react';
import {API} from 'aws-amplify';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {updateNewTransferCreation} from '../../../redux/actions/transfers_pending';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react';
import CustomReactSelect from '../../Forms/CustomReactSelect/CustomReactSelect';
import queryString from 'query-string';
// import { connect } from "net";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Datetime from 'react-datetime';

const axios = require('axios');
const moment = require('moment');

const style = {
  ...customCheckboxRadioSwitch,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  }
};

const min_transfer_amount = 0;

class Step2Transfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from_currency: '',
      to_currency: '',
      from_amount: '',
      to_amount: '',

      from_currency_state: 'error',
      to_currency_state: 'error',
      from_amount_state: 'error',
      to_amount_state: 'error',

      beneficiaries_list: [],
      beneficiary_id: '',
      beneficiary: {},
      purpose_of_payment_detail_state: '',
      amount_to_state: '',
      currencies_list: [],
      currencies_list_priority: [],

      purpose_of_payment_list: [],

      clientTransfers: [
        {
          beneficiary_id: '',
          amount_to: '',
          purpose_of_payment_detail: '',
          beneficiary_id_state: '',
          amount_to_state: '',
          purpose_of_payment_detail_state: '',
          detail_1: '',
          detail_2: '',
          detail_3: '',
          detail_1_state: '',
          detail_2_state: '',
          detail_3_state: '',
          document: '',
          purpose_of_payment_other: '',
          purpose_of_payment_other_state: ''
        }
      ]
    };
  }

  componentDidMount() {
    // API.get("currencies", `/list_not_deleted`)
    //   .then(response => {
    //     this.setState(
    //       {
    //         currencies_list_priority: response
    //       },
    //       () => {
    //         // this.queryStrings();
    //       }
    //     );
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
    // this.set_payment_radio("bank");
    // API.get("beneficiaries", `/beneficiaries/get`)
    //   .then(response => {
    //     this.setState({
    //       beneficiaries_list: response
    //     });
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.transfers_pending.new_transfer_data.client_id !==
      this.props.transfers_pending.new_transfer_data.client_id
    ) {
      await API.get(
        'beneficiaries',
        `/get/by_team_id/${
          // this.props.transfers_pending.new_transfer_data.client_id
          this.props.app_state.current_team.id
        }`
      )
        .then(response => {
          console.table(response);
          // let filterer_beneficiaries_list = response;
          // if (filterer_beneficiaries_list.length > 0) {
          //   for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
          //     if (
          //       filterer_beneficiaries_list[i].account_currency !== this.props.transfers_pending.new_transfer_data.currency_to_id
          //     ) {
          //       filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
          //     }
          //   }
          // }

          this.setState({
            // beneficiaries_list: response
            beneficiaries_list: response
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  // queryStrings() {
  //   // console.log("===== transfer to queryStrings =====")
  //   const query_strings = queryString.parse(this.props.location.search);
  //   if (Object.keys(query_strings).length !== 0) {
  //     if (query_strings.client_id) {
  //       if (query_strings.from_amount) {
  //         this.getClientDetails(
  //           Number(query_strings.client_id),
  //           "handleToAmount",
  //           query_strings
  //         );
  //       }
  //       if (query_strings.to_amount) {
  //         this.getClientDetails(
  //           Number(query_strings.client_id),
  //           "handleFromAmount",
  //           query_strings
  //         );
  //       }
  //     }
  //   }
  // }

  // getClientDetails(client_id, option, query_strings) {
  //   API.get("clients", `/get/id/${client_id}`)
  //     .then(response => {
  //       this.props.updateNewTransferCreation("client", response);
  //       if (option === "handleToAmount") {
  //         this.setState(
  //           {
  //             from_currency: Number(query_strings.from_currency),
  //             from_currency_state: "success",
  //             to_currency: Number(query_strings.to_currency),
  //             to_currency_state: "success",
  //             from_amount: query_strings.from_amount,
  //             from_amount_state: "success",
  //             last_user_type_amount_name: "from_amount"
  //           },
  //           () => {
  //             this.handleAmount("to_amount");
  //           }
  //         );
  //       }
  //       if (option === "handleFromAmount") {
  //         this.setState(
  //           {
  //             from_currency: Number(query_strings.from_currency),
  //             from_currency_state: "success",
  //             to_currency: Number(query_strings.to_currency),
  //             to_currency_state: "success",
  //             to_amount: query_strings.to_amount,
  //             to_amount_state: "success",
  //             last_user_type_amount_name: "to_amount"
  //           },
  //           () => {
  //             this.handleAmount("from_amount");
  //           }
  //         );
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }

  isEmpty(str) {
    return !str || 0 === str.length;
  }

  async addClick() {
    await this.setState(prevState => ({
      clientTransfers: [
        ...prevState.clientTransfers,
        {
          beneficiary_id: '',
          amount: '',
          purposeOfPayment: '',
          beneficiary_id_state: '',
          amount_state: '',
          purposeOfPayment_state: '',
          detail_1: '',
          detail_2: '',
          detail_3: '',
          detail_1_state: '',
          detail_2_state: '',
          detail_3_state: '',
          document: '',
          purpose_of_payment_other: '',
          purpose_of_payment_other_state: '',
          beneficiary: {},
          showDocumentUpload: false
        }
      ]
    }));

    this.props.updateNewTransferCreation('multiple_beneficiary_id', this.state.clientTransfers);
  }

  removeClick(i) {
    let clientTransfers = [...this.state.clientTransfers];
    clientTransfers.splice(i, 1);
    this.setState({clientTransfers});

    let total_amount = 0;
    let tmpValue = 0;

    clientTransfers.map((el, index) => {
      if (el.amount !== undefined) {
        tmpValue = el.amount.replace(/,/g, '');
        total_amount = Number.parseFloat(total_amount) + Number.parseFloat(tmpValue);
      }
    });
    this.setState({['amount_to']: total_amount});
    this.props.updateNewTransferCreation('amount_to', total_amount);
    // this.props.updateNewTransferCreation("multiple_beneficiary_id", this.state.clientTransfers);
    this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
  }

  getBeneficiaryDetails(beneficiary_id) {
    API.get('beneficiaries', `/get/id/${beneficiary_id}`)
      .then(async response => {
        console.log(response);
        this.setState({
          beneficiary: response
        });
        this.props.updateNewTransferCreation('beneficiary', response);
        this.props.updateNewTransferCreation('currency_to_id', response.account_currency);

        // this.setState({
        //   beneficiary: Object.assign({}, this.state.beneficiary, {
        //     account_currency_nickname: `${response[0].full_name} [${
        //       response[0].iso_alpha_3
        //       }]`
        //   })
        // });

        this.props.updateNewTransferCreation('currency_to_id', response.account_currency);
        this.props.updateNewTransferCreation('currency_to_state', 'success');
        await API.get('currencies', `/get/${response.account_currency}`)
          .then(response => {
            this.props.updateNewTransferCreation('currency_to', response);
            this.props.updateNewTransferCreation('currency_to_name', response.iso_alpha_3);
          })
          .catch(error => {
            console.log(error);
          });

        return response;
      })
      // .then(response => {
      //   API.get("system", `/currencies/get/${response[0].account_currency}`)
      //     .then(response => {
      //       this.setState({
      //         beneficiary: Object.assign({}, this.state.beneficiary, {
      //           account_currency_nickname: `${response.full_name} [${
      //             response.iso_alpha_3
      //             }]`
      //         })
      //       });
      //       this.props.updateNewTransferCreation("currency_to_id", response.id);
      //       this.props.updateNewTransferCreation("currency_to", response);

      //       API.post("email", `/interaction/main`, {
      //         body: {
      //           interaction: "New Transfer Request Step 2 PayingTo",
      //           data: {
      //             client_id: this.props.app_state.current_client.id,
      //             client_nickname: this.props.app_state.current_client.nickname,
      //             beneficiary_id: this.state.beneficiary.id,
      //             beneficiary_nickname: this.state.beneficiary.nickname
      //           },
      //           result: "New transfer request proceeding"
      //         }
      //       });
      //     })
      //     .catch(error => {
      //       console.log(error);
      //     });
      // })
      .catch(error => {
        console.log(error);
      });
  }

  // fetchNewRate = () => {
  //   if (
  //     this.props.transfers_2.new_transfer_data.to_currency &&
  //     this.props.transfers_2.new_transfer_data.from_currency
  //   ) {
  //     let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`;
  //     axios
  //       .get(url, {
  //         params: {
  //           currencies: this.props.transfers_2.new_transfer_data.from_currency
  //             .iso_alpha3,
  //           source: this.props.transfers_2.new_transfer_data.to_currency
  //             .iso_alpha3,
  //           format: "1"
  //         }
  //       })
  //       .then(response => {
  //         let key = `${this.props.transfers_2.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}${this.props.transfers.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}`;
  //         let rate_string = response.data.quotes[key];

  //         let amount_to = Number.parseFloat(
  //           this.props.transfers_2.new_transfer_data.amount_to
  //         );
  //         let rate = Number.parseFloat(rate_string);
  //         let rate_adjust =
  //           Number.parseFloat(
  //             this.props.app_state.current_client.default_rate_division
  //           ) +
  //           Number.parseFloat(
  //             this.props.app_state.current_client.default_rate_entity
  //           ) +
  //           Number.parseFloat(
  //             this.props.app_state.current_client.default_rate_team
  //           ) +
  //           Number.parseFloat(
  //             this.props.app_state.current_client.default_rate_staff
  //           );
  //         let adjusted_rate = rate + rate * rate_adjust;

  //         let amount_from = amount_to * adjusted_rate;

  //         this.props.updateNewTransferCreation(
  //           "current_rate",
  //           adjusted_rate.toFixed(5)
  //         );
  //         this.props.updateNewTransferCreation(
  //           "amount_from",
  //           amount_from.toFixed(5)
  //         );
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });
  //   }
  // };

  sendState() {
    return this.state;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  // function that verifies if a value is larger than a number or not
  isLargerThanNumber(value, number) {
    if (value !== '' && value >= number) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'isLargerThanNumber':
        if (this.isLargerThanNumber(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
    // Calculate "to amount"
    // if (stateName === "from_amount") {
    //   this.setState({ last_user_type_amount_name: "from_amount" });
    //   this.props.updateNewTransferCreation("amount_from", event.target.value);
    //   this.handleAmount("to_amount");
    // }
    // // Calculate "from amount"
    // if (stateName === "to_amount") {
    //   this.setState({ last_user_type_amount_name: "to_amount" });
    //   this.props.updateNewTransferCreation("amount_to", event.target.value);
    //   this.handleAmount("from_amount");
    // }
    // if (stateName === "amount_to") {
    //   this.fetchNewRate()
    // }
  }

  handleAmount(amount_name) {
    // console.log("===== handleAmount (" + amount_name + ") =====")

    return;
    let from_currency_Iso = '';
    let to_currency_Iso = '';

    this.state.currencies_list_priority.map((item, index) => {
      if (item.id == this.state.from_currency) {
        from_currency_Iso = item.iso_alpha_3;
      }
      if (item.id == this.state.to_currency) {
        to_currency_Iso = item.iso_alpha_3;
      }
    });

    let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`;
    axios
      .get(url, {
        params: {
          currencies: to_currency_Iso,
          source: from_currency_Iso,
          format: '1'
        }
      })
      .then(response => {
        let key = from_currency_Iso + to_currency_Iso;
        let rate_string = response.data.quotes[key];

        let rate = Number.parseFloat(rate_string);

        let rate_adjust =
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate) +
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_division) +
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_entity) +
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_team) +
          Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_staff);
        let adjusted_rate = rate - rate * rate_adjust;

        // Add Credit Card Rate Adjustment
        if (this.props.transfers_2.new_transfer_data.payment_method === 'worldpay') {
          adjusted_rate = adjusted_rate - adjusted_rate * 0.02;
        }

        if (this.state.from_currency == this.state.to_currency) {
          adjusted_rate = rate;
        }

        this.props.updateNewTransferCreation('client_rate', adjusted_rate.toFixed(5));

        if (this.state.from_currency_state === 'success' && this.state.to_currency_state === 'success') {
          // handle to_amount
          if (amount_name === 'to_amount' && this.state.from_amount_state === 'success') {
            let amount_from = Number.parseFloat(this.state.from_amount);
            let amount_to = amount_from * adjusted_rate;

            this.setState({['to_amount']: amount_to.toFixed(2)});
            this.props.updateNewTransferCreation('amount_to', amount_to.toFixed(2));
            if (amount_to >= min_transfer_amount) {
              this.setState({['to_amount_state']: 'success'});
            } else {
              this.setState({['to_amount_state']: 'error'});
            }
          }

          // handle from_amount
          if (amount_name === 'from_amount' && this.state.to_amount_state === 'success') {
            let amount_to = Number.parseFloat(this.state.to_amount);
            let amount_from = amount_to / adjusted_rate;

            this.setState({['from_amount']: amount_from.toFixed(2)});
            this.props.updateNewTransferCreation('amount_from', amount_from.toFixed(2));
            if (amount_from >= min_transfer_amount) {
              this.setState({['from_amount_state']: 'success'});
            } else {
              this.setState({['from_amount_state']: 'error'});
            }
          }
        }

        this.setState({['adjusted_rate']: adjusted_rate});
      })
      .catch(error => {
        console.log(error);
      });

    // console.log(this.props);
    //#1090
  }

  // handleSelectChange = event => {
  //   this.setState({ [event.target.name]: event.target.value });
  //   // console.log(this.props);
  //   this.props.updateNewTransferCreation(event.target.name, event.target.value);
  //   this.setState({ [event.target.name + "_state"]: "success" });
  //   // console.log(this.props);
  // };

  handleCustomReactSelectChange = (name, index, previous_from_currency, previous_to_currency) => value => {
    var option_value;
    if (value === null) {
      option_value = null;
    } else {
      option_value = value.value;
    }

    this.setState(
      {
        [name]: option_value
      },
      () => {
        // switch (this.state.last_user_type_amount_name) {
        //   case "from_amount":
        //     this.handleAmount("to_amount");
        //     break;
        //   case "to_amount":
        //     this.handleAmount("from_amount");
        //     break;
        //   default:
        //     this.handleAmount();
        // }
      }
    );
    this.setState({[name + '_state']: 'success'});

    if (name === 'beneficiary_id') {
      // let i = 0;
      // let clientTransfers = [...this.state.clientTransfers];
      // clientTransfers[i] = { ...clientTransfers[i], [name]: option_value };
      // this.setState({ clientTransfers });
      this.props.updateNewTransferCreation('beneficiary_id', option_value);

      this.getBeneficiaryDetails(option_value);
    }

    if (name === 'purpose_of_payment_detail') {
      let clientTransfers = [...this.state.clientTransfers];
      clientTransfers[index] = {
        ...clientTransfers[index],
        [name]: option_value
      };
      clientTransfers[index] = {
        ...clientTransfers[index],
        [name + '_state']: 'success'
      };
      let document_upload_show = false;
      let purpose_of_payment_description = '';
      for (let key in this.props.transfers_pending.new_transfer_data.purpose_of_payment_list) {
        if (
          this.props.transfers_pending.new_transfer_data.purpose_of_payment_list[key].id ==
          // this.props.app_state.current_client.purpose_of_payment_list[key].id ==
          option_value
        ) {
          purpose_of_payment_description =
            this.props.transfers_pending.new_transfer_data.purpose_of_payment_list[key].description;
          if (
            // this.props.app_state.current_client.purpose_of_payment_list[key]
            this.props.transfers_pending.new_transfer_data.purpose_of_payment_list[key].document_upload == 1
          ) {
            document_upload_show = true;
          }
        }
      }

      clientTransfers[index] = {
        ...clientTransfers[index],
        ['showDocumentUpload']: document_upload_show,
        ['purpose_of_payment_description']: purpose_of_payment_description
      };
      this.setState({clientTransfers});
      this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
    }
    // console.log(name);
    // if (name === "from_currency") {
    //   // console.log(this.state);
    //   this.props.updateNewTransferCreation("currency_from_id", option_value);
    //   this.props.updateNewTransferCreation("currency_from_state", "success");
    //   API.get("currencies", `/get/${option_value}`)
    //     .then(response => {
    //       this.props.updateNewTransferCreation("currency_from", response);
    //       this.props.updateNewTransferCreation(
    //         "currency_from_name",
    //         response.iso_alpha_3
    //       );
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });

    // }
    // if (name === "to_currency") {
    //   this.props.updateNewTransferCreation("currency_to_id", option_value);
    //   this.props.updateNewTransferCreation("currency_to_state", "success");
    //   API.get("currencies", `/get/${option_value}`)
    //     .then(response => {
    //       this.props.updateNewTransferCreation("currency_to", response);
    //       this.props.updateNewTransferCreation(
    //         "currency_to_name",
    //         response.iso_alpha_3
    //       );
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // }
  };

  // set_payment_radio = (value) => {
  //   // console.log(value)
  //   this.setState({ payment_method: value })
  //   this.props.updateNewTransferCreation("payment_method", value);
  //   this.handleAmount("from_amount")
  // }

  isValidated() {
    if (this.state.from_currency_state !== 'success') {
      this.setState({from_currency_state: 'error'});
    }
    if (this.state.to_currency_state !== 'success') {
      this.setState({to_currency_state: 'error'});
    }
    if (this.state.from_amount_state !== 'success') {
      this.setState({from_amount_state: 'error'});
    }
    if (this.state.to_amount_state !== 'success') {
      this.setState({to_amount_state: 'error'});
    }

    if (
      this.state.from_currency_state === 'success' &&
      this.state.to_currency_state === 'success' &&
      this.state.from_amount_state === 'success' &&
      this.state.to_amount_state === 'success'
    ) {
      API.get('system', `/currencies/get/${this.state.from_currency}`)
        .then(response => {
          console.log(response);
          this.props.updateNewTransferCreation('currency_from', response);
          //#1090
          this.props.updateNewTransferCreation('remitting_bank_account_id', response.primary_bank_account_id);
          API.get('system', `/entitybanksaccount/get/id/${response.primary_bank_account_id}`)
            .then(response => {
              console.log(response);
              this.props.updateNewTransferCreation('remitting_bank_account_detail', response);

              API.get('system', `/entitybanks/get/id/${response.entity_bank_id}`)
                .then(response => {
                  console.log(response);
                  this.props.updateNewTransferCreation('remitting_bank_detail', response);
                  //#1090
                  this.props.updateNewTransferCreation(
                    'remitting_bank_id',
                    this.props.transfers_2.new_transfer_data.remitting_bank_account_detail[0].entity_bank_id
                  );
                  // return response
                })
                .catch(error => {
                  console.log(error);
                });
            })
            .catch(error => {
              console.log(error);
            });

          API.get('system', `/currencies/get/${this.state.to_currency}`)
            .then(response => {
              this.props.updateNewTransferCreation('currency_to', response);
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
        });

      this.props.updateNewTransferCreation('currency_from_id', this.state.from_currency);
      this.props.updateNewTransferCreation('currency_to_id', this.state.to_currency);
      this.props.updateNewTransferCreation('amount_from', this.state.from_amount);
      this.props.updateNewTransferCreation('amount_to', this.state.to_amount);
      this.props.updateNewTransferCreation('client_rate', this.state.adjusted_rate);

      return true;
    }

    return false;
  }

  handleChange(e, i, stateName, type, criteria) {
    const {name, value} = e.target;
    let clientTransfers = [...this.state.clientTransfers];
    clientTransfers[i] = {...clientTransfers[i], [stateName]: value};

    switch (type) {
      case 'isLargerThanNumber':
        if (this.isLargerThanNumber(value, criteria)) {
          clientTransfers[i] = {
            ...clientTransfers[i],
            [stateName + '_state']: 'success'
          };
        } else {
          clientTransfers[i] = {
            ...clientTransfers[i],
            [stateName + '_state']: 'error'
          };
        }
        break;
      default:
        break;
    }

    if (stateName === 'purpose_of_payment_other') {
      if (value !== '') {
        clientTransfers[i] = {
          ...clientTransfers[i],
          [stateName + '_state']: 'success'
        };
      } else {
        clientTransfers[i] = {
          ...clientTransfers[i],
          [stateName + '_state']: 'error'
        };
      }
    }

    this.setState({clientTransfers});
    this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);

    // if (stateName == "beneficiary_id" && i == 0) {
    //   // this.getBeneficiaryDetails(value);

    //   API.get("beneficiaries", `/get/id/${value}`)
    //     .then(response => {
    //       clientTransfers[i] = { ...clientTransfers[i], "beneficiary": response };
    //     });

    //   clientTransfers[i] = { ...clientTransfers[i], [stateName]: value };

    //   this.setState({ clientTransfers });
    //   this.props.updateNewTransferCreation("multiple_beneficiary_id", clientTransfers);
    // } else {
    //   if (stateName == "amount") {
    //     clientTransfers[i] = { ...clientTransfers[i], [stateName]: value.replace(/,/g, '') };
    //   } else {
    //     clientTransfers[i] = { ...clientTransfers[i], [stateName]: value };
    //   }
    //   this.setState({ clientTransfers });
    //   this.props.updateNewTransferCreation("multiple_beneficiary_id", clientTransfers);
    // }

    if (stateName == 'amount') {
      let clientTransfers = [...this.state.clientTransfers];
      let total_amount = 0;
      let tmpValue = 0;

      clientTransfers.map((el, index) => {
        if (index == i) {
          if (this.isEmpty(value)) tmpValue = 0;
          else tmpValue = value.replace(/,/g, '');
          total_amount = Number.parseFloat(total_amount) + Number.parseFloat(tmpValue);
        } else {
          if (this.isEmpty(el.amount)) tmpValue = 0;
          else tmpValue = el.amount.replace(/,/g, '');
          total_amount = Number.parseFloat(total_amount) + Number.parseFloat(tmpValue);
        }
      });
      this.setState({['amount_to']: total_amount});
      this.props.updateNewTransferCreation('amount_to', total_amount);
      if (total_amount > min_transfer_amount) {
        if (this.props.transfers_pending.new_transfer_data.amount_to_state !== 'success') {
          this.props.updateNewTransferCreation('amount_to_state', 'success');
        }
      } else {
        this.props.updateNewTransferCreation('amount_to_state', 'error');
      }
      this.handleAmount('from_amount');
      this.props.updateNewTransferCreation(
        'nickname',
        `FX Transfer ${moment().format('DD/MM/YY')} ${Number.parseFloat(total_amount).toFixed(2)} ${
          this.props.transfers_pending.new_transfer_data.currency_to
            ? this.props.transfers_pending.new_transfer_data.currency_to.iso_alpha_3
            : ''
        }`
      );
    }
  }

  handleDueDateChange = (event, option) => {
    let selDate = '';
    if (event.isValid != undefined) {
      if (event.toDate().getMonth() >= 9) {
        if (option === 'onlyYearMonth')
          selDate = event.toDate().getFullYear() + '-' + (event.toDate().getMonth() + 1) + '-01';
        // set it as the first day of the month
        else {
          if (event.toDate().getDate() > 9)
            selDate =
              event.toDate().getFullYear() + '-' + (event.toDate().getMonth() + 1) + '-' + event.toDate().getDate();
          // + 'T00:00.00.000Z';
          else
            selDate =
              event.toDate().getFullYear() + '-' + (event.toDate().getMonth() + 1) + '-0' + event.toDate().getDate(); // + 'T00:00.00.000Z';
        }
      } else if (event.toDate().getMonth() < 9) {
        if (option === 'onlyYearMonth')
          selDate = event.toDate().getFullYear() + '-0' + (event.toDate().getMonth() + 1) + '-01';
        // set it as the first day of the month
        else {
          if (event.toDate().getDate() > 9)
            selDate =
              event.toDate().getFullYear() + '-0' + (event.toDate().getMonth() + 1) + '-' + event.toDate().getDate();
          // + 'T00:00.00.000Z';
          else
            selDate =
              event.toDate().getFullYear() + '-0' + (event.toDate().getMonth() + 1) + '-0' + event.toDate().getDate(); // + 'T00:00.00.000Z';
        }
      }
      this.setState({due_date_state: 'success'});
    } else {
      selDate = event;
      // selDate = this.modifydocument_expiry(event);
      this.setState({due_date_state: 'error'});
    }

    this.setState({due_date: selDate});
    this.props.updateNewTransferCreation('due_date', selDate);
    // this.setState({ document_expiry_init_for_category_change: false });
  };

  createUI() {
    const {classes} = this.props;
    // For CustomReactSelect. Generate select options for dropdown list.
    // const beneficiary_list_select_options = this.state.beneficiaries_list
    //   .sort((a, b) => {
    //     if (a.nickname && b.nickname) {
    //       return a.nickname.localeCompare(b.nickname);
    //     } else {
    //       return a.id > b.id;
    //     }
    //   })
    //   .map(item => ({
    //     value: item.id,
    //     label: item.nickname
    //   }));

    if (!this.props.transfers_pending.new_transfer_data) return null;
    if (!this.props.transfers_pending.new_transfer_data.purpose_of_payment_list) return null;
    const purpose_of_payment_select_options =
      this.props.transfers_pending.new_transfer_data.purpose_of_payment_list.map(item => ({
        value: item.id,
        label: item.description
      }));

    return this.state.clientTransfers.map((el, i) => (
      <GridContainer justify='flex-start' alignItems='center' key={`i_${i}_i`}>
        <GridItem xs={12} sm={11}>
          <Card>
            <CardBody>
              <GridContainer justify='flex-start'>
                {/* <GridItem xs={12} sm={6}>
                  <CustomReactSelect
                    error={el.beneficiary_id_state === "error"}
                    label="Select beneficiary"
                    options={beneficiary_list_select_options}
                    value={el.beneficiary_id}
                    onChange={this.handleCustomReactSelectChange(
                      "beneficiary_id",
                      i
                    )}
                    isClearable={false}
                  // isDisabled={this.state.prefill_beneficiary}
                  />
                </GridItem> */}
                <GridItem xs={12} sm={4}>
                  <CustomReactSelect
                    error={el.purpose_of_payment_detail_state === 'error'}
                    label='Purpose of payment (AusTrack)'
                    options={purpose_of_payment_select_options}
                    value={el.purpose_of_payment_detail}
                    onChange={this.handleCustomReactSelectChange('purpose_of_payment_detail', i)}
                    isClearable={false}
                    // isDisabled={this.state.prefill_purpose_of_payment}
                  />

                  {el.purpose_of_payment_detail === 1 && (
                    <CustomInput
                      style={{paddingTop: 25}}
                      success={el.purpose_of_payment_other_state === 'success'}
                      error={el.purpose_of_payment_other_state === 'error'}
                      labelText={<span>Specify purpose of payment</span>}
                      id='purpose_of_payment_other'
                      name='purpose_of_payment_other'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: el.purpose_of_payment_other,
                        onChange: event => this.handleChange(event, i, 'purpose_of_payment_other')
                      }}
                    />
                  )}
                </GridItem>
                {/* </GridContainer>
              <GridContainer justify="flex-start"> */}
                <GridItem xs={12} sm={4}>
                  <CustomInput
                    success={el.detail_1_state === 'success'}
                    error={el.detail_1_state === 'error'}
                    labelText={<span>Item Description</span>}
                    id='detail_1'
                    name='detail_1'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: el.detail_1,
                      onChange: event => this.handleChange(event, i, 'detail_1')
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <CustomInput
                    success={el.amount_state === 'success'}
                    error={el.amount_state === 'error'}
                    labelText={<span>Amount</span>}
                    id='amount'
                    labelProps={{
                      shrink: true
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: el.amount,
                      onChange: event =>
                        this.handleChange(event, i, 'amount', 'isLargerThanNumber', min_transfer_amount)
                      // onBlur: event => this.handleBlur(event, i, "amount")
                    }}
                    // helpText={this.state.beneficiary.account_currency_nickname ? <span>{this.state.beneficiary.account_currency_nickname}</span> : ""}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={1}>
          <div style={{textAlign: 'center', paddingTop: 25}}>
            <Button size='sm' color='primary' key={i} disabled={i === 0} onClick={this.removeClick.bind(this, i)}>
              X
            </Button>
          </div>
        </GridItem>
        <hr />
      </GridContainer>
    ));
  }

  render() {
    const {classes} = this.props;
    // For CustomReactSelect. Generate select options for dropdown list.
    // const select_currency_select_options = this.state.currencies_list_priority.map(
    //   item => ({
    //     value: item.id,
    //     label: item.iso_alpha_3 + ": " + item.full_name
    //   })
    // );

    const beneficiary_list_select_options = this.state.beneficiaries_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return {value: item.id, label: item.nickname};
      });

    if (!this.props.transfers_pending.new_transfer_data) return null;
    if (!this.props.app_state.current_team) return null;

    return (
      <React.Fragment>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText} />
          </GridItem>
        </GridContainer>

        {/* {false && */}
        {/* {this.props.transfers_2.new_transfer_data.client && this.props.transfers_2.new_transfer_data.client.credit_card_active &&
          <GridContainer justify="left">
            <GridItem xs={12} sm={12}>
              <h5>Choose how will you make payment</h5>
              <div
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={this.state.payment_method === "bank"}
                      onChange={() => this.set_payment_radio("bank")}
                      value="bank"
                      name="radio button enabled"
                      aria-label="A"
                      icon={<FiberManualRecord className={classes.radioUnchecked} />}
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label="Electronic Bank Transfer (EFT)"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={this.state.payment_method === "worldpay"}
                      onChange={() => this.set_payment_radio("worldpay")}
                      value="worldpay"
                      name="radio button enabled"
                      aria-label="A"
                      icon={<FiberManualRecord className={classes.radioUnchecked} />}
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label="Credit Card"
                />
              </div>
            </GridItem>
          </GridContainer>
        } */}

        <GridContainer justify='center'>
          {/* <GridItem xs={12} sm={6}>
            <CustomReactSelect
              label="From Currency"
              options={select_currency_select_options}
              value={this.state.from_currency}
              onChange={this.handleCustomReactSelectChange("from_currency")}
              isClearable={false}
            // isDisabled={!this.state.edit_mode}
            />
          </GridItem> */}

          <GridItem xs={12} sm={3}>
            <CustomReactSelect
              // error={
              //   el.beneficiary_id_state === "error"
              // }
              label='Select beneficiary'
              options={beneficiary_list_select_options}
              value={this.props.transfers_pending.new_transfer_data.beneficiary_id}
              onChange={this.handleCustomReactSelectChange('beneficiary_id')}
              isClearable={false}
              // isDisabled={this.state.prefill_beneficiary}
            />

            {/* <CustomReactSelect
              label="To Currency"
              options={select_currency_select_options}
              value={this.state.to_currency}
              onChange={this.handleCustomReactSelectChange("to_currency")}
              isClearable={false}
            // isDisabled={!this.state.edit_mode}
            /> */}
          </GridItem>
          <GridItem xs={12} sm={3}>
            {/* {(this.state.to_amount && !isNaN(this.state.to_amount)) && (
              <GridContainer>
                <GridItem> */}
            <CustomInput
              // success={this.state.to_amount_state === "success"}
              // error={this.state.to_amount_state === "error"}
              labelText={<span>Invoice Total Amount</span>}
              id='to_amount'
              labelProps={{
                shrink: true
              }}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                // value: this.state.to_amount,
                value: this.props.transfers_pending.new_transfer_data.amount_to
                // onChange: event =>
                //   this.change(
                //     event,
                //     "to_amount",
                //     "isLargerThanNumber",
                //     min_transfer_amount
                //   )
                // disabled: true
              }}
              // helpText={
              //   !isNaN(this.state.adjusted_rate)
              //     ? "Transfer Rate: " +
              //     Number.parseFloat(this.state.adjusted_rate).toFixed(5)
              //     : ""
              // }
            />
            {/* </GridItem>
              </GridContainer>
            )} */}
          </GridItem>
          <GridItem xs={12} sm={3}>
            <InputLabel className={classes.label} error={this.state.due_date_state === 'error'}>
              Due Date
            </InputLabel>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Datetime
                timeFormat={false}
                dateFormat={'DD/MM/YYYY'}
                // The following method which can hide data doesn't work for the first date pick
                // dateFormat={!this.state.document_expiry_init_for_category_change && 'DD/MM/YYYY'}
                // dateFormat={(this.props.identification.new_identification_record_data && this.props.identification.new_identification_record_data.document_expiry === "") ? false : 'DD/MM/YYYY'}
                onChange={event => {
                  this.handleDueDateChange(event);
                }}
              />
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            {this.createUI()}
          </GridItem>
        </GridContainer>
        <GridContainer justify='flex-end'>
          <GridItem xs={12} sm={6}>
            {/* <Button
              size="sm"
              color="info"
              key="addBeneficiaryKey"
              onClick={() => this.addBeneficiaryModelOpen()}
            >
              Create new beneficiary
            </Button> */}
          </GridItem>
          <GridItem xs={12} sm={2} />
          <GridItem xs={12} sm={4}>
            <Button size='sm' color='primary' key='addPayoutKey' onClick={this.addClick.bind(this)}>
              Add new invoice line item
            </Button>
            <p>Remember to add a line-item for GST (if required).</p>
          </GridItem>
        </GridContainer>

        {/* <GridContainer justify="center"> */}
        {/* <GridItem xs={12} sm={6}>
            <CustomInput
              success={this.state.from_amount_state === "success"}
              error={this.state.from_amount_state === "error"}
              labelText={<span>From amount</span>}
              id="from_amount"
              labelProps={{
                shrink: true
              }}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: this.state.from_amount,
                onChange: event =>
                  this.change(
                    event,
                    "from_amount",
                    "isLargerThanNumber",
                    min_transfer_amount
                  )
              }}
            />
          </GridItem> */}

        {/* </GridContainer> */}
      </React.Fragment>
    );
  }
}

// export default withRouter(withStyles(style)(Step1TransferTo));
// import Step1TransferTo from "./Step1TransferTo";

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers_pending: state.transfers_pending
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    }
  };
};

const Step2TransferContainer = connect(mapStateToProps, mapDispatchToProps)(Step2Transfer);

export default withRouter(withStyles(style)(Step2TransferContainer));
