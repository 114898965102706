import Add from "@material-ui/icons/Add";

const initialState = {
    bulkTransfer: [],
    expiryDate: null,
    team_id: null,
    beneficiary_id: null,
    amount: null,
    purpose_of_payment: null,
    purpose_of_payment_detail: null,
    file_id: null,
    account_currency: null
}
export const ADD_PAYMENT_REQUEST = 'ADD_PAYMENT_REQUEST';
export const UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST';
export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST';
export const PaymentRequestReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADD_PAYMENT_REQUEST:
            return {...state, ...action.payload}
        case UPDATE_PAYMENT_REQUEST:
            return {...state, [action.key]: action.value}
        default:
            return state;
    }
}

export function addPaymentRequest(data) {
    return {
        type: ADD_PAYMENT_REQUEST,
        payload: data,
    };
}

export function updatePaymentRequest(key, value) {
    return {
        type: UPDATE_PAYMENT_REQUEST,
        key: key,
        value: value
    };
}