import ListTable from "../../../components/ListTable/ListTable";
import {Button, Card, Flex, Table} from "antd";
import {useEffect, useState} from "react";
import {API} from "aws-amplify";
import React from 'react';
import dayjs from "dayjs";
import {connect} from "react-redux";
import {fetchCurrencyListNotDeleted} from "../../../redux/actions/currencies";
import {DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import {fetchStaffListNotDeleted} from "../../../redux/actions/staff";
import ChartistGraph from "react-chartist";
import Chartist from "chartist";

const ReconciliationList = ({ getColumnSearchProps, staffs, history = [], fetchStaffs }) => {

    const [tooltip, setTooltip] = useState(null);

    const [componentState, setComponentState] = useState({
        reconciliationList: [],
        charts: {
            data: {labels: [1,2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13], series: [[1, 2, 1, 4, 5, 1, 8, 10, 12, 1]]},
            chartMinValue: 1,
            chartMaxValue: 10
        }
    });

    const buildColumns = () => {
        return [
            {
                key: 'id',
                dataIndex: 'id',
                title: 'ID',
                sorter: (a, b) => a.id - b.id,
                defaultSortOrder: ['descend'],
                ...getColumnSearchProps({
                    dataIndex: 'id',
                    render: (text, record) => text
                })
            },
            {
                key: 'recordCreated',
                dataIndex: 'recordCreated',
                title: 'Created At',
                ...getColumnSearchProps({
                    dataIndex: 'recordCreated',
                    render: (text, record) => dayjs(text).format('DD/MM/YYYY')
                })
            },
            {
                key: 'staffId',
                dataIndex: 'staffID',
                title: 'Created By',
                ...getColumnSearchProps({
                    dataIndex: 'staffID',
                    render: (text, record) => {
                        const d = staffs.staff_list.find(i => i.id === Number(text));
                        return staffs.staff_list != null && staffs.staff_list.length > 0
                            ? d != null ? d.nickname : '-'
                            : '-';
                    }
                })
            },
            {
                key: 'difference',
                dataIndex: 'difference',
                title: 'Overall',
                render: (text) => {
                    return new Intl.NumberFormat('en-Au', {
                        style: 'currency',
                        currency: 'AUD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                    }).format(text);
                }
            },
            {
                key: 'accountBalanceAUD',
                dataIndex: 'accountBalanceAUD',
                title: 'Account Balance AUD',
                ...getColumnSearchProps({
                    dataIndex: 'accountBalanceAUD',
                    render: (text, record) => new Intl.NumberFormat('en-Au', {
                        style: 'currency',
                        currency: 'AUD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                    }).format(text)
                })
            },
            {
                key: 'ledgerBalanceAUD',
                dataIndex: 'ledgerBalanceAUD',
                title: 'Ledger Balance AUD',
                ...getColumnSearchProps({
                    dataIndex: 'ledgerBalanceAUD',
                    render: (text, record) => new Intl.NumberFormat('en-Au', {
                        style: 'currency',
                        currency: 'AUD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                    }).format(text)
                })
            },
            {
                key: 'accountBalanceInTransit',
                dataIndex: 'accountBalanceInTransit',
                title: 'Account Balance In Transit',
                ...getColumnSearchProps({
                    dataIndex: 'accountBalanceInTransit',
                    render: (text, record) => new Intl.NumberFormat('en-Au', {
                        style: 'currency',
                        currency: 'AUD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                    }).format(text)
                })
            },
            // {
            //     key: 'accountBalanceAdd',
            //     dataIndex: 'accountBalanceAdd',
            //     title: 'Account Balance Add',
            //     ...getColumnSearchProps({
            //         dataIndex: 'accountBalanceAdd',
            //         render: (text, record) => new Intl.NumberFormat('en-Au', {
            //             style: 'currency',
            //             currency: 'AUD',
            //             minimumFractionDigits: 2,
            //             maximumFractionDigits: 2,
            //             useGrouping: true,
            //         }).format(text)
            //     })
            // },
            // {
            //     key: 'accountBalanceSubtract',
            //     dataIndex: 'accountBalanceSubtract',
            //     title: 'Account Balance Subtract',
            //     ...getColumnSearchProps({
            //         dataIndex: 'accountBalanceSubtract',
            //         render: (text, record) => new Intl.NumberFormat('en-Au', {
            //             style: 'currency',
            //             currency: 'AUD',
            //             minimumFractionDigits: 2,
            //             maximumFractionDigits: 2,
            //             useGrouping: true,
            //         }).format(text)
            //     })
            // },
            // {
            //     key: 'ledgerBalanceAdd',
            //     dataIndex: 'ledgerBalanceAdd',
            //     title: 'Ledger Balance Add',
            //     ...getColumnSearchProps({
            //         dataIndex: 'ledgerBalanceSubtract',
            //         render: (text, record) => new Intl.NumberFormat('en-Au', {
            //             style: 'currency',
            //             currency: 'AUD',
            //             minimumFractionDigits: 2,
            //             maximumFractionDigits: 2,
            //             useGrouping: true,
            //         }).format(text)
            //     })
            // },
            // {
            //     key: 'ledgerBalanceSubtract',
            //     dataIndex: 'ledgerBalanceSubtract',
            //     title: 'Ledger Balance Subtract',
            //     ...getColumnSearchProps({
            //         dataIndex: 'ledgerBalanceSubtract',
            //         render: (text, record) => new Intl.NumberFormat('en-Au', {
            //             style: 'currency',
            //             currency: 'AUD',
            //             minimumFractionDigits: 2,
            //             maximumFractionDigits: 2,
            //             useGrouping: true,
            //         }).format(text)
            //     })
            // },
            {
                key: 'data',
                dataIndex: 'datasource',
                ...getColumnSearchProps({
                    dataIndex: 'datasource',
                    render: (text, record) => <Flex justify={'center'}>
                        <Button type={'link'} icon={<EyeOutlined />} onClick={() => history.push(`/backoffice/reconciliation/view/${record.id}`)}></Button>
                        {/*<Button type={'link'} danger={true} icon={<DeleteOutlined />}></Button>*/}
                    </Flex>
                })
            }
        ]
    }

    const prepareChartData = (res) => {
        let dates = res.map(item => dayjs(item.recordCreated).format('DD/MM/YYYY'));
        const data = res.map(item => item.difference);
        const charts = {labels: dates, series: [data]};
        setComponentState(prev => ({...prev, charts: {...prev, charts, data: charts}}));
    }

    const handleTooltip = (data) => {
        const { e, index } = data;
        if (index !== undefined) {
            setTooltip({
                content: `Date: ${index.axisX.ticks[index.index]}<br>Amount: ${index.series[index.index].toFixed(2)}`,
                x: e.clientX,
                y: e.clientY
            });
        } else {
            setTooltip(null);
        }
    };

    useEffect(() => {
        API.post("commons", "/fetch", {body: {
            context: 'reconciliation',
            fields: ['*']
        }}).then(res => {
            setComponentState(prev => ({...prev, reconciliationList: res}));
            prepareChartData(res);
        });
        fetchStaffs();
    }, []);
    return (
        <>
            <Card title={<Flex justify={'space-between'} style={{ marginBottom: '20px' }}>
                <span>Reconciliation List</span>
            </Flex>}>
                <Table size="small" dataSource={componentState.reconciliationList} columns={buildColumns()}></Table>
            </Card>
            <Card title={'Reconciliation Trend Graph'} style={{ marginTop: '20px', position: 'relative' }}>
                <ChartistGraph
                    style={{margin: 20, fontWeight: 600}}
                    data={componentState.charts.data}
                    type="Line"
                    options={{
                        lineSmooth: Chartist.Interpolation.cardinal(
                            {
                                tension: 0,
                            }
                        ),
                        axisY: {
                            labelInterpolationFnc: function (
                                value
                            ) {
                                return value;
                            },
                        },
                        // axisX: {
                        //   labelInterpolationFnc: function(value) {
                        //     if (value) {
                        //       return moment(value, "YYYY-MM-DD").format("MMM YYYY")
                        //     } else {
                        //       return ""
                        //     }
                        //   },
                        // },
                        showPoint: true,
                        low: componentState.charts.chartMinValue,
                        high: componentState.charts.chartMaxValue,
                        height: '400px',
                        chartPadding: {
                            top: 0,
                            right: 20,
                            bottom: 0,
                            left: 20,
                        },
                    }}
                    listener={{
                        draw: (data) => {
                            if (data.type === 'point') {
                                data.element._node.addEventListener('mouseover', (e) => handleTooltip({ e, index: data }));
                                data.element._node.addEventListener('mouseout', () => handleTooltip({}));
                            }
                        }
                    }}
                />
                {tooltip && (
                    <div
                        style={{
                            position: 'absolute',
                            top: tooltip.y + 10,
                            left: tooltip.x + 10,
                            backgroundColor: '#fff',
                            border: '1px solid #ccc',
                            padding: '5px',
                            pointerEvents: 'none',
                            zIndex: 10
                        }}
                        dangerouslySetInnerHTML={{ __html: tooltip.content }}
                    />
                )}
            </Card>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
        currencies: state.currencies,
        staffs: state.staff
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCurrencies: () => dispatch(fetchCurrencyListNotDeleted()),
        fetchStaffs: () => dispatch(fetchStaffListNotDeleted())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListTable(ReconciliationList))