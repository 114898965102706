import { API } from 'aws-amplify'

export const ForwardsAPI = {
    saveForwards: (payload) => API.post("forwards", "/create", {body: payload}),
    removeForward: (id) => API.get("forwards", `/delete/${id}`, {}),
    fetchForwardDrawDowns: (id) => API.get("forwards", `/draw-downs/${id}`, {}),
    fetchForwardCommunication: (id) => API.get("forwards", `/communications/${id}`, {}),
    fetchForwardsByClientId: (client_id) => API.get('forwards', `/client/${client_id}`, {}),
    fetchForwardById: (id) => API.get('forwards', `/get/${id}`, {}),
    fetchForwardByForwardId: (id) => API.get('forwards', `/get/id/${id}`, {}),
    fetchForwardList: () => API.get('forwards', '/get/list', {}),
    fetchAvailableBalance: (id) => API.get('forwards', `/available-balance/${id}`, {}),
    updateForward: (payload) => API.post("forwards", "/update", {body: payload}),
    sendCommunicationEmail: (payload) => API.post("email", "/client/forward-instructions", {body: payload})
}