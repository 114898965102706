import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Amplify, {API} from 'aws-amplify';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import entitiesStyles from "./EntitiesStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';
import Button from '../../../components/CustomButtons/Button';

import {selectEntityUi} from '../../../redux/actions/groups';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import GroupListComponentContainer from '../../../components/Groups/GroupListComponent'; //GroupList
import {withRouter} from 'react-router-dom';

const entitiesStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};
/**
 * ----------------------------------------------------------------------------
 * CLASS - Groups
 * ----------------------------------------------------------------------------
 */
class GroupList extends React.Component {
  componentDidMount() {}

  render() {
    const {classes} = this.props;
    if (!this.props.app_state.current_staff_id) {
      return <React.Fragment>LOADING</React.Fragment>;
    }

    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                Displaying Groups for {this.props.app_state.current_staff.team_nickname}
              </h4>
              <div>
                <Button
                  round
                  color={this.props.groups.groups_current_ui === 'list' ? 'rose' : null}
                  // onClick={() => this.props.selectEntityUi("list")}
                >
                  Show All
                </Button>
                <Button
                  round
                  color={this.props.groups.groups_current_ui === 'create' ? 'rose' : null}
                  onClick={() => this.props.history.push('/groups/new')}
                >
                  Create New
                </Button>
                {this.props.groups.groups_current_ui === 'edit' && (
                  <Button
                    round
                    color={this.props.groups.groups_current_ui === 'edit' ? 'rose' : null}
                    onClick={() => this.props.selectEntityUi('edit')}
                  >
                    Edit Record
                  </Button>
                )}
                {this.props.groups.groups_current_ui !== 'edit' && this.props.groups.groups_current_ui !== 'create' && (
                  <Button round onClick={() => this.props.selectEntityUi('list')}>
                    Refresh
                  </Button>
                )}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                {/*<TeamListContainer /> //put a component here */}
                <GroupListComponentContainer />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

// GroupList.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withRouter(withStyles(entitiesStyles)(GroupList));

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    groups: state.groups
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectEntityUi: value => {
      dispatch(selectEntityUi(value));
    }
  };
};

const GroupListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(entitiesStyles)(GroupList)));

export default GroupListContainer;
