import React from 'react';
import {connect} from 'react-redux';
import {API} from 'aws-amplify';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import { Button, message, Steps } from 'antd';
import Card from '../../../components/Card/Card.jsx';

import Step1Client from './Step1Client';
import Step2Transfer from './Step2TransferPayingTo';
import Step4Confirm from './Step4Confirm';


import {withRouter} from 'react-router-dom';

import {
  change_current_step,
  clearNewTransfer,
  createNewTransfer,
  updateNewTransferCreation,
  updateNewTransferCreationBeneficiary
} from 'redux/actions/transfers_dashboard_multiple';
// import {startNewTransferCreation} from 'redux/actions/transfers_2';

import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days';
dayjs.extend(dayjsBusinessDays);


class NewTransferMultipaySteps extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      current: 0
    }
  }

  componentDidMount() {
    // this.props.startNewTransferCreation();
  }

 
   next = () => {
    let current = this.state.current;

    if (current === 0) {
      const client = this.props.transfers_dashboard_multiple.new_transfer_data.client_id;
      if (client === undefined) {
        message.error("Cannot proceed to next step without selecting a client..");
      } else {
        this.setState({
          current: current + 1
        })
      }
    } else if (current === 1) {
      const beneficiaries = this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id;
      if (beneficiaries != null && beneficiaries.length > 0) {
        this.setState({
          current: current + 1
        })
      } else {
        message.error("Please add some beneficiaries before proceeding to next step.");
      }
    } else {
      this.setState({
        current: current + 1
      })
    }
  };

  prev = () => {
    let current = this.state.current;
    this.setState({
      current: current - 1
    })
  };

  getNextBusinessDay = (d, n) => {
    d = new Date(d.getTime());
    const day = d.getDay();
    d.setDate(d.getDate() + n + (day === 6 ? 2 : Number(!day)) + Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2);
    return d;
  }


  finished = async () => {
    // const set_date = this.getNextBusinessDay(new Date(), 2);
    // const settlement_date = set_date.toISOString().slice(0, 10).replace('T', ' ');
    console.log(this.props.transfers_dashboard_multiple.new_transfer_data);

    const recordCreatedDatetimeLocal = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const transactionDatetimeLocal = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const transactionDateLocal = dayjs().format('YYYY-MM-DD');

    let set_date = dayjs().add(2, 'day');
    console.log('set_date', set_date)

    if (dayjs().day() === 6) {
      set_date = set_date.add(2, 'day');
    } else if (dayjs().day() === 0) {
      set_date = set_date.add(1, 'day');
    }

    let set_date_dayofweek = set_date.day();
    if (set_date_dayofweek === 6) {
      set_date = set_date.add(2, 'day');
    } else if (set_date_dayofweek === 0) {
      set_date = set_date.add(1, 'day');
    }
    console.log('adjusted set_date', set_date)
    const settlement_date = set_date.format('YYYY-MM-DD');
    console.log('settlement_date', settlement_date)


    // if (this.props.transfers_2.new_transfer_data.payment_method === 'worldpay') {
    //   const url = await this.getWorldPayPaymentURL();
    //   window.open(url);
    // }

    let testClient = false;
    if (this.props.transfers_dashboard_multiple.new_transfer_data.client_id == 55) {
      testClient = true;
    }

    let sms = ''
    sms = `${testClient ? '**TEST** ' : ''}Admin Portal New MultiPay Submitted by ${this.props.app_state.current_staff.first_name}: Client ${this.props.transfers_dashboard_multiple.new_transfer_data.client.nickname} transfers from ${this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.short_name} ${this.props.transfers_dashboard_multiple.new_transfer_data.amount_from} at ${this.props.transfers_dashboard_multiple.new_transfer_data.client_rate} to ${this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.short_name} ${this.props.transfers_dashboard_multiple.new_transfer_data.amount_to}`

    let smsAll = ''
    if (this.props.app_state.current_staff.id == 168 || this.props.app_state.current_staff.id == 148 || this.props.app_state.current_staff.id == 102 || this.props.app_state.current_staff.id == 180) {
      smsAll = `${testClient ? '**TEST** ' : ''}Admin Portal New MultiPay Submitted by ${this.props.app_state.current_staff.first_name}: Client ${this.props.transfers_dashboard_multiple.new_transfer_data.client.nickname} transfers from ${this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.short_name} ${this.props.transfers_dashboard_multiple.new_transfer_data.amount_from} at ${this.props.transfers_dashboard_multiple.new_transfer_data.client_rate} to ${this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.short_name} ${this.props.transfers_dashboard_multiple.new_transfer_data.amount_to}`
    }


    await API.post('email', '/interaction/main', {
      body: {
        interaction: 'New Transfer Request',
        data: {
          staffID: this.props.app_state.current_staff.id,
          ...this.props.transfers_dashboard_multiple.new_transfer_data,
          client_id: this.props.transfers_dashboard_multiple.new_transfer_data.client.id,
          client_nickname: this.props.transfers_dashboard_multiple.new_transfer_data.client.nickname,
          record_created_datetime: recordCreatedDatetimeLocal,
          transaction_datetime: transactionDatetimeLocal,
          transaction_date: transactionDateLocal,
          settlement_date: settlement_date,
          // record_created_datetime: new Date().toISOString().slice(0, 19).replace('T', ' '),
          // transaction_datetime: new Date().toISOString().slice(0, 10).replace('T', ' '),
          // settlement_date: moment(set_date).format('DD/MM/YYYY'),
          status: '2',
          from: 'New Multipay Transfer (Steps)',
          purpose: 'Admin Portal New MultiPay Submission'
        },
        result: 'database submission in process',
        // sms,
        // smsAll
      }
    });

    // console.log(this.props.transfers_2.new_transfer_data)
    const message = `
      A new MULTIPAY transfer has been created.<br/>
      <strong>Admin User: </strong>${this.props.app_state.current_staff.nickname}<br/>
      <strong>Client: </strong>${this.props.transfers_dashboard_multiple.new_transfer_data.client.nickname}<br/>
      <strong>From Currency: </strong>${this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_name}<br/>
      <strong>From Amount: </strong>${this.props.transfers_dashboard_multiple.new_transfer_data.amount_from}<br/>
      <strong>Rate: </strong>${this.props.transfers_dashboard_multiple.new_transfer_data.client_rate}<br/>
      <strong>To Currency: </strong>${this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_name}<br/>
      <strong>To Amount: </strong>${this.props.transfers_dashboard_multiple.new_transfer_data.amount_to}<br/>
      <strong>Transaction Date: </strong>${dayjs(transactionDateLocal).format('DD/MM/YYYY')}<br/>
      <strong>Settlement Date: </strong>${dayjs(settlement_date).format('DD/MM/YYYY')}<br/>
      <hr/>
      <strong>Backend: </strong><a href="https://forexsport.com/fww/fwwadmin/new_requests.php">https://forexsport.com/fww/fwwadmin/new_requests.php</a><br/>
      <strong>Admin: <strong><a href="https://crm.forexworldwide.com/transfers/list">Transfer List</a><br/>
    `
    // console.log(message)
    await API.post('email', '/interaction/backoffice', {
      body: {
        staffID: this.props.app_state.current_staff.id,
        subject: `${testClient ? 'TEST TRANSFER - ' : ''}FWW Admin: New MULTIPAY Request Created`,
        message: message,
        sms: testClient ? sms : false,
        smsAll: testClient ? false : smsAll
      }
    });

    // return;
    let transferResult = await this.props.createNewTransfer({
      ...this.props.transfers_dashboard_multiple.new_transfer_data,
      nickname: `${this.props.transfers_dashboard_multiple.new_transfer_data.amount_from} ${
        this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_name
      } to ${this.props.transfers_dashboard_multiple.new_transfer_data.amount_to} ${
        this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_name
      } Settling ${dayjs(set_date).format('DD/MM/YYYY')}`,
      // record_created_datetime: new Date().toISOString().slice(0, 10).replace('T', ' '),
      // transaction_datetime: new Date().toISOString().slice(0, 10).replace('T', ' '),
      // settlement_date: settlement_date,
      transaction_date: transactionDateLocal,
      settlement_date: settlement_date,
      client_id: this.props.transfers_dashboard_multiple.new_transfer_data.client.id,
      staff_id: this.props.transfers_dashboard_multiple.new_transfer_data.client.responsible_staff_member,
      beneficiary_ids: this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id,
      status: '2',
      creatingStaffID: this.props.app_state.current_staff.id,
    });

    // console.log(transferResult);
    // const transferID = transferResult.payload.database_results.insertId;

    this.props.history.push('/transfers/list');
  };

  render() {

    if (!this.props.app_state || !this.props.app_state.current_staff_id) {
      return null;
    }

    const description = '';

    const steps = [
      {
        title: 'Select Client',
        description,
      },
      {
        title: 'Create Transfer',
        description,
      },
      {
        title: 'Confirm',
        description,
      },
    ]

    let subtitle;
    if (this.props.transfers_dashboard_multiple.new_transfer_data.client && this.props.transfers_dashboard_multiple.new_transfer_data.client.nickname) {
      subtitle = <h3>Creating a New Transfer for {this.props.transfers_dashboard_multiple.new_transfer_data.client.nickname}</h3>
    }

    return (
      <>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12}>
          {subtitle}
        </GridItem>
      </GridContainer>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12}>
          <hr/>
          <Steps
            current={this.state.current}
            items={steps}
          />
          <hr/>
        </GridItem>
      </GridContainer>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12}>
            {this.state.current === 0 && (
              <Step1Client />
            )}
            {this.state.current === 1 && (
              <Step2Transfer />
            )}
            {this.state.current === 2 && (
              <Step4Confirm />
            )}
        </GridItem>
      </GridContainer>
      <div className="steps-action">
        {this.state.current < steps.length - 1 && (
          <Button type="primary" onClick={() => this.next()}>
            Next
          </Button>
        )}
        {this.state.current === steps.length - 1 && (
          <Button type="primary" onClick={() => {
              message.success('Processing complete!');
              this.finished();
            }}>
            Done
          </Button>
        )}
        {this.state.current === 1 && (
          <Button style={{ margin: '0 8px' }} onClick={() => this.prev()}>
            Previous
          </Button>
        )}
        {this.state.current === 2 && (
          <Button style={{ margin: '0 8px' }} onClick={() => window.location.href='/transfers/multipay'}>
            Cancel
          </Button>
        )}
      </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    // transfers_2: state.transfers_2,
    language: state.language,
    transfers_dashboard_multiple: state.transfers_dashboard_multiple
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // startNewTransferCreation: () => {
    //   dispatch(startNewTransferCreation());
    // },
    createNewTransfer: data => {
      dispatch(createNewTransfer(data));
    },
    change_current_step: step => {
      dispatch(change_current_step(step));
    },
    clearNewTransfer: () => {
      dispatch(clearNewTransfer());
    },
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    },
    updateNewTransferCreationBeneficiary: array => {
      dispatch(updateNewTransferCreationBeneficiary(array));
    }
  };
};

const NewTransferMultipayStepsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTransferMultipaySteps);

export default withRouter(NewTransferMultipayStepsContainer);
