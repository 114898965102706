import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {API} from "aws-amplify";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "@material-ui/core/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Button from "../CustomButtons/Button";
import moment from "moment/moment";
import {Table} from "antd";
import CardFooter from "../Card/CardFooter";

const forexWorldWideLogo = require('assets/img/pdf_img/forexworldwide_logo_transparent_280x50.42dc12df.png')
const forexSportLogo = require('assets/img/pdf_img/fxsport_signature_gmail.png')
const logo_4qpf = require('assets/img/brand/4QPFa.png')
const logo_skiaspen = require('assets/img/brand/skiaspen.png')
const goStudyLogo = require('assets/img/pdf_img/GOSTUDY_HorizontalUsage_Black_250x50.png')

const style = {
    root: {},
    pageHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 22,
    },
    pageSubHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 18,
    },
    tableHeaders: {
        width: 150,
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 8,
    },
    tableCells: {
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
    },
    backgroundBlue: {
        backgroundColor: '#348feb20',
    },
    headerImage: {
        // width: 100,
        // height: 100,
    },

}
const PaymentRequestViewPage = (props) => {


    const [state, setState] = useState({
        value: 0,
        currencies_list_priority: [],
        map_data: {},
        beneficiary_id: '',
        transfer_heading: 'Current Transfers',
        transfer: {},
        currency_from: {},
        currency_to: {iso_alpha_3: ''},
        payouts_list: [],
        client: null,
        team: null
    });

    const {classes} = props;

    const updateTransferData = (option_value) => {
        setState(prev => ({...prev, transfer_id: option_value}));
        API.get('transfers_pending', `/get-full/id/${option_value}`, {})
            .then(response => {
                setState(prev => ({...prev, transfer: response.transfer_pending, payouts_list: response.payouts}));
                API.get("currencies", `/get/${response.transfer_pending.currency_to_id}`, {})
                    .then(response => setState(prev => ({...prev, currency_to: response})))
                    .catch(error => console.log(error));
                API.get("clients", `/get/id/${response.transfer_pending.client_id}`, {})
                    .then(res => {
                        setState(prev => ({...prev, client: res}));
                        API.get('teams', `/get/id/${res.team_id}`, {})
                            .then(res => setState(prev => ({...prev, team: res})));
                    });
            }).catch(error => console.log(error));

        API.get('currencies', `/list_not_deleted`, {})
            .then(response => setState(prev => ({...prev, currencies_list_priority: response})))
            .catch(error => console.log(error))
    }

    const getLogo = (brand) => {
        switch (brand) {
            case 58:
                return logo_4qpf
                break;
            case 59:
                return logo_skiaspen
                break;
            default:
                return forexWorldWideLogo
                break
        }
    }


    useEffect(() => {
        updateTransferData(props.match.params.payment_request_id);
    }, []);
    console.log(state);

    if (!state.transfer) return (<><strong>Error: Unable to access transfer</strong></>);
    if (!state.currency_from) return null;
    if (!state.currency_to) return null;
    if (!state.payouts_list) return null;
    if (!state.payouts_list.length) return null;

    const payoutItemTable = () => {
        const {classes} = props;

        let totalPayment = state.payouts_list
            .map(item => Number.parseFloat(item.amount_to))
            .reduce((a, b) => a + b, 0);

        let returnValue = [];

        if (!state.payouts_list.length) {
            returnValue.push(
                <table style={{width: '100%',}}>
                    <tbody>
                    <tr className={classes.backgroundBlue}>
                        <th className={classes.tableHeaders}>
                            Beneficiary
                        </th>
                        <td className={classes.tableCells}>
                            <React.Fragment>
                                <strong>
                                    Payout will be made to your currency balance
                                </strong>
                            </React.Fragment>
                        </td>
                    </tr>

                    <tr>
                        <th className={classes.tableHeaders}>
                            Amount Due
                        </th>
                        <td className={classes.tableCells}>
                            <React.Fragment>
                                <strong>
                                    {props.intl.formatNumber(state.transfer.amount_to,
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}{` `}
                                    <div
                                        className={`currency-flag currency-flag-${state.currency_to.iso_alpha_3.toLowerCase()}`}/>
                                    {` `}
                                    {state.currency_to.iso_alpha_3.toUpperCase()}
                                </strong>
                            </React.Fragment>
                        </td>
                    </tr>
                    </tbody>
                </table>
            )
        }

        for (let i = 0; i < state.payouts_list.length; i++) {
            const payout = state.payouts_list[i]
            if (i === 0 || (i > 0 && (payout.beneficiary_id !== state.payouts_list[0].beneficiary_id))) {

                let beneficiaryNickname = payout.beneficiary_nickname;
                if (payout.holdingAccountPayee) {
                    beneficiaryNickname = `Payment made to ${state.currency_to.iso_alpha_3.toUpperCase()} Currency Balance`
                }
                returnValue.push(
                    <table style={{width: '100%'}}>
                        <tbody>
                        {/* {
this.state.payouts_list.map((item) => {
return <tr> */}
                        <tr style={{width: '100%'}}>
                            {/* <td style={{ verticalAlign: "top", paddingRight: 20, paddingLeft: 8, paddingTop: 10, paddingBottom: 10 }}> */}
                            <td>
                                <table style={{width: '100%'}}>
                                    <tbody style={{width: '100%'}}>
                                    <tr style={{width: '100%'}}>
                                        <td className={classes.tableCells}>
                                            <table style={{width: '100%'}}>
                                                <tbody>
                                                <tr>
                                                    <th className={classes.tableHeaders}>
                                                        Payable To
                                                    </th>
                                                    <td className={classes.tableCells}>
                                                        <React.Fragment>
                                                            <strong>
                                                                {beneficiaryNickname}
                                                            </strong>
                                                        </React.Fragment>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th className={classes.tableHeaders}>
                                                        Address
                                                    </th>
                                                    <td className={classes.tableCells}>
                                                        <React.Fragment>
                                                            {payout.ben_address_line_1}&nbsp;
                                                            {payout.ben_address_line_2}&nbsp;
                                                            {payout.ben_address_suburb}&nbsp;
                                                            {payout.ben_address_state}&nbsp;
                                                            {payout.ben_address_postcode}&nbsp;
                                                            {payout.beneficiary_country_name}
                                                        </React.Fragment>
                                                    </td>
                                                </tr>

                                                {payout.internalAccount &&
                                                    <>
                                                        <tr>
                                                            <th className={classes.tableHeaders}>
                                                                Payment Type
                                                            </th>
                                                            <td className={classes.tableCells}>
                                                                Internal Account Payment
                                                            </td>
                                                        </tr>
                                                    </>
                                                } {/* payout.internalAccount &&  */}

                                                {!(payout.internalAccount) &&
                                                    <>
                                                        <tr>
                                                            <th className={classes.tableHeaders}>
                                                                Bank Name
                                                            </th>
                                                            <td className={classes.tableCells}>
                                                                {payout.bank_legal_name ? (
                                                                    <span>
                                                {payout.bank_legal_name}
                                                                        <br/>
                                            </span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th className={classes.tableHeaders}>
                                                                Address
                                                            </th>
                                                            <td className={classes.tableCells}>
                                                                <React.Fragment>
                                                                    {payout.bank_address_line_1}&nbsp;
                                                                    {payout.bank_address_line_2}&nbsp;
                                                                    {payout.bank_address_suburb}&nbsp;
                                                                    {payout.bank_address_state}&nbsp;
                                                                    {payout.bank_address_state}&nbsp;
                                                                    {payout.bank_address_postcode}&nbsp;
                                                                    {payout.beneficiary_country_name}
                                                                </React.Fragment>
                                                            </td>
                                                        </tr>
                                                    </>
                                                } {/* !payout.internalAccount &&  */}

                                                {payout.swift_code && (
                                                    <tr>
                                                        <th className={classes.tableHeaders}>
                                                            SWIFT Code
                                                        </th>
                                                        <td className={classes.tableCells}>
                                                            {payout.swift_code ? payout.swift_code : ''}
                                                        </td>
                                                    </tr>
                                                )}

                                                {payout.bsb_code && (
                                                    <tr>
                                                        <th className={classes.tableHeaders}>
                                                            BSB
                                                        </th>
                                                        <td className={classes.tableCells}>
                                                            {payout.bsb_code ? payout.bsb_code : ''}
                                                        </td>
                                                    </tr>
                                                )}

                                                {payout.account_number && (
                                                    <tr>
                                                        <th className={classes.tableHeaders}>
                                                            Account Number
                                                        </th>
                                                        <td className={classes.tableCells}>
                                                            {payout.account_number ? payout.account_number : ''}
                                                        </td>
                                                    </tr>
                                                )}

                                                {payout.iban && (
                                                    <tr>
                                                        <th className={classes.tableHeaders}>
                                                            IBAN
                                                        </th>
                                                        <td className={classes.tableCells}>
                                                            {payout.iban ? (
                                                                <span>
                                                    {payout.iban}
                                                </span>
                                                            ) : ''}
                                                        </td>
                                                    </tr>
                                                )}

                                                {payout.aba_routing_number && (
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            ABA
                                                            Routing
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            {payout.aba_routing_number ? (
                                                                <span
                                                                >
                                                    {
                                                        payout.aba_routing_number
                                                    }
                                                                    <br/>
                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                                {payout.sort_code && (
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            Sort
                                                            Code
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            {payout.sort_code ? (
                                                                <span
                                                                >
                                                    {
                                                        payout.sort_code
                                                    }
                                                                    <br/>
                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                                {payout.transit_code && (
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            Transit
                                                            Code
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            {payout.transit_code ? (
                                                                <span
                                                                >
                                                    {
                                                        payout.transit_code
                                                    }
                                                                    <br/>
                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                                {payout.bank_code && (
                                                    <tr>
                                                        <th
                                                            className={
                                                                classes.tableHeaders
                                                            }
                                                        >
                                                            Bank
                                                            Code
                                                        </th>
                                                        <td
                                                            className={
                                                                classes.tableCells
                                                            }
                                                        >
                                                            {payout.bank_code ? (
                                                                <span
                                                                >
                                                    {
                                                        payout.bank_code
                                                    }
                                                                    <br/>
                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        {/* })
} */}
                        </tbody>
                    </table>
                )
            }
            if (state.payouts_list.length > 1 && (payout.beneficiary_id === state.payouts_list[0].beneficiary_id)) {
                returnValue.push(
                    <table style={{width: '100%'}}>
                        <tbody>
                        {/* {
    this.state.payouts_list.map((item) => {
    return <tr> */}
                        <tr style={{width: '100%'}}>
                            {/* <td style={{ verticalAlign: "top", paddingRight: 20, paddingLeft: 8, paddingTop: 10, paddingBottom: 10 }}> */}
                            <td>
                                <table style={{width: '100%'}}>
                                    <tbody style={{width: '100%'}}>
                                    <tr
                                        style={{
                                            width:
                                                '100%',
                                        }}
                                    >
                                        <td
                                            className={
                                                classes.tableCells
                                            }
                                        >
                                            <table
                                                style={{
                                                    width:
                                                        '100%',
                                                }}
                                            >
                                                <tbody
                                                >


                                                <tr
                                                >
                                                    <th
                                                        className={
                                                            classes.tableHeaders
                                                        }
                                                    >

                                                    </th>
                                                    <td
                                                        className={
                                                            classes.tableCells
                                                        }
                                                    >
                                                        <React.Fragment
                                                        >
                                                            <em>
                                                                Payout {(i + 1)}:
                                                            </em>
                                                            &nbsp;&nbsp;&nbsp;
                                                            {props.intl.formatNumber(
                                                                payout.amount_to,
                                                                {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            )}{` `}
                                                            <div
                                                                className={`currency-flag currency-flag-${state.currency_to.iso_alpha_3.toLowerCase()}`}
                                                            />
                                                            {` `}
                                                            {state.currency_to.iso_alpha_3.toUpperCase()}
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                )
            }
        }
        return returnValue;
    }

    const buildPayoutList = () => {
        const payoutList = state.payouts_list.map(i => ({
            key: i.id,
            date: state.transfer && state.transfer.record_created ? moment(state.transfer && state.transfer.record_created).format('DD/MM/YYYY') : '-',
            description: i.invoice_detail,
            amount: props.intl.formatNumber(i.amount_to, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
        }));
        payoutList.push({
            date: '',
            description: '',
            key: '',
            amount: (
                <strong>
                    {props.intl.formatNumber(
                        state && state.transfer.amount_to,
                        {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }
                    )}
                </strong>
            )
        })
        return payoutList;
    }

    const buildPayoutColumns = () => {
        const columns = [
            {key: 1, dataIndex: 'date', title: 'Date'},
            {key: 2, dataIndex: 'description', title: 'Description'},
            {key: 3, dataIndex: 'amount', title: 'Amount'}
        ];
        return columns;
    }

    const proceedToTransfer = async (
        pendingTransferId,
        currency_iso_alpha_3,
        amount_to,
        tpp_beneficiary_id,
        purpose_of_payment_detail,
        detail_1
    ) => {
        await API.put('transfers_pending', `/update/${pendingTransferId}`, {
            body: {
                status_id: 3,
            },
        })
            .then(apiResponse => {
                console.log(apiResponse)
            })
            .catch(error => {
                console.log(error)
            })
        props.history.push(`/shortcut_transfer/AUD-${currency_iso_alpha_3}--${amount_to}-${tpp_beneficiary_id}-${purpose_of_payment_detail}--${detail_1}?pt=${pendingTransferId}`)
    }

    return (
        <>
            <div>
                <GridContainer alignItems="stretch">
                    <GridItem xs={12}>
                        <Card style={{height: '100%'}}>
                            <CardHeader color="info" stats icon />
                            <CardBody>
                                <p className={classes.cardCategory}></p>
                                <div
                                    id="divToPrint"
                                    className="mt4"
                                    style={{
                                        hidden: 'hidden',

                                        width: '210mm',
                                        minHeight: 'auto',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        padding: '20mm',
                                    }}
                                >
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        {
                                            state.team && <img
                                                src={require(`assets/img/brand/${state.team.brand_sidebar_logo}`)}
                                                alt="logo"
                                                width={'100px'}
                                                className={classes.headerImage}
                                                style={{width: state.payouts_list[0].beneficiary_id === 5585 ? '100px' : ''}}
                                            />
                                        }
                                        {
                                            state.payouts_list.length > 0 && <>
                                                    <span style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'end'
                                                    }}>
                                                   <h4>
                                                        <span>{state.payouts_list[0].ben_legal_name}</span>
                                                    </h4>
                                                    <span style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'end',
                                                        alignItems: 'end'
                                                    }}>
                                                        <React.Fragment>
                                                            <span>{state.payouts_list[0].ben_address_line_1}</span>
                                                            <span>{state.payouts_list[0].ben_address_line_2}</span>
                                                            <span>
                                                                {state.payouts_list[0].ben_address_suburb}
                                                                {state.payouts_list[0].ben_address_state} {' '}
                                                                {state.payouts_list[0].ben_address_postcode}
                                                            </span>
                                                            <span>
                                                                {state.payouts_list[0].beneficiary_country_name}
                                                            </span>
                                                        </React.Fragment>
                                                    </span>
                                            </span>
                                            </>
                                        }
                                    </div>
                                    <hr/>
                                    <h3 className={classes.pageHeadings}>
                                        {state && state.team && state.team.brand_pr_paymentRequestHeading}
                                    </h3>
                                    <GridContainer>
                                        <GridItem xs={12} md={12} style={{textAlign: 'right'}}>
                                            <strong>Due Date: </strong>
                                            {state.transfer.expiryDate != null ? moment(state.transfer.expiryDate).format('DD/MM/YYYY') : '-'}
                                        </GridItem>
                                    </GridContainer>
                                    <br/>

                                    <GridContainer justify="center">
                                        <GridItem xs={12} md={12}>
                                            <h5 className={classes.pageSubHeadings}>
                                                Invoice To:
                                            </h5>
                                            <p>
                                                <strong>
                                                    {state && state.transfer && state.transfer
                                                        .client_nickname
                                                        ? state
                                                            .transfer
                                                            .client_nickname
                                                        : ''}
                                                </strong>
                                                <br/>

                                                {state.client !=
                                                null ? (
                                                    <>
                                                            <span>
                                                                {
                                                                    state.client
                                                                        .residential_street_line_1
                                                                }
                                                            </span>
                                                        <br/>
                                                        <span>
                                                                {
                                                                    state.client
                                                                        .residential_street_line_2
                                                                }
                                                            </span>
                                                        {state.client
                                                            .residential_street_line_2 && (
                                                            <span>
                                                                    <br/>
                                                                </span>
                                                        )}
                                                        <span>
                                                                {
                                                                    state.client
                                                                        .residential_street_suburb
                                                                }
                                                            </span>
                                                        &nbsp;
                                                        <span>
                                                                {
                                                                    state.client
                                                                        .residential_street_state
                                                                }
                                                            </span>
                                                        &nbsp;
                                                        <span>
                                                                {
                                                                    state.client
                                                                        .residential_street_postcode
                                                                }
                                                            </span>
                                                        &nbsp;
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </p>
                                            <br/>
                                            <h5
                                                className={
                                                    classes.pageSubHeadings
                                                }
                                            >
                                                Invoice Details:
                                            </h5>
                                            <p>
                                                <strong>Currency:</strong>
                                                {` `}
                                                <div
                                                    className={`currency-flag currency-flag-${state && state.currency_to.iso_alpha_3.toLowerCase()}`}
                                                />
                                                {` `}
                                                {state && state.currency_to.iso_alpha_3.toUpperCase()}
                                            </p>
                                            <p>
                                                <strong>Summary:</strong>
                                                {` `}
                                                {
                                                    state && state
                                                        .payouts_list[0]
                                                        .detail_1
                                                }
                                            </p>
                                            <Table dataSource={buildPayoutList()}
                                                   size={'small'}
                                                   columns={buildPayoutColumns()}
                                                   pagination={false}
                                                   bordered={true}
                                                   style={{marginBottom: '5px'}}>
                                            </Table>
                                            <hr/>
                                            <h5 className={classes.pageSubHeadings}>
                                                Payment Details
                                            </h5>
                                            <div style={{border: '1px solid #a1a1a1',}}>
                                                <table style={{width: '100%',}}>
                                                    <tbody>
                                                    {payoutItemTable()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </CardBody>
                            <CardFooter/>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

export default connect(mapStateToProps)(injectIntl(withRouter(withStyles(style)(PaymentRequestViewPage))))