import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {API} from 'aws-amplify';
import 'react-circular-progressbar/dist/styles.css';
import {AiOutlineSearch} from 'react-icons/ai';
import {FormattedNumber} from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Button from '../CustomButtons/Button';
import Dvr from '@material-ui/icons/Dvr';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Close from '@material-ui/core/SvgIcon/SvgIcon';
import moment from 'moment';

const style = theme => ({
  id: {color: '#0BACF4'},
  currencyContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  currency: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'fit-content',
    '& > .currency-flag': {
      marginRight: '0.5rem',
      width: 52,
      height: 26
    }
  },
  currencyLabel: {
    textAlign: 'right',
    '& > p': {
      marginBottom: -5,
      fontSize: '0.6rem'
    }
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'left',
    '& > span': {
      // marginLeft: 12,
      fontSize: 12,
      textTransform: 'uppercase'
    }
  },
  progressBar: {width: 50}
});

const CurrencyElement = ({classes, currency, value}) => (
  <div className={classes.currency}>
    <div className={`currency-flag currency-flag-${currency ? currency.toLowerCase() : ''}`} />
    <div className={classes.currencyLabel}>
      <p>{currency}</p>
      <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={value} />
    </div>
  </div>
);
class CustomTransferList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transfer_list: [],
      entity_list: []
    };
  }

  componentDidMount() {
    API.get('transfers', '/get-list-not-deleted')
      // API.get("transfers", `/get-list-for-dashboard`)
      .then(response => {
        // console.log(response);
        this.setState({transfer_list: response});
      })
      .catch(error => {
        console.log(error);
        API.get('transfers', '/get-list-not-deleted')
          // API.get("transfers", `/get-list-for-dashboard`)
          .then(response => {
            // console.log(response);
            this.setState({transfer_list: response});
          });
      });
  }

  handleClose = e => {
    window.location = '/transfers/edit/' + e;
  };

  buildTableData() {
    let date_range_transfer_list = this.state.transfer_list;
    // console.log(this.state.transfer_list); //print out to watch #892 changes - duplicate transfer display
    if (this.state.startDate && this.state.endDate) {
      date_range_transfer_list = this.props.transfers.transfer_list.filter(transfer => {
        return (
          this.state.startDate.isBefore(moment(transfer.transaction_datetime).add(1, 'day')) &&
          this.state.endDate.isAfter(moment(transfer.transaction_datetime))
        );
      });
    }

    const transfer_list_length = date_range_transfer_list.length;
    if (transfer_list_length > 0) {
      return date_range_transfer_list.map((prop, key) => {
        // console.log(prop);
        return {
          id: prop.id,
          client: prop.client_nickname,
          transaction_date: prop.transaction_datetime,
          status_id: prop.status_id,
          memo: prop.memo,
          nickname: prop.nickname,
          settlement_date: prop.settlement_date,
          beneficiary: prop.beneficiaries_nickname,

          currency_from: prop.currency_from_iso_alpha_3,
          amount_from: prop.amount_from,

          amount_to: prop.amount_to,
          currency_to: prop.currency_to_iso_alpha_3,
          client_rate: prop.client_rate ? prop.client_rate.toFixed(5) : '',
          transfer_status: prop.transfer_status,
          actions: (
            <div className='actions-right'>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  this.props.history.push(`/transfers/edit/${prop.id}`);
                }}
                color='warning'
                className='edit'
              >
                <Dvr />
              </Button>{' '}
              {this.props.app_state.current_staff_super_admin && (
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    //console.log(this);
                    const data = this.props.transfers.transfer_list;
                    data.find((o, i) => {
                      if (o.id === prop.id) {
                        this.setState({
                          dialog_delete_confirm: true,
                          dialog_delete_item: prop.id,
                          dialog_delete_item_name: `${prop.nickname}`
                        });

                        return true;
                      }
                      return false;
                    });
                    this.setState({data: data});
                  }}
                  color='danger'
                  className='remove'
                >
                  <Close />
                </Button>
              )}{' '}
            </div>
          )
        };
      });
    }
  }

  getSteps() {
    return ['Submitted', 'Acknowledged', 'Customer Money Received', 'Beneficiary Payment Made', 'Completed'];
  }

  getSteps1() {
    return ['Submitted', 'Not Proceeding'];
  }
  // 'In Process' ? '#632DE6' : c.value === 'Completed' ? '#00FF00' : '#FF0000'
  green = '#238636';
  red = '#FF0000';
  orange = 'orange';
  purple = '#632DE6';
  render() {
    const {classes} = this.props; //disable it because classes never used #1100 by Tom
    const steps = this.getSteps();
    const steps1 = this.getSteps1();

    const transfer_status = this.state.transfer_list.map((prop, key) => {
      return prop.transfer_status;
    });
    const transfer_status_unique = [...new Set(transfer_status)];
    const transfer_status_optionlist = transfer_status_unique.map((prop, key) => {
      // console.log(prop);
      return (
        <option key={key} value={prop}>
          {prop}
        </option>
      );
    });

    return (
      <div>
        <ReactTable
          data={this.buildTableData()}
          filterable
          columns={[
            {
              Header: 'Id',
              accessor: 'id',
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['id']}),
              filterAll: true,
              maxWidth: 75
            },
            {
              Header: 'Client',
              accessor: 'client',
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['client']}),
              filterAll: true,
              maxWidth: 450
            },
            {
              Header: 'Transaction',
              accessor: 'transaction_date',
              Cell: c => <React.Fragment>{moment(c.value).format('DD/MM/YYYY')} </React.Fragment>,
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['transaction_date']}),
              filterAll: true
            },
            {
              Header: 'Settlement',
              accessor: 'settlement_date',
              Cell: c => <React.Fragment>{moment(c.value).format('DD/MM/YYYY')} </React.Fragment>,
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['settlement_date']}),
              filterAll: true
            },
            {
              Header: 'From',
              accessor: 'amount_from',
              Cell: c => (
                <React.Fragment>
                  <CurrencyElement
                    currency={c.original.currency_from}
                    value={c.original.amount_from}
                    classes={classes}
                  />
                </React.Fragment>
              ),
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['amount_from']}),
              filterAll: true
            },
            {
              Header: '',
              sortable: false,
              filterable: false,
              width: 50,
              Cell: c => <span>⮕</span>
            },
            {
              Header: 'To',
              accessor: 'amount_to',
              Cell: c => (
                <React.Fragment>
                  <CurrencyElement currency={c.original.currency_to} value={c.original.amount_to} classes={classes} />
                </React.Fragment>
              ),
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['amount_to']}),
              filterAll: true
            },
            {
              Header: 'Rate',
              accessor: 'client_rate',
              width: 75,
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['client_rate']}),
              filterAll: true
            },
            {
              Header: 'Status ',
              accessor: 'transfer_status',
              width: 200,
              Cell: c => (
                <div className={classes.progress}>
                  <CircularProgressbar
                    style={{
                      trail: {
                        // Trail color
                        stroke: '#d6d6d6',
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Rotate the trail
                        transform: 'rotate(0.25turn)',
                        transformOrigin: 'left left'
                      },
                      path: {
                        // Path color
                        // stroke: `rgba(62, 152, 199, ${percentage / 100})`,
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Customize transition animation
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                        // Rotate the path
                        transform: 'rotate(0.25turn)',
                        transformOrigin: 'left left'
                      }
                    }}
                    className={classes.progressBar}
                    value={
                      c.value === 'IN Process'
                        ? 30
                        : c.value === 'Completed'
                        ? 100
                        : c.value === 'Acknowledged'
                        ? 20
                        : c.value === 'Funds Received'
                        ? 70
                        : c.value === 'Not Proceeding'
                        ? 100
                        : c.value === 'Processing'
                        ? 40
                        : 50
                    }
                    text={`${
                      c.value === 'In Process'
                        ? 30
                        : c.value === 'Completed'
                        ? 100
                        : c.value === 'Acknowledged'
                        ? 20
                        : c.value === 'Funds Received'
                        ? 70
                        : c.value === 'Not Proceeding'
                        ? 100
                        : c.value === 'Processing'
                        ? 40
                        : 50
                    }%`}
                    styles={buildStyles({
                      textColor: '#000',
                      textSize: '20px',
                      transformOrigin: 'left center',
                      trail: {
                        // Trail color
                        stroke: '#d6d6d6',
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Rotate the trail
                        transform: 'rotate(0.25turn)',
                        transformOrigin: 'left center'
                      },
                      // pathColor: c.value === 'In Process' ? '#632DE6' : c.value === 'Completed' ? '#00FF00' : '#FF0000'
                      pathColor:
                        c.value === 'In Process'
                          ? this.green
                          : c.value === 'Completed'
                          ? this.green
                          : c.value === 'Acknowledged'
                          ? this.green
                          : c.value === 'Funds Received'
                          ? this.green
                          : c.value === 'Not Proceeding'
                          ? this.red
                          : c.value === 'Processing'
                          ? this.green
                          : this.orange
                    })}
                  />
                  <span>{c.value}</span>
                </div>
              ),
              filterMethod: (filter, row) => {
                if (filter.value === 'all') {
                  return true;
                }
                return row[filter.id] == filter.value;
              },
              Filter: ({filter, onChange}) => (
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{width: '100%'}}
                  value={filter ? filter.value : 'all'}
                >
                  <option value='all'>Show All</option>
                  {transfer_status_optionlist}
                </select>
              )
            },
            {
              Header: '',
              accessor: 'actions',
              sortable: false,
              filterable: false,
              maxWidth: 100
            }
          ]}
          defaultSorted={[
            {
              id: 'transaction_date',
              desc: true
            },
            {
              id: 'id',
              desc: true
            }
          ]}
          defaultPageSize={10}
          showPaginationBottom
          className='-highlight'
          SubComponent={v => {
            let ActivTab = 0;
            if (v.original.status_id == 2) {
              ActivTab = 0;
            }
            if (v.original.status_id == 3) {
              ActivTab = 1;
            }
            if (v.original.status_id == 4) {
              ActivTab = 1;
            }
            if (v.original.status_id == 5) {
              ActivTab = 1;
            }
            if (v.original.status_id == 6) {
              ActivTab = 2;
            }
            if (v.original.status_id == 7) {
              ActivTab = 3;
            }
            if (v.original.status_id == 8) {
              ActivTab = 4;
            }

            if (v.original.status_id == 10) {
              ActivTab = 1;
            }

            return (
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={9}>
                      {/*<h6>{v.original.transfer_status_name}</h6>*/}
                      {v.original.status_id != 10 && (
                        <Stepper activeStep={ActivTab}>
                          {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};

                            return (
                              <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                              </Step>
                            );
                          })}
                        </Stepper>
                      )}
                      {v.original.status_id == 10 && (
                        <Stepper activeStep={ActivTab} style={{width: '30%'}}>
                          {steps1.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};

                            return (
                              <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                              </Step>
                            );
                          })}
                        </Stepper>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                      {/*<h6>Actions</h6>*/}
                      <div style={{paddingTop: 16}}>
                        {
                          // == Remove "Copy to New Transfer: button for CRM Issue #941 by Tom
                          // <Button
                          //   style={{width: 200}} color="primary" size="sm"
                          //   onClick={() => {
                          //     API.get("transfers", `/get/id/${v.original.id}`)
                          //       .then(transfer_detail => {
                          //         //console.log(transfer_detail);
                          //         //console.log(v.original);
                          //         this.props.history.push(`/dashboard/${v.original.currency_from}-${v.original.currency_to}-${v.original.amount_from}-${v.original.amount_to}-${(transfer_detail.fullList.beneficiary_id === null) ? "" : transfer_detail.fullList.beneficiary_id}-${(transfer_detail.fullList.purpose_of_payment_id === null) ? "" : transfer_detail.fullList.purpose_of_payment_id}-${(transfer_detail.fullList.purpose_of_payment_detail === null) ? " " : transfer_detail.fullList.purpose_of_payment_detail}`);
                          //       })
                          //   }}
                          // >
                          //   Copy to New Transfer</Button>
                          // <br/>
                        }
                        <Button
                          style={{width: 200}}
                          color='primary'
                          size='sm'
                          onClick={() => {
                            this.props.history.push(`/transfers/edit/${v.original.id}`);
                          }}
                          className='edit'
                        >
                          View Transfer Details
                        </Button>
                        <br />
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

const CustomTransferListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(CustomTransferList)));

export default CustomTransferListContainer;
