import {API} from 'aws-amplify';
import {Button} from 'antd';
import React from 'react';

export const TransferDetailConstants = {
  COLUMNS: [
    {name: 'id', label: 'ID', filterable: true, sortable: true},
    {name: 'transferId', label: 'TRANSFER ID', filterable: true, sortable: true},
    {name: 'documentId', label: 'DOCUMENT ID', filterable: true, sortable: true},
    {name: 'description', label: 'DESCRIPTION', filterable: true, sortable: true},
    {name: 'recordCreated', label: 'CREATED', filterable: true, sortable: true, colType: 'DATE'},
    {name: 'actions', label: 'ACTION', filterable: false, sortable: false},
  ]
}

export const TransferDetailAPI = {
  fetchTransferDocuments: (transferId) => API.get('transfer-documents', `/list-by-transfer-id/${transferId}`, {}),
  saveTransferDocument: (data) => API.post('transfer-documents', '/create', {body: data})
}

export const TransferDetailUtil = {
    
}