import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';

import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardAvatar from 'components/Card/CardAvatar.jsx';
import Table from 'components/Table/Table.jsx';
import NavPills from 'components/NavPills/NavPills.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import settingsStaffProfileStyles from './SettingsStaffProfileStyles';
import PermIdentity from '@material-ui/icons/PermIdentity';

import avatar from '../../assets/img/faces/blank-profile-picture.jpg';
import {API} from 'aws-amplify';
import {Auth} from 'aws-amplify';
import ChangeEmailDialog from './Settings/StaffProfile/ChangeEmailDialog.jsx';

//core for react-intl
import {FormattedMessage} from 'react-intl';

class SettingsStaffProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      update_loading: false,
      user: null,
      aws_cognito_id: '',
      email: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      change_password_dialog: false,
      old_password: '',
      new_password: '',
      new_password_confirm: '',
      new_password_state: '',
      new_password_confirm_state: '',
      new_password_changing: false,
      change_email_dialog: false
    };
  }

  async componentDidMount() {
    // console.log("componentDidMount");

    // if (this.props.isAuthenticated && this.props.current_user) {
    if (this.props.app_state.current_staff_id && this.props.app_state.current_staff) {
      console.log('authenticated & current_user so getting details');
      try {
        const user = await this.getUserProfile(this.props.app_state.current_staff_id);
        const {
          aws_cognito_id,
          email,
          first_name,
          middle_name,
          last_name,
          residential_street_line_1,
          residential_street_line_2,
          residential_street_suburb,
          residential_street_state,
          residential_street_postcode,
          residential_street_country,
          telephone_home,
          telephone_mobile,
          telephone_work,
          email_secondary
        } = user;

        this.setState({
          aws_cognito_id,
          email,
          first_name,
          middle_name,
          last_name,
          residential_street_line_1,
          residential_street_line_2,
          residential_street_suburb,
          residential_street_state,
          residential_street_postcode,
          residential_street_country,
          telephone_home,
          telephone_mobile,
          telephone_work,
          email_secondary
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log("componentWillReceiveProps");
    // console.log(nextProps);

    // if (nextProps.isAuthenticated && nextProps.current_user) {
    if (nextProps.app_state.current_staff_id !== this.props.app_state_current_staff_id) {
      // console.log(
      //   "nextProps gives authenticated & current_user so getting details"
      // );
      try {
        const user = await this.getUserProfile(
          // nextProps.current_user.aws_cognito_id
          nextProps.app_state.current_staff_id
        );
        const {
          aws_cognito_id,
          email,
          first_name,
          middle_name,
          last_name,
          residential_street_line_1,
          residential_street_line_2,
          residential_street_suburb,
          residential_street_state,
          residential_street_postcode,
          residential_street_country,
          telephone_home,
          telephone_mobile,
          telephone_work,
          email_secondary
        } = user;

        this.setState({
          aws_cognito_id,
          email,
          first_name,
          middle_name,
          last_name,
          residential_street_line_1,
          residential_street_line_2,
          residential_street_suburb,
          residential_street_state,
          residential_street_postcode,
          residential_street_country,
          telephone_home,
          telephone_mobile,
          telephone_work,
          email_secondary
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  async getUserProfile(aws_cognito_id) {
    return API.get('staff', `/get/aws_cognito_id/${aws_cognito_id}`);
  }

  async saveUserProfile(user) {
    // console.log(user);
    return API.put('staff', `/update/aws_cognito_id/${this.state.aws_cognito_id}`, {
      body: user
    });
  }

  handle_change_password_dialog_open = () => {
    this.setState({
      change_password_dialog: true
    });
  };

  handle_change_password_dialog_close = () => {
    this.setState({
      change_password_dialog: false
    });
  };

  handle_change_password_proceed = async (old_password, new_password) => {
    this.setState({
      new_password_changing: true
    });

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      // console.log(currentUser);
      await Auth.changePassword(currentUser, old_password, new_password);
      // console.log(currentUser);
      this.setState({
        new_password_changing: false,
        change_password_dialog: false,
        new_password: '',
        new_password_confirm: ''
      });
    } catch (e) {
      alert(e.message);
      this.setState({
        new_password_changing: false,
        change_password_dialog: false,
        new_password: '',
        new_password_confirm: ''
      });
    }
    this.setState({
      new_password_changing: false,
      change_password_dialog: false,
      new_password: '',
      new_password_confirm: ''
    });
  };

  // Change Email Dialog Handlers
  handle_change_email_dialog_open = () => {
    this.setState({
      change_email_dialog: true
    });
  };

  handle_change_email_dialog_close = () => {
    this.setState({
      change_email_dialog: false
    });
    this.props.history.push('/pages/login');
  };

  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
    // this.props.updateNewClientCreation(stateName, event.target.value)
  }
  // function that returns true if value is valid password, false otherwise
  verifyPassword(event, value, stateName) {
    // var passwordRex = /^(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z])(?=.*\d.*\d)(?=.*\W.*\W)[a-zA-Z0-9\S]{9,}$/;
    // This pattern requires at least two lowercase letters, two uppercase letters, two digits, and two special characters. There must be a minimum of 9 characters total, and no white space characters are allowed.
    // very strong password

    var passwordRex = /^(?=.*[!@#$%^&*()\-_=+`~\[\]{}?|])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/;
    // This pattern requires at least longer than 8 characters and contain at least 1 uppercase & 1 number and 1 special character

    this.setState({[stateName]: event.target.value});
    // console.log(event.target.value);
    if (passwordRex.test(event.target.value)) {
      // console.log("True");
      this.setState({
        new_password_state: 'success',
        new_password_helptext: ''
      });
      return true;
    }
    // console.log("False");
    this.setState({
      new_password_state: 'error',
      new_password_helptext:
        'Must be longer than 8 characters and contain at least 1 uppercase & 1 number & 1 special character'
    });
    return false;
  }
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading: true});
    // console.log(this.state);
    const {
      // aws_cognito_id,
      first_name,
      middle_name,
      last_name,
      // email
      residential_street_line_1,
      residential_street_line_2,
      residential_street_suburb,
      residential_street_state,
      residential_street_postcode,
      residential_street_country,
      telephone_home,
      telephone_mobile,
      telephone_work,
      email_secondary
    } = this.state;
    try {
      await this.saveUserProfile({
        // aws_cognito_id: aws_cognito_id,
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        residential_street_line_1: residential_street_line_1,
        residential_street_line_2: residential_street_line_2,
        residential_street_suburb: residential_street_suburb,
        residential_street_state: residential_street_state,
        residential_street_postcode: residential_street_postcode,
        residential_street_country: residential_street_country,
        telephone_home: telephone_home,
        telephone_mobile: telephone_mobile,
        telephone_work: telephone_work,
        email_secondary: email_secondary
      });
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      alert(e);
      // this.setState({ isLoading: false });
    }
    // this.props.reload_user(this.state.aws_cognito_id);
  };

  render() {
    const {classes} = this.props;
    // console.log("state:", this.state);
    // console.log(this.props);

    if (!this.props.app_state.current_staff_id || !this.props.app_state.current_staff) {
      return <div>NO CURRENT USER</div>;
    }

    return (
      <React.Fragment>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card profile>
                <CardAvatar profile>
                  <a href='#pablo' onClick={e => e.preventDefault()}>
                    <img src={avatar} alt='...' />
                  </a>
                </CardAvatar>
                <CardBody profile>
                  <h4 className={classes.cardTitle}>{`${this.state.first_name}  ${this.state.last_name}`}</h4>
                  <Table
                    tableData={[
                      [
                        <FormattedMessage id='staffProfile.cardItem1.username' defaultMessage={`Username`} />,
                        `${this.state.email}`
                      ],
                      [
                        <FormattedMessage
                          id='staffProfile.cardItem1.securityLevel'
                          defaultMessage={`Security Level`}
                        />,
                        this.props.app_state.current_staff.super_admin === 1 ? 'Super Admin' : 'Staff Member'
                      ],
                      // [
                      //   <FormattedMessage
                      //     id='staffProfile.cardItem1.activeCustomers'
                      //     defaultMessage={`Active Customers`}
                      //   />,
                      //   '0'
                      // ]
                    ]}
                  />

                  <GridContainer>
                    {/* <GridItem xs={12} sm={12}>
                      <Button color='warning' onClick={() => this.handle_change_email_dialog_open()}>
                        <FormattedMessage id='staffProfile.cardItem1.button1' defaultMessage={`Change Primary Email`} />
                      </Button>
                    </GridItem> */}
                    <GridItem xs={12} sm={12}>
                      <Button color='warning' onClick={() => this.handle_change_password_dialog_open()}>
                        <FormattedMessage id='staffProfile.cardItem1.button2' defaultMessage={`Change Password`} />
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color='rose' icon>
                  <CardIcon color='rose'>
                    <PermIdentity />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    <FormattedMessage id='staffProfile.cardItem2.title' defaultMessage={`Staff Profile`} />
                  </h4>
                </CardHeader>
                <CardBody>
                  <NavPills
                    color='info'
                    tabs={[
                      {
                        tabButton: (
                          <FormattedMessage id='staffProfile.cardItem2.tabButton1' defaultMessage={`User & Account`} />
                        ),
                        tabContent: (
                          <div style={{padding: 20}}>
                            <form onSubmit={this.handleSubmit}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText={
                                      <FormattedMessage
                                        id='staffProfile.cardItem2.usernameWithEmail'
                                        defaultMessage={`Username (primary email)`}
                                      />
                                    }
                                    id='email'
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      disabled: true,
                                      value: this.state.email || '',
                                      onChange: event => {
                                        this.handleChange(event);
                                      }
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>

                              <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                  <CustomInput
                                    labelText={
                                      <FormattedMessage
                                        id='staffProfile.cardItem2.firstname'
                                        defaultMessage={`First Name`}
                                      />
                                    }
                                    id='first_name'
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      value: this.state.first_name || '',
                                      onChange: event => {
                                        this.handleChange(event);
                                      }
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                  <CustomInput
                                    labelText={
                                      <FormattedMessage
                                        id='staffProfile.cardItem2.middlename'
                                        defaultMessage={`Middle Name(s)`}
                                      />
                                    }
                                    id='middle_name'
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      value: this.state.middle_name || '',
                                      onChange: event => {
                                        this.handleChange(event);
                                      }
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                  <CustomInput
                                    labelText={
                                      <FormattedMessage
                                        id='staffProfile.cardItem2.lastname'
                                        defaultMessage={`Last Name`}
                                      />
                                    }
                                    id='last_name'
                                    disabled
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      value: this.state.last_name || '',
                                      onChange: event => {
                                        this.handleChange(event);
                                      }
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>

                              <Button color='primary' type='submit' className={classes.updateProfileButton}>
                                <FormattedMessage
                                  id='staffProfile.cardItem2.button1'
                                  defaultMessage={`Update User & Account`}
                                />
                              </Button>
                            </form>
                          </div>
                        )
                      },
                      {
                        tabButton: (
                          <FormattedMessage id='staffProfile.cardItem2.tabButton2' defaultMessage={`Address`} />
                        ),
                        tabContent: (
                          <div style={{padding: 20}}>
                            <form onSubmit={this.handleSubmit}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText={
                                      <FormattedMessage
                                        id='staffProfile.cardItem2.addressLine1'
                                        defaultMessage={`Address (line 1)`}
                                      />
                                    }
                                    id='residential_street_line_1'
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      value: this.state.residential_street_line_1 || '',
                                      onChange: event => {
                                        this.handleChange(event);
                                      }
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText={
                                      <FormattedMessage
                                        id='staffProfile.cardItem2.addressLine2'
                                        defaultMessage={`Address (line 2)`}
                                      />
                                    }
                                    id='residential_street_line_2'
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      value: this.state.residential_street_line_2 || '',
                                      onChange: event => {
                                        this.handleChange(event);
                                      }
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>

                              <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                  <CustomInput
                                    labelText={
                                      <FormattedMessage id='staffProfile.cardItem2.suburb' defaultMessage={`Suburb`} />
                                    }
                                    id='residential_street_suburb'
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      value: this.state.residential_street_suburb || '',
                                      onChange: event => {
                                        this.handleChange(event);
                                      }
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                  <CustomInput
                                    labelText={
                                      <FormattedMessage id='staffProfile.cardItem2.state' defaultMessage={`State`} />
                                    }
                                    id='residential_street_state'
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      value: this.state.residential_street_state || '',
                                      onChange: event => {
                                        this.handleChange(event);
                                      }
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                  <CustomInput
                                    labelText={
                                      <FormattedMessage
                                        id='staffProfile.cardItem2.postcode'
                                        defaultMessage={`Postcode`}
                                      />
                                    }
                                    id='residential_street_postcode'
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      value: this.state.residential_street_postcode || '',
                                      onChange: event => {
                                        this.handleChange(event);
                                      }
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                              <Button color='primary' type='submit' className={classes.updateProfileButton}>
                                <FormattedMessage
                                  id='staffProfile.cardItem2.button2'
                                  defaultMessage={`Update User & Account`}
                                />
                              </Button>
                            </form>
                          </div>
                        )
                      },
                      {
                        tabButton: (
                          <FormattedMessage id='staffProfile.cardItem2.tabButton3' defaultMessage={`Contact`} />
                        ),
                        tabContent: (
                          <div style={{padding: 20}}>
                            <form onSubmit={this.handleSubmit}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText={
                                      <FormattedMessage
                                        id='staffProfile.cardItem2.homePhone'
                                        defaultMessage={`Home Telephone`}
                                      />
                                    }
                                    id='telephone_home'
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      value: this.state.telephone_home || '',
                                      onChange: event => {
                                        this.handleChange(event);
                                      }
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText={
                                      <FormattedMessage
                                        id='staffProfile.cardItem2.workPhone'
                                        defaultMessage={`Work Telephone`}
                                      />
                                    }
                                    id='telephone_work'
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      value: this.state.telephone_work || '',
                                      onChange: event => {
                                        this.handleChange(event);
                                      }
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText={
                                      <FormattedMessage
                                        id='staffProfile.cardItem2.mobilePhone'
                                        defaultMessage={`Mobile Telephone`}
                                      />
                                    }
                                    id='telephone_mobile'
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      value: this.state.telephone_mobile || '',
                                      onChange: event => {
                                        this.handleChange(event);
                                      }
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>

                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText={
                                      <FormattedMessage
                                        id='staffProfile.cardItem2.primaryEmail'
                                        defaultMessage={`Primary Email`}
                                      />
                                    }
                                    id='email'
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      disabled: true,
                                      value: this.state.email || '',
                                      onChange: event => {
                                        this.handleChange(event);
                                      }
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText={
                                      <FormattedMessage
                                        id='staffProfile.cardItem2.secondaryEmail'
                                        defaultMessage={`Secondary Email`}
                                      />
                                    }
                                    id='email_secondary'
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      value: this.state.email_secondary || '',
                                      onChange: event => {
                                        this.handleChange(event);
                                      }
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                              <Button color='primary' type='submit' className={classes.updateProfileButton}>
                                <FormattedMessage
                                  id='staffProfile.cardItem2.button3'
                                  defaultMessage={`Update Contact`}
                                />
                              </Button>
                            </form>
                          </div>
                        )
                      }
                    ]}
                  />
                  <Clearfix />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>

        <Dialog
          open={this.state.change_password_dialog}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            <FormattedMessage id='staffProfile.dialog.title' defaultMessage={`Change Password`} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <CustomInput
                success={this.state.old_password_state === 'success'}
                error={this.state.old_password_state === 'error'}
                labelText='Current Password *'
                id='old_password'
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.password,
                  onChange: event => {
                    this.handleChange(event);
                  }
                }}
              />
              <CustomInput
                success={this.state.new_password_state === 'success'}
                error={this.state.new_password_state === 'error'}
                labelText='New Password *'
                id='new_password'
                helpText={this.state.new_password_helptext}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.new_password,
                  //event => this.change(event, "password", "password"),
                  onChange: event => this.verifyPassword(event, 'password', 'new_password'),
                  type: 'password'
                }}
              />
              <CustomInput
                success={this.state.new_password_confirm_state === 'success'}
                error={this.state.new_password_confirm_state === 'error'}
                labelText='Confirm New Password *'
                id='new_password_confirm'
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.new_password_confirm,
                  onChange: event => this.change(event, 'new_password_confirm', 'equalTo', 'new_password'),
                  type: 'password'
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handle_change_password_dialog_close} color='info'>
              <FormattedMessage id='staffProfile.dialog.cancel' defaultMessage={`Cancel`} />
            </Button>
            <Button
              onClick={() => this.handle_change_password_proceed(this.state.old_password, this.state.new_password)}
              color='primary'
              autoFocus
              disabled={
                !(
                  this.state.old_password.length > 0 &&
                  this.state.new_password_state === 'success' &&
                  this.state.new_password_confirm_state === 'success'
                )
              }
            >
              <FormattedMessage id='staffProfile.dialog.confirm' defaultMessage={`Change Password`} />
            </Button>
          </DialogActions>
        </Dialog>

        <ChangeEmailDialog
          open={this.state.change_email_dialog}
          on_close={this.handle_change_email_dialog_close}
          staff_id={this.props.app_state.current_staff.id}
        />
      </React.Fragment>
    );
  }
}

SettingsStaffProfile.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(settingsStaffProfileStyles)(SettingsStaffProfile);
