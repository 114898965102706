import React from 'react';
import {Input} from 'antd';
import 'antd/dist/antd.css';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomReactSelect from '../Forms/CustomReactSelect/CustomReactSelect';

const style = {
  column: {
    paddingRight: '0 !important',
    paddingBottom: '0 !important'
  },
  columnRight: {paddingLeft: '0 !important'},
  boxShadow: {
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    boxSizing: 'border-box',
    height: '100%'
  },
  input: {
    margin: 0,
    padding: 0,
    border: 'none',
    textAlign: 'right',
    paddingRight: '4%',
    fontWeight: '700',
    fontFamily: 'Roboto',
    fontSize: '24px'
  },
  divv: {
    filter: 'drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25))',
    boxSizing: 'border-box'
  },

  inputReadOnly: {
    paddingTop: '4px',
    zIndex: '-1000',
    paddingRight: '4%',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '13px',
    margin: 0,
    padding: 0,
    paddingBottom: '0',
    border: 'none',
    direction: 'rtl'
  }
};
const colors = {
  blue: '#00BDAB',
  yellow: '#F4C10B'
};
const CommonCurrency = props => {
  const {classes} = props;
  let slectBackground;
  switch (props.color) {
    case 'blue':
      slectBackground = colors.blue;
      break;
    case 'yellow':
      slectBackground = colors.yellow;
      break;

    default:
      slectBackground = colors.blue;
      break;
  }
  return (
    <React.Fragment>
      <GridItem xs={2} sm={3} md={3} className={classes.column}>
        <div style={{backgroundColor: slectBackground}} className={classes.boxShadow}>
          <CustomReactSelect
            isYellow={false}
            error={props.selectError}
            label={<span style={{color: slectBackground}}>{props.selectLabel}</span>}
            options={props.selectOptions}
            value={props.selectValue}
            onChange={props.onSelectChange}
            isClearable={props.isClearable}
            isDisabled={props.isDisabled}
            disableUnderlined={true}
            align={'center'}
            inputStyle={{
              color: 'white',
              lineHeight: '1.0',
              fontSize: '16px',
              fontFamily: 'Roboto',
              fontWeight: '700'
            }}
            containerStyle={{
              overflow: 'visible',
              marginTop: '-1px'
            }}
          />
        </div>
      </GridItem>

      <GridItem xs={8} sm={8} md={9} className={classes.columnRight}>
        <div className={classes.divv}>
          <Input
            className={classes.inputReadOnly}
            value={props.inputValue}
            disabled={Boolean(props.inputIsDisabled != undefined && props.inputIsDisabled == true)}
          />
          <Input
            className={classes.input}
            type='number'
            onChange={props.onInputChange}
            defaultValue={props.defaultValue ? props.defaultValue : ''}
            value={props.inputValue2}
            disabled={Boolean(props.inputIsDisabled != undefined && props.inputIsDisabled == true)}
          />
        </div>
      </GridItem>
    </React.Fragment>
  );
};

const CommoneCurrentComponent = withStyles(style)(CommonCurrency);
export default CommoneCurrentComponent;
