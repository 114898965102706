import {API} from 'aws-amplify';

export const GROUP_FETCH_GROUP_LIST_REQUEST = 'GROUP_FETCH_GROUP_LIST_REQUEST';
export const GROUP_FETCH_GROUP_LIST_SUCCESS = 'GROUP_FETCH_GROUP_LIST_SUCCESS';
export const GROUP_FETCH_GROUP_LIST_FAILURE = 'GROUP_FETCH_GROUP_LIST_FAILURE';
export const GROUP_CREATE_NEW_SHOW_UI = 'GROUP_CREATE_NEW_SHOW_UI';
export const GROUP_CREATE_NEW_START = 'GROUP_CREATE_NEW_START';
export const GROUP_CREATE_NEW_CANCEL = 'GROUP_CREATE_NEW_CANCEL';
export const GROUP_CREATE_NEW_COMPLETE = 'GROUP_CREATE_NEW_COMPLETE';
export const GROUP_SELECT_UI = 'GROUP_SELECT_UI';
export const GROUP_ENTITIES_LIST_IS_DIRTY = 'GROUP_ENTITIES_LIST_IS_DIRTY';
export const GROUP_SELECT_SET_ID = 'GROUP_SELECT_SET_ID';
export const GROUP_SELECT_GROUP_REQUEST = 'GROUP_SELECT_GROUP_REQUEST';
export const GROUP_SELECT_GROUP_SUCCESS = 'GROUP_SELECT_GROUP_SUCCESS';

export function requestEntityList() {
  return {
    type: GROUP_FETCH_GROUP_LIST_REQUEST
  };
}

export function receiveEntityList(json) {
  return {
    type: GROUP_FETCH_GROUP_LIST_SUCCESS,
    groups_list: json,
    receivedAt: Date.now()
  };
}

export function fetchEntityListNotDeleted(team_id) {
  return function (dispatch) {
    dispatch(requestEntityList);

    API.get('groups', `/get/list/${team_id}`)
      .then(response => {
        dispatch(receiveEntityList(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function selectEntityUi(value) {
  return {
    type: GROUP_SELECT_UI,
    value: value
  };
}

export function entitiesListIsDirty(value) {
  return {
    type: GROUP_ENTITIES_LIST_IS_DIRTY,
    value: value
  };
}

export async function create_new_entity_in_database(
  nickname,
  team_id
  //division_id
) {
  API.post('groups', `/create/in-database`, {
    body: {
      nickname: nickname,
      team_id: team_id
    }
  })
    .then(database_result => {
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });

  ////NOT created API yet
  // API.post("teams", `/create/in-database`, {
  //   body: {
  //     nickname: nickname,
  //     division_id: division_id
  //   }
  // })
  //   .then(database_result => {
  //     return database_result;
  //   })
  //   .catch(error => {
  //     console.log(error);
  //   });
}

export function createNewGroup(data) {
  return async function (dispatch) {
    ////NOT created API yet

    dispatch(createNewEntityStart());

    try {
      await create_new_entity_in_database(data.nickname, data.team_id);
    } catch (e) {
      console.log(e);
    }
  };
}

export function cancelCreateNewEntity() {
  return {
    type: GROUP_CREATE_NEW_CANCEL
  };
}

export function setEntityDetailId(id) {
  return {
    type: GROUP_SELECT_SET_ID,
    value: id
  };
}

export function loadEntityDetailsRequest() {
  return {
    type: GROUP_SELECT_GROUP_REQUEST
  };
}
export function loadEntityDetailsSuccess() {
  return {
    type: GROUP_SELECT_GROUP_SUCCESS
  };
}

export function createNewEntityStart() {
  return {
    type: GROUP_CREATE_NEW_START
  };
}

export function createNewEntityComplete() {
  return {
    type: GROUP_CREATE_NEW_COMPLETE
  };
}
