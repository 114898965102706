import React from 'react';
import {API} from 'aws-amplify';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
// import {updateNewTransferCreationTransferType} from '../../../redux/actions/transfers_2';
import {updateNewTransferCreation, clearNewTransfer} from '../../../redux/actions/transfers_dashboard_multiple';
import {Flex, Select, Tag} from "antd";
import FormItem from "antd/lib/form/FormItem";

const style = {
  ...customCheckboxRadioSwitch,

  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {color: '#555'},
  inputAdornment: {position: 'relative'}
};

class Step1Client extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      client_list: [],
      client_id: '',
      client: {},
      team_nickname: '',
      division_nickname: '',
      entity_nickname: '',
      with_query_string: false
    };
  }

  componentDidMount() {
    API.get('clients', `/list/staff/${this.props.app_state.current_staff.id}`)
      .then(response => {
        // console.log(response);
        this.setState({client_list: response}, () => {
          this.checkInitialState();
          this.queryStrings();
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.app_state.current_staff.id !== this.props.app_state.current_staff.id) {
      API.get('clients', `/list/staff/${this.props.app_state.current_staff.id}`)
        .then(response => {
          console.log(response);
          this.setState({client_list: response}, () => {
            this.checkInitialState();
            this.queryStrings();
            });
        }).catch(error => console.log(error));
    }
  }

  checkInitialState = () => {
    const client = this.props.transfers_dashboard_multiple.new_transfer_data.client_id;
    if (client != null) {
      this.handleClientSelectChange(client);
    }
  }

  queryStrings() {
    const query_strings = queryString.parse(this.props.location.search);
    if (Object.keys(query_strings).length !== 0) {
      if (query_strings.client_id) {
        this.setState({client_id: Number(query_strings.client_id)});
        this.props.updateNewTransferCreation('client_id', Number(query_strings.client_id));
        this.getClientDetails(Number(query_strings.client_id));
        this.setState({with_query_string: true});
      }
    }
  }

  getClientDetails(client_id) {
    API.get('clients', `/get/id/${client_id}`)
      .then(response => {
        this.setState({client: response});

        this.props.updateNewTransferCreation('client', response);
        return [response.team_id, response.account_status];
      })
      .then(([team_id, account_status]) => {
        API.get('teams', `/get/div-entity/${team_id}`)
          .then(response => {
            this.setState({
              client: {
                ...this.state.client,
                division_nickname: response.division_nickname,
                entity_nickname: response.entity_nickname,
                team_nickname: response.team_nickname
              }
            });
            this.props.updateNewTransferCreation('division_id', response.division_id);

            if (this.state.client_id != 55) {
              API.post('email', '/interaction/main', {
                body: {
                  interaction: 'New Transfer Request Starting',
                  data: {
                    staff_id: this.props.app_state.current_staff.id,
                    staff_nickname: this.props.app_state.current_staff.nickname,
                    client_id: this.state.client.id,
                    client_nickname: this.state.client.nickname
                  },
                  result: 'New transfer request starting'
                }
              });
            }
            return account_status;
          })
          // Get account status nickname from account status id
          .then(account_status => {
            API.get('clients', '/client-status/get')
              .then(response => {
                let obj;
                for (obj of response) {
                  if (obj.id === account_status) {
                    this.setState({
                      client: {
                        ...this.state.client,
                        account_status_nickname: obj.nickname
                      }
                    });
                  }
                }
              })
              .catch(error => {
                console.log(error);
              });
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  }

  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});

    this.props.updateNewTransferCreation(stateName, event.target.value);
  }

  handleSelectChange = event => {
    this.setState({[event.target.name]: event.target.value});
    this.props.updateNewTransferCreation(event.target.name, event.target.value);
    if (event.target.name === 'client_id') {
      this.getClientDetails(event.target.value);
    }
  };

  isValidated() {
   return true;
  }

  handleClientSelectChange = value => {
    this.setState({client_id: value});
    this.props.updateNewTransferCreation('client_id', value);
    this.getClientDetails(value);
  };

  render() {
    const {classes} = this.props;

    const client_list_select_options = this.state.client_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return {
          value: item.id,
          label: `${item.nickname} [${item.id.toString().padStart(5, '0')}] (${item.email})`
        };
      });

    return (
      <React.Fragment>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              {
                this.state.with_query_string ? 'Please confirm client details for new MultiPay Transfer' : ''
                // languageObj["wizard.transfers.client.sentence1"]
              }
            </h4>
          </GridItem>
        </GridContainer>

        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <FormItem label={'Select Client'} name="client_id">
              <Select
                  showSearch
                  style={{width: 400}}
                  placeholder='Type to search for client...'
                  options={client_list_select_options}
                  value={this.state.client_id}
                  filterOption={(input, option) => (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                      (optionA && optionA.label ? optionA.label : '').toLowerCase().localeCompare((optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase())
                  }
                  onChange={val => this.handleClientSelectChange(val)} />
            </FormItem>
          </GridItem>
        </GridContainer>

        {Boolean(this.state.client_id) && (
          <React.Fragment>
            <p>
              <br />
              Please confirm client details for new MultiPay Transfer
            </p>
            {this.state.client.account_type == 2 && (
              <p>
                <em style={{color: 'green'}}>
                  {this.state.client.business_company_name && (
                    <span>
                      <b>Business Name: {this.state.client.business_company_name.toUpperCase()} </b>
                    </span>
                  )}
                </em>
              </p>
            )}

            {/* if the client status is not 2 (approved) then display warning */}
            {this.state.client.account_status !== 2 && (
              <p>
                <em style={{color: 'red'}}>
                  {this.state.client.account_status_nickname && (
                    <span>
                      Warning: this client has status {this.state.client.account_status_nickname.toUpperCase()}{' '}
                    </span>
                  )}
                </em>
              </p>
            )}

            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <GridContainer justify='center'>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <CustomInput
                      labelText='First Name'
                      id='first_name'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: true,
                        value: this.state.client.first_name || ''
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <CustomInput
                      labelText='Middle Name(s)'
                      id='middle_name'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: true,
                        value: this.state.client.middle_name || ''
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <CustomInput
                  labelText='Last Name'
                  id='last_name'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: true,
                    value: this.state.client.last_name || ''
                  }}
                />
                <CustomInput
                  labelText='Email'
                  id='email'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: true,
                    value: this.state.client.email || ''
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={6}>
                <CustomInput
                  labelText='Team'
                  id='team_nickname'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: true,
                    value: this.state.client.team_nickname || ''
                  }}
                />
                {Boolean(this.props.app_state.current_staff_super_admin) && (
                  <span>
                    <CustomInput
                      labelText='Division'
                      id='division_nickname'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: true,
                        value: this.state.client.division_nickname || ''
                      }}
                    />
                  </span>
                )}
              </GridItem>
            </GridContainer>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    // transfers_2: state.transfers_2,
    language: state.language,
    transfers_dashboard_multiple: state.transfers_dashboard_multiple
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // updateNewTransferCreationTransferType: transfer_type => {
    //   dispatch(updateNewTransferCreationTransferType(transfer_type));
    // },
    clearNewTransfer: () => {
      dispatch(clearNewTransfer());
    },
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    }
  };
};

const Step1ClientContainer = connect(mapStateToProps, mapDispatchToProps)(Step1Client);

export default withRouter(withStyles(style)(Step1ClientContainer));
