import {useEffect, useState} from "react";
import {Button, Card, Flex, message, Table} from "antd";
import ListTable from "../../../components/ListTable/ListTable";
import {API} from "aws-amplify";
import {ReloadOutlined} from "@ant-design/icons";
import React from 'react';

const ClientProfitReport = ({getColumnSearchProps}) => {

    const [componentState, setComponentState] = useState({
        isLoading: false,
        clientProfitList: []
    });

    const buildColumns = () => {
        return [
            {
                key: 'id',
                title: 'ID',
                dataIndex: 'id',
                sorter: (a, b) => a.id - b.id,
                ...getColumnSearchProps({
                    dataIndex: 'id'
                })
            },
            {
                key: 'first_name',
                title: 'First Name',
                dataIndex: 'first_name',
                ...getColumnSearchProps({
                    dataIndex: 'first_name'
                })
            },
            {
                key: 'last_name',
                title: 'Last Name',
                dataIndex: 'last_name',
                ...getColumnSearchProps({
                    dataIndex: 'last_name'
                })
            },
            {
                key: 'nickname',
                title: 'Nickname',
                dataIndex: 'nickname',
                ...getColumnSearchProps({
                    dataIndex: 'nickname'
                })
            },
            {
                key: 'profit_last_3_months',
                title: 'Last 3 Months',
                dataIndex: 'profit_last_3_months',
                sorter: (a, b) => a.profit_last_3_months - b.profit_last_3_months,
                ...getColumnSearchProps({
                    dataIndex: 'profit_last_3_months',
                    render: (text) => new Intl.NumberFormat('en-Au', {
                        style: 'currency',
                        currency: 'AUD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                    }).format(text)
                })
            },
            {
                key: 'profit_last_6_months',
                title: 'Last 6 Months',
                dataIndex: 'profit_last_6_months',
                sorter: (a, b) => a.profit_last_6_months - b.profit_last_6_months,
                ...getColumnSearchProps({
                    dataIndex: 'profit_last_6_months',
                    render: (text) => new Intl.NumberFormat('en-Au', {
                        style: 'currency',
                        currency: 'AUD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                    }).format(text)
                })
            },
            {
                key: 'profit_last_12_months',
                title: 'Last 12 Months',
                dataIndex: 'profit_last_12_months',
                sorter: (a, b) => a.profit_last_12_months - b.profit_last_12_months,
                ...getColumnSearchProps({
                    dataIndex: 'profit_last_12_months',
                    render: (text) => new Intl.NumberFormat('en-Au', {
                        style: 'currency',
                        currency: 'AUD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                    }).format(text)
                })
            },
            {
                key: 'profit_last_24_months',
                title: 'Last 24 Months',
                dataIndex: 'profit_last_24_months',
                sorter: (a, b) => a.profit_last_24_months - b.profit_last_24_months,
                ...getColumnSearchProps({
                    dataIndex: 'profit_last_24_months',
                    render: (text) => new Intl.NumberFormat('en-Au', {
                        style: 'currency',
                        currency: 'AUD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                    }).format(text)
                })
            }
        ]
    }

    const fetchClientProfits = () => {
        const loading = message.loading("Loading profit report. Please wait...");
        setComponentState(prev => ({...prev, isLoading: true}));
        API.get('reports', "/client/profit-report", {}).then(res => {
            setComponentState(prev => ({...prev, clientProfitList: res, isLoading: false}));
        }).catch(err => {
            console.log(err);
            setComponentState(prev => ({...prev, clientProfitList: [], isLoading: false}));
        }).finally(() => loading());
    }

    useEffect(() => {
        fetchClientProfits();
    }, []);
    return <>
        <Card title={<>
            <Flex justify={'space-between'}>
                <span>Client Profit Report</span>
                <Button type={'primary'}
                        danger={true}
                        onClick={() => fetchClientProfits()}
                        icon={<ReloadOutlined />}>Reload Page</Button>
            </Flex>
        </>}>
            <Table columns={buildColumns()}
                   dataSource={componentState.clientProfitList}
                   loading={componentState.isLoading}></Table>
        </Card>
    </>
}

export default ListTable(ClientProfitReport);