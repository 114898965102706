import React from 'react';
import ReactTable from 'react-table';
import {API} from 'aws-amplify';

import withStyles from '@material-ui/core/styles/withStyles';
import {AiOutlineSearch} from 'react-icons/ai';
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';
import Button from 'components/CustomButtons/Button.jsx';

import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import {fetchCurrencyListNotDeleted} from '../../redux/actions/currencies';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

class CurrenciesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currencies: []
    };
  }

  componentDidMount() {
    const {fetchCurrencyListNotDeleted} = this.props;
    fetchCurrencyListNotDeleted();
  }

  buildTableData() {
    var country_list_length = this.props.currencies.currencies.length;
    if (country_list_length > 0) {
      return this.props.currencies.currencies.map((prop, key) => {
        return {
          id: prop.id,
          full_name: prop.full_name,
          // short_name: prop.short_name,
          iso_alpha_3: prop.iso_alpha_3,
          iso_numeric: prop.iso_numeric,
          actions: (
            <div className='actions-right'>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  var data = this.props.currencies.currencies;
                  data.find(o => {
                    if (o.id === prop.id) {
                      this.props.editCurrencies(o.id);
                    }
                  });
                }}
                color='warning'
                className='edit'
              >
                <Dvr />
              </Button>{' '}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  if (window.confirm('Are you sure you wish to delete this item?')) {
                    var data = this.props.currencies.currencies;
                    data.find((o, i) => {
                      if (o.id === prop.id) {
                        let item_to_delete = prop.id;
                        //console.log(prop.id);
                        API.put(
                          // "system",
                          // `/currencies/delete/currency_id/${prop.id}`
                          'system',
                          `/currencies/update/id/${item_to_delete}`,
                          {
                            body: {deleted: true}
                          }
                        )
                          .then(response => {
                            this.props.fetchCurrencyListNotDeleted();
                            // dispatch(receiveStaffList(response));
                          })
                          .catch(error => {
                            console.log(error);
                          });

                        return true;
                      }
                      return false;
                    });
                    this.setState({data: data});
                  }
                }}
                color='danger'
                className='remove'
              >
                <Close />
              </Button>{' '}
            </div>
          )
        };
      });
    }
  }

  render() {
    return (
      <ReactTable
        data={this.buildTableData()}
        filterable
        columns={[
          {
            Header: 'ISO Alpha 3',
            accessor: 'iso_alpha_3'
          },
          {
            Header: 'Full Name',
            accessor: 'full_name'
          },
          {
            Header: 'ISO Numeric',
            accessor: 'iso_numeric'
          },
          {
            Header: 'Actions',
            accessor: 'actions',
            sortable: false,
            filterable: false
          }
        ]}
        defaultSorted={[
          {
            id: 'iso_alpha_3',
            desc: false
          }
        ]}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom={false}
        className='-striped -highlight'
      />
    );
  }
}

export default withStyles(styles)(CurrenciesTable);
