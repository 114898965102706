import React, {useEffect} from 'react';
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';
import Button from '../../../components/CustomButtons/Button';

import {TeamInvites} from '../../../components/Teams/TeamInvites';
export const TeamInvite = ({teams, fetchTeamRequestList, classes, selectEntityUi, history}) => {
  useEffect(() => {
    fetchTeamRequestList();
  }, []);
  console.info('teams', teams);

  return (
    <div>
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <PermIdentity />
          </CardIcon>
          <div className={classes.flexEnd}>
            <h4 className={classes.cardIconTitle}>Teams Requests</h4>
            <div>
              <Button
                round
                color={teams.teams_current_ui === 'list' ? 'rose' : null}
                // onClick={() => selectEntityUi("list")}
              >
                Show All
              </Button>
              <Button
                round
                color={teams.teams_current_ui === 'create' ? 'rose' : null}
                onClick={() => history.push('/settings/teams/new')}
              >
                Create New
              </Button>
              {teams.teams_current_ui === 'edit' && (
                <Button
                  round
                  color={teams.teams_current_ui === 'edit' ? 'rose' : null}
                  onClick={() => selectEntityUi('edit')}
                >
                  Edit Record
                </Button>
              )}
              {teams.teams_current_ui !== 'edit' && teams.teams_current_ui !== 'create' && (
                <Button round onClick={() => selectEntityUi('list')}>
                  Refresh
                </Button>
              )}
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <TeamInvites data={teams.teams_request_list} />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
};
