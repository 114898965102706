import axios from 'axios';

import Amplify, {API, Auth} from 'aws-amplify';

import config from '../../config/awsConfig';
var generator = require('generate-password');

// export const CLIENTS_SELECT_STAFF_MEMBER = "STAFF_SELECT_STAFF_MEMBER";
export const CLIENTS_FETCH_CLIENT_LIST_REQUEST = 'CLIENTS_FETCH_CLIENT_LIST_REQUEST';
export const CLIENTS_FETCH_CLIENT_LIST_SUCCESS = 'CLIENTS_FETCH_CLIENT_LIST_SUCCESS';
export const CLIENTS_FETCH_CLIENT_LIST_FAILURE = 'CLIENTS_FETCH_CLIENT_LIST_FAILURE';

export const SIMPLE_CLIENTS_FETCH_CLIENT_LIST_REQUEST = 'SIMPLE_CLIENTS_FETCH_CLIENT_LIST_REQUEST';
export const SIMPLE_CLIENTS_FETCH_CLIENT_LIST_SUCCESS = 'SIMPLE_CLIENTS_FETCH_CLIENT_LIST_SUCCESS';
export const SIMPLE_CLIENTS_FETCH_CLIENT_LIST_FAILURE = 'SIMPLE_CLIENTS_FETCH_CLIENT_LIST_FAILURE';
// export const CLIENTS_CREATE_NEW_SHOW_UI = "STAFF_CREATE_NEW_SHOW_UI";
// export const CLIENTS_CREATE_NEW_START = "STAFF_CREATE_NEW_START";
// export const CLIENTS_CREATE_NEW_CANCEL = "STAFF_CREATE_NEW_CANCEL";
// export const CLIENTS_CREATE_NEW_COMPLETE = "STAFF_CREATE_NEW_COMPLETE";
// export const CLIENTS_EDIT_SHOW_UI = "STAFF_EDIT_SHOW_UI";
export const CLIENTS_SELECT_UI = 'CLIENTS_SELECT_UI';
export const CLIENTS_SELECT_SET_ID = 'CLIENTS_SELECT_SET_ID ';
export const CLIENTS_SELECT_CLIENT_REQUEST = 'CLIENTS_SELECT_CLIENT_REQUEST';
export const CLIENTS_SELECT_CLIENT_SUCCESS = 'CLIENTS_SELECT_CLIENT_SUCCESS';
export const CLIENTS_FETCH_CLIENT_REQUEST = 'CLIENTS_FETCH_CLIENT_REQUEST';
export const CLIENTS_FETCH_CLIENT_SUCCESS = 'CLIENTS_FETCH_CLIENT_SUCCESS';
export const CLIENTS_START_NEW_CLIENT_CREATION = 'CLIENTS_START_NEW_CLIENT_CREATION';
export const CLIENTS_UPDATE_NEW_CLIENT_CREATION_CLIENT_TYPE = 'CLIENTS_UPDATE_NEW_CLIENT_CREATION_CLIENT_TYPE';
export const CLIENTS_UPDATE_NEW_CLIENT_CREATION_EMAIL = 'CLIENTS_UPDATE_NEW_CLIENT_CREATION_EMAIL';
export const CLIENTS_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME = 'CLIENTS_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME';
export const CLIENTS_UPDATE_NEW_CLIENT_CREATION_LASTNAME = 'CLIENTS_UPDATE_NEW_CLIENT_CREATION_LASTNAME';
export const CLIENTS_UPDATE_NEW_CLIENT_CREATION = 'CLIENTS_UPDATE_NEW_CLIENT_CREATION';
export const CLIENTS_NEW_CLIENT_DATABASE_CREATE_REQUEST = 'CLIENTS_NEW_CLIENT_DATABASE_CREATE_REQUEST';
export const CLIENTS_NEW_CLIENT_DATABASE_CREATE_SUCCESS = 'CLIENTS_NEW_CLIENT_DATABASE_CREATE_SUCCESS';
export const CLIENTS_CREATE_COGNITO_START = 'CLIENTS_CREATE_COGNITO_START';
export const CLIENTS_CREATE_COGNITO_SUCCESS = 'CLIENTS_CREATE_COGNITO_SUCCESS';

export function fetchClientListRequest() {
  return {type: CLIENTS_FETCH_CLIENT_LIST_REQUEST};
}
export function fetchSimpleClientListRequest() {
  return {type: SIMPLE_CLIENTS_FETCH_CLIENT_LIST_REQUEST};
}



export function fetchClientListSuccess(data) {
  return {
    type: CLIENTS_FETCH_CLIENT_LIST_SUCCESS,
    client_list: data,
    receivedAt: Date.now()
  };
}

export function fetchSimpleClientListSuccess(data) {
  return {
    type: SIMPLE_CLIENTS_FETCH_CLIENT_LIST_SUCCESS,
    client_list: data,
    receivedAt: Date.now()
  };
}

export function fetchClientList() {
  return function (dispatch) {
    dispatch(fetchClientListRequest());
    API.get('clients', '/get-list-not-deleted')
      .then(response => {
        // console.log(response);
        dispatch(fetchClientListSuccess(response));
      })
      .catch(error => {
        console.log(error);
        // API.get('clients', '/get-list-not-deleted')
        //   .then(response => {
        //     // console.log(response);
        //     dispatch(fetchClientListSuccess(response));
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });
      });
  };
}

export function fetchSimpleClientList() {
  return function (dispatch) {
    dispatch(fetchSimpleClientListRequest());
    API.get('clients-simple', '/get-all')
      .then(response => {
        // console.log(response);
        dispatch(fetchSimpleClientListSuccess(response));
      })
      .catch(error => {
        console.log(error);
        API.get('clients-simple', '/get-all')
          .then(response => {
            // console.log(response);
            dispatch(fetchSimpleClientListSuccess(response));
          })
          .catch(error => {
            console.log(error);
          });
      });
  };
}
export function fetchClientDetails(aws_cognito_id) {
  return function (dispatch) {
    // console.log(aws_cognito_id)
    dispatch(loadClientDetailsRequest());
    API.get('clients', `/get/${aws_cognito_id}`)
      .then(response => {
        // console.log(response);
        dispatch(loadClientDetailsSuccess(response));
      })
      .catch(error => {
        console.log(error);
      });
    API.get('clients', `/get/${aws_cognito_id}`)
      .then(response => {
        // console.log(response);
        dispatch(loadClientDetailsSuccess(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function createNewClient(data) {
  return async function (dispatch) {
    dispatch(newClientCreationDatabaseStart());
    try {
      const database_result = await createNewClientInDatabase(data, data.createPortalAccount);
      console.log(database_result);
      return database_result;
    } catch (e) {
      console.log(e);
    }
  };
}

export async function createNewClientInDatabase(datap, create_cognito) {

  const database_result = await API.post('clients', '/create/wizard-client-finish', {
    body: datap
  }).then(database_result => {
      const responseBody = {
          interaction: 'Create New Client',
          data: datap,
          result: datap.client_id
      };
      API.post('email', '/interaction/main', { body: responseBody })
          .then(response => console.log(response))
          .catch(error => console.log(error));
    API.post('email-18', '/client/new-client-created', { body: {client_id: datap.client_id, user: datap.user }})
        .then(response => console.log(response))
        .catch(error => console.log(error));
      API.post('teams', `/create/client_team/in-database`, {
        body: {
          teamId: datap.team,
          clientId: datap.client_id,
          telephone_mobile: datap.telephone_mobile || ''
        }
      }).then(result => {
        // Send email once client is created

        API.post("")
        console.log(result);
      })
        .catch(error => console.log(error));

      return database_result.payload;
    })
    .catch(error => console.log(error));
  return database_result;
}

export async function createNewTransferInDatabase(data, client_id) {
  // console.log(data.beneficiary_ids); return;
  console.log(client_id);
  console.log(data);
  const database_result = API.post('transfers', '/create/in-database', {
    body: {
      ...data,
      client_id: client_id
    }
  })
    .then(database_result => {
      console.log(database_result);
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
  return database_result;
}

export function createNewClientAndPayment(client, transfer) {
  return async function (dispatch) {
    dispatch(newClientCreationDatabaseStart());

    try {
      let create_cognito = false;
      if (client.initial_portal_password) {
        create_cognito = true;
      }

      const client_database_result = await createNewClientInDatabase(client, create_cognito);
      console.log(client_database_result);
      // if (data.initial_portal_password) {
      if (create_cognito) {
        dispatch(
          createCognitoAccountForClient(
            client_database_result.insertId,
            client.email,
            client.initial_portal_password,
            client.language_id,
            1
          )
        );
      }
      dispatch(newClientCreationDatabaseSuccess(client_database_result.insertId));
      //dispatch(apistuff(database_result, data));

      const transfer_database_result = await createNewTransferInDatabase(transfer, client_database_result.insertId);

      return {
        client: client_database_result,
        transfer: transfer_database_result
      };
    } catch (e) {
      console.log(e);
    }
  };
}
// <<<<<<< FWWCRM-243
// =======
// export function createNewClient(data) {
//   return async function (dispatch) {
//     dispatch(newClientCreationDatabaseStart());

//     try {
//       let create_cognito = false;
//       if (data.initial_portal_password) {
//         create_cognito = true;
//       }

//       const database_result = await createNewClientInDatabase(data, create_cognito);
//       console.log(database_result);
//       // if (data.initial_portal_password) {
//       if (create_cognito) {
//         dispatch(
//           createCognitoAccountForClient(data, database_result.insertId, data.email, data.initial_portal_password, 1)
//         );
//       }
//       dispatch(newClientCreationDatabaseSuccess(database_result.insertId));
//       //dispatch(apistuff(database_result, data));
//       return database_result;
//     } catch (e) {
//       console.log(e);
//     }
//   };
// }
// >>>>>>> develop

export async function updateClientInDatabase(client_detail, cl_id) {
  return API.put('clients', `/update/id/${cl_id}`, {body: client_detail});
}

// export async function apistuffForUpdate(id, data) {
//   data.client_id = id.insertId;
//   const dte = new Date();
//   const sec_keys =
//     dte.getUTCFullYear() +
//     '-' +
//     dte.getDate().toString().padStart(4, '0') +
//     '-' +
//     dte.getDay().toString().padStart(4, '0') +
//     '-' +
//     dte.getDate().toString().padStart(4, '0');

//   data.secret_keys = sec_keys;
//   data.mid = id;

//   const axiosConfig = {headers: {'Access-Control-Allow-Origin': '*'}};

//   axios
//     .post('https://www.forexsport.com/API/update_client.php', {data: data}, axiosConfig)
//     .then(res => {
//       console.log('RESPONSE RECEIVED: ', res);
//     })
//     .catch(err => {
//       console.log('AXIOS ERROR: ', err);
//     });
// }

export function updateClient(data, cl_id) {
  return async function (dispatch) {
    try {
      const database_result = await updateClientInDatabase(data, cl_id);

      //dispatch(apistuffForUpdate(cl_id, data));
      return database_result;
    } catch (e) {
      console.log(e);
    }
  };
}

/*
 * Functions to create an AWS Cognito user for an existing client record
 */

// export async function create_new_user_in_database(
//   aws_cognito_id,
//   email,
//   first_name,
//   last_name
// ) {
//   API.post("staff", `/create/in-database`, {
//     body: {
//       email: email,
//       aws_cognito_id: aws_cognito_id,
//       first_name: first_name,
//       last_name: last_name
//     }
//   })
//     .then(database_result => {
//       return database_result;
//     })
//     .catch(error => {
//       console.log(error);
//     });
// }

async function update_client_in_database(client_id, client_detail) {
  console.log(client_detail);
  API.put('clients', `/update/id/${client_id}`, {body: client_detail})
    .then(database_result => {
      console.log(database_result);
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
}

export function createCognitoAccountForClient(
  // data,
  id,
  username,
  password,
  language_id,
  doNotEmail
) {
  console.log(id);
  console.log(username);
  return async function (dispatch) {
    dispatch(createCognitoAccountStart());

    try {
      Amplify.configure({
        Auth: {
          mandatorySignIn: true,
          region: config.cognito_clients.REGION,
          userPoolId: config.cognito_clients.USER_POOL_ID,
          identityPoolId: config.cognito_clients.IDENTITY_POOL_ID,
          userPoolWebClientId: config.cognito_clients.APP_CLIENT_ID
        }
      });

      const newUser = await Auth.signUp({
        username: username,
        password: password
      });

      console.log(newUser);

      await update_client_in_database(id, {
        aws_cognito_id: newUser.userSub,
        portal_account_created: '1'
      });

      Amplify.configure({
        Auth: {
          mandatorySignIn: true,
          region: config.cognito_staff.REGION,
          userPoolId: config.cognito_staff.USER_POOL_ID,
          identityPoolId: config.cognito_staff.IDENTITY_POOL_ID,
          userPoolWebClientId: config.cognito_staff.APP_CLIENT_ID
        }
      });
      API.post('email', '/interaction/main', {
        body: {
          interaction: 'Create New Client Portal Record',
          data: {
            id: id,
            username: username,
            password: password,
            doNotEmail: doNotEmail
          },
          result: 'portal record created successfully'
        }
      })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });

      if (!doNotEmail) {
        API.post('email', '/client/new-client-portal-created-new', {
          body: {
            id: id,
            username: username,
            password: password,
            language_id: language_id
            // data: data
          }
        })
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      }
      dispatch(createCognitoAccountSuccess());
    } catch (e) {
      console.log(e);
      // if (e.code === "UsernameExistsException") {
      //   await Auth.resendSignUp(data.email);
      // }
      Amplify.configure({
        Auth: {
          mandatorySignIn: true,
          region: config.cognito_staff.REGION,
          userPoolId: config.cognito_staff.USER_POOL_ID,
          identityPoolId: config.cognito_staff.IDENTITY_POOL_ID,
          userPoolWebClientId: config.cognito_staff.APP_CLIENT_ID
        }
      });
    }
  };
}

export function createCognitoAccountStart() {
  return {type: CLIENTS_CREATE_COGNITO_START};
}

export function createCognitoAccountSuccess() {
  return {type: CLIENTS_CREATE_COGNITO_SUCCESS};
}

// export function fetchStaffList() {
//   return function(dispatch) {
//     dispatch(requestStaffListRequest);
//
//     API.get("crm", `/staff/get-list`)
//       .then(response => {
//         console.log(response);
//         dispatch(receiveStaffList(response));
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// }

// export function fetchStaffListNotDeleted() {
//   return function(dispatch) {
//     dispatch(requestStaffListRequest);
//
//     API.get("crm", `/staff/get-list-not-deleted`)
//       .then(response => {
//         // console.log(response);
//         dispatch(receiveStaffList(response));
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// }
//
export function selectClientUi(value) {
  return {
    type: CLIENTS_SELECT_UI,
    value: value
  };
}

// async function createNewUserWithAws(username, password) {
//   const newUser = await Auth.signUp({
//     username: username,
//     password: password
//   });
//   return newUser;
// }

// export async function create_new_user_in_database(
//   aws_cognito_id,
//   email,
//   first_name,
//   last_name
// ) {
//   API.post("crm", `/staff/create/in-database`, {
//     body: {
//       email: email,
//       aws_cognito_id: aws_cognito_id,
//       first_name: first_name,
//       last_name: last_name
//     }
//   })
//     .then(database_result => {
//       return database_result;
//     })
//     .catch(error => {
//       console.log(error);
//     });
// }
//
export function setClientSelectId(id) {
  return {
    type: CLIENTS_SELECT_SET_ID,
    value: id
  };
}

//
// export function loadStaffMemberDetails(aws_cognito_id) {
//   return async function(dispatch) {
//     dispatch(loadStaffMemberDetailsRequest(aws_cognito_id));
//
//   };
// }
//
export function loadClientDetailsRequest() {
  return {type: CLIENTS_FETCH_CLIENT_REQUEST};
}

export function loadClientDetailsSuccess() {
  return {type: CLIENTS_FETCH_CLIENT_SUCCESS};
}

export function startNewClientCreation() {
  return {type: CLIENTS_START_NEW_CLIENT_CREATION};
}

export function updateNewClientCreationClientType(client_type) {
  return {
    type: CLIENTS_UPDATE_NEW_CLIENT_CREATION_CLIENT_TYPE,
    value: client_type
  };
}

export function updateNewClientCreation(key, value) {
  // console.log(key, value);
  return {
    type: CLIENTS_UPDATE_NEW_CLIENT_CREATION,
    value: {
      key,
      value
    }
  };
}

export function newClientCreationDatabaseStart() {
  return {type: CLIENTS_NEW_CLIENT_DATABASE_CREATE_REQUEST};
}

export function newClientCreationDatabaseSuccess(id) {
  return {
    type: CLIENTS_NEW_CLIENT_DATABASE_CREATE_SUCCESS,
    value: id
  };
}

