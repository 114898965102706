import withStyles from '@material-ui/core/styles/withStyles'
import PermIdentity from '@material-ui/icons/PermIdentity'
import { Alert, Button, DatePicker, Divider, Flex, Form, Input, Select, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { AppUrlConstants } from '../../../AppAPI'
import { AppTemplates } from '../../../AppTemplates'
import { cardTitle } from '../../../assets/jss/material-dashboard-pro-react'
import Card from '../../../components/Card/Card'
import CardBody from '../../../components/Card/CardBody'
import CardHeader from '../../../components/Card/CardHeader'
import CardIcon from '../../../components/Card/CardIcon'
import { ClientAPI } from '../../../components/Clients/ClientUtil'
import { ForwardsAPI } from './ForwardsUtil'

const styles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center'
    },
    description: { color: '#999999' },
    updateProfileButton: { float: 'right' },
    title: {
        color: '#3C4858',
        textDecoration: 'none'
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between'
    }
};

const CreateForwards = ({ history, classes, state }) => {

    const [formRef] = Form.useForm();
    const [clients, setClients] = useState([]);
    const [clientOpts, setClientOpts] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [activeClient, setActiveClient] = useState(null);

    const submitForm = (formValues) => {
        formValues.staff_id = state.current_staff.id;
        formValues.status_id = 1;
        console.log(formValues);
        return;
        setBtnLoading(true);
        ForwardsAPI.saveForwards(formValues).then(() => {
            message.success("Forwards has been saved", 2).then(() => history.push('/forwards/list'));
            setBtnLoading(false);
        }).catch((err) => {
            console.log(err);
            setBtnLoading(false);
            message.error("Unable to save forwards. Please try again...", 1);
        })
    }

    const fetchClientList = () => {
        if (state.current_staff_id != null) {
            ClientAPI.fetchClients(state.current_staff.id).then(response => {
                const clientList = AppTemplates.clients(response);
                setClients(response);
                setClientOpts(clientList);
            });
        }
    }

    const onClientChange = (value) => {
        const activeClient = clients.find(item => item.id === value);
        formRef.setFieldValue('client_id', value);
        if (activeClient != null) {
            setActiveClient(`${activeClient.nickname} [${activeClient.email}]`);
        }
    }

    const handleAmount = (buttonId) => {

        let amount_from = formRef.getFieldValue('amount_from');
        let amount_to = formRef.getFieldValue('amount_to');
        let adjustedRate = formRef.getFieldValue('client_rate');

        if (adjustedRate == null && (amount_from == null || amount_to == null)) {
            message.info('Enter the values first...');
            return;
        }


        switch (buttonId) {
            case 1:
                amount_from = Number.parseFloat(amount_from);
                amount_to = amount_from * adjustedRate;
                formRef.setFieldValue('amount_to', amount_to.toFixed(2))
                break;

            case 2:
                amount_from = Number.parseFloat(amount_from);
                amount_to = amount_from / adjustedRate;
                formRef.setFieldValue('amount_to', amount_to.toFixed(2))
                break;

            case 3:
                amount_to = Number.parseFloat(amount_to);
                amount_from = amount_to * adjustedRate;
                formRef.setFieldValue('amount_from', amount_from.toFixed(2))
                break;

            case 4:
                amount_to = Number.parseFloat(amount_to);
                amount_from = amount_to / adjustedRate;
                formRef.setFieldValue('amount_from', amount_from.toFixed(2))
                break;

        }
    }


    useEffect(() => {
        AppUrlConstants.fetchCurrencies().then(response => {
            const currenciesSorted = response.sort((a, b) => a.iso_alpha_3.localeCompare(b.iso_alpha_3));
            const templateCurrency = currenciesSorted.map(item => ({
                value: item.id,
                alt: item.iso_alpha_3 + ': ' + item.full_name,
                label: <React.Fragment>
                    <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
                    &nbsp;
                    {item.iso_alpha_3 + ': ' + item.full_name}
                </React.Fragment>
            }))
            setCurrencyList(templateCurrency);
        }).catch(error => console.log(error));
    }, []);

    useEffect(() => {
        fetchClientList();
    }, [state.current_staff_id])


    return <>
        <Card>
            <CardHeader color='rose' icon>
                <CardIcon color='rose'>
                    <PermIdentity />
                </CardIcon>
                <div className={classes.flexEnd}>
                    <h4 className={classes.cardIconTitle}>
                        <FormattedMessage id='forwardList.cardItem1.forwardListing' defaultMessage={'Create New Forwards'} />
                    </h4>
                    <div style={{ marginTop: '12px' }}>
                        <Button type='primary' size={'middle'} onClick={() => history.push('/forwards/list')}>
                            <FormattedMessage id='newTransfer.cardItem1.showAll' defaultMessage={'Show All'} />
                        </Button>
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                <Form
                    form={formRef}
                    layout={'horizontal'}
                    labelCol={{ span: 8 }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{ maxWidth: 600 }}

                    onFinish={submitForm}>
                    <Form.Item name="client_id"
                               rules={[{ required: true, message: 'Client is required' }]}
                               label={'Client'}>
                        <Select showSearch
                                onChange={(value) => onClientChange(value)}
                                style={{ width: '100%' }}
                                placeholder="Search to Select"
                                optionFilterProp="alt"
                                filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                                }
                                options={clientOpts}></Select>
                        <span style={{ display: 'block', marginTop: '5px' }}>
                            {
                                activeClient != null && activeClient !== '-' ?
                                    <Alert message={`${activeClient}`} type="info" /> : ''
                            }
                        </span>
                    </Form.Item>
                    <Form.Item name="currency_from_id"
                               rules={[{ required: true, message: 'Currency from is required' }]}
                               label={'Currency'}>
                        <Select showSearch
                                style={{ width: '100%' }}
                                placeholder="Search to Select"
                                optionFilterProp="alt"
                                filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                                }
                                options={currencyList}></Select></Form.Item>

                    <Form.Item name="amount_from"
                               rules={[{ required: true, message: 'Amount from is required' }]}
                               label={'Amount From'}>
                        <Input style={{ marginRight: '5px' }} placeholder={'Enter Amount from'} type={'number'} />
                    </Form.Item>

                    <Flex justify={'flex-end'} style={{ marginTop: '-10px' }}>
                        <Button size={'small'} type={'primary'} style={{ marginRight: '5px' }} onClick={() => handleAmount(1)}>
                            &#xd7;
                        </Button>
                        <br />
                        <Button size={'small'} type={'primary'} danger={true} onClick={() => handleAmount(2)}>
                            &#xF7;
                        </Button>
                    </Flex>
                    <Divider />



                    <Form.Item name="client_rate"
                               rules={[{ required: true, message: 'Rate is required' }]}
                               label={'Client Rate'}>
                        <Input placeholder={'Enter Client Rate'} type={'number'} />
                    </Form.Item>

                    <Form.Item name="currency_to_id"
                               rules={[{ required: true, message: 'Currency to is required' }]}
                               label={'Currency'}>
                        <Select showSearch
                                style={{ width: '100%' }}
                                placeholder="Search to Select"
                                optionFilterProp="alt"
                                filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                                }
                                options={currencyList}></Select></Form.Item>

                    <Form.Item name="amount_to" shouldUpdate={(prev, next) => prev.amount_to !== next.amount_to}
                               rules={[{ required: true, message: 'Amount to is required' }]}
                               label={'Amount to'}>
                        <Input style={{ marginRight: '5px' }} placeholder={'Enter Amount To'} type={'number'} />
                    </Form.Item>

                    <Flex justify={'flex-end'} style={{ marginTop: '-10px' }}>
                        <Button size={'small'} type={'primary'} style={{ marginRight: '5px' }} onClick={() => handleAmount(3)}>
                            &#xd7;
                        </Button>
                        <br />
                        <Button size={'small'} type={'primary'} danger={true} onClick={() => handleAmount(4)}>
                            &#xF7;
                        </Button>
                    </Flex>

                    <Divider />

                    <Form.Item name="transaction_date"
                               rules={[{ required: true, message: 'Transaction date is required' }]}
                               label={'Transaction Date'}>
                        <DatePicker format={'DD/MM/YYYY'} />
                    </Form.Item>

                    <Form.Item name="settlement_date"
                               rules={[{ required: true, message: 'Settlement date is required' }]}
                               label={'Settlement Date'}>
                        <DatePicker format={'DD/MM/YYYY'} />
                    </Form.Item>

                    <Button htmlType={'submit'} type={'primary'} size={'middle'} loading={btnLoading}>Add Forward</Button>
                </Form>
            </CardBody>
        </Card>
    </>
}

const mapStateToProps = state => {
    return {
        state: state.app_state,
    };
};

const CreateForwardsContainer = connect(mapStateToProps)(withRouter(withStyles(styles)(CreateForwards)));

export default CreateForwardsContainer;