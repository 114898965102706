export default {
  s3: {
    REGION: 'ap-southeast-2',
    BUCKET: 'com.forexworldwide.crm.documents.01'
  },
  apiGateway: {
    REGION: 'ap-southeast-2',
    URL: 'https://9s3zyajdba.execute-api.ap-southeast-2.amazonaws.com/dev'
  },
  cognito: {
    REGION: 'ap-southeast-2',
    USER_POOL_ID: 'ap-southeast-2_j8yViqt34',
    APP_CLIENT_ID: '64n5lrg1emb9pbjurrj09ulidc',
    IDENTITY_POOL_ID: 'ap-southeast-2:3d1e2212-2ed8-4aa8-b0e4-45ed28a4c701'
  },
  cognito_staff: {
    REGION: 'ap-southeast-2',
    USER_POOL_ID: 'ap-southeast-2_j8yViqt34',
    APP_CLIENT_ID: '64n5lrg1emb9pbjurrj09ulidc',
    IDENTITY_POOL_ID: 'ap-southeast-2:3d1e2212-2ed8-4aa8-b0e4-45ed28a4c701'
  },
  cognito_clients: {
    REGION: 'ap-southeast-2',
    USER_POOL_ID: 'ap-southeast-2_pLZ4EKm2d',
    APP_CLIENT_ID: '36hpfb5vkdiqp59ptdj6vtccks',
    IDENTITY_POOL_ID: 'ap-southeast-2:8f95960b-dcd6-44c7-80aa-6ab6dbf8ece0'
  }
};
