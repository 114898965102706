import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import clientsListStyles from "./ClientsListStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {FormattedMessage} from 'react-intl';

import {fetchClientList, selectClientUi} from '../../../redux/actions/clients';


/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import Button from '../../../components/CustomButtons/Button';
import ClientListContainer from '../../../components/Clients/ClientList.jsx';

const clientsListStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};
/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientsList
 * ----------------------------------------------------------------------------
 */
class ClientsListIncomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    // console.log(this.props);
    const {classes} = this.props;
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id='clientList.cardItem1.clientListing' defaultMessage={`Client Listing `} />
              </h4>
              <div>
                <Button
                  round
                  color='primary'
                  // onClick={() => this.props.history.push("/clients/list")}
                >
                  <FormattedMessage id='newClient.cardItem1.showAll' defaultMessage={`Show All`} />
                </Button>
                <Button round color='info' onClick={() => this.props.history.push('/clients/new')}>
                  <FormattedMessage id='newClient.cardItem1.createNew' defaultMessage={`Create New`} />
                </Button>
                <Button round color='info' onClick={() => this.props.fetchClientList()}>
                  <FormattedMessage id='newClient.cardItem1.refresh' defaultMessage={`Refresh`} />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <ClientListContainer status="5" />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

// ClientsList.propTypes = {
//   classes: PropTypes.object.isRequired,
//   clients: PropTypes.object.isRequired,
//   fetchClientList: PropTypes.func.isRequired,
//   selectClientUi: PropTypes.func.isRequired
// };

ClientsListIncomplete = withStyles(clientsListStyles)(ClientsListIncomplete);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchClientList: () => {
      dispatch(fetchClientList());
    },
    selectClientUi: data => {
      dispatch(selectClientUi(data));
    }
  };
};

const ClientsListContainer = connect(mapStateToProps, mapDispatchToProps)(ClientsListIncomplete);

export default ClientsListContainer;
