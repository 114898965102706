import React from 'react';
import PropTypes from 'prop-types';

// redux components
import {connect} from 'react-redux';
import {fetchClientList, selectClientUi} from '../../../redux/actions/clients';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import clientsNewStyles from "./ClientsNewStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {FormattedMessage} from 'react-intl';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
//import Button from "../../../components/CustomButtons/Button";
import InvoicesBulkContainer from '../../../components/Invoicing/InvoicesBulk';

const clientsNewStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - TransferBulk - #1054 Improvement by Tom
 * ----------------------------------------------------------------------------
 */
class InvoicesBulkPage extends React.Component {
  render() {
    const {classes} = this.props;
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id='Create bulk invoice record' defaultMessage={`Create bulk invoice record`} />
              </h4>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <InvoicesBulkContainer />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

// TransfersBulk.propTypes = {
//   classes: PropTypes.object.isRequired
// };

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchClientList: () => {
      dispatch(fetchClientList());
    },
    selectClientUi: data => {
      dispatch(selectClientUi(data));
    }
  };
};

const InvoicesBulkPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(clientsNewStyles)(InvoicesBulkPage));

export default InvoicesBulkPageContainer; //Rename TransfersBulkContainer into TransfersBulkContainerView because of duplicated naming issue - #1054 Improvement
