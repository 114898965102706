import React, {forwardRef, useState} from "react";
import {message, Modal, Image} from "antd";
import {API, Storage} from "aws-amplify";
import {AppUtil} from "../../AppUtil";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {CloseOutlined} from "@ant-design/icons";

const FilePreview = forwardRef(({
                                    documentIds = []
                                 }, ref) => {

    const breakPoint = useBreakpoint();
    const [previewImage, setPreviewImage] = useState(null);

    const onPreview = async (documentId) => {

        const loading = message.loading("Loading document preview. Please wait..", 0);
        const [document] = await API.post("commons", "/fetch", {
            body: {
                context: 'documents',
                fields: ['*'],
                condition: {id: documentId}
            }
        });
        if (document) {
            const url = await Storage.get(document.file_ref, {expiry: 30});
            const data = await AppUtil.getFileInfo(url);
            if (data.type === 'application/pdf') {
                if (breakPoint.lg || breakPoint.xl) {
                    Modal.confirm({
                        width: '90%',
                        closeIcon: <CloseOutlined/>,
                        icon: null,
                        okButtonProps: {style: {display: 'none'}},
                        cancelButtonProps: {style: {display: 'none'}},
                        bodyStyle: {padding: 0, margin: 0},
                        style: {height: '90%', padding: 0, top: 10, zIndex: 1033, margin: 0},
                        maskClosable: true,
                        content: <iframe width={'100%'}
                                         style={{height: '90vh'}}
                                         src={url}/>
                    });
                } else {
                    window.open(url, '_blank');
                }
            } else {
                setPreviewImage(url);
            }

        }
        loading();
    }


    return <>

        <ul style={{ paddingLeft: 15 }}>
            {documentIds.map(i => (
                <li><a onClick={() => onPreview(i)}>#{i}</a></li>
            ))}
        </ul>

        <Image
            src={previewImage}
            alt="Preview"
            style={{ display: 'none' }} // Hide the image element
            preview={{
                visible: previewImage != null,
                onVisibleChange: (visible) => setPreviewImage(!visible ? null : visible),
            }}
        />
    </>
});

export default FilePreview;