import {connect} from 'react-redux';
import StaffEdit from './StaffEdit';
import {
  loadStaffMemberDetailsRequest,
  loadStaffMemberDetailsSuccess,
  createCognitoAccountForStaff
} from '../../../redux/actions/staff';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    staff: state.staff
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadStaffMemberDetailsRequest: () => {
      dispatch(loadStaffMemberDetailsRequest());
    },
    loadStaffMemberDetailsSuccess: () => {
      dispatch(loadStaffMemberDetailsSuccess());
    },
    createCognitoAccountForStaff: (id, username, password) => {
      dispatch(createCognitoAccountForStaff(id, username, password));
    }
  };
};

const StaffEditContainer = connect(mapStateToProps, mapDispatchToProps)(StaffEdit);

export default StaffEditContainer;
