import {API} from 'aws-amplify';

export const STAFF_SELECT_STAFF_MEMBER = 'STAFF_SELECT_STAFF_MEMBER';
export const TRANSFERS_FETCH_TRANSFER_LIST_REQUEST = 'TRANSFERS_FETCH_TRANSFER_LIST_REQUEST';
export const TRANSFERS_FETCH_TRANSFER_LIST_SUCCESS = 'TRANSFERS_FETCH_TRANSFER_LIST_SUCCESS';
export const TRANSFERS_FETCH_TRANSFER_LIST_FAILURE = 'TRANSFERS_FETCH_TRANSFER_LIST_FAILURE';
// export const CLIENTS_CREATE_NEW_SHOW_UI = "STAFF_CREATE_NEW_SHOW_UI";
// export const CLIENTS_CREATE_NEW_START = "STAFF_CREATE_NEW_START";
// export const CLIENTS_CREATE_NEW_CANCEL = "STAFF_CREATE_NEW_CANCEL";
// export const CLIENTS_CREATE_NEW_COMPLETE = "STAFF_CREATE_NEW_COMPLETE";
// export const CLIENTS_EDIT_SHOW_UI = "STAFF_EDIT_SHOW_UI";
export const TRANSFERS_SELECT_UI = 'TRANSFERS_SELECT_UI';
export const TRANSFERS_SELECT_SET_ID = 'TRANSFERS_SELECT_SET_ID ';
export const CLIENTS_SELECT_CLIENT_REQUEST = 'CLIENTS_SELECT_CLIENT_REQUEST';
export const CLIENTS_SELECT_CLIENT_SUCCESS = 'CLIENTS_SELECT_CLIENT_SUCCESS';
export const TRANSFERS_FETCH_TRANSFER_REQUEST = 'TRANSFERS_FETCH_TRANSFER_REQUEST';
export const TRANSFERS_FETCH_TRANSFER_SUCCESS = 'TRANSFERS_FETCH_TRANSFER_SUCCESS';
export const TRANSFERS_START_NEW_TRANSFER_CREATION = 'TRANSFERS_START_NEW_TRANSFER_CREATION';
export const TRANSFERS_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE =
  'TRANSFERS_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE';
export const CLIENTS_UPDATE_NEW_CLIENT_CREATION_EMAIL = 'CLIENTS_UPDATE_NEW_CLIENT_CREATION_EMAIL';
export const CLIENTS_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME = 'CLIENTS_UPDATE_NEW_CLIENT_CREATION_FIRSTNAME';
export const CLIENTS_UPDATE_NEW_CLIENT_CREATION_LASTNAME = 'CLIENTS_UPDATE_NEW_CLIENT_CREATION_LASTNAME';
export const TRANSFERS_UPDATE_NEW_TRANSFER_CREATION = 'TRANSFERS_UPDATE_NEW_TRANSFER_CREATION';
export const TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_REQUEST = 'TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_REQUEST';
export const TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_SUCCESS = 'TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_SUCCESS';
export const TRANSFERS_CLEAR_NEW_TRANSFER = 'TRANSFERS_CLEAR_NEW_TRANSFER';

export function fetchTransferListRequest() {
  return {
    type: TRANSFERS_FETCH_TRANSFER_LIST_REQUEST
  };
}
export function clearNewTransfer() {
  return {
    type: TRANSFERS_CLEAR_NEW_TRANSFER
  };
}

export function fetchTransferListSuccess(data) {
  return {
    type: TRANSFERS_FETCH_TRANSFER_LIST_SUCCESS,
    transfer_list: data,
    receivedAt: Date.now()
  };
}

export function fetchTransferList() {
  return function (dispatch) {
    dispatch(fetchTransferListRequest());
    API.get('transfers', `/get-list-not-deleted`)
      .then(response => {
        // console.log(response)
        dispatch(fetchTransferListSuccess(response));
      })
      .catch(error => {
        console.log(error);
        // API.get('transfers', `/get-list-not-deleted`).then(response => {
        //   // console.log(response)
        //   dispatch(fetchTransferListSuccess(response));
        // });
      });
  };
}

export function fetchTransferDetails(id) {
  return function (dispatch) {
    dispatch(loadTransferDetailsRequest());
    API.get('transfers', `/get/${id}`)
      .then(response => {
        dispatch(loadTransferDetailsSuccess(response));
      })
      .catch(error => {
        console.log(error);
        API.get('transfers', `/get/${id}`).then(response => {
          dispatch(loadTransferDetailsSuccess(response));
        });
      });
  };
}

export async function createNewTransferInDatabase(data) {
  // console.log(data.beneficiary_ids); return;
  let database_result = API.post('transfers', `/create/in-database`, {
    body: {
      ...data
    }
  })
    .then(database_result => {
      // console.log(database_result)
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
  return database_result;
}

export function createNewTransfer(data) {
  return async function (dispatch) {
    dispatch(newTransferCreationDatabaseStart());

    try {
      let database_result = await createNewTransferInDatabase(data);
      dispatch(newTransferCreationDatabaseSuccess(database_result.insertId));
      return database_result;
    } catch (e) {
      console.log(e);
    }
  };
}

// export function fetchStaffList() {
//   return function(dispatch) {
//     dispatch(requestStaffListRequest);
//
//     API.get("crm", `/staff/get-list`)
//       .then(response => {
//         console.log(response);
//         dispatch(receiveStaffList(response));
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// }

// export function fetchStaffListNotDeleted() {
//   return function(dispatch) {
//     dispatch(requestStaffListRequest);
//
//     API.get("crm", `/staff/get-list-not-deleted`)
//       .then(response => {
//         // console.log(response);
//         dispatch(receiveStaffList(response));
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// }
//
export function selectTransferUi(value) {
  return {
    type: TRANSFERS_SELECT_UI,
    value: value
  };
}

// async function createNewUserWithAws(username, password) {
//   const newUser = await Auth.signUp({
//     username: username,
//     password: password
//   });
//   return newUser;
// }

// export async function create_new_user_in_database(
//   aws_cognito_id,
//   email,
//   first_name,
//   last_name
// ) {
//   API.post("crm", `/staff/create/in-database`, {
//     body: {
//       email: email,
//       aws_cognito_id: aws_cognito_id,
//       first_name: first_name,
//       last_name: last_name
//     }
//   })
//     .then(database_result => {
//       return database_result;
//     })
//     .catch(error => {
//       console.log(error);
//     });
// }
//
export function setTransferSelectId(id) {
  return {
    type: TRANSFERS_SELECT_SET_ID,
    value: id
  };
}
//
// export function loadStaffMemberDetails(aws_cognito_id) {
//   return async function(dispatch) {
//     dispatch(loadStaffMemberDetailsRequest(aws_cognito_id));
//
//   };
// }
//
export function loadTransferDetailsRequest() {
  return {
    type: TRANSFERS_FETCH_TRANSFER_REQUEST
  };
}
export function loadTransferDetailsSuccess() {
  return {
    type: TRANSFERS_FETCH_TRANSFER_SUCCESS
  };
}
export function startNewTransferCreation() {
  return {
    type: TRANSFERS_START_NEW_TRANSFER_CREATION
  };
}
export function updateNewTransferCreationTransferType(client_type) {
  return {
    type: TRANSFERS_UPDATE_NEW_TRANSFER_CREATION_TRANSFER_TYPE,
    value: client_type
  };
}
export function updateNewTransferCreation(key, value) {
  // console.log(key, value);
  return {
    type: TRANSFERS_UPDATE_NEW_TRANSFER_CREATION,
    value: {key, value}
  };
}
export function newTransferCreationDatabaseStart() {
  return {
    type: TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_REQUEST
  };
}
export function newTransferCreationDatabaseSuccess(id) {
  return {
    type: TRANSFERS_NEW_TRANSFER_DATABASE_CREATE_SUCCESS,
    value: id
  };
}

//
// export function createNewStaffMemberStart() {
//   return {
//     type: STAFF_CREATE_NEW_START
//   };
// }
//
// export function createNewStaffMemberComplete() {
//   return {
//     type: STAFF_CREATE_NEW_COMPLETE
//   };
// }
