import React from 'react';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

import {
  createCognitoAccountForClient,
  loadClientDetailsRequest,
  loadClientDetailsSuccess,
  updateClient
} from '../../redux/actions/clients';

// core components
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
import Card from '../Card/Card.jsx';
import CardBody from '../Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader';
import CardFooter from '../../components/Card/CardFooter';
import Button from '../CustomButtons/Button';
import {Alert, Button as AntButton, message, Typography} from 'antd';
import {API} from 'aws-amplify';

import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Col, DatePicker, Flex, Form, Input, Row, Select} from "antd";
import AntUploader from "../AntUploader/AntUploader";
import {addPaymentRequest, updatePaymentRequest} from "../../redux/reducers/paymentRequest";
import {DeleteOutlined} from "@ant-design/icons";

import dayjs from 'dayjs';
import {fetchCountryListNotDeleted} from "../../redux/actions/countries";
import {fetchCurrencyListNotDeleted} from "../../redux/actions/currencies";
import TypographyCurrency from "../CurrencySelect/TypographyCurrency";


const staffEditStyles = {
    ...switchStyles,
    ...selectStyles,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center'
    },
    description: {color: '#999999'},
    updateProfileButton: {float: 'right'},
    title: {
        color: '#3C4858',
        textDecoration: 'none'
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px'
    },
    registerButton: {float: 'right'},
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between'
    }
};

class TransfersBulk extends React.Component {

    mainFormRef = React.createRef();
    mainUploadRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            teams_list: [],
            beneficiaries_list: [],
            client_list: [],
            purpose_list: [],
            paymentRequestList: [],
            fClientList: {},
            submitting: false,
            group_list: [],
            group_id: null,
            selected_currency: null
        };
    }

    componentDidMount() {
        this.props.fetchCountries();
        this.props.fetchCurrencies();
        console.log(this.props)
        API.get('teams', `/list/staff/${this.props.app_state.current_staff.id}`, {})
            .then(response => {
                this.setState({teams_list: response});
            })
            .catch(error => {
                console.log(error);
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.app_state.current_staff && prevProps.app_state.current_staff &&
            (this.props.app_state.current_staff.id !== prevProps.app_state.current_staff.id)) {
            API.get('teams', `/list/staff/${this.props.app_state.current_staff.id}`, {})
                .then(response => {
                    this.setState({teams_list: response});
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    componentWillUnmount() {
        this.props.createPaymentRequest({
            bulkTransfer: [],
            expiryDate: null,
            team_id: null,
            beneficiary_id: null,
            amount: null,
            purpose_of_payment: null,
            purpose_of_payment_detail: null,
            file_id: null,
            account_currency: null
        });
    }

    addClick() {
        this.mainFormRef.current.validateFields().then(() => {
            const state = this.props.paymentRequest;
            if (state.team_id == null) {
                message.info("Please select team before adding new record..");
                return;
            }
            const transferBulk = state.bulkTransfer;
            const param = {
                uploadRef: React.createRef(),
                formRef: React.createRef(),
                client_id: null,
                beneficiary_id: this.props.paymentRequest.beneficiary_id,
                amount: this.props.paymentRequest.amount,
                purpose_of_payment: this.props.paymentRequest.purpose_of_payment,
                purpose_of_payment_detail: this.props.paymentRequest.purpose_of_payment_detail,
                account_currency: this.state.beneficiaries_list.find(item => item.id === this.props.paymentRequest.beneficiary_id).account_currency,
                file_id: this.props.paymentRequest.file_id,
                isHidden: false
            }
            const bene = this.state.beneficiaries_list.find(item => item.id === this.props.paymentRequest.beneficiary_id);
            if (bene != null) {
                param.account_currency = bene.account_currency;
                this.props.updatePaymentRequest('account_currency', bene.account_currency);
            } else {
                message.info("This beneficiary does not have account currency");
            }
            transferBulk.push(param);
            state.bulkTransfer = transferBulk;
            this.props.createPaymentRequest(state);
        })
    }

    removeClick(i) {
        const paymentRequestState = this.props.paymentRequest;
        const bulkTransfer = paymentRequestState.bulkTransfer;
        bulkTransfer[i] = {...bulkTransfer[i], isHidden: true};
        paymentRequestState.bulkTransfer = bulkTransfer;
        this.props.createPaymentRequest(paymentRequestState);
    }


    async save_pending_transfers(transfer_detail) {
        const today = dayjs().format('YYYY-MM-DD');
        const staffID = this.props.app_state.current_staff.id;
        return API.put('transfers_pending', '/bulk/add', {body: 
            {
                ...transfer_detail,
                today,
                staffID
            }
        });
    }

    onTeamChange = (teamId) => {
        Promise.all([
            API.get('teams', `/get/client-bene/${teamId}`, {}),
            API.get('transfers', `/get_purpose_by_team/id/${teamId}`, {})
        ]).then(([res1, res2]) => {
            this.setState({
                beneficiaries_list: res1.beneficiaries,
                client_list: res1.clients,
                purpose_list: res2
            });
            this.props.updatePaymentRequest('team_id', teamId)
        });
        API.post("commons", "/fetch", {
            body: {
                context: 'groups',
                fields: ['*'],
                condition: {team_id: teamId}
            }
        }).then(res => {
            this.setState({ group_list: res });
        });
    }

    handleImageUpload = (fileId, ind = null) => {
        if (ind == null) {
            this.props.updatePaymentRequest('file_id', fileId);
        } else {
            const state = this.props.paymentRequest;
            const transferBulk = state.bulkTransfer;
            const index = transferBulk.findIndex((item, i) => i === ind);
            if (index != null && index !== -1) {
                transferBulk[index] = {...transferBulk[index], file_id: fileId}
            }
            state.bulkTransfer = transferBulk;
            this.props.createPaymentRequest(state);
        }
    }

    handleOnChange = (key, val, ind) => {
        let acc_curr = null;
        acc_curr = this.state.beneficiaries_list.find(item => item.id === this.props.paymentRequest.beneficiary_id).account_currency
        if (key === 'beneficiary_id') {
            const bene = this.state.beneficiaries_list.find(item => item.id === val);
            if (bene != null) {
                acc_curr = bene.account_currency;
            } else {
                message.info("This beneficiary does not have account currency");
            }
        }
        const state = this.props.paymentRequest;
        const transferBulk = state.bulkTransfer;
        const index = transferBulk.findIndex((item, i) => i === ind);
        if (index != null && index !== -1) {
            transferBulk[index] = {...transferBulk[index], [key]: val, account_currency: acc_curr}
        }
        state.bulkTransfer = transferBulk;
        this.props.createPaymentRequest(state);
    }

    disablePrefillChange = () => {
        const state = this.props.paymentRequest.bulkTransfer.filter(item => item.isHidden === false);
        return !!state.length;
    }

    onRemoveImage = (index) => {
        const state = this.props.paymentRequest;
        const bulkTransfer = state.bulkTransfer;
        bulkTransfer[index].file_id = null;
        this.props.createPaymentRequest(state);
    }

    handleSubmit = () => {
        this.setState({submitting: true});
        const paymentRequest = this.props.paymentRequest;

        const clients = paymentRequest.bulkTransfer.filter(v => v.isHidden === false).map(item => item.client_id);
        if (clients.some(k => k == null)) {
            message.info("Please select client on every payment request");
            return;
        }

        const transferItems = paymentRequest.bulkTransfer.filter(v => v.isHidden === false).map(item => {
            return {
                client_id: item.client_id,
                beneficiary_id: item.beneficiary_id || paymentRequest.beneficiary_id,
                amount: item.amount || paymentRequest.amount,
                details: item.purpose_of_payment_detail || paymentRequest.purpose_of_payment_detail,
                purposeOfPayment: item.purpose_of_payment || paymentRequest.purpose_of_payment,
                attachmentFileId: item.file_id || paymentRequest.file_id,
                account_currency: item.account_currency || paymentRequest.account_currency,
                invoice_detail: item.invoice_detail || paymentRequest.invoice_detail
            }
        });
        const params = {
            bulkTransfer: transferItems,
            team_id: paymentRequest.team_id,
            expiryDate: paymentRequest.expiryDate
        }

        this.save_pending_transfers(params).finally(() => {
            this.setState({submitting: false});
            this.props.createPaymentRequest({
                bulkTransfer: [],
                expiryDate: null,
                team_id: null,
                beneficiary_id: null,
                amount: null,
                purpose_of_payment: null,
                purpose_of_payment_detail: null,
                file_id: null,
                account_currency: null
            });
            this.props.history.push('/transfers/payment-requests/list');
        });
    }

    handleDateChange = (e) => {
        let newDate = null;
        if (e && typeof e === "object") {
            newDate = e.format('YYYY-MM-DD');
        }
        this.props.updatePaymentRequest('expiryDate', newDate)
    }

    viewSelectedBeneficiary = (index = null) => {

        console.log(this.props.paymentRequest);

        let beneficiary = this.state.beneficiaries_list.find(i => i.id === this.props.paymentRequest.beneficiary_id);
        if (index != null) {
            const state = this.props.paymentRequest.bulkTransfer[index];
            if (state != null && state.beneficiary_id) {
                beneficiary = this.state.beneficiaries_list.find(i => i.id === state.beneficiary_id);
            }
        }
        if (beneficiary != null) {
            const country = this.props.countries.countries_list.find(i => beneficiary.ben_address_country === i.id);
            const currency = this.props.currencies.currencies.find(i => beneficiary.account_currency === i.id);
            return <>
                <Alert message={
                    <Flex vertical={true}>
                        <Typography.Text>Account Number: {beneficiary.account_number}</Typography.Text>
                        {country && <Typography.Text>Country: {country.full_name}</Typography.Text>}
                        {currency && <Typography.Text>Currency: {currency.full_name} ({currency.iso_alpha_3})</Typography.Text>}
                    </Flex>
                }></Alert>
            </>
        } else {
            return <></>
        }
    }

    viewClientDetail = (index) => {
        const state = this.props.paymentRequest.bulkTransfer[index];
        if (state != null && state.client_id) {
            const client = this.state.client_list.find(i => i.id === state.client_id);
            return <Alert message={
                <Flex justify={'start'} vertical={true}>
                    <Typography.Text>Name: {client.first_name} {client.last_name}</Typography.Text>
                    <Typography.Text>Email: {client.email}</Typography.Text>
                </Flex>
            }></Alert>
        } else {
            return <></>
        }
    }

    filterClientByGroup = async (val) => {
        if (!val){
            this.setState({
                fClientList: this.state.client_list.map(i => ({
                    label: i.nickname,
                    value: i.id
                })),
                group_id: null
            });
        }
        const res = await API.post("commons", "/fetch", {
            body: {
                context: 'group_client_cross',
                fields: ['*'],
                condition: {group_id: val}
            }
        });
        const clients = res.map(item => item.client_id);
        const clientList = this.state.client_list.filter(i => clients.includes(i.id));
        this.setState({ fClientList: clientList.map(item => ({label: item.nickname, value: item.id})), group_id: val });
    }

    render() {
        const {classes} = this.props;
        if (!this.props.app_state.current_staff) {
            return <CircularProgress/>;
        }
        if (!this.props.app_state.current_staff.id) {
            return <CircularProgress/>;
        }

        if (this.state.isLoading) {
            return (
                <div>
                    <CircularProgress/>
                </div>
            );
        }

      const client_list_select_options = this.state.client_list
          .sort((a, b) => a.nickname.localeCompare(b.nickname))
          .map(item => ({
            value: item.id,
            label: item.nickname
          }));

        const team_list_select_options = this.state.teams_list
            .sort((a, b) => a.nickname.localeCompare(b.nickname))
            .map(item => ({
                value: item.id,
                label: item.nickname
            }));

        const group_list_select_options = this.state.group_list
            .sort((a, b) => a.nickname.localeCompare(b.nickname))
            .map(item => ({
                value: item.id,
                label: item.nickname
            }));

        const beneficiary_list_options = this.state.beneficiaries_list
            .filter(i => this.state.selected_currency != null ? i.account_currency === this.state.selected_currency : true)
            .sort((a, b) => a.nickname.localeCompare(b.nickname))
            .map(item => {
                const curr = this.props.currencies.currencies.find(i => i.id === item.account_currency);
                return {
                    value: item.id,
                    label: <TypographyCurrency iso_alpha_3={curr.iso_alpha_3}>{item.nickname}</TypographyCurrency>
                }
            })

        const purpose_of_payment_list_options = this.state.purpose_list
            .sort((a, b) => a.description.localeCompare(b.description))
            .map(item => ({
                value: item.id,
                label: item.description
            }))

        let currencyList = []
        if (this.props.currencies && this.props.currencies.currencies) {
        currencyList = this.props.currencies.currencies;
        }
        const select_currency_select_options = currencyList.map(item => ({
            value: item.id,
            alt:  item.iso_alpha_3 + ': ' + item.full_name,
            label: <React.Fragment>
                <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
                &nbsp;
                {item.iso_alpha_3 + ': ' + item.full_name}
            </React.Fragment>
        }));

        return (<>

            <div>
                <Card>
                    <CardHeader icon>
                        {/*<h4 className={classes.cardIconTitle}>*/}
                        {/*    <FormattedMessage id='Enter details for Bulk Transfer'*/}
                        {/*                      defaultMessage={'Payment Request #'}/> {i + 1}*/}
                        {/*</h4>*/}
                    </CardHeader>

                    <CardBody>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form key={'mainForm'} name="mainFormRef" ref={this.mainFormRef} layout={'vertical'}>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Row gutter={16}>
                                                <Col span={24}>
                                                    <Form.Item label={'Team'} name="team_id"
                                                               rules={[{required: true, message: 'Team is required'}]}>
                                                        <Select disabled={this.disablePrefillChange()} placeholder={'-- SELECT TEAM --'} options={team_list_select_options} allowClear={true}
                                                                onChange={val => this.onTeamChange(val)}></Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item label={'Group'}>
                                                        <Select placeholder={'-- SELECT GROUP --'}
                                                                onChange={val => this.filterClientByGroup(val)}
                                                                options={group_list_select_options}
                                                                allowClear={true}></Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col className={'gutter-row'} span={12}>
                                                    <Form.Item name="account_currency"
                                                            label={'Currency'}
                                                            >
                                                        <Select showSearch
                                                                allowClear={true}
                                                                style={{ width: '100%' }}
                                                                onChange={(event) => this.setState({ selected_currency: event }) }
                                                                placeholder=" -- SELECT CURRENCY --"
                                                                optionFilterProp="alt"
                                                                filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())}
                                                                filterSort={(optionA, optionB) =>
                                                                (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                                                                }
                                                                options={select_currency_select_options}></Select></Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label={'Beneficiary'} name="beneficiary_id"
                                                               rules={[{
                                                                   required: true,
                                                                   message: 'Beneficiary is required'
                                                               }]}>
                                                        <Select disabled={this.disablePrefillChange()} onChange={(val) => this.props.updatePaymentRequest('beneficiary_id', val)} placeholder={'-- SELECT BENEFICIARY --'} options={beneficiary_list_options}
                                                                allowClear={true}></Select>
                                                    </Form.Item>
                                                    {this.viewSelectedBeneficiary()}
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item label={'Amount'} name="amount" rules={[{required: true, message: 'Amount is required'}]}>
                                                        <Input disabled={this.disablePrefillChange()} onChange={(e) => this.props.updatePaymentRequest('amount', e.target.value)} placeholder='Enter amount..' type={'number'} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item label={'Purpose of Payment'} name="purpose_of_payment"
                                                               rules={[{
                                                                   required: true,
                                                                   message: 'Purpose of payment is required'
                                                               }]}>
                                                        <Select disabled={this.disablePrefillChange()} onChange={(val) => this.props.updatePaymentRequest('purpose_of_payment', val)} placeholder={'-- PURPOSE OF PAYMENT --'} options={purpose_of_payment_list_options}
                                                                allowClear={true}></Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label={'Details'} name="purpose_of_payment_detail">
                                                        <Input disabled={this.disablePrefillChange()} onChange={(e) => this.props.updatePaymentRequest('purpose_of_payment_detail', e.target.value)} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Form.Item label={'Details'} style={{ width: '100%' }} name="invoice_detail">
                                                    <Input.TextArea disabled={this.disablePrefillChange()} onChange={(e) => this.props.updatePaymentRequest('invoice_detail', e.target.value)} />
                                                </Form.Item>
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="payment_request_expiry" label={'Payment Expiry'}>
                                                <DatePicker locale={''} onChange={(e) => this.handleDateChange(e)} format={'DD/MM/YYYY'}> </DatePicker>
                                            </Form.Item>
                                            <Form.Item label={'Upload'}>
                                                <AntUploader ref={this.mainUploadRef} clearList={() => []} onRemoveImage={() => this.props.updatePaymentRequest('file_id', null)}
                                                             onHandleResult={(fileId) => this.handleImageUpload(fileId)}></AntUploader>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <hr/>
                      <Row gutter={16}>
                        <Col span={24}>
                          {this.props.paymentRequest.bulkTransfer.map((item, index) => {
                             return <>
                             {
                                 !item.isHidden && <Form key={`form-${index}`} layout={'vertical'} name={`form-${index}`} ref={item.formRef}>
                                     <Row gutter={16}>
                                         <Col span={12}>
                                             <Flex justify={'start'} gap={10} align={'center'}>
                                                 <Form.Item label="Client" name="client_id" rules={[{required: true, message: 'Client is required'}]}>
                                                     <Select placeholder={'-- SELECT CLIENT --'}
                                                             onChange={val => this.handleOnChange('client_id', val, index)}
                                                             options={this.state.group_id ? this.state.fClientList : client_list_select_options}
                                                             allowClear={true}></Select>
                                                 </Form.Item>
                                                 <span>
                                                     {this.viewClientDetail(index)}
                                                 </span>
                                             </Flex>
                                         </Col>
                                         <Col span={12}>
                                             <Flex justify={'end'}>
                                                 <AntButton icon={<DeleteOutlined />} onClick={(() => this.removeClick(index))}></AntButton>
                                             </Flex>
                                         </Col>
                                     </Row>
                                     <Row gutter={16}>
                                         <Col span={16}>
                                             <Row gutter={16}>
                                                 <Col span={6}>
                                                     <Form.Item label='Beneficiary' name="beneficiary_id" rules={[{required: true, message: 'Beneficiary is required'}]}>
                                                         <Select defaultValue={this.props.paymentRequest.beneficiary_id}
                                                                 onChange={val => this.handleOnChange('beneficiary_id', val, index)}
                                                                 placeholder={'-- SELECT BENEFICIARY --'}
                                                                 options={beneficiary_list_options}></Select>
                                                     </Form.Item>
                                                 </Col>
                                                 <Col span={6}>
                                                     <Form.Item label='Amount' name="amount" rules={[{required: true, message: 'Amount'}]}>
                                                         <Input defaultValue={this.props.paymentRequest.amount}
                                                                onChange={e => this.handleOnChange('amount', e.target.value, index)}
                                                         />
                                                     </Form.Item>
                                                 </Col>
                                                 <Col span={6}>
                                                     <Form.Item label='Purpose of Payment' name="purpose_of_payment" rules={[{required: true, message: 'Purpose of payment is required'}]}>
                                                         <Select defaultValue={this.props.paymentRequest.purpose_of_payment} onChange={(val) => this.handleOnChange('purpose_of_payment', val, index)} placeholder={'-- PURPOSE OF PAYMENT --'} options={purpose_of_payment_list_options}
                                                                 allowClear={true}></Select>
                                                     </Form.Item>
                                                 </Col>
                                                 <Col span={6}>
                                                     <Form.Item label='Detail' name="purpose_of_payment_detail">
                                                         <Input defaultValue={this.props.paymentRequest.purpose_of_payment_detail} onChange={e => this.handleOnChange('purpose_of_payment_detail', e.target.value, index)} />
                                                     </Form.Item>
                                                 </Col>
                                             </Row>
                                         </Col>
                                         <Col span={8}>
                                             <AntUploader ref={item.uploadRef} clearList={() => []} onRemoveImage={() => this.onRemoveImage(index) }
                                                          onHandleResult={(fileId) => this.handleImageUpload(fileId, index)}></AntUploader>
                                         </Col>
                                     </Row>
                                 </Form>
                             }
                             </>
                          })}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                        <GridContainer>
                            <GridItem xs={12} md={2}>
                                {/*<Button size='sm' color='warning' key={i} onClick={this.removeClick.bind(this)}>*/}
                                {/*    Remove This Record*/}
                                {/*</Button>*/}
                            </GridItem>
                        </GridContainer>
                    </CardFooter>
                </Card>
            </div>

            <Flex justify={'space-between'}>
                <Button size='sm' color='warning' onClick={() => this.addClick()}>
                    Add new Record
                </Button>
                <Button color='rose'
                         onClick={() => this.handleSubmit()}
                         disabled={this.state.submitting || !this.disablePrefillChange() }
                         className={classes.registerButton}>
                    Create Payment Requests
                </Button>
            </Flex>
        </>)
    }
}

// TransfersUpload.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withRouter(withStyles(staffEditStyles)(TransfersUpload));

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        clients: state.clients,
        paymentRequest: state.paymentRequest,
        countries: state.countries,
        currencies: state.currencies
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadClientDetailsRequest: () => {
            dispatch(loadClientDetailsRequest());
        },
        loadClientDetailsSuccess: () => {
            dispatch(loadClientDetailsSuccess());
        },
        createCognitoAccountForClient: (id, username, password, language_id) => {
            dispatch(createCognitoAccountForClient(id, username, password, language_id));
        },
        updateClient: (data, id) => {
            dispatch(updateClient(data, id));
        },
        createPaymentRequest: (data) => {
            dispatch(addPaymentRequest(data));
        },
        updatePaymentRequest: (key, value) => {
            dispatch(updatePaymentRequest(key, value));
        },
        fetchCountries: () => {
            dispatch(fetchCountryListNotDeleted())
        },
        fetchCurrencies: () => {
            dispatch(fetchCurrencyListNotDeleted())
        }
    };
};

const TransfersBulkContainer = connect(mapStateToProps, mapDispatchToProps)(TransfersBulk);

export default withRouter(withStyles(staffEditStyles)(TransfersBulkContainer));
