import React, {Fragment} from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import {AiOutlineSearch} from 'react-icons/ai';
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';
import Button from 'components/CustomButtons/Button.jsx';
import {API} from 'aws-amplify';

const ClientContacts = props => {
  const {client_contacts_list, history, loading, setLoading, fetchClientContactsList} = props;

  const getFilter = (filter, onChange) => {
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AiOutlineSearch />
          <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
        </div>
      </React.Fragment>
    );
  };

  const handleClientContactsDelete = clientContactsId => {
    if (!loading) {
      setLoading(true);
    }
    API.put('clientcontacts', `/id/${clientContactsId}`, {body: {deleted: true}})
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
        API.put('clientcontacts', `/id/${clientContactsId}`, {body: {deleted: true}})
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .finally(() => {
        fetchClientContactsList();
      });
  };

  const buildTableData = data => {
    if (!data) {
      return [];
    }

    return data.map(item => ({
      ...item,
      actions: (
        <div className='actions-right'>
          <Button
            justIcon
            round
            simple
            onClick={() => {
              history.push(`/clients/contacts/view/${item.id}`);
            }}
            color='warning'
          >
            <Dvr />
          </Button>
          <Button
            justIcon
            round
            simple
            onClick={() => handleClientContactsDelete(item.id)}
            color='danger'
            className='remove'
          >
            <Close />
          </Button>
        </div>
      )
    }));
  };

  return (
    <Fragment>
      <ReactTable
        loading={loading}
        data={buildTableData(client_contacts_list)}
        filterable
        columns={[
          {
            Header: 'Id',
            accessor: 'id',
            Filter: ({filter, onChange}) => getFilter(filter, onChange),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['id']}),
            filterAll: true,
            maxWidth: 50
          },
          {
            Header: 'First Name',
            accessor: 'firstName',
            Filter: ({filter, onChange}) => getFilter(filter, onChange),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['firstName']}),
            filterAll: true,
            maxWidth: 150
          },
          {
            Header: 'Last Name',
            accessor: 'lastName',
            Filter: ({filter, onChange}) => getFilter(filter, onChange),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['lastName']}),
            filterAll: true,
            maxWidth: 150
          },
          {
            Header: 'Client Id',
            accessor: 'clientId',
            Filter: ({filter, onChange}) => getFilter(filter, onChange),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['clientId']}),
            filterAll: true,
            maxWidth: 100
          },
          {
            Header: 'Clients Nickname',
            accessor: 'clients_nickname',
            Filter: ({filter, onChange}) => getFilter(filter, onChange),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['clients_nickname']}),
            filterAll: true,
            maxWidth: 200
          },
          {
            Header: 'Email',
            accessor: 'email',
            Filter: ({filter, onChange}) => getFilter(filter, onChange),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['email']}),
            filterAll: true,
            maxWidth: 200
          },
          {
            Header: 'Actions',
            accessor: 'actions',
            sortable: false,
            filterable: false
          }
        ]}
        defaultSorted={[
          {
            id: 'id',
            desc: true
          }
        ]}
        defaultPageSize={10}
        showPageSizeOptions={true}
        showPaginationBottom
      />
    </Fragment>
  );
};

export default ClientContacts;
