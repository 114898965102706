import {connect} from 'react-redux';
import ReportsClient from './ReportsClient';
import {} from '../../../redux/actions/clients';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers: state.transfers
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const ReportsClientContainer = connect(mapStateToProps, mapDispatchToProps)(ReportsClient);

export default ReportsClientContainer;
