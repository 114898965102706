import {Card, CardHeader} from '@material-ui/core';
import React, {Fragment} from 'react';
import CardBody from '../../Card/CardBody';
import CustomInput from '../../CustomInput/CustomInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import Button from '../../CustomButtons/Button';

export const Address = ({
  classes,
  handleSubmit,
  app_state,
  state,
  handleChange,
  setState,
  handleCustomReactSelectChange,
  handleToggle,

  referral_source_select_options
}) => {
  const select_country_select_options = state.country_list_prio.map(item => ({
    value: item.id,
    label: item.full_name
  }));

  return (
    <Fragment>
      <div style={{padding: 20}}>
        <h4 className={classes.cardIconTitle}>Address Details</h4>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card style={{height: '90%'}}>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Residential Address</h4>
              </CardHeader>

              <CardBody>
                <CustomInput
                  labelText='Residential Street Address (line 1)'
                  id='residential_street_line_1'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: !state.edit_mode,
                    value: state.residential_street_line_1 || '',
                    onChange: event => {
                      handleChange(event);
                    }
                  }}
                />

                <CustomInput
                  labelText='Residential Street Address (line 2)'
                  id='residential_street_line_2'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: !state.edit_mode,
                    value: state.residential_street_line_2 || '',
                    onChange: event => {
                      handleChange(event);
                    }
                  }}
                />

                <CustomInput
                  labelText='Suburb'
                  id='residential_street_suburb'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: !state.edit_mode,
                    value: state.residential_street_suburb || '',
                    onChange: event => {
                      handleChange(event);
                    }
                  }}
                />

                <CustomInput
                  labelText='State'
                  id='residential_street_state'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: !state.edit_mode,
                    value: state.residential_street_state || '',
                    onChange: event => {
                      handleChange(event);
                    }
                  }}
                />

                <CustomInput
                  labelText='Postcode'
                  id='residential_street_postcode'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: !state.edit_mode,
                    value: state.residential_street_postcode || '',
                    onChange: event => {
                      handleChange(event);
                    }
                  }}
                />

                <CustomReactSelect
                  label='Country'
                  options={select_country_select_options}
                  value={state.residential_street_country}
                  onChange={handleCustomReactSelectChange('residential_street_country')}
                  isClearable={false}
                  isDisabled={!state.edit_mode}
                />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card style={{height: '90%'}}>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Postal Address</h4>
              </CardHeader>

              <CardBody>
                <CustomInput
                  labelText='Postal Street Address (line 1)'
                  id='postal_street_line_1'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: !state.edit_mode,
                    value: state.postal_street_line_1 || '',
                    onChange: event => {
                      handleChange(event);
                    }
                  }}
                />

                <CustomInput
                  labelText='Postal Street Address (line 2)'
                  id='postal_street_line_2'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: !state.edit_mode,
                    value: state.postal_street_line_2 || '',
                    onChange: event => {
                      handleChange(event);
                    }
                  }}
                />

                <CustomInput
                  labelText='Suburb'
                  id='postal_street_suburb'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: !state.edit_mode,
                    value: state.postal_street_suburb || '',
                    onChange: event => {
                      handleChange(event);
                    }
                  }}
                />

                <CustomInput
                  labelText='State'
                  id='postal_street_state'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: !state.edit_mode,
                    value: state.postal_street_state || '',
                    onChange: event => {
                      handleChange(event);
                    }
                  }}
                />

                <CustomInput
                  labelText='Postcode'
                  id='postal_street_postcode'
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    disabled: !state.edit_mode,
                    value: state.postal_street_postcode || '',
                    onChange: event => {
                      handleChange(event);
                    }
                  }}
                />

                <CustomReactSelect
                  label='Country'
                  options={select_country_select_options}
                  value={state.postal_street_country}
                  onChange={handleCustomReactSelectChange('postal_street_country')}
                  isClearable={false}
                  isDisabled={!state.edit_mode}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </Fragment>
  );
};
