import PersonAdd from '@material-ui/icons/PersonAdd';
import Fingerprint from '@material-ui/icons/Fingerprint';
import LoginPageContainer from '../views/Pages/LoginPage';
import VerifyEmailPage from '../views/Pages/VerifyEmailPage';
import RegisterTeamPage from '../views/Pages/RegisterTeamPage';

const pagesRoutes = [
  {
    path: '/pages/register-business',
    name: 'Register Business',
    short: 'Register',
    mini: 'RP',
    invisible: true,
    icon: PersonAdd,
    component: RegisterTeamPage
  },
  {
    path: '/pages/login/:referral_code',
    name: 'Login Page',
    short: 'Login',
    mini: 'LP',
    invisible: true,
    icon: Fingerprint,
    component: LoginPageContainer
  },
  {
    path: '/pages/login',
    name: 'Login Page',
    short: 'Login',
    mini: 'LP',
    icon: Fingerprint,
    component: LoginPageContainer
  },
  {
    path: '/pages/verify-email/:referral_code',
    name: 'Verify Email',
    short: 'Verify',
    mini: 'VE',
    invisible: true,
    icon: PersonAdd,
    component: VerifyEmailPage
  },
  {
    path: '/pages/verify-email',
    name: 'Verify Email',
    short: 'Verify',
    mini: 'VE',
    invisible: true,
    icon: PersonAdd,
    component: VerifyEmailPage
  },
  {
    redirect: true,
    path: '/pages',
    pathTo: '/pages/login',
    name: 'Login Page'
  }
];

export default pagesRoutes;
