import {connect} from 'react-redux';
import DivisionEdit from './DivisionEdit';

import {
  requestEntityList,
  fetchEntityListNotDeleted,
  entitiesListIsDirty,
  setEntityDetailId,
  selectEntityUi
} from '../../redux/actions/divisions';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    divisions: state.divisions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestEntityList: () => {
      dispatch(requestEntityList());
    },
    fetchEntityListNotDeleted: () => {
      dispatch(fetchEntityListNotDeleted());
    },
    entitiesListIsDirty: value => {
      dispatch(entitiesListIsDirty(value));
    },
    setEntityDetailId: id => {
      dispatch(setEntityDetailId(id));
    },
    selectEntityUi: value => {
      dispatch(selectEntityUi(value));
    }
  };
};

const DivisionEditContainer = connect(mapStateToProps, mapDispatchToProps)(DivisionEdit);

export default DivisionEditContainer;
