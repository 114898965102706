import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';

import CardHeader from '../../components/Card/CardHeader';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import NavPills from 'components/NavPills/NavPills.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import {API} from 'aws-amplify';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const staffEditStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  }
};

class EntitybanksEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      is_loading: true,
      id: '',
      eb_long_name: '',
      eb_short_name: '',
      address_line_1: '',
      address_line_2: '',
      address_suburb: '',
      address_postcode: '',
      address_state: '',
      address_country: '',
      country_list: [],
      country_list_prio: []
    };
  }

  componentDidMount() {
    if (this.props.entity_banks.id) {
      API.get('system', `/entitybanks/get/id/${this.props.entity_banks.id}`)
        .then(entity_detail => {
          console.log(entity_detail);

          this.setState({
            loaded: true,
            is_loading: false,
            id: entity_detail[0].id,
            eb_long_name: entity_detail[0].eb_long_name,
            eb_short_name: entity_detail[0].eb_short_name,
            // eb_address: entity_detail[0].eb_address,
            address_line_1: entity_detail[0].address_line_1,
            address_line_2: entity_detail[0].address_line_2,
            address_suburb: entity_detail[0].address_suburb,
            address_postcode: entity_detail[0].address_postcode,
            address_state: entity_detail[0].address_state,
            address_country: entity_detail[0].address_country
          });

          //this.props.loadStaffMemberDetailsSuccess();
        })
        .catch(error => {
          console.log(error);
        });
    }
    API.get('countries', `/list_not_deleted`)
      .then(response => {
        this.setState({
          // country_list: response.fullList,
          country_list_prio: response
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  async saveStaffMember(entity_bank_detail) {
    return API.put('system', `/entitybanks/update/id/${this.props.entity_banks.id}`, {
      body: entity_bank_detail
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSelectChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading: true});

    const {
      id,
      eb_long_name,
      eb_short_name,
      // eb_address,
      address_line_1,
      address_line_2,
      address_suburb,
      address_state,
      address_postcode,
      address_country
    } = this.state;
    try {
      await this.saveStaffMember({
        id: id,
        eb_long_name: eb_long_name,
        eb_short_name: eb_short_name,
        // eb_address: eb_address,
        address_line_1,
        address_line_2,
        address_suburb,
        address_state,
        address_postcode,
        address_country
      });
    } catch (e) {
      // this.setState({ isLoading: false });
    }

    this.props.selectEntitybanksUi('list');
  };

  render() {
    const {classes} = this.props;
    //console.log(this.state);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Editing {this.state.eb_long_name}</h4>
              </CardHeader>
              <CardBody>
                <NavPills
                  color='info'
                  tabs={[
                    {
                      // tabButton: "User & Account",
                      tabContent: (
                        <div style={{padding: 20}}>
                          <form onSubmit={this.handleSubmit}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText='Nickname'
                                  id='eb_short_name'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.eb_short_name || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText='Legal Name'
                                  id='eb_long_name'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.eb_long_name || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText='Address Line 1'
                                  id='address_line_1'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.address_line_1 || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                                <CustomInput
                                  labelText='Address Line 2'
                                  id='address_line_2'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.address_line_2 || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                                <CustomInput
                                  labelText='Address Suburb'
                                  id='address_suburb'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.address_suburb || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                                <CustomInput
                                  labelText='Address State'
                                  id='address_state'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.address_state || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                                <CustomInput
                                  labelText='Address Postcode'
                                  id='address_postcode'
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    value: this.state.address_postcode || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                                {/*<CustomInput*/}
                                {/*  labelText="Address Country"*/}
                                {/*  id="address_country"*/}
                                {/*  formControlProps={{*/}
                                {/*    fullWidth: true*/}
                                {/*  }}*/}
                                {/*  inputProps={{*/}
                                {/*    value: this.state.address_country || "",*/}
                                {/*    onChange: event => {*/}
                                {/*      this.handleChange(event);*/}
                                {/*    }*/}
                                {/*  }}*/}
                                {/*/>*/}

                                <FormControl fullWidth className={classes.selectFormControl}>
                                  <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                                    Select Country
                                  </InputLabel>

                                  <Select
                                    MenuProps={{
                                      className: classes.selectMenu
                                    }}
                                    classes={{
                                      select: classes.select
                                    }}
                                    value={this.state.address_country || '0'}
                                    onChange={this.handleSelectChange}
                                    // onChange={this.change(event, "residential_street_country", "length", 3)}
                                    inputProps={{
                                      name: 'address_country',
                                      id: 'address_country'
                                    }}
                                  >
                                    <MenuItem
                                      key='0x0'
                                      value='0'
                                      disabled
                                      classes={{
                                        root: classes.selectMenuItem
                                      }}
                                    >
                                      Select Country
                                    </MenuItem>

                                    {this.state.country_list_prio
                                      .sort((a, b) => a.list_priority < b.list_priority)
                                      .map(item => {
                                        return (
                                          <MenuItem
                                            key={item.id}
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected
                                            }}
                                            value={item.id}
                                          >
                                            {item.full_name}
                                          </MenuItem>
                                        );
                                      })}
                                    {/*<MenuItem*/}
                                    {/*  key="0x0"*/}
                                    {/*  value="0"*/}
                                    {/*  disabled*/}
                                    {/*  classes={{*/}
                                    {/*    root: classes.selectMenuItem*/}
                                    {/*  }}*/}
                                    {/*>*/}
                                    {/*  --------------------------------------------------*/}
                                    {/*</MenuItem>*/}
                                    {/*{this.state.country_list*/}
                                    {/*  .sort((a, b) => a.full_name.localeCompare(b.full_name))*/}
                                    {/*  .map(item => {*/}
                                    {/*    return (*/}
                                    {/*      <MenuItem*/}
                                    {/*        key={item.id}*/}
                                    {/*        classes={{*/}
                                    {/*          root: classes.selectMenuItem,*/}
                                    {/*          selected: classes.selectMenuItemSelected*/}
                                    {/*        }}*/}
                                    {/*        value={item.id}*/}
                                    {/*      >*/}
                                    {/*        {item.full_name}*/}
                                    {/*      </MenuItem>*/}
                                    {/*    );*/}
                                    {/*  })}*/}
                                  </Select>
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                            <Button color='primary' type='submit' className={classes.updateProfileButton}>
                              Update Entity Banks
                            </Button>
                          </form>
                        </div>
                      )
                    }
                  ]}
                />
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

EntitybanksEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(staffEditStyles)(EntitybanksEdit);
