import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// @material-ui/icons
import Check from '@material-ui/icons/Check';

// core components
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
import Card from '../Card/Card.jsx';
import CardBody from '../Card/CardBody.jsx';
import checkboxStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';

import CardHeader from '../../components/Card/CardHeader';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import NavPills from 'components/NavPills/NavPills.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import {API} from 'aws-amplify';

const staffEditStyles = {
  ...checkboxStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {float: 'right'}
};

class CurrenciesEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      is_loading: true,
      full_name: '',
      iso_alpha_2: '',
      iso_alpha_3: '',
      list_priority: ''
    };
  }

  componentDidMount() {
    if (this.props.currencies.currency_id) {
      API.get('system', `/currencies/get/${this.props.currencies.currency_id}`)
        .then(currency_detail => {
          this.setState({
            loaded: true,
            is_loading: false,
            full_name: currency_detail.full_name,
            iso_alpha_3: currency_detail.iso_alpha_3,
            iso_numeric: currency_detail.iso_numeric,
            list_priority: currency_detail.list_priority,
            iban: currency_detail.iban,
            bsb: currency_detail.bsb,
            sort_code: currency_detail.sort_code,
            swift_code: currency_detail.swift_code,
            aba_routing: currency_detail.aba_routing,
            allowFrom: currency_detail.allowFrom
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  async saveCurrency(currency_detail) {
    return API.put('system', `/currencies/update/id/${this.props.currencies.currency_id}`, {body: currency_detail});
  }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value});
  };

  handleToggle(stateName) {
    const current_stateName = this.state[stateName];
    this.setState({[stateName]: current_stateName === 1 ? 0 : 1});
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading: true});

    const {
      full_name,
      // short_name,
      iso_alpha_3,
      iso_numeric,
      list_priority,
      iban,
      bsb,
      sort_code,
      swift_code,
      aba_routing,
      allowFrom
    } = this.state;
    try {
      await this.saveCurrency({
        full_name: full_name,
        // short_name: short_name,
        iso_alpha_3: iso_alpha_3,
        iso_numeric: iso_numeric,
        list_priority: list_priority,
        iban: iban,
        bsb: bsb,
        sort_code: sort_code,
        swift_code: swift_code,
        aba_routing: aba_routing,
        allowFrom: allowFrom
      });
    } catch (e) {
      // this.setState({ isLoading: false });
    }
  };

  render() {
    const {classes} = this.props;
    console.log(this.state);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Editing {this.state.full_name}</h4>
              </CardHeader>
              <CardBody>
                <NavPills
                  color='info'
                  tabs={[
                    {
                      tabButton: 'Currency',
                      tabContent: (
                        <div style={{padding: 20}}>
                          <form onSubmit={this.handleSubmit}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText='Full Name'
                                  id='full_name'
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    value: this.state.full_name || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText='ISO Alpha 3'
                                  id='iso_alpha_3'
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    value: this.state.iso_alpha_3 || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText='ISO numeric'
                                  id='iso_numeric'
                                  disabled
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    value: this.state.iso_numeric || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <div className={classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={() => this.handleToggle('list_priority')}
                                        checked={this.state.list_priority == 1}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked}}
                                      />
                                    }
                                    classes={{label: classes.label}}
                                    label='Priority Country (place at top of lists)'
                                  />

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={() => this.handleToggle('iban')}
                                        checked={this.state.iban == 1}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked}}
                                      />
                                    }
                                    classes={{label: classes.label}}
                                    label='IBAN'
                                  />

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={() => this.handleToggle('bsb')}
                                        checked={this.state.bsb == 1}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked}}
                                      />
                                    }
                                    classes={{label: classes.label}}
                                    label='BSB'
                                  />

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={() => this.handleToggle('sort_code')}
                                        checked={this.state.sort_code == 1}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked}}
                                      />
                                    }
                                    classes={{label: classes.label}}
                                    label='Sort Code'
                                  />

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={() => this.handleToggle('swift_code')}
                                        checked={this.state.swift_code == 1}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked}}
                                      />
                                    }
                                    classes={{label: classes.label}}
                                    label='Swift Code'
                                  />

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={() => this.handleToggle('aba_routing')}
                                        checked={this.state.aba_routing == 1}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked}}
                                      />
                                    }
                                    classes={{label: classes.label}}
                                    label='ABA Routing'
                                  />

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={() => this.handleToggle('allowFrom')}
                                        checked={this.state.allowFrom == 1}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked}}
                                      />
                                    }
                                    classes={{label: classes.label}}
                                    label='Allow From'
                                  />
                                </div>
                              </GridItem>
                            </GridContainer>

                            <Button color='primary' type='submit' className={classes.updateProfileButton}>
                              Update Currency
                            </Button>
                          </form>
                        </div>
                      )
                    }
                  ]}
                />
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

CurrenciesEdit.propTypes = {classes: PropTypes.object.isRequired};

export default withStyles(staffEditStyles)(CurrenciesEdit);
