import React, {Fragment} from 'react';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import CustomInput from '../../CustomInput/CustomInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';

export const Summary = ({classes, state: {client_details}}) => {
  console.log('gS', client_details);
  return (
    <Fragment>
      <div style={{padding: 0}}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card style={{height: '90%'}}>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>User Details</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText='Username (primary email)'
                      id='email'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: true,
                        value: client_details.email || ''
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText='Nick Name(s)'
                      id='nickname'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: true,
                        value: client_details.nickname || ''
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText='Telephone'
                      id='telephone'
                      disabled
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        disabled: true,
                        value: client_details.telephone_mobile || ''
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </Fragment>
  );
};
