import React from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';

import {connect} from 'react-redux';
import {AiOutlineSearch} from 'react-icons/ai';
// import ClientList from "./ClientList";
import {
  fetchSimpleClientList
  // setClientSelectId
} from '../../redux/actions/clients';

import {API} from 'aws-amplify';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

// @material-ui/icons
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// core components
// import GridContainer from "components/Grid/GridContainer.jsx";
// import GridItem from "components/Grid/GridItem.jsx";
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
// import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from 'components/Card/CardHeader.jsx';

import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import matchSorter from 'match-sorter';
import Badge from '../Badge/Badge';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientList
 * ----------------------------------------------------------------------------
 */
class SimpleClientList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // entity_list: [],
      dialog_delete_confirm: false,
      dialog_delete_item: 0,
      dialog_delete_item_name: '',
      team_ddl_options: null
    };
  }

  componentDidMount() {
    const {fetchSimpleClientList} = this.props;
    fetchSimpleClientList();
  }

  handle_delete_dialog_click = item_to_delete => {
    this.setState({dialog_delete_confirm: false});
    API.put('clients', `/update/id/${item_to_delete}`, {body: {deleted: true}})
      .then(response => {
        //console.log(response);
        this.props.fetchSimpleClientList();
        // dispatch(receiveStaffList(response));
      })
      .catch(error => {
        console.log(error);
        // quickfix because of the api bug, so it returns api error because client talks to old database
        this.props.fetchSimpleClientList();
      });
  };

  handle_delete_dialog_cancel = () => {
    this.setState({
      dialog_delete_confirm: false,
      dialog_delete_item: 0,
      dialog_delete_item_name: ''
    });
  };

  buildTableData() {
    // Check to see if user is super_admin
    const super_admin = this.props.app_state.current_staff_super_admin;

    const client_list_length = this.props.clients.simple_client_list.length;

    if (client_list_length > 0) {
      console.log(this.props.clients.simple_client_list);
      return this.props.clients.simple_client_list.map((prop, key) => {
        return {
          id: prop.id,
          nickname: prop.nickname,
          email: prop.email,
          first_name: prop.first_name,
          telephone_mobile: prop.telephone_mobile,

          actions: (
            <div className='actions-right'>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  this.props.history.push(`/clients/simple-edit/${prop.id}`);
                }}
                color='warning'
                className='edit'
              >
                <Dvr />
              </Button>{' '}
              {/* use this button to remove the data row */}
              {super_admin && (
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    const data = this.props.clients.simple_client_list;
                    // eslint-disable-next-line no-unused-vars
                    data.find((o, i) => {
                      if (o.id === prop.id) {
                        this.setState({
                          dialog_delete_confirm: true,
                          dialog_delete_item: prop.id,
                          dialog_delete_item_name: `${prop.first_name} ${prop.last_name}`
                        });

                        return true;
                      }
                      return false;
                    });
                    this.setState({data: data});
                  }}
                  color='danger'
                  className='remove'
                >
                  <Close />
                </Button>
              )}{' '}
            </div>
          )
        };
      });
      // console.log(data);
    }
  }

  getFilter = (filter, onChange) => {
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AiOutlineSearch />
          <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
        </div>
      </React.Fragment>
    );
  };

  render() {
    // console.log(this.props);

    const team_nicknames = this.props.clients.simple_client_list.map((prop, key) => {
      return prop.team_nickname;
    });
    // console.log(team_nicknames);
    // console.log([...new Set(team_nicknames)]);
    const team_nicknames_unique = [...new Set(team_nicknames)];
    const team_nicknames_optionlist = team_nicknames_unique.map((prop, key) => {
      return <option value={prop}>{prop}</option>;
    });

    const account_status_nickname = this.props.clients.simple_client_list.map((prop, key) => {
      return prop.client_status_nickname;
    });
    const status_nicknames_unique = [...new Set(account_status_nickname)];
    const status_nicknames_optionlist = status_nicknames_unique.map((prop, key) => {
      return <option value={prop}>{prop}</option>;
    });

    const division_nicknames = this.props.clients.simple_client_list.map((prop, key) => {
      return prop.division_nickname;
    });
    const division_nicknames_unique = [...new Set(division_nicknames)];

    //== Remove Unused variables #1077 Tom Improvement
    // var division_nicknames_optionlist = division_nicknames_unique.map((prop, key) => {
    //     return <option value={prop}>{prop}</option>;
    //   }
    // );

    return (
      <React.Fragment>
        <ReactTable
          // data={this.state.entity_list}
          data={this.buildTableData()}
          filterable
          /*
           * Tom's note
           * = previous design =
           * <Badge color={props.original.account_status === 1 ? "info" : props.original.account_status == 2 ? "success" : props.original.account_status === 3 ? "danger" : props.original.account_status === 4 ? "danger" : "warning"}>
           * = current design =
           * we swtich to new one from Issue#961 improvement: info for incomplete, warning for pending, success for approved
           */
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
              Filter: ({filter, onChange}) => this.getFilter(filter, onChange),
              Cell: props => (
                <React.Fragment>
                  <Badge
                    color={
                      props.original.account_status === 1
                        ? 'warning'
                        : props.original.account_status == 2
                        ? 'success'
                        : props.original.account_status === 3
                        ? 'danger'
                        : props.original.account_status === 4
                        ? 'danger'
                        : 'info'
                    }
                  >
                    ID: {props.value.toString().padStart(5, '0')}
                    <br />
                    {props.original.account_status === 1
                      ? 'Pending'
                      : props.original.account_status === 2
                      ? 'Approved'
                      : props.original.account_status === 3
                      ? 'Declined'
                      : props.original.account_status === 4
                      ? 'Suspended'
                      : 'Incomplete'}
                  </Badge>
                  {props.original.verified && <Badge color='info'>VERIFIED</Badge>}
                </React.Fragment>
              ),
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['id']}),
              filterAll: true,
              maxWidth: 100
            },
            {
              Header: 'Name',
              accessor: 'nickname',
              Filter: ({filter, onChange}) => this.getFilter(filter, onChange),
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['nickname']}),
              filterAll: true,
              maxWidth: 300
            },
            {
              Header: 'Email',
              accessor: 'email',
              Filter: ({filter, onChange}) => this.getFilter(filter, onChange),
              Cell: props => (
                <React.Fragment>
                  <a href={`mailto: ${props.value}`}>{props.value}</a>
                </React.Fragment>
              ),
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['email']}),
              filterAll: true,
              maxWidth: 550
            },
            {
              Header: 'Telephone',
              accessor: 'telephone_mobile',
              Filter: ({filter, onChange}) => this.getFilter(filter, onChange),
              Cell: props => (
                <React.Fragment>
                  <a href={`tel: ${props.value}`}>{props.value}</a>
                </React.Fragment>
              ),
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['telephone_mobile']}),
              filterAll: true
            },
            {
              Header: '',
              accessor: 'actions',
              maxWidth: 100,
              sortable: false,
              filterable: false
            }
          ]}
          defaultSorted={[
            {
              id: 'id',
              desc: false
            },
            {
              id: 'nickname',
              desc: false
            }
          ]}
          defaultPageSize={10}
          showPaginationBottom
          className='-highlight'
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSimpleClientList: () => {
      dispatch(fetchSimpleClientList());
    }
  };
};

const SimpleClientListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(SimpleClientList)));

export default SimpleClientListContainer;
