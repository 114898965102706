import {API} from 'aws-amplify';

// export const ENTITIES_SELECT_STAFF_MEMBER = "STAFF_SELECT_STAFF_MEMBER";
export const CURRENCIES_FETCH_CURRENCY_LIST_REQUEST = 'CURRENCIES_FETCH_CURRENCY_LIST_REQUEST';
export const CURRENCIES_FETCH_CURRENCY_LIST_SUCCESS = 'CURRENCIES_FETCH_CURRENCY_LIST_SUCCESS';
// export const CURRENCIES_FETCH_CURRENCY_LIST_FAILURE = "CURRENCIES_FETCH_CURRENCY_LIST_FAILURE";
export const CURRENCIES_CREATE_NEW_SHOW_UI = 'CURRENCIES_CREATE_NEW_SHOW_UI';
export const CURRENCIES_CREATE_NEW_START = 'CURRENCIES_CREATE_NEW_START';
export const CURRENCIES_CREATE_NEW_CANCEL = 'CURRENCIES_CREATE_NEW_CANCEL';
export const CURRENCIES_CREATE_NEW_COMPLETE = 'CURRENCIES_CREATE_NEW_COMPLETE';
export const CURRENCIES_SELECT_UI = 'CURRENCIES_SELECT_UI';
export const CURRENCIES_CURRENCIES_LIST_IS_DIRTY = 'CURRENCIES_CURRENCIES_LIST_IS_DIRTY';
export const CURRENCIES_CURRENCY_EDIT = 'CURRENCIES_CURRENCY_EDIT';

export function requestEntityList() {
  return {
    type: CURRENCIES_FETCH_CURRENCY_LIST_REQUEST
  };
}

export function receiveEntityList(json) {
  return {
    type: CURRENCIES_FETCH_CURRENCY_LIST_SUCCESS,
    currencies: json,
    receivedAt: Date.now()
  };
}

export function fetchCurrencyListNotDeleted() {
  return function (dispatch) {
    dispatch(requestEntityList);

    API.get('system', `/currencies/get-not-deleted`)
      .then(response => {
        // console.log(response);
        //console.log('DATA RECEIVED.');
        dispatch(receiveEntityList(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function selectCurrenciesUi(value) {
  return {
    type: CURRENCIES_SELECT_UI,
    value: value
  };
}

export function currenciesListIsDirty(value) {
  return {
    type: CURRENCIES_CURRENCIES_LIST_IS_DIRTY,
    value: value
  };
}

export async function create_new_currency_in_database(
  full_name,
  short_name,
  iso_alpha_3,
  iso_numeric,
  list_priority,
  iban,
  bsb,
  sort_code,
  swift_code,
  aba_routing
) {
  API.post('system', `/currencies/create/in-database`, {
    body: {
      full_name: full_name,
      short_name: short_name,
      iso_alpha_3: iso_alpha_3,
      iso_numeric: iso_numeric,
      list_priority: list_priority,
      iban: iban,
      bsb: bsb,
      sort_code: sort_code,
      swift_code: swift_code,
      aba_routing: aba_routing
    }
  })
    .then(database_result => {
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
}

export function createNewCurrency(data) {
  return async function (dispatch) {
    //dispatch(createNewEntityStart());

    try {
      await create_new_currency_in_database(
        data.full_name,
        data.short_name,
        data.iso_alpha_3,
        data.iso_numeric,
        data.list_priority,
        data.iban,
        data.bsb,
        data.sort_code,
        data.swift_code,
        data.aba_routing
      );
    } catch (e) {
      console.log(e);
    }
  };
}

export function cancelCreateNewCurrency() {
  return {
    type: CURRENCIES_CREATE_NEW_CANCEL
  };
}

export function createNewCurrencyStart() {
  return {
    type: CURRENCIES_CREATE_NEW_START
  };
}

export function createNewCurrencyComplete() {
  return {
    type: CURRENCIES_CREATE_NEW_COMPLETE
  };
}

export function editCurrencies(currency_id) {
  return {
    type: CURRENCIES_CURRENCY_EDIT,
    value: currency_id
  };
}
