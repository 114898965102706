import {API} from 'aws-amplify';

export const DIVISION_FETCH_DIVISION_LIST_REQUEST = 'DIVISION_FETCH_DIVISION_LIST_REQUEST';
export const DIVISION_FETCH_DIVISION_LIST_SUCCESS = 'DIVISION_FETCH_DIVISION_LIST_SUCCESS';
export const DIVISION_FETCH_DIVISION_LIST_FAILURE = 'ENTITIES_FETCH_DIVISION_LIST_FAILURE';
export const DIVISION_CREATE_NEW_SHOW_UI = 'DIVISION_CREATE_NEW_SHOW_UI';
export const DIVISION_CREATE_NEW_START = 'DIVISION_CREATE_NEW_START';
export const DIVISION_CREATE_NEW_CANCEL = 'DIVISION_CREATE_NEW_CANCEL';
export const DIVISION_CREATE_NEW_COMPLETE = 'DIVISION_CREATE_NEW_COMPLETE';
export const DIVISION_SELECT_UI = 'DIVISION_SELECT_UI';
export const DIVISION_ENTITIES_LIST_IS_DIRTY = 'DIVISION_ENTITIES_LIST_IS_DIRTY';
export const DIVISION_SELECT_SET_ID = 'DIVISION_SELECT_SET_ID';
export const DIVISION_SELECT_ENTITY_REQUEST = 'DIVISION_SELECT_ENTITY_REQUEST';
export const DIVISION_SELECT_ENTITY_SUCCESS = 'DIVISION_SELECT_ENTITY_SUCCESS';

export function requestEntityList() {
  return {
    type: DIVISION_FETCH_DIVISION_LIST_REQUEST
  };
}

export function receiveEntityList(json) {
  return {
    type: DIVISION_FETCH_DIVISION_LIST_SUCCESS,
    entities_list: json,
    receivedAt: Date.now()
  };
}

export function fetchEntityListNotDeleted() {
  return function (dispatch) {
    dispatch(requestEntityList);

    API.get('divisions', `/get-list-not-deleted`)
      .then(response => {
        dispatch(receiveEntityList(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function selectEntityUi(value) {
  return {
    type: DIVISION_SELECT_UI,
    value: value
  };
}

export function entitiesListIsDirty(value) {
  return {
    type: DIVISION_ENTITIES_LIST_IS_DIRTY,
    value: value
  };
}

export async function create_new_entity_in_database(nickname, system_email, entity_id, staff_admin) {
  API.post('divisions', `/create/in-database`, {
    body: {
      nickname: nickname,
      system_email: system_email,
      entity_id: entity_id,
      staff_admin: staff_admin
    }
  })
    .then(database_result => {
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
}

export function createNewDivision(data) {
  return async function (dispatch) {
    dispatch(createNewEntityStart());

    try {
      await create_new_entity_in_database(data.nickname, data.system_email, data.entity_id, data.staff_admin);
    } catch (e) {
      console.log(e);
    }
  };
}

export function cancelCreateNewEntity() {
  return {
    type: DIVISION_CREATE_NEW_CANCEL
  };
}

export function setEntityDetailId(id) {
  return {
    type: DIVISION_SELECT_SET_ID,
    value: id
  };
}

export function loadEntityDetailsRequest() {
  return {
    type: DIVISION_SELECT_ENTITY_REQUEST
  };
}
export function loadEntityDetailsSuccess() {
  return {
    type: DIVISION_SELECT_ENTITY_SUCCESS
  };
}

export function createNewEntityStart() {
  return {
    type: DIVISION_CREATE_NEW_START
  };
}

export function createNewEntityComplete() {
  return {
    type: DIVISION_CREATE_NEW_COMPLETE
  };
}
