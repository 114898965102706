import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import clientsListStyles from "./ClientsListStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {FormattedMessage} from 'react-intl';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import Button from '../../../components/CustomButtons/Button';
import ClientListContainer from '../../../components/Clients/ClientList.jsx';
import SimpleClientListContainer from '../../../components/Clients/SimpleClientList.jsx';

const clientsListStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};
/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientsList
 * ----------------------------------------------------------------------------
 */
class SimpleClientsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    // console.log(this.props);
    const {classes} = this.props;
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id='clientList.cardItem1.clientListing' defaultMessage={'Client Listing '} />
              </h4>
              <div>
                <Button
                  round
                  color='primary'
                  // onClick={() => this.props.history.push("/clients/list")}
                >
                  <FormattedMessage id='newClient.cardItem1.showAll' defaultMessage={'Show All'} />
                </Button>
                <Button round color='info' onClick={() => this.props.history.push('/clients/new')}>
                  <FormattedMessage id='newClient.cardItem1.createNew' defaultMessage={'Create New'} />
                </Button>
                <Button round color='info' onClick={() => this.props.fetchClientList()}>
                  <FormattedMessage id='newClient.cardItem1.refresh' defaultMessage={'Refresh'} />
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <SimpleClientListContainer />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

SimpleClientsList.propTypes = {
  classes: PropTypes.object.isRequired,
  clients: PropTypes.object.isRequired,
  fetchClientList: PropTypes.func.isRequired,
  selectClientUi: PropTypes.func.isRequired
};

export default withStyles(clientsListStyles)(SimpleClientsList);
