import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// @material-ui/icons
import Menu from '@material-ui/icons/Menu';

// core components
import Button from 'components/CustomButtons/Button';
import pagesRoutes from 'routes/pages.jsx';
import pagesHeaderStyle from 'assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle.jsx';

//setup for language components
import LanguageSelectionContainer from '../CustomDropdown/LanguageSelectionContainer';
import queryString from 'query-string';

// import logo from "assets/img/GOSTUDY_HorizontalWhite.png";

class PagesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    const query_strings = queryString.parse(this.props.location.search);
    if (query_strings) {
      if (query_strings.ref) {
        this.setState({
          ref: true,
          ref_code: query_strings.ref
        });
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState({open: !this.state.open});
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({open: false});
    }
  }

  render() {
    const {classes, color} = this.props;

    // Handle Branding
    let page_title;
    switch (this.state.ref_code) {
      case 'forexsport':
        page_title = 'Forex Sport FX CRM';
        break;
      case 'forexstudent':
        page_title = 'Forex Student FX CRM';
        break;
      case 'forexworldwide':
        page_title = 'Forex WorldWide FX CRM';
        break;
      case 'crocmedia':
        page_title = 'CrocMedia Admin Portal';
        break;
      default:
        page_title = 'Forex WorldWide FX CRM';
        break;
    }
    switch (this.props.app_state.current_brand) {
      case 'gostudy':
        page_title = 'Go Study Administration Portal';
        break;
      case 'dorman':
        page_title = 'Forex Sport - Dorman Admin Portal';
        break;
      case 'crocmedia':
        page_title = 'Forex WorldWide - CrocMedia Admin Portal';
        break;
    }

    const appBarClasses = cx({
      [' ' + classes[color]]: color
    });
    var list = (
      <List className={classes.list}>
        {pagesRoutes.map((prop, key) => {
          if (prop.redirect) {
            return null;
          }
          if (prop.invisible) {
            return null;
          }
          const navLink =
            classes.navLink +
            cx({
              [' ' + classes.navLinkActive]: this.activeRoute(prop.path)
            });
          return (
            <ListItem key={key} className={classes.listItem}>
              <NavLink to={prop.path} className={navLink}>
                <ListItemIcon className={classes.listItemIcon}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText primary={prop.short} disableTypography={true} className={classes.listItemText} />
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    );
    return (
      <AppBar position='static' className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <Hidden smDown>
            <div className={classes.flex}></div>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.flex}>
              <Button href='#' className={classes.title} color='transparent'>
                Forex WorldWide CRM
              </Button>
            </div>
          </Hidden>
          <LanguageSelectionContainer page='yes'></LanguageSelectionContainer>
          <Hidden mdUp>
            <Button
              className={classes.sidebarButton}
              color='transparent'
              justIcon
              aria-label='open drawer'
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Hidden mdUp>
              <Drawer
                variant='temporary'
                anchor={'right'}
                open={this.state.open}
                classes={{
                  paper: classes.drawerPaper
                }}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {list}
              </Drawer>
            </Hidden>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

PagesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger'])
};

export default withStyles(pagesHeaderStyle)(PagesHeader);
