import React, {Fragment} from 'react';
import {connect} from 'react-redux';
// import PropTypes from "prop-types";

// react component for creating dynamic tables
import ReactTable from 'react-table';

// @material-ui/core components
// import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter} from 'react-router-dom';
// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import CustomInput from '../CustomInput/CustomInput';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import {API} from 'aws-amplify';
//import CustomReactSelect from "../Forms/CustomReactSelect/CustomReactSelect";

import NavPills from 'components/NavPills/NavPills.jsx';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import {Select} from '@material-ui/core';

// @material-ui/icons
// import MenuItem from "@material-ui/core/MenuItem";
// import Dvr from "@material-ui/icons/Dvr";
// import Close from "@material-ui/icons/Close";
// import matchSorter from "match-sorter";

// import GroupEdit from "./GroupEdit";

// import {
//   requestEntityList,
//   fetchEntityListNotDeleted,
//   entitiesListIsDirty,
//   setEntityDetailId,
//   selectEntityUi
// } from "../../redux/actions/groups";
import GroupEditClientContainer from './GroupEditClient.jsx';
import {Col, Form, Input, Row, Button, message} from "antd";

// const staffEditStyles = {
//   cardTitle,
//   cardIconTitle: {
//     ...cardTitle,
//     marginTop: "15px",
//     marginBottom: "0px",
//     "& small": {
//       fontSize: "80%",
//       fontWeight: "400"
//     }
//   },
//   cardCategory: {
//     marginTop: "10px",
//     color: "#999999 !important",
//     textAlign: "center"
//   },
//   description: {
//     color: "#999999"
//   },
//   updateProfileButton: {
//     float: "right"
//   },
//   title: {
//     color: "#3C4858",
//     textDecoration: "none"
//   },

//   formCategory: {
//     marginBottom: "0",
//     color: "#999999",
//     fontSize: "14px",
//     padding: "10px 0 10px"
//   },
//   registerButton: {
//     float: "right"
//   }
// };

class GroupEdit extends React.Component {

  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      is_loading: true,
      group_id: '',
      nickname: '',
      description: ''
      // division_id: "",
      // ref_code: "",
      // division_list: [],
      // responsible_staff_member: "",
      // staff_list: []
    };
  }

  componentDidMount() {
    ////Code sample
    // if (this.props.team_id) {
    //   API.get("teams", `/get/id/${this.props.team_id}`)
    //     .then(entity_detail => {
    //       //console.log(entity_detail);
    //       this.setState({
    //         loaded: true,
    //         is_loading: false,
    //         nickname: entity_detail.nickname,
    //         division_id: entity_detail.division_id,
    //         ref_code: entity_detail.ref_code,
    //         responsible_staff_member: entity_detail.responsible_staff_member
    //       });
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // }

    if (this.props.group_id) {
      API.get('groups', `/get/id/${this.props.group_id}`)
        .then(entity_detail => {
          console.log(entity_detail);
          this.setState({
            loaded: true,
            is_loading: false,
            nickname: entity_detail.nickname,
            description: entity_detail.description,
            group_id: entity_detail.id
            // division_id: entity_detail.division_id,
            // ref_code: entity_detail.ref_code,
            // responsible_staff_member: entity_detail.responsible_staff_member
          });
          this.formRef.current.setFieldsValue({ nickname: entity_detail.nickname, description: entity_detail.description });
        })
        .catch(error => {
          console.log(error);
        });
    }

    API.get('groups', `/get-all`)
      .then(response => {
        console.log(response);
        this.setState({
          group_list: response,
          group_all_list: response
        });
      })
      .catch(error => {
        console.log(error);
        // console.log("Error handler start");
        // API.get("groups", `/get-all`).then(response => {
        //   console.log(response);
        //   this.setState({
        //     group_list: response,
        //     group_all_list: response
        //   });
        // });
      });

    // API.get("divisions", `/get-list-not-deleted`)
    //   .then(response => {
    //     this.setState({
    //       division_list: response
    //     });
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });

    // API.get("staff", `/get-list-not-deleted`)
    //   .then(response => {
    //     //console.log(response);
    //     this.setState({
    //       staff_list: response
    //     });
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }

  saveGroup = async (group_detail) => {
    API.put('groups', `/update/id/${this.props.group_id}`, {
      body: group_detail
    }).then(() => {
      message.success("Client Group Detail Updated");
    }).catch(err => {
      message.error("Failed to update client detail. Please try again..");
    });
  }

  getNavPill_Summary = (group_id, classes) => {
    return (
      <>
        <p>
          You can categorise your customers into categories, groups, or tours by creating a "Group".
          <br />
          Here you can view customers, identification and transactions applicable to that group.
        </p>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} lg={12} xl={12} xxl={12}>
            <Form layout={'vertical'} ref={this.formRef} form={this.formRef.current} onFinish={this.saveGroup}>
              <Form.Item name={'nickname'} label={'Nickname'}>
                <Input />
              </Form.Item>
              <Form.Item name={'description'} label={'Description'}>
                <Input.TextArea placeholder={'Enter Description'} />
              </Form.Item>
              <Form.Item>
                <Button type={'primary'} htmlType={'submit'}>Update Group</Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  async handleSubmit() {
    this.setState({isLoading: true});
    try {
      await this.saveGroup({
        nickname: this.state.nickname,
        description: this.state.description
      });
      this.props.history.push(`/groups/list`);
    } catch (e) {
      // alert(e);
      console.log(e);
    }
  }

  handleCancel() {
    this.props.history.push(`/system-settings/groups`);
  }

  getGroupClientsDetails(group_id) {
    try {
      API.get('groups', `/get/id/${group_id}`)
        .then(response => {
          console.log(response);
          console.log(typeof response);
          let groupSQLResult = {};
          groupSQLResult = [response];
          this.setState({group_list: groupSQLResult});
        })
        .catch(error => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  reset = () => {
    try {
      API.get('groups', `/get-all`)
        .then(response => {
          this.setState({group_list: response});
        })
        .catch(error => {
          console.log(error);
          API.get('groups', `/get-all`)
            .then(response => {
              this.setState({group_list: response});
            })
            .catch(error => {
              console.log(error);
            });
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleSelectChange = event => {
    // console.log(event);
    // this.setState({
    //   [event.target.name]: event.target.value
    // });
    if (event.target.value !== 0) {
      this.setState({[event.target.name]: event.target.value});
      this.getGroupClientsDetails(event.target.value);
    }
  };

  renderForm() {
    const {classes, group_id} = this.props;

    // console.log(this.props);
    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <h3>Edit Group Details</h3>
        <Card>
          <NavPills
            color='info'
            tabs={[
              {
                tabButton: 'Summary',
                tabContent: <CardBody>{this.getNavPill_Summary(classes, group_id)}</CardBody>
              },
              {
                tabButton: 'Clients in Group',
                tabContent: (
                  <CardBody>
                    <GroupEditClientContainer group_id={group_id} />
                  </CardBody>
                )
              }
              // {
              //   tabButton: "Identification",
              //   tabContent: (
              //     <CardBody>{this.getNavPill_Identification()}</CardBody>
              //   )
              // },
              // {
              //   tabButton: "Transfer",
              //   tabContent: <CardBody>{this.getNavPill_Transfer()}</CardBody>
              // }
            ]}
          />
        </Card>
      </GridItem>
    );
  }

  render() {
    console.log(this.state);
    return (
      <div>
        <GridContainer>{this.renderForm()}</GridContainer>
      </div>
    );
  }
}

// GroupEdit.propTypes = {
//   classes: PropTypes.object.isRequired,
//   group_id: PropTypes.number
// };

// export default withRouter(withStyles(staffEditStyles)(GroupEdit));

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    groups: state.groups
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // requestEntityList: () => {
    //   dispatch(requestEntityList());
    // },
    // fetchEntityListNotDeleted: () => {
    //   dispatch(fetchEntityListNotDeleted());
    // },
    // entitiesListIsDirty: value => {
    //   dispatch(entitiesListIsDirty(value));
    // },
    // setEntityDetailId: id => {
    //   dispatch(setEntityDetailId(id));
    // },
    // selectEntityUi: value => {
    //   dispatch(selectEntityUi(value));
    // }
  };
};

const GroupEditContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(GroupEdit));

export default GroupEditContainer;
