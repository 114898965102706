import {API} from 'aws-amplify';

// export const CLIENTS_SELECT_STAFF_MEMBER = "STAFF_SELECT_STAFF_MEMBER";
export const BENEFICIARIES_SELECT_SET_ID = 'BENEFICIARIES_SELECT_SET_ID';
export const IDENTIFICATION_SELECT_UI = 'IDENTIFICATION_SELECT_UI';
export const IDENTIFICATION_UPDATE_NEW_IDENTIFICATION_CREATION = 'IDENTIFICATION_UPDATE_NEW_IDENTIFICATION_CREATION';
export const IDENTIFICATION_FETCH_IDENTIFICATION_REQUEST = 'IDENTIFICATION_FETCH_IDENTIFICATION_REQUEST';
export const IDENTIFICATION_FETCH_IDENTIFICATION_SUCCESS = 'IDENTIFICATION_FETCH_IDENTIFICATION_SUCCESS';
export const IDENTIFICATION_FETCH_BENEFICIARY_LIST_REQUEST = 'IDENTIFICATION_FETCH_BENEFICIARY_LIST_REQUEST';
export const IDENTIFICATION_FETCH_IDENTIFICATION_LIST_SUCCESS = 'IDENTIFICATION_FETCH_IDENTIFICATION_LIST_SUCCESS';
export const IDENTIFICATION_START_NEW_IDENTIFICATION_CREATION = 'IDENTIFICATION_START_NEW_IDENTIFICATION_CREATION';
export const BENEFICIARIES_UPDATE_NEW_BENEFICIARY_CREATION = 'BENEFICIARIES_UPDATE_NEW_BENEFICIARY_CREATION';
export const BENEFICIARIES_NEW_BENEFICIARY_DATABASE_CREATE_REQUEST =
  'BENEFICIARIES_NEW_BENEFICIARY_DATABASE_CREATE_REQUEST';
export const IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_START =
  'IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_START';
export const IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_SUCCESS =
  'IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_SUCCESS';

export function fetchIdentificationListRequest() {
  return {
    type: IDENTIFICATION_FETCH_BENEFICIARY_LIST_REQUEST
  };
}

export function fetchIdentificationListSuccess(data) {
  return {
    type: IDENTIFICATION_FETCH_IDENTIFICATION_LIST_SUCCESS,
    identification_list: data,
    receivedAt: Date.now()
  };
}

export function fetchIdentificationListAll() {
  return function (dispatch) {
    dispatch(fetchIdentificationListRequest());
    //API.get("beneficiaries", `/get-all`)
    API.get('identification', `/get/all`)
      .then(response => {
        // console.log(response);
        dispatch(fetchIdentificationListSuccess(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchIdentificationList(client_id) {
  if (client_id) {
    return function (dispatch) {
      dispatch(fetchIdentificationListRequest());
      API.get('identification', `/get/client_id/${client_id}`)
        .then(response => {
          // console.log(response);
          dispatch(fetchIdentificationListSuccess(response));
        })
        .catch(error => {
          console.log(error);
        });
    };
  }
}

//
// export function fetchClientDetails(aws_cognito_id) {
//   return function(dispatch) {
//     // console.log(aws_cognito_id)
//     dispatch(loadClientDetailsRequest());
//     API.get("clients", `/get/${aws_cognito_id}`)
//       .then(response => {
//         // console.log(response);
//         dispatch(loadClientDetailsSuccess(response));
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// }

export async function createNewIdentificationRecordInDatabase(data) {
  // console.log(data)
  let database_result = API.post('identification', `/create/in-database`, {
    body: {
      ...data
    }
  })
    .then(database_result => {
      // console.log(database_result)

      API.post('email', `/interaction/main`, {
        body: {
          interaction: 'Identification document uploaded',
          data: data,
          result: database_result.insertId
        }
      })
        .then(response => {
          // console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
      API.post('email', `/client/identification-document-uploaded`, {
        body: {
          data: data
        }
      })
        .then(response => {
          //console.log(response);
        })
        .catch(error => {
          console.log(error);
        });

      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
  return database_result;
}

export function createNewIdentificationRecord(data) {
  // console.log(data);
  return async function (dispatch) {
    dispatch(newIdentificationRecordCreationDatabaseStart());

    try {
      let database_result = await createNewIdentificationRecordInDatabase(data);
      // console.log(database_result)
      dispatch(newIdentificationRecordCreationDatabaseSuccess(database_result.insertId));
      // dispatch(insertIntoLagacyCRM(database_result, data));
      return database_result;
    } catch (e) {
      console.log(e);
    }
  };
}

// export function fetchStaffList() {
//   return function(dispatch) {
//     dispatch(requestStaffListRequest);
//
//     API.get("crm", `/staff/get-list`)
//       .then(response => {
//         console.log(response);
//         dispatch(receiveStaffList(response));
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// }

// export function fetchStaffListNotDeleted() {
//   return function(dispatch) {
//     dispatch(requestStaffListRequest);
//
//     API.get("crm", `/staff/get-list-not-deleted`)
//       .then(response => {
//         // console.log(response);
//         dispatch(receiveStaffList(response));
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// }
//

export function selectIdentificationUi(value) {
  return {
    type: IDENTIFICATION_SELECT_UI,
    value: value
  };
}

// async function createNewUserWithAws(username, password) {
//   const newUser = await Auth.signUp({
//     username: username,
//     password: password
//   });
//   return newUser;
// }

// export async function create_new_user_in_database(
//   aws_cognito_id,
//   email,
//   first_name,
//   last_name
// ) {
//   API.post("crm", `/staff/create/in-database`, {
//     body: {
//       email: email,
//       aws_cognito_id: aws_cognito_id,
//       first_name: first_name,
//       last_name: last_name
//     }
//   })
//     .then(database_result => {
//       return database_result;
//     })
//     .catch(error => {
//       console.log(error);
//     });
// }

export function setBeneficiarySelectId(id) {
  return {
    type: BENEFICIARIES_SELECT_SET_ID,
    value: id
  };
}

// export function loadStaffMemberDetails(aws_cognito_id) {
//   return async function(dispatch) {
//     dispatch(loadStaffMemberDetailsRequest(aws_cognito_id));
//
//   };
// }

export function loadIdentificationRecordDetailsRequest() {
  return {
    type: IDENTIFICATION_FETCH_IDENTIFICATION_REQUEST
  };
}

export function loadIdentificationRecordDetailsSuccess() {
  return {
    type: IDENTIFICATION_FETCH_IDENTIFICATION_SUCCESS
  };
}

export function startNewIdentificationRecordCreation() {
  return {
    type: IDENTIFICATION_START_NEW_IDENTIFICATION_CREATION
  };
}

// export function updateNewClientCreationClientType(client_type) {
//   return {
//     type: CLIENTS_UPDATE_NEW_CLIENT_CREATION_CLIENT_TYPE,
//     value: client_type
//   };
// }

export function updateNewIdentificationRecordCreation(key, value) {
  // console.log(key, value);
  return {
    type: IDENTIFICATION_UPDATE_NEW_IDENTIFICATION_CREATION,
    value: {key, value}
  };
}

export function newIdentificationRecordCreationDatabaseStart() {
  return {
    type: IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_START
  };
}

export function newIdentificationRecordCreationDatabaseSuccess(id) {
  return {
    type: IDENTIFICATION_NEW_IDENTIFICATION_DATABASE_CREATE_SUCCESS,
    value: id
  };
}

