import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// import StaffEdit from './StaffEdit';
import {
  loadStaffMemberDetailsRequest,
  loadStaffMemberDetailsSuccess,
  createCognitoAccountForStaff
} from '../../redux/actions/staff';


// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {Slider} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LensIcon from '@material-ui/icons/LensOutlined';

// core components
import {Security2} from './Edit/Security2';
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
import Card from '../Card/Card.jsx';
import CardBody from '../Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import NavPills from 'components/NavPills/NavPills.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import {API} from 'aws-amplify';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import {Teams} from './staffEdit/Teams';

var generator = require('generate-password');

const staffEditStyles = {
  ...switchStyles,
  ...selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },
  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {float: 'right'},
  slider: {padding: '22px 0px'},
  thumbIcon: {borderRadius: '50%'},
  thumbIconWrapper: {backgroundColor: '#fff'},
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class StaffEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // register form
      team_list: [],
      division_list: [],
      entity_list: [],
      country_list: [],
      country_list_prio: [],
      id: '',
      loaded: false,
      is_loading: true,
      title: '',
      first_name: '',
      middle_name: '',
      once: false,
      last_name: '',
      email: '',
      password: '',
      password_state: '',
      password_helptext: '',
      password_confirm: '',
      password_confirm_state: '',
      security_entities: [],
      security_entities_forexsport: false,
      security_entities_forexworldwide: false,
      security_entities_forexstudent: false,
      team_id: 0,
      staff_team_security: {},
      backofficeStaff: null,
      security_team_admin: null,
      security_seeTeamClients: null,
      super_admin: null,
      menuShowGroups: null,
      menuShowForwards: null,
      menuShowInvoicing: null,
      menuShowHoldingAcc: null,
      menuShowMultipay: null,
      menuShowReports: null,
      menuShowSettings: null,
      menuSimpleClientList: null,
      menuSimpleTransferList: null,
      security_rateAdjust_show: null,
      security_beneficiary_edit: null,
      security_rateAdjust_client: null,
      security_rateAdjust_staff: null,
      security_rateAdjust_team: null,
      sales_staff: null,
      staff_division_security: {},
      staff_entity_security: {},
      ec_info_dashboard_charts: false,
      multiple_beneficiaries: false,
      default_rate: 0,
      referrer: false
    };
  }

  componentDidMount() {
    if (this.props.staff_id) {
      this.props.loadStaffMemberDetailsRequest();
      API.get('staff', `/get/${this.props.staff_id}`)
        .then(staff_detail => {
          this.setState({
            id: staff_detail.id,
            loaded: true,
            edit_mode: false,
            is_loading: false,
            title: staff_detail.title,
            email: staff_detail.email.toLowerCase(),
            portal_account_created: staff_detail.portal_account_created,
            portal_email_confirmed: staff_detail.portal_email_confirmed,
            portal_account_active: staff_detail.portal_account_active,
            portal_last_login_datetime: staff_detail.portal_last_login_datetime,
            first_name: staff_detail.first_name,
            middle_name: staff_detail.middle_name,
            last_name: staff_detail.last_name,
            residential_street_line_1: staff_detail.residential_street_line_1,
            residential_street_line_2: staff_detail.residential_street_line_2,
            residential_street_suburb: staff_detail.residential_street_suburb,
            residential_street_state: staff_detail.residential_street_state,
            residential_street_postcode: staff_detail.residential_street_postcode,
            residential_street_country: staff_detail.residential_street_country,
            telephone_home: staff_detail.telephone_home,
            telephone_mobile: staff_detail.telephone_mobile,
            telephone_work: staff_detail.telephone_work,
            email_secondary: staff_detail.email_secondary,
            security_entities_forexsport: staff_detail.security_entities_forexsport === 1,
            security_entities_forexworldwide: staff_detail.security_entities_forexworldwide === 1,
            security_entities_forexstudent: staff_detail.security_entities_forexstudent === 1,
            team_id: staff_detail.team_id,
            super_admin: staff_detail.super_admin,
            menuShowGroups: staff_detail.menuShowGroups,
            menuShowForwards: staff_detail.menuShowForwards,
            menuShowInvoicing: staff_detail.menuShowInvoicing,
            menuShowHoldingAcc: staff_detail.menuShowHoldingAcc,
            menuShowMultipay: staff_detail.menuShowMultipay,
            menuShowReports: staff_detail.menuShowReports,
            menuShowSettings: staff_detail.menuShowSettings,
            menuSimpleClientList: staff_detail.menuSimpleClientList,
            menuSimpleTransferList: staff_detail.menuSimpleTransferList,
            security_rateAdjust_show: staff_detail.security_rateAdjust_show,
            security_beneficiary_edit: staff_detail.security_beneficiary_edit,
            security_rateAdjust_client: staff_detail.security_rateAdjust_client,
            security_rateAdjust_staff: staff_detail.security_rateAdjust_staff,
            security_rateAdjust_team: staff_detail.security_rateAdjust_team,
            backofficeStaff: staff_detail.backofficeStaff,
            security_team_admin: staff_detail.security_team_admin,
            security_seeTeamClients: staff_detail.security_seeTeamClients,
            sales_staff: staff_detail.sales_staff,
            default_rate: staff_detail.default_rate,
            referrer: staff_detail.referrer
          });

          if (staff_detail.ec_info_dashboard_charts == 1) {
            this.setState({ec_info_dashboard_charts: true});
          } else {
            this.setState({ec_info_dashboard_charts: false});
          }
          if (staff_detail.multiple_beneficiaries == 1) {
            this.setState({multiple_beneficiaries: true});
          } else {
            this.setState({multiple_beneficiaries: false});
          }

          this.props.loadStaffMemberDetailsSuccess();
          this.getTeamDetails(staff_detail.team_id);
        })
        .catch(error => {
          console.log(error);
        });
      API.get('teams', '/get-list-not-deleted')
        .then(response => {
          // console.log(response);
          this.setState({team_list: response});
        })
        .catch(error => {
          console.log(error);
        });
      API.get('entities', '/get-list-not-deleted')
        .then(response => {
          // console.log(response);
          this.setState({entity_list: response});
        })
        .catch(error => {
          console.log(error);
        });
      API.get('divisions', '/get-list-not-deleted')
        .then(response => {
          // console.log(response);
          this.setState({division_list: response});
        })
        .catch(error => {
          console.log(error);
        });

      API.get('countries', '/list_not_deleted')
        .then(response => {
          this.setState({
            // country_list: response.fullList,
            country_list_prio: response
          });
        })
        .catch(error => {
          console.log(error);
        });

      API.get('staff', `/get/security/${this.props.staff_id}`)
        .then(response => {
          const team_length = response.team_access.length;
          const division_length = response.division_access.length;
          const entity_length = response.entity_access.length;
          const staff_team_security = {};
          const staff_division_security = {};
          const staff_entity_security = {};
          for (let i = 0; i < team_length; i++) {
            staff_team_security[response.team_access[i].team_id] = response.team_access[i].access;
          }
          for (let i = 0; i < division_length; i++) {
            staff_division_security[response.division_access[i].division_id] = response.division_access[i].access;
          }
          for (let i = 0; i < entity_length; i++) {
            staff_entity_security[response.entity_access[i].entity_id] = response.entity_access[i].access;
          }

          this.setState({
            staff_team_security: staff_team_security,
            staff_division_security: staff_division_security,
            staff_entity_security: staff_entity_security
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  async saveStaffMember(staff_detail) {
    return API.put('staff', `/update/id/${this.props.staff_id}`, {body: staff_detail});
  }

  async saveStaffSecurity(team, division, entity) {
    return API.put('staff', `/update/security/${this.props.staff_id}`, {
      body: {
        team,
        division,
        entity
      }
    });
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  lessThen(value, length) {
    if (value <= length) {
      return true;
    }
    return false;
  }

  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }

  handleToggle = name => event => {
    this.setState({[name]: event.target.checked});
  };

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'lessThen':
        if (this.lessThen(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    if (stateName == 'defaut_rate') {
    } else {
      this.setState({[stateName]: event.target.value});
    }
    // this.props.updateNewClientCreation(stateName, event.target.value)
  }

  handleChange = event => {
    this.setState({[event.target.id]: event.target.value});
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading: true});
    const {
      title,
      first_name,
      middle_name,
      last_name,
      residential_street_line_1,
      residential_street_line_2,
      residential_street_suburb,
      residential_street_state,
      residential_street_postcode,
      residential_street_country,
      telephone_home,
      telephone_mobile,
      telephone_work,
      email_secondary,
      security_entities_forexsport,
      security_entities_forexworldwide,
      security_entities_forexstudent,
      team_id,
      ec_info_dashboard_charts,
      backofficeStaff,
      security_team_admin,
      security_seeTeamClients,
      super_admin,
      menuShowGroups,
      menuShowForwards,
      menuShowInvoicing,
      menuShowMultipay,
      menuShowHoldingAcc,
      menuShowReports,
      menuShowSettings,
      menuSimpleClientList,
      menuSimpleTransferList,
      security_rateAdjust_show,
      security_beneficiary_edit,
      security_rateAdjust_client,
      security_rateAdjust_staff,
      security_rateAdjust_team,
      multiple_beneficiaries,
        sales_staff,
      default_rate,
      referrer
    } = this.state;
    try {
      let email_secondary_lowercase;
      if (email_secondary !== null) {
        email_secondary_lowercase = email_secondary.toLowerCase();
      }

      await this.saveStaffMember({
        title: title,
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        residential_street_line_1: residential_street_line_1,
        residential_street_line_2: residential_street_line_2,
        residential_street_suburb: residential_street_suburb,
        backofficeStaff: backofficeStaff,
        security_team_admin: security_team_admin,
        security_seeTeamClients: security_seeTeamClients,
        sales_staff: sales_staff,
        super_admin: super_admin,
        menuShowGroups: menuShowGroups,
        menuShowForwards: menuShowForwards,
        menuShowInvoicing: menuShowInvoicing,
        menuShowHoldingAcc: menuShowHoldingAcc,
        menuShowMultiPay: menuShowMultipay,
        menuShowReports: menuShowReports,
        menuShowSettings: menuShowSettings,
        menuSimpleClientList: menuSimpleClientList,
        menuSimpleTransferList: menuSimpleTransferList,
        security_rateAdjust_show: security_rateAdjust_show,
        security_beneficiary_edit: security_beneficiary_edit,
        security_rateAdjust_client: security_rateAdjust_client,
        security_rateAdjust_staff: security_rateAdjust_staff,
        security_rateAdjust_team: security_rateAdjust_team,
        residential_street_state: residential_street_state,
        residential_street_postcode: residential_street_postcode,
        residential_street_country: residential_street_country,
        telephone_home: telephone_home,
        telephone_mobile: telephone_mobile,
        telephone_work: telephone_work,
        email_secondary: email_secondary_lowercase,
        security_entities_forexsport: security_entities_forexsport,
        security_entities_forexworldwide: security_entities_forexworldwide,
        security_entities_forexstudent: security_entities_forexstudent,
        team_id: team_id,
        ec_info_dashboard_charts: ec_info_dashboard_charts,
        default_rate: default_rate,
        multiple_beneficiaries: multiple_beneficiaries,
        referrer: referrer
      });
    } catch (e) {
      alert(e);
      // this.setState({ isLoading: false });
    }
    // this.props.reload_user(this.state.aws_cognito_id);
  };

  handleSubmitSecurity = async event => {
    event.preventDefault();
    const {staff_team_security, staff_division_security, staff_entity_security, portal_account_active} = this.state;
    try {
      const database_result = await this.saveStaffSecurity(
        staff_team_security,
        staff_division_security,
        staff_entity_security
      );
      const database_portal_account_active = await this.saveStaffMember({portal_account_active: portal_account_active});
      // console.log(database_result);
      // console.log(database_portal_account_active);
    } catch (e) {
      alert(e);
      // this.setState({ isLoading: false });
    }
  };

  handleCreateStaffCrmAccount = () => {

    var password = generator.generate({
      length: 8,
      numbers: true,
      symbols: true,
      strict: true
    });


    this.props.createCognitoAccountForStaff({
      id: this.state.id,
      email: this.state.email,
      password: password
    });

    this.setState({portal_account_created: 1});
  };

  handleSelectChange = event => {
    this.setState({[event.target.name]: event.target.value});
    if (event.target.name === 'team_id') {
      this.getTeamDetails(event.target.value);
    }
  };

  handleSliderChange = (category, item, value) => {
    // console.log(category, item, value);
    switch (category) {
      case 'team':
        this.setState({
          staff_team_security: {
            ...this.state.staff_team_security,
            [item]: value
          }
        });
        break;
      case 'division':
        this.setState({
          staff_division_security: {
            ...this.state.staff_division_security,
            [item]: value
          }
        });
        break;
      case 'entity':
        this.setState({
          staff_entity_security: {
            ...this.state.staff_entity_security,
            [item]: value
          }
        });
        break;
      default:
        return;
    }
  };

  toggleEdit = () => {
    const edit_mode = !this.state.edit_mode;
    this.setState({edit_mode: edit_mode});
  };

  togglePortalAccountActive = () => {
    const portal_account_active = !this.state.portal_account_active;
    this.setState({portal_account_active: portal_account_active});
  };

  getTeamDetails(team_id) {
    API.get('teams', `/get/div-entity/${team_id}`)
      .then(response => {
        // console.log(response);
        this.setState({
          division_nickname: response.division_nickname,
          entity_nickname: response.entity_nickname,
          team_nickname: response.team_nickname
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  getCrmPortalCardContent() {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    };
    const portal_last_login = this.state.portal_last_login_datetime
      ? new Date(this.state.portal_last_login_datetime).toLocaleString('en-AU', options)
      : 'N/A';

    if (!this.state.portal_account_created) {
      return (
        <React.Fragment>
          <p>
            {this.state.first_name} <strong>DOES NOT HAVE</strong> a CRM login.
          </p>
          <p>
            Clicking the button below will initiate the account creation procedure. This will send an email to{' '}
            {this.state.email} advising them of account opening and requesting email confirmation.
          </p>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText='Username (primary email)'
                id='email'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  disabled: true,
                  value: this.state.email || '',
                  onChange: event => {
                    this.handleChange(event);
                  }
                }}
              />
              {/* <CustomInput
                success={this.state.password_state === 'success'}
                error={this.state.password_state === 'error'}
                labelText='Password *'
                id='password'
                helpText={this.state.password_helptext}
                formControlProps={{fullWidth: true}}
                inputProps={{
                  disabled: !this.state.edit_mode,
                  value: this.state.password,
                  //event => this.change(event, "password", "password"),
                  onChange: event => this.verifyPassword(event, 'password', 'password'),
                  type: 'password'
                }}
              />
              <CustomInput
                success={this.state.password_confirm_state === 'success'}
                error={this.state.password_confirm_state === 'error'}
                labelText='Confirm Password *'
                id='password_confirm'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  disabled: !this.state.edit_mode,
                  value: this.state.password_confirm,
                  onChange: event => this.change(event, 'password_confirm', 'equalTo', 'password'),
                  type: 'password'
                }}
              /> */}
            </GridItem>
          </GridContainer>
          <Button
            color='primary'
            onClick={() => this.handleCreateStaffCrmAccount()}
            // disabled={!(this.state.password_state === 'success' && this.state.password_confirm_state === 'success')}
            // type="submit"
            // className={classes.updateProfileButton}
          >
            Create CRM account
          </Button>
        </React.Fragment>
      );
    }

    if (!this.state.portal_email_confirmed) {
      return (
        <React.Fragment>
          <p>
            {this.state.first_name}
            's CRM login <strong>HAS NOT </strong> been confirmed.
          </p>
          <p>
            An email has been sent to {this.state.email} with a confirmation code. The user should visit
            https://crm.forexworldwide.com and verify their account.
          </p>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <ul>
          <li>
            {this.state.first_name} has a CRM registration [{this.state.email}]
          </li>
          <li>
            {this.state.first_name}
            's CRM registration has been confirmed
          </li>
          <li>
            {this.state.first_name} last CRM login: {portal_last_login}
          </li>
        </ul>
        {/* <Button
        // color="primary"
        // type="submit"
        // className={classes.updateProfileButton}
        >
          Login to Client Portal
        </Button>
        <Button
        // color="primary"
        // type="submit"
        // className={classes.updateProfileButton}
        >
          Change user password
        </Button> */}
      </React.Fragment>
    );
  }

  getCrmClassificationCardContent() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <FormControl fullWidth error={!this.state.team_id > 0} className={classes.selectFormControl}>
              <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                Team
              </InputLabel>
              <Select
                MenuProps={{className: classes.selectMenu}}
                classes={{select: classes.select}}
                value={this.state.team_id}
                onChange={this.handleSelectChange}
                disabled={!this.props.app_state.current_staff.super_admin}
                inputProps={{
                  disabled: !this.state.edit_mode || !this.props.app_state.current_staff.super_admin,
                  name: 'team_id',
                  id: 'team-id'
                }}
              >
                <MenuItem key='0x0' disabled classes={{root: classes.selectMenuItem}}>
                  Select staff member
                </MenuItem>
                {this.state.team_list
                  .sort((a, b) => a.nickname.localeCompare(b.nickname))
                  .map(item => {
                    return (
                      <MenuItem
                        key={item.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={item.id}
                      >
                        {item.nickname.toUpperCase()}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </GridItem>
          {this.state.team_id > 0 && (
            <GridItem xs={12} sm={10}>
              <Card>
                <CardBody>
                  <p>This staff member will be allocated to Team {this.state.team_nickname}.</p>
                  <p>The {this.state.team_nickname} Team is a member of:</p>
                  <ul>
                    <li>Division: {this.state.division_nickname}</li>
                    <li>Entity: {this.state.entity_nickname}</li>
                  </ul>
                </CardBody>
              </Card>
            </GridItem>
          )}
        </GridContainer>
      </React.Fragment>
    );
  }

  getSecurityPill() {
    const {classes} = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <p>Please note that you can only set the security level to a maximum of one lower than your own level.</p>
          <p>You also have no access to alter these if your security level is below 7.</p>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h5>Teams</h5>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{width: '90%'}}>
                {this.state.team_list
                  .sort((a, b) => a.nickname.localeCompare(b.nickname))
                  .map(item => {
                    if (
                      this.props.app_state.current_staff_security.staff_team_security[item.id] > 0 ||
                      this.props.app_state.current_staff.super_admin
                    ) {
                      return (
                        <React.Fragment key={`team_${item.id}`}>
                          <Typography>
                            {item.nickname} [
                            {this.state.staff_team_security[item.id] === 0 || !this.state.staff_team_security[item.id]
                              ? 'NO ACCESS'
                              : this.state.staff_team_security[item.id]}
                            ]
                          </Typography>
                          <Slider
                            disabled={this.props.app_state.current_staff_security.staff_team_security[item.id] < 7}
                            classes={{
                              container: classes.slider,
                              thumbIconWrapper: classes.thumbIconWrapper
                            }}
                            thumb={<LensIcon style={{color: '#2196f3'}} />}
                            value={this.state.staff_team_security[item.id] || 0}
                            name={`slider_team_${item.id}`}
                            min={0}
                            max={
                              this.props.app_state.current_staff.super_admin === 1
                                ? 9
                                : this.props.app_state.current_staff_security.staff_team_security[item.id] - 1
                            }
                            step={1}
                            onChange={(event, value) => this.handleSliderChange('team', item.id, value)}
                          />
                        </React.Fragment>
                      );
                    }
                  })}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {this.props.app_state.current_staff.super_admin === 1 && (
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h5>Divisions</h5>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{width: '90%'}}>
                {this.state.division_list
                  .sort((a, b) => a.nickname.localeCompare(b.nickname))
                  .map(item => {
                    if (this.props.app_state.current_staff_security.staff_division_security[item.id] > 0) {
                      return (
                        <React.Fragment key={`division_${item.id}`}>
                          <Typography>
                            {item.nickname} [
                            {this.state.staff_division_security[item.id] === 0 ||
                            !this.state.staff_division_security[item.id]
                              ? 'NO ACCESS'
                              : this.state.staff_division_security[item.id]}
                            ]
                          </Typography>
                          <Slider
                            disabled={this.props.app_state.current_staff_security.staff_division_security[item.id] < 7}
                            classes={{
                              container: classes.slider,
                              thumbIconWrapper: classes.thumbIconWrapper
                            }}
                            thumb={<LensIcon style={{color: '#2196f3'}} />}
                            value={this.state.staff_division_security[item.id] || 0}
                            name={`slider_division_${item.id}`}
                            min={0}
                            max={
                              this.props.app_state.current_staff.super_admin === 1
                                ? 9
                                : this.props.app_state.current_staff_security.staff_division_security[item.id] - 1
                            }
                            step={1}
                            onChange={(event, value) => this.handleSliderChange('division', item.id, value)}
                          />
                        </React.Fragment>
                      );
                    }
                  })}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          )}
          {this.props.app_state.current_staff.super_admin === 1 && (
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h5>Entities</h5>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{width: '90%'}}>
                {this.state.entity_list
                  .sort((a, b) => a.nickname.localeCompare(b.nickname))
                  .map(item => {
                    if (this.props.app_state.current_staff_security.staff_entity_security[item.id] > 0) {
                      return (
                        <React.Fragment key={`entity_${item.id}`}>
                          <Typography>
                            {item.nickname} [
                            {this.state.staff_entity_security[item.id] === 0 ||
                            !this.state.staff_entity_security[item.id]
                              ? 'NO ACCESS'
                              : this.state.staff_entity_security[item.id]}
                            ]
                          </Typography>
                          <Slider
                            disabled={this.props.app_state.current_staff_security.staff_entity_security[item.id] < 7}
                            classes={{
                              container: classes.slider,
                              thumbIconWrapper: classes.thumbIconWrapper
                            }}
                            thumb={<LensIcon style={{color: '#2196f3'}} />}
                            value={this.state.staff_entity_security[item.id] || 0}
                            name={`slider_entity_${item.id}`}
                            min={0}
                            max={
                              this.props.app_state.current_staff.super_admin === 1
                                ? 9
                                : this.props.app_state.current_staff_security.staff_entity_security[item.id] - 1
                            }
                            step={1}
                            onChange={(event, value) => this.handleSliderChange('entity', item.id, value)}
                          />
                        </React.Fragment>
                      );
                    }
                  })}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          )}
        </GridItem>
      </GridContainer>
    );
  }

  // function that returns true if value is valid password, false otherwise
  verifyPassword(event, value, stateName) {
    // var passwordRex = /^(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z])(?=.*\d.*\d)(?=.*\W.*\W)[a-zA-Z0-9\S]{9,}$/;
    // This pattern requires at least two lowercase letters, two uppercase letters, two digits, and two special characters. There must be a minimum of 9 characters total, and no white space characters are allowed.
    // very strong password

    const passwordRex = /^(?=.*[!@#$%^&*()\-_=+`~\[\]{}?|])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/;
    // This pattern requires at least longer than 8 characters and contain at least 1 uppercase & 1 number and 1 special character

    this.setState({[stateName]: event.target.value});
    // console.log(event.target.value);
    if (passwordRex.test(event.target.value)) {
      // console.log("True");
      this.setState({
        password_state: 'success',
        password_helptext: ''
      });
      return true;
    }
    // console.log("False");
    this.setState({
      password_state: 'error',
      password_helptext:
        'Must be longer than 8 characters and contain at least 1 uppercase & 1 number & 1 symbol character'
    });
    return false;
  }

  render() {
    const {classes} = this.props;
    if (!this.props.app_state.current_staff) {
      return <span />;
    }
    // console.log(this.state);
    if (!this.state.loaded) {
      return <span />;
    }
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='rose' icon>
                <div className={classes.flexEnd}>
                  <h4 className={classes.cardIconTitle}>
                    {this.state.edit_mode ? 'Edit Record: ' : 'View Record: '}
                    {this.state.first_name} {this.state.last_name}{' '}
                  </h4>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.edit_mode}
                          onChange={() => this.toggleEdit()}
                          value='edit_mode'
                          disabled={
                            !(
                              this.props.app_state.current_staff.super_admin ||
                              this.props.app_state.current_staff_security.staff_team_security[this.state.team_id] >= 7
                            )
                          }
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked
                            // icon: classes.switchIcon,
                            // iconChecked: classes.switchIconChecked,
                            // bar: classes.switchBar
                          }}
                        />
                      }
                      classes={{label: classes.label}}
                      label={this.state.edit_mode ? 'Edit Mode' : 'Read Only'}
                    />
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <NavPills
                  color='info'
                  tabs={[
                    {
                      tabButton: 'User & Account',
                      tabContent: (
                        <div style={{padding: 20}}>
                          <form onSubmit={this.handleSubmit}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <Card style={{height: '90%'}}>
                                  <CardHeader>
                                    <h4 className={classes.cardIconTitle}>User Information</h4>
                                  </CardHeader>
                                  <CardBody>
                                    <CustomInput
                                      labelText='Username (primary email)'
                                      id='email'
                                      formControlProps={{fullWidth: true}}
                                      inputProps={{
                                        disabled: true,
                                        value: this.state.email || '',
                                        onChange: event => {
                                          this.handleChange(event);
                                        }
                                      }}
                                    />
                                    <FormControl className={classes.selectFormControl}>
                                      <InputLabel htmlFor='title'>title</InputLabel>
                                      <Select
                                        value={this.state.title}
                                        onChange={this.handleSelectChange}
                                        inputProps={{
                                          disabled: !this.state.edit_mode,
                                          name: 'title',
                                          id: 'title'
                                        }}
                                      >
                                        <MenuItem value='Mr'>Mr</MenuItem>
                                        <MenuItem value='Ms'>Ms</MenuItem>
                                        <MenuItem value='Mrs'>Mrs</MenuItem>
                                      </Select>
                                    </FormControl>

                                    <GridContainer>
                                      <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                          labelText='First Name'
                                          id='first_name'
                                          formControlProps={{fullWidth: true}}
                                          inputProps={{
                                            disabled: !this.state.edit_mode,
                                            value: this.state.first_name || '',
                                            onChange: event => {
                                              this.handleChange(event);
                                            }
                                          }}
                                        />
                                      </GridItem>
                                      <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                          labelText='Middle Name(s)'
                                          id='middle_name'
                                          formControlProps={{fullWidth: true}}
                                          inputProps={{
                                            disabled: !this.state.edit_mode,
                                            value: this.state.middle_name || '',
                                            onChange: event => {
                                              this.handleChange(event);
                                            }
                                          }}
                                        />
                                      </GridItem>
                                      <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                          labelText='Last Name'
                                          id='last_name'
                                          formControlProps={{fullWidth: true}}
                                          inputProps={{
                                            disabled: !this.state.edit_mode,
                                            value: this.state.last_name || '',
                                            onChange: event => {
                                              this.handleChange(event);
                                            }
                                          }}
                                        />
                                      </GridItem>
                                    </GridContainer>
                                  </CardBody>
                                </Card>
                              </GridItem>

                              <GridItem xs={12} sm={12} md={6}>
                                <Card style={{height: '90%'}}>
                                  <CardHeader>
                                    <h4 className={classes.cardIconTitle}>Staff Classification</h4>
                                  </CardHeader>
                                  <CardBody>{this.getCrmClassificationCardContent()}</CardBody>
                                </Card>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                  <CardHeader color='rose' icon>
                                    <h4 className={classes.cardIconTitle}>CRM Account</h4>
                                  </CardHeader>
                                  <CardBody>{this.getCrmPortalCardContent()}</CardBody>
                                </Card>
                              </GridItem>
                            </GridContainer>
                            <Button
                              disabled={!this.state.edit_mode}
                              color='primary'
                              type='submit'
                              className={classes.updateProfileButton}
                            >
                              Update User & Account
                            </Button>
                          </form>
                        </div>
                      )
                    },
                    {
                      tabButton: 'Address',
                      tabContent: (
                        <div style={{padding: 20}}>
                          <form onSubmit={this.handleSubmit}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText='Residential Street Address (line 1)'
                                  id='residential_street_line_1'
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    disabled: !this.state.edit_mode,
                                    value: this.state.residential_street_line_1 || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText='Residential Street Address (line 2)'
                                  id='residential_street_line_2'
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    disabled: !this.state.edit_mode,
                                    value: this.state.residential_street_line_2 || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText='Suburb'
                                  id='residential_street_suburb'
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    disabled: !this.state.edit_mode,
                                    value: this.state.residential_street_suburb || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText='State'
                                  id='residential_street_state'
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    disabled: !this.state.edit_mode,
                                    value: this.state.residential_street_state || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText='Postcode'
                                  id='residential_street_postcode'
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    disabled: !this.state.edit_mode,
                                    value: this.state.residential_street_postcode || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <FormControl
                                  fullWidth
                                  error={!this.state.residential_street_country > 0}
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                                    Country
                                  </InputLabel>
                                  <Select
                                    MenuProps={{className: classes.selectMenu}}
                                    classes={{select: classes.select}}
                                    value={this.state.residential_street_country || '0'}
                                    onChange={this.handleSelectChange}
                                    inputProps={{
                                      disabled: !this.state.edit_mode,
                                      name: 'residential_street_country',
                                      id: 'residential_street_country'
                                    }}
                                  >
                                    <MenuItem key='0x0' value='0' disabled classes={{root: classes.selectMenuItem}}>
                                      Select Country
                                    </MenuItem>

                                    {this.state.country_list_prio
                                      .sort((a, b) => a.list_priority < b.list_priority)
                                      .map(item => {
                                        return (
                                          <MenuItem
                                            key={item.id}
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected
                                            }}
                                            value={item.id}
                                          >
                                            {item.full_name}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </FormControl>
                              </GridItem>
                            </GridContainer>

                            <Button
                              disabled={!this.state.edit_mode}
                              color='primary'
                              type='submit'
                              className={classes.updateProfileButton}
                            >
                              Update Address
                            </Button>
                          </form>
                        </div>
                      )
                    },
                    {
                      tabButton: 'Contact',
                      tabContent: (
                        <div style={{padding: 20}}>
                          <form onSubmit={this.handleSubmit}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText='Home Telephone'
                                  id='telephone_home'
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    disabled: !this.state.edit_mode,
                                    value: this.state.telephone_home || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText='Work Telephone'
                                  id='telephone_work'
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    disabled: !this.state.edit_mode,
                                    value: this.state.telephone_work || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText='Mobile Telephone'
                                  id='telephone_mobile'
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    disabled: !this.state.edit_mode,
                                    value: this.state.telephone_mobile || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText='Primary Email'
                                  id='email'
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    disabled: true,
                                    value: this.state.email || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText='Secondary Email'
                                  id='email_secondary'
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    disabled: !this.state.edit_mode,
                                    value: this.state.email_secondary || '',
                                    onChange: event => {
                                      this.handleChange(event);
                                    }
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <Button
                              disabled={!this.state.edit_mode}
                              color='primary'
                              type='submit'
                              className={classes.updateProfileButton}
                            >
                              Update Contact
                            </Button>
                          </form>
                        </div>
                      )
                    },

                    {
                      tabButton: 'Security',
                      tabContent: (
                        <div style={{padding: 20}}>
                          {this.props.app_state.current_staff_super_admin ? (
                            <>
                          <div>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={this.state.portal_account_active}
                                  onChange={() => this.togglePortalAccountActive()}
                                  value='portal_account_active'
                                  disabled={
                                    !(
                                      this.props.app_state.current_staff.super_admin ||
                                      this.props.app_state.current_staff_security.staff_team_security[
                                        this.state.team_id
                                      ] >= 7
                                    ) || !this.state.edit_mode
                                  }
                                  classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked
                                    // icon: classes.switchIcon,
                                    // iconChecked: classes.switchIconChecked,
                                    // bar: classes.switchBar
                                  }}
                                />
                              }
                              classes={{label: classes.label}}
                              label={
                                this.state.portal_account_active ? 'Active Portal Account' : 'Inactive Portal Account'
                              }
                            />
                          </div>
                          <form onSubmit={this.handleSubmitSecurity}>
                            {this.getSecurityPill()}
                            <Button
                              disabled={!this.state.edit_mode}
                              color='primary'
                              type='submit'
                              className={classes.updateProfileButton}
                            >
                              Update Security
                            </Button>
                          </form>
                          </>
                          ) : (
                            <p>Invalid Security Level</p>
                          )}
                        </div>
                      )
                    },
                    {
                      tabButton: 'Security 2',
                      tabContent: (
                        <div style={{padding: 20}}>
                          {this.props.app_state.current_staff_super_admin ? (
                            <Security2
                              classes={classes}
                              handleSubmit={this.handleSubmit.bind(this)}
                              handleToggle={this.handleToggle.bind(this)}
                              state={this.state}
                            />
                          ) : (
                            <p>Invalid Security Level</p>
                          )}
                        </div>
                      )
                    },
                    {
                      tabButton: 'Teams',
                      tabContent: this.props.app_state.current_staff_super_admin ? (
                      <Teams />
                      ): (<p>Invalid Security Level</p>)
                    },
                    this.props.app_state.current_staff_super_admin ? 
                    {
                      tabButton: 'Finance',
                      tabContent: (
                        <div style={{padding: 20}}>
                          <form onSubmit={this.handleSubmit}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <Checkbox
                                  checked={this.state.ec_info_dashboard_charts === true}
                                  value='ec_info_dashboard_charts'
                                  onClick={this.handleToggle('ec_info_dashboard_charts')}
                                  checkedIcon={<Check className={classes.checkedIcon} />}
                                  icon={<Check className={classes.uncheckedIcon} />}
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                  inputProps={{disabled: !this.state.edit_mode}}
                                />
                                Display charts on dashboard
                                <br />
                                <Checkbox
                                  checked={this.state.multiple_beneficiaries === true}
                                  value='multiple_beneficiaries'
                                  onClick={this.handleToggle('multiple_beneficiaries')}
                                  checkedIcon={<Check className={classes.checkedIcon} />}
                                  icon={<Check className={classes.uncheckedIcon} />}
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                  inputProps={{disabled: !this.state.edit_mode}}
                                />
                                Multiple Beneficiaries
                                <br />
                              </GridItem>

                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  success={this.state.default_rate_state === 'success'}
                                  error={this.state.default_rate_state === 'error'}
                                  labelText='Team Ref Code'
                                  id='default_rate'
                                  formControlProps={{fullWidth: true}}
                                  inputProps={{
                                    disabled: !this.state.edit_mode,
                                    value: parseFloat(this.state.default_rate),
                                    onChange: event => this.change(event, 'default_rate', 'lessThen', 1),
                                    type: 'number'
                                  }}
                                />
                                {/* <Checkbox
                                  checked={this.state.default_rate === true}
                                  value="default_rate"
                                  onClick={this.handleToggle('default_rate')}
                                  checkedIcon={<Check className={classes.checkedIcon} />}
                                  icon={<Check className={classes.uncheckedIcon} />}
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                  inputProps={{disabled: !this.state.edit_mode}}
                                />
                                Default Rate */}
                              </GridItem>
                            </GridContainer>

                            <Button
                              disabled={!this.state.edit_mode}
                              color='primary'
                              type='submit'
                              className={classes.updateProfileButton}
                            >
                              Update Finance
                            </Button>
                          </form>
                        </div>
                      )
                    } : {}
                  ]}
                />
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// StaffEdit.propTypes = {classes: PropTypes.object.isRequired};
// export default withStyles(staffEditStyles)(StaffEdit);

StaffEdit = withStyles(staffEditStyles)(StaffEdit);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    staff: state.staff
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadStaffMemberDetailsRequest: () => {
      // console.log(data)
      dispatch(loadStaffMemberDetailsRequest());
    },
    loadStaffMemberDetailsSuccess: () => {
      // console.log(data)
      dispatch(loadStaffMemberDetailsSuccess());
    },
    createCognitoAccountForStaff: (id, username, password) => {
      // console.log(data)
      dispatch(createCognitoAccountForStaff(id, username, password));
    }
  };
};

const StaffEditContainer = connect(mapStateToProps, mapDispatchToProps)(StaffEdit);

export default StaffEditContainer;
