import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

// import blankPageStyle from "./BlankPageStyles";
import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import Button from '../../../components/CustomButtons/Button';
import HoldingAccountLineItemNewContainer from '../../../components/HoldingAccounts/HoldingAccountLineItemNew';

import {FormattedMessage} from 'react-intl';
import {Col, Row} from 'antd';

const idententificationListStyle = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - ClientsList
 * ----------------------------------------------------------------------------
 */
class HoldingAccountLineItemNewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // const { fetchBeneficiaryList } = this.props
    // console.log(this.props);
    // fetchBeneficiaryList();
  }

  render() {
    // console.log(this.props);
    const {classes} = this.props;
    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>
                {/* <FormattedMessage
                id="identificationList.cardItem1.identificationListing"
                defaultMessage={
                  `Identification Management`}
                /> */}
                Holding Account Detail
              </h4>
              <div>
                <Button round color='primary' onClick={() => this.props.history.push('/holding-accounts/clients/list')}>
                  {/* <FormattedMessage
                  id="newIdentification.cardItem1.showAll"
                  defaultMessage={
                    `Show All`}
                  /> */}
                  Show Accounts
                </Button>
                <Button
                  round
                  color='primary'
                  onClick={() =>
                    this.props.history.push(`/holding-accounts/view/${this.props.match.params.holding_account_id}`)
                  }
                >
                  {/* <FormattedMessage
                  id="newIdentification.cardItem1.showAll"
                  defaultMessage={
                    `Show All`}
                  /> */}
                  Show Transactions
                </Button>
                <Button
                  round
                  color='info'
                  onClick={() => {
                      const holding_detail_id = this.props.match.params.holding_detail_id;
                      let url = `/holding-accounts/new/debit/${this.props.match.params.holding_account_id}`;
                      if (holding_detail_id != null && holding_detail_id !== 0 ) {
                        url = `/holding-accounts/edit/${holding_detail_id}/debit/${this.props.match.params.holding_account_id}`;
                      }
                      this.props.history.push(url);
                    }
                  }
                >
                  {/* <FormattedMessage
                  id="newIdentification.cardItem1.createNew"
                  defaultMessage={
                    `Create New`}
                  /> */}
                  Debit (Withdrawal)
                </Button>
                <Button
                  round
                  color='info'
                  onClick={() => {
                      const holding_detail_id = this.props.match.params.holding_detail_id;
                      let url = `/holding-accounts/new/credit/${this.props.match.params.holding_account_id}`;
                      if (holding_detail_id != null && holding_detail_id !== 0 ) {
                          url = `/holding-accounts/edit/${holding_detail_id}/credit/${this.props.match.params.holding_account_id}`;
                      }
                      this.props.history.push(url);
                  }
                  }
                >
                  {/* <FormattedMessage
                  id="newIdentification.cardItem1.refresh"
                  defaultMessage={
                    `Refresh`}
                  /> */}
                  Credit (Deposit)
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col span={24}>
                <HoldingAccountLineItemNewContainer
                  holding_detail_id={this.props.match.params.holding_detail_id}
                  holding_account_id={this.props.match.params.holding_account_id}
                  type={this.props.match.params.type}
                />
                {/* <HoldingAccountLineItemListContainer
                holding_account_id = {this.props.match.params.holding_account_id}
                /> */}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

// IdentificationList.propTypes = {
//   classes: PropTypes.object.isRequired
// };

export default withStyles(idententificationListStyle)(HoldingAccountLineItemNewPage);
