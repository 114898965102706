import {connect} from 'react-redux';
import EntityEdit from './EntityEdit';
import {loadEntityDetailsRequest, loadEntityDetailsSuccess, selectEntityUi} from '../../redux/actions/entities';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    entities: state.entities
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadEntityDetailsRequest: () => {
      dispatch(loadEntityDetailsRequest());
    },
    loadEntityDetailsSuccess: () => {
      dispatch(loadEntityDetailsSuccess());
    },
    selectEntityUi: value => {
      dispatch(selectEntityUi(value));
    }
  };
};

const EntityEditContainer = connect(mapStateToProps, mapDispatchToProps)(EntityEdit);

export default EntityEditContainer;
