import React from 'react';
import {API} from 'aws-amplify';
import {connect} from 'react-redux';
// import Step3Confirm from "./Step3Confirm";

import {updateNewTransferCreation} from 'redux/actions/transfers_2';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Table from 'components/Table/Table.jsx';

import {Checkbox, Divider} from 'antd';

import {cardTitle} from 'assets/jss/material-dashboard-pro-react';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';

import {defaultFont, primaryColor, dangerColor, tooltip} from 'assets/jss/material-dashboard-pro-react.jsx';

var moment = require('moment');

const style = {
  ...customCheckboxRadioSwitch,
  ...extendedTablesStyle,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  },

  table: {
    marginBottom: '0'
  },
  tableRow: {
    position: 'relative',
    borderBottom: '1px solid #dddddd'
  },
  tableActions: {
    border: 'none',
    padding: '12px 8px !important',
    verticalAlign: 'middle'
  },
  tableCell: {
    ...defaultFont,
    padding: '0',
    verticalAlign: 'middle',
    border: 'none',
    lineHeight: '1.42857143',
    fontSize: '14px',
    width: '80%'
  },
  tableActionButton: {
    width: '27px',
    height: '27px',
    padding: '0'
  },
  tableActionButtonIcon: {
    width: '17px',
    height: '17px'
  },
  edit: {
    backgroundColor: 'transparent',
    color: primaryColor,
    boxShadow: 'none'
  },
  close: {
    backgroundColor: 'transparent',
    color: dangerColor,
    boxShadow: 'none'
  },
  tooltip
};

class Step4Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nickname: `FX Payment ${moment().format('Do MMMM YYYY')}`,
      nickname_state: 'success',
      checked_client: true,
      checked_paying_to: false,
      checked_paying_from: false,
      checked_amount: false,
      purpose_list: [],
      remittingAccountDetails: "No remitting account has been automatically selected for this transfer.",
      remittingAccountEmailDisabled: true
    };
  }
  componentDidMount() {
    API.post('transfers', `/remitting-account/get-details`, {
      body: {
        clientID: this.props.transfers_2.new_transfer_data.client_id,
        currencyID: this.props.transfers_2.new_transfer_data.currency_from_id,
        amountFrom: this.props.transfers_2.new_transfer_data.amount_from
      }
    })
    .then(response => {
      console.log(response);
      if (response.remittingAccountID) {
        this.setState({
          remittingAccountDetails: `A remitting account has been automatically selected for this transfer: ${response.remittingAccountDetails.nicknameInternal}`,
          remittingAccountEmailDisabled: false
        })
      } else {
        this.setState({
          remittingAccountDetails: "No remitting account has been automatically selected for this transfer.",
          remittingAccountEmailDisabled: true
        })
      }
    })
    .catch(error => {
      console.log(error);
    });

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevProps.transfers_2.new_transfer_data.amount_from !== this.props.transfers_2.new_transfer_data.amount_from) ||
        (prevProps.transfers_2.new_transfer_data.currency_from_id !== this.props.transfers_2.new_transfer_data.currency_from_id)) {
      
          API.post('transfers', `/remitting-account/get-details`, {
            body: {
              clientID: this.props.transfers_2.new_transfer_data.client_id,
              currencyID: this.props.transfers_2.new_transfer_data.currency_from_id,
              amountFrom: this.props.transfers_2.new_transfer_data.amount_from
            }
          })
          .then(response => {
            console.log(response);
            if (response.remittingAccountID) {
              this.setState({
                remittingAccountDetails: `A remitting account has been automatically selected for this transfer: ${response.remittingAccountDetails.nicknameInternal}`,
                remittingAccountEmailDisabled: false
              })
            } else {
              this.setState({
                remittingAccountDetails: "No remitting account has been automatically selected for this transfer.",
                remittingAccountEmailDisabled: true
              })
            }
          })
          .catch(error => {
            console.log(error);
          });
  
      }
    }


  sendState() {
    return this.state;
  }

  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
    this.props.updateNewTransferCreation(stateName, event.target.value);
  }
  // handleChange = event => {
  //   this.setState({ selectedValue: event.target.value });
  // };
  // handleToggle = value => () => {
  //   const current_state = this.state[value];

  //   this.props.updateNewTransferCreation(value, !current_state)
  // };
  isValidated() {
    if (this.state.nickname_state !== 'success') {
      this.setState({nickname_state: 'error'});
    }
    return (
      this.state.nickname_state === 'success'
      // this.props.payments.new_payment_data &&
      // this.props.payments.new_payment_data.checked_paying_to &&
      // //this.props.payments.new_payment_data.checked_paying_from &&
      // this.props.payments.new_payment_data.checked_amount
    );
  }
  // isValidatedForWarningMessage() {
  //   return (
  //     // this.state.nickname_state === "success" &&
  //     //this.state.checked_client &&
  //     this.state.checked_paying_to &&
  //     //this.state.checked_paying_from &&
  //     this.state.checked_amount
  //   );
  // }

  getClientPortalCardContent() {
    if (this.props.clients.new_client_created) {
      return (
        <React.Fragment>
          <p>Client record has been created...</p>
        </React.Fragment>
      );
    }
    if (this.props.clients.new_client_submitted) {
      return (
        <React.Fragment>
          <p>Client record is being created...</p>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <p>
          Please press the <strong>Finish</strong> button below to create this client record.
        </p>
      </React.Fragment>
    );
  }

  toggleRemitEmailCheck = () => {
    const currentStatus = this.props.transfers_2.new_transfer_data.sendRemitEmail || false;
    if (currentStatus) {
      this.props.updateNewTransferCreation('sendRemitEmail', false)
    } else {
      this.props.updateNewTransferCreation('sendRemitEmail', true)
    }
  }

  render() {
    const {classes} = this.props;
    if (!this.props.transfers_2.new_transfer_data) {
      return null;
    }

    if (!this.props.transfers_2.new_transfer_data.multiple_beneficiary_id) {
      return null;
    }

    // if (
    //   !this.props.transfers_2.new_transfer_data.purpose_of_payment_detail_text
    // ) {
    //   return null;
    // }

    let client = this.props.transfers_2.new_transfer_data.client || {};
    let currency_from = this.props.transfers_2.new_transfer_data.currency_from || {};
    let currency_to = this.props.transfers_2.new_transfer_data.currency_to || {};
    let current_rate = Number.parseFloat(this.props.transfers_2.new_transfer_data.current_rate).toFixed(4);
    let amount_from = Number.parseFloat(this.props.transfers_2.new_transfer_data.amount_from);
    let amount_to = Number.parseFloat(this.props.transfers_2.new_transfer_data.amount_to);

    let beneficiaryTable = [];
    // console.log(beneficiaryTable)
    if (this.props.transfers_2.new_transfer_data.send_to_holding_acc_checkbox) {
      beneficiaryTable.push(['Beneficiary', `Client Holding Account`]);
      beneficiaryTable.push(['Currency', currency_to.iso_alpha_3]);
      beneficiaryTable.push(['Holding Account ID', this.props.transfers_2.new_transfer_data.ledgerTo]);
      // console.log(beneficiaryTable)
    } else {
      if (this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary) {
        beneficiaryTable.push([
          'Beneficiary',
          this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary.ben_legal_name
        ]);
        beneficiaryTable.push([
          'SWIFT Code',
          this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary.swift_code
        ]);
        beneficiaryTable.push([
          'Account',
          this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary.account_number
        ]);
        beneficiaryTable.push([
          'BSB',
          this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary.bsb_code
        ]);
        beneficiaryTable.push([
          'Sort Code',
          this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary.sort_code
        ]);
        beneficiaryTable.push([
          'ABA Routing',
          this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary.aba_routing_number
        ]);
        beneficiaryTable.push([
          'IBAN',
          this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary.iban
        ]);

      }
      // console.log(beneficiaryTable)
    }

    // console.log(beneficiaryTable)
    let payee = {};
    if (this.props.transfers_2.new_transfer_data.payee_different === '0') {
      payee.nickname = this.props.app_state.current_client ? this.props.app_state.current_client.nickname : '';
    } else {
      payee.nickname = `${this.props.transfers_2.new_transfer_data.payee_first_name || ''} ${
        this.props.transfers_2.new_transfer_data.payee_last_name || ''
      }`;
    }

    return (
      <React.Fragment>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>You're almost finished...</h4>
          </GridItem>
        </GridContainer>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={10} md={6}>
            <CustomInput
              success={this.state.nickname_state === 'success'}
              error={this.state.nickname_state === 'error'}
              labelText={<span>Short description to assist finding transaction</span>}
              id='nickname'
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: this.state.nickname,
                onChange: event => this.change(event, 'nickname', 'length', 3)
              }}
            />
          </GridItem>
        </GridContainer>

        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} lg={6}>
            <Card style={{height: '50%'}}>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Confirm client</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableData={[
                    // ['Name', `${client.first_name} ${client.last_name}`],
                    ['Name', `${client.nickname}`],
                    [
                      'Address',
                      `${
                        (client.residential_street_line_1 || '') +
                        ' ' +
                        (client.residential_street_line_2 || '') +
                        ' ' +
                        (client.residential_street_suburb || '') +
                        ' ' +
                        (client.residential_street_state || '') +
                        ' ' +
                        (client.residential_street_postcode || '')
                      }`
                    ],
                    ['Email', `${client.email}`]
                  ]}
                />
              </CardBody>
            </Card>
            <Card style={{height: '50%'}}>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Confirm beneficiary</h4>
              </CardHeader>
              <CardBody>
                <Table tableData={beneficiaryTable} />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} lg={6}>
            <Card style={{height: '100%'}}>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Confirm payment</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableData={[
                      // ["Bank", `${beneficiary.bank_legal_name}`],
                      // ["BSB", `${beneficiary.bsb_code ? beneficiary.bsb_code : "N/A"}`],
                      // ["Account", `${beneficiary.account_number}`],
                      // [
                      //   "Payment for",
                      //   `${this.props.transfers_2.new_transfer_data.purpose_of_payment_detail_text}`
                      // ],
                      // [
                      //   "Further Details",
                      //   `${
                      //   this.props.transfers_2.new_transfer_data.detail_1
                      //     ? this.props.transfers_2.new_transfer_data.detail_1
                      //     : ""
                      //   }`
                      // ],
                      ['Amount from', `${amount_from.toFixed(2)} ${currency_from.iso_alpha_3}`],
                      ['Amount to', `${amount_to.toFixed(2)} ${currency_to.iso_alpha_3}`],
                      [
                          'Rate',
                          `${Number.parseFloat(this.props.transfers_2.new_transfer_data.client_rate).toFixed(4)} `
                      ],
                      [
                          'Fee Amount',
                          `${this.props.transfers_2.new_transfer_data.fee_visible_amount ? Number.parseFloat(this.props.transfers_2.new_transfer_data.fee_visible_amount).toFixed(2) : ''} `
                      ],
                      [
                          'Fee Description',
                          `${this.props.transfers_2.new_transfer_data.fee_visible_description || ''} `
                      ]
                  ]}
                />
                <Divider/>
                <h4 className={classes.cardIconTitle}>Automatic Settlement Instructions</h4>
                <p>
                  Please see below as to whether a settlement account has been automatically selected for this transfer.<br/>
                  If one has, then you may select whether to automatically send the settlement instruction email to the client.
                </p>
                  <p>
                    <strong>
                      {this.state.remittingAccountDetails}
                    </strong>
                  </p>
                <Checkbox onClick={this.toggleRemitEmailCheck} disabled={this.props.app_state.current_staff.security_send_remit_on_tfr !== 1 || this.state.remittingAccountEmailDisabled}>
                  &nbsp;Send Settlement Instruction Email Immediately
                </Checkbox>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

// export default withStyles(style)(Step4Confirm);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers_2: state.transfers_2
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    }
  };
};

const Step4ConfirmContainer = connect(mapStateToProps, mapDispatchToProps)(Step4Confirm);

export default withStyles(style)(Step4ConfirmContainer);
