import {connect} from 'react-redux';
// import Entities from "./Entities";
import {selectCountriesUi} from '../../../redux/actions/countries';
import Countries from './Countries';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    countries: state.countries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectCountriesUi: value => {
      dispatch(selectCountriesUi(value));
    }
  };
};

const CountriesContainer = connect(mapStateToProps, mapDispatchToProps)(Countries);

export default CountriesContainer;
