import React, {useEffect, useState} from 'react';
import './Compliance.css';
import {IndividualCompliance} from './IndividualCompliance';
import {CompanyCompliance} from './CompanyCompliance';
import {TrustCompliance} from './TrustCompliance';
import {Button, Checkbox, DatePicker, Form, Input} from 'antd';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import dayjs from 'dayjs';
import {
  COMPANY_COMPLIANCE_LIST,
  completeCompliance,
  ComplianceService,
  ComplianceUtil,
  INDIVIDUAL_COMPLIANCE_LIST,
  TRUST_COMPLIANCE_LIST
} from './ComplianceAPI';

export const Compliance = (props) => {

  const [completedBy, setCompletedBy] = useState(null);
  const [completedDate, setCompletedDate] = useState(null);
  const [verifiedBy, setVerifiedBy] = useState(null);
  const [verifiedDate, setVerifiedDate] = useState(null);
  const [complianceData, setComplianceData] = useState({checkBoxOptions: [], note: '', complianceType: ''});
  const [requiredEdd, setRequiredEdd] = useState(false);
  const [completedByName, setCompletedByName] = useState(null);
  const [verifiedByName, setVerifiedByName] = useState(null);
  const [requiredFields, setRequiredFields] = useState([]);

  const handleSelectedValuesChange = (complianceData) => {
    setRequiredFields([]);
    setComplianceData(complianceData);
  };

  const appState = props.state || null;

  const handleCompletedDateChange = (event) => setCompletedDate(event);

  const onCompleteBtnClick = (isVerify, date) => {

    const validatedFields = validateFields();
    if (validatedFields.length > 0) {
      setRequiredFields(validatedFields);
      return;
    }
    const data = completeCompliance(complianceData, props, requiredEdd, date, isVerify);
    ComplianceService.createCompliance(data)
      .then(response => restoreCompliance(response))
      .catch(err => console.log(err));
  };
  const handleVerifiedDateChange = (event) => setVerifiedDate(event);

  const handleNoteChange = () => onCompleteBtnClick('SAVE_NOTE', null);

  const validateFields = () => {
    let opts = [];
    if (complianceData.complianceType === 'INDIVIDUAL') {
      opts = INDIVIDUAL_COMPLIANCE_LIST
        .filter(item => item.required).map(item => item.value);
    } else if (complianceData.complianceType === 'COMPANY') {
      opts = COMPANY_COMPLIANCE_LIST
        .filter(item => item.required).map(item => item.value);
    } else {
      opts = TRUST_COMPLIANCE_LIST
        .filter(item => item.required).map(item => item.value);
    }
    return complianceData.checkBoxOptions.length === 0 ? opts : opts.filter(item => !complianceData.checkBoxOptions.includes(item));
  }

  const restoreCompliance = (response) => {

    const checkBoxOptions = {...response};
    const keysToRemove = ['compliance_type', 'required_edd', 'completed_by', 'completed_date', 'verified_by', 'verified_date'];
    keysToRemove.forEach(key => delete checkBoxOptions[key]);
    const checkedBoxes = Object.keys(checkBoxOptions).filter(item => checkBoxOptions[item] === true);

    setComplianceData({
      checkBoxOptions: checkedBoxes,
      complianceType: response['compliance_type'] != null ? response['compliance_type'] : ComplianceUtil.accountTypeCheck(appState),
      note: response.note
    });
    setCompletedBy(response.completed_by);
    setCompletedDate(dayjs(response.completed_date == null ? new Date() : response.completed_date));
    setVerifiedBy(response.verified_by);

    setRequiredEdd(response.required_edd);
    setVerifiedDate(dayjs(response.verified_date == null ? new Date() : response.verified_date));
    setVerifiedBy(response.verified_by);

    const completedByUser = (props.state.staff_list || []).find(item => item.id === response.completed_by) || null;
    if (completedByUser != null) {
      setCompletedByName(completedByUser.first_name + ' ' + completedByUser.last_name);
    }
    const verifiedByUser = (props.state.staff_list || []).find(item => item.id === response.verified_by) || null;
    if (verifiedByUser != null) {
      setVerifiedByName(verifiedByUser.first_name + ' ' + verifiedByUser.last_name);
    }
  };

  useEffect(() => {
    const data = {...complianceData};
    data.complianceType = ComplianceUtil.accountTypeCheck(appState);
    setComplianceData(data);
    ComplianceService.fetchCompliance({client_id: props.client_detail_id}).then(response => restoreCompliance(response));
  }, [0])

  return (
    <React.Fragment>

      {/*<a className="primary" style={{display: 'block'}} onClick={() => setActiveTab(1)}> Individual</a>*/}
      {/*<a className="primary" style={{display: 'block'}} onClick={() => setActiveTab(2)}> Company</a>*/}
      {/*<a className="primary" style={{display: 'block'}} onClick={() => setActiveTab(3)}> Trust</a>*/}

      {appState &&
            ComplianceUtil.accountTypeCheck(appState) === 'INDIVIDUAL' ? (
          <IndividualCompliance state={props.state} complianceData={complianceData}
            onSelectedValuesChange={handleSelectedValuesChange} onNoteChange={handleNoteChange} requiredFields={requiredFields} />
        ) : ComplianceUtil.accountTypeCheck(appState) === 'COMPANY' ? (
          <CompanyCompliance state={props.state} complianceData={complianceData}
            onSelectedValuesChange={handleSelectedValuesChange} onNoteChange={handleNoteChange} requiredFields={requiredFields}/>
        ) : ComplianceUtil.accountTypeCheck(appState) === 'TRUST' ? (
          <TrustCompliance state={props.state} complianceData={complianceData}
            onSelectedValuesChange={handleSelectedValuesChange} onNoteChange={handleNoteChange} requiredFields={requiredFields}/>
        ) : null}

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Form labelCol={{span: 5}} layout={'horizontal'}>
            <Form.Item label={'Completed By '}>
              <Input
                value={completedByName == null ? null : completedByName}
                readOnly={true} style={{width: 200}}/>
            </Form.Item>
            <Form.Item label={'Completed Date: '} className={'mb-0'}>
              <DatePicker value={completedDate} showTime={false} style={{width: 200, marginLeft: '5px'}}
                mode="date" onChange={handleCompletedDateChange} disabled />
            </Form.Item>
            <Form.Item label={' Required EDD '}>
              <Checkbox onChange={(e) => setRequiredEdd(e.target.checked)} disabled={completedBy != null}
                defaultChecked={false} checked={requiredEdd}/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={() => onCompleteBtnClick('COMPLETED', completedDate)}
                disabled={completedBy != null || (appState && !appState.edit_mode) }>Complete</Button>
            </Form.Item>
          </Form>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Form labelCol={{span: 5}} layout={'horizontal'}>
            <Form.Item label={'Verified By '}>
              <Input
                value={verifiedByName == null ? '' : verifiedByName} readOnly={true} style={{width: 200}} />
            </Form.Item>
            <Form.Item label={'Verified Date '}>
              <DatePicker value={verifiedDate} showTime={false} style={{width: 200}} mode="date"
                onChange={handleVerifiedDateChange} disabled />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={() => onCompleteBtnClick('VERIFIED', verifiedDate)}
                disabled={completedBy == null || verifiedBy != null || (appState && !appState.edit_mode)}>Verify</Button>
            </Form.Item>
          </Form>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}