import React, {Fragment, useState, useEffect} from 'react';

import {Box, Grid, TextField, Typography} from '@material-ui/core';

import Button from '../../components/CustomButtons/Button.jsx';
import {API} from 'aws-amplify';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {DropzoneArea} from 'material-ui-dropzone';
import {s3Upload} from 'libs/awsLib.js';

const ClientDocumentCreate = ({classes}) => {
  const [loading, setLoading] = useState(false);

  const [clientsList, setClientsList] = useState([]);
  const [clientsListLoading, setClientsListLoading] = useState(false);

  const [selectedClient, setSelectedClient] = useState(null);

  const [description, setDescription] = useState('');

  const [awsFileId, setAwsFileId] = useState('');

  const clientSelectOnChange = (_, value) => {
    if (!value || !value.id) {
      return;
    }
    setSelectedClient(value || null);
  };

  const fetchClients = () => {
    setClientsListLoading(true);

    API.get('clients', '/get-list-not-deleted')
      .then(response => {
        setClientsList(response);
      })
      .catch(error => {
        console.log(error);
        setClientsList([]);
      })
      .finally(() => {
        setClientsListLoading(false);
      });
  };

  const handleSubmit = () => {
    const clientId = selectedClient && selectedClient.id ? selectedClient.id : null;

    const clientDocumentData = {
      clientId,
      description,
      file_ref: awsFileId
    };

    setLoading(true);

    API.post('client-documents', '/create/client_document', {body: clientDocumentData})
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
        API.post('client-documents', '/create/client_document', {body: clientDocumentData})
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDropzoneFileChange = async files => {
    const fileId = files && files[0] ? await s3Upload(files[0]) : null;

    if (fileId) {
      setAwsFileId(fileId);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <Fragment>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Box className={classes.createFields}>
            <Autocomplete
              id='client-select'
              loading={clientsListLoading}
              options={clientsList}
              getOptionLabel={option => option.nickname}
              value={selectedClient}
              onChange={clientSelectOnChange}
              size={'small'}
              renderInput={params => (
                <TextField {...params} label={clientsListLoading ? 'loading...' : 'Select Client'} />
              )}
            />
            <TextField
              fullWidth
              label='Description'
              value={description}
              onChange={e => setDescription(e.target.value)}
              size='small'
              autoComplete='no'
              multiline
              minRows={3}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={6}>
          <Typography color='textSecondary'>Upload Document</Typography>
          <DropzoneArea
            filesLimit={1}
            acceptedFiles={['image/*', 'application/pdf']}
            onChange={handleDropzoneFileChange}
            showAlerts={false}
          />
        </Grid>
      </Grid>
      <Button
        disabled={loading || !awsFileId || !selectedClient || !selectedClient.id}
        onClick={handleSubmit}
        type='submit'
        color='primary'
        className={classes.updateProfileButton}
      >
        {loading ? 'Creating...' : 'Create'}
      </Button>
    </Fragment>
  );
};

export default ClientDocumentCreate;
