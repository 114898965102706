export const IdentificationConstants = {
  identificationStateKeys: {
    client_list: [],
    document_category_list: [],

    // form_valid: false,
    document_category: {},
    document_category_id: '',
    document_category_id_state: '',
    document_number: '',
    document_number_state: '',
    document_detail: '',
    document_detail_state: '',
    document_expiry: '',
    document_expiry_state: '',
    document_expiry_init_for_category_change: false,
    document_issue_state: '',
    document_issue_state_state: '',
    document_issue_country: '',
    document_issue_country_state: '',
    nickname: '',
    nickname_state: '',
    newUser: null,
    client_id: '',
    client_id_state: '',
    client: {},
    query_string: false,

    country_list: [],
    country_list_prio: [],
    front_image: '',
    back_image: '',
    multipleImages: [],
      activeClient: '-'
  }
}