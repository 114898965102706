import React, {Fragment} from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader.jsx';
import CardIcon from '../../../components/Card/CardIcon.jsx';
import PermIdentity from '@material-ui/icons/PermIdentity';
import BeneficiaryDocumentsNew from '../../../features/Beneficiaries/documents/New.jsx';

import {FormattedMessage} from 'react-intl';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */

const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const DocumentsNewContainer = props => {
  const {classes} = props;

  return (
    <Fragment>
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <PermIdentity />
          </CardIcon>
          <div className={classes.flexEnd}>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage
                id='beneficiaryDocuments.cardItem1.beneficiaryDocumentsNew'
                defaultMessage={'Create a new beneficiary document'}
              />
            </h4>
          </div>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <BeneficiaryDocumentsNew />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default withStyles(styles)(DocumentsNewContainer);
