import {API} from 'aws-amplify';

export const ENTITYBANKS_FETCH_ENTITYBANKS_LIST_REQUEST = 'ENTITYBANKS_FETCH_ENTITYBANKS_LIST_REQUEST';
export const ENTITYBANKS_FETCH_ENTITYBANKS_LIST_SUCCESS = 'ENTITYBANKS_FETCH_ENTITYBANKS_LIST_SUCCESS';
// export const ENTITYBANKS_FETCH_ENTITYBANKS_LIST_FAILURE = "ENTITYBANKS_FETCH_ENTITYBANKS_LIST_FAILURE";
export const ENTITYBANKS_CREATE_NEW_SHOW_UI = 'ENTITYBANKS_CREATE_NEW_SHOW_UI';
export const ENTITYBANKS_CREATE_NEW_START = 'ENTITYBANKS_CREATE_NEW_START';
export const ENTITYBANKS_CREATE_NEW_CANCEL = 'ENTITYBANKS_CREATE_NEW_CANCEL';
export const ENTITYBANKS_CREATE_NEW_COMPLETE = 'ENTITYBANKS_CREATE_NEW_COMPLETE';
export const ENTITYBANKS_SELECT_UI = 'ENTITYBANKS_SELECT_UI';
export const ENTITYBANKS_ENTITYBANKS_LIST_IS_DIRTY = 'ENTITYBANKS_ENTITYBANKS_LIST_IS_DIRTY';
export const ENTITYBANKS_ENTITYBANKS_EDIT = 'ENTITYBANKS_ENTITYBANKS_EDIT';

export function requestEntityList() {
  return {
    type: ENTITYBANKS_FETCH_ENTITYBANKS_LIST_REQUEST
  };
}

export function receiveEntityList(json) {
  return {
    type: ENTITYBANKS_FETCH_ENTITYBANKS_LIST_SUCCESS,
    entitybanks: json,
    receivedAt: Date.now()
  };
}

export function fetchEntitybanksListNotDeleted() {
  return function (dispatch) {
    dispatch(requestEntityList);

    API.get('system', `/entitybanks/get-all`)
      .then(response => {
        dispatch(receiveEntityList(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function selectEntitybanksUi(value) {
  return {
    type: ENTITYBANKS_SELECT_UI,
    value: value
  };
}

export function entitybanksListIsDirty(value) {
  return {
    type: ENTITYBANKS_ENTITYBANKS_LIST_IS_DIRTY,
    value: value
  };
}

export async function create_new_currency_in_database(data) {
  API.post('system', `/entitybanks/create/in-database`, {
    body: {
      ...data
    }
  })
    .then(database_result => {
      return database_result;
    })
    .catch(error => {
      console.log(error);
    });
}

export function createNewEntitybanks(data) {
  return async function (dispatch) {
    try {
      await create_new_currency_in_database(data);
    } catch (e) {
      console.log(e);
    }
  };
}

export function cancelCreateNewEntitybanks() {
  return {
    type: ENTITYBANKS_CREATE_NEW_CANCEL
  };
}

export function createNewEntitybanksStart() {
  return {
    type: ENTITYBANKS_CREATE_NEW_START
  };
}

export function createNewEntitybanksComplete() {
  return {
    type: ENTITYBANKS_CREATE_NEW_COMPLETE
  };
}

export function editEntitybanks(id) {
  // console.log(' EDIT: ' + id);
  return {
    type: ENTITYBANKS_ENTITYBANKS_EDIT,
    value: id
  };
}
