import React, {Fragment} from 'react';
import moment from 'moment';
import ListTable from '../../../components/ListTable/ListTable'
import {Button, Table} from 'antd'
import {DashboardOutlined} from "@ant-design/icons";

const BeneficiaryDocumentsList = ({beneficiary_documents_list, history}) => {

    const buildTableData = data => {
        if (!data) {
            return [];
        }

        return data.map(item => ({
            ...item,
            actions: (
                <Button
                    type={'link'}
                    onClick={() => {
                        history.push(`/beneficiaries/documents/view/${item.id}`);
                    }}
                    className='edit'
                >
                    <DashboardOutlined/>
                </Button>
            )
        }));
    };

    return (
        <Fragment>
            <Table
                dataSource={buildTableData(beneficiary_documents_list)}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        sorter: (a, b) => a.id - b.id,
                    },
                    {
                        title: 'Beneficiary ID',
                        dataIndex: 'beneficiaryId',
                        sorter: (a, b) => a.beneficiaryId - b.beneficiaryId,
                    },
                    {
                        title: 'Document ID',
                        dataIndex: 'documentId',
                        sorter: (a, b) => a.beneficiaryId - b.beneficiaryId,
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                    },
                    {
                        title: 'Created',
                        dataIndex: 'recordCreated',
                        render: text => <React.Fragment>{moment(text).format('DD/MM/YYYY')} </React.Fragment>,
                    },
                    {
                        title: 'Actions',
                        dataIndex: 'actions'
                    }
                ]}
            />
        </Fragment>
    );
};

export default ListTable(BeneficiaryDocumentsList);
