import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {onLoginSuccess, updateCurrentBrand} from '../../redux/actions';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Language from "@material-ui/icons/Language";
// import ThumbUp from "@material-ui/icons/ThumbUp";
// import VpnLock from "@material-ui/icons/VpnLock";
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
// import LockOutline from "@material-ui/icons/LockOutline";
// import Check from "@material-ui/icons/Check";

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
// import InfoArea from "components/InfoArea/InfoArea.jsx";
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import {API, Auth} from 'aws-amplify';

// import registerPageStyle from "./RegisterPageStyle";
import Icon from '@material-ui/core/Icon';

import {container, cardTitle} from '../../assets/jss/material-dashboard-pro-react.jsx';

import customCheckboxRadioSwitch from '../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import {FormattedMessage} from 'react-intl';
import CardHeader from '../../components/Card/CardHeader';

const registerPageStyle = {
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    textAlign: 'center'
  },
  container: {
    ...container,
    position: 'relative',
    zIndex: '3'
    // paddingTop: "23vh"
  },
  cardSignup: {
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    marginBottom: '100px',
    padding: '40px 0px',
    marginTop: '15vh'
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left'
  },
  form: {
    padding: '0 20px',
    position: 'relative'
  },
  socialTitle: {
    fontSize: '18px'
  },
  inputAdornment: {
    marginRight: '18px',
    position: 'relative'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  customFormControlClasses: {
    margin: '0 12px'
  },
  checkboxLabelControl: {
    margin: '0'
  },
  checkboxLabel: {
    marginLeft: '6px',
    color: 'rgba(0, 0, 0, 0.26)'
  }
};

class VerifyEmailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      email: '',
      password: '',
      confirm_code: '',
      error_message: '',
      newUser: null
      // display_resend_code_link: false
    };
    this.handleToggle = this.handleToggle.bind(this);
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    if (this.props.match.params.referral_code) {
      this.props.updateCurrentBrand(this.props.match.params.referral_code);
    }
  }
  handleToggle(value) {
    const {checked} = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    // console.log(this.state);
  };

  async updateUserProfile(user_id, data) {
    return API.put('staff', `/update/aws_cognito_id/${user_id}`, {
      body: data
    });
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({isLoading: true});
    var confirmed_user = '';
    try {
      confirmed_user = await Auth.confirmSignUp(this.state.email, this.state.confirm_code);

      const signed_in_user = await Auth.signIn(this.state.email, this.state.password);

      const database_result = await this.updateUserProfile(signed_in_user.username, {
        portal_email_confirmed: 1,
        portal_last_login_datetime: new Date().toISOString().slice(0, 19).replace('T', ' ')
      });
      //
      this.props.userHasAuthenticated(true);
      this.props.history.push('/');
    } catch (e) {
      this.setState({
        error_message: e.message
      });
      this.setState({isLoading: false});
      if (confirmed_user === 'SUCCESS') {
        this.props.history.push('/');
      }
    }
  };

  resendAuthCode = async event => {
    try {
      await Auth.resendSignUp(this.state.email);
      alert('New Verification code has been sent.  Please check your email (including Spam/Junk folders).');
    } catch (e) {
      alert('Resending code failed.  Please check you have entered correct email address.');
    }
  };

  validateConfirmationForm() {
    return this.state.confirm_code.length > 0;
  }

  renderConfirmationForm() {
    const {classes} = this.props;
    return (
      <form className={classes.form} onSubmit={this.handleConfirmationSubmit}>
        <p style={{textAlign: 'center'}}>Please enter your email address and the confirmation code sent to you.</p>
        <CustomInput
          // labelText="Email..."
          id='email'
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            value: this.state.email,
            onChange: event => {
              this.handleChange(event);
            },
            startAdornment: (
              <InputAdornment position='start' className={classes.inputAdornment}>
                <Email className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
            type: 'email',
            placeholder: 'Email...'
          }}
        />
        <CustomInput
          // labelText="Password"
          id='password'
          value={this.state.password}
          onChange={this.handleChange}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            value: this.state.password,
            onChange: event => {
              this.handleChange(event);
            },
            type: 'password',
            startAdornment: (
              <InputAdornment position='start' className={classes.inputAdornment}>
                <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
              </InputAdornment>
            ),
            placeholder: 'Password...'
          }}
        />
        <CustomInput
          // labelText="First Name..."
          id='confirm_code'
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            value: this.state.confirm_code,
            onChange: event => {
              this.handleChange(event);
            },
            startAdornment: (
              <InputAdornment position='start' className={classes.inputAdornment}>
                <Face className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
            placeholder: 'Confirmation Code...'
          }}
        />
        <p>{this.state.error_message}</p>
        <div className={classes.center}>
          <Button
            round
            color='primary'
            disabled={!this.validateConfirmationForm()}
            type='submit'
            // onClick={() => this.props.history.push("/pages/login-page")}
          >
            Validate Code
          </Button>
          <Button
            round
            color='primary'
            disabled={!this.state.email}
            // type="submit"
            onClick={() => this.resendAuthCode()}
          >
            Resend New Code
            {/* TODO: Translate */}
          </Button>
        </div>
      </form>
    );
  }

  render() {
    const {classes} = this.props;

    // Branding
    let logo;
    let form_text;
    switch (this.props.app_state.current_brand) {
      case 'dorman':
        form_text = 'Dorman FX';
        break;
      case 'gostudy':
        logo = require('assets/img/GOSTUDY_HorizontalWhite.png');
        form_text = 'GoStudy FX';
        break;
    }

    return (
      <div className={classes.container}>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={8}>
            <Card className={classes.cardSignup}>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color='primary'>
                {logo && <img src={logo} height={70} />}
                <h2 style={{color: 'white'}} className={classes.cardTitle}>
                  {form_text} Account Verification
                </h2>
              </CardHeader>

              <CardBody>
                <GridContainer justify='center'>
                  <GridItem xs={12} sm={10} md={10}>
                    {this.renderConfirmationForm()}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

VerifyEmailPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
    // clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentBrand: brand => {
      dispatch(updateCurrentBrand(brand));
    },
    onLoginSuccess: staff_id => {
      dispatch(onLoginSuccess(staff_id));
    }
  };
};

const VerifyEmailPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(registerPageStyle)(VerifyEmailPage));

export default VerifyEmailPageContainer;
