import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// react component for creating dynamic tables

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';
// core components
import {AiOutlineSearch} from 'react-icons/ai';
import CustomInput from '../CustomInput/CustomInput';
import {API} from 'aws-amplify';

import NavPills from 'components/NavPills/NavPills.jsx';

// @material-ui/icons
import MenuItem from '@material-ui/core/MenuItem';
import Dvr from '@material-ui/icons/Dvr';
import matchSorter from 'match-sorter';
import Close from '@material-ui/icons/Close';

import {connect} from 'react-redux';
// import GroupEdit from "./GroupEdit";

import {
  requestEntityList,
  fetchEntityListNotDeleted,
  entitiesListIsDirty,
  setEntityDetailId,
  selectEntityUi
} from '../../redux/actions/groups';
import {Alert, Button, Card, Col, Descriptions, message, Modal, Row, Select, Table} from "antd";
import {DeleteOutlined, InfoCircleOutlined} from "@ant-design/icons";
import FormItem from "antd/lib/form/FormItem";


class GroupEditClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      is_loading: true,
      group_id: '',
      nickname: '',
      description: '',
      client_list: [],
      client_id: '',
      client: {},
      clients_in_group: [],
      team_id: null

      // division_id: "",
      // ref_code: "",
      // division_list: [],
      // responsible_staff_member: "",
      // staff_list: []
    };
  }

  componentDidMount() {
    API.get('clients', `/get-list-not-deleted`)
      .then(response => {
        // console.log(response);
        this.setState({
          client_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });

    if (this.props.group_id) {
      console.log(this.props);
      API.get('groups', `/get/id/${this.props.group_id}`)
        .then(response => {
          console.log(response);
          this.setState({
            loaded: true,
            is_loading: false,
            nickname: response.nickname,
            description: response.description,
            group_id: response.id,
            team_id: response.team_id,
            // division_id: entity_detail.division_id,
            // ref_code: entity_detail.ref_code,
            // responsible_staff_member: entity_detail.responsible_staff_member
          });
        })
        .catch(error => {
          console.log(error);
        });
      API.get('groups', `/get/clients/${this.props.group_id}`)
        .then(response => {
          console.log(response);
          this.setState({
            clients_in_group: response
          });
        })
        .catch(error => {
          console.log(error);
        });
    }

    API.get('groups', `/get-all`)
      .then(response => {
        console.log(response);
        this.setState({
          group_list: response,
          group_all_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  getGroupClientsDetails(group_id) {
    try {
      API.get('groups', `/get/id/${group_id}`)
        .then(response => {
          console.log(response);
          console.log(typeof response);
          let groupSQLResult = {};
          groupSQLResult = [response];
          this.setState({group_list: groupSQLResult});
        })
        .catch(error => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  handleSelectChange = event => {
    // console.log(event);
    // this.setState({
    //   [event.target.name]: event.target.value
    // });
    if (event.target.value !== 0) {
      this.setState({[event.target.name]: event.target.value});
      // this.getGroupClientsDetails(event.target.value);
    }
  };
  handleClientSelectChange = event => {
    // console.log(event);
    this.setState({client_to_add: event.value});
    // this.props.updateNewTransferCreation("client_id", event.value);
    // this.getClientDetails(event.value);
  };

  add_client_to_group = async () => {
    let client_to_add = this.state.client_to_add;
    let proceed = true;
    for (const idx in this.state.clients_in_group) {
      if (this.state.clients_in_group[idx].id === client_to_add) {
        proceed = false;
      }
    }
    if (proceed) {
      await API.post('groups', `/update/add-client/${this.props.group_id}`, {
        body: {
          client_id: this.state.client_to_add
        }
      });
    } else {
      message.success('That customer is already in the group.');
    }
    API.get('groups', `/get/clients/${this.props.group_id}`)
      .then(response => {
        console.log(response);
        this.setState({
          clients_in_group: response
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  remove_client_from_group = async client_id => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'Are you sure you want to remove this client from the group ?',
           onOk: async () => {
               await API.post('groups', `/update/remove-client/${this.props.group_id}`, {
                   body: {
                       client_id
                   }
               });
               API.get('groups', `/get/clients/${this.props.group_id}`)
                   .then(response => {
                       console.log(response);
                       this.setState({
                           clients_in_group: response
                       });
                       message.success("Client has been removed from the group..");
                   })
                   .catch(error => {
                       console.log(error);
                       message.error("Failed to remove the client from the group");
               });
           }
        })
  }

  render() {

    let table_data = this.state.clients_in_group.map((prop, key) => {
      // if(this.state.client_id == prop.client_id)
      return {
        id: prop.id,
        nickname: prop.nickname,
        email: prop.email,
        actions: (
          <div className='actions-right'>
            <Button
              type={'primary'}
              danger={true}
              onClick={() => this.remove_client_from_group(prop.id)}
              className='edit' icon={<DeleteOutlined />}>
            </Button>
          </div>
        )
      };
    });

    const client_list_select_options = this.state.client_list.filter(i => i.team_id === this.state.team_id)
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return {
          value: item.id,
          label: item.nickname + ' (' + item.email + ')'
        };
      });

    return (
      <>
        <Row justify='left'>
          <Col xs={24} sm={24} md={24} lg={12}>
            <FormItem layout={'vertical'} label={'Select Client'}>
              <Select allowClear={true} optionFilterProp={'label'} showSearch={true} placeholder={'-- SELECT CLIENT --'} style={{ width: '200px' }} options={client_list_select_options} onChange={(val) => this.setState({client_to_add: val})}></Select>
            </FormItem>
              {
                  this.state.client_to_add != null
                      ? <Alert message={
                          <Descriptions column={1} items={[
                              {label: 'Name', style: {margin:0, padding: 0}, children: <>{this.state.client_list.find(i => i.id === this.state.client_to_add).nickname}</>},
                              {label: 'Email', style: {margin:0, padding: 0}, children: <>{this.state.client_list.find(i => i.id === this.state.client_to_add).email}</>}
                          ]}></Descriptions>
                      }></Alert>
                      : <></>
              }
          </Col>
        </Row>
        <br />
        <Button type={'primary'} onClick={this.add_client_to_group}>Add to Group</Button>
        <br />
        <Card style={{ marginTop: '10px' }}>
            <Table
              size={'small'}
              dataSource={table_data}
              loading={this.state.is_loading}
              columns={[
                {
                  title: 'ID',
                  dataIndex: 'id'
                },
                {
                  title: 'Client',
                  dataIndex: 'nickname'
                },
                {
                  title: 'Email',
                  dataIndex: 'email'
                },
                {
                  title: 'Actions',
                  dataIndex: 'actions'
                }
              ]}
            />
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    groups: state.groups
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestEntityList: () => {
      dispatch(requestEntityList());
    },
    fetchEntityListNotDeleted: () => {
      dispatch(fetchEntityListNotDeleted());
    },
    entitiesListIsDirty: value => {
      dispatch(entitiesListIsDirty(value));
    },
    setEntityDetailId: id => {
      dispatch(setEntityDetailId(id));
    },
    selectEntityUi: value => {
      dispatch(selectEntityUi(value));
    }
  };
};

const GroupEditClientContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupEditClient);

export default GroupEditClientContainer;
