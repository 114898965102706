import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

import {
  updateClient,
  loadClientDetailsRequest,
  loadClientDetailsSuccess,
  createCognitoAccountForClient
} from '../../redux/actions/clients';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader';
import CardFooter from '../../components/Card/CardFooter';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import {API} from 'aws-amplify';

import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import {FormattedMessage} from 'react-intl';
import CustomReactSelect from '../Forms/CustomReactSelect/CustomReactSelect';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import {CSVDownload} from 'react-csv';
import Datetime from 'react-datetime';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const staffEditStyles = {
  ...switchStyles,
  ...selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class InvoicesBulk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      is_loading: true,
      team_id: '',
      teams_list: [],
      due_date: '',
      due_date_state: 'error',

      bulkTransfer: [
        {
          first_name: '',
          last_name: '',
          email: '',
          mobile: '',
          client_ref: '',
          first_name_state: '',
          last_name_state: '',
          email_state: '',
          mobile_state: '',
          client_ref_state: '',
          client_id: '',
          beneficiary_id: '',
          amount: '',
          details: '',
          purposeOfPayment: '',
          account_currency: '',
          client_id_state: '',
          beneficiary_id_state: '',
          amount_state: '',
          details_state: '',
          purposeOfPayment_state: ''
        }
      ],

      beneficiaries_list: [],
      clients_list: [],
      purpose_list: []
    };
  }

  componentDidMount() {
    API.get('teams', `/get-list-not-deleted`)
      .then(response => {
        this.setState({
          teams_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  addClick() {
    this.setState(prevState => ({
      bulkTransfer: [
        ...prevState.bulkTransfer,
        {
          first_name: '',
          last_name: '',
          email: '',
          mobile: '',
          client_ref: '',
          first_name_state: '',
          last_name_state: '',
          email_state: '',
          mobile_state: '',
          client_ref_state: '',
          client_id: '',
          beneficiary_id: '',
          amount: '',
          details: '',
          purposeOfPayment: '',
          account_currency: '',
          client_id_state: '',
          beneficiary_id_state: '',
          amount_state: '',
          details_state: '',
          purposeOfPayment_state: ''
        }
      ]
    }));
  }

  removeClick(i) {
    let bulkTransfer = [...this.state.bulkTransfer];
    bulkTransfer.splice(i, 1);
    this.setState({bulkTransfer});
  }

  handleChange(e, i, stateName) {
    const {name, value} = e.target;
    // console.log(stateName, value)
    let bulkTransfer = [...this.state.bulkTransfer];

    bulkTransfer[i] = {...bulkTransfer[i], [stateName]: value};
    bulkTransfer[i] = {...bulkTransfer[i], [stateName + '_state']: 'success'};

    if (stateName == 'beneficiary_id') {
      for (let index = 0; index < this.state.beneficiaries_list.length; index++) {
        if (value == this.state.beneficiaries_list[index].id) {
          bulkTransfer[i] = {
            ...bulkTransfer[i],
            ['account_currency']: this.state.beneficiaries_list[index].account_currency
          };
        }
      }
    }
    // console.log(bulkTransfer)
    this.setState({bulkTransfer});
  }

  verifyCognitoPassword(value) {
    var passwordRex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/;
    if (passwordRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyCognitoPassword(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
  }

  isNumeric(number) {
    if (+number === +number && number > 0) {
      return true;
    }
    return false;
  }

  isValidated() {
    let bulkTransfer = [...this.state.bulkTransfer];
    let return_flag = true;

    if (this.state.team_id == '') {
      return_flag = false;
    }

    if (this.state.due_date_state == 'error') {
      return_flag = false;
    }

    bulkTransfer.map(el => {
      if (
        el.client_id == '' &&
        (el.first_name == '' || el.last_name == '' || el.email == '' || el.mobile == '' || el.client_ref == '')
      ) {
        el.client_id_state = 'error';
        return_flag = false;
      } else {
        el.client_id_state = 'success';
      }
      if (el.beneficiary_id == '') {
        el.beneficiary_id_state = 'error';
        return_flag = false;
      } else {
        el.beneficiary_id_state = 'success';
      }
      if (el.amount == '' || !this.isNumeric(el.amount)) {
        el.amount_state = 'error';
        return_flag = false;
      } else {
        el.amount_state = 'success';
      }
      if (el.purposeOfPayment == '') {
        el.purposeOfPayment_state = 'error';
        return_flag = false;
      } else {
        el.purposeOfPayment_state = 'success';
      }

      this.setState({
        bulkTransfer: bulkTransfer
      });
    });

    return return_flag;
  }

  createUI() {
    const {classes} = this.props;

    return this.state.bulkTransfer.map((el, i) => (
      <div key={i}>
        <Card>
          <CardHeader icon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id='Enter details for Bulk Transfer' defaultMessage={`Bulk Transfer Item #`} /> {i + 1}
            </h4>
          </CardHeader>

          <CardBody>
            <GridContainer justify='left'>
              <GridItem xs={12} md={2}>
                <Select
                  fullWidth
                  style={{paddingTop: 25}}
                  success={el.client_id_state === 'success'}
                  error={el.client_id_state === 'error'}
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  key={i}
                  value={el.client_id || '0'}
                  onChange={event => this.handleChange(event, i, 'client_id')}
                  inputProps={{
                    name: 'client_id',
                    id: 'client_id'
                  }}
                >
                  <MenuItem
                    key='0x0'
                    value='0'
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Select Client
                  </MenuItem>

                  {this.state.clients_list
                    .sort((a, b) => a.nickname.localeCompare(b.nickname))
                    .map(item => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nickname}
                        </MenuItem>
                      );
                    })}
                </Select>
              </GridItem>
              <GridItem xs={12} md={2}>
                <CustomInput
                  success={el.first_name_state === 'success'}
                  error={el.first_name_state === 'error'}
                  labelText={<span>First Name</span>}
                  id='first_name'
                  name='first_name'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: el.first_name ? el.first_name : '',
                    onChange: event => this.handleChange(event, i, 'first_name')
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={2}>
                <CustomInput
                  success={el.last_name_state === 'success'}
                  error={el.last_name_state === 'error'}
                  labelText={<span>Last Name</span>}
                  id='last_name'
                  name='last_name'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: el.last_name ? el.last_name : '',
                    onChange: event => this.handleChange(event, i, 'last_name')
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={2}>
                <CustomInput
                  success={el.email_state === 'success'}
                  error={el.email_state === 'error'}
                  labelText={<span>Email</span>}
                  id='email'
                  name='email'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: el.email ? el.email : '',
                    onChange: event => this.handleChange(event, i, 'email')
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={2}>
                <CustomInput
                  success={el.mobile_state === 'success'}
                  error={el.mobile_state === 'error'}
                  labelText={<span>Mobile</span>}
                  id='mobile'
                  name='mobile'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: el.mobile ? el.mobile : '',
                    onChange: event => this.handleChange(event, i, 'mobile')
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={2}>
                <CustomInput
                  success={el.client_ref_state === 'success'}
                  error={el.client_ref_state === 'error'}
                  labelText={<span>Client ref</span>}
                  id='client_ref'
                  name='client_ref'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: el.client_ref ? el.client_ref : '',
                    onChange: event => this.handleChange(event, i, 'client_ref')
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify='left'>
              <GridItem xs={12} md={2}>
                <Select
                  fullWidth
                  style={{paddingTop: 25}}
                  success={el.beneficiary_id_state === 'success'}
                  error={el.beneficiary_id_state === 'error'}
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  key={i}
                  value={el.beneficiary_id || '0'}
                  onChange={event => this.handleChange(event, i, 'beneficiary_id')}
                  inputProps={{
                    name: 'beneficiary_id',
                    id: 'beneficiary_id'
                  }}
                >
                  <MenuItem
                    key='0x0'
                    value='0'
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Select Beneficiary
                  </MenuItem>

                  {this.state.beneficiaries_list
                    .sort((a, b) => a.nickname.localeCompare(b.nickname))
                    .map(item => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nickname}
                        </MenuItem>
                      );
                    })}
                </Select>
              </GridItem>
              <GridItem xs={12} md={2}>
                <CustomInput
                  success={el.amount_state === 'success'}
                  error={el.amount_state === 'error'}
                  labelText={<span>Amount</span>}
                  id='email'
                  name='email'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: el.amount ? el.amount : '',
                    onChange: event => this.handleChange(event, i, 'amount')
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={2}>
                <Select
                  fullWidth
                  style={{paddingTop: 25}}
                  success={el.purposeOfPayment_state === 'success'}
                  error={el.purposeOfPayment_state === 'error'}
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  key={i}
                  value={el.purposeOfPayment || '0'}
                  onChange={event => this.handleChange(event, i, 'purposeOfPayment')}
                  inputProps={{
                    name: 'purposeOfPayment',
                    id: 'purposeOfPayment'
                  }}
                >
                  <MenuItem
                    key='0x0'
                    value='0'
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Purpose of Payment
                  </MenuItem>

                  {this.state.purpose_list.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </GridItem>
              <GridItem xs={12} md={3}>
                <CustomInput
                  success={el.details_state === 'success'}
                  error={el.details_state === 'error'}
                  labelText={<span>Details</span>}
                  id='details'
                  name='details'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: el.details ? el.details : '',
                    onChange: event => this.handleChange(event, i, 'details')
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <GridContainer>
              <GridItem xs={12} md={2}>
                <Button size='sm' color='warning' key={i} onClick={this.removeClick.bind(this, i)}>
                  Remove This Record
                </Button>
              </GridItem>
            </GridContainer>
          </CardFooter>
        </Card>
      </div>
    ));
  }

  handleCustomReactSelectChange = name => value => {
    var option_value;
    if (value === null) {
      option_value = null;
    } else {
      option_value = value.value;

      API.get('teams', `/get/client-bene/${option_value}`).then(response => {
        this.setState({
          clients_list: response.clients,
          beneficiaries_list: response.beneficairies
        });
      });

      for (let index = 0; index < this.state.teams_list.length; index++) {
        if (option_value == this.state.teams_list[index].id) {
          this.setState({
            division_id: this.state.teams_list[index].division_id
          });

          API.get('transfers', `/get_purpose/id/${this.state.teams_list[index].division_id}`)
            .then(response => {
              this.setState({
                purpose_list: response
              });
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    }
    this.setState({
      [name]: option_value
    });
  };

  handleDueDateChange = (event, option) => {
    let selDate = '';
    if (event.isValid != undefined) {
      if (event.toDate().getMonth() >= 9) {
        if (option === 'onlyYearMonth')
          selDate = event.toDate().getFullYear() + '-' + (event.toDate().getMonth() + 1) + '-01';
        // set it as the first day of the month
        else {
          if (event.toDate().getDate() > 9)
            selDate =
              event.toDate().getFullYear() + '-' + (event.toDate().getMonth() + 1) + '-' + event.toDate().getDate();
          // + 'T00:00.00.000Z';
          else
            selDate =
              event.toDate().getFullYear() + '-' + (event.toDate().getMonth() + 1) + '-0' + event.toDate().getDate(); // + 'T00:00.00.000Z';
        }
      } else if (event.toDate().getMonth() < 9) {
        if (option === 'onlyYearMonth')
          selDate = event.toDate().getFullYear() + '-0' + (event.toDate().getMonth() + 1) + '-01';
        // set it as the first day of the month
        else {
          if (event.toDate().getDate() > 9)
            selDate =
              event.toDate().getFullYear() + '-0' + (event.toDate().getMonth() + 1) + '-' + event.toDate().getDate();
          // + 'T00:00.00.000Z';
          else
            selDate =
              event.toDate().getFullYear() + '-0' + (event.toDate().getMonth() + 1) + '-0' + event.toDate().getDate(); // + 'T00:00.00.000Z';
        }
      }
      this.setState({due_date_state: 'success'});
    } else {
      selDate = event;
      // selDate = this.modifydocument_expiry(event);
      this.setState({due_date_state: 'error'});
    }

    this.setState({due_date: selDate});
    console.log(selDate);
    // this.props.updateNewTransferCreation(
    //   "due_date",
    //   selDate
    // );
    // this.setState({ document_expiry_init_for_category_change: false });
  };

  async save_pending_transfers(transfer_detail) {
    return API.put('transfers_pending', `/bulk/add`, {
      body: transfer_detail
    });
  }

  async handleSubmit() {
    console.log(this.state);
    // return;
    if (this.isValidated()) {
      // this.setState({ isLoading: true });
      try {
        let response = await this.save_pending_transfers({
          bulkTransfer: this.state.bulkTransfer,
          team_id: this.state.team_id,
          due_date: this.state.due_date,
          invoice: true
        });
        console.log(response);
        this.props.history.push(`/invoices/list`);
      } catch (e) {
        console.log(e);
        // alert(e);
        this.props.history.push(`/invoices/list`);
      }
    }
  }

  render() {
    const {classes} = this.props;

    if (this.state.isLoading) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }

    const team_list_select_options = this.state.teams_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));

    return (
      <div>
        <GridContainer justify='left'>
          <GridItem xs={6} md={3}>
            <CustomReactSelect
              label='Select Team'
              options={team_list_select_options}
              value={this.state.team_id}
              onChange={this.handleCustomReactSelectChange('team_id')}
              error={this.state.team_id === ''}
              isClearable={true}
            />
            {/* <p>Select Team</p> */}
          </GridItem>
        </GridContainer>
        <br />
        <br />
        <GridContainer>
          <GridItem xs={12} sm={3}>
            <InputLabel className={classes.label} error={this.state.due_date_state === 'error'}>
              Invoice Due Date
            </InputLabel>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Datetime
                timeFormat={false}
                dateFormat={'DD/MM/YYYY'}
                // The following method which can hide data doesn't work for the first date pick
                // dateFormat={!this.state.document_expiry_init_for_category_change && 'DD/MM/YYYY'}
                // dateFormat={(this.props.identification.new_identification_record_data && this.props.identification.new_identification_record_data.document_expiry === "") ? false : 'DD/MM/YYYY'}
                onChange={event => {
                  this.handleDueDateChange(event);
                }}
              />
            </FormControl>
          </GridItem>
        </GridContainer>
        {this.createUI()}

        <GridContainer justify='left'>
          <GridItem xs={6} md={2}>
            <Button size='sm' color='info' key='addKey' onClick={this.addClick.bind(this)}>
              Add New Record
            </Button>
          </GridItem>
        </GridContainer>

        <Button color='rose' onClick={() => this.handleSubmit()} className={classes.registerButton}>
          Save
        </Button>
      </div>
    );
  }
}

// TransfersUpload.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default withRouter(withStyles(staffEditStyles)(TransfersUpload));

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadClientDetailsRequest: () => {
      dispatch(loadClientDetailsRequest());
    },
    loadClientDetailsSuccess: () => {
      dispatch(loadClientDetailsSuccess());
    },
    createCognitoAccountForClient: (id, username, password, language_id) => {
      dispatch(createCognitoAccountForClient(id, username, password, language_id));
    },
    updateClient: (data, id) => {
      dispatch(updateClient(data, id));
    }
  };
};

const InvoicesBulkContainer = connect(mapStateToProps, mapDispatchToProps)(InvoicesBulk);

export default withRouter(withStyles(staffEditStyles)(InvoicesBulkContainer));
