import { Button, message } from 'antd'
import React from 'react';
import { API } from 'aws-amplify'
import axios from 'axios'

const BdoRemit = () => {
    const authenticate = () => {
        API.get("banking", "/bdo/authenticate", {}).then(response => {
            console.log(response);
            const payload = {
                userName: 'abc',
                password: 'abc'
            };
            API.post("banking", "/bdo/transfer-payment", {body: {token: response.token, payload: payload}}).then(response => {
                message.info("Transfer Payment Success");
                console.log(response);
            }).catch(err => {
                message.info("TransferPayment Error");
                console.log(err);
            })
        }).catch(() => {
            message.info("Unable to get token. Contact customer service team");
        })
    }

    return <>
        <Button type={'primary'} onClick={authenticate}>Authenticate</Button>
    </>
}

export default BdoRemit;