import React from 'react';
import PropTypes from 'prop-types';
import {API} from 'aws-amplify';

import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Button from '../../CustomButtons/Button';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react';
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
const moment = require('moment')

const staffEditStyles = {
  ...switchStyles,
  ...selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class ClientEditCommunication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activation_email_sending: false,
      activation_email_sent: false
    };
  }

  // componentDidMount() {
  // }

  handle_send_account_activation = async event => {
    // event.preventDefault();
    this.setState({
      activation_email_sending: true
    });
    API.post('email', `/client/new-client-account-active`, {
      body: {
        id: this.props.id,
        currentStaffID: this.props.currentStaffID
      }
    })
      .then(response => {
        this.setState({
          // activation_email_sent: true,
          activation_email_sending: false,
          emailsent_approved_date: moment().format('YYYY-MM-DD HH:mm:ss'),
          emailsent_approved_staff: `You (staff ID ${this.props.currentStaffID})`

        });
      })
      .catch(error => {
        console.log(error);
      });
  };
  handle_send_account_activation_staff = async event => {
    // event.preventDefault();
    this.setState({
      activation_email_sending: true
    });
    API.post('email', `/client/new-client-account-active`, {
      body: {
        id: this.props.id,
        currentStaffID: this.props.currentStaffID,
        staffOnly: true
      }
    })
      .then(response => {
        this.setState({
          // activation_email_sent: true,
          activation_email_sending: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const {classes} = this.props;

    const backoffice = this.props.backoffice;

    const emailsent_approved_date = this.state.emailsent_approved_date || this.props.emailSentDateTime
    const emailsent_approved_staff = this.state.emailsent_approved_staff || this.props.emailSentStaffID

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button
              color='info'
              disabled={!(this.props.enabled && backoffice)}
              size='sm'
              style={{width: '100px'}}
              onClick={this.handle_send_account_activation}
              className={classes.updateProfileButton}
            >
              Email
            </Button> Send account activated email to client
            <br/>
            <Button
              color='info'
              disabled={!(this.props.enabled && backoffice)}
              size='sm'
              style={{width: '100px'}}
              onClick={this.handle_send_account_activation_staff}
              className={classes.updateProfileButton}
            >
              Email Staff
            </Button> Send preview email to {this.props.current_staff_email}
            {/* <br/> */}
            {/* {!this.props.enabled && <span>(insufficient security level)</span>} */}
            {this.state.activation_email_sending && <span><br/>(email sending)</span>}
            {this.state.activation_email_sent && <span><br/>(email sent)</span>}
            <br/>
            <span>Email sent on: {emailsent_approved_date ? moment(emailsent_approved_date).format("DD-MM-YYYY @ HH:mm") : "n/a"}</span><br/>
            <span>Email sent by staff member: {emailsent_approved_staff}</span>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ClientEditCommunication.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(staffEditStyles)(ClientEditCommunication));
