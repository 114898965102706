import {connect} from 'react-redux';
import Entities from './Entities';
import {selectEntityUi} from '../../../redux/actions/entities';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    entities: state.entities
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectEntityUi: value => {
      dispatch(selectEntityUi(value));
    }
  };
};

const EntitiesContainer = connect(mapStateToProps, mapDispatchToProps)(Entities);

export default EntitiesContainer;
