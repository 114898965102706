import { SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Flex, Input, Space } from 'antd'

import moment from 'moment/moment';
import React, { useRef } from 'react';

const { RangePicker } = DatePicker;

const ListTable = Component => {

    const HOC = props => {
        const [searchText, setSearchText] = React.useState('');
        const [searchedColumn, setSearchedColumn] = React.useState('');
        const searchInput = useRef();

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
        };

        const handleReset = (clearFilters, confirm) => {
            clearFilters();
            setSearchText('');
            confirm();
        };

        const getColumnSearchProps = ({ dataIndex, label, filterInputType, render = (text) => text, onFilter, searchIndex = [] }) => {
            const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
                switch (filterInputType) {
                    case 'DATE':
                        return dateRangerFilter(selectedKeys, setSelectedKeys, searchInput, dataIndex, handleSearch, confirm, clearFilters, handleReset, setSearchText, setSearchedColumn, close);
                    default:
                        return simpleSearch(selectedKeys, setSelectedKeys, searchInput, dataIndex, handleSearch, confirm, clearFilters, handleReset, setSearchText, setSearchedColumn, close, label);
                }
            };

            const columnProps =  {
                filterIcon: filtered => (
                    filterInputType !== 'SELECT' ? <SearchOutlined
                        style={{
                            color: filtered ? '#1677ff' : undefined
                        }}
                    /> : <span role="img" aria-label="filter" className="anticon anticon-filter"><svg viewBox="64 64 896 896" focusable="false" data-icon="filter"
                                                                                                      width="1em" height="1em" fill="currentColor"
                                                                                                      aria-hidden="true"><path
                        d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"></path></svg></span>
                ),
                onFilter: (value, record) => {
                    if (onFilter != null) {
                        return onFilter(value, record);
                    } else {
                        if (filterInputType === 'DATE' && record[dataIndex] != null) {
                            const [day, month, year] = record[dataIndex].split("/");
                            const realDate = new Date(year, parseInt(month, 10) - 1, day);
                            let dateMoment = moment;
                            let valueDate = dateMoment(realDate, 'DD/MM/YYYY');
                            let fromDate = dateMoment(value[0].toDate(), 'DD/MM/YYYY');
                            let toDate = dateMoment(value[1].toDate(), 'DD/MM/YYYY');
                            return valueDate.isSameOrAfter(fromDate) && valueDate.isSameOrBefore(toDate);
                        }
                        let isReturnRecord = record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toString().toLowerCase()) : false;
                        if (searchIndex != null && searchIndex.length) {
                            return searchIndex.some(i => record[i] != null ? record[i].toString().toLowerCase().includes(value.toString().toLowerCase()) : false)
                        }
                        return isReturnRecord;

                    }
                },
                onFilterDropdownOpenChange: visible => {
                    console.log(searchInput);
                    if (visible) {
                        const focusMethods = {
                            'DATE': 'focus',
                            'INPUT': 'select',
                            'SELECT': 'focus'
                        }
                        const method = focusMethods[filterInputType];
                        setTimeout(() => searchInput.current && searchInput.current[method] && searchInput.current[method](), 100);
                    }
                },
                render: (text, record) => {
                    const parts = text != null ? text.toString().split(new RegExp(`(${searchText})`, 'gi')) : [];
                    return render(
                               searchedColumn === dataIndex
                                   ? <div>
                                       {parts.map((part, index) => (
                                           <span
                                               key={index}
                                               style={{
                                                   backgroundColor: part.toLowerCase() === (searchText || '').toLowerCase() ? 'orange' : 'transparent',
                                                   color: 'black', // Set the text color as needed
                                               }}>{part}</span>
                                       ))}
                                   </div>
                                   : (text), record
                           )
                }
            }
            if (filterInputType !== 'SELECT') {
                columnProps['filterDropdown'] = filterDropdown;
            }
            return columnProps;

        }

        return <Component {...props} getColumnSearchProps={getColumnSearchProps}></Component>;
    };
    return HOC;
};

const optionBasedSearch = (options, selectedKeys, setSelectedKeys, searchInput, dataIndex, handleSearch, confirm, clearFilters, handleReset) => {
    const handleCheckboxChange = (value) => {
        const updatedKeys = selectedKeys.includes(value)
            ? selectedKeys.filter(key => key !== value)
            : [...selectedKeys, value];

        setSelectedKeys(updatedKeys);
    };
    return <>
        <ul style={{listStyle: 'none', paddingLeft: '5px', overflowY: 'scroll', maxHeight: '350px' }}>
            {options.map(item => {
                return <>
                    <li>
                        <Space style={{ padding: 8 }}>
                            <Checkbox checked={selectedKeys.includes(item.value)} onChange={() => handleCheckboxChange(item.value)} /> {item.text}
                        </Space>
                    </li>
                </>
            })}
        </ul>

        <Flex justify={'space-between'} style={{paddingRight: '5px'}}>
            <Button
                type={'link'}
                onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                size='reset'
                style={{
                    width: 90,
                }}>Reset</Button>
            <Button
                type='primary'
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                size='small'>OK</Button>
        </Flex>
    </>
}

const simpleSearch = (selectedKeys, setSelectedKeys, searchInput, dataIndex, handleSearch, confirm, clearFilters, handleReset, setSearchText, setSearchedColumn, close, label = '') => {
    return <div
        style={{
            padding: 8
        }}
        onKeyDown={e => e.stopPropagation()}
    >

        <Input
            ref={searchInput}
            placeholder={`Search ${label}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
                marginBottom: 8,
                display: 'block'
            }}
        />
        <Space>
            <Button
                type='primary'
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size='small'
                style={{
                    width: 90
                }}
            >
                Search
            </Button>
            <Button
                onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                size='small'
                style={{
                    width: 90
                }}
            >
                Reset
            </Button>
            <Button
                type='link'
                size='small'
                onClick={() => {
                    confirm({
                        closeDropdown: false
                    });
                    setSearchText(selectedKeys[0]);
                    setSearchedColumn(dataIndex);
                }}
            >
                Filter
            </Button>
            <Button
                type='link'
                size='small'
                onClick={() => {
                    close();
                }}
            >
                close
            </Button>
        </Space>
    </div>
}

const dateRangerFilter = (selectedKeys, setSelectedKeys, searchInput, dataIndex, handleSearch, confirm, clearFilters, handleReset, setSearchText, setSearchedColumn, close) => {
    return <>
        <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
            <div style={{ marginBottom: '10px', display: 'block' }} >
                <RangePicker
                    ref={searchInput}
                    format={'DD/MM/YYYY'}
                    onChange={e => {
                        setSelectedKeys([e]);
                    }}
                    value={selectedKeys[0]} />
            </div>
            {/*<DatePicker*/}
            {/*    ref={searchInput}*/}
            {/*    format={'DD/MM/YYYY'}*/}
            {/*    placeholder={`Search ${dataIndex}`}*/}
            {/*    value={selectedKeys[0]}*/}
            {/*    onChange={e => {*/}
            {/*        setSelectedKeys([e]);*/}
            {/*    }}*/}
            {/*    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}*/}
            {/*    style={{*/}
            {/*        marginBottom: 8,*/}
            {/*        display: 'block'*/}
            {/*    }} />*/}
            <Space>
                <Button
                    type='primary'
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size='small'
                    style={{
                        width: 90
                    }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                    size='small'
                    style={{
                        width: 90
                    }}
                >
                    Reset
                </Button>
                <Button
                    type='link'
                    size='small'
                    onClick={() => {
                        confirm({
                            closeDropdown: false
                        });
                        setSearchText(selectedKeys[0]);
                        setSearchedColumn(dataIndex);
                    }}
                >
                    Filter
                </Button>
                <Button
                    type='link'
                    size='small'
                    onClick={() => {
                        close();
                    }}
                >
                    close
                </Button>
            </Space>
        </div>
    </>
}

export default ListTable