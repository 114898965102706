import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';

import {cardTitle} from '../../assets/jss/material-dashboard-pro-react.jsx';
import {API} from 'aws-amplify';
import CustomReactSelect from '../Forms/CustomReactSelect/CustomReactSelect';
import {withRouter} from 'react-router-dom';

const entityNewStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  }
};

class DivisionNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nickname: '',
      nickname_state: '',
      system_email: '',
      system_email_state: '',
      entity_id: '',
      staff_admin: '',
      entity_list: [],
      staff_list: []
    };
  }

  componentDidMount() {
    API.get('entities', `/get-list-not-deleted`)
      .then(response => {
        this.setState({
          entity_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });

    API.get('staff', `/get-list-not-deleted`)
      .then(response => {
        this.setState({
          staff_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  validateForm() {
    var valid = true;
    if (this.state.nickname === '') {
      this.setState({nickname_state: 'error'});
      valid = false;
    }
    if (this.state.system_email === '') {
      this.setState({system_email_state: 'error'});
      valid = false;
    }
    if (this.state.entity_id === '') {
      valid = false;
    }
    if (this.state.staff_admin === '') {
      valid = false;
    }
    return valid;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'checkbox':
        if (event.target.checked) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-length':
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'url':
        if (this.verifyUrl(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'min-value':
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'max-value':
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      case 'range':
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({[stateName + 'State']: 'success'});
        } else {
          this.setState({[stateName + 'State']: 'error'});
        }
        break;
      default:
        break;
    }
    switch (type) {
      case 'checkbox':
        this.setState({[stateName]: event.target.checked});
        break;
      default:
        this.setState({[stateName]: event.target.value});
        break;
    }
  }

  handleCustomReactSelectChange = name => value => {
    var option_value;
    if (value === null) {
      option_value = null;
    } else {
      option_value = value.value;
    }
    this.setState({
      [name]: option_value
    });
  };

  async handleSubmitForm() {
    if (this.validateForm()) {
      await this.props.createNewDivision({
        nickname: this.state.nickname,
        system_email: this.state.system_email,
        entity_id: this.state.entity_id,
        staff_admin: this.state.staff_admin
      });
      this.props.history.push(`/system-settings/divisions`);
    }
  }

  handleCancel() {
    this.props.history.push(`/system-settings/divisions`);
  }

  renderForm() {
    const {classes} = this.props;

    const entity_list_select_options = this.state.entity_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));
    const staff_list_select_options = this.state.staff_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));

    return (
      <GridItem xs={12} sm={6}>
        <h3>Enter details for new Division</h3>
        <form>
          <CustomInput
            success={this.state.nickname_state === 'success'}
            error={this.state.nickname_state === 'error'}
            labelText='Nick Name *'
            id='nickname'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.nickname,
              onChange: event => this.change(event, 'nickname', 'length', 0),
              type: 'text'
            }}
          />
          <CustomInput
            success={this.state.system_email_state === 'success'}
            error={this.state.system_email_state === 'error'}
            labelText='System email*'
            id='system_email'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.system_email,
              onChange: event => this.change(event, 'system_email', 'length', 0),
              type: 'text'
            }}
          />
          <CustomReactSelect
            label='Entity'
            options={entity_list_select_options}
            value={this.state.entity_id}
            onChange={this.handleCustomReactSelectChange('entity_id')}
            error={this.state.entity_id === ''}
            isClearable={true}
          />
          <CustomReactSelect
            label='Staff'
            options={staff_list_select_options}
            value={this.state.staff_admin}
            onChange={this.handleCustomReactSelectChange('staff_admin')}
            error={this.state.staff_admin === ''}
            isClearable={true}
          />

          <div className={classes.formCategory}>
            <small>*</small> Required fields
          </div>
          <Button color='rose' onClick={() => this.handleSubmitForm()} className={classes.registerButton}>
            Register
          </Button>
          <Button
            // color=""
            onClick={() => this.handleCancel()}
            className={classes.registerButton}
          >
            Cancel
          </Button>
        </form>
      </GridItem>
    );
  }

  render() {
    return (
      <div>
        <GridContainer>{this.renderForm()}</GridContainer>
      </div>
    );
  }
}

DivisionNew.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(entityNewStyles)(DivisionNew));
