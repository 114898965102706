import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import ReportsTransferContainer from '../../../components/Reports/ReportsTransferContainer.js';

import {FormattedMessage} from 'react-intl';
import ReportsTransferProfit from "./ReportsTransferProfit";

const styles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center'
    },
    description: {
        color: '#999999'
    },
    updateProfileButton: {
        float: 'right'
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none'
    },

    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between'
    }
};

class ReportsTransferProfitContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    //React lifecycle function from here
    componentDidMount() {}
    //end of React lifecycle function

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardHeader color='rose' icon>
                        <CardIcon color='rose'>
                            <PermIdentity />
                        </CardIcon>
                        <div className={classes.flexEnd}>
                            <h4 className={classes.cardIconTitle}>
                                <FormattedMessage id='reportsTransfer.cardItem1.title' defaultMessage={`Transfer Listing`} />
                            </h4>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12}>
                                <ReportsTransferProfit />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(ReportsTransferProfitContainer);
