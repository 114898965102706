import React, { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import { injectIntl } from 'react-intl';
import 'react-dates/initialize';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import { Divider, Radio, Tabs } from 'antd';
import { fetchForwardCommunication, fetchForwardDetails, fetchForwardDrawDowns } from '../../redux/actions/forwards'
import ForwardDetailSummary from '../../views/Dashboard/Forwards/tabComponents/ForwardDetailSummary'
import ForwardDetailDrawDowns from '../../views/Dashboard/Forwards/tabComponents/ForwardDetailDrawDowns'
import ForwardDetailCommunication from '../../views/Dashboard/Forwards/tabComponents/ForwardDetailCommunication'

const styles = { infoItem: {} };

const ForwardsDetail = ({
                            forward,
                            fetching,
                            forwardDrawDown,
                            classes,
                            intl,
                            fetchForwardDetails,
                            fetchForwardDrawDowns,
                            fetchForwardCommunication,
                            forwardCommunication,
                        }) => {
    const { id: forwardId } = useParams();

    useEffect(() => {
        forwardId && fetchForwardDetails(forwardId);
        forwardId && fetchForwardDrawDowns(forwardId);
        forwardId && fetchForwardCommunication(forwardId);
    }, []);

    return fetching || !forward ? (
        <>
            <span>Loading...</span>
        </>
    ) : (
        <>
            <ForwardDetailSummary forward={forward} fetchForwardDetails={fetchForwardDetails}/>
            <Divider/>
            <Tabs type="card" defaultActiveKey={'drawdowns'}
                  size={'large'}
                  style={{marginBottom:32}}
                  items={
                      [
                          {
                              label: 'Draw Downs',
                              key: 'drawdowns',
                              children: <ForwardDetailDrawDowns loading={fetching} forwardDrawDown={forwardDrawDown} />
                          },
                          {
                              label: 'Communications',
                              key: 'communication',
                              children: <ForwardDetailCommunication loading={fetching} forwardId={forwardId} forwardDetailCommunication={forwardCommunication} />
                          }
                      ]
                  }></Tabs>
        </>
    );
};

const mapStateToProps = state => ({
    forward: state.forwards.forwardDetail,
    fetching: state.forwards.fetching,
    forwardDrawDown: state.forwards.forwardDrawDown,
    forwardCommunication: state.forwards.forwardCommunication
});

const mapDispatchToProps = dispatch => ({
    fetchForwardDetails: id => dispatch(fetchForwardDetails(id)),
    fetchForwardDrawDowns: id => dispatch(fetchForwardDrawDowns(id)),
    fetchForwardCommunication: id => dispatch(fetchForwardCommunication(id))
});

const ForwardsDetailComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(injectIntl(withStyles(styles)(ForwardsDetail))));

export default ForwardsDetailComponent;
