import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Button, Image, message, Modal, Upload} from "antd";
import {s3Upload} from "../../libs/awsLib";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {CloseOutlined, EyeOutlined, InboxOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {AppUtil} from "../../AppUtil";
import dayjs from "dayjs";
import {Storage} from 'aws-amplify';
import './uploaders.css';

const unknownFile = require('../../assets/img/unknown_file.png');

const {Dragger} = Upload;

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
/**
 *
 * @param fetchFileList Must be an array of UploadFile[] which is coming from ant Component itself
 * @param dragNDrop
 * @oaran previewOnly
 * @param onUploadSuccess Do something once each of your single file gets uploaded. It will receive the file argument which you can modify and do anything with a url
 * @param onDelete Deletes from your bucket and then receives a file param which you can use to delete it from your database from parent component
 * @constructor
 */
const FileUploader = forwardRef(({
                          fetchFileList = async () => {
                          },
                          previewOnly = false,
                          uploaderType = 'DRAGDROP',
                          thumbType = 'picture-circle',
                          onUploadSuccess = () => {
                          },
                          onDelete = async () => {
                          },
                          onChange
                      }, ref) => {

    const [componentState, setComponentState] = useState({loading: false});
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const breakPoint = useBreakpoint();

    useImperativeHandle(ref, () => ({
        clearList: () => setFileList([])
    }));
    const onFileUpload = async ({file, onSuccess}) => {
        const toast = message.loading("Uploading file. Please wait..", 0);
        const filename = `${dayjs(new Date()).format('YYYYMMDDHHmmss')}_${file.name}`;
        try {
            let newFile = {
                name: filename,
                status: 'uploading',
                uid: filename
            }
            setComponentState(prev => ({...prev, loading: true}));
            if (uploaderType === 'DRAGDROP') {
                setFileList(prev => [...prev, newFile]);
            } else if (uploaderType === 'AVATAR') {
                setFileList([]);
            }
            const attachment = file ? await s3Upload(file) : null;

            getBase64(file, async (url) => {
                newFile = {
                    ...newFile,
                    name: attachment,
                    filename: attachment,
                    thumbUrl: url,
                    uid: attachment,
                    url: url,
                    status: 'done',
                    type: file.type
                };
                setFileList(prev => {
                    const files = prev.filter(item => item.name !== filename);
                    const newFiles = [...files, newFile];
                    if (onChange) {
                        onChange(newFiles);
                    }
                    return newFiles;
                });
                onSuccess(newFile);
                onUploadSuccess(newFile);
                setComponentState(prev => ({...prev, loading: false}));
                message.success(`${newFile.name} file uploaded successfully.`);
            });

        } catch (e) {
            console.log(e);
            const list = fileList.filter(i => i.fileName !== filename);
            const uploadFile = {uid: filename, status: 'error', percent: 100, name: filename};
            setFileList([...list, uploadFile]);
            message.error("Upload Failed");
        } finally {
            toast();
        }
    }

    const onFileRemove = (file) => {
        Modal.confirm({
            title: 'Delete ?',
            content: `Are you sure you want to delete the file named ${file.name}?`,
            okText: 'Delete',
            onOk: () => {
                const toast = message.loading("Deleting file.. Please wait.", 0);
                onDelete(file).then((res) => {
                    console.log(res);
                    // fetchFileList().then(res => {
                    //     if (uploaderType === 'AVATAR') {
                    //         setFileList([])
                    //     } else {
                    //         setFileList([...res])
                    //     }
                    // });
                    const fList = fileList.filter(item => item.uid !== file.uid);
                    setFileList(fList);
                    onChange(fList);
                    message.success("File as been removed");
                }).catch(err => {
                    console.log(err);
                    message.error("Failed to remove the file. Please try again..");
                }).finally(() => toast());
            },
            okButtonProps: {loading: componentState.loading},
            footer: (_, {OkBtn, CancelBtn}) => {
                return <>
                    <OkBtn/>
                    <CancelBtn/>
                </>
            }

        })
    }

    const onFilePreview = async (file) => {
        if (file.thumbUrl == null) {
            const toast = message.loading("Opening file preview. Please wait..", 0);
            try {
                file.thumbUrl = await Storage.get(file.name, {expires: 3600});
            } catch (err) {
                console.log(err);
                message.error("Cannot preview the file. Please try again...");
            } finally {
                toast();
            }
        }

        const data = await AppUtil.getFileInfo(file.thumbUrl);
        if (data.type === 'application/pdf') {
            if (breakPoint.lg || breakPoint.xl) {
                Modal.confirm({
                    width: '90%',
                    wrapClassName: 'pdf-preview-modal',
                    closeIcon: <CloseOutlined/>,
                    icon: null,
                    style: {height: '90%', padding: 0, top: 10},
                    styles: {body: {padding: 0}, content: {padding: 0, margin: 0}},
                    maskClosable: true,
                    footer: null,
                    content: <iframe width={'100%'}
                                     style={{height: '90vh'}}
                                     src={file.thumbUrl}/>
                });
            } else {
                window.open(file.thumbUrl, '_blank');
            }
        } else {
            setPreviewImage(file.url);
        }
    }

    const uploadButton = (
        <button style={{border: 0, background: 'none'}} type="button">
            {componentState.loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{marginTop: 8}}>Upload</div>
        </button>
    );

    const restoreFileList = () => {
        fetchFileList().then(res => {
            if (res && res.length > 0) {
                if (uploaderType === 'AVATAR' || uploaderType === 'PDF') {
                    res.forEach(async item => {
                        if (item.name != null) {
                            const url = await Storage.get(item.name, {expires: 60});
                            const file = await AppUtil.getFileInfo(url);
                            item.type = file.type
                            item.url = url;
                            item.thumbUrl = url;
                        }
                    })
                } else {

                    res = res.map(async (item, index) => {
                        const url = await Storage.get(item.name, {expires: 300});
                        const file = await AppUtil.getFileInfo(url);
                        return {...item, key: index, type: file.type, url: file.url};
                    });
                }
                setFileList([...res]);
            }
        });
    }

    useEffect(() => {
        restoreFileList();
    }, [0]);

    const customItemRender = (originNode, file) => {
        if (file.type === 'application/pdf') {
            return (
                <div className="ant-upload-list-item ant-upload-list-item-done" onClick={() => onFilePreview(file)}>
                    <div className="ant-upload-list-item-info">
                         <span>
                             <a className="ant-upload-list-item-thumbnail">
                                 {
                                     file.type !== 'application/pdf'
                                         ? <img alt='image' src={file.url} style={{ width: '100%', height: '210px' }} />
                                         : <embed src={file.url} style={{ width: '100%', height: '210px' }} />
                                 }
                            </a>
                            <span className="ant-upload-list-item-name">{file.name}</span>
                        </span>
                    </div>
                    <span className="ant-upload-list-item-actions">
                        <Button type="text" icon={<EyeOutlined/>} />
                    </span>
                </div>
            );
        } else if (!file.type || (!(file.type === 'application/pdf' || file.type.startsWith('image/')))) {
            return <div style={{ display: 'flex', flexDirection: 'column', gap: 3, height: '210px' }}>
                <Image src={unknownFile}></Image>
                <Button type={'primary'} icon={<EyeOutlined />} onClick={() => window.open(file.thumbUrl, '_blank')}>Click to View</Button>
            </div>
        }
        return originNode;

    };

    return (
        <>
            {
                uploaderType === 'DRAGDROP' && <Dragger multiple={true}
                                                        accept='.pdf, .jpg, .jpeg, .png'
                                                        customRequest={onFileUpload}
                                                        onRemove={onFileRemove}
                                                        fileList={fileList}
                                                        maxCount={5}
                                                        onPreview={onFilePreview}
                                                        listType={'picture'}>
                    <p className="ant-upload-drag-icon"><InboxOutlined/></p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Drop your files here or click to select files
                    </p>
                </Dragger>
            }
            {
                uploaderType === 'AVATAR' && <>
                    <Upload
                        name="avatar"
                        listType={thumbType}
                        className="avatar-uploader"
                        fileList={fileList}
                        showUploadList={{ showRemoveIcon: !previewOnly }}
                        onPreview={(file) => setPreviewImage(file.thumbUrl)}
                        onRemove={onFileRemove}
                        customRequest={onFileUpload}>
                        {(componentState.loading || fileList.length === 0) && uploadButton}
                    </Upload>
                </>
            }
            {
                uploaderType === 'PDF' && <>
                    <Upload
                        className={'pdf-upload-preview'}
                        itemRender={customItemRender}
                        listType={thumbType}
                        showUploadList={{ showRemoveIcon: !previewOnly }}
                        fileList={fileList}
                        onPreview={(file) => setPreviewImage(file.thumbUrl)}
                        customRequest={onFileUpload}>
                        {(componentState.loading || fileList.length === 0) && uploadButton}
                    </Upload>
                </>
            },
            {previewImage && <Image wrapperStyle={{display: 'none'}} preview={{
                visible: previewImage,
                onVisibleChange: (visible) => setPreviewImage(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(null),
            }} src={previewImage}/>}
        </>
    )
});

export default FileUploader;