import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {
  update_map_data,
  updateNewTransferCreation,
  updateNewTransferCreationBeneficiary
} from '../../../redux/actions/transferSplitPay';
import CloseIcon from '@material-ui/icons/Close';
import {createNewBeneficiary} from '../../../redux/actions/beneficiaries';
import debounce from 'lodash.debounce';
import withStyles from '@material-ui/core/styles/withStyles';
import dashboardStyle from '../../../views/Pages/DashboardPageStyle';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import queryString from 'query-string';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

//core for react-intl
import {FormattedMessage} from 'react-intl';
import CustomReactSelect from '../../Forms/CustomReactSelect/CustomReactSelect';

import {API, Storage} from 'aws-amplify';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import CustomTextField from 'components/CustomInput/CustomTextField.jsx';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react';
import cardPng from '../../../assets/img/payment_24px.png';
import CommoneCurrentComponent from '../../common/Common';
import InLineCurrencyComponent from '../../common/InLineCurrencySelect';
import {withRouter} from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import {isEmpty} from 'libs/miscFunc.js';
import {Divider, IconButton} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const WORLDPAY_ADJUSTMENT = 0.02;
const min_transfer_amount = 0;
const style = {
  ...dashboardStyle,
  ...customCheckboxRadioSwitch,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  infoText: {
    fontWeight: '600',
    fontSize: '24px',
    margin: '10px 0 30px',
    textAlign: 'left'
  },
  primaryB: {
    fontFamily: 'Roboto',
    width: '100%',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    background: '#7C4DFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), -10px -10px 30px rgba(124, 77, 255, 0.3)'
  },
  primaryC: {
    width: '100%',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
    backgroundColor: 'transparent'
  },
  inputAdornmentIcon: {color: '#555'},
  inputAdornment: {position: 'relative'},
  selectControl: {
    backgroundColor: '#00BDAB !important',
    color: 'white',
    padding: '5%',
    width: '100% !important'
  },
  optionStyle: {backgroundColor: '#00BDAB !important'},

  column: {
    paddingRight: '0 !important',
    paddingBottom: '0 !important'
  },
  columnRight: {paddingLeft: '0 !important'},
  boxShadow: {
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    boxSizing: 'border-box'
  },
  input: {
    margin: 0,
    padding: 0,
    border: 'none',
    direction: 'rtl',
    paddingRight: '4%',
    fontWeight: '700',
    fontFamily: 'Roboto',
    fontSize: '24px'
  },
  divv: {
    filter: 'drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25))',
    boxSizing: 'border-box'
  },
  pp: {
    float: 'right',
    paddingRight: '9px',
    padding: '0',
    margin: '0',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '12px',
    paddingTop: '1px'
  },
  iso: {fontSize: '13px !important'},
  isoSpan: {width: '33% !important'},
  textArea: {
    backgroundColor: 'white',
    width: '100%'
  },
  inlineCurrency: {display: 'flex'},
  inputReadOnly: {
    paddingTop: '4px',
    zIndex: '-1000',
    paddingRight: '4%',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '13px',
    margin: 0,
    padding: 0,
    paddingBottom: '0',
    border: 'none',
    direction: 'rtl'
  },
  paddingZeroMarginZero: {
    padding: '0px !important',
    margin: '0px !important'
  },
  transferRate: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '2px',
    paddingLeft: '4%',
    color: '#131313',
    paddingTop: '3%',
    textTransform: 'uppercase'
  },
  splitTransferRate: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#131313',
    padding: '0px',
    margin: '0px'
  },
  selectContainer: {
    padding: '4%',
    background: '#FFFFFF',
    border: '1px solid #0BACF4',
    boxSizing: 'border-box',
    borderRadius: '6px'
  },
  paymentMethod: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '17.04px',
    float: 'right',
    textAlign: 'right',
    opacity: '0.7',
    color: '#131313',
    paddingTop: '4%',
    paddingRight: '3%'
  },
  cardImg: {
    textAlign: 'center',
    paddingLeft: '7% !important'
  },
  transactionOptions: {
    border: 'none',
    width: '100%',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '2px',
    textAlign: 'right',
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
    color: '#0BACF4',
    backgroundColor: 'white'
  },
  benficiaryTitle: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '13px',
    marginBottom: '0 !important',
    paddingBottom: '0 !important'
  },
  beneficiaryDivider: {
    border: '0px solid rgba(0, 0, 0, 0.12)',
    marginTop: '1px !important',
    paddingTop: '0 !important',
    marginBottom: '5% !important'
  },
  createBeneficary: {
    fontFamily: 'Roboto',
    cursor: 'pointer',
    fontWeight: '700',
    fontSize: '16px',
    letterSpacing: '1px',
    color: '#0BACF4'
  },
  currency: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'fit-content',
    '& > .currency-flag': {
      width: 36,
      height: 24
    }
  },
  red: {color: ' red'}
};

class MultiPayTransferStep2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beneficiaries_list: [],
      allList: [],
      disableAll: false,
      purpose_list: [],
      currencies_list_priority: [],
      map_data: {},
      show_iban: false,
      reRender: true,
      show_bsb: false,
      show_sort_code: false,
      show_swift_code: false,
      show_aba: false,
      show_account_number: false,
      country_list: [],
      currencies_list: [],
      //primary
      primary_beneficiary_list: [],
      primary_from_currency: 'AUD',
      primary_to_currency: 'USD',
      primary_from_amount: '1000',
      primary_to_amount: '',
      primary_from_currency_state: 'error',
      primary_adjusted_rate: '',
      primary_to_currency_state: 'error',
      primary_from_amount_state: 'success',
      primary_to_amount_state: 'success',
      primary_amount_to_state: '',
      primary_beneficiary_id: '',
      primary_beneficiary: {},
      primary_purpose_of_payment_detail_state: '',
      primary_purpose_of_payment_other: '',
      primary_purpose_of_payment_detail: '',
      primary_clientTransfers: [
        {
          beneficiary_id: '',
          amount_to: '',
          purpose_of_payment_detail: '',
          beneficiary_id_state: '',
          amount_to_state: '',
          purpose_of_payment_detail_state: '',
          detail_1: '',
          detail_2: '',
          detail_3: '',
          detail_1_state: '',
          detail_2_state: '',
          detail_3_state: '',
          document: '',
          purpose_of_payment_other: '',
          purpose_of_payment_other_state: ''
        }
      ],
      //secondary
      clientTransfers: [
        // {
        //   beneficiary_list: [],
        //   from_currency: 'AUD',
        //   to_currency: 'USD',
        //   from_amount: '1000',
        //   to_amount: '',
        //   from_currency_state: 'error',
        //   adjusted_rate: '',
        //   to_currency_state: 'error',
        //   from_amount_state: 'success',
        //   to_amount_state: 'success',
        //   amount_to_state: '',
        //   beneficiary_id: '',
        //   beneficiary: {},
        //   purpose_of_payment_detail_state: '',
        //   purpose_of_payment_other: '',
        //   purpose_of_payment_detail: '',
        //   amount_to: '',
        //   beneficiary_id_state: '',
        //   detail_1: '',
        //   detail_2: '',
        //   detail_3: '',
        //   detail_1_state: '',
        //   detail_2_state: '',
        //   detail_3_state: '',
        //   document: '',
        //   purpose_of_payment_other_state: ''
        // }
      ],

      showDocumentUpload: false,
      adjusted_rate: ''
    };
  }

  queryStrings() {
    const query_strings = queryString.parse(this.props.location.search);
    if (Object.keys(query_strings).length !== 0) {
      if (query_strings.client_id) {
        if (query_strings.from_amount) {
          this.getClientDetails(Number(query_strings.client_id), 'handleToAmount', query_strings);
        }
        if (query_strings.to_amount) {
          this.getClientDetails(Number(query_strings.client_id), 'handleFromAmount', query_strings);
        }
      }
    }
  }

  componentDidMount() {
    API.get('currencies', '/list_not_deleted')
      .then(response => {
        this.setState({currencies_list_priority: response}, () => {
          this.queryStrings();
        });
      })
      .then(() => {
        API.get('beneficiaries', '/beneficiaries/get')
          .then(response => {
            this.setState(
              {
                beneficiaries_list: response,
                primary_beneficiary_list: [],
                prefill_from_currency: false,
                prefill_to_currency: false,
                prefill_from_amount: false,
                prefill_to_amount: false,
                prefill_beneficiary: false,
                prefill_purpose_of_payment: false,
                prefill_payment_detail: false
              },
              () => {
                // this.preFillFields();
              }
            );
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });

    API.get('countries', '/list_not_deleted')
      .then(response => {
        this.setState({country_list: response});
      })
      .catch(error => {
        console.log(error);
      });

    API.get('currencies', '/get-all')
      .then(response => {
        this.setState({currencies_list: response});
      })
      .catch(error => {
        console.log(error);
      });

    const beneficiary_array = this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id || [{}];
    if (beneficiary_array.length > 0) {
      this.setState({clientTransfers: beneficiary_array});
    }
  }

  defaultCurrencies = (name, option_value) => {
    this.setState({[name]: option_value}, () => {
      switch (this.state.last_user_type_amount_name) {
        case 'from_amount':
          this.handleAmount('to_amount');
          break;
        case 'to_amount':
          this.handleAmount('from_amount');
          break;
        default:
          this.handleAmount();
          this.handlePrimaryAmount();
          break;
      }
    });
    this.setState({[name + '_state']: 'success'});

    if (name === 'from_currency') {
      this.props.updateNewTransferCreation('currency_from_id', option_value);
      this.props.updateNewTransferCreation('currency_from_state', 'success');
      API.get('currencies', `/get/${option_value}`)
        .then(response => {
          this.props.updateNewTransferCreation('currency_from', response);
          this.props.updateNewTransferCreation('currency_from_name', response.iso_alpha_3);
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (name === 'to_currency') {
      this.props.updateNewTransferCreation('currency_to_id', option_value);
      this.props.updateNewTransferCreation('currency_to_state', 'success');
      API.get('currencies', `/get/${option_value}`)
        .then(response => {
          this.props.updateNewTransferCreation('currency_to', response);
          this.props.updateNewTransferCreation('currency_to_name', response.iso_alpha_3);
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (name === 'primary_from_currency') {
      this.props.updateNewTransferCreation('primary_currency_from_id', option_value);
      this.props.updateNewTransferCreation('primary_currency_from_state', 'success');
      API.get('currencies', `/get/${option_value}`)
        .then(response => {
          this.props.updateNewTransferCreation('primary_currency_from', response);
          this.props.updateNewTransferCreation('primary_currency_from_name', response.iso_alpha_3);
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (name === 'primary_to_currency') {
      this.props.updateNewTransferCreation('primary_currency_to_id', option_value);
      this.props.updateNewTransferCreation('primary_currency_to_state', 'success');
      API.get('currencies', `/get/${option_value}`)
        .then(response => {
          this.props.updateNewTransferCreation('primary_currency_to', response);
          this.props.updateNewTransferCreation('primary_currency_to_name', response.iso_alpha_3);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  initializeDefaultCurries = () => {
    this.setState({clientTransfers: []}, () => {
      this.props.updateNewTransferCreation('primary_amount_lock', 'from_amount');
      this.props.updateNewTransferCreation('primary_amount_lock', '1000');
      this.handlePrimaryAmount('primary_to_amount');
      this.defaultCurrencies(
        'from_currency',
        this.props.app_state.current_team && (this.props.app_state.current_team.defaultFromCurrency || 1)
      );
      this.defaultCurrencies(
        'to_currency',
        this.props.app_state.current_team && (this.props.app_state.current_team.defaultToCurrency || 5)
      );
      this.defaultCurrencies(
        'primary_from_currency',
        this.props.app_state.current_team && (this.props.app_state.current_team.defaultFromCurrency || 1)
      );
      this.defaultCurrencies(
        'primary_to_currency',
        this.props.app_state.current_team && (this.props.app_state.current_team.defaultToCurrency || 5)
      );
      this.changeDefault('1000', 'primary_from_amount', 'isLargerThanNumber', min_transfer_amount);
      this.setState({});
    });
  };

  changeDefault(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'isLargerThanNumber':
        if (this.isLargerThanNumber(event, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event});
    // Calculate "to amount"
    if (stateName === 'primary_from_amount') {
      this.setState({last_user_type_amount_name: 'primary_from_amount'});
      this.props.updateNewTransferCreation('primary_amount_from', event);
      // this.handleAmount('primary_to_amount');
    }
    // Calculate "from amount"
    if (stateName === 'primary_to_amount') {
      this.setState({last_user_type_amount_name: 'primary_to_amount'});
      this.props.updateNewTransferCreation('primary_amount_to', event);
      this.props.updateNewTransferCreation('amount_to', event);
      // this.handleAmount('primary_from_amount');
    }
    // if (stateName === "amount_to") {
    //   this.fetchNewRate()
    // }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.prefill !== this.props.prefill) {
      this.componentDidMount();
    }
    if (prevProps.transferSplitPay.new_transfer_data.client && this.state.reRender) {
      this.initializeDefaultCurries();
      this.setState({reRender: false});
    }
    if (
      this.props.app_state.current_team &&
      this.state.purpose_list.length == 0 &&
      this.props.app_state.current_team !== prevProps.app_state.current_team
    ) {
      API.get('transfers', `/get_purpose_by_team/id/${this.props.app_state.current_team.id}`)
        .then(response => {
          console.log('response', response);
          this.setState({purpose_list: response});
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (
      this.props.transferSplitPay.new_transfer_data.primary_currency_to_id &&
      this.props.transferSplitPay.new_transfer_data.primary_currency_to_id !==
        prevProps.transferSplitPay.new_transfer_data.primary_currency_to_id
    ) {
      API.get('beneficiaries', `/get/by_client_id/${this.props.transferSplitPay.new_transfer_data.client_id}`)
        .then(response => {
          const filterer_beneficiaries_list = response;

          const primary_beneficiary_list = JSON.parse(JSON.stringify(response));
          const allList = JSON.parse(JSON.stringify(response));
          if (filterer_beneficiaries_list.length > 0) {
            for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
              if (
                filterer_beneficiaries_list[i].account_currency !=
                this.props.transferSplitPay.new_transfer_data.currency_to_id
              ) {
                filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
              }
              if (
                primary_beneficiary_list[i].account_currency !=
                this.props.transferSplitPay.new_transfer_data.primary_currency_to_id
              ) {
                primary_beneficiary_list.splice(i, 1); // Removes the (i)th element of response
              }
            }
          }
          this.setState({
            // beneficiaries_list: response
            beneficiaries_list: filterer_beneficiaries_list,
            primary_beneficiary_list: primary_beneficiary_list,
            beneficiaries_list_Empty: true,
            allList: allList
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  componentWillUnmount() {}

  async addFirstClick() {
    await this.setState(
      prevState => ({
        clientTransfers: [
          ...prevState.clientTransfers,
          {
            beneficiary_id: '',
            amount: '',
            purposeOfPayment: '',
            beneficiary_id_state: '',
            amount_state: '',
            purposeOfPayment_state: '',
            detail_1: '',
            client_rate: 0,
            currency_from: '',
            currency_from_id: '',
            currency_from_name: '',
            currency_from_state: 'success',
            detail_2: '',
            detail_3: '',
            detail_1_state: '',
            detail_2_state: '',
            detail_3_state: '',
            document: '',
            purpose_of_payment_other: '',
            purpose_of_payment_other_state: '',
            beneficiary: {},
            showDocumentUpload: false
          }
        ]
      }),
      () => {
        this.state.clientTransfers.map((t, index) => {
          API.get('currencies', `/get/${this.props.transferSplitPay.new_transfer_data.primary_currency_to_id}`)
            .then(response => {
              this.state.clientTransfers.map((t, index) => {
                this.handleFromOfSplit(
                  'from_currency',
                  index,
                  this.props.transferSplitPay.new_transfer_data.primary_currency_to_id,
                  response
                );
              });
              this.props.updateNewTransferCreation('primary_currency_to', response);
              this.props.updateNewTransferCreation('primary_currency_to_name', response.iso_alpha_3);
            })
            .catch(error => {
              console.log(error);
            });
        });
      }
    );

    this.props.updateNewTransferCreation('multiple_beneficiary_id', this.state.clientTransfers);
  }

  async addClick() {
    await this.setState(prevState => ({
      clientTransfers: [
        ...prevState.clientTransfers,
        {
          beneficiary_id: '',
          amount: '',
          purposeOfPayment: '',
          beneficiary_id_state: '',
          amount_state: '',
          purposeOfPayment_state: '',
          detail_1: '',
          client_rate: prevState.clientTransfers[0].client_rate,
          currency_from: prevState.clientTransfers[0].currency_from,
          currency_from_id: prevState.clientTransfers[0].currency_from_id,
          currency_from_name: prevState.clientTransfers[0].currency_from_name,
          currency_from_state: 'success',
          detail_2: '',
          detail_3: '',
          detail_1_state: '',
          detail_2_state: '',
          detail_3_state: '',
          document: '',
          purpose_of_payment_other: '',
          purpose_of_payment_other_state: '',
          beneficiary: {},
          showDocumentUpload: false
        }
      ]
    }));

    this.props.updateNewTransferCreation('multiple_beneficiary_id', this.state.clientTransfers);
  }

  removeClick(i) {
    const clientTransfers = [...this.state.clientTransfers];

    clientTransfers.splice(i, 1);
    this.setState({clientTransfers});

    let total_amount = 0;
    let tmpValue = 0;

    clientTransfers.map((el, index) => {
      if (el.amount !== undefined) {
        tmpValue = el.amount.replace(/,/g, '');
        total_amount = Number.parseFloat(total_amount) + Number.parseFloat(tmpValue);
      }
    });
    this.setState({amount_to: total_amount});
    this.props.updateNewTransferCreation('amount_to', total_amount);
    this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
  }

  clearBeneficiaryDetails() {
    const beneficiary_array = this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id || [{}];
    beneficiary_array[0].beneficiary = {};
    beneficiary_array[0].beneficiary_id = '';
    beneficiary_array[0].beneficiary_id_state = '';
    this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if a value is larger than a number or not
  isLargerThanNumber(value, number) {
    if (value !== '' && value >= number) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'isLargerThanNumber':
        if (this.isLargerThanNumber(event.target.value, stateNameEqualTo)) {
          if (stateName === 'from_amount') {
            this.props.updateNewTransferCreation('amount_from_state', 'success');
          }
          if (stateName === 'to_amount') {
            this.props.updateNewTransferCreation('amount_to_state', 'success');
          }
        } else {
          if (stateName === 'from_amount') {
            this.props.updateNewTransferCreation('amount_from_state', 'error');
          }
          if (stateName === 'to_amount') {
            this.props.updateNewTransferCreation('amount_to_state', 'error');
          }
        }
        break;
      default:
        break;
    }
    // Calculate "to amount"
    if (stateName === 'from_amount') {
      this.props.updateNewTransferCreation('amount_lock', 'from_amount');
      this.props.updateNewTransferCreation('amount_from', event.target.value);
      this.handleAmount('to_amount');
    }
    // Calculate "from amount"
    if (stateName === 'to_amount') {
      this.props.updateNewTransferCreation('amount_lock', 'to_amount');
      this.props.updateNewTransferCreation('amount_to', event.target.value);
      this.handleAmount('from_amount');
    }
  }

  changePrimary(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'isLargerThanNumber':
        if (this.isLargerThanNumber(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
    // Calculate "to amount"
    if (stateName === 'primary_from_amount') {
      this.setState({last_user_type_amount_name: 'primary_from_amount'});
      this.props.updateNewTransferCreation('primary_amount_from', event.target.value);
      this.delayedPrimaryCallBack('primary_to_amount');
    }
    // Calculate "from amount"
    if (stateName === 'primary_to_amount') {
      this.setState({last_user_type_amount_name: 'primary_to_amount'});
      this.props.updateNewTransferCreation('primary_amount_to', event.target.value);
      this.delayedPrimaryCallBack('primary_from_amount');
    }
  }

  handlePrimaryAmount(amount_name) {
    let from_currency_Iso = 'AUD';
    let to_currency_Iso = 'USD';

    this.state.currencies_list_priority.map((item, index) => {
      if (item.id == this.state.primary_from_currency) {
        from_currency_Iso = item.iso_alpha_3;
      }
      if (item.id == this.state.primary_to_currency) {
        to_currency_Iso = item.iso_alpha_3;
      }
    });

    const url = 'https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
    axios
      .get(url, {
        params: {
          currencies: to_currency_Iso,
          source: from_currency_Iso,
          format: '1'
        }
      })
      .then(response => {
        const key = from_currency_Iso + to_currency_Iso;
        const rate_string = response.data.quotes[key];

        const rate = Number.parseFloat(rate_string);
        const rate_adjust =
          Number.parseFloat(this.props.transferSplitPay.new_transfer_data.client.default_rate) +
          Number.parseFloat(this.props.transferSplitPay.new_transfer_data.client.default_rate_division) +
          Number.parseFloat(this.props.transferSplitPay.new_transfer_data.client.default_rate_entity) +
          Number.parseFloat(this.props.transferSplitPay.new_transfer_data.client.default_rate_team) +
          Number.parseFloat(this.props.transferSplitPay.new_transfer_data.client.default_rate_staff);
        let adjusted_rate = rate - rate * rate_adjust;
        // Add Credit Card Rate Adjustment
        if (this.props.transferSplitPay.new_transfer_data.payment_method === 'worldpay') {
          adjusted_rate = adjusted_rate - adjusted_rate * WORLDPAY_ADJUSTMENT;
        }

        if (this.state.primary_from_currency == this.state.primary_to_currency) {
          adjusted_rate = rate;
        }

        if (this.state.from_currency_state === 'success' && this.state.to_currency_state === 'success') {
          // handle to_amount
          if (amount_name === 'primary_to_amount' && this.state.primary_from_amount_state === 'success') {
            const primary_amount_from = Number.parseFloat(this.state.primary_from_amount);
            const primary_amount_to = primary_amount_from * adjusted_rate;

            this.setState({primary_to_amount: primary_amount_to.toFixed(2)});
            this.props.updateNewTransferCreation('primary_amount_to', primary_amount_to.toFixed(2));
            this.props.updateNewTransferCreation('amount_to', primary_amount_to.toFixed(2));
            if (primary_amount_to >= min_transfer_amount) {
              this.setState({primary_to_amount_state: 'success'});
            } else {
              this.setState({primary_to_amount_state: 'error'});
            }
          }

          // handle primary_from_amount
          if (amount_name === 'primary_from_amount' && this.state.primary_to_amount_state === 'success') {
            const primary_amount_to = Number.parseFloat(this.state.primary_to_amount);
            const primary_amount_from = primary_amount_to / adjusted_rate;
            this.props.updateNewTransferCreation('primary_amount_to', primary_amount_to.toFixed(2));
            this.props.updateNewTransferCreation('amount_to', primary_amount_to.toFixed(2));
            this.setState({
              primary_from_amount: primary_amount_from.toFixed(2),
              primary_to_amount: (adjusted_rate * primary_amount_from).toFixed(2)
            });
            this.props.updateNewTransferCreation('primary_amount_from', primary_amount_from.toFixed(2));
            if (primary_amount_from >= min_transfer_amount) {
              this.setState({primary_from_amount_state: 'success'});
            } else {
              this.setState({primary_from_amount_state: 'error'});
            }
          }
        }
        console.info('adjusted rate', adjusted_rate);
        this.setState({primary_adjusted_rate: adjusted_rate});
        this.props.updateNewTransferCreation('primary_client_rate', adjusted_rate);
      })
      .catch(error => {
        console.log(error);
      });
  }

  getBeneficiaryDetails(beneficiary_id) {
    API.get('beneficiaries', `/get/id/${beneficiary_id}`)
      .then(response => {
        this.setState({beneficiary: response});
        this.props.updateNewTransferCreation('beneficiary', response);

        this.setState({
          beneficiary: {
            ...this.state.beneficiary,
            account_currency_nickname: `${response.full_name} [${response.iso_alpha_3}]`
          }
        });

        return response;
      })
      .then(response => {
        API.get('system', `/currencies/get/${response.account_currency}`)
          .then(response => {
            this.setState({
              beneficiary: {
                ...this.state.beneficiary,
                account_currency_nickname: `${response.full_name} [${response.iso_alpha_3}]`
              }
            });

            API.post('email', '/interaction/main', {
              body: {
                interaction: 'New Transfer Request Step 2 PayingTo',
                data: {
                  client_id: this.props.transfers_2.new_transfer_data.client_id,
                  client_nickname: this.props.transfers_2.new_transfer_data.nickname,
                  beneficiary_id: this.state.beneficiary.id,
                  beneficiary_nickname: this.state.beneficiary.nickname
                },
                result: 'New transfer request proceeding'
              }
            });
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleAmount(amount_name, ind) {
    if (ind == undefined) {
      ind = 0;
    }
    let from_currency_Iso = '';
    let to_currency_Iso = '';
    const clientTransfers = [...this.state.clientTransfers];
    this.state.currencies_list_priority.map((item, index) => {
      if (
        this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id.length > 0 &&
        item.id == this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[ind].currency_from_id
      ) {
        from_currency_Iso = item.iso_alpha_3;
      }

      if (
        this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id.length > 0 &&
        item.id == this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[ind].currency_to_id
      ) {
        to_currency_Iso = item.iso_alpha_3;
      }
    });

    const url = 'https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
    axios
      .get(url, {
        params: {
          currencies: to_currency_Iso,
          source: from_currency_Iso,
          format: '1'
        }
      })
      .then(response => {
        const key = from_currency_Iso + to_currency_Iso;
        const rate_string = response.data.quotes[key];

        const rate = Number.parseFloat(rate_string);

        const rate_adjust =
          Number.parseFloat(this.props.transferSplitPay.new_transfer_data.client.default_rate) +
          Number.parseFloat(this.props.transferSplitPay.new_transfer_data.client.default_rate_division) +
          Number.parseFloat(this.props.transferSplitPay.new_transfer_data.client.default_rate_entity) +
          Number.parseFloat(this.props.transferSplitPay.new_transfer_data.client.default_rate_team) +
          Number.parseFloat(this.props.transferSplitPay.new_transfer_data.client.default_rate_staff);
        let adjusted_rate = rate - rate * rate_adjust;
        if (
          this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[ind].currency_from_id ==
          this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[ind].currency_to_id
        ) {
          adjusted_rate = rate;
        }

        if (
          this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[ind].currency_from_state ===
            'success' &&
          this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[ind].currency_to_state === 'success'
        ) {
          // handle to_amount
          if (
            amount_name === 'to_amount' &&
            this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[ind].amount_from_state === 'success'
          ) {
            const amount_from = Number.parseFloat(
              this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[ind].amount_from
            );
            const amount_to = amount_from * adjusted_rate;
            clientTransfers[ind] = {
              ...clientTransfers[ind],
              amount_to: amount_to.toFixed(2)
            };

            if (amount_to >= min_transfer_amount) {
              clientTransfers[ind] = {
                ...clientTransfers[ind],
                amount_to_state: 'success'
              };
            } else {
              clientTransfers[ind] = {
                ...clientTransfers[ind],
                amount_to_state: 'error'
              };
            }
          }

          // handle from_amount
          if (
            amount_name === 'from_amount' &&
            this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[ind].amount_to_state === 'success'
          ) {
            const amount_to = Number.parseFloat(
              this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[ind].amount_to
            );
            const amount_from = amount_to / adjusted_rate;
            clientTransfers[ind] = {
              ...clientTransfers[ind],
              amount_from: amount_from.toFixed(2)
            };

            let totalSplitamount = 0;
            clientTransfers.map((el, index) => {
              totalSplitamount += Number.parseFloat(el.amount_from);
            });
            if (this.props.transferSplitPay.new_transfer_data.amount_to - totalSplitamount >= 0) {
              this.props.updateNewTransferCreation(
                'primary_amount_to',
                this.props.transferSplitPay.new_transfer_data.amount_to - totalSplitamount
              );
              this.changePrimary(
                {target: {value: this.props.transferSplitPay.new_transfer_data.amount_to}},
                'primary_to_amount',
                'isLargerThanNumber',
                min_transfer_amount
              );
            } else {
              // this.props.updateNewTransferCreation('primary_amount_to', totalSplitamount);
              this.props.updateNewTransferCreation('amount_to', totalSplitamount);
              this.changePrimary(
                {target: {value: totalSplitamount}},
                'primary_to_amount',
                'isLargerThanNumber',
                min_transfer_amount
              );
              // this.handlePrimaryAmount('primary_from_amount');
            }
            if (amount_from >= min_transfer_amount) {
              clientTransfers[ind] = {
                ...clientTransfers[ind],
                amount_from_state: 'success'
              };
              // this.props.updateNewTransferCreation('amount_from_state', 'success');
            } else {
              clientTransfers[ind] = {
                ...clientTransfers[ind],
                amount_from_state: 'error'
              };
              // this.props.updateNewTransferCreation('amount_from_state', 'error');
            }
          }
        }
        clientTransfers[ind] = {
          ...clientTransfers[ind],
          client_rate: adjusted_rate
        };
        this.setState(state => {
          state.clientTransfers = clientTransfers;
        });
        this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
      })
      .catch(error => {
        console.log(error);
      });
  }

  handlePrimaryCustomReactSelectChange = (name, index, previous_from_currency, previous_to_currency) => value => {
    let option_value;
    if (value === null) {
      option_value = null;
    } else {
      option_value = value.value;
    }

    this.setState({[name]: option_value}, () => {
      switch (this.state.last_user_type_amount_name) {
        case 'primary_from_amount':
          this.handlePrimaryAmount('primary_to_amount');
          break;
        case 'primary_to_amount':
          this.handlePrimaryAmount('primary_from_amount');
          break;
        default:
          this.handlePrimaryAmount();
      }
    });
    this.setState({[name + '_state']: 'success'});

    if (name === 'primary_from_currency') {
      this.props.updateNewTransferCreation('primary_currency_from_id', option_value);
      this.props.updateNewTransferCreation('primary_currency_from_state', 'success');
      API.get('currencies', `/get/${option_value}`)
        .then(response => {
          this.props.updateNewTransferCreation('primary_currency_from', response);
          this.props.updateNewTransferCreation('primary_currency_from_name', response.iso_alpha_3);
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (name === 'primary_to_currency') {
      this.props.updateNewTransferCreation('primary_currency_to_id', option_value);
      this.props.updateNewTransferCreation('primary_currency_to_state', 'success');
      API.get('currencies', `/get/${option_value}`)
        .then(response => {
          this.state.clientTransfers.map((t, index) => {
            this.handleFromOfSplit('from_currency', index, option_value, response);
          });
          this.props.updateNewTransferCreation('primary_currency_to', response);
          this.props.updateNewTransferCreation('primary_currency_to_name', response.iso_alpha_3);
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (name == 'beneficiary_id') {
      const i = 0;
      const primary_clientTransfers = [...this.state.primary_clientTransfers];
      primary_clientTransfers[i] = {
        ...primary_clientTransfers[i],
        [name]: option_value
      };
      // this.setState({primary_clientTransfers});
      this.props.updateNewTransferCreation('primary_multiple_beneficiary_id', primary_clientTransfers);

      API.get('beneficiaries', `/get/id/${option_value}`)
        .then(response => {
          primary_clientTransfers[i] = {
            ...primary_clientTransfers[i],
            beneficiary: response
          };
          this.setState({primary_clientTransfers});
          this.props.updateNewTransferCreation('primary_multiple_beneficiary_id', primary_clientTransfers);
        })
        .catch(error => {
          console.log(error);
          API.get('beneficiaries', `/get/id/${option_value}`)
            .then(response => {
              primary_clientTransfers[i] = {
                ...primary_clientTransfers[i],
                beneficiary: response
              };
              this.setState({primary_clientTransfers});
              this.props.updateNewTransferCreation('primary_multiple_beneficiary_id', primary_clientTransfers);
            })
            .catch(error => {
              console.log(error);
            });
        });

      // this.getBeneficiaryDetails(option_value);
    }
    if (name === 'purpose_of_payment_detail') {
      const i = 0;
      const primary_clientTransfers = [...this.state.primary_clientTransfers];
      primary_clientTransfers[i] = {
        ...primary_clientTransfers[i],
        [name]: option_value
      };
      this.setState({primary_clientTransfers});

      this.props.updateNewTransferCreation('primary_multiple_beneficiary_id', primary_clientTransfers);
      console.log(value);
      this.props.updateNewTransferCreation('primary_purpose_of_payment_detail_text', value.label);
    }

    this.props.updateNewTransferCreation(name, option_value);
  };

  handleFromOfSplit = (name, index, option_value, response) => {
    if (name === 'from_currency') {
      const clientTransfers = [...this.state.clientTransfers];
      clientTransfers[index] = {
        ...clientTransfers[index],
        currency_from_id: option_value,
        currency_from_state: 'success'
      };
      this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
      clientTransfers[index] = {
        ...clientTransfers[index],
        currency_from: response,
        currency_from_name: response.is_alpha_3
      };
      this.setState(
        {
          clientTransfers,
          disableAll: false
        },
        () => {
          this.handleAmount('to_amount', index);
        }
      );
      this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
    }
  };
  handleCustomReactSelectChange = (name, index, previous_from_currency, previous_to_currency) => async value => {
    let option_value;
    let option_label;
    if (value === null) {
      option_value = null;
      option_label = null;
    } else {
      option_value = value.value;
      option_label = value.label;
    }

    if (name === 'bank_country') {
      const country_dataset = this.state.country_list;
      for (let i = 0; i < country_dataset.length; i++) {
        if (country_dataset[i].id === option_value) {
          console.table(country_dataset[i]);
          this.setState({
            show_swift_code: true,
            show_account_number: true
          });

          //iban check
          if (country_dataset[i].use_iban === 1) {
            this.setState({show_iban: true});
          } else {
            this.setState({show_iban: false});
          }

          //bsb check
          if (country_dataset[i].use_bsb === 1) {
            this.setState({show_bsb: true});
          } else {
            this.setState({show_bsb: false});
          }

          //aba_routing check
          if (country_dataset[i].use_aba === 1) {
            this.setState({show_aba: true});
          } else {
            this.setState({show_aba: false});
          }

          //sort_code check
          if (country_dataset[i].use_sort_code === 1) {
            this.setState({show_sort_code: true});
          } else {
            this.setState({show_sort_code: false});
          }
          //account_number check
          if (country_dataset[i].use_account_number === 1) {
            this.setState({show_account_number: true});
          } else {
            this.setState({show_account_number: false});
          }

          // transit_code
          if (country_dataset[i].use_transit_code === 1) {
            this.setState({show_transit_code: true});
          } else {
            this.setState({show_transit_code: false});
          }

          // bank_code
          if (country_dataset[i].use_bank_code === 1) {
            this.setState({show_bank_code: true});
          } else {
            this.setState({show_bank_code: false});
          }
        }
      }
    }

    if (name === 'account_currency' || name == 'to_currency') {
      //do something here
      const currency_dataset = this.state.currencies_list_priority;
      for (let i = 0; i < currency_dataset.length; i++) {
        if (currency_dataset[i].id === option_value) {
          //iban check
          if (currency_dataset[i].iban === 1) {
            this.setState({show_iban: true});
          } else {
            this.setState({show_iban: false});
          }
          //bsb check
          if (currency_dataset[i].bsb === 1) {
            this.setState({show_bsb: true});
          } else {
            this.setState({show_bsb: false});
          }
          //sort_code check
          if (currency_dataset[i].sort_code === 1) {
            this.setState({show_sort_code: true});
          } else {
            this.setState({show_sort_code: false});
          }
          //swift_code check
          if (currency_dataset[i].swift_code === 1) {
            this.setState({show_swift_code: true});
          } else {
            this.setState({show_swift_code: false});
          }
          //aba_routing check
          if (currency_dataset[i].aba_routing === 1) {
            this.setState({show_aba: true});
          } else {
            this.setState({show_aba: false});
          }
          //account number check
          if (currency_dataset[i].account_number === 1) {
            this.setState({show_account_number: true});
          } else {
            this.setState({show_account_number: false});
          }
        }
      }
    }

    if (name !== 'from_currency' && name !== 'to_currency') {
      this.setState(
        {
          [name]: option_value,
          disableAll: true
        },
        () => {
          if (name === 'from_currency' || name === 'to_currency') {
            switch (this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[index].amount_lock) {
              case 'from_amount':
                this.handleAmount('to_amount', index);
                break;
              case 'to_amount':
                this.handleAmount('from_amount', index);
                break;
              default:
                this.handleAmount('', index);
            }
          }
        }
      );
    }

    if (name === 'from_currency' || name === 'to_currency') {
      // this.updateMapData(name, previous_from_currency, previous_to_currency, value);
      const clientTransfers = [...this.state.clientTransfers];

      if (name === 'from_currency') {
        clientTransfers[index] = {
          ...clientTransfers[index],
          currency_from_id: option_value,
          currency_from_state: 'success'
        };
        this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
        API.get('currencies', `/get/${option_value}`)
          .then(response => {
            clientTransfers[index] = {
              ...clientTransfers[index],
              currency_from: response,
              currency_from_name: response.is_alpha_3
            };
            this.setState(
              {
                clientTransfers,
                disableAll: false,
                [name]: option_value
              },
              () => {
                if (name === 'from_currency' || name === 'to_currency') {
                  switch (this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[index].amount_lock) {
                    case 'from_amount':
                      this.handleAmount('to_amount', index);
                      break;
                    case 'to_amount':
                      this.handleAmount('from_amount', index);
                      break;
                    default:
                      this.handleAmount('', index);
                  }
                }
              }
            );
            this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
          })
          .catch(error => {
            this.setState({disableAll: false});
            console.log(error);
          });
      }
      if (name === 'to_currency') {
        // this.setState({account_currency: option_value});
        clientTransfers[index] = {
          ...clientTransfers[index],
          currency_to_id: option_value,
          currency_to_state: 'success'
        };
        const [response, currResponse] = await Promise.all([
          API.get('beneficiaries', `/get/by_client_id/${this.props.transferSplitPay.new_transfer_data.client_id}`),
          API.get('currencies', `/get/${option_value}`)
        ]);
        const resume_beneficiaries_list = response;
        const primary_beneficiary_list = JSON.parse(JSON.stringify(response));
        if (resume_beneficiaries_list.length > 0) {
          //remove items from beneficiary-list by not related this to_currency items
          for (let i = resume_beneficiaries_list.length - 1; i > -1; i--) {
            if (resume_beneficiaries_list[i].account_currency !== option_value) {
              //response.remove(i);
              resume_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
              //console.log("remove index "+i)
            }
            if (
              primary_beneficiary_list[i].account_currency !=
              this.props.transferSplitPay.new_transfer_data.primary_currency_to_id
            ) {
              primary_beneficiary_list.splice(i, 1); // Removes the (i)th element of response
            }
          }
        }
        clientTransfers[index] = {
          ...clientTransfers[index],
          beneficiaries_list: resume_beneficiaries_list,
          currency_to: currResponse,
          currency_to_name: currResponse.iso_alpha_3,
          client_rate: this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[index].client_rate
        };
        this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
        this.setState(
          {
            beneficiaries_list: resume_beneficiaries_list,
            primary_beneficiary_list: primary_beneficiary_list,
            account_currency: option_value,
            clientTransfers: clientTransfers,
            [name]: option_value
          },
          () => {
            if (name === 'from_currency' || name === 'to_currency') {
              this.handleAmount('to_amount', index);
            }
          }
        );
      }
    }
    if (name == 'beneficiary_id') {
      const clientTransfers = [...this.state.clientTransfers];
      clientTransfers[index] = {
        ...clientTransfers[index],
        [name]: option_value,
        [name + '_state']: 'success'
      };
      this.props.updateNewTransferCreation('beneficiary_id', clientTransfers);
      API.get('beneficiaries', `/get/id/${option_value}`)
        .then(response => {
          clientTransfers[index] = {
            ...clientTransfers[index],
            beneficiary: response
          };
          this.setState(
            {
              clientTransfers,
              disableAll: false
            },
            () => {
              this.props.updateNewTransferCreation('beneficiary_id', clientTransfers);
            }
          );
        })
        .catch(error => {
          console.log(error);
          API.get('beneficiaries', `/get/id/${option_value}`)
            .then(response => {
              clientTransfers[index] = {
                ...clientTransfers[index],
                beneficiary: response
              };
              this.setState({
                clientTransfers,
                disableAll: false
              });
              this.props.updateNewTransferCreation('beneficiary_id', clientTransfers);
            })
            .catch(error => {
              console.log(error);
            });
        });
    }

    if (name === 'purpose_of_payment_detail') {
      const clientTransfers = [...this.state.clientTransfers];
      clientTransfers[index] = {
        ...clientTransfers[index],
        [name]: option_value
      };
      clientTransfers[index] = {
        ...clientTransfers[index],
        [name + '_state']: 'success'
      };
      let document_upload_show = false;
      for (const key in this.state.purpose_list) {
        if (this.state.purpose_list[key].id == option_value) {
          if (this.state.purpose_list[key].document_upload == 1) {
            document_upload_show = true;
          }
        }
      }

      clientTransfers[index] = {
        ...clientTransfers[index],
        showDocumentUpload: document_upload_show
      };
      this.setState({
        clientTransfers,
        disableAll: false
      });
      this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
    }
  };

  handleCheckboxToggle = name => event => {
    const beneficiary_array = this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id || [{}];
    beneficiary_array[0].checked_beneficiary_details = event.target.checked;
    this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
    if (event.target.checked === true) {
      this.clearBeneficiaryDetails();
    }
  };

  updateMapData(currency_name, previous_from_currency, previous_to_currency, currency_object) {
    const map_values = this.state.map_data;
    const from_currency_map_value = 0;
    const to_currency_map_value = 1;

    // update the properties for visulization
    if (currency_name === 'from_currency') {
      // delele previous currency property in map_values
      if (previous_from_currency !== previous_to_currency) {
        this.deleleCurrency(map_values, previous_from_currency, previous_to_currency);
      }
      // add new currency property in map_values
      this.addCurrency(map_values, currency_object.value, from_currency_map_value);
    }
    if (currency_name === 'to_currency') {
      // delele previous currency property in map_values
      if (previous_to_currency !== previous_from_currency) {
        this.deleleCurrency(map_values, previous_to_currency, previous_from_currency);
      }
      // add new currency property in map_values
      this.addCurrency(map_values, currency_object.value, to_currency_map_value);
    }
    this.setState({map_data: map_values});
    this.props.update_map_data(map_values);
  }

  deleleCurrency(map_values, currency_deleted, the_other_currency) {
    switch (currency_deleted) {
      case 1: // AUD
        delete map_values.AU; // Australia
        break;
      case 5: // USD
        delete map_values.US; // United States
        break;
      case 8: // GBP
        // if the other currency is not Euro, delete GB
        if (the_other_currency !== 9) {
          delete map_values.GB; // United Kingdom
        }
        break;
      case 9: // Eur. For Europe: italy, spain, france, portugal, germany
        // if the other currency is not GB, delete GB
        if (the_other_currency !== 8) {
          delete map_values.GB; // United Kingdom
        }
        delete map_values.IT; // Italy
        delete map_values.ES; // Spain
        delete map_values.FR; // France
        delete map_values.PT; // Portugal
        delete map_values.DE; // Germany
        break;
      case 6: // ATS
        delete map_values.AT; // 	Austria
        break;
      case 16:
        delete map_values.JP;
        break;
      case 17:
        delete map_values.NZ;
        break;
      case 18:
        delete map_values.CA;
        break;
      case 19:
        delete map_values.HK;
        break;
      case 20:
        delete map_values.SG;
        break;
      case 21:
        delete map_values.CH;
        break;
      case 22:
        delete map_values.TH;
        break;
      case 23:
        delete map_values.DK;
        break;
      case 24:
        delete map_values.NO;
        break;
      case 25:
        delete map_values.SE;
        break;
      case 30:
        delete map_values.NP;
        break;
      case 31:
        delete map_values.VN;
        break;
        default:
    }
  }

  addCurrency(map_values, currency, currency_map_value) {
    switch (currency) {
      case 1:
        map_values.AU = currency_map_value;
        break;
      case 5:
        map_values.US = currency_map_value;
        break;
      case 8:
        map_values.GB = currency_map_value;
        break;
      case 9:
        map_values.GB = currency_map_value;
        map_values.IT = currency_map_value;
        map_values.ES = currency_map_value;
        map_values.FR = currency_map_value;
        map_values.PT = currency_map_value;
        map_values.DE = currency_map_value;
        break;
      case 6:
        map_values.AT = currency_map_value;
        break;
      case 16:
        map_values.JP = currency_map_value;
        break;
      case 17:
        map_values.NZ = currency_map_value;
        break;
      case 18:
        map_values.CA = currency_map_value;
        break;
      case 19:
        map_values.HK = currency_map_value;
        break;
      case 20:
        map_values.SG = currency_map_value;
        break;
      case 21:
        map_values.CH = currency_map_value;
        break;
      case 22:
        map_values.TH = currency_map_value;
        break;
      case 23:
        map_values.DK = currency_map_value;
        break;
      case 24:
        map_values.NO = currency_map_value;
        break;
      case 25:
        map_values.SE = currency_map_value;
        break;
      case 30:
        map_values.NP = currency_map_value;
        break;
      case 31:
        map_values.VN = currency_map_value;
        break;
        default:
    }
  }

  onRemove = index => {
    const beneficiary_array = this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id || [{}];
    beneficiary_array[0].document = null;
    this.props.updateNewTransferCreationBeneficiary(beneficiary_array);
  };

  render_beneficiary_details_section = () => {
    const {classes} = this.props;
    if (
      this.props.transferSplitPay.new_transfer_data.currency_from_state !== 'success' ||
      this.props.transferSplitPay.new_transfer_data.currency_to_state !== 'success' ||
      this.props.transferSplitPay.new_transfer_data == undefined
    ) {
      return null;
    }
    const newTransferData = this.props.transferSplitPay.new_transfer_data;
    const item = this.state.currencies_list_priority.find(x => x.id === newTransferData.primary_currency_to_id);

    let splitInputValue = 0;
    newTransferData.multiple_beneficiary_id.map(i => {
      splitInputValue += parseFloat(i.amount_from || 0);
    });

    return (
      <React.Fragment>
        <GridContainer justify='flex-start'>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              <FormattedMessage id='splitPay.headingSecond' defaultMessage={'Primary Transfer'} />
            </h4>
            <h3>
              <b>
                Balance {item && <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />}{' '}
                &nbsp;
                <span className={classes.red}>{newTransferData.primary_currency_to_name}</span>
                &nbsp;
                {newTransferData.primary_amount_to &&
                  (
                    Number.parseFloat(this.props.transferSplitPay.new_transfer_data.primary_amount_to) - splitInputValue
                  ).toFixed(2)}
              </b>
            </h3>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            {this.createUI()}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={6}>
            <GridContainer justify='flex-start'>
              <GridItem xs={12} sm={6}>
                {this.state.clientTransfers.length == 0 ? (
                  <span className={classes.createBeneficary} key='addPayoutKey' onClick={this.addFirstClick.bind(this)}>
                    Add new payout record
                  </span>
                ) : (
                  <span className={classes.createBeneficary} key='addPayoutKey' onClick={this.addClick.bind(this)}>
                    Add new payout record
                  </span>
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  };

  handleSelectChange = event => {
    this.setState({[event.target.name]: event.target.value});
    this.props.updateNewTransferCreation(event.target.name, event.target.value);
    if (event.target.name === 'beneficiary_id') {
      this.getBeneficiaryDetails(event.target.value);
    }
  };

  isValidateBeneficiary() {
    if (this.state.bank_country == '' || typeof this.state.bank_country === 'undefined') {
      this.setState({bank_country_state: 'error'});
    } else {
      this.setState({bank_country_state: 'success'});
    }

    if (this.state.ben_legal_name == '' || typeof this.state.ben_legal_name === 'undefined') {
      this.setState({ben_legal_name_state: 'error'});
    } else {
      this.setState({ben_legal_name_state: 'success'});
    }

    if (this.state.account_currency == '' || typeof this.state.account_currency === 'undefined') {
      this.setState({account_currency_state: 'error'});
    } else {
      this.setState({account_currency_state: 'success'});
    }

    if (
      this.state.ben_legal_name == '' ||
      typeof this.state.ben_legal_name === 'undefined' ||
      this.state.account_currency == '' ||
      typeof this.state.account_currency === 'undefined'
    ) {
      return false;
    }
    return true;
  }

  createBeneficiaryIntoDatabase = async data => {
    await this.props.createNewBeneficiary(data);
    const th = this;
    setTimeout(async () => {
      await API.get('beneficiaries', `/get/by_client_id/${this.props.transferSplitPay.new_transfer_data.client.id}`)
        .then(response => {
          const filterer_beneficiaries_list = JSON.parse(JSON.stringify(response));
          const primary_beneficiary_list = JSON.parse(JSON.stringify(response));
          if (filterer_beneficiaries_list.length > 0) {
            for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
              if (
                filterer_beneficiaries_list[i].account_currency !=
                this.props.transferSplitPay.new_transfer_data.currency_to_id
              ) {
                filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
              }
              if (
                primary_beneficiary_list[i].account_currency !=
                this.props.transferSplitPay.new_transfer_data.primary_currency_to_id
              ) {
                primary_beneficiary_list.splice(i, 1); // Removes the (i)th element of response
              }
            }
          }

          // change split pay
          const client_Transfer = this.state.clientTransfers;
          //currency_to_id
          client_Transfer.map(item => {
            const list = JSON.parse(JSON.stringify(response));
            if (list.length > 0) {
              for (let i = list.length - 1; i > -1; i--) {
                if (list[i].account_currency != item.currency_to_id) {
                  list.splice(i, 1); // Removes the (i)th element of response
                }
              }
            }

            item.beneficiaries_list = list;
          });

          th.setState({
            beneficiaries_list: filterer_beneficiaries_list,
            primary_beneficiary_list: primary_beneficiary_list,
            beneficiaries_list_Empty: true,
            clientTransfers: client_Transfer
          });
        })
        .catch(error => {
          console.log(error);
        });
    }, 2000);

    this.setState({
      ben_legal_name: '',
      ben_email_main: '',
      ben_address_line_1: '',
      ben_address_line_2: '',
      ben_address_suburb: '',
      ben_address_state: '',
      ben_address_postcode: '',
      ben_address_country: null,

      bank_legal_name: '',
      iban: '',
      account_currency: '',
      bsb_code: '',
      swift_code: '',
      account_number: '',
      aba_routing_number: '',
      sort_code: '',
      bank_code: '',
      transit_code: ''
    });
    return;
  };

  finished = async () => {
    if (this.isValidateBeneficiary()) {
      const {
        bank_country,
        ben_legal_name,
        ben_email_main,
        ben_address_line_1,
        ben_address_line_2,
        ben_address_suburb,
        ben_address_state,
        ben_address_postcode,
        ben_address_country,
        bank_legal_name,
        iban,
        account_currency,
        bsb_code,
        swift_code,
        account_number,
        aba_routing_number,
        sort_code,
        bank_code,
        transit_code
      } = this.state;

      try {
        await this.createBeneficiaryIntoDatabase({
          client_id: this.props.transferSplitPay.new_transfer_data.client.id,
          nickname: ben_legal_name,
          ben_legal_name: ben_legal_name,
          ben_email_main: ben_email_main,
          ben_address_line_1: ben_address_line_1,
          ben_address_line_2: ben_address_line_2,
          ben_address_suburb: ben_address_suburb,
          ben_address_state: ben_address_state,
          ben_address_postcode: ben_address_postcode,
          ben_address_country: ben_address_country,
          bank_legal_name: bank_legal_name,
          bank_address_country: bank_country,
          iban: iban,
          account_currency: account_currency,
          bsb_code: bsb_code,
          swift_code: swift_code,
          account_number: account_number,
          aba_routing_number: aba_routing_number,
          sort_code: sort_code,
          bank_code: bank_code,
          transit_code: transit_code
        });
        this.setState({addBeneficiaryModalOpen: false});
      } catch (e) {
        console.log('errro', e);
        this.setState({isLoading: false});
      }
    }
  };

  addBeneficiaryModelOpen = () => {
    this.setState({addBeneficiaryModalOpen: true});
  };

  addBeneficiaryModelClose = () => {
    this.setState({
      addBeneficiaryModalOpen: false,
      ben_legal_name: '',
      ben_email_main: '',
      ben_address_line_1: '',
      ben_address_line_2: '',
      ben_address_suburb: '',
      ben_address_state: '',
      ben_address_postcode: '',
      ben_address_country: null,
      bank_country: '',
      bank_legal_name: '',
      iban: '',
      bsb_code: '',
      swift_code: '',
      account_number: '',
      aba_routing_number: '',
      sort_code: '',
      bank_code: '',
      transit_code: ''
    });
  };

  newBeneficiaryChange(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
  }
  handleChangePrimary(e, i, stateName, type, criteria) {
    const {name, value} = e.target;
    // const primaryClientTransfers = [...this.state.primaryClientTransfers];
    // primaryClientTransfers[i] = {
    //   ...primaryClientTransfers[i],
    //   [stateName]: value
    // };

    const primary_clientTransfers = [...this.state.primary_clientTransfers];
    primary_clientTransfers[i] = {
      ...primary_clientTransfers[i],
      [stateName]: value
    };
    if (stateName == 'detail_1') {
      this.props.updateNewTransferCreation('primary_multiple_beneficiary_id', primary_clientTransfers);
      this.setState({primary_clientTransfers});
    }
  }
  handleChange(e, i, stateName, type, criteria) {
    const {name, value} = e.target;
    const clientTransfers = [...this.state.clientTransfers];
    clientTransfers[i] = {
      ...clientTransfers[i],
      [stateName]: value
    };

    switch (type) {
      case 'isLargerThanNumber':
        if (this.isLargerThanNumber(value, criteria)) {
          clientTransfers[i] = {
            ...clientTransfers[i],
            [stateName + '_state']: 'success'
          };
        } else {
          clientTransfers[i] = {
            ...clientTransfers[i],
            [stateName + '_state']: 'error'
          };
        }
        break;
      default:
        break;
    }

    if (stateName === 'purpose_of_payment_other') {
      if (value !== '') {
        clientTransfers[i] = {
          ...clientTransfers[i],
          [stateName + '_state']: 'success'
        };
      } else {
        clientTransfers[i] = {
          ...clientTransfers[i],
          [stateName + '_state']: 'error'
        };
      }
    }
    this.setState({clientTransfers});
    this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);

    if (stateName == 'amount') {
      const clientTransfers = [...this.state.clientTransfers];
      let total_amount = 0;
      let tmpValue = 0;
      const index = i;

      clientTransfers.map((el, index) => {
        if (index == i) {
          if (isEmpty(value)) {
            tmpValue = 0;
          } else {
            tmpValue = value.replace(/,/g, '');
          }
          total_amount = Number.parseFloat(total_amount) + Number.parseFloat(tmpValue);
        } else {
          if (isEmpty(el.amount)) {
            tmpValue = 0;
          } else {
            tmpValue = el.amount.replace(/,/g, '');
          }
          total_amount = Number.parseFloat(total_amount) + Number.parseFloat(tmpValue);
        }
      });
      clientTransfers[index] = {
        ...clientTransfers[index],
        amount_to: total_amount
      };

      if (total_amount > min_transfer_amount) {
        if (
          this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[index].amount_to_state !== 'success'
        ) {
          clientTransfers[index] = {
            ...clientTransfers[index],
            amount_to_state: 'success'
          };
        }
      } else {
        clientTransfers[index] = {
          ...clientTransfers[index],
          amount_to_state: 'error'
        };
      }
      this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);

      this.setState({clientTransfers}, () => {
        this.delayedCallback(index);
      });
    }
    if (stateName == 'from_amount') {
      const clientTransfers = [...this.state.clientTransfers];
      let total_amount = 0;
      let tmpValue = 0;
      const index = i;

      clientTransfers.map((el, index) => {
        if (index == i) {
          if (isEmpty(value)) {
            tmpValue = 0;
          } else {
            tmpValue = value.replace(/,/g, '');
          }
          total_amount = Number.parseFloat(total_amount) + Number.parseFloat(tmpValue);
        } else {
          if (isEmpty(el.amount)) {
            tmpValue = 0;
          } else {
            tmpValue = el.amount.replace(/,/g, '');
          }
          total_amount = Number.parseFloat(total_amount) + Number.parseFloat(tmpValue);
        }
      });
      clientTransfers[index] = {
        ...clientTransfers[index],
        amount_from: total_amount
      };

      if (total_amount >= min_transfer_amount) {
        if (
          this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[index].amount_to_state !== 'success'
        ) {
          clientTransfers[index] = {
            ...clientTransfers[index],
            amount_from_state: 'success'
          };
        }
      } else {
        clientTransfers[index] = {
          ...clientTransfers[index],
          amount_from_state: 'error'
        };
        this.props.updateNewTransferCreation(
          'primary_amount_to',
          this.props.transferSplitPay.new_transfer_data.amount_to
        );
      }
      this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);

      this.setState({clientTransfers}, () => {
        this.delayedCallback2(index);
      });
    }
    if (stateName === 'purpose_of_payment_detail') {
      this.setState({FIXME: value == 1});
    }
  }

  componentWillMount() {
    this.delayedCallback = debounce(function (index) {
      this.handleAmount('from_amount', index);
    }, 1000);
    this.delayedCallback2 = debounce(function (index) {
      this.handleAmount('to_amount', index);
    }, 1000);
    this.delayedPrimaryCallBack = debounce(function (stateName) {
      this.handlePrimaryAmount(stateName);
    }, 1000);
  }

  createUI() {
    const {classes} = this.props;
    // For CustomRea6ctSelect. Generate select options for dropdown list.
    const select_currency_select_options = this.state.currencies_list_priority.map(item => ({
      value: item.id,
      label: (
        <React.Fragment>
          <div className={classes.currency}>
            <span className={` currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
            &nbsp;
            {item.iso_alpha_3}
          </div>
        </React.Fragment>
      )
    }));

    let transferRateCondition = '';

    try {
      if (!isNaN(this.props.transferSplitPay.new_transfer_data.client_rate)) {
        transferRateCondition = 'dashboard.transferRate';
      } else {
        transferRateCondition = 'dashboard.blank';
      }
    } catch (error) {}

    const beneficiary_list_select_options = this.state.beneficiaries_list
      .sort((a, b) => {
        if (a.nickname && b.nickname) {
          return a.nickname.localeCompare(b.nickname);
        }
        return a.id > b.id;
      })
      .map(item => ({
        value: item.id,
        label: item.nickname
      }));

    const purpose_of_payment_select_options = this.state.purpose_list.map(item => ({
      value: item.id,
      label: item.description
    }));

    return this.state.clientTransfers.map((el, i) => (
      <Fragment>
        <GridContainer>
          <GridItem xs={6}>
            <GridContainer
              justify='flex-start'
              style={{
                maxWidth: '100%',
                flexBasis: '100%'
              }}
            >
              <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: '30px'}}>
                <GridContainer className='paddingZeroMarginZero'>
                  <GridItem xs={5} className='paddingZeroMarginZero'>
                    <InLineCurrencyComponent
                      selectLabel='From currency'
                      inputColor='black'
                      // defaultValue={0}
                      color='white'
                      selectOptions={select_currency_select_options}
                      selectValue={el.currency_from_id}
                      isClearable={false}
                      inputIsDisabled={false}
                      onSelectChange={this.handleCustomReactSelectChange(
                        'from_currency',
                        i,
                        el.currency_from_id,
                        el.currency_to_id
                      )}
                      inputValue='From amount'
                      inputValue2={el.amount_from}
                      isDisabled={true}
                      onInputChange={event => {
                        // this.change(event, 'from_amount', 'isLargerThanNumber', min_transfer_amount);
                        this.handleChange(event, i, 'from_amount', 'isLargerThanNumber', min_transfer_amount);
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} className='paddingZeroMarginZero' style={{textAlign: 'center'}}>
                    <p className={classes.splitTransferRate}>
                      {!isNaN(el.client_rate) ? `${el.currency_from.iso_alpha_3}/${el.currency_to_name}:` : ''}
                    </p>
                    <p className={classes.splitTransferRate}>
                      {!isNaN(el.client_rate) ? Number.parseFloat(el.client_rate).toFixed(5) : ''}
                    </p>
                  </GridItem>
                  <GridItem xs={5} className='paddingZeroMarginZero'>
                    <InLineCurrencyComponent
                      color='white'
                      inputColor='black'
                      selectLabel={
                        <span>
                          <FormattedMessage id='dashboard.toCurrency' defaultMessage={'To currency '} />
                        </span>
                      }
                      selectOptions={select_currency_select_options}
                      selectValue={el.currency_to_id}
                      isClearable={false}
                      isDisabled={false}
                      inputIsDisabled={false}
                      inputValue2={
                        // isNaN(el.amount_to) ? 'Must be a number greater than ' + min_transfer_amount : el.amount_to
                        el.amount_to
                      }
                      onSelectChange={this.handleCustomReactSelectChange(
                        'to_currency',
                        i,
                        el.currency_from_id,
                        el.currency_to_id
                      )}
                      inputValue='To amount'
                      onInputChange={event => {
                        this.handleChange(event, i, 'amount', 'isLargerThanNumber', min_transfer_amount);
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6}>
            <GridContainer justify='flex-start' alignItems='center'>
              <GridItem xs={12} sm={10}>
                <Card>
                  <CardBody>
                    <GridContainer justify='flex-start'>
                      <GridItem xs={12} sm={6}>
                        <CustomReactSelect
                          error={el.beneficiary_id_state === 'error'}
                          label='Select beneficiary'
                          options={
                            el.beneficiaries_list &&
                            el.beneficiaries_list
                              .sort((a, b) => {
                                if (a.nickname && b.nickname) {
                                  return a.nickname.localeCompare(b.nickname);
                                }
                                return a.id > b.id;
                              })
                              .map(item => ({
                                value: item.id,
                                label: item.nickname
                              }))
                          }
                          value={el.beneficiary_id}
                          onChange={this.handleCustomReactSelectChange('beneficiary_id', i)}
                          isClearable={false}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <CustomReactSelect
                          error={el.purpose_of_payment_detail_state === 'error'}
                          label='Purpose of payment'
                          options={purpose_of_payment_select_options}
                          value={el.purpose_of_payment_detail}
                          onChange={this.handleCustomReactSelectChange('purpose_of_payment_detail', i)}
                          isClearable={false}
                          // isDisabled={this.state.prefill_purpose_of_payment}
                        />

                        {el.purpose_of_payment_detail === 1 && (
                          <CustomInput
                            style={{paddingTop: 25}}
                            success={el.purpose_of_payment_other_state === 'success'}
                            error={el.purpose_of_payment_other_state === 'error'}
                            labelText={<span>Specify purpose of payment</span>}
                            id='purpose_of_payment_other'
                            name='purpose_of_payment_other'
                            formControlProps={{fullWidth: true}}
                            inputProps={{
                              value: el.purpose_of_payment_other,
                              onChange: event => this.handleChange(event, i, 'purpose_of_payment_other')
                            }}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <div
                  style={{
                    textAlign: 'center',
                    paddingTop: 25
                  }}
                >
                  <Button size='sm' color='warning' key={i} disabled={i === 0} onClick={this.removeClick.bind(this, i)}>
                    X
                  </Button>
                </div>
              </GridItem>
              <hr />
            </GridContainer>
            <Button size='sm' color='info' key='addKey' onClick={() => this.addBeneficiaryModelOpen()}>
              Create new beneficiary
            </Button>
          </GridItem>
        </GridContainer>
        <hr />
      </Fragment>

      // <GridContainer justify="flex-start" alignItems="center">
      //   <GridItem xs={12} sm={10}>
      //     <Card>
      //       <CardBody>
      //         <GridContainer justify="flex-start">
      //           <GridItem xs={12} sm={6}>
      //             <CustomReactSelect
      //               error={el.beneficiary_id_state === 'error'}
      //               label="Select beneficiary"
      //               options={beneficiary_list_select_options}
      //               value={el.beneficiary_id}
      //               onChange={this.handleCustomReactSelectChange('beneficiary_id', i)}
      //               isClearable={false}
      //             />
      //           </GridItem>
      //           <GridItem xs={12} sm={6}>
      //             <CustomReactSelect
      //               error={el.purpose_of_payment_detail_state === 'error'}
      //               label="Purpose of payment"
      //               options={purpose_of_payment_select_options}
      //               value={el.purpose_of_payment_detail}
      //               onChange={this.handleCustomReactSelectChange('purpose_of_payment_detail', i)}
      //               isClearable={false}
      //               // isDisabled={this.state.prefill_purpose_of_payment}
      //             />

      //             {el.purpose_of_payment_detail === 1 && (
      //               <CustomInput
      //                 style={{paddingTop: 25}}
      //                 success={el.purpose_of_payment_other_state === 'success'}
      //                 error={el.purpose_of_payment_other_state === 'error'}
      //                 labelText={<span>Specify purpose of payment</span>}
      //                 id="purpose_of_payment_other"
      //                 name="purpose_of_payment_other"
      //                 formControlProps={{fullWidth: true}}
      //                 inputProps={{
      //                   value: el.purpose_of_payment_other,
      //                   onChange: event => this.handleChange(event, i, 'purpose_of_payment_other')
      //                 }}
      //               />
      //             )}
      //           </GridItem>
      //         </GridContainer>
      //         <GridContainer justify="flex-start">
      //           <GridItem xs={12} sm={6}>
      //             <CustomInput
      //               success={el.detail_1_state === 'success'}
      //               error={el.detail_1_state === 'error'}
      //               labelText={<span>Invoice number</span>}
      //               id="detail_1"
      //               name="detail_1"
      //               formControlProps={{fullWidth: true}}
      //               inputProps={{
      //                 value: el.detail_1,
      //                 onChange: event => this.handleChange(event, i, 'detail_1')
      //               }}
      //             />
      //           </GridItem>
      //           <GridItem xs={12} sm={6}>
      //             <CustomInput
      //               success={el.amount_state === 'success'}
      //               error={el.amount_state === 'error'}
      //               labelText={<span>Amount</span>}
      //               id="amount"
      //               labelProps={{shrink: true}}
      //               formControlProps={{fullWidth: true}}
      //               inputProps={{
      //                 value: el.amount,
      //                 onChange: event =>
      //                   this.handleChange(event, i, 'amount', 'isLargerThanNumber', min_transfer_amount)
      //               }}
      //             />
      //           </GridItem>
      //         </GridContainer>

      //         <GridContainer justify="flex-start">
      //           <GridItem xs={12} sm={6}>
      //             <CustomInput
      //               success={el.detail_1_state === 'success'}
      //               error={el.detail_1_state === 'error'}
      //               labelText={<span>Team Commision %</span>}
      //               id="detail_1"
      //               name="detail_1"
      //               formControlProps={{fullWidth: true}}
      //               inputProps={{
      //                 type: 'number',
      //                 value: el.teamCommissionPercent,
      //                 onChange: event => this.handleChange(event, i, 'teamCommissionPercent')
      //               }}
      //             />
      //           </GridItem>
      //           <GridItem xs={12} sm={6}>
      //             <CustomInput
      //               success={el.amount_state === 'success'}
      //               error={el.amount_state === 'error'}
      //               labelText={<span>Team Commision Fee</span>}
      //               id="amount"
      //               labelProps={{shrink: true}}
      //               formControlProps={{fullWidth: true}}
      //               inputProps={{
      //                 type: 'number',
      //                 value: el.teamCommissionFee,
      //                 onChange: event => this.handleChange(event, i, 'teamCommissionFee')

      //                 // onBlur: event => this.handleBlur(event, i, "amount")
      //               }}
      //               // helpText={this.state.beneficiary.account_currency_nickname ? <span>{this.state.beneficiary.account_currency_nickname}</span> : ""}
      //             />
      //           </GridItem>
      //         </GridContainer>
      //       </CardBody>
      //     </Card>
      //   </GridItem>
      //   <GridItem xs={12} sm={2}>
      //     <div
      //       style={{
      //         textAlign: 'center',
      //         paddingTop: 25
      //       }}
      //     >
      //       <Button size="sm" color="warning" key={i} disabled={i === 0} onClick={this.removeClick.bind(this, i)}>
      //         X
      //       </Button>
      //     </div>
      //   </GridItem>
      //   <hr />
      // </GridContainer>
    ));
  }

  getInstructions = () => {
    return (
      <React.Fragment>
        <GridContainer>
          {this.state.bank_country_full_name && (
            <GridItem xs={12} sm={12}>
              <p>
                When transfering to &nbsp;
                <img
                  src={
                    this.state.bank_country_iso_alpha_2 !== ''
                      ? require(`../../../assets/img/flags/${this.state.bank_country_iso_alpha_2}.png`)
                      : ''
                  }
                />{' '}
                &nbsp;
                <strong>{this.state.bank_country_full_name}</strong>, we require
              </p>
              <ul>
                <li>Bank name</li>
                {this.state.show_iban && <li>IBAN</li>}
                {this.state.show_swift_code && <li>SWIFT Code</li>}
                {this.state.show_aba && <li>ABA Routing Number</li>}
                {this.state.show_sort_code && <li>Sort Code</li>}
                {this.state.show_bsb && <li>BSB</li>}
                {this.state.show_transit_code && <li>Transit &amp; Institution No</li>}
                {this.state.show_bank_code && <li>Bank Code</li>}
                {this.state.show_account_number && <li>Account Number</li>}
              </ul>
            </GridItem>
          )}
        </GridContainer>
      </React.Fragment>
    );
  };

  createNewBeneficiaryUi = () => {
    const {classes} = this.props;
    const country_list_select_options = this.state.country_list.map(item => ({
      value: item.id,
      label: (
        <React.Fragment>
          <span>
            <img src={item.iso_alpha_2 !== '' ? require(`../../../assets/img/flags/${item.iso_alpha_2}.png`) : ''} />
          </span>{' '}
          {' ' + item.iso_alpha_2 + ': ' + item.full_name}{' '}
        </React.Fragment>
      )
    }));

    const select_currency_select_options = this.state.currencies_list_priority.map(item => ({
      value: item.id,
      label: (
        <React.Fragment>
          <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
          &nbsp;
          {item.iso_alpha_3 + ': ' + item.full_name}
        </React.Fragment>
      )
    }));

    return (
      <React.Fragment>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          maxWidth='lg'
          open={this.state.addBeneficiaryModalOpen}
          transition={Transition}
          keepMounted
          onClose={() => this.addBeneficiaryModelClose()}
          aria-labelledby='modal-slide-title'
          aria-describedby='modal-slide-description'
        >
          <DialogTitle
            id='classic-modal-slide-title'
            disableTypography
            style={{backgroundColor: '#E0E0E0'}}
            onClose={() => this.addBeneficiaryModelClose()}
            className={classes.modalHeader}
          >
            <GridContainer justify='flex-start'>
              <GridItem xs={10} md={10}>
                <h4 className={classes.modalTitle2}>Add New Beneficiary</h4>
              </GridItem>
              <GridItem xs={2} md={2} style={{textAlign: 'right'}}>
                <IconButton edge='start' color='inherit' onClick={this.addBeneficiaryModelClose} aria-label='close'>
                  <CloseIcon />
                </IconButton>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            style={{backgroundColor: '#E0E0E0'}}
            id='modal-slide-description'
            className={classes.modalBody}
          >
            <GridContainer justify='flex-start'>
              <GridItem xs={12} md={6}>
                <CustomReactSelect
                  label='Currency'
                  options={select_currency_select_options}
                  value={this.state.account_currency}
                  onChange={this.handleCustomReactSelectChange('account_currency')}
                  style={{
                    dropdownIndicator: base => ({
                      ...base,
                      color: 'gray' // Custom colour
                    })
                  }}
                  isClearable={false}
                />
                <br />
                <CustomReactSelect
                  label='Bank Country'
                  options={country_list_select_options}
                  value={this.state.bank_country}
                  style={{
                    dropdownIndicator: base => ({
                      ...base,
                      color: 'gray' // Custom colour
                    })
                  }}
                  onChange={this.handleCustomReactSelectChange('bank_country')}
                  isClearable={false}
                  error={this.state.bank_country_state === 'error'}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                {this.getInstructions()}
              </GridItem>
            </GridContainer>
            <br />
            <br />
            <br />
            <GridContainer justify='flex-start'>
              <GridItem xs={6}>
                <p className={classes.benficiaryTitle}>Beneficiary details</p>
                <Divider className={classes.beneficiaryDivider} />
                <GridContainer>
                  <GridItem xs={12} md={12}>
                    <CustomTextField
                      success={this.state.ben_legal_name_state === 'success'}
                      error={this.state.ben_legal_name_state === 'error'}
                      labelText={<span>Beneficiary (Account) Name</span>}
                      id='first_name'
                      formControlProps={{
                        fullWidth: true,
                        style: {
                          marginLeft: '0',
                          borderRadius: '0px'
                        }
                      }}
                      inputProps={{
                        value: this.state.ben_legal_name,
                        variant: 'outlined',
                        size: 'small',
                        label: <span>Name</span>,
                        style: {backgroundColor: 'white'},
                        onChange: event => this.newBeneficiaryChange(event, 'ben_legal_name', 'length', 3)
                      }}
                    />
                    <CustomTextField
                      success={this.state.ben_address_line_1_state === 'success'}
                      error={this.state.ben_address_line_1_state === 'error'}
                      labelText={<span>Address Line 1</span>}
                      id='ben_address_line_1'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: this.state.ben_address_line_1,
                        variant: 'outlined',
                        style: {backgroundColor: 'white'},
                        size: 'small',
                        label: <span>Address</span>,
                        onChange: event => this.newBeneficiaryChange(event, 'ben_address_line_1', 'length', 3)
                      }}
                    />
                    <CustomTextField
                      success={this.state.ben_address_suburb_state === 'success'}
                      error={this.state.ben_address_suburb_state === 'error'}
                      labelText={<span>Suburb / Region</span>}
                      id='ben_address_suburb'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: this.state.ben_address_suburb,
                        variant: 'outlined',
                        style: {backgroundColor: 'white'},
                        size: 'small',
                        label: <span>Suburb / Region</span>,
                        onChange: event => this.newBeneficiaryChange(event, 'ben_address_suburb', 'length', 3)
                      }}
                    />
                    <CustomTextField
                      success={this.state.ben_address_postcode_state === 'success'}
                      error={this.state.ben_address_postcode_state === 'error'}
                      labelText={<span>Postcode</span>}
                      id='ben_address_postcode'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: this.state.ben_address_postcode,
                        variant: 'outlined',
                        style: {backgroundColor: 'white'},
                        size: 'small',
                        label: <span>Postcode</span>,
                        onChange: event => this.newBeneficiaryChange(event, 'ben_address_postcode', 'length', 3)
                      }}
                    />
                    <CustomTextField
                      success={this.state.ben_email_main_state === 'success'}
                      error={this.state.ben_email_main_state === 'error'}
                      labelText={<span>Beneficiary Email [optional]</span>}
                      // helpText="May be used to provide copy of payout confirmation to beneficiary.  Confirmation is required from you on each transfer."
                      id='ben_email_main'
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        value: this.state.ben_email_main,
                        variant: 'outlined',
                        style: {backgroundColor: 'white'},
                        size: 'small',
                        label: <span>Beneficiary Email (optional)</span>,
                        onChange: event => this.newBeneficiaryChange(event, 'ben_email_main', 'length', 3)
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={6}>
                <p className={classes.benficiaryTitle}>Payment Details</p>
                <Divider className={classes.beneficiaryDivider} />
                <GridContainer justify='flex-start'>
                  <GridItem xs={12}>
                    <CustomTextField
                      success={this.state.bank_legal_name_state === 'success'}
                      error={this.state.bank_legal_name_state === 'error'}
                      labelText={<span>Bank Name</span>}
                      style={{borderRadius: '0px'}}
                      id='bank_legal_name'
                      formControlProps={{
                        fullWidth: true,
                        style: {borderRadius: '0px'}
                      }}
                      inputProps={{
                        value: this.state.bank_legal_name,
                        variant: 'outlined',
                        style: {
                          backgroundColor: 'white',
                          borderRadius: '0px'
                        },
                        size: 'small',
                        label: <span>Bank Name</span>,
                        onChange: event => this.newBeneficiaryChange(event, 'bank_legal_name', 'length', 3)
                      }}
                    />
                    {this.state.show_iban && (
                      <CustomTextField
                        success={this.state.iban === 'success'}
                        error={this.state.iban === 'error'}
                        labelText={<span>IBAN</span>}
                        id='iban'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          variant: 'outlined',
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '0px'
                          },
                          size: 'small',
                          value: this.state.iban,
                          onChange: event => this.newBeneficiaryChange(event, 'iban', 'length', 3)
                        }}
                      />
                    )}
                    {this.state.show_swift_code && (
                      <CustomTextField
                        success={this.state.swift_code_state === 'success'}
                        error={this.state.swift_code_state === 'error'}
                        labelText={<span>Swift Code</span>}
                        id='swift_code'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          value: this.state.swift_code,
                          variant: 'outlined',
                          style: {backgroundColor: 'white'},
                          size: 'small',
                          label: <span>Swift Code</span>,
                          onChange: event => this.newBeneficiaryChange(event, 'swift_code', 'length', 3)
                        }}
                      />
                    )}
                    {this.state.show_aba && (
                      <CustomTextField
                        success={this.state.aba_routing_number_state === 'success'}
                        error={this.state.aba_routing_number_state === 'error'}
                        labelText={<span>ABA Routing Number</span>}
                        id='aba_routing_number'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          variant: 'outlined',
                          style: {backgroundColor: 'white'},
                          size: 'small',
                          label: <span>ABA Routing Number</span>,
                          value: this.state.aba_routing_number,
                          onChange: event => this.newBeneficiaryChange(event, 'aba_routing_number', 'length', 3)
                        }}
                      />
                    )}

                    {this.state.show_sort_code && (
                      <CustomTextField
                        success={this.state.sort_code_state === 'success'}
                        error={this.state.sort_code_state === 'error'}
                        labelText={<span>Sort Code</span>}
                        id='sort_code'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          value: this.state.sort_code,
                          variant: 'outlined',
                          style: {backgroundColor: 'white'},
                          size: 'small',
                          label: <span>Sort Code</span>,
                          onChange: event => this.newBeneficiaryChange(event, 'sort_code', 'length', 3)
                        }}
                      />
                    )}
                    {this.state.show_bsb && (
                      <CustomTextField
                        success={this.state.bsb_code_state === 'success'}
                        error={this.state.bsb_code_state === 'error'}
                        labelText={<span>BSB</span>}
                        id='bsb_code'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          value: this.state.bsb_code,
                          variant: 'outlined',
                          style: {backgroundColor: 'white'},
                          size: 'small',
                          label: <span>BSB</span>,
                          onChange: event => this.newBeneficiaryChange(event, 'bsb_code', 'length', 3)
                        }}
                      />
                    )}
                    {this.state.show_transit_code && (
                      <CustomTextField
                        success={this.state.transit_code_state === 'success'}
                        error={this.state.transit_code_state === 'error'}
                        labelText={<span>Transit &amp; Institution No</span>}
                        id='transit_code'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          value: this.state.transit_code,
                          variant: 'outlined',
                          style: {backgroundColor: 'white'},
                          size: 'small',
                          label: <span>Transit &amp; Institution No</span>,
                          onChange: event => this.newBeneficiaryChange(event, 'transit_code', 'length', 3)
                        }}
                      />
                    )}
                    {this.state.show_bank_code && (
                      <CustomTextField
                        success={this.state.bank_code_state === 'success'}
                        error={this.state.bank_code_state === 'error'}
                        labelText={<span>Bank Code</span>}
                        id='bank_code'
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                          value: this.state.bank_code,
                          variant: 'outlined',
                          style: {backgroundColor: 'white'},
                          size: 'small',
                          label: <span>Bank Code</span>,
                          onChange: event => this.newBeneficiaryChange(event, 'bank_code', 'length', 3)
                        }}
                      />
                    )}

                    {this.state.show_account_number && (
                      <CustomTextField
                        success={this.state.account_number_state === 'success'}
                        error={this.state.account_number_state === 'error'}
                        style={{borderRadius: '0px'}}
                        labelText={<span>Account Number </span>}
                        id='account_number'
                        formControlProps={{
                          fullWidth: true,
                          style: {borderRadius: '0px'}
                        }}
                        inputProps={{
                          value: this.state.account_number,
                          variant: 'outlined',
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '0px'
                          },
                          size: 'small',
                          label: <span>Account Number</span>,
                          onChange: event => this.newBeneficiaryChange(event, 'account_number', 'length', 3)
                        }}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <br />
            <br />
          </DialogContent>

          <DialogActions
            className={classes.modalFooter + ' ' + classes.modalFooterCenter}
            style={{
              backgroundColor: '#E0E0E0',
              display: 'block',
              padding: '0px 30%'
            }}
          >
            <Button onClick={this.finished} className={classes.primaryB}>
              Add Beneficiary
            </Button>
            <Button className={classes.primaryC} onClick={() => this.addBeneficiaryModelClose()}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  set_payment_radio_second = e => {
    e.preventDefault();
    this.setState({payment_method: e.target.value});
    this.props.updateNewTransferCreation('payment_method', e.target.value);
    console.info('iiiaasd');
    this.handleAmount('from_amount');
  };

  createPrimaryUI = () => {
    const {classes} = this.props;
    const beneficiary_list_select_options = this.state.primary_beneficiary_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return {
          value: item.id,
          label: item.nickname
        };
      });

    const purpose_of_payment_select_options = this.state.purpose_list.map(item => ({
      value: item.id,
      label: item.description
    }));

    let show_other = false;
    if (this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id) {
      if (this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id.length > 0) {
        for (let i = 0; i < purpose_of_payment_select_options.length; i++) {
          if (
            this.props.transferSplitPay.new_transfer_data.primary_multiple_beneficiary_id &&
            purpose_of_payment_select_options[i].value ===
              this.props.transferSplitPay.new_transfer_data.multiple_beneficiary_id[0].purpose_of_payment_detail
          ) {
            show_other = purpose_of_payment_select_options[i].label === 'Other';
          }
        }
      }
    }

    return this.state.primary_clientTransfers.map((el, i) => (
      <div key={i}>
        {i > 0 ? null : (
          <GridContainer justify='center'>
            <GridItem xs={12} sm={6}>
              {this.state.checked_beneficiary_details !== true && (
                <React.Fragment>
                  <CustomReactSelect
                    error={el.beneficiary_id_state === 'error'}
                    label='Select beneficiary'
                    options={beneficiary_list_select_options}
                    value={
                      this.props.transferSplitPay.new_transfer_data.primary_multiple_beneficiary_id &&
                      this.props.transferSplitPay.new_transfer_data.primary_multiple_beneficiary_id.length > 0
                        ? this.props.transferSplitPay.new_transfer_data.primary_multiple_beneficiary_id[0]
                            .beneficiary_id
                        : 0
                    }
                    onChange={this.handlePrimaryCustomReactSelectChange('beneficiary_id')}
                    isClearable={false}
                    // isDisabled={this.state.prefill_beneficiary}
                  />
                </React.Fragment>
              )}

              <br />
              <br />
              <Button size='sm' color='info' key='addKey' onClick={() => this.addBeneficiaryModelOpen()}>
                Create new beneficiary
              </Button>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomReactSelect
                error={
                  this.props.transferSplitPay.new_transfer_data.primary_multiple_beneficiary_id &&
                  this.props.transferSplitPay.new_transfer_data.primary_multiple_beneficiary_id.length > 0 &&
                  this.props.transferSplitPay.new_transfer_data.primary_multiple_beneficiary_id[0]
                    .purpose_of_payment_detail_state === 'error'
                }
                label='Purpose of payment'
                options={purpose_of_payment_select_options}
                value={
                  this.props.transferSplitPay.new_transfer_data.primary_multiple_beneficiary_id &&
                  this.props.transferSplitPay.new_transfer_data.primary_multiple_beneficiary_id.length > 0
                    ? this.props.transferSplitPay.new_transfer_data.primary_multiple_beneficiary_id[0]
                        .purpose_of_payment_detail
                    : 0
                }
                onChange={this.handlePrimaryCustomReactSelectChange('purpose_of_payment_detail')}
                isClearable={false}
                isDisabled={this.state.prefill_purpose_of_payment}
              />

              <CustomInput
                style={{paddingTop: 25}}
                success={el.detail_1_state === 'success'}
                error={el.detail_1_state === 'error'}
                labelText={<span>Payment detail / invoice number (*)</span>}
                id='detail_1'
                name='detail_1'
                formControlProps={{fullWidth: true}}
                inputProps={{
                  value: el.detail_1,
                  onChange: event => this.handleChangePrimary(event, i, 'detail_1')
                }}
              />
            </GridItem>
          </GridContainer>
        )}
      </div>
    ));
  };

  transferPaymentUI = () => {
    const {classes} = this.props;
    // For CustomReactSelect. Generate select options for dropdown list.
    const select_currency_select_options = this.state.currencies_list_priority.map(item => ({
      value: item.id,
      label: (
        <React.Fragment>
          <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
          &nbsp;
          {item.iso_alpha_3}
        </React.Fragment>
      )
    }));

    return (
      <GridContainer
        justify='flex-start'
        style={{
          maxWidth: '100%',
          flexBasis: '100%'
        }}
      >
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <GridContainer>
            <CommoneCurrentComponent
              selectLabel='From Currency'
              defaultValue={1000}
              color='blue'
              selectOptions={select_currency_select_options}
              selectValue={this.state.primary_from_currency}
              isClearable={false}
              onSelectChange={this.handlePrimaryCustomReactSelectChange('primary_from_currency')}
              inputValue='You send'
              inputValue2={this.state.primary_from_amount}
              onInputChange={event => {
                this.changePrimary(event, 'primary_from_amount', 'isLargerThanNumber', min_transfer_amount);
              }}
            />
          </GridContainer>
          <br />

          <GridContainer>
            <GridItem xs={8} sm={8} md={7}>
              <p className={classes.transferRate}>
                {!isNaN(this.state.primary_adjusted_rate)
                  ? 'Transfer Rate: ' + Number.parseFloat(this.state.primary_adjusted_rate).toFixed(5)
                  : ''}
              </p>
            </GridItem>
            <GridItem xs={8} sm={8} md={5}>
              <div className={classes.selectContainer}>
                <GridContainer justify='center' style={{marginBottom: '1px'}}>
                  <GridItem xs={3} md={3} className={classes.cardImg}>
                    <img src={cardPng} alt='' />
                  </GridItem>
                  <GridItem xs={9} md={9} style={{paddingLeft: '0'}}>
                    <span className={classes.paymentMethod}>Choose a payment method:</span>
                  </GridItem>
                </GridContainer>
                <GridContainer justify='center'>
                  <GridItem xs={12} sm={12}>
                    <select
                      name='cars'
                      id='cars'
                      className={classes.transactionOptions}
                      defaultChecked={this.state.payment_method}
                      onChange={this.set_payment_radio_second.bind(this)}
                    >
                      <option value='bank'>Bank Transfer (EFT)</option>
                      {/* <option value="worldpay">Credit Card</option> */}
                    </select>
                  </GridItem>
                  {/* <GridItem xs={1} sm={1} /> */}
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>

          <br />
          <GridContainer>
            <CommoneCurrentComponent
              color='yellow'
              selectLabel='To Currency'
              selectOptions={select_currency_select_options}
              selectValue={this.state.primary_to_currency}
              isClearable={false}
              isDisabled={this.state.prefill_to_currency}
              inputValue2={this.state.primary_to_amount}
              onSelectChange={this.handlePrimaryCustomReactSelectChange('primary_to_currency')}
              inputValue='Beneficiary gets'
              onInputChange={event => {
                this.changePrimary(event, 'primary_to_amount', 'isLargerThanNumber', min_transfer_amount);
              }}
            />
          </GridContainer>
        </GridItem>{' '}
      </GridContainer>
    );
  };

  newBeneficiary = () => {
    const {classes} = this.props;
    // For CustomReactSelect. Generate select options for dropdown list.
    const beneficiary_list_select_options = this.state.beneficiaries_list.map(item => ({
      value: item.id,
      label: item.nickname
    }));
    const purpose_of_payment_select_options = this.state.purpose_list.map(item => ({
      value: item.id,
      label: item.description
    }));

    return (
      <React.Fragment>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>Please indicate beneficiary from the drop down menu below</h4>
          </GridItem>
        </GridContainer>
        {this.createNewBeneficiaryUi()}
        {this.createPrimaryUI()}

        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12} lg={6} />
          <GridItem xs={12} sm={12} md={12} lg={6} />
        </GridContainer>
      </React.Fragment>
    );
  };
  render() {
    const {classes} = this.props;
    // For CustomRea6ctSelect. Generate select options for dropdown list.

    let transferRateCondition = '';

    try {
      if (!isNaN(this.props.transferSplitPay.new_transfer_data.client_rate)) {
        transferRateCondition = 'dashboard.transferRate';
      } else {
        transferRateCondition = 'dashboard.blank';
      }
    } catch (error) {}

    return (
      <React.Fragment>
        <p>
          <FormattedMessage id='dashboard.welcome' defaultMessage={' '} />
        </p>
        <p>
          <FormattedMessage id='dashboard.welcomeMessage' defaultMessage={' '} />
        </p>
        <br />
        <h4 className={classes.infoText}>
          <FormattedMessage id='splitPay.heading' defaultMessage={'Primary Transfer'} />
        </h4>
        {this.props.app_state.current_brand === 'gostudy' && (
          <span>
            Payment provider for <strong>Go Study Australia</strong>
          </span>
        )}
        {this.props.app_state.current_brand === 'redhill' && (
          <span>
            Payment provider for <strong>RedHill Education</strong>
          </span>
        )}
        <GridContainer>
          <GridItem xs={6}>{this.transferPaymentUI()}</GridItem>
          <GridItem xs={6}>{this.createPrimaryUI()}</GridItem>
        </GridContainer>
        <br />
        <Divider />
        <GridContainer>
          <GridItem xs={12}>
            {this.createNewBeneficiaryUi()}
            {this.render_beneficiary_details_section()}
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    transferSplitPay: state.transferSplitPay
  };
};

const mapDispatchToProps = dispatch => {
  return {
    update_map_data: data => {
      dispatch(update_map_data(data));
    },
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    },
    updateNewTransferCreationBeneficiary: array => {
      dispatch(updateNewTransferCreationBeneficiary(array));
    },
    createNewBeneficiary: data => {
      dispatch(createNewBeneficiary(data));
    }
  };
};

const SplitPayTransferStep2Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(MultiPayTransferStep2));

export default withRouter(SplitPayTransferStep2Container);
