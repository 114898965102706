import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {withRouter} from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import {FormattedMessage} from 'react-intl';
import {TextareaAutosize} from '@material-ui/core';
import Button from 'components/CustomButtons/Button.jsx';
import {message} from 'antd';
import { Form, Input } from 'antd';
import moment from 'moment';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';

import queryString from 'query-string';

import NavPills from 'components/NavPills/NavPills.jsx';
import MBL_SendTransaction from './MBL_SendTransaction';
import GlobalIME_GetRemoteRate from './GlobalIME_GetRemoteRate';
import GlobalIME_AddRemoteRemit from './GlobalIME_AddRemoteRemit';



class GlobalIMETransmissionPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navpill_active: 1
    }
  }

  componentDidMount() {
    this.getActive()
  }

  getActive = () => {
    const query_strings = queryString.parse(this.props.location.search);
    this.setState({query: query_strings})
    if (query_strings) {
      switch (query_strings.tab) {
        case 'summary':
          this.setState({navpill_active: 0});
          break;
        case 'send_transaction':
          this.setState({navpill_active: 1});
          break;
      }
    }
  }

  render() {
  console.log(this.state)
  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <NavPills
            active={this.state.navpill_active}
            color='info'
            tabs={[
              {
                tabButton: 'Get Remote Rate',
                tabContent: <GlobalIME_GetRemoteRate />
              },
              {
                tabButton: 'Send Transaction',
                tabContent: <GlobalIME_AddRemoteRemit query={this.state.query} />
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </Fragment>
  );
}

}




export default withRouter(GlobalIMETransmissionPageComponent);
