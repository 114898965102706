import {connect} from 'react-redux';
import SimpleClientsList from './SimpleClientList';
import {fetchSimpleClientList, selectClientUi} from '../../../redux/actions/clients';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSimpleClientList: () => {
      dispatch(fetchSimpleClientList());
    },
    selectClientUi: data => {
      dispatch(selectClientUi(data));
    }
  };
};

const SimpleClientsListContainer = connect(mapStateToProps, mapDispatchToProps)(SimpleClientsList);

export default SimpleClientsListContainer;
