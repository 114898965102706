import React from 'react';
import {API} from 'aws-amplify';
import {connect} from 'react-redux';
// import Step2PayingTo from "./Step2PayingTo";
import {updateNewTransferCreation} from '../../../redux/actions/transfers_2';

import {
  //startNewBeneficiaryCreation,
  updateNewBeneficiaryCreation,
  createNewBeneficiary
} from '../../../redux/actions/beneficiaries';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react';
// import CustomReactSelect from "../../Forms/CustomReactSelect/CustomReactSelect";
import DocumentUpload from 'components/DocumentUpload/DocumentUpload.jsx';
import document_image from 'assets/img/document_image.jpg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import {isEmpty} from 'libs/miscFunc.js';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Button from 'components/CustomButtons/Button.jsx';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';

const axios = require('axios');
var moment = require('moment');

const style = {
  ...customCheckboxRadioSwitch,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  }
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

class Step3PayingTo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beneficiaries_list: [],
      // beneficiary_id: "",
      beneficiary: {},
      purpose_of_payment_detail: '',
      purpose_of_payment_detail_state: '',
      amount_to_state: '',
      purpose_list: [],
      checked_beneficiary_details: false,

      clientTransfers: [
        {
          beneficiary_id: '',
          amount_to: '',
          purpose_of_payment_detail: '',
          beneficiary_id_state: '',
          amount_to_state: '',
          purpose_of_payment_detail_state: '',
          detail_1: '',
          detail_2: '',
          detail_3: '',
          detail_1_state: '',
          detail_2_state: '',
          detail_3_state: '',
          document: '',
          purpose_of_payment_other: '',
          purpose_of_payment_other_state: ''
        }
      ],

      FIXME: false,

      country_list: [],
      currencies_list: [],

      addBeneficiaryModalOpen: false,
      ben_legal_name: '',
      ben_email_main: '',
      ben_address_line_1: '',
      ben_address_line_2: '',
      ben_address_suburb: '',
      ben_address_state: '',
      ben_address_postcode: '',
      ben_address_country: '',

      bank_legal_name: '',
      account_currency: '',
      bsb_code: '',
      swift_code: '',
      account_number: '',
      aba_routing_number: '',
      sort_code: ''
    };
  }

  componentDidMount() {
    // console.log(this.props);
    API.get('beneficiaries', `/get/by_client_id/${this.props.transfers_2.new_transfer_data.client_id}`)
      .then(response => {
        let filterer_beneficiaries_list = response;
        if (filterer_beneficiaries_list.length > 0) {
          for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
            if (
              filterer_beneficiaries_list[i].account_currency !==
              this.props.transfers_2.new_transfer_data.currency_to_id
            ) {
              filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
            }
          }
        }

        this.setState({
          // beneficiaries_list: response
          beneficiaries_list: filterer_beneficiaries_list
        });
      })
      .catch(error => {
        console.log(error);
      });

    // API.get("system", `/countries/list_not_deleted`)
    API.get('countries', `/list_not_deleted`)
      .then(response => {
        this.setState({
          country_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });

    API.get('system', `/currencies/get-all`)
      .then(response => {
        this.setState({
          currencies_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.transfers_2.new_transfer_data.client_id !== this.props.transfers_2.new_transfer_data.client_id) {
      API.get('beneficiaries', `/get/by_client_id/${this.props.transfers_2.new_transfer_data.client_id}`)
        .then(response => {
          let filterer_beneficiaries_list = response;
          if (filterer_beneficiaries_list.length > 0) {
            for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
              if (
                filterer_beneficiaries_list[i].account_currency !==
                this.props.transfers_2.new_transfer_data.currency_to_id
              ) {
                filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
              }
            }
          }

          this.setState({
            // beneficiaries_list: response
            beneficiaries_list: filterer_beneficiaries_list
          });
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (
      prevProps.transfers_2.new_transfer_data.currency_to_id !== this.props.transfers_2.new_transfer_data.currency_to_id
    ) {
      API.get('beneficiaries', `/get/by_client_id/${this.props.transfers_2.new_transfer_data.client_id}`)
        .then(response => {
          // console.log(response)
          let filterer_beneficiaries_list = response;
          if (filterer_beneficiaries_list.length > 0) {
            for (let i = filterer_beneficiaries_list.length - 1; i > -1; i--) {
              if (
                filterer_beneficiaries_list[i].account_currency !==
                this.props.transfers_2.new_transfer_data.currency_to_id
              ) {
                filterer_beneficiaries_list.splice(i, 1); // Removes the (i)th element of response
              }
            }
          }

          this.setState({
            // beneficiaries_list: response
            beneficiaries_list: filterer_beneficiaries_list
          });
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (prevProps.transfers_2.new_transfer_data.division_id !== this.props.transfers_2.new_transfer_data.division_id) {
      API.get('transfers', `/get_purpose/id/${this.props.transfers_2.new_transfer_data.division_id}`)
        .then(response => {
          this.setState({
            purpose_list: response
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  getBeneficiaryDetails(beneficiary_id) {
    API.get('beneficiaries', `/get/id/${beneficiary_id}`)
      .then(response => {
        this.setState({
          beneficiary: response
        });
        this.props.updateNewTransferCreation('beneficiary', response);

        this.setState({
          beneficiary: Object.assign({}, this.state.beneficiary, {
            account_currency_nickname: `${response.full_name} [${response.iso_alpha_3}]`
          })
        });

        return response;
      })
      .then(response => {
        API.get('system', `/currencies/get/${response.account_currency}`)
          .then(response => {
            this.setState({
              beneficiary: Object.assign({}, this.state.beneficiary, {
                account_currency_nickname: `${response.full_name} [${response.iso_alpha_3}]`
              })
            });

            API.post('email', `/interaction/main`, {
              body: {
                interaction: 'New Transfer Request Step 2 PayingTo',
                data: {
                  client_id: this.props.transfers_2.new_transfer_data.client_id,
                  client_nickname: this.props.transfers_2.new_transfer_data.nickname,
                  beneficiary_id: this.state.beneficiary.id,
                  beneficiary_nickname: this.state.beneficiary.nickname
                },
                result: 'New transfer request proceeding'
              }
            });
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  }

  clearBeneficiaryDetails() {
    this.setState({['beneficiary']: {}});
    this.setState({['beneficiary_id']: ''});
    this.props.updateNewTransferCreation('beneficiary', {});

    let clientTransfers = [...this.state.clientTransfers];
    clientTransfers[0] = {...clientTransfers[0], ['beneficiary_id']: ''};
    this.setState({clientTransfers});
    this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
  }

  fetchNewRate = () => {
    if (
      this.props.transfers_2.new_transfer_data.currency_to &&
      this.props.transfers_2.new_transfer_data.currency_from
    ) {
      let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f`;
      axios
        .get(url, {
          params: {
            currencies: this.props.transfers_2.new_transfer_data.currency_from.iso_alpha3,
            source: this.props.transfers_2.new_transfer_data.currency_to.iso_alpha3,
            format: '1'
          }
        })
        .then(response => {
          let key = `${this.props.transfers_2.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}${this.props.transfers_2.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}`;
          let rate_string = response.data.quotes[key];

          let amount_to = Number.parseFloat(this.props.transfers_2.new_transfer_data.amount_to);
          let rate = Number.parseFloat(rate_string);
          let rate_adjust =
            Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_division) +
            Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_entity) +
            Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_team) +
            Number.parseFloat(this.props.transfers_2.new_transfer_data.client.default_rate_staff);

          let adjusted_rate = rate + rate * rate_adjust;

          let amount_from = amount_to * adjusted_rate;

          this.props.updateNewTransferCreation('current_rate', adjusted_rate.toFixed(5));
          this.props.updateNewTransferCreation('amount_from', amount_from.toFixed(5));
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  sendState() {
    return this.state;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
    this.props.updateNewTransferCreation(stateName, event.target.value);
    if (stateName === 'amount_to') {
      this.fetchNewRate();
    }
  }

  handleSelectChange = event => {
    this.setState({[event.target.name]: event.target.value});
    this.props.updateNewTransferCreation(event.target.name, event.target.value);
    if (event.target.name === 'beneficiary_id') {
      this.getBeneficiaryDetails(event.target.value);
    }
  };

  handleFileUpdate = file_id => {
    console.log(file_id);
    this.props.updateNewTransferCreation('document', file_id);
  };

  handleToggle = name => event => {
    this.setState({[name]: event.target.checked});
    if (event.target.checked === true) {
      this.clearBeneficiaryDetails();
    }
  };

  // isValidated() {
  // console.log(this.state);
  // if (this.state.purpose_of_payment_detail_state !== "success") {
  //   this.setState({ purpose_of_payment_detail_state: "error" });
  // }
  // if (this.state.amount_to_state !== "success") {
  //   this.setState({ amount_to_state: "error" });
  // }
  // if (
  //   this.state.beneficiary_id > 0 &&
  //   this.state.purpose_of_payment_detail_state === "success"
  // ) {
  //   return true;
  // }
  // return false;
  // if (
  //   ((this.state.beneficiary_id > 0) || (this.state.checked_beneficiary_details === true)) &&
  //   this.state.purpose_of_payment_detail > 0
  // ) {
  //   return true;
  // } else {
  //   return false;
  // }
  // }

  isValidated() {
    let clientTransfers = [...this.state.clientTransfers];
    let return_flag = true;

    // console.log(this.state.checked_beneficiary_details);

    clientTransfers.map(el => {
      if (el.beneficiary_id == '' && this.state.checked_beneficiary_details == false) {
        el.beneficiary_id_state = 'error';
        return_flag = false;
      }

      if (el.purpose_of_payment_detail == '') {
        el.purpose_of_payment_detail_state = 'error';
        return_flag = false;
      }

      if (el.purpose_of_payment_detail == 1) {
        if (el.purpose_of_payment_other == '') {
          el.purpose_of_payment_other_state = 'error';
          return_flag = false;
        }
      } else {
        el.purpose_of_payment_other_state = 'success';
      }

      this.setState({
        clientTransfers: clientTransfers
      });
    });

    return return_flag;
  }

  onupdate = (file_id, index) => {
    // alert(file_id + "<>" + index);

    let clientTransfers = this.state.clientTransfers;
    clientTransfers[index] = {
      ...clientTransfers[index],
      ['document']: file_id
    };
    this.setState({clientTransfers});
    this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);
  };

  handleCustomReactSelectChange = name => value => {
    // console.log(name)
    // console.log(value)
    var option_value;
    if (value === null) {
      option_value = null;
    } else {
      option_value = value.value;
    }
    this.setState({
      [name]: option_value
    });

    if (name === 'beneficiary_id') {
      let i = 0;
      let clientTransfers = [...this.state.clientTransfers];
      clientTransfers[i] = {...clientTransfers[i], [name]: option_value};
      this.setState({clientTransfers});
      this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);

      this.getBeneficiaryDetails(option_value);
    }

    if (name === 'purpose_of_payment_detail') {
      let i = 0;
      let clientTransfers = [...this.state.clientTransfers];
      clientTransfers[i] = {...clientTransfers[i], [name]: option_value};
      this.setState({clientTransfers});

      this.props.updateNewTransferCreation(
        // "purpose_of_payment_detail_nickname",
        // this.state.purpose_list[option_value - 1].description
        'multiple_beneficiary_id',
        clientTransfers
      );
      console.log(value);
      this.props.updateNewTransferCreation('purpose_of_payment_detail_text', value.label);
    }

    this.props.updateNewTransferCreation(name, option_value);
  };

  handleChange(e, i, stateName) {
    // console.log(e, i, stateName)
    const {name, value} = e.target;
    let clientTransfers = [...this.state.clientTransfers];
    clientTransfers[i] = {...clientTransfers[i], [stateName]: value};
    this.setState({clientTransfers});
    this.props.updateNewTransferCreation('multiple_beneficiary_id', clientTransfers);

    if (stateName == 'beneficiary_id' && i == 0) {
      this.getBeneficiaryDetails(value);
    }

    if (stateName == 'amount') {
      let clientTransfers = [...this.state.clientTransfers];
      let total_amount = 0;
      let tmpValue = 0;

      clientTransfers.map((el, index) => {
        if (index == i) {
          if (isEmpty(value)) tmpValue = 0;
          else tmpValue = value;
          total_amount = Number.parseFloat(total_amount) + Number.parseFloat(tmpValue);
        } else {
          if (isEmpty(el.amount)) tmpValue = 0;
          else tmpValue = el.amount;
          total_amount = Number.parseFloat(total_amount) + Number.parseFloat(tmpValue);
        }
      });
      this.setState({['amount_to']: total_amount});
      this.props.updateNewTransferCreation('amount_to', total_amount);
      this.props.updateNewTransferCreation(
        'nickname',
        `FX Transfer ${moment().format('DD/MM/YY')} ${Number.parseFloat(total_amount).toFixed(2)} ${
          this.props.transfers.new_transfer_data.currency_to
            ? this.props.transfers.new_transfer_data.currency_to.iso_alpha_3
            : ''
        }`
      );
    }

    if (stateName === 'purpose_of_payment_detail') {
      this.setState({
        FIXME: value == 1
      });
    }
  }

  newBeneficiaryChange(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + ' _state']: 'error'});
        }
        break;
      case 'equalTo':
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({[stateName + '_state']: 'success'});
        } else {
          this.setState({[stateName + '_state']: 'error'});
        }
        break;
      default:
        break;
    }
    this.setState({[stateName]: event.target.value});
    //this.props.updateNewBeneficiaryCreation([event.target.name], event.target.value)
  }

  isValidateBeneficiary() {
    if (this.state.ben_legal_name == '') {
      this.setState({ben_legal_name_state: 'error'});
    } else {
      this.setState({ben_legal_name_state: 'success'});
    }

    if (this.state.account_currency == '') {
      this.setState({account_currency_state: 'error'});
    } else {
      this.setState({account_currency_state: 'success'});
    }

    if (this.state.ben_legal_name == '' || this.state.account_currency == '') {
      return false;
    } else {
      return true;
    }
  }

  createBeneficiaryIntoDatabase = async data => {
    await this.props.createNewBeneficiary(data);

    await API.get('beneficiaries', `/get/by_client_id/${this.props.app_state.current_client.id}`)
      .then(response => {
        this.setState({
          beneficiaries_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({
      ben_legal_name: '',
      ben_email_main: '',
      ben_address_line_1: '',
      ben_address_line_2: '',
      ben_address_suburb: '',
      ben_address_state: '',
      ben_address_postcode: '',
      ben_address_country: '',

      bank_legal_name: '',
      account_currency: '',
      bsb_code: '',
      swift_code: '',
      account_number: '',
      aba_routing_number: '',
      sort_code: ''
    });

    return;
  };

  finished = async () => {
    if (this.isValidateBeneficiary()) {
      const {
        ben_legal_name,
        ben_email_main,
        ben_address_line_1,
        ben_address_line_2,
        ben_address_suburb,
        ben_address_state,
        ben_address_postcode,
        ben_address_country,
        bank_legal_name,
        account_currency,
        bsb_code,
        swift_code,
        account_number,
        aba_routing_number,
        sort_code
      } = this.state;

      try {
        await this.createBeneficiaryIntoDatabase({
          client_id: this.props.app_state.current_client.id,
          nickname: ben_legal_name,
          ben_legal_name: ben_legal_name,
          ben_email_main: ben_email_main,
          ben_address_line_1: ben_address_line_1,
          ben_address_line_2: ben_address_line_2,
          ben_address_suburb: ben_address_suburb,
          ben_address_state: ben_address_state,
          ben_address_postcode: ben_address_postcode,
          ben_address_country: ben_address_country,
          bank_legal_name: bank_legal_name,
          account_currency: account_currency,
          bsb_code: bsb_code,
          swift_code: swift_code,
          account_number: account_number,
          aba_routing_number: aba_routing_number,
          sort_code: sort_code
        });
      } catch (e) {
        this.setState({isLoading: false});
      }

      // this.setState({
      //   addBeneficiaryModalOpen: false
      // });
    }
  };

  addBeneficiaryModelOpen = () => {
    this.setState({
      addBeneficiaryModalOpen: true
    });
  };

  addBeneficiaryModelClose = () => {
    this.setState({
      addBeneficiaryModalOpen: false
    });
  };

  createNewBeneficiaryUi = () => {
    const {classes} = this.props;
    const country_list_select_options = this.state.country_list.map(item => ({
      value: item.id,
      label: item.full_name
    }));

    return (
      <React.Fragment>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.addBeneficiaryModalOpen}
          transition={Transition}
          keepMounted
          onClose={() => this.addBeneficiaryModelClose()}
          aria-labelledby='modal-slide-title'
          aria-describedby='modal-slide-description'
        >
          <DialogTitle id='classic-modal-slide-title' disableTypography className={classes.modalHeader}>
            <h4 className={classes.modalTitle}>Add New Beneficiary</h4>
          </DialogTitle>
          <DialogContent id='modal-slide-description' className={classes.modalBody}>
            <h6>Note: Add beneficiary not yet functioning.</h6>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <CustomInput
                  success={this.state.ben_legal_name_state === 'success'}
                  error={this.state.ben_legal_name_state === 'error'}
                  labelText={<span>Full legal name</span>}
                  id='ben_legal_name'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.ben_legal_name,
                    onChange: event => this.newBeneficiaryChange(event, 'ben_legal_name', 'length', 3)
                  }}
                />
                <CustomInput
                  success={this.state.ben_email_main_state === 'success'}
                  error={this.state.ben_email_main_state === 'error'}
                  labelText={<span>Email</span>}
                  id='ben_email_main'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.ben_email_main,
                    onChange: event => this.newBeneficiaryChange(event, 'ben_email_main', 'length', 3)
                  }}
                />
                <CustomInput
                  success={this.state.ben_address_line_1_state === 'success'}
                  error={this.state.ben_address_line_1_state === 'error'}
                  labelText={<span>Address Line 1</span>}
                  id='ben_address_line_1'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.ben_address_line_1,
                    onChange: event => this.newBeneficiaryChange(event, 'ben_address_line_1', 'length', 3)
                  }}
                />
                <CustomInput
                  success={this.state.ben_address_line_2_state === 'success'}
                  error={this.state.ben_address_line_2_state === 'error'}
                  labelText={<span>Address Line 2</span>}
                  id='ben_address_line_2'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.ben_address_line_2,
                    onChange: event => this.newBeneficiaryChange(event, 'ben_address_line_2', 'length', 3)
                  }}
                />
                <CustomInput
                  success={this.state.ben_address_suburb_state === 'success'}
                  error={this.state.ben_address_suburb_state === 'error'}
                  labelText={<span>Suburb</span>}
                  id='ben_address_suburb'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.ben_address_suburb,
                    onChange: event => this.newBeneficiaryChange(event, 'ben_address_suburb', 'length', 3)
                  }}
                />
                <GridContainer>
                  <GridItem xs={12} md={6}>
                    <CustomInput
                      success={this.state.ben_address_state_state === 'success'}
                      error={this.state.ben_address_state_state === 'error'}
                      labelText={<span>State</span>}
                      id='ben_address_state'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.ben_address_state,
                        onChange: event => this.newBeneficiaryChange(event, 'ben_address_state', 'length', 3)
                      }}
                    />
                    <CustomInput
                      success={this.state.ben_address_postcode_state === 'success'}
                      error={this.state.ben_address_postcode_state === 'error'}
                      labelText={<span>Postcode</span>}
                      id='ben_address_postcode'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.ben_address_postcode,
                        onChange: event => this.newBeneficiaryChange(event, 'ben_address_postcode', 'length', 3)
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                    Country
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.ben_address_country || '0'}
                    onChange={this.handleSelectChange}
                    inputProps={{
                      name: 'ben_address_country',
                      id: 'ben_address_country'
                    }}
                  >
                    <MenuItem
                      key='0x0'
                      value='0'
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Select Country
                    </MenuItem>
                    {this.state.country_list
                      .sort((a, b) => a.full_name.localeCompare(b.full_name))
                      .map(item => {
                        return (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={item.id}
                          >
                            {item.full_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomInput
                  success={this.state.bank_legal_name_state === 'success'}
                  error={this.state.bank_legal_name_state === 'error'}
                  labelText={<span>Bank Name</span>}
                  id='bank_legal_name'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.bank_legal_name,
                    onChange: event => this.newBeneficiaryChange(event, 'bank_legal_name', 'length', 3)
                  }}
                />
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                    Currency
                  </InputLabel>
                  <Select
                    success={this.state.account_currency_state === 'success'}
                    error={this.state.account_currency_state === 'error'}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.account_currency || '0'}
                    onChange={this.handleSelectChange}
                    inputProps={{
                      name: 'account_currency',
                      id: 'account_currency'
                    }}
                  >
                    <MenuItem
                      key='0x0'
                      value='0'
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Select Currency
                    </MenuItem>
                    {this.state.currencies_list
                      .sort((a, b) => a.full_name.localeCompare(b.full_name))
                      .map(item => {
                        return (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={item.id}
                          >
                            {item.full_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <CustomInput
                  success={this.state.bsb_code_state === 'success'}
                  error={this.state.bsb_code_state === 'error'}
                  labelText={<span>BSB</span>}
                  id='bsb_code'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.bsb_code,
                    onChange: event => this.newBeneficiaryChange(event, 'bsb_code', 'length', 3)
                  }}
                />
                <CustomInput
                  success={this.state.swift_code_state === 'success'}
                  error={this.state.swift_code_state === 'error'}
                  labelText={<span>SWIFT Code</span>}
                  id='swift_code'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.swift_code,
                    onChange: event => this.newBeneficiaryChange(event, 'swift_code', 'length', 3)
                  }}
                />
                <CustomInput
                  success={this.state.account_number_state === 'success'}
                  error={this.state.account_number_state === 'error'}
                  labelText={<span>Account Number </span>}
                  id='account_number'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.account_number,
                    onChange: event => this.newBeneficiaryChange(event, 'account_number', 'length', 3)
                  }}
                />
                <CustomInput
                  success={this.state.aba_routing_number_state === 'success'}
                  error={this.state.aba_routing_number_state === 'error'}
                  labelText={<span>ABA Routing Number</span>}
                  id='aba_routing_number'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.aba_routing_number,
                    onChange: event => this.newBeneficiaryChange(event, 'aba_routing_number', 'length', 3)
                  }}
                />
                <CustomInput
                  success={this.state.sort_code_state === 'success'}
                  error={this.state.sort_code_state === 'error'}
                  labelText={<span>Sort Code</span>}
                  id='sort_code'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.sort_code,
                    onChange: event => this.newBeneficiaryChange(event, 'sort_code', 'length', 3)
                  }}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
            <Button onClick={() => this.addBeneficiaryModelClose()}>Cancel</Button>
            <Button color='primary' onClick={this.finished}>
              Add Beneficiary
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  createUI() {
    const {classes} = this.props;
    const beneficiary_list_select_options = this.state.beneficiaries_list
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .map(item => {
        return {value: item.id, label: item.nickname};
      });

    const purpose_of_payment_select_options = this.state.purpose_list.map(item => ({
      value: item.id,
      label: item.description
    }));
    // console.log(purpose_of_payment_select_options)

    let show_other = false;
    if (this.props.transfers_2.new_transfer_data.multiple_beneficiary_id) {
      for (let i = 0; i < purpose_of_payment_select_options.length; i++) {
        if (
          purpose_of_payment_select_options[i].value ===
          this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].purpose_of_payment_detail
        ) {
          // console.log(purpose_of_payment_select_options[i])
          show_other = purpose_of_payment_select_options[i].label === 'Other';
        }
      }
    }

    return this.state.clientTransfers.map((el, i) => (
      <div key={i}>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={6}>
            {this.state.checked_beneficiary_details !== true && (
              <React.Fragment>
                <CustomReactSelect
                  error={el.beneficiary_id_state === 'error'}
                  label='Select beneficiary'
                  options={beneficiary_list_select_options}
                  value={
                    this.props.transfers_2.new_transfer_data.multiple_beneficiary_id
                      ? this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].beneficiary_id
                      : 0
                  }
                  onChange={this.handleCustomReactSelectChange('beneficiary_id')}
                  isClearable={false}
                  // isDisabled={this.state.prefill_beneficiary}
                />
                {/* <Select
                  style={{ paddingTop: 25 }}
                  success={el.beneficiary_id_state === "success"}
                  error={el.beneficiary_id_state === "error"}
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  key={i}
                  value={el.beneficiary_id || "0"}
                  onChange={event =>
                    this.handleChange(event, i, "beneficiary_id")
                  }
                  inputProps={{
                    name: "beneficiary_id",
                    id: "beneficiary_id"
                  }}
                >
                  <MenuItem
                    key="0x0"
                    value="0"
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Select Beneficiary
                </MenuItem>

                  {this.state.beneficiaries_list
                    .sort((a, b) => a.nickname.localeCompare(b.nickname))
                    .map(item => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nickname}
                        </MenuItem>
                      );
                    })}
                </Select> */}
              </React.Fragment>
            )}

            <br />
            <br />
            <Button size='sm' color='info' key='addKey' onClick={() => this.addBeneficiaryModelOpen()}>
              Create new beneficiary
            </Button>
            {/* <Select
              style={{ paddingTop: 25 }}
              success={el.beneficiary_id_state === "success"}
              error={el.beneficiary_id_state === "error"}
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              key={i}
              value={el.beneficiary_id || "0"}
              onChange={(event) => this.handleChange(event, i, "beneficiary_id")}
              inputProps={{
                name: "beneficiary_id",
                id: "beneficiary_id"
              }}
            >
              <MenuItem
                key="0x0"
                value="0"
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Select Beneficiary
              </MenuItem>

              {this.state.beneficiaries_list
                .sort((a, b) =>
                  a.nickname.localeCompare(b.nickname)
                )
                .map(item => {
                  return (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                    >
                      {item.nickname}
                    </MenuItem>
                  );
                })}
            </Select> */}

            {/* {(!!this.state.beneficiary_id) && ( */}
            {!!this.state.beneficiary.id && (
              <React.Fragment>
                <h6>Selected beneficiary details:</h6>
                <p>
                  <strong>{this.state.beneficiary.nickname}</strong>
                  <br />
                  {this.state.beneficiary.ben_legal_name}
                  <br />
                  {this.state.beneficiary.ben_address_line_1 ? (
                    <React.Fragment>
                      {this.state.beneficiary.ben_address_line_1}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {this.state.beneficiary.ben_address_line_2 ? (
                    <React.Fragment>
                      {this.state.beneficiary.ben_address_line_2}
                      <br />
                    </React.Fragment>
                  ) : null}
                  {this.state.beneficiary.ben_address_suburb ? (
                    <React.Fragment>
                      {this.state.beneficiary.ben_address_suburb} {this.state.beneficiary.ben_address_state}{' '}
                      {this.state.beneficiary.ben_address_postcode}
                      <br />
                    </React.Fragment>
                  ) : null}
                </p>
                <p>
                  <strong>Account details:</strong>
                  <br />
                  {this.state.beneficiary.account_currency_nickname} <br />
                  {this.state.beneficiary.bank_legal_name} <br />
                  {this.state.beneficiary.account_number} <br />
                </p>
              </React.Fragment>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  // value={this.state.checked_beneficiary_details}
                  onClick={this.handleToggle('checked_beneficiary_details')}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              label='Check box to provide beneficiary details later'
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomReactSelect
              error={
                this.props.transfers_2.new_transfer_data.multiple_beneficiary_id &&
                this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].purpose_of_payment_detail_state ===
                  'error'
              }
              label='Purpose of payment'
              options={purpose_of_payment_select_options}
              value={
                this.props.transfers_2.new_transfer_data.multiple_beneficiary_id
                  ? this.props.transfers_2.new_transfer_data.multiple_beneficiary_id[0].purpose_of_payment_detail
                  : 0
              }
              onChange={this.handleCustomReactSelectChange('purpose_of_payment_detail')}
              isClearable={false}
              isDisabled={this.state.prefill_purpose_of_payment}
            />

            {/* <Select
              fullWidth
              success={el.purpose_of_payment_detail_state === "success"}
              error={el.purpose_of_payment_detail_state === "error"}
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              key={i}
              value={el.purpose_of_payment_detail || "0"}
              onChange={event =>
                this.handleChange(event, i, "purpose_of_payment_detail")
              }
              inputProps={{
                name: "purpose_of_payment_detail",
                id: "purpose_of_payment_detail"
              }}
            >
              <MenuItem
                key="0x0"
                value="0"
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Purpose of Payment
              </MenuItem>

              {this.state.purpose_list.map(item => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.description}
                  </MenuItem>
                );
              })}
            </Select> */}

            {show_other && (
              <CustomInput
                style={{paddingTop: 25}}
                // success={el.detail_1_state === "success"}
                // error={el.detail_1_state === "error"}
                labelText={<span>Specify purpose of payment (*)</span>}
                id='purpose_of_payment_other'
                name='purpose_of_payment_other'
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: el.purpose_of_payment_other,
                  onChange: event => this.handleChange(event, i, 'purpose_of_payment_other')
                }}
              />
            )}

            <CustomInput
              style={{paddingTop: 25}}
              success={el.detail_1_state === 'success'}
              error={el.detail_1_state === 'error'}
              labelText={<span>Payment detail / invoice number (*)</span>}
              id='detail_1'
              name='detail_1'
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: el.detail_1,
                onChange: event => this.handleChange(event, i, 'detail_1')
              }}
            />
            {/* if user selects "personal payment" from DDL, hide the upload component */}
            {el.purpose_of_payment_detail !== 5 && (
              <div style={{textAlign: 'center', paddingTop: 25}}>
                {/*<DocumentUpload*/}
                {/*  circle*/}
                {/*  change={false}*/}
                {/*  placeholder={document_image}*/}
                {/*  updateFunction={this.handleFileUpdate}*/}
                {/*/>*/}
                <DocumentUpload
                  circle
                  change={false}
                  placeholder={document_image}
                  updateFunction={this.onupdate}
                  index={i}
                />
                <p>Please upload a copy of an invoice or any form of document regarding this payment.</p>
              </div>
            )}
          </GridItem>
        </GridContainer>
      </div>
    ));
  }

  render() {
    const {classes} = this.props;
    // For CustomReactSelect. Generate select options for dropdown list.
    const beneficiary_list_select_options = this.state.beneficiaries_list.map(item => ({
      value: item.id,
      label: item.nickname
    }));
    const purpose_of_payment_select_options = this.state.purpose_list.map(item => ({
      value: item.id,
      label: item.description
    }));

    return (
      <React.Fragment>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>Please indicate beneficiary from the drop down menu below</h4>
          </GridItem>
        </GridContainer>
        {this.createNewBeneficiaryUi()}
        {this.createUI()}

        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12} lg={6} />
          <GridItem xs={12} sm={12} md={12} lg={6} />
        </GridContainer>
      </React.Fragment>
    );
  }
}

// export default withStyles(style)(Step3PayingTo);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    beneficiaries: state.beneficiaries,
    transfers_2: state.transfers_2
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    },
    //startNewBeneficiaryCreation: () => {
    //  dispatch(startNewBeneficiaryCreation())
    //},
    createNewBeneficiary: data => {
      dispatch(createNewBeneficiary(data));
    },
    updateNewBeneficiaryCreation: (key, value) => {
      dispatch(updateNewBeneficiaryCreation(key, value));
    }
  };
};

const Step3PayingToContainer = connect(mapStateToProps, mapDispatchToProps)(Step3PayingTo);

export default withStyles(style)(Step3PayingToContainer);
