import React from 'react';
import {connect} from 'react-redux';
import {
  fetchInvoicePendingList
  // setTransferSelectId
} from '../../redux/actions/transfers_pending';

// react component for creating dynamic tables
import ReactTable from 'react-table';

import {API} from 'aws-amplify';
import {AiOutlineSearch} from 'react-icons/ai';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

// @material-ui/icons
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";

//core components for #Issue 941
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

// core components
// import GridContainer from "components/Grid/GridContainer.jsx";
// import GridItem from "components/Grid/GridItem.jsx";
import Button from 'components/CustomButtons/Button.jsx';
import queryString from 'query-string';
// import CardBody from "components/Card/CardBody.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
import {FormattedNumber} from 'react-intl';
import 'react-dates/initialize';

import {
  DateRangePicker
  //SingleDatePicker,
  //DayPickerRangeController
} from 'react-dates';

import Badge from '../Badge/Badge';

/**
 * ----------------------------------------------------------------------------
 * STYLING
 * ----------------------------------------------------------------------------
 */
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import matchSorter from 'match-sorter';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';

var moment = require('moment');

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

/**
 * ----------------------------------------------------------------------------
 * CLASS - TransferList - Components for list all the transfer from this user
 * ----------------------------------------------------------------------------
 */
class InvoiceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity_list: [],
      dialog_delete_confirm: false,
      client_id: '',
      startDate: moment().subtract(6, 'month'),
      endDate: moment().add(1, 'month')
    };
  }

  componentDidMount() {
    this.props.fetchTransferPendingList();
    this.queryStrings();
  }

  //= Function to build data into ReactDataTable
  buildTableData() {
    // console.log(this.props);
    //= Remove #1076 Debug message
    // console.log(this.props.transfers.transfer_list);

    let transfer_pending_list = this.props.transfers_pending.transfer_pending_list;
    console.log(transfer_pending_list);
    console.log(this.state.client_id);

    if (this.state.client_id == '') {
      let date_range_transfer_list = transfer_pending_list;
      if (this.state.startDate && this.state.endDate) {
        date_range_transfer_list = transfer_pending_list.filter(transfer => {
          return (
            this.state.startDate.isBefore(moment(transfer.transaction_datetime).add(1, 'day')) &&
            this.state.endDate.isAfter(moment(transfer.transaction_datetime))
          );
        });
      }

      /*
       * #1054 Split Transfer List into current and past transfers
       *
       *  Tom's solution - filtering transfer list by status
       */
      // console.log(typeof (this.props.status) === "string"); //disable debug messages #1054
      if (typeof this.props.status === 'string') {
        console.log(this.props.status);
        if (this.props.status === 'past') {
          // console.log("We go current!!"); //disable debug messages #1054

          //Code Block - generate data set and return to ReactTabble component

          //Start coding a filter for current transfers
          //console.log(this.props.transfers.transfer_list); //disable debug messages #1054
          date_range_transfer_list = transfer_pending_list.filter(transfer => {
            return transfer.status_id === 8 || transfer.status_id === 10;
          });
          //end of filter

          var transfer_list_length = date_range_transfer_list.length;
          if (transfer_list_length > 0) {
            // eslint-disable-next-line no-unused-vars
            return date_range_transfer_list.map((prop, key) => {
              // console.log(prop); //disable debug messages //disable debug messages #1054
              return {
                id: prop.id,
                client: prop.client_nickname,
                transaction_date: prop.transaction_datetime,
                status_id: prop.status_id,
                memo: prop.memo,
                nickname: prop.nickname,
                settlement_date: prop.settlement_date,
                beneficiary: prop.beneficiaries_nickname,
                amount_from: prop.amount_from,
                amount_to: prop.amount_to,
                currency_from: prop.currency_from_iso_alpha_3,
                currency_to: prop.currency_to_iso_alpha_3,
                client_rate: prop.client_rate ? prop.client_rate.toFixed(5) : '',
                transfer_status: prop.transfer_status,
                actions: (
                  <div className='actions-right'>
                    <Button
                      justIcon
                      round
                      simple
                      onClick={() => {
                        this.props.history.push(`/transfers/edit/${prop.id}`);
                      }}
                      color='warning'
                      className='edit'
                    >
                      <Dvr />
                    </Button>{' '}
                    {this.props.app_state.current_staff_super_admin && (
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() => {
                          // console.log(this); //disable debug messages #1054
                          var data = this.props.transfers.transfer_list;
                          // eslint-disable-next-line no-unused-vars
                          data.find((o, i) => {
                            if (o.id === prop.id) {
                              this.setState({
                                dialog_delete_confirm: true,
                                dialog_delete_item: prop.id,
                                dialog_delete_item_name: `${prop.nickname}`
                              });

                              return true;
                            }
                            return false;
                          });
                          this.setState({data: data});
                        }}
                        color='danger'
                        className='remove'
                      >
                        <Close />
                      </Button>
                    )}{' '}
                  </div>
                )
              };
            });
          } else {
            return (
              transfer_pending_list
                .filter(u => u.client_id == this.state.client_id)
                // eslint-disable-next-line no-unused-vars
                .map((prop, key) => {
                  return {
                    id: prop.id,
                    client: prop.client_nickname,
                    transaction_date: prop.transaction_datetime,
                    status_id: prop.status_id,
                    memo: prop.memo,
                    nickname: prop.nickname,
                    settlement_date: prop.settlement_date,
                    beneficiary: prop.beneficiaries_nickname,
                    currency_from: prop.currency_from_iso_alpha_3,
                    amount_from: prop.amount_from,
                    amount_to: prop.amount_to,
                    currency_to: prop.currency_to_iso_alpha_3,
                    client_rate: prop.client_rate.toFixed(5),
                    transfer_status: prop.transfer_status,
                    actions: (
                      <div className='actions-right'>
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            this.props.history.push(`/transfers/edit/${prop.id}`);
                          }}
                          color='warning'
                          className='edit'
                        >
                          <Dvr />
                        </Button>{' '}
                        {/* use this button to remove the data row */}
                        {this.props.app_state.current_staff_super_admin && (
                          <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                              // console.log(this); //disable debug messages #1054
                              var data = this.props.transfers.transfer_list;
                              data.find((o, i) => {
                                if (o.id === prop.id) {
                                  this.setState({
                                    dialog_delete_confirm: true,
                                    dialog_delete_item: prop.id,
                                    dialog_delete_item_name: `${prop.nickname}`
                                  });

                                  return true;
                                }
                                return false;
                              });
                              this.setState({data: data});
                            }}
                            color='danger'
                            className='remove'
                          >
                            <Close />
                          </Button>
                        )}{' '}
                      </div>
                    )
                  };
                })
            );
          }
          //end of code block
        } else if (this.props.status == 'pending') {
          //filter(this.state.transferList) = status = 1,2,3,4,5,6,9
          // console.log("We go past!!"); //disable debug messages #1054

          //Code block generate data set and return to ReactTabble component

          //filter for current transfers
          console.log(date_range_transfer_list);
          date_range_transfer_list = transfer_pending_list.filter(transfer => {
            return transfer.status_id === 2;
            /*
             * return (transfer.transfer_status === "Draft"
             *   || transfer.transfer_status === "In Process"
             *   || transfer.transfer_status === "Acknowledged"
             *   || transfer.transfer_status === "Processing"
             *   || transfer.transfer_status === "Awaiting Funds"
             *   || transfer.transfer_status === "Funds Received"
             *   || transfer.transfer_status === "Payment Made"
             *   || transfer.transfer_status === "On Hold"
             * )
             */
          });
          console.log(date_range_transfer_list);
          //end of filter

          var transfer_list_length = date_range_transfer_list.length;
          if (transfer_list_length > 0) {
            return date_range_transfer_list.map((prop, key) => {
              // console.log(prop); //disable debug messages #1054
              return {
                id: prop.id,
                client: prop.client_nickname,
                record_created: prop.record_created,
                status_id: prop.status_id,
                // memo: prop.memo,
                // nickname: prop.nickname,
                // settlement_date: prop.settlement_date,
                invoice_date: prop.invoice_date,
                due_date: prop.due_date,
                // amount_from: prop.amount_from,
                amount_to: prop.amount_to,
                // currency_from: prop.currency_from_iso_alpha_3,
                currency_to: prop.currency_to_iso_alpha_3,
                // client_rate: prop.client_rate
                //   ? prop.client_rate.toFixed(5)
                //   : "",
                transfer_status: prop.transfer_status_name,
                actions: (
                  <div className='actions-right'>
                    <Button
                      justIcon
                      round
                      simple
                      onClick={() => {
                        this.props.history.push(`/invoices/edit/${prop.id}`);
                      }}
                      color='warning'
                      className='edit'
                    >
                      <Dvr />
                    </Button>{' '}
                    {this.props.app_state.current_staff_super_admin && (
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() => {
                          // console.log(this); //disable debug messages #1054
                          var data = this.props.transfers.transfer_list;
                          data.find((o, i) => {
                            if (o.id === prop.id) {
                              this.setState({
                                dialog_delete_confirm: true,
                                dialog_delete_item: prop.id,
                                dialog_delete_item_name: `${prop.nickname}`
                              });

                              return true;
                            }
                            return false;
                          });
                          this.setState({data: data});
                        }}
                        color='danger'
                        className='remove'
                      >
                        <Close />
                      </Button>
                    )}{' '}
                  </div>
                )
              };
            });
          } else {
            return transfer_pending_list
              .filter(u => u.client_id == this.state.client_id)
              .map((prop, key) => {
                return {
                  id: prop.id,
                  client: prop.client_nickname,
                  transaction_date: prop.transaction_datetime,
                  status_id: prop.status_id,
                  memo: prop.memo,
                  nickname: prop.nickname,
                  settlement_date: prop.settlement_date,
                  beneficiary: prop.beneficiaries_nickname,
                  currency_from: prop.currency_from_iso_alpha_3,
                  amount_from: prop.amount_from,
                  amount_to: prop.amount_to,
                  currency_to: prop.currency_to_iso_alpha_3,
                  client_rate: prop.client_rate.toFixed(5),
                  transfer_status: prop.transfer_status,
                  actions: (
                    <div className='actions-right'>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() => {
                          this.props.history.push(`/transfers/edit/${prop.id}`);
                        }}
                        color='warning'
                        className='edit'
                      >
                        <Dvr />
                      </Button>{' '}
                      {/* use this button to remove the data row */}
                      {this.props.app_state.current_staff_super_admin && (
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            // console.log(this); //disable debug messages #1054
                            var data = this.props.transfers.transfer_list;
                            data.find((o, i) => {
                              if (o.id === prop.id) {
                                this.setState({
                                  dialog_delete_confirm: true,
                                  dialog_delete_item: prop.id,
                                  dialog_delete_item_name: `${prop.nickname}`
                                });

                                return true;
                              }
                              return false;
                            });
                            this.setState({data: data});
                          }}
                          color='danger'
                          className='remove'
                        >
                          <Close />
                        </Button>
                      )}{' '}
                    </div>
                  )
                };
              });
          }
          //end of code block
        }
      }
      //end of solutions

      var transfer_list_length = date_range_transfer_list.length;
      if (transfer_list_length > 0) {
        // return this.props.transfers.transfer_list.map((prop, key) => {
        return date_range_transfer_list.map((prop, key) => {
          // console.log(prop);
          return {
            id: prop.id,
            client: prop.client_nickname,
            transaction_date: prop.transaction_datetime,
            status_id: prop.status_id,
            memo: prop.memo,
            nickname: prop.nickname,
            settlement_date: prop.settlement_date,
            beneficiary: prop.beneficiaries_nickname,
            amount_from: prop.amount_from,
            amount_to: prop.amount_to,
            currency_from: prop.currency_from_iso_alpha_3,
            currency_to: prop.currency_to_iso_alpha_3,
            client_rate: prop.client_rate ? prop.client_rate.toFixed(5) : '',
            transfer_status: prop.transfer_status,
            actions: (
              <div className='actions-right'>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    this.props.history.push(`/transfers/edit/${prop.id}`);
                  }}
                  color='warning'
                  className='edit'
                >
                  <Dvr />
                </Button>{' '}
                {this.props.app_state.current_staff_super_admin && (
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      //console.log(this);
                      var data = this.props.transfers.transfer_list;
                      data.find((o, i) => {
                        if (o.id === prop.id) {
                          this.setState({
                            dialog_delete_confirm: true,
                            dialog_delete_item: prop.id,
                            dialog_delete_item_name: `${prop.nickname}`
                          });

                          return true;
                        }
                        return false;
                      });
                      this.setState({data: data});
                    }}
                    color='danger'
                    className='remove'
                  >
                    <Close />
                  </Button>
                )}{' '}
              </div>
            )
          };
        });
      } else {
        return transfer_pending_list
          .filter(u => u.client_id == this.state.client_id)
          .map((prop, key) => {
            // if(this.state.client_id == prop.client_id)
            return {
              id: prop.id,
              client: prop.client_nickname,
              transaction_date: prop.transaction_datetime,
              status_id: prop.status_id,
              memo: prop.memo,
              nickname: prop.nickname,
              settlement_date: prop.settlement_date,
              beneficiary: prop.beneficiaries_nickname,
              currency_from: prop.currency_from_iso_alpha_3,
              amount_from: prop.amount_from,
              amount_to: prop.amount_to,
              currency_to: prop.currency_to_iso_alpha_3,
              client_rate: prop.client_rate.toFixed(5),
              transfer_status: prop.transfer_status,
              actions: (
                <div className='actions-right'>
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      // let obj = this.props.staff.staff_list.find(o => o.aws_cognito_id === prop.aws_cognito_id);
                      // this.props.setEntityDetailId(prop.id);
                      // this.props.selectEntityUi("edit")
                      // this.props.setTransferSelectId(prop.id);
                      // this.props.history.push(`/transfers/edit`);
                      this.props.history.push(`/transfers/edit/${prop.id}`);
                      // alert(
                      //   "You've clicked EDIT button on \n{ \nName: " +
                      //   obj.first_name +
                      //   ", \nlast name: " +
                      //   obj.last_name +
                      //   ", \nemail: " +
                      //   obj.email +
                      //   "\n}."
                      // );
                    }}
                    color='warning'
                    className='edit'
                  >
                    <Dvr />
                  </Button>{' '}
                  {/* use this button to remove the data row */}
                  {this.props.app_state.current_staff_super_admin && (
                    <Button
                      justIcon
                      round
                      simple
                      onClick={() => {
                        //console.log(this);
                        var data = this.props.transfers.transfer_list;
                        data.find((o, i) => {
                          if (o.id === prop.id) {
                            this.setState({
                              dialog_delete_confirm: true,
                              dialog_delete_item: prop.id,
                              dialog_delete_item_name: `${prop.nickname}`
                            });

                            return true;
                          }
                          return false;
                        });
                        this.setState({data: data});
                      }}
                      color='danger'
                      className='remove'
                    >
                      <Close />
                    </Button>
                  )}{' '}
                </div>
              )
            };
          });
      }
    }
  }
  //end of function

  getFilter = (filter, onChange) => {
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AiOutlineSearch />
          <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />
        </div>
      </React.Fragment>
    );
  };

  handle_delete_dialog_cancel = () => {
    this.setState({
      dialog_delete_confirm: false,
      dialog_delete_item: 0,
      dialog_delete_item_name: ''
    });
  };

  handle_delete_dialog_click = item_to_delete => {
    this.setState({dialog_delete_confirm: false});
    console.log(item_to_delete);
    API.put('transfers', `/update/id/${item_to_delete}`, {
      body: {deleted: true}
    })
      .then(response => {
        console.log(response);
        this.props.fetchTransferList();
        // dispatch(receiveStaffList(response));
      })
      .catch(error => {
        console.log(error);
        // quickfix because of the api bug, so it returns api error because client talks to old database
        this.props.fetchTransferList();
      });
  };

  handle_date_change = (startDate, endDate) => {
    // console.log(startDate, endDate);
    this.setState({startDate, endDate});
    this.buildTableData();
  };

  getFullDate = s => {
    let showDate = '';
    if (s.getMonth() >= 9) {
      if (s.getDate() > 9) showDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-' + s.getDate();
      else showDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-0' + s.getDate();
    } else if (s.getMonth() < 9) {
      if (s.getDate() > 9) showDate = s.getFullYear() + '-0' + (s.getMonth() + 1) + '-' + s.getDate();
      else showDate = s.getFullYear() + '-0' + (s.getMonth() + 1) + '-0' + s.getDate();
    }
    return showDate;
  };

  //function to bring in #Issue 941 Improvement visuals of transfers by Tom
  getSteps() {
    return ['Submitted', 'Acknowledged', 'Customer Money Received', 'Beneficiary Payment Made', 'Completed'];
  }

  getSteps1() {
    return ['Submitted', 'Not Proceeding'];
  }

  queryStrings() {
    const query_strings = queryString.parse(this.props.location.search);
    if (Object.keys(query_strings).length !== 0) {
      if (query_strings.client_id) {
        this.setState({client_id: Number(query_strings.client_id)});
      }
    }
  }

  render() {
    // console.log(this.props);

    var transfer_status = this.props.transfers_pending.transfer_pending_list.map((prop, key) => {
      return prop.transfer_status_name;
    });

    //= Remove #1076 Debug message
    //console.log(transfer_status);

    var transfer_status_unique = [...new Set(transfer_status)];
    var transfer_status_optionlist = transfer_status_unique.map((prop, key) => {
      return (
        <option key={key} value={prop}>
          {prop}
        </option>
      );
    });

    //constances to bring in #Issue 941 Improvement visuals of transfers by Tom
    const steps = this.getSteps();
    const steps1 = this.getSteps1();

    return (
      <React.Fragment>
        <DateRangePicker
          isOutsideRange={() => false}
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
          displayFormat='DD/MM/YYYY'
          onDatesChange={({startDate, endDate}) => this.handle_date_change(startDate, endDate)} // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => this.setState({focusedInput})} // PropTypes.func.isRequired,
        />
        <ReactTable
          // data={this.state.entity_list}
          data={this.buildTableData()}
          filterable
          columns={[
            {
              Header: 'Id',
              Filter: ({filter, onChange}) => this.getFilter(filter, onChange),
              accessor: 'id',
              Cell: c => (
                <React.Fragment>
                  <Badge
                    color={c.original.status_id === 1 ? 'warning' : c.original.status_id === 2 ? 'info' : 'success'}
                  >
                    ID: {c.original.id}
                    {
                      //c.original.transfer_status
                    }
                  </Badge>
                </React.Fragment>
              ),
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['id']}),
              filterAll: true,
              maxWidth: 50
            },
            {
              Header: 'Client',
              Filter: ({filter, onChange}) => this.getFilter(filter, onChange),
              accessor: 'client',
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['client']}),
              filterAll: true,
              maxWidth: 450
            },
            {
              Header: 'Created',
              Filter: ({filter, onChange}) => this.getFilter(filter, onChange),
              accessor: 'record_created',
              Cell: c => (
                <React.Fragment>{c.value === null ? '' : moment(c.value).format('DD/MM/YYYY')} </React.Fragment>
              ),
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['transaction_date']}),
              filterAll: true,
              maxWidth: 150
            },
            {
              Header: 'Invoice',
              Filter: ({filter, onChange}) => this.getFilter(filter, onChange),
              accessor: 'invoice_date,',
              Cell: c => (
                <React.Fragment>{c.value === null ? '' : moment(c.value).format('DD/MM/YYYY')} </React.Fragment>
              ),
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['invoice_date']}),
              filterAll: true,
              maxWidth: 150
            },
            {
              Header: 'Due',
              Filter: ({filter, onChange}) => this.getFilter(filter, onChange),
              accessor: 'due_date',
              Cell: c => (
                <React.Fragment>{c.value === null ? '' : moment(c.value).format('DD/MM/YYYY')} </React.Fragment>
              ),
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['settlement_date']}),
              filterAll: true,
              maxWidth: 150
            },
            // {
            //   Header: "From",
            //  Filter: ({filter, onChange}) => this.getFilter(filter, onChange),//
            // accessor: "amount_from",
            //   Cell: c => (
            //     <React.Fragment>
            //       <div
            //         className={`currency-flag currency-flag-${
            //           c.original.currency_from
            //             ? c.original.currency_from.toLowerCase()
            //             : ""
            //           }`}
            //       />
            //       {c.original.currency_from} <br />
            //       <FormattedNumber
            //         minimumFractionDigits={2}
            //         maximumFractionDigits={2}
            //         value={c.value}
            //       />{" "}
            //     </React.Fragment>
            //   ),
            //   filterMethod: (filter, rows) =>
            //     matchSorter(rows, filter.value, { keys: ["amount_from"] }),
            //   filterAll: true,
            //   maxWidth: 150
            // },
            {
              Header: 'Amount',
              Filter: ({filter, onChange}) => this.getFilter(filter, onChange),
              accessor: 'amount_to',
              Cell: c => (
                <React.Fragment>
                  <div
                    className={`currency-flag currency-flag-${
                      c.original.currency_to ? c.original.currency_to.toLowerCase() : ''
                    }`}
                  />{' '}
                  {c.original.currency_to} <br />
                  <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} value={c.value} />{' '}
                </React.Fragment>
              ),
              filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['amount_to']}),
              filterAll: true,
              maxWidth: 150
            },
            // {
            //   Header: "Amount From",
            //  Filter: ({filter, onChange}) => this.getFilter(filter, onChange),//
            // accessor: "amount_base",
            //   filterMethod: (filter, rows) =>
            //     matchSorter(rows, filter.value, { keys: ["amount_base"] }),
            //   filterAll: true,
            //   maxWidth: 150
            // },
            // {
            //   Header: "Amount To",
            //  Filter: ({filter, onChange}) => this.getFilter(filter, onChange),//
            // accessor: "amount_terms",
            //   filterMethod: (filter, rows) =>
            //     matchSorter(rows, filter.value, { keys: ["amount_terms"] }),
            //   filterAll: true,
            //   maxWidth: 150
            // },
            // {
            //   Header: "Rate",
            //  Filter: ({filter, onChange}) => this.getFilter(filter, onChange),//
            // accessor: "client_rate",
            //   filterMethod: (filter, rows) =>
            //     matchSorter(rows, filter.value, { keys: ["client_rate"] }),
            //   filterAll: true,
            //   maxWidth: 150
            // },
            {
              Header: 'Status ',
              Filter: ({filter, onChange}) => this.getFilter(filter, onChange),
              accessor: 'transfer_status',
              filterMethod: (filter, row) => {
                if (filter.value === 'all') {
                  return true;
                }
                return row[filter.id] == filter.value;
              },
              Filter: ({filter, onChange}) => (
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{width: '100%'}}
                  value={filter ? filter.value : 'all'}
                >
                  <option value='all'>Show All</option>
                  {transfer_status_optionlist}
                </select>
              )
            },
            {
              Header: 'Actions',
              Filter: ({filter, onChange}) => this.getFilter(filter, onChange),
              accessor: 'actions',
              sortable: false,
              filterable: false
            }
          ]}
          defaultSorted={[
            {
              id: 'transaction_date',
              desc: true
            },
            {
              id: 'client_fullname',
              desc: false
            }
          ]}
          defaultPageSize={10}
          showPageSizeOptions={true}
          // showPaginationTop
          showPaginationBottom
          className='-highlight'
          //Subcomponent to bring in #Issue 941
          // SubComponent={v => {
          //   let ActivTab = 0;
          //   if (v.original.status_id == 2) ActivTab = 0;
          //   if (v.original.status_id == 3) ActivTab = 1;
          //   if (v.original.status_id == 4) ActivTab = 1;
          //   if (v.original.status_id == 5) ActivTab = 1;
          //   if (v.original.status_id == 6) ActivTab = 2;
          //   if (v.original.status_id == 7) ActivTab = 3;
          //   if (v.original.status_id == 8) ActivTab = 4;

          //   if (v.original.status_id == 10) ActivTab = 1;

          //   return (
          //     <Card>
          //       <CardBody>
          //         <GridContainer>
          //           <GridItem xs={12} sm={9}>
          //             {/*<h6>{v.original.transfer_status_name}</h6>*/}
          //             {v.original.status_id != 10 && (
          //               <Stepper activeStep={ActivTab}>
          //                 {steps.map((label, index) => {
          //                   const stepProps = {};
          //                   const labelProps = {};

          //                   return (
          //                     <Step key={label} {...stepProps}>
          //                       <StepLabel {...labelProps}>{label}</StepLabel>
          //                     </Step>
          //                   );
          //                 })}
          //               </Stepper>
          //             )}
          //             {v.original.status_id == 10 && (
          //               <Stepper activeStep={ActivTab} style={{ width: "30%" }}>
          //                 {steps1.map((label, index) => {
          //                   const stepProps = {};
          //                   const labelProps = {};

          //                   return (
          //                     <Step key={label} {...stepProps}>
          //                       <StepLabel {...labelProps}>{label}</StepLabel>
          //                     </Step>
          //                   );
          //                 })}
          //               </Stepper>
          //             )}
          //           </GridItem>
          //           <GridItem xs={12} sm={3}>
          //             {/*<h6>Actions</h6>*/}
          //             <div style={{ paddingTop: 16 }}>
          //               {
          //                 //Remove "Copy to New Transfer: button for CRM Issue #941 by Tom
          //                 // <Button
          //                 //   style={{width: 200}} color="primary" size="sm"
          //                 //   onClick={() => {
          //                 //     API.get("transfers", `/get/id/${v.original.id}`)
          //                 //     .then(transfer_detail => {
          //                 //       //console.log(transfer_detail);
          //                 //       //console.log(v.original);
          //                 //       this.props.history.push(`/dashboard/${v.original.currency_from}-${v.original.currency_to}-${v.original.amount_from}-${v.original.amount_to}-${(transfer_detail.fullList.beneficiary_id===null)?"":transfer_detail.fullList.beneficiary_id}-${(transfer_detail.fullList.purpose_of_payment_id===null)?"":transfer_detail.fullList.purpose_of_payment_id}-${(transfer_detail.fullList.purpose_of_payment_detail===null)?" ":transfer_detail.fullList.purpose_of_payment_detail}`);
          //                 //     })
          //                 //   }}
          //                 //   >
          //                 //   Copy to New Transfer</Button>
          //                 // <br/>
          //               }
          //               <Button
          //                 style={{ width: 200 }}
          //                 color="primary"
          //                 size="sm"
          //                 onClick={() => {
          //                   this.props.history.push(
          //                     `/transfers/edit/${v.original.id}`
          //                   );
          //                 }}
          //                 className="edit"
          //               >
          //                 View Transfer Details
          //               </Button>
          //               {/*<br/>*/}
          //             </div>
          //           </GridItem>
          //         </GridContainer>
          //       </CardBody>
          //     </Card>
          //   );
          // }}
        />

        <Dialog
          open={this.state.dialog_delete_confirm}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Are you sure that you wish to <strong>delete</strong> transfer record {this.state.dialog_delete_item_name}
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handle_delete_dialog_cancel} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => this.handle_delete_dialog_click(this.state.dialog_delete_item)}
              color='danger'
              autoFocus
            >
              Delete Record
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers: state.transfers,
    transfers_pending: state.transfers_pending
  };
};

const mapDispatchToProps = dispatch => {
  return {
    //TODO: Watch this fetching transfer list function for #892 changes - duplicate transfer display
    fetchTransferPendingList: () => {
      dispatch(fetchInvoicePendingList());
    }

    // setTransferSelectId: id => {
    //   dispatch(setTransferSelectId(id));
    // }
  };
};

const InvoiceListContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(InvoiceList)));

export default InvoiceListContainer;
