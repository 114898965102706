import {
  DIVISION_FETCH_DIVISION_LIST_REQUEST,
  DIVISION_FETCH_DIVISION_LIST_SUCCESS,
  DIVISION_CREATE_NEW_SHOW_UI,
  DIVISION_CREATE_NEW_START,
  DIVISION_CREATE_NEW_CANCEL,
  DIVISION_CREATE_NEW_COMPLETE,
  DIVISION_SELECT_UI,
  DIVISION_ENTITIES_LIST_IS_DIRTY,
  DIVISION_SELECT_SET_ID,
  DIVISION_SELECT_ENTITY_REQUEST,
  DIVISION_SELECT_ENTITY_SUCCESS
} from '../actions/divisions.js';

const initialState = {
  is_fetching: false,
  did_invalidate: false,
  divisions_list_is_dirty: false,
  divisions_list: [],
  divisions_detail_id: null,
  divisions_detail_fetching: false,
  divisions_current_ui: 'list',
  divisions_create_new_in_process: false
};

function division(state = initialState, action) {
  switch (action.type) {
    case DIVISION_FETCH_DIVISION_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
      });

    case DIVISION_FETCH_DIVISION_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        divisions_list: action.entities_list,
        lastUpdated: action.receivedAt
      });

    case DIVISION_CREATE_NEW_SHOW_UI:
      return Object.assign({}, state, {
        divisions_current_ui: 'list'
      });

    case DIVISION_CREATE_NEW_START:
      return Object.assign({}, state, {
        divisions_create_new_in_process: true
      });

    case DIVISION_CREATE_NEW_CANCEL:
      return Object.assign({}, state, {
        divisions_create_new_in_process: false,
        divisions_current_ui: 'list'
      });

    case DIVISION_CREATE_NEW_COMPLETE:
      return Object.assign({}, state, {
        divisions_create_new_in_process: false,
        divisions_current_ui: 'list',
        divisions_list_is_dirty: true
      });

    case DIVISION_SELECT_UI:
      return Object.assign({}, state, {
        divisions_current_ui: action.value
      });
    case DIVISION_ENTITIES_LIST_IS_DIRTY:
      return Object.assign({}, state, {
        divisions_list_is_dirty: action.value
      });

    case DIVISION_SELECT_SET_ID:
      return Object.assign({}, state, {
        divisions_detail_id: action.value
      });

    case DIVISION_SELECT_ENTITY_REQUEST:
      return Object.assign({}, state, {
        divisions_detail_fetching: true
      });

    case DIVISION_SELECT_ENTITY_SUCCESS:
      return Object.assign({}, state, {
        divisions_detail_fetching: false
      });

    default:
      return state;
  }
}

export default division;
