import React from 'react';
import {API} from 'aws-amplify';
import {connect} from 'react-redux';
// import Step3Confirm from "./Step3Confirm";
import {injectIntl} from 'react-intl';

import {updateNewTransferCreation} from '../../../redux/actions/transfers_2';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CardHeader from '../../../components/Card/CardHeader';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Table from 'components/Table/Table.jsx';
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableRow from "@material-ui/core/TableRow";
// import Checkbox from "@material-ui/core/Checkbox";

import Check from '@material-ui/icons/Check';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';

import {defaultFont, primaryColor, dangerColor, tooltip} from 'assets/jss/material-dashboard-pro-react.jsx';

var moment = require('moment');

const style = {
  ...customCheckboxRadioSwitch,
  ...extendedTablesStyle,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  },

  table: {
    marginBottom: '0'
  },
  tableRow: {
    position: 'relative',
    borderBottom: '1px solid #dddddd'
  },
  tableActions: {
    border: 'none',
    padding: '12px 8px !important',
    verticalAlign: 'middle'
  },
  tableCell: {
    ...defaultFont,
    padding: '0',
    verticalAlign: 'middle',
    border: 'none',
    lineHeight: '1.42857143',
    fontSize: '14px',
    width: '80%'
  },
  tableActionButton: {
    width: '27px',
    height: '27px',
    padding: '0'
  },
  tableActionButtonIcon: {
    width: '17px',
    height: '17px'
  },
  edit: {
    backgroundColor: 'transparent',
    color: primaryColor,
    boxShadow: 'none'
  },
  close: {
    backgroundColor: 'transparent',
    color: dangerColor,
    boxShadow: 'none'
  },
  tooltip
};

class Step4Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nickname: `FX Payment ${moment().format('Do MMMM YYYY')}`,
      nickname_state: 'success',
      checked_client: true,
      checked_paying_to: false,
      checked_paying_from: false,
      checked_amount: false,
      purpose_list: []
    };
  }
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  get_line_items = () => {};

  render() {
    const {classes} = this.props;

    if (!this.props.transfers_pending.new_transfer_data) return null;
    if (!this.props.transfers_pending.new_transfer_data.currency_to) return null;
    if (!this.props.transfers_pending.new_transfer_data.multiple_beneficiary_id) return null;

    let request_summary = (
      <React.Fragment>
        <table>
          <tr>
            <th style={{textAlign: 'left', paddingRight: 20}}>Invoice Amount:</th>
            <td>
              {this.props.intl.formatNumber(this.props.transfers_pending.new_transfer_data.amount_to, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}{' '}
              {this.props.transfers_pending.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}
            </td>
          </tr>
          <tr>
            <th style={{textAlign: 'left', paddingRight: 20}}>Due Date:</th>
            <td>{this.props.transfers_pending.new_transfer_data.due_date}</td>
          </tr>
        </table>
        <br />
        <hr />
        <table>
          <tr>
            <th style={{textAlign: 'left', paddingRight: 20}}>Item Category</th>
            <th style={{textAlign: 'left', paddingRight: 20}}>Item Detail</th>
            <th style={{textAlign: 'left'}}>Item Amount</th>
          </tr>
          {this.props.transfers_pending.new_transfer_data.multiple_beneficiary_id.map(item => {
            return (
              <React.Fragment>
                <tr>
                  <td style={{paddingRight: 20}}>{item.purpose_of_payment_description}</td>
                  <td style={{paddingRight: 20}}>{item.detail_1}</td>
                  <td style={{textAlign: 'right'}}>{item.amount}</td>
                  <br />
                </tr>
              </React.Fragment>
            );
          })}
        </table>
      </React.Fragment>
    );

    // let table_data_beneficiary = [];
    // let table_data_purpose = [];
    // let table_data_invoice = [];

    // for (let idx in this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id) {
    //   let payment = this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id[idx];

    //   let purpose_of_payment_str = "";
    //   let p_of_Payment = this.props.app_state.current_client.purpose_of_payment_list;
    //   for (var j = 0; j < p_of_Payment.length; j++){
    //     if(p_of_Payment[j].id == payment.purpose_of_payment_detail) purpose_of_payment_str = p_of_Payment[j].description;
    //   }
    //   let item_number = Number(idx) + 1;
    //   let item0 = <React.Fragment>{(item_number!==1) && <React.Fragment>-------------------------------------<br /></React.Fragment>}</React.Fragment>
    //   let item1 = <React.Fragment>{item_number}. {payment.beneficiary.nickname} (Acc: {payment.beneficiary.account_number})<br /></React.Fragment>;
    //   let item2 = <React.Fragment>{purpose_of_payment_str} {payment.detail_1 ? `[reference: ${payment.detail_1}]`: ""}<br />
    //   </React.Fragment>;
    //   let item3 = <React.Fragment>{this.props.intl.formatNumber(payment.amount)} {payment.beneficiary.currency_full_name} <br /></React.Fragment>;
    //   let data_item = [
    //     item0,
    //     item1,
    //     item2,
    //     item3
    //   ];
    //   let purpose_item = [<React.Fragment>{item_number}. {purpose_of_payment_str}<br /></React.Fragment>];

    //   let invoice_itm = <React.Fragment>
    //     {payment.detail_1 ? `${item_number}. [reference: ${payment.detail_1}]` : ""}<br />
    //   </React.Fragment>;

    //   let invoice_item = [invoice_itm];

    //   table_data_beneficiary.push(data_item);
    //   table_data_purpose.push(purpose_item);
    //   table_data_invoice.push(invoice_item);
    // }

    // if (!this.props.transfers_2.new_transfer_data) {
    //   // console.log(1)
    //   return null;
    // }
    // if (!this.state.purpose_list.length > 0) {
    //   // console.log(2)
    //   return null;
    // }
    // if (!this.props.transfers_2.new_transfer_data.multiple_beneficiary_id) {
    //   // console.log(3)
    //   return null;
    // }

    // if (
    //   !this.props.transfers_2.new_transfer_data.purpose_of_payment_detail_text
    // ) {
    //   // console.log(4)
    //   return null;
    // }

    // let beneficiary =
    //   this.props.transfers_2.new_transfer_data.beneficiary || {};
    // let client = this.props.transfers_2.new_transfer_data.client || {};
    // let currency_from =
    //   this.props.transfers_2.new_transfer_data.currency_from || {};
    // let currency_to =
    //   this.props.transfers_2.new_transfer_data.currency_to || {};
    // let current_rate = Number.parseFloat(
    //   this.props.transfers_2.new_transfer_data.current_rate
    // ).toFixed(5);
    // let amount_from = Number.parseFloat(
    //   this.props.transfers_2.new_transfer_data.amount_from
    // );
    // let amount_to = Number.parseFloat(
    //   this.props.transfers_2.new_transfer_data.amount_to
    // );

    // let payee = {};
    // if (this.props.transfers_2.new_transfer_data.payee_different === "0") {
    //   payee.nickname = this.props.app_state.current_client
    //     ? this.props.app_state.current_client.nickname
    //     : "";
    // } else {
    //   payee.nickname = `${this.props.transfers_2.new_transfer_data
    //     .payee_first_name || ""} ${this.props.transfers_2.new_transfer_data
    //       .payee_last_name || ""}`;
    // }

    return (
      <React.Fragment>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>Confirm Invoice Details...</h4>
          </GridItem>
        </GridContainer>
        {/* <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={6}>
            <CustomInput
              success={this.state.nickname_state === "success"}
              error={this.state.nickname_state === "error"}
              labelText={
                <span>Short description to assist finding transaction</span>
              }
              id="nickname"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: this.state.nickname,
                onChange: event => this.change(event, "nickname", "length", 3)
              }}
            />
          </GridItem>
        </GridContainer> */}

        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} lg={6}>
            <Card>
              <CardHeader color='rose' icon>
                <h4 className={classes.cardIconTitle}>Invoice Details</h4>
              </CardHeader>
              <CardBody>
                {request_summary}
                {/* <Table
                  tableData={[
                    ["Name", `${client.first_name} ${client.last_name}`],
                    [
                      "Address",
                      `${(client.residential_street_line_1 || "") +
                      " " +
                      (client.residential_street_line_2 || "") +
                      " " +
                      (client.residential_street_suburb || "")}`
                    ],
                    ["Email", `${client.email}`]
                  ]}
                /> */}
              </CardBody>
            </Card>
            {/* <Card style={{ height: "45%" }}>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>Confirm beneficiary</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableData={[
                    ["Beneficiary", `${beneficiary.ben_legal_name}`],
                    [
                      "Address",
                      `${(beneficiary.ben_address_line_1 || "") +
                      " " +
                      (beneficiary.ben_address_line_2 || "") +
                      " " +
                      (beneficiary.ben_address_suburb || "")}`
                    ]
                  ]}
                />
              </CardBody>
            </Card> */}
          </GridItem>

          {/* <GridItem xs={12} sm={12} lg={6}>
            <Card style={{ height: "90%" }}>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>Confirm payment</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableData={[
                    ["Bank", `${beneficiary.bank_legal_name}`],
                    ["BSB", `${beneficiary.bsb_code ? beneficiary.bsb_code : "N/A"}`],
                    ["Account", `${beneficiary.account_number}`],
                    [
                      "Payment for",
                      `${this.props.transfers_2.new_transfer_data.purpose_of_payment_detail_text}`
                    ],
                    [
                      "Further Details",
                      `${
                      this.props.transfers_2.new_transfer_data.detail_1
                        ? this.props.transfers_2.new_transfer_data.detail_1
                        : ""
                      }`
                    ],
                    [
                      "Amount from",
                      `${amount_from.toFixed(2)} ${currency_from.iso_alpha_3}`
                    ],
                    [
                      "Amount to",
                      `${amount_to.toFixed(2)} ${currency_to.iso_alpha_3}`
                    ],
                    [
                      "Rate",
                      `${Number.parseFloat(
                        this.props.transfers_2.new_transfer_data.client_rate
                      ).toFixed(5)} locked in until ${moment()
                        .add(10, "minutes")
                        .format("hh:mm A, DD/MM/YYYY")}.`
                    ]
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem> */}
        </GridContainer>
      </React.Fragment>
    );
  }
}

// export default withStyles(style)(Step4Confirm);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    transfers_pending: state.transfers_pending
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    }
  };
};

const Step4ConfirmContainer = connect(mapStateToProps, mapDispatchToProps)(Step4Confirm);

export default injectIntl(withStyles(style)(Step4ConfirmContainer));
