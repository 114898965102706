import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {selectRemittanceUi, fetchRemittanceList} from '../../../redux/actions/remittance_accounts';

import withStyles from '@material-ui/core/styles/withStyles';

import GridContainer from '../../../components/Grid/GridContainer.jsx';
import GridItem from '../../../components/Grid/GridItem.jsx';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';

import CardHeader from '../../../components/Card/CardHeader';
import CardIcon from '../../../components/Card/CardIcon';
import PermIdentity from '@material-ui/icons/PermIdentity';

import Button from '../../../components/CustomButtons/Button';

import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
import RemittanceEditContainer from '../../../components/Remittance/RemittanceEdit';

const staffStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class RemittanceEdit extends React.Component {
  render() {
    const {classes} = this.props;

    return (
      <div>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <PermIdentity />
            </CardIcon>
            <div className={classes.flexEnd}>
              <h4 className={classes.cardIconTitle}>Remittance Accounts</h4>
              <div>
                <Button
                  round
                  color={'info'}
                  onClick={() => this.props.history.push('/system-settings/remittance/list')}
                >
                  Show All
                </Button>
                <Button round color={'info'} onClick={() => this.props.history.push('/system-settings/remittance/new')}>
                  Create New
                </Button>
                <Button round color={'primary'}>
                  Edit Record
                </Button>
                <Button round color={'info'} onClick={() => this.props.fetchRemittanceList()}>
                  Refresh
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <RemittanceEditContainer remittance_id={this.props.match.params.remittance_id} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

RemittanceEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  selectStaffUi: PropTypes.func.isRequired
};

// export default withStyles(staffStyles)(RemittanceEdit);
const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    remittance: state.remittance
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectRemittanceUi: value => {
      dispatch(selectRemittanceUi(value));
    },
    fetchRemittanceList: value => {
      dispatch(fetchRemittanceList(value));
    }
  };
};

const RemittanceEditViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(staffStyles)(RemittanceEdit));

export default RemittanceEditViewContainer;
