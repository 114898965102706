import React from 'react';
import ReactTable from 'react-table';
import {API} from 'aws-amplify';
import {connect} from 'react-redux';
// import ReportsClient from "./ReportsClient";
import {
  fetchClientList
  // setClientSelectId
} from '../../redux/actions/clients';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom';

// @material-ui/icons
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// core components
import Button from 'components/CustomButtons/Button.jsx';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import matchSorter from 'match-sorter';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import GridItem from 'components/Grid/GridItem.jsx';

import {FormattedMessage} from 'react-intl';
import {CSVLink} from 'react-csv';
import GridContainer from '../Grid/GridContainer';

const moment = require('moment');

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

const columns = [
  {
    Header: 'Last Name',
    accessor: 'last_name',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['last_name']}),
    filterAll: true,
    maxWidth: 200
  },
  {
    Header: 'First Name',
    accessor: 'first_name',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['first_name']}),
    filterAll: true,
    maxWidth: 150
  },
  {
    Header: 'Email',
    accessor: 'email',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['email']}),
    filterAll: true,
    maxWidth: 450
  },
  {
    Header: 'Mobile',
    accessor: 'telephone_mobile',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['telephone_mobile']}),
    filterAll: true,
    maxWidth: 150
  },
  {
    Header: 'Team',
    accessor: 'team',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['team']}),
    filterAll: true
  },
  {
    Header: 'Verified',
    accessor: 'verified',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['verified']}),
    filterAll: true,
    Cell: props => {
      let val = '';
      if (props.value == 1) {
        val = 'VERIFIED';
      } else {
        val = 'NOT VERIFIED';
      }
      return <React.Fragment>{val}</React.Fragment>;
    }
  },
  {
    Header: 'Verification Review',
    accessor: 'verification_review',
    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['verification_review']}),
    filterAll: true,
    Cell: props => {
      return props.value ? moment(props.value).format('DD/MM/YYYY') : '';
    }
  }
];

class ReportsVerificationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client_list: [],
      entity_list: [],
      dialog_delete_confirm: false,
      dialog_delete_item: 0,
      dialog_delete_item_name: '',
      team_list: [],
      team: '',
      table_data: [],
      finished: false,
      dataToDownload: []
    };
  }

  componentDidMount() {
    API.get('teams', `/get-list-not-deleted`)
      .then(response => {
        this.setState({
          team_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.client_list !== this.state.client_list) {
      // console.log(this.state.client_list);
      this.setState({table_data: this.buildTableData()}, this.finished());
    }
  }

  download = event => {
    const currentRecords = this.reactTable.getResolvedState().sortedData;
    var data_to_download = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < columns.length; colIndex++) {
        record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor];
      }
      data_to_download.push(record_to_download);
    }
    this.setState({dataToDownload: data_to_download}, () => {
      // click the CSVLink component to trigger the CSV download
      this.csvLink.link.click();
    });
  };

  finished() {
    this.setState({finished: true});
  }

  handleSelectChange = event => {
    this.setState({[event.target.name]: event.target.value});
    this.getClientDetails(event.target.value);
  };

  getClientDetails(team_id) {
    // this.props.history.push("/reports/client/"+team_id);

    API.get('clients', `/getByTeam/id/${team_id}`)
      .then(response => {
        this.setState({client_list: response});
      })
      .catch(error => {
        console.log(error);
      });
  }

  handle_delete_dialog_click = item_to_delete => {
    this.setState({dialog_delete_confirm: false});
    API.put('clients', `/update/id/${item_to_delete}`, {
      body: {deleted: true}
    })
      .then(response => {
        console.log(response);
        this.props.fetchClientList();
      })
      .catch(error => {
        console.log(error);
        this.props.fetchClientList();
      });
  };

  handle_delete_dialog_cancel = () => {
    this.setState({
      dialog_delete_confirm: false,
      dialog_delete_item: 0,
      dialog_delete_item_name: ''
    });
  };

  buildTableData() {
    var client_list_length = this.state.client_list.length;
    if (client_list_length > 0) {
      return this.state.client_list.map((prop, key) => {
        return {
          id: prop.id,
          aws_cognito_id: prop.aws_cognito_id,
          first_name: prop.first_name,
          last_name: prop.last_name,
          verified: prop.verified,
          verification_review: prop.verification_review,
          email: prop.email,
          telephone_mobile: prop.telephone_mobile,
          residential_street_suburb: prop.residential_street_suburb,
          residential_street_country: prop.residential_street_country,
          entity: prop.entity_nickname,
          team: prop.team_nickname,
          division: prop.division_nickname,
          actions: (
            <div className='actions-right'>
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  this.props.history.push(`/clients/edit/${prop.id}`);
                }}
                color='warning'
                className='edit'
              >
                <Dvr />
              </Button>{' '}
              {/* use this button to remove the data row */}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  var data = this.state.client_list;
                  data.find((o, i) => {
                    if (o.id === prop.id) {
                      this.setState({
                        dialog_delete_confirm: true,
                        dialog_delete_item: prop.id,
                        dialog_delete_item_name: `${prop.first_name} ${prop.last_name}`
                      });

                      return true;
                    }
                    return false;
                  });
                  this.setState({data: data});
                }}
                color='danger'
                className='remove'
              >
                <Close />
              </Button>
              }{' '}
            </div>
          )
        };
      });
    }
  }

  render() {
    const {classes} = this.props;

    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                <FormattedMessage id='reportsClient.cardItem1.team' defaultMessage={`Teams`} />
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.team || ''}
                onChange={this.handleSelectChange}
                inputProps={{
                  name: 'team',
                  id: 'team'
                }}
              >
                <MenuItem
                  key='0x0'
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Select client {' from ' + this.state.clients_fullname}
                </MenuItem>
                {this.state.team_list
                  // .sort((a, b) => a.nickname.localeCompare(b.nickname))
                  .map(item => {
                    return (
                      <MenuItem
                        key={item.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={item.id}
                      >
                        {item.nickname}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <div style={{float: 'right'}}>
              <Button round color={'primary'} onClick={this.download}>
                <i className='material-icons'>cloud_download</i> CSV
              </Button>
            </div>
          </GridItem>
        </GridContainer>
        <hr />

        <ReactTable
          ref={r => (this.reactTable = r)}
          // data={this.buildTableData()}
          data={this.state.table_data}
          filterable
          columns={columns}
          defaultSorted={[
            {
              id: 'division',
              desc: false
            },
            {
              id: 'team',
              desc: false
            },
            {
              id: 'entity',
              desc: false
            },
            {
              id: 'last_name',
              desc: false
            },
            {
              id: 'first_name',
              desc: false
            }
          ]}
          defaultPageSize={10}
          // showPaginationTop
          showPaginationBottom
          className='-highlight'
        />
        <CSVLink
          data={this.state.dataToDownload}
          filename='data.csv'
          className='hidden'
          ref={r => (this.csvLink = r)}
          target='_blank'
        />
        <Dialog
          open={this.state.dialog_delete_confirm}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Are you sure that you wish to <strong>delete</strong> staff member record{' '}
              {this.state.dialog_delete_item_name}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handle_delete_dialog_cancel} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => this.handle_delete_dialog_click(this.state.dialog_delete_item)}
              color='danger'
              autoFocus
            >
              Delete Record
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

// export default withRouter(withStyles(styles)(ReportsClient));

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchClientList: () => {
      dispatch(fetchClientList());
    }
    // setClientSelectId: (id) => {
    // dispatch(setClientSelectId(id))
    // }
  };
};

const ReportsVerificationContainer = connect(mapStateToProps, mapDispatchToProps)(ReportsVerificationComponent);

export default withRouter(withStyles(styles)(ReportsVerificationContainer));
