import React, {Fragment, useEffect, useState} from 'react';
import Button from 'components/CustomButtons/Button.jsx';
import {API} from 'aws-amplify';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomReactSelect from 'components/Forms/CustomReactSelect/CustomReactSelect.jsx';
import Dvr from '@material-ui/icons/Dvr';
import Close from '@material-ui/icons/Close';
import {CustomDrawerComponent} from '../../Drawer/Drawer';

export const Teams = ({app_state, state: gState, client_id}) => {
  const [state, setState] = useState({
    clientsAccess: [],
    modal: false,
    team_list: [],
    teams: [],
    team_id: null,
    cuurentTeam: null
  });
  const [toggleDrawer, setToggleDrawer] = useState(null);
  const toggleModal = bool => {
    setState({
      ...state,
      modal: bool
    });
  };

  const fetchData = async () => {
    const [teams, team_list] = await Promise.all([
      API.get('teams', `/get/client_team/all/${client_id}`),
      API.get('teams', '/get-list-not-deleted')
    ]);
    setState({
      ...state,
      teams,
      modal: false,
      team_list
    });
  };

  const getInstructions = () => {
    if (!state.currentTeam) {
      return <div></div>;
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <h3 style={{color: '#fff'}}>Client Teams:</h3>
          {state.currentTeam.nickname && <p>Name: {state.currentTeam.nickname}</p>}
          {state.currentTeam.telephone_mobile && <p>Telephone: {state.currentTeam.telephone_mobile}</p>}
        </GridItem>
      </GridContainer>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClientSelectChange = event => {
    setState({
      ...state,
      team_id: event.value
    });
    // getClientDetails(event.value);
  };

  const handleSubmit = async () => {
    const team = state.team_list.find(x => x.id == state.team_id);
    const payload = {
      teamId: team.id,
      clientId: client_id
    };
    await API.post('teams', '/create/client_team/in-database', {body: payload});
    fetchData();
  };

  const deleteRow = async value => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      if (value.value) {
        const {clientId, teamId} = value.value;
        const response = await API.put('teams', '/update/client_team/id', {
          body: {
            clientId,
            teamId,
            allowAccess: false
          }
        });
        fetchData();
      }
    }
  };
  return (
    <Fragment>
      <GridContainer>
        <GridItem
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            color='info'
            size='md'
            // disabled = {state.team_list.length == 0}
            id='editClient'
            style={{cursor: 'pointer'}}
            onClick={() => toggleModal(true)}
          >
            Create Reord
          </Button>
        </GridItem>
      </GridContainer>
      <ReactTable
        data={state.teams}
        filterable
        columns={[
          {
            Header: 'Client ID',
            accessor: 'clientId',
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['clientId']}),
            filterAll: true
          },
          {
            Header: 'Team ID',
            accessor: 'teamId',
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['teamId']}),
            filterAll: true
          },
          {
            Header: 'Team Name',
            accessor: 'nickname',
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ['nickname']}),
            filterAll: true
          },
          {
            Header: 'Action',
            accessor: '',
            Cell: obj => (
              <div className='actions-right'>
                <Button
                  justIcon
                  round
                  simple
                  onClick={async () => {
                    console.info(' state is', obj);
                    const response = await API.get(
                      'teams',
                      `/get/client_team/${obj.original.clientId}/${obj.original.teamId}`
                    );
                    console.info('response', response);
                    if (response.length > 0) {
                      setState({
                        ...state,
                        currentTeam: response[0]
                      });
                    }
                    toggleDrawer();
                  }}
                  color='warning'
                  className='edit'
                >
                  <Dvr />
                </Button>{' '}
                <Button justIcon round simple onClick={() => deleteRow()} color='danger' className='remove'>
                  <Close />
                </Button>{' '}
              </div>
            )
          }
        ]}
        defaultSorted={[
          {
            id: 'teamId',
            desc: true
          },
          {
            id: 'clientId',
            desc: false
          }
        ]}
        defaultPageSize={10}
        showPaginationBottom
        className='-highlight'
      />

      {state.modal && (
        <Dialog
          fullWidth={true}
          open={state.modal ? state.modal : false}
          onClose={() => toggleModal(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Create Record</DialogTitle>
          <DialogContent>
            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <CustomReactSelect
                  label='Select teams'
                  options={state.team_list
                    .sort((a, b) => a.nickname.localeCompare(b.nickname))
                    .map(item => {
                      return {
                        value: item.id,
                        label: `${item.nickname} [${item.id.toString().padStart(5, '0')}] (${item.email})`
                      };
                    })}
                  value={state.team_id}
                  onChange={e => handleClientSelectChange(e)}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toggleModal(false)} color='primary'>
              Cancel
            </Button>
            <Button onClick={() => handleSubmit()} color='danger' autoFocus>
              Create Record
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <CustomDrawerComponent
        content={getInstructions()}
        toggleDraw={toggle => {
          setToggleDrawer(() => {
            return toggle;
          });
        }}
      />
    </Fragment>
  );
};
