import {connect} from 'react-redux';
import EntityNew from './EntityNew';
import {
  createNewEntity,
  createNewEntityStart,
  createNewEntityComplete,
  cancelCreateNewEntity,
  selectEntityUi,
  entitiesListIsDirty,
  fetchEntityListNotDeleted
} from '../../redux/actions/entities';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    staff: state.staff
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createNewEntity: data => {
      // console.log(data)
      dispatch(createNewEntity(data));
    },
    createNewEntityStart: () => {
      // console.log(data)
      dispatch(createNewEntityStart());
    },
    createNewEntityComplete: data => {
      dispatch(createNewEntityComplete(data));
    },
    cancelCreateNewEntity: () => {
      dispatch(cancelCreateNewEntity());
    },
    selectEntityUi: () => {
      dispatch(selectEntityUi());
    },
    entitiesListIsDirty: value => {
      dispatch(entitiesListIsDirty(value));
    },
    fetchEntityListNotDeleted: () => {
      dispatch(fetchEntityListNotDeleted());
    }
  };
};

const EntityNewContainer = connect(mapStateToProps, mapDispatchToProps)(EntityNew);

export default EntityNewContainer;
