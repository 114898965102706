import React, {Fragment, useState, useEffect} from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Box, Grid, Link, TextField, Typography} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody.jsx';
import CardHeader from '../../../components/Card/CardHeader';
import Button from '../../../components/CustomButtons/Button.jsx';
import {API, Storage} from 'aws-amplify';
import {Col, Row} from "antd";
import FileUploader from "../../../components/Uploaders/FileUploader";

const TransferDocumentsView = ({classes, transferDocumentData, transferDocumentId}) => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [transferId, setTransferId] = useState('');
  const [description, setDescription] = useState('');
  const [documentId, setDocumentId] = useState('');
  const [attachments, setAttachments] = useState([]);

  const [documentDownloadUrl, setDocumentDownloadUrl] = useState(null);

  const handleSave = () => {
    setLoading(true);

    const transferDocumentData = {
      transferId,
      description
    };

    API.put('transfer-documents', `/id/${transferDocumentId}`, {body: transferDocumentData})
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
        API.put('transfer-documents', `/id/${transferDocumentId}`, {body: transferDocumentData})
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUrl = async fileKey => {
    return await Storage.get(fileKey);
  };

  useEffect(() => {
    if (!transferDocumentData) {
      return;
    }

    if (transferDocumentData.file_ref) {
      getUrl(transferDocumentData.file_ref)
        .then(response => {
          setDocumentDownloadUrl(response);
        })
        .catch(err => {
          console.log(err.message || 'error');
        });
    }

    setTransferId(transferDocumentData.transferId);
    setDescription(transferDocumentData.description);
    setDocumentId(transferDocumentData.documentId);
    console.log(transferDocumentData);
    if (transferDocumentData.attachments != null) {
      const files = transferDocumentData.file_refs;
      setAttachments(files);
      if (transferDocumentData.file_refs != null && transferDocumentData.file_refs.length > 0) {
        setTimeout(() => setEditMode(true), 3000);
      }
    }
  }, [transferDocumentData]);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Card>
            <CardHeader color='rose' icon>
              <div className={classes.flexEnd}>
                <h4 className={classes.cardIconTitle}>{editMode ? 'Edit Record: ' : 'View Record: '}</h4>
                <React.Fragment>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={editMode}
                        onChange={() => setEditMode(prevState => !prevState)}
                        value='edit_mode'
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label={editMode ? 'Edit Mode' : 'Read Only'}
                  />
                </React.Fragment>
              </div>
            </CardHeader>
            <CardBody>
              <Box className={classes.editFields}>
                {documentId && (
                  <Typography color={editMode ? 'textPrimary' : 'textSecondary'}>Document ID: {documentId}</Typography>
                )}

                <TextField
                  disabled={!editMode}
                  fullWidth
                  label='Transfer Id'
                  value={transferId}
                  onChange={e => setTransferId(e.target.value)}
                  size='small'
                  autoComplete='no'
                />
                <TextField
                  disabled={!editMode}
                  fullWidth
                  label='Description'
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  size='small'
                  autoComplete='no'
                  multiline
                  minRows={3}
                />
              </Box>
              <Button
                disabled={!editMode || !transferDocumentId || loading}
                onClick={handleSave}
                type='submit'
                color='primary'
              >
                {loading ? 'Saving...' : 'Save'}
              </Button>
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} lg={9}>
          {documentDownloadUrl && attachments.length === 0 &&
            <Typography>
              Document Preview{' - '}
              <Typography component={Link} target={'_blank'} rel='noreferrer' href={documentDownloadUrl}>
                Click here to download
              </Typography>
            </Typography>
          }
          <Box boxShadow={2} style={{ padding: '10px' }}>
            {documentDownloadUrl && attachments.length === 0 && (
              <Fragment>
                <object data={documentDownloadUrl} width='100%' height={800}>
                  <Typography component={Link} target={'_blank'} href={documentDownloadUrl} rel='noreferrer'>
                    Click here to download
                  </Typography>
                </object>
              </Fragment>
            )}
            {
              <Row gutter={[16, 16]}>
                {
                    attachments && attachments.map(item => {
                      return <Col span={8}
                                  style={{textAlign: 'center', paddingLeft: '10px', paddingRight: '10px'}}>
                        <FileUploader previewOnly={true}
                                      thumbType={'picture-card'}
                                      uploaderType={'PDF'}
                                      fetchFileList={() => {
                                        const file = {
                                          name: item,
                                          uid: item,
                                          status: 'done'
                                        }
                                        return Promise.resolve([file]);
                                      }}></FileUploader>
                      </Col>
                    })
                }
              </Row>
            }
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default TransferDocumentsView;
