import {connect} from 'react-redux';
import EntitybanksaccountEdit from './EntitybanksaccountEdit';
import {
  createNewEntitybanks,
  createNewEntitybanksStart,
  createNewEntitybanksComplete,
  cancelCreateNewEntitybanks,
  selectEntitybanksUi,
  entitybanksListIsDirty
} from '../../redux/actions/entitybanksaccount';

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    entity_banks_account: state.entity_banks_account
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createNewEntitybanks: data => {
      dispatch(createNewEntitybanks(data));
    },
    createNewEntitybanksStart: () => {
      dispatch(createNewEntitybanksStart());
    },
    createNewEntitybanksComplete: data => {
      dispatch(createNewEntitybanksComplete(data));
    },
    cancelCreateNewEntitybanks: () => {
      dispatch(cancelCreateNewEntitybanks());
    },
    selectEntitybanksUi: value => {
      dispatch(selectEntitybanksUi(value));
    },
    entitybanksListIsDirty: value => {
      dispatch(entitybanksListIsDirty(value));
    }
  };
};

const EntitybanksaccountEditContainer = connect(mapStateToProps, mapDispatchToProps)(EntitybanksaccountEdit);

export default EntitybanksaccountEditContainer;
