import React from 'react';
import {connect} from 'react-redux';
import {
  updateNewTransferCreation,
  updateNewTransferCreationBeneficiary,
  createNewTransfer
} from '../../../redux/actions/transfers_dashboard_multiple';

import withStyles from '@material-ui/core/styles/withStyles';
import dashboardStyle from '../../../views/Pages/DashboardPageStyle';
import {injectIntl} from 'react-intl';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import {API} from 'aws-amplify';
import Table from 'components/Table/Table.jsx';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const moment = require('moment');
const axios = require('axios');

class MultiPayTransferStep3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_step: 2,
      time: {},
      seconds: 1,
      purpose_list: []
    };

    this.timer = 0;
  }

  componentDidMount() {
    if (this.props.transfers_dashboard_multiple.current_step === 2) {
      this.startTimer();
    }
    if (this.props.transfers_dashboard_multiple.current_step !== 2) {
      this.stopTimer();
      this.go = false;
    }
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.prefill !== this.props.prefill) {
      this.props.switch_to_step1();
    }
    if (
      this.props.app_state.current_team &&
      this.state.purpose_list.length == 0 &&
      this.props.app_state.current_team !== prevProps.app_state.current_team
    ) {
      API.get('transfers', `/get_purpose_by_team/id/${this.props.app_state.current_team.id}`)
        .then(response => {
          console.log('response', response);
          this.setState({purpose_list: response});
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  secondsToTime = secs => {
    const hours = Math.floor(secs / (60 * 60));

    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);

    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);

    const obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  };

  startTimer = () => {
    if (this.props.transfers_dashboard_multiple.current_step === 2) {
      this.go = true;
    }

    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    //go means this is step 2, we start count down

    const seconds = this.state.seconds - 1;

    if (this.go) {
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds
      });

      if (seconds === 0) {
        this.fetchNewRate();
        this.setState({seconds: 30});
      }
    }
  };

  stopTimer = () => {
    this.go = false;
    this.setState({
      seconds: 0,
      time: 0
    });
    clearInterval(this.timer);
    this.timer = null;
  };

  fetchNewRate = () => {
    const url = 'https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
    axios
      .get(url, {
        params: {
          currencies: this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3,
          source: this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.iso_alpha_3,
          format: '1'
        }
      })
      .then(response => {
        const key = `${this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}${this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}`;
        const rate_string = response.data.quotes[key];
        const rate = Number.parseFloat(rate_string);
        const rate_adjust =
          Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate) +
          Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate_division) +
          Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate_entity) +
          Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate_team) +
          Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.client.default_rate_staff);
        let adjusted_rate = rate - rate * rate_adjust;

        if (
          this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_id ==
          this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_id
        ) {
          adjusted_rate = rate;
        }

        // handle to_amount
        if (this.props.transfers_dashboard_multiple.new_transfer_data.amount_lock === 'from_amount') {
          const amount_from = Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.amount_from);
          const amount_to = amount_from * adjusted_rate;
          this.props.updateNewTransferCreation('amount_to', amount_to.toFixed(2));
        }

        // handle from_amount
        if (this.props.transfers_dashboard_multiple.new_transfer_data.amount_lock === 'to_amount') {
          const amount_to = Number.parseFloat(this.props.transfers_dashboard_multiple.new_transfer_data.amount_to);
          const amount_from = amount_to / adjusted_rate;
          this.props.updateNewTransferCreation('amount_from', amount_from.toFixed(2));
        }
        this.props.updateNewTransferCreation('client_rate', adjusted_rate);
      })
      .catch(error => {
        console.log(error);
      });
  };

  getNextBusinessDay(d, n) {
    d = new Date(d.getTime());
    const day = d.getDay();
    d.setDate(d.getDate() + n + (day === 6 ? 2 : Number(!day)) + Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2);
    return d;
  }

  handle_transfer_creation = () => {
    const set_date = this.getNextBusinessDay(new Date(), 2);
    const settlement_date = set_date.toISOString().slice(0, 10).replace('T', ' ');

    API.post('email', '/interaction/main', {
      body: {
        interaction: 'New Transfer Request',
        data: {
          ...this.props.transfers_dashboard_multiple.new_transfer_data,
          record_created_datetime: new Date().toISOString().slice(0, 19).replace('T', ' '),
          transaction_datetime: new Date().toISOString().slice(0, 10).replace('T', ' '),
          settlement_date: moment(set_date).format('DD/MM/YYYY'),
          status: '2',
          from: 'Step 0 - Email us',
          purpose: 'test only'
        },
        result: 'database submission in process'
      }
    });

    // Step 1 - Email client
    // the following API call is working but the content might need to be modified
    const adjusted_remittance_reference = this.props.transfers_dashboard_multiple.new_transfer_data
      .remitting_bank_account_detail.reference_mandatory
      ? this.props.transfers_dashboard_multiple.new_transfer_data.remitting_bank_account_detail.reference_mandatory
      : this.props.transfers_dashboard_multiple.new_transfer_data.remitting_bank_account_detail.reference_prefix
      ? this.props.transfers_dashboard_multiple.new_transfer_data.remitting_bank_account_detail.reference_prefix +
        this.props.transfers_dashboard_multiple.new_transfer_data.client.id
      : 'FS' + this.props.transfers_dashboard_multiple.new_transfer_data.client.id.toString().padStart(5, '0');

    API.post('email', '/client/remittance-instructions', {
      body: {
        id: this.props.transfers_dashboard_multiple.new_transfer_data.client.id,
        rate: this.props.transfers_dashboard_multiple.new_transfer_data.client_rate,
        settlement_date: moment(set_date).format('DD/MM/YYYY'),
        amount_to: this.props.transfers_dashboard_multiple.new_transfer_data.amount_to,
        currency_to: this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.full_name,
        currency_to_iso3: this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3,
        amount_from: this.props.transfers_dashboard_multiple.new_transfer_data.amount_from,
        currency_from: this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.full_name,
        currency_from_iso3: this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.iso_alpha_3,
        remitting_bank_id:
          this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.primary_bank_account_id,
        eba_account_name:
          this.props.transfers_dashboard_multiple.new_transfer_data.remitting_bank_account_detail.eba_accout_name,
        remittance_reference: adjusted_remittance_reference,
        language_id: this.props.app_state.language_id
      }
    })
      .then(response => {
        // console.log(response);
      })
      .catch(error => {
        console.log(error);
      });

    // Step 3 - Create transfer
    this.props.createNewTransfer({
      ...this.props.transfers_dashboard_multiple.new_transfer_data,
      nickname: `${this.props.transfers_dashboard_multiple.new_transfer_data.amount_from} ${
        this.props.transfers_dashboard_multiple.new_transfer_data.currency_from_name
      } to ${this.props.transfers_dashboard_multiple.new_transfer_data.amount_to} ${
        this.props.transfers_dashboard_multiple.new_transfer_data.currency_to_name
      } Settling ${moment(set_date).format('DD/MM/YYYY')}`,
      record_created_datetime: new Date().toISOString().slice(0, 10).replace('T', ' '),
      transaction_datetime: new Date().toISOString().slice(0, 10).replace('T', ' '),
      settlement_date: settlement_date,
      client_id: this.props.transfers_dashboard_multiple.new_transfer_data.client.id,
      staff_id: this.props.transfers_dashboard_multiple.new_transfer_data.client.responsible_staff_member,
      beneficiary_ids: this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id
    });
    this.props.on_proceed_click();
  };

  handleCheckboxToggle = name => event => {
    if (name === 'checked_email_beneficiary') {
      const checked_email_beneficiary = event.target.checked;
      this.props.updateNewTransferCreation('checked_email_beneficiary', checked_email_beneficiary);
    }
  };

  render() {
    const {classes} = this.props;
    if (
      !this.props.transfers_dashboard_multiple.new_transfer_data.currency_from ||
      !this.props.transfers_dashboard_multiple.new_transfer_data.currency_to
    ) {
      return null;
    }

    const request_summary = `You sell
      ${this.props.intl.formatNumber(this.props.transfers_dashboard_multiple.new_transfer_data.amount_from, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })} ${this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}
      to buy ${this.props.intl.formatNumber(this.props.transfers_dashboard_multiple.new_transfer_data.amount_to, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })} ${this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}
      `;

    const table_data_beneficiary = [];
    const table_data_purpose = [];
    const table_data_invoice = [];

    for (const idx in this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id) {
      const payment = this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id[idx];

      if (payment.beneficiary == undefined) {
        return null;
      }

      let purpose_of_payment_str = '';
      const p_of_Payment = this.state.purpose_list;
      for (let j = 0; j < p_of_Payment.length; j++) {
        if (p_of_Payment[j].id == payment.purpose_of_payment_detail) {
          purpose_of_payment_str = p_of_Payment[j].description;
        }
      }
      const item_number = Number(idx) + 1;
      const item0 = (
        <React.Fragment>
          {item_number !== 1 && (
            <React.Fragment>
              -------------------------------------
              <br />
            </React.Fragment>
          )}
        </React.Fragment>
      );
      const item1 = (
        <React.Fragment>
          {item_number}. {payment.beneficiary.nickname} (Acc: {payment.beneficiary.account_number})<br />
        </React.Fragment>
      );
      const item1a = (
        <React.Fragment>
          {payment.beneficiary.swift_code && <span>SWIFT: {payment.beneficiary.swift_code}<br/></span>}
          {payment.beneficiary.aba_routing_number && <span>ABA: {payment.beneficiary.aba_routing_number}<br/></span>}
          {payment.beneficiary.account_number && <span>Account: {payment.beneficiary.account_number}<br/></span>}
          {payment.beneficiary.iban && <span>IBAN: {payment.beneficiary.iban}<br/></span>}
          
        </React.Fragment>
      );
      const item2 = (
        <React.Fragment>
          {purpose_of_payment_str} {payment.detail_1 ? `[reference: ${payment.detail_1}]` : ''}
          <br />
        </React.Fragment>
      );
      const item3 = (
        <React.Fragment>
          {Number.parseFloat(payment.amount).toFixed(2)} {payment.beneficiary.currency_full_name} <br />
        </React.Fragment>
      );
      const data_item = [item0, item1, item3, item1a, item2];
      const purpose_item = [
        <React.Fragment>
          {item_number}. {purpose_of_payment_str}
          <br />
        </React.Fragment>
      ];

      const invoice_itm = (
        <React.Fragment>
          {payment.detail_1 ? `${item_number}. [reference: ${payment.detail_1}]` : ''}
          <br />
        </React.Fragment>
      );

      const invoice_item = [invoice_itm];

      table_data_beneficiary.push(data_item);
      table_data_purpose.push(purpose_item);
      table_data_invoice.push(invoice_item);
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          <h4>New Transfer Request - Confirm your details</h4>
        </GridItem>
        <GridItem>
          <Table
            tableData={[
              [
                'You Sell',
                <React.Fragment>
                  <div
                    className={`currency-flag currency-flag-${this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.iso_alpha_3.toLowerCase()}`}
                  />{' '}
                  {this.props.transfers_dashboard_multiple.new_transfer_data.currency_from.iso_alpha_3.toUpperCase()}{' '}
                  {this.props.intl.formatNumber(this.props.transfers_dashboard_multiple.new_transfer_data.amount_from, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </React.Fragment>
              ],
              [
                'You Buy',
                <React.Fragment>
                  <div
                    className={`currency-flag currency-flag-${this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3.toLowerCase()}`}
                  />{' '}
                  {this.props.transfers_dashboard_multiple.new_transfer_data.currency_to.iso_alpha_3.toUpperCase()}{' '}
                  {this.props.intl.formatNumber(this.props.transfers_dashboard_multiple.new_transfer_data.amount_to, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </React.Fragment>
              ],
              [
                'Exchange Rate',
                <React.Fragment>
                  {this.props.intl.formatNumber(this.props.transfers_dashboard_multiple.new_transfer_data.client_rate, {
                    minimumFractionDigits: 5,
                    maximumFractionDigits: 5
                  })}
                </React.Fragment>
              ],
              [
                'Payments',
                <React.Fragment>
                  {this.props.transfers_dashboard_multiple.new_transfer_data.multiple_beneficiary_id[0]
                    .checked_beneficiary_details === true ? (
                    <React.Fragment>To Be Advised</React.Fragment>
                  ) : (
                    <React.Fragment>{table_data_beneficiary}</React.Fragment>
                  )}
                </React.Fragment>
              ],
              ['Request Summary', `${request_summary}`]
            ]}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    transfers_dashboard_multiple: state.transfers_dashboard_multiple
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewTransferCreation: (key, value) => {
      dispatch(updateNewTransferCreation(key, value));
    },
    updateNewTransferCreationBeneficiary: array => {
      dispatch(updateNewTransferCreationBeneficiary(array));
    },
    createNewTransfer: data => {
      dispatch(createNewTransfer(data));
    }
  };
};

const MultiPayTransferStep3Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(MultiPayTransferStep3));

export default injectIntl(MultiPayTransferStep3Container);
