import React, {useState, useEffect, useRef} from 'react';
import { Box } from '@material-ui/core';
import { API } from 'aws-amplify';
import BeneficiaryDocumentsList from '../../../features/Beneficiaries/documents/List.jsx';
import {Button, Flex, Modal, Spin} from "antd";
import TextArea from "antd/lib/input/TextArea";
import FileUploadNew from "../../FileUpload/FileUploadNew";
import {TransferDetailAPI} from "../../Transfers/TransferDetail/TransferDetailConstants";
import {BeneficiaryAPI} from "../BeneficiaryUtils";

const BeneficiaryDocuments = props => {

    const { beneficiaryId, history } = props;
    const uploadRef = useRef();
    const [beneficiaryDocumentsList, setBeneficiaryDocumentsList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadedImageId, setUploadedImageId] = useState(null);
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const saveBeneficiaryDocument = () => {
        const beneficiaryDocumentData = {
            beneficiaryId,
            description,
            file_ref: uploadedImageId
        };
        BeneficiaryAPI.saveBeneficiaryDocument(beneficiaryDocumentData)
            .then(() => {
                setIsModalOpen(false);
            }).catch(error => {
            BeneficiaryAPI.saveBeneficiaryDocument(beneficiaryDocumentData).then(() => setIsModalOpen(false)).catch(error => {
                alert('Upload Failed');
            });
        }).finally(() => {
            setIsLoading(false);
            fetchDocumentsByBeneficiaryId(beneficiaryId);
            setDescription(null);
            setUploadedImageId(null);
            uploadRef.current.removeImage();
        });
    }


    const onModalCancel = () => {
        setIsModalOpen(false);
        setDescription(null);
        uploadRef.current.removeImage();
    }

    const fetchDocumentsByBeneficiaryId = (beneficiaryId) => {
        BeneficiaryAPI.fetchBeneficiaryDocument(beneficiaryId)
            .then(response => {
                console.log(response);
                setBeneficiaryDocumentsList(response);
            })
            .catch(error => {
                console.log(error);
                BeneficiaryAPI.fetchBeneficiaryDocument(beneficiaryId)
                    .then(response => {
                        console.log(response);
                        setBeneficiaryDocumentsList(response);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
    };

    useEffect(() => {
        if (beneficiaryId) {
            fetchDocumentsByBeneficiaryId(beneficiaryId);
        }

    }, [beneficiaryId]);

    return (
        <Box sx={{ padding: '16px' }}>
            <Flex justify={'flex-end'}>
                <Button type={'primary'} onClick={() => setIsModalOpen(true)}>Upload Document &nbsp;<i className={'fas fa-cloud-upload-alt'}></i></Button>
            </Flex>
            <Modal title={`Upload Beneficiary Documents to Beneficiary ID - ${beneficiaryId}`} open={isModalOpen} onOk={() => saveBeneficiaryDocument()} onCancel={onModalCancel}>
                <TextArea value={description} placeholder={'Enter Invoice Description'} name={'description'} style={{marginBottom: '10px'}} onChange={(e) => setDescription(e.target.value)} />
                <label>Upload Image:</label>
                <FileUploadNew ref={uploadRef} onHandleResult={(data) => setUploadedImageId(data.file_id)}></FileUploadNew>
                <span style={{textAlign: 'center' , display: isLoading ? 'block' : 'none'}}><Spin /> Saving... Please wait</span>
            </Modal>
            <BeneficiaryDocumentsList beneficiary_documents_list={beneficiaryDocumentsList} history={history} />
        </Box>
    );
};

export default BeneficiaryDocuments;
