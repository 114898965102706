import {withStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {fetchTeamRequestList} from '../../../redux/actions/teams';
import {TeamInvite} from './TeamInvite';
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react.jsx';
const entitiesStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};
const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    teams: state.teams
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTeamRequestList: data => {
      dispatch(fetchTeamRequestList(data));
    }
  };
};

const TeamInviteContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(entitiesStyles)(TeamInvite)));

export default TeamInviteContainer;
