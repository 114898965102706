/*
 *  Take out unused imports improvement by Tom #1076
 */

import React from 'react';
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// import TransferEdit from "./TransferEdit";
import {
  //fetchClientDetails,
  loadTransferDetailsRequest,
  loadTransferDetailsSuccess
} from '../../redux/actions/transfers';

// import matchSorter from 'match-sorter'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// @material-ui/icons
// import Today from "@material-ui/icons/Today";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import AvTimer from "@material-ui/icons/AvTimer";

import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
// import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// import staffEditStyles from "./StaffEditStyles";
// import PermIdentity from "@material-ui/icons/PermIdentity";
import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import NavPills from 'components/NavPills/NavPills.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import {API} from 'aws-amplify';
// import { loadStaffMemberDetailsSuccess } from "../../redux/actions/staff";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import CardFooter from "../Card/CardFooter";
// import TransferDetailRemittance from "./TransferDetail/TransferDetailRemittance";
// import TransferDetailPayout from "./TransferDetail/TransferDetailPayout";
import {injectIntl} from 'react-intl';

import TimelineComponent from 'components/Timeline/Timeline.jsx';
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import CardTravel from '@material-ui/icons/CardTravel';
// import Build from "@material-ui/icons/Build";
import Table from 'components/Table/Table.jsx';

var moment = require('moment');

const staffEditStyles = {
  selectStyles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {
    color: '#999999'
  },
  updateProfileButton: {
    float: 'right'
  },
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {
    float: 'right'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

class InvoicesEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // register form
      loaded: false,
      is_loading: true,
      current_transfer: {},
      current_transfer_client: {},
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      password: '',
      security_entities: [],
      security_entities_forexsport: false,
      security_entities_forexworldwide: false,
      security_entities_forexstudent: false,
      transfer_nickname: '',
      client: '',
      client_id: '',
      transaction_datetime: '',
      beneficiary_name: '',
      client_list: [],
      currencies_list: [],
      currencies_list_priority: [],
      beneficiaries_list: [],
      transfer_status_list: [],
      beneficiary_id: '',
      beneficiary: {},
      currency_from_id: '',
      currency_to_id: '',
      amount_from: '',
      amount_to: '',
      client_rate: '',
      settlement_date: ''
    };
  }

  componentDidMount() {
    if (this.props.transfer_pending_detail_id) {
      // this.props.loadTransferDetailsRequest();

      API.get('transfers_pending', `/get/id/${this.props.transfer_pending_detail_id}`)
        .then(response => {
          // console.log(this.props.transfer_detail_id);
          console.log(response);
          this.setState({
            loaded: true,
            is_loading: false,
            transfer_status: response.status,
            ...response.transfer_pending
          });
          // this.props.loadTransferDetailsSuccess();
          return response.transfer_pending;
        })
        .then(response => {
          API.get('clients', `/get/id/${response.client_id}`)
            .then(response => {
              console.log(response);
              if (!response) {
                this.setState({
                  loaded: false,
                  is_loading: true
                });
              }
              this.setState({
                current_transfer_client: response
              });
              return response.team_id;
            })
            .then(team_id => {
              API.get('teams', `/get/div-entity/${team_id}`)
                .then(response => {
                  console.log(response);
                  this.setState({
                    current_transfer_client: Object.assign({}, this.state.current_transfer_client, {
                      division_nickname: response.division_nickname,
                      entity_nickname: response.entity_nickname,
                      team_nickname: response.team_nickname
                    })
                  });
                })
                .catch(error => {
                  console.log(error);
                  // API.get("teams", `/get/div-entity/${team_id}`)
                  //   .then(response => {
                  //     // console.log(response)
                  //     this.setState({
                  //       current_transfer_client: Object.assign(
                  //         {},
                  //         this.state.current_transfer_client,
                  //         {
                  //           division_nickname: response.division_nickname,
                  //           entity_nickname: response.entity_nickname,
                  //           team_nickname: response.team_nickname
                  //         }
                  //       )
                  //     });
                  //   })
                  //   .catch(error => {
                  //     console.log(error);
                  //   });
                });
            })
            .catch(error => {
              console.log(error);
              // API.get("transfers", `/get/id/${this.props.transfer_detail_id}`)
              //   .then(transfer_detail => {
              //     // console.log(this.props.transfer_detail_id)
              //     // console.log(transfer_detail);
              //     this.setState({
              //       loaded: true,
              //       is_loading: false,
              //       transfer_status: transfer_detail.status,
              //       ...transfer_detail.fullList
              //     });
              //     this.props.loadTransferDetailsSuccess();
              //     return transfer_detail.fullList;
              //   })
              //   .then(response => {
              //     API.get("clients", `/get/id/${response.client_id}`)
              //       .then(response => {
              //         // console.log(response);
              //         if (!response) {
              //           this.setState({
              //             loaded: false,
              //             is_loading: true
              //           });
              //         }
              //         this.setState({
              //           current_transfer_client: response
              //         });
              //         return response.team_id;
              //       })
              //       .then(team_id => {
              //         API.get("teams", `/get/div-entity/${team_id}`)
              //           .then(response => {
              //             // console.log(response);
              //             this.setState({
              //               current_transfer_client: Object.assign(
              //                 {},
              //                 this.state.current_transfer_client,
              //                 {
              //                   division_nickname: response.division_nickname,
              //                   entity_nickname: response.entity_nickname,
              //                   team_nickname: response.team_nickname
              //                 }
              //               )
              //             });
              //           })
              //           .catch(error => {
              //             console.log(error);
              //             API.get("teams", `/get/div-entity/${team_id}`)
              //               .then(response => {
              //                 // console.log(response);
              //                 this.setState({
              //                   current_transfer_client: Object.assign(
              //                     {},
              //                     this.state.current_transfer_client,
              //                     {
              //                       division_nickname: response.division_nickname,
              //                       entity_nickname: response.entity_nickname,
              //                       team_nickname: response.team_nickname
              //                     }
              //                   )
              //                 });
              //               })
              //               .catch(error => {
              //                 console.log(error);
              //               });
              //           });
              //       })
              //       .catch(error => {
              //         console.log(error);
              //       });
              //   });
            });
        })
        .catch(error => {
          console.log(error);
          // API.get("transfers", `/get/id/${this.props.transfer_detail_id}`)
          //   .then(transfer_detail => {
          //     // console.log(this.props.transfer_detail_id);
          //     // console.log(transfer_detail);
          //     this.setState({
          //       loaded: true,
          //       is_loading: false,
          //       transfer_status: transfer_detail.status,
          //       ...transfer_detail.fullList
          //     });
          //     this.props.loadTransferDetailsSuccess();
          //     return transfer_detail.fullList;
          //   })
          //   .then(response => {
          //     API.get("clients", `/get/id/${response.client_id}`)
          //       .then(response => {
          //         // console.log(response);
          //         if (!response) {
          //           this.setState({
          //             loaded: false,
          //             is_loading: true
          //           });
          //         }
          //         this.setState({
          //           current_transfer_client: response
          //         });
          //         return response.team_id;
          //       })
          //       .then(team_id => {
          //         API.get("teams", `/get/div-entity/${team_id}`)
          //           .then(response => {
          //             // console.log(response)
          //             this.setState({
          //               current_transfer_client: Object.assign(
          //                 {},
          //                 this.state.current_transfer_client,
          //                 {
          //                   division_nickname: response.division_nickname,
          //                   entity_nickname: response.entity_nickname,
          //                   team_nickname: response.team_nickname
          //                 }
          //               )
          //             });
          //           })
          //           .catch(error => {
          //             console.log(error);
          //             API.get("teams", `/get/div-entity/${team_id}`)
          //               .then(response => {
          //                 // console.log(response)
          //                 this.setState({
          //                   current_transfer_client: Object.assign(
          //                     {},
          //                     this.state.current_transfer_client,
          //                     {
          //                       division_nickname: response.division_nickname,
          //                       entity_nickname: response.entity_nickname,
          //                       team_nickname: response.team_nickname
          //                     }
          //                   )
          //                 });
          //               })
          //               .catch(error => {
          //                 console.log(error);
          //               });
          //           });
          //       });
          //   });
        });

      API.get('clients', `/get-list-not-deleted`)
        .then(response => {
          // console.log(response);
          this.setState({
            client_list: response
          });
        })
        .catch(error => {
          console.log(error);
        });

      API.get('beneficiaries', `/get-all`).then(response => {
        // console.log(response);
        this.setState({
          beneficiaries_list: response
        });
        return response;
      });

      API.get('transfers', `/get/transfer-status`)
        .then(response => {
          // console.log(response);
          this.setState({
            transfer_status_list: response
          });
          return response;
        })
        .catch(error => {
          console.log(error);
          API.get('transfers', `/get/transfer-status`).then(response => {
            // console.log(response);
            this.setState({
              transfer_status_list: response
            });
          });
        });

      API.get('currencies', `/list_not_deleted`).then(response => {
        // console.log(response);
        this.setState({
          // currencies_list: response.fullList,
          currencies_list_priority: response
        });
        return response;
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.transfer_detail_id !== this.props.transfer_detail_id) {
      this.props.loadTransferDetailsRequest();

      API.get('transfers', `/get/id/${this.props.transfer_detail_id}`)
        .then(transfer_detail => {
          console.log(transfer_detail);
          this.setState({
            loaded: true,
            is_loading: false,
            transfer_status: transfer_detail.status,
            ...transfer_detail.fullList
          });
          this.props.loadTransferDetailsSuccess();
          return transfer_detail;
        })
        .then(response => {
          API.get('clients', `/get/id/${response.client_id}`)
            .then(response => {
              // console.log(response);
              this.setState({
                current_transfer_client: response
              });
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
          API.get('transfers', `/get/id/${this.props.transfer_detail_id}`)
            .then(transfer_detail => {
              console.log(transfer_detail);
              this.setState({
                loaded: true,
                is_loading: false,
                transfer_status: transfer_detail.status,
                ...transfer_detail.fullList
              });
              this.props.loadTransferDetailsSuccess();
              return transfer_detail;
            })
            .then(response => {
              API.get('clients', `/get/id/${response.client_id}`)
                .then(response => {
                  // console.log(response);
                  this.setState({
                    current_transfer_client: response
                  });
                })
                .catch(error => {
                  console.log(error);
                });
            });
        });

      API.get('clients', `/get-list-not-deleted`)
        .then(response => {
          // console.log(response);
          this.setState({
            client_list: response
          });
        })
        .catch(error => {
          console.log(error);
        });

      API.get('beneficiaries', `/get-all`).then(response => {
        // console.log(response);
        this.setState({
          beneficiaries_list: response
        });
        return response;
      });

      API.get('transfers', `/get/transfer-status`)
        .then(response => {
          // console.log(response);
          this.setState({
            transfer_status_list: response
          });
          return response;
        })
        .catch(error => {
          console.log(error);
          API.get('transfers', `/get/transfer-status`).then(response => {
            // console.log(response);
            this.setState({
              transfer_status_list: response
            });
          });
        });

      API.get('currencies', `/list_not_deleted`).then(response => {
        // console.log(response);
        this.setState({
          // currencies_list: response.fullList,
          currencies_list_priority: response
        });
        return response;
      });
    }
  }

  async saveTransferDetail(transfer_detail) {
    return API.put('transfers', `/update/id/${this.props.transfer_detail_id}`, {
      body: transfer_detail
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleBlur = event => {
    this.setState({
      [event.target.id]: this.props.intl.formatNumber(event.target.value)
    });
  };

  handleToggle = name => event => {
    this.setState({[name]: event.target.checked});
  };

  toggleEdit = () => {
    const edit_mode = !this.state.edit_mode;
    this.setState({edit_mode: edit_mode});
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading: true});
    // console.log(this.state)
    try {
      await this.saveTransferDetail({
        nickname: this.state.nickname,
        client_id: this.state.client_id,
        transaction_datetime: this.state.transaction_datetime,
        beneficiary_id: this.state.beneficiary_id,
        currency_from_id: this.state.currency_from_id,
        currency_to_id: this.state.currency_to_id,
        // amount_from: this.state.amount_from.replace(/,/g, ''),
        // amount_to: this.state.amount_to.replace(/,/g, ''),
        amount_from: this.state.amount_from,
        amount_to: this.state.amount_to,
        client_rate: this.state.client_rate,
        settlement_date: this.state.settlement_date,
        status: this.state.status,
        payout_bank_reference: this.state.payout_bank_reference,
        payout_datetime: this.state.payout_datetime,
        record_created_datetime: new Date().toISOString().slice(0, 19).replace('T', ' '),
        staff_id: this.props.app_state.current_staff.id
      });
    } catch (e) {
      alert(e);
      // this.setState({ isLoading: false });
    }
  };

  handleSelectChange = event => {
    this.setState({[event.target.name]: event.target.value});

    if (event.target.name === 'client_id') {
      this.getClientDetails(event.target.value);
    }
    if (event.target.name === 'beneficiary_id') {
      this.getBeneficiaryDetails(event.target.value);
    }
    if (event.target.name === 'currency_base') {
      this.setCurrencyBaseDetails(event.target.value);
    }
    if (event.target.name === 'currency_terms') {
      this.setCurrencyTermsDetails(event.target.value);
    }
  };

  getBeneficiaryDetails(beneficiary_id) {
    API.get('beneficiaries', `/get/id/${beneficiary_id}`)
      .then(response => {
        //console.log(response.id);
        this.setState({
          beneficiary_id: response.id,
          beneficiary_name: response.nickname
        });
        return response;
      })
      .then(response => {
        API.get('system', `/currencies/get/${response.account_currency}`)
          .then(response => {
            // console.log(response);
            this.setState({
              beneficiary: Object.assign({}, this.state.beneficiary, {
                account_currency_nickname: `${response.full_name} [${response.iso_alpha_3}]`
              })
            });
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  }

  getClientDetails(client_id) {
    API.get('clients', `/get/id/${client_id}`)
      .then(response => {
        // console.log(response);
        this.setState({
          client: response
        });
        return response.team_id;
      })
      .then(team_id => {
        // console.log(team_id);
        API.get('teams', `/get/div-entity/${team_id}`)
          .then(response => {
            // console.log(response);
            this.setState({
              client: Object.assign({}, this.state.client, {
                division_nickname: response.division_nickname,
                entity_nickname: response.entity_nickname,
                team_nickname: response.team_nickname
              })
            });
          })
          .catch(error => {
            console.log(error);
            API.get('teams', `/get/div-entity/${team_id}`)
              .then(response => {
                // console.log(response);
                this.setState({
                  client: Object.assign({}, this.state.client, {
                    division_nickname: response.division_nickname,
                    entity_nickname: response.entity_nickname,
                    team_nickname: response.team_nickname
                  })
                });
              })
              .catch(error => {
                console.log(error);
              });
          });
      })
      .catch(error => {
        console.log(error);
      });
  }

  setCurrencyBaseDetails(currency_id) {
    API.get('system', `/currencies/get-all`)
      .then(response => {
        this.setState({
          currencies_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });

    API.get('system', `/currencies/get/${currency_id}`)
      .then(response => {
        this.setState({
          currency_from_id: response.id
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  setCurrencyTermsDetails(currency_id) {
    API.get('system', `/currencies/get-all`)
      .then(response => {
        this.setState({
          currencies_list: response
        });
      })
      .catch(error => {
        console.log(error);
      });

    API.get('system', `/currencies/get/${currency_id}`)
      .then(response => {
        this.setState({
          currency_to_id: response.id
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  getFullDate = s => {
    //console.log(s);
    let showDate = '';
    if (s.getMonth() >= 9) {
      if (s.getDate() > 9) showDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-' + s.getDate();
      else showDate = s.getFullYear() + '-' + (s.getMonth() + 1) + '-0' + s.getDate();
    } else if (s.getMonth() < 9) {
      if (s.getDate() > 9) showDate = s.getFullYear() + '-0' + (s.getMonth() + 1) + '-' + s.getDate();
      else showDate = s.getFullYear() + '-0' + (s.getMonth() + 1) + '-0' + s.getDate();
    }
    return showDate;
  };

  getFullDateTime = s => {
    //console.log(s);
    let showDateTime = '';
    if (s.getMonth() >= 9) {
      if (s.getDate() > 9)
        showDateTime =
          s.getFullYear() +
          '-' +
          (s.getMonth() + 1) +
          '-' +
          s.getDate() +
          ' ' +
          s.getHours() +
          ':' +
          s.getMinutes() +
          ':' +
          s.getSeconds();
      else
        showDateTime =
          s.getFullYear() +
          '-' +
          (s.getMonth() + 1) +
          '-0' +
          s.getDate() +
          ' ' +
          s.getHours() +
          ':' +
          s.getMinutes() +
          ':' +
          s.getSeconds();
    } else if (s.getMonth() < 9) {
      if (s.getDate() > 9)
        showDateTime =
          s.getFullYear() +
          '-0' +
          (s.getMonth() + 1) +
          '-' +
          s.getDate() +
          ' ' +
          s.getHours() +
          ':' +
          s.getMinutes() +
          ':' +
          s.getSeconds();
      else
        showDateTime =
          s.getFullYear() +
          '-0' +
          (s.getMonth() + 1) +
          '-0' +
          s.getDate() +
          ' ' +
          s.getHours() +
          ':' +
          s.getMinutes() +
          ':' +
          s.getSeconds();
    }
    return showDateTime;
  };

  getPageHeader() {
    //console.log(this.state)
    return `Invoice to ${this.state.current_transfer_client.nickname}`;
    // `${this.state.nickname}`
  }

  getNavPill_Summary() {
    //set this.props into classes to use
    const {classes} = this.props;

    if (!this.state.current_transfer_client) return null;

    let currency_from_name = '';
    let currency_to_name = '';
    for (var i in this.state.currencies_list_priority) {
      if (this.state.currencies_list_priority[i].id === this.state.currency_from_id) {
        currency_from_name = this.state.currencies_list_priority[i].short_name;
      }
      if (this.state.currencies_list_priority[i].id === this.state.currency_to_id) {
        currency_to_name = this.state.currencies_list_priority[i].short_name;
      }
    }
    // let timeline = [];
    // let submittedFlag = false;
    // let acknowledgeFlag = false;
    // let receivedFlag = false;
    // let paymentFlag = false;
    // let complitedFlag = false;

    // console.log(this.state.transfer_status)
    // this.state.transfer_status.forEach(function (entry) {
    //   let elm = {};

    //   if (entry.status_id == 10) {
    //     elm = {
    //       inverted: true,
    //       badgeColor: "danger",
    //       badgeIcon: CardTravel,
    //       title: moment(entry.status_date).format("DD/MM/YYYY"),
    //       titleColor: "danger",
    //       body: <p>Not Proceeding</p>
    //     };
    //     timeline.push(elm);

    //     submittedFlag = true;
    //     acknowledgeFlag = true;
    //     receivedFlag = true;
    //     paymentFlag = true;
    //     complitedFlag = true;
    //   } else {
    //     if (entry.status_id == 2) {
    //       elm = {
    //         inverted: true,
    //         badgeColor: "primary",
    //         badgeIcon: CardTravel,
    //         title: moment(entry.status_date).format("DD/MM/YYYY"),
    //         titleColor: "primary",
    //         body: <p>Transfer Submitted</p>
    //       };
    //       submittedFlag = true;
    //       timeline.push(elm);
    //     }

    //     if (entry.status_id == 3) {
    //       elm = {
    //         inverted: true,
    //         badgeColor: "primary",
    //         badgeIcon: CardTravel,
    //         title: moment(entry.status_date).format("DD/MM/YYYY"),
    //         titleColor: "primary",
    //         body: <p>Transfer acknowledged</p>
    //       };
    //       timeline.push(elm);
    //       acknowledgeFlag = true;
    //     }

    //     if (entry.status_id == 5) {
    //       elm = {
    //         inverted: true,
    //         badgeColor: "primary",
    //         badgeIcon: CardTravel,
    //         title: moment(entry.status_date).format("DD/MM/YYYY"),
    //         titleColor: "primary",
    //         body: <p>Awaiting funds from client</p>
    //       };
    //       timeline.push(elm);
    //       acknowledgeFlag = true;
    //     }

    //     if (entry.status_id == 6) {
    //       elm = {
    //         inverted: true,
    //         badgeColor: "primary",
    //         badgeIcon: CardTravel,
    //         title: moment(entry.status_date).format("DD/MM/YYYY"),
    //         titleColor: "primary",
    //         body: <p>Funds received from client</p>
    //       };
    //       timeline.push(elm);
    //       receivedFlag = true;
    //     }

    //     if (entry.status_id == 7) {
    //       elm = {
    //         inverted: true,
    //         badgeColor: "primary",
    //         badgeIcon: CardTravel,
    //         title: moment(entry.status_date).format("DD/MM/YYYY"),
    //         titleColor: "primary",
    //         body: <p>Payment made to beneficiary</p>
    //       };
    //       timeline.push(elm);
    //       paymentFlag = true;
    //     }

    //     if (entry.status_id == 8) {
    //       elm = {
    //         inverted: true,
    //         badgeColor: "primary",
    //         badgeIcon: CardTravel,
    //         title: moment(entry.status_date).format("DD/MM/YYYY"),
    //         titleColor: "primary",
    //         body: <p>Completed</p>
    //       };
    //       timeline.push(elm);
    //       complitedFlag = true;
    //     }
    //   }
    // });

    // let elm = "";
    // if (acknowledgeFlag == false && !complitedFlag) {
    //   elm = {
    //     inverted: true,
    //     badgeColor: "info",
    //     badgeIcon: CardTravel,
    //     title: "Awaiting",
    //     titleColor: "info",
    //     body: <p>Transfer acknowledged</p>
    //   };
    //   timeline.push(elm);
    // }

    // if (receivedFlag == false && !complitedFlag) {
    //   elm = {
    //     inverted: true,
    //     badgeColor: "info",
    //     badgeIcon: CardTravel,
    //     title: "Awaiting",
    //     titleColor: "info",
    //     body: <p>Funds received from client</p>
    //   };
    //   timeline.push(elm);
    // }

    // if (paymentFlag == false && !complitedFlag) {
    //   elm = {
    //     inverted: true,
    //     badgeColor: "info",
    //     badgeIcon: CardTravel,
    //     title: "Awaiting",
    //     titleColor: "info",
    //     body: <p>Payment made to beneficiary</p>
    //   };
    //   timeline.push(elm);
    // }
    //console.log(this.state);
    return (
      <React.Fragment>
        <GridContainer>
          {/* <GridItem xs={12} sm={12} md={3}>
            <TimelineComponent simple stories={timeline} />
          </GridItem> */}
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader>
                <h6>Invoice Details</h6>
              </CardHeader>
              <CardBody>
                {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Invoice To"
                      id="current_transfer_client.nickname"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          this.state.current_transfer_client.nickname || ""
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div
                      style={{
                        color: "#AAAAAA",
                        "fontSize": "11px",
                        "marginTop": "10px"
                      }}
                    >
                      Client ID
                      </div>
                    <a
                      href={`/clients/edit/${
                        this.state.current_transfer_client.id
                        }`}
                      target="_blank"
                    >
                      {this.state.current_transfer_client.id}
                    </a>
                    <hr
                      style={{
                        "borderTop": "1px dotted lightgray",
                        "marginTop": "7px"
                      }}
                    />
                  </GridItem>
                </GridContainer> */}
                {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Email"
                      id="current_transfer_client.email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.current_transfer_client.email || ""
                      }}
                    />
                  </GridItem>
                </GridContainer> */}
                {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Invoice From"
                      id="current_transfer_client.team_nickname"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          this.state.current_transfer_client.team_nickname ||
                          ""
                      }}
                    />
                  </GridItem>
                </GridContainer> */}
                {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Division"
                      id="current_transfer_client.division_nickname"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          this.state.current_transfer_client
                            .division_nickname || ""
                      }}
                    />
                  </GridItem>
                </GridContainer> */}
                <Table
                  tableData={[
                    // [
                    //   "Transfer From",
                    //   <React.Fragment>
                    //     <span>
                    //       {
                    //         <div
                    //           className={`currency-flag currency-flag-${
                    //             currency_from_name
                    //               ? currency_from_name.toLowerCase()
                    //               : ""
                    //             }`}
                    //         />
                    //       }
                    //     </span>{" "}
                    //     {currency_from_name}{" "}
                    //     {this.props.intl.formatNumber(this.state.amount_from, {
                    //       minimumFractionDigits: 2,
                    //       maximumFractionDigits: 2
                    //     })}
                    //   </React.Fragment>
                    // ],
                    [
                      'Invoice To',
                      <React.Fragment>
                        <a href={`/clients/edit/${this.state.current_transfer_client.id}`} target='_blank'>
                          {this.state.current_transfer_client.nickname}
                        </a>
                      </React.Fragment>
                    ],
                    [
                      'Invoice From',
                      <React.Fragment>{this.state.current_transfer_client.team_nickname}</React.Fragment>
                    ],
                    [
                      'Invoice Amount',
                      <React.Fragment>
                        <span>
                          {
                            <div
                              className={`currency-flag currency-flag-${
                                currency_to_name ? currency_to_name.toLowerCase() : ''
                              }`}
                            />
                          }
                        </span>{' '}
                        {currency_to_name}{' '}
                        {this.props.intl.formatNumber(this.state.amount_to, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </React.Fragment>
                    ],
                    [
                      'Invoice Date',
                      <React.Fragment>{moment(this.state.invoice_date).format('DD/MM/YYYY')}</React.Fragment>
                    ],
                    [
                      'Due Date',
                      <React.Fragment>
                        {moment(this.state.due_date).format('DD/MM/YYYY')} (due in{' '}
                        {0 - moment().diff(moment(this.state.due_date), 'days')} days)
                      </React.Fragment>
                    ]

                    // [
                    //   "Rate",
                    //   `${this.props.intl.formatNumber(this.state.client_rate, {
                    //     maximumFractionDigits: 5
                    //   })}`
                    // ]
                  ]}
                />
              </CardBody>
            </Card>

            {/* <Card>
              <CardHeader>
                <h6>Transfer Details</h6>
              </CardHeader>
              <CardBody>
                <Table
                  tableData={[
                    ["Transfer ID", `${this.state.id}`],
                    [
                      "Client",
                      <React.Fragment>
                        <a
                          href={`/clients/edit/${
                            this.state.current_transfer_client.id
                            }`}
                          target="_blank"
                        >
                          {this.state.current_transfer_client.nickname}
                        </a>
                      </React.Fragment>
                      //`${this.state.current_transfer_client.nickname}`
                    ],
                    ["Transfer Nickname", `${this.state.nickname}`],
                    [
                      "Transaction Date",
                      `${moment(this.state.transaction_datetime).format(
                        "DD/MM/YYYY"
                      )}`
                    ],
                    [
                      "Settlement Date",
                      `${moment(this.state.settlement_date).format(
                        "DD/MM/YYYY"
                      )}`
                    ]
                  ]}
                />
              </CardBody>
            </Card> */}
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <CardHeader>
                <h6>Functions</h6>
              </CardHeader>
              <CardBody>
                <Button
                  fullwidth='true'
                  color='info'
                  //disabled={!this.props.enabled}
                  size='sm'
                  onClick={() => {
                    var win = window.open(`/invoices/view/${this.props.transfer_pending_detail_id}`, '_blank');
                    win.focus();
                  }}
                  // className={classes.updateProfileButton}
                >
                  <span style={{height: 20}}>View Invoice</span>
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {/* <Button
            color="primary"
            type="submit"
            className={classes.updateProfileButton}
            disabled={!this.state.edit_mode}
          >
            Update Transfer
          </Button> */}
      </React.Fragment>
    );
  }

  // getNavPill_Transfer() {
  //   const { classes } = this.props;
  //   return (
  //     <React.Fragment>
  //       <form onSubmit={this.handleSubmit}>
  //         <GridContainer>
  //           <GridItem xs={12} sm={12} md={6}>
  //             <Card style={{ height: "95%" }}>
  //               <CardHeader>
  //                 <h4>Financials</h4>
  //               </CardHeader>
  //               <CardBody>
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <FormControl
  //                       fullWidth
  //                       className={classes.selectFormControl}
  //                     >
  //                       <InputLabel
  //                         htmlFor="simple-select"
  //                         className={classes.selectLabel}
  //                       >
  //                         From
  //                       </InputLabel>
  //                       <Select
  //                         MenuProps={{
  //                           className: classes.selectMenu
  //                         }}
  //                         classes={{
  //                           select: classes.select
  //                         }}
  //                         value={this.state.currency_from_id || ""}
  //                         onChange={this.handleSelectChange}
  //                         inputProps={{
  //                           disabled: !this.state.edit_mode,
  //                           name: "currency_base",
  //                           id: "currency_base"
  //                         }}
  //                       >
  //                         <MenuItem
  //                           key="0x0"
  //                           disabled
  //                           classes={{
  //                             root: classes.selectMenuItem
  //                           }}
  //                         >
  //                           Select currencies_from to replace{" "}
  //                           {this.state.currency_base}
  //                         </MenuItem>
  //                         {this.state.currencies_list_priority
  //                           //sorted by id
  //                           .sort((a, b) =>
  //                             a.id.toString().localeCompare(b.id.toString())
  //                           )
  //                           .map(item => {
  //                             return (
  //                               <MenuItem
  //                                 key={item.id}
  //                                 classes={{
  //                                   root: classes.selectMenuItem,
  //                                   selected: classes.selectMenuItemSelected
  //                                 }}
  //                                 value={item.id}
  //                               >
  //                                 [{item.iso_alpha_3}] {item.full_name}
  //                               </MenuItem>
  //                             );
  //                           })}
  //                         {/*<MenuItem*/}
  //                         {/*  key="0x0"*/}
  //                         {/*  value="0"*/}
  //                         {/*  disabled*/}
  //                         {/*  classes={{*/}
  //                         {/*    root: classes.selectMenuItem*/}
  //                         {/*  }}*/}
  //                         {/*>*/}
  //                         {/*  .........................................*/}
  //                         {/*</MenuItem>*/}
  //                         {/*{this.state.currencies_list*/}
  //                         {/*//sorted by id*/}
  //                         {/*  .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))*/}
  //                         {/*  .map((item) => {*/}
  //                         {/*    return (*/}
  //                         {/*      <MenuItem*/}
  //                         {/*        key={item.id}*/}
  //                         {/*        classes={{*/}
  //                         {/*          root: classes.selectMenuItem,*/}
  //                         {/*          selected: classes.selectMenuItemSelected*/}
  //                         {/*        }}*/}
  //                         {/*        value={item.id}*/}
  //                         {/*      >*/}
  //                         {/*        [{item.iso_alpha_3}] {item.full_name}*/}
  //                         {/*      </MenuItem>*/}
  //                         {/*    );*/}
  //                         {/*  })}*/}
  //                       </Select>
  //                     </FormControl>
  //                   </GridItem>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <FormControl
  //                       fullWidth
  //                       className={classes.selectFormControl}
  //                     >
  //                       <InputLabel
  //                         htmlFor="simple-select"
  //                         className={classes.selectLabel}
  //                       >
  //                         To
  //                       </InputLabel>
  //                       <Select
  //                         MenuProps={{
  //                           className: classes.selectMenu
  //                         }}
  //                         classes={{
  //                           select: classes.select
  //                         }}
  //                         value={this.state.currency_to_id || ""}
  //                         onChange={this.handleSelectChange}
  //                         inputProps={{
  //                           disabled: !this.state.edit_mode,
  //                           name: "currency_terms",
  //                           id: "currency_terms"
  //                         }}
  //                       >
  //                         <MenuItem
  //                           key="0x0"
  //                           disabled
  //                           classes={{
  //                             root: classes.selectMenuItem
  //                           }}
  //                         >
  //                           Select currencies_to to replace{" "}
  //                           {this.state.currency_terms}
  //                         </MenuItem>

  //                         {this.state.currencies_list_priority
  //                           //sorted by id
  //                           .sort((a, b) =>
  //                             a.id.toString().localeCompare(b.id.toString())
  //                           )
  //                           .map(item => {
  //                             return (
  //                               <MenuItem
  //                                 key={item.id}
  //                                 classes={{
  //                                   root: classes.selectMenuItem,
  //                                   selected: classes.selectMenuItemSelected
  //                                 }}
  //                                 value={item.id}
  //                               >
  //                                 [{item.iso_alpha_3}] {item.full_name}
  //                               </MenuItem>
  //                             );
  //                           })}
  //                         {/*<MenuItem*/}
  //                         {/*  key="0x0"*/}
  //                         {/*  value="0"*/}
  //                         {/*  disabled*/}
  //                         {/*  classes={{*/}
  //                         {/*    root: classes.selectMenuItem*/}
  //                         {/*  }}*/}
  //                         {/*>*/}
  //                         {/*  .........................................*/}
  //                         {/*</MenuItem>*/}
  //                         {/*{this.state.currencies_list*/}
  //                         {/*//sorted by id*/}
  //                         {/*  .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))*/}
  //                         {/*  .map((item) => {*/}
  //                         {/*    return (*/}
  //                         {/*      <MenuItem*/}
  //                         {/*        key={item.id}*/}
  //                         {/*        classes={{*/}
  //                         {/*          root: classes.selectMenuItem,*/}
  //                         {/*          selected: classes.selectMenuItemSelected*/}
  //                         {/*        }}*/}
  //                         {/*        value={item.id}*/}
  //                         {/*      >*/}
  //                         {/*        [{item.iso_alpha_3}] {item.full_name}*/}
  //                         {/*      </MenuItem>*/}
  //                         {/*    );*/}
  //                         {/*  })}*/}
  //                       </Select>
  //                     </FormControl>
  //                   </GridItem>
  //                 </GridContainer>

  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <CustomInput
  //                       labelText="Amount from"
  //                       id="amount_from"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: !this.state.edit_mode,
  //                         //value: this.state.amount_from || "",
  //                         value:
  //                           this.props.intl.formatNumber(
  //                             this.state.amount_from,
  //                             {
  //                               maximumFractionDigits: 2,
  //                               minimumFractionDigits: 2
  //                             }
  //                           ) || "",
  //                         onChange: event => {
  //                           this.handleChange(event);
  //                         },
  //                         onBlur: event => this.handleBlur(event)
  //                       }}
  //                     />
  //                   </GridItem>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <CustomInput
  //                       labelText="Amount to"
  //                       id="amount_to"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: !this.state.edit_mode,
  //                         //value: this.state.amount_to || "",
  //                         value:
  //                           this.props.intl.formatNumber(this.state.amount_to, {
  //                             maximumFractionDigits: 2,
  //                             minimumFractionDigits: 2
  //                           }) || "",
  //                         onChange: event => {
  //                           this.handleChange(event);
  //                         },
  //                         onBlur: event => this.handleBlur(event)
  //                       }}
  //                     />
  //                   </GridItem>
  //                 </GridContainer>

  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <CustomInput
  //                       labelText="Rate"
  //                       id="client_rate"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: !this.state.edit_mode,
  //                         // value:
  //                         //   this.state.client_rate ||
  //                         //   "",
  //                         value:
  //                           this.props.intl.formatNumber(
  //                             this.state.client_rate,
  //                             {
  //                               maximumFractionDigits: 5,
  //                               minimumFractionDigits: 5
  //                             }
  //                           ) || "",
  //                         onChange: event => {
  //                           this.handleChange(event);
  //                         }
  //                       }}
  //                     />
  //                   </GridItem>
  //                 </GridContainer>
  //               </CardBody>
  //             </Card>
  //           </GridItem>
  //           <GridItem xs={12} sm={12} md={6}>
  //             <Card style={{ height: "95%" }}>
  //               <CardHeader>
  //                 <h4>Transfer Details</h4>
  //               </CardHeader>
  //               <CardBody>
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <CustomInput
  //                       labelText="Transfer ID"
  //                       id="id"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: true,
  //                         value: this.state.id || "",
  //                         onChange: event => {
  //                           this.handleChange(event);
  //                         }
  //                       }}
  //                     />
  //                   </GridItem>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <CustomInput
  //                       labelText="Transfer Nickname"
  //                       id="nickname"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: !this.state.edit_mode,
  //                         value: this.state.nickname || "",
  //                         onChange: event => {
  //                           this.handleChange(event);
  //                         }
  //                       }}
  //                     />
  //                   </GridItem>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <FormControl
  //                       fullWidth
  //                       className={classes.selectFormControl}
  //                     >
  //                       <InputLabel
  //                         htmlFor="simple-select"
  //                         className={classes.selectLabel}
  //                       >
  //                         Transfer Status
  //                       </InputLabel>
  //                       <Select
  //                         MenuProps={{
  //                           className: classes.selectMenu
  //                         }}
  //                         classes={{
  //                           select: classes.select
  //                         }}
  //                         value={this.state.status || ""}
  //                         onChange={this.handleSelectChange}
  //                         inputProps={{
  //                           disabled: !this.state.edit_mode,
  //                           name: "status",
  //                           id: "status"
  //                         }}
  //                       >
  //                         <MenuItem
  //                           key="0x0"
  //                           disabled
  //                           classes={{
  //                             root: classes.selectMenuItem
  //                           }}
  //                         >
  //                           Select client{" "}
  //                           {" from " + this.state.clients_fullname}
  //                         </MenuItem>
  //                         {this.state.transfer_status_list
  //                           // .sort((a, b) => a.nickname.localeCompare(b.nickname))
  //                           .map(item => {
  //                             return (
  //                               <MenuItem
  //                                 key={item.id}
  //                                 classes={{
  //                                   root: classes.selectMenuItem,
  //                                   selected: classes.selectMenuItemSelected
  //                                 }}
  //                                 value={item.id}
  //                               >
  //                                 {item.nickname}
  //                               </MenuItem>
  //                             );
  //                           })}
  //                       </Select>
  //                     </FormControl>
  //                   </GridItem>
  //                 </GridContainer>
  //                 {/* <hr />
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <FormControl
  //                       fullWidth
  //                       className={classes.selectFormControl}
  //                     >
  //                       <InputLabel
  //                         htmlFor="simple-select"
  //                         className={classes.selectLabel}
  //                       >
  //                         Client
  //                       </InputLabel>
  //                       <Select
  //                         MenuProps={{
  //                           className: classes.selectMenu
  //                         }}
  //                         classes={{
  //                           select: classes.select
  //                         }}
  //                         value={this.state.client_id || ""}
  //                         onChange={this.handleSelectChange}
  //                         inputProps={{
  //                           disabled: !this.state.edit_mode,
  //                           name: "client_id",
  //                           id: "client_id"
  //                         }}
  //                       >
  //                         <MenuItem
  //                           key="0x0"
  //                           disabled
  //                           classes={{
  //                             root: classes.selectMenuItem
  //                           }}
  //                         >
  //                           Select client {" from " + this.state.clients_fullname}
  //                         </MenuItem>
  //                         {this.state.client_list
  //                           .sort((a, b) => a.last_name.localeCompare(b.last_name))
  //                           .map((item) => {
  //                             return (
  //                               <MenuItem
  //                                 key={item.id}
  //                                 classes={{
  //                                   root: classes.selectMenuItem,
  //                                   selected: classes.selectMenuItemSelected
  //                                 }}
  //                                 value={item.id}
  //                               >
  //                                 {item.nickname}
  //                               </MenuItem>
  //                             );
  //                           })}
  //                       </Select>
  //                     </FormControl>
  //                   </GridItem>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <FormControl
  //                       fullWidth
  //                       className={classes.selectFormControl}
  //                     >
  //                       <InputLabel
  //                         htmlFor="simple-select"
  //                         className={classes.selectLabel}
  //                       >
  //                         Beneficiary
  //                       </InputLabel>
  //                       <Select
  //                         MenuProps={{
  //                           className: classes.selectMenu
  //                         }}
  //                         classes={{
  //                           select: classes.select
  //                         }}
  //                         value={this.state.beneficiary_id || ""}
  //                         onChange={this.handleSelectChange}
  //                         inputProps={{
  //                           disabled: !this.state.edit_mode,
  //                           name: "beneficiary_id",
  //                           id: "beneficiary_id"
  //                         }}
  //                       >
  //                         <MenuItem
  //                           key="0x0"
  //                           disabled
  //                           classes={{
  //                             root: classes.selectMenuItem
  //                           }}
  //                         >
  //                           Select beneficiary to replace {this.state.beneficiary_name}
  //                         </MenuItem>
  //                         {this.state.beneficiaries_list
  //                           .sort((a, b) => a.nickname.localeCompare(b.nickname))
  //                           .map((item) => {
  //                             return (
  //                               <MenuItem
  //                                 key={item.id}
  //                                 classes={{
  //                                   root: classes.selectMenuItem,
  //                                   selected: classes.selectMenuItemSelected
  //                                 }}
  //                                 value={item.id}
  //                               >
  //                                 {item.nickname}
  //                               </MenuItem>
  //                             );
  //                           })}
  //                       </Select>
  //                     </FormControl>
  //                   </GridItem>
  //                 </GridContainer> */}
  //                 <hr />
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     Transaction
  //                     <br />
  //                     <FormControl fullWidth>
  //                       <Datetime
  //                         inputProps={{
  //                           disabled: !this.state.edit_mode,
  //                           // placeholder: this.getFullDate(new Date(this.state.transaction_datetime))
  //                           placeholder: this.state.transaction_datetime
  //                             ? moment(this.state.transaction_datetime).format(
  //                               "DD/MM/YYYY"
  //                             )
  //                             : "",
  //                           value: this.state.transaction_datetime
  //                             ? moment(this.state.transaction_datetime).format(
  //                               "DD/MM/YYYY"
  //                             )
  //                             : ""
  //                           //value: this.getFullDate(new Date(this.state.transaction_datetime)),
  //                         }}
  //                         onChange={event => {
  //                           if (event.isValid !== undefined) {
  //                             let selDate = this.getFullDateTime(
  //                               new Date(event.toDate())
  //                             );
  //                             this.setState({
  //                               transaction_datetime: selDate
  //                             });
  //                           }
  //                         }}
  //                       />
  //                     </FormControl>
  //                   </GridItem>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     Settlement
  //                     <br />
  //                     <FormControl fullWidth>
  //                       <Datetime
  //                         inputProps={{
  //                           disabled: !this.state.edit_mode,
  //                           // placeholder: this.getFullDate(new Date(this.state.settlement_date))
  //                           placeholder: this.state.settlement_date
  //                             ? moment(this.state.settlement_date).format(
  //                               "DD/MM/YYYY"
  //                             )
  //                             : "",
  //                           value: this.state.settlement_date
  //                             ? moment(this.state.settlement_date).format(
  //                               "DD/MM/YYYY"
  //                             )
  //                             : ""

  //                           //value: this.getFullDate(new Date(this.state.transaction_datetime)),
  //                         }}
  //                         onChange={event => {
  //                           if (event.isValid !== undefined) {
  //                             let selDate = this.getFullDateTime(
  //                               new Date(event.toDate())
  //                             );
  //                             this.setState({
  //                               settlement_date: selDate
  //                             });
  //                           }
  //                         }}
  //                       />
  //                     </FormControl>
  //                   </GridItem>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <CustomInput
  //                       labelText="Payout Bank Reference"
  //                       id="payout_bank_reference"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: !this.state.edit_mode,
  //                         value: this.state.payout_bank_reference || "",
  //                         onChange: event => {
  //                           this.handleChange(event);
  //                         }
  //                       }}
  //                     />
  //                   </GridItem>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     Payout Date & Time
  //                     <br />
  //                     <FormControl fullWidth>
  //                       <Datetime
  //                         inputProps={{
  //                           disabled: !this.state.edit_mode,
  //                           // placeholder: this.getFullDate(new Date(this.state.payout_datetime))
  //                           placeholder: this.state.payout_datetime
  //                             ? moment(this.state.payout_datetime).format(
  //                               "DD/MM/YYYY hh:mm:ss A"
  //                             )
  //                             : "",
  //                           value: this.state.payout_datetime
  //                             ? moment(this.state.payout_datetime).format(
  //                               "DD/MM/YYYY hh:mm:ss A"
  //                             )
  //                             : ""
  //                           //value: this.getFullDate(new Date(this.state.payout_datetime)),
  //                         }}
  //                         onChange={event => {
  //                           if (event.isValid !== undefined) {
  //                             let selDate = this.getFullDateTime(
  //                               new Date(event.toDate())
  //                             );
  //                             this.setState({
  //                               payout_datetime: selDate
  //                             });
  //                           }
  //                         }}
  //                       />
  //                     </FormControl>
  //                   </GridItem>
  //                 </GridContainer>
  //               </CardBody>
  //             </Card>
  //           </GridItem>
  //           {/* <GridItem xs={12} sm={12} md={6}>

  //           </GridItem> */}
  //         </GridContainer>
  //         <Button
  //           color="primary"
  //           type="submit"
  //           className={classes.updateProfileButton}
  //           disabled={!this.state.edit_mode}
  //         >
  //           Update Transfer
  //         </Button>
  //       </form>
  //     </React.Fragment>
  //   );
  // }

  // getNavPill_Client() {
  //   const { classes } = this.props;
  //   return (
  //     <React.Fragment>
  //       <form onSubmit={this.handleSubmit}>
  //         <GridContainer>
  //           <GridItem xs={12} sm={12} md={6}>
  //             <Card style={{ height: "90%" }}>
  //               <CardHeader>
  //                 <h6>Client Details</h6>
  //               </CardHeader>
  //               <CardBody>
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={12}>
  //                     <FormControl
  //                       fullWidth
  //                       className={classes.selectFormControl}
  //                     >
  //                       <InputLabel
  //                         htmlFor="simple-select"
  //                         className={classes.selectLabel}
  //                       >
  //                         Client
  //                       </InputLabel>
  //                       <Select
  //                         MenuProps={{
  //                           className: classes.selectMenu
  //                         }}
  //                         classes={{
  //                           select: classes.select
  //                         }}
  //                         value={this.state.client_id || ""}
  //                         onChange={this.handleSelectChange}
  //                         inputProps={{
  //                           disabled: !this.state.edit_mode,
  //                           name: "client_id",
  //                           id: "client_id"
  //                         }}
  //                       >
  //                         <MenuItem
  //                           key="0x0"
  //                           disabled
  //                           classes={{
  //                             root: classes.selectMenuItem
  //                           }}
  //                         >
  //                           Select client{" "}
  //                           {" from " + this.state.clients_fullname}
  //                         </MenuItem>
  //                         {this.state.client_list
  //                           .sort((a, b) =>
  //                             a.last_name.localeCompare(b.last_name)
  //                           )
  //                           .map(item => {
  //                             return (
  //                               <MenuItem
  //                                 key={item.id}
  //                                 classes={{
  //                                   root: classes.selectMenuItem,
  //                                   selected: classes.selectMenuItemSelected
  //                                 }}
  //                                 value={item.id}
  //                               >
  //                                 {item.nickname + " (" + item.email + ")"}
  //                               </MenuItem>
  //                             );
  //                           })}
  //                       </Select>
  //                     </FormControl>
  //                   </GridItem>
  //                 </GridContainer>
  //                 <hr />
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <CustomInput
  //                       labelText="Nickname"
  //                       id="current_transfer_client.nickname"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: true,
  //                         value:
  //                           this.state.current_transfer_client.nickname || ""
  //                       }}
  //                     />
  //                   </GridItem>
  //                 </GridContainer>
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <CustomInput
  //                       labelText="First Name"
  //                       id="current_transfer_client.first_name"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: true,
  //                         value:
  //                           this.state.current_transfer_client.first_name || ""
  //                       }}
  //                     />
  //                   </GridItem>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <CustomInput
  //                       labelText="Middle Name"
  //                       id="current_transfer_client.middle_name"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: true,
  //                         value:
  //                           this.state.current_transfer_client.middle_name || ""
  //                       }}
  //                     />
  //                   </GridItem>
  //                 </GridContainer>
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <CustomInput
  //                       labelText="Last Name"
  //                       id="current_transfer_client.last_name"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: true,
  //                         value:
  //                           this.state.current_transfer_client.last_name || ""
  //                       }}
  //                     />
  //                   </GridItem>
  //                 </GridContainer>
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={12}>
  //                     <CustomInput
  //                       labelText="Email"
  //                       id="current_transfer_client.email"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: true,
  //                         value: this.state.current_transfer_client.email || ""
  //                       }}
  //                     />
  //                   </GridItem>
  //                 </GridContainer>
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={12}>
  //                     <CustomInput
  //                       labelText="Team"
  //                       id="current_transfer_client.team_nickname"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: true,
  //                         value:
  //                           this.state.current_transfer_client.team_nickname ||
  //                           ""
  //                       }}
  //                     />
  //                   </GridItem>
  //                 </GridContainer>
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={12}>
  //                     <CustomInput
  //                       labelText="Division"
  //                       id="current_transfer_client.division_nickname"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: true,
  //                         value:
  //                           this.state.current_transfer_client
  //                             .division_nickname || ""
  //                       }}
  //                     />
  //                   </GridItem>
  //                 </GridContainer>
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <div
  //                       style={{
  //                         color: "#AAAAAA",
  //                         "fontSize": "11px",
  //                         "marginTop": "10px"
  //                       }}
  //                     >
  //                       Client ID
  //                     </div>
  //                     <a
  //                       href={`/clients/edit/${
  //                         this.state.current_transfer_client.id
  //                         }`}
  //                       target="_blank"
  //                     >
  //                       {this.state.current_transfer_client.id}
  //                     </a>
  //                     <hr
  //                       style={{
  //                         "borderTop": "1px dotted lightgray",
  //                         "marginTop": "7px"
  //                       }}
  //                     />
  //                   </GridItem>
  //                 </GridContainer>
  //               </CardBody>
  //             </Card>
  //           </GridItem>
  //         </GridContainer>
  //         <Button
  //           color="primary"
  //           type="submit"
  //           className={classes.updateProfileButton}
  //           disabled={!this.state.edit_mode}
  //         >
  //           Update Transfers
  //         </Button>
  //       </form>
  //     </React.Fragment>
  //   );
  // }

  // getNavPill_Remittance() {
  //   const { classes } = this.props;
  //   return (
  //
  //     <React.Fragment>
  //       <form onSubmit={this.handleSubmit}>
  //         <GridContainer>
  //           <GridItem xs={12} sm={12} md={6}>
  //             <Card style={{ height: "90%" }}>
  //               <CardHeader>
  //                 <h6>Client Details</h6>
  //               </CardHeader>
  //               <CardBody>
  //
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <FormControl
  //                       fullWidth
  //                       className={classes.selectFormControl}
  //                     >
  //                       <InputLabel
  //                         htmlFor="simple-select"
  //                         className={classes.selectLabel}
  //                       >
  //                         Client
  //                       </InputLabel>
  //                       <Select
  //                         MenuProps={{
  //                           className: classes.selectMenu
  //                         }}
  //                         classes={{
  //                           select: classes.select
  //                         }}
  //                         value={this.state.client_id || ""}
  //                         onChange={this.handleSelectChange}
  //                         inputProps={{
  //                           disabled: !this.state.edit_mode,
  //                           name: "client_id",
  //                           id: "client_id"
  //                         }}
  //                       >
  //                         <MenuItem
  //                           key="0x0"
  //                           disabled
  //                           classes={{
  //                             root: classes.selectMenuItem
  //                           }}
  //                         >
  //                           Select client {" from " + this.state.clients_fullname}
  //                         </MenuItem>
  //                         {this.state.client_list
  //                           .sort((a, b) => a.last_name.localeCompare(b.last_name))
  //                           .map((item) => {
  //                             return (
  //                               <MenuItem
  //                                 key={item.id}
  //                                 classes={{
  //                                   root: classes.selectMenuItem,
  //                                   selected: classes.selectMenuItemSelected
  //                                 }}
  //                                 value={item.id}
  //                               >
  //                                 {item.nickname}
  //                               </MenuItem>
  //                             );
  //                           })}
  //                       </Select>
  //                     </FormControl>
  //                   </GridItem>
  //                 </GridContainer>
  //                 <hr />
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <CustomInput
  //                       labelText="Nickname"
  //                       id="current_transfer_client.nickname"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: true,
  //                         value:
  //                           this.state.current_transfer_client.nickname || ""
  //                       }}
  //                     />
  //                   </GridItem>
  //                 </GridContainer>
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <CustomInput
  //                       labelText="First Name"
  //                       id="current_transfer_client.first_name"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: true,
  //                         value:
  //                           this.state.current_transfer_client.first_name || ""
  //                       }}
  //                     />
  //                   </GridItem>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <CustomInput
  //                       labelText="Middle Name"
  //                       id="current_transfer_client.middle_name"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: true,
  //                         value:
  //                           this.state.current_transfer_client.middle_name || ""
  //                       }}
  //                     />
  //                   </GridItem>
  //                 </GridContainer>
  //                 <GridContainer>
  //                   <GridItem xs={12} sm={12} md={6}>
  //                     <CustomInput
  //                       labelText="Last Name"
  //                       id="current_transfer_client.last_name"
  //                       formControlProps={{
  //                         fullWidth: true
  //                       }}
  //                       inputProps={{
  //                         disabled: true,
  //                         value:
  //                           this.state.current_transfer_client.last_name || ""
  //                       }}
  //                     />
  //                   </GridItem>
  //                 </GridContainer>
  //
  //               </CardBody>
  //             </Card>
  //           </GridItem>
  //         </GridContainer>
  //         <Button
  //           color="primary"
  //           type="submit"
  //           className={classes.updateProfileButton}
  //           disabled={!this.state.edit_mode}
  //         >
  //           Update Transfers
  //         </Button>
  //       </form>
  //     </React.Fragment>
  //   );
  // }

  render() {
    const {classes} = this.props;

    if (!this.state.currency_to_id) {
      return null;
    }
    if (!this.state.loaded) {
      return null;
    }
    if (!this.props.app_state.current_staff) {
      return null;
    }

    // console.log(this.props.app_state.current_staff_super_admin);
    //current_staff_super_admin

    // console.log(this.state);
    // console.log(this.props);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='rose' icon>
                <div className={classes.flexEnd}>
                  <h4 className={classes.cardIconTitle}>{this.getPageHeader()}</h4>
                  {/* <div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.edit_mode}
                          disabled={
                            !this.props.app_state.current_staff_super_admin
                          }
                          onChange={() => this.toggleEdit()}
                          value="edit_mode"
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            icon: classes.switchIcon,
                            iconChecked: classes.switchIconChecked,
                            bar: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={this.state.edit_mode ? "Edit Mode" : "Read Only"}
                    />
                  </div> */}
                </div>
              </CardHeader>
              <CardBody>
                <CardBody>{this.getNavPill_Summary()}</CardBody>
                {/* <NavPills
                  color="info"
                  tabs={[
                    {
                      tabButton: "Summary",
                      tabContent: (
                        <CardBody>{this.getNavPill_Summary()}</CardBody>
                      )
                    },
                    {
                      tabButton: "Transfer",
                      tabContent: (
                        <CardBody>{this.getNavPill_Transfer()}</CardBody>
                      )
                    },
                    {
                      tabButton: "Client",
                      tabContent: (
                        <CardBody>{this.getNavPill_Client()}</CardBody>
                      )
                    },
                    {
                      tabButton: "Remittance",
                      tabContent: (
                        <React.Fragment></React.Fragment>
                        // <TransferDetailRemittance
                        //   edit_mode={this.state.edit_mode}
                        //   transfer_id={this.props.transfer_detail_id}
                        //   remitting_bank_id={this.state.remitting_bank_account_id}
                        //   currency_from_id={this.state.currency_from_id}
                        //   amount_from={this.state.amount_from}
                        //   rate={this.state.client_rate}
                        //   currency_to_id={this.state.currency_to_id}
                        //   amount_to={this.state.amount_to}
                        //   client_id={this.state.client_id}
                        //   current_staff_super_admin={
                        //     this.props.app_state.current_staff_super_admin
                        //   }
                        //   received_datetime={this.state.received_datetime}
                        //   received_notes={this.state.received_notes}
                        //   language_id={
                        //     this.state.current_transfer_client.language_id
                        //   }
                        // />
                      )
                    },
                    {
                      tabButton: "Payout",
                      tabContent: (
                        <React.Fragment></React.Fragment>
                        // <TransferDetailPayout
                        //   edit_mode={this.state.edit_mode}
                        //   transfer_id={this.props.transfer_detail_id}
                        //   transfer_nickname={this.state.nickname}
                        //   beneficiary_id={this.state.beneficiary_id}
                        //   currency_from_id={this.state.currency_from_id}
                        //   currency_to_id={this.state.currency_to_id}
                        //   amount_from={this.state.amount_from}
                        //   amount_to={this.state.amount_to}
                        //   rate={this.state.client_rate}
                        //   settlement_date={this.state.settlement_date}
                        //   client_id={this.state.client_id}
                        //   payout_datetime={this.state.payout_datetime}
                        //   payout_bank_reference={
                        //     this.state.payout_bank_reference
                        //   }
                        //   current_staff_super_admin={
                        //     this.props.app_state.current_staff_super_admin
                        //   }
                        //   language_id={
                        //     this.state.current_transfer_client.language_id
                        //   }
                        //   transfer_status={this.state.status}
                        // />
                      )
                    }
                  ]}
                /> */}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// TransferEdit.propTypes = {
//   classes: PropTypes.object.isRequired
// };

// export default injectIntl(withStyles(staffEditStyles)(InvoicesEdit));

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // loadClientDetailsRequest: () => {
    //   dispatch(loadClientDetailsRequest())
    // },
    // loadClientDetailsSuccess: () => {
    //   dispatch(loadClientDetailsSuccess())
    // },
    loadTransferDetailsRequest: () => {
      dispatch(loadTransferDetailsRequest());
    },
    loadTransferDetailsSuccess: () => {
      dispatch(loadTransferDetailsSuccess());
    }
  };
};

const InvoicesEditontainer = connect(mapStateToProps, mapDispatchToProps)(InvoicesEdit);

export default injectIntl(withStyles(staffEditStyles)(InvoicesEditontainer));
