import React from 'react';
// react component for creating dynamic tables

import { API } from 'aws-amplify';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';

// @material-ui/icons
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Close from '@material-ui/icons/Close';
import Dvr from '@material-ui/icons/Dvr';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { CSVLink } from 'react-csv';
import GridContainer from '../Grid/GridContainer';

import { Button, DatePicker } from 'antd'

import { Select, Table } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import ProtoTypes from 'prop-types';
import ListTable from '../ListTable/ListTable';
import { TeamAPI } from '../Teams/TeamUtil';
import dayjs from 'dayjs'

var moment = require('moment');
const {RangePicker} = DatePicker;

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    }
};

class ReportsTransfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entity_list: [],
            team_list: [],
            team_id: null,
            dialog_delete_confirm: false,
            transfer_list: [],
            table_data: [],
            finished: false,
            columns: [],
            loading: false,
            data: [],
            dataToDownload: [],
            sorts: null,
            startDate: moment().subtract(1, 'month').startOf('month'),
            endDate: moment().subtract(1, 'month').endOf('month')
        };
    }

    componentDidMount() {
        const columns = this.buildTableColumns();
        this.setState({ columns: columns });
        this.getTeamList()
    }

    buildTableColumns = () => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                name: 'id',
                defaultSortOrder: 'descend',
                sorter: { compare: (a,b) => a.id - b.id },
                ...this.props.getColumnSearchProps({
                    dataIndex: 'id',
                    label: 'id',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{record.id}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Client',
                dataIndex: 'client',
                key: 'client',
                name: 'client',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'client',
                    label: 'Client',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{record.client}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Staff',
                dataIndex: 'creating_staff_nickname',
                key: 'creating_staff_nickname',
                name: 'creating_staff_nickname',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'creating_staff_nickname',
                    label: 'Staff Name',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{record.creating_staff_nickname}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Source',
                dataIndex: 'src',
                key: 'src',
                name: 'src',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'src',
                    label: 'Source',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{record.src}</span>
                            </>
                        );
                    }
                })
            },
            // {
            //     title: 'Source',
            //     dataIndex: 'source',
            //     key: 'source',
            //     name: 'source',
            //     ...this.props.getColumnSearchProps({
            //         dataIndex: 'source',
            //         label: 'Source',
            //         render: (text, record) => {
            //             console.log('source', record.source)
            //             return (
            //                 <>
            //                     <span>{record.source}</span>
            //                 </>
            //             );
            //         }
            //     })
            // },
            {
                title: 'Transaction',
                dataIndex: 'transaction_date',
                key: 'transaction_date',
                name: 'transaction_date',
                sorter: { compare: (a,b) => {
                        const momentA = moment(a.transaction_date, 'DD/MM/YYYY');
                        const momentB = moment(b.transaction_date, 'DD/MM/YYYY');
                        const momentA_unix = momentA.unix();
                        const momentB_unix = momentB.unix();
                        return momentA - momentB;
                    } },
            },
            {
                title: 'Settlement',
                dataIndex: 'settlement_date',
                key: 'settlement_date',
                name: 'Settlement Date',
                sorter: { compare: (a,b) => {
                    const momentA = moment(a.settlement_date, 'DD/MM/YYYY');
                    const momentB = moment(b.settlement_date, 'DD/MM/YYYY');
                    const momentA_unix = momentA.unix();
                    const momentB_unix = momentB.unix();
                    return momentA - momentB;
                    } },
            },
            {
                title: 'Beneficiary',
                dataIndex: 'beneficiary',
                key: 'beneficiary',
                name: 'beneficiary',
                width: '500px',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'beneficiary',
                    label: 'Beneficiary',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{record.beneficiary}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'From',
                dataIndex: 'currency_from',
                key: 'currency_from',
                name: 'currency_from',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'currency_from',
                    label: 'Currency From',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{record.currency_from}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Amount From',
                dataIndex: 'amount_from',
                key: 'amount_from',
                name: 'amount_from',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'amount_from',
                    label: 'amount_from',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{Number(record.amount_from).toFixed(2)}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'To',
                dataIndex: 'currency_to',
                key: 'currency_to',
                name: 'currency_to',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'currency_to',
                    label: 'currency_to',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{record.currency_to}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Amount to',
                dataIndex: 'amount_to',
                key: 'amount_to',
                name: 'amount_to',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'amount_to',
                    label: 'amount_to',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{Number(record.amount_to).toFixed(2)}</span>
                            </>
                        );
                    }
                })
            },
            {
                title: 'Rate',
                dataIndex: 'client_rate',
                key: 'client_rate',
                name: 'client_rate',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'client_rate',
                    label: 'client_rate',
                    render: (text, record) => {
                        return (
                            <>
                                <span>{Number(record.client_rate).toFixed(4)}</span>
                            </>
                        );
                    }
                })
            },
            // {
            //     title: 'Cover',
            //     dataIndex: 'bank_rate',
            //     key: 'bank_rate',
            //     name: 'bank_rate',
            //     ...this.props.getColumnSearchProps({
            //         dataIndex: 'bank_rate',
            //         label: 'bank_rate',
            //         render: (text, record) => {
            //             return (
            //                 <>
            //                     <span style={{color: record.bank_rate ? '' : 'red'}}>{Number(record.bank_rate).toFixed(4)}</span>
            //                 </>
            //             );
            //         }
            //     })
            // },
            // {
            //     title: 'Profit',
            //     dataIndex: 'transferProfit',
            //     key: 'transferProfit',
            //     name: 'transferProfit',
            //     ...this.props.getColumnSearchProps({
            //         dataIndex: 'transferProfit',
            //         label: 'transferProfit',
            //         render: (text, record) => {
            //             return (
            //                 <>
            //                     <span>{Number(record.transferProfit).toFixed(2)}</span>
            //                 </>
            //             );
            //         }
            //     })
            // },
            {
                title: 'Status',
                dataIndex: 'transfer_status',
                key: 'transfer_status',
                name: 'transfer_status',
                filters: [
                    {text: 'Completed', value: 'Completed'},
                    {text: 'Draft', value: 'Draft'},
                    {text: 'In Process', value: 'In Process'},
                    {text: 'Acknowledged', value: 'Acknowledged'},
                    {text: 'Processing', value: 'Processing'},
                    {text: 'Awaiting Funds', value: 'Awaiting Funds'},
                    {text: 'Funds Received', value: 'Funds Received'},
                    {text: 'Payment Made', value: 'Payment Made'},
                    {text: 'On Hold', value: 'On Hold'},
                    {text: 'Not Proceeding', value: 'Not Proceeding'},
                ],
                onFilter: (value, record) => record.transfer_status != null ? record.transfer_status.indexOf(value) === 0 : false
            },
        ];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
            this.getTeamList()
        }
        if (this.state.startDate !== prevState.startDate || this.state.startDate !== prevState.startDate) {
            this.buildTableData();
        }
    }

    getTeamList = () => {
        if (this.props.app_state.current_staff) {
            TeamAPI.fetchTeamList(this.props.app_state.current_staff.id).then(response => {
                if (response != null && response.length > 0) {
                    const teamList = response.sort(item => item.nickname.localeCompare(item.nickname)).map(item => ({ label: item.nickname, value: item.id }));
                    this.setState({
                        team_list: teamList,
                        // team_id: teamList[0].value
                    });
                }
            })
                .catch(error => {
                    console.log(error);
                });
        }
    }


    // = Start from functions =
    buildTableData() {
        let date_range_transfer_list = [...this.state.transfer_list];
        const filteredData = date_range_transfer_list.filter(transfer => { return transfer.status !== 10 });
        const transfer_list_length = filteredData.length;

        if (transfer_list_length > 0) {
            const data = filteredData.map((prop, key) => {

                const amountFrom = Number.parseFloat(prop.amount_from);
                const amountTo = Number.parseFloat(prop.amount_to);
                const clientRate = Number.parseFloat(prop.client_rate);
                const bankRate = Number.parseFloat(prop.bank_rate);

                let transferProfit = 0;

                if (prop.currency_from_iso_alpha_3 === 'AUD') {
                    transferProfit = ((amountFrom * clientRate) - (amountFrom * bankRate)) / bankRate;
                } else {
                    const inverseClientRate = 1 / Number.parseFloat(clientRate);
                    const inverseBankRate = 1 / Number.parseFloat(bankRate);
                    transferProfit = ((amountTo * inverseClientRate) - (amountTo * inverseBankRate)) / inverseBankRate;
                }

                transferProfit = transferProfit.toFixed(2);
                transferProfit = Math.abs(transferProfit);
                if (prop.fee_admin_amount != null) {
                    transferProfit = transferProfit - Number.parseFloat(prop.fee_admin_amount);
                }

                if (prop.status === 10) { transferProfit = 0}

                if (!bankRate) { transferProfit = 0 }
                return {
                    id: prop.id,
                    client: prop.client_nickname,
                    transaction_date: moment(prop.transaction_datetime).format('DD/MM/YYYY'),
                    creating_staff_nickname: prop.creating_staff_nickname,
                    src: prop.client_src,
                    memo: prop.memo,
                    nickname: prop.nickname,
                    settlement_date: moment(prop.settlement_date).format('DD/MM/YYYY'),
                    // beneficiary: prop.beneficiaries_nickname,
                    beneficiary: prop.payouts.length > 0 ? prop.payouts[0].beneficiary_nickname : '',
                    amount_from: prop.amount_from,
                    amount_to: prop.amount_to,
                    currency_from: prop.currency_from_iso_alpha_3,
                    currency_to: prop.currency_to_iso_alpha_3,
                    client_rate: prop.client_rate,
                    bank_rate: prop.bank_rate,
                    transferProfit: transferProfit,
                    fee_admin_amount: prop.fee_admin_amount,
                    transfer_status:
                        prop.status === 8
                            ? 'Completed'
                            : prop.status === 1
                                ? 'Draft'
                                : prop.status === 2
                                    ? 'In Process'
                                    : prop.status === 3
                                        ? 'Acknowledged'
                                        : prop.status === 4
                                            ? 'Processing'
                                            : prop.status === 5
                                                ? 'Awaiting Funds'
                                                : prop.status === 6
                                                    ? 'Funds Received'
                                                    : prop.status === 7
                                                        ? 'Payment Made'
                                                        : prop.status === 9
                                                            ? 'On Hold'
                                                            : prop.status === 10
                                                                ? 'Not Proceeding'
                                                                : '',
                    actions: (
                        <div className='actions-right'>
                            <Button
                                onClick={() => {
                                    this.props.history.push(`/transfers/edit/${prop.id}`);
                                }}
                                type={'primary'}
                            >
                                <Dvr />
                            </Button>{' '}
                            use this button to remove the data row
                            <Button
                                onClick={() => {
                                    const data = this.state.transfer_list;
                                    data.find((o, i) => {
                                        if (o.id === prop.id) {
                                            this.setState({
                                                dialog_delete_confirm: true,
                                                dialog_delete_item: prop.id,
                                                dialog_delete_item_name: `${prop.nickname}`
                                            });

                                            return true;
                                        }
                                        return false;
                                    });
                                    this.setState({ data: data });
                                }}
                                type={'primary'}
                            >
                                <Close />
                            </Button>{' '}
                        </div>
                    )
                };
            });
            
            this.setState({ data: data });
        } else {
            this.setState({ data: [] });
        }
    }

    download = event => {
        const currentRecords = this.state.data;
        let sortData = (currentRecord,  sortObject) => {
            sortObject = sortObject == null ? {columnKey: 'id', order: 'descend'} : sortObject;
            const {columnKey = 'id', order = 'descend'} = sortObject;
            return currentRecord.sort((a, b) => {
                const aValue = a[columnKey];
                const bValue = b[columnKey];

                // Compare the values based on the sort order
                if (order === 'ascend') {
                    return aValue - bValue;
                } else if (order === 'descend') {
                    return bValue - aValue;
                }
            });
        };
        const sortedData = sortData(currentRecords, this.state.sorts);

        const data_to_download = [];
        for (let index = 0; index < sortedData.length; index++) {
          let record_to_download = {};
          for (let colIndex = 0; colIndex < this.state.columns.length - 1; colIndex++) {
            record_to_download[this.state.columns[colIndex].dataIndex] = sortedData[index][this.state.columns[colIndex].dataIndex];
          }
          data_to_download.push(record_to_download);
        }
        this.setState({dataToDownload: data_to_download}, () => {
          // click the CSVLink component to trigger the CSV download
          this.csvLink.link.click();
        });
    };

    finished() {
        this.setState({ finished: true });
    }

    handle_date_change = (e) => {
        if (e == null) {
            this.setState({
                startDate: moment().subtract(1, 'month').startOf('month'),
                endDate: moment().subtract(1, 'month').endOf('month')
            }, () => this.fetchDataBySettlementDate());
        } else {
            this.setState({ startDate: e[0],  endDate: e[1] }, () => this.fetchDataBySettlementDate());
        }

    };

    fetchDataBySettlementDate = () => {
        this.setState({ loading: true });
        const payload = {from_date: this.state.startDate.format('YYYY-MM-DD'), to_date: this.state.endDate.format('YYYY-MM-DD')};
        API.post("transfers", `/getByTeam/id/${this.state.team_id}`, {body: payload}).then(res => {
            this.setState({transfer_list: res, loading: false}, () => {
                this.buildTableData();
            });
        })
    }

    handle_delete_dialog_click = item_to_delete => {
        this.setState({ dialog_delete_confirm: false });
        API.put('transfers', `/update/id/${item_to_delete}`, {
            body: { deleted: true }
        })
            .then(response => this.props.fetchTransferList())
            .catch(error => {
                console.log(error);
                this.props.fetchTransferList();
            });
    }

    handle_delete_dialog_cancel = () => {
        this.setState({
            dialog_delete_confirm: false,
            dialog_delete_item: 0,
            dialog_delete_item_name: ''
        });
    };

    getTransferDetails(team_id) {
        this.setState({ loading: true });
        if (!team_id) { return null }
        const payload = {from_date: this.state.startDate.format('YYYY-MM-DD'), to_date: this.state.endDate.format('YYYY-MM-DD')};
        API.post("transfers", `/getByTeam/id/${team_id}`, {body: payload})
            .then(response => {
                // console.log(response);
                this.setState({ transfer_list: response });
                this.buildTableData();
                this.setState({ loading: false });
            })
            .catch(error => {
                this.setState({ loading: false });
                console.log(error);
            });
    }

    handleSelectChange = value => {
        this.setState({ loading: true });
        this.setState({ team_id: value });
        this.getTransferDetails(value);
    };

    handleTableChange = (pagination, filters, sorter) => {
        console.log(sorter);
        this.setState({sorts: sorter});
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                        <FormItem name="client_id"
                                  label={'Team List'}>
                            <Select showSearch
                                    onChange={(e) => this.handleSelectChange(e)}
                                    style={{ width: '100%' }}
                                    placeholder="Search to Select"
                                    optionFilterProp="alt"
                                    filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input)}
                                    filterSort={
                                        (optionA, optionB) =>
                                            (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                                    }
                                    options={this.state.team_list}></Select></FormItem>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div style={{ float: 'right' }}>
                            <Button type={'primary'} onClick={this.download}>
                                 <span><i className='fa fa-download'></i> Download CSV</span>
                            </Button>
                        </div>
                    </GridItem>
                </GridContainer>
                {this.state.team_id &&
                <>

                <hr />
                <strong>Search Settlement Date Range&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                <RangePicker
                    format={'DD/MM/YYYY'}
                    onChange={e => this.handle_date_change(e)} />
                <p>
                    Currently displaying settlement dates of {this.state.startDate && this.state.endDate ? this.state.startDate.format('DD/MM/YYYY') : ''} to {this.state.startDate && this.state.endDate ? this.state.endDate.format('DD/MM/YYYY') : ''}.
                </p>
                <div style={{ width: '100%', overflow: 'scroll' }}>
                    <Table
                        dataSource={this.state.data}
                        ref={r => (this.reactTable = r)}
                        // data={this.state.table_data}
                        columns={this.state.columns}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                        size={'small'}
                    />
                    <CSVLink data={this.state.dataToDownload} filename="data.csv" className={'hidden'} ref={r => (this.csvLink = r)} target={'_blank'}/>
                </div>
                </>}

                <Dialog
                    open={this.state.dialog_delete_confirm}
                    onClose={this.handleClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            Are you sure that you wish to <strong>delete</strong> transfer record {this.state.dialog_delete_item_name}
                            ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handle_delete_dialog_cancel} color='primary'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => this.handle_delete_dialog_click(this.state.dialog_delete_item)}
                            color='danger'
                            autoFocus
                        >
                            Delete Record
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

ReportsTransfer.protoType = {
    fetchTransferList: ProtoTypes.func
};

export default withRouter(withStyles(styles)(ListTable(ReportsTransfer)));
