import {
  // STAFF_SELECT_STAFF_MEMBER,
  ENTITIES_FETCH_ENTITY_LIST_REQUEST,
  ENTITIES_FETCH_ENTITY_LIST_SUCCESS,
  // ENTITIES_FETCH_ENTITY_LIST_FAILURE,
  ENTITIES_CREATE_NEW_SHOW_UI,
  ENTITIES_CREATE_NEW_START,
  ENTITIES_CREATE_NEW_CANCEL,
  ENTITIES_CREATE_NEW_COMPLETE,
  ENTITIES_SELECT_UI,
  ENTITIES_ENTITIES_LIST_IS_DIRTY,
  ENTITIES_SELECT_SET_ID,
  ENTITIES_SELECT_ENTITY_REQUEST,
  ENTITIES_SELECT_ENTITY_SUCCESS
} from '../actions/entities.js';

const initialState = {
  is_fetching: false,
  did_invalidate: false,
  entities_list_is_dirty: false,
  entities_list: [],
  entities_detail_id: null,
  entities_detail_fetching: false,
  // staff_detail_loaded: false,
  entities_current_ui: 'list',
  // ui_show_create_new_staff_screen: false,
  // ui_show_edit_staff_screen: true,
  entities_create_new_in_process: false
  // staff_create_new_awaiting_confirmation: false
};

function staff(state = initialState, action) {
  switch (action.type) {
    // case STAFF_SELECT_STAFF_MEMBER:
    //   return Object.assign({}, state, {
    //     staff_detail_id: action.staff_id
    //   });

    case ENTITIES_FETCH_ENTITY_LIST_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true
        // console.log(action);
      });

    case ENTITIES_FETCH_ENTITY_LIST_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        entities_list: action.entities_list,
        lastUpdated: action.receivedAt
      });

    case ENTITIES_CREATE_NEW_SHOW_UI:
      return Object.assign({}, state, {
        // ui_show_create_new_staff_screen: action.value,
        // ui_show_edit_staff_screen: false,
        entities_current_ui: 'list'
      });
    //
    case ENTITIES_CREATE_NEW_START:
      return Object.assign({}, state, {
        entities_create_new_in_process: true
        // staff_create_new_awaiting_confirmation: true
      });

    case ENTITIES_CREATE_NEW_CANCEL:
      return Object.assign({}, state, {
        entities_create_new_in_process: false,
        // staff_create_new_awaiting_confirmation: false,
        entities_current_ui: 'list'
      });

    case ENTITIES_CREATE_NEW_COMPLETE:
      return Object.assign({}, state, {
        entities_create_new_in_process: false,
        // staff_create_new_awaiting_confirmation: false,
        // ui_show_create_new_staff_screen: false,
        entities_current_ui: 'list',
        entities_list_is_dirty: true
      });

    case ENTITIES_SELECT_UI:
      return Object.assign({}, state, {
        entities_current_ui: action.value
      });
    case ENTITIES_ENTITIES_LIST_IS_DIRTY:
      return Object.assign({}, state, {
        entities_list_is_dirty: action.value
      });

    case ENTITIES_SELECT_SET_ID:
      return Object.assign({}, state, {
        entities_detail_id: action.value
      });

    case ENTITIES_SELECT_ENTITY_REQUEST:
      return Object.assign({}, state, {
        // staff_detail: null,
        // staff_detail_id: action.staff_id,
        entities_detail_fetching: true
        // staff_detail_loaded: false
      });

    case ENTITIES_SELECT_ENTITY_SUCCESS:
      return Object.assign({}, state, {
        // staff_detail: action.staff_detail,
        entities_detail_fetching: false
        // staff_detail_loaded: true
      });

    default:
      return state;
  }
}

export default staff;
