import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import {API} from 'aws-amplify';
import {TextareaAutosize} from '@material-ui/core';
import Button from 'components/CustomButtons/Button.jsx';
import {Form, Input} from 'antd';
import moment from 'moment';

const styles = themes => ({
    customFormControlClasses: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        marginTop: '0px !important',
        marginBottom: '0px !important'
    },
    backgroundColor: {}
});

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


const GlobalIME_AddRemoteRemit = ({classes, query}) => {
    const [state, setState] = useState({
        AGENT_CODE: 'FOREX001',
        USER_ID: 'FOREX_API',
        PASSWORD: 'PASSWORD',
        AGENT_SESSION_ID: 'AGENT_SESSION_ID',
        ACCOUNT_NO: 'ACCOUNT_NO',
        TRANSFERAMOUNT: '11000.00',
        PAYMENTMODE: 'D',
        CALC_BY: 'C',
        LOCATION_ID: '96811068',
        PAYOUT_COUNTRY: 'NEPAL',
        SIGNATURE: '80a6af32a94befd3be8fc8de5b8f9f62d63f1f0d2fd6c2c729f862a928b7d227',
        paymentSaved: false
    });
    const [form] = Form.useForm();
    const [paymentForm] = Form.useForm();
    // form.setFieldsValue({
    //   remitType: 'Bank',
    //   rCurrency: 'AUD',
    //   serviceCharge: '0',
    //   rCommission: '0',
    //   source: 'Business',
    //   newAccount: 'N'
    // });

    const fetchBeneficiaries = async (query) => {
        if (query.beneficiary) {
            const [response, bank_list] = await Promise.all([
                API.get('beneficiaries', `/get/id/${query.beneficiary}`, {}),
                API.get('banking', `/fetch-nepal-banks`, {}),
            ]);
            console.log(bank_list)
            setState({
                ...state,
                swiftCode: response.swift_code,
                bankName: response.bank_legal_name
            });
            form.setFieldsValue({
                benefName: response.ben_legal_name,
                benefAddress: (response.ben_address_line_1 || '') + ' ' +
                    (response.ben_address_line_2 || '') + ' ' +
                    (response.ben_address_suburb || '') + ' ' +
                    (response.ben_address_state || '') + ' ' +
                    (response.ben_address_postcode || '') + ' ' +
                    (response.ben_address_country_full_name || ''),
                benefAccIDNo: response.account_number
            });
            const payingBank = bank_list.find(item => (item.swift != null && item.swift.startsWith(response.swift_code)) ? item : null);
            if (payingBank != null) {
                form.setFieldsValue({
                    payingBankBranchCd: payingBank.branchCode
                });
            }
        }
    }

    const fetchClientIdentification = async (query) => {
        const identification = await API.get('identification', `/get/client_id/${query.clientID}`, {});
        console.log("Identification", identification);
        const client_identification = identification.find(item => item.client_id === Number(query.clientID) && item.document_category_id === 2);
        if (client_identification != null) {
            form.setFieldsValue({
                senderIdType: 'Driver licence',
                senderIdNo: client_identification.document_number
            });
        }
    }

    useEffect(() => {

        if (query) {
            form.setFieldsValue({
                paymentID: query.paymentID,
                controlNo: 'p' + query.paymentID + '.t' + query.transferID,
            });

            if (query.paymentID) {
                let amount;
                API.get('payments', `/id/${query.paymentID}`)
                    .then(response => {
                        amount = response.amount_to
                        form.setFieldsValue({
                            remarks: response.detail_1,
                            amount: amount,
                            purpose: response.purposeOfPayment
                        });
                        paymentForm.setFieldsValue({
                            // amount: response.fullList.amount_to,
                            transferReference: query.transferID,
                            paymentReference: query.paymentID,
                            payoutBankID: "Global IME",
                        });

                        if (query.transferID) {
                            API.get('transfers', `/get-full/id/${query.transferID}`)
                                .then(response => {
                                    form.setFieldsValue({
                                        // amount: response.fullList.amount_to,
                                        exchangeRate: response.fullList.client_rate,
                                        localAmount: (Number.parseFloat(amount) / Number.parseFloat(response.fullList.client_rate)).toFixed(2),
                                    });
                                    paymentForm.setFieldsValue({
                                        // amount: response.fullList.amount_to,
                                        transferReference: query.transferID,
                                    });
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }

                    })
                    .catch(error => {
                        console.log(error);
                    });
            }


            if (query.clientID) {
                API.get('clients', `/get/id/${query.clientID}`)
                    .then(response => {
                        console.log(response)
                        form.setFieldsValue({
                            senderName: response.nickname,
                            senderAddress: (response.residential_street_line_1 || '') + ' ' +
                                (response.residential_street_line_2 || '') + ' ' +
                                (response.residential_street_suburb || '') + ' ' +
                                (response.residential_street_state || '') + ' ' +
                                (response.residential_street_postcode || '') + ' ' +
                                (response.residential_street_country_full_name || ''),
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }

            fetchBeneficiaries(query);
            fetchClientIdentification(query);
        }
    }, [query]);


    const handleChange = (stateName, value) => {
        setState({
            ...state,
            [stateName]: value
        });
    }

    const onFinish = (values: any) => {
        console.log(values);
        API.post('banking', '/gime/add-remote-remit', {body: values})
            .then(response => {
                console.log(response);
                console.log(response.axiosResponse);
                setState(state => {
                    return {
                        ...state,
                        axiosResponse: response.axiosResponse
                    };
                });
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                console.log('finally')
            });
    };

    const handleSavePayment = (values: any) => {
        console.log(values);
        API.put('transfers', `/update/update_transfer_payout/${values.paymentReference}`, {
            body: {
                payoutBankId: 31,
                payoutDate: moment().format('YYYY-MM-DD'),
                payoutRef: values.bankPaymentReference,
            }
        })
            .then(response => {
                console.log(response);
                setState({
                    paymentSaved: true
                })
            })
            .catch(error => {
                console.log(error);
            })
    };

    return (
        <Fragment>
            <GridContainer>
                <GridItem xs={6}>
                    <Form
                        {...layout}
                        form={form}
                        name="control-hooks"
                        onFinish={onFinish}
                        style={{maxWidth: 600}}
                    >

                        <Form.Item name="paymentID" label="paymentID" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="controlNo" label="controlNo" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="benefName" label="benefName" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="benefAddress" label="benefAddress" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        {/* <Form.Item name="benefTel" label="benefTel" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item name="benefMobile" label="benefMobile" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item name="benefIdType" label="benefIdType" rules={[{ required: false }]}>
        <Input />
      </Form.Item> */}
                        <Form.Item name="senderName" label="senderName" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="senderAddress" label="senderAddress" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        {/* <Form.Item name="senderTel" label="senderTel" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item name="senderMobile" label="senderMobile" rules={[{ required: false }]}>
        <Input />
      </Form.Item> */}
                        <Form.Item name="senderIdType" label="senderIdType" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="senderIdNo" label="senderIdNo" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        {/* <Form.Item name="customerDOB" label="customerDOB" rules={[{ required: false }]}>
        <Input />
      </Form.Item> */}
                        <Form.Item name="purpose" label="purpose" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        {/* <Form.Item name="remitType" label="remitType" rules={[{ required: true }]}>
        <Input />
      </Form.Item> */}
                        <Form.Item name="payingBankBranchCd" label="payingBankBranchCd" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <p>
                            <strong>Extra Information</strong><br/>
                            <strong>Bank Details: </strong><em>{state.swiftCode} | {state.bankName}</em>
                        </p>
                        <Form.Item name="benefAccIDNo" label="benefAccIDNo" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        {/* <Form.Item name="rCurrency" label="rCurrency" rules={[{ required: true }]}>
        <Input />
      </Form.Item> */}
                        <Form.Item
                            name="amount"
                            label="amount"
                            rules={[{
                                required: true,
                                message: 'Must enter an amount greater than zero'
                            }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="exchangeRate" label="exchangeRate" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="localAmount" label="localAmount" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        {/* <Form.Item name="serviceCharge" label="serviceCharge" rules={[{ required: true }]}>
        <Input />
      </Form.Item> */}
                        {/* <Form.Item name="rCommission" label="rCommission" rules={[{ required: true }]}>
        <Input />
      </Form.Item> */}
                        <Form.Item name="remarks" label="remarks" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        {/* <Form.Item name="source" label="source" rules={[{ required: true }]}>
        <Input />
      </Form.Item> */}
                        {/* <Form.Item name="newAccount" label="newAccount" rules={[{ required: true }]}>
        <Input />
      </Form.Item> */}
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form>

                </GridItem>

                <GridItem xs={6}>

                    <TextareaAutosize
                        minRows={13}
                        style={{width: '100%'}}
                        multiline
                        aria-label='maximum height'
                        disabled
                        placeholder='JSON Response'
                        value={state.axiosResponse}
                    />

                    <Form
                        {...layout}
                        form={paymentForm}
                        name="control-hooks"
                        onFinish={handleSavePayment}
                        style={{maxWidth: 600}}
                    >

                        <Form.Item name="transferReference" label="Transfer ID">
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item name="paymentReference" label="Payment ID">
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item name="payoutBankID" label="Payout Bank">
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item name="bankPaymentReference" label="Bank Confirmation" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save Payout Details
                        </Button>
                        {state.paymentSaved &&
                            <p><strong>
                                Payment Details have been updated
                            </strong></p>
                        }
                    </Form>


                </GridItem>


            </GridContainer>
        </Fragment>
    );
};

GlobalIME_AddRemoteRemit.propTypes = {classes: PropTypes.object.isRequired};

export default withStyles(styles)(GlobalIME_AddRemoteRemit);
